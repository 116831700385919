import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accountsSlice, accountsThunk } from 'app/store';
import { UserTable } from './UserTable';

import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

const { fetchAccountsPage } = accountsThunk;
const {
    getManagementAccounts,
    getManagementPageInfo,
    resetAccountLoadedStatus,
} = accountsSlice;

function UserManagementTab() {
    const dispatch = useDispatch();
    const ref = useRef(false);
    const managementAccounts = useSelector(getManagementAccounts);
    const pageInfo = useSelector(getManagementPageInfo);
    const loadedAccountsStatus = useSelector(
        (state) => state.accounts.loadedAccountsStatus
    );
    const [userPayload, setUserPayload] = useState({});
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');

    const getUserData = async (payload) => {
        dispatch(activateLoading());
        await dispatch(fetchAccountsPage({ ...payload }));
        dispatch(deactivateLoading());
    };

    useEffect(() => {
        if (ref.current === true) {
            getUserData({ ...userPayload });
        }
        return () => {
            dispatch(resetAccountLoadedStatus());
        };
    }, [userPayload]);

    useEffect(() => {
        if (loadedAccountsStatus === LoadingStatus.Idle) {
            getUserData({ ...userPayload });
        }
        ref.current = true;
    }, [loadedAccountsStatus]);

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        if (_sortBy === 'is_active') {
            _sortDirection = _sortDirection === 'ASC' ? 'DESC' : 'ASC';
        }
        setUserPayload({
            ...userPayload,
            sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
        });
    };

    return (
        <UserTable
            users={managementAccounts}
            pageInfo={pageInfo}
            userPayload={userPayload}
            setUserPayload={setUserPayload}
            sortDirection={sortDirection}
            sortBy={sortBy}
            handleSort={(_sortBy, _sortDirection) =>
                setSorting(_sortBy, _sortDirection)
            }
        />
    );
}

export { UserManagementTab };
