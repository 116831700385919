import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import CheckboxField from '../CheckboxField';
import DateField from '../DateField';
import FormTextField from '../FormTextField';
import InitialField from '../InitialField';
import InspectionField from '../InspectionField';
import LabelField from '../LabelField';
import LinkField from '../LinkField';
import MediaCompareField from '../MediaCompareField';
import MediaField from '../MediaField';
import MultilineField from '../MultilineField';
import RadioGroupField from '../RadioGroupField';
import SelectField from '../SelectField';
import { makeCommonFormPreviewStyles } from '../../styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

const TableFieldChildElement = ({
    fieldData,
    fieldValue,
    setFieldValue,
    errors,
    setErrors,
    handleBlur,
    groupKeyPrefix = 'Group',
    isReadOnly = false,
    isSubmitAllow = true,
    handleClick = () => {},
    isMobileWidth,
    contentService,
}) => {
    const theme = useSelector(selectActiveTheme);
    const formStyles = makeCommonFormPreviewStyles(theme);

    return (
        <>
            {fieldData.type === 'text' && (
                <FormTextField
                    data={fieldData}
                    inGroup={true}
                    key={`${groupKeyPrefix}${fieldData?.id}`}
                    isReadOnly={isReadOnly}
                    isSubmitAllow={isSubmitAllow}
                    handleBlur={handleBlur}
                    fieldValue={fieldValue}
                    handleClick={handleClick}
                />
            )}

            {fieldData.type === 'initial' && (
                <InitialField
                    key={`${groupKeyPrefix}${fieldData?.id}`}
                    data={fieldData}
                    fieldValue={fieldValue}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    setErrors={setErrors}
                    inGroup={true}
                    isReadOnly={isReadOnly}
                    isSubmitAllow={isSubmitAllow}
                    handleClick={handleClick}
                />
            )}

            {fieldData.type === 'multiline' && (
                <MultilineField
                    key={`${groupKeyPrefix}${fieldData?.id}`}
                    data={fieldData}
                    inGroup={true}
                    isReadOnly={isReadOnly}
                    isSubmitAllow={isSubmitAllow}
                    handleBlur={handleBlur}
                    fieldValue={fieldValue}
                    handleClick={handleClick}
                />
            )}

            {fieldData.type === 'link' && (
                <LinkField
                    data={fieldData}
                    fieldValue={fieldValue}
                    styles={formStyles}
                    key={`${groupKeyPrefix}${fieldData?.id}`}
                    isReadOnly={isReadOnly}
                    isSubmitAllow={isSubmitAllow}
                    handleClick={handleClick}
                />
            )}

            {fieldData.type === 'checkbox' && (
                <CheckboxField
                    key={`${groupKeyPrefix}${fieldData?.id}`}
                    data={fieldData}
                    inGroup={true}
                    fieldValue={fieldValue}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    setErrors={setErrors}
                    validationError={errors?.[fieldData?.variable]}
                    isReadOnly={isReadOnly}
                    isSubmitAllow={isSubmitAllow}
                    handleBlur={handleBlur}
                    handleClick={handleClick}
                />
            )}

            {fieldData.type === 'select' && (
                <SelectField
                    key={`${groupKeyPrefix}${fieldData?.id}`}
                    data={fieldData}
                    inGroup={true}
                    fieldValue={fieldValue}
                    setFieldValue={setFieldValue}
                    validationError={errors?.[fieldData?.variable]}
                    isReadOnly={isReadOnly}
                    isSubmitAllow={isSubmitAllow}
                    handleBlur={handleBlur}
                    handleClick={handleClick}
                />
            )}

            {fieldData.type === 'datetime' && (
                <DateField
                    key={`${groupKeyPrefix}${fieldData?.id}`}
                    data={fieldData}
                    inGroup={true}
                    fieldValue={fieldValue}
                    setFieldValue={setFieldValue}
                    validationError={errors?.[fieldData?.variable]}
                    isReadOnly={isReadOnly}
                    isSubmitAllow={isSubmitAllow}
                    handleBlur={handleBlur}
                    handleClick={handleClick}
                />
            )}

            {fieldData.type === 'radiogroup' && (
                <RadioGroupField
                    key={`${groupKeyPrefix}${fieldData?.id}`}
                    data={fieldData}
                    inGroup={true}
                    fieldValue={fieldValue}
                    setFieldValue={setFieldValue}
                    validationError={errors?.[fieldData?.variable]}
                    isReadOnly={isReadOnly}
                    isSubmitAllow={isSubmitAllow}
                    handleBlur={handleBlur}
                    handleClick={handleClick}
                />
            )}

            {fieldData.type === 'label' && (
                <LabelField
                    key={`${groupKeyPrefix}${fieldData?.id}`}
                    data={fieldData}
                    inGroup={true}
                    isSubmitAllow={isSubmitAllow}
                    handleClick={handleClick}
                    contentService={contentService}
                />
            )}
            {fieldData.type === 'media' && (
                <MediaField
                    key={`${groupKeyPrefix}${fieldData?.id}`}
                    isSubmitAllow={isSubmitAllow}
                    errors={errors}
                    setErrors={setErrors}
                    data={fieldData}
                    inGroup={true}
                    handleClick={handleClick}
                />
            )}

            {fieldData.type === 'mediacompare' && (
                <MediaCompareField
                    key={`${groupKeyPrefix}${fieldData?.id}`}
                    data={fieldData}
                    isSubmitAllow={isSubmitAllow}
                    inGroup={true}
                    handleClick={handleClick}
                />
            )}
            {fieldData.type === 'inspectionpoint' && (
                <Box
                    border={`${
                        isMobileWidth === true &&
                        !fieldData?.showBorder &&
                        '1px solid black'
                    }`}
                    style={{
                        borderRadius: '4px',
                    }}
                >
                    <InspectionField
                        key={`${groupKeyPrefix}${fieldData?.id}`}
                        data={fieldData}
                        fieldValue={fieldValue}
                        setFieldValue={setFieldValue}
                        isSubmitAllow={isSubmitAllow}
                        inGroup={true}
                        validationError={errors?.[fieldData?.variable]}
                        handleClick={handleClick}
                        handleBlur={handleBlur}
                    />
                </Box>
            )}
        </>
    );
};

export { TableFieldChildElement };
