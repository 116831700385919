import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { locationService } from '_features/_services';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';

const initialState = {
    locations: [],
    locationStatus: LoadingStatus.Idle,
    locationPageInfo: {},
    displayLocation: {},
    loadedLocationMap: {},
};

export const fetchLocationsPage = createAsyncThunk(
    'location/fetchLocationsPage',
    async (params = {}, { getState }) =>
        await locationService.getLocations(
            params.searchString,
            params.sort,
            getState().accounts.activeUser.s3_prefix
        )
);

export const fetchLocationById = createAsyncThunk(
    'location/fetchLocationById',
    async (locationId, { getState }) => {
        const response = await locationService.getById(
            locationId,
            getState().accounts.activeUser.s3_prefix
        );
        response.id = locationId;
        return response;
    }
);

export const createLocation = createAsyncThunk(
    'location/createLocation',
    async (params, { getState }) =>
        await locationService.create(
            params,
            getState().accounts.activeUser.s3_prefix
        )
);

export const updateLocation = createAsyncThunk(
    'location/updateLocation',
    async (params, { getState }) =>
        await locationService.update(
            params,
            getState().accounts.activeUser.s3_prefix
        )
);

export const deleteLocation = createAsyncThunk(
    'location/deleteLocation',
    async ({ tool_location_id }) => {
        const response = await locationService.delete(tool_location_id);
        return response;
    }
);

// export const deleteLocationsArray = createAsyncThunk(
//     'assets/deleteLocationsArray',
//     async (locationIds) => {
//         const response = await locationService.deleteArray(locationIds);
//         response.ids = locationIds;
//         return response;
//     }
// );

const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        resetLocationState: (state) => initialState,
    },
    extraReducers: {
        [fetchLocationsPage.pending]: (state, action) => {
            state.locationStatus = LoadingStatus.Loading;
        },
        [fetchLocationsPage.fulfilled]: (state, action) => {
            state.locationPageInfo = action.payload.pageInfo;
            state.locations = action.payload.locations;
            state.locationStatus = LoadingStatus.Loaded;
        },
        [fetchLocationsPage.rejected]: (state, action) => {
            state.locationStatus = LoadingStatus.Failed;
        },
        [createLocation.fulfilled]: (state, action) => {
            state.locationStatus = LoadingStatus.Idle;
        },
        [updateLocation.fulfilled]: (state, action) => {
            state.locationStatus = LoadingStatus.Idle;
        },
        [deleteLocation.fulfilled]: (state, action) => {
            state.locationStatus = LoadingStatus.Idle;
        },
        // [deleteLocationsArray.fulfilled]: (state, action) => {
        //     state.locationStatus = LoadingStatus.Idle;
        // },
        // [deleteLocationsArray.rejected]: (state, action) => {
        //     state.status = SavingStatus.Failed;
        //     state.error = action.error.message;
        // },
        [fetchLocationById.fulfilled]: (state, action) => {
            state.displayLocation = action.payload;
        },
    },
});

export const selectLocations = (state) => state.location.locations;
export const getLocationStatus = (state) => state.location.locationStatus;
export const getLocationPageInfo = (state) => state.location.locationPageInfo;

export const { resetLocationState } = locationSlice.actions;

export default locationSlice.reducer;
