import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { itemService } from '_features/_services';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';

const initialState = {
    status: LoadingStatus.Idle,
    error: null,
    item: {},
};

export const searchItems = createAsyncThunk(
    'items/searchItems',
    async (params) => {
        return await itemService.search(params.assetId, params.searchString);
    }
);

const itemsSlice = createSlice({
    name: 'items',
    initialState,
    extraReducers: {
        [searchItems.pending]: (state, action) => {
            state.status = LoadingStatus.Loading;
            state.item = {};
        },
        [searchItems.fulfilled]: (state, action) => {
            state.item = action.payload;
            state.status = LoadingStatus.Loaded;
        },
        [searchItems.rejected]: (state, action) => {
            state.status = LoadingStatus.Failed;
            state.error = action.error.message;
        },
    },
});

export const selectSearchItemsLoadingStatus = (state) =>
    state.itemsSlice.status;

export default itemsSlice.reducer;
