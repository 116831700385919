import React from 'react';
import { useSelector } from 'react-redux';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { Autocomplete, Checkbox, Grid, TextField } from '@mui/material';

import useStyles from '../../../styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { MultiSelectDoneButton } from 'GeminiViewerComponent/components/MultiSelectDoneButton';
import { getNewsFlashDisplayOptions } from 'GeminiViewerComponent/_features/newsFlash/newsFlashSlice';

const DisplayOptionFilter = ({
    debouncedSearch,
    searchString,
    startDate,
    endDate,
    displayOptionId,
    setDisplayOptionId,
}) => {
    //#region Constants
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    //#endregion State

    //#region Selectors
    const theme = useSelector(selectActiveTheme);
    const newsFlashDisplayOptions = useSelector(getNewsFlashDisplayOptions);
    const classes = useStyles(theme);
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    //#endregion Effects

    //#region Methods
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    return (
        <Grid
            item={true}
            xs={12}
            md={6}
            lg={6}
            className={classes.customSelect}
        >
            <Autocomplete
                variant="outline"
                limitTags={2}
                multiple
                id="news_flash_display_option"
                options={newsFlashDisplayOptions || []}
                onChange={(event, newInputValue) => {
                    setDisplayOptionId(newInputValue);
                    const array = newInputValue.map(
                        (item) => item.news_flash_display_option_id
                    );
                    debouncedSearch(searchString, startDate, endDate, array);
                }}
                PaperComponent={MultiSelectDoneButton}
                disableCloseOnSelect
                value={displayOptionId}
                getOptionLabel={(option) => option.display_name}
                renderOption={(props, option, { selected }) => (
                    <li
                        {...props}
                        key={option.news_flash_display_option_id}
                        style={{
                            padding: '0',
                        }}
                    >
                        <Checkbox
                            icon={
                                <MdCheckBoxOutlineBlank className="react-icon" />
                            }
                            checkedIcon={<MdCheckBox className="react-icon" />}
                            style={{
                                marginRight: 8,
                            }}
                            checked={selected}
                        />
                        {option.display_name}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Display Options"
                        placeholder="Display Options"
                    />
                )}
            />
        </Grid>
    );
};

export { DisplayOptionFilter };
