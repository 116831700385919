import { IconButton } from '@mui/material';
import { MdAdd } from 'react-icons/md';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import SelectAnswerField from './SelectAnswerField';
import SelectPairsTargetNode from './SelectPairsTargetNode';

const SelectAnswerGroupType = ({
    node,
    nodes,
    values,
    setFieldValue,
    nodeFormStyles,
    onDragEnd,
    handleRemove,
    handleRemoveOptionsSelected,
}) => {
    const handleAddSelectOption = (select_options, setFieldValue) => {
        if (!Array.isArray(select_options)) {
            select_options = [];
        }
        const selectOpts = [...select_options];
        selectOpts.push({
            action: {
                id: '',
                type: 'node',
            },
            option_id: Number(selectOpts?.length),
            option_text: '',
            incorrect: false,
            answer_value: '',
        });
        setFieldValue('select_options', selectOpts);
    };

    const handleAddSelectPairTarget = (
        select_target_combinations,
        setFieldValue
    ) => {
        if (!Array.isArray(select_target_combinations)) {
            select_target_combinations = [];
        }
        const selectPairs = [...select_target_combinations];
        selectPairs.push({
            action: {
                id: '',
                type: 'node',
            },
            select_pair_id: Number(selectPairs?.length),
            options_required: [],
            incorrect: false,
            answer_value: '',
        });
        setFieldValue('select_target_combinations', selectPairs);
    };

    return (
        <>
            <div>
                Select Options{' '}
                <IconButton
                    className={nodeFormStyles.addButtonIcon}
                    onClick={() =>
                        handleAddSelectOption(
                            values?.select_options,
                            setFieldValue
                        )
                    }
                    size="small"
                >
                    <MdAdd className="react-icon" />
                </IconButton>
            </div>
            <DragDropContext
                onDragEnd={(result) =>
                    onDragEnd(
                        result,
                        values?.select_options,
                        'select_options',
                        setFieldValue
                    )
                }
            >
                <Droppable droppableId="droppable-content-nodes-selects">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                                margin: '40px 0',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '8px',
                            }}
                        >
                            {values?.select_options?.map((option, idx) => {
                                return (
                                    <SelectAnswerField
                                        option={option}
                                        key={`SelectField${idx}`}
                                        idx={idx}
                                        node={node}
                                        nodes={nodes}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        provided={provided}
                                        handleRemove={(id) => {
                                            let opt_txt =
                                                values.select_options[id]
                                                    .option_text;
                                            handleRemove(
                                                id,
                                                values.select_options,
                                                'select_options',
                                                setFieldValue
                                            );
                                            handleRemoveOptionsSelected(
                                                opt_txt,
                                                values.select_target_combinations,
                                                'select_target_combinations',
                                                setFieldValue
                                            );
                                        }}
                                    />
                                );
                            })}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {values?.multiple_answers_allow === true &&
                Array.isArray(values?.select_options) &&
                values?.select_options?.filter((opt) => opt?.option_text)
                    ?.length > 1 && (
                    <>
                        <div>
                            Target Combinations{' '}
                            <IconButton
                                className={nodeFormStyles.addButtonIcon}
                                onClick={() =>
                                    handleAddSelectPairTarget(
                                        values?.select_target_combinations,
                                        setFieldValue
                                    )
                                }
                                size="small"
                            >
                                <MdAdd className="react-icon" />
                            </IconButton>
                        </div>
                        <DragDropContext
                            onDragEnd={(result) =>
                                onDragEnd(
                                    result,
                                    values?.select_target_combinations,
                                    'select_target_combinations',
                                    setFieldValue
                                )
                            }
                        >
                            <Droppable droppableId="droppable-content-nodes-select-combinations">
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        style={{
                                            margin: '40px 0',
                                        }}
                                    >
                                        {values?.select_target_combinations?.map(
                                            (option, idx) => {
                                                return (
                                                    <SelectPairsTargetNode
                                                        option={option}
                                                        selectOptions={values?.select_options?.filter(
                                                            (opt) =>
                                                                opt?.option_text
                                                        )}
                                                        key={`SelectPairForTarget${idx}`}
                                                        idx={idx}
                                                        node={node}
                                                        nodes={nodes}
                                                        values={values}
                                                        setFieldValue={
                                                            setFieldValue
                                                        }
                                                        provided={provided}
                                                        handleRemove={(id) =>
                                                            handleRemove(
                                                                id,
                                                                values.select_target_combinations,
                                                                'select_target_combinations',
                                                                setFieldValue
                                                            )
                                                        }
                                                    />
                                                );
                                            }
                                        )}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                )}
        </>
    );
};

export default SelectAnswerGroupType;
