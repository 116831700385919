import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    deletedAsset,
    updateActiveAsset,
    updateOrbitZoneExistence,
} from 'GeminiViewerComponent/_features/asset/assetSlice';
import { assetService } from '_features/_services';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';

const initialState = {
    editAsset: {},
    loadingStatus: LoadingStatus.Idle,
    savingStatus: SavingStatus.Idle,
};

export const getEditAsset = createAsyncThunk(
    'editAsset/getEditAsset',
    async (assetId, { getState, dispatch }) => {
        const response = await assetService.getById(
            assetId,
            getState().accounts.activeUser.s3_prefix
        );
        return response;
    }
);

export const editAsset = createAsyncThunk(
    'editAsset/editAsset',
    async (updates, { getState, dispatch }) => {
        const response = await assetService.update(
            updates,
            getState().accounts.activeUser.s3_prefix
        );
        if (!response.error) {
            dispatch(
                updateActiveAsset({
                    ...response,
                    s3_prefix: getState().accounts.activeUser.s3_prefix,
                })
            );
        }
        return response;
    }
);

export const editOrbitAssetImages = createAsyncThunk(
    'editAsset/editOrbitAssetImages',
    async (updates, { getState, dispatch }) => {
        const response = await assetService.updateOrbitImages(
            updates,
            getState().accounts.activeUser.s3_prefix
        );
        if (!response.error) {
            dispatch(
                updateActiveAsset({
                    ...response,
                    s3_prefix: getState().accounts.activeUser.s3_prefix,
                })
            );
        }
        return response;
    }
);

export const deleteAsset = createAsyncThunk(
    'editAsset/deleteAsset',
    async (assetId, { dispatch }) => {
        const response = await assetService.delete(assetId);
        if (!response.error) {
            response.id = assetId;
            dispatch(deletedAsset(assetId));
        }
        return response;
    }
);

const editAssetSlice = createSlice({
    name: 'editAsset',
    initialState,
    reducers: {
        clearEditAsset: (state) => {
            state.editAsset = {};
        },
    },
    extraReducers: {
        [getEditAsset.pending]: (state) => {
            state.loadingStatus = LoadingStatus.Loading;
        },
        [getEditAsset.fulfilled]: (state, action) => {
            state.editAsset = action.payload;
            updateOrbitZoneExistence(state.editAsset);
            state.loadingStatus = LoadingStatus.Loaded;
        },
        [getEditAsset.rejected]: (state) => {
            state.loadingStatus = LoadingStatus.Failed;
        },
        [editAsset.pending]: (state) => {
            state.savingStatus = SavingStatus.Saving;
        },
        [editAsset.fulfilled]: (state, action) => {
            state.editAsset = action.payload;
            state.savingStatus = SavingStatus.Saved;
        },
        [editAsset.rejected]: (state) => {
            state.savingStatus = SavingStatus.Failed;
        },
        [deleteAsset.fulfilled]: (state, action) => {
            if (action.payload === state.editAsset?.asset_id) {
                state.editAsset = {};
            }
        },
    },
});

export const selectEditAsset = (state) => state.editAsset.editAsset;

export const selectEditAssetLoadingStatus = (state) =>
    state.editAsset.loadingStatus;
export const selectEditAssetSavingStatus = (state) =>
    state.editAsset.savingStatus;

export const { clearEditAsset } = editAssetSlice.actions;

export default editAssetSlice.reducer;
