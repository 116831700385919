import React from 'react';

import { Action } from '_helpers';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';

function JobStatusAssetExportPopup({ object, level, onClose, readOnlyMode }) {
    return (
        <PopupAction
            action={Action.Delete}
            object={object}
            level={level}
            showLabel={true}
            onActionHandled={onClose}
        />
    );
}

export { JobStatusAssetExportPopup };
