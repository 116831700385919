import { LABEL_FIELD_TYPE } from 'components/ProcedureDesigner/components/FormNodeEditor/_helpers/constant';
import { assetService } from './asset.service';

const fillBranchNodes = async ({
    branchesAdded,
    parentProcedure,
    nodes,
    assetsLoaded,
    procedureIdsLoaded,
}) => {
    if (
        !Array.isArray(nodes) ||
        nodes?.length === 0 ||
        procedureIdsLoaded.has(parentProcedure.procedure_id)
    ) {
        if (procedureIdsLoaded.has(parentProcedure.procedure_id)) {
            console.log(
                `BRANCH: Warning: recursive reference to ${parentProcedure.procedure_id}`
            );
        }
        return branchesAdded;
    }
    for (var node of nodes) {
        if (node.type === 'branch') {
            if (node.asset_id !== 0) {
                // If a parent already loaded this asset
                let response = assetsLoaded[node.asset_id];
                if (!response) {
                    response = await assetService.getById(
                        node.asset_id,
                        '',
                        false
                    );
                }
                if (response && Number(response.asset_type_id) === 3) {
                    branchesAdded++;
                    var procedure = response.procedure;
                    procedure.procedure_id = response.procedure_id;
                    procedure.parent_tree_id = parentProcedure.procedure_id;
                    node.asset_title = procedure.title ?? node.title;
                    node.branch_procedure = procedure;

                    // Recursively get branch nodes in current tree if this isn't a repeat
                    assetsLoaded[node.asset_id] = response;
                    procedureIdsLoaded.add(parentProcedure.procedure_id);
                    branchesAdded = fillBranchNodes({
                        branchesAdded: branchesAdded,
                        parentProcedure: procedure,
                        nodes: procedure.nodes,
                        assetsLoaded: assetsLoaded,
                        procedureIdsLoaded: procedureIdsLoaded,
                    });
                }
            }
        }
    }
    return branchesAdded;
};

const updateNodeFields = (node) => {
    node.fields?.forEach((field) => {
        if (field.type === LABEL_FIELD_TYPE.id && field.content) {
            if (!field.labelcontent) {
                field.labelcontent = field.content;
            }
            delete field.content;
        }
        if (field.fields) {
            updateNodeFields(field);
        }
    });
};
export const updateProcedureNodes = async (procedure, followBranches) => {
    // var procedureNodes = cloneDeep(procedure.nodes);
    if (followBranches) {
        var branchesAdded = 0;
        const assetsLoaded = {};
        const procedureIdsLoaded = new Set();

        await fillBranchNodes({
            branchesAdded: branchesAdded,
            parentProcedure: procedure,
            nodes: procedure.nodes,
            assetsLoaded: assetsLoaded,
            procedureIdsLoaded: procedureIdsLoaded,
        });
    }

    procedure.nodes?.forEach((node) => {
        updateNodeFields(node);
    });
};
