import { CaptionLanguages } from 'GeminiViewerComponent/_helpers';

const VideoCaptions = ({ contentURL, captionLanguages }) => {
    if (!captionLanguages) {
        return null;
    }
    return captionLanguages.map((caption, index) => {
        const captionLanguage = Object.values(CaptionLanguages)?.find(
            (lang) => lang.LanguageCode === caption
        );
        return (
            <track
                key={`caption-${index}`}
                label={captionLanguage.DisplayName}
                kind="subtitles"
                srcLang={captionLanguage.LanguageCode}
                src={`${contentURL}_${captionLanguage.LanguageCode}.vtt`}
                default={index == 0 ? true : undefined}
            />
        );
    });
};

export { VideoCaptions };
