import { makeStyles } from '@mui/styles';

const orbitStyles = makeStyles(() => ({
    orbitContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    orbitViewWrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
    },
    orbitView: {
        margin: '0 auto',
        '& #viewerImage': {
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            overflow: 'visible',
        },
        height: '100%',
        width: '100%',
    },
    splitOrbitView: {
        height: '100%',
        width: '100%',
    },
    splitOrbitViewAuto: {
        height: 'auto',
    },
    orbitHeader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '0px',
        left: '0px',
    },
    orbitNavigation: {
        width: '100%',
        position: 'absolute',
        top: '50%',
        display: 'flex',
        flexDirection: 'row',
        height: '30px',
        alignSelf: 'center',
        justifyContent: 'space-between',
        pointerEvents: 'none',
    },
    navigationButton: {
        pointerEvents: 'all',
        backgroundColor: '#0009',
        borderRadius: '5px',
        color: 'white',
        width: '30px',
        height: '30px',
        '&:hover': {
            backgroundColor: '#FFF9',
            color: 'black',
        },
    },
}));

export { orbitStyles };
