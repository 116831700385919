import React from 'react';
import { Box, Divider, Popover } from '@mui/material';

import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { Action } from '_helpers';

const ItemHotspotPopup = ({ object, level, anchorEl, setAnchorEl }) => {
    const open = Boolean(anchorEl);

    const handleClose = () => setAnchorEl(null);

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
            <Box p={2}>
                <PopupAction
                    action={Action.Unposition}
                    object={object}
                    level={level}
                    showLabel={true}
                    onActionHandled={handleClose}
                />
                <Divider />
                <PopupAction
                    action={Action.ResetPosition}
                    object={object}
                    level={level}
                    showLabel={true}
                    onActionHandled={handleClose}
                />
            </Box>
        </Popover>
    );
};

export { ItemHotspotPopup };
