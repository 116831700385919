import { makeStyles } from '@mui/styles';

const navItemStyles = makeStyles(() => ({
    navLink: {
        height: '22px',
        width: '84px',
        color: '#7E8C8D',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        letterSpacing: '0',
        lineHeight: '22px',
        textDecoration: 'none',
        textTransform: 'uppercase',
    },
    activeLink: {
        color: '#2D3D50',
        fontSize: '1.25rem',
        '& span': {
            fontWeight: '800 !important',
        },
    },
    activeLinkCustomIcons: {
        '& svg': {
            fill: '#2D3D50',
        },
    },
    navLinkIcon: {
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: '1.25rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
        marginLeft: '4px',
    },
}));

export { navItemStyles };
