import React from 'react';
import { PageHeader } from 'components';
import { useStyles } from 'scenes/styles';
import { MdPrecisionManufacturing } from 'react-icons/md';

import { PartsTabPanel } from 'components/_Layout/PartsTabPanel';

const Parts = () => {
    const classes = useStyles();

    return (
        <div className={classes.content}>
            <PageHeader
                header="Parts Manager"
                icon={<MdPrecisionManufacturing className="react-icon" />}
            />
            <PartsTabPanel />
        </div>
    );
};

export { Parts };
