import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    viewerLoaded: false,
};

const viewerStatusSlice = createSlice({
    name: 'viewerStatus',
    initialState,
    reducers: {
        setViewerLoaded: (state, action) => {
            state.viewerLoaded = true;
        },
    },
});

export const getViewerLoaded = (state) => state.viewerStatus.viewerLoaded;

export const { setViewerLoaded } = viewerStatusSlice.actions;

export default viewerStatusSlice.reducer;
