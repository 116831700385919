import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    smeViewContainer: {
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '50%',
        overflow: 'auto',
        justifyContent: 'center',
    },
    smeInputContainer: {
        display: 'flex',
        color: '#2D3D50',
        fontSize: '18px',
        fontWeight: 'bold',
        margin: '25px 0px',
    },
    smeInputIcon: {
        color: '#2D3D50',
        height: '30px',
        width: '30px',
        marginRight: '10px',
    },
    smeInput: {
        width: '100%',
        marginLeft: '10px',
    },
    smeField: {
        marginTop: '5px',
        color: '#2D3D50',
    },
    smeTopInputs: {
        display: 'flex',
        marginBottom: '10px',
    },
    smeName: {
        marginBottom: '15px',
        fontSize: '28px',
        fontWeight: 'bold',
        color: '#2D3D50',
    },
    avatar: {
        height: '100px',
        width: '100px',
        marginRight: '15px',
    },
    dropzone: {
        backgroundColor: '#2D3D50',
        borderRadius: '50%',
        minHeight: '100px',
        width: '100px',
        height: '100px',
        outline: 'none',
        '& .MuiDropzoneArea-textContainer': {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column-reverse',
            '& ..MuiDropzoneArea-text': {
                padding: '0px !important',
            },
            '& svg': {
                height: '20px',
                width: '20px',
                color: 'white',
                marginBottom: '5px',
            },
        },
    },
    dropzoneText: {
        fontSize: '12px',
        color: 'white',
        margin: '0px',
    },
    removeSmeImage: {
        height: '25px',
        width: '25px',
        cursor: 'pointer',
    },
    hidden: {
        display: 'none',
    },
}));

export { useStyles };
