import { makeStyles } from '@mui/styles';

export const makeNodeManagerStyles = makeStyles(() => ({
    detailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '3px',
    },
    detailsTitle: {
        fontWeight: 'bold',
        fontStyle: 'italic',
    },
    detailsQ: {
        fontWeight: 'bold',
    },
    buttonPreviewContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '5px',
    },
    buttonPreview: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        backgroundColor: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        borderRadius: '5px',
        fontSize: '12px',
        lineHeight: '12px',
        padding: '5px',
        '&:hover': {
            backgroundColor: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(90%)',
        },
    },
    button: {
        backgroundColor: (props) => props.colors.button.secondaryBackground,
        color: (props) => props.colors.button.secondaryForeground,
        border: '0px',
        padding: '6px',
        borderRadius: '5px',
        fontSize: '12px',
        textTransform: 'uppercase',
        cursor: 'pointer',
        '&:disabled': {
            backgroundColor: (props) => props.colors.button.secondaryBackground,
            color: (props) => props.colors.button.secondaryForeground,
            filter: 'brightness(50%)',
        },
        '&:hover': {
            backgroundColor: (props) => props.colors.button.secondaryBackground,
            color: (props) => props.colors.button.secondaryForeground,
            filter: 'brightness(90%)',
        },
    },
    branchTitle: {
        backgroundColor: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        padding: '3px',
        borderRadius: '5px',
        fontSize: '12px',
        cursor: 'pointer',
        width: 'fit-content',
    },
    rightButtons: {
        marginLeft: 'auto',
        display: 'flex',
        gap: '5px',
    },
    contentAnchors: {
        '& a': {
            textDecoration: 'revert',
            color: 'revert',
        },
        '& p': {
            margin: 'revert',
        },
        // '& button': {
        //     backgroundColor: (props) => props.colors.button.secondaryBackground,
        //     color: (props) => props.colors.button.secondaryForeground,
        //     border: '0px',
        //     padding: '6px',
        //     borderRadius: '5px',
        //     fontSize: '12px',
        //     textTransform: 'uppercase',
        //     cursor: 'pointer',
        //     '&:disabled': {
        //         filter: 'brightness(50%)',
        //     },
        //     '&:hover': {
        //         filter: 'brightness(90%)',
        //     },
        // },
    },
    branchNodesContainer: {
        border: '1px solid #aaa',
        borderRadius: '5px',
        padding: '5px',
        backgroundColor: '#eee',
    },
    branchNodesTopButtons: {
        display: 'flex',
        gap: '5px',
        flex: '0',
        marginBottom: '5px',
        marginRight: '5px',
    },
    dragTd: {
        padding: '3px',
    },
    dragColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    addButtonIcon: {
        width: '25px',
        height: '25px',
        padding: '3px !important',
        borderRadius: '25px !important',
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
    },
}));
