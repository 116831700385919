import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { makeStyles } from '@mui/styles';
import { makeFormStyles } from 'forms/styles';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Autocomplete, FormControl, TextField as MuiText } from '@mui/material';

import DirtyProcedure from '../DirtyProcedure';
import { assetService } from '_features/_services';
import SaveNodeDataDialog from '../SaveNodeDataDialog';
import { setProcedureDirty } from '_features/procedures/proceduresSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    CommonNodeFormFIelds,
    ExcludeFromNavigationField,
    NodeCategoryField,
} from '../CommonNodeFormFIelds';
import {
    getAssetsPicklist,
    selectAssetsPicklist,
    selectAssetsPicklistLoadingStatus,
} from '_features/picklists/pickListsSlice';

const makeNodeFormStyles = makeStyles(() => ({
    container: {
        padding: '20px',
        width: '100%',
        height: '100%',
    },
    form: {
        display: 'flex',
        width: '100%',
        height: 'auto',
        padding: '10px',
        border: '1px solid',
    },
    formBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        width: '100%',
    },
    submitButtonRow: {
        marginTop: 'auto',
        alignSelf: 'end',
        display: 'flex',
        gap: '5px',
    },
    contentRow: {
        cursor: 'pointer',
    },
    dialogPaper: {
        minHeight: '100%',
        maxHeight: '100%',
    },
    addButtonIcon: {
        padding: '3px !important',
        borderRadius: '25px !important',
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
    },
}));

const BranchNodeForm = ({
    nodes,
    node,
    onCancel,
    onSubmit,
    assetId,
    openSaveDataDialog,
    setOpenSaveDataDialog,
    procedureCategories,
}) => {
    const theme = useSelector(selectActiveTheme);
    const nodeFormStyles = makeNodeFormStyles(theme);
    const commonFormStyles = makeFormStyles(theme);
    const dispatch = useDispatch();
    const assetListStatus = useSelector(selectAssetsPicklistLoadingStatus);
    let assetLists = useSelector(selectAssetsPicklist);
    assetLists = assetLists.filter(
        (asset) => asset.asset_type_id === 3 && asset.asset_id !== assetId
    );

    const optionNodes = [...[{ id: 0 }], ...nodes];

    const [formValues, setFormValues] = useState({
        ...node,
        asset_id: node?.asset_id ? node.asset_id : undefined,
        start_node_id: node?.start_node_id ? node.start_node_id : 0,
        // end_node_id: node?.end_node_id ? node.end_node_id : 0,
        return_node_id: node?.return_node_id ? node.return_node_id : 0,
    });

    useEffect(
        () =>
            setFormValues({
                exclude_from_navigation: true,
                ...node,
                procedureCategories: procedureCategories,
                asset_id: node?.asset_id ? node.asset_id : undefined,
                start_node_id: node?.start_node_id ? node.start_node_id : 0,
                return_node_id: node?.return_node_id ? node.return_node_id : 0,
            }),
        [node]
    );

    useEffect(() => {
        return () => {
            setFormValues({});
            dispatch(setProcedureDirty(false));
        };
    }, []);

    const [branchAssetNodes, setBranchAssetNodes] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (assetListStatus === LoadingStatus.Idle) {
            dispatch(getAssetsPicklist());
        }
    }, []);

    useEffect(() => {
        async function setValues(nd) {
            if (nd?.asset_id && nd?.asset_id > 0) {
                const response = await assetService.getById(nd?.asset_id);
                if (!response.error) {
                    setBranchAssetNodes([
                        ...[{ id: 0 }],
                        ...(JSON.parse(response?.procedure_json)?.nodes ?? ''),
                    ]);
                }
            }
            await setFormValues({
                exclude_from_navigation: true,
                ...nd,
                asset_id: nd?.asset_id ? nd.asset_id : undefined,
                start_node_id: nd?.start_node_id ? nd.start_node_id : 0,
                // end_node_id: nd?.end_node_id ? nd.end_node_id : 0,
                return_node_id: nd?.return_node_id ? nd.return_node_id : 0,
                procedureCategories: procedureCategories,
            });
            setLoaded(true);
        }
        if (assetListStatus === LoadingStatus.Loaded) {
            setValues(node);
        }
    }, [node, assetListStatus]);

    const handleAssetChange = async (assetId, setFieldValue) => {
        let isAssetSelected = false;
        setFieldValue('asset_id', assetId ? assetId : 0);
        if (assetId) {
            const response = await assetService.getById(assetId);
            if (!response.error) {
                isAssetSelected = true;
                setBranchAssetNodes([
                    ...[{ id: 0 }],
                    ...(JSON.parse(response?.procedure_json)?.nodes ?? ''),
                ]);
            }
        }
        setFieldValue('start_node_id', 0);
        // setFieldValue('end_node_id', 0);
        if (!isAssetSelected) {
            setBranchAssetNodes([]);
        }
    };

    return (
        <div className={nodeFormStyles.container}>
            <div className={nodeFormStyles.form}>
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={Yup.object({
                        title: Yup.string().required('Required'),
                        asset_id: Yup.number().min(0).required('Required'),
                        start_node_id: Yup.number().min(0).required('Required'),
                        // end_node_id: Yup.number().min(0).required('Required'),
                        return_node_id: Yup.number()
                            .min(0)
                            .required('Required'),
                    })}
                    onSubmit={async (values) => {
                        onSubmit(values);
                    }}
                >
                    {({ values, errors, touched, isSubmitting, dirty }) => (
                        <Form className={nodeFormStyles.formBody}>
                            <DirtyProcedure />
                            {loaded && (
                                <>
                                    <CommonNodeFormFIelds />
                                    <NodeCategoryField />
                                    <ExcludeFromNavigationField />
                                    <FormControl
                                        fullWidth
                                        style={{ marginTop: '10px' }}
                                    >
                                        <Field name="asset_id">
                                            {({
                                                field: { value },
                                                form: { setFieldValue },
                                            }) => (
                                                <Autocomplete
                                                    fullWidth={true}
                                                    getOptionLabel={(option) =>
                                                        option.display_name
                                                    }
                                                    variant="outline"
                                                    id="asset_id"
                                                    options={assetLists}
                                                    value={assetLists.find(
                                                        (asset) =>
                                                            asset.asset_id ===
                                                            value
                                                    )}
                                                    onChange={(
                                                        event,
                                                        newInputValue
                                                    ) => {
                                                        handleAssetChange(
                                                            newInputValue?.asset_id,
                                                            setFieldValue
                                                        );
                                                    }}
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <li
                                                            {...props}
                                                            key={
                                                                option.asset_id
                                                            }
                                                        >
                                                            {
                                                                option.display_name
                                                            }
                                                        </li>
                                                    )}
                                                    renderInput={(params) => (
                                                        <MuiText
                                                            {...params}
                                                            error={
                                                                errors.asset_id &&
                                                                touched.asset_id
                                                            }
                                                            variant="outlined"
                                                            label="Procedure"
                                                            placeholder="Procedure"
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage
                                            className={
                                                commonFormStyles.inputErr
                                            }
                                            name="asset_id"
                                            component="div"
                                        />
                                    </FormControl>
                                    {values?.asset_id > 0 && (
                                        <>
                                            <FormControl
                                                fullWidth
                                                style={{ marginTop: '10px' }}
                                            >
                                                <Field name="start_node_id">
                                                    {({
                                                        field: { value },
                                                        form: { setFieldValue },
                                                    }) => (
                                                        <Autocomplete
                                                            fullWidth={true}
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                option.id == 0
                                                                    ? 'Default'
                                                                    : option.title
                                                                    ? `${option.title} - #${option.id}`
                                                                    : `${option.type} - #${option.id}`
                                                            }
                                                            variant="outline"
                                                            id="start_node_id"
                                                            options={
                                                                branchAssetNodes
                                                            }
                                                            value={branchAssetNodes?.find(
                                                                (node) =>
                                                                    Number(
                                                                        node?.id
                                                                    ) ===
                                                                    Number(
                                                                        value
                                                                    )
                                                            )}
                                                            onChange={(
                                                                event,
                                                                newInputValue
                                                            ) => {
                                                                setFieldValue(
                                                                    'start_node_id',
                                                                    newInputValue?.id
                                                                        ? newInputValue?.id
                                                                        : 0
                                                                );
                                                            }}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <MuiText
                                                                    {...params}
                                                                    error={
                                                                        errors.start_node_id &&
                                                                        touched.start_node_id
                                                                    }
                                                                    variant="outlined"
                                                                    label="Start Node"
                                                                    placeholder="Start Node"
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage
                                                    className={
                                                        commonFormStyles.inputErr
                                                    }
                                                    name="start_node_id"
                                                    component="div"
                                                />
                                            </FormControl>

                                            {/* <FormControl
                                                fullWidth
                                                style={{ marginTop: '10px' }}
                                            >
                                                <Field name="end_node_id">
                                                    {({
                                                        field: { value },
                                                        form: { setFieldValue },
                                                    }) => (
                                                        <>
                                                            <Autocomplete
                                                                fullWidth={true}
                                                                getOptionLabel={(
                                                                    option
                                                                ) =>
                                                                    option.id ==
                                                                    0
                                                                        ? 'Default'
                                                                        : option.title
                                                                        ? `${option.title} - #${option.id}`
                                                                        : `${option.type} - #${option.id}`
                                                                }
                                                                variant="outline"
                                                                id="end_node_id"
                                                                options={
                                                                    branchAssetNodes
                                                                }
                                                                value={branchAssetNodes?.find(
                                                                    (node) =>
                                                                        Number(
                                                                            node?.id
                                                                        ) ===
                                                                        Number(
                                                                            value
                                                                        )
                                                                )}
                                                                onChange={(
                                                                    event,
                                                                    newInputValue
                                                                ) => {
                                                                    setFieldValue(
                                                                        'end_node_id',
                                                                        newInputValue?.id
                                                                            ? newInputValue?.id
                                                                            : 0
                                                                    );
                                                                }}
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <MuiText
                                                                        {...params}
                                                                        error={
                                                                            errors.end_node_id &&
                                                                            touched.end_node_id
                                                                        }
                                                                        variant="outlined"
                                                                        label="End Node"
                                                                        placeholder="End Node"
                                                                    />
                                                                )}
                                                            />
                                                        </>
                                                    )}
                                                </Field>
                                                <ErrorMessage
                                                    className={
                                                        commonFormStyles.inputErr
                                                    }
                                                    name="end_node_id"
                                                    component="div"
                                                />
                                            </FormControl> */}
                                        </>
                                    )}
                                    <FormControl
                                        fullWidth
                                        style={{ marginTop: '10px' }}
                                    >
                                        <Field name="return_node_id">
                                            {({
                                                field: { value },
                                                form: { setFieldValue },
                                            }) => (
                                                <Autocomplete
                                                    fullWidth={true}
                                                    getOptionLabel={(option) =>
                                                        option.id == 0
                                                            ? 'Default'
                                                            : option.title
                                                            ? `${option.title} - #${option.id}`
                                                            : `${option.type} - #${option.id}`
                                                    }
                                                    variant="outline"
                                                    id="return_node_id"
                                                    options={optionNodes}
                                                    value={optionNodes?.find(
                                                        (node) =>
                                                            Number(node?.id) ===
                                                            Number(value)
                                                    )}
                                                    onChange={(
                                                        event,
                                                        newInputValue
                                                    ) => {
                                                        setFieldValue(
                                                            'return_node_id',
                                                            newInputValue?.id
                                                                ? newInputValue?.id
                                                                : 0
                                                        );
                                                    }}
                                                    renderInput={(params) => (
                                                        <MuiText
                                                            {...params}
                                                            error={
                                                                errors.return_node_id &&
                                                                touched.return_node_id
                                                            }
                                                            variant="outlined"
                                                            label="Return Node"
                                                            placeholder="Return Node"
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage
                                            className={
                                                commonFormStyles.inputErr
                                            }
                                            name="return_node_id"
                                            component="div"
                                        />
                                    </FormControl>
                                </>
                            )}
                            {openSaveDataDialog && (
                                <SaveNodeDataDialog
                                    openSaveDataDialog={openSaveDataDialog}
                                    setOpenSaveDataDialog={
                                        setOpenSaveDataDialog
                                    }
                                    isSubmitting={isSubmitting}
                                    onCancel={onCancel}
                                />
                            )}
                            <div className={nodeFormStyles.submitButtonRow}>
                                <button
                                    className={commonFormStyles.cancel}
                                    type="button"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        if (dirty) {
                                            setOpenSaveDataDialog(true);
                                        } else {
                                            onCancel();
                                        }
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={commonFormStyles.submit}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};
export default BranchNodeForm;
