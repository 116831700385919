import React from 'react';
import { Box, FormControl } from '@mui/material';
import { CheckboxWithLabel } from 'formik-mui';

import { FormTextField } from 'components/TextField';
import FieldValidationError from './FieldValidationError';
import { ImageAttachments } from '../../ImageAttachments';

const CheckboxField = ({
    data,
    fieldValue,
    setFieldValue,
    errors,
    setErrors,
    validationError,
    handleBlur,
    inGroup = false,
    isReadOnly = false,
    isSubmitAllow = true,
    handleClick = () => {},
}) => {
    let fieldErrors = errors;

    const checkboxData = (
        <FormControl
            style={{
                padding: '5px',
                width: data?.field_width ? data.field_width + '%' : '100%',
            }}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <FormTextField
                    component={CheckboxWithLabel}
                    id={data?.variable}
                    Label={{
                        label: `${data.hide_label !== true ? data.label : ''}`,
                    }}
                    label={data.hide_label !== true ? data.label : ''}
                    type="checkbox"
                    name={data.variable}
                    disabled={isReadOnly}
                    helperText={errors?.[data.variable]}
                    style={{
                        [`&, &.Mui-disabled`]: {
                            color: 'black',
                        },
                    }}
                    onBlur={() => isSubmitAllow && handleBlur(fieldValue)}
                    onChange={(evt) => {
                        if (isSubmitAllow) {
                            if (
                                data?.required === true &&
                                evt.target.checked !== true
                            ) {
                                fieldErrors[data.variable] = 'Required';
                            } else {
                                delete fieldErrors?.[data.variable];
                            }
                            setErrors({ ...fieldErrors });
                            setFieldValue(data.variable, evt.target.checked);
                        }
                    }}
                    onClick={(e) => {
                        if (!isSubmitAllow) {
                            e.stopPropagation();
                            handleClick(e, data);
                        }
                    }}
                />
                {data.image_attachments_allow && (
                    <ImageAttachments
                        data={data}
                        var_name={data.variable}
                        level="field"
                        isReadOnly={!isSubmitAllow}
                        handleClick={handleClick}
                    />
                )}
            </Box>
            {validationError && (
                <FieldValidationError validationError={validationError} />
            )}
        </FormControl>
    );

    return inGroup ? (
        checkboxData
    ) : (
        <div style={{ width: '100%' }}>{checkboxData}</div>
    );
};

export default CheckboxField;
