import { Box } from '@mui/material';
import React from 'react';
import { makeClientConfigStyles } from './styles';
import { useSelector } from 'react-redux';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { MdPublic, MdVrpano, MdWorkOutline } from 'react-icons/md';

export const TableLegend = () => {
    const theme = useSelector(selectActiveTheme);
    const styles = makeClientConfigStyles(theme);

    return (
        <Box sx={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
            <div style={{ display: 'flex' }}>
                <MdPublic className="react-icon" style={{ color: '#1154ce' }} />
                &nbsp;= Global Value
            </div>
            <div style={{ display: 'flex' }}>
                <MdWorkOutline
                    className="react-icon"
                    style={{
                        color: '#11cc6d',
                    }}
                />
                &nbsp;= Client Value
            </div>
            <div style={{ display: 'flex' }}>
                <MdVrpano
                    className="react-icon"
                    style={{
                        color: '#d65712',
                    }}
                />
                &nbsp;= Asset Value
            </div>
            <div className={styles.unsetRow}>&nbsp;= Unset value</div>
        </Box>
    );
};
