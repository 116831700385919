import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { contentService } from '_features/_services';
// NOTE: Importing LinkTypes from enumerations.js here causes
// the program to throw an exception at startup. Have not
// figured out why yet.
import { BasicLinkTypes } from '_helpers/basicLinkTypes';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { orderBy, uniqBy } from 'GeminiViewerComponent/_helpers/lodashUtils';

const initialState = {
    externalContent: [],
    externalContentPageInfo: {},
    loadedRowsMap: {},
    status: LoadingStatus.Idle,
    error: null,
    contentCategoriesStatus: LoadingStatus.Idle,
    contentCategories: [],
    contentStatus: LoadingStatus.Idle,
    content: [],
    contentPageInfo: {},
    contentLoadedRowsMap: {},
    contentUsageFiltersData: {
        asset_usage: [],
        zone_usage: [],
        item_usage: [],
        procedure_usage: [],
    },

    // CONTENT VERSIONS
    contentVersions: [],
    contentUsage: [],
    contentIdToCreateLink: null,
};

export const fetchExternalContentPage = createAsyncThunk(
    'content/fetchExternalContentPage',
    async (params) =>
        await contentService.getAllExternalContent(
            params.page,
            params.pageSize,
            params.contentType,
            params.useTagFilter,
            params.categoryId,
            params.searchString,
            params.tagFilter
        )
);

export const fetchMoreExternalContent = createAsyncThunk(
    'content/fetchMoreExternalContent',
    async (params) =>
        await contentService.getAllExternalContentRange(
            params.startIndex,
            params.stopIndex,
            params.contentType,
            params.useTagFilter,
            params.categoryId,
            params.searchString,
            params.tagFilter
        )
);

export const fetchAllContentByClientId = createAsyncThunk(
    'content/fetchAllContentByClientId',
    async (id) => {
        const response = await contentService.getAllContentByProcedureId(id);
        return response;
    }
);

export const fetchAllContentVersionByProcedureId = createAsyncThunk(
    'content/fetchAllContentVersionByProcedureId',
    async (id) => {
        const response = await contentService.getAllContentVersionByProcedureId(
            id
        );
        return response;
    }
);

export const fetchContentByTypeId = createAsyncThunk(
    'content/fetchContentByTypeId',
    async (params, { getState }) =>
        await contentService.getAllContentByTypeIdRange(
            params.startIndex ?? 0,
            params.stopIndex ?? 0,
            params.contentTypeIds,
            params.assetId ?? null,
            params.searchString,
            params.sort,
            getState().accounts.activeUser.s3_prefix,
            params.assetUsageId,
            params.zoneUsageId,
            params.itemUsageId,
            params.procedureUsageId,
            params.minFileSize,
            params.maxFileSize,
            params.minContentWidth,
            params.maxContentWidth,
            params.minContentHeight,
            params.maxContentHeight,
            params.assetOnly,
            params.externalId,
            params.encodingProgress,
            params.excludeContentTypeIds,
            params.noCaptions
        )
);

export const createContent = createAsyncThunk(
    'content/createContent',
    async (newContent, { getState }) =>
        await contentService.create(
            newContent,
            getState().accounts.activeUser.s3_prefix
        )
);

export const deleteContentById = createAsyncThunk(
    'content/deleteContentById',
    async ({ contentId, permanentDelete }) => {
        let response = null;
        if (permanentDelete) {
            response = await contentService.delete(
                contentId + '?delete_files=true'
            );
        } else {
            response = await contentService.delete(contentId);
        }
        response.id = contentId;
        return response;
    }
);

export const deleteContentArray = createAsyncThunk(
    'content/deleteContentArray',
    async ({ contentIds }) => {
        const response = await contentService.deleteArray(contentIds);
        response.ids = contentIds;
        return response;
    }
);

export const deleteContentVersionArray = createAsyncThunk(
    'content/deleteContentVersionArray',
    async ({ contentIds }) => {
        const response = await contentService.deleteContentVersionArray(
            contentIds
        );
        response.ids = contentIds;
        return response;
    }
);

export const fetchContentCategories = createAsyncThunk(
    'content/fetchContentCategories',
    async (params) =>
        await contentService.getAllContentCategories(params.contentType)
);

export const fetchContentUsageById = createAsyncThunk(
    'content/fetchContentUsageById',
    async (contentId) => await contentService.fetchContentUsageById(contentId)
);

export const fetchAllUsageById = createAsyncThunk(
    'content/fetchAllUsageById',
    async (params) =>
        await contentService.fetchAllUsageById(
            params.contentTypeIds,
            params.assetId ?? null,
            params.searchString,
            params.sort,
            params.assetUsageId,
            params.zoneUsageId,
            params.itemUsageId,
            params.minFileSize,
            params.maxFileSize,
            params.minContentWidth,
            params.maxContentWidth,
            params.minContentHeight,
            params.maxContentHeight,
            params.assetOnly,
            params.externalId,
            params.encodingProgress,
            params.excludeContentTypeIds,
            params.noCaptions
        )
);

export const fetchContentById = createAsyncThunk(
    'content/fetchContentById',
    async ({ contentId, addS3Prefix = true }, { getState }) =>
        await contentService.fetchContentById(
            contentId,
            addS3Prefix ? getState().accounts.activeUser.s3_prefix : ''
        )
);

export const editContentById = createAsyncThunk(
    'content/editContentById',
    async (params, { getState }) =>
        await contentService.editContentById(
            params,
            getState().accounts.activeUser.s3_prefix
        )
);

export const isDuplicated = createAsyncThunk(
    'content/isDuplicated',
    async (params) => await contentService.isDuplicated(params)
);

// ---------------- CONTENT VERSIONS ----------------
export const createContentVersion = createAsyncThunk(
    'content/createContentVersion',
    async (newContentVersion, { getState }) =>
        await contentService.createContentVersion(
            newContentVersion,
            getState().accounts.activeUser.s3_prefix
        )
);

export const updateContentVersion = createAsyncThunk(
    'content/updateContentVersion',
    async (newContentVersion, { getState }) =>
        await contentService.updateContentVersion(
            newContentVersion,
            getState().accounts.activeUser.s3_prefix
        )
);

export const updateContentVersionCaptions = createAsyncThunk(
    'content/updateContentVersionCaptions',
    async (newContentVersion, { getState }) =>
        await contentService.updateContentVersionCaptions(
            newContentVersion,
            getState().accounts.activeUser.s3_prefix
        )
);

export const transcribeContents = createAsyncThunk(
    'content/transcribeContents',
    async ({ contentIds, params }) => {
        const response = await contentService.transcribeContents(
            contentIds,
            params
        );
        response.ids = contentIds;
        return response;
    }
);

export const fetchContentVersions = createAsyncThunk(
    'content/fetchContentVersions',
    async (contentId, { getState }) =>
        await contentService.getContentVersions(
            contentId,
            getState().accounts.activeUser.s3_prefix
        )
);

export const getContentImportSampleCsv = createAsyncThunk(
    'content/getContentImportSampleCsv',
    async () => await contentService.getContentImportCsv()
);

export const uploadContentImportCsv = createAsyncThunk(
    'content/uploadContentImportCsv',
    async (params) => await contentService.uploadContentImportCsv(params)
);

export const exportContent = createAsyncThunk(
    'content/exportContent',
    async (params) =>
        await contentService.exportContent(
            params.startIndex ?? 0,
            params.stopIndex ?? 0,
            params.contentTypeIds,
            params.assetId ?? null,
            params.searchString,
            params.sort,
            params.assetUsageId,
            params.zoneUsageId,
            params.itemUsageId,
            params.minFileSize,
            params.maxFileSize,
            params.minContentWidth,
            params.maxContentWidth,
            params.minContentHeight,
            params.maxContentHeight,
            params.assetOnly,
            params.externalId,
            params.encodingProgress,
            params.excludeContentTypeIds,
            params.include_header,
            params.include_unused,
            params.output_fields,
            params.contentIds
        )
);

const contentSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {
        resetContentState: (state) => initialState,
        resetExternalContentStatus: (state) => {
            state.status = LoadingStatus.Idle;
        },
        resetContentStatus: (state) => {
            state.contentStatus = LoadingStatus.Idle;
            state.content = [];
            state.contentPageInfo = {};
            state.contentLoadedRowsMap = {};
            state.contentUsageFiltersData = {
                asset_usage: [],
                zone_usage: [],
                item_usage: [],
                procedure_usage: [],
            };
        },
        setContentIdToCreateLink: (state, action) => {
            state.contentIdToCreateLink = action.payload;
        },
    },
    extraReducers: {
        [fetchExternalContentPage.fulfilled]: (state, action) => {
            state.status = LoadingStatus.Loaded;
            state.externalContentPageInfo = action.payload.pageInfo;
            state.externalContent = action.payload.externalContent;

            const startIndex =
                (action.meta.arg.page - 1) * action.meta.arg.pageSize;
            const stopIndex = startIndex + state.externalContent.length - 1;
            const requestedStopIndex =
                startIndex + action.meta.arg.pageSize - 1;

            state.loadedRowsMap = {};

            for (var i = startIndex; i <= stopIndex; i++) {
                state.loadedRowsMap[i] = LoadingStatus.Loaded;
            }

            // In case we didn't load as many as requested
            for (var j = stopIndex + 1; j <= requestedStopIndex; j++) {
                delete state.loadedRowsMap[j];
            }
        },
        [fetchExternalContentPage.rejected]: (state, action) => {
            state.status = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [fetchMoreExternalContent.pending]: (state, action) => {
            const startIndex = action.meta.arg.startIndex;
            const stopIndex = action.meta.arg.stopIndex;

            for (var i = startIndex; i <= stopIndex; i++) {
                state.loadedRowsMap[i] = LoadingStatus.Loading;
            }
        },
        [fetchMoreExternalContent.fulfilled]: (state, action) => {
            const startIndex = action.meta.arg.startIndex;
            const stopIndex =
                startIndex + action.payload.externalContent.length - 1;
            const requestedStopIndex = action.meta.arg.stopIndex;

            for (var i = startIndex; i <= stopIndex; i++) {
                state.loadedRowsMap[i] = LoadingStatus.Loaded;
            }

            // In case we didn't load as many as requested
            for (var j = stopIndex + 1; j <= requestedStopIndex; j++) {
                delete state.loadedRowsMap[j];
            }

            state.externalContent = state.externalContent.concat(
                action.payload.externalContent
            );
        },
        [fetchMoreExternalContent.rejected]: (state, action) => {
            const startIndex = action.meta.arg.startIndex;
            const stopIndex = action.meta.arg.stopIndex;

            for (var i = startIndex; i <= stopIndex; i++) {
                delete state.loadedRowsMap[i];
            }

            state.status = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [fetchContentByTypeId.pending]: (state, action) => {
            state.contentStatus = LoadingStatus.Loading;
        },
        [fetchContentByTypeId.fulfilled]: (state, action) => {
            state.contentStatus = LoadingStatus.Loaded;
            state.contentPageInfo = action.payload.pageInfo;

            if (action.meta.arg.reset) {
                state.contentLoadedRowsMap = {};
            }

            const startIndex = action.meta.arg.startIndex;
            const stopIndex =
                startIndex + action.payload.contentById.length - 1;

            for (var i = startIndex; i <= stopIndex; i++) {
                state.contentLoadedRowsMap[i] = LoadingStatus.Loaded;
            }

            // In case we didn't load as many as requested
            for (
                let j = stopIndex + 1;
                j <= action.payload?.pageInfo?.TotalCount;
                j++
            ) {
                delete state.contentLoadedRowsMap[j];
            }

            const content = action.payload.contentById.map((content) => {
                const linkType = Object.values(BasicLinkTypes).find(
                    (lt) => lt.id === content.default_link_type_id
                )?.label;
                return {
                    ...content,
                    contentAssetName:
                        content.display_name +
                        (content?.asset_name
                            ? `  (${content?.asset_name})`
                            : ``) +
                        (linkType ? `  (${linkType})` : ``),
                };
            });
            if (action.meta.arg.reset) {
                state.content = content;
            } else {
                state.content = state.content.concat(content);
            }
            let allContents = state.content;
            if (Array.isArray(allContents) && allContents?.length > 0) {
                let assets = [];
                let zones = [];
                let items = [];
                let newContents = Array();
                for (let ct of allContents) {
                    let content_zones_usage = [];
                    let content_items_usage = [];
                    if (ct?.asset_usage?.length > 0) {
                        ct?.asset_usage?.map((asset) => {
                            assets = [...assets, asset];
                            if (asset?.zones?.length > 0) {
                                asset?.zones?.map((zone) => {
                                    let zoneUsageObj = {
                                        ...zone,
                                        asset_id: asset?.asset_id,
                                        asset_name: asset?.asset_name,
                                    };
                                    zones = [...zones, zoneUsageObj];
                                    content_zones_usage = [
                                        ...content_zones_usage,
                                        zoneUsageObj,
                                    ];

                                    if (zone?.items?.length > 0) {
                                        zone?.items?.map((item) => {
                                            let itemUsageObj = {
                                                ...item,
                                                asset_id: asset?.asset_id,
                                                asset_name: asset?.asset_name,
                                                zone_id: zone?.zone_id,
                                                zone_name: zone?.zone_name,
                                            };
                                            items = [...items, itemUsageObj];
                                            content_items_usage = [
                                                ...content_items_usage,
                                                itemUsageObj,
                                            ];
                                        });
                                    }
                                });
                            }
                        });
                    }
                    newContents.push({
                        ...ct,
                        zone_usage: content_zones_usage,
                        item_usage: content_items_usage,
                    });
                }
                assets = uniqBy(assets, 'asset_id');
                assets = orderBy(
                    assets,
                    [(asset) => asset?.asset_name?.toLowerCase()],
                    ['asc']
                );
                zones = uniqBy(zones, 'zone_id');
                zones = orderBy(
                    zones,
                    [(zone) => zone?.zone_name?.toLowerCase()],
                    ['asc']
                );
                items = uniqBy(items, 'item_id');
                items = orderBy(
                    items,
                    [(item) => item?.item_name?.toLowerCase()],
                    ['asc']
                );
                state.content = newContents;
            }
        },
        [fetchContentByTypeId.rejected]: (state, action) => {
            const startIndex = action.meta.arg.startIndex;
            const stopIndex = action.meta.arg.stopIndex;

            for (var i = startIndex; i <= stopIndex; i++) {
                delete state.contentLoadedRowsMap[i];
            }

            state.contentStatus = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [createContent.fulfilled]: (state, action) => {
            state.status = LoadingStatus.Idle;
            state.externalContent = [];
            state.loadedRowsMap = {};
        },
        [editContentById.fulfilled]: (state, action) => {
            state.contentStatus = LoadingStatus.Idle;
            state.content = [];
            state.contentLoadedRowsMap = {};
            state.contentPageInfo = {};
        },
        [deleteContentById.fulfilled]: (state, action) => {
            state.contentStatus = LoadingStatus.Idle;
            state.content = [];
            state.contentLoadedRowsMap = {};
            state.contentPageInfo = {};
        },
        [deleteContentArray.fulfilled]: (state, action) => {
            state.contentStatus = LoadingStatus.Idle;
        },
        [deleteContentArray.rejected]: (state, action) => {
            state.status = SavingStatus.Failed;
            state.error = action.error.message;
        },
        [fetchContentCategories.pending]: (state, action) => {
            state.contentCategoriesStatus = LoadingStatus.Loading;
        },
        [fetchContentCategories.fulfilled]: (state, action) => {
            state.contentCategoriesStatus = LoadingStatus.Loaded;
            state.contentCategories = action.payload.contentCategories;
        },
        [fetchContentCategories.rejected]: (state, action) => {
            state.contentCategoriesStatus = LoadingStatus.Failed;
        },
        // CONTENT VERSIONS
        [transcribeContents.fulfilled]: (state, action) => {
            state.status = LoadingStatus.Idle;
            state.externalContent = [];
            state.loadedRowsMap = {};
        },
        [fetchContentVersions.fulfilled]: (state, action) => {
            state.contentVersions = action.payload;
        },
        [fetchContentUsageById.fulfilled]: (state, action) => {
            state.contentUsage = action.payload;
        },
        [getContentImportSampleCsv.fulfilled]: (state, action) => {
            if (action?.payload) {
                const url = window.URL.createObjectURL(
                    new Blob([action.payload])
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'content_import.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        },
        [fetchAllUsageById.fulfilled]: (state, action) => {
            state.contentUsageFiltersData = action.payload;
        },
        [fetchAllUsageById.rejected]: (state, action) => {
            state.contentStatus = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [exportContent.fulfilled]: (state, action) => {
            if (action?.payload) {
                const url = window.URL.createObjectURL(
                    new Blob([action.payload])
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'content_inventory.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        },
    },
});

export const getContentPageInfo = (state) => state.content.contentPageInfo;
export const getContentStatus = (state) => state.content.contentStatus;
export const selectContentById = (state) => state.content.content;
export const getContentById = (state, contentId) =>
    state.content.content.find((a) => a.content_id === contentId);

export const getContentCategoriesStatus = (state) =>
    state.content.contentCategoriesStatus;
export const selectContentCategories = (state) =>
    state.content.contentCategories;

export const getExternalContentPageInfo = (state) =>
    state.content.externalContentPageInfo;
export const selectAllExternalContent = (state) =>
    state.content.externalContent;

// CONTENT VERSIONS
export const getContentVersions = (state) => state.content.contentVersions;

export const getContentUsage = (state) => state.content.contentUsage;
export const getContentUsageFiltersData = (state) =>
    state.content.contentUsageFiltersData;

export const getContentIdToCreateLink = (state) =>
    state.content.contentIdToCreateLink;

export const {
    resetContentState,
    resetExternalContentStatus,
    resetContentStatus,
    setContentIdToCreateLink,
} = contentSlice.actions;

export default contentSlice.reducer;
