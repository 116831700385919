// eslint-disable-next-line
import React, { createElement } from 'react';
import { useSelector } from 'react-redux';
import { SceneButtons } from '../SceneButtons';
import { sceneMenuStyles } from './styles';
import { getScenesOpen } from '../../_features/globals/visibilitySlice';
import { ScenePreviews } from '../ScenePreviews';
import { getOrientation } from 'GeminiViewerComponent/_features/zonePreview/zonePreviewSlice';
import { OrbitPreviews } from '../OrbitPreviews';

const SceneMenu = ({
    onCategoryClick,
    zonesByCategory,
    searchMenu,
    controlMenu,
}) => {
    const previewOrientation = useSelector(getOrientation);

    const classes = sceneMenuStyles();

    const scenesOpen = useSelector(getScenesOpen);

    return (
        <div className={classes.sceneMenu}>
            <SceneButtons mode="asset" />
            {previewOrientation === 'vertical' ? (
                <ScenePreviews
                    open={scenesOpen}
                    onCategoryClick={onCategoryClick}
                    zonesByCategory={zonesByCategory}
                    searchMenu={searchMenu}
                    controlMenu={controlMenu}
                />
            ) : (
                <OrbitPreviews
                    onCategoryClick={onCategoryClick}
                    zonesByCategory={zonesByCategory}
                    searchMenu={searchMenu}
                    controlMenu={controlMenu}
                />
            )}
        </div>
    );
};

export { SceneMenu };
