import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import { MdAdd } from 'react-icons/md';
import { IconButton, Tooltip } from '@mui/material';

import { Action } from '_helpers';
import { accountsSlice } from 'app/store';
import { useConfigValues } from 'hooks/useConfigValues';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { makeButtonStyles } from 'GeminiViewerComponent/components/styles';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { selectConfigValuesByFields } from '_features/configValues/configValuesSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { ResizableTable } from 'GeminiViewerComponent/components/_Tables/ResizableTable';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import {
    closePanel,
    openPanel,
    setEditObjectId,
} from '_features/common/formSlice';
import {
    resizableLabelHeader,
    resizableLabelCell,
    resizableActionCell,
    resizableCheckCell,
    resizableCheckHeaderCell,
    resizableContentButtonTypeCell,
    resizableCheckBoxCell,
    resizableUsageDisplayCell,
} from 'components';

function DeploymentPackageTable({ deploymentPackage, pageInfo }) {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const buttonStyles = makeButtonStyles(theme);
    const classes = useStyles();
    const tableClasses = tableStyles();
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);

    const { configValues, configFields } = useConfigValues({
        clientId: activeUser?.client_id,
    });

    const {
        GaisApplicationAvailable: gaisApplicationAvailable,
        GapsApplicationAvailable: gapsApplicationAvailable,
    } = selectConfigValuesByFields(configValues, configFields, [
        'GaisApplicationAvailable',
        'GapsApplicationAvailable',
    ]);

    const loadedRowsMap = useSelector(
        (state) => state.jobs.loadeddeploymentPackages
    );

    const headerHeight = 40;
    const rowHeight = 60;

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== deploymentPackage.length) {
            setSelected(
                deploymentPackage.map((row) => row.deployment_package_id)
            );
            setSelectedAll(true);
        }
    }

    const handleClick = (event, deployment_package_id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(deployment_package_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, deployment_package_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        setSelectedAll(newSelected.length === deploymentPackage.length);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedIds = deploymentPackage.map(
                (group) => group.deployment_package_id
            );
            setSelected(newSelectedIds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const handleRowClick = async ({ rowData }) => {
        await dispatch(
            closePanel({
                formKey: 'managementDeployForm',
            })
        );
        if (rowData?.deployment_package_id) {
            await dispatch(
                setEditObjectId({
                    formKey: `managementDeployForm`,
                    object_id: rowData?.deployment_package_id,
                })
            );
            dispatch(
                openPanel({
                    formKey: `managementDeployForm`,
                    formAction: 'Edit',
                })
            );
        }
    };
    const handleColumnVisibility = (key, column) => {
        if (gaisApplicationAvailable === 'true' && key === 'include_gais') {
            return column;
        }
        if (gapsApplicationAvailable === 'true' && key === 'include_gaps') {
            return column;
        }
        return undefined;
    };

    const columns = [
        {
            accessorKey: 'deployment_package_id',
            size: 50,
            minSize: 50,
            enableResizing: false,
            label: 'Select',
            padding: 'checkbox',
            cellLevel: 'deployment_package',
            numSelected: selected.length,
            rowCount: deploymentPackage?.length,
            handleClick: handleClick,
            handleSelectAllClick: handleSelectAllClick,
            isSelected: isSelected,
            header: (info) =>
                resizableCheckHeaderCell(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableCheckCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'deployment_package_id',
            size: 50,
            minSize: 50,
            enableResizing: false,
            padding: 'none',
            label: '',
            actionCellLevel: 'managementDeploy',
            targetPopup: 'managementDeploy',
            formKey: 'managementDeployForm',
            dispatch: dispatch,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableActionCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'name',
            flexGrow: 1,
            size: 200,
            minSize: 200,
            label: 'Name',
            padding: 'normal',
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'viewer_authentication_name',
            flexGrow: 1,
            size: 200,
            minSize: 200,
            label: 'Viewer Authentication Type',
            padding: 'normal',
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        handleColumnVisibility('include_gais', {
            accessorKey: 'include_gais',
            flexGrow: 1,
            size: 120,
            minSize: 120,
            enableResizing: false,
            label: 'Include GAIS',
            padding: 'normal',
            cellLevel: 'include_gais',
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableCheckBoxCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        }),
        handleColumnVisibility('include_gaps', {
            accessorKey: 'include_gaps',
            flexGrow: 1,
            size: 120,
            minSize: 120,
            enableResizing: false,
            label: 'Include GAPS',
            padding: 'normal',
            cellLevel: 'include_gaps',
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableCheckBoxCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        }),
        {
            accessorKey: 'include_landing_json',
            flexGrow: 1,
            size: 200,
            minSize: 200,
            enableResizing: false,
            label: 'Include Landing Screen',
            padding: 'normal',
            cellLevel: 'include_landing_json',
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableCheckBoxCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'include_web_config',
            flexGrow: 1,
            size: 200,
            minSize: 200,
            enableResizing: false,
            label: 'Include Web.config',
            padding: 'normal',
            cellLevel: 'include_web_config',
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableCheckBoxCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'assets',
            flexGrow: 1,
            size: 80,
            minSize: 80,
            enableResizing: false,
            label: 'Assets',
            displayDataKey: 'display_name',
            padding: 'none',
            transform: (data) => {
                return data.filter((data) => data?.asset_type_id !== 3);
            },
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableUsageDisplayCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'assets',
            flexGrow: 1,
            size: 80,
            minSize: 80,
            enableResizing: false,
            label: 'Procedures',
            displayDataKey: 'display_name',
            padding: 'none',
            transform: (data) => {
                return data.filter((data) => data?.asset_type_id === 3);
            },
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableUsageDisplayCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
    ].filter(Boolean);

    // eslint-disable-next-line no-unused-vars
    function loadMoreRows({ startIndex, stopIndex }) {}

    const handleCreateNew = () => {
        dispatch(
            openPanel({
                formKey: 'managementDeployForm',
                formAction: 'Create',
                clearEditId: true,
            })
        );
    };

    return (
        <div className={classes.root}>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    toolTipTitle={'Delete'}
                    totalCount={pageInfo.TotalCount}
                >
                    <PopupAction
                        action={Action.DeleteArray}
                        object={{
                            deploymentIds: selected,
                            setDeploymentIds: setSelected,
                        }}
                        level={'managementDeploy'}
                        showLabel={false}
                    />
                </EnhancedTableToolbar>
            )}
            <div style={{ display: 'flex', gap: '5px', marginBottom: '1rem' }}>
                <Tooltip title="Add New Deployment">
                    <IconButton
                        onClick={handleCreateNew}
                        size="large"
                        className={buttonStyles.contentAddButton}
                    >
                        <MdAdd className="react-icon" />
                    </IconButton>
                </Tooltip>
                <div style={{ flexGrow: 1 }}></div>
            </div>
            <div style={{ height: 'calc(100vh - 295px)', minWidth: '500px' }}>
                <ResizableTable
                    initialRows={deploymentPackage}
                    allRows={deploymentPackage}
                    totalRowCount={pageInfo.TotalCount}
                    loadedRowsMap={loadedRowsMap}
                    loadMoreRows={loadMoreRows}
                    columns={columns}
                    cellClassName={tableClasses.flexContainer}
                    className={tableClasses.table}
                    rowClassName={tableClasses.flexContainer}
                    headerHeight={headerHeight}
                    rowHeight={rowHeight}
                    selected={selected}
                    onRowClick={handleRowClick}
                />
            </div>
        </div>
    );
}

export { DeploymentPackageTable };
