export const ProcedureNodeTypes = {
    content: { type: 'content', displayName: 'Content', mode: 'display' },
    contentViewAction: {
        type: 'contentViewAction',
        displayName: 'Content View Action',
        mode: 'action',
    },
    navigationAction: {
        type: 'navigationAction',
        displayName: 'Navigation Action',
        mode: 'action',
    },
    logic: { type: 'logic', displayName: 'Logic', mode: 'action' },
    email: { type: 'email', displayName: 'Email', mode: 'action' },
    results: { type: 'results', displayName: 'Result', mode: 'display' },
    branch: { type: 'branch', displayName: 'Branch', mode: 'branch' },
    // form: { type: 'form', displayName: 'Form', mode: 'display' },
    // lrs: { type: 'lrs', displayName: 'LRS', mode: 'display' },
};
