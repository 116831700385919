import { useState, useEffect, useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { MdClear, MdLock, MdLockOpen } from 'react-icons/md';
import { CheckboxWithLabel } from 'formik-mui';
import { useDispatch, useSelector } from 'react-redux';
import {
    TextField,
    InputLabel,
    Select,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    FormControl,
    Grid,
    Autocomplete,
    Box,
    IconButton,
    Tooltip,
} from '@mui/material';

import { makeFormStyles } from 'forms/styles';
import { FormTextField } from 'components/TextField';
import { SelectContentDialog } from './components/SelectContentDialog';
import { ChapterButtonsLocation } from 'GeminiViewerComponent/_helpers';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { VideoChapterField } from '../../ContentViewActionNodeEditor/components/VideoChapterField';
import { setViewContentPanel } from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import {
    fetchContentById,
    fetchContentVersions,
} from '_features/content/contentSlice';
import {
    fetchContentByTypeId,
    resetContentStatus,
} from '_features/content/contentSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

const ContentWidthHeightField = ({ values, setFieldValue }) => {
    const [lockAspectRatio, setLockAspectRatio] = useState(true);
    const [aspectRatio, setAspectRatio] = useState(null);

    useEffect(() => {
        if (!values?.content_id || !values?.contentVersions) {
            setAspectRatio(null);
            return;
        }

        const version = values.selectedVersion
            ? values.contentVersions.find(
                  (v) => v.content_version_id === values.selectedVersion
              )
            : values.contentVersions[0];
        if (!version || !version.file_width || !version.file_height) {
            setAspectRatio(null);
            return;
        }

        setAspectRatio(version.file_width / version.file_height);
    }, [values?.content_id, values?.contentVersions, values?.selectedVersion]);

    useEffect(() => {
        if (!lockAspectRatio || !aspectRatio) return;

        if (values.width) {
            setFieldValue('height', Math.round(values.width / aspectRatio));
        } else if (values.height) {
            setFieldValue('width', Math.round(values.height * aspectRatio));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aspectRatio]);

    return (
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <FormControl fullWidth>
                <FormTextField
                    label="Width"
                    type="number"
                    value={values.width ?? ''}
                    name="width"
                    placeholder="Width"
                    onChange={(evt) => {
                        setFieldValue('width', evt.target.value);
                        if (lockAspectRatio && aspectRatio) {
                            setFieldValue(
                                'height',
                                Math.round(evt.target.value / aspectRatio)
                            );
                        }
                    }}
                />
            </FormControl>
            <FormControl fullWidth>
                <FormTextField
                    label="Height"
                    type="number"
                    value={values.height ?? ''}
                    name="height"
                    placeholder="Height"
                    onChange={(evt) => {
                        setFieldValue('height', evt.target.value);
                        if (lockAspectRatio && aspectRatio) {
                            setFieldValue(
                                'width',
                                Math.round(evt.target.value * aspectRatio)
                            );
                        }
                    }}
                />
            </FormControl>
            <Tooltip
                title={
                    lockAspectRatio
                        ? 'Unlock aspect ratio'
                        : 'Lock aspect ratio'
                }
            >
                <IconButton
                    disabled={!aspectRatio}
                    onClick={() => setLockAspectRatio((v) => !v)}
                    tooltip="Lock aspect ratio"
                >
                    {lockAspectRatio && aspectRatio ? (
                        <MdLock />
                    ) : (
                        <MdLockOpen />
                    )}
                </IconButton>
            </Tooltip>
        </Box>
    );
};

const ContentVersionFormField = ({
    values,
    setFieldValue,
    posterVersion = false,
}) => {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);

    return (
        <FormControl
            variant="outlined"
            fullWidth
            className={classes.handleClear}
            style={{
                position: 'relative',
            }}
        >
            <Autocomplete
                variant="outline"
                id="selectedVersion"
                name="selectedVersion"
                options={
                    posterVersion
                        ? values?.posterVersions
                        : values?.contentVersions
                }
                value={
                    posterVersion
                        ? values?.posterVersions?.find(
                              (version) =>
                                  version.content_version_id ===
                                  +values?.posterVersion
                          ) ?? null
                        : values?.contentVersions?.find(
                              (version) =>
                                  version.content_version_id ===
                                  +values?.selectedVersion
                          ) ?? null
                }
                onChange={async (event, newInputValue) => {
                    if (posterVersion) {
                        setFieldValue('poster_image_url', newInputValue?.url);
                        setFieldValue(
                            'posterVersion',
                            newInputValue.content_version_id
                        );
                    } else {
                        setFieldValue(
                            'selectedVersion',
                            newInputValue.content_version_id
                        );
                        setFieldValue('selectedChapter', null);
                        dispatch(activateLoading());
                        const versions = await dispatch(
                            fetchContentVersions(values?.content_id)
                        );
                        const chapters = versions?.payload?.find(
                            (version) =>
                                version.content_version_id ===
                                newInputValue.content_version_id
                        );
                        dispatch(deactivateLoading());
                        setFieldValue('chapters', chapters.chapters);
                    }
                }}
                disableClearable
                getOptionLabel={(option) => `Version ${option?.version}`}
                renderInput={(params) => (
                    <>
                        <TextField
                            {...params}
                            variant="outlined"
                            margin="dense"
                            label={posterVersion ? 'Poster Version' : 'Version'}
                            placeholder={
                                posterVersion ? 'Poster Version' : 'Version'
                            }
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                right: '25px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                fontSize: '20px',
                            }}
                            className={'clearSelect'}
                        >
                            <IconButton
                                onClick={() => {
                                    setFieldValue('selectedVersion', null);
                                }}
                            >
                                <MdClear />
                            </IconButton>
                        </Box>
                    </>
                )}
            />
        </FormControl>
    );
};

const VideoContentForm = ({
    values,
    setFieldValue,
    selectedContent,
    setOpenContentDialog,
    setIsSelectingVideoPoster,
    selectedPoster,
}) => {
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);

    return (
        <>
            <FormControl fullWidth>
                <FormTextField
                    component={CheckboxWithLabel}
                    Label={{
                        label: 'Auto Play',
                    }}
                    label="Auto Play"
                    type="checkbox"
                    checked={values.auto_play}
                    onChange={(evt) =>
                        setFieldValue('auto_play', evt.target.checked)
                    }
                />
            </FormControl>
            <FormControl fullWidth>
                <FormTextField
                    component={CheckboxWithLabel}
                    Label={{
                        label: 'Display Chapters',
                    }}
                    label="Display Chapters"
                    type="checkbox"
                    checked={values.show_chapters}
                    onChange={(evt) => {
                        if (!evt.target.checked) {
                            setFieldValue('collapse_chapters', false);
                        }
                        setFieldValue('show_chapters', evt.target.checked);
                    }}
                />
            </FormControl>
            {values.show_chapters && (
                <>
                    <FormControl fullWidth>
                        <FormTextField
                            component={CheckboxWithLabel}
                            Label={{
                                label: 'Collapse Chapters',
                            }}
                            label="Collapse Chapters"
                            type="checkbox"
                            checked={values.collapse_chapters}
                            onChange={(evt) =>
                                setFieldValue(
                                    'collapse_chapters',
                                    evt.target.checked
                                )
                            }
                        />
                    </FormControl>
                    <Autocomplete
                        fullWidth
                        variant="outline"
                        id="chapter-buttons-location"
                        value={values?.chapter_buttons_location}
                        options={Object.values(ChapterButtonsLocation)}
                        onChange={(evt, newInputValue) => {
                            setFieldValue(
                                'chapter_buttons_location',
                                newInputValue
                            );
                        }}
                        disableClearable={true}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                margin="dense"
                                variant="outlined"
                                label="Chapter Buttons Location"
                                placeholder="Chapter Buttons Location"
                            />
                        )}
                    />
                </>
            )}
            <Autocomplete
                open={false}
                fullWidth
                variant="outline"
                id="existing-content"
                value={selectedContent}
                onKeyDown={(e) => e.preventDefault()}
                options={[]}
                disableClearable={true}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        margin="dense"
                        variant="outlined"
                        label="Content"
                        placeholder="Content"
                    />
                )}
                freeSolo={true}
                onClickCapture={() => {
                    setIsSelectingVideoPoster(false);
                    setOpenContentDialog(true);
                }}
            />
            {values?.contentVersions && values?.contentVersions?.length > 0 && (
                <ContentVersionFormField
                    values={values}
                    setFieldValue={setFieldValue}
                />
            )}
            {values?.chapters && values?.chapters?.length > 0 && (
                <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.handleClear}
                    style={{
                        position: 'relative',
                    }}
                >
                    <Autocomplete
                        variant="outline"
                        id="selectedChapter"
                        name="selectedChapter"
                        options={values?.chapters}
                        value={
                            values?.chapters?.find(
                                (chapter) =>
                                    chapter.chapter_id ===
                                    +values?.selectedChapter
                            ) ?? null
                        }
                        onChange={(event, newInputValue) => {
                            setFieldValue(
                                'selectedChapter',
                                newInputValue.chapter_id
                            );
                        }}
                        disableClearable
                        getOptionLabel={(option) => option?.title}
                        renderInput={(params) => (
                            <>
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    margin="dense"
                                    label="Chapter"
                                    placeholder="Chapter"
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        right: '25px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        fontSize: '20px',
                                    }}
                                    className={'clearSelect'}
                                >
                                    <IconButton
                                        onClick={() => {
                                            setFieldValue(
                                                'selectedChapter',
                                                null
                                            );
                                        }}
                                    >
                                        <MdClear />
                                    </IconButton>
                                </Box>
                            </>
                        )}
                    />
                </FormControl>
            )}
            <Autocomplete
                margin="dense"
                open={false}
                fullWidth
                name="poster_image_name"
                variant="outline"
                id="existing-content"
                options={[]}
                value={values?.poster_image_name || selectedPoster}
                disableClearable={true}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        margin="dense"
                        variant="outlined"
                        label="Poster Image"
                        placeholder="Poster Image"
                    />
                )}
                freeSolo={true}
                onClickCapture={() => {
                    setIsSelectingVideoPoster(true);
                    setOpenContentDialog(true);
                }}
            />
            {values?.poster_image_name &&
                values?.posterVersions?.length > 0 && (
                    <ContentVersionFormField
                        values={values}
                        setFieldValue={setFieldValue}
                        posterVersion={true}
                    />
                )}
            <ContentWidthHeightField
                values={values}
                setFieldValue={setFieldValue}
            />
        </>
    );
};

const ImageContentForm = ({
    values,
    setFieldValue,
    selectedContent,
    setOpenContentDialog,
}) => {
    return (
        <>
            <Autocomplete
                fullWidth
                variant="outline"
                id="existing-content"
                value={selectedContent}
                onKeyDown={(e) => e.preventDefault()}
                options={[]}
                disableClearable
                renderInput={(params) => (
                    <TextField
                        {...params}
                        margin="dense"
                        variant="outlined"
                        label="Content"
                        placeholder="Content"
                    />
                )}
                freeSolo
                onClickCapture={() => setOpenContentDialog(true)}
            />
            {values?.contentVersions && values?.contentVersions?.length > 0 && (
                <ContentVersionFormField
                    values={values}
                    setFieldValue={setFieldValue}
                />
            )}
            <FormControl fullWidth>
                <FormTextField
                    label="Description"
                    value={values.altText ?? ''}
                    name="altText"
                    placeholder="Description"
                    onChange={(evt) =>
                        setFieldValue('altText', evt.target.value)
                    }
                />
            </FormControl>
            <ContentWidthHeightField
                values={values}
                setFieldValue={setFieldValue}
            />
        </>
    );
};

const LinkContentForm = ({
    values,
    setFieldValue,
    selectedContent,
    setOpenContentDialog,
}) => {
    return (
        <>
            <FormControl fullWidth>
                <FormTextField
                    label="Title"
                    value={values.link_text}
                    name="link_text"
                    placeholder="Title"
                    onChange={(evt) =>
                        setFieldValue('link_text', evt.target.value)
                    }
                />
            </FormControl>
            <FormControl fullWidth>
                <FormTextField
                    component={CheckboxWithLabel}
                    Label={{
                        label: 'Display as a button',
                    }}
                    label="Display as a button"
                    type="checkbox"
                    checked={values.is_button}
                    onChange={(evt) =>
                        setFieldValue('is_button', evt.target.checked)
                    }
                />
            </FormControl>
            <FormControl fullWidth>
                <FormTextField
                    component={CheckboxWithLabel}
                    Label={{
                        label: 'Auto play videos',
                    }}
                    label="Auto play videos"
                    type="checkbox"
                    checked={values.auto_play}
                    onChange={(evt) =>
                        setFieldValue('auto_play', evt.target.checked)
                    }
                />
            </FormControl>
            <FormControl style={{ margin: '5px 0' }} fullWidth>
                <InputLabel id="display_mode_label">Display Mode</InputLabel>
                <Select
                    labelId="display_mode"
                    id="display_mode"
                    value={values.display_mode}
                    label="Display Mode"
                    onChange={(e) => {
                        setFieldValue('display_mode', e.target.value);
                    }}
                >
                    <MenuItem value={'inline'}>Inline</MenuItem>

                    <MenuItem value={'external'}>External</MenuItem>

                    <MenuItem value={'companion_app'}>Companion App</MenuItem>
                </Select>
            </FormControl>
            <FormControl style={{ margin: '5px 0' }} fullWidth>
                <Autocomplete
                    open={false}
                    fullWidth
                    variant="outline"
                    id="existing-content"
                    value={selectedContent}
                    onKeyDown={(e) => e.preventDefault()}
                    options={[]}
                    disableClearable={true}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Content"
                            placeholder="Content"
                        />
                    )}
                    freeSolo={true}
                    onClickCapture={() => setOpenContentDialog(true)}
                />
            </FormControl>
            {values?.contentVersions?.length > 0 && (
                <ContentVersionFormField
                    values={values}
                    setFieldValue={setFieldValue}
                />
            )}
            <VideoChapterField />
        </>
    );
};

const EXISTING_CONTENT_INIT_VALUES = {
    display_mode: '',
    reference_mode: '',
    auto_play: true,
    link_text: '',
    is_button: false,
    content_id: null,
};
const INLINE_VIDEO_INIT_VALUES = {
    auto_play: false,
    show_chapters: false,
    collapse_chapters: false,
    chapter_buttons_location: ChapterButtonsLocation[1],
    content_id: null,
    width: null,
    height: null,
};

const SelectContentForm = ({
    content,
    onSelectedContent,
    contentType, // link | image | video
}) => {
    const dispatch = useDispatch();
    const isComponentLoaded = useRef(true);
    const [selectedContent, setSelectedContent] = useState(null);
    const [openContentDialog, setOpenContentDialog] = useState(false);
    const [selectedPoster, setSelectedPoster] = useState(null);
    const [isSelectingVideoPoster, setIsSelectingVideoPoster] = useState(false);
    const [sortBy, setSortBy] = useState('display_name');
    const [sortDirection, setSortDirection] = useState('ASC');
    const [contentPayload, setContentPayload] = useState({});

    let onEditValues = Object.create(null);
    if (content) {
        if (contentType === 'video') {
            onEditValues['auto_play'] = !!content.auto_play;
            onEditValues['show_chapters'] = !!content.show_chapters;
            onEditValues['collapse_chapters'] = !!content.collapse_chapters;
            onEditValues['chapter_buttons_location'] =
                content.chapter_buttons_location;
            onEditValues['content_id'] = content.content_id;
            onEditValues['poster_id'] = content.poster_id;
            onEditValues['selectedChapter'] = content.selectedChapter;
            onEditValues['selectedVersion'] = content.selectedVersion;
            onEditValues['posterVersion'] = content.posterVersion;
            onEditValues['poster_image_name'] = content.poster_image_name;
            onEditValues['poster_image_url'] = content.poster_image_url;
            onEditValues['width'] = content.width;
            onEditValues['height'] = content.height;
        } else if (contentType === 'image') {
            onEditValues['content_id'] = content.content_id;
            onEditValues['selectedVersion'] = content.selectedVersion;
            onEditValues['altText'] = content.altText;
            onEditValues['width'] = content.width;
            onEditValues['height'] = content.height;
        } else {
            onEditValues['display_mode'] = content.display_mode;
            onEditValues['reference_mode'] = content.reference_mode;
            onEditValues['link_text'] = content.link_text;
            onEditValues['is_button'] = !!content.is_button;
            onEditValues['auto_play'] = !!content.auto_play;
            onEditValues['content_id'] = content.content_id;
            onEditValues['selectedVersion'] = content.selectedVersion;
            onEditValues['chapter_id'] = content.chapter_id;
        }
    } else {
        if (contentType === 'video') {
            onEditValues = {
                ...INLINE_VIDEO_INIT_VALUES,
            };
        } else {
            onEditValues = {
                ...EXISTING_CONTENT_INIT_VALUES,
            };
        }
    }

    const [initValues, setInitValues] = useState({ ...onEditValues });

    const getContentById = useCallback(async () => {
        await dispatch(activateLoading());
        const contentName = await dispatch(
            fetchContentById({ contentId: content?.content_id })
        );
        setSelectedContent(contentName?.payload?.display_name);
        const versions = await dispatch(
            fetchContentVersions(content?.content_id)
        );
        const posterVersions = await dispatch(
            fetchContentVersions(content?.poster_id)
        );
        const contentChapters = versions?.payload?.find(
            (version) =>
                version.content_version_id === initValues?.selectedVersion
        );
        if (Object.keys(contentChapters || {}).length > 0) {
            setInitValues({
                ...initValues,
                contentVersions: versions?.payload,
                posterVersions: posterVersions?.payload,
                chapters: contentChapters?.chapters,
            });
        } else {
            setInitValues({
                ...initValues,
                contentVersions: versions?.payload,
                posterVersions: posterVersions?.payload,
                chapters: contentName?.payload?.last_content_version?.chapters,
            });
        }
        await dispatch(deactivateLoading());
    }, [content?.content_id, content?.poster_id, dispatch, initValues]);

    const getContentData = async (payload) => {
        await dispatch(activateLoading());
        await dispatch(
            fetchContentByTypeId({
                ...payload,
                sort: `${sortDirection === 'ASC' ? '' : '-'}${
                    sortBy === 'content_type_id' ? 'content_type_name' : sortBy
                }`,
            })
        );
        await dispatch(deactivateLoading());
    };

    useEffect(() => {
        dispatch(setViewContentPanel(false));
        dispatch(resetContentStatus());
        if (isComponentLoaded.current) {
            isComponentLoaded.current = false;
            return;
        }
        getContentData(contentPayload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentPayload, dispatch]);

    useEffect(() => {
        if (content) {
            getContentById();
            if (contentType === 'video') {
                setInitValues({
                    ...INLINE_VIDEO_INIT_VALUES,
                    ...onEditValues,
                });
            } else {
                setInitValues({
                    ...EXISTING_CONTENT_INIT_VALUES,
                    ...onEditValues,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content, contentType]);

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        if (_sortBy !== '') {
            setContentPayload({
                ...contentPayload,
                reset: true,
                startIndex: 0,
                stopIndex: 19,
                sort: `${_sortDirection === 'ASC' ? '' : '-'}${
                    _sortBy === 'content_type_id'
                        ? 'content_type_name'
                        : _sortBy
                }`,
            });
        }
    };

    const ExistingContentValidation = Yup.object({
        link_text: Yup.string().required('Required'),
    });

    const contentDialogProps = (setFieldValue) => {
        const props = {
            openDialog: openContentDialog,
            setOpenDialog: setOpenContentDialog,
            setFieldValue,
            setSorting,
            contentPayload,
            setContentPayload,
            sortBy,
            sortDirection,
            setSelectedContent,
            contentType: contentType,
        };
        if (isSelectingVideoPoster) {
            return {
                ...props,
                setSelectedPoster: setSelectedPoster,
            };
        }
        return props;
    };

    return (
        <Dialog open={true} fullWidth maxWidth="sm">
            <DialogTitle>
                {contentType === 'video'
                    ? 'Select Inline Video'
                    : contentType === 'image'
                    ? 'Select Inline Image'
                    : 'Select Content'}
            </DialogTitle>
            <DialogContent
                style={{
                    paddingTop: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Formik
                            initialValues={initValues}
                            enableReinitialize={true}
                            validationSchema={
                                contentType === 'link' &&
                                ExistingContentValidation
                            }
                            onSubmit={(values) =>
                                onSelectedContent(values, content)
                            }
                        >
                            {({ setFieldValue, values }) => (
                                <Form>
                                    {contentType === 'video' ? (
                                        <VideoContentForm
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            selectedContent={selectedContent}
                                            setOpenContentDialog={
                                                setOpenContentDialog
                                            }
                                            selectedPoster={selectedPoster}
                                            setIsSelectingVideoPoster={
                                                setIsSelectingVideoPoster
                                            }
                                        />
                                    ) : contentType === 'image' ? (
                                        <ImageContentForm
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            selectedContent={selectedContent}
                                            setOpenContentDialog={
                                                setOpenContentDialog
                                            }
                                        />
                                    ) : (
                                        <LinkContentForm
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            selectedContent={selectedContent}
                                            setOpenContentDialog={
                                                setOpenContentDialog
                                            }
                                        />
                                    )}
                                    <div
                                        style={{
                                            justifyContent: 'end',
                                            display: 'flex',
                                            marginTop: '25px',
                                        }}
                                    >
                                        <Button
                                            onClick={() =>
                                                onSelectedContent(null)
                                            }
                                        >
                                            Cancel
                                        </Button>
                                        <Button type="submit">Save</Button>
                                    </div>
                                    {openContentDialog && (
                                        <SelectContentDialog
                                            {...contentDialogProps(
                                                setFieldValue
                                            )}
                                        />
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default SelectContentForm;
