import React, { useEffect, useRef, useState, useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { accountsSlice } from 'app/store';
import { MdAdd, MdFileDownload, MdFilterNone } from 'react-icons/md';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Action } from '_helpers';
import { useStyles } from '../styles';
import { VisualEdit } from './VisualEdit';
import ZoneCategoryModal from '../ZoneCategoryModal';
import { ZoneItems } from 'scenes/Asset/components/ZoneItems';
import { getAsset } from '_features/assets/assetManagerSlice';
import { resetTableItems } from '_features/items/itemsTableSlice';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import {
    closeAllPanel,
    openPanel,
    setEditObject,
} from '_features/common/formSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { DuplicateZonesModal } from 'components/_Modals/DuplicateZonesModal';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { ResizableTable } from 'GeminiViewerComponent/components/_Tables/ResizableTable';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import {
    setChildZones,
    setZonesOnActiveAsset,
} from 'GeminiViewerComponent/_features/asset/assetSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    debounce,
    map,
    sortBy as lodashSortBy,
} from 'GeminiViewerComponent/_helpers/lodashUtils';
import {
    resizableLabelHeader,
    resizableActionCell,
    resizableCheckCell,
    resizableCheckHeaderCell,
    resizablePinCell,
    resizableZonePreviewLabelCell,
    resizableLabelCell,
    resizableConditionalLabelCell,
} from 'components';
import {
    clearSelectedZone,
    createZoneCategory,
    deleteZoneCategory,
    fetchMoreZones,
    fetchZoneCategory,
    fetchZonesRange,
    selectAllTableZones,
    selectCategoryStatus,
    selectInitialTableZones,
    selectLoadedZonesMap,
    selectSelectedZone,
    selectTableZoneStatus,
    selectZonePageInfo,
    setSelectedZone,
    updateMultipleZoneCategory,
} from '_features/zones/zonesTableSlice';
import SearchWithFilters from 'components/SearchWithFilters';
import {
    clearEditZone,
    selectVisualEditZone,
    editZone,
} from '_features/common/editZoneSlice';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function AssetZones({ asset }) {
    const INIT_ZONES_PAYLOAD = {
        searchString: '',
        assetId: asset?.asset_id,
        startIndex: 0,
        stopIndex: 19,
        includeItems: true,
        sort: '-is_pinned,display_name',
    };
    const dispatch = useDispatch();
    const isComponentLoaded = useRef(true);
    const initialZones = useSelector(selectInitialTableZones) ?? [];
    const zoneStatus = useSelector(selectTableZoneStatus);
    const pageInfo = useSelector(selectZonePageInfo);
    const allZones = useSelector(selectAllTableZones);
    const loadedZonesMap = useSelector(selectLoadedZonesMap);
    const selectedZone = useSelector(selectSelectedZone);
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const zoneCategoryStatus = useSelector(selectCategoryStatus);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    // const viewContentPanel = useSelector(selectViewContentPanel);

    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);
    const tableClasses = tableStyles();
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);
    const [orbitZones, setOrbitZones] = useState([]);
    // const [selectedZone, setSelectedZone] = useState(null);
    const [isLoadingAssetsZones, setLoadingAssetsZones] = useState(false);

    const prevSelectedAll = usePrevious(selectedAll);
    const [zonesPayload, setZonesPayload] = useState(INIT_ZONES_PAYLOAD);
    const [sortBy, setSortBy] = useState('display_name');
    const [sortDirection, setSortDirection] = useState('ASC');
    const [openCopyZoneModal, setOpenCopyZoneModal] = useState(false);
    const [openCategoryDialog, setOpenCategoryDialog] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setZonesPayload({
                ...zonesPayload,
                searchString: searchString,
            });
        }, 500),
        []
    );

    const removeDeletedZoneCategories = useCallback(
        async (deletedZoneCategories) => {
            if (deletedZoneCategories && deletedZoneCategories?.length > 0) {
                const removeCategoryRequests = [];
                deletedZoneCategories.forEach((category) => {
                    removeCategoryRequests.push(
                        dispatch(deleteZoneCategory(category))
                    );
                });
                await Promise.all(removeCategoryRequests);
            }
        },
        [dispatch]
    );

    const createZoneCategories = useCallback(
        async (updatedZoneCategories) => {
            const createCategoryRequests = [];
            let zoneCategories = [];
            updatedZoneCategories.forEach((category) => {
                if (typeof category.zone_category_id === 'string') {
                    createCategoryRequests.push(
                        dispatch(
                            createZoneCategory({
                                display_name: category.display_name,
                                asset_id: asset?.asset_id,
                                display_order: category?.display_order,
                            })
                        )
                    );
                } else {
                    zoneCategories.push(category);
                }
            });
            let createdZoneCategories = await Promise.all(
                createCategoryRequests
            );
            createdZoneCategories.forEach((cat) => {
                if (cat?.error) {
                    dispatch(deactivateLoading());
                }
                zoneCategories.push(cat.payload);
            });
            return zoneCategories.sort(lodashSortBy('display_order'));
        },
        [asset?.asset_id, dispatch]
    );

    const zoneCategoryUpdate = useCallback(
        async (updatedZoneCategories) => {
            const allZoneCategory = updatedZoneCategories.map((cat, idx) => {
                // eslint-disable-next-line no-unused-vars
                const { asset_id, client_id, ...rest } = cat;
                return { ...rest, display_order: idx + 1 };
            });

            const updatedZoneCategory = await dispatch(
                updateMultipleZoneCategory(allZoneCategory)
            );
            if (updatedZoneCategories?.error) {
                dispatch(deactivateLoading());
            }
            return updatedZoneCategory.payload;
        },
        [dispatch]
    );

    const updateZoneData = useCallback(
        async (updatedZoneCategories, deletedZoneCategories, zones) => {
            dispatch(activateLoading());
            const zoneCategories = await createZoneCategories(
                updatedZoneCategories
            );
            await removeDeletedZoneCategories(deletedZoneCategories);
            await zoneCategoryUpdate(zoneCategories);
            if (zones && zones?.length > 0) {
                const editZoneRequests = [];
                zones.forEach((zone) => {
                    editZoneRequests.push(dispatch(editZone(zone)));
                });
                await Promise.all(editZoneRequests);
            }

            dispatch(deactivateLoading());
            dispatch(getAsset(asset?.asset_id));
            setOpenCategoryDialog(false);
        },
        [
            asset?.asset_id,
            createZoneCategories,
            dispatch,
            removeDeletedZoneCategories,
            zoneCategoryUpdate,
        ]
    );

    const handleDownloadAssetDetails = useCallback(() => {
        dispatch(
            setEditObject({
                formKey: 'exportAssetDetailsForm',
                editObject: zonesPayload,
            })
        );
        dispatch(
            openPanel({
                formKey: 'exportAssetDetailsForm',
                formAction: 'Create',
                clearEditId: true,
            })
        );
    }, [dispatch, zonesPayload]);

    const isEditingZone = useSelector(selectVisualEditZone);
    const getZoneData = async (payload, setInitZones = false) => {
        const response = await dispatch(
            fetchZonesRange({
                ...payload,
                assetId: asset.asset_id,
                startIndex: 0,
                stopIndex: 19,
            })
        );
        if (
            setInitZones &&
            !isEditingZone &&
            response.payload?.zones &&
            response.payload?.zones?.length > 0
        ) {
            await dispatch(
                setZonesOnActiveAsset({
                    assetId: asset.asset_id,
                    zoneInfo: { zones: response.payload.zones },
                })
            );
        }
    };

    useEffect(() => {
        if (zoneStatus === LoadingStatus.Idle) {
            setLoadingAssetsZones(true);
            getZoneData({ ...zonesPayload }, true);
        }
        (zoneStatus === LoadingStatus.Loaded ||
            zoneStatus === LoadingStatus.Failed) &&
            setLoadingAssetsZones(false);

        if (
            zoneCategoryStatus === LoadingStatus.Idle &&
            zoneCategoryStatus !== LoadingStatus.Loading
        ) {
            dispatch(
                fetchZoneCategory({
                    asset_id: asset.asset_id,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        zoneStatus,
        dispatch,
        asset.asset_id,
        zonesPayload,
        zoneCategoryStatus,
    ]);

    // it's called only when assetPayload changes not on component load.
    useEffect(() => {
        if (isComponentLoaded.current) {
            isComponentLoaded.current = false;
            return;
        }
        getZoneData({ ...zonesPayload });
    }, [dispatch, zonesPayload]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Array.isArray(initialZones)) {
            const index = initialZones.findIndex(
                (x) => x?.zone_id === selectedZone?.zone_id
            );
            if (index === -1) {
                dispatch(clearSelectedZone());
            }
        }
    }, [initialZones]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const childZones = [];
        if (asset?.children && asset?.children?.length > 0) {
            addChildZonesRecursively(childZones, asset);
        }
        dispatch(setChildZones(childZones));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allZones]);

    useEffect(() => {
        if (
            asset?.children &&
            asset?.children?.length > 0 &&
            allZones.length > 0
        ) {
            dispatch(
                setZonesOnActiveAsset({
                    assetId: asset.asset_id,
                    zoneInfo: { zones: allZones },
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allZones]);

    if (initialZones === undefined) {
        return null;
    }

    const addChildZonesRecursively = (childZones, asset) => {
        return asset.children.flatMap((childAsset) => {
            childZones.push(
                ...(childAsset?.compact_zones &&
                childAsset?.compact_zones?.length > 0
                    ? childAsset.compact_zones.map((zone) => ({
                          ...zone,
                          asset_id: childAsset.asset_id,
                          asset_name: childAsset.display_name,
                      }))
                    : [])
            );

            addChildZonesRecursively(childZones, childAsset);
        });
    };

    const isSelected = (zone_id) => selected.indexOf(zone_id) !== -1;

    if (prevSelectedAll && selectedAll) {
        if (selected.length !== allZones.length) {
            setSelected(allZones.map((row) => row.zone_id));
            setSelectedAll(true);
        }
    }

    const rowHeight = 60;

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedIds = allZones.map((zone) => zone.zone_id);
            setSelected(newSelectedIds);
            setSelectedAll(true);
            const selectedOrbitZones = allZones.filter(
                (zone) =>
                    newSelectedIds.includes(zone.zone_id) &&
                    zone?.orbit_frame >= 0
            );
            setOrbitZones(map(selectedOrbitZones, 'zone_id'));
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    const handleClick = (event, zone_id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(zone_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, zone_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        const selectedOrbitZones = allZones.filter(
            (zone) =>
                newSelected.includes(zone.zone_id) && zone?.orbit_frame >= 0
        );
        setOrbitZones(map(selectedOrbitZones, 'zone_id'));
        setSelected(newSelected);
        setSelectedAll(selected.length === allZones.length);
    };

    const handleItemTable = (props) => {
        const { rowData } = props;
        if (selectedZone?.zone_id !== rowData.zone_id) {
            dispatch(setSelectedZone(rowData.zone_id));
        } else {
            dispatch(setSelectedZone(null));
        }
        dispatch(resetTableItems());
    };

    /* start of start sorting */
    const handleSort = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        setZonesPayload({
            ...zonesPayload,
            sort: `-is_pinned,${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
        });
    };
    /* end of asset sorting */

    let columns = [
        {
            accessorKey: 'zone_id',
            size: 80,
            minSize: 80,
            enableResizing: false,
            padding: 'none',
            label: '',
            actionCellLevel: 'zone',
            targetPopup: 'zone',
            dispatch: dispatch,
            childComponents: [VisualEdit],
            hasHeaderSearchBar: true,
            readOnly: readOnlyMode,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableActionCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'is_pinned',
            size: 50,
            minSize: 50,
            width: 50,
            label: 'Pin',
            enableResizing: false,
            padding: 'checkbox',
            cellLevel: 'zone',
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizablePinCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
            hasHeaderSearchBar: true,
        },
        {
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'Name',
            accessorKey: 'display_name',
            padding: 'normal',
            hasHeaderSearchBar: true,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableZonePreviewLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'zone_category_name',
            padding: 'none',
            label: 'Zone Category',
            flexGrow: 1,
            size: 250,
            minSize: 250,
            hasHeaderSearchBar: true,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
    ];

    if (readOnlyMode !== true) {
        columns = [
            {
                accessorKey: 'zone_id',
                size: 50,
                minSize: 50,
                enableResizing: false,
                label: 'Select',
                padding: 'checkbox',
                cellLevel: 'zone',
                numSelected: selected.length,
                rowCount: allZones.length,
                handleClick: handleClick,
                handleSelectAllClick: handleSelectAllClick,
                isSelected: isSelected,
                hasHeaderSearchBar: true,
                header: (info) =>
                    resizableCheckHeaderCell(
                        null,
                        info?.header?.column?.columnDef
                    ),
                cell: (info) =>
                    resizableCheckCell(
                        { rowData: info?.row?.original },
                        info?.column?.columnDef
                    ),
            },
            ...columns,
        ];
    }

    if (asset?.children_ids && asset?.children_ids?.length > 0) {
        const assetColumn = {
            accessorKey: 'asset_name',
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'Asset',
            padding: 'none',
            hasHeaderSearchBar: true,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableConditionalLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        };
        if (readOnlyMode === true) {
            columns.splice(4, 0, assetColumn);
        } else {
            columns.splice(5, 0, assetColumn);
        }
    }

    async function loadMoreRows({ startIndex, stopIndex }) {
        if (zoneStatus === LoadingStatus.Loaded) {
            await dispatch(
                fetchMoreZones({
                    assetId: asset.asset_id,
                    includeItems: true,
                    ...zonesPayload,
                    startIndex: startIndex,
                    stopIndex: stopIndex,
                })
            );
        }
    }

    const handleCreateZone = () => {
        dispatch(closeAllPanel());
        dispatch(clearEditZone());
        dispatch(
            openPanel({
                formKey: 'zoneForm',
                formAction: 'Create',
                clearEditId: true,
            })
        );
    };

    return (
        <>
            <Box display="flex" justifyContent="space-between">
                <Box
                    sx={{
                        margin: '15px 0 0',
                        display: 'flex',
                        gap: '0.5rem',
                        alignItems: 'center',
                    }}
                >
                    <Tooltip title="New Zone">
                        <IconButton
                            className={classes.addNewIcon}
                            onClick={handleCreateZone}
                            size="large"
                        >
                            <MdAdd className="react-icon" />
                        </IconButton>
                    </Tooltip>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => setOpenCategoryDialog((prev) => !prev)}
                    >
                        Edit Zone Order
                    </Button>
                    <Button
                        onClick={handleDownloadAssetDetails}
                        endIcon={<MdFileDownload />}
                        variant="contained"
                        size="small"
                    >
                        Export Asset Details
                    </Button>
                </Box>
                <SearchWithFilters
                    filter={false}
                    onChange={(evt) => debouncedSearch(evt.target.value)}
                />
            </Box>
            <div
                className={clsx(
                    classes.root,
                    classes.flexGrid,
                    selectedZone != null
                        ? classes.overflowX1080
                        : classes.overflowX640
                )}
            >
                <div
                    className={clsx(
                        classes.paper,
                        selectedZone != null && classes.assetZones
                    )}
                    style={{
                        height: `calc(100vh - ${
                            selected.length > 0 ? '260px' : '250px'
                        })`,
                        minWidth: '570px',
                        overflow: 'auto hidden',
                    }}
                >
                    {selected.length > 0 && (
                        <EnhancedTableToolbar
                            numSelected={selected.length}
                            totalCount={pageInfo.TotalCount}
                            toolTipTitle={'Delete'}
                        >
                            <Tooltip
                                title={'Duplicate All'}
                                onClick={() => setOpenCopyZoneModal(true)}
                            >
                                <IconButton
                                    onClick={() => setOpenCopyZoneModal(true)}
                                    size="small"
                                    toolTipTitle="Duplicate"
                                >
                                    <MdFilterNone
                                        className="react-icon"
                                        style={{
                                            width: '18px',
                                            height: '18px',
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                            <PopupAction
                                action={Action.DeleteArray}
                                object={{
                                    assetId: asset.asset_id,
                                    zoneIds: selected,
                                    setZoneIds: setSelected,
                                }}
                                level={'zone'}
                                showLabel={false}
                                toolTipTitle="Delete All"
                            />
                        </EnhancedTableToolbar>
                    )}
                    <ResizableTable
                        initialRows={initialZones}
                        allRows={allZones}
                        totalRowCount={pageInfo.TotalCount}
                        loadMoreRows={loadMoreRows}
                        loadedRowsMap={loadedZonesMap}
                        columns={columns}
                        cellClassName={tableClasses.flexContainer}
                        className={tableClasses.table}
                        selected={selected}
                        rowClassName={clsx(
                            tableClasses.searchBarFlexContainer,
                            tableClasses.tableRowHover
                        )}
                        getRowClassName={(row) =>
                            row === selectedZone ? tableClasses.activeRow : null
                        }
                        rowHeight={rowHeight}
                        onRowClick={handleItemTable}
                        loadingStatus={
                            isLoadingAssetsZones
                                ? LoadingStatus.Loading
                                : 'No zones'
                        }
                        headerStyle={{ backgroundColor: '#eeeeee' }}
                    />
                </div>
                {selectedZone != null && <ZoneItems />}
                {openCopyZoneModal && (
                    <DuplicateZonesModal
                        open={openCopyZoneModal}
                        handleClose={() => setOpenCopyZoneModal(false)}
                        selectedItems={selected}
                        setSelectedItems={setSelected}
                        currentAssetId={asset.asset_id}
                        orbitZones={orbitZones}
                    />
                )}
            </div>
            <ZoneCategoryModal
                asset={asset}
                openDialog={openCategoryDialog}
                setOpenDialog={setOpenCategoryDialog}
                updateZoneData={updateZoneData}
            />
        </>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export { AssetZones };
