import React, { useEffect, useState } from 'react';
import { Chip } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import clsx from 'clsx';
import Dropzone from 'react-dropzone';
import { MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { makeFormStyles } from 'forms/styles';
import { ConfigHeader } from 'forms/ContentForm/components/ConfigSourceHeader';
import { ImageConfigs } from 'forms/ContentForm/components/ImageConfigs';
import { VideoConfigs } from 'forms/ContentForm/components/VideoConfigs';
import { panelStyles } from 'GeminiViewerComponent/components/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { LinkTypes } from '_helpers';
import { getContentById } from '_features/content/contentSlice';
import { ContentChapterFields } from 'forms/ContentForm/components/ContentChapterFields';

const ContentVersionFormField = ({
    contentt,
    contentId,
    readOnlyMode,
    values,
    getContentTypes,
    // getDefaultContentTypeId,
    // handleVersionChange,
    handleToggle,
    getContentMaxSize,
    onDrop,
    onDropError,
    validateVideoCompression,
    // handleViewLink,
    // handleCreateContentClick,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    imageSettings,
    setImageSettings,
    setIsVideoCompressionEnable,
    configValues,
    configFields,
    validationTypes,
}) => {
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    const panelClasses = panelStyles(theme);
    const [defaultConfigValues, setDefaultConfigValues] = useState({});
    const content = useSelector((state) => getContentById(state, contentId));

    useEffect(() => {
        if (Array.isArray(configValues)) {
            let defaultConfig = {};
            var imageAndVideoConfigKeys = [
                'convert_image_to_jpeg',
                'compress_videos',
                'enable_hls',
                'image_compression_percent',
                'max_image_width',
                'max_video_height',
                'transcribe_videos',
                'transcribe_language',
            ];

            let audioVideoConfigFields = configFields?.filter((fld) =>
                imageAndVideoConfigKeys.includes(fld.key)
            );

            audioVideoConfigFields.map((fld) => {
                defaultConfig = {
                    ...defaultConfig,
                    [fld.key]: configValues.find(
                        (data) => data.config_field_id == fld.config_field_id
                    ),
                };
            });

            setDefaultConfigValues(defaultConfig);
        }
    }, [configValues, configFields]);

    useEffect(() => {
        setFieldValue('compress_videos', null);
        setFieldValue('enable_hls', null);
        setFieldValue('max_video_height', null);
        setFieldValue('convert_image_to_jpeg', null);
        setFieldValue('image_compression_percent', null);
        setFieldValue('max_image_width', null);
        setFieldValue('transcribe_videos', null);
        setFieldValue('transcribe_language', null);
        setFieldValue('defaultConfigValues', defaultConfigValues);

        if (Object.keys(defaultConfigValues).length > 0) {
            if (content?.default_link_type_id === LinkTypes.Video.id) {
                setFieldValue(
                    'compress_videos',
                    defaultConfigValues.compress_videos.value === 'true'
                        ? true
                        : false
                );
                setFieldValue(
                    'enable_hls',
                    defaultConfigValues.enable_hls.value === 'true'
                        ? true
                        : false
                );
                setFieldValue(
                    'max_video_height',
                    defaultConfigValues.max_video_height.value
                );
                setFieldValue(
                    'transcribe_videos',
                    defaultConfigValues?.transcribe_videos?.value === 'true'
                        ? true
                        : false
                );
                setFieldValue(
                    'transcribe_language',
                    defaultConfigValues.transcribe_language.value
                );
            }
            if (content?.default_link_type_id === LinkTypes.Image.id) {
                setFieldValue(
                    'convert_image_to_jpeg',
                    defaultConfigValues.convert_image_to_jpeg.value === 'true'
                        ? true
                        : false
                );
                setFieldValue(
                    'image_compression_percent',
                    defaultConfigValues.image_compression_percent.value
                );
                setFieldValue(
                    'max_image_width',
                    defaultConfigValues.max_image_width.value
                );
            }
        }
    }, [content?.default_link_type_id, defaultConfigValues]);

    const getConfigOptionsArray = (
        configFields,
        validationTypes,
        defaultConfigValues,
        key = null
    ) => {
        if (
            Array.isArray(configFields) &&
            Array.isArray(validationTypes) &&
            Object.keys(defaultConfigValues)?.length > 0 &&
            key
        ) {
            let configFieldId = defaultConfigValues?.[key]?.config_field_id;
            if (configFieldId) {
                let validationTypeId = configFields.find(
                    (fld) => fld.config_field_id === configFieldId
                )?.validation_type_id;
                if (validationTypeId) {
                    let options = validationTypes.find(
                        (valid) =>
                            valid?.validation_type_id === validationTypeId
                    )?.options_array;
                    if (Array.isArray(options)) {
                        return options;
                    }
                }
            }
        }
        return [];
    };

    return (
        <>
            {values.toggleFormType === 'file' && (
                <>
                    {[LinkTypes.Video.id, LinkTypes.Image.id].includes(
                        content?.default_link_type_id
                    ) === true &&
                        ((content?.default_link_type_id ===
                            LinkTypes.Image.id &&
                            values?.raw_file &&
                            /(?:\.([^.]+))?$/.exec(
                                values?.raw_file?.name
                            )[1] !== 'svg') ||
                            (content?.default_link_type_id ===
                                LinkTypes.Video.id &&
                                values?.content_data)) &&
                        Object.keys(defaultConfigValues).length > 0 && (
                            <fieldset
                                style={{
                                    border: '1px solid #bdc3c7',
                                    borderRadius: '5px',
                                    padding: '10px',
                                    color: '#000000',
                                }}
                            >
                                <ConfigHeader
                                    link_type_id={content?.default_link_type_id}
                                />
                                {content?.default_link_type_id ===
                                    LinkTypes.Video.id && (
                                    <VideoConfigs
                                        defaultConfigValues={
                                            defaultConfigValues
                                        }
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        configFields={configFields}
                                        validationTypes={validationTypes}
                                        readOnlyMode={readOnlyMode}
                                        setIsVideoCompressionEnable={
                                            setIsVideoCompressionEnable
                                        }
                                        getConfigOptionsArray={
                                            getConfigOptionsArray
                                        }
                                    />
                                )}
                                {content?.default_link_type_id ===
                                    LinkTypes.Image.id && (
                                    <ImageConfigs
                                        defaultConfigValues={
                                            defaultConfigValues
                                        }
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        configFields={configFields}
                                        validationTypes={validationTypes}
                                        readOnlyMode={readOnlyMode}
                                        imageSettings={imageSettings}
                                        setImageSettings={setImageSettings}
                                        getConfigOptionsArray={
                                            getConfigOptionsArray
                                        }
                                    />
                                )}
                            </fieldset>
                        )}
                    <Field className={classes.input} name="content_data">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <div className={panelClasses.dropzoneContainer}>
                                <Dropzone
                                    maxSize={getContentMaxSize(
                                        +content?.default_link_type_id
                                    )}
                                    accept={getContentTypes(
                                        +content?.default_link_type_id
                                    ).map((at) => at.extension)}
                                    maxFiles={1}
                                    multiple={false}
                                    onDropAccepted={async (files) => {
                                        if (!readOnlyMode) {
                                            await onDrop({
                                                file: files[0],
                                                setFieldValue: setFieldValue,
                                                values: values,
                                            });
                                        }
                                    }}
                                    onDropRejected={(err) => {
                                        onDropError({
                                            err,
                                            setFieldError: setFieldError,
                                            setFieldTouched: setFieldTouched,
                                        });
                                    }}
                                    validator={(file) =>
                                        validateVideoCompression(
                                            file,
                                            content?.default_link_type_id
                                        )
                                    }
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            {...getRootProps()}
                                            className={clsx(
                                                panelClasses.dropzoneArea
                                            )}
                                            style={{
                                                backgroundImage: `url(${values.image_url})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'contain',
                                                backgroundPosition:
                                                    'center center',
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            {!values.image_url && (
                                                <div
                                                    className={
                                                        panelClasses.dropzoneText
                                                    }
                                                >
                                                    Upload Content
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Dropzone>
                                <div className={panelClasses.previewContainer}>
                                    {values?.img_name && (
                                        <Chip
                                            className={
                                                panelClasses.previewUploadFileName
                                            }
                                            color="secondary"
                                            size="small"
                                            deleteIcon={
                                                <MdClose className="react-icon" />
                                            }
                                            onDelete={() => {
                                                if (!readOnlyMode) {
                                                    setFieldValue(
                                                        'content_data',
                                                        null
                                                    );
                                                    setFieldValue(
                                                        'img_name',
                                                        null
                                                    );
                                                    setFieldValue(
                                                        'image_url',
                                                        ''
                                                    );
                                                }
                                            }}
                                            label={values?.img_name}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </Field>
                    <ErrorMessage
                        className={classes.inputErr}
                        name="content_data"
                        component="div"
                    />
                </>
            )}
            <ContentChapterFields content={content} onDropError={onDropError} />
        </>
    );
};

export { ContentVersionFormField };
