import { makeStyles } from '@mui/styles';

const linkAccordionStyles = makeStyles((theme) => ({
    sectionHeader: {
        backgroundColor: (props) => props.colors.primarySectionBackground,
        marginBottom: '1px',
    },
    heading: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 'bold',
        color: (props) => props.colors.primarySectionForeground,
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px',
        width: '20px',
        height: '20px',
        fill: (props) => props.colors.primarySectionForeground,
        color: (props) => props.colors.primarySectionForeground,
    },
    expandIcon: {
        color: (props) => props.colors.primarySectionForeground,
    },
    links: {
        fontSize: theme.typography.pxToRem(14),
        backgroundColor: (props) => props.colors.secondarySectionBackground,
        color: (props) => props.colors.secondarySectionForeground,
        flexDirection: 'column',
        padding: '8px 5px 5px 15px',
    },
    linkButtonWrapper: {
        '& > div': {
            color: (props) => props.colors.link_visited_color,
        },
    },
}));

export { linkAccordionStyles };
