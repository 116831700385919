// eslint-disable-next-line
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { accountsSlice } from 'app/store';
const { selectActiveUser } = accountsSlice;
import { linkButtonStyles } from './styles';
import { Tooltip, Typography } from '@mui/material';
import { LrsVerbs } from 'GeminiViewerComponent/_features/xAPI/xApiData';
import { saveStatement } from 'GeminiViewerComponent/_features/xAPI/xapiSlice';
import { setActiveAssetProcedureId } from '../Procedure/_features/checklistDataSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { getActiveAssetActiveZone } from 'GeminiViewerComponent/_features/asset/assetSlice';
import { setCompanionApp } from 'GeminiViewerComponent/_features/companionApps/companionAppsSlice';
import { selectContentPanelItemlId } from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import {
    displayProcedureContentVersion,
    setLinkDisplayVis,
    setPanelVis,
} from '../../_features/globals/visibilitySlice';
import {
    DisplayTypes,
    LinkTypeIds,
    mapPrefixUrl,
    mapVideoContentURL,
    prefixUrl,
} from 'GeminiViewerComponent/_helpers';
import { config } from 'gemini-config';
import { setActiveTreeId } from '../Procedure/_features/procedureSlice';

const LinkButton = ({ link, allLinks, setOpen }) => {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const classes = linkButtonStyles(theme);
    const itemId = useSelector(selectContentPanelItemlId);
    const activeZone = useSelector(getActiveAssetActiveZone);
    const activeUser = useSelector(selectActiveUser);
    const s3Prefix = activeUser?.s3_prefix;

    const linkTypeId = link.link_type_id
        ? link.link_type_id
        : link.link_type.link_type_id;

    const handleLink = useCallback(() => {
        if (
            config.modeType === 'author' &&
            (link?.display_type_id === DisplayTypes.CompanionApp.id ||
                link?.display_type?.display_type_id ===
                    DisplayTypes.CompanionApp.id)
        ) {
            setOpen(true);
        }
        dispatch(
            saveStatement({
                LrsVerb: LrsVerbs.Accessed,
                response: {
                    linkType: link.display_type_id
                        ? link.display_type_id
                        : link.display_type.display_type_id,
                    url: link.content_version?.url,
                    linkContent: link.content_version?.url
                        ? link.content_version.url
                        : link.content_version?.embed_data,
                },
                linkId: link?.link_id,
                itemId,
                zoneId: activeZone?.zone_id,
            })
        );
        const companionData = {
            display_name: link.display_name,
            app_url:
                link?.content_version?.encoding_status &&
                link?.content_version?.encoding_status.toLowerCase() ===
                    'complete'
                    ? link?.content_version?.encoded_url
                    : link.content_version?.url
                    ? link.content_version.url
                    : link.content_version?.embed_data,
            app_mode: 1,
            linkType: linkTypeId,
            linkData: link.content,
        };
        switch (
            link.display_type_id
                ? link.display_type_id
                : link.display_type.display_type_id
        ) {
            case DisplayTypes.CompanionApp.id:
                if (linkTypeId === LinkTypeIds.Procedure) {
                    dispatch(
                        setActiveAssetProcedureId(
                            link?.content_version?.procedure_id
                        )
                    );
                    dispatch(setPanelVis({ open: false }));
                    dispatch(
                        displayProcedureContentVersion(link.content_version)
                    );
                    dispatch(setCompanionApp({}));
                } else if (linkTypeId === LinkTypeIds.SME) {
                    dispatch(
                        setCompanionApp({
                            ...companionData,
                            linkData: link.content,
                        })
                    );
                } else if (linkTypeId === LinkTypeIds.Video) {
                    dispatch(
                        setCompanionApp({
                            ...companionData,
                            skill_id: link.content.skill_id,
                        })
                    );
                } else {
                    dispatch(
                        setCompanionApp({
                            ...companionData,
                        })
                    );
                }
                break;
            case DisplayTypes.External.id:
                window.open(
                    link.content_version.url ||
                        link.content_version.encoded_url,
                    '_blank'
                );
                break;
            case DisplayTypes.Inline.id:
                if (linkTypeId === LinkTypeIds.Procedure) {
                    dispatch(
                        setActiveAssetProcedureId(
                            link.content_version?.procedure_id
                        )
                    );
                    dispatch(
                        setActiveTreeId(link.content_version?.procedure_id)
                    );
                    dispatch(setPanelVis({ open: false }));
                    dispatch(
                        displayProcedureContentVersion(link.content_version)
                    );
                    dispatch(setCompanionApp({}));
                } else {
                    const linkContent = mapVideoContentURL(link);

                    dispatch(
                        setLinkDisplayVis({
                            open: true,
                            chapter_data: link?.chapter,
                            //  ||
                            // link?.content_version?.chapters?.[0],
                            allLinks: mapPrefixUrl(
                                allLinks,
                                s3Prefix,
                                'encoded_url'
                            ),
                            linkContent: prefixUrl(linkContent, s3Prefix),
                            linkData: {
                                ...link,
                                ...link?.content,
                                ...(typeof link.content_version === 'object'
                                    ? link.content_version
                                    : {}),
                            },
                            linkTypeId: linkTypeId,
                            autoPlay: Boolean(link?.auto_play),
                            overlayText: link.content_version?.overlay_text,
                        })
                    );
                }
                break;
            default:
                break;
        }
    }, [allLinks]);

    return (
        <div className={classes.linkContainer} onClick={handleLink}>
            <Tooltip title={link.display_name}>
                <Typography
                    overflow="hidden"
                    textOverflow="ellipsis"
                    display="inline-block"
                    whiteSpace="nowrap"
                    width="100%"
                >
                    {link.display_name}
                </Typography>
            </Tooltip>
            {link?.description && (
                <Typography
                    className={classes.description}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    display="inline-block"
                    whiteSpace="nowrap"
                    width="100%"
                >
                    {link.description}
                </Typography>
            )}
        </div>
    );
};

export { LinkButton };
