import React, { useCallback, useState } from 'react';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { getActiveTree } from '../../_features/procedureSlice';
import { getChecklistData } from '../../_features/checklistDataSlice';
import { SessionSubmitDialog } from './components/SessionSubmitDialog';
import { makeCommonFormPreviewStyles } from '../ProcedureFormNode/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { updateStoreSessionData } from 'GeminiViewerComponent/_helpers/updateStoreSessionData';
import { getProcedureSession } from 'GeminiViewerComponent/_features/procedureSession/procedureSessionSlice';

const SessionSubmitBtn = () => {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const formClasses = makeCommonFormPreviewStyles(theme);
    const activeTree = useSelector(getActiveTree);
    const userData = useSelector(getChecklistData);
    const getSessionData = useSelector(getProcedureSession);

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const updateSessionData = useCallback(async () => {
        updateStoreSessionData(
            dispatch,
            activeTree,
            userData,
            getSessionData,
            true
        );
        setOpenConfirmDialog(true);
    }, [dispatch, activeTree, userData, getSessionData]);

    return (
        <>
            {openConfirmDialog && (
                <SessionSubmitDialog
                    openConfirmDialog={openConfirmDialog}
                    setOpenConfirmDialog={setOpenConfirmDialog}
                />
            )}
            <Button
                onClick={updateSessionData}
                className={formClasses.submit}
                sx={{
                    padding: '5px 6px !important',
                    margin: '0 !important',
                    fontSize: '16px !important',
                }}
            >
                Submit
            </Button>
        </>
    );
};

export { SessionSubmitBtn };
