import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

export function getNetworkService({ checkAuth, config }) {
    const clearUserStorage = () => {
        const postfix = config.modeType === 'author' ? '' : '-viewer';
        localStorage.removeItem(`user${postfix}`);
        sessionStorage.removeItem(`loginEmail${postfix}`);
    };

    const setUserStorage = (data) => {
        const postfix = config.modeType === 'author' ? '' : '-viewer';
        localStorage.setItem(`user${postfix}`, data);
    };

    const getUserStorage = () => {
        const postfix = config.modeType === 'author' ? '' : '-viewer';
        return localStorage.getItem(`user${postfix}`);
    };

    const clearLoginEmail = () => {
        const postfix = config.modeType === 'author' ? '' : '-viewer';
        sessionStorage.removeItem(`loginEmail${postfix}`);
    };

    const setLoginEmail = (data) => {
        const postfix = config.modeType === 'author' ? '' : '-viewer';
        sessionStorage.setItem(`loginEmail${postfix}`, data);
    };

    const getLoginEmail = () => {
        const postfix = config.modeType === 'author' ? '' : '-viewer';
        return sessionStorage.getItem(`loginEmail${postfix}`) ?? '';
    };

    async function get(url, timeout = 60000) {
        const requestOptions = {
            headers: { 'Content-Type': 'application/json', ...authHeader(url) },
            timeout: timeout,
        };
        try {
            var response = await axios.get(url, requestOptions);
            return handleAxiosResponse(response);
        } catch (e) {
            return Promise.reject({
                code: e.code,
                error: e?.response?.data ? e?.response?.data : e.message,
            });
        }
    }

    async function post(url, body, timeout = 0, headers) {
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                ...authHeader(url),
                ...headers,
            },
            withCredentials: true,
            timeout: timeout,
        };

        try {
            var response = await axios.post(
                url,
                JSON.stringify(body),
                requestOptions
            );
            return handleAxiosResponse(response);
        } catch (e) {
            return Promise.reject({
                code: e.response?.status ?? 500,
                error: e.response?.data ?? {},
            });
        }
    }

    async function postMultiFormData(
        url,
        formData,
        params,
        timeout = 0,
        transId = false
    ) {
        var uuid = uuidv4();
        if (!transId) {
            formData.append('transaction_id', uuid);
        }

        var timer = null;

        var config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...authHeader(url),
            },
            timeout: timeout,
        };

        var progressParams = null;

        if (params && params.onUploadProgress) {
            config['onUploadProgress'] = (progressEvent) => {
                progressParams = { progressEvent, url, params, timer, uuid };
                progressHandler(params, progressParams);
            };
        }

        try {
            var response = await axios.post(url, formData, config);

            if (progressParams && progressParams?.timer != null) {
                clearInterval(progressParams?.timer);
                progressParams.timer = null;
                if (params.onProcessProgress) {
                    params.onProcessProgress(100);
                }
                if (params.onProcessFullProgress) {
                    params.onProcessFullProgress({ percent_done: 100 });
                }
                await new Promise((r) => setTimeout(r, 1000));
            }
            return response;
        } catch (e) {
            clearInterval(progressParams?.timer);
            if (progressParams?.timer) {
                progressParams.timer = null;
            }
            return Promise.reject({
                code: e?.response?.status,
                error: e?.response?.data,
            });
        }
    }

    async function put(url, body, timeout = 0) {
        let requestOptions = {
            headers: { 'Content-Type': 'application/json', ...authHeader(url) },
            timeout: timeout,
        };

        try {
            var response = await axios.put(url, body, requestOptions);
            return handleAxiosResponse(response);
        } catch (e) {
            return Promise.reject({
                code: e.code,
                error: e?.response?.data ? e?.response?.data : e.message,
            });
        }
    }

    async function putMultiFormData(url, formData, params, timeout = 0) {
        var uuid = uuidv4();
        formData.append('transaction_id', uuid);

        var timer = null;

        var config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...authHeader(url),
            },
            timeout: timeout,
        };

        var progressParams = null;

        if (params && params.onUploadProgress) {
            config['onUploadProgress'] = (progressEvent) => {
                progressParams = { progressEvent, url, params, timer, uuid };
                progressHandler(params, progressParams);
            };
        }

        try {
            var response = await axios.put(url, formData, config);
            if (progressParams && progressParams?.timer != null) {
                clearInterval(progressParams?.timer);
                progressParams.timer = null;
                if (params.onProcessProgress) {
                    params.onProcessProgress(100);
                }
                if (params?.onProcessFullProgress) {
                    params?.onProcessFullProgress({ percent_done: 100 });
                }
                await new Promise((r) => setTimeout(r, 1000));
            }

            return response;
        } catch (e) {
            clearInterval(progressParams?.timer);
            if (progressParams?.timer) {
                progressParams.timer = null;
            }
            return Promise.reject({
                code: e?.response?.status,
                error: e?.response?.data,
            });
        }
    }

    const progressHandler = (params, progressParams) => {
        if (params.onUploadProgress) {
            params.onUploadProgress(progressParams.progressEvent);
        }

        if (params.onUploadDynamicProgress) {
            params.onUploadDynamicProgress(progressParams.progressEvent);
        }

        if (
            progressParams.progressEvent.total ===
            progressParams.progressEvent.loaded
        ) {
            if (params.onProcessProgress) {
                params.onProcessProgress(0);
            }
            if (params.onProcessFullProgress) {
                params.onProcessFullProgress({
                    percent_done: 0,
                    total_files: 0,
                    files_transferred: 0,
                });
            }
            if (params.onUploadDynamicProgress) {
                params.onUploadDynamicProgress({
                    percent_done: 0,
                    current_step: 0,
                    total_steps: 0,
                });
            }
            progressParams.timer = setInterval(async () => {
                if (params.onProcessProgress) {
                    let response = await axios.get(
                        `${config.apiUrl}/progress/${progressParams.uuid}`,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                ...authHeader(progressParams.url),
                            },
                        }
                    );
                    params.onProcessProgress(response.data.percent_done);
                }
                if (params.onProcessFullProgress) {
                    let response = await axios.get(
                        `${config.apiUrl}/progress/fullprogress/${progressParams.uuid}`,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                ...authHeader(progressParams.url),
                            },
                        }
                    );
                    params.onProcessFullProgress(response.data);
                }

                if (params.onUploadDynamicProgress) {
                    let response = await axios.get(
                        `${config.apiUrl}/progress/generalprogress/${progressParams.uuid}`,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                ...authHeader(progressParams.url),
                            },
                        }
                    );
                    params.onUploadDynamicProgress(response.data);
                }
            }, 2000);
        }
    };

    // prefixed with underscored because delete is a reserved word in javascript
    async function _delete(url) {
        const requestOptions = {
            headers: authHeader(url),
        };
        try {
            const response = await axios.delete(url, requestOptions);
            return handleAxiosResponse(response);
        } catch (e) {
            return Promise.reject({
                code: e?.response?.status,
                error: e?.response?.data,
            });
        }
    }

    // helper functions
    function authHeader(url) {
        // return auth header with jwt if user is logged in and request is to the api url
        const user = JSON.parse(getUserStorage());
        const isLoggedIn = user && user.jwt_token;
        const isApiUrl = url.startsWith(config.apiUrl);
        if (isLoggedIn && isApiUrl) {
            return { Authorization: `Bearer ${user.jwt_token}` };
        } else {
            return {};
        }
    }

    function handleAxiosResponse(response) {
        if (response.status < 200 || response.status > 299) {
            checkAuth(response.status);
            const error =
                (response.data && response.data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (response.headers['x-pagination']) {
            response.headers['x-pagination'] = JSON.parse(
                response.headers['x-pagination']
            );
        }
        return { headers: response.headers, data: response.data };
    }

    async function postWithBody(url, formData, params, timeout = 0) {
        var uuid = uuidv4();

        formData.transaction_id = uuid;

        var timer = null;

        var config = {
            headers: {
                'Content-Type': 'application/json',
                ...authHeader(url),
            },
            timeout: timeout,
        };

        var progressParams = null;

        if (params && params.onUploadDynamicProgress) {
            config['onUploadProgress'] = (progressEvent) => {
                progressParams = { progressEvent, url, params, timer, uuid };
                progressHandler(params, progressParams);
            };
        }

        try {
            var response = await axios.post(url, formData, config);

            if (progressParams && progressParams?.timer != null) {
                clearInterval(progressParams?.timer);
                progressParams.timer = null;
                if (params.onProcessProgress) {
                    params.onProcessProgress(100);
                }
                if (params.onProcessFullProgress) {
                    params.onProcessFullProgress({ percent_done: 100 });
                }
                await new Promise((r) => setTimeout(r, 1000));
            }
            return response;
        } catch (e) {
            clearInterval(progressParams?.timer);
            if (progressParams?.timer) {
                progressParams.timer = null;
            }
            return Promise.reject({
                code: e?.response?.status,
                error: e?.response?.data,
            });
        }
    }

    return {
        get,
        post,
        postMultiFormData,
        put,
        putMultiFormData,
        delete: _delete,
        setUserStorage,
        clearUserStorage,
        getUserStorage,
        clearLoginEmail,
        setLoginEmail,
        getLoginEmail,
        postWithBody,
    };
}
