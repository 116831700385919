// eslint-disable-next-line
import React, { useCallback, useEffect, useMemo } from 'react';
import { BiImageAlt } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tooltip, Typography } from '@mui/material';
import {
    MdAllInclusive,
    MdDeselect,
    MdFilterAlt,
    MdKeyboardArrowDown,
    MdKeyboardArrowUp,
    MdSelectAll,
} from 'react-icons/md';

import { useStyles } from './styles';
import { draggable } from '../Draggable';
import { setItemLinks } from 'shared/loadZoneSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    getActiveAssetActiveZone,
    getChildZones,
    getSelectedSearchItemId,
    selectCompactZones,
} from 'GeminiViewerComponent/_features/asset/assetSlice';
import {
    getTagFilters,
    setTagFilters,
    getShowAllTags,
    setShowAllTags,
    getTagsNameDisplay,
    setTagsNameDisplay,
    getTagsContainerDisplay,
    setTagsContainerDisplay,
    getShowOnlyByFilter,
    setShowOnlyByFilter,
} from 'GeminiViewerComponent/_features/globals/visibilitySlice';

let didDrag = false;

const TagBar = ({ tagContainerRef, itemTagsList, setItemTagsList }) => {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);
    const tagFilters = useSelector(getTagFilters);
    const selectedSearchItemId = useSelector(getSelectedSearchItemId);
    const activeZone = useSelector(getActiveAssetActiveZone);
    const tagsContainerDisplay = useSelector(getTagsContainerDisplay);
    const tagsNameDisplay = useSelector(getTagsNameDisplay);
    const showAllTags = useSelector(getShowAllTags);
    const allZones = useSelector(selectCompactZones);
    const childZones = useSelector(getChildZones);
    const allZoneData = useMemo(() => {
        if (childZones && childZones?.length > 0) {
            return [...allZones, ...childZones];
        } else {
            return allZones;
        }
    }, [allZones, childZones]);
    const showOnlyByFilter = useSelector(getShowOnlyByFilter);

    useEffect(() => {
        let tagsData = [];
        let allData = [];
        let uniqueIds = [];

        if (showAllTags) {
            allZoneData.forEach((zone) => {
                if (
                    Array.isArray(zone.item_tags) &&
                    zone.item_tags.length > 0
                ) {
                    allData = [...allData, ...zone.item_tags];
                }
            });
            if (allData.length > 0) {
                allData.forEach((tag) => {
                    if (!uniqueIds.includes(tag.tag_id)) {
                        tagsData.push(tag);
                        uniqueIds.push(tag.tag_id);
                    }
                });
                tagsData.sort((tag1, tag2) =>
                    tag1.name.localeCompare(tag2.name)
                );
                setItemTagsList(tagsData);
            } else {
                setItemTagsList([]);
            }
        } else {
            if (
                Array.isArray(activeZone?.items) &&
                activeZone?.items?.length > 0
            ) {
                let tagsData = [];
                let allData = [];
                let uniqueIds = [];
                activeZone?.items.forEach((item) => {
                    if (Array.isArray(item?.tags) && item?.tags.length > 0) {
                        allData = [...allData, ...item.tags];
                    }
                });
                if (allData.length > 0) {
                    allData.forEach((tag) => {
                        if (!uniqueIds.includes(tag.tag_id)) {
                            tagsData.push(tag);
                            uniqueIds.push(tag.tag_id);
                        }
                    });
                    tagsData.sort((tag1, tag2) =>
                        tag1.name.localeCompare(tag2.name)
                    );

                    setItemTagsList(tagsData);
                } else {
                    setItemTagsList([]);
                }
            } else {
                setItemTagsList([]);
            }
        }
    }, [activeZone, selectedSearchItemId, showAllTags]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleTagClick = useCallback(
        (clickedTag) => {
            dispatch(setItemLinks([{ links: [] }]));
            let newTagFilters;
            // If filter is in list then remove it, otherwise add it
            const existingFilter = tagFilters.item_tags.findIndex(
                (tag) => tag.tag_id === clickedTag.tag_id
            );
            if (existingFilter > -1) {
                newTagFilters = tagFilters.item_tags.filter(
                    (tag) => tag.tag_id !== clickedTag.tag_id
                );
            } else {
                newTagFilters = [...tagFilters.item_tags, clickedTag];
            }
            dispatch(
                setTagFilters({ ...tagFilters, item_tags: newTagFilters })
            );
        },
        [dispatch, tagFilters]
    );

    const clearAllTags = useCallback(() => {
        dispatch(setItemLinks([{ links: [] }]));
        const newItemTags = tagFilters.item_tags.filter(
            (tag) =>
                itemTagsList.findIndex((it) => it.tag_id === tag.tag_id) === -1
        );
        dispatch(setTagFilters({ ...tagFilters, item_tags: newItemTags }));
    }, [dispatch, itemTagsList, tagFilters]);

    const selectAllTags = useCallback(() => {
        dispatch(setItemLinks([{ links: [] }]));
        const allItemTags = [...tagFilters.item_tags, ...itemTagsList];
        dispatch(setTagFilters({ ...tagFilters, item_tags: allItemTags }));
    }, [dispatch, itemTagsList, tagFilters]);

    const TagElement = useCallback(
        ({ tag }) => {
            return (
                <div
                    className={classes.tagContainerTagDiv}
                    style={{
                        backgroundColor:
                            tagFilters.item_tags?.findIndex(
                                (itemTag) => itemTag.tag_id === tag.tag_id
                            ) > -1
                                ? theme?.colors?.tag_active_background_color
                                : theme?.colors?.tag_inactive_background_color,
                        maxHeight: '25px',
                    }}
                    key={tag?.tag_id}
                    onClick={(e) => {
                        if (didDrag) {
                            didDrag = false;
                        } else {
                            handleTagClick(tag);
                        }
                    }}
                >
                    {!tagsNameDisplay && tag?.image_url ? (
                        <img
                            src={tag?.image_url}
                            alt={tag?.name}
                            width="25px"
                            height="20px"
                            style={{
                                opacity: `${
                                    theme?.opacity?.tag_foreground_opacity / 100
                                }`,
                            }}
                        />
                    ) : (
                        <div className={classes.tagContainerTagNameDiv}>
                            {tag.name}
                        </div>
                    )}
                </div>
            );
        },
        [
            classes.tagContainerTagDiv,
            classes.tagContainerTagNameDiv,
            handleTagClick,
            tagFilters.item_tags,
            tagsNameDisplay,
            theme?.colors?.tag_active_background_color,
            theme?.colors?.tag_inactive_background_color,
            theme?.opacity?.tag_foreground_opacity,
        ]
    );

    const TagType = useCallback(() => {
        return (
            <div className={classes.tagContainerTagNameToggleIconDiv}>
                <Tooltip title={`${tagsNameDisplay ? 'Image' : 'Text'}`}>
                    <IconButton
                        variant="contained"
                        size="small"
                        onClick={() => {
                            if (didDrag) {
                                didDrag = false;
                            } else {
                                dispatch(setTagsNameDisplay());
                            }
                        }}
                    >
                        <BiImageAlt
                            className="react-icon"
                            style={{
                                color: `${
                                    tagsNameDisplay
                                        ? theme?.colors?.icon_active_color
                                        : theme?.colors?.icon_inactive_color
                                }`,
                            }}
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip title={!showOnlyByFilter ? 'Inclusive' : 'Exclusive'}>
                    <IconButton
                        variant="contained"
                        size="small"
                        onClick={() => {
                            dispatch(setShowOnlyByFilter());
                        }}
                    >
                        <MdFilterAlt
                            className="react-icon"
                            style={{
                                color: `${
                                    showOnlyByFilter
                                        ? theme?.colors.icon_active_color
                                        : theme?.colors.icon_inactive_color
                                }`,
                            }}
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip title={`${showAllTags ? 'Zone' : 'All'} Tags`}>
                    <IconButton
                        variant="contained"
                        size="small"
                        onClick={() => {
                            if (didDrag) {
                                didDrag = false;
                            } else {
                                dispatch(setShowAllTags());
                            }
                        }}
                    >
                        <MdAllInclusive
                            className="react-icon"
                            style={{
                                color: `${
                                    showAllTags
                                        ? theme?.colors?.icon_active_color
                                        : theme?.colors?.icon_inactive_color
                                }`,
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </div>
        );
    }, [
        classes.tagContainerTagNameToggleIconDiv,
        tagsNameDisplay,
        theme?.colors.icon_active_color,
        theme?.colors.icon_inactive_color,
        showOnlyByFilter,
        showAllTags,
        dispatch,
    ]);

    const TagSelection = useCallback(() => {
        return (
            <div className={classes.tagSelectButtons}>
                <Tooltip title="Select All">
                    <IconButton
                        variant="contained"
                        size="small"
                        onClick={() => {
                            if (didDrag) {
                                didDrag = false;
                            } else {
                                selectAllTags();
                            }
                        }}
                        className={classes.tagsIconsBackground}
                    >
                        <MdSelectAll
                            className={`react-icon ${classes.tagsIcons}`}
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Clear All">
                    <IconButton
                        variant="contained"
                        size="small"
                        onClick={() => clearAllTags()}
                        sx={{ color: 'white' }}
                        className={classes.tagsIconsBackground}
                    >
                        <MdDeselect
                            className={`react-icon ${classes.tagsIcons}`}
                        />
                    </IconButton>
                </Tooltip>
            </div>
        );
    }, [
        classes.tagSelectButtons,
        classes.tagsIcons,
        classes.tagsIconsBackground,
        clearAllTags,
        selectAllTags,
    ]);

    function onMovedBar(screenPosition, dragElement) {
        didDrag = true;
    }

    return (
        itemTagsList.length > 0 && (
            <div
                ref={tagContainerRef}
                className={classes.tagBarContainer}
                style={{
                    bottom: '0',
                    ...(tagsContainerDisplay
                        ? {
                              padding: '5px 10px 0px 10px',
                              borderRadius: '10px 10px 0 0',
                          }
                        : {
                              padding: '0px 10px 0px 10px',
                              borderRadius: '5px 5px 0 0',
                          }),
                }}
                onPointerDown={(event) => {
                    didDrag = false;
                    draggable.handleMouseDown({
                        event: event,
                        dragElement: event.currentTarget,
                        onMoved: onMovedBar,
                        horizontalOnly: true,
                        contained: true,
                    });
                    event.preventDefault();
                    event.stopPropagation();
                }}
            >
                {tagsContainerDisplay ? (
                    <>
                        <Typography
                            gutterBottom={true}
                            style={{ fontSize: '12px' }}
                        >{`${showAllTags ? 'All' : 'Zone specific'} Tags, ${
                            showOnlyByFilter ? 'Inclusive' : 'Exclusive'
                        }`}</Typography>
                        <div className={classes.tagContainerListDiv}>
                            {itemTagsList.map((tag) => (
                                <TagElement tag={tag} key={tag?.tag_id} />
                            ))}
                        </div>
                        <div className={classes.tagButtons}>
                            <TagType />
                            <div className={classes.tagContainerToggleIconDiv}>
                                <Tooltip title="Hide Tags">
                                    <IconButton
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                            if (didDrag) {
                                                didDrag = false;
                                            } else {
                                                dispatch(
                                                    setTagsContainerDisplay()
                                                );
                                            }
                                        }}
                                        style={{
                                            color: '#ffffff',
                                        }}
                                    >
                                        <MdKeyboardArrowDown className="react-icon" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <TagSelection />
                        </div>
                    </>
                ) : (
                    <div>
                        <Tooltip title="Show Tags">
                            <IconButton
                                variant="contained"
                                size="small"
                                sx={{ padding: '0' }}
                                onClick={() => {
                                    if (didDrag) {
                                        didDrag = false;
                                    } else {
                                        dispatch(setTagsContainerDisplay());
                                    }
                                }}
                                style={{
                                    color: '#ffffff',
                                }}
                            >
                                <MdKeyboardArrowUp className="react-icon" />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
            </div>
        )
    );
};

export { TagBar };
