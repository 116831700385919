import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { MdAdd } from 'react-icons/md';
import { useFormikContext } from 'formik';
import { IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { makeCommonFormFieldStyles } from '../styles';
import { TableFieldArray } from './components/TableFieldArray';
import { TableDuplicateFieldArray } from './components/TableDuplicateFieldArray';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    getDuplicateFields,
    setDuplicateFields,
} from 'GeminiViewerComponent/components/Procedure/_features/checklistDataSlice';

const TableField = ({
    data,
    fieldValue,
    setFieldValue,
    errors,
    setErrors,
    handleBlur,
    isReadOnly = false,
    isSubmitAllow = true,
    isMobileWidth = false,
    handleClick = () => {},
    contentService,
}) => {
    const { validateForm } = useFormikContext();
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const getDuplicatedField = useSelector(getDuplicateFields);
    const [fieldData, setFieldData] = useState(
        data?.fields ? [...data.fields] : []
    );
    const [repeatingRow, setRepeatingRow] = useState([]);
    const classes = makeCommonFormFieldStyles(theme);
    let colWidthRemaining = 100;
    let colWidths = [];

    let undefinedCols = [];
    for (var i = 0; i < data.columns?.length; i++) {
        let curCol = data.columns[i];
        if (curCol?.width) {
            colWidths[i] = curCol.width;
            colWidthRemaining = colWidthRemaining - curCol.width;
        } else {
            undefinedCols.push(i);
        }
    }

    var disWidth = colWidthRemaining / undefinedCols.length;
    for (var j = 0; j < undefinedCols.length; j++) {
        colWidths[undefinedCols[j]] = disWidth;
    }

    const tableClasses = data?.showBorder
        ? clsx(classes.formTable, classes.formTableBorders)
        : clsx(classes.formTable);

    const hasLabel = !!data?.label;
    const hasHeaders = data?.columns?.some((elm) => !!elm.label);
    const cols =
        data?.columns
            ?.map((col) => (col?.colspan ? Number(col?.colspan) : 1))
            ?.reduce((a, b) => a + b, 0) ?? 1;
    let rows = [];
    let startIndex = 0;
    let spanTotal = 0;
    fieldData?.forEach((field, index) => {
        spanTotal += field?.colspan ? Number(field?.colspan) : 1;
        if (spanTotal >= cols || index === fieldData?.length - 1) {
            // Pass true in rows to display add button dynamically.
            if (fieldData[index]?.duplicateCount) {
                if (!fieldData?.[index + cols]?.duplicateCount) {
                    spanTotal = 0;
                    rows = [...rows, [startIndex, index + 1, true]];
                    startIndex = index + 1;
                } else {
                    spanTotal = 0;
                    rows = [...rows, [startIndex, index + 1]];
                    startIndex = index + 1;
                }
            } else {
                if (!fieldData?.[index + cols]?.duplicateCount) {
                    spanTotal = 0;
                    rows = [...rows, [startIndex, index + 1, true]];
                    startIndex = index + 1;
                } else {
                    spanTotal = 0;
                    rows = [...rows, [startIndex, index + 1]];
                    startIndex = index + 1;
                }
            }
        }
    });
    let width = 0;

    const initialDuplicatedField = Object.fromEntries(
        Array.from({ length: rows.length }, (_, key) => [key, []])
    );
    const [duplicatedField, setDuplicatedField] = useState(
        initialDuplicatedField
    );

    const createUniqueVariable = (row, index) => {
        let allVariables = fieldData.map((field) => field.variable);
        let isUniqueVariable = false;
        let suffixVariableCount = 1;
        let duplicateField = {};
        let rowData = { ...row };

        if (rowData?.duplicateCount) {
            let lastIndex = rowData?.variable.lastIndexOf(
                `-${rowData.duplicateCount}`
            );
            rowData = {
                ...row,
                variable: rowData?.variable.slice(0, lastIndex),
            };
        }
        while (isUniqueVariable === false) {
            if (
                !allVariables.includes(
                    `${rowData?.variable}-${suffixVariableCount}`
                )
            ) {
                duplicateField = {
                    ...rowData,
                    variable: `${rowData?.variable}-${
                        duplicatedField?.[index]?.length / cols + 1 || 1
                    }`,
                    duplicateCount:
                        duplicatedField?.[index]?.length / cols + 1 || 1,
                };
                isUniqueVariable = true;
            } else {
                ++suffixVariableCount;
            }
        }
        return duplicateField;
    };

    const addRepeatingRow = (row, index) => {
        const duplicated = [];
        let updatedField = { ...duplicatedField };
        if (data?.allowRepeatingRows && row) {
            row.map((fieldRow) => {
                const duplicateField = createUniqueVariable(fieldRow, index);
                duplicated.push(duplicateField);
                if (duplicateField.type === 'checkbox') {
                    setFieldValue(duplicateField.variable, false);
                } else if (duplicateField.type === 'inspectionpoint') {
                    setFieldValue(duplicateField.variable, {
                        field_id: '',
                        action: '',
                        reason: '',
                    });
                } else if (duplicateField.type === 'datetime') {
                    if (duplicateField.autofill) {
                        const format =
                            duplicateField.date_time_type === 'date'
                                ? 'MM/DD/YYYY'
                                : duplicateField.date_time_type === 'time'
                                ? 'h:mm A'
                                : duplicateField.date_time_type ===
                                      'datetime' && 'MM/DD/YYYY h:mm A';

                        setFieldValue(
                            duplicateField.variable,
                            moment(Date.now()).format(format)
                        );
                    } else {
                        setFieldValue(duplicateField.variable, '');
                    }
                } else {
                    setFieldValue(duplicateField.variable, '');
                }
                // }
            });

            if (updatedField[index]) {
                updatedField[index] = [...updatedField[index], ...duplicated];
            } else {
                updatedField[index] = [...duplicated];
            }
        }
        setDuplicatedField(updatedField);

        dispatch(
            setDuplicateFields({
                ...getDuplicatedField,
                [data?.id]: updatedField,
            })
        );
    };

    useEffect(() => {
        setFieldData(
            Array.isArray(data?.fields) && data?.fields?.length > 0
                ? [...data.fields]
                : []
        );
    }, [data]);

    useEffect(() => {
        // if (isReadOnly)
        if (getDuplicatedField) {
            setDuplicatedField(getDuplicatedField[data?.id]);
            if (
                getDuplicatedField[data?.id] &&
                Object.keys(getDuplicatedField[data?.id]).length > 0
            ) {
                validateForm();
            }
        }
    }, [getDuplicatedField]);

    const isTableFieldFullFilled = fieldData.length % cols;

    return (
        <table
            className={tableClasses}
            width={data.width ?? '100%'}
            style={{
                border: 'none',
                margin: '5px',
                maxWidth: data.width ? 'unset' : 'calc(100% - 10px)',
            }}
            onClick={(e) => {
                !isSubmitAllow && handleClick(e, data);
            }}
        >
            {(hasLabel || hasHeaders) && (
                <thead>
                    {cols > 1 && (
                        <tr style={{ display: 'none' }}>
                            {Array.from({ length: cols }).map((col, idx) => (
                                <td key={`empty-colspan-${idx}`}>&nbsp;</td>
                            ))}
                        </tr>
                    )}
                    {hasLabel && (
                        <tr>
                            <td style={{ textAlign: 'center' }} colSpan={cols}>
                                {data.label}
                            </td>
                        </tr>
                    )}
                    {isMobileWidth !== true && hasHeaders && (
                        <tr>
                            {data?.columns?.map((field, idx) => {
                                width = width + +field.width;
                                return (
                                    <th
                                        style={{ width: `${colWidths[idx]}%` }}
                                        key={`table_${data.id}_col_${idx}`}
                                        colSpan={
                                            field?.colspan
                                                ? Number(field?.colspan)
                                                : 1
                                        }
                                    >
                                        {field.label}
                                    </th>
                                );
                            })}
                        </tr>
                    )}
                </thead>
            )}
            <tbody>
                {rows.map((rowIndexes, index) => {
                    const cells = fieldData.slice(rowIndexes[0], rowIndexes[1]);
                    let nextCells = [];
                    nextCells = fieldData.slice(
                        rowIndexes[0] + cols,
                        rowIndexes[1] + cols
                    );
                    const commonFields =
                        JSON.stringify(cells.map((cell) => cell.type)) ===
                        JSON.stringify(nextCells.map((cell) => cell.type));

                    return (
                        <React.Fragment key={`table_${data.id}_row_${index}`}>
                            <TableFieldArray
                                repeatingRow={repeatingRow}
                                setRepeatingRow={setRepeatingRow}
                                fieldData={fieldData}
                                setFieldData={setFieldData}
                                rowIndexes={rowIndexes}
                                cells={cells}
                                data={data}
                                index={index}
                                hasHeaders={hasHeaders}
                                colWidths={colWidths}
                                cols={cols}
                                isMobileWidth={isMobileWidth}
                                rows={rows}
                                fieldValue={fieldValue}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                setErrors={setErrors}
                                isReadOnly={isReadOnly}
                                isSubmitAllow={isSubmitAllow}
                                handleBlur={handleBlur}
                                handleClick={handleClick}
                                contentService={contentService}
                            />
                            <TableDuplicateFieldArray
                                repeatingRow={repeatingRow}
                                setRepeatingRow={setRepeatingRow}
                                fieldData={duplicatedField?.[index]}
                                setFieldData={setDuplicatedField}
                                rowIndexes={rowIndexes}
                                allDuplicateFields={duplicatedField}
                                data={data}
                                idx={index}
                                hasHeaders={hasHeaders}
                                colWidths={colWidths}
                                cols={cols}
                                isMobileWidth={isMobileWidth}
                                rows={rows}
                                fieldValue={fieldValue}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                setErrors={setErrors}
                                isReadOnly={isReadOnly}
                                isSubmitAllow={isSubmitAllow}
                                handleBlur={handleBlur}
                                handleClick={handleClick}
                                contentService={contentService}
                            />
                            {data.allowRepeatingRows &&
                                isTableFieldFullFilled === 0 &&
                                !commonFields && (
                                    <IconButton
                                        disabled={isReadOnly}
                                        onClick={() => {
                                            setRepeatingRow({
                                                ...repeatingRow,
                                                [index]: true,
                                            });
                                            addRepeatingRow(cells, index);
                                        }}
                                        className={classes.repeateRowButton}
                                        aria-label="close"
                                        style={{
                                            margin: `${
                                                isMobileWidth
                                                    ? '0'
                                                    : '10px 0 20px 0'
                                            }`,
                                        }}
                                        size="large"
                                    >
                                        <MdAdd
                                            className="react-icon"
                                            style={{ fontSize: '18px' }}
                                        />
                                    </IconButton>
                                )}
                        </React.Fragment>
                    );
                })}
            </tbody>
        </table>
    );
};

export default TableField;
