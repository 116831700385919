import React, { useEffect, useRef, useState } from 'react';
import Tree, { mutateTree, moveItemOnTree } from '@atlaskit/tree';
import { BiChevronDown, BiChevronRight } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { FieldEditDialog } from './components/FieldPopups/FieldEditDialog';
import { FieldDeleteDialog } from './components/FieldPopups/FieldDeleteDialog';
import { FieldDuplicateDialog } from './components/FieldPopups/FieldDuplicateDialog';
import {
    getPreviewField,
    setFieldPreview,
} from '_features/procedures/proceduresSlice';
import { Paper, styled, Tooltip, IconButton, Box } from '@mui/material';
import {
    MdAdd,
    MdContentCopy,
    MdDelete,
    MdDragHandle,
    MdEdit,
} from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';

import { makeStyles } from '@mui/styles';
import { makeProcedureStyles } from '../../styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

const makeFormEditorStyles = makeStyles(() => ({
    container: {
        width: '100%',
    },
    dot: {
        display: 'flex',
        width: '24px',
        height: '32px',
        justifyContent: 'center',
        fontSize: '12px',
        lineHeight: '32px',
    },
    icon: {
        width: '18px',
        height: '18px',
    },
    row: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        paddingBottom: '3px',
    },
}));

const addField = (tree, field) => {
    tree.items[field.id] = {
        id: field.id,
        children: [],
        hasChildren:
            field.type === 'group' || field.type === 'table' ? false : true,
        isExpanded:
            field.type === 'group' || field.type === 'table' ? true : false,
        isChildrenLoading: false,
        data: field,
    };
    if (field.type === 'group' || field.type === 'table') {
        const group = tree.items[field.id];
        group.hasChildren = false;
        field?.fields?.forEach((fld) => {
            group.children.push(fld.id);
            addField(tree, fld);
        });
    }
};

const fieldsToTree = (fields) => {
    var tree = {
        rootId: 'root',
        items: {
            root: {
                id: 'root',
                children: [],
                hasChildren: false,
                isExpanded: true,
                isChildrenLoading: false,
            },
        },
    };

    fields?.forEach((field) => {
        addField(tree, field);
        tree.items.root.children.push(field.id);
    });

    return tree;
};

const makeNodeTableStyles = makeStyles(() => ({
    detailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '3px',
    },
    detailsTitle: {
        fontWeight: 'bold',
        fontStyle: 'italic',
        textTransform: 'capitalize',
    },
    detailsLabel: {
        fontWeight: 'bold',
        fontStyle: 'italic',
    },
    detailsVariable: {
        fontWeight: 'bold',
        fontStyle: 'italic',
    },
    addButtonIcon: {
        padding: '0 !important',
        borderRadius: '25px !important',
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
    },
}));

const StyledTableRow = styled(Box)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    verticalAlign: 'center',
    width: '100%',
    padding: '0 6px 0 0',
    display: 'flex',
    flexWrap: 'nowrap',
    fontSize: '12px',
}));

const NodeContentCell = ({ node }) => {
    const theme = useSelector(selectActiveTheme);
    const nodeTableStyles = makeNodeTableStyles(theme);
    const titlePadding = '7px 0';

    return (
        <>
            <Box
                alignItems="center"
                display="flex"
                align="left"
                style={{ padding: titlePadding }}
                width={'22%'}
            >
                <Tooltip
                    title={node.type}
                    className={nodeTableStyles.detailsContainer}
                    style={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <div className={nodeTableStyles.detailsTitle}>
                        {node.type}
                    </div>
                </Tooltip>
            </Box>
            <Box
                alignItems="center"
                display="flex"
                align="left"
                px="0"
                py="7px"
                width={'22%'}
                overflow="hidden"
            >
                <Tooltip
                    title={node?.label}
                    className={nodeTableStyles.detailsContainer}
                    style={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <div className={nodeTableStyles.detailsLabel}>
                        {node?.label}
                    </div>
                </Tooltip>
            </Box>
            <Box
                alignItems="center"
                display="flex"
                align="left"
                px="0"
                py="7px"
                width={'22%'}
                overflow="hidden"
            >
                <Tooltip
                    title={node?.variable}
                    className={nodeTableStyles.detailsContainer}
                    style={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <div className={nodeTableStyles.detailsVariable}>
                        {node?.variable}
                    </div>
                </Tooltip>
            </Box>
        </>
    );
};

const ActionCell = ({ node, hasGroup = false }) => {
    const dispatch = useDispatch();
    const procedureStyles = makeProcedureStyles();
    return (
        <Box
            alignItems="center"
            display="flex"
            py="7px"
            px="0"
            width={'20%'}
            overflow="hidden"
        >
            <div style={{ display: 'flex' }}>
                <Tooltip title="Edit">
                    <Box>
                        <MdEdit
                            className={`react-icon ${procedureStyles.iconContainer}`}
                            onClick={() => {
                                dispatch(
                                    setFieldPreview({
                                        previewAction: 'edit',
                                        field: {
                                            selectedField: node,
                                            has_group: hasGroup,
                                        },
                                    })
                                );
                            }}
                        />
                    </Box>
                </Tooltip>
                <Tooltip title="Delete">
                    <Box>
                        <MdDelete
                            className="react-icon"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                dispatch(
                                    setFieldPreview({
                                        previewAction: 'delete',
                                        field: node.id,
                                    })
                                );
                            }}
                        />
                    </Box>
                </Tooltip>
                <Tooltip title="Duplicate">
                    <Box>
                        <MdContentCopy
                            className="react-icon"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                dispatch(
                                    setFieldPreview({
                                        previewAction: 'duplicate',
                                        field: node,
                                    })
                                );
                            }}
                        />
                    </Box>
                </Tooltip>
            </div>
        </Box>
    );
};

const FieldDataRow = ({ node, provided, hasGroup = false, selectedField }) => {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const nodeTableStyles = makeNodeTableStyles(theme);
    return (
        <div
            style={{
                width: 'calc(100% - 18px)',
                border: `${
                    node.id === selectedField ? '1px solid black' : 'none'
                }`,
                backgroundColor: `${
                    node.id === selectedField ? 'lightblue' : 'inherit'
                }`,
            }}
        >
            <StyledTableRow gap="5px" tabIndex={-1} key={node.id}>
                <Box
                    display="flex"
                    style={{
                        borderLeft: '1px solid #ccc',
                        padding: '7px 0',
                        width: '7%',
                    }}
                    {...provided.dragHandleProps}
                >
                    <MdDragHandle className="react-icon" />
                </Box>
                <ActionCell node={node} hasGroup={hasGroup} />
                <NodeContentCell node={node} />
                <Box
                    alignItems="center"
                    display="flex"
                    align="left"
                    px="0"
                    py="7px"
                    width={'7%'}
                    overflow="hidden"
                >
                    <Tooltip title="Add Field Below">
                        <IconButton
                            className={nodeTableStyles.addButtonIcon}
                            onClick={() => {
                                dispatch(
                                    setFieldPreview({
                                        previewAction: 'add',
                                        field: node,
                                    })
                                );
                            }}
                            size="small"
                        >
                            <MdAdd className="react-icon" />
                        </IconButton>
                    </Tooltip>
                </Box>
            </StyledTableRow>
        </div>
    );
};

const treeToFields = (tree) => {
    var nodeFields = [];

    var treeRootChildren = tree.items[tree.rootId].children;

    treeRootChildren?.forEach((itemId) => {
        var treeItem = tree.items[itemId];
        if (
            (treeItem.data.type === 'group' ||
                treeItem.data.type === 'table') &&
            treeItem?.children?.length > 0
        ) {
            treeItem.data = { ...treeItem.data, fields: [] };
            treeItem?.children?.forEach((itemId) => {
                var childTreeItem = tree.items[itemId];
                treeItem.data.fields[treeItem.data.fields.length] =
                    childTreeItem.data;
            });
        }
        nodeFields.push(treeItem.data);
    });

    return nodeFields;
};

export function FormNodeFieldsEditor({
    nodes,
    node,
    procedureNodes,
    setFieldValue,
    openEditFieldForm,
    setOpenEditFieldForm,
    isValidVariableName,
    checkUniqueVariableName,
    selectedField = null,
}) {
    const tree = fieldsToTree(node.fields);
    const dispatch = useDispatch();
    const [treeState, setTreeState] = useState({ tree: tree });
    const DEFAULT_FIELD_STATE = {
        id: uuidv4(),
        display_name: '',
        action: 'create',
        has_group: false,
    };
    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
        useState(false);
    const [selectedDeleteField, setSelectedDeleteField] = useState(null);
    const [openDuplicateConfirmDialog, setOpenDuplicateConfirmDialog] =
        useState(false);
    const [selectedDuplicateField, setSelectedDuplicateField] = useState(null);
    const [sortedNodes, setSortedNodes] = useState(nodes);
    const [activeField, setActiveField] = useState(DEFAULT_FIELD_STATE);
    const [unsavedVariables, setUnsavedVariables] = useState([]);
    const duplicateVariables = useRef([]);
    const fieldPreviewAction = useSelector(getPreviewField);

    useEffect(() => {
        setSortedNodes(nodes);
        setTreeState({ tree: fieldsToTree(node.fields) });
        return () => {
            resetFieldPreview();
        };
    }, [nodes, node]);

    const handleNodeClick = (id, hasGroup) => {
        setActiveField({ ...id, action: 'edit', has_group: hasGroup });
        setOpenEditFieldForm(true);
    };

    const handleAddNodeClick = (node) => {
        let rootNodeIds = Array.isArray(nodes) ? nodes.map((nd) => nd.id) : [];
        setActiveField({
            ...DEFAULT_FIELD_STATE,
            id: uuidv4(),
            add_node_at: { id: node?.id, type: node.type },
            has_group:
                rootNodeIds.includes(node?.id) &&
                !['group', 'table'].includes(node.type)
                    ? false
                    : true,
        });
        setOpenEditFieldForm(true);
    };

    const showDuplicateThemeDialog = (fieldId) => {
        setSelectedDuplicateField(fieldId);
        setOpenDuplicateConfirmDialog(true);
    };
    const resetFieldPreview = () => {
        dispatch(
            setFieldPreview({
                previewAction: null,
                field: null,
            })
        );
    };

    const closeDuplicateConfirmDialog = () => {
        setSelectedDuplicateField(null);
        setOpenDuplicateConfirmDialog(false);
        resetFieldPreview();
    };

    const showDeleteThemeDialog = (fieldId) => {
        setSelectedDeleteField(fieldId);
        setOpenDeleteConfirmDialog(true);
    };

    useEffect(() => {
        const displayPreviewPopup = () => {
            switch (fieldPreviewAction.previewAction) {
                case 'add':
                    handleAddNodeClick(fieldPreviewAction.previewField);
                    break;
                case 'edit':
                    handleNodeClick(
                        fieldPreviewAction.previewField?.selectedField,
                        fieldPreviewAction.previewField?.has_group
                    );
                    break;
                case 'delete':
                    showDeleteThemeDialog(fieldPreviewAction.previewField);
                    break;
                case 'duplicate':
                    showDuplicateThemeDialog(fieldPreviewAction.previewField);
                    break;
                default:
                    break;
            }
        };
        if (fieldPreviewAction.previewAction) {
            displayPreviewPopup();
        }
    }, [fieldPreviewAction]);

    const closeDeleteConfirmDialog = () => {
        setSelectedDeleteField(null);
        setOpenDeleteConfirmDialog(false);
        resetFieldPreview();
    };

    const formEditorStyles = makeFormEditorStyles();

    function getIcon(item, onExpand, onCollapse) {
        if (item.children && item.children.length > 0) {
            return item.isExpanded ? (
                <div
                    onClick={() => onCollapse(item.id)}
                    className={formEditorStyles.icon}
                >
                    <BiChevronDown />
                </div>
            ) : (
                <div
                    onClick={() => onExpand(item.id)}
                    className={formEditorStyles.icon}
                >
                    <BiChevronRight />
                </div>
            );
        }
        return <div className={formEditorStyles.icon} />;
    }

    const renderItem = ({ item, onExpand, onCollapse, provided }) => {
        return (
            <div
                className={formEditorStyles.row}
                ref={provided.innerRef}
                {...provided.draggableProps}
            >
                <span>{getIcon(item, onExpand, onCollapse)}</span>
                <FieldDataRow
                    key={item.id}
                    node={item.data}
                    provided={provided}
                    selectedField={selectedField}
                />
            </div>
        );
    };

    const onExpand = (itemId) => {
        setTreeState({
            tree: mutateTree(treeState.tree, itemId, { isExpanded: true }),
        });
    };

    const onCollapse = (itemId) => {
        setTreeState({
            tree: mutateTree(treeState.tree, itemId, { isExpanded: false }),
        });
    };

    const orderDraggableField = (
        sortedNodes,
        draggableId,
        sourceIndex,
        destinationIndex,
        sourceDroppableId,
        destinationDroppableId
    ) => {
        let draggableObject = null;
        let isSourceBeforeDestination = null;
        let isDrop = false;
        if (
            isSourceBeforeDestination === null &&
            ((sourceDroppableId !== 'root' &&
                destinationDroppableId !== 'root' &&
                sourceDroppableId === destinationDroppableId) ||
                (sourceDroppableId === 'root' &&
                    destinationDroppableId === 'root'))
        ) {
            if (sourceIndex < destinationIndex) {
                isSourceBeforeDestination = true;
            } else {
                isSourceBeforeDestination = false;
            }
        }
        sortedNodes.map((i, index) => {
            if (
                isSourceBeforeDestination === null &&
                ((destinationDroppableId === 'root' &&
                    index === destinationIndex) ||
                    (destinationDroppableId !== 'root' &&
                        destinationDroppableId === i.id))
            ) {
                isSourceBeforeDestination = false;
            }
            if (
                isSourceBeforeDestination === null &&
                ((sourceDroppableId === 'root' && index === sourceIndex) ||
                    (sourceDroppableId !== 'root' &&
                        sourceDroppableId === i.id))
            ) {
                isSourceBeforeDestination = true;
            }

            if (draggableObject === null) {
                if (i.id === draggableId) {
                    draggableObject = i;
                } else if (
                    (i.type === 'group' || i.type === 'table') &&
                    i?.fields &&
                    i?.fields?.length > 0
                ) {
                    i.fields.map((j) => {
                        if (
                            isSourceBeforeDestination === null &&
                            destinationDroppableId !== 'root' &&
                            destinationDroppableId === j.id
                        ) {
                            isSourceBeforeDestination = false;
                        }
                        if (
                            isSourceBeforeDestination === null &&
                            sourceDroppableId !== 'root' &&
                            sourceDroppableId === j.id
                        ) {
                            isSourceBeforeDestination = true;
                        }
                        if (draggableObject === null) {
                            if (j.id === draggableId) {
                                draggableObject = j;
                            }
                        }
                        return null;
                    });
                }
            }
            return null;
        });
        if (draggableObject === null) {
            return sortedNodes;
        }
        if (
            (draggableObject.type === 'group' ||
                draggableObject.type === 'table') &&
            destinationDroppableId !== 'root'
        ) {
            return sortedNodes;
        }

        let fieldsOrder = [];
        if (sourceDroppableId === 'root' && destinationDroppableId === 'root') {
            console.log('default-default::>>');
            sortedNodes.map((i, index) => {
                if (sourceIndex === index) {
                    return null;
                } else if (destinationIndex === index) {
                    if (isSourceBeforeDestination) {
                        fieldsOrder = [...fieldsOrder, i, draggableObject];
                    } else {
                        fieldsOrder = [...fieldsOrder, draggableObject, i];
                    }
                    isDrop = true;
                } else {
                    fieldsOrder = [...fieldsOrder, i];
                }
            });
        } else if (
            sourceDroppableId !== 'root' &&
            destinationDroppableId !== 'root'
        ) {
            if (sourceDroppableId === destinationDroppableId) {
                console.log('sub-sub::>>');
                sortedNodes.map((i, idx) => {
                    if (i.id === sourceDroppableId) {
                        let subFields = [];
                        if (
                            !Array.isArray(i.fields) ||
                            i.fields.length === 0 ||
                            destinationIndex === 0
                        ) {
                            subFields = [draggableObject];
                            isDrop = true;
                        }
                        i.fields.map((j, index) => {
                            if (sourceIndex === index) {
                                return null;
                            } else if (destinationIndex === index && !isDrop) {
                                if (isSourceBeforeDestination) {
                                    subFields = [
                                        ...subFields,
                                        j,
                                        draggableObject,
                                    ];
                                } else {
                                    subFields = [
                                        ...subFields,
                                        draggableObject,
                                        j,
                                    ];
                                }
                                isDrop = true;
                            } else {
                                subFields = [...subFields, j];
                            }
                        });
                        if (!isDrop) {
                            subFields = [...subFields, draggableObject];
                            isDrop = true;
                        }
                        fieldsOrder = [
                            ...fieldsOrder,
                            { ...i, fields: subFields },
                        ];
                    } else {
                        fieldsOrder = [...fieldsOrder, i];
                    }
                    return null;
                });
            } else {
                console.log('sub1-sub2::>>');
                sortedNodes.map((i) => {
                    let subFields = [];
                    if (i.id === sourceDroppableId) {
                        i.fields.map((j, index) => {
                            if (sourceIndex !== index) {
                                subFields = [...subFields, j];
                            }
                            return null;
                        });
                        fieldsOrder = [
                            ...fieldsOrder,
                            { ...i, fields: subFields },
                        ];
                    } else if (i.id === destinationDroppableId) {
                        if (i?.fields && i?.fields?.length > 0) {
                            i.fields.map((k, index) => {
                                if (index === destinationIndex) {
                                    if (isSourceBeforeDestination) {
                                        subFields = [
                                            ...subFields,
                                            k,
                                            draggableObject,
                                        ];
                                    } else {
                                        subFields = [
                                            ...subFields,
                                            draggableObject,
                                            k,
                                        ];
                                    }
                                    isDrop = true;
                                } else {
                                    subFields = [...subFields, k];
                                }
                                return null;
                            });
                            if (!isDrop) {
                                subFields = [...subFields, draggableObject];
                            }
                            fieldsOrder = [
                                ...fieldsOrder,
                                { ...i, fields: subFields },
                            ];
                        } else {
                            fieldsOrder = [
                                ...fieldsOrder,
                                { ...i, fields: [draggableObject] },
                            ];
                        }
                    } else {
                        fieldsOrder = [...fieldsOrder, i];
                    }
                    return null;
                });
            }
        } else if (
            sourceDroppableId === 'root' &&
            destinationDroppableId !== 'root'
        ) {
            console.log('default-sub::>>');
            sortedNodes.map((i, idx) => {
                let subFields = [];
                if (i.id === destinationDroppableId) {
                    if (i?.fields && i?.fields?.length > 0) {
                        i.fields.map((k, index) => {
                            if (index === destinationIndex) {
                                if (destinationIndex === 0) {
                                    subFields = [draggableObject, k];
                                } else if (isSourceBeforeDestination) {
                                    subFields = [
                                        ...subFields,
                                        k,
                                        draggableObject,
                                    ];
                                } else {
                                    subFields = [
                                        ...subFields,
                                        draggableObject,
                                        k,
                                    ];
                                }
                                isDrop = true;
                            } else {
                                subFields = [...subFields, k];
                            }
                            return null;
                        });
                        if (!isDrop) {
                            subFields = [...subFields, draggableObject];
                            isDrop = true;
                        }
                        fieldsOrder = [
                            ...fieldsOrder,
                            { ...i, fields: subFields },
                        ];
                    } else {
                        fieldsOrder = [
                            ...fieldsOrder,
                            { ...i, fields: [draggableObject] },
                        ];
                    }
                } else if (idx === sourceIndex) {
                    fieldsOrder = [...fieldsOrder];
                } else {
                    if (!isDrop || (isDrop && draggableId !== i.id)) {
                        fieldsOrder = [...fieldsOrder, i];
                    }
                }
                return null;
            });
        } else if (
            destinationDroppableId === 'root' &&
            sourceDroppableId !== 'root'
        ) {
            console.log('sub-default::>>');
            sortedNodes.map((i, index) => {
                let subFields = [];
                if (index === destinationIndex) {
                    if (i.id === sourceDroppableId) {
                        i.fields.map((j, k) => {
                            if (k !== sourceIndex) {
                                subFields = [...subFields, j];
                            }
                            return null;
                        });

                        if (isSourceBeforeDestination) {
                            fieldsOrder = [
                                ...fieldsOrder,
                                { ...i, fields: subFields },
                                draggableObject,
                            ];
                        } else {
                            fieldsOrder = [
                                ...fieldsOrder,
                                draggableObject,
                                { ...i, fields: subFields },
                            ];
                        }
                    } else {
                        fieldsOrder = [...fieldsOrder, draggableObject, i];
                    }
                    isDrop = true;
                } else if (i.id === sourceDroppableId) {
                    i.fields.map((j, k) => {
                        if (k !== sourceIndex) {
                            subFields = [...subFields, j];
                        }
                        return null;
                    });
                    fieldsOrder = [...fieldsOrder, { ...i, fields: subFields }];
                } else {
                    fieldsOrder = [...fieldsOrder, i];
                }
                return null;
            });
            if (!isDrop) {
                fieldsOrder = [...fieldsOrder, draggableObject];
            }
        }
        return fieldsOrder;
    };

    const onDragEnd = async (source, destination) => {
        if (
            !destination ||
            (source.parentId === destination.parentId &&
                source.index === destination.index)
        ) {
            return;
        }
        let currentFields = treeToFields(treeState.tree);
        if (
            destination.parentId !== 'root' &&
            treeState.tree.items[destination.parentId]?.data?.type !==
                'group' &&
            treeState.tree.items[destination.parentId]?.data?.type !== 'table'
        ) {
            return;
        }
        const orderedFields = await orderDraggableField(
            currentFields,
            treeState.tree.items[source.parentId].children[source.index],
            source.index,
            destination?.index ? destination.index : 0,
            source.parentId,
            destination.parentId
        );
        if (
            currentFields !== orderedFields ||
            (source.parentId === destination.parentId &&
                source.index === (destination?.index ? destination.index : 0))
        ) {
            moveItemOnTree(treeState.tree, source, destination);
            setTreeState({
                tree: fieldsToTree(orderedFields),
            });
            setFieldValue('fields', orderedFields);
        }
    };

    return (
        <>
            <Paper style={{ flex: '1', overflow: 'auto', boxShadow: 'none' }}>
                <div className={formEditorStyles.container} tabIndex="1">
                    <Tree
                        tree={treeState.tree}
                        renderItem={renderItem}
                        onExpand={onExpand}
                        onCollapse={onCollapse}
                        onDragEnd={onDragEnd}
                        onDragStart={(item) => onCollapse(item)}
                        offsetPerLevel={12}
                        isDragEnabled={true}
                        isNestingEnabled={true}
                    />
                </div>
            </Paper>
            {openEditFieldForm && (
                <FieldEditDialog
                    openEditFieldForm={openEditFieldForm}
                    setOpenEditFieldForm={setOpenEditFieldForm}
                    activeField={activeField}
                    setActiveField={setActiveField}
                    sortedNodes={sortedNodes}
                    setSortedNodes={setSortedNodes}
                    unsavedVariables={unsavedVariables}
                    setUnsavedVariables={setUnsavedVariables}
                    node={node}
                    procedureNodes={procedureNodes}
                    isValidVariableName={isValidVariableName}
                    checkUniqueVariableName={checkUniqueVariableName}
                    setTreeState={setTreeState}
                />
            )}
            {openDeleteConfirmDialog && (
                <FieldDeleteDialog
                    openDeleteConfirmDialog={openDeleteConfirmDialog}
                    closeDeleteConfirmDialog={closeDeleteConfirmDialog}
                    selectedDeleteField={selectedDeleteField}
                    treeState={treeState}
                    setSortedNodes={setSortedNodes}
                    setTreeState={setTreeState}
                />
            )}
            {openDuplicateConfirmDialog && (
                <FieldDuplicateDialog
                    openDuplicateConfirmDialog={openDuplicateConfirmDialog}
                    closeDuplicateConfirmDialog={closeDuplicateConfirmDialog}
                    unsavedVariables={unsavedVariables}
                    setUnsavedVariables={setUnsavedVariables}
                    nodes={nodes}
                    duplicateVariables={duplicateVariables}
                    procedureNodes={procedureNodes}
                    setSortedNodes={setSortedNodes}
                    selectedDuplicateField={selectedDuplicateField}
                    setOpenEditFieldForm={setOpenEditFieldForm}
                    setActiveField={setActiveField}
                    setTreeState={setTreeState}
                />
            )}
        </>
    );
}
