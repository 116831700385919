import { AudioTab } from 'components/_Layout/LinkPanel/LinkTabs/AudioTab';
import { DocumentTab } from 'components/_Layout/LinkPanel/LinkTabs/DocumentTab';
import { CustomDataTab } from 'components/_Layout/LinkPanel/LinkTabs/CustomDataTab';
import { ImageTab } from 'components/_Layout/LinkPanel/LinkTabs/ImageTab';
import { LearningTab } from 'components/_Layout/LinkPanel/LinkTabs/LearningTab';
import { SchooxTab } from 'components/_Layout/LinkPanel/LinkTabs/SchooxTab';
import { SmeTab } from 'components/_Layout/LinkPanel/LinkTabs/SmeTab';
import { SystemTab } from 'components/_Layout/LinkPanel/LinkTabs/SystemTab';
import { VideoTab } from 'components/_Layout/LinkPanel/LinkTabs/VideoTab';
import { EmbedTab } from 'components/_Layout/LinkPanel/LinkTabs/EmbedTab';
import { ProcedureTab } from 'components/_Layout/LinkPanel/LinkTabs/ProcedureTab';
import { ModelTab } from 'components/_Layout/LinkPanel/LinkTabs/ModelTab';
import { TechUTab } from 'components/_Layout/LinkPanel/LinkTabs/TechUTab';
import {
    MdAccountTree,
    MdContentPaste,
    MdDomainVerification,
    MdEmail,
    MdLibraryBooks,
    MdMemory,
    MdNavigation,
    MdVideoFile,
} from 'react-icons/md';
import video from 'assets/video.png';
import audio from 'assets/audio.png';
import document from 'assets/document.png';
import image from 'assets/image.png';
import schoox from 'assets/schoox.png';
import model from 'assets/model.png';
import { Box } from '@mui/material';

export const NodeTypeIcons = {
    email: {
        title: 'Email',
        icon: (
            <Box>
                <MdEmail className="react-icon" width="20px" height="20px" />
            </Box>
        ),
    },
    lrs: {
        title: 'LRS',
        icon: (
            <Box>
                <MdLibraryBooks
                    className="react-icon"
                    width="20px"
                    height="20px"
                />
            </Box>
        ),
    },
    // form: {
    //     title: 'Form',
    //     icon: <BallotIcon width="20px" height="20px" />,
    // },
    content: {
        title: 'Content',
        icon: (
            <Box>
                <MdContentPaste
                    className="react-icon"
                    width="20px"
                    height="20px"
                />
            </Box>
        ),
    },
    branch: {
        title: 'Branch',
        icon: (
            <Box>
                <MdAccountTree
                    className="react-icon"
                    width="20px"
                    height="20px"
                />
            </Box>
        ),
    },
    contentViewAction: {
        title: 'Content View Action',
        icon: (
            <Box>
                <MdVideoFile
                    className="react-icon"
                    width="20px"
                    height="20px"
                />
            </Box>
        ),
    },
    navigationAction: {
        title: 'Navigation',
        icon: (
            <Box>
                <MdNavigation
                    className="react-icon"
                    width="20px"
                    height="20px"
                />
            </Box>
        ),
    },
    results: {
        title: 'Result',
        icon: (
            <Box>
                <MdDomainVerification
                    className="react-icon"
                    width="20px"
                    height="20px"
                />
            </Box>
        ),
    },
    logic: {
        title: 'Logic',
        icon: (
            <Box>
                <MdMemory className="react-icon" width="20px" height="20px" />
            </Box>
        ),
    },
};
export const ContentTypes = {
    Pdf: {
        id: 1,
        label: 'Pdf',
        extension: '.pdf',
        img: document,
    },
    Doc: {
        id: 2,
        label: 'Doc',
        extension: '.doc',
        img: document,
    },
    Png: {
        id: 3,
        label: 'Png',
        extension: '.png',
        img: image,
    },
    Jpg: {
        id: 4,
        label: 'Jpg',
        extension: '.jpg',
        img: image,
    },
    Jpeg: {
        id: 5,
        label: 'Jpeg',
        extension: '.jpeg',
        img: image,
    },
    Mp3: {
        id: 6,
        label: 'Mp3',
        extension: '.mp3',
        img: audio,
    },
    Mp4: {
        id: 7,
        label: 'Mp4',
        extension: '.mp4',
        img: video,
    },
    Hls: {
        id: 30,
        label: 'HLS',
        extension: '.m3u8',
        img: video,
    },
    Webm: {
        id: 22,
        label: 'Webm',
        extension: '.webm',
        img: video,
    },
    Mov: {
        id: 23,
        label: 'Mov',
        extension: '.mov',
        img: video,
    },
    Flv: {
        id: 24,
        label: 'Flv',
        extension: '.flv',
        img: video,
    },
    Avi: {
        id: 25,
        label: 'Avi',
        extension: '.avi',
        img: video,
    },
    Txt: {
        id: 8,
        label: 'Txt',
        extension: '.txt',
        img: document,
    },
    Pages: {
        id: 9,
        label: 'Pages',
        extension: '.pages',
        img: document,
    },
    Csv: {
        id: 10,
        label: 'Csv',
        extension: '.csv',
        img: document,
    },
    Json: {
        id: 11,
        label: 'Json',
        extension: '.json',
        img: document,
    },
    Schoox: {
        id: 12,
        label: 'Schoox',
        img: schoox,
    },
    Sme: {
        id: 13,
        label: 'Sme',
        img: document,
    },
    Svg: {
        id: 14,
        label: 'Svg',
        extension: '.svg',
        img: image,
    },
    Embed: {
        id: 15,
        label: 'Embed',
        img: document,
    },
    Procedure: {
        id: 16,
        label: 'Procedure',
        img: document,
    },
    Glb: {
        id: 17,
        label: 'GLB',
        extension: '.glb',
        img: model,
    },
    Fxb: {
        id: 18,
        label: 'FXB',
        extension: '.fxb',
        img: model,
    },
    Stl: {
        id: 19,
        label: 'STL',
        extension: '.stl',
        img: model,
    },
    Obj: {
        id: 20,
        label: 'OBJ',
        extension: '.obj',
        img: model,
    },
    Html: {
        id: 21,
        label: 'HTML',
        extension: '.html',
        img: document,
    },
    Ppt: {
        id: 28,
        label: 'PPT',
        extension: '.ppt',
        img: document,
    },
    Pptx: {
        id: 29,
        label: 'PPTX',
        extension: '.pptx',
        img: document,
    },
};

export const LinkTypes = {
    Document: {
        id: 1,
        label: 'Document',
        contentTypes: [
            ContentTypes.Csv,
            ContentTypes.Pdf,
            ContentTypes.Doc,
            ContentTypes.Pages,
            ContentTypes.Txt,
            ContentTypes.Ppt,
            ContentTypes.Pptx,
        ],
        fileSizeLimit: 31457280,
        tabComponent: <DocumentTab />,
    },
    Video: {
        id: 2,
        label: 'Video',
        contentTypes: [
            ContentTypes.Mp4,
            ContentTypes.Webm,
            ContentTypes.Mov,
            ContentTypes.Flv,
            ContentTypes.Avi,
            ContentTypes.Hls,
        ],
        fileSizeLimit: 501000000,
        tabComponent: <VideoTab />,
    },
    System: {
        id: 3,
        label: 'System',
        contentTypes: [
            ContentTypes.Csv,
            ContentTypes.Pdf,
            ContentTypes.Doc,
            ContentTypes.Pages,
            ContentTypes.Txt,
        ],
        fileSizeLimit: 31457280,
        tabComponent: <SystemTab />,
    },
    Audio: {
        id: 4,
        label: 'Audio',
        contentTypes: [ContentTypes.Mp3],
        fileSizeLimit: 52428800,
        tabComponent: <AudioTab />,
    },
    Learning: {
        id: 5,
        label: 'Learning',
        contentTypes: [
            ContentTypes.Csv,
            ContentTypes.Pdf,
            ContentTypes.Doc,
            ContentTypes.Pages,
            ContentTypes.Txt,
            ContentTypes.Html,
        ],
        fileSizeLimit: 31457280,
        tabComponent: <LearningTab />,
    },
    SubjectMatterExpert: {
        id: 6,
        label: 'Subject Matter Expert',
        contentTypes: [ContentTypes.Json, ContentTypes.Sme],
        fileSizeLimit: 31457280,
        tabComponent: <SmeTab />,
    },
    Image: {
        id: 7,
        label: 'Image',
        contentTypes: [
            ContentTypes.Jpeg,
            ContentTypes.Jpg,
            ContentTypes.Png,
            ContentTypes.Svg,
        ],
        fileSizeLimit: 8000000,
        tabComponent: <ImageTab />,
    },
    CustomData: {
        id: 8,
        label: 'Custom Data',
        contentTypes: [ContentTypes.Json],
        fileSizeLimit: 31457280,
        tabComponent: <CustomDataTab />,
    },
    Schoox: {
        id: 9,
        label: 'Schoox',
        contentTypes: [ContentTypes.Schoox],
        tabComponent: <SchooxTab />,
    },
    Embed: {
        id: 10,
        label: 'Embed',
        contentTypes: [ContentTypes.Embed],
        tabComponent: <EmbedTab />,
    },
    Procedure: {
        id: 11,
        label: 'Procedure',
        contentTypes: [ContentTypes.Procedure],
        tabComponent: <ProcedureTab />,
    },
    Model: {
        id: 12,
        label: 'Model',
        fileSizeLimit: 104715200,
        contentTypes: [
            ContentTypes.Glb,
            ContentTypes.Fxb,
            ContentTypes.Stl,
            ContentTypes.Obj,
        ],
        tabComponent: <ModelTab />,
    },
    TechU: {
        id: 13,
        label: 'TechU',
        contentTypes: [
            ContentTypes.Csv,
            ContentTypes.Pdf,
            ContentTypes.Doc,
            ContentTypes.Pages,
            ContentTypes.Txt,
            ContentTypes.Html,
            ContentTypes.Ppt,
            ContentTypes.Pptx,
        ],
        tabComponent: <TechUTab />,
    },
};

export const AssetExportJobStatus = {
    Errored: {
        id: -99,
        label: 'Errored',
    },
    Cancelled: {
        id: -1,
        label: 'Cancelled',
    },
    Created: {
        id: 0,
        label: 'Created',
    },
    Queued: {
        id: 1,
        label: 'Queued',
    },
    PlayerCompiling: {
        id: 2,
        label: 'PlayerCompiling',
    },
    SelfContaining: {
        id: 3,
        label: 'SelfContaining',
    },
    PreparingScorm: {
        id: 4,
        label: 'PreparingScorm',
    },
    Complete: {
        id: 5,
        label: 'Complete',
    },
};

export const DeploymentPackageStatus = {
    Created: {
        id: 0,
        label: 'Created',
    },
    Queued: {
        id: 1,
        label: 'Queued',
    },
    Complete: {
        id: 2,
        label: 'Complete',
    },
};

export const HotspotTypes = [
    { value: 1, name: 'Item' },
    { value: 2, name: 'Navigation' },
    { value: 3, name: 'Highlight' },
];

export const OutputFields = [
    {
        value: 'content_id',
        name: 'Content Id',
    },
    {
        value: 'content_name',
        name: 'Content Name',
    },
    {
        value: 'link_name',
        name: 'Link Name',
    },
    {
        value: 'item_name',
        name: 'Item Name',
    },
    {
        value: 'zone_name',
        name: 'Zone Name',
    },
    {
        value: 'asset_name',
        name: 'Asset Name',
    },
    {
        value: 'url',
        name: 'URL',
    },
    {
        value: 'size',
        name: 'Size',
    },
    {
        value: 'width',
        name: 'Width',
    },
    {
        value: 'height',
        name: 'Height',
    },
    {
        value: 'duration',
        name: 'Duration',
    },
    {
        value: 'origin',
        name: 'Origin',
    },
];

export const AssetOutputFields = [
    {
        value: 'zone_id',
        name: 'Zone Id',
    },
    {
        value: 'zone_name',
        name: 'Zone Name',
    },
    {
        value: 'child_asset_id',
        name: 'Child Asset Id',
    },
    {
        value: 'child_asset_name',
        name: 'Child Asset Name',
    },
    {
        value: 'item_id',
        name: 'Item Id',
    },
    {
        value: 'item_name',
        name: 'Item Name',
    },
    {
        value: 'item_external_id',
        name: 'Item External Id',
    },
    {
        value: 'navlink_id',
        name: 'NavLink Id',
    },
    {
        value: 'navlink_target_id',
        name: 'NavLink Target Id',
    },
    {
        value: 'navlink_target_name',
        name: 'NavLink Target Name',
    },
    {
        value: 'link_id',
        name: 'Link Id',
    },
    {
        value: 'link_type',
        name: 'Link Type',
    },
    {
        value: 'link_name',
        name: 'Link Name',
    },
    {
        value: 'link_url',
        name: 'Link URL',
    },
    {
        value: 'zone_tags',
        name: 'Zone Tags',
    },
    {
        value: 'item_tags',
        name: 'Item Tags',
    },
];
