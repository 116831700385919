import { config } from 'gemini-config';
import { getNetworkService } from 'GeminiViewerComponent/_helpers';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/Snippet`;

const getAll = async () => {
    try {
        const response = await networkService.get(`${baseUrl}`);
        const snippets = response.data;
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, snippets: snippets };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getById = async (id) => {
    try {
        const response = await networkService.get(`${baseUrl}/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _create = async (params) => {
    const updatedParams = {
        display_name: params.display_name,
        snippet_folder_id: params.snippet_folder_id,
        trigger_key: params.trigger_key,
        source: params.source,
    };

    try {
        const response = await networkService.post(baseUrl, updatedParams);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _duplicate = async (id) => {
    try {
        const response = await networkService.post(
            `${baseUrl}/duplicate/${id}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _update = async (params) => {
    const updatedParams = {
        display_name: params.display_name,
        snippet_folder_id: params.snippet_folder_id,
        trigger_key: params.trigger_key,
        source: params.source,
    };
    try {
        const response = await networkService.put(
            `${baseUrl}/${params?.snippet_id}`,
            updatedParams
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _delete = async (id) => {
    try {
        const response = await networkService.delete(`${baseUrl}/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getAllFolder = async () => {
    try {
        const response = await networkService.get(`${baseUrl}/folder`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getFolderById = async (id) => {
    try {
        const response = await networkService.get(`${baseUrl}/folder/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const createFolder = async (params) => {
    const updatedParams = {
        display_name: params.display_name,
    };
    try {
        const response = await networkService.post(
            `${baseUrl}/folder`,
            updatedParams
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const updateFolder = async (params) => {
    const updatedParams = {
        display_name: params.display_name,
    };
    try {
        const response = await networkService.put(
            `${baseUrl}/folder/${params?.id}`,
            updatedParams
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const deleteFolder = async (id) => {
    try {
        const response = await networkService.delete(`${baseUrl}/folder/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const snippetService = {
    getAll,
    getById,
    create: _create,
    duplicate: _duplicate,
    update: _update,
    delete: _delete,
    getAllFolder,
    getFolderById,
    createFolder,
    updateFolder,
    deleteFolder,
};
