import { makeStyles } from '@mui/styles';

export const buttonStyles = makeStyles(() => ({
    button: {
        margin: '10px',
    },
    badge: {
        right: -3,
        bottom: 13,
        fontSize: '8px',
    },
}));
