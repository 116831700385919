import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toolsService } from '_features/_services';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';

const initialState = {
    tools: [],
    toolsStatus: LoadingStatus.Idle,
    toolsPageInfo: {},
    displayTool: {},
    loadedToolMap: {},
};

export const fetchToolsPage = createAsyncThunk(
    'tool/fetchToolsPage',
    async (params = {}, { getState }) =>
        await toolsService.getTools(
            params.locationIDs,
            params.categoryIDs,
            params.searchString,
            params.sort,
            getState().accounts.activeUser.s3_prefix
        )
);

export const fetchToolById = createAsyncThunk(
    'tool/fetchToolById',
    async (toolId, { getState }) => {
        const response = await toolsService.getById(
            toolId,
            getState().accounts.activeUser.s3_prefix
        );
        response.id = toolId;
        return response;
    }
);

export const createTool = createAsyncThunk(
    'tool/createTool',
    async (params, { getState }) =>
        await toolsService.create(
            params,
            getState().accounts.activeUser.s3_prefix
        )
);

export const updateTool = createAsyncThunk(
    'tool/updateTool',
    async (params, { getState }) =>
        await toolsService.update(
            params,
            getState().accounts.activeUser.s3_prefix
        )
);

export const deleteTool = createAsyncThunk(
    'tool/deleteTool',
    async ({ tool_id }) => {
        const response = await toolsService.delete(tool_id);
        return response;
    }
);

// export const deleteToolArray = createAsyncThunk(
//     'assets/deleteToolArray',
//     async (toolIDs) => {
//         const response = await toolsService.deleteArray(toolIDs);
//         response.ids = toolIDs;
//         return response;
//     }
// );

export const getToolImportSampleCsv = createAsyncThunk(
    'tool/getToolImportSampleCsv',
    async () => await toolsService.getToolImportCsv()
);

export const uploadToolImportCsv = createAsyncThunk(
    'tool/uploadToolImportCsv',
    async (params) => await toolsService.uploadToolImportCsv(params)
);

const toolSlice = createSlice({
    name: 'tools',
    initialState,
    reducers: {
        resetToolsState: (state) => initialState,
    },
    extraReducers: {
        [fetchToolsPage.pending]: (state, action) => {
            state.toolsStatus = LoadingStatus.Loading;
        },
        [fetchToolsPage.fulfilled]: (state, action) => {
            state.toolsPageInfo = action.payload.pageInfo;
            state.tools = action.payload.tools;
            state.toolsStatus = LoadingStatus.Loaded;
        },
        [fetchToolsPage.rejected]: (state, action) => {
            state.toolsStatus = LoadingStatus.Failed;
        },
        [createTool.fulfilled]: (state, action) => {
            state.toolsStatus = LoadingStatus.Idle;
        },
        [updateTool.fulfilled]: (state, action) => {
            state.toolsStatus = LoadingStatus.Idle;
        },
        [deleteTool.fulfilled]: (state, action) => {
            state.toolsStatus = LoadingStatus.Idle;
        },
        // [deleteToolArray.fulfilled]: (state, action) => {
        //     state.toolsStatus = LoadingStatus.Idle;
        // },
        // [deleteToolArray.rejected]: (state, action) => {
        //     state.status = SavingStatus.Failed;
        //     state.error = action.error.message;
        // },
        [fetchToolById.fulfilled]: (state, action) => {
            state.displayTool = action.payload;
        },
        [getToolImportSampleCsv.fulfilled]: (state, action) => {
            if (action?.payload) {
                const url = window.URL.createObjectURL(
                    new Blob([action.payload])
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'tool_import.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        },
    },
});

export const selectTools = (state) => state.tools.tools;
export const getToolsStatus = (state) => state.tools.toolsStatus;
export const getToolsPageInfo = (state) => state.tools.toolsPageInfo;

export const { resetToolsState } = toolSlice.actions;

export default toolSlice.reducer;
