import { makeStyles } from '@mui/styles';

const makeCommonStyles = makeStyles((theme) => ({
    titleContainer: {
        // position: 'absolute',
        // top: '0px',
        // left: '0px',
        textAlign: 'center',
        width: '100%',
        fontSize: '16px',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'visible',
        pointerEvents: 'none',
        backgroundColor: '#8f8f8f',
    },
    titleBar: {
        position: 'absolute',
        top: '40px',
        left: '0px',
        width: '100%',
        height: '40px',
        backgroundColor: '#8f8f8f',
        zIndex: '1',
        '&:empty': {
            display: 'none',
        },
    },
    titleBarContent: {
        display: 'flex',
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
    },
    orbitTitleBar: {
        position: 'relative !important',
        textAlign: 'center',
        width: '100%',
        height: '40px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        flexDirection: 'row',
    },
    sceneName: {
        [theme.breakpoints.down('lg')]: {
            width: '200px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '120px',
        },
        background: 'transparent !important',
        width: 'auto',
        textAlign: 'left',
        lineHeight: '40px',
        overflow: 'hidden',
        verticalAlign: 'middle',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        mozUserSelect: 'text',
        webkitUserSelect: 'text',
        msUserSelect: 'text',
        userSelect: 'text',
        flex: 'none',
        zIndex: '1',
        height: 'max-content',
    },
    orbitControls: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        columnGap: '5px',
        height: '40px',
        marginLeft: 'auto',
        pointerEvents: 'auto',
        zIndex: 1,
        justifyContent: 'end',
        backgroundColor: '#8f8f8f',
        position: 'absolute',
        right: '0',
        padding: '3px',
    },
    orbitControlsButton: {
        backgroundColor: 'white',
        height: '34px',
        width: '34px',
        '& > img': {
            maxWidth: '100%',
            height: 'auto',
        },
    },
    orbitControlsButtonSelected: {
        backgroundColor: '#aaa',
    },
    orbitControlsButtonRotated: {
        transform: 'rotate(90deg)',
    },
    orbitControlsClose: {
        color: 'white',
        cursor: 'pointer',
    },
    showLoadingEffect: {
        '&::before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: 'calc(100% - 40px)',
            background: 'rgba(0,0,0,0.5)',
            zIndex: '1',
            marginTop: '40px',
            transform: 'translateY(0)',
        },
    },
    hideLoadingEffect: {
        // position: 'relative',
        '&::before': {
            content: '""',
            // position: 'absolute',
            width: '100%',
            height: 'calc(100% - 40px)',
            zIndex: '1',
            transform: 'translateY(-100%)',
        },
    },
    loader: {
        position: 'absolute',
    },
    '@keyframes rotate': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' },
    },
    loadingSvg: {
        height: '30px',
        width: '30px',
        zIndex: 2,
    },
    rotateSvg: {
        animationName: '$rotate',
        animationDuration: '1.5s',
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: 'infinite',
    },
    viewWrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    panoViewWrapper: {
        flex: 'auto',
        backgroundColor: (props) => props?.colors?.pano_background_color,
    },
    orbitViewWrapper: {
        backgroundColor: (props) => props?.colors?.orbit_background_color,
    },
    splitViewModes: {
        background: (props) =>
            `${props?.colors?.button?.primaryBackground} !important`,
        position: 'absolute',
        zIndex: 9,
        display: 'flex',
        gap: '5px',
        border: '1px solid',
        padding: '5px',
        borderRadius: '5px',
        right: '10px',
        transform: 'translateY(50px)',
        left: 'inherit',
    },
    appModes: {
        background: (props) =>
            `${props?.colors?.button?.primaryBackground} !important`,
        position: 'absolute',
        zIndex: 9991,
        display: 'flex',
        gap: '5px',
        border: '1px solid',
        padding: '5px',
        borderRadius: '5px',
        left: 0,
        top: '38px',
        transform: 'translateY(0px)',
    },
    companionApps: {
        background: '#ffffff',
        position: 'absolute',
        padding: '5px',
        gap: '5px',
        zIndex: 9,
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid',
        borderRadius: '5px',
        left: 0,
        top: '38px',
        transform: 'translateY(0px)',
    },
    appModeButton: {
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '3px',
        border: '1px solid #ffffff',
    },
    companionIconButton: {
        backgroundColor: 'lightblue',
        fontWeight: '600',
        padding: '5px',
    },
    companionButton: {
        width: 'auto',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '3px',
        gap: '5px',
        border: '1px solid #ffffff',
    },
    companionButtonText: {
        maxWidth: '150px',
        whiteSpace: 'nowrap',
        fontSize: '14px',
        color: '#000000',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    companionAppName: {
        fontSize: '16px',
        fontWeight: '600',
        maxWidth: '250px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

export { makeCommonStyles };
