import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import { useSelector } from 'react-redux';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { getInspectionReasonList } from 'GeminiViewerComponent/_features/inspection/inspectionSlice';
import FieldValidationError from '../FieldValidationError';

const ReasonsDropdown = ({
    data,
    handleBlur,
    isSubmitAllow,
    fieldValue,
    setFieldValue,
    validationError,
    reasonId,
}) => {
    const [reasonsList, setReasonsList] = useState([]);
    const reasonList = useSelector(getInspectionReasonList);
    let variable = data?.variable;
    if (data?.duplicateCount) {
        let lastIndex = data?.variable.lastIndexOf(`-${data?.duplicateCount}`);
        variable = data?.variable.slice(0, lastIndex);
    }
    const reasonValue = data?.duplicateCount
        ? fieldValue[variable]?.[data.duplicateCount]?.['reason']
        : fieldValue[variable]?.[0]?.['reason'] ||
          fieldValue[variable]?.['reason'];

    // const validationMessage = data?.duplicateCount
    //     ? validationError?.[data.duplicateCount]?.['reason']
    //     : validationError?.[0]?.['reason'];

    const validationMessage = validationError?.['reason'];

    useEffect(() => {
        setReasonsList(
            reasonList?.find(
                (reasonData) =>
                    reasonData.inspection_reason_list_id === reasonId
            )
        );
    }, [reasonId]);

    return (
        <FormControl
            sx={{
                width: { xs: '100%', sm: 'calc(20% - 10px)' },
                minWidth: '200px',
            }}
            margin="dense"
            disabled={!isSubmitAllow}
            error={validationError?.reason}
        >
            <Field
                name={
                    data?.duplicateCount
                        ? fieldValue[variable]?.[data.duplicateCount]?.[
                              'reason'
                          ]
                        : fieldValue[variable]?.[0]?.['reason']
                }
            >
                {() => (
                    <Box display="flex" width="100%">
                        <InputLabel id="reason-list-label">Reason</InputLabel>
                        <Select
                            labelId="reason-list-label"
                            id={`Reason-${reasonId}`}
                            label="Reason"
                            value={reasonValue}
                            onChange={(e) => {
                                if (data?.duplicateCount) {
                                    setFieldValue(
                                        `${variable}.${[
                                            data?.duplicateCount,
                                        ]}.reason`,
                                        e.target.value
                                    );
                                } else {
                                    setFieldValue(
                                        `${variable}.${[0]}.reason`,
                                        e.target.value
                                    );
                                }
                            }}
                            style={{
                                width: '100%',
                            }}
                            onBlur={() =>
                                isSubmitAllow && handleBlur(fieldValue)
                            }
                        >
                            {reasonsList?.inspection_reasons?.map((reasons) => (
                                <MenuItem
                                    key={reasons.inspection_reason_id}
                                    value={reasons.display_name}
                                >
                                    {reasons.display_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                )}
            </Field>
            {validationMessage && (
                <FieldValidationError validationError={validationMessage} />
            )}
        </FormControl>
    );
};
export { ReasonsDropdown };
