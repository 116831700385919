import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { alpha } from '@mui/material/styles';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {},
        inputHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        inputHeaderRight: {
            display: 'flex',
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                // marginLeft: theme.spacing(3),
                width: 'auto',
                margin: '0px',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#BDC3C7',
        },
        inputRoot: {
            color: 'inherit',
            border: '1px solid #BDC3C7',
            borderRadius: '5px',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
        formInput: {
            padding: '2px 10px',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            '& .MuiInput-underline:before': {
                borderBottom: 'unset !important',
            },
            '& .MuiInputBase-root': {
                border: '1px solid #BDC3C7',
                borderRadius: '5px',
                padding: '2px 10px',
            },
        },
        buttonContainer: {
            float: 'right',
            marginTop: '10px',
        },
        buttonSpacing: {
            margin: '0px 5px',
        },
    })
);

export default useStyles;
