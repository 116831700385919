import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            light: '#3598DB',
            main: '#297FB9',
            dark: '#2D3D50',
        },
        secondary: {
            light: '#ECF0F1',
            main: '#BDC3C7',
            dark: '#7E8C8D',
        },
        neutral: {
            light: '#ECF0F1',
            main: '#BDC3C7',
            dark: '#7E8C8D',
        },
    },
    typography: {
        fontFamily: 'Open Sans',
    },
});
