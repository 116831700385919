import { useSelector } from 'react-redux';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

export const useTinyMCETextInit = () => {
    const theme = useSelector(selectActiveTheme);

    const contentStyle = `
        body {
            font-family: 'Lato', 'Helvetica', Helvetica;
        }
        button {
            border: 0;
            display: inline-block;
            padding: 12px 24px;
            font-size: 14px;
            border-radius: 4px;
            margin-top: 5px;
            cursor: pointer;
            background-color: ${
                theme.colors.link_button_background_color
                    ? theme.colors.link_button_background_color
                    : '#1064EA'
            };
            color: ${
                theme.colors.link_button_text_color
                    ? theme.colors.link_button_text_color
                    : '#FFFFFF'
            };
        }
        button a {
            color: #FFFFFF;
            cursor: pointer;
            font-size: 14px;
            text-decoration: none;
        }
    `;

    return {
        // height: '100% !important',
        // overflow: 'hidden !important',
        menubar: false,
        // contextmenu: '',
        content_style: contentStyle,
        plugins: ['searchreplace autoresize'],
        help_tabs: [''],
        target_list: [
            {
                title: 'External',
                value: '_blank',
            },
            {
                title: 'Inline',
                value: '_self',
            },
        ],
        // toolbar: false,
        toolbar: 'undo redo | searchreplace',
        paste_data_images: false,
        automatic_uploads: false,
        relative_urls: false,
        convert_urls: false,
        remove_script_host: false,
        branding: false,
        forced_root_block: false,
        toolbar_mode: 'sliding',
    };
};
