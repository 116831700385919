import { config } from 'gemini-config';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import { getNetworkService } from 'GeminiViewerComponent/_helpers';

const baseUrl = `${config.apiUrl}/group`;
const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});

const getAllByClientId = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const response = await networkService.get(
        `${baseUrl}/getallbyclientid/${user.client_id}`
    );
    return response.data;
};

const getAll = async (
    page = 1,
    pageSize = 10,
    searchString = '',
    sort = 'display_name',
    activeOnly = true
) => {
    const user = JSON.parse(localStorage.getItem('user'));
    try {
        const response = await networkService.get(
            `${baseUrl}/getallbyclientid/${
                user.client_id
            }?filters=is_active==${activeOnly.toString()}${
                searchString ? `,display_name@=*${searchString}` : ``
            }&sorts=${sort}&page=${page}&pageSize=${pageSize}`
        );
        const groups = response.data;
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, groups: groups };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getById = async (id) => {
    try {
        const response = await networkService.get(`${baseUrl}/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const fetchGroupUsageById = async (contentId) => {
    try {
        const response = await networkService.get(
            `${baseUrl}/usage/${contentId}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const update = async (id, params) => {
    try {
        const response = await networkService.put(`${baseUrl}/${id}`, params);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const create = async (params) => {
    const { client_id } = JSON.parse(localStorage.getItem('user'));
    params.client_id = client_id;
    try {
        const response = await networkService.post(baseUrl, params);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _delete = async (id) => {
    try {
        const response = await networkService.delete(`${baseUrl}/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const deleteArray = async (ids) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const query = ids.join('&ids=');
    try {
        const response = await networkService.delete(
            `${baseUrl}/?client_id=${user.client_id}&ids=${query}`
        );

        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const groupService = {
    getAllByClientId,
    getAll,
    getById,
    create,
    update,
    delete: _delete,
    fetchGroupUsageById,
    deleteArray,
};
