import { useSelector } from 'react-redux';
import { List, ListItemButton, Typography } from '@mui/material';

import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

const LinkList = ({
    zoneItem,
    linkItem,
    hotspotItem,
    item,
    linkProperty,
    handleZoneClick,
}) => {
    const theme = useSelector(selectActiveTheme);
    return (
        <List component="div" disablePadding>
            <ListItemButton
                sx={{ pl: 6 }}
                onClick={(e) => {
                    e.stopPropagation();
                    handleZoneClick(
                        zoneItem,
                        item?.asset_id,
                        hotspotItem,
                        linkItem
                    );
                }}
            >
                <span style={{ color: theme.colors.text, fontWeight: 'bold' }}>
                    Link:&nbsp;
                </span>
                <Typography
                    title={linkItem[linkProperty]}
                    style={{
                        width: '100%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        margin: '4px 0',
                    }}
                >
                    {linkItem[linkProperty]}
                </Typography>
            </ListItemButton>
        </List>
    );
};

export default LinkList;
