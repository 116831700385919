import React from 'react';
import { useSnackbar } from 'notistack';
import { AutoSizer } from 'react-virtualized';
import { MdClose, MdDownload } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Dialog,
    DialogContent,
    IconButton,
    Typography,
} from '@mui/material';

import { assetStatsModalStyles } from './styles';
import { DataNotFound } from 'components/DataNotFound';
import { labelHeader, labelCell, VirtualTable } from 'components';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    exportAssetStats,
    getAssetStatsPageInfo,
    getAllAssetStats,
    getAssetStatsRange,
    getAssetStatsRowsMap,
    getAssetStatsStatus,
} from '_features/assets/assetManagerSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

export const AssetStatsModal = ({ openModal, setOpenModal }) => {
    //#region Constants
    const rowHeight = 40;
    const headerHeight = 40;
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    //#endregion Constants

    //#region Hooks
    const theme = useSelector(selectActiveTheme);
    const modalStyles = assetStatsModalStyles(theme);
    const tableClasses = tableStyles();
    //#endregion Hooks

    //#region State
    //#endregion State

    //#region Selectors
    const assetStats = useSelector(getAllAssetStats);
    const pageInfo = useSelector(getAssetStatsPageInfo);
    const loadedRowsMap = useSelector(getAssetStatsRowsMap);
    const assetStatsStatus = useSelector(getAssetStatsStatus);
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    //#endregion Effects

    //#region Methods
    const loadMoreRows = ({ startIndex, stopIndex }) => {
        if (assetStatsStatus !== LoadingStatus.Loading) {
            dispatch(
                getAssetStatsRange({
                    startIndex: startIndex,
                    stopIndex: stopIndex,
                })
            );
        }
    };

    const downloadAssetStats = async () => {
        await dispatch(activateLoading());
        const downloadCsv = await dispatch(
            exportAssetStats({
                startIndex: 0,
                stopIndex: assetStats.length,
            })
        );
        if (!downloadCsv.error) {
            enqueueSnackbar('Download CSV successful', {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'success',
            });
        }
        await dispatch(deactivateLoading());
    };
    //#endregion Methods

    //#region Render time calcs
    const columns = [
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 210,
            minWidth: 210,
            label: 'Name',
            dataKey: 'display_name',
            padding: 'normal',
            rowHeight: rowHeight,
            component: labelCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 100,
            minWidth: 100,
            maxWidth: 100,
            label: '# Zones',
            dataKey: 'zone_count',
            padding: 'none',
            rowHeight: rowHeight,
            component: labelCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 100,
            minWidth: 100,
            maxWidth: 100,
            label: '# Items',
            dataKey: 'item_count',
            padding: 'none',
            rowHeight: rowHeight,
            component: labelCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 100,
            minWidth: 100,
            maxWidth: 100,
            label: '# Links',
            dataKey: 'link_count',
            padding: 'none',
            rowHeight: rowHeight,
            component: labelCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 100,
            minWidth: 100,
            maxWidth: 100,
            label: '# Procedures',
            dataKey: 'procedure_count',
            padding: 'none',
            rowHeight: rowHeight,
            component: labelCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 100,
            minWidth: 100,
            maxWidth: 100,
            label: '# Child Assets',
            dataKey: 'child_asset_count',
            padding: 'none',
            rowHeight: rowHeight,
            component: labelCell,
            headerComponent: labelHeader,
        },
    ];
    //#endregion Render time calcs

    //#region Render

    return (
        <Dialog open={openModal} maxWidth="md" fullWidth>
            <Box className={modalStyles.headerWrapper}>
                <IconButton
                    className={modalStyles.headerButtons}
                    disabled={!assetStats?.length > 0}
                    onClick={downloadAssetStats}
                >
                    <MdDownload className="react-icon" />
                </IconButton>
                <Typography className={modalStyles.headerText}>
                    Asset Stats
                </Typography>
                <IconButton
                    className={modalStyles.headerButtons}
                    onClick={() => setOpenModal(false)}
                >
                    <MdClose className="react-icon" />
                </IconButton>
            </Box>
            <DialogContent>
                <div style={{ height: 'calc(100vh - 350px)' }}>
                    {assetStatsStatus !== LoadingStatus.Loading &&
                    !assetStats?.length > 0 ? (
                        <DataNotFound title="Asset Stat" insideModal={true} />
                    ) : (
                        <AutoSizer>
                            {({ width, height }) => (
                                <VirtualTable
                                    initialRows={assetStats}
                                    allRows={assetStats}
                                    totalRowCount={pageInfo.TotalCount}
                                    loadedRowsMap={loadedRowsMap}
                                    loadMoreRows={loadMoreRows}
                                    columnDefinitions={columns}
                                    cellClassName={tableClasses.flexContainer}
                                    className={tableClasses.table}
                                    rowClassName={tableClasses.flexContainer}
                                    headerHeight={headerHeight}
                                    width={width}
                                    height={height}
                                    rowHeight={rowHeight}
                                />
                            )}
                        </AutoSizer>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
    //#endregion Render
};
