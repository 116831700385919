import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { AssetExportTable } from './AssetExportTable';
import {
    fetchAssetExportsPage,
    selectAssetExportLoadingStatus,
    selectAssetExportPageInfo,
    selectAssetExports,
    setAssetExportStatus,
} from '_features/assets/assetExportSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

const AssetExportsTab = () => {
    const dispatch = useDispatch();
    const ref = useRef(false);
    const assetExports = useSelector(selectAssetExports);
    const assetExportStatus = useSelector(selectAssetExportLoadingStatus);
    const assetExportPageInfo = useSelector(selectAssetExportPageInfo);
    const [assetExportPayload, setAssetExportPayload] = useState({});
    const [sortBy, setSortBy] = useState('created_date');
    const [sortDirection, setSortDirection] = useState('DES');

    const getAssetExportData = async (payload) => {
        dispatch(activateLoading());
        await dispatch(fetchAssetExportsPage({ ...payload }));
        dispatch(deactivateLoading());
    };

    useEffect(() => {
        if (ref.current === true) {
            getAssetExportData({ ...assetExportPayload });
        }
    }, [assetExportPayload]);

    useEffect(() => {
        setAssetExportPayload({
            ...assetExportPayload,
            sort: `${sortDirection === 'ASC' ? '' : '-'}${
                sortBy === 'status_name' ? 'status_id' : sortBy
            }`,
        });
        dispatch(setAssetExportStatus(LoadingStatus.Idle));
    }, []);

    useEffect(() => {
        if (assetExportStatus === LoadingStatus.Idle) {
            getAssetExportData({ ...assetExportPayload });
        }
        ref.current = true;
    }, [assetExportStatus]);

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        setAssetExportPayload({
            ...assetExportPayload,
            sort: `${_sortDirection === 'ASC' ? '' : '-'}${
                _sortBy === 'status_name' ? 'status_id' : _sortBy
            }`,
        });
    };

    return (
        <AssetExportTable
            assetExports={assetExports}
            pageInfo={assetExportPageInfo}
            assetExportPayload={assetExportPayload}
            setAssetExportPayload={setAssetExportPayload}
            sortDirection={sortDirection}
            sortBy={sortBy}
            handleSort={(_sortBy, _sortDirection) =>
                setSorting(_sortBy, _sortDirection)
            }
        />
    );
};

export { AssetExportsTab };
