import { useCallback, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    getProcedureFlowConfigs,
    setProcedureFlowConfigs,
} from '_features/procedures/proceduresSlice';
import {
    active_node_actions,
    highlight_visibility,
    node_highlighting,
    orientations,
    smoothness,
} from './FlowSettingsOptions';

const ProcedureFlowSettings = ({ openDialog, setOpenDialog }) => {
    const dispatch = useDispatch();
    const flowConfigs = useSelector(getProcedureFlowConfigs);
    const [currValues, setCurrValues] = useState({
        smoothness: smoothness.Low.id,
        orientation: orientations.TB.id,
        active_action: active_node_actions.Center.id,
        node_highlight: node_highlighting.Incoming.id,
        highlight_visibility: highlight_visibility.Normal.id,
        // expand_branches: false,
    });

    useEffect(() => {
        setCurrValues(flowConfigs);
    }, [flowConfigs]);

    const handleSubmit = useCallback(() => {
        dispatch(setProcedureFlowConfigs(currValues));
        setOpenDialog(false);
    }, [currValues]);

    return (
        openDialog && (
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'sm'}
                fullWidth
            >
                <DialogTitle id="alert-dialog-title" component={'h2'}>
                    Flow Settings
                </DialogTitle>
                <DialogContent style={{ paddingTop: '10px' }}>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            display: 'flex',
                            gap: '10px',
                            flexDirection: 'column',
                        }}
                    >
                        <FormControl fullWidth>
                            <InputLabel id="smoothness-id">
                                Connection smoothness
                            </InputLabel>
                            <Select
                                style={{ margin: 0 }}
                                labelId="smoothness-id"
                                margin="dense"
                                label="Connection smoothness"
                                placeholder="Connection smoothness"
                                value={currValues?.smoothness}
                                onChange={(e) => {
                                    setCurrValues({
                                        ...currValues,
                                        smoothness: e.target.value,
                                    });
                                }}
                            >
                                {Object.keys(smoothness)?.length > 0 &&
                                    Object.keys(smoothness).map((val) => (
                                        <MenuItem
                                            key={`smoothness-${smoothness[val].id}`}
                                            value={smoothness[val].id}
                                        >
                                            {smoothness[val].label}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="orientation-id">
                                Orientation
                            </InputLabel>
                            <Select
                                style={{ margin: 0 }}
                                labelId="orientation-id"
                                margin="dense"
                                label="Orientation"
                                placeholder="Orientation"
                                value={currValues?.orientation}
                                onChange={(e) => {
                                    setCurrValues({
                                        ...currValues,
                                        orientation: e.target.value,
                                    });
                                }}
                            >
                                {Object.keys(orientations)?.length > 0 &&
                                    Object.keys(orientations).map((val) => (
                                        <MenuItem
                                            key={`orientation-${orientations[val].id}`}
                                            value={orientations[val].id}
                                        >
                                            {orientations[val].label}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="active-action-id">
                                Action to take when selecting the active node
                            </InputLabel>
                            <Select
                                style={{ margin: 0 }}
                                labelId="active-action-id"
                                margin="dense"
                                label="Action to take when selecting the active node"
                                placeholder="Action to take when selecting the active node"
                                value={currValues?.active_action}
                                onChange={(e) => {
                                    setCurrValues({
                                        ...currValues,
                                        active_action: e.target.value,
                                    });
                                }}
                            >
                                {Object.keys(active_node_actions)?.length > 0 &&
                                    Object.keys(active_node_actions).map(
                                        (val) => (
                                            <MenuItem
                                                key={`active-action-${active_node_actions[val].id}`}
                                                value={
                                                    active_node_actions[val].id
                                                }
                                            >
                                                {active_node_actions[val].label}
                                            </MenuItem>
                                        )
                                    )}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="node-highlight-id">
                                Highlight nodes and connections related to the
                                active node
                            </InputLabel>
                            <Select
                                style={{ margin: 0 }}
                                labelId="node-highlight-id"
                                margin="dense"
                                label="Highlight nodes and connections related to the active node"
                                placeholder="Highlight nodes and connections related to the active node"
                                value={currValues?.node_highlight}
                                onChange={(e) => {
                                    setCurrValues({
                                        ...currValues,
                                        node_highlight: e.target.value,
                                    });
                                }}
                            >
                                {Object.keys(node_highlighting)?.length > 0 &&
                                    Object.keys(node_highlighting).map(
                                        (val) => (
                                            <MenuItem
                                                key={`node-highlight-${node_highlighting[val].id}`}
                                                value={
                                                    node_highlighting[val].id
                                                }
                                            >
                                                {node_highlighting[val].label}
                                            </MenuItem>
                                        )
                                    )}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="highlight-visibility-id">
                                How to display nodes and connections that are
                                not related to the active node
                            </InputLabel>
                            <Select
                                style={{ margin: 0 }}
                                labelId="highlight-visibility-id"
                                margin="dense"
                                label="How to display nodes and connections that are not related to the active node"
                                placeholder="How to display nodes and connections that are not related to the active node"
                                value={currValues?.highlight_visibility}
                                onChange={(e) => {
                                    setCurrValues({
                                        ...currValues,
                                        highlight_visibility: e.target.value,
                                    });
                                }}
                            >
                                {Object.keys(highlight_visibility)?.length >
                                    0 &&
                                    Object.keys(highlight_visibility).map(
                                        (val) => (
                                            <MenuItem
                                                key={`highlight-visibility-${highlight_visibility[val].id}`}
                                                value={
                                                    highlight_visibility[val].id
                                                }
                                            >
                                                {
                                                    highlight_visibility[val]
                                                        .label
                                                }
                                            </MenuItem>
                                        )
                                    )}
                            </Select>
                        </FormControl>
                        {/* <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(e) =>
                                        setCurrValues({
                                            ...currValues,
                                            expand_branches: e.target.checked,
                                        })
                                    }
                                    checked={currValues?.expand_branches}
                                    color="primary"
                                />
                            }
                            label={'Expand Branches'}
                        /> */}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} autoFocus>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        )
    );
};

export { ProcedureFlowSettings };
