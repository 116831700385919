import React from 'react';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import { Field } from 'formik';

const DateTimeField = ({ validationError }) => {
    return (
        <div>
            <Field name="date_time_type">
                {({ field: { value }, form: { setFieldValue } }) => (
                    <FormControl fullWidth error={validationError}>
                        <InputLabel id="label-date-time-type">
                            Date Time Type
                        </InputLabel>
                        <Select
                            labelId="label-date-time-type"
                            id="date_time_type"
                            label="Date Time Type"
                            value={value}
                            onChange={(e) =>
                                setFieldValue('date_time_type', e.target.value)
                            }
                        >
                            <MenuItem key={'date'} value={'date'}>
                                Date Only
                            </MenuItem>
                            <MenuItem key={'time'} value={'time'}>
                                Time Only
                            </MenuItem>
                            <MenuItem key={'datetime'} value={'datetime'}>
                                Date Time
                            </MenuItem>
                        </Select>
                        <FormHelperText>{validationError}</FormHelperText>
                    </FormControl>
                )}
            </Field>
        </div>
    );
};

export default DateTimeField;
