import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    dropzoneText: {
        fontSize: '1em !important',
        margin: '10px !important',
        color: 'white',
        cursor: 'default',
        borderRadius: '5px',
        padding: '5px',
    },
    panoEditor: {
        height: '100%',
        backgroundColor: '#cbcccc',
        borderRadius: '10px',
        padding: '5px',
    },
    zoneListingDiv: {
        height: 'calc(100% - 130px)',
        overflowY: 'auto',
        backgroundColor: '#cbcccc',
        borderRadius: '10px 10px 0 0',
    },
    unitZoneDiv: {
        gap: '5px',
        flexWrap: 'nowrap',
        marginTop: '10px',
        padding: '15px 5px 15px 5px',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        color: 'white',
    },
    zoneNameInput: {
        padding: '3px',
        backgroundColor: '#ffffff',
        borderRadius: '5px',
        color: '#666666',
        width: '100%',
        border: 'none',
        fontWeight: '600',
    },
    morePanoDropzone: {
        padding: '10px',
        border: '1px solid #002a63',
        borderRadius: '5px',
        backgroundColor: '#0009',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        height: '110px',
        width: '100%',
        marginBottom: '10px',
        marginTop: '20px',
    },
    panoDisplayDiv: {
        height: 'calc(100vh - 235px)',
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        padding: '5px',
        border: '2px solid #666666',
    },
    viewerDisplayDiv: {
        width: '100%',
        marginTop: '5px',
        height: 'calc(100% - 30px)',
        position: 'relative',
    },
    panoTextDiv: {
        border: '2px dashed #ffffff',
        borderRadius: '5px',
        height: '100%',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dropZoneDiv: {
        textAlign: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        minHeight: '100px !important',
        display: 'contents',
        cursor: 'pointer',
    },
    dropZoneDialogTitle: {
        backgroundColor: '#d6d7d7',
        fontWeight: 'bold',
        fontSize: '24px',
    },
    dropZoneDialogContent: {
        backgroundColor: '#7b7b7b',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    dropZoneDialogContentText1: {
        color: 'white',
        marginTop: '10px',
        fontSize: '18px',
        border: '3px dashed #ffffff',
        borderRadius: '5px',
        width: '100%',
    },
    dropZoneDialogContentText2: {
        margin: '10px 0',
        padding: '5px',
        backgroundColor: 'white',
        color: '#3b3b3b',
        borderRadius: '5px',
        width: '70%',
        fontSize: '18px',
    },
    dropZoneDialogContentText3: {
        color: '#ffffff',
        borderRadius: '5px',
        width: '70%',
        fontSize: '18px',
    },
    dropZonePictureIcon: {
        color: '#f4f4f4',
        transform: 'scaleX(-1)',
        height: '5em !important',
        width: '5em !important',
    },
}));

export default useStyles;
