import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { tagsService } from '_features/_services';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';

const initialState = {
    status: LoadingStatus.Idle,
    error: null,
    tags: [],
    tagStatus: LoadingStatus.Idle,
    tagPageInfo: {},
    displayTag: {},
    tagUsage: [],
    loadedTagsMap: {},
};

export const fetchTags = createAsyncThunk(
    'tags/fetchTags',
    async (params = {}, { getState }) =>
        await tagsService.getTags(
            params.searchString,
            params.sort,
            getState().accounts.activeUser.s3_prefix
        )
);

export const fetchTagById = createAsyncThunk(
    'tags/fetchTagById',
    async (tagId, { getState }) => {
        const response = await tagsService.getById(
            tagId,
            getState().accounts.activeUser.s3_prefix
        );
        response.id = tagId;
        return response;
    }
);

export const fetchTagUsageById = createAsyncThunk(
    'groups/fetchTagUsageById',
    async (tagId) => await tagsService.getTagUsageById(tagId)
);

export const createTag = createAsyncThunk(
    'tags/createTag',
    async (params, { getState }) =>
        await tagsService.create(
            params,
            getState().accounts.activeUser.s3_prefix
        )
);

export const updateTag = createAsyncThunk(
    'tags/updateTag',
    async (params, { getState }) =>
        await tagsService.update(
            params,
            getState().accounts.activeUser.s3_prefix
        )
);

export const deleteTag = createAsyncThunk(
    'tags/deleteTag',
    async ({ tag_id }) => {
        const response = await tagsService.delete(tag_id);
        return response;
    }
);

export const deleteTagArray = createAsyncThunk(
    'assets/deleteTagArray',
    async (tagIds) => {
        const response = await tagsService.deleteArray(tagIds);
        response.ids = tagIds;
        return response;
    }
);

const tagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        resetTagState: (state) => initialState,
    },
    extraReducers: {
        [fetchTags.pending]: (state, action) => {
            state.tagStatus = LoadingStatus.Loading;
        },
        [fetchTags.fulfilled]: (state, action) => {
            state.tagPageInfo = action.payload.pageInfo;
            state.tags = action.payload.tags;
            state.tagStatus = LoadingStatus.Loaded;
        },
        [fetchTags.rejected]: (state, action) => {
            state.tagStatus = LoadingStatus.Failed;
        },
        [createTag.fulfilled]: (state, action) => {
            state.tagStatus = LoadingStatus.Idle;
        },
        [updateTag.fulfilled]: (state, action) => {
            state.tagStatus = LoadingStatus.Idle;
        },
        [deleteTag.fulfilled]: (state, action) => {
            state.tagStatus = LoadingStatus.Idle;
        },
        [deleteTagArray.fulfilled]: (state, action) => {
            state.tagStatus = LoadingStatus.Idle;
        },
        [deleteTagArray.rejected]: (state, action) => {
            state.status = SavingStatus.Failed;
            state.error = action.error.message;
        },
        [fetchTagById.fulfilled]: (state, action) => {
            state.displayTag = action.payload;
        },
        [fetchTagUsageById.fulfilled]: (state, action) => {
            state.tagUsage = action.payload;
        },
    },
});

export const selectTags = (state) => state.tags.tags;
export const getTagStatus = (state) => state.tags.tagStatus;
export const getTagPageInfo = (state) => state.tags.tagPageInfo;
export const getTagUsage = (state) => state.tags.tagUsage;

export const { resetTagState } = tagsSlice.actions;

export default tagsSlice.reducer;
