import React from 'react';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { Action } from '_helpers';
import { Divider } from '@mui/material';

function LinkPopup({ object, level, onClose, readOnlyMode, targetPopup }) {
    return (
        <React.Fragment>
            {targetPopup !== 'techu_link' && (
                <>
                    <PopupAction
                        action={Action.Edit}
                        object={object}
                        level={level}
                        showLabel={true}
                        onActionHandled={onClose}
                    />
                    <Divider />
                </>
            )}
            {readOnlyMode !== true && (
                <PopupAction
                    action={Action.Delete}
                    object={object}
                    level={level}
                    showLabel={true}
                    onActionHandled={onClose}
                />
            )}
        </React.Fragment>
    );
}

export { LinkPopup };
