import { useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { BiImageAlt } from 'react-icons/bi';
import clsx from 'clsx';

import { ReactComponent as SetInitViewIcon } from 'GeminiViewerComponent/assets/set-initview-icon.svg';
import {
    MdAllInclusive,
    MdDeselect,
    MdExpandMore,
    MdFilterAlt,
    MdKeyboardArrowDown,
    MdSelectAll,
} from 'react-icons/md';
import { ReactComponent as ContentDocument } from 'GeminiViewerComponent/assets/content_document.svg';
import { ReactComponent as ContentImage } from 'GeminiViewerComponent/assets/content_image.svg';
import { SceneButtons } from 'GeminiViewerComponent/components/SceneButtons';

const makeThemePreviewStyles = makeStyles({
    container: {
        position: 'relative',
        width: '100%',
        minWidth: '250px',
        paddingBottom: '60%',
        backgroundColor: '#ccc',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid',
        fontSize: '10px',
    },
    activeThemeContainer: {
        border: '5px solid #1ac405',
    },
    innerContainer: {
        backgroundColor: 'red',
        height: '100%',
    },
    viewerHeader: {
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: (props) => props.colors.primarySectionBackground,
        color: (props) => props.colors.primarySectionForeground,
    },
    navigationHeader: {
        height: '30px',
        backgroundColor: '#0009',
    },
    sidePanel: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        right: '0px',
        top: '0px',
        height: '100%',
        width: '95px',
        backgroundColor: (props) => props.colors.side_panel_body,
    },
    panelTop: {
        height: '15px',
        backgroundColor: (props) => props.colors.side_panel_body,
    },
    panelHeader: {
        height: 'auto',
        fontWeight: 'bold',
        padding: '5px',
        fontSize: '12px',
        backgroundColor: (props) => props.colors.secondarySectionBackground,
        color: (props) => props.colors.secondarySectionForeground,
    },
    closeIcon: {
        width: '12px',
        height: '12px',
        color: (props) => props.colors.secondarySectionForeground,
    },
    mainView: {
        position: 'absolute',
        left: '18%',
        right: '0',
        zIndex: 2,
        maxWidth: '40%',
        backgroundColor: (props) => props?.colors?.main_background_color,
        color: 'white',
        width: 'max-content',
        border: '2.5px solid #c4c4c4',
        borderBottomWidth: '0',
        cursor: 'grabbing',
        bottom: '25px',
        borderRadius: '10px 10px 0 0',
        padding: '2px',
        opacity: (props) => props?.opacity?.main_background_opacity / 100,
    },
    tagContainerTagDiv: {
        width: 'max-content',
        cursor: 'pointer',
        padding: '1px 2px',
        borderRadius: '8px',
        color: '#000000',
        userSelect: 'none',
        fontSize: '8px',
        height: '20px',
        opacity: (props) => props?.opacity?.tag_background_opacity / 100,
    },
    tagsForegroundOpacity: {
        opacity: (props) => props?.opacity?.tag_foreground_opacity / 100,
    },
    bottomPanel: {
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        backgroundColor: 'white',
        width: '100%',
        height: '25px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '5px',
    },
    tagContainerTagNameDiv: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        userSelect: 'none',
    },
    tagContainerListDiv: {
        display: 'flex',
        height: '25px',
        gap: '4px',
        overflow: 'auto',
    },
    tagContainerToggleIconDiv: {
        flex: 1,
        flexBasis: '100%',
        textAlign: 'center',
    },
    tagButtons: {
        display: 'flex',
        flexWrap: 'nowrap',
        minWidth: '150px',
    },
    tagContainerTagNameToggleIconDiv: {
        flex: 1,
        flexBasis: '100%',
        minWidth: '40px',
    },
    tagSelectButtons: {
        flex: 1,
        flexBasis: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        minWidth: '40px',
    },
    sceneButtonContainer: {
        backgroundColor: (props) => props.colors.button.accentBackground,
        border: '0px',
        color: 'white',
        padding: '0',
        borderRadius: '5px',
        fontSize: '10px',
        textTransform: 'uppercase',
        cursor: 'pointer',
        overflow: 'hidden',
        alignSelf: 'center',
        display: 'inline-flex',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        '&:disabled': {
            filter: 'brightness(50%)',
        },
        '&:hover': {
            filter: 'brightness(90%)',
        },
    },
    sceneButton: {
        padding: '6px 6px',
        fontSize: '10px',
        textTransform: 'uppercase',
        backgroundColor: (props) => props.colors.button.secondaryBackground,
        color: (props) => props.colors.button.secondaryForeground,
    },
    sceneButtonIconWrap: {
        paddingLeft: '8px',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
    },
    sceneButtonIcon: {
        width: '16px',
        height: '16px',
        marginRight: '5px',
        color: 'white',
    },
    bottomButtons: {
        display: 'flex',
        width: '100%',
        height: '10px',
        gap: '5px',
    },
    linksSection: {
        '&.Mui-expanded': {
            margin: '0px',
        },
    },
    linksSectionHeader: {
        backgroundColor: (props) => props.colors.primarySectionBackground,
        color: (props) => props.colors.primarySectionForeground,
        marginBottom: '1px',
        padding: '0px',
        minHeight: '26px',
        height: '26px',
        '&.Mui-expanded': {
            minHeight: '26px',
            height: '26px',
            margin: '0px',
        },
    },
    linksHeading: {
        fontSize: '10px',
        fontWeight: 'bold',
        color: (props) => props.colors.primarySectionForeground,
        display: 'flex',
        alignItems: 'center',
        marginLeft: '3px',
    },
    linksIcon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '5px',
        width: '10px',
    },
    linksExpandIcon: {
        color: (props) => props.colors.primarySectionForeground,
        width: '15px',
        height: '15px',
    },
    linksLinks: {
        fontSize: '8px',
        backgroundColor: (props) => props.colors.secondarySectionBackground,
        color: (props) => props.colors.button.secondarySectionForeground,
        flexDirection: 'column',
        padding: '4px 4px 4px 10px',
    },
    linkButton: {
        borderRadius: '4px',
        fontSize: '8px',
        background: (props) => props.colors.link_button_background_color,
        color: (props) => props.colors.link_button_text_color,
        border: '1px solid #34485e38',
        padding: '5px',
        '&:hover': {
            filter: 'brightness(85%)',
            cursor: 'pointer',
        },
    },
    titleContainer: {
        textAlign: 'center',
        position: 'relative',
        width: '100%',
        height: '24px',
        fontSize: '10px',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
        pointerEvents: 'none',
    },
    titleBar: {
        position: 'relative',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    titleBarContent: {
        display: 'flex',
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        transformOrigin: 'left',
        position: 'absolute',
        left: '3px',
        top: '-2px',
        transform: 'scale(0.5)',
    },
    children: {
        position: 'absolute',
        backgroundColor: '#fff9',
        width: '100%',
        height: '100%',
        opacity: '0',
        '&:hover': {
            opacity: '1',
        },
        webkitTransition: 'opacity 0.5s',
        transition: 'opacity 0.5s',
    },
    primaryButton: {
        backgroundColor: (props) => props.colors.button.primaryBackground,
        border: '0px',
        margin: '0px 100px 0px auto',
        color: (props) => props.colors.button.primaryForeground,
        padding: '4px',
        borderRadius: '5px',
        fontSize: '8px',
        textTransform: 'uppercase',
        cursor: 'pointer',
        '&:disabled': {
            filter: 'brightness(50%)',
        },
        '&:hover': {
            filter: 'brightness(90%)',
        },
    },
    viewerPreview: {
        position: 'absolute',
        top: '54px',
        width: 'calc(100% - 95px)',
        height: 'calc(100% - 54px - 25px)',
        fontSize: '10px',
        color: '#fff',
        backgroundColor: (props) => props.colors.body,
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
        justifyContent: ' center',
        alignItems: ' center',
    },
    viewerWrapper: {
        backgroundColor: '#ffffff',
        width: '190px',
        border: '1px solid black',
    },
    procedureContentHeader: {
        backgroundColor: '#000000',
        color: '#ffffff',
        display: 'block',
        textAlign: 'center',
        padding: '3px',
    },
    viewerContainer: {
        padding: '10px',
        width: 'fit-content',
        marginRight: 'auto',
        marginLeft: 'auto',
        lineHeight: '1.3',
        '& a': {
            position: 'relative',
            display: 'inline-block',
            padding: '10px',
            color: (props) => props.colors.link_text_color,
            borderRadius: '5px',
            '& > button': {
                pointerEvents: 'none',
            },
            '&:visited': {
                color: (props) => props.colors.link_visited_color,
            },
            '&:hover': {
                color: (props) => `${props.colors.link_hover_color} !important`,
            },
        },
        '& button': {
            backgroundColor: (props) =>
                props.colors.link_button_background_color,
            color: (props) => props.colors.link_button_text_color,
            border: '0px',
            padding: '10px 20px',
            borderRadius: '4px',
            fontSize: '14px',
            textTransform: 'uppercase',
            cursor: 'pointer',
            marginTop: '5px',
            '&:has(a)': {
                padding: '0',
            },
            '& > a': {
                padding: '10px 20px',
                color: 'inherit',
            },
            '&:disabled': {
                backgroundColor: (props) =>
                    props.colors.link_button_background_color,
                color: (props) => props.colors.link_button_text_color,
                filter: 'brightness(50%)',
            },
            '&:hover': {
                backgroundColor: (props) =>
                    props.colors.link_button_background_color,
                color: (props) => props.colors.link_button_text_color,
                filter: 'brightness(90%)',
            },
            position: 'relative',
        },
    },
    visitedContentLink: {
        color: (props) => `${props.colors.link_visited_color} !important`,
    },
});

export const ThemePreview = (props) => {
    const { theme, isActive, ...otherProps } = props;
    const themePreviewStyles = makeThemePreviewStyles(theme.theme);
    const [tagsNameDisplay, setTagsNameDisplay] = useState(false);
    const [showAllTags, setShowAllTags] = useState(false);
    const [showOnlyByFilter, setShowOnlyByFilter] = useState(false);
    const [tags, setTags] = useState({
        tag1: false,
        tag2: false,
        tag3: false,
        tag4: false,
    });
    return (
        <div
            className={clsx(themePreviewStyles.container, {
                [themePreviewStyles.activeThemeContainer]: isActive,
            })}
            {...otherProps}
        >
            <div className={themePreviewStyles.viewerHeader}>
                <button className={themePreviewStyles.primaryButton}>
                    Logout
                </button>
            </div>

            <div className={themePreviewStyles.titleContainer}>
                <div className={themePreviewStyles.titleBar} />
                <div className={themePreviewStyles.titleBarContent}>
                    <SceneButtons mode="preview" style={{ margin: '0px' }} />
                </div>
            </div>
            <div className={themePreviewStyles.mainView}>
                <div className={themePreviewStyles.tagContainerListDiv}>
                    <div
                        className={themePreviewStyles.tagContainerTagDiv}
                        style={{
                            backgroundColor: `${
                                tags.tag1
                                    ? theme?.theme?.colors
                                          ?.tag_active_background_color
                                    : theme?.theme?.colors
                                          ?.tag_inactive_background_color
                            }`,
                        }}
                    >
                        <div
                            className={
                                themePreviewStyles.tagContainerTagNameDiv
                            }
                            onClick={() => {
                                setTags({ ...tags, tag1: !tags.tag1 });
                            }}
                        >
                            {tagsNameDisplay ? (
                                <span
                                    className={
                                        themePreviewStyles?.tagsForegroundOpacity
                                    }
                                >
                                    Tag1
                                </span>
                            ) : (
                                <ContentImage
                                    className={
                                        themePreviewStyles?.tagsForegroundOpacity
                                    }
                                    style={{ height: '12px', width: '15px' }}
                                />
                            )}
                        </div>
                    </div>
                    <div
                        className={themePreviewStyles.tagContainerTagDiv}
                        style={{
                            backgroundColor: `${
                                tags.tag2
                                    ? theme?.theme?.colors
                                          ?.tag_active_background_color
                                    : theme?.theme?.colors
                                          ?.tag_inactive_background_color
                            }`,
                        }}
                    >
                        <div
                            className={
                                themePreviewStyles.tagContainerTagNameDiv
                            }
                            onClick={() => {
                                setTags({ ...tags, tag2: !tags.tag2 });
                            }}
                        >
                            {tagsNameDisplay ? (
                                <span
                                    className={
                                        themePreviewStyles?.tagsForegroundOpacity
                                    }
                                >
                                    Tag2
                                </span>
                            ) : (
                                <ContentImage
                                    className={
                                        themePreviewStyles?.tagsForegroundOpacity
                                    }
                                    style={{ height: '12px', width: '15px' }}
                                />
                            )}
                        </div>
                    </div>
                    <div
                        className={themePreviewStyles.tagContainerTagDiv}
                        style={{
                            backgroundColor: `${
                                tags.tag3
                                    ? theme?.theme?.colors
                                          ?.tag_active_background_color
                                    : theme?.theme?.colors
                                          ?.tag_inactive_background_color
                            }`,
                        }}
                    >
                        <div
                            className={
                                themePreviewStyles.tagContainerTagNameDiv
                            }
                            onClick={() => {
                                setTags({ ...tags, tag3: !tags.tag3 });
                            }}
                        >
                            {tagsNameDisplay ? (
                                <span
                                    className={
                                        themePreviewStyles?.tagsForegroundOpacity
                                    }
                                >
                                    Tag3
                                </span>
                            ) : (
                                <ContentImage
                                    className={
                                        themePreviewStyles?.tagsForegroundOpacity
                                    }
                                    style={{ height: '12px', width: '15px' }}
                                />
                            )}
                        </div>
                    </div>
                    <div
                        className={themePreviewStyles.tagContainerTagDiv}
                        style={{
                            backgroundColor: `${
                                tags.tag4
                                    ? theme?.theme?.colors
                                          ?.tag_active_background_color
                                    : theme?.theme?.colors
                                          ?.tag_inactive_background_color
                            }`,
                        }}
                    >
                        <div
                            className={
                                themePreviewStyles.tagContainerTagNameDiv
                            }
                            onClick={() => {
                                setTags({ ...tags, tag4: !tags.tag4 });
                            }}
                        >
                            {tagsNameDisplay ? (
                                <span
                                    className={
                                        themePreviewStyles?.tagsForegroundOpacity
                                    }
                                >
                                    Tag4
                                </span>
                            ) : (
                                <ContentImage
                                    className={
                                        themePreviewStyles?.tagsForegroundOpacity
                                    }
                                    style={{
                                        height: '12px',
                                        width: '15px',
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className={themePreviewStyles.tagButtons}>
                    <div
                        className={
                            themePreviewStyles.tagContainerTagNameToggleIconDiv
                        }
                    >
                        <IconButton
                            variant="contained"
                            size="small"
                            style={{ padding: '2px' }}
                            onClick={() => {
                                setTagsNameDisplay(!tagsNameDisplay);
                            }}
                        >
                            <BiImageAlt
                                className="react-icon"
                                style={{
                                    fontSize: '12px',
                                    color: `${
                                        tagsNameDisplay
                                            ? theme?.theme?.colors
                                                  ?.icon_active_color
                                            : theme?.theme?.colors
                                                  ?.icon_inactive_color
                                    }`,
                                }}
                            />
                        </IconButton>

                        <IconButton
                            variant="contained"
                            size="small"
                            style={{ padding: '2px' }}
                            onClick={() => {
                                setShowOnlyByFilter(!showOnlyByFilter);
                            }}
                        >
                            <MdFilterAlt
                                className="react-icon"
                                style={{
                                    fontSize: '12px',
                                    color: `${
                                        showOnlyByFilter
                                            ? theme?.theme?.colors
                                                  ?.icon_active_color
                                            : theme?.theme?.colors
                                                  ?.icon_inactive_color
                                    }`,
                                }}
                            />
                        </IconButton>

                        <IconButton
                            variant="contained"
                            size="small"
                            style={{ padding: '2px' }}
                            onClick={() => {
                                setShowAllTags(!showAllTags);
                            }}
                        >
                            <MdAllInclusive
                                className="react-icon"
                                style={{
                                    fontSize: '12px',
                                    color: `${
                                        showAllTags
                                            ? theme?.theme?.colors
                                                  ?.icon_active_color
                                            : theme?.theme?.colors
                                                  ?.icon_inactive_color
                                    }`,
                                }}
                            />
                        </IconButton>
                    </div>
                    <div
                        className={themePreviewStyles.tagContainerToggleIconDiv}
                    >
                        <IconButton
                            variant="contained"
                            size="small"
                            style={{ padding: '2px' }}
                        >
                            <MdKeyboardArrowDown
                                className="react-icon"
                                style={{
                                    fontSize: '12px',
                                    color: theme?.theme?.colors
                                        ?.icon_active_color,
                                }}
                            />
                        </IconButton>
                    </div>
                    <div className={themePreviewStyles.tagSelectButtons}>
                        <IconButton
                            variant="contained"
                            size="small"
                            sx={{ padding: '2px' }}
                        >
                            <MdSelectAll
                                className="react-icon"
                                style={{
                                    fontSize: '12px',
                                    color: theme?.theme?.colors
                                        ?.icon_active_color,
                                }}
                            />
                        </IconButton>
                        <IconButton
                            variant="contained"
                            size="small"
                            sx={{
                                padding: '2px',
                                color: `${theme?.theme?.colors?.icon_active_color}`,
                            }}
                        >
                            <MdDeselect
                                className="react-icon"
                                style={{
                                    fontSize: '12px',
                                    color: `${theme?.theme?.colors?.icon_active_color}`,
                                }}
                            />
                        </IconButton>
                    </div>
                </div>
            </div>
            <div className={themePreviewStyles.bottomPanel}>
                <div className={themePreviewStyles.bottomButtons}>
                    <div className={themePreviewStyles.sceneButtonContainer}>
                        <div className={themePreviewStyles.sceneButtonIconWrap}>
                            <SetInitViewIcon
                                className={themePreviewStyles.sceneButtonIcon}
                            />
                        </div>
                        <div className={themePreviewStyles.sceneButton}>
                            Initial View
                        </div>
                    </div>
                </div>
            </div>

            <div className={themePreviewStyles.viewerPreview}>
                <div className={themePreviewStyles.viewerWrapper}>
                    <span className={themePreviewStyles.procedureContentHeader}>
                        Procedure Content
                    </span>
                    <div className={themePreviewStyles.viewerContainer}>
                        <button data-target-content="true">
                            <a>Content Button</a>
                        </button>
                        <br />
                        <a>Content Link</a>
                        <br />
                        <a className={themePreviewStyles.visitedContentLink}>
                            Visited Content Link
                        </a>
                    </div>
                </div>
            </div>

            <div className={themePreviewStyles.sidePanel}>
                <div className={themePreviewStyles.panelTop}>
                    <IoMdCloseCircleOutline
                        className={themePreviewStyles.closeIcon}
                    />
                </div>
                <div className={themePreviewStyles.panelHeader}>
                    <div className={themePreviewStyles.panelTitle}>
                        Panel Title
                    </div>
                </div>
                <Accordion
                    defaultExpanded={true}
                    className={themePreviewStyles.linksSection}
                >
                    <AccordionSummary
                        className={themePreviewStyles.linksSectionHeader}
                        expandIcon={
                            <MdExpandMore
                                className={themePreviewStyles.linksExpandIcon}
                            />
                        }
                    >
                        <Typography className={themePreviewStyles.linksHeading}>
                            <ContentDocument
                                className={themePreviewStyles.linksIcon}
                            />
                            Document
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={themePreviewStyles.linksLinks}>
                        <div className={themePreviewStyles.linkButton}>
                            Document Link
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    defaultExpanded={true}
                    className={themePreviewStyles.linksSection}
                >
                    <AccordionSummary
                        className={themePreviewStyles.linksSectionHeader}
                        expandIcon={
                            <MdExpandMore
                                className={themePreviewStyles.linksExpandIcon}
                            />
                        }
                    >
                        <Typography className={themePreviewStyles.linksHeading}>
                            <ContentImage
                                className={themePreviewStyles.linksIcon}
                            />
                            Image
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={themePreviewStyles.linksLinks}>
                        <div className={themePreviewStyles.linkButton}>
                            Image Link
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            {/* <div className={themePreviewStyles.children}>{props.children}</div> */}
            {props.children}
        </div>
    );
};
