import { Box, Divider } from '@mui/material';
import Popover from '@mui/material/Popover';
import { last } from 'GeminiViewerComponent/_helpers/lodashUtils';

import { useStyles } from '../PopupAction/styles';

function ZoneSelectPopup({ item, anchorEl, onZoneSelected, zonesWithoutItem }) {
    const classes = useStyles();

    const stopPropagation = (event) => {
        event.stopPropagation();
    };

    const handleClose = () => onZoneSelected(null);

    const handleAction = (zone) => onZoneSelected(zone);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const lastZone = last(zonesWithoutItem);

    return (
        <div onClick={(event) => stopPropagation(event)}>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClick={handleClose}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Box p={2}>
                    {zonesWithoutItem.map((zone) => {
                        return (
                            <>
                                <div
                                    className={classes.popupActionContainer}
                                    onClick={() => handleAction(zone)}
                                >
                                    <div className={classes.popupActionLabel}>
                                        {zone.display_name}
                                    </div>
                                </div>
                                {zone !== lastZone && <Divider />}
                            </>
                        );
                    })}
                </Box>
            </Popover>
        </div>
    );
}

export { ZoneSelectPopup };
