/* eslint-disable no-unused-vars */
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Canvas, events, useThree } from '@react-three/fiber';
import {
    EffectComposer,
    Outline,
    Selection,
    SMAA,
    SSAO,
} from '@react-three/postprocessing';
// import {
//     ArcballControls,
//     Bounds,
//     Environment,
//     GizmoHelper,
//     GizmoViewport,
//     Lightformer,
//     OrbitControls,
//     useCamera,
// } from '@react-three/drei';
import { Leva } from 'leva';
import { Slider } from '@mui/material';
import * as THREE from 'three';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { MeshBasicMaterial } from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import {
    Bounds,
    Environment,
    Lightformer,
    OrbitControls,
    useGLTF,
} from '@react-three/drei';

const LoadingComponent = (event) => {
    return <div>Loading</div>;
};

const MyModel = ({
    explodeFactor,
    setExplodeFactor,
    containerRef,
    orbitControlsRef,
    setMaxExplode,
    ...props
}) => {
    const { scene } = useGLTF(props.modelURL);
    const { invalidate, camera, gl: renderer, scene: parentScene } = useThree();
    const sceneCenterRef = useRef();
    const selectedMaterialRef = useRef();
    const transformControlsRef = useRef();

    useEffect(() => {
        return () => {
            setExplodeFactor(0.0);
            transformControlsRef.current.detach();
            scene.traverseVisible(function (node) {
                if (node.isMesh) {
                    var childCenter = node.geometry.boundingSphere.center;
                    var direction = childCenter.sub(sceneCenterRef.current);
                    direction = direction.normalize();
                    node.position.x = 0;
                    node.position.y = 0;
                    node.position.z = 0;
                    node.geometry.computeBoundingBox();
                    node.geometry.computeBoundingSphere();
                }
            });
        };
    }, [props.modelURL]);

    useEffect(() => {
        const selectedMaterial = new MeshBasicMaterial({
            color: 'green',
        });
        selectedMaterial.transparent = true;
        selectedMaterial.opacity = 0.5;
        selectedMaterial.needsUpdate = true;

        selectedMaterialRef.current = selectedMaterial;

        const container = containerRef.current;

        const transformControls = new TransformControls(
            camera,
            renderer.domElement
        );
        parentScene.add(transformControls);

        transformControls.addEventListener(
            'dragging-changed',
            function (event) {
                orbitControlsRef.current.enabled = !event.value;
            }
        );
        transformControls.addEventListener('change', invalidate);
        transformControls.addEventListener('objectChange', (event) => {
            event.target.object.userData.originalPosition =
                event.target.object.position.clone();
        });

        transformControlsRef.current = transformControls;

        scene.traverseVisible(function (node) {
            if (node.isMesh) {
                node.userData.originalPosition = node.position.clone();
            }
        });

        const sceneCenter = getCenter();

        sceneCenterRef.current = sceneCenter;

        const box = new THREE.Box3().setFromObject(scene);
        const boxSize = box.getSize(new THREE.Vector3()).length();

        setMaxExplode(boxSize * 0.3);

        container.addEventListener('mousedown', onMouseDown);
        container.addEventListener('mouseup', onMouseUp);

        return () => {
            container.removeEventListener('mousedown', onMouseDown);
            container.removeEventListener('mouseup', onMouseUp);
        };
    }, []);

    useEffect(() => {
        transformControlsRef.current.detach();

        scene.traverseVisible(function (node) {
            if (node.isMesh) {
                // Finding the vector between the center and the part and normalising it
                var childCenter = node.geometry.boundingSphere.center;
                var direction = childCenter.sub(sceneCenterRef.current);
                direction = direction.normalize();

                node.position.x =
                    node.userData?.originalPosition?.x ||
                    0 + direction.x * explodeFactor;
                node.position.y =
                    node.userData?.originalPosition?.y ||
                    0 + direction.y * explodeFactor;
                node.position.z =
                    node.userData?.originalPosition?.z ||
                    0 + direction.z * explodeFactor;

                node.geometry.computeBoundingBox();
                node.geometry.computeBoundingSphere();
            }
        });

        invalidate();
    }, [explodeFactor]);

    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    const onDownPosition = new THREE.Vector2();
    const onUpPosition = new THREE.Vector2();

    const getIntersects = (point) => {
        mouse.set(point.x * 2 - 1, -(point.y * 2) + 1);

        raycaster.setFromCamera(mouse, camera);

        const objects = [];

        transformControlsRef.current.detach();

        scene.traverseVisible(function (node) {
            if (node.isMesh) {
                objects.push(node);
            }
        });

        return raycaster.intersectObjects(objects, false);
    };

    const getCenter = () => {
        var centers = [];

        transformControlsRef.current.detach();

        scene.traverseVisible(function (node) {
            if (node.isMesh) {
                node.geometry.computeBoundingSphere();
                centers.push(node.geometry.boundingSphere.center);
            }
        });

        var center = new THREE.Vector3();
        centers.forEach(function (vec) {
            center = center.add(vec);
        });
        center.x /= centers.length;
        center.y /= centers.length;
        center.z /= centers.length;

        return center;
    };

    function getMousePosition(x, y) {
        const rect = containerRef.current.getBoundingClientRect();
        return [(x - rect.left) / rect.width, (y - rect.top) / rect.height];
    }

    function handleClick() {
        if (onDownPosition.distanceTo(onUpPosition) < 0.01) {
            const intersects = getIntersects(onUpPosition);

            if (intersects.length > 0) {
                const object = intersects[0].object;

                if (
                    object.material.transparent === true &&
                    object.userData.originalMaterial
                ) {
                    object.material = object.userData.originalMaterial;
                    object.material.needsUpdate = true;
                } else {
                    if (!object.userData.originalMaterial) {
                        object.userData.originalMaterial =
                            object.material.clone();
                        if (object.userData.originalMaterial.map) {
                            object.userData.originalMaterial.map =
                                object.material.map.clone();
                            object.userData.originalMaterial.map.needsUpdate = true;
                        }
                    }

                    object.material = new MeshBasicMaterial({
                        color: 'green',
                    });
                    object.material.transparent = true;
                    object.material.opacity = 0.5;
                }
                transformControlsRef.current.attach(object);
            } else {
                transformControlsRef.current.detach();
            }

            invalidate();
        }
    }

    function onMouseDown(event) {
        // event.preventDefault();
        const array = getMousePosition(event.clientX, event.clientY);
        onDownPosition.fromArray(array);
    }

    function onMouseUp(event) {
        const array = getMousePosition(event.clientX, event.clientY);
        onUpPosition.fromArray(array);

        handleClick();
    }

    // scene.background = new THREE.Color('#ece');

    return (
        <Suspense fallback={null}>
            {/* eslint-disable-next-line react/no-unknown-property */}
            <primitive object={scene} />
        </Suspense>
    );
};

const ModelContainer = ({ Model, ...props }) => {
    const [explodeFactor, setExplodeFactor] = useState(0.0);
    const containerRef = useRef();

    const orbitControlsRef = useRef();
    const [maxExplode, setMaxExplode] = useState(20.0);

    const mountRef = useRef(null);

    const handleChange = (event, newValue) => {
        setExplodeFactor(newValue);
    };

    const eventManagerFactory = (state) => ({
        enabled: false,
    });

    return (
        <>
            <Slider
                aria-label="Explode"
                value={explodeFactor}
                onChange={handleChange}
                step={maxExplode / 50.0}
                min={0.0}
                max={maxExplode}
            />
            <div style={{ width: '100%', height: '100%' }} ref={mountRef}>
                <Canvas events={eventManagerFactory} frameloop="demand">
                    <OrbitControls makeDefault ref={orbitControlsRef} />
                    {/* eslint-disable-next-line react/no-unknown-property */}
                    <ambientLight intensity={0.5} position={[10, 10, 10]} />
                    {/* <Environment preset="city" /> */}
                    {/* <Environment ground /> */}
                    <Environment resolution={256}>
                        {/* eslint-disable-next-line react/no-unknown-property */}
                        <group rotation={[-Math.PI / 2, 0, 0]}>
                            <Lightformer
                                intensity={1}
                                rotation-x={Math.PI / 2}
                                position={[0, 5, -9]}
                                scale={[10, 10, 1]}
                            />
                            {[2, 0, 2, 0, 2, 0, 2, 0].map((x, i) => (
                                <Lightformer
                                    key={i}
                                    form="circle"
                                    intensity={1}
                                    rotation={[Math.PI / 2, 0, 0]}
                                    position={[x, 4, i * 4]}
                                    scale={[4, 1, 1]}
                                />
                            ))}
                            <Lightformer
                                intensity={1}
                                rotation-y={Math.PI / 2}
                                position={[-5, 1, -1]}
                                scale={[50, 2, 1]}
                            />
                            <Lightformer
                                intensity={1}
                                rotation-y={Math.PI / 2}
                                position={[-5, -1, -1]}
                                scale={[50, 2, 1]}
                            />
                            <Lightformer
                                intensity={1}
                                rotation-y={-Math.PI / 2}
                                position={[10, 1, 0]}
                                scale={[50, 2, 1]}
                            />
                        </group>
                    </Environment>
                    <Bounds fit>
                        <MyModel
                            explodeFactor={explodeFactor}
                            setExplodeFactor={setExplodeFactor}
                            containerRef={mountRef}
                            orbitControlsRef={orbitControlsRef}
                            setMaxExplode={setMaxExplode}
                            {...props}
                        />
                    </Bounds>
                </Canvas>
            </div>
        </>
    );
};

export default ModelContainer;
