import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Button,
} from '@mui/material';

const UnsavedPromptDialog = ({ openDialog, setOpenDialog, confirmSubmit }) => {
    return (
        openDialog && (
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Reset Changes</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`This will reset all changes. Are you sure you want to cancel?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} autoFocus>
                        No
                    </Button>
                    <Button
                        onClick={async () => {
                            confirmSubmit();
                            setOpenDialog(false);
                        }}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        )
    );
};

export default UnsavedPromptDialog;
