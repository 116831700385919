import React from 'react';
import { LinkTypes } from '_helpers/enumerations';
import { GeneralContentTab } from '../GeneralContentTab';

function EmbedTab() {
    return (
        <GeneralContentTab
            contentName={'Embed'}
            linkTypeId={LinkTypes.Embed.id}
            contentTypes={LinkTypes.Embed.contentTypes}
        />
    );
}

export { EmbedTab };
