import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    setUserDragging,
    setTrimPositions,
    setLastChangedIndex,
    setPlaybackPosition,
    setMarkerPlaybackPosition,
    updateZonePosition,
    selectCurrentZone,
} from '../videoImportSlice';
import { withStyles } from '@mui/styles';
import useStyles from '../styles';
import { Tooltip } from '@mui/material';
import { MdArrowDropDown } from 'react-icons/md';

const TrimSlider = withStyles({
    root: {
        color: '#3a8589',
        height: 3,
        padding: '13px 0',
        marginTop: '5px',
    },
    thumb: {
        height: 23,
        width: 23,
        backgroundColor: '#fff',
        border: '1px solid currentColor',
        marginTop: '0px',
        marginLeft: 0,
        pointerEvents: 'inherit !important',
        '&.second-thumb': {
            border: '2px dashed purple',
        },
        boxShadow: '#ebebeb 0 2px 2px',
        '&:focus, &:hover, &$active': {
            boxShadow: '#ccc 0 2px 3px 1px',
        },
        '& .bar': {
            // display: inline-block !important;
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    active: {},
    track: {
        height: 5,
    },
    rail: {
        color: '#d8d8d8',
        opacity: 1,
        height: 3,
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: 8,
        width: 3,
        marginTop: -20,
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Slider);

const MarkerSlider = withStyles({
    root: {
        color: '#3a8589',
        height: 3,
        paddingTop: 0,
        marginTop: '10px',
    },
    thumb: {
        width: 6,
        height: 6,
        marginLeft: 0,
        background: 'transparent',
        border: 'transparent',
        pointerEvents: 'inherit !important',
        '& .activeZone': {
            backgroundColor: 'green',
        },
        '&:focus, &:hover, &:active': { boxShadow: 'none' },
        '& .markerLabel': {
            fontSize: 10,
            position: 'absolute',
            opacity: 1,
            marginTop: -18,
            maxWidth: '40px',
            whiteSpace: 'nowrap',
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    active: {},
    track: {
        opacity: 0,
    },
    rail: {
        opacity: 0,
    },
})(Slider);

const VideoSlider = memo(
    forwardRef(
        (
            {
                videoRef,
                framesPerSecond,
                hasImages,
                setCurrentImageIdx,
                shortcutKeysEnabled,
            },
            ref
        ) => {
            var timeout;

            const theme = useSelector(selectActiveTheme);
            const classes = useStyles(theme);
            const dispatch = useDispatch();

            const draggingThumbIndex = useRef(0);

            const currentZone = useSelector(selectCurrentZone);

            const zonePositions = useSelector(
                (state) => state.videoImport.zonePositions
            );

            const lastChangedIndex = useSelector(
                (state) => state.videoImport.lastChangedIndex
            );

            const playbackPosition = useSelector(
                (state) => state.videoImport.playbackPosition
            );
            const trimScrubberPositionMax = useSelector(
                (state) => state.videoImport.trimScrubberPositionMax
            );
            const trimPositions = useSelector(
                (state) => state.videoImport.trimPositions
            );
            const minimumDistance = useSelector(
                (state) => state.videoImport.minimumDistance
            );
            // const [manageDialog, setManageDialog] = React.useState({openDialog: false, type: ''});
            const [zonePositionsState, setZonePositionsState] =
                useState(zonePositions);

            const [trimPositionsState, setTrimPositionsState] =
                useState(trimPositions);

            const [lastActiveThumb, setLastActiveThumb] = useState(-1);

            useEffect(() => {
                setZonePositionsState(zonePositions.map((zone) => zone.time));
            }, [zonePositions]);

            useEffect(() => {
                setTrimPositionsState(trimPositions);
            }, [trimPositions]);

            const handleMarkerChange = (event, newValue, activeThumb) => {
                setLastActiveThumb(activeThumb);

                if (activeThumb > -1 && (videoRef.current || hasImages)) {
                    if (!hasImages) {
                        if (activeThumb > 0) {
                            newValue[activeThumb] = Math.max(
                                newValue[activeThumb],
                                zonePositionsState[activeThumb - 1] +
                                    minimumDistance
                            );
                        }
                        if (activeThumb < newValue.length - 1) {
                            newValue[activeThumb] = Math.min(
                                newValue[activeThumb],
                                zonePositionsState[activeThumb + 1] -
                                    minimumDistance
                            );
                        }
                    }

                    setZonePositionsState([...newValue]);
                    const index = activeThumb;
                    const newTime = newValue[index];
                    if (!hasImages) {
                        videoRef.current.currentTime = newTime;
                    }
                    dispatch(setMarkerPlaybackPosition(newTime));
                }
            };
            const handleMarkerChangeCommitted = (event, newValue) => {
                if (newValue.length != zonePositions.length) {
                    return;
                }

                let zoneIndex = lastActiveThumb;

                let lastPosition = zonePositions[zoneIndex];
                let newPosition = newValue[zoneIndex];

                // NOTE: We are using a range of values for the slider
                // and the range values are the same as the zone positions.
                // newValue represents all of the updated zone positions
                // after a user has dragged a single zone position.
                // In case the updated zone contains the same index as
                // another zone - shift it by one (back in the direction it came from).
                if (
                    zonePositions.find(
                        (index, z) =>
                            index != zoneIndex && z.time == newPosition
                    )
                ) {
                    if (newPosition > lastPosition) {
                        newPosition--;
                    } else {
                        newPosition++;
                    }
                }

                if (hasImages) {
                    setCurrentImageIdx(Math.round(newPosition));
                } else {
                    if (videoRef.current) {
                        videoRef.current.currentTime = newPosition;
                    }
                }
                dispatch(
                    updateZonePosition({
                        index: zoneIndex,
                        time: newPosition,
                    })
                );
            };

            const handleMarkerPlayerChange = (event, newValue) => {
                if (!hasImages && videoRef.current) {
                    videoRef.current.currentTime = newValue;
                }
            };

            const handleMarkerPlayerChangeCommitted = (event, newValue) => {
                if (hasImages) {
                    setCurrentImageIdx(Math.round(newValue));
                }
                dispatch(setMarkerPlaybackPosition(newValue));
                dispatch(setPlaybackPosition(newValue));
            };

            const handleTrimChange = (event, newValue, activeThumb) => {
                if (videoRef.current && !hasImages) {
                    setTrimPositionsState([...newValue]);

                    dispatch(
                        setUserDragging({
                            userDragging: true,
                            scrubberPositions: newValue,
                        })
                    );
                    var index = undefined;
                    if (activeThumb > -1) {
                        index = activeThumb;
                    } else {
                        index = draggingThumbIndex.current;
                    }

                    if (index === undefined) {
                        return;
                    }

                    draggingThumbIndex.current = index;
                    const newTime = newValue[index];
                    if (hasImages) {
                        setCurrentImageIdx(Math.round(newTime));
                    } else {
                        videoRef.current.currentTime = newTime;
                    }
                    dispatch(setMarkerPlaybackPosition(newTime));
                }
            };

            const handleTrimChangeCommitted = (event, newValue) => {
                timeout && clearTimeout(timeout);
                dispatch(
                    setUserDragging({
                        userDragging: false,
                        scrubberPositions: newValue,
                    })
                );

                var index = undefined;

                if (event?.target?.attributes['data-index']) {
                    index = event.target.attributes['data-index'].value;
                } else {
                    index = draggingThumbIndex.current;
                }

                if (!index) {
                    return;
                }

                draggingThumbIndex.current = index;
                let changedPosition = newValue[index];

                if (!Array.isArray(newValue)) {
                    return;
                }

                // Setting minimum distance between two thumb
                if (Number(index) === 0) {
                    dispatch(
                        setTrimPositions([
                            Math.min(
                                newValue[0],
                                trimPositions[1] - minimumDistance
                            ),
                            trimPositions[1],
                        ])
                    );
                    changedPosition = Math.min(
                        newValue[0],
                        trimPositions[1] - minimumDistance
                    );
                } else {
                    dispatch(
                        setTrimPositions([
                            trimPositions[0],
                            Math.max(
                                newValue[1],
                                trimPositions[0] + minimumDistance
                            ),
                        ])
                    );
                    changedPosition = Math.max(
                        newValue[1],
                        trimPositions[0] + minimumDistance
                    );
                }

                // Recalculate ticks so we have target time between trim points
                dispatch(setLastChangedIndex(index));
                dispatch(setMarkerPlaybackPosition(changedPosition));
                dispatch(setPlaybackPosition(changedPosition));
                if (hasImages) {
                    setCurrentImageIdx(Math.round(changedPosition));
                }
                if (videoRef.current) {
                    videoRef.current.currentTime = changedPosition;
                }
            };

            useEffect(() => {
                const handleKeyUp = (event) => {
                    if (!shortcutKeysEnabled) {
                        return;
                    }
                    let newPositions;
                    switch (event.keyCode) {
                        case 37: // left arrow
                            newPositions = [trimPositions[0], trimPositions[1]];
                            newPositions[lastChangedIndex] =
                                newPositions[lastChangedIndex] - 0.1;
                            draggingThumbIndex.current = lastChangedIndex;
                            handleTrimChangeCommitted(undefined, newPositions);
                            event.preventDefault();
                            break;
                        case 39: // right arrow
                            newPositions = [trimPositions[0], trimPositions[1]];
                            newPositions[lastChangedIndex] =
                                newPositions[lastChangedIndex] + 0.1;
                            draggingThumbIndex.current = lastChangedIndex;
                            handleTrimChangeCommitted(undefined, newPositions);
                            event.preventDefault();
                            break;
                        default:
                            break;
                    }
                };
                window.addEventListener('keyup', handleKeyUp);
                return () => window.removeEventListener('keyup', handleKeyUp);
            }, [
                trimPositions,
                playbackPosition,
                lastChangedIndex,
                shortcutKeysEnabled,
            ]);

            function TrimThumbComponent(props) {
                const { children, className, ...other } = props;
                const extraClassName =
                    other['data-index'] === 0 ? 'first-thumb' : 'second-thumb';

                const label = other['data-index'] === 0 ? 'I' : 'O';

                return (
                    <SliderThumb
                        {...other}
                        className={clsx(className, extraClassName)}
                    >
                        {children}
                        <span>{label}</span>
                    </SliderThumb>
                );
            }

            function MarkerThumbComponent(props) {
                const { children, className, ...other } = props;

                if (!zonePositions[other['data-index']]) {
                    return null;
                }

                const markerName = zonePositions[other['data-index']].name;
                // const markerId = marks[other['data-index']].id;

                const isCurrent =
                    zonePositions[other['data-index']] === currentZone;

                const currentBackgroundColor = isCurrent
                    ? '#cccc'
                    : 'transparent';

                return (
                    <Tooltip title={markerName}>
                        <SliderThumb {...other} className={className}>
                            {children}
                            <MdArrowDropDown
                                className="react-icon"
                                key={other['data-index']}
                                //onClick={() => handleMarkClick(other['data-index'])}
                                style={{
                                    width: '23px',
                                    cursor: 'pointer',
                                    backgroundColor: currentBackgroundColor,
                                    borderRadius: '11px',
                                }}
                            />
                            <span className={'markerLabel'}>{markerName}</span>
                        </SliderThumb>
                    </Tooltip>
                );
            }

            const Ticks = () => {
                var targetTime = 15.0;
                var frameTimes = [];
                var totalTime = trimPositions[1] - trimPositions[0];
                if (totalTime <= 0) {
                    return null;
                }
                var ratio = totalTime / targetTime;
                var secondsPerFrame = (1.0 / framesPerSecond) * ratio;
                for (
                    var i = trimPositions[0];
                    i <= trimPositions[1];
                    !hasImages ? (i += secondsPerFrame) : i++
                ) {
                    frameTimes.push(i);
                }

                frameTimes.push(trimPositions[1]);

                return (
                    <div
                        style={
                            !hasImages
                                ? { marginTop: '16px', position: 'relative' }
                                : {
                                      marginBottom: '16px',
                                      position: 'relative',
                                      height: '8px',
                                      borderBottom: '2px solid #888888',
                                  }
                        }
                    >
                        {frameTimes.map((time, index) => {
                            const tickSize =
                                index % 5 ? classes.smallTick : classes.bigTick;
                            const percent =
                                (time / trimScrubberPositionMax) * 100.0;
                            return (
                                <span
                                    key={index}
                                    className={clsx(classes.tick, tickSize)}
                                    style={{
                                        left: `${percent}%`,
                                    }}
                                ></span>
                            );
                        })}
                    </div>
                );
            };

            // const currentPlaybackFrame =
            //     lastChangedIndex === 0 ? currentScrubberFrame : endScrubberFrame;

            const PlaybackSlider = (props) => {
                const playbackPosition = useSelector(
                    (state) => state.videoImport.markerPlaybackPosition
                );

                return (
                    <Slider
                        key={`slider2-${playbackPosition}`}
                        defaultValue={playbackPosition}
                        {...props}
                        size="small"
                        disableSwap
                    />
                );
            };

            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        padding: '0px 12px 0px 12px',
                    }}
                >
                    <PlaybackSlider
                        max={trimScrubberPositionMax}
                        step={hasImages ? 1 : 0.1}
                        onChange={handleMarkerPlayerChange}
                        onChangeCommitted={handleMarkerPlayerChangeCommitted}
                    />

                    <MarkerSlider
                        value={zonePositionsState}
                        track={false}
                        max={trimScrubberPositionMax}
                        onChange={handleMarkerChange}
                        onChangeCommitted={handleMarkerChangeCommitted}
                        step={hasImages ? 1 : 0.1}
                        components={{
                            Thumb: MarkerThumbComponent,
                        }}
                        disableSwap
                        style={
                            hasImages && zonePositions.length > 0
                                ? {
                                      padding: '0px 0px',
                                      marginBottom: '13px',
                                      borderTop: '2px solid #d8d8d8',
                                  }
                                : { padding: '0px 0px' }
                        }
                    />

                    <Ticks />
                    {!hasImages && (
                        <TrimSlider
                            step={hasImages ? 1 : 0.2}
                            max={trimScrubberPositionMax}
                            value={trimPositionsState}
                            onChange={handleTrimChange}
                            onChangeCommitted={handleTrimChangeCommitted}
                            components={{
                                Thumb: TrimThumbComponent,
                            }}
                            disableSwap
                        />
                    )}
                </div>
            );
        }
    )
);

export { VideoSlider };
