import { useSelector } from 'react-redux';
import { Box, IconButton } from '@mui/material';
import { MdArrowLeft, MdArrowRight } from 'react-icons/md';

import { orbitStyles } from './styles';
import { selectZoneLoadingStatus } from 'shared/loadZoneSlice';

const OrbitNavigation = (props) => {
    const {
        tridiRef,
        onPrevClick,
        onNextClick,
        hasImages = false,
        ...otherProps
    } = props;

    const classes = orbitStyles();

    const zoneLoadingStatus = useSelector(selectZoneLoadingStatus);

    const leftClickHandler = () => {
        hasImages ? onPrevClick(tridiRef) : tridiRef.current.prev();
    };

    const rightClickHandler = () => {
        hasImages ? onNextClick(tridiRef) : tridiRef.current.next();
    };

    return (
        <Box {...otherProps}>
            <IconButton
                className={classes.navigationButton}
                variant="contained"
                size="small"
                onClick={leftClickHandler}
                disabled={zoneLoadingStatus !== 'Loaded'}
            >
                <MdArrowLeft className="react-icon" />
            </IconButton>
            <IconButton
                className={classes.navigationButton}
                variant="contained"
                size="small"
                onClick={rightClickHandler}
                disabled={zoneLoadingStatus !== 'Loaded'}
            >
                <MdArrowRight className="react-icon" />
            </IconButton>
        </Box>
    );
};

export { OrbitNavigation };
