import { config } from 'gemini-config';
import {
    createProgressParams,
    FormDataUtils,
    getNetworkService,
    prefixUrl,
} from 'GeminiViewerComponent/_helpers';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/tool`;

const mapTool = (tool, s3Prefix) => {
    if (tool?.image_url) {
        tool.image_url = prefixUrl(tool.image_url, s3Prefix);
    }
    return tool;
};

const mapTools = (tools, s3Prefix) => {
    return tools.map((tool) => {
        return mapTool(tool, s3Prefix);
    });
};

const getTools = async (
    locationIDs,
    categoryIDs,
    searchString = '',
    sort = '-display_name',
    s3Prefix = '',
    activeOnly = true
) => {
    const filters = [
        `is_active==${activeOnly.toString()}`,
        `display_name@=*${searchString}`,
    ];
    if (locationIDs?.length > 0) {
        filters.push(`tool_location_id==${locationIDs.join('|').toString()}`);
    }
    if (categoryIDs?.length > 0) {
        filters.push(`tool_category_id==${categoryIDs.join('|').toString()}`);
    }
    const params = { filters: filters.join(','), sorts: sort };
    const query = new URLSearchParams(params).toString();
    try {
        const response = await networkService.get(`${baseUrl}?${query}`);
        response.data = mapTools(response?.data, s3Prefix);
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, tools: response?.data };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const create = async (params, s3Prefix = '') => {
    const user = JSON.parse(localStorage.getItem('user'));
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [
        ['display_name', params.display_name],
        ['description', params.description],
        ['tool_category_id', params.tool_category_id],
        ['client_id', user.client_id],
        ['image', params.image],
        ['sku', params.sku],
    ]);
    FormDataUtils.safeArrayAppend(formData, [
        ['location_ids', params.location_ids],
        ['procedure_ids', params.procedure_ids],
    ]);
    let progressParams = createProgressParams(params);
    try {
        const response = await networkService.postMultiFormData(
            baseUrl,
            formData,
            progressParams
        );
        response.data = mapTool(response.data, s3Prefix);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const update = async (params, s3Prefix = '') => {
    const user = JSON.parse(localStorage.getItem('user'));
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [
        ['display_name', params.display_name],
        ['description', params.description],
        ['tool_category_id', params.tool_category_id],
        ['client_id', user.client_id],
        ['image', params.image],
        ['image_url', params.image_url],
        ['sku', params.sku],
    ]);
    FormDataUtils.safeArrayAppend(formData, [
        ['location_ids', params.location_ids],
        ['procedure_ids', params.procedure_ids],
    ]);
    let progressParams = createProgressParams(params);
    try {
        const response = await networkService.putMultiFormData(
            `${baseUrl}/${params.tool_id}`,
            formData,
            progressParams
        );
        response.data = mapTool(response.data, s3Prefix);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _delete = async (tool_id) => {
    try {
        const response = await networkService.delete(`${baseUrl}/${tool_id}`);
        return response;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

// const deleteArray = async (ids) => {
//     try {
//         const user = JSON.parse(localStorage.getItem('user'));
//         const query = ids.join('&ids=');
//         const response = await networkService.delete(
//             `${baseUrl}/?client_id=${user.client_id}&ids=${query}`
//         );

//         return response.data;
//     } catch (e) {
//         return Promise.reject(e.error);
//     }
// };

const getById = async (id, s3Prefix = '') => {
    try {
        const response = await networkService.get(`${baseUrl}/${id}`);
        return mapTool(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getToolImportCsv = async () => {
    try {
        const response = await networkService.get(`${baseUrl}/csv`);
        return response?.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const uploadToolImportCsv = async (params) => {
    try {
        let formData = new FormData();
        FormDataUtils.safeAppend(formData, [['file', params.file]]);
        let progressParams = createProgressParams(params);
        const response = await networkService.postMultiFormData(
            `${baseUrl}/csv`,
            formData,
            progressParams
        );
        return response.data;
    } catch (err) {
        return err;
    }
};

export const toolsService = {
    getTools,
    getById,
    create,
    update,
    delete: _delete,
    getToolImportCsv,
    uploadToolImportCsv,
};
