import {
    getNetworkService,
    getHistory,
    FormDataUtils,
    prefixUrl,
    createProgressParams,
} from '../../_helpers';
import { setAllAssignableRoles, fetchByKey } from '../../_helpers/cacheStore';

export const getAccountService = ({ config }) => {
    const history = getHistory({ routerBaseName: config.routerBaseName });

    const checkAuth = (response) => {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        if ([401, 403].includes(response.status)) {
            logout();
        }
    };

    const networkService = getNetworkService({
        config: config,
        checkAuth: checkAuth,
    });

    const fetchApiVersion = async () => {
        const response = await networkService.get(`${adminUrl}/version`);
        return response.data;
    };

    const logout = () => {
        // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page
        // Revoking the token logs out user from all browsers
        const client = config.modeType == 'author' ? '' : 'viewer';
        networkService.post(`${baseUrl}/revoke-token/${client}`, {});
        stopRefreshTokenTimer();
        networkService.clearUserStorage();
        history.push('/');
    };

    const baseUrl = `${config.apiUrl}/account`;
    const adminUrl = `${config.apiUrl}/admin`;

    const mapUser = (user, s3Prefix) => {
        user.image_url = prefixUrl(user.image_url, s3Prefix);
        return user;
    };

    const mapUsers = (users, s3Prefix) => {
        return users.map((user) => {
            return mapUser(user, s3Prefix);
        });
    };

    const login = async (email, password, dod_id = null) => {
        try {
            const client = config.modeType == 'author' ? '' : 'viewer';
            const response = await networkService.post(
                `${baseUrl}/authenticate/${client}`,
                { email, password },
                10000
            );
            const user = mapUser(response.data, response.data.s3_prefix);
            if (user.themes_json) {
                user.themes = JSON.parse(user.themes_json);
                delete user.themes_json;
            }
            // FIXME: remove static value once api side changes done
            user.dod_id = user?.dod_id || dod_id || 'notavailable';
            // publish user to subscribers and start timer to refresh token
            networkService.setUserStorage(JSON.stringify(user));
            startRefreshTokenTimer();
            return user;
        } catch (e) {
            return Promise.reject(e);
        }
    };

    const refreshToken = () => {
        const client = config.modeType == 'author' ? '' : 'viewer';
        return networkService
            .post(`${baseUrl}/refresh-token/${client}`, {}, 15000)
            .then((response) => {
                const user = mapUser(response.data, response.data.s3_prefix);
                if (user.themes_json) {
                    user.themes = JSON.parse(user.themes_json);
                    delete user.themes_json;
                }
                // FIXME: remove static value once api side changes done
                user.dod_id = user?.dod_id || 'notavailable';
                // publish user to subscribers and start timer to refresh token
                networkService.setUserStorage(JSON.stringify(user));
                startRefreshTokenTimer();
                return user;
            })
            .catch((e) => {
                networkService
                    .post(`${baseUrl}/revoke-token${client}`, {})
                    .then((response) => {
                        stopRefreshTokenTimer();
                        networkService.clearUserStorage();
                        window.location.href = '/';
                    });
                return Promise.reject(e);
            });
    };

    const register = (params) =>
        networkService.post(`${baseUrl}/register`, params);

    const verifyEmail = (token) =>
        networkService.post(`${baseUrl}/verify-email`, { token });

    const forgotPassword = (email) =>
        networkService.post(`${baseUrl}/forgot-password`, { email });

    const validateResetToken = async (token) => {
        await networkService.post(`${baseUrl}/validate-reset-token`, { token });
    };

    const resetPassword = ({ token, password, confirmPassword }) =>
        networkService.post(`${baseUrl}/reset-password`, {
            token,
            password,
            confirmPassword,
        });

    const getAll = () => networkService.get(baseUrl);

    const getAllByClientId = async (s3prefix = '') => {
        const { client_id } = getUser();
        const response = await networkService.get(
            `${baseUrl}/getallbyclientid/${client_id}?sorts=last_name,first_name`
        );
        return mapUsers(response.data, s3prefix);
    };

    const getAllAccountsByClientId = async (
        searchString = '',
        sort = 'last_name',
        roles = [],
        s3Prefix = ''
    ) => {
        const { client_id } = getUser();
        const filters = [];
        if (searchString) {
            filters.push(`(first_name|last_name|email)@=*${searchString}`);
        }
        if (Array.isArray(roles) && roles.length > 0) {
            filters.push(`role==${roles.join('|')}`);
        }

        const params = {
            filters: filters.join(','),
            sorts: sort,
        };

        const query = new URLSearchParams(params).toString();
        const response = await networkService.get(
            `${baseUrl}/getallbyclientid/${client_id}?${query}`
        );
        const allAccounts = mapUsers(response.data, s3Prefix);
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, allAccounts: allAccounts };
    };

    const getById = async (id, s3Prefix) => {
        const response = await networkService.get(`${baseUrl}/${id}`);
        return mapUser(response.data, s3Prefix);
    };

    const getAllAssignableRoles = async () => {
        if (!fetchByKey('allAssignableRoles')) {
            const response = await networkService.get(
                `${config.apiUrl}/role/assignable`
            );
            setAllAssignableRoles({ roles: response?.data });
            return response.data;
        }
        return fetchByKey('allAssignableRoles').roles;
    };

    const create = async (params, s3Prefix = '') => {
        var formData = new FormData();
        const user = getUser();
        FormDataUtils.safeAppend(formData, [
            ['first_name', params.first_name],
            ['last_name', params.last_name],
            ['email', params.email],
            ['password', params.password],
            ['confirm_password', params.confirm_password],
            ['role_id', params.role],
            ['image', params.image],
            ['client_id', user.client_id],
        ]);
        params.groups.map((x) => formData.append('group_ids', x));

        var progressParams = createProgressParams(params);

        const response = await networkService.postMultiFormData(
            baseUrl,
            formData,
            progressParams
        );

        const mappedUser = mapUser(response.data, s3Prefix);
        if (mappedUser.themes_json) {
            mappedUser.themes = JSON.parse(user.themes_json);
            delete mappedUser.themes_json;
        }
        return mappedUser;
    };

    const update = async (params, s3Prefix = '') => {
        var formData = new FormData();
        const user = getUser();
        FormDataUtils.safeAppend(formData, [
            ['first_name', params.first_name],
            ['last_name', params.last_name],
            ['email', params.email],
            ['password', params.password],
            ['confirm_password', params.confirm_password],
            ['role_id', params.role],
            ['image', params.image, params.image?.name],
            ['client_id', user.client_id],
        ]);

        if (Array.isArray(params.groups)) {
            params.groups.map((x) => formData.append('group_ids', x));
        }

        var progressParams = createProgressParams(params);

        const response = await networkService.putMultiFormData(
            `${baseUrl}/${params.user_id}`,
            formData,
            progressParams
        );

        const mappedUser = mapUser(response.data, s3Prefix);
        if (mappedUser.themes_json) {
            mappedUser.themes = JSON.parse(user.themes_json);
            delete mappedUser.themes_json;
        }
        return mappedUser;
    };

    const updateSettings = async (params, activeUser) => {
        const user = getUser();

        var userSettings = activeUser?.user_settings
            ? JSON.parse(activeUser?.user_settings)
            : {};
        // Update userSetting to have new value
        userSettings[params.setting_key] = params.setting_value;

        const response = await networkService.put(
            `${baseUrl}/updatesettings/${user.user_id}`,
            { user_settings: JSON.stringify(userSettings) }
        );

        const updatedUser = mapUser(response.data, response.data.s3_prefix);
        return updatedUser;
    };

    const updateLocalUser = (params) => {
        const user = getUser();

        const updatedUser = { ...user, ...params };
        // TODO
        updatedUser.dod_id = updatedUser?.dod_id || 'notavailable';
        networkService.setUserStorage(JSON.stringify(updatedUser));
    };

    const _delete = async (id) => {
        const user = getUser();
        const response = await networkService.delete(`${baseUrl}/${id}`);
        if (id === user.user_id) {
            logout();
        }
        return response.data;
    };

    const deleteArray = async (ids) => {
        const query = ids.join('&ids=');
        const response = await networkService.delete(`${baseUrl}?ids=${query}`);
        return response.data;
    };

    const undelete = async (id) => {
        const response = await networkService.put(`${baseUrl}/${id}/undelete`);
        return response.data;
    };

    // helper functions
    let refreshTokenTimeout;

    const getUser = () => {
        return JSON.parse(networkService.getUserStorage());
    };

    const startRefreshTokenTimer = () => {
        const user = getUser();
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(user.jwt_token.split('.')[1]));
        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - 60 * 1000;
        refreshTokenTimeout = setTimeout(() => {
            refreshToken();
        }, timeout);
    };

    const stopRefreshTokenTimer = () => clearTimeout(refreshTokenTimeout);

    const clientSwitch = async (clientId) => {
        try {
            const response = await networkService.post(
                `${baseUrl}/switch-clients/${clientId}`
            );
            return response.data;
        } catch (e) {
            return Promise.reject(e.error);
        }
    };

    const getUserByClientId = async (clientId) => {
        try {
            const response = await networkService.get(
                `${baseUrl}/getallbyclientid/${clientId}`
            );
            return response.data;
        } catch (e) {
            return Promise.reject(e.error);
        }
    };

    return {
        fetchApiVersion,
        checkAuth,
        login,
        logout,
        refreshToken,
        register,
        verifyEmail,
        forgotPassword,
        validateResetToken,
        resetPassword,
        getAll,
        getAllByClientId,
        getAllAccountsByClientId,
        getById,
        getAllAssignableRoles,
        create,
        update,
        updateSettings,
        delete: _delete,
        deleteArray: deleteArray,
        undelete: undelete,
        updateLocalUser,
        getUser,
        clientSwitch,
        getUserByClientId,
    };
};
