import { Button, Dialog, Typography } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';

export const vttEditorStyles = makeStyles((theme) => ({
    subtitleHighlight: {
        backgroundColor: '#dddddd !important',
    },
    video: {
        width: '100%',
        maxHeight: '200px',
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        // gap: '5px',
    },
    iconButton: {
        margin: '5px',
        border: 'none',
        cursor: 'pointer',
        fontSize: '24px',
        height: '40px',
        padding: '0px 10px 0px 10px',
        backgroundColor: (props) => props.colors.button.secondaryBackground,
        color: (props) => props.colors.button.secondaryForeground,
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: (props) => props.colors.button.secondaryBackground,
            color: (props) => props.colors.button.secondaryForeground,
            filter: 'brightness(90%)',
        },
    },
    editButton: {
        backgroundColor: (props) => props.colors.button.primaryForeground,
        color: (props) => props.colors.button.primaryBackground,
        border: '0px',
        padding: '0px 8px',
        borderRadius: '5px',
        fontSize: '16px',
        textTransform: 'uppercase',
        cursor: 'pointer',
        '&:disabled': {
            backgroundColor: (props) => props.colors.button.primaryForeground,
            color: (props) => props.colors.button.primaryBackground,
            opacity: '0.5',
        },
        '&:hover': {
            backgroundColor: (props) => props.colors.button.primaryForeground,
            color: (props) => props.colors.button.primaryBackground,
            filter: 'brightness(90%)',
        },
    },
    sourceIconButton: {
        backgroundColor: (props) => props.colors.button.primaryForeground,
        color: (props) => props.colors.button.primaryBackground,
        border: '0px',
        padding: '3px 6px 3px 6px',
        borderRadius: '5px',
        fontSize: '20px',
        textTransform: 'uppercase',
        cursor: 'pointer',
        '&:disabled': {
            backgroundColor: (props) => props.colors.button.primaryForeground,
            color: (props) => props.colors.button.primaryBackground,
            opacity: '0.5',
        },
        '&:hover': {
            backgroundColor: (props) => props.colors.button.primaryForeground,
            color: (props) => props.colors.button.primaryBackground,
            filter: 'brightness(90%)',
        },
    },
    speedSelect: {
        margin: '0px',
        padding: '0px',
        fontSize: '16px',
        height: '30px',
    },
    captionSelect: {
        margin: '10px 0px 10px 0px',
        padding: '0px',
        fontSize: '16px',
        height: '30px',
    },
    timeDisplay: {
        marginTop: '10px',
        fontSize: '18px',
        color: '#fff',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        padding: '5px',
    },
    seekSliderContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    seekSlider: {
        color: 'black',
        width: '99%',
        alignContent: 'center',
    },
}));

export const FullScreenDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
        margin: '10px',
        width: 'calc(100% - 40px)',
        height: 'calc(100% - 40px)',
        display: 'flex',
        flexDirection: 'column',
    },
});

export const FixedColumn = styled('div')({
    width: '400px',
    flexShrink: 0,
    backgroundColor: '#f0f0f0',
    padding: '10px',
    boxSizing: 'border-box',
});

export const EditContainer = styled('div')({
    flexGrow: 1,
    padding: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
});

export const EditControls = styled('div')({
    display: 'flex',
    padding: '6px',
    boxSizing: 'border-box',
    backgroundColor: '#3598db',
    justifyContent: 'space-between',
});

export const ScrollableColumn = styled('div')({
    flexGrow: 1,
    boxSizing: 'border-box',
    overflowY: 'auto',
});

export const TextInputContainer = styled('div')({
    marginBottom: '10px',
    padding: '10px',
    backgroundColor: '#f9f9f9',
    borderRadius: '5px',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
});

export const Label = styled(Typography)({
    marginBottom: '5px',
});
