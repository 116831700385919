import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FormControl, InputLabel, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { makeFormStyles } from '../styles';
import SetDirtyForm from 'forms/SetDirtyForm';
import { FormActions } from '_helpers/form-action';
import { SelectionAutocomplete } from 'components';
import { FormTextField } from 'components/TextField';
import { useTinyMCEInit } from 'hooks/useTinyMCEInit';
import { closePanel } from '_features/common/formSlice';
import { TinyMCEEditor } from 'components/TinyMCEEditor';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';
import FieldValidationError from 'GeminiViewerComponent/components/Procedure/components/ProcedureFormNode/FormFields/FieldValidationError';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    createNewsFlash,
    fetchNewsFlashById,
    fetchNewsFlashDisplayOptions,
    getNewsFlashDisplayOptionStatus,
    getNewsFlashDisplayOptions,
    updateNewsFlash,
} from 'GeminiViewerComponent/_features/newsFlash/newsFlashSlice';

const INIT_VALUES = {
    news_flash_display_option_id: undefined,
    news_flash_display_option_display_name: '',
    display_name: '',
    content: '',
    start_date: null,
    end_date: null,
    zone_ids: [],
    asset_node_ids: {},
    minimum_display_time: null,
    window_width: null,
    window_height: null,
};

const NewsFlashForm = ({ formAction, newsFlashId }) => {
    //#region Constants
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const init = useTinyMCEInit();
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    const [loaded, setLoaded] = useState(
        formAction.id === FormActions.Create.id ? true : false
    );

    const [initValues, setInitValues] = useState({ ...INIT_VALUES });
    //#endregion State

    //#region Selectors
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    useEffect(() => {
        if (formAction.id === FormActions.Edit.id) {
            const fetchData = async () => {
                let objectData = await dispatch(
                    fetchNewsFlashById(newsFlashId)
                );
                let fetchedObject = objectData.payload;

                setInitValues({ ...initValues, ...fetchedObject });
                setLoaded(true);
            };
            fetchData();
        } else {
            setInitValues({ ...INIT_VALUES });
        }
    }, [newsFlashId]);
    //#endregion Effects

    //#region Methods
    const handleSubmit = async (
        values,
        setSubmitting,
        resetForm,
        setErrors
    ) => {
        dispatch(activateLoading());
        let resultAction = null;
        try {
            switch (formAction.id) {
                case FormActions.Edit.id:
                    resultAction = await dispatch(updateNewsFlash(values));
                    if (!resultAction.error) {
                        dispatch(closePanel({ formKey: 'newsFlashForm' }));
                    }
                    break;
                case FormActions.Create.id:
                    resultAction = await dispatch(createNewsFlash(values));
                    if (!resultAction.error) {
                        resetForm();
                    }
                    break;
                default:
                    break;
            }
            if (!resultAction.payload?.error && !resultAction?.error) {
                const { message, variant } = snackbarHandler(
                    resultAction.meta.requestStatus,
                    formAction.label
                );
                enqueueSnackbar(message, {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: variant,
                });
            } else {
                if (resultAction?.payload?.error?.errors) {
                    setErrors({ ...resultAction?.payload?.error?.errors });
                } else if (resultAction?.error?.message) {
                    throw new Error(resultAction.error.message);
                } else {
                    throw new Error(resultAction?.payload?.error);
                }
            }
        } catch (err) {
            enqueueSnackbar(
                err?.message !== undefined ? err.message : 'Create Failed',
                {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'error',
                }
            );
        }
        dispatch(deactivateLoading());
        setSubmitting(false);
    };

    const newsFlashValidation = Yup.object({
        news_flash_display_option_id: Yup.number().required('Required'),
        display_name: Yup.string()
            .max(100, 'Must be 100 characters or less')
            .required('Required'),
        content: Yup.string().required('Required'),
    });
    //#endregion Methods

    return !loaded ? (
        <LoadingLogo styleClassName={classes.loadingSvg} />
    ) : (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            validationSchema={newsFlashValidation}
            onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
                handleSubmit(values, setSubmitting, resetForm, setErrors);
            }}
        >
            {({ values, isSubmitting, setFieldValue, onBlur, errors }) => (
                <Form className={classes.form}>
                    <SetDirtyForm />
                    <Box className={classes.formHeader}>News Flash Details</Box>
                    <FormControl>
                        <Field
                            className={classes.input}
                            name="news_flash_display_option_id"
                        >
                            {({
                                field: { value },
                                form: { setFieldValue },
                            }) => (
                                <SelectionAutocomplete
                                    title="News Flash Display Option"
                                    placeholder="News Flash Display Option"
                                    keyProperty="news_flash_display_option_id"
                                    nameProperty="display_name"
                                    entityIds={value}
                                    multiSelection={false}
                                    setEntityIds={setFieldValue}
                                    entitySelector={getNewsFlashDisplayOptions}
                                    entityStatusSelector={
                                        getNewsFlashDisplayOptionStatus
                                    }
                                    fetchEntityPage={
                                        fetchNewsFlashDisplayOptions
                                    }
                                    formField={'news_flash_display_option_id'}
                                    enableCache
                                    fieldValidation={
                                        errors?.news_flash_display_option_id
                                    }
                                />
                            )}
                        </Field>
                        {errors?.news_flash_display_option_id && (
                            <FieldValidationError
                                validationError={
                                    errors.news_flash_display_option_id
                                }
                            />
                        )}
                    </FormControl>
                    <FormTextField
                        autoFocus
                        label="News Flash Name"
                        name="display_name"
                        placeholder="News Flash Name"
                    />
                    <InputLabel sx={{ m: 1, typography: 'body2' }}>
                        Content
                    </InputLabel>
                    <FormControl sx={{ mb: 1 }}>
                        <Field name="content" style={{ marginTop: '10px' }}>
                            {({ field, form: { setFieldValue } }) => (
                                <TinyMCEEditor
                                    id="content"
                                    tinymceScriptSrc={
                                        process.env.PUBLIC_URL +
                                        '/tinymce/tinymce.min.js'
                                    }
                                    plugins="addContent"
                                    init={{
                                        ...init,
                                        toolbar: `${init.toolbar} addContentLink addInlineImage addInlineVideo`,
                                        quickbars_insert_toolbar:
                                            'table | addContentLink addInlineImage addInlineVideo',
                                    }}
                                    textareaName="content"
                                    onBlur={onBlur}
                                    onEditorChange={(content) => {
                                        setFieldValue('content', content);
                                    }}
                                    value={values.content}
                                />
                            )}
                        </Field>
                        {errors?.content && (
                            <FieldValidationError
                                validationError={errors.content}
                            />
                        )}
                    </FormControl>

                    <FormControl
                        style={{
                            margin: '15px 0 2px',
                            width: '100%',
                        }}
                    >
                        <Box display="flex">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePicker
                                    name="start_date"
                                    label="Start Date"
                                    onChange={(newValue) => {
                                        setFieldValue(
                                            'start_date',
                                            !newValue ||
                                                moment(newValue).format(
                                                    'MM/DD/YYYY hh:mm A'
                                                ) === 'Invalid date'
                                                ? ''
                                                : moment(newValue).format(
                                                      'MM/DD/YYYY hh:mm A'
                                                  )
                                        );
                                    }}
                                    value={values.start_date}
                                    renderInput={(params) => {
                                        params = {
                                            ...params,
                                            error: errors.start_date,
                                        };
                                        return (
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                {...params}
                                                sx={{
                                                    '& .Mui-disabled': {
                                                        WebkitTextFillColor:
                                                            '#000000!important',
                                                    },
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </LocalizationProvider>
                        </Box>
                    </FormControl>

                    <FormControl
                        style={{
                            margin: '15px 0 4px',
                            width: '100%',
                        }}
                    >
                        <Box display="flex">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePicker
                                    name="end_date"
                                    label="End Date"
                                    onChange={(newValue) => {
                                        setFieldValue(
                                            'end_date',
                                            !newValue ||
                                                moment(newValue).format(
                                                    'MM/DD/YYYY hh:mm A'
                                                ) === 'Invalid date'
                                                ? ''
                                                : moment(newValue).format(
                                                      'MM/DD/YYYY hh:mm A'
                                                  )
                                        );
                                    }}
                                    value={values.end_date}
                                    inputFormat="MM/DD/YYYY hh:mm A"
                                    renderInput={(params) => {
                                        params = {
                                            ...params,
                                            error: errors.end_date,
                                        };

                                        return (
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                {...params}
                                                sx={{
                                                    '& .Mui-disabled': {
                                                        WebkitTextFillColor:
                                                            '#000000!important',
                                                    },
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </LocalizationProvider>
                        </Box>
                    </FormControl>

                    <FormControl
                        style={{
                            margin: '15px 0 4px',
                            width: '100%',
                        }}
                    >
                        <Box display="flex">
                            <FormTextField
                                fullWidth={true}
                                label="Minimum Display Time (seconds)"
                                type="number"
                                name="minimum_display_time"
                                placeholder="Minimum Display Time (seconds)"
                            />
                        </Box>
                    </FormControl>

                    <button
                        className={classes.submit}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {formAction.buttonLabel}
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export { NewsFlashForm };
