import { Grid, Button, FormControl } from '@mui/material';
import { FormTextField } from 'components/TextField';

const SelectField = ({
    idx,
    values,
    setFieldValue,
    handleRemoveSelect,
    validationError,
    ...otherProps
}) => {
    return (
        <Grid
            container
            key={'select_' + idx}
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                marginTop: '6px',
            }}
            {...otherProps}
        >
            <Grid item xs={12} sm={12} md={5} lg={5}>
                <FormTextField
                    fullWidth
                    label="Label"
                    placeholder="Label"
                    name={`options[${idx}].label`}
                    onChange={(e) => {
                        let currOptions = [...values.options];
                        currOptions[idx] = {
                            ...values.options[idx],
                            type: 'select',
                            label: e.target.value,
                        };
                        setFieldValue(`options[${idx}].label`, e.target.value);
                    }}
                    error={!!validationError[`options[${idx}].label`]}
                    helperText={validationError[`options[${idx}].label`]}
                    {...otherProps}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
                <FormTextField
                    fullWidth
                    label="Value"
                    placeholder="Value"
                    name={`options[${idx}].value`}
                    onChange={(e) => {
                        let currOptions = [...values.options];
                        currOptions[idx] = {
                            ...values.options[idx],
                            type: 'select',
                            value: e.target.value,
                        };
                        setFieldValue(`options[${idx}].value`, e.target.value);
                    }}
                    error={!!validationError[`options[${idx}].value`]}
                    helperText={validationError[`options[${idx}].value`]}
                    {...otherProps}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1}>
                <FormControl fullWidth>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            handleRemoveSelect(idx);
                        }}
                    >
                        Remove
                    </Button>{' '}
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default SelectField;
