import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import CardView from '..';
import useCategoryAsseyStyles from './styles';
import { CategoryPanel } from '../../CategoryPanel';
import { Each } from 'GeminiViewerComponent/_helpers/Each';
import { sortBy } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { getAllCategories } from '_features/category/categorySlice';
import { selectAllAssets } from '_features/assets/assetsTableSlice';
import { CategoriesAssetCard } from './components/CategoriesAssetCard';
import { NoneCategoryAssetCard } from './components/NoneCategoryAssetCard';

const CardContainer = ({
    assets,
    pageInfo,
    searchString,
    isLoadingAssets,
    readOnlyMode,
    assetType,
    assetPayload,
    setAssetPayload,
    categoryView,
}) => {
    //#region Constants
    const classes = useCategoryAsseyStyles();

    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    const [expanded, setExpanded] = useState([]);
    const [selectedAssetCategory, setSelectedAssetCategory] = useState([]);
    const [noCategoriesAsset, setNoCategoriesAsset] = useState(true);
    //#endregion State

    //#region Selectors
    const allCategories = useSelector(getAllCategories);
    const noAssetCategories = !allCategories.some((cat) => cat.asset_count > 0);

    const allAssets = useSelector(selectAllAssets);
    const noCategoryAssets = allAssets?.filter(
        (asset) => asset?.categories?.length <= 0
    );
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    useEffect(() => {
        setExpanded([]);
        setSelectedAssetCategory([]);
        setNoCategoriesAsset(true);
    }, [assetType]);

    //#endregion Effects

    //#region Methods
    const handleOpenCategory = (category) => (event, isExpanded) => {
        if (isExpanded) {
            setExpanded([...expanded, category]);
        } else {
            setExpanded([...expanded.filter((cat) => cat !== category)]);
        }
    };
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render
    return categoryView && allCategories && allCategories?.length > 0 ? (
        <Box
            display="flex"
            gap="10px"
            height="calc(100vh - 237px)"
            marginTop="10px"
        >
            <CategoryPanel
                selectedAssetCategory={selectedAssetCategory}
                setSelectedAssetCategory={setSelectedAssetCategory}
                displayNoneCategory={noCategoryAssets?.length > 0}
            />
            <Box className={classes.categoryAssetWrapper}>
                <Each
                    of={
                        selectedAssetCategory?.length > 0
                            ? selectedAssetCategory
                                  ?.filter((cat) => cat?.category_id !== 0)
                                  ?.sort(sortBy('display_name', 'DESC'))
                            : allCategories
                    }
                    render={(category) => (
                        <CategoriesAssetCard
                            category={category}
                            allCategories={allCategories}
                            searchString={searchString}
                            isLoadingAssets={isLoadingAssets}
                            readOnlyMode={readOnlyMode}
                            assetType={assetType}
                            assetPayload={assetPayload}
                            setAssetPayload={setAssetPayload}
                            expanded={expanded}
                            handleOpenCategory={handleOpenCategory}
                            selectedAssetCategory={selectedAssetCategory}
                            setNoCategoriesAsset={setNoCategoriesAsset}
                        />
                    )}
                />
                {/* None category assets */}
                {noCategoryAssets &&
                    noCategoryAssets?.length > 0 &&
                    (noAssetCategories ? (
                        <CardView
                            assets={assets}
                            pageInfo={pageInfo}
                            searchString={searchString}
                            isLoadingAssets={isLoadingAssets}
                            readOnly={readOnlyMode}
                            assetType={assetType}
                            assetPayload={assetPayload}
                            setAssetPayload={setAssetPayload}
                            headerDisplay={false}
                        />
                    ) : (
                        ((selectedAssetCategory?.length > 0 &&
                            selectedAssetCategory.some(
                                (cat) => cat.category_id === 0
                            )) ||
                            selectedAssetCategory?.length <= 0) && (
                            <NoneCategoryAssetCard
                                searchString={searchString}
                                isLoadingAssets={isLoadingAssets}
                                readOnlyMode={readOnlyMode}
                                assetType={assetType}
                                assetPayload={assetPayload}
                                setAssetPayload={setAssetPayload}
                                expanded={expanded}
                                handleOpenCategory={handleOpenCategory}
                                selectedAssetCategory={selectedAssetCategory}
                                singleCategory={
                                    (selectedAssetCategory?.length === 1 &&
                                        selectedAssetCategory.find(
                                            (cat) => cat.category_id === 0
                                        )) ||
                                    (noCategoriesAsset &&
                                        selectedAssetCategory?.length <= 0)
                                }
                            />
                        )
                    ))}
            </Box>
        </Box>
    ) : (
        <CardView
            assets={assets}
            pageInfo={pageInfo}
            searchString={searchString}
            isLoadingAssets={isLoadingAssets}
            readOnly={readOnlyMode}
            assetType={assetType}
            assetPayload={assetPayload}
            setAssetPayload={setAssetPayload}
        />
    );
    //#endregion Render
};

export { CardContainer };
