import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { resetLinkStatus } from 'GeminiViewerComponent/_features/links/linksSlice';
import { resetContentStatus } from '_features/content/contentSlice';
import { LinkTypes } from '_helpers/enumerations';
import { tabStyles } from 'GeminiViewerComponent/components/styles';

import { selectActiveAsset } from 'GeminiViewerComponent/_features/asset/assetSlice';
import {
    getActiveLinkTab,
    setActiveLinkContentSubTab,
    setActiveLinkTab,
    setDefaultItemPanelSection,
} from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import { getSortedLinkTypeIds } from 'GeminiViewerComponent/_helpers/linkTypeHelpers';

function LinkTab(props) {
    const { children, value, index, ...other } = props;

    const classes = tabStyles();

    return (
        <div
            role="tabpanel"
            className={classes.tabPanel}
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className={classes.tabPanelBox}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function tabProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export default function LinkTabs() {
    const dispatch = useDispatch();
    const classes = tabStyles();
    const activeLinkTab = useSelector(getActiveLinkTab);
    const displayAsset = useSelector(selectActiveAsset);
    let arr = [];

    const linkTypeIds = getSortedLinkTypeIds(
        displayAsset?.asset_link_types ?? []
    );

    linkTypeIds.forEach((linkTypeId) => {
        const linkTypeKey = Object.keys(LinkTypes).find(
            (key) => LinkTypes[key].id === linkTypeId
        );
        if (linkTypeKey) {
            arr.push(LinkTypes[linkTypeKey]);
        }
    });

    const handleChange = (event, newValue) => {
        dispatch(setActiveLinkTab(newValue));
        dispatch(setDefaultItemPanelSection(0));
        dispatch(setActiveLinkContentSubTab(0));
        dispatch(resetLinkStatus());
        dispatch(resetContentStatus());
    };

    return (
        <div className={classes.tabRoot}>
            <AppBar position="static" color="default">
                <Tabs
                    value={activeLinkTab}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    className={classes.tabs}
                >
                    {arr.map((x, index) => {
                        return (
                            <Tab
                                key={`tab-${index}`}
                                label={x?.label}
                                {...tabProps(index)}
                            />
                        );
                    })}
                </Tabs>
            </AppBar>

            {arr.map((x, index) => {
                return (
                    <LinkTab
                        key={`linktab-${index}`}
                        value={activeLinkTab}
                        index={index}
                    >
                        {x?.tabComponent}
                    </LinkTab>
                );
            })}
        </div>
    );
}
