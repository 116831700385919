import { setProgress } from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

const createProgressParams = (params) => {
    var onUploadProgress = params.onUploadProgress;
    var onProcessProgress = params.onProcessProgress;

    if (params.dispatch) {
        params.dispatch(
            setProgress({
                progress: 0,
                label: 'Step 1 of 2: Uploading',
                noAnimation: true,
            })
        );

        if (!onUploadProgress) {
            onUploadProgress = (progressEvent) => {
                var percentDone =
                    (progressEvent.loaded / progressEvent.total) * 100;
                params.dispatch(
                    setProgress({
                        progress: percentDone,
                        label: 'Step 1 of 2: Uploading',
                        noAnimation: percentDone === 0,
                    })
                );
            };
        }
        if (!onProcessProgress) {
            onProcessProgress = (processPercentDone) => {
                params.dispatch(
                    setProgress({
                        progress: processPercentDone,
                        label: 'Step 2 of 2: Processing',
                        noAnimation: processPercentDone === 0,
                    })
                );
            };
        }
    }

    return {
        onUploadProgress: onUploadProgress
            ? (progressEvent) => onUploadProgress(progressEvent)
            : null,
        onProcessProgress: onProcessProgress
            ? (processPercentDone) => onProcessProgress(processPercentDone)
            : null,
    };
};

const createFullProgressParams = (params) => {
    var onUploadProgress = params.onUploadProgress;
    var onProcessFullProgress = params.onProcessFullProgress;

    params.dispatch(
        setProgress({
            progress: 0,
            label: 'Step 1 of 2: Uploading',
            noAnimation: true,
        })
    );

    if (!onUploadProgress) {
        onUploadProgress = (progressEvent) => {
            var percentDone =
                (progressEvent.loaded / progressEvent.total) * 100;
            params.dispatch(
                setProgress({
                    progress: percentDone,
                    label: 'Step 1 of 2: Uploading',
                    noAnimation: percentDone === 0,
                })
            );
        };
    }
    if (!onProcessFullProgress) {
        onProcessFullProgress = (progress) => {
            var fileProgress = 0;
            if (progress.total_files) {
                fileProgress =
                    (progress.files_transferred / progress.total_files) * 100;
            } else {
                fileProgress = progress;
            }
            params.dispatch(
                setProgress({
                    progress: fileProgress,
                    label: 'Step 2 of 2: Processing',
                    noAnimation: fileProgress === 0,
                })
            );
        };
    }

    return {
        onUploadProgress: onUploadProgress
            ? (progressEvent) => onUploadProgress(progressEvent)
            : null,
        onProcessFullProgress: onProcessFullProgress
            ? (progress) => onProcessFullProgress(progress)
            : null,
    };
};

const createGeneralProgressParams = (params) => {
    var onUploadDynamicProgress = params.onUploadDynamicProgress;
    if (params.dispatch) {
        params.dispatch(
            setProgress({
                progress: 0,
                label: 'Step 0 of 0: Uploading',
                noAnimation: true,
            })
        );

        if (!onUploadDynamicProgress) {
            onUploadDynamicProgress = (progressEvent) => {
                var percentDone =
                    (progressEvent.current_step / progressEvent.total_steps) *
                    100;
                params.dispatch(
                    setProgress({
                        progress: percentDone,
                        label: `Step ${progressEvent.current_step} of ${progressEvent.total_steps}: Processing`,
                        noAnimation: percentDone === 0,
                        description: progressEvent.current_description,
                    })
                );
            };
        }
    }

    return {
        onUploadDynamicProgress: onUploadDynamicProgress
            ? (progressEvent) => onUploadDynamicProgress(progressEvent)
            : null,
    };
};

export {
    createProgressParams,
    createFullProgressParams,
    createGeneralProgressParams,
};
