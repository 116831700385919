import React from 'react';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';

const FieldType = ({
    resetForm,
    setFieldValue,
    node,
    values,
    fieldTypes,
    validationError,
}) => {
    return (
        <FormControl fullWidth error={!!validationError}>
            {values?.action === 'create' ? (
                <>
                    <InputLabel id="field-type">Field Type</InputLabel>
                    <Select
                        labelId="field-type"
                        id="field-type"
                        label="Field Type"
                        value={node?.type}
                        onChange={(e) => {
                            resetForm();
                            setFieldValue('type', e.target.value);
                            setFieldValue('label', '');
                            setFieldValue('variable', '');
                        }}
                    >
                        {fieldTypes?.length > 0 &&
                            fieldTypes.map((item) => (
                                <MenuItem
                                    key={`fieldType-${item?.id}`}
                                    value={item?.id}
                                >
                                    {!item?.display_name ? (
                                        <em>None</em>
                                    ) : (
                                        item?.display_name
                                    )}
                                </MenuItem>
                            ))}
                    </Select>
                    <FormHelperText>{validationError}</FormHelperText>
                </>
            ) : (
                <Typography
                    fontWeight="700"
                    fontSize="25px"
                    p={1}
                    textTransform="capitalize"
                >
                    {
                        fieldTypes.find((field) => field.id === node?.type)
                            ?.display_name
                    }
                </Typography>
            )}
        </FormControl>
    );
};

export { FieldType };
