import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { makeFormStyles } from '../styles';
import SetDirtyForm from 'forms/SetDirtyForm';
import { FormActions } from '_helpers/form-action';
import { FormTextField } from 'components/TextField';
import { UsageDisplay } from 'components/UsageDisplay';
import { closePanel } from '_features/common/formSlice';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    getGroupById,
    addNewGroup,
    editGroup,
    fetchGroupUsageById,
    getGroupUsage,
} from '_features/groups/groupsSlice';

const GroupForm = ({ formAction, groupId }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [loaded, setLoaded] = useState(
        formAction.id === FormActions.Create.id ? true : false
    );
    const theme = useSelector(selectActiveTheme);
    const groupUsage = useSelector(getGroupUsage);
    const classes = makeFormStyles(theme);
    const INIT_VALUES = {
        display_name: '',
        description: '',
    };
    const [initValues, setInitValues] = useState({ ...INIT_VALUES });

    useEffect(() => {
        if (formAction.id === FormActions.Edit.id) {
            const fetchData = async () => {
                await dispatch(fetchGroupUsageById(groupId));
                var objectData = await dispatch(getGroupById(groupId));
                var fetchedObject = objectData.payload;

                setInitValues({
                    display_name: fetchedObject.display_name,
                    description: fetchedObject.description,
                });
                setLoaded(true);
            };
            fetchData();
        } else {
            setInitValues({ ...INIT_VALUES });
        }
    }, [groupId]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        dispatch(activateLoading({ showProgress: true }));
        var resultAction = null;

        switch (formAction.id) {
            case FormActions.Edit.id:
                resultAction = await dispatch(
                    editGroup({ groupId, groupUpdates: { ...values } })
                );
                if (!resultAction.error) {
                    dispatch(closePanel({ formKey: 'groupForm' }));
                }
                break;
            case FormActions.Create.id:
                resultAction = await dispatch(addNewGroup({ ...values }));
                resetForm();
                break;
            default:
                break;
        }
        dispatch(deactivateLoading());
        const { message, variant } = snackbarHandler(
            resultAction.meta.requestStatus,
            formAction.label
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });
        setSubmitting(false);
    };

    return !loaded ? (
        <LoadingLogo styleClassName={classes.loadingSvg} />
    ) : (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            validationSchema={Yup.object({
                display_name: Yup.string()
                    .max(100, 'Must be 100 characters or less')
                    .required('Required'),
                description: Yup.string()
                    .max(255, 'Must be 255 characters or less')
                    .required('Required'),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values, setSubmitting, resetForm);
            }}
        >
            {({ isSubmitting }) => (
                <Form className={classes.form}>
                    <div className={classes.formHeader}>Group Details</div>
                    <FormTextField
                        autoFocus
                        label="Group Name"
                        name="display_name"
                        placeholder="Group Name"
                    />
                    <FormTextField
                        label="Description"
                        name="description"
                        placeholder="Description"
                    />
                    <SetDirtyForm />
                    <button
                        className={classes.submit}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {formAction.buttonLabel}
                    </button>
                    {formAction.id === FormActions.Edit.id &&
                        Object.keys(groupUsage ?? {})?.length > 0 && (
                            <UsageDisplay
                                data={groupUsage}
                                listHeader="Group Locations"
                                id="group-usage-id"
                            />
                        )}
                </Form>
            )}
        </Formik>
    );
};

export { GroupForm };
