import TopImage from 'GeminiViewerComponent/assets/companion_app_top.svg';
import BottomImage from 'GeminiViewerComponent/assets/companion_app_bottom.svg';
import LeftImage from 'GeminiViewerComponent/assets/companion_app_left.svg';
import RightImage from 'GeminiViewerComponent/assets/companion_app_right.svg';
import WindowImage from 'GeminiViewerComponent/assets/companion_app_window.svg';

import { MdTab } from 'react-icons/md';

export const DisplayTypes = {
    Inline: {
        id: 1,
        label: 'Display Inline',
    },
    External: {
        id: 2,
        label: 'Display External',
    },
    CompanionApp: {
        id: 3,
        label: 'Companion App',
    },
};

export const HotspotIconTypes = {
    Item: {
        id: 1,
        label: 'Item',
    },
    Navigation: {
        id: 2,
        label: 'Navigation',
    },
};

export const LinkTypeIds = {
    Document: 1,
    Video: 2,
    System: 3,
    Audio: 4,
    Learning: 5,
    SME: 6,
    Image: 7,
    CustomData: 8,
    Schoox: 9,
    Embed: 10,
    Procedure: 11,
    Model: 12,
    TechU: 13,
};
export const LinkTypeSortOrder = {
    Image: 1,
    Video: 2,
    Audio: 3,
    Document: 4,
    Model: 5,
    Procedure: 6,
    SME: 7,
    Learning: 8,
    TechU: 9,
    Schoox: 10,
    CustomData: 11,
    Embed: 12,
    System: 13,
};

export const SearchParams = {
    UserMode: 'user-mode',
    UserId: 'user-id',
    UserName: 'user-name',
    UserRole: 'role',
    UserException: 'user-exception',
    UserRedirect: 'redirect-url',
};

export const UserModes = {
    Anonymous: 'anonymous',
    Parameter: 'param',
    Form: 'form',
    Scorm: 'scorm',
};

export const UserExceptions = {
    Deny: 'deny',
    Continue: 'continue',
    Redirect: 'redirect',
};

export const CaptionLanguages = {
    EnglishUS: { DisplayName: 'English, US', LanguageCode: 'en-US' },
    EnglishUK: { DisplayName: 'English, UK', LanguageCode: 'en-GB' },
    SpanishUS: { DisplayName: 'Spanish, US', LanguageCode: 'es-US' },
    SpanishES: { DisplayName: 'Spanish, ES', LanguageCode: 'es-ES' },
};

export const ProcedureSplitViewTypes = {
    Left: {
        id: 4,
        type: 'left',
        label: 'Asset Right',
        drawerValue: 'left',
        icon: LeftImage,
    },
    Right: {
        id: 3,
        type: 'right',
        label: 'Asset Left',
        drawerValue: 'right',
        icon: RightImage,
    },
    Above: {
        id: 2,
        type: 'above',
        label: 'Asset Bottom',
        drawerValue: 'top',
        icon: TopImage,
    },
    Below: {
        id: 1,
        type: 'below',
        label: 'Asset Top',
        drawerValue: 'bottom',
        icon: BottomImage,
    },
};

export const ProcedureSessionStatus = {
    InProgress: {
        id: 1,
        label: 'In Progress',
    },
    Submitted: {
        id: 2,
        label: 'Submitted',
    },
    Resolved: {
        id: 3,
        label: 'Resolved',
    },
    Archived: {
        id: 4,
        label: 'Archived',
    },
};

export const ProcedureStatusOptions = [
    { status_id: 1, display_name: 'In Progress', display_color: '#293e4f' },
    { status_id: 2, display_name: 'Open', display_color: '#8a8a8a' },
    { status_id: 3, display_name: 'Resolved', display_color: '#01a172' },
    { status_id: 4, display_name: 'Archived', display_color: '#2a8fa1' },
];

export const CompanionAppMode = [
    { id: 1, display_name: 'Companion Left', imageIcon: LeftImage },
    { id: 2, display_name: 'Companion Right', imageIcon: RightImage },
    { id: 3, display_name: 'Companion Top', imageIcon: TopImage },
    { id: 4, display_name: 'Companion Bottom', imageIcon: BottomImage },
    { id: 5, display_name: 'Tab', icon: <MdTab color="#000000" /> },
    { id: 6, display_name: 'Window', imageIcon: WindowImage },
];

export const CompanionAppModes = {
    Left: { id: 1, display_name: 'Asset Right', imageIcon: LeftImage },
    Right: { id: 2, display_name: 'Asset Left', imageIcon: RightImage },
    Top: { id: 3, display_name: 'Asset Bottom', imageIcon: TopImage },
    Bottom: { id: 4, display_name: 'Asset Top', imageIcon: BottomImage },
    Tab: { id: 5, display_name: 'Tab', icon: <MdTab color="#000000" /> },
    Window: { id: 6, display_name: 'Window', imageIcon: WindowImage },
};

export const DisplayAnswer = {
    failed_only: { display_name: 'Show Failed Only', value: 'failed_only' },
    correct_only: { display_name: 'Show Correct Only', value: 'correct_only' },
    all_results: { display_name: 'Show All', value: 'all_results' },
    no_results: { display_name: "Don't show results", value: 'no_results' },
};

export const cellAlignment = {
    top: { display_name: 'Top', value: 'top' },
    middle: { display_name: 'Middle', value: 'middle' },
    bottom: { display_name: 'Bottom', value: 'bottom' },
};

export const NewsFlashDisplayOptions = {
    everyTime: { id: 1, display_name: 'Every Time' },
    oncePerSession: { id: 3, display_name: 'Once per Session' },
    oncePerUser: { id: 2, display_name: 'Once per User' },
};

export const ChapterButtonsLocation = {
    1: 'Left',
    2: 'Right',
    3: 'Top',
    4: 'Bottom',
};
