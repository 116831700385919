var themes = {
    default: {
        id: 'T_001',
        name: 'Default',
        colors: {
            primarySectionBackground: '#3a4454',
            primarySectionForeground: '#FFFFFF',
            secondarySectionBackground: '#677383',
            secondarySectionForeground: '#FFFFFF',
            main_background_color: '#000000',
            tag_active_background_color: '#FFFFFF',
            tag_inactive_background_color: '#CCCCCC',
            icon_active_color: '#FFFFFF',
            icon_inactive_color: '#777777',
            pano_background_color: '#FFFFFF',
            orbit_background_color: '#FFFFFF',
            link_button_background_color: '#F5F5F5',
            link_button_text_color: '#34485E',
            link_visited_color: '#723bb9',
            link_hover_color: '#ff0000',
            link_text_color: '#0000EE',
            side_panel_body: '#CCCCCC',
            body: '#FFFFFF',
            text: '#000000',
            button: {
                primaryBackground: '#3598db',
                primaryForeground: '#FFFFFF',
                secondaryBackground: '#c4c4c4',
                secondaryForeground: '#4a4a4a',
                accentBackground: '#8f8f8f',
                accentForeground: '#FFFFFF',
            },
            chapter_button: {
                background_color: '#3598db',
                text_color: '#FFFFFF',
                active_color: '#3B3B3B',
            },
        },
        opacity: {
            main_background_opacity: 30,
            tag_background_opacity: 100,
            tag_foreground_opacity: 100,
        },
        font: 'Tinos',
    },
};

export default themes;
