import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { CompanionAppsTable } from './CompanionAppsTable';
import {
    fetchAllComapanionApps,
    getAllCompanionApps,
    getCompanionLoadingStatus,
    getCompanionPageInfo,
    resetCompanionAppStatus,
} from 'GeminiViewerComponent/_features/companionApps/companionAppsSlice';

const CompanionAppsTab = () => {
    const dispatch = useDispatch();
    const ref = useRef(false);
    const companionApps = useSelector(getAllCompanionApps);
    const companionStatus = useSelector(getCompanionLoadingStatus);
    const pageInfo = useSelector(getCompanionPageInfo);
    const [companionPayload, setCompanionPayload] = useState({
        sort: 'display_name',
    });
    const [sortBy, setSortBy] = useState('display_name');
    const [sortDirection, setSortDirection] = useState('ASC');

    const getCompanionData = async (payload) => {
        dispatch(activateLoading());
        await dispatch(fetchAllComapanionApps({ ...payload }));
        dispatch(deactivateLoading());
    };

    useEffect(() => {
        if (ref.current === true) {
            getCompanionData({ ...companionPayload });
        }
        return () => {
            dispatch(resetCompanionAppStatus());
        };
    }, [companionPayload]);

    useEffect(() => {
        if (companionStatus === LoadingStatus.Idle) {
            getCompanionData({});
        }
        ref.current = true;
    }, [companionStatus]);

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        if (_sortBy === 'is_default') {
            _sortDirection = _sortDirection === 'ASC' ? 'DESC' : 'ASC';
        }
        setCompanionPayload({
            ...companionPayload,
            sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
        });
    };

    return (
        <CompanionAppsTable
            companionApps={companionApps}
            pageInfo={pageInfo}
            companionPayload={companionPayload}
            setCompanionPayload={setCompanionPayload}
            sortDirection={sortDirection}
            sortBy={sortBy}
            handleSort={(_sortBy, _sortDirection) =>
                setSorting(_sortBy, _sortDirection)
            }
        />
    );
};

export { CompanionAppsTab };
