class Rotateable {
    dragging = false;
    disableClick = false;
    currentX;
    currentY;
    initialX;
    initialY;
    offsetX;
    offsetY;
    moved = false;
    dragElement;
    draggingHotspot;
    onMovedElement;

    getRotation(x1, y1, x2, y2) {
        return Math.atan2(x1 - x2, -(y1 - y2)) * (180 / Math.PI);
    }

    handleMouseDown = (event, hotspot, onRotated) => {
        this.onRotatedElement = onRotated;

        window.geminiViewManager.disableControls();
        this.dragging = true;
        this.draggingHotspot = hotspot;

        this.dragElement = event.currentTarget.parentNode.children[0];

        this.startRotation = Math.round(hotspot.rotation);

        let boxBoundingRect = this.dragElement.getBoundingClientRect();
        this.boxCenter = {
            x: boxBoundingRect.left + boxBoundingRect.width / 2,
            y: boxBoundingRect.top + boxBoundingRect.height / 2,
        };

        if (event.type === 'touchstart') {
            this.initialX = event.touches[0].clientX;
            this.initialY = event.touches[0].clientY;
        } else {
            this.initialX = event.clientX;
            this.initialY = event.clientY;
        }

        this.initialRotation = Math.round(
            this.getRotation(
                this.boxCenter.x,
                this.boxCenter.y,
                this.initialX,
                this.initialY
            )
        );

        document.onpointerup = this.closeDragElement;
        document.onpointermove = this.elementDrag;

        // document.onmouseup = this.closeDragElement;
        // document.ontouchend = this.closeDragElement;

        // document.onmousemove = this.elementDrag;
        // document.ontouchmove = this.elementDrag;
    };

    elementDrag = (e) => {
        e = e || window.event;

        this.disableClick = true;

        if (e.type === 'touchmove') {
            this.currentX = e.touches[0].clientX;
            this.currentY = e.touches[0].clientY;
        } else {
            this.currentX = e.clientX;
            this.currentY = e.clientY;
        }

        let currentRotation = this.getRotation(
            this.boxCenter.x,
            this.boxCenter.y,
            this.currentX,
            this.currentY
        );

        currentRotation = Math.round(currentRotation);

        this.newRotation = Math.round(
            this.startRotation + (currentRotation - this.initialRotation)
        );
        this.newRotation = Math.round(this.newRotation / 45) * 45;

        this.offsetX = this.currentX;
        this.offsetY = this.currentY;

        this.dragElement.style.transform = `rotate(${this.newRotation}deg)`;

        this.moved = true;
    };

    closeDragElement = () => {
        window.geminiViewManager.enableControls();

        document.onpointerup = null;
        document.onpointermove = null;

        document.onmouseup = null;
        document.ontouchend = null;
        document.onmousemove = null;
        document.ontouchmove = null;

        if (this.moved) {
            this.moved = false;

            if (this.onRotatedElement) {
                this.newRotation = this.newRotation % 360;
                if (this.newRotation < 0) {
                    this.newRotation = this.newRotation + 360;
                }
                this.onRotatedElement(this.draggingHotspot, this.newRotation);
            }
        }

        setTimeout(() => {
            this.disableClick = false;
        }, 100);
    };
}

export let rotateable = new Rotateable();
