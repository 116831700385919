import React from 'react';
import { Box, Typography } from '@mui/material';

import dataNotFoundStyles from './styles';
import create_asset from 'assets/create_asset.png';

const DataNotFound = ({
    title,
    inputLength = null,
    insideModal = false,
    addPlural = true,
}) => {
    const classes = dataNotFoundStyles();
    const pluralPre = addPlural ? 'are' : 'is';
    return (
        <Box
            className={classes.container}
            style={{ height: insideModal ? '100%' : '100vh' }}
        >
            <Box className={classes.content}>
                <Box className={classes.title}>
                    <Typography className={classes.text}>
                        {inputLength === 0
                            ? `There ${pluralPre} no ${title} created yet. Start by creating
                            one.`
                            : `No Matching ${title}.`}
                    </Typography>
                    <img
                        className={classes.image}
                        alt="asset"
                        src={create_asset}
                    ></img>
                </Box>
            </Box>
        </Box>
    );
};

export { DataNotFound };
