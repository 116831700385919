import React, { useCallback } from 'react';
import { Dialog, DialogContent, Chip } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { importAssetStyle } from './style';
import Dropzone from 'react-dropzone';
import { MdClose } from 'react-icons/md';
import { useSnackbar } from 'notistack';
import { unwrapResult } from '@reduxjs/toolkit';

import { accountsSlice } from 'app/store';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { importAsset } from '_features/assets/assetsTableSlice';
import { resetGroupState } from '_features/groups/groupsSlice';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';

const ImportAsset = ({ openDialog, setOpenDialog }) => {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    const classes = importAssetStyle(theme);
    const { enqueueSnackbar } = useSnackbar();

    const onDrop = useCallback(({ file, setFieldValue }) => {
        setFieldValue('import_file', file);

        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = (e) => {};
        reader.readAsDataURL(file);
    }, []);

    const handleSubmit = async (values) => {
        dispatch(activateLoading({ showProgress: true }));
        try {
            let resultAction = await dispatch(
                importAsset({
                    ...values,
                })
            );
            dispatch(deactivateLoading());
            setOpenDialog(false);
            dispatch(resetGroupState());
            unwrapResult(resultAction);
            const { message, variant } = snackbarHandler(
                resultAction.meta.requestStatus,
                'Import',
                resultAction.payload.message
            );
            enqueueSnackbar(message, {
                action: (key) => <SnackbarDismiss snackItem={key} />,
                variant: variant,
            });
        } catch (err) {
            dispatch(deactivateLoading());
            setOpenDialog(false);
            enqueueSnackbar(
                'Import Failed' + (err?.message ? `: ${err?.message}` : ''),
                {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'error',
                }
            );
        }
    };

    return (
        <Dialog
            fullScreen={false}
            open={openDialog}
            disableEnforceFocus={true}
            onClose={() => setOpenDialog(false)}
        >
            <DialogContent>
                <Formik
                    initialValues={{ import_file: null }}
                    onSubmit={(values) => {
                        handleSubmit(values);
                    }}
                >
                    {({ values }) => (
                        <Form className={classes.form}>
                            <div className={classes.formHeader}>
                                Import Asset
                            </div>
                            <Field
                                className={classes.input}
                                name="import_file"
                                type="file"
                            >
                                {({
                                    field: { value },
                                    form: { setFieldValue },
                                }) => (
                                    <div className={classes.dropzoneContainer}>
                                        <Dropzone
                                            accept={'.json'}
                                            maxFiles={1}
                                            maxSize={31457280}
                                            multiple={false}
                                            onDropAccepted={(files) => {
                                                onDrop({
                                                    file: files[0],
                                                    setFieldValue:
                                                        setFieldValue,
                                                });
                                            }}
                                            noClick={readOnlyMode}
                                        >
                                            {({
                                                getRootProps,
                                                getInputProps,
                                            }) => (
                                                <div
                                                    {...getRootProps()}
                                                    className={
                                                        classes.dropzoneArea
                                                    }
                                                    style={{
                                                        backgroundRepeat:
                                                            'no-repeat',
                                                        backgroundSize:
                                                            'contain',
                                                        backgroundPosition:
                                                            'center center',
                                                    }}
                                                >
                                                    <input
                                                        {...getInputProps()}
                                                    />
                                                    {!values.import_file && (
                                                        <div
                                                            className={
                                                                classes.dropzoneText
                                                            }
                                                        >
                                                            Select File
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </Dropzone>
                                        <div
                                            className={classes.previewContainer}
                                        >
                                            {value &&
                                                Object.keys(value).length >
                                                    0 && (
                                                    <Chip
                                                        className={
                                                            classes.previewUploadFileName
                                                        }
                                                        color="secondary"
                                                        size="small"
                                                        deleteIcon={
                                                            <MdClose className="react-icon" />
                                                        }
                                                        onDelete={() => {
                                                            if (!readOnlyMode) {
                                                                setFieldValue(
                                                                    'import_file',
                                                                    null
                                                                );
                                                            }
                                                        }}
                                                        label={value?.name}
                                                    />
                                                )}
                                        </div>
                                    </div>
                                )}
                            </Field>
                            {readOnlyMode !== true && (
                                <button
                                    className={classes.submit}
                                    type="submit"
                                    disabled={Boolean(!values?.import_file)}
                                >
                                    Submit
                                </button>
                            )}
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default ImportAsset;
