import { Grid, Button, FormControl } from '@mui/material';
import { FormTextField } from 'components/TextField';

const TableColumnHeaderField = ({
    idx,
    values,
    setFieldValue,
    handleRemoveTableColumn,
    ...otherProps
}) => {
    return (
        <Grid
            container
            key={'table_' + idx}
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                marginTop: '6px',
            }}
            {...otherProps}
        >
            <Grid item xs={12} sm={12} md={5} lg={5}>
                <FormTextField
                    fullWidth
                    label="Header"
                    placeholder="Header"
                    name={`columns[${idx}].label`}
                    onChange={(e) => {
                        let currOptions = values.columns[idx];
                        currOptions = {
                            ...currOptions,
                            label: e.target.value,
                        };
                        setFieldValue(`columns[${idx}]`, currOptions);
                    }}
                    {...otherProps}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
                <FormTextField
                    type="number"
                    label="Width"
                    placeholder="Width(%)"
                    name={`columns[${idx}].width`}
                    onChange={(e) => {
                        let currOptions = values.columns[idx];
                        currOptions = {
                            ...currOptions,
                            width: e.target.value,
                        };
                        setFieldValue(`columns[${idx}]`, currOptions);
                    }}
                    {...otherProps}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
                <FormTextField
                    fullWidth
                    type="number"
                    label="Colspan"
                    placeholder="Colspan"
                    name={`columns[${idx}].colspan`}
                    onChange={(e) => {
                        let currOptions = values.columns[idx];
                        currOptions = {
                            ...currOptions,
                            colspan: e.target.value,
                        };
                        setFieldValue(`columns[${idx}]`, currOptions);
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1}>
                <FormControl fullWidth>
                    <Button
                        variant="outlined"
                        onClick={() => handleRemoveTableColumn(idx)}
                    >
                        Remove
                    </Button>{' '}
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default TableColumnHeaderField;
