import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useSelector } from 'react-redux';

import { makeFormStyles } from 'forms/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

const LinkField = ({ values, setFieldValue, procedureNodes }) => {
    const theme = useSelector(selectActiveTheme);
    const commonFormStyles = makeFormStyles(theme);
    return (
        <FormControl fullWidth style={{ marginTop: '10px' }}>
            <InputLabel id="target-node-id">Target Node</InputLabel>
            <Select
                className={commonFormStyles.materialSelect}
                style={{ margin: '0' }}
                labelId="target-node-id"
                margin="dense"
                id="target_node_id"
                label="Target Node"
                placeholder="Target Node"
                name={`target_node_id`}
                value={values?.link?.id}
                onChange={(e) => {
                    setFieldValue('link', {
                        id: e.target.value,
                        type: 'node',
                    });
                }}
            >
                <MenuItem key={'next'} value={'next'}>
                    Next
                </MenuItem>
                <MenuItem key={'back'} value={'back'}>
                    Back
                </MenuItem>
                {procedureNodes?.length > 0 &&
                    procedureNodes.map((nd) => (
                        <MenuItem key={nd?.id} value={nd?.id}>
                            {nd?.title} - #{nd?.id}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};

export default LinkField;
