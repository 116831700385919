export const LrsVerbs = {
    Answered: {
        id: 'http://adlnet.gov/expapi/verbs/answered',
        display: 'answered',
    },
    Interacted: {
        id: 'http://activitystrea.ms/schema/1.0/interact',
        display: 'interacted',
    },
    Accessed: {
        id: 'http://activitystrea.ms/schema/1.0/access',
        display: 'accessed',
    },
    Shared: {
        id: 'http://activitystrea.ms/schema/1.0/share',
        display: 'shared',
    },
    Submitted: {
        id: 'http://activitystrea.ms/schema/1.0/submit',
        display: 'submitted',
    },
    Completed: {
        id: 'http://adlnet.gov/expapi/verbs/completed',
        display: 'completed',
    },
    Viewed: { id: 'http://id.tincanapi.com/verb/viewed', display: 'viewed' },
    Launched: {
        id: 'http://adlnet.gov/expapi/verbs/launched',
        display: 'launched',
    },
    LoggIn: {
        id: 'https://brindlewaye.com/xAPITerms/verbs/loggedin/',
        display: 'loggedin',
    },
    Progressed: {
        id: 'http://adlnet.gov/expapi/verbs/progressed',
        display: 'progressed',
    },
    Updated: {
        id: 'http://activitystrea.ms/schema/1.0/update',
        display: 'updated',
    },
    Experienced: {
        id: 'http://activitystrea.ms/schema/1.0/experience',
        display: 'experienced',
    },
    WasAt: { id: 'http://activitystrea.ms/schema/1.0/at', display: 'was at' },
};
