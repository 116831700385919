import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchExternalContentPage,
    selectAllExternalContent,
    getExternalContentPageInfo,
} from '_features/content/contentSlice';
import { TableGrid } from './TableGrid';
import { SchooxQueryTableInputHeader } from './SchooxQueryTableInputHeader';
import { ContentTypes } from '_helpers';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { selectSelectedZoneTag } from '_features/zones/zonesTableSlice';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';

function SchooxQueryTable() {
    const dispatch = useDispatch();
    const [category, setCategory] = useState('');
    const [filterByTag, setFilterByTag] = useState(false);
    const [searchString, setSearchString] = useState('');
    const zoneTag = useSelector((state) => selectSelectedZoneTag(state));

    const content = useSelector(selectAllExternalContent);
    const pageInfo = useSelector((state) => getExternalContentPageInfo(state));

    const contentStatus = useSelector((state) => state.content.status);
    useEffect(() => {
        if (contentStatus === LoadingStatus.Idle) {
            dispatch(
                fetchExternalContentPage({
                    page: 1,
                    pageSize: 10,
                    contentType: ContentTypes.Schoox.label,
                    searchString: searchString,
                    useTagFilter: filterByTag,
                    tagFilter: zoneTag ?? '',
                    categoryId: category,
                })
            );
        }
    }, [contentStatus, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    // eslint-disable-next-line
    const debouncedSearch = useCallback(
        debounce((searchString, category, filterByTag) => {
            setSearchString(searchString);
            dispatch(
                fetchExternalContentPage({
                    page: 1,
                    pageSize: 10,
                    contentType: ContentTypes.Schoox.label,
                    searchString: searchString,
                    useTagFilter: filterByTag,
                    tagFilter: zoneTag ?? '',
                    categoryId: category,
                })
            );
        }, 1000),
        []
    );

    const handleSearch = (searchString) => {
        debouncedSearch(searchString, category, filterByTag);
    };

    const updateInputValue = (evt) => {
        var searchString = evt.target.value;
        handleSearch(searchString);
    };

    return (
        <React.Fragment>
            <SchooxQueryTableInputHeader
                category={category}
                filterByTag={filterByTag}
                setCategory={setCategory}
                setFilterByTag={setFilterByTag}
                updateInputValue={updateInputValue}
                zoneTag={zoneTag}
            />
            <TableGrid
                externalAssests={content}
                pageInfo={pageInfo}
                filterByTag={filterByTag}
                category={category}
                searchString={searchString}
                zoneTag={zoneTag}
            />
        </React.Fragment>
    );
}

export { SchooxQueryTable };
