import React, { useState, useRef, useEffect, useCallback } from 'react';
import { MdAdd } from 'react-icons/md';
import { IconButton, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Action } from '_helpers';
import { useStyles } from './styles';
import SearchWithFilters from 'components/SearchWithFilters';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { makeButtonStyles } from '../../../../GeminiViewerComponent/components/styles';
import { ResizableTable } from 'GeminiViewerComponent/components/_Tables/ResizableTable';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    closePanel,
    openPanel,
    setEditObjectId,
} from '_features/common/formSlice';
import {
    setHotspotAsDefault,
    setHotspotAsHighlight,
    setPopulatedHotspot,
    setUnPopulatedHotspot,
} from 'shared/hotspotsSlice';
import {
    resizableLabelHeader,
    resizableLabelCell,
    resizableActionCell,
    resizableCheckCell,
    resizableHotspotPreviewCell,
    resizableCheckHeaderCell,
    resizableConditionalLabelCell,
    resizableHotspotDefaultIcon,
    resizableHotspotPopulatedIcon,
} from 'components';

function HotspotTable({
    hotspots,
    pageInfo,
    hotspotPayload,
    setHotspotPayload,
    sortDirection,
    sortBy,
    handleSort,
}) {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const buttonStyles = makeButtonStyles(theme);
    const classes = useStyles();
    const tableClasses = tableStyles();

    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = React.useState(false);

    const loadedRowsMap = useSelector(
        (state) => state.hotspots.loadedHotspotsMap
    );

    const headerHeight = 40;
    const rowHeight = 60;
    const isSelected = (id) => selected.indexOf(id) !== -1;

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== hotspots.length) {
            setSelected(hotspots.map((row) => row.hotspot_icon_id));
            setSelectedAll(true);
        }
    }

    const handleClick = (event, hotspot_icon_id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(hotspot_icon_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, hotspot_icon_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        setSelectedAll(newSelected.length === hotspots.length);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedIds = hotspots.map(
                (hotspot) => hotspot.hotspot_icon_id
            );
            setSelected(newSelectedIds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    const updateDefaultHotspotIcon = async (rowData) => {
        if (!rowData.is_default) {
            await dispatch(activateLoading());
            await dispatch(setHotspotAsDefault(rowData?.hotspot_icon_id));
            await dispatch(deactivateLoading());
        }
    };

    const updateHighlightHotspotIcon = async (rowData) => {
        if (!rowData.is_highlight) {
            await dispatch(activateLoading());
            await dispatch(setHotspotAsHighlight(rowData?.hotspot_icon_id));
            await dispatch(deactivateLoading());
        }
    };

    const updatePopulatedHotspotIcon = async (rowData) => {
        if (!rowData.is_populated) {
            await dispatch(activateLoading());
            await dispatch(setPopulatedHotspot(rowData?.hotspot_icon_id));
            await dispatch(deactivateLoading());
        }
    };

    const updateUnPopulatedHotspotIcon = async (rowData) => {
        if (!rowData.is_unpopulated) {
            await dispatch(activateLoading());
            await dispatch(setUnPopulatedHotspot(rowData?.hotspot_icon_id));
            await dispatch(deactivateLoading());
        }
    };

    const handleRowClick = async ({ rowData }) => {
        await dispatch(closePanel({ formKey: 'hotspotForm' }));
        if (rowData?.hotspot_icon_id) {
            await dispatch(
                setEditObjectId({
                    formKey: `hotspotForm`,
                    object_id: rowData?.hotspot_icon_id,
                })
            );
            dispatch(openPanel({ formKey: `hotspotForm`, formAction: 'Edit' }));
        }
    };

    const columns = [
        {
            accessorKey: 'hotspot_icon_id',
            size: 50,
            minSize: 50,
            enableResizing: false,
            label: 'Select',
            padding: 'checkbox',
            cellLevel: 'hotspot_icon',
            numSelected: selected.length,
            rowCount: hotspots.length,
            handleClick: handleClick,
            handleSelectAllClick: handleSelectAllClick,
            isSelected: isSelected,
            header: (info) =>
                resizableCheckHeaderCell(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableCheckCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'hotspot_icon_id',
            size: 50,
            minSize: 50,
            enableResizing: false,
            padding: 'none',
            label: '',
            actionCellLevel: 'hotspot_icon',
            targetPopup: 'hotspotManagement',
            formKey: 'hotspotForm',
            dispatch: dispatch,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableActionCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'display_name',
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'Hotspot',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'image_url',
            flexGrow: 1,
            size: 120,
            minSize: 120,
            enableResizing: false,
            label: 'Preview Image',
            padding: 'normal',
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableHotspotPreviewCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'hotspot_type_id',
            flexGrow: 1,
            size: 200,
            minSize: 200,
            label: 'Type',
            enableResizing: false,
            padding: 'normal',
            conditionalFn: (data) => (data === 1 ? 'Item' : 'Navigation'),
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableConditionalLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'is_default',
            flexGrow: 1,
            size: 120,
            minSize: 120,
            label: 'Default',
            enableResizing: false,
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            onClick: updateDefaultHotspotIcon,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableHotspotDefaultIcon(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'is_highlight',
            flexGrow: 1,
            size: 120,
            minSize: 120,
            label: 'Highlight',
            enableResizing: false,
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            onClick: updateHighlightHotspotIcon,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableHotspotDefaultIcon(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'is_populated',
            flexGrow: 1,
            size: 120,
            minSize: 120,
            label: 'Populated',
            enableResizing: false,
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            onClick: updatePopulatedHotspotIcon,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableHotspotPopulatedIcon(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'is_unpopulated',
            flexGrow: 1,
            size: 120,
            minSize: 120,
            label: 'Unpopulated',
            enableResizing: false,
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            onClick: updateUnPopulatedHotspotIcon,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableHotspotPopulatedIcon(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
    ];

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    // eslint-disable-next-line no-unused-vars
    function loadMoreRows({ startIndex, stopIndex }) {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setHotspotPayload({
                ...hotspotPayload,
                searchString: searchString,
            });
        }, 1000),
        [hotspotPayload]
    );

    const handleCreateNew = () => {
        dispatch(
            openPanel({
                formKey: `hotspotForm`,
                formAction: 'Create',
                clearEditId: true,
            })
        );
    };

    return (
        <div className={classes.root}>
            <div style={{ display: 'flex', gap: '5px', marginBottom: '10px' }}>
                <Tooltip title="Add New Hotspot Icon">
                    <IconButton
                        onClick={handleCreateNew}
                        size="large"
                        className={buttonStyles.contentAddButton}
                    >
                        <MdAdd className="react-icon" />
                    </IconButton>
                </Tooltip>
                <div style={{ flexGrow: 1 }}></div>
                <SearchWithFilters
                    filter={false}
                    onChange={(evt) => debouncedSearch(evt.target.value)}
                />
            </div>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    toolTipTitle={'Delete'}
                    totalCount={pageInfo.TotalCount}
                >
                    <PopupAction
                        action={Action.DeleteArray}
                        object={{
                            hotspotIds: selected,
                            setHotspotIds: setSelected,
                        }}
                        level={'hotspot_icon'}
                        showLabel={false}
                    />
                </EnhancedTableToolbar>
            )}
            <div
                style={{
                    height: `calc(100vh - ${
                        selected.length > 0 ? '345px' : '280px'
                    })`,
                    minWidth: '500px',
                }}
            >
                <ResizableTable
                    initialRows={hotspots}
                    allRows={hotspots}
                    totalRowCount={pageInfo.TotalCount}
                    loadedRowsMap={loadedRowsMap}
                    loadMoreRows={loadMoreRows}
                    columns={columns}
                    cellClassName={tableClasses.flexContainer}
                    className={tableClasses.table}
                    rowClassName={tableClasses.flexContainer}
                    headerHeight={headerHeight}
                    rowHeight={rowHeight}
                    onRowClick={handleRowClick}
                    // selected={selected}
                />
            </div>
        </div>
    );
}

export { HotspotTable };
