import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    fetchDeployementPackagePage,
    getDeploymentPackagePageInfo,
    getDeploymentPackageStatus,
    resetDeploymentPackageState,
    selectAllDeploymentPackage,
} from '_features/jobs/jobsSlice';
import { DeploymentPackageTable } from './DeploymentPackageTable';
import { closePanel } from '_features/common/formSlice';

function DeploymentPackageTab() {
    const dispatch = useDispatch();
    const deploymentPackage = useSelector(selectAllDeploymentPackage);
    const deploymentPackageStatus = useSelector(getDeploymentPackageStatus);
    const pageInfo = useSelector(getDeploymentPackagePageInfo);

    const queryParams = new URLSearchParams(window.location.search);
    const deploymentId = +queryParams.get('id');

    const getDeploymentData = async (payload) => {
        dispatch(activateLoading());
        await dispatch(fetchDeployementPackagePage({ ...payload }));
        dispatch(deactivateLoading());
    };

    useEffect(() => {
        return async () => {
            await dispatch(resetDeploymentPackageState());
            dispatch(
                closePanel({
                    formKey: 'managementDeployForm',
                })
            );
        };
    }, []);

    useEffect(() => {
        if (deploymentPackageStatus === LoadingStatus.Idle) {
            getDeploymentData({
                page: 1,
                pageSize: 10,
                deployment_package_id: deploymentId,
            });
        }
    }, [deploymentPackageStatus]);

    return (
        <DeploymentPackageTable
            deploymentPackage={deploymentPackage}
            pageInfo={pageInfo}
        />
    );
}

export { DeploymentPackageTab };
