import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import { LuUnfoldHorizontal } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';
import { MdApps, MdClose, MdEdit, MdPreview } from 'react-icons/md';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';

import { FormDisplayer } from 'forms/FormDisplayer';
import { OtherZonesLink } from 'components/OtherZonesLink';
import LinkTabs from 'components/_Layout/LinkPanel/LinkTabs';
import { setEnableMultipleHotspot } from 'shared/hotspotsSlice';
import { ProcedureContentLoader } from './ProcedureContentLoader';
import { resetSchooxTab } from '_features/globals/visibilitySlice';
import { loadContentPanelItem } from 'shared/loadContentPanelSlice';
import { GeminiEditor } from 'components/GeminiEditor/GeminiEditor';
import { resetContentStatus } from '_features/content/contentSlice';
import { panelStyles } from 'GeminiViewerComponent/components/styles';
import { SidePanel } from 'GeminiViewerComponent/components/SidePanel';
import { useZoneMediaConfigValue } from 'hooks/useZoneMediaConfigValue';
import { loadItem, selectEditItem } from '_features/common/editItemSlice';
import { VisualEdit } from 'scenes/Asset/components/AssetZones/VisualEdit';
import { LinkDisplay } from 'GeminiViewerComponent/components/LinkDisplay';
import { GeminiLoader } from 'GeminiViewerComponent/components/GeminiLoader';
import { FlyerDisplay } from 'GeminiViewerComponent/components/FlyerDisplay';
import { ShortcutIcons } from 'scenes/Asset/components/ZoneItems/shortcutIcons';
import { resetLinkStatus } from 'GeminiViewerComponent/_features/links/linksSlice';
import { selectActiveAsset } from 'GeminiViewerComponent/_features/asset/assetSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    getConfig,
    updateConfig,
} from 'GeminiViewerComponent/_features/config/configSlice';
import {
    getItemTooltip,
    getNavTooltip,
    resetZonePreviewState,
} from 'GeminiViewerComponent/_features/zonePreview/zonePreviewSlice';
import {
    clearEditZone,
    selectEditZone,
    selectVisualEditZone,
    setVisualEditZone,
} from '_features/common/editZoneSlice';
import {
    isFormOpen,
    selectFormInfo,
    openPanel,
    getDirtyFlag,
    setIsDirty,
} from '_features/common/formSlice';
import {
    displayProcedureContentVersion,
    getProcedureContentVersion,
    getProcedureLoaded,
    setPanelVis,
    setTagFilters,
} from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import {
    selectViewContentPanel,
    setActiveLinkContentSubTab,
    setActiveLinkTab,
    setDefaultItemPanelSection,
    setViewContentPanel,
} from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';

// NOTE: These variables need to stay outside the react component
let isResizing = null;
const minPanelSize = 25;
const maxPanelSize = 65;

export default function ViewerSlideLayout() {
    //#region Constants
    //#endregion Constants

    //#region Hooks

    const theme = useSelector(selectActiveTheme);
    const classes = panelStyles(theme);
    const dispatch = useDispatch();

    const cbHandleMouseMove = useCallback(handleMousemove, []); // eslint-disable-line react-hooks/exhaustive-deps
    const cbHandleMouseUp = useCallback(handleMouseup, []); // eslint-disable-line react-hooks/exhaustive-deps
    const cbHandleMouseEnd = useCallback(handleMouseend, []); // eslint-disable-line react-hooks/exhaustive-deps

    //#endregion Hooks

    //#region State

    const [procedureTitle, setProcedureTitle] = useState('');
    const [loaded, setLoaded] = useState(false);

    //#endregion State

    //#region Selectors

    const config = useSelector(getConfig);

    const editItem = useSelector(selectEditItem);
    const viewContentPanel = useSelector(selectViewContentPanel);
    const procedureContentVersion = useSelector(getProcedureContentVersion);
    const formOpen = useSelector(isFormOpen);
    const formInfo = useSelector(selectFormInfo);
    const visualEditZone = useSelector(selectVisualEditZone);
    const editZone = useSelector(selectEditZone);
    const displayAsset = useSelector(selectActiveAsset);
    const isDirty = useSelector(getDirtyFlag);
    const displayItemToolTip = useSelector(getItemTooltip);
    const displayNavToolTip = useSelector(getNavTooltip);
    const isProcedureLoaded = useSelector(getProcedureLoaded);

    //#endregion Selectors

    //#region Refs

    const editPanel = React.useRef('editPanel');
    const epssPanel = React.useRef();
    const dragger = React.useRef('dragger');
    const virtualDragger = React.useRef('virtualDragger');

    //#endregion Refs

    const showGeminiViewer = formInfo.openForm
        ? formInfo.openForm.showGeminiViewer
        : true;

    const slideLayoutOpen =
        viewContentPanel === true ||
        procedureContentVersion != null ||
        formOpen === true;

    const showViewer = visualEditZone;
    const zoneMediaValue = useZoneMediaConfigValue() || false;

    //#region Effects

    useEffect(() => {
        const handler = () => {
            closeGeminiViewer();
            closeDrawer();
        };
        window.addEventListener('popstate', handler);
        return () => window.removeEventListener('popstate', handler);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (epssPanel.current?.style) {
            let percent = virtualDragger.current && slideLayoutOpen ? 70 : 100;
            epssPanel.current.style.width = percent + 'vw';
            if (virtualDragger.current?.style) {
                virtualDragger.current.style.left = percent + 'vw';
            }
            if (dragger.current?.style) {
                dragger.current.style.left = percent + 'vw';
            }
            window.dispatchEvent(new Event('resize'));

            if (virtualDragger.current?.style) {
                virtualDragger.current.style.left =
                    epssPanel.current.style.width;
            }
            if (dragger.current?.style) {
                dragger.current.style.left = epssPanel.current.style.width;
            }
        }
    }, [slideLayoutOpen, visualEditZone, formOpen]);

    function getEditPanelWidth() {
        if (epssPanel.current?.style) {
            return 100 - parseFloat(epssPanel.current.style.width) + 'vw';
        }
        return null;
    }

    //#endregion Effects

    //#region Methods

    function onLoaded() {
        if (window.APP_DATA) {
            dispatch(updateConfig({ data: window.APP_DATA }));
        }
        setLoaded(true);
    }

    function handleMousedown(e) {
        e.stopPropagation();
        e.preventDefault();
        editPanel.current.style.pointerEvents = 'none';
        document.addEventListener('pointermove', cbHandleMouseMove);
        document.addEventListener('pointerup', cbHandleMouseUp);
        document.addEventListener('pointerend', cbHandleMouseEnd);
        isResizing = true;
    }

    function handleMousemove(e) {
        if (!isResizing || !dragger.current) {
            return;
        }
        let panelPercent = 100 - (e.clientX / document.body.offsetWidth) * 100;
        panelPercent = Math.min(
            Math.max(panelPercent, minPanelSize),
            maxPanelSize
        );
        dragger.current.style.left = 100 - panelPercent + 'vw';
    }

    function handleMouseup(e) {
        dragCleanup(e);
    }

    function handleMouseend(e) {
        dragCleanup(e);
    }

    function dragCleanup(e) {
        if (isResizing) {
            let panelPercent =
                100 - (e.clientX / document.body.offsetWidth) * 100;
            panelPercent = Math.min(
                Math.max(panelPercent, minPanelSize),
                maxPanelSize
            );
            if (epssPanel.current) {
                epssPanel.current.style.width = 100 - panelPercent + 'vw';
            }
            editPanel.current.style.width = panelPercent + 'vw';
            virtualDragger.current.style.left = 100 - panelPercent + 'vw';
            dragger.current.style.left = 100 - panelPercent + 'vw';
            isResizing = false;
            window.dispatchEvent(new Event('resize'));
        }
        editPanel.current.style.pointerEvents = 'auto';
        document.removeEventListener('pointermove', cbHandleMouseMove);
        document.removeEventListener('pointerup', cbHandleMouseUp);
        document.removeEventListener('pointerend', cbHandleMouseEnd);
    }

    const closeDrawer = () => {
        dispatch(setIsDirty(false));
        if (epssPanel.current) {
            epssPanel.current.style.width = '100vw';
        }

        const zoneForm = formInfo?.zoneForm;
        const itemForm = formInfo?.itemForm;

        dispatch(setViewContentPanel(false));
        dispatch(setDefaultItemPanelSection(0));
        dispatch(setActiveLinkContentSubTab(0));
        dispatch(setActiveLinkTab(0));
        dispatch(displayProcedureContentVersion(null));
        dispatch(resetLinkStatus());
        dispatch(resetContentStatus());
        dispatch(resetSchooxTab());

        if (zoneForm?.isContentPanelOpen) {
            dispatch(
                openPanel({
                    formKey: 'zoneForm',
                    formAction: 'Edit',
                    isContentPanelOpen: false,
                })
            );
        }
        if (itemForm?.isContentPanelOpen) {
            dispatch(
                openPanel({
                    formKey: 'itemForm',
                    formAction: 'Edit',
                    isContentPanelOpen: false,
                })
            );
        }

        if (window.geminiViewManager) {
            window.geminiViewManager.updateView();
        }
    };

    const closeGeminiViewer = () => {
        const resetTags = {
            zone_tags: [],
            item_tags: [],
        };
        dispatch(setTagFilters(resetTags));
        dispatch(setVisualEditZone(false));
        dispatch(setEnableMultipleHotspot(false));
        dispatch(clearEditZone());
        dispatch(resetZonePreviewState());
    };

    const showContentPreview = async () => {
        dispatch(
            loadContentPanelItem({
                zoneId: editItem.zone_id,
                itemId: editItem.item_id,
            })
        );
        dispatch(setPanelVis({ open: true }));
    };

    const showEditItem = async () => {
        await dispatch(
            loadItem({
                zoneId: editItem.zone_id,
                itemId: editItem.item_id,
            })
        );
        dispatch(openPanel({ formKey: `itemForm`, formAction: 'Edit' }));
    };

    //#endregion Methods

    //#region Render

    return (
        <>
            <GeminiLoader onLoaded={onLoaded} />
            {slideLayoutOpen && (
                <Drawer
                    className={classes.drawerPaper}
                    anchor={'right'}
                    open={true}
                    onClose={!showViewer && closeDrawer}
                    variant={showViewer ? 'temporary' : 'persistent'}
                >
                    <div
                        className={clsx(classes.contentPanelList, {
                            [classes.unsaveData]: isDirty,
                        })}
                        style={{ width: getEditPanelWidth() }}
                        ref={editPanel}
                    >
                        {procedureContentVersion && (
                            <>
                                <div className={classes.procedureContainer}>
                                    <div className={classes.procedureHeader}>
                                        <div
                                            className={
                                                classes.createHeaderBottomRow
                                            }
                                        >
                                            <Typography
                                                className={
                                                    classes.createHeaderText
                                                }
                                            >
                                                {procedureTitle}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            classes.createHeaderCloseButton
                                        }
                                    >
                                        <MdClose
                                            className="react-icon"
                                            onClick={closeDrawer}
                                        />
                                    </div>
                                </div>
                                <ProcedureContentLoader
                                    contentVersion={procedureContentVersion}
                                    setProcedureTitle={setProcedureTitle}
                                />
                            </>
                        )}
                        {viewContentPanel &&
                            !formOpen &&
                            !procedureContentVersion && (
                                <>
                                    <div className={classes.createContainer}>
                                        <div className={classes.createHeader}>
                                            <div
                                                className={
                                                    classes.createHeaderTopRow
                                                }
                                            >
                                                <VisualEdit
                                                    rowData={editZone}
                                                />
                                            </div>
                                            <div
                                                className={
                                                    classes.createHeaderBottomRow
                                                }
                                            >
                                                <MdApps
                                                    className="react-icon"
                                                    style={{
                                                        marginBottom: '5px',
                                                    }}
                                                />
                                                <Tooltip
                                                    className={
                                                        classes.createHeaderText
                                                    }
                                                    title={
                                                        editItem.display_name
                                                    }
                                                >
                                                    <Typography
                                                        overflow="hidden"
                                                        textOverflow="ellipsis"
                                                        display="inline-block"
                                                        whiteSpace="nowrap"
                                                    >
                                                        {editItem.display_name}
                                                    </Typography>
                                                </Tooltip>
                                                <OtherZonesLink
                                                    activeItem={editItem}
                                                />
                                                <Tooltip title="Preview">
                                                    <Box>
                                                        <MdPreview
                                                            className={`react-icon ${classes.createHeaderButton}`}
                                                            onClick={
                                                                showContentPreview
                                                            }
                                                        />
                                                    </Box>
                                                </Tooltip>
                                                <Tooltip title="Edit Properties">
                                                    <Box>
                                                        <MdEdit
                                                            className={`react-icon ${classes.createHeaderButton}`}
                                                            onClick={
                                                                showEditItem
                                                            }
                                                        />
                                                    </Box>
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <ShortcutIcons
                                                    displayAsset={displayAsset}
                                                    rowData={editItem}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                classes.createHeaderCloseButton
                                            }
                                        >
                                            <MdClose
                                                className="react-icon"
                                                onClick={closeDrawer}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.contentPanel}>
                                        <LinkTabs />
                                    </div>
                                </>
                            )}

                        {formOpen && <FormDisplayer />}
                    </div>
                </Drawer>
            )}
            {showViewer && loaded && showGeminiViewer && (
                <Drawer
                    classes={
                        ({ paper: classes.paper },
                        {
                            paper: isProcedureLoaded
                                ? classes.paperOverflowHidden
                                : classes.paperOverflowVisible,
                        })
                    }
                    anchor={'left'}
                    open={true}
                    onClose={closeGeminiViewer}
                    variant="persistent"
                >
                    <div
                        className={clsx(
                            {
                                [classes.itemPreviewPanel]: slideLayoutOpen,
                                [classes.itemFullPanel]: !slideLayoutOpen,
                            },
                            displayItemToolTip ? 'item-tooltip-visible' : '',
                            displayNavToolTip ? 'nav-tooltip-visible' : ''
                        )}
                        ref={epssPanel}
                    >
                        <GeminiEditor onClose={closeGeminiViewer} />
                    </div>
                </Drawer>
            )}
            {!showViewer && (
                <>
                    <SidePanel
                        linkTypes={config.linkConfig.LinkTypes}
                        audienceIds={config.user.audienceIds}
                        zoneMediaConfigValue={zoneMediaValue}
                    />
                    <FlyerDisplay />
                </>
            )}
            <LinkDisplay />
            {slideLayoutOpen && (
                <div className={classes.draggerParent}>
                    <div
                        className={classes.virtualDragger}
                        ref={virtualDragger}
                        onPointerDown={handleMousedown}
                    />
                    <div className={classes.dragger} ref={dragger}>
                        <IconButton>
                            <LuUnfoldHorizontal />
                        </IconButton>
                    </div>
                </div>
            )}
        </>
    );

    //#endregion Render
}

export { ViewerSlideLayout };
