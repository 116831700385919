import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loaded: false,
    formData: {},
    assetId: null,
    procedureId: null,
    activeAssetId: null,
    activeTreeDisplayData: {},
    initials_user_data: {},
    activeSessionId: null,
    procedureSessionId: null,
    duplicateFields: {},
};

const checklistDataSlice = createSlice({
    name: 'checklistData',
    initialState,
    reducers: {
        setChecklistInitialState: (state, action) => {
            state.formData[state.activeAssetId] = {};
            state.activeTreeDisplayData[state.activeAssetId] = {};
            state.initials_user_data[state.activeAssetId] = [];
            state.duplicateFields = {};
        },
        setActiveAssetId: (state, action) => {
            state.assetId = action.payload;
            state.activeAssetId = `${action.payload}_${state.procedureId}`;
            state.loaded = false;
        },
        setActiveAssetProcedureId: (state, action) => {
            state.procedureId = action.payload;
            state.activeAssetId = `${state.assetId}_${action.payload}`;
            state.loaded = false;
        },
        setChecklistDataStatus: (state, action) => {
            state.loaded = action.payload;
        },
        setInitial: (state, action) => {
            state.initials_user_data[state.activeAssetId] = action.payload;
        },
        setActiveTreeDisplayData: (state, action) => {
            state.activeTreeDisplayData[state.activeAssetId] = state
                .activeTreeDisplayData?.[state?.activeAssetId]
                ? {
                      ...state.activeTreeDisplayData?.[state?.activeAssetId],
                      ...action.payload,
                  }
                : action.payload;
        },
        setChecklistData: (state, action) => {
            state.formData[state.activeAssetId] = state.formData?.[
                state?.activeAssetId
            ]
                ? { ...state.formData[state?.activeAssetId], ...action.payload }
                : action.payload;
        },
        setActiveSessionId: (state, action) => {
            state.activeSessionId = action.payload;
        },
        setProcedureSessionId: (state, action) => {
            state.procedureSessionId = action.payload;
        },
        setDuplicateFields: (state, action) => {
            state.duplicateFields = action.payload;
        },
    },
});

export const getChecklistData = (state) =>
    state.checklistData?.activeAssetId
        ? state.checklistData.formData?.[state.checklistData.activeAssetId]
        : {};

export const getChecklistDataStatus = (state) => state.checklistData.loaded;
export const getActiveTreeDisplayData = (state) =>
    state.checklistData?.activeAssetId
        ? state.checklistData.activeTreeDisplayData?.[
              state.checklistData.activeAssetId
          ]
        : {};

export const getInitialsData = (state) =>
    state.checklistData?.activeAssetId
        ? state.checklistData.initials_user_data[
              state.checklistData?.activeAssetId
          ]
        : [];

export const getActiveSessionId = (state) =>
    state.checklistData?.activeSessionId
        ? state.checklistData.activeSessionId
        : null;

export const getProcedureSessionId = (state) =>
    state.checklistData?.procedureSessionId
        ? state.checklistData.procedureSessionId
        : null;

export const getDuplicateFields = (state) =>
    state.checklistData.duplicateFields;

export const {
    setChecklistInitialState,
    setInitial,
    setActiveAssetId,
    setActiveAssetProcedureId,
    setChecklistData,
    setActiveTreeDisplayData,
    setChecklistDataStatus,
    setActiveSessionId,
    setProcedureSessionId,
    setDuplicateFields,
} = checklistDataSlice.actions;

export default checklistDataSlice.reducer;
