import { config } from 'gemini-config';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    ...config,
    ...{
        user: { role: 'admin', audiences: [] },
        editMode: true,
        allowZoneNavigation: true,
        data: {
            settings: {
                mouseViewMode: 'drag',
                autorotateEnabled: false,
                fullscreenButton: false,
                viewControlButtons: false,
                sceneMenu: false,
                showPreviews: true,
            },
        },
    },
};

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        updateConfig: (state, action) => {
            return { ...state, ...action.payload };
        },
    },
    extraReducers: {},
});

export const getConfig = (state) => state.config;

export const { updateConfig } = configSlice.actions;

export default configSlice.reducer;
