import React from 'react';

import { FormTextField } from 'components/TextField';

const LabelField = ({ text = 'Lable', validationError }) => {
    return (
        <FormTextField
            autoFocus={true}
            label={text}
            name="label"
            placeholder={text}
            error={!!validationError}
            helperText={validationError}
        />
    );
};

export default LabelField;
