import React from 'react';
import { FormControl, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
    nextNode,
    gotoNode,
    previousNode,
} from 'GeminiViewerComponent/components/Procedure/_features/procedureSlice';
import { setChecklistData } from 'GeminiViewerComponent/components/Procedure/_features/checklistDataSlice';

const LinkField = ({
    data,
    styles,
    fieldValue,
    inGroup = false,
    isSubmitAllow = true,
    handleClick = () => {},
}) => {
    const dispatch = useDispatch();

    const linkFieldData = (
        <FormControl
            style={{
                padding: '5px',
                width: '100%',
            }}
        >
            <Button
                onClick={(e) => {
                    if (!isSubmitAllow) {
                        e.stopPropagation();
                        handleClick(e, data);
                    }
                    let transformedFieldValues = {};
                    Object.keys(fieldValue).forEach((key, idx) => {
                        let val = fieldValue[key];
                        if(typeof(val) === 'object')
                        {
                            transformedFieldValues[key] = val;
                        } else {
                            transformedFieldValues[key] = [val]
                        }
                    });
                    dispatch(setChecklistData(transformedFieldValues));
                    if (data.link.id === 'next') {
                        dispatch(nextNode());
                    } else if (data.link.id === 'prev') {
                        dispatch(previousNode());
                    } else {
                        dispatch(gotoNode(data.link.id));
                    }
                }}
                className={styles.compactButton}
                style={{ width: 'fit-content' }}
                type="button"
            >
                {data.label}
            </Button>
        </FormControl>
    );

    return inGroup ? (
        <div
            style={{
                display: 'inline-flex',
                width: data?.field_width ? data.field_width + '%' : '100%',
                padding: '13px 5px 5px',
            }}
        >
            {linkFieldData}
        </div>
    ) : (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    display: 'inline-flex',
                    width: data?.field_width ? data.field_width + '%' : '100%',
                    padding: '5px',
                }}
            >
                {linkFieldData}
            </div>
        </div>
    );
};

export default LinkField;
