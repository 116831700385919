import { makeStyles } from '@mui/styles';

const sidePanelHeaderStyles = makeStyles({
    header: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: (props) => props.colors.side_panel_body,
        color: (props) => props.colors.secondarySectionForeground,
        minHeight: '75px',
        justifyContent: 'space-between',
    },
    headerBranding: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    closeIcon: {
        height: '25px',
        width: '25px',
        color: (props) => props.colors.secondarySectionForeground,
        marginRight: '10px',
        cursor: 'pointer',
        pointerEvents: 'auto',
        transition: 'transform .7s ease-in-out',
        '&:hover': {
            transform: 'rotate(360deg)',
        },
    },
    displayZoneMediaButton: {
        height: '30px',
        margin: '12px 15px',
        pointerEvents: 'auto',
        transition: 'transform .7s ease-in-out',
        backgroundColor: (props) => props.colors.icon_inactive_color,
        '&:hover': {
            backgroundColor: (props) => props.colors.icon_inactive_color,
        },
    },
    allZoneMediaButton: {
        backgroundColor: '#3598DB !important',
        color: '#ffffff',
    },
    zoneMediaIcon: {
        height: '35px',
        width: '35px',
    },
    headerLogo: {
        height: '50px',
    },
    hotspotContainer: {
        backgroundColor: (props) => props.colors.secondarySectionBackground,
        color: (props) => props.colors.secondarySectionForeground,
        padding: '15px',
    },
    displayName: {
        fontSize: '24px',
        color: 'white',
        marginBottom: '5px',
        fontWeight: 'bold',
        fontFamily: 'Helvetica',
    },
    description: {
        fontSize: '17px',
        color: 'white',
        marginBottom: '5px',
        fontWeight: '500',
        fontFamily: 'Helvetica',
    },
    externalId: {
        color: (props) => props.colors.secondarySectionForeground,
        fontSize: '16px',
    },
    zoneMediaBadge: {
        position: 'absolute',
        top: 0,
        right: 0,
        transform: 'translate(-3px , 3px)',
        '& .MuiBadge-badge': {
            backgroundColor: (props) => props.colors.secondarySectionBackground,
            fontWeight: 'bold',
            border: '1px solid #FFFFFF',
        },
    },
});

export { sidePanelHeaderStyles };
