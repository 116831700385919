import { makeStyles } from '@mui/styles';

const makeCommonFormPreviewStyles = makeStyles(() => ({
    button: {
        backgroundColor: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
    },
    formErrorDiv: {
        color: '#f44336',
        marginLeft: '14px',
        marginRight: '14px',
        marginTop: '4px',
        fontSize: '0.75rem',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        lineHeight: 1.66,
        marginBottom: '10px',
    },
    cancel: {
        backgroundColor: (props) => props.colors.button.secondaryBackground,
        color: (props) => props.colors.button.secondaryForeground,
        padding: '10px',
        borderRadius: '5px',
        fontSize: '18px',
        textTransform: 'uppercase',
        marginTop: '10px',
        cursor: 'pointer',
        border: '0px',
        '&:hover': {
            backgroundColor: (props) => props.colors.button.secondaryBackground,
            color: (props) => props.colors.button.secondaryForeground,
            filter: 'brightness(90%)',
        },
    },
    submit: {
        backgroundColor: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        border: '0px',
        padding: '10px',
        borderRadius: '5px',
        fontSize: '18px',
        textTransform: 'uppercase',
        marginTop: '10px',
        cursor: 'pointer',
        '&:disabled': {
            backgroundColor: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(50%)',
        },
        '&:hover': {
            backgroundColor: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(90%)',
        },
    },
    compactButton: {
        backgroundColor: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        border: '0px',
        borderRadius: '5px',
        textTransform: 'uppercase',
        cursor: 'pointer',
        '&:disabled': {
            backgroundColor: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(50%)',
        },
        '&:hover': {
            backgroundColor: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(90%)',
        },
    },
}));

const makeCommonFormFieldStyles = makeStyles(() => ({
    bottomRow: {
        display: 'flex',
        marginTop: '20px',
        marginBottom: '20px',
        alignItems: 'center',
        gap: '5px',
    },
    formTable: {
        '& td, & th': {
            padding: '5px',
        },
    },
    formTableBorders: {
        border: '2px solid black',
        '& td, & th': {
            border: '1px solid black',
        },
    },
    procedureForm: {},
    deleteRepeateRowButton: {
        backgroundColor: (props) =>
            `${props.colors.button.accentBackground} !important`,
        color: (props) => `${props.colors.button.accentForeground} !important`,
        '&:hover': {
            filter: 'brightness(90%)',
        },
    },
    repeateRowButton: {
        backgroundColor: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
        '&:hover': {
            filter: 'brightness(90%)',
        },
    },
}));

const makeCommonPreviewImageStyles = makeStyles(() => ({
    imageWrapper: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        position: 'relative',
        maxHeight: 'calc(100% - 84px)',
        marginBottom: '10px',
        padding: '10px',
    },
    imageWrapperBlock: {
        position: 'relative',
        width: '100%',
        display: 'inline-block',
        overflow: 'auto',
    },
}));

export {
    makeCommonFormPreviewStyles,
    makeCommonFormFieldStyles,
    makeCommonPreviewImageStyles,
};
