import React, { useState } from 'react';
import { Box } from '@mui/system';
import { Badge, Divider, Popover } from '@mui/material';
import { useSelector } from 'react-redux';
import { MdLink } from 'react-icons/md';

import { last } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { panelStyles } from 'GeminiViewerComponent/components/styles';
import { selectCompactZones } from 'GeminiViewerComponent/_features/asset/assetSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { useStyles } from 'components/_Misc/PopupActions/components/PopupAction/styles';

function OtherZonesLink({ activeItem }) {
    const theme = useSelector(selectActiveTheme);
    const classes = panelStyles(theme);
    const popOverClasses = useStyles();

    const [showOtherZones, setShowOtherZones] = useState(false);
    const [otherZonesAnchorEl, setOtherZonesAnchorEl] = useState(null);

    const allZones = useSelector(selectCompactZones);

    const otherItemZones =
        activeItem && activeItem.zones
            ? allZones.filter(
                  (zone) =>
                      zone.zone_id !== activeItem.zone_id &&
                      activeItem.zones.includes(zone.zone_id)
              )
            : [];
    const lastZone = last(otherItemZones);

    return (
        <>
            {otherItemZones.length > 0 && (
                <Badge
                    className={classes.badgeMargin}
                    badgeContent={otherItemZones.length}
                    color="primary"
                >
                    <MdLink
                        className={`react-icon ${classes.createHeaderLink}`}
                        onClick={(event) => {
                            setOtherZonesAnchorEl(event.target);
                            setShowOtherZones(true);
                        }}
                    />
                </Badge>
            )}
            {showOtherZones && (
                <Popover
                    open={true}
                    anchorEl={otherZonesAnchorEl}
                    onClick={() => setShowOtherZones(false)}
                    anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Box p={2}>
                        <div className={classes.popupTitle}>
                            <div className={popOverClasses.popupActionLabel}>
                                Other Zones
                            </div>
                        </div>
                        {otherItemZones.map((zone) => {
                            return (
                                <div key={zone.zone_id}>
                                    <div className={classes.popupContainer}>
                                        <div
                                            className={
                                                popOverClasses.popupActionLabel
                                            }
                                        >
                                            {zone.display_name}
                                        </div>
                                    </div>
                                    {zone !== lastZone && <Divider />}
                                </div>
                            );
                        })}
                    </Box>
                </Popover>
            )}
        </>
    );
}

export { OtherZonesLink };
