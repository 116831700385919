import clsx from 'clsx';
import { LinkTypes } from '_helpers';
// import Dropzone from 'react-dropzone';
import { MdAdd } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';

import ChapterField from './ChapterField';
import { Each } from 'GeminiViewerComponent/_helpers/Each';
// import { panelStyles } from 'GeminiViewerComponent/components/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { makeFieldFormStyles } from 'components/ProcedureDesigner/components/FormNodeEditor/styles';

export const ContentChapterFields = ({ onDropError, content = null }) => {
    const theme = useSelector(selectActiveTheme);
    // const panelClasses = panelStyles(theme);
    const nodeFormStyles = makeFieldFormStyles(theme);
    const { values, setFieldValue } = useFormikContext();

    const handleAddSelect = (options, setFieldValue) => {
        if (!Array.isArray(options)) {
            options = [];
        }
        const optionsArray = [...options];
        optionsArray.push({
            title: '',
            start_time: '',
            end_time: '',
        });
        setFieldValue('chapters', optionsArray);
    };

    const handleRemoveChapter = (id) => {
        let currOptionButtons = [...values.chapters];
        currOptionButtons.splice(id, 1);
        setFieldValue('chapters', currOptionButtons);
    };

    return (
        (values.link_type_id === LinkTypes.Video.id ||
            (content &&
                content?.default_link_type_id === LinkTypes.Video.id)) && (
            <Box
                component="fieldset"
                border="1px solid #cccccc"
                borderRadius="5px"
                padding="0 5px"
            >
                <Box
                    component="legend"
                    marginLeft="10px"
                    padding="3px"
                    fontSize="14px"
                    color="#6d7178"
                >
                    Chapters
                </Box>
                <Typography padding="5px">
                    Start and end times should be entered as hh:mm:ss.ttt.
                    <br />
                    hh=hours, mm=minutes, ss=seconds, ttt=fractions of seconds.
                </Typography>
                <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    gap="10px"
                    width="100%"
                >
                    <Each
                        of={values.chapters}
                        render={(_, idx) => (
                            <ChapterField
                                idx={idx}
                                handleRemoveChapter={handleRemoveChapter}
                            />
                        )}
                    />
                </Box>
                <Box padding="20px 5px 15px">
                    <Tooltip title="Add Chapter">
                        <IconButton
                            className={clsx(
                                nodeFormStyles.addButtonIcon,
                                nodeFormStyles.addButtonLessSpaceing
                            )}
                            onClick={() =>
                                handleAddSelect(values?.chapters, setFieldValue)
                            }
                            size="small"
                        >
                            <MdAdd className="react-icon" />
                        </IconButton>
                    </Tooltip>
                </Box>
                {/* <div
                    className={panelClasses.dropzoneContainer}
                    style={{ padding: '5px' }}
                >
                    <Dropzone
                        maxFiles={1}
                        maxSize={250000000}
                        multiple={true}
                        accept={['text/csv']}
                        onDrop={(accepted_files, rejected_files) => {
                            // if (
                            //     Array.isArray(rejected_files) &&
                            //     rejected_files.length === 0
                            // ) {
                            //     setFieldValue('caption_files', accepted_files);
                            //     setFieldValue(
                            //         'caption_languages',
                            //         new Array(accepted_files.length).fill(
                            //             '',
                            //             0,
                            //             accepted_files.length
                            //         )
                            //     );
                            // } else {
                            //     onDropError(rejected_files);
                            //     setFieldValue('caption_files', null);
                            //     setFieldValue('caption_languages', null);
                            // }
                        }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div
                                {...getRootProps()}
                                className={`${panelClasses.dropzoneArea} ${panelClasses.dropZoneMinHeight50}`}
                                style={{
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center center',
                                }}
                            >
                                <input {...getInputProps()} />
                                <div className={panelClasses.dropzoneText}>
                                    Drop/Select Import Files
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </div>
                <Typography padding="5px">
                    Import file should be in csv format with one chapter per row
                    and each row containing title, start time, end time.
                </Typography> */}
            </Box>
        )
    );
};
