const replaceExtension = (filename, newExtension) => {
    const lastDotPosition = filename.lastIndexOf('.');
    if (lastDotPosition <= 0) {
        return `${filename}.${newExtension}`;
    }
    const baseName = filename.substring(0, lastDotPosition);
    return `${baseName}.${newExtension}`;
};

const prefixUrl = (url, prefix) => {
    if (url && !url.toUpperCase().startsWith('HTTP')) {
        return `${prefix}${url}`;
    } else {
        return url;
    }
};

const mapPrefixUrl = (data, prefix, dataKey = '') => {
    if (Array.isArray(data) && data.length > 0) {
        data = data.map((val) => {
            if (typeof val === 'object') {
                if (dataKey && val?.[dataKey]) {
                    val = {
                        ...val,
                        [dataKey]: prefixUrl(val?.[dataKey], prefix),
                    };
                }
            } else if (typeof val === 'string' && val !== '') {
                val = prefixUrl(val, prefix);
            }
            return val;
        });
    }
    return data;
};

const mapCompanionContent = (content, s3Prefix) => {
    if (content) {
        let companionContent = { ...content };
        companionContent.app_icon_url = prefixUrl(
            content.app_icon_url,
            s3Prefix
        );
        return companionContent;
    } else {
        return content;
    }
};

const mapCompanionContents = (contents, s3Prefix) => {
    if (contents) {
        return contents.map((content) => {
            return mapCompanionContent(content, s3Prefix);
        });
    } else {
        return contents;
    }
};

const contentCaptionURL = (linkContent) => {
    let contentURL = null;
    if (linkContent && typeof linkContent === 'string') {
        contentURL = linkContent
            ? linkContent.substring(0, linkContent.lastIndexOf('.'))
            : '';
        // if (contentURL.endsWith('_e') === true) {
        //     contentURL = contentURL.substring(0, contentURL.lastIndexOf('_e'));
        // }
    }
    return contentURL;
};

const mapVideoContentURL = (link) => {
    let linkContent = null;
    if (link?.content_version?.encoding_status?.toLowerCase() === 'complete') {
        linkContent = link?.content_version?.encoded_url;
    } else if (link?.content_version?.encoded_url) {
        linkContent = link?.content_version?.encoded_url;
    } else if (link?.content_version?.url) {
        linkContent = link?.content_version?.url;
    } else if (link?.encoded_url) {
        linkContent = link?.encoded_url;
    } else if (link?.url) {
        linkContent = link?.url;
    } else if (link?.content_version?.embed_data) {
        linkContent = link?.content_version?.embed_data;
    } else {
        linkContent = link?.embed_data;
    }
    return linkContent;
};

export {
    prefixUrl,
    mapPrefixUrl,
    mapCompanionContent,
    mapCompanionContents,
    contentCaptionURL,
    mapVideoContentURL,
    replaceExtension,
};
