import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { mediaService } from '../services/media.service';

const initialState = {
    mediaStatus: LoadingStatus.Idle,
    media: [],
    mediaPageInfo: {},
    mediaLoadedRowsMap: {},
};

export const fetchAllMedia = createAsyncThunk(
    'media/fetchAllMedia',
    async (params, { getState }) =>
        await mediaService.getAllByRange(
            params.startIndex ?? 0,
            params.stopIndex ?? 0,
            params.searchString,
            params.sort,
            getState().accounts.activeUser.s3_prefix,
            params.mediaType,
            params.minFileSize,
            params.maxFileSize,
            params.minMediaWidth,
            params.maxMediaWidth,
            params.minMediaHeight,
            params.maxMediaHeight,
            params.draft,
            params.lastModifiedDate
        )
);

export const createMedia = createAsyncThunk(
    'media/createMedia',
    async (newMedia, { getState }) =>
        await mediaService.create(
            newMedia,
            getState().accounts.activeUser.s3_prefix
        )
);

export const updateMedia = createAsyncThunk(
    'media/updateMedia',
    async (params, { getState }) =>
        await mediaService.update(
            params,
            getState().accounts.activeUser.s3_prefix
        )
);

export const deleteMedia = createAsyncThunk(
    'media/deleteMedia',
    async (mediaId) => {
        const response = await mediaService.delete(mediaId);
        response.id = mediaId;
        return response;
    }
);

export const fetchMediaById = createAsyncThunk(
    'media/fetchMediaById',
    async (mediaId, { getState }) =>
        await mediaService.fetchMediaById(
            mediaId,
            getState().accounts.activeUser.s3_prefix
        )
);

const mediaSlice = createSlice({
    name: 'media',
    initialState,
    reducers: {
        resetMediaState: (state) => initialState,
        resetMediaStatus: (state) => {
            state.mediaStatus = LoadingStatus.Idle;
            state.media = [];
            state.mediaPageInfo = {};
            state.mediaLoadedRowsMap = {};
        },
    },
    extraReducers: {
        [createMedia.fulfilled]: (state, action) => {
            state.mediaStatus = LoadingStatus.Idle;
        },
        [updateMedia.fulfilled]: (state, action) => {
            state.mediaStatus = LoadingStatus.Idle;
        },
        [deleteMedia.fulfilled]: (state, action) => {
            state.mediaStatus = LoadingStatus.Idle;
        },
        [fetchAllMedia.pending]: (state, action) => {
            state.mediaStatus = LoadingStatus.Loading;
        },
        [fetchAllMedia.fulfilled]: (state, action) => {
            state.mediaStatus = LoadingStatus.Loaded;
            state.mediaPageInfo = action.payload.pageInfo;

            if (action.meta.arg.reset) {
                state.mediaLoadedRowsMap = {};
            }

            const startIndex = action.meta.arg.startIndex;
            const stopIndex = startIndex + action.payload.medias.length - 1;

            for (var i = startIndex; i <= stopIndex; i++) {
                state.mediaLoadedRowsMap[i] = LoadingStatus.Loaded;
            }

            // In case we didn't load as many as requested
            for (
                let j = stopIndex + 1;
                j <= action.payload?.pageInfo?.TotalCount;
                j++
            ) {
                delete state.mediaLoadedRowsMap[j];
            }

            if (action.meta.arg.reset) {
                state.media = action.payload.medias;
            } else {
                state.media = state.media.concat(action.payload.medias);
            }
        },
        [fetchAllMedia.rejected]: (state, action) => {
            state.mediaStatus = LoadingStatus.Failed;
        },
    },
});

export const getMediaStatus = (state) => state.media.mediaStatus;
export const selectMedia = (state) => state.media.media;
export const getMediaPageInfo = (state) => state.media.mediaPageInfo;
export const getMediaById = (state, mediaId) =>
    state.media.media.find((a) => a.media_id === mediaId);

export const { resetMediaState, resetMediaStatus } = mediaSlice.actions;

export default mediaSlice.reducer;
