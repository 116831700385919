// eslint-disable-next-line
import React, { createElement } from 'react';
import { Drawer } from '@mui/material';
import { useSelector } from 'react-redux';

import { sidePanelStyles } from './styles';
import { SidePanelBody } from '../../components/SidePanelBody';
import { SidePanelHeader } from '../../components/SidePanelHeader';
import { getPanelVis } from '../../_features/globals/visibilitySlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

const SidePanel = ({ linkTypes, audienceIds, zoneMediaConfigValue }) => {
    const theme = useSelector(selectActiveTheme);
    const classes = sidePanelStyles(theme);
    const isOpen = useSelector((state) => getPanelVis(state));

    const drawerConfig = {
        anchor: 'right',
        open: isOpen,
    };

    return (
        <div>
            <Drawer
                {...drawerConfig}
                className={classes.drawer}
                variant="persistent"
            >
                <div className={classes.sidePanelContainer}>
                    <SidePanelHeader
                        zoneMediaConfigValue={zoneMediaConfigValue}
                    />
                    <SidePanelBody
                        linkTypes={linkTypes}
                        audienceIds={audienceIds}
                    />
                    {/* <div style={{padding: '10px', backgroundColor: '#34485E', color: 'white', cursor: 'pointer'}} onClick={handleTree}>Decision Tree</div>
                    <div style={{padding: '10px', backgroundColor: '#34485E', color: 'white', cursor: 'pointer'}} onClick={handleFlyer}>Flyer</div> */}
                </div>
            </Drawer>
        </div>
    );
};

export { SidePanel };
