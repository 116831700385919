import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';

export const AddNodeCategoryDialog = ({
    openModal,
    setOpenModal,
    updatedNodeCategories,
    addNodeCategory,
}) => {
    const [newCategoryName, setNewCategoryName] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const isExisting = updatedNodeCategories.some(
            (option) => newCategoryName?.trim() === option['name']?.trim()
        );
        if (isExisting) {
            setError('Node Category must be unique.');
        } else {
            setError(null);
        }
    }, [newCategoryName]);

    return (
        <Dialog open={openModal}>
            <DialogTitle>Add a new Node Category</DialogTitle>
            <DialogContent sx={{ width: '400px' }}>
                <TextField
                    fullWidth
                    autoFocus
                    margin="dense"
                    id="name"
                    value={openModal?.node_category}
                    onBlur={(event) => {
                        setNewCategoryName(event.target.value);
                    }}
                    label="Node Category"
                    type="text"
                    variant="outlined"
                    error={!!error}
                    helperText={error}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        setOpenModal(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    autoFocus
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (!error) {
                            addNodeCategory(newCategoryName);
                        }
                    }}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
