import React, { useRef, useState } from 'react';
import { Field } from 'formik';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { useTinyMCEInit } from 'hooks/useTinyMCEInit';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import {
    fetchContentById,
    fetchContentVersions,
    selectContentById,
} from '_features/content/contentSlice';
import SelectContentForm from '../../ContentNodeEditor/SelectContentForm';
import { TinyMCEEditor } from 'components/TinyMCEEditor';

const EditorLabelField = () => {
    const dispatch = useDispatch();
    const init = useTinyMCEInit();
    const { enqueueSnackbar } = useSnackbar();
    const editorRef = useRef(null);
    const selectedContent = useSelector(selectContentById);
    // const [pendingFilesToUpload, setPendingFilesToUpload] = useState([]);
    const [selectContentForEditor, setSelectContentForEditor] = useState(null);
    const [loadedContent, setLoadedContent] = useState(null);

    const getAnchorElement = (editor, selectedElm) => {
        selectedElm = selectedElm || editor.selection.getNode();
        const parent = editor.dom.getParent(selectedElm, 'a[href]');
        return parent;
    };

    const getButtonElement = (editor, selectedElm) => {
        selectedElm = selectedElm || editor.selection.getNode();
        const parent = editor.dom.getParent(selectedElm, 'button');
        return parent;
    };

    const updateElement = (editor, nodeLinkElm, newContent) => {
        editor.selection.select(nodeLinkElm);
        editor.selection.setContent(newContent);
    };

    const handleSelectedContent = async (
        contentData,
        selectContentForEditor
    ) => {
        if (contentData) {
            var url = '';
            let contentName = null;
            let content = null;
            if (contentData.content_id) {
                if (
                    selectedContent.find(
                        (content) =>
                            content.content_id === contentData.content_id
                    )
                ) {
                    content = selectedContent.find(
                        (content) =>
                            content.content_id === contentData.content_id
                    );
                } else {
                    contentName = await dispatch(
                        fetchContentById({ contentId: contentData.content_id })
                    );
                    const versions = await dispatch(
                        fetchContentVersions(contentData?.content_id)
                    );
                    if (versions && versions?.payload?.length > 0) {
                        if (contentData?.selectedVersion) {
                            content = versions?.payload?.find(
                                (version) =>
                                    version.content_version_id ===
                                    contentData?.selectedVersion
                            );
                        } else {
                            content = [...versions.payload].pop();
                        }
                    }
                }
            }

            if (content) {
                const strToUseStart =
                    (contentData.is_button
                        ? `<button data-target-content="true">`
                        : ``) + `<a `;
                const strToUseEnd =
                    `</a>` + (contentData.is_button ? `</button>` : ``);

                url += `${strToUseStart} data-target-content="true" `;
                if (contentData.chapter_id) {
                    url += `data-chapter-id=${contentData.chapter_id} `;
                }
                if (contentData?.selectedVersion) {
                    url += `data-target-selectedVersion="${contentData?.selectedVersion}" `;
                }
                url += `data-target-contentid="${
                    contentData.content_id
                }" data-target-refmode="${
                    contentData.reference_mode
                }" data-target-auto-play="${
                    contentData.auto_play
                }" data-target-link-type-id="${
                    content?.default_link_type_id ||
                    contentName?.payload?.default_link_type_id
                }" href="`;
                // if (contentData.reference_mode === 'static') {
                if (content?.encoding_status?.toLowerCase() === 'complete') {
                    url += `${content?.encoded_url}?`;
                } else {
                    url += `${content?.url}?`;
                }
                // } else {
                //     url += `?content_id=${content.content_id}&`;
                // }
                if (contentData.auto_play) {
                    url += `auto_play=${contentData.auto_play}&`;
                }
                url += `link_type_id=${
                    content?.default_link_type_id ||
                    contentName?.payload?.default_link_type_id
                }`;

                url += `" target="${
                    contentData.display_mode === 'inline' ? '_self' : '_blank'
                }">${contentData.link_text}${strToUseEnd}`;
                if (loadedContent?.actionType === 'edit') {
                    let editor = selectContentForEditor;
                    updateElement(editor, loadedContent.targetEl, url);
                } else {
                    selectContentForEditor.insertContent(url + '&nbsp;&nbsp;');
                }
            }
        }
        setSelectContentForEditor(null);
    };

    const handleContentAction = (editor) => {
        const anchor = getAnchorElement(editor, editor.selection.getNode());

        if (!anchor) {
            setLoadedContent(null);
            setSelectContentForEditor(editor);
            return;
        }

        const button = getButtonElement(editor, editor.selection.getNode());
        const targetEl = button ? button : anchor;

        if (targetEl?.getAttribute('data-target-content')) {
            setLoadedContent({
                targetEl: targetEl,
                href: anchor.getAttribute('href'),
                display_mode:
                    anchor.getAttribute('target') === '_self'
                        ? 'inline'
                        : 'external',
                reference_mode: anchor.getAttribute('data-target-refmode'),
                content_id: Number(
                    anchor.getAttribute('data-target-contentid')
                ),
                chapter_id: Number(anchor.getAttribute('data-chapter-id')),
                selectedVersion:
                    Number(
                        anchor?.getAttribute('data-target-selectedVersion')
                    ) || null,
                auto_play:
                    anchor.getAttribute('data-target-auto-play') === 'true'
                        ? true
                        : false,
                link_type_id: Number(
                    anchor.getAttribute('data-target-link-type-id')
                ),
                is_button: button !== null,
                link_text: anchor.innerText,
                actionType: 'edit',
            });
            setSelectContentForEditor(editor);
        } else {
            enqueueSnackbar(`The selected content is not a content link.`, {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
        }
    };

    // const getBase64 = (url) => {
    //     return new Promise((resolve) => {
    //         var xhr = new XMLHttpRequest();
    //         xhr.onload = function () {
    //             var reader = new FileReader();
    //             reader.onloadend = () => {
    //                 resolve(reader.result);
    //             };
    //             reader.readAsDataURL(xhr.response);
    //         };
    //         xhr.open('GET', url);
    //         xhr.responseType = 'blob';
    //         xhr.send();
    //     });
    // };

    const file_picker_callback_function = (cb, value, meta) => {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute(
            'accept',
            meta?.filetype === 'media'
                ? ['video/mp4', 'audio/mp3']
                : ['image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml']
        );

        /*
            Note: In modern browsers input[type="file"] is functional without
            even adding it to the DOM, but that might not be the case in some older
            or quirky browsers like IE, so you might want to add it to the DOM
            just in case, and visually hide it. And do not forget do remove it
            once you do not need it anymore.
        */

        input.onchange = function () {
            var file = this.files[0];
            var extension = file.name.split('.').pop().toLowerCase();
            var validExtension =
                meta?.filetype === 'media'
                    ? ['mp4', 'mp3']
                    : ['jpg', 'jpeg', 'png', 'svg'];
            if (validExtension.indexOf(extension) > -1) {
                var reader = new FileReader();
                reader.onload = async function () {
                    /*
                        Note: Now we need to register the blob in TinyMCEs image blob
                        registry. In the next release this part hopefully won't be
                        necessary, as we are looking to handle it internally.
                    */
                    var id = 'blobid' + new Date().getTime();
                    var blobCache = editorRef.current.editorUpload.blobCache;
                    var base64 = reader.result.split(',')[1];
                    var blobInfo = blobCache.create(id, file, base64);
                    // const base64Data = await getBase64(blobInfo.blobUri());
                    // setPendingFilesToUpload((prevState) => {
                    //     const state = [...prevState];
                    //     state.push({
                    //         oldUrl: base64Data,
                    //         fileToUpload: file,
                    //     });
                    //     return state;
                    // });
                    blobCache.add(blobInfo);
                    /* call the callback and populate the Title field with the file name */
                    cb(blobInfo.blobUri(), {
                        title: file.name,
                        height: extension === 'mp3' ? '50' : 150,
                        width: 300,
                    });
                };
                reader.readAsDataURL(file);
            } else {
                enqueueSnackbar(
                    `Only ${validExtension.join(', ').trimEnd()} ${
                        validExtension?.length > 1 ? 'are' : 'is'
                    } allowed.`,
                    {
                        action: (key) => <SnackbarDismiss key={key} />,
                        variant: 'error',
                    }
                );
            }
        };

        input.click();
    };

    const setup_function = (editor) => {
        editor.ui.registry.addButton('addContentLink', {
            icon: 'embed-page',
            tooltip: 'Add link to existing content',
            onAction: async function () {
                handleContentAction(editor);
            },
        });
    };

    return (
        <>
            <Field name="labelcontent" style={{ marginTop: '10px' }}>
                {({ field, form: { setFieldValue } }) => (
                    <TinyMCEEditor
                        id="labelcontent"
                        tinymceScriptSrc={
                            process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'
                        }
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        init={{
                            ...init,
                            toolbar: `${init.toolbar} addContentLink`,
                            paste_data_images: false,
                            image_uploadtab: false,
                            media_embedtab: false,
                            media_poster: false,
                            media_alt_source: false,
                            file_picker_types: 'media image',
                            file_picker_callback: file_picker_callback_function,
                            setup: setup_function,
                        }}
                        textareaName="labelcontent"
                        onBlur={field.onBlur}
                        onEditorChange={(content) => {
                            setFieldValue('labelcontent', content);
                        }}
                        value={field.value}
                    />
                )}
            </Field>
            {selectContentForEditor && (
                <SelectContentForm
                    content={loadedContent}
                    onSelectedContent={(content) =>
                        handleSelectedContent(content, selectContentForEditor)
                    }
                />
            )}
        </>
    );
};

export default EditorLabelField;
