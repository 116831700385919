import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { partsService } from '_features/_services';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';

const initialState = {
    parts: [],
    partsStatus: LoadingStatus.Idle,
    partsPageInfo: {},
    displayPart: {},
    loadedPartMap: {},
};

export const fetchPartsPage = createAsyncThunk(
    'part/fetchPartsPage',
    async (params = {}, { getState }) => {
        return await partsService.getParts(
            params.partGroupIDs,
            params.partCategoryIDs,
            params.searchString,
            params.sort,
            getState().accounts.activeUser.s3_prefix
        );
    }
);

export const fetchPartById = createAsyncThunk(
    'part/fetchPartById',
    async (partId, { getState }) => {
        const response = await partsService.getById(
            partId,
            getState().accounts.activeUser.s3_prefix
        );
        response.id = partId;
        return response;
    }
);

export const createPart = createAsyncThunk(
    'part/createPart',
    async (params, { getState }) => {
        return await partsService.create(
            params,
            getState().accounts.activeUser.s3_prefix
        );
    }
);

export const updatePart = createAsyncThunk(
    'part/updatePart',
    async (params, { getState }) =>
        await partsService.update(
            params,
            getState().accounts.activeUser.s3_prefix
        )
);

export const deletePart = createAsyncThunk(
    'part/deletePart',
    async ({ part_id }) => {
        const response = await partsService.delete(part_id);
        return response;
    }
);

export const getPartImportSampleCsv = createAsyncThunk(
    'part/getPartImportSampleCsv',
    async () => await partsService.getPartImportCsv()
);

export const uploadPartImportCsv = createAsyncThunk(
    'part/uploadPartImportCsv',
    async (params) => await partsService.uploadPartImportCsv(params)
);

const partSlice = createSlice({
    name: 'parts',
    initialState,
    reducers: {
        resetPartsState: (state) => initialState,
    },
    extraReducers: {
        [fetchPartsPage.pending]: (state, action) => {
            state.partsStatus = LoadingStatus.Loading;
        },
        [fetchPartsPage.fulfilled]: (state, action) => {
            state.partsPageInfo = action.payload.pageInfo;
            state.parts = action.payload.parts;
            state.partsStatus = LoadingStatus.Loaded;
        },
        [fetchPartsPage.rejected]: (state, action) => {
            state.partsStatus = LoadingStatus.Failed;
        },
        [createPart.fulfilled]: (state, action) => {
            state.partsStatus = LoadingStatus.Idle;
        },
        [updatePart.fulfilled]: (state, action) => {
            state.partsStatus = LoadingStatus.Idle;
        },
        [deletePart.fulfilled]: (state, action) => {
            state.partsStatus = LoadingStatus.Idle;
        },
        [fetchPartById.fulfilled]: (state, action) => {
            state.displayPart = action.payload;
        },
        [getPartImportSampleCsv.fulfilled]: (state, action) => {
            if (action?.payload) {
                const url = window.URL.createObjectURL(
                    new Blob([action.payload])
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'part_import.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        },
    },
});

export const selectParts = (state) => state.parts.parts;
export const getPartsStatus = (state) => state.parts.partsStatus;
export const getPartsPageInfo = (state) => state.parts.partsPageInfo;

export const { resetPartsState } = partSlice.actions;

export default partSlice.reducer;
