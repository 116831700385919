import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';
import Collapse from '@mui/material/Collapse';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';

import { displayProcedureContentVersion } from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import { fetchAllHotspotIcons, getHotspotStatus } from 'shared/hotspotsSlice';
import {
    setVisualEditZone,
    selectVisualEditZone,
} from '_features/common/editZoneSlice';
import {
    closePanel,
    selectFormInfo,
    openPanel,
    setEditObjectId,
} from '_features/common/formSlice';
import { useStyles } from './style';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { loadZone } from 'shared/loadZoneSlice';
import {
    setDefaultItemPanelSection,
    setViewContentPanel,
} from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import { loadItem } from '_features/common/editItemSlice';
import { getAsset } from '_features/assets/assetManagerSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';

const UsageDisplay = ({ data, listHeader, id }) => {
    const [clientsSubMenu, setClientsSubMenu] = React.useState(false);
    const [assetsSubMenu, setAssetsSubMenu] = React.useState(false);
    const [usersSubMenu, setUsersSubMenu] = React.useState(false);
    const [itemsSubMenu, setItemsSubMenu] = React.useState(false);
    const [zonesSubMenu, setZonesSubMenu] = React.useState(false);
    const [linksSubMenu, setLinksSubMenu] = React.useState(false);
    const [navLinksSubMenu, setNavLinksSubMenu] = React.useState(false);
    const hotspotStatus = useSelector((state) => getHotspotStatus(state));

    const groupUsageCount = (data) => {
        var response = Object.keys(data ?? {})?.map((item) => {
            return data[item].length > 0 ? true : false;
        });
        return response.includes(true);
    };
    const itemUsageKeys = {
        assets: {
            name: 'Assets',
            state_key: assetsSubMenu,
            display_name: 'asset_name',
        },
        users: {
            name: 'Users',
            state_key: usersSubMenu,
            display_name: 'first_name',
        },
        items: {
            name: 'Items',
            state_key: itemsSubMenu,
            display_name: 'item_name',
        },
        zones: {
            name: 'Zones',
            state_key: zonesSubMenu,
            display_name: 'zone_name',
        },
        links: {
            name: 'Links',
            state_key: linksSubMenu,
            display_name: 'link_name',
        },
        clients: {
            name: 'Clients',
            state_key: clientsSubMenu,
            display_name: 'client_name',
        },
        nav_links: {
            name: 'NavLinks',
            state_key: navLinksSubMenu,
            display_name: 'target_zone_name',
        },
    };

    const [openList, setOpenList] = React.useState(false);

    const isFormOpen = useSelector((state) => selectFormInfo(state));
    const visualEditZone = useSelector(selectVisualEditZone);

    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const clearLastViewerProperties = async () => {
        await dispatch(setVisualEditZone(false));
        await dispatch(setViewContentPanel(false));
        await dispatch(setDefaultItemPanelSection(0));
        await dispatch(displayProcedureContentVersion(null));
        await dispatch(
            closePanel({
                formKey: 'zoneForm',
                formAction: 'Edit',
                isContentPanelOpen: false,
            })
        );
        await dispatch(
            closePanel({
                formKey: 'itemForm',
                formAction: 'Edit',
                isContentPanelOpen: false,
            })
        );
        await dispatch(
            closePanel({
                formKey: 'groupForm',
                formAction: 'Edit',
                isContentPanelOpen: false,
            })
        );
        await dispatch(
            closePanel({
                formKey: 'itemTagForm',
                formAction: 'Edit',
            })
        );
        await dispatch(
            closePanel({
                formKey: 'contentForm',
                formAction: 'Edit',
                isContentPanelOpen: false,
            })
        );
        if (window.geminiViewManager) {
            window.geminiViewManager.updateView();
        }
    };

    const handleNestedClick = async (nestedItem, item) => {
        if (visualEditZone === true) {
            clearLastViewerProperties();
        }
        switch (item) {
            case 'assets':
                navigate(`/asset/${nestedItem?.asset_id}`);
                break;
            case 'users':
                dispatch(
                    setEditObjectId({
                        formKey: `accountForm`,
                        object_id: nestedItem?.user_id,
                    })
                );
                dispatch(
                    openPanel({
                        formKey: `accountForm`,
                        formAction: 'Edit',
                    })
                );
                break;
            case 'zones':
                handleZoneClick(nestedItem);
                break;
            case 'items':
                handleZoneClick(nestedItem);
                break;
            case 'nav_links':
                handleZoneClick(nestedItem);
                break;
            default:
                break;
        }
    };
    const handleZoneClick = async (rowData) => {
        dispatch(activateLoading());
        if (visualEditZone === true) {
            await clearLastViewerProperties();
        }
        await dispatch(
            closePanel({
                formKey: 'groupForm',
            })
        );
        await dispatch(
            closePanel({
                formKey: 'itemTagForm',
            })
        );
        await dispatch(
            closePanel({
                formKey: 'audienceForm',
            })
        );
        await dispatch(getAsset(rowData?.asset_id));
        if (hotspotStatus === LoadingStatus.Idle) {
            await dispatch(fetchAllHotspotIcons());
        }
        await dispatch(loadZone({ zoneId: rowData?.zone_id, refresh: false }));
        await dispatch(setVisualEditZone(true));
        if (rowData?.item_id !== undefined) {
            handleItemClick(rowData);
        }
        dispatch(deactivateLoading());
    };
    const handleItemClick = async (rowData) => {
        isFormOpen?.zoneForm?.isOpen &&
            dispatch(
                closePanel({ formKey: 'zoneForm', isContentPanelOpen: true })
            );
        isFormOpen?.itemForm?.isOpen &&
            dispatch(
                closePanel({ formKey: 'itemForm', isContentPanelOpen: true })
            );

        await dispatch(setViewContentPanel(true));
        if (rowData?.link_id !== undefined) {
            await dispatch(setDefaultItemPanelSection(rowData?.link_type_id));
        }
        await dispatch(
            loadItem({
                zoneId: rowData?.zone_id,
                itemId: rowData?.item_id,
            })
        );
    };
    const handleClick = (subMenuKey) => {
        if (subMenuKey === 'clients') {
            setClientsSubMenu(!clientsSubMenu);
        } else if (subMenuKey === 'assets') {
            setAssetsSubMenu(!assetsSubMenu);
        } else if (subMenuKey === 'users') {
            setUsersSubMenu(!usersSubMenu);
        } else if (subMenuKey === 'items') {
            setItemsSubMenu(!itemsSubMenu);
        } else if (subMenuKey === 'zones') {
            setZonesSubMenu(!zonesSubMenu);
        } else if (subMenuKey === 'links') {
            setLinksSubMenu(!linksSubMenu);
        } else if (subMenuKey === 'nav_links') {
            setNavLinksSubMenu(!navLinksSubMenu);
        }
    };

    return (
        <List
            className={classes.listRoot}
            component="nav"
            aria-labelledby={id}
            subheader={
                <ListSubheader
                    onClick={() => {
                        if (groupUsageCount(data) === true)
                            setOpenList(!openList);
                    }}
                    component="div"
                    id={id}
                    className={classes.listSubHeaderRoot}
                >
                    <div className={classes.listSubHeaderText}>
                        {listHeader}
                        {groupUsageCount(data) === true
                            ? `(${Object.keys(data ?? {})?.length})`
                            : `(Not in use)`}
                    </div>
                    {groupUsageCount(data) === true && (
                        <div className={classes.listSubHeaderNavigation}>
                            {openList ? (
                                <MdExpandLess
                                    className="react-icon"
                                    style={{ fontSize: '28px' }}
                                />
                            ) : (
                                <MdExpandMore
                                    className="react-icon"
                                    style={{ fontSize: '28px' }}
                                />
                            )}
                        </div>
                    )}
                    <TouchRipple />
                </ListSubheader>
            }
        >
            {openList &&
                Object.keys(data ?? {})?.length > 0 &&
                Object.keys(data).map((item, index) => (
                    <div key={item}>
                        <ListItemButton
                            onClick={(e) => {
                                e.stopPropagation();
                                handleClick(item);
                            }}
                        >
                            <ListItemText
                                primary={`${itemUsageKeys[item].name} (${data?.[item].length})`}
                            />
                            {itemUsageKeys[item].state_key ? (
                                <MdExpandLess
                                    className="react-icon"
                                    style={{ fontSize: '25px' }}
                                />
                            ) : (
                                <MdExpandMore
                                    className="react-icon"
                                    style={{ fontSize: '25px' }}
                                />
                            )}
                        </ListItemButton>
                        {data?.[item].length > 0 && (
                            <Collapse
                                in={itemUsageKeys[item].state_key}
                                timeout="auto"
                                unmountOnExit
                            >
                                {data?.[item].map((nestedItem, index) => (
                                    <List
                                        component="div"
                                        disablePadding
                                        key={index}
                                    >
                                        <ListItemButton
                                            sx={{ pl: 4 }}
                                            onClick={() => {
                                                handleNestedClick(
                                                    nestedItem,
                                                    item
                                                );
                                            }}
                                        >
                                            <ListItemText
                                                primary={
                                                    nestedItem?.[
                                                        itemUsageKeys[item]
                                                            .display_name
                                                    ]
                                                }
                                            />
                                        </ListItemButton>
                                    </List>
                                ))}
                            </Collapse>
                        )}
                    </div>
                ))}
        </List>
    );
};

export { UsageDisplay };
