import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { snackbarHandler } from '_helpers';
import { Each } from 'GeminiViewerComponent/_helpers/Each';
import { editAsset } from '_features/assets/editAssetSlice';
import { getAsset } from '_features/assets/assetManagerSlice';
import useCategoryAsseyStyles from '../CardView/CardContainer/styles';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    getAllCategories,
    resetCategoryStatus,
} from '_features/category/categorySlice';

const getCategoryListStyle = (expanded, categoryId) => ({
    background: expanded.some((cat) => cat.category_id === categoryId)
        ? 'lightgrey'
        : '#efefef',
    padding: '18px 14px',
    flexDirection: 'column',
    overflow: 'hidden',
    cursor: 'pointer',
    userSelect: 'none',
    marginBottom: '5px',
    transition: 'all 0.1s ease-in 0s',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const CategoryPanel = ({
    selectedAssetCategory,
    setSelectedAssetCategory,
    cardView = true,
    displayNoneCategory = false,
}) => {
    //#region Constants
    //#endregion Constants

    //#region Hooks
    const classes = useCategoryAsseyStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    //#endregion Hooks

    //#region State
    //#endregion State

    //#region Selectors
    const allCategories = useSelector(getAllCategories);
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    //#endregion Effects

    //#region Methods

    const openCategoryList = useCallback(
        (category) => {
            const categories = [...selectedAssetCategory];
            const categoryIndex = selectedAssetCategory.findIndex(
                (cat) => cat.category_id === category.category_id
            );
            if (categoryIndex !== -1) {
                categories.splice(categoryIndex, 1);
            } else {
                categories.push(category);
            }
            setSelectedAssetCategory(categories);
        },
        [selectedAssetCategory, setSelectedAssetCategory]
    );

    const onDragEnterHandler = (e) => {
        e.preventDefault();
    };

    const onDragOverHandler = (e) => {
        e.preventDefault();
        if (e.target.className === 'boardContentArea') {
            setTimeout(() => {
                if (cardView) {
                    e.target.style.height = '250px';
                } else {
                    e.target.style.height = '150px';
                }
            }, 0);
        }
    };

    const onDragLeaveHandler = (e) => {
        e.preventDefault();
        if (e.target.className === 'boardContentArea') {
            setTimeout(() => {
                e.target.className = 'boardContentArea';
                e.target.style.height = '50px';
            }, 0);
        }
    };

    const updateAsset = async (assetId, categoryId) => {
        await dispatch(activateLoading());
        const assetData = await dispatch(getAsset(assetId));
        if (
            assetData.payload &&
            Object.keys(assetData.payload || {}).length > 0
        ) {
            const assetCategories =
                assetData.payload.categories &&
                assetData.payload.categories?.length > 0
                    ? assetData.payload.categories?.map(
                          (category) => category?.category_id
                      )
                    : [];

            const resultAction = await dispatch(
                editAsset({
                    asset_id: assetId,
                    categories: [...assetCategories, categoryId],
                    player_url: assetData.payload?.player_url || '',
                    nav_hotspot_icon_id: assetData.payload?.nav_hotspot_icon_id,
                    item_hotspot_icon_id:
                        assetData.payload?.item_hotspot_icon_id,
                })
            );
            if (!resultAction.error) {
                dispatch(resetCategoryStatus());
                const { message, variant } = snackbarHandler(
                    resultAction.meta.requestStatus,
                    'Edit'
                );
                enqueueSnackbar(message, {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: variant,
                });
            }
        }
        await dispatch(deactivateLoading());
    };

    const updateMultipleAsset = async (assetIds, categoryId) => {
        await dispatch(activateLoading());
        const promises = [];
        const updatedAssets = [];

        assetIds.forEach((assetId) => {
            promises.push(dispatch(getAsset(assetId)));
        });
        let results = await Promise.all(promises);

        if (results && results?.length > 0) {
            results.forEach((result) => {
                if (
                    result.payload &&
                    Object.keys(result.payload || {}).length > 0
                ) {
                    const assetCategories =
                        result.payload.categories &&
                        result.payload.categories?.length > 0
                            ? result.payload.categories?.map(
                                  (category) => category?.category_id
                              )
                            : [];

                    updatedAssets.push(
                        dispatch(
                            editAsset({
                                asset_id: result.payload?.asset_id,
                                categories: [...assetCategories, categoryId],
                                player_url: result.payload?.player_url || '',
                                nav_hotspot_icon_id:
                                    result.payload?.nav_hotspot_icon_id,
                                item_hotspot_icon_id:
                                    result.payload?.item_hotspot_icon_id,
                            })
                        )
                    );
                }
            });
        }

        let updatedAssetResult = await Promise.all(updatedAssets);
        dispatch(resetCategoryStatus());
        enqueueSnackbar(
            `${updatedAssetResult?.length} Assets Edited Successfully.`,
            {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'success',
            }
        );
        await dispatch(deactivateLoading());
    };

    const onDropHandler = (e, category = null) => {
        if (!category) {
            return enqueueSnackbar("Can't assign NONE category", {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
        }
        setTimeout(() => {
            e.target.className = 'boardContentArea';
            e.target.style.height = '50px';
        }, 0);

        let cardInfo = JSON.parse(e.dataTransfer.getData('cardInfo'));
        let assetIds = JSON.parse(e.dataTransfer.getData('assetIds'));

        const alreadyExist = cardInfo?.categories?.some(
            (assetCategory) =>
                assetCategory?.category_id === category.category_id
        );
        if (alreadyExist) {
            return enqueueSnackbar('Asset already exist in category', {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
        }
        if (assetIds && assetIds?.length > 0) {
            updateMultipleAsset(assetIds, category.category_id);
        } else {
            updateAsset(cardInfo?.asset_id, category.category_id);
        }
    };
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render
    return (
        <Box className={classes.categoryListWrapper}>
            <Each
                of={allCategories}
                render={(category) => (
                    <div
                        title={category.display_name}
                        style={getCategoryListStyle(
                            selectedAssetCategory,
                            category?.category_id
                        )}
                        className="boardContentArea"
                        onDragEnter={onDragEnterHandler}
                        onDragOver={onDragOverHandler}
                        onDragLeave={onDragLeaveHandler}
                        onDrop={(e) => onDropHandler(e, category)}
                        onClick={(e) => {
                            e.preventDefault();
                            openCategoryList(category);
                        }}
                    >
                        {category.display_name}
                    </div>
                )}
            />
            {displayNoneCategory && (
                <div
                    title="NONE"
                    style={getCategoryListStyle(selectedAssetCategory, 0)}
                    onDragEnter={onDragEnterHandler}
                    onDragOver={onDragOverHandler}
                    onDragLeave={onDragLeaveHandler}
                    onDrop={(e) => onDropHandler(e)}
                    onClick={(e) => {
                        e.preventDefault();
                        openCategoryList({
                            category_id: 0,
                        });
                    }}
                >
                    NONE
                </div>
            )}
        </Box>
    );
    //#endregion Render
};

export { CategoryPanel };
