import React, { useEffect, useState } from 'react';
import { MdClear } from 'react-icons/md';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    Autocomplete,
    Box,
    FormControl,
    IconButton,
    TextField,
} from '@mui/material';

import { makeFormStyles } from 'forms/styles';
import {
    fetchContentById,
    fetchContentVersions,
} from '_features/content/contentSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

export const VideoChapterField = () => {
    const dispatch = useDispatch();
    const { values, setFieldValue } = useFormikContext();
    const [chapters, setChapters] = useState([]);
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);

    const fetchContent = async () => {
        dispatch(activateLoading());
        const content = await dispatch(
            fetchContentById({
                contentId: values.content_id,
                addS3Prefix: false,
            })
        );
        if (values.selectedVersion) {
            const versions = await dispatch(
                fetchContentVersions(values?.content_id)
            );
            const selectedVersion = versions?.payload?.find(
                (version) =>
                    version.content_version_id === values.selectedVersion
            );
            if (
                selectedVersion?.chapters &&
                selectedVersion?.chapters?.length > 0
            ) {
                setChapters(selectedVersion?.chapters || []);
            }
        } else {
            setChapters(content.payload?.last_content_version?.chapters || []);
        }
        dispatch(deactivateLoading());
    };

    useEffect(() => {
        if (values?.content_id && !values?.content_versions?.length > 0) {
            fetchContent();
        }
    }, [values?.content_id, values.selectedVersion]);

    useEffect(() => {
        setChapters([]);
        if (
            values?.content_version_id &&
            values?.content_versions?.length > 0
        ) {
            const versions = [...values.content_versions];
            if (values?.content_version_id === 'Latest Version') {
                setChapters(versions.pop()?.chapters || []);
            } else {
                setChapters(
                    versions.find(
                        (version) =>
                            +version.content_version_id ===
                            +values?.content_version_id
                    )?.chapters || []
                );
            }
        }
    }, [values?.content_version_id]);

    return (
        chapters &&
        chapters.length > 0 && (
            <FormControl
                variant="outlined"
                fullWidth
                className={classes.handleClear}
                style={{
                    position: 'relative',
                }}
            >
                <Autocomplete
                    fullWidth
                    getOptionLabel={(option) => option.title}
                    variant="outline"
                    id="return_node_id"
                    options={chapters}
                    value={
                        chapters?.find(
                            (chapter) =>
                                Number(chapter?.chapter_id) ===
                                Number(values?.chapter_id)
                        ) || null
                    }
                    onChange={(event, newInputValue) => {
                        setFieldValue(
                            'chapter_id',
                            newInputValue?.chapter_id
                                ? newInputValue?.chapter_id
                                : null
                        );
                    }}
                    disableClearable
                    renderInput={(params) => (
                        <>
                            <TextField
                                {...params}
                                variant="outlined"
                                margin="dense"
                                label="Chapter"
                                placeholder="Chapter"
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    right: '25px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    fontSize: '20px',
                                }}
                                className={'clearSelect'}
                            >
                                <IconButton
                                    onClick={() => {
                                        setFieldValue('chapter_id', null);
                                    }}
                                >
                                    <MdClear />
                                </IconButton>
                            </Box>
                        </>
                    )}
                />
            </FormControl>
        )
    );
};
