import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    setProcedureDirty,
    setUnsavedProcedureData,
} from '_features/procedures/proceduresSlice';

const DirtyProcedure = () => {
    const { dirty, values } = useFormikContext();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setProcedureDirty(dirty));
    }, [dirty]);

    useEffect(() => {
        dispatch(setUnsavedProcedureData(values));
    }, [values]);
};

export default DirtyProcedure;
