export const LoadingStatus = {
    Idle: 'Idle',
    Loading: 'Loading',
    Loaded: 'Loaded',
    Failed: 'Failed',
};

export const SavingStatus = {
    Idle: 'Idle',
    Saving: 'Saving',
    Saved: 'Saved',
    Failed: 'Failed',
};
