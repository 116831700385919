import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import Dropzone from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router-dom';
import { MdClose, MdExpandMore } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Chip,
    Typography,
} from '@mui/material';

import { Role } from '_helpers';
import { config } from 'gemini-config';
import { accountsSlice } from 'app/store';
import { makeFormStyles } from '../styles';
import SetDirtyForm from 'forms/SetDirtyForm';
import { LinkTypes } from '_helpers/enumerations';
import { FormActions } from '_helpers/form-action';
import { SelectionAutocomplete } from 'components';
import { FormTextField } from 'components/TextField';
import { useTinyMCEInit } from 'hooks/useTinyMCEInit';
import { TinyMCEEditor } from 'components/TinyMCEEditor';
import { getHistory } from 'GeminiViewerComponent/_helpers';
import { addNewAsset } from '_features/assets/assetsTableSlice';
import { ConfigValuesField } from 'forms/Components/ConfigValuesField';
import { closePanel, selectFormInfo } from '_features/common/formSlice';
import { editAsset, getEditAsset } from '_features/assets/editAssetSlice';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';
import {
    accordionStyles,
    panelStyles,
} from 'GeminiViewerComponent/components/styles';
import {
    createProcedure,
    updateProcedure,
} from '_features/procedures/proceduresSlice';
import {
    fetchToolsPage,
    getToolsStatus,
    selectTools,
} from '_features/tools/toolSlice';
import {
    selectAllAudiences,
    getAudienceStatus,
    fetchAudiencesPage,
} from '_features/audiences/audiencesSlice';
import {
    setProgress,
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    createCategory,
    fetchAllCategories,
    getAllCategories,
    getCategoryStatus,
    resetCategoryStatus,
} from '_features/category/categorySlice';
import {
    selectAllGroups,
    getGroupStatus,
    fetchGroupsPage,
    resetGroupState,
} from '_features/groups/groupsSlice';

const history = getHistory({ routerBaseName: config.routerBaseName });

const ProcedureForm = ({ formAction, assetId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const init = useTinyMCEInit();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    const styles = accordionStyles();
    const panelClasses = panelStyles(theme);
    const [loaded, setLoaded] = useState(
        formAction.id === FormActions.Create.id ? true : false
    );
    const formInfo = useSelector(selectFormInfo);
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    const INIT_VALUES = {
        display_name: '',
        description: '',
        disclaimer_tooltip: '',
        disclaimer_html: '',
        custom_data: '',
        procedure_json: '{}',
        procedure_id: '',
        procedure_type_id: 2,
        groups: [],
        categories: [],
        audiences: [],
        image: {},
        asset_type_id: formInfo?.procedureForm?.assetTypeID,
        tool_ids: [],
        config_json: [{ key: '', value: '' }],
        player_url: '',
    };
    const [initValues, setInitValues] = useState({ ...INIT_VALUES });

    useEffect(() => {
        if (formAction.id === FormActions.Edit.id) {
            const fetchData = async () => {
                var objectData = await dispatch(getEditAsset(assetId));
                var fetchedObject = objectData.payload;

                setInitValues({
                    display_name: fetchedObject.display_name,
                    description: fetchedObject.description,
                    disclaimer_tooltip: fetchedObject.disclaimer_tooltip,
                    disclaimer_html: fetchedObject.disclaimer_html,
                    custom_data: fetchedObject.custom_data,
                    player_url: fetchedObject.player_url || '',
                    procedure_id: fetchedObject?.procedure_id,
                    procedure_json: fetchedObject?.procedure_json,
                    asset_type_id: fetchedObject?.asset_type_id,
                    groups: fetchedObject.groups?.map((x) => x.group.group_id),
                    categories: fetchedObject.categories?.map(
                        (x) => x.category_id
                    ),
                    audiences: fetchedObject.audiences?.map(
                        (x) => x.audience_id
                    ),
                    image: {},
                    image_url: fetchedObject.image_url,
                    config_json: fetchedObject.config_json ?? {
                        key: '',
                        value: '',
                    },
                    tool_ids: Array.isArray(fetchedObject?.tools)
                        ? fetchedObject?.tools?.map((tool) => tool?.tool_id)
                        : [],
                });
                setLoaded(true);
            };
            fetchData();
        } else {
            setInitValues({ ...INIT_VALUES });
        }
    }, [assetId]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        dispatch(activateLoading({ showProgress: true }));

        switch (formAction.id) {
            case FormActions.Edit.id:
                dispatch(
                    updateProcedure({ ...values, asset_id: assetId })
                ).then(async (result) => {
                    if (result?.meta?.requestStatus === 'rejected') {
                        afterFormSubmit(result, setSubmitting);
                        return;
                    }
                    dispatch(
                        editAsset({ asset_id: assetId, ...values, dispatch })
                    ).then((finalResult) => {
                        if (!finalResult.error) {
                            resetForm();
                        }
                        afterFormSubmit(finalResult, setSubmitting);

                        if (!finalResult.error) {
                            dispatch(resetCategoryStatus());
                            dispatch(closePanel({ formKey: 'procedureForm' }));
                            dispatch(resetGroupState());
                        }
                    });
                    setInitValues({ ...initValues, ...values });
                });

                break;
            case FormActions.Create.id:
                dispatch(
                    setProgress({
                        progress: 0,
                        label: 'Step 1 of 2: Uploading',
                        noAnimation: true,
                    })
                );
                dispatch(createProcedure({ ...values })).then(
                    async (result) => {
                        if (result?.meta?.requestStatus === 'rejected') {
                            afterFormSubmit(result, setSubmitting);
                            return;
                        }
                        dispatch(
                            addNewAsset({
                                ...values,
                                procedure_id: result?.payload?.procedure_id,
                                isProcedure: true,
                                dispatch,
                            })
                        ).then((finalResult) => {
                            if (!finalResult.error) {
                                dispatch(resetCategoryStatus());
                                resetForm();
                                dispatch(resetGroupState());
                            }
                            dispatch(closePanel({ formKey: 'procedureForm' }));
                            afterFormSubmit(finalResult, setSubmitting);
                            navigate(
                                `/procedure/${finalResult.payload.asset_id}`
                            );
                        });
                    }
                );
                break;
            default:
                break;
        }
    };

    const afterFormSubmit = async (resultAction, setSubmitting) => {
        dispatch(deactivateLoading());
        const { message, variant } = snackbarHandler(
            resultAction?.meta?.requestStatus,
            formAction?.label
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });
        setSubmitting(false);
    };

    const handleChecklist = () => {
        history.push(`/asset/${assetId}/checklist`);
    };

    const onDrop = useCallback(({ file, setFieldValue }) => {
        setFieldValue('image', file);

        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = (e) => {
            setFieldValue('image_url', e.target.result);
        };
        reader.readAsDataURL(file);
    }, []);

    return !loaded ? (
        <LoadingLogo styleClassName={classes.loadingSvg} />
    ) : (
        <Formik
            validator={() => ({})}
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            validationSchema={Yup.object({
                display_name: Yup.string()
                    .max(150, 'Must be 150 characters or less')
                    .required('Required'),
                description: Yup.string()
                    .max(255, 'Must be 255 characters or less')
                    .required('Required'),
                disclaimer_tooltip: Yup.string().max(
                    255,
                    'Must be 255 characters or less'
                ),
                custom_data: Yup.string(),
                config_json: Yup.array(),
                groups: Yup.array(),
                categories: Yup.array(),
                audiences: Yup.array(),
                image: Yup.mixed(),
                tool_ids: Yup.array(),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values, setSubmitting, resetForm);
            }}
        >
            {({ values, errors, touched, isSubmitting, onBlur }) => (
                <Form className={classes.form}>
                    <div className={classes.formHeader}>Procedure Details</div>
                    <FormTextField
                        label="Asset Name"
                        name="display_name"
                        placeholder="Asset Name"
                        disabled={readOnlyMode}
                    />
                    <FormTextField
                        label="Description"
                        name="description"
                        placeholder="Description"
                        disabled={readOnlyMode}
                    />
                    <FormTextField
                        label="Disclaimer Tooltip"
                        name="disclaimer_tooltip"
                        placeholder="Disclaimer Tooltip"
                        disabled={readOnlyMode}
                    />
                    <Field name="disclaimer_html" style={{ marginTop: '10px' }}>
                        {({ field, form: { setFieldValue } }) => (
                            <TinyMCEEditor
                                id="disclaimer_html"
                                tinymceScriptSrc={
                                    process.env.PUBLIC_URL +
                                    '/tinymce/tinymce.min.js'
                                }
                                init={{ ...init }}
                                textareaName="disclaimer_html"
                                onBlur={onBlur}
                                onEditorChange={(content) => {
                                    setFieldValue('disclaimer_html', content);
                                }}
                                value={values.disclaimer_html}
                            />
                        )}
                    </Field>
                    <FormTextField
                        label="Custom Data"
                        name="custom_data"
                        placeholder="Custom Data"
                        disabled={readOnlyMode}
                    />
                    <FormTextField
                        label="Player URL"
                        name="player_url"
                        placeholder="Player URL"
                        disabled={
                            readOnlyMode ||
                            ![Role.IPSAdmin, Role.Admin].includes(
                                activeUser?.role
                            )
                        }
                    />
                    {values.procedure_type_id === -1 && (
                        <button
                            className={classes.submit}
                            style={{
                                marginTop: '0px',
                                marginBottom: '10px',
                            }}
                            onClick={handleChecklist}
                            type="button"
                        >
                            Create Checklist
                        </button>
                    )}

                    <Field className={classes.input} name="groups">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <SelectionAutocomplete
                                title="Groups"
                                keyProperty="group_id"
                                nameProperty="display_name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={selectAllGroups}
                                entityStatusSelector={getGroupStatus}
                                fetchEntityPage={fetchGroupsPage}
                                formField={'groups'}
                                fetchParams={{ page: 1, pageSize: 100 }}
                                readOnly={readOnlyMode}
                            />
                        )}
                    </Field>

                    <Field className={classes.input} name="categories">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <SelectionAutocomplete
                                title="Categories"
                                placeholder="none"
                                keyProperty="category_id"
                                nameProperty="display_name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={getAllCategories}
                                entityStatusSelector={getCategoryStatus}
                                fetchEntityPage={fetchAllCategories}
                                formField={'categories'}
                                enableCreatable
                                creatableDialogTitle={'category'}
                                creatableDialogFieldLabel={'Category'}
                                creatableDispatch={createCategory}
                                fieldValidationLength={150}
                                fetchParams={{ page: 1, pageSize: 100 }}
                                readOnly={readOnlyMode}
                                creatableParams={{ asset_type_id: 3 }}
                            />
                        )}
                    </Field>

                    <Field className={classes.input} name="audiences">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <SelectionAutocomplete
                                title="Audiences"
                                keyProperty="audience_id"
                                nameProperty="display_name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={selectAllAudiences}
                                entityStatusSelector={getAudienceStatus}
                                fetchEntityPage={fetchAudiencesPage}
                                formField={'audiences'}
                                fetchParams={{ page: 1, pageSize: 100 }}
                                readOnly={readOnlyMode}
                            />
                        )}
                    </Field>

                    <Accordion
                        style={{ padding: '0px' }}
                        className={clsx(classes.input, styles.accordion)}
                    >
                        <AccordionSummary
                            className={styles.customAccordionSummary}
                            expandIcon={<MdExpandMore className="react-icon" />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={styles.heading}>
                                Key Value Pairs
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ConfigValuesField
                                values={values}
                                fieldName="config_json"
                            />
                        </AccordionDetails>
                    </Accordion>

                    <Field className={classes.input} name="tool_ids">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <SelectionAutocomplete
                                title="Tools"
                                keyProperty="tool_id"
                                nameProperty="display_name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={selectTools}
                                entityStatusSelector={getToolsStatus}
                                fetchEntityPage={fetchToolsPage}
                                formField={'tool_ids'}
                                fetchParams={{ page: 1, pageSize: 100 }}
                                readOnly={readOnlyMode}
                            />
                        )}
                    </Field>
                    <Field className={classes.input} name="image" type="file">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <div className={panelClasses.dropzoneContainer}>
                                <Dropzone
                                    accept={LinkTypes.Image.contentTypes.map(
                                        (at) => at.extension
                                    )}
                                    maxFiles={1}
                                    maxSize={LinkTypes.Image.fileSizeLimit}
                                    multiple={false}
                                    onDropAccepted={(files) => {
                                        if (!readOnlyMode) {
                                            onDrop({
                                                file: files[0],
                                                setFieldValue: setFieldValue,
                                            });
                                        }
                                    }}
                                    noClick={readOnlyMode}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            {...getRootProps()}
                                            className={
                                                panelClasses.dropzoneArea
                                            }
                                            style={{
                                                backgroundImage: `url(${values.image_url})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'contain',
                                                backgroundPosition:
                                                    'center center',
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            {!values.image_url && (
                                                <div
                                                    className={
                                                        panelClasses.dropzoneText
                                                    }
                                                >
                                                    Upload Asset Image
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Dropzone>
                                <div className={panelClasses.previewContainer}>
                                    {value && Object.keys(value).length > 0 && (
                                        <Chip
                                            className={
                                                panelClasses.previewUploadFileName
                                            }
                                            color="secondary"
                                            size="small"
                                            deleteIcon={
                                                <MdClose className="react-icon" />
                                            }
                                            onDelete={() => {
                                                if (!readOnlyMode) {
                                                    setFieldValue('image', {});
                                                    setFieldValue(
                                                        'image_url',
                                                        ''
                                                    );
                                                }
                                            }}
                                            label={value?.name}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </Field>
                    {readOnlyMode !== true && (
                        <>
                            <SetDirtyForm />
                            <button
                                className={classes.submit}
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {formAction.buttonLabel}
                            </button>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export { ProcedureForm };
