import { useEffect, useState, useRef, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { FormControl, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from 'formik';

import { ContentTypes } from '_helpers';
import { makeFormStyles } from 'forms/styles';
import DirtyProcedure from '../DirtyProcedure';
import { makeContentNodeFormStyles } from './styles';
import { FormTextField } from 'components/TextField';
import { useTinyMCEInit } from 'hooks/useTinyMCEInit';
import SaveNodeDataDialog from '../SaveNodeDataDialog';
import { TinyMCEEditor } from 'components/TinyMCEEditor';
import SelectTargetNodeForm from './SelectTargetNodeForm';
import { setProcedureDirty } from '_features/procedures/proceduresSlice';
import { AssociatedView } from './SelectTargetNodeForm/components/AssociatedView';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    CommonNodeFormFIelds,
    NodeCategoryField,
} from '../CommonNodeFormFIelds';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { createContent } from '_features/content/contentSlice';
import {
    ContentNodeFormAnswerOptionFields,
    ContentNodeFormAnswerOptions,
    ContentNodeFormFailureDetails,
    ContentNodeFormFields,
    ContentNodeFormPreset,
    ContentNodeFormPreview,
    ContentNodeFormSettings,
} from './components/ContentNodeFormFields';

const ContentNodeForm = ({
    assetId,
    nodes,
    node,
    onCancel,
    onSubmit,
    isValidVariableName,
    checkUniqueVariableName,
    openSaveDataDialog,
    setOpenSaveDataDialog,
    showTwoPanelMode,
    procedureCategories,
}) => {
    const theme = useSelector(selectActiveTheme);
    const nodeFormStyles = makeContentNodeFormStyles(theme);
    const commonFormStyles = makeFormStyles(theme);
    const dispatch = useDispatch();
    const init = useTinyMCEInit();
    const { enqueueSnackbar } = useSnackbar();

    const [formValues, setFormValues] = useState({
        close_associated_view: false,
        ...node,
        answer_group:
            ['hotspot', 'image', 'select'].includes(node?.answer_group) ===
                false && node?.option_buttons?.length > 0
                ? 'button'
                : node?.answer_group,
    });

    const [selectTargetNodeForEditor, setSelectTargetNodeForEditor] =
        useState(null);
    const [loadedTargetNode, setLoadedTargetNode] = useState(null);
    const [pendingFilesToUpload, setPendingFilesToUpload] = useState([]);
    const [openEditFieldForm, setOpenEditFieldForm] = useState(false);
    const editorRef = useRef(null);
    const procedureRef = useRef(null);
    const [completionNode, setCompletionNode] = useState(null);
    const [selectedField, setSelectedField] = useState(null);

    useEffect(() => {
        setFormValues({
            variable: '',
            ...node,
            procedureCategories: procedureCategories,
            answer_group:
                ['hotspot', 'image', 'select'].includes(node?.answer_group) ===
                    false && node?.option_buttons?.length > 0
                    ? 'button'
                    : node?.answer_group,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [node, node?.node_category]);

    useEffect(() => {
        setFormValues((prev) => ({
            ...prev,
            procedureCategories: procedureCategories,
        }));
    }, [procedureCategories]);

    useEffect(() => {
        return () => {
            setFormValues({});
            dispatch(setProcedureDirty(false));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelectedTargetNode = (
        { link_text, is_button, asset_id, zone_id, item_id, link_id, node_id },
        selectTargetNodeForEditor
    ) => {
        if (node_id && link_text) {
            const strToUseStart =
                (is_button ? `<button data-target-node="true">` : ``) + `<a `;
            const strToUseEnd = `</a>` + (is_button ? `</button>` : ``);
            let targetNodeLink = `${strToUseStart} data-target-node="true" href="?node_id=${node_id?.id}`;
            if (asset_id) {
                targetNodeLink += `&asset_id=${asset_id}`;
                if (zone_id) {
                    targetNodeLink += `&zone_id=${zone_id}`;
                    if (item_id) {
                        targetNodeLink += `&item_id=${item_id}`;
                        if (link_id) {
                            targetNodeLink += `&link_id=${link_id}`;
                        }
                    }
                }
            }
            targetNodeLink += `" target="_self">` + link_text + strToUseEnd;
            if (loadedTargetNode?.actionType === 'edit') {
                let editor = selectTargetNodeForEditor;
                updateElement(
                    editor,
                    loadedTargetNode.targetEl,
                    targetNodeLink
                );
            } else {
                selectTargetNodeForEditor.insertContent(
                    targetNodeLink + '&nbsp;&nbsp;'
                );
            }
        }
        setSelectTargetNodeForEditor(null);
    };

    const updateElement = (editor, nodeLinkElm, newContent) => {
        editor.selection.select(nodeLinkElm);
        editor.selection.setContent(newContent);
    };

    const getAnchorElement = (editor, selectedElm) => {
        selectedElm = selectedElm || editor.selection.getNode();
        const parent = editor.dom.getParent(selectedElm, 'a[href]');
        return parent;
    };

    const getButtonElement = (editor, selectedElm) => {
        selectedElm = selectedElm || editor.selection.getNode();
        const parent = editor.dom.getParent(selectedElm, 'button');
        return parent;
    };

    const handleTargetNodeAction = (editor) => {
        const anchor = getAnchorElement(editor, editor.selection.getNode());

        if (!anchor) {
            setLoadedTargetNode(null);
            setSelectTargetNodeForEditor(editor);
            return;
        }

        const button = getButtonElement(editor, editor.selection.getNode());
        const targetEl = button || anchor;

        if (targetEl?.getAttribute('data-target-node')) {
            setLoadedTargetNode({
                targetEl: targetEl,
                href: anchor.getAttribute('href'),
                isButton: button !== null,
                link_text: anchor.innerText,
                actionType: 'edit',
            });
            setSelectTargetNodeForEditor(editor);
        } else {
            enqueueSnackbar(`The selected content is not a target node.`, {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
        }
    };

    const getFileExtension = (file) => {
        const re = /(?:\.([^.]+))?$/;
        const extension = re.exec(file?.name)[1];
        const ext = extension.replace(/^.{1}/g, extension[0]);
        return {
            fileName: file?.name?.substring(0, file?.name?.lastIndexOf('.')),
            fileExtension:
                ContentTypes[
                    ext.charAt(0).toUpperCase() + ext.substring(1).toLowerCase()
                ]?.id,
        };
    };

    const getBase64 = (url) => {
        return new Promise((resolve) => {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        });
    };

    const selectCompletionNode = (value, setFieldValue) => {
        if (
            nodes.find((node) => node?.completion === true) &&
            nodes.find((node) => node?.completion === true).id !== node.id
        ) {
            setCompletionNode(nodes.find((node) => node?.completion === true));
        } else {
            setCompletionNode(null);
            setFieldValue('completion', value);
        }
    };

    const file_picker_callback_function = (cb, value, meta) => {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute(
            'accept',
            meta?.filetype === 'media'
                ? ['video/mp4', 'audio/mp3']
                : ['image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml']
        );

        /*
            Note: In modern browsers input[type="file"] is functional without
            even adding it to the DOM, but that might not be the case in some older
            or quirky browsers like IE, so you might want to add it to the DOM
            just in case, and visually hide it. And do not forget do remove it
            once you do not need it anymore.
        */

        input.onchange = function () {
            var file = this.files[0];
            var extension = file.name.split('.').pop().toLowerCase();
            var validExtension =
                meta?.filetype === 'media'
                    ? ['mp4', 'mp3']
                    : ['jpg', 'jpeg', 'png', 'svg'];
            if (validExtension.indexOf(extension) > -1) {
                var reader = new FileReader();
                reader.onload = async function () {
                    /*
                        Note: Now we need to register the blob in TinyMCEs image blob
                        registry. In the next release this part hopefully won't be
                        necessary, as we are looking to handle it internally.
                    */
                    var id = 'blobid' + new Date().getTime();
                    var blobCache = editorRef.current.editorUpload.blobCache;
                    var base64 = reader.result.split(',')[1];
                    var blobInfo = blobCache.create(id, file, base64);
                    const base64Data = await getBase64(blobInfo.blobUri());
                    setPendingFilesToUpload((prevState) => {
                        const state = [...prevState];
                        state.push({
                            oldUrl: base64Data,
                            fileToUpload: file,
                        });
                        return state;
                    });
                    blobCache.add(blobInfo);
                    /* call the callback and populate the Title field with the file name */
                    cb(blobInfo.blobUri(), {
                        title: file.name,
                        height: extension === 'mp3' ? '50' : 150,
                        width: 300,
                    });
                };
                reader.readAsDataURL(file);
            } else {
                enqueueSnackbar(
                    `Only ${validExtension.join(', ').trimEnd()} ${
                        validExtension?.length > 1 ? 'are' : 'is'
                    } allowed.`,
                    {
                        action: (key) => <SnackbarDismiss key={key} />,
                        variant: 'error',
                    }
                );
            }
        };

        input.click();
    };

    const setup_function = (editor) => {
        editor.ui.registry.addButton('addTargetNodeLink', {
            icon: 'new-tab',
            tooltip: 'Add Target Node',
            onAction: async function () {
                handleTargetNodeAction(editor);
            },
        });
        editor.ui.registry.addButton('addPagebreak', {
            icon: 'pagebreakIcon',
            tooltip: 'Add Page Break',
            onAction: () =>
                editor.insertContent(
                    '<div class="pagebreak" style="page-break-before: always;"></div><p></p>'
                ),
        });
    };

    const handleSubmit = useCallback(
        async (values, { setSubmitting }) => {
            dispatch(activateLoading({ showProgress: true }));
            var resultAction = null;
            let optButtons = values.option_buttons;
            optButtons = optButtons.map((btn, index) => {
                return {
                    ...btn,
                    button_id: index,
                    button_text: btn?.button_text?.trim(),
                };
            });
            let selectButtons;
            if (values.select_options) {
                selectButtons = values.select_options.map((btn, index) => {
                    return {
                        ...btn,
                        button_id: index,
                        option_text: btn?.option_text?.trim(),
                    };
                });
                values = {
                    ...values,
                    select_options: selectButtons,
                };
            }
            const contentIds = new Set();
            const addContentIds = (content) => {
                const doc = new DOMParser().parseFromString(
                    content,
                    'text/html'
                );
                const ids = Array.from(
                    doc.querySelectorAll('[data-target-contentid]')
                ).map((el) => +el.getAttribute('data-target-contentid'));
                ids.forEach((id) => contentIds.add(id));
            };

            if (values.content) {
                addContentIds(values.content);
            }
            if (Array.isArray(values.fields)) {
                for (const field of values.fields) {
                    if (field.labelcontent) {
                        addContentIds(field.labelcontent);
                    }
                    if (Array.isArray(field.fields)) {
                        for (const nestedField of field.fields) {
                            if (nestedField.labelcontent) {
                                addContentIds(nestedField.labelcontent);
                            }
                        }
                    }
                }
            }
            values = {
                ...values,
                option_buttons: optButtons,
                select_options: selectButtons,
                content_ids: Array.from(contentIds),
            };

            if (pendingFilesToUpload && pendingFilesToUpload?.length > 0) {
                let currentContent = editorRef.current.getContent();
                for (let i = 0; i < pendingFilesToUpload.length; i++) {
                    const fl = pendingFilesToUpload[i];
                    resultAction = null;
                    if (currentContent.indexOf(fl.oldUrl) !== -1) {
                        const fileContentData = getFileExtension(
                            fl.fileToUpload
                        );
                        resultAction = await dispatch(
                            createContent({
                                asset_id: assetId,
                                display_name: fileContentData?.fileName,
                                content_type_id: fileContentData?.fileExtension,
                                content_version: {
                                    content_data: fl.fileToUpload,
                                    content_type_id:
                                        fileContentData?.fileExtension,
                                    content_version_type: 2,
                                },
                            })
                        );
                        if (!resultAction.error) {
                            currentContent = await currentContent.replace(
                                fl.oldUrl,
                                resultAction?.payload?.last_content_version.url
                            );
                        }
                    }
                }
                values = {
                    ...values,
                    content: currentContent,
                };
            }

            if (values?.image_options && values.image_options?.length > 0) {
                let currImgOpts = [...values.image_options];
                let currImgTargets = Array.isArray(
                    values?.image_target_combinations
                )
                    ? [...values.image_target_combinations]
                    : [];
                for (let i = 0; i < values.image_options.length; i++) {
                    const fl = values.image_options[i];
                    resultAction = null;
                    if (fl?.img_data) {
                        const fileContentData = getFileExtension(fl.img_data);
                        resultAction = await dispatch(
                            createContent({
                                asset_id: assetId,
                                display_name: fileContentData?.fileName,
                                content_type_id: fileContentData?.fileExtension,
                                content_version: {
                                    content_data: fl.img_data,
                                    content_type_id:
                                        fileContentData?.fileExtension,
                                    content_version_type: 2,
                                },
                            })
                        );
                        if (!resultAction.error) {
                            currImgOpts[i] = {
                                ...fl,
                                img_data: null,
                                img_url:
                                    resultAction?.payload?.last_content_version
                                        .url,
                            };
                        }
                    }
                }
                currImgTargets = currImgTargets.map((targets) => {
                    return {
                        ...targets,
                        options_required: targets?.options_required.map(
                            (opt) => {
                                return currImgOpts.find(
                                    (imgs) =>
                                        imgs.option_text === opt.option_text
                                );
                            }
                        ),
                    };
                });
                values = {
                    ...values,
                    image_options: currImgOpts,
                    image_target_combinations: currImgTargets,
                };
            }
            dispatch(deactivateLoading());
            setSubmitting(false);
            onSubmit(values, null, nodes);
        },
        [dispatch, pendingFilesToUpload, onSubmit, nodes, assetId]
    );

    const validateForm = (values) => {
        let errors = {};
        if (values?.variable) {
            if (!isValidVariableName(values?.variable)) {
                errors = {
                    ...errors,
                    variable:
                        'Must start with letter followed by letters, numbers, or underscores',
                };
            } else if (
                !checkUniqueVariableName(values?.variable, `${values?.id}`)
            ) {
                errors = {
                    ...errors,
                    variable: 'Must be unique in procedure',
                };
            }
        }
        switch (values?.answer_group) {
            case 'button':
                values?.option_buttons.map((buttonOption, index) => {
                    if (!buttonOption?.button_text?.trim()) {
                        errors = {
                            ...errors,
                            [`option_buttons[${index}].button_text`]:
                                'Required.',
                        };
                    }
                });
                break;
            case 'select':
                values?.select_options.map((selectOption, index) => {
                    if (!selectOption?.option_text?.trim()) {
                        errors = {
                            ...errors,
                            [`select_options[${index}].option_text`]:
                                'Required.',
                        };
                    }
                });
                break;
            default:
                break;
        }
        return errors;
    };

    return (
        <div className={nodeFormStyles.container}>
            <div className={nodeFormStyles.form}>
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validate={validateForm}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        isSubmitting,
                        touched,
                        setFieldTouched,
                        dirty,
                    }) => (
                        <Form className={nodeFormStyles.formBody}>
                            <DirtyProcedure />
                            <CommonNodeFormFIelds />
                            <NodeCategoryField />
                            <FormTextField
                                label="Question"
                                name="question"
                                placeholder="Question"
                            />
                            <ErrorMessage name="question" component="div" />
                            <FormTextField
                                label="Assign answers to variable"
                                name="variable"
                                placeholder="Assign answers to variable"
                            />
                            <label>Content</label>
                            <Field name="content">
                                {({ field, form: { setFieldValue } }) => (
                                    <TinyMCEEditor
                                        id="content"
                                        tinymceScriptSrc={
                                            process.env.PUBLIC_URL +
                                            '/tinymce/tinymce.min.js'
                                        }
                                        onInit={(evt, editor) =>
                                            (editorRef.current = editor)
                                        }
                                        plugins="addContent"
                                        init={{
                                            ...init,
                                            toolbar: `${init.toolbar} addTargetNodeLink addContentLink addInlineImage addInlineVideo addPagebreak`,
                                            quickbars_insert_toolbar:
                                                'table | addContentLink addInlineImage addInlineVideo',
                                            file_picker_types: 'media image',
                                            file_picker_callback:
                                                file_picker_callback_function,
                                            setup: setup_function,
                                        }}
                                        textareaName="content"
                                        onBlur={field.onBlur}
                                        onFocus={() => {
                                            if (!touched?.content) {
                                                setFieldTouched(
                                                    'content',
                                                    true
                                                );
                                            }
                                        }}
                                        onEditorChange={(content) => {
                                            if (touched?.content) {
                                                setFieldValue(
                                                    field.name,
                                                    content
                                                );
                                            }
                                        }}
                                        value={field.value}
                                    />
                                )}
                            </Field>
                            <AssociatedView
                                asset_id={values?.asset_id}
                                zone_id={values?.zone_id}
                                item_id={values?.item_id}
                                close_associated_view={
                                    values?.close_associated_view
                                }
                            />
                            <FormControl
                                fullWidth
                                style={{ marginTop: '15px' }}
                            >
                                <Box
                                    display={
                                        showTwoPanelMode === true
                                            ? 'block'
                                            : 'flex'
                                    }
                                    flexWrap="wrap"
                                    width="100%"
                                >
                                    <ContentNodeFormFields
                                        nodes={nodes}
                                        openEditFieldForm={openEditFieldForm}
                                        setOpenEditFieldForm={
                                            setOpenEditFieldForm
                                        }
                                        selectedField={selectedField}
                                        isValidVariableName={
                                            isValidVariableName
                                        }
                                        checkUniqueVariableName={
                                            checkUniqueVariableName
                                        }
                                    />
                                    <ContentNodeFormPreview
                                        procedureRef={procedureRef}
                                        selectedField={selectedField}
                                        setSelectedField={setSelectedField}
                                    />
                                </Box>
                            </FormControl>
                            <ContentNodeFormFailureDetails />
                            <ContentNodeFormSettings
                                selectCompletionNode={selectCompletionNode}
                                completionNode={completionNode}
                            />
                            <ContentNodeFormPreset />
                            <Box
                                component="fieldset"
                                border="1px solid #cccccc"
                                borderRadius="5px"
                                padding="9px"
                            >
                                <ContentNodeFormAnswerOptions />
                                <ContentNodeFormAnswerOptionFields
                                    node={node}
                                    nodes={nodes}
                                />
                            </Box>
                            {openSaveDataDialog && (
                                <SaveNodeDataDialog
                                    openSaveDataDialog={openSaveDataDialog}
                                    setOpenSaveDataDialog={
                                        setOpenSaveDataDialog
                                    }
                                    isSubmitting={isSubmitting}
                                    onCancel={onCancel}
                                />
                            )}
                            <div className={nodeFormStyles.submitButtonRow}>
                                <button
                                    className={commonFormStyles.cancel}
                                    type="button"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        if (dirty) {
                                            setOpenSaveDataDialog(true);
                                        } else {
                                            onCancel();
                                        }
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={commonFormStyles.submit}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
                {selectTargetNodeForEditor && (
                    <SelectTargetNodeForm
                        nodes={nodes}
                        activeNode={node}
                        loadedTargetNode={loadedTargetNode}
                        onSelectedTargetNode={(values) => {
                            handleSelectedTargetNode(
                                values,
                                selectTargetNodeForEditor
                            );
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default ContentNodeForm;
