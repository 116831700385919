import React, { useRef } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

// const Controls = ({ zoomIn, zoomOut, resetTransform }) => (
//     <>
//         <button onClick={() => zoomIn()}>+</button>
//         <button onClick={() => zoomOut()}>-</button>
//         <button onClick={() => resetTransform()}>x</button>
//     </>
// );

const ZoomPinchComponent = ({ src, label }) => {
    const transformComponentRef = useRef(null);

    // const zoomToImage = () => {
    //     if (transformComponentRef.current) {
    //         const { zoomToElement } = transformComponentRef.current;
    //         zoomToElement('imgExample' + label);
    //     }
    // };

    return (
        <TransformWrapper
            // initialScale={1}
            // initialPositionX={200}
            // initialPositionY={100}
            ref={transformComponentRef}
        >
            {(utils) => (
                <React.Fragment>
                    {/* <Controls {...utils} /> */}
                    <TransformComponent
                        wrapperStyle={{
                            width: '100%',
                            height: '100%',
                        }}
                        contentStyle={{
                            width: '100%',
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={src}
                            alt={label}
                            id={'imgExample' + label}
                            // width={'100%'}
                            style={{
                                objectFit: 'cover',
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}
                        />
                        {/* <div onClick={zoomToImage}>Example text</div> */}
                    </TransformComponent>
                </React.Fragment>
            )}
        </TransformWrapper>
    );
};

export { ZoomPinchComponent };
