import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
    actionBarRight: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        width: '100%',
        minWidth: '130px',
        [theme.breakpoints.up('sm')]: {
            // marginLeft: theme.spacing(3),
            width: 'auto',
            margin: '0px',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#BDC3C7',
    },
    inputRoot: {
        color: 'inherit',
        border: '1px solid #BDC3C7',
        borderRadius: '5px',
        height: '45px',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        paddingRight: '5.20rem',
        transition: theme.transitions.create('width'),
        width: '100%',
        // [theme.breakpoints.up('md')]: {
        //     width: '30ch',
        // },
    },
    inputInputWithoutFilter: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        paddingRight: '0',
        transition: theme.transitions.create('width'),
        width: '100%',
        // [theme.breakpoints.up('md')]: {
        //     width: '30ch',
        // },
    },
    filterContainer: {
        position: 'absolute',
        top: '0',
        right: '0',
        height: '100%',
        cursor: 'pointer',
        zIndex: '2',
        maxWidth: '250px',
    },
    filterContent: {
        marginLeft: '5px',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    filterBadge: {
        paddingRight: '9px',
        marginRight: '10px',
        '& span': {
            backgroundColor: '#1ABB9C',
            color: '#ffffff',
        },
    },
    filterText: {
        fontSize: '12px',
        marginTop: '0',
        color: '#BDC3C7',
    },
    filterClearButton: {
        border: '1px solid #394a61',
        color: '#394a61',
        borderRadius: '3px',
    },
    filterButtonPadding: {
        padding: '6px 30px',
    },
    filterButtons: {
        cursor: 'text',
        justifyContent: 'flex-end',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        padding: '0',
    },
}));

export { useStyles };
