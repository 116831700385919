import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Button,
} from '@mui/material';

const ResetThemeIconDialog = ({ setDialogData, dialogData, handleReset }) => {
    return (
        <Dialog
            open={!!dialogData}
            onClose={() => setDialogData(null)}
            aria-labelledby="alert-reset-icon-dialog-title"
            aria-describedby="alert-reset-icon-dialog-description"
        >
            <DialogTitle id="alert-reset-icon-dialog-title">
                Reset Changes
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-reset-icon-dialog-description">
                    Do you want to reset the icon back to the default?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDialogData(null)} autoFocus>
                    No
                </Button>
                <Button onClick={() => handleReset(dialogData)}>Yes</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ResetThemeIconDialog;
