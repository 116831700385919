import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { makeCommonFormPreviewStyles } from '../../ProcedureFormNode/styles';
import {
    displayProcedureContentVersion,
    loadChecklist,
} from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import { resetCurrentTree } from 'GeminiViewerComponent/components/Procedure/_features/procedureSlice';
import {
    setActiveSessionId,
    setChecklistInitialState,
} from 'GeminiViewerComponent/components/Procedure/_features/checklistDataSlice';
import {
    resetSessionData,
    submitSessionData,
} from 'GeminiViewerComponent/_features/procedureSession/procedureSessionSlice';

const SessionSubmitDialog = ({ openConfirmDialog, setOpenConfirmDialog }) => {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const formClasses = makeCommonFormPreviewStyles(theme);

    const resetSession = () => {
        setOpenConfirmDialog(false);
        dispatch(resetCurrentTree());
        dispatch(loadChecklist(true));
        dispatch(displayProcedureContentVersion(null));
        dispatch(setChecklistInitialState());
        dispatch(resetSessionData());
        dispatch(submitSessionData(true));
        dispatch(setActiveSessionId(null));
        setOpenConfirmDialog(false);
    };

    return (
        <Dialog
            fullWidth
            open={openConfirmDialog}
            onClose={resetSession}
            maxWidth={'sm'}
        >
            <DialogTitle>Procedure submitted successfully.</DialogTitle>
            <DialogActions>
                <Button
                    className={formClasses.submit}
                    onClick={resetSession}
                    sx={{ padding: '6px !important' }}
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { SessionSubmitDialog };
