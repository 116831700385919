import { useCursor, useGLTF } from '@react-three/drei';
import { useState } from 'react';
import { Vector3 } from 'three';

export default function GenericModel({
    explodeFactor,
    containerRef,
    ...props
}) {
    const { nodes } = useGLTF(props.modelURL);

    // eslint-disable-next-line no-unused-vars
    const [hovered, setHovered] = useState(null);

    useCursor(hovered);

    var centers = [];

    const meshNodeKeys = Object.keys(nodes ?? {}).filter(
        (key) => nodes[key].type === 'Mesh'
    );

    meshNodeKeys.forEach((key) => {
        const node = nodes[key];
        node.geometry.computeBoundingSphere();
        centers.push(node.geometry.boundingSphere.center);
    });

    var center = new Vector3();
    centers.forEach(function (vec) {
        center = center.add(vec);
    });
    center.x /= centers.length;
    center.y /= centers.length;
    center.z /= centers.length;

    var translations = {};

    meshNodeKeys.forEach((key) => {
        const node = nodes[key];
        // Finding the vector between the center and the part and normalising it
        var childCenter = node.geometry.boundingSphere.center;
        var direction = childCenter.sub(center);
        direction = direction.normalize();

        var position = { ...node.position };

        // NOTE: have to have plus here to get explode to work
        position.x += direction.x * explodeFactor;
        position.y += direction.y * explodeFactor;
        position.z += direction.z * explodeFactor;

        translations[key] = position;
    });
}
