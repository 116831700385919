import React from 'react';
import { Box, Checkbox } from '@mui/material';

const TextFormField = ({ value, setFieldValue }) => {
    return (
        <Box>
            <Checkbox
                checked={Boolean(value)}
                onClick={(e) =>
                    setFieldValue(
                        'display_label_beside_field',
                        e.target.checked
                    )
                }
            />
            Display label beside field
        </Box>
    );
};

export default TextFormField;
