import { config } from 'gemini-config';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import {
    createProgressParams,
    FormDataUtils,
    getNetworkService,
    mapCompanionContent,
} from 'GeminiViewerComponent/_helpers';

const baseUrl = `${config.apiUrl}/CompanionApp`;
const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});

const getAll = async (searchString = '', sort = 'display_name') => {
    const filters = [];
    if (searchString) {
        filters.push(`display_name@=*${searchString}`);
    }

    let params =
        filters.length > 0
            ? {
                  filters: filters.join(','),
              }
            : {};
    if (sort) {
        params = { ...params, sorts: sort };
    }

    const query = new URLSearchParams(params).toString();

    const user = JSON.parse(localStorage.getItem('user'));
    const response = await networkService.get(
        `${baseUrl}/client/${user.client_id}?${query}`
    );

    const companionApps = response.data;
    const pageInfo = response.headers['x-pagination'];
    return { pageInfo: pageInfo, companionApps: companionApps };
};

const getById = async (companionId) => {
    const { s3_prefix } = JSON.parse(localStorage.getItem('user'));
    const response = await networkService.get(`${baseUrl}/${companionId}`);
    return mapCompanionContent(response.data, s3_prefix);
};

const _create = async (params, s3Prefix = '') => {
    const user = JSON.parse(localStorage.getItem('user'));
    const formData = new FormData();

    FormDataUtils.safeAppend(formData, [
        ['client_id', user.client_id],
        ['display_name', params.display_name],
        ['app_url', params.app_url],
        ['app_icon_image', params.app_icon_image],
        ['app_mode', params.app_mode],
    ]);

    var progressParams = createProgressParams(params);

    const response = await networkService.postMultiFormData(
        baseUrl,
        formData,
        progressParams
    );

    return mapCompanionContent(response.data, s3Prefix);
};

const _update = async (id, params) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const formData = new FormData();

    FormDataUtils.safeAppend(formData, [
        ['client_id', user.client_id],
        ['display_name', params.display_name],
        ['app_url', params.app_url],
        ['app_icon_image', params.app_icon_image],
        ['app_mode', params.app_mode],
    ]);

    var progressParams = createProgressParams(params);

    try {
        const response = await networkService.putMultiFormData(
            `${baseUrl}/${id}`,
            formData,
            progressParams
        );
        return mapCompanionContent(response.data);
    } catch (error) {
        return error;
    }
};

const _delete = async (companionId) => {
    try {
        const response = await networkService.delete(
            `${baseUrl}/${companionId}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const deleteArray = async (companionIds) => {
    try {
        let queryParams = [];

        companionIds?.map((companion_id) => {
            queryParams.push(`ids=${companion_id}`);
        });
        const query = queryParams.join('&').toString();

        const response = await networkService.delete(`${baseUrl}?${query}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const fetchUsageById = async (companionId) => {
    const response = await networkService.get(
        `${baseUrl}/usage/${companionId}`
    );
    return response.data;
};

export const companionAppsService = {
    getAll,
    getById,
    create: _create,
    update: _update,
    delete: _delete,
    deleteArray,
    fetchUsageById,
};
