import { useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { modalStyles } from 'GeminiViewerComponent/components/styles';
import { Modal } from '@mui/material';
import {
    getGenericModalContent,
    getGenericModalVis,
    toggleGenericModal,
} from '_features/globals/visibilitySlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

function GenericDisplayModal() {
    const theme = useSelector(selectActiveTheme);
    const classes = modalStyles(theme);
    const dispatch = useDispatch();
    const modalVis = useSelector(getGenericModalVis);
    const modalContent = useSelector(getGenericModalContent);

    const handleClose = async () => {
        dispatch(toggleGenericModal());
    };
    useEffect(() => {}, [modalContent, dispatch]);

    return (
        <div>
            <Modal open={modalVis} onClose={handleClose}>
                <div
                    className={clsx(
                        classes.modalContainer,
                        classes.roundedCornerContainer
                    )}
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: modalContent,
                        }}
                    ></div>
                </div>
            </Modal>
        </div>
    );
}

export { GenericDisplayModal };
