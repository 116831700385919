import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';

export const tableStyles = makeStyles(
    (theme) => ({
        popupActions: {
            display: 'flex !important',
            justifyContent: 'flex-end',
        },
        gridLink: {
            textDecoration: 'none',
        },
        gridLinkSpan: {
            backgroundColor: '#3598DB',
            padding: '5px',
            color: 'white',
            borderRadius: '5px',
            fontSize: '12px',
        },
        pin: {
            cursor: 'pointer',
            '& svg': {
                height: '20px',
                width: '20px',
            },
            '& svg:hover': {
                filter: 'brightness(50%)',
            },
        },
        isPinned: {
            color: '#E84C3C !important',
        },
        newObject: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            cursor: 'pointer',
        },
        flexContainer: {
            boxSizing: 'border-box',
            '&:focus': {
                outline: 'none !important',
            },
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            overflow: 'visible !important',
            overflowWrap: 'anywhere',
        },
        scrollingFlexContainer: {
            display: 'flex',
            alignItems: 'center',
            padding: '4px',
            boxSizing: 'border-box',
            '&:focus': {
                outline: 'none !important',
            },
            cursor: 'pointer',
            overflow: 'auto !important',
            overflowWrap: 'anywhere',
        },
        searchBarFlexContainer: {
            boxSizing: 'border-box',
            '&:focus': {
                outline: 'none !important',
            },
            cursor: 'pointer',
            '& .ReactVirtualized__Table__headerColumn': {
                height: '80px',
                borderBottom: '1px solid #ececec',
            },
        },
        tableRowHover: {
            '&.ReactVirtualized__Table__row': {
                '&:hover': {
                    backgroundColor: '#ecf0f1 !important',
                },
                overflow: 'visible !important',
            },
        },
        tableCell: {
            flex: 1,
            color: '#34485E',
            '& .MuiCheckbox-colorSecondary.Mui-checked': {
                color: '#3598DB',
            },
            '& .MuiCheckbox-colorPrimary.Mui-checked': {
                color: '#3598DB',
            },
            border: 'none',
            padding: '0 0 0 4px',
        },
        noClick: {
            cursor: 'initial',
        },
        launchLink: {
            color: '#7E8C8D',

            '& svg': {
                height: '20px',
                width: '20px',
            },
        },
        tableCellHeader: {
            textTransform: 'uppercase',
            color: '#7E8C8D',
            fontWeight: 'bold',
            fontSize: '10px',
        },
        selectedRow: {
            backgroundColor: '#FFFFFF',
        },
        activeCellContainer: {
            color: 'white',
            textAlign: 'center',
            borderRadius: '5px',
            width: '50%',
        },
        activeCell: {
            backgroundColor: '#18BC9B',
        },
        inactiveCell: {
            backgroundColor: '#E84C3C',
        },
        hidden: {
            display: 'none',
        },
        statusCell: {
            fontSize: 'xx-small',
            textTransform: 'uppercase',
            padding: '1px 15px',
            textAlign: 'center',
            width: '100px',
            border: 'solid',
            borderRadius: '20px',
            borderWidth: '1px',
        },
        approvedCell: {
            color: '#BDC3C7',
        },
        pendingCell: {
            color: '#3598DB',
        },
        rejectedCell: {
            color: '#E84C3C',
        },
        sortIndicatorContainer: {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
            width: '100%',
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#BDC3C7',
        },
        inputRoot: {
            color: 'inherit',
            border: '1px solid #BDC3C7',
            borderRadius: '5px',
            height: '45px',
            maxWidth: '210px',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            paddingRight: '14px',
            transition: theme.transitions.create('width'),
            width: '100%',
        },
        searchBarWrapperAll: {
            flexWrap: 'wrap',
            alignItems: 'center',
            border: 'none',
        },
        // searchBarWrapper: {
        //     paddingTop: '8px !important',
        // },
        zoneImageThumbnail: {
            '&:hover': {
                width: '200px',
                position: 'absolute',
                height: '200px',
                maxWidth: 'unset !important',
                maxHeight: 'unset !important',
                left: '80px',
                top: '0',
                zIndex: '1',
            },
        },
        usageLabel: {
            backgroundColor: '#DCDCDC',
            borderRadius: '10px',
            color: '#000000',
            padding: '0px 5px',
            margin: '2.5px 5px',
            display: 'inline-block',
            cursor: 'pointer',
        },
        tooltip: {
            whiteSpace: 'inherit',
        },
        aggTooltipTitle: {
            color: '#000',
            paddingBottom: '5px',
        },
        aggTooltipGrouping: {
            border: '1px solid black',
            margin: '5px',
            marginBottom: '10px',
            padding: '5px',
            borderRadius: '15px',
        },
    }),
    { index: 1 }
);
