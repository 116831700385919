import { config } from 'gemini-config';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import {
    getNetworkService,
    FormDataUtils,
    prefixUrl,
    createProgressParams,
} from 'GeminiViewerComponent/_helpers';

const baseUrl = `${config.gapsApiUrl}/media`;

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});

const mapMedia = (media, s3Prefix) => {
    media.url = prefixUrl(media.url, s3Prefix);
    return media;
};

const mapMedias = (medias, s3Prefix) => {
    return medias.map((media) => {
        return mapMedia(media, s3Prefix);
    });
};

const getAllByRange = async (
    startIndex,
    stopIndex,
    searchString = '',
    sort = 'display_name',
    s3Prefix = '',
    mediaTypes = 'Image',
    min_file_size = '',
    max_file_size = '',
    min_file_width = '',
    max_file_width = '',
    min_file_height = '',
    max_file_height = '',
    draft = '',
    lastModifiedDate = ''
) => {
    const { client_id } = accountService.getUser();
    let filters = [];
    if (typeof draft === 'boolean') {
        filters.push(`draft==${draft}`);
    }
    if (mediaTypes?.length > 0) {
        filters.push(`media_type==${mediaTypes.join('|').toString()}`);
    }
    if (searchString?.length > 0) {
        filters.push(`display_name@=*${searchString}`);
    }
    if (min_file_size && min_file_size !== 'No Min') {
        filters.push(`file_size>=${min_file_size}`);
    }
    if (max_file_size && max_file_size !== 'No Max') {
        filters.push(`file_size<=${max_file_size}`);
    }
    if (min_file_width && min_file_width !== 'No Min') {
        filters.push(`file_width>=${min_file_width}`);
    }
    if (max_file_width && max_file_width !== 'No Max') {
        filters.push(`file_width<=${max_file_width}`);
    }
    if (min_file_height && min_file_height !== 'No Min') {
        filters.push(`file_height>=${min_file_height}`);
    }
    if (max_file_height && max_file_height !== 'No Max') {
        filters.push(`file_height<=${max_file_height}`);
    }
    if (lastModifiedDate) {
        filters.push(`modified_date>=${lastModifiedDate}`);
    }
    let params = {
        filters: filters.join(','),
        sorts: sort,
    };

    if (startIndex !== 0 || stopIndex !== 0) {
        params.start_index = startIndex;
        params.stop_index = stopIndex;
    }

    const query = new URLSearchParams(params).toString();
    const response = await networkService.get(
        `${baseUrl}/getallbyclientid/${client_id}?${query}`
    );
    const medias = mapMedias(response.data, s3Prefix);
    const pageInfo = response.headers['x-pagination'];
    return { pageInfo: pageInfo, medias: medias };
};

const create = async (params, s3Prefix = '') => {
    try {
        const { client_id } = accountService.getUser();

        const formData = new FormData();
        FormDataUtils.safeAppend(formData, [
            ['client_id', client_id],
            ['display_name', params.display_name],
            ['draft', params.draft],
            ['media_data', params.media_data],
        ]);

        let progressParams = createProgressParams(params);
        const response = await networkService.postMultiFormData(
            baseUrl,
            formData,
            progressParams
        );
        return mapMedia(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const update = async (params, s3Prefix = '') => {
    try {
        const { client_id } = accountService.getUser();

        const formData = new FormData();

        FormDataUtils.safeAppend(formData, [
            ['client_id', client_id],
            ['display_name', params.display_name],
            ['draft', params.draft],
            ['media_data', params.media_data],
        ]);

        const response = await networkService.putMultiFormData(
            `${baseUrl}/${params.media_id}`,
            formData
        );
        return mapMedia(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const fetchMediaById = async (id, s3Prefix = '') => {
    const response = await networkService.get(`${baseUrl}/${id}`);
    return mapMedia(response.data, s3Prefix);
};

const _delete = async (id) => {
    try {
        const response = await networkService.delete(`${baseUrl}/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const mediaService = {
    create,
    update,
    delete: _delete,
    getAllByRange,
    fetchMediaById,
};
