import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Toolbar, Tooltip, Typography } from '@mui/material';

import { Action } from '_helpers';
import { useStyles } from './styles';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { useToolbarStyles } from 'GeminiViewerComponent/components/styles';
import useAnalyticsEventTracker from 'shared/hooks/useAnalyticsEventTracker';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { sortBy as lodashSortBy } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { ResizableTable } from 'GeminiViewerComponent/components/_Tables/ResizableTable';
import {
    fetchMoreAssets,
    selectAllAssets,
    selectAssetsLoadingStatus,
} from '_features/assets/assetsTableSlice';
import {
    resizableLabelHeader,
    resizableLabelCell,
    resizableActionCell,
    resizableCheckCell,
    resizableCheckHeaderCell,
    resizablePinCell,
    resizableAssetTypeIconCell,
    resizableAssetDisclaimerIconCell,
} from 'components';

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, selectedItems, totalItems } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} of {totalItems} selected
                </Typography>
            ) : (
                <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                ></Typography>
            )}

            {numSelected > 0 && (
                <Tooltip title="Publish">
                    <PopupAction
                        action={Action.PublishArray}
                        object={selectedItems}
                        level={'asset'}
                        showLabel={false}
                    />
                </Tooltip>
            )}
        </Toolbar>
    );
};

export const createLoadedDataObject = (length) => {
    const obj = {};
    for (let i = 0; i < length; i++) {
        obj[i] = 'Loading';
    }
    return obj;
};

function TableGrid({
    assets,
    pageInfo,
    searchString,
    sortDirection,
    sortBy,
    handleSort,
    loadingStatus,
    assetType,
    assetPayload,
    readOnly,
    accordianTable = false,
    categoryId = null,
    setFilteredAssets = () => {},
    singleCategory = false,
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const tableClasses = tableStyles();
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);
    const [cardWrapperHeight, setCardWrapperHeight] = useState(null);
    const allRows = useSelector(selectAllAssets);
    const [filteredloadedRowsMap, setFilteredloadedRowsMap] = useState([]);
    const assetsStatus = useSelector(selectAssetsLoadingStatus);

    const loadedRowsMap = useSelector((state) => state.assets.loadedRowsMap);

    const headerHeight = 40;
    const rowHeight = 60;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isSelected = (id) => selected.indexOf(id) !== -1;
    const gaEventTracker = useAnalyticsEventTracker('Assets');

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    let prevSelectedAll = usePrevious(selectedAll);

    useEffect(() => {
        setSelected([]);
        setSelectedAll(false);
    }, [assetType]);

    if (prevSelectedAll && selectedAll) {
        if (categoryId || categoryId === 0) {
            if (selected.length !== assets?.length) {
                setSelected(assets.map((row) => row.asset_id));
                setSelectedAll(true);
            }
        } else {
            if (selected.length !== allRows?.length) {
                setSelected(allRows.map((row) => row.asset_id));
                setSelectedAll(true);
            }
        }
    }

    const handleSelectAllClick = useCallback(
        (event) => {
            if (event.target.checked) {
                if (categoryId || categoryId === 0) {
                    const newSelecteds = assets.map((row) => row.asset_id);
                    setSelected(newSelecteds);
                    setSelectedAll(true);
                    return;
                } else {
                    const newSelecteds = allRows.map((row) => row.asset_id);
                    setSelected(newSelecteds);
                    setSelectedAll(true);
                    return;
                }
            }
            setSelected([]);
            setSelectedAll(false);
        },
        [categoryId, assets, allRows]
    );

    const handleClick = useCallback(
        (event, id) => {
            event.stopPropagation();
            const selectedIndex = selected.indexOf(id);
            let newSelected = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
            } else {
                newSelected = selected.filter(
                    (selectedId) => selectedId !== id
                );
            }
            setSelected(newSelected);
            if (categoryId || categoryId === 0) {
                setSelectedAll(newSelected.length === assets?.length);
            } else {
                setSelectedAll(newSelected.length === allRows?.length);
            }
        },
        [selected, categoryId, assets, allRows]
    );

    const handleRowClick = ({ rowData }) => {
        gaEventTracker('Asset view by id', 'Grid View', rowData.asset_id);
        navigate(
            `/${assetType === 'procedures' ? 'procedure' : 'asset'}/${
                rowData.asset_id
            }`
        );
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'asset_id',
                size: 50,
                minSize: 50,
                enableResizing: false,
                label: 'Select',
                padding: 'checkbox',
                cellLevel: 'asset',
                numSelected: selected.length,
                rowCount:
                    categoryId || categoryId === 0
                        ? assets.length
                        : allRows.length,
                handleClick: handleClick,
                handleSelectAllClick: handleSelectAllClick,
                isSelected: isSelected,
                header: (info) =>
                    resizableCheckHeaderCell(
                        null,
                        info?.header?.column?.columnDef
                    ),
                cell: (info) =>
                    resizableCheckCell(
                        { rowData: info?.row?.original },
                        info?.column?.columnDef
                    ),
            },
            {
                accessorKey: 'asset_id',
                size: 50,
                minSize: 50,
                enableResizing: false,
                padding: 'none',
                label: '',
                actionCellLevel: 'asset',
                targetPopup: 'asset',
                header: (info) =>
                    resizableLabelHeader(null, info?.header?.column?.columnDef),
                cell: (info) =>
                    resizableActionCell(
                        { rowData: info?.row?.original },
                        info?.column?.columnDef
                    ),
            },
            {
                accessorKey: 'asset_id',
                size: 50,
                minSize: 50,
                label: 'Pin',
                enableResizing: false,
                padding: 'checkbox',
                cellLevel: 'asset',
                header: (info) =>
                    resizableLabelHeader(null, info?.header?.column?.columnDef),
                cell: (info) =>
                    resizablePinCell(
                        { rowData: info?.row?.original },
                        info?.column?.columnDef
                    ),
            },
            {
                accessorKey: 'display_name',
                padding: 'normal',
                label: 'Name',
                size: 200,
                minSize: 180,
                maxSize: 500,
                sortDirection: sortDirection,
                sortBy: sortBy,
                handleSort: handleSort,
                assetLabel: true,
                header: (info) =>
                    resizableLabelHeader(null, info?.header?.column?.columnDef),
                cell: (info) =>
                    resizableLabelCell(
                        {
                            ...info?.row?.original,
                            toolTip: `${info?.row?.original['display_name']} - ${info?.row?.original['asset_id']}`,
                        },
                        info?.column?.columnDef
                    ),
            },
            {
                accessorKey: 'asset_id',
                label: '',
                size: 50,
                minSize: 50,
                enableResizing: false,
                padding: 'normal',
                dispatch: dispatch,
                header: (info) =>
                    resizableLabelHeader(null, info?.header?.column?.columnDef),
                cell: (info) =>
                    resizableAssetDisclaimerIconCell(
                        { rowData: info?.row?.original },
                        info?.column?.columnDef
                    ),
            },
            {
                accessorKey: 'zone_count',
                padding: 'normal',
                label: 'N° Zones',
                size: 100,
                minSize: 50,
                enableResizing: false,
                sortDirection: sortDirection,
                sortBy: sortBy,
                handleSort: handleSort,
                header: (info) =>
                    resizableLabelHeader(null, info?.header?.column?.columnDef),
                cell: (info) =>
                    resizableLabelCell(
                        info?.row?.original,
                        info?.column?.columnDef
                    ),
            },
            {
                accessorKey: 'owner_last_name',
                padding: 'normal',
                label: 'Owner',
                size: 200,
                minSize: 120,
                sortDirection: sortDirection,
                sortBy: sortBy,
                handleSort: handleSort,
                header: (info) =>
                    resizableLabelHeader(null, info?.header?.column?.columnDef),
                cell: (info) =>
                    resizableLabelCell(
                        info?.row?.original,
                        info?.column?.columnDef
                    ),
            },
            {
                accessorKey: 'last_release_published_date',
                padding: 'normal',
                label: 'Published',
                size: 180,
                minSize: 120,
                sortDirection: sortDirection,
                sortBy: sortBy,
                handleSort: handleSort,
                header: (info) =>
                    resizableLabelHeader(null, info?.header?.column?.columnDef),
                cell: (info) =>
                    resizableLabelCell(
                        info?.row?.original,
                        info?.column?.columnDef
                    ),
                transform: (data) =>
                    data
                        ? moment(data).local().format('MM/DD/YYYY hh:mm A')
                        : '',
            },
            {
                accessorKey: 'last_draft_published_date',
                padding: 'normal',
                label: 'Draft Published',
                size: 180,
                minSize: 120,
                sortDirection: sortDirection,
                sortBy: sortBy,
                handleSort: handleSort,
                transform: (data) =>
                    data
                        ? moment(data).local().format('MM/DD/YYYY hh:mm A')
                        : '',
                header: (info) =>
                    resizableLabelHeader(null, info?.header?.column?.columnDef),
                cell: (info) =>
                    resizableLabelCell(
                        info?.row?.original,
                        info?.column?.columnDef
                    ),
            },
        ],
        [
            allRows.length,
            assets.length,
            categoryId,
            dispatch,
            handleClick,
            handleSelectAllClick,
            handleSort,
            isSelected,
            selected.length,
            sortBy,
            sortDirection,
        ]
    );

    if (assetType === 'assets') {
        columns.splice(3, 0, {
            accessorKey: 'asset_type_id',
            label: 'Asset Type',
            size: 100,
            minSize: 100,
            enableResizing: false,
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableAssetTypeIconCell(
                    { cellData: info?.row?.original['asset_type_id'] },
                    info?.column?.columnDef
                ),
        });
    }

    const loadMoreRows = useCallback(
        async ({ startIndex, stopIndex }) => {
            if (assetsStatus === LoadingStatus.Loaded) {
                if (categoryId || categoryId === 0) {
                    const loadedAssets = new Map();
                    const moreCategoriesAssets = await dispatch(
                        fetchMoreAssets({
                            startIndex: startIndex,
                            stopIndex: stopIndex,
                            searchString: searchString,
                            assetCategoryId: categoryId,
                            ...assetPayload,
                        })
                    );
                    if (
                        moreCategoriesAssets.payload?.assets &&
                        moreCategoriesAssets.payload?.assets?.length > 0
                    ) {
                        if (startIndex === 0) {
                            setFilteredloadedRowsMap(
                                createLoadedDataObject(
                                    moreCategoriesAssets.payload.assets?.length
                                )
                            );
                            setFilteredAssets([
                                ...moreCategoriesAssets.payload.assets,
                            ]);
                        } else {
                            moreCategoriesAssets.payload.assets.map((e) =>
                                loadedAssets.set(e.asset_id, e)
                            );
                            setFilteredAssets((prev) => {
                                [
                                    ...prev,
                                    ...moreCategoriesAssets.payload.assets,
                                ].forEach((e) =>
                                    loadedAssets.set(e.asset_id, e)
                                );
                                return [
                                    ...Array.from(loadedAssets.values())
                                        .sort(
                                            lodashSortBy('display_name', 'DESC')
                                        )
                                        .sort(lodashSortBy('is_pinned', 'ASC')),
                                ];
                            });
                            setFilteredloadedRowsMap(
                                createLoadedDataObject(loadedAssets.size)
                            );
                        }
                    } else {
                        setFilteredloadedRowsMap(createLoadedDataObject(0));
                        setFilteredAssets([]);
                    }
                } else {
                    await dispatch(
                        fetchMoreAssets({
                            startIndex: startIndex,
                            stopIndex: stopIndex,
                            searchString: searchString,
                            ...assetPayload,
                        })
                    );
                }
            }
        },
        [
            assetPayload,
            assetsStatus,
            categoryId,
            dispatch,
            searchString,
            setFilteredAssets,
        ]
    );

    return (
        <div className={classes.root}>
            <div
                style={{
                    height: `${
                        cardWrapperHeight
                            ? cardWrapperHeight + 'px'
                            : `calc(100vh - ${
                                  selected.length > 0 ? '290px' : '270px'
                              })`
                    }`,
                    minWidth: '750px',
                    overflow: 'auto hidden',
                }}
            >
                {selected.length > 0 && !readOnly && (
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        selectedItems={selected}
                        totalItems={pageInfo.TotalCount}
                    />
                )}
                <ResizableTable
                    initialRows={assets}
                    allRows={allRows}
                    totalRowCount={pageInfo.TotalCount}
                    loadedRowsMap={
                        (categoryId || categoryId === 0) && !singleCategory
                            ? filteredloadedRowsMap
                            : loadedRowsMap
                    }
                    loadMoreRows={loadMoreRows}
                    columns={columns}
                    cellClassName={tableClasses.flexContainer}
                    className={tableClasses.table}
                    rowClassName={clsx(
                        tableClasses.flexContainer,
                        tableClasses.tableRowHover
                    )}
                    headerHeight={headerHeight}
                    rowHeight={rowHeight}
                    onRowClick={handleRowClick}
                    accordianTable={accordianTable}
                    selected={selected}
                    setCardWrapperHeight={setCardWrapperHeight}
                />
            </div>
        </div>
    );
}

export { TableGrid };
