import { config } from 'gemini-config';
import {
    createProgressParams,
    FormDataUtils,
    getNetworkService,
    prefixUrl,
} from 'GeminiViewerComponent/_helpers';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/tool/toolkit`;

const mapToolkit = (tool, s3Prefix) => {
    if (tool?.image_url) {
        tool.image_url = prefixUrl(tool.image_url, s3Prefix);
    }
    return tool;
};

const mapToolkits = (tools, s3Prefix) => {
    return tools.map((tool) => {
        return mapToolkit(tool, s3Prefix);
    });
};

const getToolkits = async (
    oemIDs,
    categoryIDs,
    searchString = '',
    sort = '-display_name',
    s3Prefix = '',
    activeOnly = true
) => {
    const filters = [
        `is_active==${activeOnly.toString()}`,
        `display_name@=*${searchString}`,
    ];
    if (oemIDs?.length > 0) {
        filters.push(`tool_oem_id==${oemIDs.join('|').toString()}`);
    }
    if (categoryIDs?.length > 0) {
        filters.push(`tool_category_id==${categoryIDs.join('|').toString()}`);
    }
    const params = {
        filters: filters.join(','),
        sorts: sort,
    };
    const query = new URLSearchParams(params).toString();
    try {
        const response = await networkService.get(`${baseUrl}?${query}`);
        response.data = mapToolkits(response?.data, s3Prefix);
        const pageInfo = response.headers['x-pagination'];
        return {
            pageInfo: pageInfo,
            toolkits: response?.data,
        };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const create = async (params, s3Prefix = '') => {
    const user = JSON.parse(localStorage.getItem('user'));
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [
        ['display_name', params.display_name],
        ['client_id', user.client_id],
    ]);
    let progressParams = createProgressParams(params);
    try {
        const response = await networkService.postMultiFormData(
            baseUrl,
            formData,
            progressParams
        );
        response.data = mapToolkit(response.data, s3Prefix);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const update = async (params, s3Prefix = '') => {
    const user = JSON.parse(localStorage.getItem('user'));
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [
        ['display_name', params.display_name],
        ['client_id', user.client_id],
    ]);
    let progressParams = createProgressParams(params);
    try {
        const response = await networkService.putMultiFormData(
            `${baseUrl}/${params.toolkit_id}`,
            formData,
            progressParams
        );
        response.data = mapToolkit(response.data, s3Prefix);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _delete = async (toolkit_id) => {
    try {
        const response = await networkService.delete(
            `${baseUrl}/${toolkit_id}`
        );
        return response;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const deleteTool = async ({ toolkit_id, tool_id }) => {
    try {
        const response = await networkService.delete(
            `${baseUrl}/${toolkit_id}/${tool_id}`
        );
        return response;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const addTool = async ({ toolkit_id, tool_id }) => {
    try {
        const response = await networkService.post(
            `${baseUrl}/${toolkit_id}/${tool_id}`
        );
        return response;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

// const deleteArray = async (ids) => {
//     try {
//         const user = JSON.parse(localStorage.getItem('user'));
//         const query = ids.join('&ids=');
//         const response = await networkService.delete(
//             `${baseUrl}/?client_id=${user.client_id}&ids=${query}`
//         );

//         return response.data;
//     } catch (e) {
//         return Promise.reject(e.error);
//     }
// };

const getById = async (id, s3Prefix = '') => {
    try {
        const response = await networkService.get(`${baseUrl}/${id}`);
        return mapToolkit(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const toolkitsService = {
    getToolkits,
    getById,
    create,
    update,
    delete: _delete,
    deleteTool,
    addTool,
};
