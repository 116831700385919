import { useDispatch, useSelector } from 'react-redux';

import { accountsSlice, accountsThunk } from 'app/store';
import makeStyles from '@mui/styles/makeStyles';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    selectActiveTheme,
    selectAllThemes,
    setActiveTheme,
} from 'GeminiViewerComponent/_features/globals/themeSlice';
import { ThemeCard } from './ThemeCard';
const { updateClientThemeInfo } = accountsThunk;

const { selectActiveUser } = accountsSlice;

const makeThemeSelectorStyles = makeStyles({
    container: {
        display: 'grid',
        gap: '1rem',
        gridTemplateColumns: 'repeat(4, 1fr)',
        marginTop: '3rem',
        padding: '10px',
    },
    previewTitleContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        padding: '5px',
        zIndex: '1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    previewTitle: {
        width: '100%',
        fontSize: '22px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#000',
        backgroundColor: 'white',
        padding: '6px',
        borderRadius: '5px',
        border: '2px solid black',
    },
    previewButtons: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '5px',
        padding: '20px',
        zIndex: '1',
        backgroundColor: '#fff9',
        width: '100%',
        height: '100%',
        opacity: '0',
        '&:hover': {
            opacity: '1',
        },
        webkitTransition: 'opacity 0.5s',
        transition: 'opacity 0.5s',
    },
    previewButton: {
        backgroundColor: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        border: '0px',
        padding: '10px',
        borderRadius: '5px',
        fontSize: '18px',
        textTransform: 'uppercase',
        marginTop: '10px',
        cursor: 'pointer',
        '&:disabled': {
            filter: 'brightness(50%)',
        },
        '&:hover': {
            filter: 'brightness(90%)',
        },
    },
});

export const ThemeSelector = (props) => {
    const dispatch = useDispatch();

    const activeUser = useSelector(selectActiveUser);
    const allThemes = useSelector(selectAllThemes);
    const theme = useSelector(selectActiveTheme);

    const themeSelectorStyles = makeThemeSelectorStyles(theme);

    const themeSwitcher = async (selectedTheme, themeKey) => {
        dispatch(activateLoading({ showProgress: false }));
        await dispatch(
            updateClientThemeInfo({
                client_id: activeUser.client_id,
                params: { active_theme: themeKey },
            })
        );
        dispatch(deactivateLoading());
        await dispatch(setActiveTheme(themeKey));
        props.setter(selectedTheme);
    };

    const themeEditor = (selectedTheme) => {
        props.editor(selectedTheme);
    };

    const themeRemover = (selectedTheme) => {
        props.remover(selectedTheme);
    };

    const themeDuplicate = (selectedTheme) => {
        props.duplicate(selectedTheme);
    };

    return (
        <div>
            <div className={themeSelectorStyles.container}>
                {Object.keys(allThemes ?? {}).map((themeName) => (
                    <ThemeCard
                        theme={allThemes[themeName]}
                        name={themeName}
                        themeSwitcher={themeSwitcher}
                        themeEditor={themeEditor}
                        themeRemover={themeRemover}
                        themeDuplicate={themeDuplicate}
                        key={themeName}
                    />
                ))}
            </div>
        </div>
    );
};
