import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import {
    Typography,
    Button,
    Alert,
    FormControl,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import clsx from 'clsx';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';

import { Action, Role } from '_helpers';
import { accountsSlice, accountsThunk } from 'app/store';
import {
    duplicateAsset,
    publishArray,
    deleteArray,
    exportAsset,
} from '_features/assets/assetsTableSlice';
import { deleteCurrentZone } from 'scenes/VideoImporter/videoImportSlice';
import {
    deleteGroupArray,
    deleteGroupById,
} from '_features/groups/groupsSlice';
import { deleteHotspotArray, deleteHotspotById } from 'shared/hotspotsSlice';
import { deleteLinkById } from 'GeminiViewerComponent/_features/links/linksSlice';
import {
    deleteContentById,
    deleteContentArray,
    fetchContentById,
    fetchAllContentVersionByProcedureId,
    deleteContentVersionArray,
    transcribeContents,
} from '_features/content/contentSlice';
import { deleteTag, deleteTagArray } from '_features/tags/tagsSlice';
import {
    deleteAudienceArray,
    deleteAudienceById,
} from '_features/audiences/audiencesSlice';
import { modalStyles } from 'GeminiViewerComponent/components/styles';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import {
    activateLoading,
    deactivateLoading,
    getLoadingState,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { closeModal } from 'GeminiViewerComponent/_features/common/actionModalSlice';
import { deleteZone, deleteZoneNavLink } from '_features/common/editZoneSlice';
import { loadItem } from '_features/common/editItemSlice';
import {
    deleteItem,
    deleteItemArray,
    duplicateItem,
} from '_features/items/itemsTableSlice';
import {
    deleteZoneArray,
    duplicateZone,
} from '_features/zones/zonesTableSlice';
import { getAsset, publishById } from '_features/assets/assetManagerSlice';
import { deleteAsset } from '_features/assets/editAssetSlice';
import getActionIcon from 'GeminiViewerComponent/_helpers/getActionIcon';
import { deleteToolCategory } from '_features/tools/toolsCategorySlice';
import { deleteLocation } from '_features/tools/locationSlice';
import { deleteTool } from '_features/tools/toolSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { deletePartGroup } from '_features/parts/partGroupSlice';
import { deletePartCategory } from '_features/parts/partCategorySlice';
import { deletePart } from '_features/parts/partSlice';
import {
    deleteCompanionApp,
    deleteCompanionApps,
} from 'GeminiViewerComponent/_features/companionApps/companionAppsSlice';
import {
    deleteSnippet,
    duplicateSnippet,
    fetchFolders,
    fetchSnippets,
} from '_features/snippets/snippetsSlice';
import { openPanel, setEditObjectId } from '_features/common/formSlice';
import { ProcedureNodeTypes } from 'GeminiViewerComponent/components/Procedure/ProcedureNodeTypes';
import { updateProcedure } from '_features/procedures/proceduresSlice';
import { setHeaderVariables } from 'GeminiViewerComponent/components/Procedure/_features/procedureSlice';
import { DuplicateAssetForm } from 'forms/DuplicateAssetForm';
import { ConfirmDeleteDialog } from './components/ConfirmDeleteDialog';
import { isEqual } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { deleteAssetExport } from '_features/assets/assetExportSlice';
import {
    deleteNewsFlash,
    deleteNewsFlashArray,
} from 'GeminiViewerComponent/_features/newsFlash/newsFlashSlice';
import {
    deleteCategory,
    deleteCategoryArray,
    resetCategoryStatus,
} from '_features/category/categorySlice';
import {
    deleteDeployementArray,
    deleteDeploymentById,
} from '_features/jobs/jobsSlice';
import { BatchTranscribeFields } from './components/BatchTranscribeFields';

const { deleteAccountById, undeleteAccountById, deleteUserArray } =
    accountsThunk;
const { selectActiveUser } = accountsSlice;

function ActionModal({ isOpen, object, action, level }) {
    const theme = useSelector(selectActiveTheme);
    const classes = modalStyles(theme);
    const dispatch = useDispatch();
    const loadingState = useSelector(getLoadingState);
    const { enqueueSnackbar } = useSnackbar();
    const [modalProps, setModalProps] = useState({
        isDraft: true,
        deleteZone: false,
        deleteContent: false,
        deleteProcedure: false,
        deleteAssociatedContents: [],
        deleteAssociatedContentVersions: [],
        caption_language: null,
    });
    const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] =
        useState(false);
    const activeUser = useSelector(selectActiveUser);

    const onClose = () => dispatch(closeModal());

    let resultAction = null;

    useEffect(() => {
        if (isOpen) {
            window.addEventListener('keyup', handleEnterKey);
        } else {
            window.removeEventListener('keyup', handleEnterKey);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const updateContentViewActionNode = async () => {
        var updatedNodes = [];
        if (
            !object.procedure ||
            object.procedure?.nodes?.length <= 0 ||
            object.asset_type_id !== 3
        ) {
            return null;
        }
        for (const node of object.procedure.nodes ?? []) {
            if (node?.type === ProcedureNodeTypes.contentViewAction.type) {
                let updatedNode = { ...node };
                const objectData = await dispatch(
                    fetchContentById({
                        contentId: updatedNode?.content_id,
                    })
                );
                updatedNode.content_skill_id = objectData.payload?.skill_id;
                updatedNodes.push(updatedNode);
            } else updatedNodes.push(node);
        }
        if (!isEqual(updatedNodes, object.procedure.nodes)) {
            await dispatch(
                updateProcedure({
                    header_image_url: object?.procedure?.header_image_url,
                    procedure_id: object?.procedure?.procedure_id,
                    procedure_json: JSON.stringify(
                        await setHeaderVariables({
                            ...object?.procedure,
                            ...{ nodes: updatedNodes },
                        })
                    ),
                    asset_id: object?.asset_id,
                })
            );
        }
    };

    const confirmAction = async (event) => {
        var altLabel = action.label;
        var altMessage = action.message;
        event.target.disabled = true;
        if (loadingState === false) {
            await dispatch(activateLoading());
            try {
                switch (level) {
                    case 'asset':
                        switch (action.label) {
                            case 'delete':
                                var abandonDelete = false;

                                if (
                                    modalProps.deleteProcedure &&
                                    modalProps.deleteAssociatedContentVersions
                                        ?.length > 0
                                ) {
                                    const contentVersionIds =
                                        modalProps.deleteAssociatedContentVersions.map(
                                            (contentVersion) =>
                                                contentVersion.content_version_id
                                        );
                                    resultAction = await dispatch(
                                        deleteContentVersionArray({
                                            contentIds: contentVersionIds,
                                        })
                                    );

                                    if (resultAction.error) {
                                        abandonDelete = true;
                                    }
                                }

                                if (!abandonDelete)
                                    resultAction = await dispatch(
                                        deleteAsset(object.asset_id)
                                    );
                                if (!resultAction.error) {
                                    dispatch(resetCategoryStatus());
                                }

                                break;
                            case 'duplicate':
                                if (object?.display_name.length < 146) {
                                    resultAction = await dispatch(
                                        duplicateAsset(object.asset_id)
                                    );
                                    if (
                                        resultAction?.meta?.requestStatus ===
                                        'fulfilled'
                                    ) {
                                        if (object?.asset_type_id === 3) {
                                            await dispatch(
                                                setEditObjectId({
                                                    formKey: `procedureForm`,
                                                    object_id:
                                                        resultAction.payload
                                                            ?.asset_id,
                                                })
                                            );
                                            await dispatch(
                                                openPanel({
                                                    formKey: `procedureForm`,
                                                    formAction: 'Edit',
                                                    assetTypeID:
                                                        resultAction.payload
                                                            ?.asset_type_id,
                                                })
                                            );
                                        } else {
                                            await dispatch(
                                                setEditObjectId({
                                                    formKey: `assetForm`,
                                                    object_id:
                                                        resultAction.payload
                                                            ?.asset_id,
                                                })
                                            );
                                            await dispatch(
                                                openPanel({
                                                    formKey: `assetForm`,
                                                    formAction: 'Edit',
                                                    assetTypeID:
                                                        resultAction.payload
                                                            ?.asset_type_id,
                                                })
                                            );
                                        }
                                    }
                                } else {
                                    resultAction = {
                                        meta: { requestStatus: 'error' },
                                    };
                                    altLabel = 'Asset name ';
                                    altMessage = 'is too long';
                                }
                                break;
                            case 'publish':
                                await updateContentViewActionNode();
                                resultAction = await dispatch(
                                    publishById({
                                        asset_id: object.asset_id,
                                        isDraft: modalProps.isDraft,
                                    })
                                );
                                await dispatch(getAsset(object.asset_id));
                                break;
                            case 'publishArray':
                                altLabel = 'Publish assets';
                                resultAction = await dispatch(
                                    publishArray(object)
                                );
                                break;
                            case 'deleteArray':
                                resultAction = await dispatch(
                                    deleteArray(object)
                                );
                                break;
                            case 'export':
                                resultAction = await dispatch(
                                    exportAsset({ asset_id: object.asset_id })
                                );
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'zone':
                        switch (action.label) {
                            case 'delete':
                                resultAction = await dispatch(
                                    deleteZone({
                                        zoneId: object.zone_id,
                                        permanentDelete: modalProps.deleteZone,
                                    })
                                );
                                break;
                            case 'duplicate':
                                if (object?.display_name.length < 146) {
                                    resultAction = await dispatch(
                                        duplicateZone(object.zone_id)
                                    );
                                } else {
                                    resultAction = {
                                        meta: { requestStatus: 'error' },
                                    };
                                    altLabel = 'Zone name ';
                                    altMessage = 'is too long';
                                }
                                break;
                            case 'deleteArray':
                                altLabel = 'Delete zones';
                                resultAction = await dispatch(
                                    deleteZoneArray(object)
                                );
                                object.setZoneIds([]);
                                break;
                            case 'deleteCurrentZone':
                                altLabel = 'Delete zone';
                                await dispatch(deleteCurrentZone());
                                resultAction = {
                                    meta: { requestStatus: 'fulfilled' },
                                };
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'item':
                        switch (action.label) {
                            case Action.Delete.label:
                                resultAction = await dispatch(
                                    deleteItem({
                                        zoneId: object.zone_id,
                                        itemId: object.item_id,
                                    })
                                );
                                break;
                            case Action.Duplicate.label:
                                if (object?.display_name.length < 146) {
                                    resultAction = await dispatch(
                                        duplicateItem({
                                            zoneId: object.zone_id,
                                            itemId: object.item_id,
                                        })
                                    );
                                } else {
                                    resultAction = {
                                        meta: { requestStatus: 'error' },
                                    };
                                    altLabel = 'Item name ';
                                    altMessage = 'is too long';
                                }

                                break;
                            case Action.CopyToZone.label:
                                break;
                            case Action.DeleteArray.label:
                                altLabel = 'Delete items';
                                resultAction = await dispatch(
                                    deleteItemArray(object)
                                );
                                object.setItemIds([]);
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'navLink':
                        switch (action.label) {
                            case 'delete navigation to':
                                resultAction = await dispatch(
                                    deleteZoneNavLink(object)
                                );
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'link':
                        switch (action.label) {
                            case 'delete':
                                resultAction = await dispatch(
                                    deleteLinkById(object.link_id)
                                );
                                if (!resultAction.error) {
                                    dispatch(
                                        loadItem({
                                            zoneId: object.zone_id,
                                            itemId: object.item_id,
                                        })
                                    );
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'group':
                        switch (action.label) {
                            case 'delete':
                                resultAction = await dispatch(
                                    deleteGroupById(object.group_id)
                                );
                                break;
                            case Action.DeleteArray.label:
                                altLabel = 'Delete groups';
                                resultAction = await dispatch(
                                    deleteGroupArray(object?.groupIds)
                                );
                                object.setGroupIds([]);
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'category':
                        switch (action.label) {
                            case 'delete':
                                resultAction = await dispatch(
                                    deleteCategory(object.category_id)
                                );
                                break;
                            case Action.DeleteArray.label:
                                altLabel = 'Delete Categories';
                                resultAction = await dispatch(
                                    deleteCategoryArray(object?.categoryIds)
                                );
                                object.setCategoryIds([]);
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'hotspot_icon':
                        switch (action.label) {
                            case 'delete':
                                resultAction = await dispatch(
                                    deleteHotspotById(object.hotspot_icon_id)
                                );
                                break;
                            case 'deleteArray':
                                altLabel = 'Delete Hotspot Icons';
                                resultAction = await dispatch(
                                    deleteHotspotArray(object.hotspotIds)
                                );
                                object.setHotspotIds([]);
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'companion_app':
                        switch (action.label) {
                            case 'delete':
                                resultAction = await dispatch(
                                    deleteCompanionApp(object.companion_app_id)
                                );
                                break;
                            case 'deleteArray':
                                altLabel = 'Delete Companion Apps';
                                resultAction = await dispatch(
                                    deleteCompanionApps(object.companionAppIds)
                                );
                                object.setCompanionAppIds([]);
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'user':
                        switch (action.label) {
                            case 'deactivate':
                                resultAction = await dispatch(
                                    deleteAccountById(object.user_id)
                                );
                                break;
                            case 'reactivate':
                                resultAction = await dispatch(
                                    undeleteAccountById(object.user_id)
                                );
                                break;
                            case 'deleteArray':
                                altLabel = '';
                                resultAction = await dispatch(
                                    deleteUserArray(object?.accountIds)
                                );
                                object.setAccountIds([]);
                                altMessage = resultAction.payload?.message;
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'content':
                        switch (action.label) {
                            case 'delete':
                                resultAction = await dispatch(
                                    deleteContentById({
                                        contentId: object.content_id,
                                        permanentDelete:
                                            modalProps.deleteContent,
                                    })
                                );
                                break;
                            case 'deleteArray':
                                altLabel = 'Delete Contents';
                                resultAction = await dispatch(
                                    deleteContentArray(object)
                                );
                                object.setContentIds([]);
                                break;
                            case 'transcribeArray':
                                altLabel = 'Transcribe Videos';
                                resultAction = await dispatch(
                                    transcribeContents({
                                        contentIds: object.contentIds,
                                        params: modalProps,
                                    })
                                );
                                object.setContentIds([]);
                                altMessage = resultAction.payload?.message;
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'tag':
                        switch (action.label) {
                            case 'delete':
                                resultAction = await dispatch(
                                    deleteTag(object)
                                );
                                break;
                            case 'deleteArray':
                                altLabel = 'Delete tags';
                                resultAction = await dispatch(
                                    deleteTagArray(object.tagIds)
                                );
                                object.setTagIds([]);
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'tool_category':
                        switch (action.label) {
                            case 'delete':
                                resultAction = await dispatch(
                                    deleteToolCategory(object)
                                );
                                break;
                            // case 'deleteArray':
                            //     altLabel = 'Delete Categories';
                            //     resultAction = await dispatch(
                            //         deleteCategoriesArray(object.categoryIds)
                            //     );
                            //     object.setCategoryIDs([]);
                            //     altMessage = resultAction.payload?.message;
                            //     break;
                            default:
                                break;
                        }
                        break;
                    case 'tool_location':
                        switch (action.label) {
                            case 'delete':
                                resultAction = await dispatch(
                                    deleteLocation(object)
                                );
                                break;
                            // case 'deleteArray':
                            //     altLabel = 'Delete Locations';
                            //     resultAction = await dispatch(
                            //         deleteLocationArray(object.locationIDs)
                            //     );
                            //     object.setLocationIds([]);
                            //     altMessage = resultAction.payload?.message;
                            //     break;
                            default:
                                break;
                        }
                        break;
                    case 'tool':
                        switch (action.label) {
                            case 'delete':
                                resultAction = await dispatch(
                                    deleteTool(object)
                                );
                                break;
                            // case 'deleteArray':
                            //     altLabel = 'Delete Tools';
                            //     resultAction = await dispatch(
                            //         deleteToolArray(object.toolIDs)
                            //     );
                            //     object.setToolIds([]);
                            //     altMessage = resultAction.payload?.message;
                            //     break;
                            default:
                                break;
                        }
                        break;
                    case 'audience':
                        switch (action.label) {
                            case 'delete':
                                resultAction = await dispatch(
                                    deleteAudienceById(object?.audience_id)
                                );
                                break;
                            case 'deleteArray':
                                altLabel = 'Delete Audiences';
                                resultAction = await dispatch(
                                    deleteAudienceArray(object.audienceIds)
                                );
                                object.setAudienceIds([]);
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'part_group':
                        switch (action.label) {
                            case 'delete':
                                resultAction = await dispatch(
                                    deletePartGroup(object)
                                );
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'part_category':
                        switch (action.label) {
                            case 'delete':
                                resultAction = await dispatch(
                                    deletePartCategory(object)
                                );
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'part':
                        switch (action.label) {
                            case 'delete':
                                resultAction = await dispatch(
                                    deletePart(object)
                                );
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'snippet':
                        switch (action.label) {
                            case 'delete':
                                resultAction = await dispatch(
                                    deleteSnippet(object?.snippet_id)
                                );
                                dispatch(fetchSnippets());
                                dispatch(fetchFolders());
                                break;
                            case 'duplicate':
                                resultAction = await dispatch(
                                    duplicateSnippet(object.snippet_id)
                                );
                                dispatch(fetchSnippets());
                                dispatch(fetchFolders());
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'news_flash':
                        switch (action.label) {
                            case 'delete':
                                resultAction = await dispatch(
                                    deleteNewsFlash(object?.news_flash_id)
                                );
                                break;
                            case 'deleteArray':
                                resultAction = await dispatch(
                                    deleteNewsFlashArray(object?.newsFlashIds)
                                );
                                object?.setNewsFlashIds([]);
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'managementDeploy':
                        switch (action.label) {
                            case 'delete':
                                resultAction = await dispatch(
                                    deleteDeploymentById(
                                        object.deployment_package_id
                                    )
                                );
                                break;
                            case 'deleteArray':
                                altLabel = 'Delete Deployment Package';
                                resultAction = await dispatch(
                                    deleteDeployementArray(
                                        object?.deploymentIds
                                    )
                                );
                                object.setDeploymentIds([]);
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'player_export_job':
                        switch (action.label) {
                            case 'delete':
                                resultAction = await dispatch(
                                    deleteAssetExport(
                                        object.player_export_job_id
                                    )
                                );
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
                unwrapResult(resultAction);
                const { message, variant } = snackbarHandler(
                    resultAction.meta.requestStatus,
                    altLabel,
                    altMessage
                );
                enqueueSnackbar(message, {
                    action: (key) => <SnackbarDismiss snackItem={key} />,
                    variant: variant,
                });
                onClose();
                dispatch(deactivateLoading());
            } catch (err) {
                onClose();
                dispatch(deactivateLoading());
                enqueueSnackbar(
                    err?.message ? err.message : `${action.label} failed`,
                    {
                        action: (key) => <SnackbarDismiss snackItem={key} />,
                        variant: 'error',
                    }
                );
            }
        }
    };

    const handleCheckChange = (event) => {
        setModalProps({
            ...modalProps,
            [event.target.name]: event.target.checked,
        });
    };

    const handleSelectChange = (event) => {
        setModalProps({
            ...modalProps,
            [event.target.name]: event.target.value,
        });
    };

    const maybePluralize = (count, noun, suffix = 's') =>
        `${count} ${noun}${count !== 1 ? suffix : ''}`;

    if (level === 'group' && object.asset_count > 0) {
        return (
            <Modal onClose={() => false} open={isOpen}>
                <div className={classes.modalContainer}>
                    <div className={classes.actionIcon}>
                        {getActionIcon(action.iconId)}
                    </div>
                    <Typography
                        className={classes.actionMessage}
                    >{`Unable to delete group because it contains ${maybePluralize(
                        object.asset_count,
                        'asset'
                    )}.`}</Typography>
                    <div>
                        <Button
                            onClick={onClose}
                            className={classes.buttonSpacing}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }

    function modalMessage(messageType) {
        var messageObjectTarget =
            level === 'managementDeploy'
                ? object?.viewer_authentication_name
                : level === 'navLink'
                ? object.target_zone
                : level === 'player_export_job'
                ? `${object.asset_name} asset export`
                : level === 'snippet'
                ? object.display_name
                : level === 'tag'
                ? object?.name
                : object.display_name ??
                  `${object.first_name} ${object.last_name}`;

        switch (messageType) {
            case Action.PublishArray.label:
                return (
                    <Typography
                        className={classes.actionMessage}
                    >{`Are you sure you want to publish all of the selected assets?`}</Typography>
                );
            case Action.DeleteArray.label:
                return (
                    <Typography
                        className={classes.actionMessage}
                    >{`Are you sure you want to delete all of the selected ${level}s?`}</Typography>
                );
            case Action.DeleteCurrentZone.label:
                return (
                    <Typography
                        className={classes.actionMessage}
                    >{`Are you sure you want to delete ${object?.name} zone?`}</Typography>
                );
            case Action.DeploymentPackageJob.label:
                return (
                    <Typography
                        className={classes.actionMessage}
                    >{`Create new job for ${object?.name}?`}</Typography>
                );
            case Action.TranscribeArray.label:
                return (
                    <Typography
                        className={classes.actionMessage}
                    >{`Transcribe ${object?.contentIds.length} video(s)?`}</Typography>
                );
            default:
                return (
                    <>
                        {object?.orbit_frame >= 0 ? (
                            <Alert
                                severity="error"
                                sx={{ fontWeight: 600, marginBottom: '1rem' }}
                            >
                                Zones that are part of an orbit sequence may not
                                be copied.
                            </Alert>
                        ) : (
                            <Typography
                                className={classes.actionMessage}
                            >{`Are you sure you want to ${action.label} "${messageObjectTarget}"?`}</Typography>
                        )}
                    </>
                );
        }
    }

    const handleEnterKey = (event) => {
        event.key === 'Enter' && confirmAction();
        window.removeEventListener('keyup', handleEnterKey);
    };

    const fetchAssociatedContent = async (e) => {
        dispatch(activateLoading());
        // const contents = await dispatch(
        //     fetchAllContentByClientId(object.procedure_id)
        // );
        const contentVersions = await dispatch(
            fetchAllContentVersionByProcedureId(object.procedure_id)
        );
        dispatch(deactivateLoading());
        if (contentVersions.payload?.length > 0) {
            setModalProps({
                ...modalProps,
                ['deleteProcedure']: true,
                ['deleteAssociatedContentVersions']: contentVersions.payload,
            });
            setOpenConfirmDeleteDialog(true);
        } else {
            console.log('No Content');
            confirmAction(e);
        }
    };

    return (
        <>
            {activeUser?.role === Role.IPSAdmin &&
            level === 'asset' &&
            object?.asset_type_id !== 3 &&
            action.label === Action.Duplicate.label ? (
                <DuplicateAssetForm
                    assetId={object?.asset_id}
                    handleClose={onClose}
                />
            ) : (
                <Modal onClose={() => false} open={isOpen}>
                    <div className={classes.modalContainer}>
                        <div className={classes.actionIcon}>
                            {getActionIcon(action.iconId)}
                        </div>
                        {modalMessage(action.label)}
                        {level === 'asset' &&
                            action.label === Action.Publish.label && (
                                <FormControl
                                    sx={{ mb: 3 }}
                                    component="fieldset"
                                    variant="standard"
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={modalProps.isDraft}
                                                onChange={handleCheckChange}
                                                name="isDraft"
                                            />
                                        }
                                        label="Draft"
                                    />
                                </FormControl>
                            )}
                        {/* {level === 'zone' &&
                            action.label === Action.Delete.label && (
                                <FormControl
                                    sx={{ mb: 3 }}
                                    component="fieldset"
                                    variant="standard"
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={modalProps.deleteZone}
                                                onChange={handleCheckChange}
                                                name="deleteZone"
                                            />
                                        }
                                        label="Delete Files?"
                                    />
                                </FormControl>
                            )} */}
                        {level === 'content' &&
                            action.label === Action.Delete.label && (
                                <FormControl
                                    sx={{ mb: 3 }}
                                    component="fieldset"
                                    variant="standard"
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    modalProps.deleteContent
                                                }
                                                onChange={handleCheckChange}
                                                name="deleteContent"
                                            />
                                        }
                                        label="Delete Files?"
                                    />
                                </FormControl>
                            )}

                        {level === 'content' &&
                            action.label === Action.TranscribeArray.label && (
                                <BatchTranscribeFields
                                    state={modalProps}
                                    setState={setModalProps}
                                    handleChange={handleSelectChange}
                                />
                            )}

                        <div>
                            <Button
                                onClick={onClose}
                                className={classes.buttonSpacing}
                                variant="outlined"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={(e) => {
                                    if (
                                        level === 'asset' &&
                                        action.label === 'delete' &&
                                        object?.asset_type_id === 3
                                    ) {
                                        fetchAssociatedContent(e);
                                    } else if (
                                        !modalProps.deleteZone &&
                                        !modalProps.deleteContent
                                    ) {
                                        window.removeEventListener(
                                            'keyup',
                                            handleEnterKey
                                        );
                                        confirmAction(e);
                                    } else {
                                        setOpenConfirmDeleteDialog(true);
                                    }
                                }}
                                disabled={object?.orbit_frame >= 0}
                                className={clsx(
                                    classes.buttonSpacing,
                                    classes.actionButton
                                )}
                                variant="contained"
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
            {openConfirmDeleteDialog && (
                <ConfirmDeleteDialog
                    dialogClasses={classes}
                    modalProps={modalProps}
                    openDialog={openConfirmDeleteDialog}
                    setOpenDialog={setOpenConfirmDeleteDialog}
                    handleSubmit={confirmAction}
                />
            )}
        </>
    );
}

export { ActionModal };
