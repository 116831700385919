import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Formik, Form, Field } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { useDispatch, useSelector } from 'react-redux';

import { accountsSlice } from 'app/store';
import { makeFormStyles } from '../styles';
import SetDirtyForm from 'forms/SetDirtyForm';
import { FormActions } from '_helpers/form-action';
import { SelectionAutocomplete } from 'components';
import { FormTextField } from 'components/TextField';
import { closePanel } from '_features/common/formSlice';
import { deleteKey } from 'GeminiViewerComponent/_helpers';
import { addNewItem } from '_features/items/itemsTableSlice';
import { selectSelectedZoneId } from '_features/zones/zonesTableSlice';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    fetchAllHotspotIcons,
    getHotspotStatus,
    getItemHotspots,
} from 'shared/hotspotsSlice';
import {
    selectAllAudiences,
    getAudienceStatus,
    fetchAudiencesPage,
} from '_features/audiences/audiencesSlice';
import {
    activateLoading,
    deactivateLoading,
    setProgress,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    clearEditItem,
    editItem,
    selectEditItemLoadingStatus,
    selectEditItemSavingStatus,
} from '_features/common/editItemSlice';
import {
    fetchTags,
    getTagStatus,
    selectTags,
    createTag,
} from '_features/tags/tagsSlice';

const ItemForm = ({ formAction, item }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    const defaultHotspotItemIcon = {
        hotspot_icon_id: 0,
        display_name: 'Default',
    };
    const [initValues, setInitValues] = useState({
        ...item,
        hotspot_icon_id: item?.hotspot_icon?.hotspot_icon_id
            ? item?.hotspot_icon?.hotspot_icon_id
            : defaultHotspotItemIcon?.hotspot_icon_id,
    });
    const [autoViewSingleLinkState, setAutoViewSingleLinkState] = useState(
        item.auto_view_single_link
    );
    const itemNameRef = useRef(null);
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        setInitValues({
            ...item,
            hotspot_icon_id: item?.hotspot_icon?.hotspot_icon_id
                ? item?.hotspot_icon?.hotspot_icon_id
                : defaultHotspotItemIcon?.hotspot_icon_id,
        });
    }, [formAction, item]);

    // Get zone ID in the event form is creating new item
    const zone_id = useSelector(selectSelectedZoneId);
    // const editingHotspot = useSelector((state) => getEditingHotspot(state));
    const editItemLoadingStatus = useSelector(selectEditItemLoadingStatus);
    const editItemSavingStatus = useSelector(selectEditItemSavingStatus);
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        var resultAction = null;
        dispatch(activateLoading({ showProgress: true }));
        dispatch(
            setProgress({
                progress: 0,
                label: 'Step 1 of 2: Uploading',
                noAnimation: true,
            })
        );
        switch (formAction.id) {
            case FormActions.Edit.id:
                resultAction = await dispatch(
                    editItem({ zone_id: zone_id, ...values })
                );
                if (!resultAction.error) {
                    dispatch(closePanel({ formKey: 'itemForm' }));
                }
                break;
            case FormActions.Create.id:
                resultAction = await dispatch(
                    addNewItem({ zone_id: zone_id, ...values })
                );

                if (!resultAction.error) {
                    // if (editingHotspot) {
                    //     dispatch(saveEditingHotspot(resultAction.payload));
                    // }
                    dispatch(clearEditItem());
                    resetForm();
                    if (itemNameRef.current) {
                        itemNameRef.current.focus();
                    }
                }
                break;
            default:
                break;
        }
        dispatch(deactivateLoading());
        const { message, variant } = snackbarHandler(
            resultAction.meta.requestStatus,
            formAction.label
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });
        setSubmitting(false);
        deleteKey(`tags_${user.user_id}`);
        dispatch(fetchTags({ sort: 'name' }));
    };

    const ItemFormProps = [
        {
            inputRef: itemNameRef,
            autoFocus: true,
            label: 'Item Name',
            name: 'display_name',
            placeholder: 'Item Name',
            disabled: readOnlyMode,
        },
        {
            label: 'Description',
            name: 'description',
            placeholder: 'Description',
            disabled: readOnlyMode,
        },
        {
            label: 'External ID',
            name: 'external_id',
            placeholder: 'External ID',
            disabled: readOnlyMode,
        },
        {
            component: CheckboxWithLabel,
            Label: { label: 'Auto view single link' },
            type: 'checkbox',
            name: 'auto_view_single_link',
            placeholder: 'Auto view single link',
            disabled: readOnlyMode,
        },
        {
            label: 'Pano ID',
            name: 'pano_id',
            placeholder: 'Pano ID',
            disabled: readOnlyMode,
        },
    ];

    return editItemLoadingStatus === LoadingStatus.Loading ||
        editItemSavingStatus === SavingStatus.Saving ? (
        <LoadingLogo styleClassName={classes.loadingSvg} />
    ) : (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            validationSchema={Yup.object({
                display_name: Yup.string()
                    .max(150, 'Must be 150 characters or less')
                    .required('Required'),
                description: Yup.string().max(
                    255,
                    'Must be 255 characters or less'
                ),
                external_id: Yup.string().max(
                    150,
                    'Must be 150 characters or less'
                ),
                pano_id: Yup.string().max(
                    150,
                    'Must be 150 characters or less'
                ),
                audience_ids: Yup.array(),
                hotspot_icon_id: Yup.string(),
                tag_ids: Yup.array(),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                delete values?.hotspot_icon;
                handleSubmit(
                    {
                        ...values,
                        hotspot_icon_id:
                            typeof values?.hotspot_icon_id === 'number'
                                ? values.hotspot_icon_id
                                : defaultHotspotItemIcon.hotspot_icon_id,
                    },
                    setSubmitting,
                    resetForm
                );
            }}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form className={classes.form}>
                    <div className={classes.formHeader}>Item Details</div>
                    {ItemFormProps.map((field, index) => {
                        if (field.name === 'auto_view_single_link') {
                            return (
                                <FormTextField
                                    key={index}
                                    {...field}
                                    indeterminate={
                                        values?.auto_view_single_link ===
                                        undefined
                                            ? true
                                            : false
                                    }
                                    onChange={(e) => {
                                        // Handle tri state
                                        var newState = undefined;
                                        if (
                                            autoViewSingleLinkState ===
                                            undefined
                                        ) {
                                            newState = false;
                                        } else if (
                                            autoViewSingleLinkState === false
                                        ) {
                                            newState = true;
                                        }

                                        setAutoViewSingleLinkState(newState);

                                        setFieldValue(
                                            'auto_view_single_link',
                                            newState
                                        );
                                    }}
                                />
                            );
                        } else {
                            return <FormTextField key={index} {...field} />;
                        }
                    })}
                    <Field className={classes.input} name="audience_ids">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <SelectionAutocomplete
                                title="Audiences"
                                placeholder="none"
                                keyProperty="audience_id"
                                nameProperty="display_name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={selectAllAudiences}
                                entityStatusSelector={getAudienceStatus}
                                fetchEntityPage={fetchAudiencesPage}
                                formField={'audience_ids'}
                                fetchParams={{ page: 1, pageSize: 100 }}
                                readOnly={readOnlyMode}
                            />
                        )}
                    </Field>
                    <Field className={classes.input} name="hotspot_icon_id">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <SelectionAutocomplete
                                title="Item Hotspot"
                                placeholder="Item Hotspot Icon"
                                keyProperty="hotspot_icon_id"
                                nameProperty="display_name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={getItemHotspots}
                                entityStatusSelector={getHotspotStatus}
                                fetchEntityPage={fetchAllHotspotIcons}
                                formField={'hotspot_icon_id'}
                                multiSelection={false}
                                fetchParams={{ page: 1, pageSize: 100 }}
                                extraOptions={[defaultHotspotItemIcon]}
                                readOnly={readOnlyMode}
                            />
                        )}
                    </Field>
                    <Field className={classes.input} name="tag_ids">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <SelectionAutocomplete
                                title="Item Tags"
                                placeholder="Item Tags"
                                keyProperty="tag_id"
                                nameProperty="name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                entitySelector={selectTags}
                                entityStatusSelector={getTagStatus}
                                fetchEntityPage={fetchTags}
                                formField={'tag_ids'}
                                enableCreatable
                                creatableDialogTitle={'tag'}
                                creatableDialogFieldLabel={'Tag'}
                                creatableDispatch={createTag}
                                enableCache
                                refreshCacheType={`tags_${user?.user_id}`}
                                readOnly={readOnlyMode}
                                fieldValidationLength={150}
                            />
                        )}
                    </Field>
                    {readOnlyMode !== true && (
                        <>
                            <SetDirtyForm />
                            <button
                                className={classes.submit}
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {formAction.buttonLabel}
                            </button>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export { ItemForm };
