import {
    Grid,
    MenuItem,
    Select,
    Button,
    InputLabel,
    FormControl,
    Autocomplete,
    Checkbox,
    TextField,
} from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import {
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdDragHandle,
} from 'react-icons/md';
import { useSelector } from 'react-redux';

import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { makeFormStyles } from 'forms/styles';
import IncorrectFieldSet from '../../IncorrectFieldSet';
import { MultiSelectDoneButton } from 'GeminiViewerComponent/components/MultiSelectDoneButton';
import { FormTextField } from 'components/TextField';

const ImagePairsTargetNode = ({
    option,
    selectOptions,
    idx,
    nodes,
    node,
    values,
    setFieldValue,
    handleRemove,
    ...otherProps
}) => {
    let currentCombinations =
        option.options_required?.length > 0
            ? option.options_required.map((opt) => {
                  return selectOptions.find(
                      (sel) => sel.option_text === opt.option_text
                  );
              })
            : [];
    currentCombinations = currentCombinations.filter(
        (opt) => opt !== undefined
    );
    const theme = useSelector(selectActiveTheme);
    nodes = nodes.filter((nd) => nd.id !== node.id);
    const commonFormStyles = makeFormStyles(theme);

    return (
        <Draggable
            key={'image_pairs_target_draggable_' + idx}
            draggableId={String('image_pairs_target_draggable_' + idx)}
            index={idx}
        >
            {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                    <Grid
                        container
                        key={'image_pairs_target_' + idx}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            marginBottom: '6px',
                        }}
                        {...otherProps}
                    >
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            justifyContent={'center'}
                            item
                            xs={12}
                            sm={12}
                            md={1}
                            lg={1}
                            {...provided.dragHandleProps}
                        >
                            <MdDragHandle className="react-icon" />
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                        >
                            <FormControl fullWidth>
                                <Autocomplete
                                    key={`Image-Pairs-Target-${idx}`}
                                    limitTags={3}
                                    multiple
                                    variant="outline"
                                    id={`Image-pair-options-${idx}`}
                                    disableCloseOnSelect
                                    options={selectOptions}
                                    value={currentCombinations}
                                    onChange={(event, newInputValue) => {
                                        let currImagePairs = [
                                            ...values.image_target_combinations,
                                        ];
                                        currImagePairs[idx] = {
                                            ...values.image_target_combinations[
                                                idx
                                            ],
                                            options_required: newInputValue,
                                        };
                                        setFieldValue(
                                            'image_target_combinations',
                                            currImagePairs
                                        );
                                    }}
                                    style={{ width: '100%' }}
                                    getOptionLabel={(option) => (
                                        <img
                                            src={option.img_url}
                                            alt={option.option_text}
                                            style={{
                                                paddingTop: '5px',
                                                width: '50px',
                                                height: '30px',
                                            }}
                                        />
                                    )}
                                    renderOption={(
                                        props,
                                        option,
                                        { selected }
                                    ) => {
                                        return (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={
                                                        <MdCheckBoxOutlineBlank className="react-icon" />
                                                    }
                                                    checkedIcon={
                                                        <MdCheckBox className="react-icon" />
                                                    }
                                                    style={{
                                                        marginRight: 8,
                                                    }}
                                                    checked={selected}
                                                />
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <img
                                                        src={option.img_url}
                                                        alt={option.option_text}
                                                        style={{
                                                            width: '100px',
                                                            height: '50px',
                                                        }}
                                                    />
                                                </div>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Option Pair"
                                            placeholder="Option Pair"
                                        />
                                    )}
                                    PaperComponent={MultiSelectDoneButton}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={2}
                            lg={2}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="target-node-id">
                                    Target Node
                                </InputLabel>
                                <Select
                                    className={commonFormStyles.materialSelect}
                                    style={{
                                        margin: '0',
                                    }}
                                    labelId="target-node-id"
                                    margin="dense"
                                    id="target_node_id"
                                    label="Target Node"
                                    placeholder="Target Node"
                                    name={`image_target_combinations[${idx}].target_node_id`}
                                    value={option?.action?.id}
                                    onChange={(e) => {
                                        let currImagePairs = [
                                            ...values.image_target_combinations,
                                        ];
                                        currImagePairs[idx] = {
                                            ...values.image_target_combinations[
                                                idx
                                            ],
                                            action: {
                                                ...values
                                                    .image_target_combinations[
                                                    idx
                                                ].action,
                                                id: e.target.value,
                                            },
                                        };
                                        setFieldValue(
                                            'image_target_combinations',
                                            currImagePairs
                                        );
                                    }}
                                >
                                    <MenuItem key={'next'} value={'next'}>
                                        Next
                                    </MenuItem>
                                    <MenuItem key={'back'} value={'back'}>
                                        Back
                                    </MenuItem>
                                    {nodes?.length > 0 &&
                                        nodes.map((nd) => (
                                            <MenuItem
                                                key={nd?.id}
                                                value={nd?.id}
                                            >
                                                {nd?.title} - #{nd?.id}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={2}
                            lg={2}
                        >
                            <FormControl fullWidth>
                                <FormTextField
                                    label="Answer Value"
                                    placeholder="Answer Value"
                                    name={`image_target_combinations[${idx}].answer_value`}
                                    onChange={(e) => {
                                        let currOptions = [
                                            ...values.image_target_combinations,
                                        ];
                                        currOptions[idx] = {
                                            ...values.image_target_combinations[
                                                idx
                                            ],
                                            answer_value: e.target.value,
                                        };
                                        setFieldValue(
                                            `image_target_combinations`,
                                            currOptions
                                        );
                                    }}
                                    {...otherProps}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={1}
                            lg={1}
                        >
                            <FormControl fullWidth>
                                <IncorrectFieldSet>
                                    <Checkbox
                                        color="primary"
                                        checked={Boolean(option?.incorrect)}
                                        onChange={(e) => {
                                            let currImagePairs = [
                                                ...values.image_target_combinations,
                                            ];
                                            currImagePairs[idx] = {
                                                ...values
                                                    .image_target_combinations[
                                                    idx
                                                ],
                                                incorrect: e.target.checked,
                                            };
                                            setFieldValue(
                                                'image_target_combinations',
                                                currImagePairs
                                            );
                                        }}
                                    />
                                </IncorrectFieldSet>
                            </FormControl>
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={1}
                            lg={1}
                        >
                            <FormControl fullWidth>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        handleRemove(idx);
                                    }}
                                >
                                    Remove
                                </Button>{' '}
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            )}
        </Draggable>
    );
};

export default ImagePairsTargetNode;
