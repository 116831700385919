import { GeminiSharedLoadingIcon } from 'GeminiViewerComponent/components/Common/GeminiSharedLoadingIcon';
import { selectLoadingSvg } from 'GeminiViewerComponent/_features/globals/themeSlice';

function LoadingLogo({ styleClassName, rotateClassName }) {
    return (
        <GeminiSharedLoadingIcon
            styleClassName={styleClassName}
            svgSelector={selectLoadingSvg}
        />
    );
}

export { LoadingLogo };
