export const playerURL = (player_url) => {
    let playerUrlStr = '';
    if (player_url) {
        playerUrlStr = player_url.endsWith('/') ? player_url : `${player_url}/`;
    } else if (typeof window !== 'undefined') {
        playerUrlStr = window.location.host.includes('localhost')
            ? 'http://localhost:3001/'
            : `${window.location.host}/tour/`;
    } else {
        playerUrlStr = window.location.host.includes('localhost')
            ? 'http://localhost:3001/'
            : 'tour/';
    }

    if (!playerUrlStr.startsWith('http')) {
        playerUrlStr = 'http://' + playerUrlStr;
    }

    return playerUrlStr;
};
