import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accountsSlice } from 'app/store';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, IconButton, Tooltip } from '@mui/material';

import { useStyles } from '../styles';
import {
    resizableLabelHeader,
    resizableLabelCell,
    resizableActionCell,
    resizableCheckCell,
    resizableCheckHeaderCell,
    resizablePinCell,
    resizableTagCell,
    resizableShortcutCell,
    resizableLabelHeaderWithSearch,
} from 'components';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { Action } from '_helpers';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import { EditMultipleItemsDialog } from 'components/EditMultipleItemsDialog';
import { clearEditItem, loadItem } from '_features/common/editItemSlice';
import {
    fetchItemsPage,
    fetchMoreItems,
    selectAllTableItems,
    selectItemLoadingStatus,
    selectItemPageInfo,
    selectLoadedItemsMap,
} from '_features/items/itemsTableSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { selectSelectedZone } from '_features/zones/zonesTableSlice';
import { setViewContentPanel } from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import getActionIcon from 'GeminiViewerComponent/_helpers/getActionIcon';
import { ItemTagEdit } from './itemTagEdit';
import { ShortcutIcons } from './shortcutIcons';
import { selectActiveAsset } from 'GeminiViewerComponent/_features/asset/assetSlice';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { ResizableTable } from 'GeminiViewerComponent/components/_Tables/ResizableTable';
import { MdAdd } from 'react-icons/md';
import { closeAllPanel, openPanel } from '_features/common/formSlice';

const { selectActiveUser } = accountsSlice;
function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function ZoneItems() {
    const isComponentLoaded = useRef(true);
    const dispatch = useDispatch();
    const zone = useSelector(selectSelectedZone);
    const zoneItems = useSelector(selectAllTableItems);
    const itemStatus = useSelector(selectItemLoadingStatus);
    const pageInfo = useSelector(selectItemPageInfo);
    const allItems = useSelector(selectAllTableItems);
    const loadedItemsMap = useSelector(selectLoadedItemsMap);
    const displayAsset = useSelector(selectActiveAsset);
    const INIT_ITEMS_PAYLOAD = {
        searchString: '',
        zoneId: zone?.zoneId,
        page: 1,
        pageSize: 10,
    };

    // const viewContentPanel = useSelector(selectViewContentPanel);
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);
    const tableClasses = tableStyles();
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);
    const [itemsPayload, setItemsPayload] = useState(INIT_ITEMS_PAYLOAD);
    const [editDialog, setEditDialog] = useState(false);
    const [toggleNameSearch, setToggleNameSearch] = useState(false);
    const activeUser = useSelector(selectActiveUser);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    const getSelectedZoneItems = zoneItems.filter(
        (item) => selected.includes(item?.item_id) && item
    );

    const prevSelectedAll = usePrevious(selectedAll);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setItemsPayload({
                ...itemsPayload,
                searchString: searchString,
            });
        }, 500),
        []
    );

    const onItemSearch = (e) => {
        debouncedSearch(e.target.value);
    };

    useEffect(() => {
        if (itemStatus === LoadingStatus.Idle) {
            // setLoadingZoneItems(true);
            dispatch(fetchItemsPage({ ...itemsPayload, zoneId: zone.zone_id }));
            // debug temp = testing if we need to load zone at this point or wait
            // until user goes into edit mode
            // dispatch(
            //     loadZone({
            //         zoneId: zone.zone_id,
            //         refresh: false,
            //         setActive: false,
            //     })
            // );
        }
        // (itemStatus === LoadingStatus.Loaded ||
        //     itemStatus === LoadingStatus.Failed) &&
        //     setLoadingZoneItems(false);
    }, [itemStatus, dispatch, zone.zone_id, itemsPayload]);

    // it's called only when assetPayload changes not on component load.
    useEffect(() => {
        if (isComponentLoaded.current) {
            isComponentLoaded.current = false;
            return;
        }
        dispatch(fetchItemsPage({ ...itemsPayload, zoneId: zone.zone_id }));
    }, [dispatch, itemsPayload]); // eslint-disable-line react-hooks/exhaustive-deps

    if (zoneItems === undefined) {
        return null;
    }

    const isSelected = (itemId) => selected.indexOf(itemId) !== -1;

    if (prevSelectedAll && selectedAll) {
        if (selected.length !== allItems.length) {
            setSelected(allItems.map((row) => row.item_id));
            setSelectedAll(true);
        }
    }

    const headerHeight = 70;
    const headerHeightWithoutSearch = 40;
    const rowHeight = 60;

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedIds = allItems.map((item) => item.item_id);
            setSelected(newSelectedIds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    const handleClick = (event, itemId) => {
        const selectedIndex = selected.indexOf(itemId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, itemId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        setSelectedAll(newSelected.length === allItems.length);
    };

    const displayContentPanel = (rowData) => {
        if (activeUser.role !== 'User') {
            dispatch(setViewContentPanel(true));
            dispatch(
                loadItem({ zoneId: rowData.zone_id, itemId: rowData.item_id })
            );
        }
    };

    let columns = [
        {
            accessorKey: 'item_id',
            size: 40,
            minSize: 40,
            enableResizing: false,
            label: '',
            targetPopup: 'item',
            padding: 'none',
            actionCellLevel: 'item',
            hasHeaderSearchBar: true,
            readOnly: readOnlyMode,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableActionCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'is_pinned',
            size: 50,
            minSize: 50,
            label: 'Pin',
            padding: 'checkbox',
            cellLevel: 'item',
            hasHeaderSearchBar: true,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizablePinCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'display_name',
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'Name',
            padding: 'normal',
            onClick: displayContentPanel,
            onSearchChange: onItemSearch,
            allowHeaderSearchBar: true,
            hasHeaderSearchBar: true,
            isOpenSearch: toggleNameSearch,
            searchString: itemsPayload?.searchString,
            toggleOpenSearch: () => {
                if (toggleNameSearch !== true || !itemsPayload?.searchString) {
                    setToggleNameSearch(!toggleNameSearch);
                }
            },
            header: (info) =>
                resizableLabelHeaderWithSearch(
                    null,
                    info?.header?.column?.columnDef
                ),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'tags',
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'Tags',
            padding: 'none',
            childComponents: [ItemTagEdit],
            hasHeaderSearchBar: true,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableTagCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'tags',
            flexGrow: 1,
            size: 140,
            minSize: 140,
            label: '',
            padding: 'none',
            displayAsset: displayAsset,
            childComponents: [ShortcutIcons],
            hasHeaderSearchBar: true,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableShortcutCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
    ];
    if (readOnlyMode !== true) {
        columns = [
            {
                accessorKey: 'item_id',
                size: 50,
                minSize: 50,
                label: 'Select',
                padding: 'checkbox',
                cellLevel: 'item',
                numSelected: selected.length,
                rowCount: allItems.length,
                handleClick: handleClick,
                handleSelectAllClick: handleSelectAllClick,
                isSelected: isSelected,
                hasHeaderSearchBar: true,
                header: (info) =>
                    resizableCheckHeaderCell(
                        null,
                        info?.header?.column?.columnDef
                    ),
                cell: (info) =>
                    resizableCheckCell(
                        { rowData: info?.row?.original },
                        info?.column?.columnDef
                    ),
            },
            ...columns,
        ];
    }
    async function loadMoreRows({ startIndex, stopIndex }) {
        await dispatch(
            fetchMoreItems({
                zoneId: zone.zone_id,
                startIndex: startIndex,
                stopIndex: stopIndex,
            })
        );
    }

    const handleItemsDialogClose = () => {
        setEditDialog(!editDialog);
        setSelected([]);
        setSelectedAll(false);
    };

    const handleCreateZoneItem = () => {
        dispatch(closeAllPanel());
        dispatch(clearEditItem());
        dispatch(
            openPanel({
                formKey: 'itemForm',
                formAction: 'Create',
                clearEditId: true,
            })
        );
    };

    return (
        <div className={classes.zoneItems}>
            <Box
                display="flex"
                width="100%"
                padding="10px 10px 0"
                alignItems="center"
            >
                <Tooltip title="New Zone Item">
                    <IconButton
                        className={classes.addNewIcon}
                        onClick={handleCreateZoneItem}
                        size="large"
                    >
                        <MdAdd className="react-icon" />
                    </IconButton>
                </Tooltip>
            </Box>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    totalCount={pageInfo.TotalCount}
                >
                    <Tooltip title="Edit">
                        <div
                            className={classes.popupActionContainer}
                            onClick={() => setEditDialog(!editDialog)}
                        >
                            <div className={classes.popupActionIcon}>
                                {getActionIcon(Action.Edit.iconId)}
                            </div>
                        </div>
                    </Tooltip>
                    <PopupAction
                        action={Action.DeleteArray}
                        object={{
                            zoneId: zone.zone_id,
                            itemIds: selected,
                            setItemIds: setSelected,
                        }}
                        level={'item'}
                        showLabel={false}
                        toolTipTitle="Delete"
                    />
                </EnhancedTableToolbar>
            )}
            <div
                className={clsx(classes.paper, classes.itemsAutoSizerWrapper)}
                style={{
                    height: `calc(100vh - ${
                        selected.length > 0 ? '395px' : '330px'
                    })`,
                }}
            >
                <ResizableTable
                    initialRows={zoneItems}
                    allRows={allItems}
                    totalRowCount={pageInfo.TotalCount}
                    loadMoreRows={loadMoreRows}
                    loadedRowsMap={loadedItemsMap}
                    columns={columns}
                    cellClassName={tableClasses.flexContainer}
                    className={tableClasses.table}
                    // selected={selected}
                    rowClassName={tableClasses.searchBarFlexContainer}
                    headerHeight={
                        toggleNameSearch
                            ? headerHeight
                            : headerHeightWithoutSearch
                    }
                    rowHeight={rowHeight}
                    loadingStatus={
                        itemStatus === LoadingStatus.Loading
                            ? LoadingStatus.Loading
                            : 'No items'
                    }
                    headerStyle={{ backgroundColor: '#eeeeee' }}
                />
            </div>
            <EditMultipleItemsDialog
                open={editDialog}
                handleClose={handleItemsDialogClose}
                zoneItems={getSelectedZoneItems}
            />
        </div>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export { ZoneItems };
