/**
 * This slice is to manage config fields
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { configFieldService } from '_features/_services/configField.service';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    error: null,
    configFields: [],
    loadingStatus: LoadingStatus.Idle,
    savingStatus: SavingStatus.Idle,
};

export const createNewFormField = (category) => {
    return {
        config_field_id: uuidv4(),
        display_name: '',
        key: '',
        is_feature: false,
        category: category ?? '',
    };
};

export const getConfigFields = createAsyncThunk(
    'configFields/getConfigFields',
    async (params) => {
        // await new Promise((resolve) => setTimeout(resolve, 500)); // debug temp
        return await configFieldService.getAll(params);
    }
);

export const updateConfigFields = createAsyncThunk(
    'configFields/updateConfigFields',
    async (params) => {
        // await new Promise((resolve) => setTimeout(resolve, 500)); // debug temp
        return await configFieldService.updateAll(params.values, params.params);
    }
);

const configFieldsSlice = createSlice({
    name: 'configFields',
    initialState,
    reducers: {},
    extraReducers: {
        [getConfigFields.pending]: (state) => {
            state.loadingStatus = LoadingStatus.Loading;
            state.error = null;
        },
        [getConfigFields.fulfilled]: (state, action) => {
            state.loadingStatus = LoadingStatus.Loaded;
            state.configFields = action.payload;
        },
        [getConfigFields.rejected]: (state, action) => {
            state.loadingStatus = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [updateConfigFields.pending]: (state) => {
            state.savingStatus = SavingStatus.Saving;
            state.error = null;
        },
        [updateConfigFields.fulfilled]: (state, action) => {
            state.error = null;
            state.savingStatus = SavingStatus.Saved;
            state.configFields = action.payload.config_fields;
            const failedCount = Object.keys(
                action.payload.status.failed_to_delete ?? {}
            ).length;
            if (failedCount > 0) {
                state.error = `Failed to delete ${failedCount} field(s) likely due to usage by config settings or features.`;
            }
        },
        [updateConfigFields.rejected]: (state, action) => {
            state.savingStatus = SavingStatus.Failed;
            state.error = action.error.message;
        },
    },
});

export const selectConfigFieldsErrorMessage = (state) =>
    state.configFields.error;
export const selectConfigFieldsSavingStatus = (state) =>
    state.configFields.savingStatus;
export const selectConfigFieldsLoadingStatus = (state) =>
    state.configFields.loadingStatus;
export const selectConfigFields = (state) => {
    return state.configFields.configFields;
};
// export const { } = configFieldsSlice.actions;

export default configFieldsSlice.reducer;
