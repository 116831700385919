import { createSlice } from '@reduxjs/toolkit';

import { remove } from 'GeminiViewerComponent/_helpers/lodashUtils';

const scrubberPositionMaxDefault = 0.0;
const DEFAULT_MINIMUM_DISTANCE = 10;
const MINIMUM_DISTANCE_PERCENTAGE = 5;

export const initialState = {
    status: '',
    trimScrubberPositionMax: scrubberPositionMaxDefault,
    trimPositions: [0.0, scrubberPositionMaxDefault],
    playbackPosition: 0.0,
    markerPlaybackPosition: 0.0,
    canvasWidth: 0.0,
    userDragging: false,
    lastChangedIndex: 0,
    currentZoneIndex: -1,
    zonePositions: [],
    minimumDistance: DEFAULT_MINIMUM_DISTANCE,
};

const videoImportSlice = createSlice({
    name: 'videoImport',
    initialState,
    reducers: {
        resetVideoEditor: () => initialState,
        setMarkerPlaybackPosition(state, action) {
            state.markerPlaybackPosition = action.payload;
        },
        setScrubberCanvasWidth(state, action) {
            const width = action.payload;
            state.canvasWidth =
                width > window.innerWidth ? window.innerWidth : width;
        },
        setStartTrimScrubberPosition(state, action) {
            state.trimPositions[0] = action.payload;
        },
        setEndTrimScrubberPosition(state, action) {
            state.trimPositions[1] = action.payload;
        },
        setTrimScrubberMax(state, action) {
            state.minimumDistance = Number(
                ((MINIMUM_DISTANCE_PERCENTAGE / 100) * action.payload).toFixed(
                    2
                )
            );
            state.trimScrubberPositionMax = action.payload;
        },
        setUserDragging(state, action) {
            state.userDragging = action.payload.userDragging;
            //state.tempScrubberFrames = action.payload.scrubberFrames;
            if (state.userDragging === false) {
                state.startTrimScrubberPosition =
                    action.payload.scrubberPositions[0];
                state.endTrimScrubberPosition =
                    action.payload.scrubberPositions[1];
            }
        },
        setTrimPositions(state, action) {
            state.trimPositions = action.payload;
        },
        setLastChangedIndex(state, action) {
            state.lastChangedIndex = action.payload;
        },
        addZonePosition(state, action) {
            let newZonePosition = {
                name: action.payload,
                time: state.markerPlaybackPosition,
            };
            var zoneIndex = state.zonePositions.findIndex(
                (x) => x.time === newZonePosition.time
            );
            if (zoneIndex < 0) {
                if (state.zonePositions.length > 0) {
                    const filteredArr = state.zonePositions.filter(
                        (item) =>
                            Math.abs(state.markerPlaybackPosition - item.time) <
                            state.minimumDistance
                    );
                    if (filteredArr.length > 1) {
                        alert('Not able to add zone in this position');
                        return;
                    }
                    if (filteredArr.length === 1) {
                        const newCurrentZonePosition =
                            state.minimumDistance -
                            Math.abs(
                                state.markerPlaybackPosition -
                                    filteredArr[0]?.time
                            );
                        if (
                            filteredArr[0]?.time > state.markerPlaybackPosition
                        ) {
                            // adding zone on left of the current zone
                            const distanceDiff =
                                state.markerPlaybackPosition -
                                newCurrentZonePosition;
                            if (distanceDiff < 0) {
                                alert('Not able to add zone in this position');
                                return;
                            }
                            newZonePosition.time = distanceDiff;
                        } else {
                            // adding zone on right of the current zone
                            newZonePosition.time =
                                newCurrentZonePosition +
                                state.markerPlaybackPosition;
                        }
                        state.playbackPosition = newZonePosition.time;
                        state.markerPlaybackPosition = newZonePosition.time;
                        state.playbackPosition = newZonePosition.time;
                        if (Number(state.lastChangedIndex) === 0) {
                            state.trimPositions[0] = newZonePosition.time;
                        } else {
                            state.trimPositions[1] = newZonePosition.time;
                        }
                    }
                }
                state.zonePositions.push(newZonePosition);
                // Resort in order by time
                state.zonePositions.sort((a, b) => a?.time - b?.time);
                zoneIndex = state.zonePositions.findIndex(
                    (x) => x.time === newZonePosition.time
                );
            }
            state.currentZoneIndex = zoneIndex;
        },
        renameCurrentZone(state, action) {
            if (state.currentZoneIndex > -1) {
                state.zonePositions[state.currentZoneIndex].name =
                    action.payload;
            }
        },
        deleteCurrentZone(state) {
            if (state.currentZoneIndex > -1) {
                state.zonePositions.splice(state.currentZoneIndex, 1);
            }
            if (state.zonePositions.length === 0) {
                state.currentZoneIndex = -1;
            }
        },
        deleteZonePosition(state, action) {
            remove(state.zonePositions, { time: action.payload });
        },
        updateZonePosition(state, action) {
            let finalTime = action.payload.time;
            const removedCurrentZone = state.zonePositions.filter(
                (item, i) => i !== Number(action.payload.index)
            );

            const filterZoneWithMinDistance =
                removedCurrentZone.length > 0 &&
                removedCurrentZone.filter((item) => {
                    return (
                        Math.abs(action.payload.time - item.time) <
                        state.minimumDistance
                    );
                });
            if (filterZoneWithMinDistance.length > 0) {
                let nearestZoneTime = filterZoneWithMinDistance[0]?.time;
                if (action.payload.time < nearestZoneTime) {
                    finalTime = Math.min(
                        finalTime,
                        nearestZoneTime - state.minimumDistance
                    );
                } else {
                    finalTime = Math.max(
                        finalTime,
                        nearestZoneTime + state.minimumDistance
                    );
                }
            }
            state.markerPlaybackPosition = finalTime;
            state.playbackPosition = finalTime;
            state.zonePositions[action.payload.index].time = finalTime;
            state.currentZoneIndex = action.payload.index;
        },
        setZonePositions(state, action) {
            state.zonePositions = action.payload;
        },
        setPlaybackPosition(state, action) {
            state.playbackPosition = action.payload;
        },
    },
});

export const selectCurrentZone = (state) => {
    if (
        state.videoImport.currentZoneIndex < 0 ||
        state.videoImport.currentZoneIndex >
            state.videoImport.zonePositions.length - 1
    ) {
        return null;
    }
    return state.videoImport.zonePositions[state.videoImport.currentZoneIndex];
};

export const {
    resetVideoEditor,
    setScrubberCanvasWidth,
    setTrimScrubberMax,
    setUserDragging,
    setTrimPositions,
    setLastChangedIndex,
    setPlaybackPosition,
    addZonePosition,
    renameCurrentZone,
    deleteCurrentZone,
    updateZonePosition,
    deleteZonePosition,
    setZonePositions,
    setMarkerPlaybackPosition,
} = videoImportSlice.actions;

export default videoImportSlice.reducer;
