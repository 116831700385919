import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IconButton } from '@mui/material';
import { MdAdd, MdEdit } from 'react-icons/md';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import HotspotAnswerField from './HotspotAnswerField';
import HotspotPairsTargetNode from './HotspotPairsTargetNode';
import HotspotPanoDataForm from './HotspotPanoDataForm';

const HotspotAnswerGroupType = ({
    node,
    nodes,
    values,
    setFieldValue,
    nodeFormStyles,
    onDragEnd,
    handleRemove,
    handleRemoveOptionsSelected,
}) => {
    const [openHotspotOptionForm, setOpenHotspotOptionForm] = useState(false);

    const handleAddHotspotOption = () => {
        if (values?.asset_id && values?.zone_id) {
            if (!Array.isArray(values.hotspot_options)) {
                values.hotspot_options = [];
            }
            const hotspotOpts = [...values.hotspot_options];
            hotspotOpts.push({
                action: {
                    id: '',
                    type: 'node',
                },
                hotspot_id: Number(hotspotOpts?.length),
                asset_id: values.asset_id,
                zone_id: values.zone_id,
                item_id: null,
                option_text: uuidv4(),
                answer_value: '',
            });
            setFieldValue('hotspot_options', hotspotOpts);
        }
    };

    const handleAddHotspotPairTarget = (
        hotspot_target_combinations,
        setFieldValue
    ) => {
        if (!Array.isArray(hotspot_target_combinations)) {
            hotspot_target_combinations = [];
        }
        const hotspotPairs = [...hotspot_target_combinations];
        hotspotPairs.push({
            action: {
                id: '',
                type: 'node',
            },
            hotspot_pair_id: Number(hotspotPairs?.length),
            options_required: [],
            answer_value: '',
        });
        setFieldValue('hotspot_target_combinations', hotspotPairs);
    };

    return (
        <>
            <div
                style={{
                    marginTop: '5px',
                }}
            >
                {values?.asset_name && values?.zone_name
                    ? `${values?.asset_name} - ${values?.zone_name}`
                    : 'Asset & Zone'}{' '}
                <IconButton
                    className={nodeFormStyles.addButtonIcon}
                    onClick={() => setOpenHotspotOptionForm(true)}
                    size="small"
                >
                    <MdEdit className="react-icon" />
                </IconButton>
            </div>

            <div style={{ marginTop: '5px' }}>
                Hotspots Options{' '}
                {values?.asset_id && values?.zone_id && (
                    <IconButton
                        className={nodeFormStyles.addButtonIcon}
                        onClick={() => handleAddHotspotOption()}
                        size="small"
                    >
                        <MdAdd className="react-icon" />
                    </IconButton>
                )}
            </div>

            {values?.asset_id && values?.zone_id && (
                <>
                    <DragDropContext
                        onDragEnd={(result) =>
                            onDragEnd(
                                result,
                                values?.hotspot_options,
                                'hotspot_options',
                                setFieldValue
                            )
                        }
                    >
                        <Droppable droppableId="droppable-content-nodes-hotspots">
                            {(provided) => {
                                return (
                                    <>
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            style={{ margin: '40px 0' }}
                                        >
                                            {values?.hotspot_options?.map(
                                                (option, idx) => (
                                                    <HotspotAnswerField
                                                        option={option}
                                                        key={`HotspotAnswerField${idx}`}
                                                        idx={idx}
                                                        node={node}
                                                        nodes={nodes}
                                                        values={values}
                                                        setFieldValue={
                                                            setFieldValue
                                                        }
                                                        provided={provided}
                                                        handleRemove={(id) => {
                                                            let opt_txt =
                                                                values
                                                                    .hotspot_options[
                                                                    id
                                                                ].option_text;
                                                            handleRemove(
                                                                id,
                                                                values.hotspot_options,
                                                                'hotspot_options',
                                                                setFieldValue
                                                            );
                                                            handleRemoveOptionsSelected(
                                                                opt_txt,
                                                                values.hotspot_target_combinations,
                                                                'hotspot_target_combinations',
                                                                setFieldValue
                                                            );
                                                        }}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </>
                                );
                            }}
                        </Droppable>
                    </DragDropContext>
                    {values?.multiple_answers_allow === true &&
                        Array.isArray(values?.hotspot_options) &&
                        values?.hotspot_options?.filter((opt) => opt?.item_id)
                            ?.length > 1 && (
                            <>
                                <div>
                                    Target Combinations{' '}
                                    <IconButton
                                        className={nodeFormStyles.addButtonIcon}
                                        onClick={() =>
                                            handleAddHotspotPairTarget(
                                                values?.hotspot_target_combinations,
                                                setFieldValue
                                            )
                                        }
                                        size="small"
                                    >
                                        <MdAdd className="react-icon" />
                                    </IconButton>
                                </div>
                                <DragDropContext
                                    onDragEnd={(result) =>
                                        onDragEnd(
                                            result,
                                            values?.hotspot_target_combinations,
                                            'hotspot_target_combinations',
                                            setFieldValue
                                        )
                                    }
                                >
                                    <Droppable droppableId="droppable-content-nodes-hotspots-combinations">
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                style={{
                                                    margin: '40px 0',
                                                }}
                                            >
                                                {values?.hotspot_target_combinations?.map(
                                                    (option, idx) => {
                                                        return (
                                                            <HotspotPairsTargetNode
                                                                option={option}
                                                                selectOptions={values?.hotspot_options?.filter(
                                                                    (opt) =>
                                                                        opt?.item_id
                                                                )}
                                                                key={`HotspotPairsTargetNode${idx}`}
                                                                idx={idx}
                                                                node={node}
                                                                nodes={nodes}
                                                                values={values}
                                                                setFieldValue={
                                                                    setFieldValue
                                                                }
                                                                provided={
                                                                    provided
                                                                }
                                                                handleRemove={(
                                                                    id
                                                                ) =>
                                                                    handleRemove(
                                                                        id,
                                                                        values.hotspot_target_combinations,
                                                                        'hotspot_target_combinations',
                                                                        setFieldValue
                                                                    )
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </>
                        )}
                </>
            )}
            {openHotspotOptionForm && (
                <HotspotPanoDataForm
                    valuesData={values}
                    setFieldValue={setFieldValue}
                    setOpenHotspotOptionForm={setOpenHotspotOptionForm}
                />
            )}
        </>
    );
};

export default HotspotAnswerGroupType;
