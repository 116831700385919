import { Box, Divider, Popper } from '@mui/material';
import {
    LATEST_DRAFT,
    LATEST_RELEASE,
} from 'components/ProcedureDesigner/components/FormNodeEditor/_helpers/constant';
import { useStyles } from './style';
const PopoverAction = ({ id, anchorEl, handleRelease, object }) => {
    //#region Constants
    const classes = useStyles();
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    //#endregion State

    //#region Selectors
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    //#endregion Effects

    //#region Methods
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render

    return (
        <>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement={'right-start'}
                transition={false}
                className={classes.popupWrapper}
            >
                {object?.last_draft_published_url && (
                    <>
                        <div className={classes.popupActionContainer}>
                            <Box
                                onClick={() => handleRelease(LATEST_DRAFT)}
                                className={classes.popupActionLabel}
                            >
                                {LATEST_DRAFT}
                            </Box>
                        </div>
                    </>
                )}
                {object?.last_release_published_url &&
                    object?.last_draft_published_url && <Divider />}
                {object?.last_release_published_url && (
                    <div className={classes.popupActionContainer}>
                        <Box
                            onClick={() => handleRelease(LATEST_RELEASE)}
                            className={classes.popupActionLabel}
                        >
                            {LATEST_RELEASE}
                        </Box>
                    </div>
                )}
            </Popper>
        </>
    );
    //#endregion Render
};
export { PopoverAction };
