import { makeStyles } from '@mui/styles';

const makeContentNodeFormStyles = makeStyles(() => ({
    container: {
        padding: '20px',
        width: '100%',
        height: '100%',
    },
    form: {
        display: 'flex',
        width: '100%',
        height: 'auto',
        padding: '10px',
        border: '1px solid',
    },
    formBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        width: '100%',
    },
    submitButtonRow: {
        marginTop: 'auto',
        alignSelf: 'end',
        display: 'flex',
        gap: '5px',
    },
    contentRow: {
        cursor: 'pointer',
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover,
        // },
        // // hide last border
        // '&:last-child td, &:last-child th': {
        //     border: 0,
        // },
    },
    dialogPaper: {
        minHeight: '100%',
        maxHeight: '100%',
    },
    addButtonIcon: {
        padding: '3px !important',
        borderRadius: '25px !important',
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
    },
}));

export { makeContentNodeFormStyles };
