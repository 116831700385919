import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    fetchPartsPage,
    getPartsPageInfo,
    getPartsStatus,
    resetPartsState,
    selectParts,
} from '_features/parts/partSlice';
import { resetPartCategoryState } from '_features/parts/partCategorySlice';
import { resetPartGroupState } from '_features/parts/partGroupSlice';
import { PartsTable } from './PartsTable';

const PartsTab = () => {
    const dispatch = useDispatch();
    const ref = useRef(false);
    const parts = useSelector(selectParts);
    const partStatus = useSelector(getPartsStatus);
    const partsPageInfo = useSelector(getPartsPageInfo);
    const [partPayload, setPartPayload] = useState({});
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');

    const getAllParts = async (payload) => {
        dispatch(activateLoading());
        await dispatch(fetchPartsPage(payload));
        dispatch(deactivateLoading());
    };

    useEffect(() => {
        if (ref.current === true) {
            getAllParts({ ...partPayload });
        }
    }, [partPayload]);

    useEffect(() => {
        return () => {
            dispatch(resetPartsState());
            dispatch(resetPartGroupState());
            dispatch(resetPartCategoryState());
        };
    }, []);

    useEffect(() => {
        if (partStatus === LoadingStatus.Idle) {
            getAllParts({ ...partPayload });
        }
        ref.current = true;
    }, [partStatus]);

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        setPartPayload({
            ...partPayload,
            sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
        });
    };
    return (
        <PartsTable
            parts={parts}
            pageInfo={partsPageInfo}
            partPayload={partPayload}
            setPartPayload={setPartPayload}
            sortDirection={sortDirection}
            sortBy={sortBy}
            handleSort={(_sortBy, _sortDirection) =>
                setSorting(_sortBy, _sortDirection)
            }
        />
    );
};

export { PartsTab };
