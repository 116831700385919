const variableValidation = (
    errors,
    values,
    isValidVariableName,
    checkUniqueVariableName,
    unsavedVariables
) => {
    if (values?.variable) {
        if (!isValidVariableName(values?.variable)) {
            errors = {
                ...errors,
                variable:
                    'Must start with letter followed by letters, numbers, or underscores',
            };
        } else if (!checkUniqueVariableName(values?.variable, values?.id)) {
            errors = {
                ...errors,
                variable: 'Must be unique in procedure',
            };
        } else {
            let isNotUnique = unsavedVariables.find(
                (vr) => vr.name === values?.variable && vr.id !== values?.id
            );
            if (isNotUnique) {
                errors = {
                    ...errors,
                    variable: 'Must be unique in procedure',
                };
            }
        }
    } else {
        errors = { ...errors, variable: 'Required' };
    }
    if (!values?.label) {
        errors = { ...errors, label: 'Required' };
    }
    return errors;
};

const selectFieldValidation = (errors, values) => {
    if (!values.options || values.options?.length <= 0) {
        errors = {
            ...errors,
            options: 'Required',
        };
    } else {
        values?.options.map((option, idx) => {
            if (!option.label) {
                errors = {
                    ...errors,
                    [`options[${idx}].label`]: 'Required',
                };
            }
            if (!option.value) {
                errors = {
                    ...errors,
                    [`options[${idx}].value`]: 'Required',
                };
            }
        });
    }
    return errors;
};

export { variableValidation, selectFieldValidation };
