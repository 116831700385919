import React from 'react';
import { Checkbox } from '@mui/material';

import { FormTextField } from 'components/TextField';

const HiddenField = ({ values, setFieldValue }) => {
    return (
        <>
            <div>
                <Checkbox
                    checked={Boolean(values.autoGenerateGuid)}
                    onClick={(e) =>
                        setFieldValue('autoGenerateGuid', e.target.checked)
                    }
                />
                Auto Generate Guid
            </div>

            {values.autoGenerateGuid !== true && (
                <div>
                    <FormTextField
                        label="Variable Value"
                        name="variableValue"
                        placeholder="Variable Value"
                    />
                </div>
            )}
        </>
    );
};

export default HiddenField;
