import React, { useEffect, useState, useRef } from 'react';
import { FiLayers } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

import { PageHeader } from 'components';
import { useStyles } from 'scenes/styles';
import { accountsSlice } from 'app/store';
const { selectUserSetting } = accountsSlice;
import { ContentFilter } from './ContentFilter';
import { DataNotFound } from 'components/DataNotFound';
import { ContentManagerTable } from './ContentManagerTable';
import { ContentManagerCardView } from './ContentManagerCardView';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { setViewContentPanel } from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    getContentStatus,
    selectContentById,
    getContentPageInfo,
    resetContentStatus,
    fetchContentByTypeId,
    fetchAllUsageById,
} from '_features/content/contentSlice';

const ContentManager = () => {
    const classes = useStyles();
    const [searchStringState, setSearchStringState] = useState('');
    const isComponentLoaded = useRef(true);

    const contentViewMode = useSelector((state) =>
        selectUserSetting(state, 'content_view_mode')
    );

    const dispatch = useDispatch();
    const content = useSelector(selectContentById);
    const contentPageInfo = useSelector(getContentPageInfo);
    const contentStatus = useSelector(getContentStatus);
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    const [contentPayload, setContentPayload] = useState({
        reset: true,
        startIndex: 0,
        stopIndex: 19,
        contentTypeIds: [],
    });
    const [sortBy, setSortBy] = useState('display_name');
    const [sortDirection, setSortDirection] = useState('');

    const getContentData = async (payload) => {
        await dispatch(activateLoading());
        await Promise.all([
            dispatch(fetchContentByTypeId({ ...payload })),
            dispatch(fetchAllUsageById({ ...payload })),
        ]);
        await dispatch(deactivateLoading());
    };

    useEffect(() => {
        dispatch(resetContentStatus());
        dispatch(setViewContentPanel(false));
        return () => dispatch(resetContentStatus());
    }, [dispatch]);

    useEffect(() => {
        if (contentStatus === LoadingStatus.Idle) {
            getContentData({ ...contentPayload });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentStatus, dispatch]);

    useEffect(() => {
        if (isComponentLoaded.current) {
            isComponentLoaded.current = false;
        } else {
            getContentData({ ...contentPayload });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentPayload, dispatch]);

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        if (_sortBy !== '') {
            setContentPayload({
                ...contentPayload,
                reset: true,
                startIndex: 0,
                stopIndex: 19,
                sort: `${_sortDirection === 'ASC' ? '' : '-'}${
                    _sortBy === 'content_type_id'
                        ? 'content_type_name'
                        : _sortBy
                }`,
            });
        }
    };

    return (
        <div className={classes.content}>
            <PageHeader header="Content Manager" icon={<FiLayers />} />
            <ContentFilter
                contentPayload={contentPayload}
                setContentPayload={setContentPayload}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
                searchStringState={searchStringState}
                setSearchStringState={setSearchStringState}
            />
            {content?.length > 0 ? (
                contentViewMode === 'list' ? (
                    <ContentManagerTable
                        content={content}
                        pageInfo={contentPageInfo}
                        contentPayload={contentPayload}
                        sortDirection={sortDirection}
                        sortBy={sortBy}
                        handleSort={(_sortBy, _sortDirection) =>
                            setSorting(_sortBy, _sortDirection)
                        }
                    />
                ) : (
                    <ContentManagerCardView
                        content={content}
                        pageInfo={contentPageInfo}
                        contentPayload={contentPayload}
                        readOnly={readOnlyMode}
                    />
                )
            ) : (
                contentStatus !== LoadingStatus.Loading && (
                    <DataNotFound
                        title="Content"
                        inputLength={searchStringState.length}
                        addPlural={false}
                    />
                )
            )}
        </div>
    );
};

export { ContentManager };
