import { useSelector } from 'react-redux';
import clsx from 'clsx';

// import LrsForm from './LrsNodeEditor/LrsForm';
// import FormNodeForm from './FormNodeEditor/FormNodeForm';
import LogicNodeForm from './LogicNodeEditor/LogicNodeForm';
import BranchNodeForm from './BranchNodeEditor/BranchNodeForm';
import ResultNodeForm from './ResultNodeEditor/ResultNodeForm';
import ContentNodeForm from './ContentNodeEditor/ContentNodeForm';
import { panelStyles } from 'GeminiViewerComponent/components/styles';
import EmailActionForm from './EmailActionNodeEditor/EmailActionForm';
import { getProcedureDirty } from '_features/procedures/proceduresSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import ContentViewActionForm from './ContentViewActionNodeEditor/ContentViewActionForm';
import { ProcedureNodeTypes } from 'GeminiViewerComponent/components/Procedure/ProcedureNodeTypes';
import NavigationActionForm from './NavigationActionNodeEditor/NavigationActionForm';

const NodeEditor = ({
    activeNode,
    assetId,
    variables,
    sortedNodes,
    handleSubmit,
    handleCancel,
    openSaveDataDialog,
    setOpenSaveDataDialog,
    showTwoPanelMode,
    procedureCategories = [],
}) => {
    const theme = useSelector(selectActiveTheme);
    const classes = panelStyles(theme);
    const isDirty = useSelector(getProcedureDirty);

    const isValidVariableName = (varName) => {
        const regex = /^([A-Z]|[a-z])+([A-Z]|[a-z]|\d|_)*$/;
        return regex.test(varName);
    };

    const checkUniqueVariableName = (varName, id) => {
        let isNotUnique = variables.find(
            (vr) => vr.name === varName && vr.id !== id
        );
        return isNotUnique ? false : true;
    };

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                // backgroundColor: 'white',
                overflow: 'auto',
            }}
            className={clsx({
                [classes.unsaveData]: isDirty,
            })}
        >
            {activeNode.type === ProcedureNodeTypes.content.type && (
                <ContentNodeForm
                    nodes={sortedNodes}
                    node={activeNode}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    assetId={assetId}
                    isValidVariableName={isValidVariableName}
                    checkUniqueVariableName={checkUniqueVariableName}
                    openSaveDataDialog={openSaveDataDialog}
                    setOpenSaveDataDialog={setOpenSaveDataDialog}
                    showTwoPanelMode={showTwoPanelMode}
                    procedureCategories={procedureCategories}
                />
            )}
            {/* {activeNode.type === ProcedureNodeTypes.form.type && (
                <FormNodeForm
                    nodes={sortedNodes}
                    node={activeNode}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    isValidVariableName={isValidVariableName}
                    checkUniqueVariableName={checkUniqueVariableName}
                    openSaveDataDialog={openSaveDataDialog}
                    setOpenSaveDataDialog={setOpenSaveDataDialog}
                />
            )} */}
            {activeNode.type === ProcedureNodeTypes.contentViewAction.type && (
                <ContentViewActionForm
                    nodes={sortedNodes}
                    node={activeNode}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    openSaveDataDialog={openSaveDataDialog}
                    setOpenSaveDataDialog={setOpenSaveDataDialog}
                    procedureCategories={procedureCategories}
                />
            )}
            {activeNode.type === ProcedureNodeTypes.navigationAction.type && (
                <NavigationActionForm
                    nodes={sortedNodes}
                    node={activeNode}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    openSaveDataDialog={openSaveDataDialog}
                    setOpenSaveDataDialog={setOpenSaveDataDialog}
                    procedureCategories={procedureCategories}
                />
            )}
            {/* {activeNode.type === ProcedureNodeTypes.lrs.type && (
                <LrsForm
                    nodes={sortedNodes}
                    node={activeNode}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    assetId={assetId}
                    openSaveDataDialog={openSaveDataDialog}
                    setOpenSaveDataDialog={setOpenSaveDataDialog}
                />
            )} */}
            {activeNode.type === ProcedureNodeTypes.branch.type && (
                <BranchNodeForm
                    nodes={sortedNodes}
                    node={activeNode}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    assetId={assetId}
                    openSaveDataDialog={openSaveDataDialog}
                    setOpenSaveDataDialog={setOpenSaveDataDialog}
                    procedureCategories={procedureCategories}
                />
            )}
            {activeNode.type === ProcedureNodeTypes.results.type && (
                <ResultNodeForm
                    nodes={sortedNodes}
                    node={activeNode}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    openSaveDataDialog={openSaveDataDialog}
                    setOpenSaveDataDialog={setOpenSaveDataDialog}
                    procedureCategories={procedureCategories}
                />
            )}
            {activeNode.type === ProcedureNodeTypes.logic.type && (
                <LogicNodeForm
                    nodes={sortedNodes}
                    node={activeNode}
                    variables={variables}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    openSaveDataDialog={openSaveDataDialog}
                    setOpenSaveDataDialog={setOpenSaveDataDialog}
                    procedureCategories={procedureCategories}
                />
            )}
            {activeNode.type === ProcedureNodeTypes.email.type && (
                <EmailActionForm
                    nodes={sortedNodes}
                    node={activeNode}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    openSaveDataDialog={openSaveDataDialog}
                    setOpenSaveDataDialog={setOpenSaveDataDialog}
                    procedureCategories={procedureCategories}
                />
            )}
        </div>
    );
};

export { NodeEditor };
