import { config } from 'gemini-config';
import {
    createProgressParams,
    getNetworkService,
    FormDataUtils,
    prefixUrl,
} from 'GeminiViewerComponent/_helpers';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/part/group`;

const mapPartGroup = (part_group, s3Prefix) => {
    if (part_group?.image_url) {
        part_group.image_url = prefixUrl(part_group.image_url, s3Prefix);
    }
    return part_group;
};

const mapPartGroups = (partGroups, s3Prefix) => {
    return partGroups.map((grp) => {
        return mapPartGroup(grp, s3Prefix);
    });
};

const getPartGroup = async (
    searchString = '',
    sort = '-display_name',
    s3Prefix = '',
    activeOnly = true
) => {
    const filters = [
        `is_active==${activeOnly.toString()}`,
        `display_name@=*${searchString}`,
    ];
    const params = { filters: filters.join(','), sorts: sort };
    const query = new URLSearchParams(params).toString();
    try {
        const response = await networkService.get(`${baseUrl}?${query}`);
        response.data = mapPartGroups(response?.data, s3Prefix);
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, partGroups: response?.data };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const create = async (params, s3Prefix = '') => {
    const user = JSON.parse(localStorage.getItem('user'));
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [
        ['display_name', params.display_name],
        ['client_id', user.client_id],
        ['description', params?.description],
        ['image', params.image],
    ]);
    let progressParams = createProgressParams(params);
    try {
        const response = await networkService.postMultiFormData(
            baseUrl,
            formData,
            progressParams
        );
        response.data = mapPartGroup(response.data, s3Prefix);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const update = async (params, s3Prefix = '') => {
    const user = JSON.parse(localStorage.getItem('user'));
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [
        ['display_name', params.display_name],
        ['client_id', user.client_id],
        ['description', params?.description],
        ['image', params.image],
        ['image_url', params.image_url],
    ]);
    let progressParams = createProgressParams(params);
    try {
        const response = await networkService.putMultiFormData(
            `${baseUrl}/${params.part_group_id}`,
            formData,
            progressParams
        );
        response.data = mapPartGroup(response.data, s3Prefix);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _delete = async (part_group_id) => {
    try {
        const response = await networkService.delete(
            `${baseUrl}/${part_group_id}`
        );
        return response;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getById = async (id, s3Prefix = '') => {
    try {
        const response = await networkService.get(`${baseUrl}/${id}`);
        response.data = mapPartGroup(response.data, s3Prefix);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const partGroupService = {
    getPartGroup,
    getById,
    create,
    update,
    delete: _delete,
};
