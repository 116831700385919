import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    IconButton,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Collapse,
    Tooltip,
} from '@mui/material';
import {
    MdClose,
    MdFolder,
    MdKeyboardArrowDown,
    MdKeyboardArrowUp,
} from 'react-icons/md';
import { PopupActions } from 'components/_Misc/PopupActions';
import { manageSnippetDialogStyles } from './styles';
import { useSelector } from 'react-redux';
import {
    getAllFolders,
    getAllSnippets,
} from '_features/snippets/snippetsSlice';
import { FormActions } from '_helpers';
import { CreateDialog } from '../CreateDialog';

const Row = ({ row, snippets, classes, setSelectedSnippet }) => {
    const [open, setOpen] = useState(false);

    const filterSnippet = snippets.filter(
        (item) => item?.snippet_folder_id === row?.snippet_folder_id
    );

    return (
        <>
            <TableRow>
                <TableCell className={classes.collapseRow} align="left">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                    </IconButton>
                    <MdFolder style={{ height: '20px', width: '20px' }} />
                    {row.display_name}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    {filterSnippet.map((snippet) => (
                                        <TableRow key={snippet.snippet_id}>
                                            <TableCell
                                                scope="row"
                                                className={
                                                    classes.popupActionCell
                                                }
                                            >
                                                <PopupActions
                                                    object={{
                                                        snippet: snippet,
                                                        setSelected:
                                                            setSelectedSnippet,
                                                    }}
                                                    level={'snippet'}
                                                    targetPopup={'snippet'}
                                                />
                                            </TableCell>
                                            <TableCell
                                                scope="row"
                                                className={
                                                    classes.nestedTableCell
                                                }
                                            >
                                                <Tooltip
                                                    title={snippet.display_name}
                                                    followCursor
                                                    arrow
                                                >
                                                    <Typography
                                                        className={
                                                            classes.nestedTableText
                                                        }
                                                    >
                                                        {snippet.display_name}
                                                    </Typography>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell
                                                className={
                                                    classes.nestedTableCell
                                                }
                                            >
                                                <Tooltip
                                                    title={snippet.trigger_key}
                                                    followCursor
                                                    arrow
                                                >
                                                    <Typography
                                                        className={
                                                            classes.nestedTableText
                                                        }
                                                    >
                                                        {snippet.trigger_key}
                                                    </Typography>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const ManageDialog = ({ setManageSnippet }) => {
    const [selectedSnippet, setSelectedSnippet] = useState(false);

    const snippets = useSelector(getAllSnippets);
    const folders = useSelector(getAllFolders);
    const classes = manageSnippetDialogStyles();

    return (
        <>
            <Dialog open={true} fullWidth maxWidth="sm">
                <DialogTitle className={classes.dialogTitle}>
                    Snippets
                    <IconButton
                        aria-label="close"
                        onClick={() => setManageSnippet(null)}
                        size="large"
                    >
                        <MdClose className="react-icon" />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.tableHeader}>
                    <Box className={classes.tableHeaderContainer}>
                        <Typography style={{ width: '50%' }}>NAME</Typography>
                        <Typography style={{ width: '50%' }}>
                            TRIGGER KEY
                        </Typography>
                    </Box>
                    <Table>
                        <TableBody>
                            {folders.map((folder) => (
                                <Row
                                    key={folder.snippet_folder_id}
                                    row={folder}
                                    snippets={snippets}
                                    classes={classes}
                                    setSelectedSnippet={setSelectedSnippet}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>
            {selectedSnippet && (
                <CreateDialog
                    formAction={FormActions.Edit}
                    setSnippet={setSelectedSnippet}
                />
            )}
        </>
    );
};

export { ManageDialog };
