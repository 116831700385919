import logo from 'assets/logo.png';
import { NavLink, useParams } from 'react-router-dom';

import {
    Button,
    TextField,
    Typography,
    Grid,
    Alert,
    AlertTitle,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import useStyles from '../../styles';
import clsx from 'clsx';

import { getAccountService } from '../../../../_features/services/account.service';

import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import { useSelector } from 'react-redux';

function ResetPassword() {
    const classes = useStyles();

    let params = useParams();
    let token = params.token;

    return (
        <div className={classes.backgroundContainer}>
            <Grid
                container
                className={classes.loginContainer}
                item
                xs={12}
                sm={6}
                lg={4}
                xl={3}
            >
                <Grid item className={classes.loginGridItem}>
                    <img
                        src={logo}
                        className={classes.logo}
                        alt="Gemini logo"
                    ></img>
                </Grid>
                <ResetPasswordForm token={token} />
            </Grid>
        </div>
    );
}

function ResetPasswordForm({ token }) {
    const classes = useStyles();
    const [isError, setisError] = useState(false);
    const [isTokenValidated, setisTokenValidated] = useState(false);
    const [isSuccess, setisSuccess] = useState(false);
    const [newPassword, setnewPassword] = useState('');
    const [invalidNewPassword, setInvalidNewPassword] = useState(false);
    const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const [invalidConfirmPassword, setInvalidConfirmPassword] = useState(false);
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
        useState('');

    const config = useSelector(getConfig);
    const accountService = getAccountService({ config: config });

    const onSubmit = () => {
        clearErrors();
        if (validate()) {
            accountService
                .resetPassword({
                    token,
                    password: newPassword,
                    confirmPassword: confirmPassword,
                })
                .then(() => {
                    setisSuccess(true);
                })
                .catch(() => {
                    setisError(true);
                });
        }
    };

    const validate = () => {
        if (newPassword.length < 6) {
            setInvalidNewPassword(true);
            setNewPasswordErrorMessage(
                'Must provide new password with at least 6 characters.'
            );
            return false;
        } else if (confirmPassword !== newPassword) {
            setInvalidConfirmPassword(true);
            setConfirmPasswordErrorMessage('Must match new password.');
            return false;
        } else return true;
    };

    const clearErrors = () => {
        setInvalidNewPassword(false);
        setNewPasswordErrorMessage('');
        setInvalidConfirmPassword(false);
        setConfirmPasswordErrorMessage('');
    };

    useEffect(() => {
        accountService
            .validateResetToken(token)
            .then(() => {
                setisTokenValidated(true);
            })
            .catch(() => {
                setisTokenValidated(false);
            });
    }, []); // Only allow this to run once

    return (
        <Grid className={classes.loginForm}>
            {!isTokenValidated ? (
                <Alert severity="info" className={clsx(classes.loginForm)}>
                    <AlertTitle>Validating token....</AlertTitle>
                </Alert>
            ) : (
                <React.Fragment>
                    <Grid
                        item
                        className={clsx(classes.loginForm, {
                            [classes.hide]: isSuccess,
                        })}
                    >
                        <Typography
                            className={clsx(
                                classes.spacing,
                                classes.formHeader
                            )}
                        >
                            Password Reset
                        </Typography>
                        <Typography
                            className={clsx(
                                classes.spacing,
                                classes.formSubtitle
                            )}
                        >
                            Please enter your new password.
                        </Typography>
                        <form
                            className={clsx(
                                classes.arrangement,
                                classes.spacing
                            )}
                        >
                            <TextField
                                onChange={(e) => setnewPassword(e.target.value)}
                                size="small"
                                error={invalidNewPassword}
                                helperText={newPasswordErrorMessage}
                                id="outlined-basic"
                                type="password"
                                label="New Password"
                                name="password"
                                variant="outlined"
                                required
                                className={classes.spacing}
                            />
                            <TextField
                                onChange={(e) =>
                                    setconfirmPassword(e.target.value)
                                }
                                size="small"
                                error={invalidConfirmPassword}
                                helperText={confirmPasswordErrorMessage}
                                id="outlined-basic"
                                type="password"
                                name="confirmPassword"
                                label="Confirm Password"
                                variant="outlined"
                                required
                                className={classes.spacing}
                            />
                            <Button
                                size="medium"
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={onSubmit}
                            >
                                Change Password
                            </Button>
                        </form>
                        <Alert
                            severity="error"
                            className={clsx(classes.loginError, {
                                [classes.hide]: !isError,
                            })}
                        >
                            <AlertTitle>
                                <strong>Something is wrong!</strong>
                            </AlertTitle>
                            We were unable to change your password. Please check
                            that your new password is at least 6 characters, and
                            confirm password matches. If the problem persists,
                            please contact your administrator.
                        </Alert>
                    </Grid>
                    <Grid
                        item
                        className={clsx(classes.loginForm, {
                            [classes.hide]: !isSuccess,
                        })}
                    >
                        <Typography
                            className={clsx(
                                classes.spacing,
                                classes.formHeader
                            )}
                        >
                            Password Reset
                        </Typography>
                        <Typography
                            className={clsx(
                                classes.spacing,
                                classes.formSubtitle
                            )}
                        >
                            Password successfully reset. You may now login with
                            your new password.
                        </Typography>
                        <NavLink
                            to="/account/login"
                            className={clsx(classes.resetButton)}
                        >
                            <Button
                                size="medium"
                                variant="contained"
                                color="primary"
                                disableElevation
                                type=""
                            >
                                Login
                            </Button>
                        </NavLink>
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    );
}

export { ResetPassword };
