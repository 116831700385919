import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputBase } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import NativeSelect from '@mui/material/NativeSelect';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import { MdSearch } from 'react-icons/md';

import {
    getContentCategoriesStatus,
    fetchContentCategories,
    selectContentCategories,
    resetExternalContentStatus,
} from '_features/content/contentSlice';
import useStyles from '../styles';
import { ContentTypes } from '_helpers';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';

function SchooxQueryTableInputHeader({
    category,
    filterByTag,
    setCategory,
    setFilterByTag,
    updateInputValue,
    zoneTag,
}) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
        dispatch(resetExternalContentStatus());
    };
    const handleTagChange = () => {
        setFilterByTag(!filterByTag);
        dispatch(resetExternalContentStatus());
    };

    const contentCategories = useSelector(selectContentCategories);
    const contentCategoriesStatus = useSelector((state) =>
        getContentCategoriesStatus(state)
    );
    useEffect(() => {
        if (contentCategoriesStatus === LoadingStatus.Idle) {
            dispatch(
                fetchContentCategories({
                    contentType: ContentTypes.Schoox.label,
                })
            );
        }
    }, [contentCategoriesStatus, dispatch]);

    return (
        <div className={classes.inputHeader}>
            <div className={classes.inputHeaderRight}>
                <FormControl className={classes.formControl}>
                    <NativeSelect
                        value={category}
                        onChange={handleCategoryChange}
                        name="catgeory"
                        className={classes.selectEmpty}
                    >
                        <option value="">Select Category</option>
                        {contentCategories.map((c) => {
                            return (
                                <option key={c.id} value={c.id}>
                                    {c.name}
                                </option>
                            );
                        })}
                    </NativeSelect>
                </FormControl>
                {zoneTag !== undefined ? (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filterByTag}
                                onChange={handleTagChange}
                                name="tagfilter"
                                color="primary"
                            />
                        }
                        label="Tag Filter"
                    />
                ) : null}
            </div>
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <MdSearch className="react-icon" />
                </div>
                <InputBase
                    placeholder="Search…"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    onChange={(evt) => updateInputValue(evt)}
                />
            </div>
        </div>
    );
}

export { SchooxQueryTableInputHeader };
