import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    container: {
        marginBottom: '0px',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: (props) => props.colors.primarySectionBackground,
        color: (props) => props.colors.primarySectionForeground,
        padding: '12px 15px!important',
    },
    childHeader: {
        backgroundColor: '#1a9ddd',
    },
    headerTitle: {
        textTransform: 'uppercase',
        fontSize: '22px',
    },
    userInfoWrap: {
        backgroundColor: '#878f99',
    },
    userInfoInnerWrap: {
        alignItems: 'center',
        height: '100%',
        maxHeight: '200px',
        overflow: 'auto',
    },
    userInfo: {
        textTransform: 'capitalize',
        textAlign: 'center',
        color: '#fff',
        fontSize: '16px',
        padding: '12px 15px',
    },
}));

export { useStyles };
