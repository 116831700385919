import React, { useRef, useEffect, useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, Checkbox, Tooltip } from '@mui/material';

import { Action } from '_helpers';
import { VirtualGrid } from 'components';
import ContentCard from './components/ContentCard';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import {
    selectContentById,
    fetchContentByTypeId,
    getContentStatus,
} from '_features/content/contentSlice';

function ContentManagerCardView({
    content,
    pageInfo,
    contentPayload,
    readOnly,
}) {
    const dispatch = useDispatch();
    const rowHeight = 270;

    const allRows = useSelector(selectContentById);
    const loadedRowsMap = useSelector(
        (state) => state.content.contentLoadedRowsMap
    );

    const parentRef = useRef(null);
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);
    const contentStatus = useSelector(getContentStatus);

    /* selection start */
    const isSelected = (id) => selected.indexOf(id) !== -1;

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== content.length) {
            setSelected(content.map((row) => row.content_id));
            setSelectedAll(true);
        }
    }

    const handleClick = (event, content_id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(content_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, content_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        setSelectedAll(newSelected.length === content.length);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedIds = content.map((item) => item.content_id);
            setSelected(newSelectedIds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    /* selection end */

    return (
        <div ref={parentRef}>
            <div style={{ height: 'calc(100vh - 315px)' }}>
                {selected.length > 0 && (
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        toolTipTitle={'Delete'}
                        totalCount={pageInfo.TotalCount}
                    >
                        <Tooltip title="Download Content Inventory">
                            <span>
                                <PopupAction
                                    action={Action.ContentInventory}
                                    object={selected}
                                    level={'content'}
                                    showLabel={false}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete All">
                            <span>
                                <PopupAction
                                    action={Action.DeleteArray}
                                    object={{
                                        contentIds: selected,
                                        setContentIds: setSelected,
                                    }}
                                    level={'content'}
                                    showLabel={false}
                                />
                            </span>
                        </Tooltip>
                    </EnhancedTableToolbar>
                )}
                {readOnly === false && (
                    <FormControlLabel
                        label="Select All"
                        control={
                            <Checkbox
                                color="primary"
                                indeterminate={
                                    selected.length > 0 &&
                                    selected.length < content.length
                                }
                                checked={
                                    content.length > 0 &&
                                    selected.length === content.length
                                }
                                onChange={handleSelectAllClick}
                            />
                        }
                    />
                )}
                <AutoSizer>
                    {({ width, height }) => (
                        <VirtualGrid
                            initialRows={content}
                            allRows={allRows}
                            totalRowCount={pageInfo.TotalCount}
                            loadedRowsMap={loadedRowsMap}
                            loadMoreRows={loadMoreRows}
                            width={width}
                            height={height}
                            rowHeight={rowHeight}
                            cellRenderer={cellRenderer}
                            parentRef={parentRef}
                            style={{
                                overflowX: 'hidden',
                            }}
                        />
                    )}
                </AutoSizer>
            </div>
        </div>
    );

    async function loadMoreRows({ startIndex, stopIndex }) {
        if (contentStatus !== LoadingStatus.Loading) {
            await dispatch(
                fetchContentByTypeId({
                    ...contentPayload,
                    reset: false,
                    startIndex: startIndex,
                    stopIndex: stopIndex,
                })
            );
        }
    }

    function cellRenderer(gridItem, key, style, columnWidth) {
        if (gridItem == null) {
            return null;
        }
        return (
            <div style={style} key={key}>
                <ContentCard
                    handleClick={handleClick}
                    content={gridItem}
                    width={columnWidth - 20}
                    cellLevel={'content'}
                    isSelected={isSelected}
                    readOnly={readOnly}
                />
            </div>
        );
    }
}

export { ContentManagerCardView };
