/**
 * This slice is to manage validation types
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { validationTypeService } from '_features/_services/validationType.service';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    error: null,
    validationTypes: [],
    loadingStatus: LoadingStatus.Idle,
    savingStatus: SavingStatus.Idle,
};

export const createNewValidationType = () => {
    return {
        validation_type_id: uuidv4(),
        display_name: '',
        type: 1,
        max_length: null,
        min_value: null,
        max_value: null,
        options_array: [],
    };
};

export const getValidationTypes = createAsyncThunk(
    'validationTypes/getValidationTypes',
    async (params) => {
        // await new Promise((resolve) => setTimeout(resolve, 1000)); // debug temp
        return await validationTypeService.getAll(params);
    }
);

export const updateValidationTypes = createAsyncThunk(
    'validationTypes/updateValidationTypes',
    async (params) => {
        // await new Promise((resolve) => setTimeout(resolve, 1000)); // debug temp
        return await validationTypeService.updateAll(
            params.values,
            params.params
        );
    }
);

const validationTypesSlice = createSlice({
    name: 'validationTypes',
    initialState,
    reducers: {
        setValidationTypes: (state, action) => {
            state.validationTypes = action.payload;
        },
    },
    extraReducers: {
        [getValidationTypes.pending]: (state) => {
            state.loadingStatus = LoadingStatus.Loading;
        },
        [getValidationTypes.fulfilled]: (state, action) => {
            state.loadingStatus = LoadingStatus.Loaded;
            state.validationTypes = action.payload;
        },
        [getValidationTypes.rejected]: (state, action) => {
            state.loadingStatus = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [updateValidationTypes.pending]: (state) => {
            state.savingStatus = SavingStatus.Saving;
        },
        [updateValidationTypes.fulfilled]: (state, action) => {
            state.error = null;
            state.savingStatus = SavingStatus.Saved;
            state.validationTypes = action.payload.validation_types;
            const failedCount = Object.keys(
                action.payload.status.failed_to_delete ?? {}
            ).length;
            if (failedCount > 0) {
                state.error = `Failed to delete ${failedCount} type(s) likely due to usage by config fields.`;
            }
        },
        [updateValidationTypes.rejected]: (state, action) => {
            state.savingStatus = SavingStatus.Failed;
            state.error = action.error.message;
        },
    },
});

export const selectValidationTypesErrorMessage = (state) =>
    state.validationTypes.error;
export const selectValidationTypesSavingStatus = (state) =>
    state.validationTypes.savingStatus;
export const selectValidationTypesLoadingStatus = (state) =>
    state.validationTypes.loadingStatus;
export const selectValidationTypes = (state) => {
    return state.validationTypes.validationTypes;
};

export const { setValidationTypes } = validationTypesSlice.actions;

export default validationTypesSlice.reducer;
