import { config } from 'gemini-config';
import { getNetworkService } from 'GeminiViewerComponent/_helpers';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}`;
const user = JSON.parse(localStorage.getItem('user'));

const getInspectionAction = async () => {
    const response = await networkService.get(
        `${baseUrl}/InspectionActionList`
    );
    return response.data;
};

const getInspectionActionById = async (actionId) => {
    const response = await networkService.get(
        `${baseUrl}/InspectionActionList/${actionId}`
    );
    return response.data;
};

const createInspectionAction = async (params) => {
    params.client_id = user.client_id;

    const response = await networkService.post(
        `${baseUrl}/InspectionActionList`,
        params
    );
    return response.data;
};

const updateInspectionAction = async (params) => {
    const { inspection_action_list_id } = params;
    const response = await networkService.put(
        `${baseUrl}/InspectionActionList/${inspection_action_list_id}`,
        params
    );
    return response.data;
};

const getInspectionReason = async () => {
    const response = await networkService.get(
        `${baseUrl}/InspectionReasonList`
    );
    return response.data;
};

const getInspectionReasonById = async (reasonId) => {
    const response = await networkService.get(
        `${baseUrl}/InspectionReasonList/${reasonId}`
    );
    return response.data;
};

const createInspectionReason = async (params) => {
    params.client_id = user.client_id;

    const response = await networkService.post(
        `${baseUrl}/InspectionReasonList`,
        params
    );
    return response.data;
};

const updateInspectionReason = async (params) => {
    const { inspection_reason_list_id } = params;
    const response = await networkService.put(
        `${baseUrl}/InspectionReasonList/${inspection_reason_list_id}`,
        params
    );
    return response.data;
};

export const inspectionsService = {
    getInspectionAction,
    getInspectionActionById,
    createInspectionAction,
    updateInspectionAction,
    getInspectionReason,
    getInspectionReasonById,
    createInspectionReason,
    updateInspectionReason,
};
