import { makeStyles } from '@mui/styles';

const useSceneStyles = makeStyles(() => ({
    sceneControls: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        gap: '10px',
        justifyContent: 'flex-start',
        width: '100%',
        backgroundColor: 'white',
        padding: '5px',
        borderTop: '1px solid',
        zIndex: 9,
    },
}));

export { useSceneStyles };
