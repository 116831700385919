import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';

const homeStyles = makeStyles((theme) => ({
    root: {
        '& .assetHeader': {
            fontSize: '10px',
        },
        '& .MuiDataGrid-colCellTitle': {
            color: '#7E8C8D',
            fontWeight: 800,
            fontSize: '12px',
        },
        '& .MuiDataGrid-columnSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-cell': {
            color: '#34485E',
            fontSize: '14px',
        },
        '& .MuiCheckbox-root': {
            color: '#7E8C8D',
        },
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: '#3598DB',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1.25rem',
        },
    },
    dataGridContainer: {
        '& > div': {
            height: 'unset !important',
        },
        marginBottom: '15px',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            // marginLeft: theme.spacing(3),
            width: 'auto',
            margin: '0px',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#BDC3C7',
    },
    inputRoot: {
        color: 'inherit',
        border: '1px solid #BDC3C7',
        borderRadius: '5px',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    assetDisplayname: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    actionBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    actionBarRight: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
    },
    actionButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        whiteSpace: 'nowrap',
    },
    list: {
        width: 576,
    },
    fullList: {
        width: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: '25px 50px',
        height: '85%',
        width: 'calc(100% - 165px)',
    },
    createContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
        backgroundColor: '#ECF0F1',
        color: '#2D3D50',
    },
    createHeader: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        fontSize: '24px',
        gap: '5px',
    },
    createHeaderText: {
        fontWeight: 800,
        paddingLeft: '10px',
    },
    panel: {
        padding: '25px',
    },
    panelHeader: {
        paddingBottom: '15px',
        fontWeight: 800,
        color: '#2D3D50',
    },
    panelInputBottom: {
        marginTop: '15px',
        width: '100%',
    },
    splitInput: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    splitInputRight: {
        width: '100%',
    },
    splitInputLeft: {
        width: '35%',
    },
    stackInputs: {
        display: 'flex',
        flexDirection: 'column',
    },
    stackInput: {
        marginTop: '15px',
    },
    pin: {
        display: 'flex',
        cursor: 'pointer',
        '& svg': {
            height: '20px',
            width: '20px',
        },
        '& svg:hover': {
            filter: 'brightness(50%)',
        },
    },
    isPinned: {
        color: '#E84C3C',
        display: 'flex',
    },
    gridLink: {
        textDecoration: 'none',
    },
    gridLinkSpan: {
        backgroundColor: '#3598DB',
        padding: '5px',
        color: 'white',
        borderRadius: '5px',
        fontSize: '12px',
    },
    createAsset: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    createAssetTitle: {
        fontSize: '24px',
        fontWeight: 300,
        color: '#BDC3C7',
        marginBottom: '20px',
        textAlign: 'center',
    },
    createAssetImage: {
        marginTop: '30px',
        height: '200px',
    },
}));

export { homeStyles };
