import { makeStyles } from '@mui/styles';

const flyerViewStyles = makeStyles(() => ({
    flyerView: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'contain',
        },
    },
    closeIcon: {
        height: '25px',
        width: '25px',
        color: '#34485E',
        marginRight: '10px',
        cursor: 'pointer',
        transition: 'transform .7s ease-in-out',
        '&:hover': {
            transform: 'rotate(360deg)',
        },
    },
}));

export { flyerViewStyles };
