import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { MdExpandMore } from 'react-icons/md';
import { Button } from '@mui/material';

import { accordionStyles } from 'GeminiViewerComponent/components/styles';
import { LinkContentTable } from 'components';
import { LinkTypes } from '_helpers';

function ContentAccordion({
    title,
    content,
    contentId,
    setFieldValue,
    expandedDefault,
    formField,
    contentTypes,
    linkTypeId,
    contentDisplayName,
    setContentDisplayName,
    linkNameValue,
    linkNameField,
    linkDescField,
    contentVersionsField,
    navigateToContentForm = false,
    readOnly = false,
}) {
    const classes = accordionStyles();

    const name = content ? content.display_name : title;
    const linkTypeLabel = Object.values(LinkTypes).find(
        (at) => at.id === linkTypeId
    )?.label;

    const [expanded, setExpanded] = useState(expandedDefault ?? true);

    const handleAccordionChange = () => (event, isExpanded) => {
        if (readOnly !== true) {
            setExpanded(isExpanded);
        }
    };

    return (
        <div className={classes.root}>
            <Accordion
                className={classes.accordion}
                expanded={expanded}
                onChange={handleAccordionChange()}
            >
                <AccordionSummary
                    classes={{ root: classes.accordionSummary }}
                    expandIcon={<MdExpandMore className="react-icon" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.customAccordionSummary}
                >
                    <Typography className={classes.heading}>{name}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: 'column' }}>
                    <LinkContentTable
                        setExpanded={setExpanded}
                        formField={formField}
                        contentId={contentId}
                        setFieldValue={setFieldValue}
                        contentTypes={contentTypes}
                        linkTypeId={linkTypeId}
                        contentDisplayName={contentDisplayName}
                        setContentDisplayName={setContentDisplayName}
                        linkNameValue={linkNameValue}
                        linkNameField={linkNameField}
                        linkDescField={linkDescField}
                        contentVersionsField={contentVersionsField}
                    />
                </AccordionDetails>
            </Accordion>
            {!contentDisplayName && navigateToContentForm ? (
                <>
                    <p style={{ fontWeight: 1000, margin: '5px auto' }}>OR</p>
                    <Button
                        className={classes.tabButton}
                        onClick={(e) => navigateToContentForm()}
                        variant="outlined"
                        color="primary"
                    >
                        New {linkTypeLabel} Content
                    </Button>
                </>
            ) : null}
        </div>
    );
}

export { ContentAccordion };
