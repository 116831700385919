/**
 * The purpose of this slice is to load zones from the server
 * It is only used by Gemini Author. The zoneLoadingStatusSlice will
 * look for the action creators from this loadZone thunk and set
 * state appropriately. The Gemini Viewer also has a version
 * of the zoneLoadingStatusSlice where the status is always
 * set to loaded since the viewer loads all zones at once.
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    getLoadedZoneById,
    setActiveZone,
    selectActiveAsset,
    updateOrAddZoneInActiveAsset,
} from 'GeminiViewerComponent/_features/asset/assetSlice';
import {
    popZone,
    pushZone,
} from 'GeminiViewerComponent/_features/zonePreview/zonePreviewSlice';
import { clearEditItem } from '_features/common/editItemSlice';
import { zoneService, itemService, navLinkService } from '_features/_services';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { prefixUrl } from 'GeminiViewerComponent/_helpers';

const initialState = {
    loadingStatus: LoadingStatus.Loaded,
    previousLoadingStatus: LoadingStatus.Loaded,
    loadingMessage: null,
    loadedZone: null,
    navItemStack: {},
    itemLinks: [],
};

const mapContentVersion = (contentVersionLink, s3Prefix) => {
    const link = { ...contentVersionLink };
    if (link.url) {
        link.url = prefixUrl(link.url, s3Prefix);
    }
    if (link.encoded_url) {
        link.encoded_url = prefixUrl(link.encoded_url, s3Prefix);
    }
    return link;
};

const mapLink = (itemLink, s3Prefix) => {
    const link = { ...itemLink };
    if (link.content_version) {
        link.content_version = mapContentVersion(
            link.content_version,
            s3Prefix
        );
    } else {
        link.content_version = mapContentVersion(link, s3Prefix);
    }
    return link;
};

const mapLinks = (links, s3Prefix) => {
    return links.map((link) => {
        return mapLink(link, s3Prefix);
    });
};

function addImageProps(zone) {
    if (zone.equirect_image_url) {
        zone = {
            ...zone,
            ...{
                equirect_image: {
                    file: null,
                    length: 0,
                    name: zone.original_equirect_image_name,
                },
            },
        };
    }
    if (zone.flat_image_url) {
        zone = {
            ...zone,
            ...{
                flat_image: {
                    file: null,
                    length: 0,
                    name: zone.original_flat_image_name,
                },
            },
        };
    }
    return zone;
}

export const loadZone = createAsyncThunk(
    'loadZone/loadZone',
    async (
        { zoneId, refresh = false, setActive = true, backup = false },
        { getState, dispatch }
    ) => {
        let zone = null;
        if (!refresh) {
            zone = getLoadedZoneById(getState(), zoneId);
            if (zone && setActive) {
                dispatch(
                    setActiveZone({
                        s3_prefix: getState().accounts.activeUser.s3_prefix,
                        zoneId,
                    })
                );
                if (backup) {
                    dispatch(popZone());
                } else {
                    const activeAsset = selectActiveAsset(getState());
                    dispatch(
                        pushZone({ assetId: activeAsset.asset_id, zoneId })
                    );
                }
            }
        }
        if (!zone) {
            zone = await zoneService.getById(
                zoneId,
                getState().accounts.activeUser.s3_prefix
            );
            if (zone) {
                const navLinks = await navLinkService.getAll(zoneId);
                const items = await itemService.getAll(
                    zoneId,
                    getState().accounts.activeUser.s3_prefix
                );

                zone = addImageProps(zone);

                zone.nav_links = navLinks.nav_links;
                zone.items = items.items;

                await dispatch(
                    updateOrAddZoneInActiveAsset({
                        zone: zone,
                        setActive: setActive,
                    })
                );
                if (setActive) {
                    dispatch(
                        setActiveZone({
                            s3_prefix: getState().accounts.activeUser.s3_prefix,
                            zoneId,
                        })
                    );

                    if (backup) {
                        dispatch(popZone());
                    } else {
                        const activeAsset = selectActiveAsset(getState());
                        dispatch(
                            pushZone({ assetId: activeAsset.asset_id, zoneId })
                        );
                    }
                }
            }
        }

        if (zone) {
            dispatch(clearEditItem);
        }

        return zone;
    }
);

const loadZoneSlice = createSlice({
    name: 'loadZone',
    initialState,
    reducers: {
        clearLoadZone: (state) => {
            state.loadingStatus = LoadingStatus.Idle;
            state.loadingMessage = null;
            state.navItemStack = {};
        },
        addItemToUndoStack: (state, action) => {
            if (state.loadedZone === action.payload.zone_id) {
                state.navItemStack[action.payload.zone_id].items.push(
                    action.payload
                );
            }
        },
        addNavLinkToUndoStack: (state, action) => {
            if (state.loadedZone === action.payload.zone_id) {
                state.navItemStack[action.payload.zone_id].nav_links.push(
                    action.payload
                );
            }
        },
        setStackPointer: (state, action) => {
            if (
                state.loadedZone === action.payload.zone_id &&
                state.navItemStack[action.payload.zone_id]
            ) {
                state.navItemStack[action.payload.zone_id] = {
                    ...state.navItemStack[action.payload.zone_id],
                    currentPointer:
                        action.payload.action === 'undo'
                            ? --state.navItemStack[action.payload.zone_id]
                                  .currentPointer
                            : ++state.navItemStack[action.payload.zone_id]
                                  .currentPointer,
                };
            }
        },
        updateNavItemStack: (state, action) => {
            if (
                state.loadedZone === action.payload.zone_id &&
                state.navItemStack[action.payload.zone_id]
            ) {
                if (
                    state.navItemStack[action.payload.zone_id]
                        .currentPointer === -1
                ) {
                    state.navItemStack[action.payload.zone_id] = {
                        ...state.navItemStack[action.payload.zone_id],
                        stack: [action.payload],
                    };
                } else {
                    state.navItemStack[action.payload.zone_id] = {
                        ...state.navItemStack[action.payload.zone_id],
                        stack: [
                            ...state.navItemStack[
                                action.payload.zone_id
                            ].stack.slice(
                                0,
                                state.navItemStack[action.payload.zone_id]
                                    .currentPointer + 1
                            ),
                            action.payload,
                        ],
                    };
                }
                state.navItemStack[action.payload.zone_id] = {
                    ...state.navItemStack[action.payload.zone_id],
                    currentPointer: ++state.navItemStack[action.payload.zone_id]
                        .currentPointer,
                };
            }
        },
        setItemLinks: (state, action) => {
            if (action.payload?.links && action.payload?.links?.length > 0) {
                if (action?.payload?.s3Prefix) {
                    const links = [...action.payload.links];
                    state.itemLinks = mapLinks(links, action.payload.s3Prefix);
                } else {
                    state.itemLinks = action.payload.links;
                }
            }
        },
        clearItemLinks: (state, action) => {
            state.itemLinks = [];
        },
    },
    extraReducers: {
        [loadZone.pending]: (state) => {
            state.loadingMessage = null;
            state.previousLoadingStatus = state.loadingStatus;
            state.loadingStatus = LoadingStatus.Loading;
        },
        [loadZone.fulfilled]: (state, action) => {
            // state.itemLinks = [];
            // action.payload?.items?.forEach(async (item) => {
            //     state.itemLinks = [...state.itemLinks, ...item.links];
            // });
            const data = action.payload;
            if (data?.zone_id) {
                state.loadedZone = data.zone_id;
                state.navItemStack[data?.zone_id] = {
                    currentPointer: -1,
                    items: data.items,
                    nav_links: data.nav_links,
                    stack: [],
                };
            }
            state.loadingMessage = null;
            state.loadingStatus = LoadingStatus.Loaded;
        },
        [loadZone.rejected]: (state, action) => {
            state.loadingMessage = `Could not load zone ${action.meta.arg.zone_id}`;
            state.loadingStatus = state.previousLoadingStatus;
        },
    },
});

export const selectZoneLoaded = (state) => state.loadZone.loadedZone;
export const selectZoneNavItemsStack = (state) => state.loadZone.navItemStack;
export const selectZoneLoadingStatus = (state) => state.loadZone.loadingStatus;
export const selectZoneLoadingMessage = (state) =>
    state.loadZone.loadingMessage;
export const getZoneMediaItems = (state) => state.loadZone.itemLinks;

export const {
    updateNavItemStack,
    setStackPointer,
    addItemToUndoStack,
    addNavLinkToUndoStack,
    setItemLinks,
    clearItemLinks,
} = loadZoneSlice.actions;

export default loadZoneSlice.reducer;
