import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    sortContainer: {
        display: 'flex',
        marginTop: '16px',
        flexWrap: 'wrap',
    },
    actionBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },
    newsFlashTypeIcon: {
        padding: '10px !important',
        borderRadius: '25px !important',
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
    },
    newsFlashContent: {
        flexGrow: 1,
        padding: '25px 50px',
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh)',
        padding: '25px 50px',
        overflow: 'auto',
    },
    createNewsFlash: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    createNewsFlashTitle: {
        fontSize: '24px',
        fontWeight: 300,
        color: '#BDC3C7',
        marginBottom: '20px',
        textAlign: 'center',
    },
    createNewsFlashImage: {
        marginTop: '30px',
        height: '200px',
    },
    newsFlashFilter: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        alignItems: 'flex-start',
    },
    sortButton: {
        position: 'absolute',
        right: '0',
        top: '50%',
        transform: 'translateY(-50%)',
    },
}));

export default useStyles;
