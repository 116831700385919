import { makeStyles } from '@mui/styles';

const searchMenuStyles = makeStyles((theme) => ({
    searchMenu: {
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
        },
        position: 'relative',
        padding: '1px 3px',
        display: 'flex',
        flex: 'none',
        maxWidth: '200px',
        height: 'fit-content',
        zIndex: '2',
        pointerEvents: 'all',
        margin: 'auto 0',
        backgroundColor: '#8f8f8f',
    },
    controlIcon: {
        padding: '3px',
        display: 'flex',
        pointerEvents: 'all',
        marginLeft: 'auto',
    },
}));

export { searchMenuStyles };
