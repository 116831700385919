import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Button,
    FormControl,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Tooltip,
    RadioGroup,
    FormControlLabel,
    Radio,
    Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeCommonFormPreviewStyles } from '../styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    getInitialsData,
    setInitial,
    setChecklistData
} from 'GeminiViewerComponent/components/Procedure/_features/checklistDataSlice';
import { useFormikContext } from 'formik';
import { ImageAttachments } from '../../ImageAttachments';

const InitialField = ({
    data,
    inGroup = false,
    errors,
    setErrors,
    fieldValue,
    setFieldValue,
    isReadOnly = false,
    isSubmitAllow = true,
    handleClick = () => {},
    handleBlur,
}) => {
    const { validateField } = useFormikContext();
    const initialListRef = useRef([]);
    const setInitialListRef = useRef(false);
    const dispatch = useDispatch();
    const initials = useSelector(getInitialsData);
    const theme = useSelector(selectActiveTheme);
    const formStyles = makeCommonFormPreviewStyles(theme);
    const [openInitialForm, setOpenInitialForm] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [inputError, setInputError] = useState(false);
    const [previousValue, setPreviousValue] = useState('');

    useEffect(() => {
        setInputValue(fieldValue[data.variable]);
    }, [fieldValue, data.variable]);

    useEffect(() => {
        if (!openInitialForm && setInitialListRef.current) {
            if (
                Array.isArray(initialListRef.current) &&
                initialListRef.current?.length > 0
            ) {
                dispatch(setInitial(initialListRef.current));
            }
            setInitialListRef.current = false;
        }
        handleBlur?.(fieldValue);
    }, [openInitialForm]);

    useEffect(() => {
        initialListRef.current = initials;
    }, [initials]);

    const handleAdd = () => {
        if (inputValue.trim()) {
            if (inputValue.trim()?.length !== 2) {
                setInputError('Only 2 letters are allowed.');
            } else {
                const regex = /^[A-Z]*$/;
                if (regex.test(inputValue)) {
                    const currentErrors = errors;
                    delete currentErrors?.[data.variable];
                    setErrors(currentErrors);
                    setFieldValue(data.variable, inputValue);
                    let result = {};
                    result[data.variable] = [inputValue];
                    dispatch(setChecklistData(result));
                    initialListRef.current = [
                        ...new Set(
                            Array.isArray(initialListRef.current)
                                ? [...initialListRef.current, inputValue]
                                : [inputValue]
                        ),
                    ];
                    setInitialListRef.current = true;
                    setInputError(false);
                    setPreviousValue('');
                    setOpenInitialForm(false);
                } else {
                    setInputError('Only letters are allowed.');
                }
            }
        } else {
            setInitialListRef.current = true;
            setInputError(false);
            setFieldValue(data.variable, '');
            setPreviousValue('');
            setOpenInitialForm(false);
        }
        validateField(data.variable);
    };

    const handleCancel = useCallback(() => {
        setOpenInitialForm(false);
        setFieldValue(data.variable, previousValue);
        setPreviousValue('');
    }, [fieldValue]);

    const dialogButton = (
        <Button
            fullWidth
            className={formStyles.button}
            onClick={(e) => isSubmitAllow && setOpenInitialForm(true)}
            variant="contained"
            color="primary"
            disabled={isReadOnly}
        >
            X
        </Button>
    );

    const initialButton =
        data?.hide_label === true ? (
            dialogButton
        ) : (
            <Tooltip title={data?.label}>{dialogButton}</Tooltip>
        );

    const initialData = (
        <TextField
            margin="dense"
            fullWidth
            value={fieldValue[data.variable]}
            className={'initial-text'}
            label={data?.hide_label === true ? '' : data?.label}
            placeholder={data?.hide_label === true ? '' : data?.label}
            type="text"
            variant="standard"
            disabled={isReadOnly}
            sx={{
                '& .Mui-disabled': {
                    WebkitTextFillColor: '#000000 !important',
                },
            }}
            onClick={() => isSubmitAllow && setOpenInitialForm(!isReadOnly)}
            inputProps={{
                style: {
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: '#000000 !important',
                    fontFamily: 'inherit',
                    fontStyle: 'inherit',
                },
                readOnly: true,
            }}
        />
    );

    const showComponent = (
        <FormControl
            style={{
                padding: '5px',
                width: data?.field_width ? data.field_width + '%' : '100%',
            }}
        >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                onClick={(e) => {
                    if (!isSubmitAllow) {
                        e.stopPropagation();
                        handleClick(e, data);
                    }
                }}
            >
                {fieldValue[data.variable] ? initialData : initialButton}

                {data.image_attachments_allow && (
                    <ImageAttachments
                        data={data}
                        var_name={data.variable}
                        level="field"
                        isReadOnly={!isSubmitAllow}
                        handleClick={handleClick}
                    />
                )}
            </Box>
            {errors?.[data?.variable] && (
                <div className={formStyles.formErrorDiv}>
                    {errors?.[data?.variable]}
                </div>
            )}
        </FormControl>
    );
    const value = fieldValue[data.variable] || '';

    return (
        <>
            {inGroup ? (
                showComponent
            ) : (
                <div style={{ width: '100%' }}>{showComponent}</div>
            )}
            <Dialog
                open={openInitialForm}
                onClose={() => {
                    setPreviousValue('');
                    setOpenInitialForm(false);
                }}
            >
                <DialogTitle>Add a new initial</DialogTitle>
                <DialogContent sx={{ width: '400px' }}>
                    <DialogContentText marginBottom={'5px'}>
                        Add a new item in initial list.
                    </DialogContentText>
                    <TextField
                        fullWidth
                        autoFocus
                        margin="dense"
                        value={value}
                        onChange={(event) => {
                            if (isSubmitAllow) {
                                const isExisting =
                                    initialListRef.current &&
                                    initialListRef.current?.length > 0 &&
                                    initialListRef.current.indexOf(
                                        fieldValue[data.variable]
                                    ) > -1;
                                if (!previousValue && isExisting) {
                                    setPreviousValue(fieldValue[data.variable]);
                                }
                                setFieldValue(
                                    data.variable,
                                    event.target.value.toUpperCase()
                                );
                                let result = {};
                                result[data.variable] = [event.target.value.toUpperCase()];
                                dispatch(setChecklistData(result));
                            }
                        }}
                        label={data?.hide_label === true ? '' : data?.label}
                        placeholder={
                            data?.hide_label === true ? '' : data?.label
                        }
                        error={!!inputError}
                        helperText={inputError}
                        type="text"
                        variant="outlined"
                    />
                    {initialListRef.current?.length > 0 && (
                        <RadioGroup
                            style={{ display: 'flex', flexDirection: 'row' }}
                            value={fieldValue[data.variable]}
                            onChange={(e) => {
                                if (isSubmitAllow === true) {
                                    const currentErrors = errors;
                                    delete currentErrors?.[data?.variable];
                                    setErrors(currentErrors);
                                    setFieldValue(
                                        data.variable,
                                        e.target.value
                                    );
                                    let result = {};
                                    result[data.variable] = [e.target.value];
                                    dispatch(setChecklistData(result));
                                    setInitialListRef.current = true;
                                    setInputError(false);
                                    setPreviousValue('');
                                    setOpenInitialForm(false);
                                    validateField(data?.variable);
                                }
                            }}
                        >
                            {initialListRef.current?.map((intl, idx) => (
                                <FormControlLabel
                                    key={`initials-${idx}`}
                                    value={intl}
                                    control={<Radio color="primary" />}
                                    label={intl}
                                />
                            ))}
                        </RadioGroup>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        className={formStyles.cancel}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleAdd} className={formStyles.submit}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default InitialField;
