import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    footer: {
        backgroundColor: '#bec3c7',
        marginTop: '5px',
        padding: '5px 0px',
    },
    footerContent: {
        textTransform: 'uppercase',
        color: '#5f6468',
        fontWeight: 'normal',
        fontSize: '12px',
        textAlign: 'center',
    },
}));

export { useStyles };
