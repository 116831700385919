import React, { useState } from 'react';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { Action } from '_helpers';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { Divider } from '@mui/material';
import { ZoneSelectPopup } from 'components/_Misc/PopupActions/components/ZoneSelectPopup';
import { useStyles } from '../PopupAction/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { useSnackbar } from 'notistack';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { addItemToZone } from '_features/items/itemsTableSlice';
import {
    selectActiveAsset,
    setZonesOnActiveAsset,
} from 'GeminiViewerComponent/_features/asset/assetSlice';
import { CopyToZonesDialog } from '../ZoneSelectPopup/CopyToZonesDialog';
import getActionIcon from 'GeminiViewerComponent/_helpers/getActionIcon';
import { fetchZonesRange } from '_features/zones/zonesTableSlice';

function ItemPopup({ object, level, onClose, readOnlyMode }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [showZoneSelect, setShowZoneSelect] = useState(false);
    const [openCopyZoneModal, setOpenCopyZoneModel] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const asset = useSelector(selectActiveAsset);

    const zonesWithoutItem = asset.compact_zones.filter(
        (zone) => !object.zones.includes(zone.zone_id)
    );
    const INIT_ZONES_PAYLOAD = {
        searchString: '',
        assetId: asset?.asset_id,
        startIndex: 0,
        stopIndex: 19,
        includeItems: true,
    };
    const getZoneData = async (payload, setInitZones = false) => {
        const response = await dispatch(
            fetchZonesRange({
                ...payload,
                assetId: asset.asset_id,
                startIndex: 0,
                stopIndex: 19,
            })
        );
        if (setInitZones) {
            await dispatch(
                setZonesOnActiveAsset({
                    assetId: asset.asset_id,
                    zoneInfo: { zones: response.payload?.zones },
                })
            );
        }
    };
    const onZoneSelected = async (zone) => {
        if (zone) {
            dispatch(activateLoading());
            const result = await dispatch(
                addItemToZone({
                    zoneId: zone.zone_id,
                    itemId: object.item_id,
                })
            );
            dispatch(deactivateLoading());
            if (!result.error) {
                const { message, variant } = snackbarHandler(
                    'fulfilled',
                    'Copy item'
                );
                enqueueSnackbar(message, {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: variant,
                });
                getZoneData({ ...INIT_ZONES_PAYLOAD }, true);
            } else {
                dispatch(deactivateLoading());
                enqueueSnackbar('Copy Failed', {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'error',
                });
            }
        }
        setShowZoneSelect(false);
        onClose();
    };

    return (
        <React.Fragment>
            <PopupAction
                action={Action.Edit}
                object={object}
                level={level}
                showLabel={true}
                onActionHandled={onClose}
            />
            {readOnlyMode !== true && (
                <>
                    <Divider />
                    <PopupAction
                        action={Action.Duplicate}
                        object={object}
                        level={level}
                        showLabel={true}
                        onActionHandled={onClose}
                    />
                    <Divider />
                    <div
                        className={classes.popupActionContainer}
                        onClick={(event) => {
                            if (zonesWithoutItem.length > 0) {
                                setAnchorEl(event.target);
                                setShowZoneSelect(true);
                            } else {
                                setOpenCopyZoneModel(true);
                            }
                        }}
                    >
                        <div className={classes.popupActionIcon}>
                            {getActionIcon(Action.CopyToZone.iconId)}
                        </div>
                        <div className={classes.popupActionLabel}>
                            {Action.CopyToZone.displayLabel}
                        </div>
                    </div>
                    <Divider />
                    {showZoneSelect && (
                        <>
                            <ZoneSelectPopup
                                item={object}
                                anchorEl={anchorEl}
                                onZoneSelected={onZoneSelected}
                                zonesWithoutItem={zonesWithoutItem}
                            />
                            <Divider />
                        </>
                    )}
                    <PopupAction
                        action={Action.Delete}
                        object={object}
                        level={level}
                        showLabel={true}
                        onActionHandled={onClose}
                    />
                </>
            )}
            <CopyToZonesDialog
                openCopyZoneModal={openCopyZoneModal}
                setOpenCopyZoneModel={setOpenCopyZoneModel}
            />
        </React.Fragment>
    );
}

export { ItemPopup };
