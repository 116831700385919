import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { makeFormStyles } from '../styles';
import SetDirtyForm from 'forms/SetDirtyForm';
import { FormActions } from '_helpers/form-action';
import { FormTextField } from 'components/TextField';
import { UsageDisplay } from 'components/UsageDisplay';
import { closePanel } from '_features/common/formSlice';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';
import {
    setProgress,
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    addNewAudience,
    getAudienceUsage,
    fetchAudienceUsageById,
    getAudienceById,
    editAudience,
} from '_features/audiences/audiencesSlice';

const AudienceForm = ({ formAction, audienceId }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    // eslint-disable-next-line no-unused-vars
    const [loaded, setLoaded] = useState(
        formAction.id === FormActions.Create.id ? true : false
    );
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    // eslint-disable-next-line no-unused-vars
    const INIT_VALUES = {
        display_name: '',
        description: '',
    };
    const [initValues, setInitValues] = useState({
        ...INIT_VALUES,
    });
    const audienceNameRef = useRef(null);
    const audienceUsage = useSelector(getAudienceUsage);

    useEffect(() => {
        if (formAction.id === FormActions.Edit.id) {
            const fetchData = async () => {
                await dispatch(fetchAudienceUsageById(audienceId));
                var objectData = await dispatch(getAudienceById(audienceId));
                var fetchedObject = objectData.payload;
                setInitValues({
                    display_name: fetchedObject.display_name,
                    description: fetchedObject.description,
                });
                setLoaded(true);
            };
            fetchData();
        } else {
            setInitValues({ ...INIT_VALUES });
        }
    }, [audienceId]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        dispatch(activateLoading({ showProgress: true }));
        var resultAction = null;

        switch (formAction.id) {
            case FormActions.Edit.id:
                resultAction = await dispatch(
                    editAudience({ audienceId, audienceUpdates: { ...values } })
                );
                if (!resultAction.error) {
                    dispatch(closePanel({ formKey: 'audienceForm' }));
                }
                break;
            case FormActions.Create.id:
                dispatch(
                    setProgress({
                        progress: 0,
                        label: 'Step 1 of 2: Uploading',
                        noAnimation: true,
                    })
                );
                resultAction = await dispatch(
                    addNewAudience({ ...values, dispatch })
                );
                if (!resultAction.error) {
                    resetForm();
                    if (audienceNameRef.current) {
                        audienceNameRef.current.focus();
                    }
                }
                break;
            default:
                break;
        }
        await dispatch(closePanel({ formKey: 'audienceForm' }));
        dispatch(deactivateLoading());
        const { message, variant } = snackbarHandler(
            resultAction.meta.requestStatus,
            formAction.label
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });
        setSubmitting(false);
    };

    return !loaded ? (
        <LoadingLogo styleClassName={classes.loadingSvg} />
    ) : (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            validationSchema={Yup.object({
                display_name: Yup.string()
                    .max(150, 'Must be 150 characters or less')
                    .required('Required'),
                description: Yup.string()
                    .max(255, 'Must be 255 characters or less')
                    .required('Required'),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values, setSubmitting, resetForm);
            }}
        >
            {({ isSubmitting }) => (
                <Form className={classes.form}>
                    <div className={classes.formHeader}>Audience Details</div>
                    <FormTextField
                        autoFocus
                        inputRef={audienceNameRef}
                        label="Audience Name"
                        name="display_name"
                        placeholder="Audience Name"
                    />
                    <FormTextField
                        label="Description"
                        name="description"
                        placeholder="Description"
                    />
                    <SetDirtyForm />
                    <button
                        className={classes.submit}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {formAction.buttonLabel}
                    </button>

                    {formAction.id === FormActions.Edit.id &&
                        Object.keys(audienceUsage ?? {})?.length > 0 && (
                            <UsageDisplay
                                data={audienceUsage}
                                listHeader="Audience Locations"
                                id="group-usage-id"
                            />
                        )}
                </Form>
            )}
        </Formik>
    );
};

export { AudienceForm };
