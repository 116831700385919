import { IconButton, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MdDelete } from 'react-icons/md';

import { getChecklistData } from 'GeminiViewerComponent/components/Procedure/_features/checklistDataSlice';

const AttachmentCard = ({
    attachImage,
    index,
    var_name,
    selectedImages,
    setSelectedImages,
}) => {
    const [imgName, setImageName] = useState('');
    const checklistData = useSelector(getChecklistData);

    useEffect(() => {
        setImageName(attachImage?.name || '');
    }, [attachImage]);

    const handleRemove = useCallback(() => {
        let currentImages = selectedImages.filter((img, idx) => idx !== index);
        setSelectedImages(currentImages);
    }, [index, checklistData, selectedImages, var_name]);

    return (
        <div style={{ width: '200px' }}>
            <div
                style={{
                    position: 'relative',
                    border: 'solid 1px lightgray',
                    width: '200px',
                    height: '200px',
                    boxSizing: 'content-box',
                    overflow: 'hidden',
                }}
            >
                <img
                    src={attachImage?.url}
                    alt={attachImage?.name}
                    style={{ objectFit: 'contain' }}
                    width={'200px'}
                    height={'200px'}
                />
                <IconButton
                    onClick={handleRemove}
                    aria-label="close"
                    sx={{ position: 'absolute', right: 0, top: 0, padding: 0 }}
                    size="large"
                >
                    <div
                        style={{
                            fontSize: '12px',
                            margin: 0,
                            color: '#000000',
                            backgroundColor: '#ffffff',
                        }}
                    >
                        <MdDelete className="react-icon" />
                    </div>
                </IconButton>
            </div>
            <div>
                <TextField
                    fullWidth
                    margin="normal"
                    value={imgName}
                    onChange={(e) => setImageName(e.target.value)}
                    onBlur={(e) => {
                        let currentImages = selectedImages;
                        currentImages = currentImages.map((img, idx) => {
                            if (index === idx) {
                                return {
                                    ...attachImage,
                                    name: e.target.value,
                                    isSaved: false,
                                };
                            }
                            return img;
                        });
                        setSelectedImages(currentImages);
                    }}
                    type="text"
                    variant="outlined"
                    inputProps={{
                        style: {
                            padding: '3px',
                            border: '1.5px solid #000000',
                            borderRadius: '5px',
                        },
                    }}
                />
            </div>
        </div>
    );
};

export { AttachmentCard };
