import { makeStyles } from '@mui/styles';

const sceneButtonStyles = makeStyles(() => ({
    imageHoverContainer: {
        '&:hover .normal': {
            transform: 'scale(1.08)',
        },
    },
    sceneButton: {
        width: 'auto',
        height: '100%',
    },
    sceneImg: {
        height: '100%',
        width: 'auto',
    },
    sceneImgFixedWidth: {
        height: '100%',
        width: '34px',
    },
    zoneMediaBadge: {
        position: 'absolute',
        top: '50%',
        right: 0,
        pointerEvents: 'none',
        transform: 'translate(-3px , 0px)',
        '& .MuiBadge-badge': {
            backgroundColor: (props) => props.colors.secondarySectionBackground,
            fontWeight: 'bold',
            border: '1px solid #FFFFFF',
        },
    },
}));

export { sceneButtonStyles };
