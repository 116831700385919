import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { MdAccountTree, MdContentPaste } from 'react-icons/md';
import {
    Box,
    Paper,
    styled,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Tooltip,
} from '@mui/material';

import { NodeContentCell, NodeNumberCell } from './NodeTable';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { useFilterContentProcedureNodes } from 'GeminiViewerComponent/hooks/useFilterContentProcedureNodes';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    verticalAlign: 'top',
}));

const makeNodeTableStyles = makeStyles(() => ({
    rightButtons: {
        marginLeft: 'auto',
        display: 'flex',
        gap: '5px',
    },
}));

const NodeTableViewOnly = ({
    procedure,
    contentVersions = [],
    selectedVersion = null,
    nodes,
    width = '100%',
    content = null,
}) => {
    const theme = useSelector(selectActiveTheme);
    const nodeTableStyles = makeNodeTableStyles(theme);

    const [sortedNodes, setSortedNodes] = useState(nodes);

    const getContentVersion = (
        procedureId,
        selectedVersion,
        contentVersions
    ) => {
        if (procedureId) {
            return contentVersions.find(
                (version) => version.procedure_id === procedureId
            );
        }
        if (selectedVersion) {
            return contentVersions.find(
                (version) => version.version === selectedVersion
            );
        }
        return [];
    };

    const contentVersion = getContentVersion(
        procedure?.procedure_id,
        selectedVersion,
        contentVersions
    );

    const filteredNodes = useFilterContentProcedureNodes(contentVersion, nodes);

    useEffect(() => {
        if (content && nodes && contentVersions.length > 0) {
            let contentVersion = [];
            if (procedure.procedure_id) {
                contentVersion = contentVersions.find(
                    (version) => version.procedure_id === procedure.procedure_id
                );
            }
            if (selectedVersion) {
                contentVersion = contentVersions.find(
                    (version) => version.version === selectedVersion
                );
            }
            if (contentVersion) {
                setSortedNodes(filteredNodes);
            } else {
                setSortedNodes(nodes);
            }
        } else {
            setSortedNodes(nodes);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodes, procedure, selectedVersion]);

    const [compactMode, setCompactMode] = useState(true);
    const [showBranches, setShowBranches] = useState(false);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    gap: '5px',
                    flex: '0',
                    marginBottom: '5px',
                    width: width,
                }}
            >
                <div className={nodeTableStyles.rightButtons}>
                    <Tooltip title="Toggle Content Display">
                        <Box>
                            <MdContentPaste
                                className="react-icon"
                                style={{
                                    cursor: 'pointer',
                                    color: `${!compactMode ? 'blue' : 'unset'}`,
                                }}
                                onClick={() => {
                                    setCompactMode(!compactMode);
                                }}
                            />
                        </Box>
                    </Tooltip>
                    <Tooltip title="Toggle Branch Display">
                        <Box>
                            <MdAccountTree
                                className="react-icon"
                                style={{
                                    cursor: 'pointer',
                                    color: `${showBranches ? 'blue' : 'unset'}`,
                                }}
                                onClick={() => {
                                    setShowBranches(!showBranches);
                                }}
                            />
                        </Box>
                    </Tooltip>
                </div>
            </div>
            <Paper style={{ overflow: 'auto', width: width }}>
                <TableContainer>
                    <Table
                        //sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size="small"
                    >
                        <TableBody>
                            {sortedNodes?.map((node, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <StyledTableRow
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={node.id}
                                    >
                                        <NodeNumberCell
                                            procedure={procedure}
                                            node={node}
                                            labelId={labelId}
                                            showHome={true}
                                        />
                                        <NodeContentCell
                                            nodes={sortedNodes}
                                            node={node}
                                            compactMode={compactMode}
                                            showBranches={showBranches}
                                        />
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
};

export default NodeTableViewOnly;
