// eslint-disable-next-line
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Checkbox,
    MenuItem,
    TextField,
    Autocomplete,
    Tooltip,
    IconButton,
} from '@mui/material';
import {
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdFilterAlt,
} from 'react-icons/md';

import {
    getTagFilters,
    setTagFilters,
    getShowOnlyByFilter,
    setShowOnlyByFilter,
} from '../../_features/globals/visibilitySlice';
import SearchFilter from '../SearchFilter';
import { searchMenuStyles } from './styles';
import { getSearchResults } from 'GeminiViewerComponent/_features/globals/searchSlice';
import { getAllTags } from 'GeminiViewerComponent/_features/asset/assetSlice';
import { MultiSelectDoneButton } from 'GeminiViewerComponent/components/MultiSelectDoneButton';
import { setItemLinks } from 'shared/loadZoneSlice';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';

const SearchMenu = ({ onSearchItems, searchItemSelected }) => {
    const dispatch = useDispatch();

    const classes = searchMenuStyles();

    const [filterBadge, setFilterBadge] = useState(0);

    const tagFilters = useSelector(getTagFilters);

    const [tagFiltersView, setTagFiltersView] = React.useState(tagFilters);

    const allTags = useSelector(getAllTags);
    const searchResults = useSelector(getSearchResults);
    const showOnlyByFilter = useSelector(getShowOnlyByFilter);

    const debouncedSearch = useCallback(
        debounce((searchString) => {
            onSearchItems(searchString);
        }, 1000)
    );

    const countFilters = (filters) => {
        let badgeValue = 0;
        if (filters.zone_tags.length > 0) {
            ++badgeValue;
        }
        if (filters.item_tags.length > 0) {
            ++badgeValue;
        }
        return badgeValue;
    };

    useEffect(() => {
        setTagFiltersView(tagFilters);
        setFilterBadge(countFilters(tagFilters));
    }, [tagFilters]);

    const handleSearch = (searchString) => {
        debouncedSearch(searchString);
    };

    const clearFilters = () => {
        const resetTags = {
            zone_tags: [],
            item_tags: [],
        };
        dispatch(setTagFilters(resetTags));
        setTagFiltersView(resetTags);
        setFilterBadge(0);
    };

    const addFilters = () => {
        dispatch(setItemLinks({ links: [] }));
        dispatch(setTagFilters(tagFiltersView));
        setFilterBadge(countFilters(tagFiltersView));
    };

    const updateInputValue = (evt) => {
        var searchString = evt.target.value;
        handleSearch(searchString);
    };

    return (
        <div className={classes.searchMenu}>
            <SearchFilter
                filterBadge={filterBadge}
                onChange={(evt) => updateInputValue(evt)}
                addFilters={addFilters}
                clearFilters={clearFilters}
                searchResults={searchResults}
                searchItemSelected={searchItemSelected}
            >
                <MenuItem disableRipple>
                    <Autocomplete
                        limitTags={2}
                        multiple
                        variant="outline"
                        id="checkboxes-zone-tags-demo"
                        disableCloseOnSelect
                        options={allTags}
                        value={tagFiltersView.zone_tags}
                        onChange={(event, newInputValue) => {
                            setTagFiltersView({
                                ...tagFiltersView,
                                zone_tags: newInputValue,
                            });
                        }}
                        style={{ width: '100%' }}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={
                                        <MdCheckBoxOutlineBlank className="react-icon" />
                                    }
                                    checkedIcon={
                                        <MdCheckBox className="react-icon" />
                                    }
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Zone Tags"
                                placeholder="Zone Tags"
                            />
                        )}
                        PaperComponent={MultiSelectDoneButton}
                    />
                </MenuItem>
                <MenuItem disableRipple>
                    <Autocomplete
                        limitTags={2}
                        multiple
                        variant="outline"
                        id="checkboxes-item-tags-demo"
                        disableCloseOnSelect
                        options={allTags}
                        value={tagFiltersView.item_tags}
                        onChange={(event, newInputValue) => {
                            setTagFiltersView({
                                ...tagFiltersView,
                                item_tags: newInputValue,
                            });
                        }}
                        isOptionEqualToValue={(option, value) =>
                            option.tag_id === value.tag_id
                        }
                        style={{ width: '100%' }}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={
                                        <MdCheckBoxOutlineBlank className="react-icon" />
                                    }
                                    checkedIcon={
                                        <MdCheckBox className="react-icon" />
                                    }
                                    style={{
                                        marginRight: 8,
                                    }}
                                    checked={selected}
                                />
                                {option.name}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Item Tags"
                                placeholder="Item Tags"
                            />
                        )}
                        PaperComponent={MultiSelectDoneButton}
                    />
                </MenuItem>
                <MenuItem style={{ justifyContent: 'center' }}>
                    <Tooltip
                        title={
                            !showOnlyByFilter === true
                                ? 'Inclusive'
                                : 'Exclusive'
                        }
                    >
                        <IconButton
                            variant="contained"
                            size="small"
                            onClick={() => {
                                dispatch(setShowOnlyByFilter());
                            }}
                            style={{
                                color: `${
                                    !showOnlyByFilter ? '#CCCCCC' : '#000000'
                                }`,
                            }}
                        >
                            <MdFilterAlt className="react-icon" />
                        </IconButton>
                    </Tooltip>
                </MenuItem>
            </SearchFilter>
        </div>
    );
};

export { SearchMenu };
