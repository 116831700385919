import React, { useState } from 'react';
import { Box, Button, Chip, Dialog, DialogContent } from '@mui/material';
import { makeFormStyles } from 'forms/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { accountsSlice } from 'app/store';
import Dropzone from 'react-dropzone';
import { CheckboxWithLabel } from 'formik-mui';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { MdClose } from 'react-icons/md';

import { panelStyles } from 'GeminiViewerComponent/components/styles';
import {
    uploadContentImportCsv,
    getContentImportSampleCsv,
    resetContentStatus,
} from '_features/content/contentSlice';
import { setConfigValuesAssetId } from '_features/configValues/configValuesSlice';
import { setIsDirty } from '_features/common/formSlice';
import { SelectionAutocomplete } from 'components';
import {
    getAssetsPicklist,
    selectAssetsPicklist,
    selectAssetsPicklistLoadingStatus,
} from '_features/picklists/pickListsSlice';
import { BulkImportResultDisplay } from 'components/BulkImportResultDisplay';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { LinkTypes } from '_helpers';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { FormSwitchField, FormTextField } from 'components/TextField';

const ContentBulkImport = ({ openForm, setOpenForm }) => {
    const theme = useSelector(selectActiveTheme);
    const formClasses = makeFormStyles(theme);
    const panelClasses = panelStyles(theme);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    const [lastImportResult, setLastImportResult] = useState(null);

    const init = {
        global: true,
        file: null,
        allow_duplicates: true,
    };

    const getContentsImportCSVSample = async () => {
        dispatch(activateLoading());
        await dispatch(getContentImportSampleCsv());
        dispatch(deactivateLoading());
    };

    const handleImport = async (formData) => {
        dispatch(activateLoading());
        const response = await dispatch(uploadContentImportCsv(formData));
        dispatch(deactivateLoading());
        if (response?.payload) {
            setLastImportResult(response.payload);
        }
        if (response?.payload?.error?.message) {
            setLastImportResult(null);
            enqueueSnackbar(response.payload.error.message, {
                action: (key) => <SnackbarDismiss key={key} />,
                persist: true,
                variant: 'error',
                style: { whiteSpace: 'pre-line' },
            });
            return;
        }
        if (response?.payload?.imported) {
            enqueueSnackbar(
                `${response.payload.imported} Contents Imported Successfully`,
                {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'success',
                }
            );
            dispatch(resetContentStatus());
        }
        if (
            !(
                response?.payload?.errors &&
                typeof response?.payload?.errors === 'object' &&
                !Array.isArray(response?.payload?.errors) &&
                Object.keys(response?.payload?.errors ?? {})?.length > 0
            ) &&
            !(
                response?.payload?.duplicates &&
                Array.isArray(response?.payload?.duplicates) &&
                response?.payload?.duplicates?.length > 0
            )
        ) {
            setLastImportResult(null);
            setOpenForm(false);
        }
    };

    const onDropError = (err) => {
        if (err?.[0]?.errors?.[0]?.message) {
            enqueueSnackbar(err[0].errors[0].message, {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
        }
    };

    return (
        openForm && (
            <Dialog open={openForm}>
                <DialogContent sx={{ width: '600px', p: 0 }}>
                    <Box
                        sx={{
                            width: '600px',
                            pt: 2,
                            px: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            fontSize: '20px',
                        }}
                    >
                        Content Bulk Import
                        <MdClose
                            className="react-icon"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setLastImportResult(null);
                                setOpenForm(false);
                            }}
                        />
                    </Box>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{ ...init }}
                        validationSchema={Yup.object().shape({
                            file: Yup.mixed()
                                .nullable(false)
                                .required('Required'),
                        })}
                        onSubmit={(values) => handleImport(values)}
                    >
                        {({ values, setFieldValue, isSubmitting }) => (
                            <Form className={formClasses.form}>
                                <FormSwitchField
                                    label="Visible to all assets"
                                    checked={values.global}
                                    onChange={async (e) => {
                                        setFieldValue(
                                            'global',
                                            e.target.checked,
                                            false
                                        );
                                        if (e.target.checked === true) {
                                            setFieldValue('asset_id', '');
                                            dispatch(
                                                setConfigValuesAssetId({
                                                    assetId: 0,
                                                    clientId:
                                                        activeUser?.client_id,
                                                })
                                            );
                                            dispatch(setIsDirty(false));
                                        }
                                    }}
                                    name="global"
                                    disabled={readOnlyMode}
                                />
                                {!values.global && (
                                    <Field
                                        className={formClasses.input}
                                        name="asset_id"
                                    >
                                        {({
                                            field: { value },
                                            form: { setFieldValue },
                                        }) => (
                                            <SelectionAutocomplete
                                                title="Asset assignment"
                                                keyProperty="asset_id"
                                                nameProperty="display_name"
                                                entityIds={value}
                                                setEntityIds={setFieldValue}
                                                entitySelector={
                                                    selectAssetsPicklist
                                                }
                                                fetchEntityPage={
                                                    getAssetsPicklist
                                                }
                                                formField={'asset_id'}
                                                multiSelection={false}
                                                entityStatusSelector={
                                                    selectAssetsPicklistLoadingStatus
                                                }
                                                readOnly={readOnlyMode}
                                            />
                                        )}
                                    </Field>
                                )}
                                <FormTextField
                                    component={CheckboxWithLabel}
                                    Label={{
                                        label: 'Allow duplicates',
                                    }}
                                    label="Allow duplicates"
                                    type="checkbox"
                                    disabled={readOnlyMode}
                                    checked={!!values.allow_duplicates}
                                    onChange={(evt) => {
                                        setFieldValue(
                                            'allow_duplicates',
                                            evt.target.checked
                                        );
                                    }}
                                />
                                <div className={panelClasses.dropzoneContainer}>
                                    <Dropzone
                                        multiple={false}
                                        maxFiles={1}
                                        maxSize={
                                            LinkTypes.Document.fileSizeLimit
                                        }
                                        accept={['text/csv']}
                                        name="file"
                                        onDropAccepted={(file) => {
                                            setFieldValue('file', file[0]);
                                        }}
                                        onDropRejected={onDropError}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div
                                                {...getRootProps()}
                                                className={`${panelClasses.dropzoneArea} ${panelClasses.dropZoneMinHeight50}`}
                                            >
                                                <input {...getInputProps()} />
                                                <div
                                                    className={
                                                        panelClasses.dropzoneText
                                                    }
                                                >
                                                    Drop/Select Csv File
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                    {values?.file && (
                                        <div
                                            className={
                                                panelClasses.previewContainer
                                            }
                                        >
                                            <Chip
                                                className={
                                                    panelClasses.previewUploadFileName
                                                }
                                                color="secondary"
                                                size="small"
                                                deleteIcon={
                                                    <MdClose className="react-icon" />
                                                }
                                                onDelete={() => {
                                                    setFieldValue(`file`, null);
                                                }}
                                                label={values?.file?.name}
                                            />
                                        </div>
                                    )}
                                </div>
                                <ErrorMessage
                                    className={formClasses.inputErr}
                                    name="file"
                                    component="div"
                                />
                                {lastImportResult && (
                                    <BulkImportResultDisplay
                                        result={lastImportResult}
                                    />
                                )}
                                <Button
                                    className={formClasses.submit}
                                    onClick={getContentsImportCSVSample}
                                >
                                    Download Sample CSV
                                </Button>
                                <button
                                    type="submit"
                                    className={formClasses.submit}
                                    disabled={isSubmitting}
                                >
                                    Import
                                </button>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        )
    );
};

export default ContentBulkImport;
