import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { partCategoryService } from '_features/_services';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';

const initialState = {
    partCategories: [],
    partCategoryStatus: LoadingStatus.Idle,
    partCategoryPageInfo: {},
    displayPartCategory: {},
    loadedPartCategoryMap: {},
};

export const fetchPartCategoryPage = createAsyncThunk(
    'partCategory/fetchPartCategoryPage',
    async (params = {}, { getState }) =>
        await partCategoryService.getPartCategories(
            params.searchString,
            params.sort,
            getState().accounts.activeUser.s3_prefix
        )
);

export const fetchPartCategoryById = createAsyncThunk(
    'partCategory/fetchPartCategoryById',
    async (partCategoryId, { getState }) => {
        const response = await partCategoryService.getById(
            partCategoryId,
            getState().accounts.activeUser.s3_prefix
        );
        response.id = partCategoryId;
        return response;
    }
);

export const createPartCategory = createAsyncThunk(
    'partCategory/createPartCategory',
    async (params, { getState }) =>
        await partCategoryService.create(
            params,
            getState().accounts.activeUser.s3_prefix
        )
);

export const updatePartCategory = createAsyncThunk(
    'partCategory/updatePartCategory',
    async (params, { getState }) =>
        await partCategoryService.update(
            params,
            getState().accounts.activeUser.s3_prefix
        )
);

export const deletePartCategory = createAsyncThunk(
    'partCategory/deletePartCategory',
    async ({ part_category_id }) => {
        const response = await partCategoryService.delete(part_category_id);
        return response;
    }
);

const partCategorySlice = createSlice({
    name: 'partCategories',
    initialState,
    reducers: {
        resetPartCategoryState: (state) => initialState,
    },
    extraReducers: {
        [fetchPartCategoryPage.pending]: (state, action) => {
            state.partCategoryStatus = LoadingStatus.Loading;
        },
        [fetchPartCategoryPage.fulfilled]: (state, action) => {
            state.partCategoryPageInfo = action.payload.pageInfo;
            state.partCategories = action.payload.categories;
            state.partCategoryStatus = LoadingStatus.Loaded;
        },
        [fetchPartCategoryPage.rejected]: (state, action) => {
            state.partCategoryStatus = LoadingStatus.Failed;
        },
        [createPartCategory.fulfilled]: (state, action) => {
            state.partCategoryStatus = LoadingStatus.Idle;
        },
        [updatePartCategory.fulfilled]: (state, action) => {
            state.partCategoryStatus = LoadingStatus.Idle;
        },
        [deletePartCategory.fulfilled]: (state, action) => {
            state.partCategoryStatus = LoadingStatus.Idle;
        },
        [fetchPartCategoryById.fulfilled]: (state, action) => {
            state.displayPartCategory = action.payload;
        },
    },
});

export const selectPartCategories = (state) =>
    state.partCategory.partCategories;
export const getPartCategoryStatus = (state) =>
    state.partCategory.partCategoryStatus;
export const getPartCategoryPageInfo = (state) =>
    state.partCategory.partCategoryPageInfo;

export const { resetPartCategoryState } = partCategorySlice.actions;

export default partCategorySlice.reducer;
