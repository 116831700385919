import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CategoryTable } from './CategoryTable';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    fetchAllCategories,
    getAllCategories,
    getCategoryPageInfo,
    getCategoryStatus,
    resetCategoryState,
} from '_features/category/categorySlice';

const CategoryManagementTab = () => {
    //#region Constants
    //#endregion Constants

    //#region Hooks
    const dispatch = useDispatch();
    const ref = useRef(false);
    //#endregion Hooks

    //#region State
    const [categoryPayload, setCategoryPayload] = useState({});
    const [sortBy, setSortBy] = useState('display_name');
    const [sortDirection, setSortDirection] = useState('ASC');
    //#endregion State

    //#region Selectors
    const categories = useSelector(getAllCategories);
    const categoryStatus = useSelector(getCategoryStatus);
    const pageInfo = useSelector(getCategoryPageInfo);
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    useEffect(() => {
        if (ref.current === true) {
            getCategoryData({ ...categoryPayload });
        }
    }, [categoryPayload]);

    useEffect(() => {
        return async () => {
            await dispatch(resetCategoryState());
        };
    }, []);

    useEffect(() => {
        if (categoryStatus === LoadingStatus.Idle) {
            getCategoryData({ ...categoryPayload, page: 1, pageSize: 10 });
        }
        ref.current = true;
    }, [categoryStatus]);
    //#endregion Effects

    //#region Methods
    const getCategoryData = async (payload) => {
        dispatch(activateLoading());
        await dispatch(fetchAllCategories({ ...payload }));
        dispatch(deactivateLoading());
    };

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        if (_sortBy === 'is_active') {
            _sortDirection = _sortDirection === 'ASC' ? 'DESC' : 'ASC';
        }
        setCategoryPayload({
            ...categoryPayload,
            sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
        });
    };
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render
    return (
        <CategoryTable
            categories={categories}
            pageInfo={pageInfo}
            categoryPayload={categoryPayload}
            setCategoryPayload={setCategoryPayload}
            sortDirection={sortDirection}
            sortBy={sortBy}
            handleSort={(_sortBy, _sortDirection) =>
                setSorting(_sortBy, _sortDirection)
            }
        />
    );
    //#endregion Render
};

export { CategoryManagementTab };
