import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    MdAdd,
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdFileDownload,
    MdLibraryAdd,
} from 'react-icons/md';
import {
    MenuItem,
    TextField,
    IconButton,
    Checkbox,
    Box,
    FormControl,
    FormControlLabel,
    Grid,
    Select,
    InputLabel,
    Tooltip,
    Autocomplete,
} from '@mui/material';

import { ViewMode } from 'components';
import contentManagerStyles from '../styles';
import { ContentTypes } from '_helpers/enumerations';
import ContentBulkImport from '../ContentBulkImport';
import { accountsThunk, accountsSlice } from 'app/store';
import MinMaxSelection from 'components/MinMaxSelection';
import SearchWithFilters from 'components/SearchWithFilters';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { openPanel, setEditObject } from '_features/common/formSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { MultiSelectDoneButton } from 'GeminiViewerComponent/components/MultiSelectDoneButton';
import {
    resetContentStatus,
    getContentUsageFiltersData,
} from '_features/content/contentSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { FilterAccordion } from 'components/_Accordions/FilterAccordion';

const { updateUserSettings } = accountsThunk;
const { selectUserSetting } = accountsSlice;

const ContentFilter = ({
    contentPayload,
    setContentPayload,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    searchStringState,
    setSearchStringState,
}) => {
    const theme = useSelector(selectActiveTheme);
    const contentStyles = contentManagerStyles(theme);
    const dispatch = useDispatch();
    const [contentTypeFilter, setContentTypeFilter] = useState([]);
    const [assetUsageId, setAssetUsageId] = useState('');
    const [zoneUsageId, setZoneUsageId] = useState('');
    const [itemUsageId, setItemUsageId] = useState('');
    const [procedureUsageId, setProcedureUsageId] = useState('');
    const [fileSize, setFileSize] = useState([]);
    const [contentWidth, setContentWidth] = useState([]);
    const [contentHeight, setContentHeight] = useState([]);
    const [openBulkImportForm, setOpenBulkImportForm] = useState(false);
    const [noCaptions, setNoCaptions] = useState(false);

    const contentUsageFiltersData = useSelector(getContentUsageFiltersData);
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    const contentViewMode = useSelector((state) =>
        selectUserSetting(state, 'content_view_mode')
    );

    const handleViewModeToggle = async (viewMode) => {
        dispatch(activateLoading());
        await dispatch(
            updateUserSettings({
                setting_key: 'content_view_mode',
                setting_value: viewMode,
            })
        );
        dispatch(deactivateLoading());
    };

    useEffect(() => {
        dispatch(resetContentStatus());
    }, [dispatch]);

    // eslint-disable-next-line
    const debouncedSearch = useCallback(
        debounce((params) => {
            if (params.fileSize) {
                params.minFileSize = params.fileSize[0] * 1024 * 1024;
                params.maxFileSize = params.fileSize[1] * 1024 * 1024;
                delete params.fileSize;
            }
            if (params.contentWidth) {
                params.minContentWidth = params.contentWidth[0];
                params.maxContentWidth = params.contentWidth[1];
                delete params.contentWidth;
            }
            if (params.contentHeight) {
                params.minContentHeight = params.contentHeight[0];
                params.maxContentHeight = params.contentHeight[1];
                delete params.contentHeight;
            }
            setContentPayload({
                ...contentPayload,
                reset: true,
                startIndex: 0,
                stopIndex: 19,
                contentTypeIds: contentTypeFilter.map((item) => item.id),
                searchString: searchStringState,
                assetUsageId,
                zoneUsageId,
                itemUsageId,
                procedureUsageId,
                minFileSize: fileSize[0] ? fileSize[0] * 1024 * 1024 : '',
                maxFileSize: fileSize[1] ? fileSize[1] * 1024 * 1024 : '',
                minContentWidth: contentWidth[0],
                maxContentWidth: contentWidth[1],
                minContentHeight: contentHeight[0],
                maxContentHeight: contentHeight[1],
                noCaptions,
                ...params,
            });
        }, 1000),
        [contentPayload]
    );

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        if (_sortBy !== '') {
            setContentPayload({
                ...contentPayload,
                reset: true,
                startIndex: 0,
                stopIndex: 19,
                sort: `${_sortDirection === 'DESC' ? '-' : ''}${
                    _sortBy === 'content_type_id'
                        ? 'content_type_name'
                        : _sortBy
                }`,
            });
        }
    };

    const handleSearch = (searchString) => {
        setSearchStringState(searchString);
        debouncedSearch({ searchString });
    };

    const clearFilters = () => {
        setContentTypeFilter([]);
        setAssetUsageId('');
        setZoneUsageId('');
        setItemUsageId('');
        setProcedureUsageId('');
        setFileSize([]);
        setContentWidth([]);
        setContentHeight([]);
        setNoCaptions(false);
        setContentPayload({
            ...contentPayload,
            reset: true,
            startIndex: 0,
            stopIndex: 19,
            contentTypeIds: [],
            assetUsageId: '',
            zoneUsageId: '',
            itemUsageId: '',
            procedureUsageId: '',
            minFileSize: '',
            maxFileSize: '',
            minContentWidth: '',
            maxContentWidth: '',
            minContentHeight: '',
            maxContentHeight: '',
            noCaptions: false,
        });
    };

    const handleCreateNew = () => {
        dispatch(openPanel({ formKey: `contentForm`, formAction: 'Create' }));
    };

    const handleDownloadContentInventory = useCallback(() => {
        dispatch(
            setEditObject({
                formKey: 'contentInventoryForm',
                editObject: contentPayload,
            })
        );
        dispatch(
            openPanel({
                formKey: 'contentInventoryForm',
                formAction: 'Create',
                clearEditId: true,
            })
        );
    }, [contentPayload, dispatch]);

    return (
        <div className={contentStyles.actionBar} style={{ flexWrap: 'wrap' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flex: { xs: '0 0 100%', md: '0 0 75%' },
                }}
            >
                <ViewMode
                    viewMode={contentViewMode}
                    setViewMode={(viewMode) => handleViewModeToggle(viewMode)}
                />
                {!readOnlyMode && (
                    <div className={contentStyles.contentActionBar}>
                        <div className={contentStyles.contentActionWrapper}>
                            <Tooltip title="Add New Content">
                                <IconButton
                                    onClick={handleCreateNew}
                                    size="large"
                                    className={contentStyles.contentAddButton}
                                >
                                    <MdAdd className="react-icon" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Content Bulk Import">
                                <IconButton
                                    onClick={() => setOpenBulkImportForm(true)}
                                    size="large"
                                    className={contentStyles.contentAddButton}
                                >
                                    <MdLibraryAdd className="react-icon" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Download Content Inventory">
                                <IconButton
                                    onClick={handleDownloadContentInventory}
                                    size="large"
                                    className={contentStyles.contentAddButton}
                                >
                                    <MdFileDownload className="react-icon" />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <ContentBulkImport
                            openForm={openBulkImportForm}
                            setOpenForm={setOpenBulkImportForm}
                        />
                        <FilterAccordion
                            filteredTitles={[]}
                            assetType="content"
                            expandedId="content-filter"
                            onClearFilter={clearFilters}
                            style={{ margin: '0', width: '100%' }}
                        >
                            <Grid container spacing={'1rem'}>
                                {Object.keys(ContentTypes)?.length > 0 && (
                                    <Grid item xs={12} md={6} lg={2}>
                                        <Autocomplete
                                            limitTags={2}
                                            multiple
                                            variant="outline"
                                            id="checkboxes-content-types"
                                            options={Object.values(
                                                ContentTypes
                                            ).toSorted((a, b) =>
                                                a.label.localeCompare(b.label)
                                            )}
                                            onChange={(
                                                event,
                                                newInputValue
                                            ) => {
                                                setContentTypeFilter(
                                                    newInputValue
                                                );
                                                debouncedSearch({
                                                    contentTypeIds:
                                                        newInputValue.map(
                                                            (item) => item.id
                                                        ),
                                                });
                                            }}
                                            PaperComponent={
                                                MultiSelectDoneButton
                                            }
                                            disableCloseOnSelect
                                            value={contentTypeFilter}
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            renderOption={(
                                                props,
                                                option,
                                                { selected }
                                            ) => (
                                                <li
                                                    {...props}
                                                    style={{
                                                        padding: '0',
                                                    }}
                                                >
                                                    <Checkbox
                                                        icon={
                                                            <MdCheckBoxOutlineBlank className="react-icon" />
                                                        }
                                                        checkedIcon={
                                                            <MdCheckBox className="react-icon" />
                                                        }
                                                        style={{
                                                            marginRight: 2,
                                                        }}
                                                        checked={selected}
                                                    />
                                                    {option.label}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Content Type"
                                                    placeholder="Content Type"
                                                />
                                            )}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12} md={6} lg={2}>
                                    <Autocomplete
                                        variant="outline"
                                        id="checkboxes-assets"
                                        options={
                                            contentUsageFiltersData.asset_usage
                                        }
                                        onChange={(event, newInputValue) => {
                                            const assetUsageId =
                                                newInputValue &&
                                                newInputValue?.asset_id
                                                    ? newInputValue?.asset_id
                                                    : '';
                                            setAssetUsageId(assetUsageId);
                                            debouncedSearch({ assetUsageId });
                                        }}
                                        disableCloseOnSelect={false}
                                        value={
                                            contentUsageFiltersData.asset_usage.find(
                                                (asset) =>
                                                    asset.asset_id ===
                                                    assetUsageId
                                            ) ?? null
                                        }
                                        getOptionLabel={(option) =>
                                            option.asset_name
                                        }
                                        renderOption={(
                                            props,
                                            option,
                                            { selected }
                                        ) => (
                                            <li
                                                {...props}
                                                key={`${option.asset_name}-${option.asset_id}`}
                                                style={{
                                                    padding: '6px',
                                                }}
                                            >
                                                {option.asset_name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Asset Usage"
                                                placeholder="Asset Usage"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={2}>
                                    <Autocomplete
                                        variant="outline"
                                        id="checkboxes-zones"
                                        options={
                                            contentUsageFiltersData.zone_usage
                                        }
                                        onChange={(event, newInputValue) => {
                                            const zoneUsageId =
                                                newInputValue &&
                                                newInputValue?.zone_id
                                                    ? newInputValue?.zone_id
                                                    : '';
                                            setZoneUsageId(zoneUsageId);
                                            debouncedSearch({ zoneUsageId });
                                        }}
                                        disableCloseOnSelect={false}
                                        value={
                                            contentUsageFiltersData.zone_usage.find(
                                                (zone) =>
                                                    zone.zone_id === zoneUsageId
                                            ) ?? null
                                        }
                                        getOptionLabel={(option) =>
                                            option.zone_name
                                        }
                                        renderOption={(
                                            props,
                                            option,
                                            { selected }
                                        ) => (
                                            <li
                                                {...props}
                                                key={`${option.zone_name}-${option.zone_id}`}
                                                style={{
                                                    padding: '6px',
                                                }}
                                            >
                                                {option.zone_name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Zone Usage"
                                                placeholder="Zone Usage"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={2}>
                                    <Autocomplete
                                        variant="outline"
                                        id="checkboxes-items"
                                        options={
                                            contentUsageFiltersData.item_usage
                                        }
                                        onChange={(event, newInputValue) => {
                                            let itemUsageId =
                                                newInputValue &&
                                                newInputValue?.item_id
                                                    ? newInputValue?.item_id
                                                    : '';
                                            setItemUsageId(itemUsageId);
                                            debouncedSearch({ itemUsageId });
                                        }}
                                        disableCloseOnSelect={false}
                                        value={
                                            contentUsageFiltersData.item_usage.find(
                                                (item) =>
                                                    item.item_id === itemUsageId
                                            ) ?? null
                                        }
                                        getOptionLabel={(option) =>
                                            option.item_name
                                        }
                                        renderOption={(
                                            props,
                                            option,
                                            { selected }
                                        ) => (
                                            <li
                                                {...props}
                                                key={`${option.item_name}-${option.item_id}`}
                                                style={{
                                                    padding: '6px',
                                                }}
                                            >
                                                {option.item_name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Item Usage"
                                                placeholder="Item Usage"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={2}>
                                    <Autocomplete
                                        variant="outline"
                                        id="procedure-usage"
                                        options={
                                            contentUsageFiltersData.procedure_usage
                                        }
                                        onChange={(event, value) => {
                                            const procedureUsageId =
                                                value?.procedure_id || '';
                                            setProcedureUsageId(
                                                procedureUsageId
                                            );
                                            debouncedSearch({
                                                procedureUsageId,
                                            });
                                        }}
                                        disableCloseOnSelect={false}
                                        value={
                                            contentUsageFiltersData.procedure_usage.find(
                                                (item) =>
                                                    item.procedure_id ===
                                                    procedureUsageId
                                            ) ?? null
                                        }
                                        getOptionLabel={(option) =>
                                            option.asset_name
                                        }
                                        renderOption={(props, option) => (
                                            <li
                                                {...props}
                                                key={option.procedure_id}
                                                style={{
                                                    padding: '6px',
                                                }}
                                            >
                                                {option.asset_name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Procedure Usage"
                                                placeholder="Procedure Usage"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    lg={2}
                                    style={{ minWidth: '210px' }}
                                >
                                    <MinMaxSelection
                                        inputLabel="File Size"
                                        selectorId="content_file_size"
                                        values={[
                                            1, 10, 50, 100, 200, 500, 1000,
                                        ]}
                                        displayUnit="MB"
                                        value={fileSize}
                                        onChange={(v) => {
                                            setFileSize(v);
                                            debouncedSearch({ fileSize: v });
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    lg={2}
                                    style={{ minWidth: '210px' }}
                                >
                                    <MinMaxSelection
                                        inputLabel="Width"
                                        selectorId={'content_width'}
                                        values={[
                                            426, 640, 854, 1280, 1920, 2560,
                                            3840, 7680,
                                        ]}
                                        displayUnit="px"
                                        max={10000}
                                        value={contentWidth}
                                        onChange={(v) => {
                                            setContentWidth(v);
                                            debouncedSearch({
                                                contentWidth: v,
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    lg={2}
                                    style={{ minWidth: '210px' }}
                                >
                                    <MinMaxSelection
                                        inputLabel="Height"
                                        selectorId={'content_height'}
                                        values={[
                                            240, 360, 480, 720, 1080, 1440,
                                            2160, 4320,
                                        ]}
                                        displayUnit="px"
                                        max={10000}
                                        value={contentHeight}
                                        onChange={(v) => {
                                            setContentHeight(v);
                                            debouncedSearch({
                                                contentHeight: v,
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    lg={2}
                                    style={{ display: 'flex' }}
                                >
                                    <FormControlLabel
                                        label="No Captions"
                                        control={
                                            <Checkbox
                                                checked={noCaptions}
                                                style={{
                                                    marginLeft: 8,
                                                }}
                                                onChange={(e) => {
                                                    const noCaptions =
                                                        e.target.checked;
                                                    setNoCaptions(noCaptions);
                                                    debouncedSearch({
                                                        noCaptions,
                                                    });
                                                }}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </FilterAccordion>
                    </div>
                )}
            </Box>
            <Box
                sx={{
                    paddingLeft: '10px',
                    flex: { xs: '0 0 50%', md: '0 0 25%' },
                    mt: { xs: '0.5rem', md: '0' },
                    ml: 'auto',
                    maxWidth: '280px',
                }}
            >
                <SearchWithFilters
                    onChange={(e) => handleSearch(e.target.value)}
                    filter={contentViewMode === 'card' ? true : false}
                    setSorting={setSorting}
                    sortDirection={sortDirection}
                    sortBy={sortBy}
                    addButtonText={'APPLY'}
                >
                    {contentViewMode === 'card' && (
                        <div className={contentStyles.sortContainer}>
                            <FormControl sx={{ mb: '1rem' }} fullWidth>
                                <InputLabel id="sort-by-id" variant="outlined">
                                    Sort By
                                </InputLabel>
                                <Select
                                    labelId="sort-by-id"
                                    id="select-sort-by-id"
                                    value={sortBy}
                                    label="Sort By"
                                    variant="outlined"
                                    className={contentStyles.selectRoot}
                                    onChange={(event) =>
                                        setSortBy(event?.target.value)
                                    }
                                >
                                    <MenuItem value="display_name">
                                        Content Name
                                    </MenuItem>
                                    <MenuItem value="content_type_name">
                                        Content Type
                                    </MenuItem>
                                    <MenuItem value="file_size">
                                        File Size
                                    </MenuItem>
                                    <MenuItem value="file_width">
                                        File Width
                                    </MenuItem>
                                    <MenuItem value="file_height">
                                        File Height
                                    </MenuItem>
                                    <MenuItem value="encoding_status">
                                        Status
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl sx={{ mb: '1rem' }} fullWidth>
                                <InputLabel
                                    id="sot-direction-id"
                                    variant="outlined"
                                >
                                    Order
                                </InputLabel>
                                <Select
                                    labelId="sot-direction-id"
                                    id="select-sort-direction-id"
                                    className={contentStyles.selectRoot}
                                    value={sortDirection}
                                    label="Order"
                                    variant="outlined"
                                    onChange={(event) =>
                                        setSortDirection(event?.target.value)
                                    }
                                >
                                    <MenuItem value="ASC">Ascending</MenuItem>
                                    <MenuItem value="DESC">Descending</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    )}
                </SearchWithFilters>
            </Box>
        </div>
    );
};

export { ContentFilter };
