import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';

import { displayProcedureContentVersion } from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import {
    setVisualEditZone,
    selectVisualEditZone,
} from '_features/common/editZoneSlice';
import { closePanel, selectFormInfo } from '_features/common/formSlice';
import { useStyles } from './style';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { loadZone } from 'shared/loadZoneSlice';
import {
    setDefaultItemPanelSection,
    setViewContentPanel,
} from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import { loadItem } from '_features/common/editItemSlice';
import { getAsset } from '_features/assets/assetManagerSlice';
import { fetchAllHotspotIcons, getHotspotStatus } from 'shared/hotspotsSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import AssetList from './components/AssetList';

const AppList = ({
    data,
    keyProperty,
    nameProperty,
    listHeader,
    id,
    zoneKey,
    zoneProperty,
    itemKey,
    itemProperty,
    linkKey,
    linkProperty,
}) => {
    const [assetSubMenu, setAssetSubMenu] = React.useState(Object.create(null));
    const [zoneSubMenu, setzoneSubMenu] = React.useState(Object.create(null));
    const [itemSubMenu, setItemSubMenu] = React.useState(Object.create(null));
    const [openList, setOpenList] = React.useState(false);
    const hotspotStatus = useSelector(getHotspotStatus);

    const isFormOpen = useSelector(selectFormInfo);
    const visualEditZone = useSelector(selectVisualEditZone);

    const classes = useStyles();
    const dispatch = useDispatch();
    const clearLastViewerProperties = async () => {
        await dispatch(setVisualEditZone(false));
        await dispatch(setViewContentPanel(false));
        await dispatch(setDefaultItemPanelSection(0));
        await dispatch(displayProcedureContentVersion(null));
        await dispatch(
            closePanel({
                formKey: 'zoneForm',
                formAction: 'Edit',
                isContentPanelOpen: false,
            })
        );
        await dispatch(
            closePanel({
                formKey: 'itemForm',
                formAction: 'Edit',
                isContentPanelOpen: false,
            })
        );
        await dispatch(
            closePanel({
                formKey: 'contentForm',
                formAction: 'Edit',
                isContentPanelOpen: false,
            })
        );
        if (window.geminiViewManager) {
            window.geminiViewManager.updateView();
        }
    };
    const handleZoneClick = async (
        rowData,
        assetId,
        item = null,
        link = null
    ) => {
        dispatch(activateLoading());
        if (visualEditZone === true) {
            await clearLastViewerProperties();
        }
        dispatch(closePanel({ formKey: 'contentForm' }));
        await dispatch(getAsset(assetId));
        if (hotspotStatus === LoadingStatus.Idle) {
            await dispatch(fetchAllHotspotIcons());
        }
        await dispatch(loadZone({ zoneId: rowData?.zone_id, refresh: false }));
        await dispatch(setVisualEditZone(true));
        if (item !== null) {
            handleItemClick(rowData.zone_id, item, link);
        }
        dispatch(deactivateLoading());
    };
    const handleItemClick = async (zone_id, hotspot, link = null) => {
        isFormOpen?.zoneForm?.isOpen &&
            dispatch(
                closePanel({ formKey: 'zoneForm', isContentPanelOpen: true })
            );
        isFormOpen?.itemForm?.isOpen &&
            dispatch(
                closePanel({ formKey: 'itemForm', isContentPanelOpen: true })
            );

        await dispatch(setViewContentPanel(true));
        if (link !== null) {
            await dispatch(setDefaultItemPanelSection(link.link_type_id));
        }
        await dispatch(loadItem({ zoneId: zone_id, itemId: hotspot.item_id }));
    };
    const handleClick = (subMenu, setSubMenu, index) => {
        if (!subMenu[index]) {
            subMenu[index] = true;
        } else {
            subMenu[index] = false;
        }
        setSubMenu({ ...subMenu });
    };

    return (
        <List
            className={classes.listRoot}
            component="nav"
            aria-labelledby={id}
            subheader={
                <ListSubheader
                    onClick={() => setOpenList(!openList)}
                    component="div"
                    id={id}
                    className={classes.listSubHeaderRoot}
                >
                    <div className={classes.listSubHeaderText}>
                        {listHeader}
                    </div>
                    <div className={classes.listSubHeaderNavigation}>
                        {openList ? (
                            <MdExpandLess
                                className="react-icon"
                                style={{ fontSize: '28px' }}
                            />
                        ) : (
                            <MdExpandMore
                                className="react-icon"
                                style={{ fontSize: '28px' }}
                            />
                        )}
                    </div>
                    <TouchRipple />
                </ListSubheader>
            }
        >
            {openList &&
                data?.assets.length > 0 &&
                data?.assets?.map((item, index) => (
                    <AssetList
                        key={item[keyProperty]}
                        index={index}
                        nameProperty={nameProperty}
                        assetSubMenu={assetSubMenu}
                        item={item}
                        itemSubMenu={itemSubMenu}
                        setItemSubMenu={setItemSubMenu}
                        itemProperty={itemProperty}
                        linkProperty={linkProperty}
                        handleClick={handleClick}
                        handleZoneClick={handleZoneClick}
                        linkKey={linkKey}
                        zoneSubMenu={zoneSubMenu}
                        zoneProperty={zoneProperty}
                        setzoneSubMenu={setzoneSubMenu}
                        itemKey={itemKey}
                        keyProperty={keyProperty}
                        setAssetSubMenu={setAssetSubMenu}
                        zoneKey={zoneKey}
                        clearLastViewerProperties={clearLastViewerProperties}
                    />
                ))}
        </List>
    );
};

export { AppList };
