import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import JSZip from 'jszip';
import clsx from 'clsx';
import Dropzone from 'react-dropzone';
import { MdPause, MdPlayArrow } from 'react-icons/md';
// import { saveAs } from 'file-saver';

import useStyles, { StyledButton } from '../styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { VideoSlider } from './videoSlider';
import {
    setMarkerPlaybackPosition,
    setPlaybackPosition,
    setTrimScrubberMax,
    setTrimPositions,
    addZonePosition,
    selectCurrentZone,
    renameCurrentZone,
    resetVideoEditor,
} from '../videoImportSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { Action } from '_helpers';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { PromptDialog } from 'components/PromptDialog';
import {
    showActionModal,
    selectActionModalInfo,
} from 'GeminiViewerComponent/_features/common/actionModalSlice';
import { ImageResizer } from 'forms/ZoneForm/ImageResizer';
import { editAsset, selectEditAsset } from '_features/assets/editAssetSlice';
import ImportHeader from './importHeader';
import ZonePreviews from './zonePreviews';
import TridiComponent from './tridiComponent';

const targetTime = 15.0;
var interval;

const CommandButton = (props) => {
    return (
        <StyledButton
            variant="contained"
            color="primary"
            size="small"
            {...props}
        >
            {props.children}
        </StyledButton>
    );
};

// Prevent slider from rendering everytime this parent renders.

function VideoEditor() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const videoRef = useRef(null);
    const viewerRef = useRef(null);
    const imgViewerRef = useRef(null);
    const trimPositionsRef = useRef(null);
    const playbackPositionRef = useRef(null);

    const [inverse, setInverse] = useState(false);
    const [originalFiles, setOriginalFiles] = useState([]);

    const asset = useSelector(selectEditAsset);
    const currentZone = useSelector(selectCurrentZone);
    const zonePositions = useSelector(
        (state) => state.videoImport.zonePositions
    );

    const trimPositions = useSelector(
        (state) => state.videoImport.trimPositions
    );
    trimPositionsRef.current = trimPositions;

    const playbackPosition = useSelector(
        (state) => state.videoImport.playbackPosition
    );

    playbackPositionRef.current = playbackPosition;

    const trimScrubberPositionMax = useSelector(
        (state) => state.videoImport.trimScrubberPositionMax
    );

    const [generatingPreview, setGeneratingPreview] = React.useState(false);

    const [framesPerSecond, setFramesPerSecond] = React.useState(2);
    const [imageResize, setImageResize] = React.useState(0);

    const [imageArray, setImageArray] = React.useState([]);
    const [hasImages, setHasImages] = React.useState(null);
    const [sequenceImages, setSequenceImages] = React.useState([]);
    const [currentImageIdx, setCurrentImageIdx] = React.useState(0);
    const [playing, setPlaying] = React.useState(false);
    const [zonePreviewImages, setZonePreviewImages] = React.useState([]);
    const [trimPreviewArray, setTrimPreviewArray] = React.useState([]);
    const [itemName, setItemName] = React.useState();
    const [manageDialog, setManageDialog] = useState({
        isDialogOpen: false,
        type: '',
    });
    const actionModalInfo = useSelector(selectActionModalInfo);
    const shortcutKeysEnabled = useRef(true);

    useEffect(() => {
        shortcutKeysEnabled.current =
            !manageDialog.isDialogOpen && !actionModalInfo?.isOpen;
    }, [manageDialog.isDialogOpen, actionModalInfo?.isOpen]);

    const theme = useSelector(selectActiveTheme);
    const classes = useStyles({ visible: generatingPreview, ...theme });

    const onVideoLoad = useCallback(() => {
        const currentVideo = videoRef.current;
        const trimPositions = trimPositionsRef.current;
        const playbackPosition = playbackPositionRef.current;

        if (currentVideo) {
            dispatch(setTrimScrubberMax(currentVideo.duration));
            if (trimPositions[1] > currentVideo.duration) {
                dispatch(
                    setTrimPositions([trimPositions[0], currentVideo.duration])
                );
            }
            if (trimPositions[0] === 0 && trimPositions[1] === 0) {
                dispatch(setTrimPositions([0, currentVideo.duration]));
            }
            if (playbackPosition > currentVideo.duration) {
                dispatch(setPlaybackPosition(0));
            }
            setImageArray([]);
        }
    }, [dispatch]);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener('loadeddata', onVideoLoad);
        }
        dispatch(resetVideoEditor());
    }, [dispatch, onVideoLoad]);

    useEffect(() => {
        async function getImages() {
            let zoneImages = [];
            if (!hasImages) {
                const times = zonePositions.map((zone) => zone.time);
                zoneImages = await videoTimesToImages(
                    videoRef.current.src,
                    200,
                    times
                );
            } else {
                zoneImages = sequenceImages;
            }
            const zonePreviewImages = [];

            zonePositions.forEach((zone, index) => {
                zonePreviewImages.push({
                    name: zone.name,
                    image: zoneImages[hasImages ? zone?.time : index],
                });
            });

            setZonePreviewImages(zonePreviewImages);
        }
        if (zonePositions.length > 0) {
            getImages();
        }
    }, [zonePositions, dispatch, onVideoLoad, hasImages, sequenceImages]);

    useEffect(() => {
        async function getImages() {
            let trimImages = [];
            if (!hasImages) {
                trimImages = await videoTimesToImages(
                    videoRef.current.src,
                    200,
                    trimPositions
                );
            } else {
                trimImages.push(
                    sequenceImages[0],
                    sequenceImages[sequenceImages.length - 1]
                );
            }

            if (trimImages.length === 2) {
                const trimPreviews = [];

                trimPreviews.push({ name: 'Start', image: trimImages[0] });
                trimPreviews.push({
                    name: 'End',
                    image: trimImages[1],
                });

                setTrimPreviewArray(trimPreviews);
            }
        }
        if (videoRef.current || hasImages) {
            getImages();
        }
    }, [trimPositions, hasImages, sequenceImages]);

    function renderImages(times) {
        var images = [];
        if (!hasImages) {
            getVideoFrames(
                videoRef.current.src,
                videoRef.current.videoWidth,
                times,
                null,
                function (index, imgSrc) {
                    images.push(imgSrc);
                },
                function () {
                    setImageArray(images);
                    setGeneratingPreview(false);
                }
            );
        } else {
            const zoneImage = zonePreviewImages.map((img) => img.image);
            setImageArray([...sequenceImages, ...zoneImage]);
            setGeneratingPreview(false);
        }
    }

    function videoTimesToImages(videoSrc, width, times) {
        return new Promise((resolve, reject) => {
            var images = [];
            var timeIndex = 0;
            const video = document.createElement('video');
            video.src = videoSrc;

            video.addEventListener('loadedmetadata', () => {
                video.currentTime = times[timeIndex];
            });

            video.addEventListener('seeked', () => {
                const ratio = video.videoWidth / video.videoHeight;

                const canvas = document.createElement('canvas');

                canvas.width = width;
                canvas.height = canvas.width / ratio;
                var ctx = canvas.getContext('2d');
                ctx.drawImage(
                    video,
                    0,
                    0,
                    video.videoWidth,
                    video.videoHeight,
                    0,
                    0,
                    canvas.width,
                    canvas.height
                );

                const imgSrc = canvas.toDataURL('image/jpeg', 0.7);

                images.push(imgSrc);

                if (timeIndex < times.length - 1) {
                    video.currentTime = times[++timeIndex];
                } else {
                    resolve(images);
                }

                video.addEventListener('error', () => {
                    reject();
                });
            });
        });
    }

    function getVideoFrames(
        path,
        width,
        times,
        canvasElements,
        callback,
        completeCallback
    ) {
        var me = this;

        var timeIndex = 0;
        const video = document.createElement('video');
        video.src = path;

        video.addEventListener('loadedmetadata', () => {
            video.currentTime = times[timeIndex];
        });

        video.addEventListener('seeked', () => {
            const ratio = video.videoWidth / video.videoHeight;

            let secs = times[timeIndex];
            var canvas = null;

            if (canvasElements) {
                canvas = canvasElements[timeIndex];
            } else {
                canvas = document.createElement('canvas');
            }

            canvas.width = width;
            canvas.height = canvas.width / ratio;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(
                video,
                0,
                0,
                video.videoWidth,
                video.videoHeight,
                0,
                0,
                canvas.width,
                canvas.height
            );

            if (canvasElements) {
                callback?.call(me, timeIndex, null, secs);
            } else {
                const imgSrc = canvas.toDataURL('image/jpeg', 0.7);
                callback?.call(me, timeIndex, imgSrc, secs);
            }

            if (timeIndex < times.length - 1) {
                video.currentTime = times[++timeIndex];
            } else {
                completeCallback?.call();
            }

            video.addEventListener('error', (e) => {
                callback?.call(me, undefined, undefined, e);
            });
        });
    }

    const secsToFrame = (secs, framesPerSecond) => {
        const ratio = targetTime / videoRef.current.duration;
        const targetSecs = secs * ratio;
        const frame = targetSecs * framesPerSecond;
        return frame;
    };

    const frameToSecs = (frame, framesPerSecond) => {
        const ratio = targetTime / videoRef.current.duration;
        const targetSecs = frame / framesPerSecond;
        const secs = targetSecs / ratio;
        return secs;
    };

    useEffect(() => {
        const handleKeyUp = (event) => {
            if (!shortcutKeysEnabled.current) {
                return;
            }
            switch (event.keyCode) {
                case 77: // M
                case 90: // Z
                    event.preventDefault();
                    handleAddZone();
                    break;
                case 82: // R
                    event.preventDefault();
                    handleRenameZone();
                    break;
                case 68: // D
                    event.preventDefault();
                    handleDeleteZone();
                    break;
            }
        };
        window.addEventListener('keyup', handleKeyUp);
        return () => window.removeEventListener('keyup', handleKeyUp);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentZone]);

    const handleSubmitZone = (value) => {
        setItemName('');
        setManageDialog({ isDialogOpen: false, type: '' });
        manageDialog.type === 'add'
            ? dispatch(addZonePosition(value))
            : dispatch(renameCurrentZone(value));
    };

    const handleAddZone = () => {
        setManageDialog({
            isDialogOpen: true,
            type: 'add',
        });
    };

    const handleRenameZone = () => {
        if (!currentZone) {
            return;
        }
        setManageDialog({
            isDialogOpen: true,
            type: 'rename',
        });
        setItemName(currentZone.name);
    };

    const handleDeleteZone = () => {
        if (!currentZone) {
            return;
        }
        dispatch(
            showActionModal({
                isOpen: true,
                object: currentZone,
                action: Action.DeleteCurrentZone,
                level: 'zone',
            })
        );
    };

    const handleDialogClose = () => {
        setManageDialog({ isDialogOpen: false, type: '' });
        setItemName('');
    };

    const getFrameTimes = () => {
        var frameTimes = [];
        var totalTime = trimPositions[1] - trimPositions[0];
        if (totalTime === 0.0) {
            return frameTimes;
        }
        var ratio = totalTime / targetTime;
        var secondsPerFrame = (1.0 / framesPerSecond) * ratio;
        for (
            var i = trimPositions[0];
            i <= trimPositions[1];
            i += secondsPerFrame
        ) {
            frameTimes.push(i);
        }
        return frameTimes;
    };

    const handleUpdatePreview = () => {
        setGeneratingPreview(true);
        renderImages(getFrameTimes());
    };

    const handlePlay = () => {
        clearInterval(interval);
        setPlaying(true);
        if (hasImages) {
            interval = setInterval(() => {
                const element =
                    imgViewerRef.current?.getViewerImageRef()?.current;
                const imgIndex = element.getAttribute('data-id');
                dispatch(setMarkerPlaybackPosition(imgIndex));
                imgViewerRef.current.next();
                if (Number(imgIndex) === sequenceImages?.length - 1) {
                    dispatch(setMarkerPlaybackPosition(0));
                    dispatch(setPlaybackPosition(0));
                    setCurrentImageIdx(0);
                    clearInterval(interval);
                    setPlaying(false);
                    return;
                }
            }, (1.0 / framesPerSecond) * 1000);
        } else {
            var currentPosition = playbackPosition;
            var ratio = targetTime / videoRef.current.duration;
            var secondsPerFrame = 1.0 / framesPerSecond / ratio;

            interval = setInterval(() => {
                videoRef.current.currentTime = currentPosition;
                dispatch(setMarkerPlaybackPosition(currentPosition));
                currentPosition += secondsPerFrame;
                if (currentPosition > trimScrubberPositionMax) {
                    dispatch(
                        setMarkerPlaybackPosition(trimScrubberPositionMax)
                    );
                    clearInterval(interval);
                    setPlaying(false);
                }
            }, (1.0 / framesPerSecond) * 1000);
        }
    };

    const getZoneFrameIndexes = (frameTimes) => {
        var zoneFrameIndexes = [];

        var closestIndex = 0;

        zonePositions.forEach((zone) => {
            if (!hasImages) {
                frameTimes.reduce(function (prev, curr, currentIndex) {
                    if (
                        Math.abs(curr - zone.time) < Math.abs(prev - zone.time)
                    ) {
                        closestIndex = currentIndex;
                        return curr;
                    } else {
                        return prev;
                    }
                });
            } else {
                closestIndex = zone.time;
            }
            zoneFrameIndexes.push(closestIndex);
        });
        return zoneFrameIndexes;
    };

    const handleSave = async () => {
        dispatch(activateLoading({ showProgress: true }));
        let zoneImages = [];
        let orbitImages = [];
        if (!hasImages) {
            // Get updated sequence of images
            const frameTimes = getFrameTimes();

            zoneImages = await videoTimesToImages(
                videoRef.current.src,
                videoRef.current.videoWidth,
                zonePositions.map((zone) => zone.time)
            );

            orbitImages = await videoTimesToImages(
                videoRef.current.src,
                videoRef.current.videoWidth,
                frameTimes
            );
        } else {
            zoneImages = zonePreviewImages;
            orbitImages = sequenceImages;
        }

        let zoneZip = new JSZip();
        let orbitZip = new JSZip();
        await createImagesZip(zoneImages, zoneZip, true);

        const zoneImagesZip = await zoneZip.generateAsync({ type: 'blob' });
        // .then(function (content) {
        //     // see FileSaver.js
        //     saveAs(content, 'zone.zip');
        // });

        await createImagesZip(orbitImages, orbitZip);

        const orbitImagesZip = await orbitZip.generateAsync({ type: 'blob' });
        // .then(function (content) {
        //     // see FileSaver.js
        //     saveAs(content, 'orbit.zip');
        // });

        zoneZip = null;
        orbitZip = null;
        var zoneFrameIndexes = getZoneFrameIndexes(frameTimes);
        const element = imgViewerRef.current?.getViewerImageRef()?.current;

        const resultAction = await dispatch(
            editAsset({
                asset_id: asset.asset_id,
                orbit_reverse: inverse,
                trim_positions: trimPositions,
                zone_positions: zonePositions.map((zone) => zone.time),
                zone_names: zonePositions.map((zone) => zone.name),
                orbit_images_zip: orbitImagesZip,
                zone_frame_indexes: zoneFrameIndexes,
                zone_images_zip: zoneImagesZip,
                zone_image_width: hasImages
                    ? element.offsetWidth
                    : videoRef.current.videoWidth,
                zone_image_height: hasImages
                    ? element.offsetHeight
                    : videoRef.current.videoHeight,
                dispatch,
            })
        );
        dispatch(deactivateLoading());
        const { message, variant } = snackbarHandler(
            resultAction.meta.requestStatus,
            'Import Video'
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });
        navigate(`/asset/${asset.asset_id}`);
    };

    const createImagesZip = async (images, zip, isZoneImage = false) => {
        for (let index = 0; index < (images || []).length; index++) {
            const image = images[index];
            if (hasImages) {
                const base64Img = await getBase64(
                    isZoneImage ? image?.image : image
                );
                zip.file(
                    `${isZoneImage ? image?.name : index + 1}.jpg`,
                    base64Img.split('base64,')[1],
                    {
                        base64: true,
                    }
                );
            } else {
                zip.file(`${index + 1}.jpg`, image.split('base64,')[1], {
                    base64: true,
                });
            }
        }
    };

    const getBase64 = (url) => {
        return new Promise((resolve) => {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        });
    };

    const handleOrbitReverseChange = (e) => {
        setInverse(e.target.checked);
    };

    const handlePause = () => {
        clearInterval(interval);
        setPlaying(false);
        if (hasImages) {
            const element = imgViewerRef.current?.getViewerImageRef()?.current;
            const imgIndex = element.getAttribute('data-id');
            setCurrentImageIdx(Number(imgIndex));
        }
    };

    const handleSmoothnessChange = (event) => {
        setFramesPerSecond(Number(event.target.value));
    };

    const handleImageResizeChange = async (event) => {
        const resizeNumber = Number(event.target.value);
        setImageResize(resizeNumber);

        const promises = [];

        originalFiles.forEach((file) => {
            promises.push(resizeImage(file, resizeNumber));
        });
        const results = await Promise.all(promises);

        let images = [];

        results.forEach((resizedFile) => {
            images.push(window.URL.createObjectURL(resizedFile));
        });

        setSequenceImages(images);
    };

    const resizeImage = async (file, imageResizeNumber = 3) => {
        let imageResizer = new ImageResizer(file);
        switch (imageResizeNumber) {
            case 1:
                return await imageResizer.run(512, 512);
            case 2:
                return await imageResizer.run(1024, 1024);
            case 3:
                return await imageResizer.run(1280, 1280);
            default:
                return file;
        }
    };

    const videoVisible = videoRef.current && videoRef.current.src;
    const canSave = trimPositions.length > 0 && zonePositions.length > 0;
    const dropZoneExtraClass = videoVisible ? 'hide' : '';
    const videoExtraClass = !videoVisible ? 'hide' : '';

    const frameTimes = getFrameTimes();
    var zoneFrameIndexes = getZoneFrameIndexes(frameTimes);

    const handleFileChange = async (files) => {
        // const files = myFileArray.map((file) => file.file);
        let images = [];
        const isImageType = files.some((file) => file?.type.includes('image'));
        setHasImages(isImageType);
        if (files && files.length > 0) {
            if (!isImageType) {
                if (files[0] && files[0].type.includes('video')) {
                    videoRef.current.addEventListener(
                        'loadeddata',
                        onVideoLoad,
                        {
                            once: true,
                        }
                    );
                    videoRef.current.src = window.URL.createObjectURL(files[0]);
                    setFramesPerSecond(framesPerSecond);
                    return;
                } else {
                    setHasImages(null);
                    enqueueSnackbar('No valid images or video found', {
                        action: (key) => <SnackbarDismiss key={key} />,
                        variant: 'error',
                    });
                }
            }
            let hasError = false;
            const filteredFiles = files
                .map((file) => {
                    const removeExt = file?.name?.substring(
                        0,
                        file?.name?.lastIndexOf('.')
                    );
                    if (hasString(removeExt) || hasInvalidFileName(removeExt)) {
                        hasError = true;
                        return false;
                    }
                    const numericFilesName = removeExt.replace(/[^\d.]/g, '');
                    return renameFile(file, numericFilesName);
                })
                .sort((a, b) => a.name - b.name);
            if (hasError) {
                setSequenceImages([]);
                setHasImages(null);
                return;
            }
            const promises = [];

            setOriginalFiles(filteredFiles);
            filteredFiles.forEach((file) => {
                promises.push(resizeImage(file, imageResize));
            });
            const results = await Promise.all(promises);

            results.forEach((resizedFile) => {
                images.push(window.URL.createObjectURL(resizedFile));
            });

            setSequenceImages(images);
            dispatch(setTrimPositions([0, filteredFiles?.length - 1]));
            dispatch(setTrimScrubberMax(filteredFiles?.length - 1));
        }
    };

    const renameFile = (originalFile, newName) => {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    };

    const hasString = (removeExt) => {
        let hasError = false;
        if (/^[a-zA-Z!@#$%*&]+$/.test(removeExt)) {
            setHasImages(null);
            setSequenceImages([]);
            enqueueSnackbar('File name must be only numeric', {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
            hasError = true;
        }
        return hasError;
    };

    const hasInvalidFileName = (removeExt) => {
        let hasError = false;
        const onlyNumeric = removeExt.replace(/[^\d.]/g, '');
        if (isNaN(Number(onlyNumeric))) {
            setHasImages(null);
            setSequenceImages([]);
            enqueueSnackbar('Invalid file name', {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
            hasError = true;
        }
        return hasError;
    };

    const onNextClick = async (ref) => {
        await imgViewerRef.current.next();
    };

    const onPrevClick = async (ref) => {
        await imgViewerRef.current.prev();
    };

    const onKeyFrameChange = async (index) => {
        dispatch(setMarkerPlaybackPosition(index));
    };

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                overflow: 'auto',
            }}
        >
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'absolute',
                }}
            >
                <ImportHeader
                    canSave={canSave}
                    inverse={inverse}
                    hasImages={hasImages}
                    imageResize={imageResize}
                    trimPositions={trimPositions}
                    framesPerSecond={framesPerSecond}
                    trimPreviewArray={trimPreviewArray}
                    handleSave={handleSave}
                    handleSmoothnessChange={handleSmoothnessChange}
                    handleImageResizeChange={handleImageResizeChange}
                    handleOrbitReverseChange={handleOrbitReverseChange}
                />

                {!asset || (asset && Object.keys(asset).length === 0) ? (
                    'Asset not defined'
                ) : (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginTop: 10,
                                }}
                            >
                                <div
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    {hasImages ? (
                                        <TridiComponent
                                            showHeader={false}
                                            tridiImages={sequenceImages}
                                            viewerRef={imgViewerRef}
                                            zoneFrameIndexes={zoneFrameIndexes}
                                            hasImages={hasImages}
                                            currentImageIdx={currentImageIdx}
                                            inverse={inverse}
                                            setInverse={setInverse}
                                            onNextClick={onNextClick}
                                            onPrevClick={onPrevClick}
                                            onKeyFrameChange={onKeyFrameChange}
                                        />
                                    ) : (
                                        <video
                                            ref={videoRef}
                                            className={clsx(
                                                classes.video,
                                                videoExtraClass
                                            )}
                                        >
                                            Your browser does not support HTML5
                                            video.
                                        </video>
                                    )}
                                    {hasImages == null && (
                                        <Dropzone
                                            className={clsx(
                                                classes.dropzoneAreaImport,
                                                dropZoneExtraClass
                                            )}
                                            maxSize={250000000}
                                            accept={[
                                                'video/mp4',
                                                'video/mpeg',
                                                'video/quicktime',
                                                'image/jpeg',
                                                'image/png',
                                                'image/webp',
                                                'image/svg+xml',
                                            ]}
                                            maxFiles={100}
                                            onDropAccepted={(files) =>
                                                handleFileChange(files)
                                            }
                                        >
                                            {({
                                                getRootProps,
                                                getInputProps,
                                            }) => (
                                                <div
                                                    {...getRootProps()}
                                                    className={clsx(
                                                        classes.dropzoneAreaImport,
                                                        dropZoneExtraClass
                                                    )}
                                                    style={{}}
                                                >
                                                    <input
                                                        {...getInputProps()}
                                                    />
                                                    <div
                                                        className={
                                                            classes.dropzoneText
                                                        }
                                                    >
                                                        Drop video/images here
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                    )}

                                    {playing ? (
                                        <MdPause
                                            onClick={handlePause}
                                            className={clsx(
                                                classes.playPause,
                                                videoExtraClass,
                                                hasImages && classes.maxOpacity,
                                                'react-icon'
                                            )}
                                        />
                                    ) : (
                                        <MdPlayArrow
                                            onClick={handlePlay}
                                            className={clsx(
                                                classes.playPause,
                                                videoExtraClass,
                                                hasImages && classes.maxOpacity,
                                                'react-icon'
                                            )}
                                        />
                                    )}
                                </div>
                                <div
                                    className={clsx(
                                        classes.videoControls,
                                        videoExtraClass,
                                        hasImages && classes.maxOpacity,
                                        hasImages && classes.marginBottom
                                    )}
                                >
                                    <VideoSlider
                                        videoRef={videoRef}
                                        secsToFrame={secsToFrame}
                                        frameToSecs={frameToSecs}
                                        framesPerSecond={framesPerSecond}
                                        hasImages={hasImages}
                                        setCurrentImageIdx={(index) => {
                                            if (imgViewerRef?.current) {
                                                imgViewerRef.current.setCurrentImageIdx(
                                                    index
                                                );
                                            }
                                            setCurrentImageIdx(index);
                                        }}
                                        shortcutKeysEnabled={
                                            shortcutKeysEnabled.current
                                        }
                                    />
                                    <div className={classes.previewHeader}>
                                        <CommandButton onClick={handleAddZone}>
                                            Add Zone (z)
                                        </CommandButton>
                                        <CommandButton
                                            onClick={handleRenameZone}
                                            disabled={!currentZone}
                                        >
                                            Rename Zone (r)
                                        </CommandButton>
                                        <CommandButton
                                            onClick={handleDeleteZone}
                                            disabled={!currentZone}
                                        >
                                            Delete Zone (d)
                                        </CommandButton>
                                        {!hasImages && (
                                            <CommandButton
                                                onClick={handleUpdatePreview}
                                            >
                                                Preview
                                            </CommandButton>
                                        )}
                                        <Typography
                                            className={
                                                classes.generatingPreview
                                            }
                                        >
                                            Generating Preview...
                                        </Typography>
                                    </div>
                                    {!hasImages && (
                                        <div className={classes.orbitContainer}>
                                            <TridiComponent
                                                showHeader={false}
                                                tridiImages={imageArray}
                                                viewerRef={viewerRef}
                                                zoneFrameIndexes={
                                                    zoneFrameIndexes
                                                }
                                                hasImages={hasImages}
                                                currentImageIdx={
                                                    currentImageIdx
                                                }
                                                inverse={inverse}
                                                setInverse={setInverse}
                                                onNextClick={onNextClick}
                                                onPrevClick={onPrevClick}
                                                showPreview
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    margin: '10px 0px 10px 10px',
                                }}
                            >
                                <ZonePreviews
                                    title="Zones"
                                    previews={zonePreviewImages}
                                    className={classes.zonePreviews}
                                    isTrimPositions={false}
                                />
                            </div>
                        </div>
                        <PromptDialog
                            title="Enter name"
                            label="New zone"
                            cancelButtonLabel="Cancel"
                            okButtonLabel="Ok"
                            open={manageDialog.isDialogOpen}
                            value={itemName}
                            handleClose={handleDialogClose}
                            handleSubmit={(e) => handleSubmitZone(e)}
                            handleOnChange={(e) => setItemName(e)}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

export { VideoEditor };
