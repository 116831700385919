import { Fragment, useState } from 'react';
import {
    List,
    ListSubheader,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';

import TouchRipple from '@mui/material/ButtonBase/TouchRipple';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { useStyles } from './style';

const BulkImportResultDisplay = ({ result = null }) => {
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles();
    const [openErrorList, setOpenErrorList] = useState(false);
    const [openDuplicateList, setOpenDuplicateList] = useState(false);
    if (!result) return null;

    const hasErrors =
        result?.errors &&
        typeof result?.errors === 'object' &&
        !Array.isArray(result?.errors) &&
        result?.errors !== null &&
        Object.keys(result?.errors)?.length > 0
            ? true
            : false;

    const hasDuplicates =
        result?.duplicates &&
        Array.isArray(result?.duplicates) &&
        result?.duplicates?.length > 0
            ? true
            : false;

    return (
        <fieldset
            style={{
                border: '1px solid #cccccc',
                padding: '15px',
                borderRadius: '5px',
            }}
        >
            <legend
                style={{ padding: '3px', fontSize: '14px', color: '#6d7178' }}
            >
                Last Import Results
            </legend>
            {isNaN(+result?.imported) === false && (
                <p>
                    Items imported: {result?.imported}
                    <br />
                </p>
            )}

            {hasErrors === true && (
                <List
                    className={classes.listRoot}
                    component="nav"
                    subheader={
                        <ListSubheader
                            onClick={() => setOpenErrorList(!openErrorList)}
                            component="div"
                            className={classes.listSubHeaderRoot}
                        >
                            <div className={classes.listSubHeaderText}>
                                Errors:
                                {Object.keys(result?.errors ?? {})?.length}
                            </div>
                            <div className={classes.listSubHeaderNavigation}>
                                {openErrorList ? (
                                    <MdExpandLess
                                        className="react-icon"
                                        style={{ fontSize: '28px' }}
                                    />
                                ) : (
                                    <MdExpandMore
                                        className="react-icon"
                                        style={{ fontSize: '28px' }}
                                    />
                                )}
                            </div>
                            <TouchRipple />
                        </ListSubheader>
                    }
                >
                    {openErrorList && (
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table" padding="none">
                                <TableHead>
                                    <TableRow
                                        sx={{
                                            'td, th': {
                                                padding: '5px',
                                                backgroundColor:
                                                    theme?.colors?.button
                                                        ?.primaryBackground,
                                                color: '#ffffff',
                                                fontWeight: 'bold',
                                            },
                                        }}
                                    >
                                        <TableCell align="center">
                                            Row #
                                        </TableCell>
                                        <TableCell align="left">
                                            Errors
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(result?.errors ?? {})?.map(
                                        (ln) => (
                                            <TableRow
                                                key={`import-error-line-${ln}`}
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                    'td, th': {
                                                        padding: '5px',
                                                    },
                                                }}
                                            >
                                                <TableCell align="center">
                                                    {ln}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {Array.isArray(
                                                        result.errors?.[ln]
                                                    ) &&
                                                        result.errors?.[ln].map(
                                                            (err) => (
                                                                <Fragment
                                                                    key={`${err}-${ln}`}
                                                                >
                                                                    {err} <br />
                                                                </Fragment>
                                                            )
                                                        )}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </List>
            )}
            {hasDuplicates === true && (
                <List
                    className={classes.listRoot}
                    component="nav"
                    subheader={
                        <ListSubheader
                            onClick={() =>
                                setOpenDuplicateList(!openDuplicateList)
                            }
                            component="div"
                            className={classes.listSubHeaderRoot}
                        >
                            <div className={classes.listSubHeaderText}>
                                Duplicates:
                                {Object.keys(result?.duplicates ?? {})?.length}
                            </div>
                            <div className={classes.listSubHeaderNavigation}>
                                {openDuplicateList ? (
                                    <MdExpandLess
                                        className="react-icon"
                                        sx={{ fontSize: '28px' }}
                                    />
                                ) : (
                                    <MdExpandMore
                                        className="react-icon"
                                        sx={{ fontSize: '28px' }}
                                    />
                                )}
                            </div>
                            <TouchRipple />
                        </ListSubheader>
                    }
                >
                    {openDuplicateList && (
                        <TableContainer
                            style={{ width: 'fit-content' }}
                            component={Paper}
                        >
                            <Table aria-label="simple table" padding="5">
                                <TableHead>
                                    <TableRow
                                        sx={{
                                            'td, th': {
                                                padding: '5px',
                                                backgroundColor:
                                                    theme?.colors?.button
                                                        ?.primaryBackground,
                                                color: '#ffffff',
                                                fontWeight: 'bold',
                                            },
                                        }}
                                    >
                                        <TableCell align="center">
                                            Row #
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {result?.duplicates?.map((ln) => (
                                        <>
                                            <TableRow
                                                key={`import-error-line-${ln}`}
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                    'td, th': {
                                                        padding: '5px',
                                                    },
                                                }}
                                            >
                                                <TableCell align="center">
                                                    {ln}
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </List>
            )}
        </fieldset>
    );
};

export { BulkImportResultDisplay };
