import { ProcedureNodeTypes } from 'GeminiViewerComponent/components/Procedure/ProcedureNodeTypes';

const convertNodes = {
    form: ProcedureNodeTypes.content.type,
    lrs: ProcedureNodeTypes.content.type,
};

function getNodesHistoricalAnswers(activeTree) {
    return activeTree?.history
        ?.filter((data) => data.history_answer)
        .map((n) => {
            if (n?.history_answer) {
                return {
                    Node: n.id,
                    Question: n?.question,
                    Answer: n?.history_answer,
                };
            }
        });
}

function convertNodeTypes(procedure) {
    if (Array.isArray(procedure?.nodes) && procedure.nodes.length > 0) {
        let changableNodes = Object.keys(convertNodes);
        let nodes = procedure?.nodes?.map((nd) => {
            if (changableNodes.includes(nd?.type)) {
                nd = { ...nd, type: convertNodes[nd?.type] };
            }
            return nd;
        });
        procedure = { ...procedure, nodes: nodes };
    }
    return procedure;
}

export { getNodesHistoricalAnswers, convertNodes, convertNodeTypes };
