import { configureStore } from '@reduxjs/toolkit';

import {
    getAccountsThunk,
    getCreateAccountsSlice,
} from 'GeminiViewerComponent/_features/accounts/accountsSlice';
import actionModalReducer from 'GeminiViewerComponent/_features/common/actionModalSlice';
import contentReducer from '_features/content/contentSlice';
import assetManagerReducer from '_features/assets/assetManagerSlice';
import zonesTableReducer from '_features/zones/zonesTableSlice';
import itemsTableReducer from '_features/items/itemsTableSlice';
import picklistsReducer from '_features/picklists/pickListsSlice';
import proceduresReducer from '_features/procedures/proceduresSlice';
import audiencesReducer from '_features/audiences/audiencesSlice';
import editPanelReducer from '_features/common/editPanelSlice';
import formPanelReducer from '_features/common/formSlice';
import editZoneReducer from '_features/common/editZoneSlice';
import editAssetReducer from '_features/assets/editAssetSlice';
import loadZoneReducer from 'shared/loadZoneSlice';
import editItemReducer from '_features/common/editItemSlice';
import groupsReducer from '_features/groups/groupsSlice';
import categoryReducer from '_features/category/categorySlice';
import hotspotsReducer from 'shared/hotspotsSlice';
import linksReducer from 'GeminiViewerComponent/_features/links/linksSlice';
import assetsReducer from '_features/assets/assetsTableSlice';
import assetExportReducer from '_features/assets/assetExportSlice';
import locationReducer from '_features/tools/locationSlice';
import toolsCategoryReducer from '_features/tools/toolsCategorySlice';
import toolsReducer from '_features/tools/toolSlice';
import rulesReducer from '_features/rules/rulesSlice';
import partGroupReducer from '_features/parts/partGroupSlice';
import partCategoryReducer from '_features/parts/partCategorySlice';
import partsReducer from '_features/parts/partSlice';
import visibilityReducer from '_features/globals/visibilitySlice';
import videoImportSliceReducer from 'scenes/VideoImporter/videoImportSlice';
import tagsSliceReducer from '_features/tags/tagsSlice';
import loadContentPanelReducer from 'shared/loadContentPanelSlice';
import validationTypesReducer from '_features/validationTypes/validationTypesSlice';
import validationRawTypesReducer from '_features/validationRawTypes/validationRawTypesSlice';
import configFieldsReducer from '_features/configFields/configFieldsSlice';
import configValuesReducer from '_features/configValues/configValuesSlice';
import clientsReducer from '_features/clients/clientsSlice';
import snippetsReducer from '_features/snippets/snippetsSlice';
import jobsReducer from '_features/jobs/jobsSlice';

import configReducer from 'GeminiViewerComponent/_features/config/configSlice';
import loadingProgressReducer from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import viewerVisibilityReducer from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import flyerReducer from 'GeminiViewerComponent/_features/globals/flyerSlice';
import viewerStatusReducer from 'GeminiViewerComponent/_features/globals/viewerStatusSlice';
import assetReducer from 'GeminiViewerComponent/_features/asset/assetSlice';
import xapiReducer from 'GeminiViewerComponent/_features/xAPI/xapiSlice';
import zonePreviewReducer from 'GeminiViewerComponent/_features/zonePreview/zonePreviewSlice';
import searchSliceReducer from 'GeminiViewerComponent/_features/globals/searchSlice';
import themeSliceReducer from 'GeminiViewerComponent/_features/globals/themeSlice';
import mediaSliceReducer from 'GeminiViewerComponent/_features/globals/mediaSlice';
import procedureSliceReducer from 'GeminiViewerComponent/components/Procedure/_features/procedureSlice';
import checklistDataReducer from 'GeminiViewerComponent/components/Procedure/_features/checklistDataSlice';
import contentPanelItemReducer from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import procedureSessionSliceReducer from 'GeminiViewerComponent/_features/procedureSession/procedureSessionSlice';
import inspectionReducer from 'GeminiViewerComponent/_features/inspection/inspectionSlice';
import companionAppsReducer from 'GeminiViewerComponent/_features/companionApps/companionAppsSlice';
import chapterSliceReducer from 'GeminiViewerComponent/_features/globals/chapterSlice';
import newsFlashReducer from 'GeminiViewerComponent/_features/newsFlash/newsFlashSlice';

import { config } from 'gemini-config';

export const accountsThunk = getAccountsThunk({ config: config });
export const accountsSlice = getCreateAccountsSlice({
    accountsThunk: accountsThunk,
});

export const persistor = {}; // dummy object to match viewer

export default configureStore({
    reducer: {
        accounts: accountsSlice.slice.reducer,
        config: configReducer,
        clients: clientsReducer,
        assetManager: assetManagerReducer,
        zonesTable: zonesTableReducer,
        itemsTable: itemsTableReducer,
        picklists: picklistsReducer,
        procedures: proceduresReducer,
        actionModal: actionModalReducer,
        content: contentReducer,
        audiences: audiencesReducer,
        editPanel: editPanelReducer,
        forms: formPanelReducer,
        editZone: editZoneReducer,
        editAsset: editAssetReducer,
        loadZone: loadZoneReducer,
        editItem: editItemReducer,
        groups: groupsReducer,
        category: categoryReducer,
        hotspots: hotspotsReducer,
        links: linksReducer,
        loadingProgress: loadingProgressReducer,
        assets: assetsReducer,
        rules: rulesReducer,
        visibility: visibilityReducer,
        viewerVisibility: viewerVisibilityReducer,
        flyer: flyerReducer,
        viewerStatus: viewerStatusReducer,
        asset: assetReducer,
        xAPI: xapiReducer,
        zonePreview: zonePreviewReducer,
        videoImport: videoImportSliceReducer,
        tags: tagsSliceReducer,
        search: searchSliceReducer,
        theme: themeSliceReducer,
        procedure: procedureSliceReducer,
        checklistData: checklistDataReducer,
        loadContentPanel: loadContentPanelReducer,
        contentPanelItem: contentPanelItemReducer,
        validationTypes: validationTypesReducer,
        validationRawTypes: validationRawTypesReducer,
        configFields: configFieldsReducer,
        configValues: configValuesReducer,
        assetExport: assetExportReducer,
        location: locationReducer,
        toolsCategory: toolsCategoryReducer,
        tools: toolsReducer,
        media: mediaSliceReducer,
        partGroup: partGroupReducer,
        partCategory: partCategoryReducer,
        parts: partsReducer,
        inspection: inspectionReducer,
        procedureSession: procedureSessionSliceReducer,
        companionApps: companionAppsReducer,
        snippets: snippetsReducer,
        chapter: chapterSliceReducer,
        newsFlash: newsFlashReducer,
        jobs: jobsReducer,
    },
});
