import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ItemLinkTable } from 'components/_Tables/ItemLinkTable';
import { Button } from '@mui/material';
import { LinkTypes } from '_helpers';
import { toggleSchooxTab } from '_features/globals/visibilitySlice';
import { tabStyles } from 'GeminiViewerComponent/components/styles';
import { FormActions } from '_helpers/form-action';
import { LinkForm } from 'forms/LinkForm';

function ItemSchooxTable() {
    const dispatch = useDispatch();

    const [toggleLinkView, setToggleLinkView] = useState(false);
    const classes = tabStyles();

    return (
        <div className={classes.tabContent}>
            {!toggleLinkView ? (
                <>
                    <div className={classes.tabButtons}>
                        <Button
                            className={classes.tabButton}
                            onClick={() => setToggleLinkView(!toggleLinkView)}
                            variant="outlined"
                            color="primary"
                            disableElevation
                        >
                            New Schoox Link
                        </Button>
                        <Button
                            className={classes.tabButton}
                            onClick={() => dispatch(toggleSchooxTab())}
                            variant="outlined"
                            color="primary"
                            disableElevation
                        >
                            Import Schoox Courses
                        </Button>
                    </div>
                    <div className={classes.linksHeader}>Links</div>
                    <div className={classes.tabBottom}>
                        <ItemLinkTable linkTypeId={LinkTypes.Schoox.id} />
                    </div>
                </>
            ) : (
                <>
                    {toggleLinkView && (
                        <LinkForm
                            formAction={FormActions.Create}
                            contentTypes={LinkTypes.Schoox.contentTypes}
                            linkTypeId={LinkTypes.Schoox.id}
                            tabView={true}
                            setToggleView={setToggleLinkView}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export { ItemSchooxTable };
