import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { Collapse, ListItemButton, Typography } from '@mui/material';

import ZoneList from './ZoneList';
import { selectVisualEditZone } from '_features/common/editZoneSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

const AssetList = ({
    index,
    item,
    itemSubMenu,
    setItemSubMenu,
    itemProperty,
    linkProperty,
    handleClick,
    handleZoneClick,
    linkKey,
    zoneSubMenu,
    zoneProperty,
    setzoneSubMenu,
    itemKey,
    keyProperty,
    clearLastViewerProperties,
    nameProperty,
    assetSubMenu,
    setAssetSubMenu,
    zoneKey,
}) => {
    const navigate = useNavigate();
    const theme = useSelector(selectActiveTheme);
    const visualEditZone = useSelector(selectVisualEditZone);
    return (
        <div key={item[keyProperty]}>
            <ListItemButton
                onClick={() => {
                    if (visualEditZone === true) {
                        clearLastViewerProperties();
                    }
                    navigate(`/asset/${item?.asset_id}`);
                }}
            >
                <span style={{ color: theme.colors.text, fontWeight: 'bold' }}>
                    Asset:&nbsp;
                </span>
                <Typography
                    title={item[nameProperty]}
                    style={{
                        width: '100%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        margin: '4px 0',
                    }}
                >
                    {item[nameProperty]}
                </Typography>
                {assetSubMenu[index] ? (
                    <MdExpandLess
                        className="react-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClick(assetSubMenu, setAssetSubMenu, index);
                        }}
                        style={{ fontSize: '28px' }}
                    />
                ) : (
                    <MdExpandMore
                        className="react-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClick(assetSubMenu, setAssetSubMenu, index);
                        }}
                        style={{ fontSize: '28px' }}
                    />
                )}
            </ListItemButton>
            {item[zoneKey] !== undefined && (
                <Collapse in={assetSubMenu[index]} timeout="auto" unmountOnExit>
                    {item[zoneKey]?.map((zoneItem, idx) => (
                        <ZoneList
                            key={`ZoneList-${idx}`}
                            index={idx}
                            zoneItem={zoneItem}
                            item={item}
                            itemSubMenu={itemSubMenu}
                            setItemSubMenu={setItemSubMenu}
                            itemProperty={itemProperty}
                            linkProperty={linkProperty}
                            handleClick={handleClick}
                            handleZoneClick={handleZoneClick}
                            linkKey={linkKey}
                            zoneSubMenu={zoneSubMenu}
                            zoneProperty={zoneProperty}
                            setzoneSubMenu={setzoneSubMenu}
                            itemKey={itemKey}
                        />
                    ))}
                </Collapse>
            )}
        </div>
    );
};

export default AssetList;
