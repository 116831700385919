import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { useFormikContext } from 'formik';

const FieldDeleteDialog = ({
    openDeleteConfirmDialog,
    closeDeleteConfirmDialog,
    selectedDeleteField,
    treeState,
    setSortedNodes,
    setTreeState,
}) => {
    const { setFieldValue } = useFormikContext();
    const addField = (tree, field) => {
        tree.items[field.id] = {
            id: field.id,
            children: [],
            hasChildren:
                field.type === 'group' || field.type === 'table' ? false : true,
            isExpanded:
                field.type === 'group' || field.type === 'table' ? true : false,
            isChildrenLoading: false,
            data: field,
        };
        if (field.type === 'group' || field.type === 'table') {
            const group = tree.items[field.id];
            group.hasChildren = false;
            field?.fields?.forEach((fld) => {
                group.children.push(fld.id);
                addField(tree, fld);
            });
        }
    };

    const fieldsToTree = (fields) => {
        var tree = {
            rootId: 'root',
            items: {
                root: {
                    id: 'root',
                    children: [],
                    hasChildren: false,
                    isExpanded: true,
                    isChildrenLoading: false,
                },
            },
        };

        fields?.forEach((field) => {
            addField(tree, field);
            tree.items.root.children.push(field.id);
        });

        return tree;
    };

    const treeToFields = (tree) => {
        var nodeFields = [];

        var treeRootChildren = tree.items[tree.rootId].children;

        treeRootChildren?.forEach((itemId) => {
            var treeItem = tree.items[itemId];
            if (
                (treeItem.data.type === 'group' ||
                    treeItem.data.type === 'table') &&
                treeItem?.children?.length > 0
            ) {
                treeItem.data = { ...treeItem.data, fields: [] };
                treeItem?.children?.forEach((itemId) => {
                    var childTreeItem = tree.items[itemId];
                    treeItem.data.fields[treeItem.data.fields.length] =
                        childTreeItem.data;
                });
            }
            nodeFields.push(treeItem.data);
        });

        return nodeFields;
    };

    const deleteField = (deleteArray, deleteId) => {
        let filterArray = [];
        deleteArray.map((i) => {
            if (i.id === deleteId) {
                return null;
            } else if (Object.prototype.hasOwnProperty.call(i, 'fields')) {
                filterArray = [
                    ...filterArray,
                    { ...i, fields: deleteField(i.fields, deleteId) },
                ];
            } else {
                filterArray = [...filterArray, i];
            }
        });
        return filterArray;
    };

    const handleFieldDelete = () => {
        var fields = treeToFields(treeState.tree);
        let filteredNodes = deleteField(fields, selectedDeleteField);
        setSortedNodes(filteredNodes);
        setFieldValue('fields', filteredNodes);
        setTreeState({ tree: fieldsToTree(filteredNodes) });
        closeDeleteConfirmDialog();
    };

    return (
        <Dialog
            open={openDeleteConfirmDialog}
            onClose={closeDeleteConfirmDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{'Delete Field'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`Are you sure you want to delete this Field?`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDeleteConfirmDialog}>Cancel</Button>
                <Button
                    variant="outlined"
                    onClick={handleFieldDelete}
                    autoFocus
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { FieldDeleteDialog };
