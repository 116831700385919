import React from 'react';
import { PageHeader, ToolsTabPanel } from 'components';
import { useStyles } from 'scenes/styles';
import { MdHomeRepairService } from 'react-icons/md';

const Tools = () => {
    const classes = useStyles();

    return (
        <div className={classes.content}>
            <PageHeader
                header="Tools Manager"
                icon={<MdHomeRepairService className="react-icon" />}
            />
            <ToolsTabPanel />
        </div>
    );
};

export { Tools };
