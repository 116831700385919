import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { groupService } from '_features/_services';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';

const initialState = {
    groupsStatus: LoadingStatus.Idle,
    groups: [],
    loadedGroupsMap: {},
    displayGroup: {},
    groupPageInfo: {},
    groupUsage: [],
};

export const fetchGroupsPage = createAsyncThunk(
    'groups/fetchGroupsPage',
    async (params) => {
        const response = await groupService.getAll(
            params.page,
            params.pageSize,
            params.searchString,
            params.sort
        );
        return response;
    }
);

export const getGroupById = createAsyncThunk(
    'groups/getGroupById',
    async (groupId) => {
        const response = await groupService.getById(groupId);
        response.id = groupId;
        return response;
    }
);
export const fetchGroupUsageById = createAsyncThunk(
    'groups/fetchGroupUsageById',
    async (groupId) => await groupService.fetchGroupUsageById(groupId)
);
export const addNewGroup = createAsyncThunk(
    'groups/addNewGroup',
    async (newGroup) => await groupService.create(newGroup)
);

export const editGroup = createAsyncThunk(
    'groups/editGroup',
    async (updates) =>
        await groupService.update(updates.groupId, updates.groupUpdates)
);

export const deleteGroupById = createAsyncThunk(
    'groups/deleteGroupById',
    async (groupId) => {
        const response = await groupService.delete(groupId);
        response.id = groupId;
        return response;
    }
);

export const deleteGroupArray = createAsyncThunk(
    'assets/deleteGroupArray',
    async (groupIds) => {
        const response = await groupService.deleteArray(groupIds);
        response.ids = groupIds;
        return response;
    }
);

const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        setGroupRefreshFlag: (state) => {
            state.groupsStatus = LoadingStatus.Idle;
        },
        resetGroupState: (state) => initialState,
    },
    extraReducers: {
        [fetchGroupsPage.fulfilled]: (state, action) => {
            state.groupsStatus = LoadingStatus.Loaded;
            state.groupPageInfo = action.payload.pageInfo;
            state.groups = action.payload.groups;

            const startIndex =
                (action.meta.arg.page - 1) * action.meta.arg.pageSize;
            const stopIndex = startIndex + state.groups.length - 1;
            const requestedStopIndex =
                startIndex + action.meta.arg.pageSize - 1;

            state.loadedGroupsMap = {};

            for (var i = startIndex; i <= stopIndex; i++) {
                state.loadedGroupsMap[i] = LoadingStatus.Loaded;
            }

            // In case we didn't load as many as requested
            for (var j = stopIndex + 1; j <= requestedStopIndex; j++) {
                delete state.loadedGroupsMap[j];
            }
        },
        [fetchGroupsPage.rejected]: (state, action) => {
            state.groupsStatus = LoadingStatus.Failed;
            state.loadedGroupsMap = {};
        },
        [getGroupById.fulfilled]: (state, action) => {
            state.displayGroup = action.payload;
        },
        [editGroup.fulfilled]: (state, action) => {
            const existingGroup = state.groups.find(
                (group) => group.group_id === action.payload.group_id
            );
            if (existingGroup) {
                existingGroup.display_name = action.payload.display_name;
                existingGroup.description = action.payload.description;
            }
        },
        [addNewGroup.fulfilled]: (state, action) => {
            state.groupsStatus = LoadingStatus.Idle;
        },
        [deleteGroupById.fulfilled]: (state, action) => {
            state.groupsStatus = LoadingStatus.Idle;
        },
        [deleteGroupArray.fulfilled]: (state, action) => {
            state.groupsStatus = LoadingStatus.Idle;
        },
        [deleteGroupArray.rejected]: (state, action) => {
            state.status = SavingStatus.Failed;
            state.error = action.error.message;
        },
        [fetchGroupUsageById.fulfilled]: (state, action) => {
            state.groupUsage = action.payload;
        },
    },
});

export const selectAllGroups = (state) => state.groups.groups;
export const getGroupPageInfo = (state) => state.groups.groupPageInfo;
export const getGroupStatus = (state) => state.groups.groupsStatus;
export const getGroupUsage = (state) => state.groups.groupUsage;

export const { setGroupRefreshFlag, resetGroupState } = groupsSlice.actions;

export default groupsSlice.reducer;
