import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    item: {},
    viewContentPanel: false,
    defaultItemPanelSection: 0,
    activeLinkTab: 0,
    activeLinkContentSubTab: 0,
};

const contentPanelItemSlice = createSlice({
    name: 'contentPanelItem',
    initialState,
    reducers: {
        clearContentPanelItem: (state) => {
            state.item = {};
        },
        setContentPanelItem: (state, action) => {
            state.item = action.payload;
        },
        setViewContentPanel: (state, action) => {
            state.viewContentPanel = action.payload;
        },
        setDefaultItemPanelSection: (state, action) => {
            state.defaultItemPanelSection = action.payload;
        },
        setActiveLinkTab: (state, action) => {
            state.activeLinkTab = action.payload;
        },
        setActiveLinkContentSubTab: (state, action) => {
            state.activeLinkContentSubTab = action.payload;
        },
    },
    extraReducers: {},
});

export const selectDefaultItemPanelSection = (state) =>
    state.contentPanelItem.defaultItemPanelSection;

export const selectContentPanelItem = (state) => state.contentPanelItem.item;
export const selectContentPanelItemlId = (state) =>
    state.contentPanelItem.item?.item_id;

export const selectViewContentPanel = (state) =>
    state.contentPanelItem.viewContentPanel;

export const getActiveLinkTab = (state) => state.contentPanelItem.activeLinkTab;
export const getActiveLinkContentSubTab = (state) =>
    state.contentPanelItem.activeLinkContentSubTab;

export const {
    clearContentPanelItem,
    setContentPanelItem,
    setViewContentPanel,
    setDefaultItemPanelSection,
    setActiveLinkTab,
    setActiveLinkContentSubTab,
} = contentPanelItemSlice.actions;

export default contentPanelItemSlice.reducer;
