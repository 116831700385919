import { config } from 'gemini-config';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import { getNetworkService } from 'GeminiViewerComponent/_helpers';

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});

const baseUrl = `${config.apiUrl}/configfield`;

// debug temp
// eslint-disable-next-line no-unused-vars
let fakeData = [
    {
        config_field_id: 1,
        is_feature: false,
        display_name: 'Access Key',
        key: 'AccessKey',
        validation_type_id: 3,
        category: 'AWS S3 Settings',
        modified_by_id: 1,
        modified_date: Date.now(),
    },
    {
        config_field_id: 2,
        is_feature: false,
        display_name: 'Secret Key',
        key: 'SecretKey',
        validation_type_id: 3,
        category: 'AWS S3 Settings',
        modified_by_id: 1,
        modified_date: Date.now(),
    },
    {
        config_field_id: 3,
        is_feature: false,
        display_name: 'Bucket Name',
        key: 'S3Bucket',
        validation_type_id: 3,
        category: 'AWS S3 Settings',
        modified_by_id: 1,
        modified_date: Date.now(),
    },
    {
        config_field_id: 4,
        is_feature: false,
        display_name: 'Region',
        key: 'Region',
        validation_type_id: 5,
        category: 'AWS S3 Settings',
        modified_by_id: 1,
        modified_date: Date.now(),
    },
    {
        config_field_id: 5,
        is_feature: true,
        display_name: 'Procedures',
        key: 'Procedures',
        validation_type_id: 1,
        category: 'Features',
        modified_by_id: 1,
        modified_date: Date.now(),
    },
    {
        config_field_id: 6,
        is_feature: true,
        display_name: 'Max Zones',
        key: 'MaxZones',
        validation_type_id: 6,
        category: 'Features',
        modified_by_id: 1,
        modified_date: Date.now(),
    },
    {
        config_field_id: 7,
        is_feature: false,
        display_name: 'Name',
        key: 'LrsName',
        validation_type_id: 3,
        category: 'LRS Connection',
        modified_by_id: 1,
        modified_date: Date.now(),
    },
    {
        config_field_id: 8,
        is_feature: false,
        display_name: 'API Key',
        key: 'LrsApiKey',
        validation_type_id: 3,
        category: 'LRS Connection',
        modified_by_id: 1,
        modified_date: Date.now(),
    },
    {
        config_field_id: 9,
        is_feature: false,
        display_name: 'Token',
        key: 'LrsToken',
        validation_type_id: 3,
        category: 'LRS Connection',
        modified_by_id: 1,
        modified_date: Date.now(),
    },
];

// Set 0 config_fields_id = 0 on new types
const convertConfigFieldsToServer = (configFields) => {
    return configFields.map((configField) => {
        if (typeof configField.config_field_id === 'string') {
            configField.config_field_id = 0;
        }
        return configField;
    });
};

const convertConfigFieldsFromServer = (configFields) => {
    return configFields.map((configField) => {
        configField.validation_type_id =
            configField.validation_type.validation_type_id;
        configField.category = configField.category ?? '';
        delete configField.validation_type;
    });
};

const getAll = async (params) => {
    // return fakeData;
    // eslint-disable-next-line no-unreachable
    try {
        const queryParams = {
            sorts: params?.sort ?? 'category,display_name',
        };
        const query = new URLSearchParams(queryParams);

        const response = await networkService.get(`${baseUrl}/?${query}`);
        if (response) {
            convertConfigFieldsFromServer(response.data);
            return response.data;
        }
        return [];
    } catch {
        return [];
    }
};

const updateAll = async (values, params) => {
    // debug temp
    // fakeData = params;
    // return fakeData;
    // eslint-disable-next-line no-unreachable
    try {
        const queryParams = {
            sorts: params?.sort ?? 'category,display_name',
        };
        const query = new URLSearchParams(queryParams);

        const response = await networkService.put(
            `${baseUrl}/multiple/?${query}`,
            {
                config_fields: convertConfigFieldsToServer(values),
            }
        );
        if (response) {
            convertConfigFieldsFromServer(response.data.config_fields);
            return response.data;
        }
        return Promise.reject('Error saving fields');
    } catch (e) {
        return Promise.reject(e);
    }
};

export const configFieldService = {
    getAll,
    updateAll,
};
