import { App } from './app/index.jsx';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from './theme';
// import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './app/store';
import './index.css';
import { config } from './gemini-config';

import StylesProvider from '@mui/styles/StylesProvider';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const generateClassName = createGenerateClassName({
    productionPrefix: 'c',
    disableGlobal: true,
});

startApp();

function startApp() {
    const container = document.getElementById('root');
    const root = createRoot(container);
    const queryClient = new QueryClient();

    root.render(
        // <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter basename={config.routerBaseName}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <StylesProvider generateClassName={generateClassName}>
                            <QueryClientProvider client={queryClient}>
                                <App />
                            </QueryClientProvider>
                        </StylesProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </BrowserRouter>
        </Provider>
        // </React.StrictMode>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
