import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { audienceService } from '_features/_services';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';

const initialState = {
    audiencesStatus: LoadingStatus.Idle,
    audiences: [],
    loadedAudiencesMap: {},
    audienceUsage: [],
};

export const fetchAudiencesPage = createAsyncThunk(
    'groups/fetchAudiencesPage',
    async (params) => {
        const response = await audienceService.getAll(
            params.searchString,
            params.page,
            params.pageSize,
            params.sort
        );
        return response;
    }
);

export const getAudienceById = createAsyncThunk(
    'audiences/getAudienceById',
    async (audienceId) => {
        const response = await audienceService.getById(audienceId);
        response.id = audienceId;
        return response;
    }
);

export const fetchAudienceUsageById = createAsyncThunk(
    'groups/fetchAudienceUsageById',
    async (audienceId) => await audienceService.getAudienceUsageById(audienceId)
);

export const addNewAudience = createAsyncThunk(
    'audiences/addNewAudience',
    async (newAudience) => await audienceService.create(newAudience)
);

export const editAudience = createAsyncThunk(
    'audiences/editAudience',
    async (updates) =>
        await audienceService.update(
            updates.audienceId,
            updates.audienceUpdates
        )
);

export const deleteAudienceById = createAsyncThunk(
    'audiences/deleteAudienceById',
    async (audienceId) => {
        const response = await audienceService.delete(audienceId);
        response.id = audienceId;
        return response;
    }
);

export const deleteAudienceArray = createAsyncThunk(
    'audiences/deleteAudienceArray',
    async (audienceIds) => {
        const response = await audienceService.deleteArray(audienceIds);
        response.id = audienceIds;
        return response;
    }
);

export const addRule = createAsyncThunk('audiences/addRule', async (params) => {
    const response = await audienceService.addRule(params);
    return response;
});

export const removeRule = createAsyncThunk(
    'audiences/removeRule',
    async (params) => {
        const response = await audienceService.removeRule(params);
        return response;
    }
);

const audiencesSlice = createSlice({
    name: 'audiences',
    initialState,
    reducers: {
        resetAudienceState: (state) => initialState,
    },
    extraReducers: {
        [fetchAudiencesPage.pending]: (state, action) => {
            state.audiencesStatus = LoadingStatus.Loaded;
        },
        [fetchAudiencesPage.fulfilled]: (state, action) => {
            state.audiencesStatus = LoadingStatus.Loaded;
            state.audiencesPageInfo = action.payload.pageInfo;
            state.audiences = action.payload.audiences;

            const startIndex =
                (action.meta.arg.page - 1) * action.meta.arg.pageSize;
            const stopIndex = startIndex + state.audiences.length - 1;
            const requestedStopIndex =
                startIndex + action.meta.arg.pageSize - 1;

            state.loadedAudiencesMap = {};

            for (var i = startIndex; i <= stopIndex; i++) {
                state.loadedAudiencesMap[i] = LoadingStatus.Loaded;
            }

            // In case we didn't load as many as requested
            for (var j = stopIndex + 1; j <= requestedStopIndex; j++) {
                delete state.loadedAudiencesMap[j];
            }
        },
        [fetchAudiencesPage.rejected]: (state, action) => {
            state.audiencesStatus = LoadingStatus.Failed;
        },
        [getAudienceById.fulfilled]: (state, action) => {},
        [addNewAudience.fulfilled]: (state, action) => {
            state.audiencesStatus = LoadingStatus.Idle;
        },
        [editAudience.fulfilled]: (state, action) => {
            state.audiencesStatus = LoadingStatus.Idle;
        },
        [deleteAudienceById.fulfilled]: (state, action) => {
            state.audiencesStatus = LoadingStatus.Idle;
        },
        [fetchAudienceUsageById.fulfilled]: (state, action) => {
            state.audienceUsage = action.payload;
        },
        [deleteAudienceArray.fulfilled]: (state, action) => {
            state.audiencesStatus = LoadingStatus.Idle;
        },
        [deleteAudienceArray.rejected]: (state, action) => {
            state.status = SavingStatus.Failed;
            state.error = action.error.message;
        },
    },
});

export const selectAllAudiences = (state) => state.audiences.audiences;
export const getAudiencePageInfo = (state) => state.audiences.audiencePageInfo;
export const getAudienceStatus = (state) => state.audiences.audiencesStatus;
export const getAudienceUsage = (state) => state.audiences.audienceUsage;

export const { resetAudienceState } = audiencesSlice.actions;

export default audiencesSlice.reducer;
