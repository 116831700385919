import React from 'react';
import { useDispatch } from 'react-redux';
import { pinAsset } from '_features/assets/assetsTableSlice';
import { RiPushpin2Line } from 'react-icons/ri';
import { useSnackbar } from 'notistack';

import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { pinZone } from '_features/common/editZoneSlice';
import { pinItem } from '_features/items/itemsTableSlice';

function PinAction({ pinId, level }) {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const pinObjectHandler = async () => {
        dispatch(activateLoading());
        try {
            let resultAction;
            switch (level) {
                case 'asset':
                    resultAction = await dispatch(pinAsset(pinId));
                    break;
                case 'zone':
                    resultAction = await dispatch(pinZone(pinId));
                    break;
                case 'item':
                    resultAction = await dispatch(pinItem(pinId));
                    break;
                default:
                    break;
            }
            const { message, variant } = snackbarHandler(
                resultAction.meta.requestStatus,
                resultAction.payload.isPinned ? 'pin' : 'unpin'
            );
            enqueueSnackbar(message, {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: variant,
            });
            dispatch(deactivateLoading());
        } catch (err) {
            dispatch(deactivateLoading());
            enqueueSnackbar('Pin Update Failed', {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
        }
    };

    return <RiPushpin2Line onClick={pinObjectHandler} />;
}

export { PinAction };
