import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { useCallback, useState } from 'react';
import { CancelDialog } from '../CancelDialog';
import { AttachmentCard } from '../AttachmentCard';
import {
    createMedia,
    updateMedia,
} from 'GeminiViewerComponent/_features/globals/mediaSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    getChecklistData,
    setChecklistData,
} from 'GeminiViewerComponent/components/Procedure/_features/checklistDataSlice';
import { dialogStyles } from 'GeminiViewerComponent/components/Procedure/styles';

const PreviewDialog = ({
    var_name,
    level,
    selectedImages,
    setSelectedImages,
    originalAttachmentData,
    openPreviewDialog,
    setOpenPreviewDialog,
    openFileSelection,
}) => {
    const dispatch = useDispatch();
    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const theme = useSelector(selectActiveTheme);
    const classes = dialogStyles(theme);
    const checklistData = useSelector(getChecklistData);

    const handleSubmit = useCallback(async () => {
        dispatch(activateLoading());
        let isError = false;
        let updatedImages = [];
        if (selectedImages?.length > 0) {
            let response = null;
            for (let [index, imgObj] of selectedImages.entries()) {
                if (imgObj?.isSaved !== true) {
                    if (imgObj?.file) {
                        response = await dispatch(
                            createMedia({
                                display_name: imgObj?.name,
                                media_data: imgObj?.file,
                                draft: false,
                            })
                        );
                    } else {
                        response = await dispatch(
                            updateMedia({
                                display_name: imgObj?.name,
                                media_id: imgObj?.media_id,
                            })
                        );
                    }
                    if (!response.error) {
                        updatedImages[index] = {
                            ...imgObj,
                            url: response.payload?.url,
                            isSaved: true,
                            file: null,
                            media_id: response.payload?.media_id,
                        };
                    } else {
                        updatedImages[index] = imgObj;
                        isError = true;
                    }
                } else {
                    updatedImages[index] = imgObj;
                }
            }
        }
        setSelectedImages(updatedImages);
        if (isError === false) {
            if (checklistData?.imgAtt) {
                dispatch(
                    setChecklistData({
                        imgAtt: {
                            ...checklistData?.imgAtt,
                            [var_name]: updatedImages,
                        },
                    })
                );
            } else {
                dispatch(
                    setChecklistData({
                        imgAtt: { [var_name]: updatedImages },
                    })
                );
            }
            setOpenPreviewDialog(false);
        }
        dispatch(deactivateLoading());
    }, [var_name, level, selectedImages, checklistData]);

    return (
        <>
            <Dialog
                fullWidth
                open={openPreviewDialog}
                onClose={() => setOpenCancelDialog(true)}
                maxWidth={'lg'}
            >
                <DialogTitle display={'flex'} justifyContent={'center'}>
                    <Button onClick={openFileSelection} variant="outlined">
                        Add Images
                    </Button>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        margin={'15px'}
                        display={'flex'}
                        flexDirection={'row'}
                        gap={'10px'}
                        flexWrap={'wrap'}
                        justifyContent={'center'}
                    >
                        {selectedImages?.length > 0 &&
                            selectedImages?.map((image, idx) => (
                                <AttachmentCard
                                    key={`attachment-card-${idx}`}
                                    attachImage={image}
                                    index={idx}
                                    var_name={var_name}
                                    selectedImages={selectedImages}
                                    setSelectedImages={setSelectedImages}
                                />
                            ))}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={classes.cancel}
                        onClick={() => setOpenCancelDialog(true)}
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} className={classes.submit}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <CancelDialog
                originalAttachmentData={originalAttachmentData}
                setSelectedImages={setSelectedImages}
                setOpenPreviewDialog={setOpenPreviewDialog}
                openCancelDialog={openCancelDialog}
                setOpenCancelDialog={setOpenCancelDialog}
            />
        </>
    );
};

export { PreviewDialog };
