import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InputLabel, FormControl, MenuItem, Select } from '@mui/material';

import { accountsSlice } from 'app/store';
import { useConfigValues } from 'hooks/useConfigValues';

export const BatchTranscribeFields = ({ state, setState, handleChange }) => {
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const [defaultConfigValues, setDefaultConfigValues] = useState(null);

    const { configValues, configFields, validationTypes } = useConfigValues({
        clientId: activeUser?.client_id,
        assetId: null,
    });

    useEffect(() => {
        if (Array.isArray(configValues)) {
            let defaultConfig = {};
            var imageAndVideoConfigKeys = ['transcribe_language'];

            let audioVideoConfigFields = configFields?.filter((fld) =>
                imageAndVideoConfigKeys.includes(fld.key)
            );

            audioVideoConfigFields.map((fld) => {
                defaultConfig = {
                    ...defaultConfig,
                    [fld.key]: configValues.find(
                        (data) => data.config_field_id == fld.config_field_id
                    ),
                };
            });

            setDefaultConfigValues(defaultConfig);

            if (Object.keys(defaultConfig).length > 0) {
                setState({
                    transcribe_language:
                        defaultConfig.transcribe_language.value != ''
                            ? defaultConfig.transcribe_language.value
                            : 'Auto Detect',
                });
            }
        }
    }, [configValues, configFields, setState]);

    const getConfigOptionsArray = (
        configFields,
        validationTypes,
        defaultConfigValues,
        key = null
    ) => {
        if (
            Array.isArray(configFields) &&
            Array.isArray(validationTypes) &&
            Object.keys(defaultConfigValues ?? {})?.length > 0 &&
            key
        ) {
            let configFieldId = defaultConfigValues?.[key]?.config_field_id;
            if (configFieldId) {
                let validationTypeId = configFields.find(
                    (fld) => fld.config_field_id === configFieldId
                )?.validation_type_id;
                if (validationTypeId) {
                    let options = validationTypes.find(
                        (valid) =>
                            valid?.validation_type_id === validationTypeId
                    )?.options_array;
                    if (Array.isArray(options)) {
                        return options;
                    }
                }
            }
        }
        return [];
    };

    if (!defaultConfigValues?.transcribe_language) {
        return null;
    }

    return (
        <>
            <FormControl sx={{ margin: '8px 0' }} fullWidth>
                <InputLabel id="transcribe-language-label">
                    {defaultConfigValues.transcribe_language.display_name}
                </InputLabel>
                <Select
                    labelId="transcribe-language-label"
                    name="transcribe_language"
                    id="transcribe-language-id"
                    label={defaultConfigValues.transcribe_language.display_name}
                    value={state.transcribe_language || ''}
                    onChange={handleChange}
                >
                    {getConfigOptionsArray(
                        configFields,
                        validationTypes,
                        defaultConfigValues,
                        'transcribe_language'
                    ).map((opt) => (
                        <MenuItem key={`transcribe_language${opt}`} value={opt}>
                            {opt}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};
