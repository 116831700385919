import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToolsCategoryTable } from './ToolCategoryTable';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    fetchToolCategoriesPage,
    getToolsCategoryPageInfo,
    getToolsCategoryStatus,
    resetCategoryState,
    selectToolsCategories,
} from '_features/tools/toolsCategorySlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

const CategoryTab = () => {
    //#region Constants
    //#endregion Constants

    //#region Hooks
    const dispatch = useDispatch();
    const ref = useRef(false);
    //#endregion Hooks

    //#region State
    const [categoryPayload, setCategoryPayload] = useState({});
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    //#endregion State

    //#region Selectors
    const categories = useSelector(selectToolsCategories);
    const categoryStatus = useSelector(getToolsCategoryStatus);
    const categoriesPageInfo = useSelector(getToolsCategoryPageInfo);
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    useEffect(() => {
        if (ref.current === true) {
            getAllCategories({ ...categoryPayload });
        }
    }, [categoryPayload]);

    useEffect(() => {
        return () => {
            dispatch(resetCategoryState());
        };
    }, []);

    useEffect(() => {
        if (categoryStatus === LoadingStatus.Idle) {
            getAllCategories({ ...categoryPayload });
        }
        ref.current = true;
    }, [categoryStatus]);
    //#endregion Effects

    //#region Methods
    const getAllCategories = async (payload) => {
        dispatch(activateLoading());
        await dispatch(fetchToolCategoriesPage(payload));
        dispatch(deactivateLoading());
    };

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        setCategoryPayload({
            ...categoryPayload,
            sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
        });
    };
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render
    return (
        <ToolsCategoryTable
            categories={categories}
            pageInfo={categoriesPageInfo}
            categoryPayload={categoryPayload}
            setCategoryPayload={setCategoryPayload}
            sortDirection={sortDirection}
            sortBy={sortBy}
            handleSort={(_sortBy, _sortDirection) =>
                setSorting(_sortBy, _sortDirection)
            }
        />
    );
    //#endregion Render
};

export { CategoryTab };
