import { aboutInfoStyles } from './style';
import about_bgimg from 'assets/gemini_splash_image.jpg';
import hg_logo from 'assets/hg_logo.png';
import hvtc_logo_title from 'assets/hvtc_logo_title_page.png';
import {
    Box,
    Dialog,
    DialogContent,
    Grid,
    Link,
    Typography,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { ReleaseNotesModal } from './ReleaseNotesPdf';
import {
    isAboutModalOpen,
    showAboutModal,
} from 'GeminiViewerComponent/_features/common/actionModalSlice';
import { accountsSlice, accountsThunk } from 'app/store';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { config } from 'gemini-config';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

const { selectApiVersion } = accountsSlice;
const { getApiVersion } = accountsThunk;

const AboutModal = () => {
    //#region Constants
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const classes = aboutInfoStyles(theme);

    const versionRegex = /(\d+\.\d+\.\d+)/;
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    const [isReleaseNotesOpen, setIsReleaseNotesOpen] = useState(false);
    const [releaseNotesPdf, setReleaseNotesPdf] = useState([]);
    //#endregion State

    //#region Selectors
    const open = useSelector(isAboutModalOpen);
    const apiVersion = useSelector(selectApiVersion);
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    useEffect(() => {
        const fetchData = async () => {
            dispatch(activateLoading());
            await dispatch(getApiVersion());
            dispatch(deactivateLoading());
        };
        fetchData();
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const importAll = (r) => {
            return r.keys().map((key) => ({
                version: key.replace('./', '').replace('.pdf', ''),
                url: r(key),
            }));
        };
        const releaseNoteFiles = importAll(
            require.context('../../../releasenotes', false, /^\.\/[^/]+\.pdf$/)
        );
        setReleaseNotesPdf(releaseNoteFiles);
    }, []);
    //#endregion Effects

    //#region Methods
    const handleClose = () => {
        dispatch(showAboutModal(false));
    };

    const handleOpenReleaseNotesModal = () => {
        setIsReleaseNotesOpen(true);
    };
    const versions = extractVersions(releaseNotesPdf);

    function extractVersions(fileKeys) {
        const pdfVersion = {};
        fileKeys.forEach((fileName) => {
            const match = fileName?.version?.match(versionRegex);
            if (match && match[1]) {
                const a = match[1];
                pdfVersion[a] = fileName;

                return pdfVersion;
            }
        });
        return pdfVersion;
    }
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="responsive-dialog-title"
                maxWidth="md"
                className={classes.aboutModalWrapper}
            >
                <button
                    aria-label="close"
                    onClick={handleClose}
                    style={{
                        position: 'absolute',
                        fontSize: '1.3rem',
                        right: 0,
                        top: 0,
                        color: (theme) => theme.palette.grey[500],
                        backgroundColor: '#ffffff',
                        transform: 'translate(35%, -35%)',
                        boxShadow: '0 0 12px 0 rgba(0,0,0,0.5)',
                        borderRadius: '50%',
                        width: '35px',
                        height: '35px',
                        border: '0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <MdClose />
                </button>
                <DialogContent sx={{ padding: '0' }}>
                    <Grid container spacing={0}>
                        <Grid
                            item
                            xs={12}
                            md={5}
                            sx={{
                                padding: '1rem',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img src={hg_logo} alt="Hg" width="60" />
                                <div>
                                    <Typography
                                        fontSize="22px"
                                        fontWeight="600"
                                        display="flex"
                                        paddingLeft="5px"
                                    >
                                        HVTC Gemini
                                        <span
                                            style={{
                                                fontSize: '14px',
                                                padding: '3px',
                                                lineHeight: '14px',
                                                alignSelf: 'flex-start',
                                            }}
                                        >
                                            TM
                                        </span>
                                    </Typography>
                                </div>
                            </Box>
                            <Box
                                sx={{
                                    margin: {
                                        xs: '1rem 0 auto',
                                        md: '4rem 0 auto',
                                    },
                                    paddingY: '2rem',
                                }}
                            >
                                <Typography
                                    className={classes.aboutContent}
                                    sx={{ paddingBottom: '1rem' }}
                                >
                                    2022 - {new Date().getFullYear()} HVTC All
                                    rights reserved
                                </Typography>
                                <Typography
                                    className={classes.aboutContent}
                                    sx={{ paddingBottom: '1rem' }}
                                >
                                    Web Version: {config.appVersion}{' '}
                                    {versions[config.appVersion]?.url && (
                                        <Link
                                            target="_blank"
                                            href={
                                                versions[config.appVersion]?.url
                                            }
                                        >
                                            Release Notes
                                        </Link>
                                    )}
                                </Typography>
                                <Typography className={classes.aboutContent}>
                                    API Version: {apiVersion}
                                </Typography>
                                <Link
                                    onClick={handleOpenReleaseNotesModal}
                                    variant="contained"
                                    sx={{
                                        mt: '1rem',
                                        display: 'inline-block',
                                        cursor: 'pointer',
                                    }}
                                >
                                    View All Release Notes
                                </Link>
                            </Box>
                            <img
                                src={hvtc_logo_title}
                                alt="HVTC"
                                width="180"
                                style={{ marginBottom: '1rem' }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={7}
                            sx={{
                                padding: '1rem',
                                display: { xs: 'none', md: 'flex' },
                            }}
                            className={classes.borderRounded}
                        >
                            <img
                                src={about_bgimg}
                                alt="hvtc Background"
                                className={classes.imgResponsive}
                            ></img>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <ReleaseNotesModal
                isReleaseNotesOpen={isReleaseNotesOpen}
                setIsReleaseNotesOpen={setIsReleaseNotesOpen}
                releaseNotesPdf={releaseNotesPdf}
            />
        </>
    );
    //#endregion Render
};
export { AboutModal };
