import { InputLabel, FormControl, MenuItem, Select, Box } from '@mui/material';
import { Field } from 'formik';
import FieldValidationError from './FieldValidationError';
import { ImageAttachments } from '../../ImageAttachments';

const SelectField = ({
    data,
    fieldValue,
    setFieldValue,
    validationError,
    handleBlur,
    inGroup = false,
    isReadOnly = false,
    isSubmitAllow = true,
    handleClick = () => {},
}) => {
    const selectFieldData = (
        <FormControl style={{ width: '100%' }} error={!!validationError}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Field name={data.variable}>
                    {({ field }) => (
                        <>
                            {data.hide_label !== true && (
                                <InputLabel id={`label-${data.variable}`}>
                                    {data.label}
                                </InputLabel>
                            )}

                            <Select
                                {...field}
                                value={fieldValue[data.variable] ?? ''} // null causes issues: https://github.com/mui/material-ui/issues/4991#issuecomment-552446934
                                fullWidth
                                labelId={`label-${data.variable}`}
                                id={data.variable}
                                label={
                                    data.hide_label === true ? '' : data.label
                                }
                                disabled={!isSubmitAllow}
                                sx={{
                                    '& .Mui-disabled': {
                                        WebkitTextFillColor:
                                            '#000000 !important',
                                    },
                                }}
                                onChange={(e) => {
                                    if (isSubmitAllow) {
                                        setFieldValue(
                                            data.variable,
                                            e.target.value
                                        );
                                    }
                                }}
                                onClick={(e) => {
                                    if (!isSubmitAllow) {
                                        e.stopPropagation();
                                        handleClick(e, data);
                                    }
                                }}
                                onBlur={() =>
                                    isSubmitAllow && handleBlur(fieldValue)
                                }
                            >
                                {data.options?.length > 0 &&
                                    data.options.map((opt, idx) => (
                                        <MenuItem key={idx} value={opt.value}>
                                            {opt.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </>
                    )}
                </Field>
                {data.image_attachments_allow && (
                    <ImageAttachments
                        data={data}
                        var_name={data.variable}
                        level="field"
                        isReadOnly={!isSubmitAllow}
                        handleClick={handleClick}
                    />
                )}
            </Box>
            {validationError && (
                <FieldValidationError validationError={validationError} />
            )}
        </FormControl>
    );
    return inGroup ? (
        <div
            style={{
                display: 'inline-flex',
                width: data?.field_width ? data.field_width + '%' : '100%',
                padding: '13px 5px 5px',
            }}
        >
            {selectFieldData}
        </div>
    ) : (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    display: 'inline-flex',
                    width: data?.field_width ? data.field_width + '%' : '100%',
                    padding: '5px',
                }}
            >
                {selectFieldData}
            </div>
        </div>
    );
};

export default SelectField;
