import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';

import { useStyles } from '../../styles';
import { accountsSlice } from 'app/store';
const { selectActiveUser } = accountsSlice;
import { prefixUrl } from 'GeminiViewerComponent/_helpers';
import { Each } from 'GeminiViewerComponent/_helpers/Each';
import { ProcedureNodeTypes } from '../../ProcedureNodeTypes';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    getActiveTree,
    getCurrentNode,
    handleNodeAction,
} from '../../_features/procedureSlice';
import {
    displayProcedureContentVersion,
    loadAssociateAsset,
    loadChecklist,
} from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import { selectAsset } from 'GeminiViewerComponent/_features/asset/assetSlice';

const NavigationLink = ({
    currentNode,
    navigationLinks,
    setIsBranchProcedure,
}) => {
    const dispatch = useDispatch();
    const config = useSelector(getConfig);
    const activeUser = useSelector(selectActiveUser);
    const activeTree = useSelector(getActiveTree);
    const getAsset = useSelector(selectAsset);

    return navigationLinks && navigationLinks.length > 0 ? (
        <Each
            of={navigationLinks}
            render={(node) => (
                <ListItemButton
                    className={clsx([
                        'navigationLinkButton',
                        node.id === currentNode.id
                            ? 'activeNavigationLink'
                            : 'navigationPanel',
                    ])}
                    sx={{ pl: 4 }}
                    onClick={async () => {
                        if (node.type === ProcedureNodeTypes.branch.type) {
                            setIsBranchProcedure(true);
                        }
                        await dispatch(
                            handleNodeAction({
                                action: {
                                    id: node.id,
                                    type: 'node',
                                },
                                addToHistory: true,
                            })
                        );
                        if (
                            !node.close_associated_view &&
                            node.asset_id &&
                            node.zone_id &&
                            config?.modeType === 'viewer'
                        ) {
                            const navigateAsset = getAsset(
                                node.asset_id,
                                node.zone_id
                            );
                            if (!navigateAsset) {
                                return;
                            }

                            dispatch(loadAssociateAsset(true));
                            await dispatch(
                                displayProcedureContentVersion({
                                    procedure: {
                                        procedure_id: activeTree?.procedure_id,
                                        procedure_json:
                                            JSON.stringify(activeTree),
                                        header_image_url: prefixUrl(
                                            activeTree?.header_image_url,
                                            activeUser.s3_prefix
                                        ),
                                    },
                                    doAction: node,
                                    addToHistory: true,
                                })
                            );
                        }
                        if (
                            node.type ===
                            ProcedureNodeTypes.navigationAction.type
                        ) {
                            dispatch(loadChecklist(false));
                        }
                    }}
                >
                    <ListItemText primary={node.title || '-'} />
                </ListItemButton>
            )}
        />
    ) : (
        <ListItemButton
            className={clsx(['navigationLinkButton', 'navigationPanel'])}
            disabled
            sx={{ pl: 4 }}
        >
            <ListItemText primary="No Node" />
        </ListItemButton>
    );
};

const NoneCategory = ({
    open,
    currentNode,
    handleClick,
    navigationLinks,
    setIsBranchProcedure,
}) => {
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);

    return (
        navigationLinks.filter((link) => !link?.node_category).length > 0 && (
            <List component="div" disablePadding>
                <ListItemButton
                    className={classes.categoryButton}
                    onClick={() => handleClick(0)}
                >
                    <ListItemText
                        sx={{
                            '& span': {
                                fontWeight: 'bold',
                            },
                        }}
                        primary="NONE"
                    />
                    {open[0] ? <MdExpandLess /> : <MdExpandMore />}
                </ListItemButton>
                <Collapse
                    sx={{ marginLeft: '10px' }}
                    in={open[0]}
                    timeout="auto"
                    unmountOnExit
                >
                    <NavigationLink
                        currentNode={currentNode}
                        navigationLinks={navigationLinks.filter(
                            (link) => !link?.node_category
                        )}
                        setIsBranchProcedure={setIsBranchProcedure}
                    />
                </Collapse>
            </List>
        )
    );
};

const NodeCategoryPanel = ({
    navigationLinks,
    procedureCategories,
    setIsBranchProcedure,
}) => {
    const currentNode = useSelector(getCurrentNode);
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);

    const [open, setOpen] = useState(
        new Array(procedureCategories?.length).fill(false)
    );

    const handleClick = async (index) => {
        let accordian = [...open];
        accordian[index] = !accordian[index];
        setOpen(accordian);
    };

    useEffect(() => {
        let accordian = new Array(procedureCategories?.length).fill(false);
        if (currentNode?.node_category?.id) {
            accordian[currentNode?.node_category?.id] =
                !accordian[currentNode?.node_category?.id];
        } else {
            accordian[0] = !accordian[0];
        }
        setOpen(accordian);
    }, [currentNode]);

    const anyNodesWithoutCategories = navigationLinks.find(
        (link) => !link?.node_category
    );

    return (
        <>
            <Each
                of={procedureCategories}
                render={(category) =>
                    navigationLinks.filter(
                        (link) => link?.node_category?.id === category.id
                    ).length > 0 && (
                        <List component="div" disablePadding>
                            <ListItemButton
                                className={classes.categoryButton}
                                onClick={() => handleClick(category?.id)}
                                tabIndex={category?.id}
                            >
                                <ListItemText
                                    sx={{
                                        '& span': {
                                            fontWeight: 'bold',
                                        },
                                    }}
                                    primary={category?.name}
                                />
                                {open[category?.id] ? (
                                    <MdExpandLess />
                                ) : (
                                    <MdExpandMore />
                                )}
                            </ListItemButton>
                            <Collapse
                                in={open[category?.id]}
                                timeout="auto"
                                sx={{ marginLeft: '10px' }}
                                unmountOnExit
                            >
                                <NavigationLink
                                    currentNode={currentNode}
                                    navigationLinks={navigationLinks.filter(
                                        (link) =>
                                            link?.node_category?.id ===
                                            category.id
                                    )}
                                    setIsBranchProcedure={setIsBranchProcedure}
                                />
                            </Collapse>
                        </List>
                    )
                }
            />
            {anyNodesWithoutCategories && (
                <NoneCategory
                    open={open}
                    currentNode={currentNode}
                    handleClick={handleClick}
                    navigationLinks={navigationLinks}
                    setIsBranchProcedure={setIsBranchProcedure}
                />
            )}
        </>
    );
};

export { NodeCategoryPanel };
