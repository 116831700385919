import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import {
    getShowFlyerImg,
    setShowFlyer,
} from '../../../../_features/globals/flyerSlice';
import { flyerViewStyles } from './styles';

const FlyerView = () => {
    const dispatch = useDispatch();
    const classes = flyerViewStyles();
    const flyerImg = useSelector((state) => getShowFlyerImg(state));

    const handleClose = () => {
        dispatch(setShowFlyer({ showFlyer: false, flyerImg: '' }));
    };

    return (
        <div className={classes.flyerView}>
            <IoMdCloseCircleOutline
                className={classes.closeIcon}
                onClick={handleClose}
            />
            <img src={flyerImg} alt="" />
        </div>
    );
};

export { FlyerView };
