import { useRef, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { IconButton } from '@mui/material';
import useResizeObserver from 'use-resize-observer';
import { MdMoreVert, MdSearch } from 'react-icons/md';

import { SceneMenu } from '../SceneMenu';
import { SearchMenu } from '../SearchMenu';
import { makeCommonStyles } from './styles';
import { ViewControls } from './ViewControls';
import { searchMenuStyles } from '../SearchMenu/styles';
import { selectZoneLoadingStatus } from 'shared/loadZoneSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

const GeminiTitleBar = ({
    hideNavOptions,
    activeZone,
    zonesByCategory,
    onCategoryClick,
    onSearchItems,
    searchItemSelected,
    handleResetPosition,
    onClose,
    onClickRefreshItem,
    handleItemDragging,
    handleNavDragging,
    enableItemDragging,
    enableNavDragging,
    undoRedoProps,
    isOrbitMode,
    tridiRef,
    setSplitViewTopPos,
    zoneMediaConfigValue = false,
}) => {
    const theme = useSelector(selectActiveTheme);
    const commonStyles = makeCommonStyles(theme);
    const zoneLoadingStatus = useSelector((state) =>
        selectZoneLoadingStatus(state)
    );
    const titleBar = useRef();
    const classes = searchMenuStyles();
    const [searchMenu, setSearchMenu] = useState(false);
    const [controlMenu, setControlMenu] = useState(false);
    const muiTheme = useTheme();
    const mdWidth = muiTheme.breakpoints.values.md;
    const { width } = useResizeObserver({ ref: titleBar });

    return (
        <>
            <div
                ref={titleBar}
                id="titleContainer"
                className={clsx(commonStyles.titleContainer, {
                    [commonStyles.showLoadingEffect]:
                        zoneLoadingStatus !== 'Loaded',
                    [commonStyles.hideLoadingEffect]:
                        zoneLoadingStatus === 'Loaded',
                })}
            >
                {!hideNavOptions.hideNavBar && (
                    <div className={commonStyles.titleBarContent}>
                        {!hideNavOptions.hideNavZones && (
                            <SceneMenu
                                onCategoryClick={onCategoryClick}
                                zonesByCategory={zonesByCategory}
                                searchMenu={searchMenu}
                                controlMenu={controlMenu}
                            />
                        )}
                        {!hideNavOptions.hideNavSearch &&
                            (width < mdWidth ? (
                                <div className={classes.searchMenu}>
                                    <IconButton
                                        sx={{ p: 0 }}
                                        className={classes.searchIcon}
                                        onClick={() => {
                                            setSearchMenu(!searchMenu);
                                        }}
                                    >
                                        <MdSearch
                                            className="react-icon"
                                            style={{ color: 'white' }}
                                        />
                                    </IconButton>
                                </div>
                            ) : (
                                <SearchMenu
                                    onSearchItems={onSearchItems}
                                    searchItemSelected={searchItemSelected}
                                />
                            ))}
                        {!hideNavOptions.hideNavTitle && (
                            <h1
                                className={clsx(
                                    'sceneName',
                                    commonStyles.sceneName
                                )}
                            >
                                {activeZone.display_name}
                            </h1>
                        )}
                        {!hideNavOptions.hideNavControls &&
                            (width < mdWidth ? (
                                <div className={classes.controlIcon}>
                                    <IconButton
                                        sx={{ p: 0 }}
                                        onClick={() => {
                                            setControlMenu(!controlMenu);
                                        }}
                                    >
                                        <MdMoreVert
                                            className="react-icon"
                                            style={{ color: 'white' }}
                                        />
                                    </IconButton>
                                </div>
                            ) : (
                                <ViewControls
                                    className={commonStyles.orbitControls}
                                    onResetPosition={handleResetPosition}
                                    showOrientation={true}
                                    onClose={onClose}
                                    onClickRefreshItem={onClickRefreshItem}
                                    enableItemDragging={enableItemDragging}
                                    enableNavDragging={enableNavDragging}
                                    handleItemDragging={handleItemDragging}
                                    handleNavDragging={handleNavDragging}
                                    undoRedoProps={undoRedoProps}
                                    tridiRef={isOrbitMode ? tridiRef : null}
                                    zoneMediaConfigValue={zoneMediaConfigValue}
                                />
                            ))}
                    </div>
                )}
            </div>
            {width < mdWidth && (
                <div
                    id="titleContainer"
                    className={clsx(commonStyles.titleBar, {
                        [commonStyles.showLoadingEffect]:
                            zoneLoadingStatus !== 'Loaded',
                        [commonStyles.hideLoadingEffect]:
                            zoneLoadingStatus === 'Loaded',
                    })}
                >
                    {!hideNavOptions.hideNavSearch && searchMenu && (
                        <SearchMenu
                            style={{ width: '100%' }}
                            onSearchItems={onSearchItems}
                            searchItemSelected={searchItemSelected}
                        />
                    )}
                    {!hideNavOptions.hideNavControls && controlMenu && (
                        <ViewControls
                            style={{ width: '100%' }}
                            className={commonStyles.orbitControls}
                            onResetPosition={handleResetPosition}
                            showOrientation={true}
                            onClose={onClose}
                            onClickRefreshItem={onClickRefreshItem}
                            enableItemDragging={enableItemDragging}
                            enableNavDragging={enableNavDragging}
                            handleItemDragging={handleItemDragging}
                            handleNavDragging={handleNavDragging}
                            undoRedoProps={undoRedoProps}
                            tridiRef={isOrbitMode ? tridiRef : null}
                            zoneMediaConfigValue={zoneMediaConfigValue}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export { GeminiTitleBar };
