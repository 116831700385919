import { makeStyles } from '@mui/styles';

const orbitPreviewsStyles = makeStyles(() => ({
    scenePreviewsContainer: {
        flexGrow: 1,
        position: 'relative',
        width: '0px',
    },
    scenePreviews: {
        display: 'flex',
        cursor: 'default',
        alignContent: 'center',
        pointerEvents: 'auto',
        transformOrigin: '50% 50%',
        width: 'min-content',
        margin: '0',
        padding: '0',
        height: 'auto',
        maxHeight: '0px',
        opacity: 0,
        listStyle: 'none',
        transition: 'max-height 0.2s ease-out',
        position: 'absolute',
        left: '5px',
        top: '0px',
    },
    sceneOpen: {
        maxHeight: '100px',
        opacity: 1,
        visibility: 'inherit',
        transition: 'max-height 0.2s ease-in',
        overflowY: 'hidden',
        width: 'calc(100vw - 10px)',
    },
    scenePreviewContainer: {
        position: 'relative',
        width: 'auto',
        minWidth: '130px',
        maxWidth: '170px',
        padding: '0',
        '&:hover': {
            transform: 'scale(0.95)',
        },
        display: 'flex',
        overflow: 'hidden',
    },
    sceneImage: {
        border: '3px solid rgb(150, 150, 150)',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    sceneNoImage: {
        backgroundColor: 'transparent',
        color: 'white',
        lineHeight: '100px',
        textAlign: 'center',
        padding: '4px',
    },
    sceneActive: {
        border: '3px solid rgb(0, 0, 0)',
    },
    scenePreviewTitle: {
        position: 'absolute',
        fontSize: '12px',
        height: 'auto',
        lineHeight: 'auto',
        width: '100%',
        padding: '2px',
        margin: '0',
        left: '0px',
        bottom: '0px',
        textAlign: 'center',
        backgroundColor: (props) => props.colors.primarySectionBackground,
        color: (props) => props.colors.primarySectionForeground,
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        overflow: 'hidden',
    },
    scenePreviewTitleActive: {
        backgroundColor: (props) => props.colors.secondarySectionBackground,
    },
    categoryContainer: {
        display: 'flex',
        gap: '5px',
    },
    categoryTitle: {
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg)',
        border: 'solid white 1px',
        textAlign: 'center',
        backgroundColor: 'black',
        color: 'white',
        padding: '6px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    categoryZones: {
        display: 'flex',
        width: 'auto',
        maxWidth: '0px',
        overflow: 'hidden',
        transition: 'max-width 0.2s ease-out',
        '&.categoryOpen': {
            maxWidth: '100%',
        },
    },
}));

export { orbitPreviewsStyles };
