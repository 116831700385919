import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        padding: '10px',
        backgroundColor: '#ECF0F1',
        borderRadius: '5px',
        marginTop: '10px',
    },
    paper: {
        width: '100%',
        boxShadow: 'unset',
    },
    inputRoot: {
        color: 'inherit',
        border: '1px solid #BDC3C7',
        borderRadius: '5px',
        marginRight: '10px',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `10px`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        '& .MuiInput-underline:before': {
            borderBottom: 'unset !important',
        },
        '& .MuiInputBase-root': {
            border: '1px solid #BDC3C7',
            borderRadius: '5px',
            padding: '2px 10px',
        },
    },
    formInput: {
        marginRight: '5px',
    },
    formInputs: {
        display: 'flex',
        alignItems: 'center',
    },
    buttonContainer: {
        float: 'right',
        marginTop: '10px',
    },
    buttonSpacing: {
        margin: '0px 5px',
    },
    tableHeader: {
        cursor: 'pointer',
        textTransform: 'uppercase',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#34485E',
    },
    directionArrow: {
        height: '20px',
        width: '20px',
        color: '#34485E',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    hidden: {
        display: 'none',
    },
}));
