import { useCallback } from 'react';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { CheckboxWithLabel } from 'formik-mui';
import { useDispatch, useSelector } from 'react-redux';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import {
    Autocomplete,
    Box,
    Checkbox,
    FormControl,
    TextField,
} from '@mui/material';

import { AssetOutputFields } from '_helpers';
import { makeFormStyles } from '../styles';
import SetDirtyForm from 'forms/SetDirtyForm';
import { FormTextField } from 'components/TextField';
import { closePanel } from '_features/common/formSlice';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { MultiSelectDoneButton } from 'GeminiViewerComponent/components/MultiSelectDoneButton';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import * as Yup from 'yup';
import FieldValidationError from 'GeminiViewerComponent/components/Procedure/components/ProcedureFormNode/FormFields/FieldValidationError';
import { exportAssetDetails } from '_features/zones/zonesTableSlice';

const INIT_VALUES = {
    output_fields: [],
    fill_all_fields: false,
    include_header: false,
    include_items: false,
    include_nav_links: false,
    include_links: false,
};

const ExportAssetDetailsForm = ({ formAction, contentPayload }) => {
    //#region Constants
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    //#endregion State

    //#region Selectors
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    //#endregion Effects

    //#region Methods
    const handleSubmit = useCallback(
        async (values, setSubmitting, resetForm, setErrors) => {
            let exportFilters = {
                include_header: values.include_header,
                include_items: values.include_items,
                include_nav_links: values.include_nav_links,
                include_links: values.include_links,
                fill_all_fields: values.fill_all_fields,
                assetId: contentPayload?.assetId,
            };
            if (values?.fill_all_fields) {
                exportFilters = { ...exportFilters, ...contentPayload };
            }
            if (values.output_fields && values.output_fields.length > 0) {
                exportFilters = {
                    ...exportFilters,
                    output_fields: values.output_fields
                        .map((field) => field.value)
                        .join(','),
                };
            }
            dispatch(activateLoading());
            try {
                const resultAction = await dispatch(
                    exportAssetDetails(exportFilters)
                );
                if (!resultAction.payload?.error && !resultAction?.error) {
                    dispatch(closePanel({ formKey: 'exportAssetDetailsForm' }));
                } else {
                    if (resultAction?.payload?.error?.errors) {
                        setErrors({ ...resultAction?.payload?.error?.errors });
                    } else if (resultAction?.error?.message) {
                        throw new Error(resultAction.error.message);
                    } else {
                        throw new Error(resultAction?.payload?.error);
                    }
                }
            } catch (err) {
                enqueueSnackbar(
                    err?.message !== undefined ? err.message : 'Create Failed',
                    {
                        action: (key) => <SnackbarDismiss key={key} />,
                        variant: 'error',
                    }
                );
            }
            dispatch(deactivateLoading());
            setSubmitting(false);
        },
        [contentPayload]
    );
    const validationSchema = Yup.object().shape({
        include_header: Yup.boolean()
            .oneOf([true], 'Required')
            .required('Required'),
        output_fields: Yup.array().min(
            1,
            'At least one output field is required'
        ),
    });
    //#endregion Methods

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...INIT_VALUES }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
                handleSubmit(values, setSubmitting, resetForm, setErrors);
            }}
        >
            {({ values, isSubmitting, setFieldValue, errors, touched }) => (
                <Form className={classes.form}>
                    <SetDirtyForm />
                    <Box className={classes.formHeader}>
                        Download CSV Asset Details
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FormTextField
                            component={CheckboxWithLabel}
                            Label={{
                                label: 'Include Header',
                            }}
                            label="Include Header"
                            type="checkbox"
                            checked={!!values.include_header}
                            onChange={(evt) => {
                                setFieldValue(
                                    'include_header',
                                    evt.target.checked
                                );
                            }}
                        />
                        {errors.include_header && (
                            <FieldValidationError
                                validationError={errors.include_header}
                            />
                        )}
                    </Box>
                    <FormTextField
                        component={CheckboxWithLabel}
                        Label={{
                            label: 'Fill all fields',
                        }}
                        label="Fill all fields"
                        type="checkbox"
                        checked={!!values.fill_all_fields}
                        onChange={(evt) => {
                            setFieldValue(
                                'fill_all_fields',
                                evt.target.checked
                            );
                        }}
                    />
                    <FormTextField
                        component={CheckboxWithLabel}
                        Label={{
                            label: 'Hotspot Items',
                        }}
                        label="Hotspot Items"
                        type="checkbox"
                        checked={!!values.include_items}
                        onChange={(evt) => {
                            setFieldValue('include_items', evt.target.checked);
                        }}
                    />
                    <FormTextField
                        component={CheckboxWithLabel}
                        Label={{
                            label: 'Navigation Links',
                        }}
                        label="Navigation Links"
                        type="checkbox"
                        checked={!!values.include_nav_links}
                        onChange={(evt) => {
                            setFieldValue(
                                'include_nav_links',
                                evt.target.checked
                            );
                        }}
                    />
                    <FormTextField
                        component={CheckboxWithLabel}
                        Label={{
                            label: 'Item Links',
                        }}
                        label="Item Links"
                        type="checkbox"
                        checked={!!values.include_links}
                        onChange={(evt) => {
                            setFieldValue('include_links', evt.target.checked);
                        }}
                    />
                    <FormControl margin="dense">
                        <Autocomplete
                            fullWidth
                            limitTags={2}
                            multiple
                            variant="outline"
                            id="output_fields"
                            name="output_fields"
                            value={values.output_fields}
                            options={['', ...AssetOutputFields]}
                            onChange={(event, newInputValue) => {
                                if (newInputValue.includes('')) {
                                    if (
                                        newInputValue.filter(Boolean).length ===
                                        AssetOutputFields.length
                                    ) {
                                        setFieldValue('output_fields', []);
                                    } else {
                                        setFieldValue(
                                            'output_fields',
                                            AssetOutputFields
                                        );
                                    }
                                } else {
                                    setFieldValue(
                                        'output_fields',
                                        newInputValue
                                    );
                                }
                            }}
                            PaperComponent={MultiSelectDoneButton}
                            getOptionLabel={(option) => option.name || ''}
                            disableCloseOnSelect
                            renderOption={(props, option, { selected }) => (
                                <li
                                    {...props}
                                    key={props['data-option-index']}
                                    style={{
                                        padding: '0',
                                        borderBottom: `${
                                            !option && '1px solid #4a4a4a'
                                        }`,
                                    }}
                                >
                                    <Checkbox
                                        icon={
                                            <MdCheckBoxOutlineBlank className="react-icon" />
                                        }
                                        checkedIcon={
                                            <MdCheckBox className="react-icon" />
                                        }
                                        style={{
                                            marginRight: 8,
                                        }}
                                        indeterminate={
                                            !option &&
                                            values.output_fields.length > 0 &&
                                            values.output_fields.length !==
                                                AssetOutputFields.length
                                        }
                                        checked={
                                            selected ||
                                            values.output_fields.length ===
                                                AssetOutputFields.length
                                        }
                                    />
                                    {option.name}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Output Fields"
                                    placeholder="Output Fields"
                                />
                            )}
                        />
                        {errors.output_fields && touched.output_fields && (
                            <FieldValidationError
                                validationError={errors.output_fields}
                            />
                        )}
                    </FormControl>
                    <button
                        className={classes.submit}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        Submit
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export { ExportAssetDetailsForm };
