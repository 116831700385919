import React from 'react';
import { Box, Divider, Popover } from '@mui/material';

import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { Action } from 'GeminiViewerComponent/_helpers/action';

const FieldPreviewPopup = ({ anchorEl, setAnchorEl, selectedField = null }) => {
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClose = () => setAnchorEl(null);

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    overflow: 'visible',
                },
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    mt: '10px',
                    '&::before': {
                        backgroundColor: 'white',
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        width: 12,
                        height: 12,
                        top: -6,
                        transform: 'rotate(45deg)',
                        left: 'calc(50% - 6px)',
                        borderWidth: '1px 0 0 1px',
                        borderColor: 'lightgray',
                        borderStyle: 'solid',
                        boxSizing: 'border-box',
                    },
                }}
            />
            <Box
                sx={{
                    p: 2,
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    boxShadow:
                        '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 0px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
                }}
            >
                <PopupAction
                    action={Action.Edit}
                    object={{
                        selectedField: selectedField,
                        has_group: selectedField?.has_group,
                    }}
                    level="formField"
                    showLabel={true}
                    onActionHandled={handleClose}
                />
                <Divider />
                <PopupAction
                    action={Action.Delete}
                    object={selectedField?.id}
                    level="formField"
                    showLabel={true}
                    onActionHandled={handleClose}
                />
                <Divider />
                <PopupAction
                    action={Action.Duplicate}
                    object={selectedField}
                    level="formField"
                    showLabel={true}
                    onActionHandled={handleClose}
                />
            </Box>
        </Popover>
    );
};

export { FieldPreviewPopup };
