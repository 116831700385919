import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import interceptClicks from 'GeminiViewerComponent/_helpers/interceptClicks';
import {
    findVideoChapter,
    setLinkDisplayVis,
} from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import { saveStatement } from 'GeminiViewerComponent/_features/xAPI/xapiSlice';
import { LrsVerbs } from 'GeminiViewerComponent/_features/xAPI/xApiData';
import { getDependencyContents } from 'GeminiViewerComponent/_features/asset/assetSlice';
import { prefixUrl } from 'GeminiViewerComponent/_helpers';
import { accountsSlice } from 'app/store';
import { setCompanionApp } from 'GeminiViewerComponent/_features/companionApps/companionAppsSlice';
import { gotoNode } from 'GeminiViewerComponent/components/Procedure/_features/procedureSlice';
import { useZoneNav } from 'shared/hooks/useZoneNav';

const { selectActiveUser } = accountsSlice;

export default function useContentLinkInterceptor(
    selector,
    contentService,
    handleNext = null,
    handleBack = null
) {
    const dispatch = useDispatch();
    const config = useSelector(getConfig);
    const dependencyContents = useSelector(getDependencyContents);
    const activeUser = useSelector(selectActiveUser);
    const { gotoZone, gotoZoneAndItem } = useZoneNav();

    useEffect(() => {
        return interceptClicks(
            document.querySelector(selector),
            {
                //
                // Leave all these as defauts:
                //
                // modifierKeys: true
                // download: true
                // target: true
                // hash: true
                // mailTo: true

                // Intercept all clicks, even ones that are not same origin
                sameOrigin: false,
            },
            async function (e, el) {
                e.preventDefault();
                const params = new URLSearchParams(el.search);
                console.log(`Clicked on link with params ${params}`);
                const contentId = parseInt(
                    params.get('content_id') ?? el.dataset.targetContentid
                );
                const autoPlay = params.get('auto_play');
                const linkTypeId = parseInt(params.get('link_type_id')) || 2;
                const target = el.getAttribute('target');

                dispatch(
                    saveStatement({
                        LrsVerb: LrsVerbs.Interacted,
                        response: JSON.stringify({
                            contentId: contentId,
                            autoPlay: autoPlay,
                            linkTypeId: linkTypeId,
                        }),
                        contentId,
                    })
                );

                let content;
                if (contentId) {
                    if (config.modeType === 'viewer') {
                        content = dependencyContents.find(
                            (c) => c.content_id === contentId
                        );
                    } else {
                        content = await contentService.fetchContentById(
                            contentId,
                            activeUser.s3_prefix
                        );
                    }
                }

                let contentVersion = content?.last_content_version;
                if (!contentVersion && content?.content_versions) {
                    contentVersion = content.content_versions.toSorted(
                        (a, b) => b.version - a.version
                    )[0];
                }
                if (contentVersion) {
                    const url = prefixUrl(
                        contentVersion.encoding_status?.toLowerCase() ===
                            'complete'
                            ? contentVersion.encoded_url
                            : contentVersion.url,
                        activeUser.s3_prefix
                    );
                    if (target === 'companion_app') {
                        const companionData = {
                            display_name: el.textContent,
                            app_url: url || contentVersion.embed_data,
                            app_mode: 1,
                            linkType: linkTypeId,
                            linkData: contentVersion,
                            skill_id: content.skill_id,
                        };
                        dispatch(setCompanionApp(companionData));
                    } else {
                        dispatch(
                            setLinkDisplayVis({
                                open: true,
                                linkTypeId:
                                    linkTypeId ?? content.default_link_type_id,
                                linkContent: url,
                                linkData: {
                                    display_name: content.display_name,
                                    ...contentVersion,
                                },
                                autoPlay: autoPlay,
                                overlayText: contentVersion.overlay_text,
                            })
                        );
                    }
                } else {
                    let nodeId = params.get('node_id');
                    let assetId = params.get('asset_id');
                    let zoneId = params.get('zone_id');
                    let itemId = params.get('item_id');
                    let linkId = params.get('link_id');
                    if (nodeId) {
                        if (zoneId) {
                            if (itemId) {
                                dispatch(
                                    saveStatement({
                                        LrsVerb: LrsVerbs.Progressed,
                                        response: { zoneId, itemId },
                                        zoneId,
                                        itemId,
                                    })
                                );
                                gotoZoneAndItem(
                                    zoneId,
                                    itemId,
                                    linkId,
                                    assetId
                                );
                            } else {
                                dispatch(
                                    saveStatement({
                                        LrsVerb: LrsVerbs.Progressed,
                                        response: { nodeId, zoneId },
                                        zoneId,
                                    })
                                );
                                gotoZone(zoneId);
                            }
                        }
                        if (nodeId === 'next') {
                            handleNext?.(null, null);
                        } else if (nodeId === 'previous') {
                            handleBack?.(null, null);
                        } else {
                            dispatch(gotoNode(Number(nodeId)));
                        }
                    } else if (zoneId) {
                        if (itemId) {
                            dispatch(
                                saveStatement({
                                    LrsVerb: LrsVerbs.Progressed,
                                    response: { zoneId, itemId },
                                    zoneId,
                                    itemId,
                                })
                            );
                            gotoZoneAndItem(zoneId, itemId);
                        } else {
                            dispatch(
                                saveStatement({
                                    LrsVerb: LrsVerbs.Progressed,
                                    response: { zoneId },
                                    zoneId,
                                })
                            );
                            gotoZone(zoneId);
                        }
                    } else {
                        dispatch(
                            saveStatement({
                                LrsVerb: LrsVerbs.Progressed,
                                response: {
                                    linkTypeId,
                                    linkContent: el.href,
                                    autoPlay,
                                },
                            })
                        );
                        if (config.modeType === 'author') {
                            dispatch(
                                setLinkDisplayVis({
                                    open: true,
                                    chapter_id:
                                        +el.getAttribute('data-chapter-id') ||
                                        null,
                                    linkContent: el.href,
                                    linkTypeId,
                                    linkData: null,
                                    autoPlay: autoPlay,
                                    overlayText: null,
                                })
                            );
                        } else {
                            const chapter_data = findVideoChapter(
                                {
                                    content_id: +el.getAttribute(
                                        'data-target-contentid'
                                    ),
                                    content_version_id: +el.getAttribute(
                                        'data-target-selectedversion'
                                    ),
                                    chapter_id:
                                        +el.getAttribute('data-chapter-id') ||
                                        null,
                                },
                                dependencyContents || []
                            );
                            dispatch(
                                setLinkDisplayVis({
                                    open: true,
                                    chapter_data: chapter_data,
                                    linkContent: el.href,
                                    linkTypeId: linkTypeId,
                                    linkData: null,
                                    autoPlay: autoPlay,
                                    overlayText: null,
                                })
                            );
                        }
                    }
                }
                // Change the page state here
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentService, gotoZone, gotoZoneAndItem, dispatch]);
}
