import React from 'react';
import { Box, Checkbox } from '@mui/material';

const AutoFillField = ({ value, setFieldValue }) => {
    return (
        <Box>
            <Checkbox
                checked={Boolean(value)}
                onClick={(e) => setFieldValue('autofill', e.target.checked)}
            />
            Autofill
        </Box>
    );
};

export default AutoFillField;
