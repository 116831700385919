import React, { useState } from 'react';
import clsx from 'clsx';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';
import { Action } from '_helpers';

const ConfirmDeleteDialog = ({
    dialogClasses,
    modalProps,
    openDialog,
    setOpenDialog,
    handleSubmit,
}) => {
    const [confirmationText, setConfirmationText] = useState(null);
    return (
        <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle>Permanently delete files</DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom={'5px'}>
                    {modalProps.deleteZone &&
                        "You have selected the Delete Files option. This will permanently delete any files on the server for this zone's image. To proceed, type the word “delete” below."}
                    {modalProps.deleteContent &&
                        'You have selected the Delete Files option. This will permanently delete any files on the server for all versions of this content. To proceed, type the word “delete” below.'}
                    {modalProps.deleteProcedure && (
                        <>
                            <Typography marginBottom="15px">
                                The procedure you are attempting to delete is
                                being used by the following content versions:
                            </Typography>
                            <Box marginBottom="15px">
                                {modalProps.deleteAssociatedContentVersions?.map(
                                    (contentVersion, index) => {
                                        const version =
                                            contentVersion.content_version_count ==
                                            1
                                                ? ''
                                                : ` - Version ${contentVersion?.version}`;
                                        return (
                                            <Typography
                                                key={index}
                                                padding="2px 0"
                                                fontWeight="600"
                                                fontSize="18px"
                                            >
                                                {`${contentVersion?.content.display_name}${version}`}
                                            </Typography>
                                        );
                                    }
                                )}
                            </Box>
                            <Typography marginBottom="15px">
                                If you continue, the content versions listed.
                                above will also be deleted.
                            </Typography>
                            <Typography marginBottom="15px">
                                {`Type the word "delete" in the text box below to
                                continue.`}
                            </Typography>
                        </>
                    )}
                </DialogContentText>
                <TextField
                    fullWidth
                    autoFocus
                    margin="dense"
                    id="name"
                    value={confirmationText}
                    onChange={(event) => {
                        setConfirmationText(event.target.value);
                    }}
                    type="text"
                    variant="outlined"
                />
            </DialogContent>
            <DialogActions>
                <Button
                    className={dialogClasses.buttonSpacing}
                    variant="outlined"
                    onClick={() => setOpenDialog(false)}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    disabled={confirmationText !== Action.Delete.label}
                    className={clsx(
                        dialogClasses.buttonSpacing,
                        dialogClasses.actionButton
                    )}
                    variant="contained"
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { ConfirmDeleteDialog };
