import { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { AppNavigation } from './AppNavigation';
import { ThemeProvider } from '@mui/material/styles';
import { accountsSlice, accountsThunk } from 'app/store';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { useStyles } from './styles';
import { getDirtyFlag } from '_features/common/formSlice';
import { getProcedureDirty } from '_features/procedures/proceduresSlice';

const { selectActiveUser, setAppLoading } = accountsSlice;
const { refreshToken } = accountsThunk;

const App = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const getDirty = useSelector(getDirtyFlag);
    const getDirtyProcedure = useSelector(getProcedureDirty);
    const activeUser = useSelector(selectActiveUser);
    const [user, setUser] = useState(activeUser);

    const theme = useSelector(selectActiveTheme);

    useEffect(() => {
        async function refresh() {
            try {
                const refreshUser = await dispatch(refreshToken());
                if (!refreshUser.error) {
                    setUser(refreshUser.payload);
                }
                dispatch(setAppLoading(false));
            } catch (err) {
                dispatch(setAppLoading(false));
            }
        }
        if (!user) {
            dispatch(setAppLoading(true));
        }
        refresh();
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
    // NOTE: Including user as a dependency causes it to keep refreshing the token

    return (
        theme && (
            <ThemeProvider theme={theme}>
                <div
                    className={
                        getDirty || getDirtyProcedure
                            ? `${classes.unsaveDataMain}`
                            : ''
                    }
                >
                    <AppNavigation activeUser={activeUser} />
                </div>
            </ThemeProvider>
        )
    );
};

export { App };
