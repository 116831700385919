import clsx from 'clsx';
import { Typography } from '@mui/material';
import { FiServer, FiGrid } from 'react-icons/fi';
import { MdOutlineCategory } from 'react-icons/md';

import { useStyles } from './styles';

function ViewMode({
    viewMode,
    setViewMode,
    categoryView = null,
    setCategoryView = () => {},
}) {
    const classes = useStyles();

    return (
        <div className={classes.viewMode}>
            <div className={classes.viewModes}>
                <Typography>View in</Typography>
                <FiServer
                    className={clsx({
                        [classes.selectedView]: viewMode === 'list',
                    })}
                    title="List View"
                    onClick={() => setViewMode('list')}
                />
                <FiGrid
                    className={clsx({
                        [classes.selectedView]:
                            viewMode === 'card' || !viewMode,
                    })}
                    title="Card View"
                    onClick={() => setViewMode('card')}
                />
                {categoryView !== null && (
                    <MdOutlineCategory
                        title={
                            categoryView ? 'Hide Categories' : 'Show Categories'
                        }
                        style={{ marginInline: '4px', fontSize: '18px' }}
                        className={clsx({
                            [classes.selectedView]: categoryView,
                        })}
                        onClick={() => setCategoryView(!categoryView)}
                    />
                )}
            </div>
        </div>
    );
}

export { ViewMode };
