import React from 'react';
import { Box, Checkbox } from '@mui/material';

const PasswordField = ({ value, setFieldValue }) => {
    return (
        <Box>
            <Checkbox
                checked={Boolean(value)}
                onClick={(e) => setFieldValue('password', e.target.checked)}
            />
            Password
        </Box>
    );
};

export default PasswordField;
