import { Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { closeAllPanel, isFormOpen } from '_features/common/formSlice';
import { accountsSlice } from 'app/store';
const { selectActiveUser } = accountsSlice;

function PrivateRoute({ children }) {
    const activeUser = useSelector(selectActiveUser);
    let location = useLocation();
    const dispatch = useDispatch();
    const isFormPanelOpen = useSelector(isFormOpen);
    useEffect(() => {
        // TODO: This code needs to eventually be removed so we can
        // allow forms to stay open when changing paths. In order to support
        // this fully we need all forms to recognize when new form data is loaded
        // because the user has click on another item to edit. The forms should
        // prompt the user if they want to save changes before loading the new
        // contnet into the form.
        if (isFormPanelOpen) {
            dispatch(closeAllPanel());
        }
    }, [location.pathname]);

    return activeUser ? (
        children
    ) : (
        <Navigate to="/account/login" state={{ from: location }} replace />
    );
}

export { PrivateRoute };
