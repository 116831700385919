import { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Badge, Divider, Tooltip } from '@mui/material';
import {
    MdClose,
    MdFitScreen,
    MdRefresh,
    MdViewColumn,
    MdUndo,
    MdRedo,
    MdLink,
    MdOutlineLinkOff,
} from 'react-icons/md';

import { config } from 'gemini-config';
import { accountsSlice } from 'app/store';
const { selectActiveUser } = accountsSlice;
import { makeCommonStyles } from './styles';
import { SceneButton } from '../SceneButton';
import { sceneButtonStyles } from '../SceneButton/styles';
import themes from 'GeminiViewerComponent/theme/defaultThemes';
import NavLock from 'GeminiViewerComponent/assets/nav-lock.svg';
import ItemLock from 'GeminiViewerComponent/assets/item-lock.svg';
import NavUnlock from 'GeminiViewerComponent/assets/nav-unlock.svg';
import { GeminiSharedLoadingIcon } from './GeminiSharedLoadingIcon';
import ItemUnlock from 'GeminiViewerComponent/assets/item-unlock.svg';
import NavLabelOn from 'GeminiViewerComponent/assets/nav-label-on.svg';
import NavLabelOff from 'GeminiViewerComponent/assets/nav-label-off.svg';
import ItemLabelOn from 'GeminiViewerComponent/assets/item-label-on.svg';
import ItemLabelOff from 'GeminiViewerComponent/assets/item-label-off.svg';
import useAnalyticsEventTracker from 'shared/hooks/useAnalyticsEventTracker';
import { hotSpotHasTag } from 'GeminiViewerComponent/_helpers/hotspot_helpers';
import { getAllAssetLinks } from 'GeminiViewerComponent/_features/links/linksSlice';
import { clearContentPanelItem } from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import {
    getActiveAssetActiveZone,
    getAssetZoneMedia,
    selectActiveAsset,
} from 'GeminiViewerComponent/_features/asset/assetSlice';
import {
    selectActiveTheme,
    selectLoadingSvg,
} from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    clearItemLinks,
    selectZoneLoadingStatus,
    setItemLinks,
} from 'shared/loadZoneSlice';
import {
    getIsEmptyHotspotVisible,
    getShowOnlyByFilter,
    getTagFilters,
    setIsEmptyHotspotVisible,
    setPanelVis,
} from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import {
    getItemTooltip,
    getNavTooltip,
    getOrientation,
    setItemTooltip,
    setNavTooltip,
    setOrientation,
} from 'GeminiViewerComponent/_features/zonePreview/zonePreviewSlice';

const ViewControls = (props) => {
    const {
        tridiRef,
        showOrientation,
        onResetPosition,
        onClose,
        onClickRefreshItem,
        handleItemDragging,
        handleNavDragging,
        enableItemDragging,
        enableNavDragging,
        undoRedoProps,
        zoneMediaConfigValue,
        ...otherProps
    } = props;
    const dispatch = useDispatch();
    const activeUser = useSelector(selectActiveUser);
    const asset = useSelector(selectActiveAsset);
    const activeZone = useSelector(getActiveAssetActiveZone);
    const theme = useSelector(selectActiveTheme);
    const [preventImgCache, setPreventImgCache] = useState(null);
    const [allZoneMedia, setAllZoneMedia] = useState(false);
    const [activeZoneItemsLength, setActiveZoneItemsLength] = useState(null);

    const clientID = asset?.client_id || activeUser?.client_id;
    const s3Prefix = activeUser?.s3_prefix || asset.s3_prefix;
    const themeIconPath =
        theme?.id && theme?.id !== themes?.default?.id ? '/' + theme.id : '';
    const zoneMediaIconPath = `${s3Prefix}client_${clientID}/ThemeData${themeIconPath}/zone_media_icon.svg?${preventImgCache}`;
    const { undoRedoStack, undoItemPosition, redoItemPosition } =
        undoRedoProps ?? {};
    const tagFilters = useSelector(getTagFilters);
    const showOnlyByFilter = useSelector(getShowOnlyByFilter);
    const allAssetLinks = useSelector(getAllAssetLinks);
    const configZoneMediaValue = useSelector(getAssetZoneMedia);
    const isEmptyHotspotVisible = useSelector(getIsEmptyHotspotVisible);

    const commonStyles = makeCommonStyles();
    const classes = sceneButtonStyles(theme);

    useEffect(() => {
        setPreventImgCache(new Date().getTime());
    }, []);

    useEffect(() => {
        if (config.modeType === 'author') {
            setAllZoneMedia(zoneMediaConfigValue);
        } else {
            setAllZoneMedia(configZoneMediaValue);
        }
        let itemCount = 0;
        activeZone?.items?.forEach((hotspot) => {
            if (Array.isArray(hotspot?.links) && hotspot?.links?.length > 0) {
                itemCount = itemCount + hotspot.links.length;
            }
        });
        setActiveZoneItemsLength(itemCount);
    }, [activeZone, zoneMediaConfigValue, configZoneMediaValue]);

    const previewOrientation = useSelector(getOrientation);
    const zoneLoadingStatus = useSelector(selectZoneLoadingStatus);
    const gaEventTracker = useAnalyticsEventTracker(
        tridiRef ? 'Orbit Viewer' : 'Marzipano Viewer'
    );

    const displayItemToolTip = useSelector(getItemTooltip);
    const displayNavToolTip = useSelector(getNavTooltip);

    const handelItemLabel = useCallback(() => {
        dispatch(setItemTooltip(displayItemToolTip === false ? true : false));
    }, [dispatch, displayItemToolTip]);

    const handelNavTooltip = useCallback(() => {
        dispatch(setNavTooltip(displayNavToolTip === false ? true : false));
    }, [dispatch, displayNavToolTip]);

    const resetViewHandler = () => {
        if (tridiRef) {
            tridiRef.current.resetPosition();
        } else if (onResetPosition) {
            onResetPosition();
        }
    };

    const previewClickHandler = () => {
        gaEventTracker('Orbit Previews Orientation', previewOrientation);
        dispatch(
            setOrientation(
                previewOrientation === 'vertical' ? 'horizontal' : 'vertical'
            )
        );
    };

    const displayZoneMedia = useCallback(
        async (zoneMedia = true) => {
            dispatch(clearItemLinks());
            if (zoneMedia) {
                dispatch(setPanelVis({ open: true }));
            }
            dispatch(clearContentPanelItem());
            let allItemData = [];
            var visibleHotspots = [];
            var itemTags = tagFilters?.item_tags.map((tag) => tag.tag_id);
            if (!allZoneMedia) {
                allItemData = [];
                activeZone?.items?.forEach((hotspot) => {
                    let hasLocation = hotspot.yaw || hotspot.flat_x;
                    if (
                        hasLocation &&
                        hotSpotHasTag(itemTags, hotspot, showOnlyByFilter)
                    ) {
                        visibleHotspots.push({
                            ...hotspot,
                            zone_id: activeZone?.zone_id,
                            zone_display_name: activeZone?.display_name,
                        });
                    }
                });
                visibleHotspots?.forEach(async (item) => {
                    if (Array.isArray(item?.links) && item?.links?.length > 0) {
                        item.links.forEach(async (link) => {
                            let linkData = {
                                ...link,
                                zone_id: item?.zone_id,
                                zone_display_name: item?.zone_display_name,
                            };
                            allItemData = [...allItemData, linkData];
                        });
                    }
                });
                await dispatch(
                    setItemLinks({ s3Prefix: s3Prefix, links: allItemData })
                );
            } else {
                allItemData = [];
                if (config.modeType === 'viewer') {
                    asset.zones.map((zone) => {
                        zone?.items?.forEach((item) => {
                            if (
                                !visibleHotspots.find(
                                    (hotspot) =>
                                        hotspot.item_id === item.item_id
                                )
                            ) {
                                visibleHotspots.push({
                                    ...item,
                                    zone_id: zone?.zone_id,
                                    zone_display_name: zone?.display_name,
                                });
                            }
                        });
                    });
                    visibleHotspots?.forEach(async (item) => {
                        if (
                            Array.isArray(item?.links) &&
                            item?.links?.length > 0
                        ) {
                            item.links.forEach(async (link) => {
                                let linkData = {
                                    ...link,
                                    zone_id: item?.zone_id,
                                    zone_display_name: item?.zone_display_name,
                                };
                                allItemData = [...allItemData, linkData];
                            });
                        }
                    });
                    await dispatch(
                        setItemLinks({ s3Prefix: s3Prefix, links: allItemData })
                    );
                } else {
                    await dispatch(setItemLinks({ links: allAssetLinks }));
                }
            }
        },
        [
            dispatch,
            tagFilters?.item_tags,
            allZoneMedia,
            activeZone?.items,
            activeZone?.zone_id,
            activeZone?.display_name,
            s3Prefix,
            showOnlyByFilter,
            asset.zones,
            allAssetLinks,
        ]
    );

    const toggleDisplayEmptyHotspot = useCallback(() => {
        dispatch(setIsEmptyHotspotVisible(!isEmptyHotspotVisible));
    }, [isEmptyHotspotVisible]);

    return (
        <Box {...otherProps}>
            {undoRedoProps && (
                <>
                    <Tooltip title="Undo">
                        <IconButton
                            className={commonStyles.orbitControlsButton}
                            size="small"
                            onClick={undoItemPosition}
                        >
                            <Badge
                                badgeContent={undoRedoStack.undo}
                                color="primary"
                                max={9}
                            >
                                <MdUndo className="react-icon" />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Redo">
                        <IconButton
                            className={commonStyles.orbitControlsButton}
                            size="small"
                            onClick={redoItemPosition}
                        >
                            <Badge
                                badgeContent={undoRedoStack.redo}
                                color="primary"
                                max={9}
                            >
                                <MdRedo className="react-icon" />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                </>
            )}
            {onClickRefreshItem &&
                (zoneLoadingStatus !== 'Loaded' ? (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <GeminiSharedLoadingIcon
                            styleClassName={commonStyles.loadingSvg}
                            svgSelector={selectLoadingSvg}
                            fadeIn={true}
                        />
                    </Box>
                ) : (
                    <Tooltip title="Refresh (R)">
                        <IconButton
                            className={commonStyles.orbitControlsButton}
                            variant="contained"
                            size="small"
                            onClick={onClickRefreshItem}
                        >
                            <MdRefresh className="react-icon" />
                        </IconButton>
                    </Tooltip>
                ))}
            <Tooltip
                title={
                    isEmptyHotspotVisible
                        ? 'Hide Empty Items'
                        : 'Show Empty Items'
                }
            >
                <IconButton
                    className={commonStyles.orbitControlsButton}
                    variant="contained"
                    size="small"
                    onClick={toggleDisplayEmptyHotspot}
                >
                    {isEmptyHotspotVisible ? (
                        <MdLink className="react-icon" />
                    ) : (
                        <MdOutlineLinkOff className="react-icon" />
                    )}
                </IconButton>
            </Tooltip>
            {enableItemDragging !== undefined &&
                (enableItemDragging === true ? (
                    <Tooltip title="Lock Dragging">
                        <IconButton
                            className={commonStyles.orbitControlsButton}
                            variant="contained"
                            size="small"
                            onClick={handleItemDragging}
                        >
                            <img
                                src={ItemUnlock}
                                alt={`Item Drag Off`}
                                height={'20px'}
                                width={'20px'}
                            />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Unlock Dragging">
                        <IconButton
                            className={commonStyles.orbitControlsButton}
                            variant="contained"
                            size="small"
                            onClick={handleItemDragging}
                        >
                            <img
                                src={ItemLock}
                                alt={`Item Drag On`}
                                height={'20px'}
                                width={'20px'}
                            />
                        </IconButton>
                    </Tooltip>
                ))}
            {enableNavDragging !== undefined &&
                (enableNavDragging === true ? (
                    <Tooltip title="Lock Dragging">
                        <IconButton
                            className={commonStyles.orbitControlsButton}
                            variant="contained"
                            size="small"
                            onClick={handleNavDragging}
                        >
                            <img
                                src={NavUnlock}
                                alt={`Nav Drag Off`}
                                height={'20px'}
                                width={'20px'}
                            />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Unlock Dragging">
                        <IconButton
                            className={commonStyles.orbitControlsButton}
                            variant="contained"
                            size="small"
                            onClick={handleNavDragging}
                        >
                            <img
                                src={NavLock}
                                alt={`Nav Drag On`}
                                height={'20px'}
                                width={'20px'}
                            />
                        </IconButton>
                    </Tooltip>
                ))}
            <Tooltip title="Toggle Item Labels">
                <IconButton
                    className={commonStyles.orbitControlsButton}
                    variant="contained"
                    size="small"
                    onClick={handelItemLabel}
                >
                    <img
                        src={displayItemToolTip ? ItemLabelOn : ItemLabelOff}
                        alt={`Nab Label Off`}
                        height={'24px'}
                        width={'24px'}
                    />
                </IconButton>
            </Tooltip>
            <Tooltip title="Toggle Navigation Labels">
                <IconButton
                    className={commonStyles.orbitControlsButton}
                    variant="contained"
                    size="small"
                    onClick={handelNavTooltip}
                >
                    <img
                        src={displayNavToolTip ? NavLabelOn : NavLabelOff}
                        alt={`Nab Label Off`}
                        height={'24px'}
                        width={'24px'}
                    />
                </IconButton>
            </Tooltip>
            {showOrientation && (
                <Tooltip title="Toggle Zone Navigation Orientation">
                    <IconButton
                        className={clsx(commonStyles.orbitControlsButton, {
                            [commonStyles.orbitControlsButtonRotated]:
                                previewOrientation === 'vertical',
                        })}
                        size="small"
                        onClick={previewClickHandler}
                    >
                        <MdViewColumn
                            className="react-icon"
                            style={{ height: '24px', width: '24px' }}
                        />
                    </IconButton>
                </Tooltip>
            )}
            <Tooltip title="Reset View">
                <IconButton
                    className={commonStyles.orbitControlsButton}
                    variant="contained"
                    size="small"
                    onClick={resetViewHandler}
                >
                    <MdFitScreen
                        className="react-icon"
                        style={{ height: '24px', width: '24px' }}
                    />
                </IconButton>
            </Tooltip>
            <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                style={{
                    backgroundColor: '#ffffff',
                    margin: '3px 0',
                    width: '1.55px',
                }}
            />
            <Box
                padding="0 5px"
                position="relative"
                marginRight={activeZoneItemsLength > 0 ? '10px' : 0}
            >
                <SceneButton
                    iconPath={zoneMediaIconPath}
                    onClick={displayZoneMedia}
                    extraClasses={classes.sceneImgFixedWidth}
                    tooltipTitle="Zone Media"
                />
                <Badge
                    className={classes.zoneMediaBadge}
                    badgeContent={activeZoneItemsLength}
                />
            </Box>
            {onClose && (
                <IconButton
                    className={clsx(commonStyles.orbitControlsClose)}
                    size="small"
                    onClick={onClose}
                >
                    <MdClose
                        className="react-icon"
                        style={{ height: '24px', width: '24px' }}
                    />
                </IconButton>
            )}
        </Box>
    );
};

export { ViewControls };
