import { version } from 'version';

const newBaseUrl =
    'https://epss-pano-test.s3.us-east-2.amazonaws.com/marzipano/cfastore/';
const newCommonUrl =
    'https://epss-pano-test.s3.us-east-2.amazonaws.com/common/';

const config = {
    apiUrl: process.env.REACT_APP_API_URL,
    gapsApiUrl: process.env.REACT_APP_SESSION_URL,
    routerBaseName: process.env.REACT_APP_ROUTER_BASENAME,
    gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID,
    deltaTechUDomain: process.env.REACT_APP_DELTA_TECHU_DOMAIN,
    appVersion: version,
    modeType: 'author',
    paths: {
        // PUBLISHED_FILE: 'https://pasi-dev.s3.amazonaws.com/client_3/product_4/published.json', // Chick-fil-A
        TREE_FILE:
            'http://epss-pano-test.s3-website.us-east-2.amazonaws.com/treedata/transmissionTree.js',
        BASE_STORE_URL: newBaseUrl,
        BASE_COMMON_URL: newCommonUrl,
        // DATA_URL: newBaseUrl+"data.js",
        // TILES_URL: newBaseUrl+"tiles",
        INFO_IMAGE_URL: newBaseUrl + 'img/info.png',
        LINK_IMAGE_URL: newBaseUrl + 'img/link.png',
        // LINK_IMAGE_URL: newCommonUrl+'img/scene-nav-icon-animate.svg',
        LINK_NAV_ICON_URL: newCommonUrl + 'img/navigate-icon.svg',
        LINK_DELETE_ICON_URL: newCommonUrl + 'img/trash-icon.svg',
        LINK_ROTATE_ICON_URL: newCommonUrl + 'img/rotate-icon.svg',
        LINK_EDIT_ICON_URL: newCommonUrl + 'img/edit-icon.svg',
        LINK_SCALEUP_ICON_URL: newCommonUrl + 'img/scaleup-icon.svg',
        LINK_SCALEDOWN_ICON_URL: newCommonUrl + 'img/scaledown-icon.svg',
        LINK_CLOSE_ICON_URL: newCommonUrl + 'img/close.png',
        INFO_EDIT_ICON_URL: newCommonUrl + 'img/info-edit-icon.svg',
        SCENE_INFO_ICON_URL: newCommonUrl + 'img/scene-info-item-icon.svg',
        SCENE_NAV_ICON_URL: newCommonUrl + 'img/scene-nav-icon.svg',
        SCENE_INITVIEW_ICON_URL:
            newCommonUrl + 'img/scene-set-initview-icon.svg',
        SCENE_INITVIEW_ANIM_URL:
            newCommonUrl + 'img/scene-set-initview-anim.svg',
        LIBRARY_URL:
            'https://epss-pano-test.s3.us-east-2.amazonaws.com/libraryjs/',
    },
    linkConfig: {
        LinkTypes: {
            1: { label: 'Document' },
            2: { label: 'Video' },
            3: { label: 'System' },
            4: { label: 'Audio' },
            5: { label: 'Learning' },
            6: { label: 'Subject Matter Expert' },
            7: { label: 'Image' },
            8: { label: 'Custom Data' },
            9: { label: 'Schoox' },
            10: { label: 'Embed' },
            11: { label: 'Procedure' },
            12: { label: 'Model' },
            13: { label: 'TechU' },
        },
        LinkTypesIdRef: {
            Document: 1,
            Video: 2,
            System: 3,
            Audio: 4,
            Learning: 5,
            SME: 6,
            Image: 7,
            CustomData: 8,
            Schoox: 9,
            Embed: 10,
            Procedure: 11,
            Model: 12,
            TechU: 13,
        },
    },
};

export { config };
