import { config } from 'gemini-config';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import { getNetworkService } from 'GeminiViewerComponent/_helpers';

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});

const baseUrl = `${config.apiUrl}/validationtype`;

// debug temp
// eslint-disable-next-line no-unused-vars
let validatonRawTypesFake = [
    { validation_raw_type_id: 0, display_name: 'Boolean' },
    { validation_raw_type_id: 1, display_name: 'Integer' },
    { validation_raw_type_id: 2, display_name: 'String' },
    { validation_raw_type_id: 3, display_name: 'JSON' },
    { validation_raw_type_id: 4, display_name: 'Select' },
];

let validatonRawTypesByName = {
    Boolean: 0,
    Integer: 1,
    String: 2,
    JSON: 3,
    Select: 4,
};

// eslint-disable-next-line no-unused-vars
let fakeData = [
    {
        validation_type_id: 1,
        display_name: 'Boolean',
        type_id: validatonRawTypesByName['Boolean'],
        options_array: [],
    },
    {
        validation_type_id: 2,
        display_name: 'Integer No Limit',
        type_id: validatonRawTypesByName['Integer'],
        max_length: null,
        min_value: null,
        max_value: null,
        options_array: [],
    },
    {
        validation_type_id: 3,
        display_name: 'String No Limit',
        type_id: validatonRawTypesByName['String'],
        max_length: null,
        min_value: null,
        max_value: null,
        options_array: [],
    },
    {
        validation_type_id: 4,
        display_name: 'JSON',
        type_id: validatonRawTypesByName['JSON'],
        options_array: [],
    },
    {
        validation_type_id: 5,
        display_name: 'AWS Region',
        type_id: validatonRawTypesByName['Select'],
        max_length: null,
        min_value: null,
        max_value: null,
        options_array: ['us-east-1', 'us-east-2', 'us-west-1', 'us-west-2'],
    },
    {
        validation_type_id: 6,
        display_name: 'Max Zones',
        type_id: validatonRawTypesByName['Integer'],
        max_length: null,
        min_value: 1,
        max_value: 2000,
        options_array: [],
    },
];

// For Select type the available options are stored as a JSON
// string in the format { Options: ["option1", "option2"] }.
// Convert this into actual objects and array making it easier
// for the reset of the code to work with the Select options
const convertValidationTypesFromServer = (validationTypes) => {
    // Transform options into simpler versions
    return validationTypes.map((validationType) => {
        if (validationType.options) {
            const options = JSON.parse(validationType.options);
            validationType.options_array = options.Options
                ? options.Options
                : [];
            delete validationType.options;
        }
        return validationType;
    });
};
// Undo the conversions used in convertValidationTypesFromServer.
// Also set 0 validation_type_id = 0 on new types
const convertValidationTypesToServer = (validationTypes) => {
    return validationTypes.map((validationType) => {
        validationType.options = JSON.stringify({
            Options: validationType.options_array,
        });
        delete validationType.options_array;
        if (typeof validationType.validation_type_id === 'string') {
            validationType.validation_type_id = 0;
        }
        return validationType;
    });
};

const getAll = async (params) => {
    // return validatonRawTypesFake;
    try {
        const queryParams = {
            sorts: params?.sort ?? 'display_name',
        };
        const query = new URLSearchParams(queryParams);

        const response = await networkService.get(`${baseUrl}/?${query}`);
        if (response) {
            return convertValidationTypesFromServer(response.data);
        }
        return [];
    } catch {
        return [];
    }
};

const getRawTypes = async () => {
    // return fakeData;
    try {
        const response = await networkService.get(`${baseUrl}/availabletypes`);
        if (response) {
            return Object.keys(response.data.available_type_names ?? {}).map(
                (key) => {
                    return {
                        validation_raw_type_id: parseInt(key),
                        display_name: response.data.available_type_names[key],
                    };
                }
            );
        }
    } catch (e) {
        return null;
    }
};

const updateAll = async (values, params) => {
    // debug temp
    // fakeData = params;
    // return fakeData;
    // eslint-disable-next-line no-unreachable

    try {
        const queryParams = {
            sorts: params?.sort ?? 'display_name',
        };
        const query = new URLSearchParams(queryParams);

        const response = await networkService.put(
            `${baseUrl}/multiple/?${query}`,
            {
                validation_types: convertValidationTypesToServer(values),
            }
        );

        if (response) {
            convertValidationTypesFromServer(response.data.validation_types);
            return response.data;
        }
        return Promise.reject('Error saving validation types');
    } catch (e) {
        return Promise.reject(e);
    }
};

export const validationTypeService = {
    getAll,
    getRawTypes,
    updateAll,
};
