/**
 * This slice is to manage a single asset
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { assetService } from '_features/_services';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { setActiveAsset } from 'GeminiViewerComponent/_features/asset/assetSlice';
import { resetTableZones } from '_features/zones/zonesTableSlice';
import { setProcedureState } from 'GeminiViewerComponent/components/Procedure/_features/procedureSlice';

const initialState = {
    asset: {},

    assetTypes: [],

    assetLinkTypes: [],
    assetLinkTypesStatus: LoadingStatus.Idle,

    loadStatus: LoadingStatus.Idle,
    saveStatus: SavingStatus.Idle,
    assetStatsStatus: LoadingStatus.Idle,
    assetStats: [],
    assetStatsRowsMap: {},
    assetStatsPageInfo: {},
};

// Actions related to a single asset
export const getAsset = createAsyncThunk(
    'assetManager/getAsset',
    async (assetId, { getState, dispatch }) => {
        const response = await assetService.getById(
            assetId,
            getState().accounts.activeUser.s3_prefix
        );
        if (!response.error) {
            response.id = assetId;
            dispatch(
                setActiveAsset({
                    ...response,
                    s3_prefix: getState().accounts.activeUser.s3_prefix,
                })
            );
            if (response?.asset_type_id === 3) {
                const procedure = response?.procedure;
                dispatch(setProcedureState(procedure));
            }
            dispatch(resetTableZones());
        }

        return response;
    }
);

export const getAssetStatsRange = createAsyncThunk(
    'assetManager/getAssetStatsRange',
    async (params) =>
        await assetService.getAssetStatsRange(
            params.startIndex,
            params.stopIndex
        )
);

export const publishById = createAsyncThunk(
    'assetManager/publishById',
    async ({ asset_id, isDraft }) => {
        const response = await assetService.publishById(asset_id, isDraft);
        response.id = asset_id;
        return response;
    }
);

export const getAssetTypes = createAsyncThunk(
    'assetManager/getAssetTypes',
    async () => await assetService.getAssetTypes()
);

export const getAllLinkTypes = createAsyncThunk(
    'assetManager/getAllLinkTypes',
    async () => await assetService.getAllLinkTypes()
);

export const exportAssetStats = createAsyncThunk(
    'assetManager/exportAssetStats',
    async (params) =>
        await assetService.exportAssetStats(params.startIndex, params.stopIndex)
);

const assetManagerSlice = createSlice({
    name: 'assetManager',
    initialState,
    reducers: {
        setDisplayAssetProcedureNodes: (state, action) => {
            state.asset = {
                ...state.asset,
                procedure: { ...state.asset.procedure, nodes: action.payload },
            };
        },
    },
    extraReducers: {
        [publishById.fulfilled]: (state, action) => {
            // state.asset = action.payload;
        },
        [publishById.rejected]: (state, action) => {
            state.status = SavingStatus.Failed;
            state.error = action.error.message;
        },
        [getAsset.pending]: (state, action) => {
            state.loadingStatus = LoadingStatus.Loading;
            // state.asset = {};
        },
        [getAsset.fulfilled]: (state, action) => {
            // state.asset = action.payload;
            state.loadingStatus = LoadingStatus.Loaded;
        },
        [getAsset.rejected]: (state, action) => {
            state.loadingStatus = LoadingStatus.Failed;
        },
        [getAssetTypes.pending]: (state, action) => {
            state.assetTypesStatus = LoadingStatus.Loading;
        },
        [getAssetTypes.fulfilled]: (state, action) => {
            state.assetTypes = action.payload.asset_types;
            state.assetTypesStatus = LoadingStatus.Loading;
        },
        [getAssetTypes.rejected]: (state, action) => {
            state.assetTypesStatus = LoadingStatus.Failed;
        },
        [getAllLinkTypes.pending]: (state, action) => {
            state.assetLinkTypesStatus = LoadingStatus.Loading;
        },
        [getAllLinkTypes.fulfilled]: (state, action) => {
            state.assetLinkTypesStatus = LoadingStatus.Loaded;
            state.assetLinkTypes = action.payload.link_types;
        },
        [getAllLinkTypes.rejected]: (state, action) => {
            state.assetLinkTypesStatus = LoadingStatus.Failed;
        },
        [getAssetStatsRange.pending]: (state, action) => {
            const startIndex = action.meta.arg.startIndex;
            const stopIndex = action.meta.arg.stopIndex;

            for (var i = startIndex; i <= stopIndex; i++) {
                state.assetStatsRowsMap[i] = LoadingStatus.Loading;
            }

            state.assetStatsPageInfo = {};
            state.assetStatsStatus = LoadingStatus.Loading;
        },
        [getAssetStatsRange.fulfilled]: (state, action) => {
            if (
                action.meta.arg.assetCategoryId ||
                action.meta.arg.assetCategoryId === 0
            ) {
                return;
            }
            if (action.meta.arg.reset) {
                state.assetStatsRowsMap = {};
            }
            state.assetStatsStatus = LoadingStatus.Loaded;
            state.assetStatsPageInfo = action.payload.pageInfo;

            const startIndex = action.meta.arg.startIndex;
            const stopIndex = startIndex + action.payload.stats.length - 1;

            for (var i = startIndex; i <= stopIndex; i++) {
                state.assetStatsRowsMap[i] = LoadingStatus.Loaded;
            }

            // In case we didn't load as many as requested
            for (
                var j = stopIndex + 1;
                j <= action.payload?.pageInfo?.TotalCount;
                j++
            ) {
                delete state.assetStatsRowsMap[j];
            }

            if (action.meta.arg.reset) {
                state.assetStats = action.payload.stats;
            } else {
                state.assetStats = state.assetStats.concat(
                    action.payload.stats
                );
            }
        },
        [getAssetStatsRange.rejected]: (state, action) => {
            const startIndex = action.meta.arg.startIndex;
            const stopIndex = action.meta.arg.stopIndex;

            for (var i = startIndex; i <= stopIndex; i++) {
                delete state.assetStatsRowsMap[i];
            }

            state.assetStatsStatus = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [exportAssetStats.fulfilled]: (state, action) => {
            if (action?.payload) {
                const url = window.URL.createObjectURL(
                    new Blob([action.payload])
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'asset_stats.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        },
    },
});

export const selectAssetLoadingStatus = (state) =>
    state.assetManager.loadingStatus;

export const selectAssetTypes = (state) => state.assetManager.assetTypes;

export const selectAssetLinkTypes = (state) =>
    state.assetManager.assetLinkTypes;

export const selectAssetLinkTypesStatus = (state) =>
    state.assetManager.assetLinkTypesStatus;

export const getAssetStatsStatus = (state) =>
    state.assetManager.assetStatsStatus;
export const getAllAssetStats = (state) => state.assetManager.assetStats;
export const getAssetStatsRowsMap = (state) =>
    state.assetManager.assetStatsRowsMap;
export const getAssetStatsPageInfo = (state) =>
    state.assetManager.assetStatsPageInfo;

export const { setDisplayAssetProcedureNodes } = assetManagerSlice.actions;

export default assetManagerSlice.reducer;
