import { config } from 'gemini-config';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { AssetTypes, fetchByKey } from 'GeminiViewerComponent/_helpers';
import { prefixUrl } from 'GeminiViewerComponent/_helpers/urlUtils';
import { remove } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { NewsFlashDisplayOptions } from 'GeminiViewerComponent/_helpers';
import { convertNodeTypes } from 'GeminiViewerComponent/_helpers/node_helpers';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';

const initialState = {
    status: '',
    error: '',
    audiences: [],
    rulesById: [],
    newsFlashesById: [],
    queuedNewsFlashes: [],
    viewedNewsFlashIds: [],
    zoneNewsFlashIds: [],
    newsFlashesLoaded: false,
    editingHotspot: null,
    selectedHotspotFromUser: false,
    assetInfo: {},
    assetDetails: {},
    // Currently loaded asset - only one is loaded at a time. This could be either from the user
    // selecting the asset in the Author app or the published asset file being loaded in the Viewer app.
    activeAsset: {
        // Fully loaded zones with items. Zones in this list are ready to be viewed. This is set
        // when the user selects a zone to view in the Author app, or set all at once when the Viewer app
        // loads a  published asset file.
        zones: [],
        // Compact list all zones defined for asset. Only contains basic info about each zone.
        // This can be used by UI components that need access to the full list of all zones, since not
        // all zones are always present in the zones array.
        // THese are loaded when the asset is fetched from the server in Author app, or when the
        // publoshed asset file is loaded in the Viewer app.
        compact_zones: [],
        isOrbitZoneExist: false,
    },
    // The asset that was published to create the asset file (i.e. not a dependency).
    publishedAsset: null,
    // Currently active zone. Always points to a zone in the activeAsset.zones list.
    activeZoneId: null,
    hotspotIcons: [],
    allTags: [],
    selectedSearchItemId: null,
    defaultHighlightIconId: 2,
    dependencies: {},
    published_date: null,
    isDraft: false,
    zoneMedia: false,
    childZones: [],
    orbit_images_url: null,
    orbit_images_count: null,
    empty_item_opacity: '60',
};

const mapHotspot = (hotspot, s3Prefix) => {
    hotspot = { ...hotspot, image_url: prefixUrl(hotspot.image_url, s3Prefix) };
    return hotspot;
};

const mapHotspots = (hotspots, s3Prefix) => {
    return hotspots.map((hotspot) => {
        return mapHotspot(hotspot, s3Prefix);
    });
};

const mapContentVersion = (contentVersion, s3Prefix) => {
    contentVersion = {
        ...contentVersion,
        url: prefixUrl(contentVersion.url, s3Prefix),
    };
    if (contentVersion?.encoded_url) {
        contentVersion = {
            ...contentVersion,
            encoded_url: prefixUrl(contentVersion.encoded_url, s3Prefix),
        };
    }
    return contentVersion;
};

const mapLink = (link, s3Prefix) => {
    link = { ...link, url: prefixUrl(link.url, s3Prefix) };
    if (link?.encoded_url) {
        link = { ...link, encoded_url: prefixUrl(link.encoded_url, s3Prefix) };
    }
    if (link?.content_version) {
        link = {
            ...link,
            content_version: mapContentVersion(link.content_version, s3Prefix),
        };
    }
    return link;
};

const mapTag = (tag, s3Prefix) => {
    tag = { ...tag, image_url: prefixUrl(tag.image_url, s3Prefix) };
    return tag;
};

const mapLinks = (links, s3Prefix) => {
    return links.map((link) => {
        return mapLink(link, s3Prefix);
    });
};

const mapTags = (tags, s3Prefix) => {
    if (!tags) {
        return {};
    }
    return tags.map((tag) => {
        return mapTag(tag, s3Prefix);
    });
};

const mapItem = (item, s3Prefix) => {
    item = {
        ...item,
        links: mapLinks(item.links, s3Prefix),
        tags: mapTags(item.tags, s3Prefix),
    };
    return item;
};

const mapItems = (items, s3Prefix) => {
    return items.map((item) => {
        return mapItem(item, s3Prefix);
    });
};
const mapZone = (zone, s3Prefix) => {
    zone = {
        ...zone,
        equirect_image_url: prefixUrl(zone.equirect_image_url, s3Prefix),
        equirect_image_preview_url: prefixUrl(
            zone.equirect_image_preview_url,
            s3Prefix
        ),
        flat_image_url: prefixUrl(zone.flat_image_url, s3Prefix),
        flat_image_preview_url: prefixUrl(
            zone.flat_image_preview_url,
            s3Prefix
        ),
        items: mapItems(zone.items, s3Prefix),
        item_tags: mapTags(zone.item_tags, s3Prefix),
        zone_tags: mapTags(zone.zone_tags, s3Prefix),
    };

    return zone;
};
const mapPreviewImageOnlyZone = (zone, s3Prefix) => {
    zone = {
        ...zone,
        equirect_image_url: prefixUrl(zone.equirect_image_url, s3Prefix),
        equirect_image_preview_url: prefixUrl(
            zone.equirect_image_preview_url,
            s3Prefix
        ),
        flat_image_url: prefixUrl(zone.flat_image_url, s3Prefix),
        flat_image_preview_url: prefixUrl(
            zone.flat_image_preview_url,
            s3Prefix
        ),
        item_tags: mapTags(zone.item_tags, s3Prefix),
        zone_tags: mapTags(zone.zone_tags, s3Prefix),
    };
    return zone;
};

const mapZones = (zones, s3Prefix, childAsset = false) => {
    return zones.map((zone) => {
        if (childAsset) {
            return mapPreviewImageOnlyZone(zone, s3Prefix);
        } else {
            return mapZone(zone, s3Prefix);
        }
    });
};

const mapChildAssets = (state, assets, s3Prefix) => {
    return assets.map((asset) => {
        state.activeAsset.orbit_images_url = prefixUrl(
            asset?.orbit_images_url,
            s3Prefix
        );
        return {
            ...asset,
            orbit_images_url: prefixUrl(asset?.orbit_images_url, s3Prefix),
            compact_zones: mapZones(asset.compact_zones, s3Prefix, true),
        };
    });
};

const updateCompactZones = (compactZones, updates) => {
    const zoneIdx = compactZones.findIndex(
        (x) => x.zone_id === updates.zone_id
    );
    if (zoneIdx !== -1) {
        // Just basic updates for compact zones
        const existZone = compactZones[zoneIdx];
        const updatedZone = {
            zone_id: existZone.zone_id,
            category: updates.category,
            audiences: updates.audiences ?? existZone.audiences,
            zone_tags: updates.tags ?? existZone.zone_tags,
            display_name: updates.display_name ?? existZone.display_name,
            equirect_image_preview_url:
                updates.equirect_image_preview_url ??
                existZone.equirect_image_preview_url,
            flat_image_preview_url:
                updates.flat_image_preview_url ??
                existZone.flat_image_preview_url,
        };
        compactZones[zoneIdx] = {
            ...existZone,
            ...updatedZone,
        };
    }
};

const newsFlashDateCompare = (newsFlash) => {
    let curDate = new Date();
    if (
        newsFlash.start_date !== undefined &&
        curDate < new Date(newsFlash.start_date)
    ) {
        return false;
    }

    if (
        newsFlash.end_date !== undefined &&
        curDate > new Date(newsFlash.end_date)
    ) {
        return false;
    }

    return true;
};

const addCompactZone = (compactZones, updates) => {
    if (
        compactZones.findIndex((zone) => zone.zone_id === updates.zone_id) !==
        -1
    ) {
        return;
    }
    compactZones.push({
        zone_id: updates.zone_id,
        audiences: updates.audiences,
        zone_tags: updates.tags,
        category: updates.category,
        display_name: updates.display_name,
        equirect_image_preview_url: updates.equirect_image_preview_url,
        flat_image_preview_url: updates.flat_image_preview_url,
    });
};

export const updateOrbitZoneExistence = (assetState) => {
    if (
        Array.isArray(assetState.compact_zones) &&
        assetState.compact_zones.length > 0
    ) {
        const zones = assetState.compact_zones;
        const orbitZones = zones.filter((zone) => zone.orbit_frame);
        assetState.isOrbitZoneExist = orbitZones?.length > 0 ? true : false;
    }
};

const allChildAssets = (assets) => {
    let childAssets = [];
    if (config.modeType === 'viewer') {
        assets.forEach((asset) => {
            childAssets.push(asset);
            if (
                asset?.dependencies?.assets &&
                asset?.dependencies?.assets?.length > 0
            ) {
                childAssets = childAssets.concat(
                    allChildAssets(asset.dependencies.assets)
                );
            }
        });
    } else {
        assets.forEach((asset) => {
            childAssets.push(asset);
            if (asset?.children && asset?.children?.length > 0) {
                childAssets = childAssets.concat(
                    allChildAssets(asset.children)
                );
            }
        });
    }
    return childAssets;
};

const queueNewsFlashes = (state, zone) => {
    let newsFlashIds =
        zone && zone?.news_flash_ids?.length > 0
            ? zone.news_flash_ids
            : state.zoneNewsFlashIds;
    state.queuedNewsFlashes = state.queuedNewsFlashes ?? [];
    newsFlashIds.forEach((newsFlashId) => {
        let newsFlash = state.newsFlashesById[newsFlashId];
        if (newsFlash !== undefined) {
            switch (newsFlash.display_option_id) {
                case NewsFlashDisplayOptions.everyTime.id:
                    state.queuedNewsFlashes.push(newsFlash);
                    if (
                        !state.viewedNewsFlashIds.includes(
                            newsFlash.news_flash_id
                        )
                    ) {
                        state.viewedNewsFlashIds.push(newsFlash.news_flash_id);
                    }
                    break;
                case NewsFlashDisplayOptions.oncePerSession.id:
                case NewsFlashDisplayOptions.oncePerUser.id:
                    if (
                        !state.viewedNewsFlashIds.includes(
                            newsFlash.news_flash_id
                        )
                    ) {
                        state.queuedNewsFlashes.push(newsFlash);
                        state.viewedNewsFlashIds.push(newsFlash.news_flash_id);
                    }
                    break;
                default:
                    break;
            }
        }
    });
};

// update orbit_images_url when child asset orbit zone is loaded
const updateOrbitImagesUrl = (state, zone, action) => {
    if (config.modeType === 'viewer') {
        if (
            zone?.asset_id != undefined &&
            zone?.asset_id !== state.activeAsset?.asset_id
        ) {
            const childZone = state.childZones?.find(
                (zone) => zone.zone_id === action.payload.zoneId
            );
            if (
                !childZone &&
                state.activeAsset.orbit_images_url &&
                state.activeAsset.orbit_images_count > 1
            ) {
                state.orbit_images_url = prefixUrl(
                    state.activeAsset.orbit_images_url,
                    action.payload?.s3_prefix
                );
                state.orbit_images_count = state.activeAsset.orbit_images_count;
            }
            const allChildAssetList = allChildAssets(
                state.dependencies?.assets
            );
            if (allChildAssetList) {
                const childAsset = allChildAssetList?.find(
                    (asset) => asset.asset_id === childZone?.asset_id
                );
                if (
                    childAsset?.orbit_images_url &&
                    childAsset?.orbit_images_count > 1
                ) {
                    state.orbit_images_url = prefixUrl(
                        childAsset?.orbit_images_url,
                        action.payload?.s3_prefix
                    );
                    state.orbit_images_count = childAsset?.orbit_images_count;
                } else {
                    state.orbit_images_url = null;
                    state.orbit_images_count = 0;
                }
            }
        } else {
            if (
                state.activeAsset?.orbit_images_url &&
                state.activeAsset?.orbit_images_count > 1
            ) {
                state.orbit_images_url = state.activeAsset?.orbit_images_url;
                state.orbit_images_count =
                    state.activeAsset?.orbit_images_count;
            } else {
                state.orbit_images_url = null;
                state.orbit_images_count = 0;
            }
        }
    } else {
        if (zone?.asset_id !== state.activeAsset?.asset_id) {
            const allChildAssetList = allChildAssets(
                state.activeAsset?.children
            );
            if (allChildAssetList?.length > 0) {
                const childAsset = allChildAssetList?.find(
                    (asset) => asset.asset_id === zone?.asset_id
                );
                if (
                    childAsset?.orbit_images_url &&
                    childAsset?.orbit_images_count > 1
                ) {
                    state.activeAsset.orbit_images_url = prefixUrl(
                        childAsset?.orbit_images_url,
                        action.payload?.s3_prefix
                    );
                    state.activeAsset.orbit_images_count =
                        childAsset?.orbit_images_count;
                    state.orbit_images_url = state.activeAsset.orbit_images_url;
                    state.orbit_images_count =
                        state.activeAsset.orbit_images_count;
                } else {
                    state.orbit_images_url = null;
                    state.orbit_images_count = 0;
                }
            }
        } else {
            if (
                state.activeAsset?.default_orbit_images_url &&
                state.activeAsset?.default_orbit_images_count > 1
            ) {
                state.activeAsset.orbit_images_url =
                    state.activeAsset?.default_orbit_images_url;
                state.activeAsset.orbit_images_count =
                    state.activeAsset?.default_orbit_images_count;
                state.orbit_images_url =
                    state.activeAsset?.default_orbit_images_url;
                state.orbit_images_count =
                    state.activeAsset?.default_orbit_images_count;
            } else {
                state.orbit_images_url = null;
                state.orbit_images_count = 0;
            }
        }
    }
};

const sortTags = (data) => {
    const sortBy = 'name';

    return data.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) return -1;
        if (a[sortBy] > b[sortBy]) return 1;
        return 0;
    });
};

const assetSlice = createSlice({
    name: 'asset',
    initialState,
    reducers: {
        setActiveAsset(state, action) {
            const accountService = getAccountService({ config });
            state.activeAsset = action.payload;
            state.activeAsset.default_orbit_images_url =
                action.payload.orbit_images_url;
            state.activeAsset.default_orbit_images_count =
                action.payload.orbit_images_count;
            if (action.payload.procedure) {
                state.activeAsset.procedure = convertNodeTypes(
                    action.payload?.procedure
                );
            }
            const user = accountService.getUser();
            let response = fetchByKey(`tags_${user.user_id}`);
            // Always make sure activeAsset zones has a value
            if (!state.activeAsset.zones) {
                state.activeAsset.zones = [];
            }
            updateOrbitZoneExistence(state.activeAsset);
            state.allTags =
                Array.isArray(response) && response.length > 0 ? response : [];
            if (action.payload.children?.length > 0) {
                state.activeAsset.children = mapChildAssets(
                    state,
                    action.payload.children,
                    action.payload.s3_prefix
                );
            }
        },
        updateActiveAsset(state, action) {
            if (action.payload.asset_id === state.activeAsset.asset_id) {
                const accountService = getAccountService({ config });
                const user = accountService.getUser();
                let response = fetchByKey(`tags_${user.user_id}`);
                state.activeAsset = {
                    ...state.activeAsset,
                    ...action.payload,
                    clearCache: new Date().getTime(),
                };
                state.activeAsset.default_orbit_images_url =
                    action.payload.orbit_images_url ||
                    state.activeAsset.default_orbit_images_url;
                state.activeAsset.default_orbit_images_count =
                    action.payload.orbit_images_count ||
                    state.activeAsset.default_orbit_images_count;
                if (action.payload?.procedure) {
                    state.activeAsset.procedure = convertNodeTypes(
                        action.payload?.procedure
                    );
                }
                state.allTags =
                    Array.isArray(response) && response.length > 0
                        ? response
                        : [];
                if (
                    action.payload?.children &&
                    action.payload?.children?.length > 0
                ) {
                    state.activeAsset.children = mapChildAssets(
                        state,
                        action.payload?.children,
                        action.payload.s3_prefix
                    );
                }
            }
        },
        deletedAsset(state, action) {
            if (action.payload === state.activeAsset?.asset_id) {
                state.activeAsset = {
                    zones: [],
                    compact_zones: [],
                };
            }
        },
        setZonesOnActiveAsset(state, action) {
            if (action.payload.assetId === state.activeAsset.asset_id) {
                state.activeAsset.zones = action.payload.zoneInfo.zones;
            }
        },
        addZonesToActiveAsset(state, action) {
            if (action.payload.assetId === state.activeAsset.asset_id) {
                state.activeAsset.zones = state.activeAsset.zones.concat(
                    action.payload.zoneInfo.zones
                );
            }
        },
        addZoneToActiveAsset(state, action) {
            if (action.payload.asset_id === state.activeAsset.asset_id) {
                state.activeAsset.zones.push(action.payload);
                addCompactZone(state.activeAsset.compact_zones, action.payload);
                if (action.payload.setActive) {
                    state.activeZoneId = action.payload.zone_id;
                }
            }
        },
        removeZoneFromActiveAsset(state, action) {
            remove(state.activeAsset.zones, {
                zone_id: action.payload.zoneId,
            });
            remove(state.activeAsset.compact_zones, {
                zone_id: action.payload.zoneId,
            });
        },
        updateOrAddZoneInActiveAsset(state, action) {
            let zoneIdx = state.activeAsset.zones.findIndex(
                (zone) => zone.zone_id === action.payload.zone.zone_id
            );
            if (zoneIdx !== -1) {
                state.activeAsset.zones[zoneIdx] = {
                    ...state.activeAsset.zones[zoneIdx],
                    ...action.payload.zone,
                };
                // Just basic updates for compact zones
                updateCompactZones(
                    state.activeAsset.compact_zones,
                    action.payload.zone
                );
            } else {
                state.activeAsset.zones.push(action.payload.zone);
                addCompactZone(
                    state.activeAsset.compact_zones,
                    action.payload.zone
                );
            }
            if (action.payload.setActive) {
                state.activeZoneId = action.payload.zone.zone_id;
            }
        },
        updateZoneInActiveAsset(state, action) {
            let zoneIdx = state.activeAsset.zones.findIndex(
                (zone) => zone.zone_id === action.payload.zone_id
            );
            if (zoneIdx !== -1) {
                state.activeAsset.zones[zoneIdx] = {
                    ...state.activeAsset.zones[zoneIdx],
                    ...action.payload,
                };
            }

            updateCompactZones(state.activeAsset.compact_zones, action.payload);
        },
        setActiveZone(state, action) {
            let zone = state.activeAsset?.zones?.find(
                (zone) => zone.zone_id === action.payload.zoneId
            );
            if (!zone) {
                zone = state.childZones?.find(
                    (zone) => zone.zone_id === action.payload.zoneId
                );
            }

            if (!zone) {
                throw new Error(
                    `Zone ${action.payload.zoneId} not found in active asset.`
                );
            }

            state.activeZoneId = action.payload.zoneId;

            updateOrbitImagesUrl(state, zone, action);

            state.zoneNewsFlashIds = [];
            if (zone && zone.news_flash_ids?.length > 0) {
                zone.news_flash_ids.forEach((nfId) => {
                    state.zoneNewsFlashIds.push(nfId);
                });
            }
            queueNewsFlashes(state, zone);
            state.selectedSearchItemId = action.payload.searchItemId;
        },
        loadNewsFlashes(state) {
            queueNewsFlashes(state);
        },
        setSelectedSearchItemId(state, action) {
            state.selectedSearchItemId = action.payload;

            if (action.payload == null) {
                return;
            }

            let zone = state.activeAsset?.zones?.find(
                (zone) =>
                    zone?.items?.length > 0 &&
                    zone?.items?.find((item) => item.item_id === action.payload)
            );
            if (zone) {
                let item = zone?.items?.find(
                    (item) => item.item_id === action.payload
                );
                zone = {
                    ...zone,
                    init_view_yaw: item.yaw,
                    init_view_pitch: item.pitch,
                };
                state.activeZoneId = zone.zone_id;
                state.activeAsset.zones = state.activeAsset.zones.map((zn) => {
                    if (zn.zone_id === zone.zone_id) {
                        return zone;
                    }
                    return zn;
                });
            }
        },
        setHotspotIcons(state, action) {
            state.hotspotIcons = action.payload;
            state.defaultHighlightIconId =
                action.payload?.find(
                    (val) =>
                        val?.is_highlight === true && val.hotspot_type_id === 1
                )?.hotspot_icon_id ?? 2;
        },
        initAsset: (state, action) => {
            if (state.activeAsset.asset_id == action.payload.asset_id) {
                return;
            }
            state.assetInfo.client_id = action.payload.client.client_id;
            state.assetInfo.nav_hotspot_icon_id =
                action.payload.nav_hotspot_icon_id;
            state.assetInfo.item_hotspot_icon_id =
                action.payload.item_hotspot_icon_id;
            console.log(`initAsset: S3_PREFIX =  ${action.payload.s3_prefix}`);

            state.assetInfo.s3_prefix = action.payload.s3_prefix;
            state.assetInfo.image_url = prefixUrl(
                action.payload.image_url,
                action.payload.s3_prefix
            );
            state.assetInfo.orbit_images_url = prefixUrl(
                action.payload.orbit_images_url,
                action.payload.s3_prefix
            );
            state.assetInfo.orbit_images_count =
                action.payload.orbit_images_count;

            if (action.payload.options_json) {
                state.assetInfo.options = JSON.parse(
                    action.payload.options_json
                );
                state.activeAsset.options = JSON.parse(
                    action.payload.options_json
                );
            }

            state.activeAsset.asset_id = action.payload.asset_id;
            state.activeAsset.asset_type_id = action.payload.asset_type_id;
            state.activeAsset.client_id = action.payload.client.client_id;
            state.activeAsset.nav_hotspot_icon_id =
                action.payload.nav_hotspot_icon_id;
            state.activeAsset.item_hotspot_icon_id =
                action.payload.item_hotspot_icon_id;

            state.activeAsset.s3_prefix = action.payload.s3_prefix;
            state.activeAsset.image_url = prefixUrl(
                action.payload.image_url,
                action.payload.s3_prefix
            );
            state.activeAsset.orbit_images_url = prefixUrl(
                action.payload.orbit_images_url,
                action.payload.s3_prefix
            );
            state.activeAsset.orbit_images_count =
                action.payload.orbit_images_count;
            state.activeAsset.initial_zone_id =
                action.payload.initial_zone_id ||
                [...action.payload.zones].shift()?.zone_id;

            state.hotspotIcons = mapHotspots(
                action.payload.hotspot_icons,
                action.payload.s3_prefix
            );
            state.defaultHighlightIconId =
                action.payload.hotspot_icons?.find(
                    (val) =>
                        val?.is_highlight === true && val.hotspot_type_id === 1
                )?.hotspot_icon_id || 2;
            if (action?.payload?.auto_view_single_link) {
                state.activeAsset.auto_view_single_link =
                    action?.payload?.auto_view_single_link;
            }

            state.allTags = sortTags(
                mapTags(action.payload.tags, action.payload.s3_prefix)
            );

            state.activeAsset.zones = mapZones(
                action.payload.zones.filter(
                    (zone) => zone.equirect_image_url || zone.flat_image_url
                ),
                action.payload.s3_prefix
            );
            const activeZone = state.activeAsset.zones.find(
                (z) => z.zone_id === state.activeZoneId
            );
            if (!activeZone) {
                state.activeZoneId =
                    state.activeAsset.initial_zone_id ??
                    state.activeAsset.zones[0]?.zone_id ??
                    null;
            }

            state.empty_item_opacity =
                action.payload.empty_item_opacity ?? '60';

            if (state.dependencies?.assets?.length > 0) {
                const childAssets = allChildAssets(state.dependencies.assets);
                childAssets.forEach((asset) => {
                    state.orbit_images_url = prefixUrl(
                        asset?.orbit_images_url,
                        action.payload.s3_prefix
                    );
                    state.orbit_images_count = asset?.orbit_images_count;
                });
                const childZones = childAssets.flatMap((childAsset) => {
                    if (childAsset?.zones && childAsset.zones.length > 0) {
                        return mapZones(
                            childAsset.zones.map((zone) => ({
                                ...zone,
                                asset_id: childAsset.asset_id,
                                asset_name: childAsset.display_name,
                            })),
                            action.payload.s3_prefix
                        );
                    }
                });
                if (childZones?.length > 0) {
                    state.childZones = childZones.filter(Boolean);
                }
            }
            if (action.payload.orbit_images_url) {
                state.activeAsset.orbit_images_url = prefixUrl(
                    action.payload.orbit_images_url,
                    action.payload.s3_prefix
                );
                state.orbit_images_url = prefixUrl(
                    action.payload.orbit_images_url,
                    action.payload.s3_prefix
                );
                state.activeAsset.orbit_images_count =
                    action.payload.orbit_images_count;
                state.orbit_images_count = action.payload.orbit_images_count;
            }
            state.activeAsset.player_url = action.payload.player_url;
            state.activeAsset.asset_companion_apps =
                action.payload.asset_companion_apps;

            // Create audiences by audience id
            if (action.payload.audiences !== undefined) {
                state.audiences = action.payload.audiences.map((audience) => {
                    var obj = {};
                    obj[audience.audience_id] = audience;
                    return obj;
                });
            }
            if (action.payload.rules !== undefined) {
                // Create rules by rule id
                state.rulesById = [];
                action.payload.rules.forEach((rule) => {
                    rule = {
                        ...rule,
                        rule_json: {
                            ...rule.rule_json,
                            event: {
                                ...rule.rule_json.event,
                                type: rule.rule_id,
                            },
                        },
                    };
                    state.rulesById[rule.rule_id] = rule;
                });
            }

            if (action.payload.isPublishedAsset) {
                state.publishedAsset = action.payload;
            }
        },
        setAssetDetails: (state, action) => {
            state.assetDetails.assetDisplayName = action.payload.display_name;
            state.assetDetails.assetDescription = action.payload.description;
        },
        addActiveZoneItem: (state, action) => {
            // If item is in a loaded zone then add to it
            const zone = state.activeAsset.zones?.find(
                (zone) => zone.zone_id === action.payload.zone_id
            );
            if (zone) {
                zone.items.push(action.payload);
            }
        },
        updateActiveZoneItem: (state, action) => {
            // If item is in a loaded zone then update it
            const zone = state.activeAsset.zones?.find(
                (zone) => zone.zone_id === action.payload.zone_id
            );
            if (zone) {
                const itemIdx = zone.items.findIndex(
                    (item) => item.item_id === action.payload.item_id
                );
                if (itemIdx !== -1) {
                    zone.items[itemIdx] = {
                        ...zone.items[itemIdx],
                        ...action.payload,
                    };
                } else {
                    zone.items.push(action.payload);
                }
            }
        },
        deleteActiveZoneItem: (state, action) => {
            // If item is in a loaded zone then delete it
            const zone = state.activeAsset.zones?.find(
                (zone) => zone.zone_id === action.payload.zone_id
            );
            if (zone) {
                remove(zone.items, (x) => x.item_id === action.payload.item_id);
            }
        },
        deleteActiveZoneItems: (state, action) => {
            // If items are in loaded zone then delete them
            const zone = state.activeAsset.zones?.find(
                (zone) => zone.zone_id === action.payload.zone_id
            );
            if (zone) {
                remove(zone.items, (x) =>
                    action.payload.ids.includes(x.item_id)
                );
            }
        },
        addActiveZoneNavLink: (state, action) => {
            // If item is in a loaded zone then add to it
            const zone = state.activeAsset.zones?.find(
                (zone) => zone.zone_id === action.payload.zone_id
            );
            if (zone) {
                zone.nav_links.push(action.payload);
            }
        },
        updateActiveZoneNavLink: (state, action) => {
            // If item is in a loaded zone then update it
            const zone = state.activeAsset.zones?.find(
                (zone) => zone.zone_id === action.payload.zone_id
            );
            if (zone) {
                const idx = zone.nav_links.findIndex(
                    (x) => x.nav_link_id === action.payload.nav_link_id
                );
                if (idx !== -1) {
                    zone.nav_links[idx] = {
                        ...action.payload,
                    };
                }
            }
        },
        deleteActiveZoneNavLink: (state, action) => {
            // If item is in a loaded zone then delete it
            const zone = state.activeAsset.zones?.find(
                (zone) => zone.zone_id === action.payload.zone_id
            );
            if (zone) {
                remove(
                    zone.nav_links,
                    (x) => x.nav_link_id === action.payload.nav_link_id
                );
            }
        },
        setZoneStatus: (state, action) => {
            state.zoneStatus = action.payload;
        },
        setDependencies: (state, action) => {
            state.dependencies = action?.payload ?? {};

            if (action.payload?.news_flashes) {
                state.newsFlashesById = [];
                state.dependencies.news_flashes.forEach((newsFlash) => {
                    newsFlash = {
                        ...newsFlash,
                        check_persistence:
                            newsFlash.display_option_id ==
                            NewsFlashDisplayOptions.oncePerUser.id,
                    };

                    if (newsFlashDateCompare(newsFlash)) {
                        state.newsFlashesById[newsFlash.news_flash_id] =
                            newsFlash;
                    }
                });
            }
        },
        clearPromptedNewsFlash: (state, action) => {
            if (action?.payload !== undefined) {
                if (
                    !state.viewedNewsFlashIds.includes(
                        action.payload.news_flash_id
                    )
                ) {
                    state.viewedNewsFlashIds.push(action.payload.news_flash_id);
                }
                state.queuedNewsFlashes = state.queuedNewsFlashes.filter(
                    (x) => action.payload.news_flash_id !== x.news_flash_id
                );
            }
        },
        clearAssetNewsFlashOnLogout: (state) => {
            state.viewedNewsFlashIds = initialState.viewedNewsFlashIds;
            state.queuedNewsFlashes = initAsset.queuedNewsFlashes;
        },
        setAssetPublishedDate: (state, action) => {
            state.published_date = action?.payload ?? null;
        },
        setIsDraft: (state, action) => {
            state.isDraft = action?.payload;
        },
        setZoneMedia: (state, action) => {
            state.zoneMedia = action.payload;
        },
        setChildZones: (state, action) => {
            state.childZones = action.payload;
        },
        clearActiveAsset: (state) => {
            state.activeAsset = {
                zones: [],
                compact_zones: [],
                isOrbitZoneExist: false,
            };
        },
    },
    extraReducers: {},
});

export const getPanelVis = (state) => state.asset.panelOpen;
export const getDependencies = (state) => state.asset.dependencies;
export const getDependencyAssets = (state) => state.asset.dependencies.assets;
export const getDependencyContents = (state) =>
    state.asset.dependencies?.content;

export const getInitialZoneId = (state) =>
    state.asset.activeAsset.initial_zone_id;

export const getAllHotspotZones = (state) => {
    return state.asset.zones.all;
};

export const getActiveAssetActiveZone = (state) => {
    let zone = state.asset.activeAsset?.zones?.find(
        (zone) => zone.zone_id === state.asset.activeZoneId
    );
    if (!Object.keys(zone || {}).length > 0) {
        zone = state.asset.childZones?.find(
            (zone) => zone.zone_id === state.asset.activeZoneId
        );
    }
    return zone;
};

export const selectAsset =
    (state) =>
    (assetId, zoneId = null) => {
        let asset;

        if (assetId === state.asset.publishedAsset.asset_id)
            asset = state.asset.publishedAsset;
        else {
            // walk dependency tree
            const nodes = [state.asset];
            while (nodes.length > 0) {
                const node = nodes.shift();
                if (node.asset_id === assetId) {
                    asset = node;
                    break;
                }
                if (Array.isArray(node.dependencies?.assets)) {
                    nodes.push(...node.dependencies.assets);
                }
            }
        }

        if (!asset) return null;

        if (zoneId && !asset.zones.find((z) => z.zone_id === zoneId)) {
            console.log(`Could not find asset id ${assetId} and ${zoneId}`);
            return null;
        }

        return asset;
    };

export const selectHasUnpositionedItems = (state) => {
    let activeZone = state.asset.activeAsset.zones.find(
        (zone) => zone.zone_id === state.asset.activeZoneId
    );
    if (!Object.keys(activeZone || {}).length > 0) {
        activeZone = state.asset.childZones?.find(
            (zone) => zone.zone_id === state.asset.activeZoneId
        );
    }
    const unpositionedItems = activeZone?.items?.filter(
        (item) =>
            (!item.yaw && !item.flat_x) ||
            (!item.flat_x && (item.flat_x < 0 || item.flat_y < 0))
    );
    return unpositionedItems?.length ?? 0;
};

export const selectCompactZones = (state) =>
    state.asset.activeAsset?.compact_zones.length > 0
        ? state.asset.activeAsset?.compact_zones
        : state.asset.activeAsset?.zones;

export const getAllTags = (state) => {
    return state.asset.allTags;
};

export const getEditingHotspot = (state) => state.asset.editingHotspot;

export const getSelectedSearchItemId = (state) =>
    state.asset.selectedSearchItemId;
export const getDefaultHighlightIconId = (state) =>
    state.asset.defaultHighlightIconId;
export const getSelectedHotspotFromUser = (state) =>
    state.asset.selectedHotspotFromUser;

export const getAllAudiences = (state) => state.asset.audiences;

export const getAllRulesById = (state) => state.asset.rulesById;

export const getAllHotspotIcons = (state) => state.asset.hotspotIcons;
export const getOrbitImagesUrl = (state) => state.asset?.orbit_images_url;
export const getOrbitImagesCount = (state) => state.asset?.orbit_images_count;

export const getAssetPublishedDate = (state) => state.asset.published_date;
export const getIsDraft = (state) => state.asset.isDraft;
export const getAssetZoneMedia = (state) => state.asset.zoneMedia;

export const getLoadedZoneById = (state, zoneId) => {
    let zone = state.asset.activeAsset?.zones?.find(
        (zone) => zone.zone_id === zoneId
    );
    return zone;
};

export const selectActiveAsset = (state) => state.asset.activeAsset;
export const selectPublishedAsset = (state) => state.asset.publishedAsset;
export const selectEmptyItemOpacity = (state) => state.asset.empty_item_opacity;

export const getActiveDetails = (state) => state.asset.assetDetails;

export const selectLoadedZones = (state) => {
    return state.asset.activeAsset.zones ?? [];
};

const buildAssetTrail = (assetTrail, targetAssetId, assets) => {
    var asset = assets.find((asset) => asset.asset_id === targetAssetId);
    if (asset) {
        assetTrail.push(asset.display_name);
        return;
    } else {
        // Pop last asset since targetAssetId asset wasn't find
        assetTrail.pop();
        // Go through each asset and look for child assets
        assets.forEach((asset) => {
            assetTrail.push(asset.display_name);
            buildAssetTrail(
                assetTrail,
                targetAssetId,
                asset.dependencies.assets
            );
        });
    }
};

export const getAssetPath = (state) => {
    if (!state.asset.activeZoneId || !state.asset.activeAsset.asset_id) {
        return 'N/A';
    }

    let zone = state.asset.activeAsset?.zones?.find(
        (zone) => zone.zone_id === state.asset.activeZoneId
    );
    if (!Object.keys(zone || {}).length > 0) {
        zone = state.asset.childZones?.find(
            (zone) => zone.zone_id === state.asset.activeZoneId
        );
    }

    if (zone?.asset_id && zone.asset_id !== state.asset.activeAsset.asset_id) {
        let currentAssetId = zone.asset_id;
        // Find asset in dependencies
        let assetTrail = [];
        buildAssetTrail(
            assetTrail,
            currentAssetId,
            state.asset.dependencies.assets
        );
        state.asset.dependencies.assets.find(
            (asset) => asset.asset_id === currentAssetId
        );
        assetTrail.unshift(state.asset.assetDetails.assetDisplayName);
        return assetTrail.join(' > ');
    } else {
        return state.asset.assetDetails.assetDisplayName;
    }
};

export const getChildZones = (state) => state.asset.childZones ?? [];

// Use createSelector so items is only calculated once (unless loadedZones changed)
// no matter how many times selectAllHotspotItems is called
export const selectAllHotspotItems = createSelector(
    [selectLoadedZones],
    (zones) => {
        const items = zones?.flatMap((zone) => zone.items);
        return items;
    }
);

export const getLoadedZone = (state, zoneId) => {
    let zone = state.asset.activeAsset?.zones?.find(
        (zone) => zone.zone_id === zoneId
    );
    if (!Object.keys(zone || {}).length > 0) {
        zone = state.asset.childZones?.find((zone) => zone.zone_id === zoneId);
    }
    return zone;
};

export const getLoadedZoneItem = (state, zoneId, itemId) => {
    let zone = state.asset.activeAsset?.zones?.find(
        (zone) => zone.zone_id === zoneId
    );
    if (!Object.keys(zone || {}).length > 0) {
        zone = state.asset.childZones?.find((zone) => zone.zone_id === zoneId);
    }
    if (zone) {
        return zone.items.find((item) => item.item_id === itemId);
    }
    return null;
};

export const getHomeZone = (state) => {
    const zones =
        state.asset.activeAsset?.compact_zones.length > 0
            ? state.asset.activeAsset?.compact_zones
            : state.asset.activeAsset?.zones;

    if (state.asset.activeAsset.initial_zone_id && zones.length > 0) {
        return (
            zones.find(
                (zone) =>
                    zone.zone_id === state.asset.activeAsset.initial_zone_id
            ) ?? zones[0]
        );
    } else {
        return null;
    }
};

export const getQueuedNewsFlashes = (state) => state.asset.queuedNewsFlashes;
export const zoneNewsFlashes = (state) => state.asset.zoneNewsFlashIds;
export const allNewsFlashById = (state) => state.asset.newsFlashesById;

export const {
    updateActiveAsset,
    addActiveZoneItem,
    updateActiveZoneItem,
    deleteActiveZoneItem,
    deleteActiveZoneItems,
    addActiveZoneNavLink,
    updateActiveZoneNavLink,
    deleteActiveZoneNavLink,
    setActiveAsset,
    setZonesOnActiveAsset,
    addZonesToActiveAsset,
    addZoneToActiveAsset,
    removeZoneFromActiveAsset,
    updateOrAddZoneInActiveAsset,
    deletedAsset,
    setActiveZone,
    loadNewsFlashes,
    updateZoneInActiveAsset,
    initAsset,
    setEditingHotspot,
    saveEditingHotspot,
    setHotspotIcons,
    setSelectedSearchItemId,
    setDependencies,
    clearPromptedNewsFlash,
    setAssetPublishedDate,
    clearAssetNewsFlashOnLogout,
    setIsDraft,
    setZoneMedia,
    setAssetDetails,
    setChildZones,
    clearActiveAsset,
} = assetSlice.actions;

export default assetSlice.reducer;
