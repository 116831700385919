import React, { useEffect, useState, useMemo } from 'react';
import {
    Button,
    Box,
    Paper,
    Tooltip,
    styled,
    Modal,
    Typography,
} from '@mui/material';
import { modalStyles } from 'GeminiViewerComponent/components/styles';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { MdDragHandle } from 'react-icons/md';
import Tree, { moveItemOnTree } from '@atlaskit/tree';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { makeFormStyles } from 'forms/styles';
import { makeContentNodeFormStyles } from 'components/ProcedureDesigner/components/ContentNodeEditor/styles';
import { selectAllTableZones } from '_features/zones/zonesTableSlice';

const makeNodeTableStyles = makeStyles(() => ({
    detailsWrapper: {
        alignItems: 'center',
        display: 'flex',
        align: 'left',
        width: '22%',
    },
    detailsContainer: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        lineHeight: '20px',
    },
    detailsTitle: {
        fontWeight: 'bold',
    },
    categoryCount: {
        fontWeight: 'bold',
        fontSize: '14px',
    },
    addButtonIcon: {
        padding: '0 !important',
        borderRadius: '25px !important',
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
    },
}));

const addZone = (tree, zone) => {
    tree.items[zone?.zone_id] = {
        id: zone.zone_id,
        data: zone,
    };
};

const zonesToTree = (zones) => {
    var tree = {
        rootId: 'root',
        items: {
            root: {
                id: 'root',
                children: [],
            },
        },
    };

    zones?.forEach((zone) => {
        addZone(tree, zone);
        tree.items.root.children.push(zone.zone_id);
    });

    return tree;
};

const StyledTableRow = styled(Box)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    verticalAlign: 'center',
    width: '100%',
    padding: '0 6px 0 0',
    display: 'flex',
    flexWrap: 'nowrap',
    fontSize: '12px',
}));

const treeToZones = (tree) => {
    var zones = [];

    var treeRootChildren = tree.items[tree.rootId].children;

    treeRootChildren?.forEach((itemId) => {
        var treeItem = tree.items[itemId];
        zones.push(treeItem.data);
    });

    return zones;
};

const ZoneDataRow = ({ zone, provided }) => {
    const theme = useSelector(selectActiveTheme);
    const nodeTableStyles = makeNodeTableStyles(theme);
    return (
        <div
            style={{
                width: '100%',
                borderBottom: '1px solid black',
            }}
        >
            <StyledTableRow
                gap="5px"
                tabIndex={-1}
                key={zone.zone_id}
                style={{
                    backgroundColor: 'white',
                }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    style={{ width: '7%' }}
                    {...provided.dragHandleProps}
                >
                    <MdDragHandle className="react-icon" />
                </Box>
                <Box className={nodeTableStyles.detailsWrapper}>
                    <Tooltip
                        title={zone.display_name}
                        className={nodeTableStyles.detailsContainer}
                    >
                        <div className={nodeTableStyles.detailsTitle}>
                            {zone.display_name}
                        </div>
                    </Tooltip>
                </Box>
            </StyledTableRow>
        </div>
    );
};

export const ReorderZoneDialog = ({
    openModal,
    setOpenModal,
    zones,
    resetOrder,
    formEditorStyles,
    categoryBeingReordered,
    setAnyZonesUpdated,
    updateDisplayOrderZones,
}) => {
    //#region State
    const [error, setError] = useState(null);
    //#endregion

    //#region Hooks
    const theme = useSelector(selectActiveTheme);
    const classes = modalStyles(theme);
    const formClasses = makeFormStyles(theme);
    //#endregion Hooks

    //#region Selectors
    const memoZones = useMemo(() => {
        let tmp = [];
        zones?.forEach((x) => {
            if (
                x.zone_category_id === categoryBeingReordered?.zone_category_id
            ) {
                // Appends the display and id with a ! since that is the highest prio sorting char to respect end of string before using the id
                let idx = x.display_order ?? `${x.display_name}!${x.zone_id}`;
                tmp[idx] = x;
            }
        });

        let res = [];
        Object.keys(tmp)
            .sort()
            .forEach((x) => {
                res.push(tmp[x]);
            });
        return res;
    }, [categoryBeingReordered, zones]);
    const [updatedZones, setUpdatedZones] = useState(memoZones);

    const tree = zonesToTree(memoZones);
    const [treeState, setTreeState] = useState({ tree: tree });

    const areZonesUpdated =
        JSON.stringify(memoZones) !== JSON.stringify(updatedZones);
    //#endregion

    //#region Effects

    useEffect(() => {
        setTreeState({
            tree: zonesToTree(memoZones),
        });
    }, [memoZones]);

    useEffect(() => {
        setTreeState({
            tree: zonesToTree(updatedZones),
        });
    }, [updatedZones]);

    useEffect(() => {
        setUpdatedZones([...memoZones]);
    }, [memoZones]);
    //#endregion

    //#region Methods
    const renderItem = ({ item, provided }) => {
        return (
            <div
                className={formEditorStyles.row}
                ref={provided.innerRef}
                {...provided.draggableProps}
            >
                <ZoneDataRow
                    key={item.id}
                    zone={item.data}
                    provided={provided}
                    error={error}
                    setError={setError}
                    updatedZones={updatedZones}
                    setUpdatedZones={setUpdatedZones}
                />
            </div>
        );
    };

    const orderDraggableField = (
        sortedNodes,
        draggableId,
        sourceIndex,
        destinationIndex,
        sourceDroppableId,
        destinationDroppableId
    ) => {
        let draggableObject = null;
        let isSourceBeforeDestination = null;
        if (
            isSourceBeforeDestination === null &&
            ((sourceDroppableId !== 'root' &&
                destinationDroppableId !== 'root' &&
                sourceDroppableId === destinationDroppableId) ||
                (sourceDroppableId === 'root' &&
                    destinationDroppableId === 'root'))
        ) {
            if (sourceIndex < destinationIndex) {
                isSourceBeforeDestination = true;
            } else {
                isSourceBeforeDestination = false;
            }
        }
        sortedNodes.map((i, index) => {
            if (
                isSourceBeforeDestination === null &&
                ((destinationDroppableId === 'root' &&
                    index === destinationIndex) ||
                    (destinationDroppableId !== 'root' &&
                        destinationDroppableId === i.zone_id))
            ) {
                isSourceBeforeDestination = false;
            }
            if (
                isSourceBeforeDestination === null &&
                ((sourceDroppableId === 'root' && index === sourceIndex) ||
                    (sourceDroppableId !== 'root' &&
                        sourceDroppableId === i.zone_id))
            ) {
                isSourceBeforeDestination = true;
            }

            if (draggableObject === null) {
                if (i.zone_id === draggableId) {
                    draggableObject = i;
                }
            }
            return null;
        });
        if (draggableObject === null) {
            return sortedNodes;
        }

        let fieldsOrder = [];
        if (sourceDroppableId === 'root' && destinationDroppableId === 'root') {
            sortedNodes.map((i, index) => {
                if (sourceIndex === index) {
                    return null;
                } else if (destinationIndex === index) {
                    if (isSourceBeforeDestination) {
                        fieldsOrder = [...fieldsOrder, i, draggableObject];
                    } else {
                        fieldsOrder = [...fieldsOrder, draggableObject, i];
                    }
                } else {
                    fieldsOrder = [...fieldsOrder, i];
                }
            });
        }
        return fieldsOrder.map((field, idx) => ({
            ...field,
            display_order: idx + 1,
        }));
    };

    const onDragEnd = async (source, destination) => {
        if (
            !destination ||
            (source.parentId === destination.parentId &&
                source.index === destination.index)
        ) {
            return;
        }
        let currentZones = treeToZones(treeState.tree);
        if (destination.parentId !== 'root') {
            return;
        }
        const orderedZones = await orderDraggableField(
            currentZones,
            treeState.tree.items[source.parentId].children[source.index],
            source.index,
            destination?.index ? destination.index : 0,
            source.parentId,
            destination.parentId
        );
        if (
            currentZones !== orderedZones ||
            (source.parentId === destination.parentId &&
                source.index === (destination?.index ? destination.index : 0))
        ) {
            moveItemOnTree(treeState.tree, source, destination);
            setTreeState({
                tree: zonesToTree(orderedZones),
            });
            setUpdatedZones(orderedZones);
        }
    };
    //#endregion

    return (
        <Modal onClose={() => setOpenModal(false)} open={openModal}>
            <div className={classes.dargModalContainer}>
                <Box className={classes.dragModalWrapper}>
                    <Typography width="100%" variant="h6">
                        {categoryBeingReordered?.display_name ?? '[N/A]'}{' '}
                        Zone(s)
                    </Typography>
                    <Box overflow="auto">
                        <Box
                            display="flex"
                            mt="10px"
                            width="600px"
                            flexDirection="column"
                            maxHeight="60vh"
                        >
                            <table
                                style={{
                                    borderBottom: '1px solid #000',
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                }}
                            >
                                <tr align="left">
                                    <th style={{ padding: '10px' }}>Zones</th>
                                </tr>
                            </table>
                            <Paper
                                style={{
                                    flex: '1',
                                    overflow: 'auto',
                                    boxShadow: 'none',
                                }}
                            >
                                <div
                                    className={formEditorStyles.container}
                                    tabIndex="1"
                                >
                                    <Tree
                                        tree={treeState.tree}
                                        renderItem={renderItem}
                                        onDragEnd={onDragEnd}
                                        offsetPerLevel={12}
                                        isDragEnabled={true}
                                        isNestingEnabled={true}
                                    />
                                </div>
                            </Paper>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                    >
                        <Button
                            className={formClasses.submitButtonSmall}
                            onClick={() => {
                                resetOrder(true);
                            }}
                        >
                            Reset Order...
                        </Button>

                        <Box display="flex" gap="5px">
                            <Button
                                className={formClasses.cancelButtonSmall}
                                onClick={() => {
                                    setUpdatedZones(memoZones);
                                    setOpenModal(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                className={formClasses.submitButtonSmall}
                                onClick={() => {
                                    if (error) return;
                                    setAnyZonesUpdated(true);
                                    updateDisplayOrderZones(updatedZones);
                                    setOpenModal(false);
                                }}
                                disabled={!areZonesUpdated}
                            >
                                Ok
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </div>
        </Modal>
    );
};
