import { makeStyles } from '@mui/styles';

const makeProcedureStyles = makeStyles((theme) => ({
    container: {
        flexGrow: 1,
        width: '100%',
        height: '100%',
        // overflow: 'auto',
        // height: 'auto', //pcalc(100vh)`,
        position: 'relative',
    },
    formContainer: {
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        overflow: 'auto',
    },
    nodeTableContainer: {
        width: '100%',
        height: '100%',
        padding: '10px',
        // overflow: 'auto',
    },
    nodeListTable: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
    },
    nodeRow: {
        display: 'flex',
        cursor: 'pointer',
        padding: '5px',
        backgroundColor: '#eee',
    },
    tableHeaderCell: {
        fontWeight: 'bold',
    },
    iconContainer: {
        cursor: 'pointer',
    },
    popupActionContainer: {
        display: 'flex',
        alignItems: 'center',
        color: '#7E8C8D',
        gap: '5px',
        cursor: 'pointer',
        padding: '10px',
        fontSize: '14px',
        '&:hover': {
            cursor: 'pointer',
            transform: 'scale(1.05)',
        },
    },
    popupActionIcon: {
        display: 'flex',
        justifyContent: 'center',
        '& svg': {
            height: '20px',
            width: '20px',
        },
    },
    popupActionLabel: {
        marginLeft: '10px',
        textTransform: 'capitalize',
    },
    twoPanelMode: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: '15px',
            height: 'calc(100vh - 30%)',
        },
        flexDirection: 'row',
        width: '100%',
        position: 'relative',
        height: 'calc(100vh - 228px)',
        display: 'flex',
        borderTop: '1px solid',
    },
    panelResizer: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        height: 'calc(100vh - 228px)',
    },
    nodeDisplayPanel: {
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
            border: '1px solid',
        },
        height: 'calc(100vh - 228px)',
        display: 'flex',
        overflow: 'auto',
    },
    nodePreviewPanel: {
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
            border: '1px solid',
        },
        height: 'calc(100vh - 228px)',
        display: 'flex',
        overflow: 'auto',
    },
    paper: {
        maxHeight: '100%',
    },
}));

export { makeProcedureStyles };
