import { makeStyles } from '@mui/styles';

const assetCardStyles = makeStyles(() => ({
    card: {
        height: '220px',
        display: 'flex',
        boxShadow: ' 0 0 17px 0 rgba(45,61,80,0.11)',
        cursor: 'pointer',
    },
    cardLink: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none !important',
        },
    },
    cardPin: {
        justifyContent: 'center',
        marginTop: '5px',
    },
    cardDisclaimer: {
        justifyContent: 'right',
    },
    cardBottom: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& svg': {
            color: '#95A5A6',
        },
        width: '100%',
    },
    cardDescription: {
        fontSize: '16px',
        color: '#34485E',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        marginBottom: '5px',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        whiteSpace: 'normal',
        lineHeight: '18px',
        userSelect: 'none',
    },
    authorDescription: {
        fontSize: '14px',
        color: '#95a5a6',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        marginBottom: '10px',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        whiteSpace: 'normal',
        userSelect: 'none',
    },
    cardImage: {
        minWidth: '48%',
        maxWidth: '48%',
        height: '100%',
        objectFit: 'contain',
        pointerEvents: 'none',
        userSelect: 'none',
        backgroundColor: '#f4f4f4',
    },
    cardDate: {
        display: 'flex',
        alignItems: 'center',
        color: '#95A5A6',
        fontSize: '14px',
        '& svg': {
            height: '15px',
        },
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    cardContainer: {
        width: '60%',
        overflow: 'hidden',
        display: 'flex',
        flexWrap: 'wrap',
        padding: '12px !important',
    },
    cardHeader: {
        alignItems: 'flex-start',
        fontWeight: 800,
        fontSize: '20px',
        color: '#34485E',
        textOverflow: 'ellipsis',
        display: 'flex',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        overflow: 'hidden',
        whiteSpace: 'normal',
        justifyContent: 'space-between',
        lineHeight: '24px',
        lineClamp: 2,
    },
    pin: {
        display: 'flex',
        cursor: 'pointer',
        '& svg': {
            height: '20px',
            width: '20px',
        },
        '& svg:hover': {
            filter: 'brightness(50%)',
        },
    },
    disclaimer: {
        display: 'flex',
        cursor: 'pointer',
        '& svg': {
            height: '20px',
            width: '20px',
        },
        '& svg:hover': {
            filter: 'brightness(50%)',
        },
    },
    isPinned: {
        color: '#E84C3C',
        display: 'flex',
    },
    iconWrapper: {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        marginLeft: 'auto',
        flexDirection: 'column',
    },
    cardContainerWrapper: {
        height: '155px',
        width: '100%',
        overflow: 'auto',
        marginBottom: '10px',
    },
    assetIcon: {
        width: '40px',
        height: '40px',
        backgroundColor: '#34485ec7',
        borderRadius: '4px',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tooltip: {
        whiteSpace: 'inherit',
    },
}));

export { assetCardStyles };
