import { useDispatch, useSelector } from 'react-redux';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { useSnackbar } from 'notistack';
import { MdClose } from 'react-icons/md';
import { Button, Box } from '@mui/material';

import { SelectionAutocomplete } from 'components';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { makeFormStyles } from '../../forms/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    getClients,
    selectClients,
    selectClientsLoadingStatus,
} from '_features/clients/clientsSlice';
import { selectActionModalInfo } from 'GeminiViewerComponent/_features/common/actionModalSlice';
import { accountsSlice, accountsThunk } from 'app/store';
import {
    copyAssetToClient,
    duplicateAsset,
} from '_features/assets/assetsTableSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

const { fetchAllUserByClientID } = accountsThunk;

const { getUserByClientId, getUserByClientIdStatus, resetUsersByClientId } =
    accountsSlice;

const DuplicateAssetForm = ({ handleClose, assetId }) => {
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    const actionModalInfo = useSelector(selectActionModalInfo);

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const initValues = {
        target_client_id: undefined,
        target_user_id: undefined,
    };

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        dispatch(activateLoading({ showProgress: true }));
        let resultAction = null;
        const requestPayload = {
            ...values,
        };
        if (requestPayload?.target_client_id) {
            resultAction = await dispatch(
                copyAssetToClient({
                    asset_id: assetId,
                    ...requestPayload,
                    dispatch: dispatch,
                })
            );
        } else {
            resultAction = await dispatch(duplicateAsset(assetId));
        }
        const { message, variant } = snackbarHandler(
            resultAction.meta.requestStatus,
            'Duplicate'
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });
        dispatch(deactivateLoading());
        setSubmitting(false);
        resetForm();
        handleClose();
    };

    return (
        <Dialog
            fullWidth
            maxWidth={'xs'}
            open={actionModalInfo?.isOpen}
            onClose={handleClose}
        >
            <DialogTitle
                sx={{
                    fontSize: '20px',
                    padding: '10px 24px',
                    borderBottom: '1px solid #dddddd',
                }}
            >
                Duplicate Asset
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 6,
                        color: '#000',
                    }}
                    size="large"
                >
                    <MdClose className="react-icon" />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ marginTop: '10px' }}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{ ...initValues }}
                    validationSchema={Yup.object({
                        target_user_id: Yup.number().when('target_client_id', {
                            is: (targetClientId) => !!targetClientId,
                            then: Yup.number().required('Required'),
                            otherwise: Yup.number().nullable(),
                        }),
                    })}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        handleSubmit(values, setSubmitting, resetForm);
                    }}
                >
                    {({ isSubmitting, values, errors }) => (
                        <Form
                            className={classes.form}
                            style={{ padding: '0px' }}
                        >
                            <Field
                                className={classes.input}
                                name="target_client_id"
                            >
                                {({
                                    field: { value },
                                    form: { setFieldValue },
                                }) => (
                                    <SelectionAutocomplete
                                        title="Target Client"
                                        placeholder="Client"
                                        keyProperty="client_id"
                                        multiSelection={false}
                                        nameProperty="display_name"
                                        entityIds={value}
                                        setEntityIds={setFieldValue}
                                        entitySelector={selectClients}
                                        entityStatusSelector={
                                            selectClientsLoadingStatus
                                        }
                                        onCustomChange={(evt) => {
                                            setFieldValue(
                                                'client_id',
                                                evt.target_client_id
                                            );
                                            dispatch(resetUsersByClientId());
                                        }}
                                        fetchEntityPage={getClients}
                                        formField={'target_client_id'}
                                        fieldValidationLength={150}
                                        freeSolo={false}
                                    />
                                )}
                            </Field>
                            {!!values?.target_client_id && (
                                <Field
                                    className={classes.input}
                                    name="target_user_id"
                                >
                                    {({
                                        field: { value },
                                        form: { setFieldValue },
                                    }) => (
                                        <SelectionAutocomplete
                                            title="Target User"
                                            placeholder="User"
                                            keyProperty="user_id"
                                            multiSelection={false}
                                            nameProperty="full_name"
                                            entityIds={value}
                                            setEntityIds={setFieldValue}
                                            entitySelector={getUserByClientId}
                                            entityStatusSelector={
                                                getUserByClientIdStatus
                                            }
                                            fetchEntityPage={
                                                fetchAllUserByClientID
                                            }
                                            formField={'target_user_id'}
                                            freeSolo={false}
                                            fetchParams={{
                                                target_client_id:
                                                    values?.target_client_id,
                                            }}
                                        />
                                    )}
                                </Field>
                            )}
                            {errors?.target_user_id && (
                                <div className={classes.inputErr}>
                                    {errors.target_user_id}
                                </div>
                            )}
                            <Typography
                                sx={{ marginTop: '0.5rem' }}
                                variant="caption"
                                component="span"
                            >
                                Note: If no client is selected, the asset will
                                be duplicated in the current client.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    mt: '1rem',
                                }}
                            >
                                <Button
                                    onClick={handleClose}
                                    className={classes.cancelButtonSmall}
                                    variant="outlined"
                                    sx={{ mr: '1rem' }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={classes.submitButtonSmall}
                                    variant="contained"
                                    type="submit"
                                    disabled={isSubmitting}
                                    sx={{
                                        padding: '5px 15px',
                                        margin: '0',
                                        fontSize: '14px',
                                    }}
                                >
                                    Duplicate
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export { DuplicateAssetForm };
