import React, { useEffect } from 'react';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { MdDelete } from 'react-icons/md';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { Box, Grid, IconButton, TextField, Tooltip } from '@mui/material';

import { getVideoDurationFromURL } from '../_helpers/contentFormValidations';
import FieldValidationError from 'GeminiViewerComponent/components/Procedure/components/ProcedureFormNode/FormFields/FieldValidationError';

export const ChapterField = ({ idx, handleRemoveChapter }) => {
    const { values, setFieldValue, errors } = useFormikContext();

    useEffect(() => {
        if ((values?.image_url || values?.url) && !values?.content_data) {
            (async () => {
                const duration = await getVideoDurationFromURL(
                    values?.image_url || values?.url
                );
                setFieldValue('videoDuration', duration);
            })();
        }
    }, [values?.image_url]);

    return (
        <Grid
            container
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                paddingLeft: '5px',
            }}
        >
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box position="relative">
                    <TextField
                        margin="dense"
                        variant="outlined"
                        type="text"
                        fullWidth
                        label="Title"
                        value={values?.['chapters']?.[idx]?.title}
                        error={errors?.chapters?.[idx]?.title}
                        placeholder="Title"
                        name={`chapters[${idx}].title`}
                        onChange={(e) => {
                            let currOptions = [...values.chapters];
                            currOptions[idx] = {
                                ...values.chapters[idx],
                                title: e.target.value,
                            };
                            setFieldValue(
                                `chapters[${idx}].title`,
                                e.target.value
                            );
                        }}
                    />
                    {errors?.chapters?.[idx]?.title && (
                        <Box
                            position="absolute"
                            top="90%"
                            width="100%"
                            sx={{
                                '& div': {
                                    margin: '4px !important',
                                },
                            }}
                        >
                            <FieldValidationError
                                validationError={errors?.chapters?.[idx]?.title}
                            />
                        </Box>
                    )}
                </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                        name={`chapters[${idx}].start_time`}
                        label="Start Time"
                        views={['hours', 'minutes', 'seconds']}
                        ampm={false}
                        ampmInClock={false}
                        disableOpenPicker
                        inputFormat="HH:mm:ss.SSS"
                        value={moment(
                            values?.['chapters']?.[idx]?.start_time,
                            'HH:mm:ss.SSS'
                        )}
                        onChange={(e) => {
                            setFieldValue(
                                `chapters[${idx}].start_time`,
                                !e ||
                                    moment(e).format('HH:mm:ss.SSS') ===
                                        'Invalid date'
                                    ? e
                                    : moment(e).format('HH:mm:ss.SSS')
                            );
                        }}
                        renderInput={(params) => {
                            params = {
                                ...params,
                                error: errors?.chapters?.[idx]?.start_time,
                                inputProps: {
                                    ...params.inputProps,
                                },
                            };

                            return (
                                <Box position="relative">
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        {...params}
                                    />
                                    {errors?.chapters?.[idx]?.start_time && (
                                        <Box
                                            position="absolute"
                                            top="90%"
                                            width="100%"
                                            sx={{
                                                '& div': {
                                                    margin: '4px !important',
                                                },
                                            }}
                                        >
                                            <FieldValidationError
                                                validationError={
                                                    errors?.chapters?.[idx]
                                                        ?.start_time
                                                }
                                            />
                                        </Box>
                                    )}
                                </Box>
                            );
                        }}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                        name={`chapters[${idx}].end_time`}
                        label="End Time"
                        ampm={false}
                        ampmInClock={false}
                        disableOpenPicker
                        views={['hours', 'minutes', 'seconds']}
                        inputFormat="HH:mm:ss.SSS"
                        value={moment(
                            values?.['chapters']?.[idx]?.end_time,
                            'HH:mm:ss.SSS'
                        )}
                        onChange={(e) => {
                            setFieldValue(
                                `chapters[${idx}].end_time`,
                                !e ||
                                    moment(e).format('HH:mm:ss.SSS') ===
                                        'Invalid date'
                                    ? e
                                    : moment(e).format('HH:mm:ss.SSS')
                            );
                        }}
                        renderInput={(params) => {
                            params = {
                                ...params,
                                error: errors?.chapters?.[idx]?.end_time,
                                inputProps: {
                                    ...params.inputProps,
                                },
                            };
                            return (
                                <Box position="relative">
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        {...params}
                                    />
                                    {errors?.chapters?.[idx]?.end_time && (
                                        <Box
                                            position="absolute"
                                            top="90%"
                                            width="100%"
                                            sx={{
                                                '& div': {
                                                    margin: '4px !important',
                                                },
                                            }}
                                        >
                                            <FieldValidationError
                                                validationError={
                                                    errors?.chapters?.[idx]
                                                        ?.end_time
                                                }
                                            />
                                        </Box>
                                    )}
                                </Box>
                            );
                        }}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1}>
                <Tooltip title="Remove Chapter">
                    <IconButton
                        onClick={() => {
                            handleRemoveChapter(idx);
                        }}
                        size="small"
                    >
                        <MdDelete className="react-icon" />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

export default ChapterField;
