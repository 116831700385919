import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { useStyles } from './styles';
import { PartsFilter } from './PartsFilter';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { ResizableTable } from 'GeminiViewerComponent/components/_Tables/ResizableTable';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import {
    closePanel,
    openPanel,
    setEditObjectId,
} from '_features/common/formSlice';
import {
    resizableLabelHeader,
    resizableLabelCell,
    resizableActionCell,
    resizableCheckCell,
    resizableCheckHeaderCell,
    resizableNoOfRecordCell,
    resizableUsageDisplayCell,
} from 'components';

function PartsTable({
    parts,
    pageInfo,
    partPayload,
    setPartPayload,
    sortDirection,
    sortBy,
    handleSort,
}) {
    const classes = useStyles();
    const tableClasses = tableStyles();
    const dispatch = useDispatch();
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);
    const [searchStringState, setSearchStringState] = useState('');

    const loadedRowsMap = useSelector((state) => state.parts.loadedPartMap);

    const headerHeight = 40;
    const rowHeight = 70;

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== parts.length) {
            setSelected(parts.map((row) => row.part_id));
            setSelectedAll(true);
        }
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedIds = parts.map((part) => part.part_id);
            setSelected(newSelectedIds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    const handleClick = (event, part_id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(part_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, part_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        setSelectedAll(newSelected.length === parts.length);
    };

    const handleRowClick = async ({ rowData }) => {
        await dispatch(closePanel({ formKey: 'partForm' }));
        if (rowData?.part_id) {
            await dispatch(
                setEditObjectId({
                    formKey: 'partForm',
                    object_id: rowData?.part_id,
                })
            );
            dispatch(openPanel({ formKey: 'partForm', formAction: 'Edit' }));
        }
    };

    const columns = [
        {
            accessorKey: 'part_id',
            size: 50,
            minSize: 50,
            label: '',
            enableResizing: false,
            padding: 'checkbox',
            cellLevel: 'part',
            numSelected: selected.length,
            rowCount: parts?.length,
            handleClick: handleClick,
            handleSelectAllClick: handleSelectAllClick,
            isSelected: isSelected,
            header: (info) =>
                resizableCheckHeaderCell(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableCheckCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'part',
            size: 50,
            minSize: 50,
            label: '',
            enableResizing: false,
            padding: 'none',
            actionCellLevel: 'part',
            targetPopup: 'partManagement',
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableActionCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'display_name',
            classes: tableClasses,
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'Name',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'part_category_name',
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'Category',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'groups',
            flexGrow: 1,
            size: 80,
            minSize: 80,
            enableResizing: false,
            label: 'Groups',
            padding: 'normal',
            displayDataKey: 'display_name',
            onClick: () => {
                console.log('Groups');
            },
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableUsageDisplayCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'procedures',
            classes: tableClasses,
            flexGrow: 1,
            size: 100,
            minSize: 100,
            enableResizing: false,
            label: 'Procedures',
            padding: 'normal',
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableNoOfRecordCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
    ];

    // eslint-disable-next-line no-unused-vars
    function loadMoreRows({ startIndex, stopIndex }) {}
    return (
        <div className={classes.root}>
            <Box display="flex" alignItems="center">
                <PartsFilter
                    partPayload={partPayload}
                    setPartPayload={setPartPayload}
                    searchStringState={searchStringState}
                    setSearchStringState={setSearchStringState}
                />
            </Box>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    toolTipTitle={'Delete'}
                    totalCount={pageInfo.TotalCount}
                >
                    {/* <PopupAction
                                action={Action.DeleteArray}
                                object={{ toolIDs: selected, setToolIds: setSelected }}
                                level={'part'}
                                showLabel={false}
                            /> */}
                </EnhancedTableToolbar>
            )}
            <div
                className={classes.partsWrapper}
                style={{
                    height: `calc(100vh - ${
                        selected.length > 0 ? '370px' : '305px'
                    })`,
                }}
            >
                <ResizableTable
                    initialRows={parts}
                    allRows={parts}
                    totalRowCount={pageInfo.TotalCount}
                    loadedRowsMap={loadedRowsMap}
                    loadMoreRows={loadMoreRows}
                    columns={columns}
                    cellClassName={tableClasses.flexContainer}
                    className={tableClasses.table}
                    rowClassName={tableClasses.flexContainer}
                    headerHeight={headerHeight}
                    rowHeight={rowHeight}
                    onRowClick={handleRowClick}
                />
            </div>
        </div>
    );
}

export { PartsTable };
