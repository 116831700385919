import { ReactComponent as ReactLogo } from 'GeminiViewerComponent/assets/icon_spinner.svg';
import clsx from 'clsx';
import { makeCommonStyles } from './styles';
import { useSelector } from 'react-redux';
import Fade from '@mui/material/Fade';

function GeminiSharedLoadingIcon({
    styleClassName,
    rotateStyleName,
    svgSelector,
    fadeIn = false,
}) {
    const classes = makeCommonStyles();
    const svgObj = useSelector(svgSelector);

    const curStyleClassName = styleClassName ?? classes.loadingSvg;
    const curRotateClassname = rotateStyleName ?? classes.rotateSvg;

    const svgClasses =
        svgObj?.loadingSvgCssAnimate ?? false
            ? clsx(curStyleClassName, curRotateClassname)
            : clsx(curStyleClassName);

    const Loader = svgObj?.loadingSvgUri ? (
        <object
            type="image/svg+xml"
            className={svgClasses}
            data={svgObj?.loadingSvgUri}
        />
    ) : (
        <ReactLogo className={clsx(curStyleClassName, curRotateClassname)} />
    );

    return fadeIn ? (
        <Fade in={true} unmountOnExit>
            {Loader}
        </Fade>
    ) : (
        Loader
    );
}

export { GeminiSharedLoadingIcon };
