import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from '@mui/material';
import { FlyerGrid } from './components/FlyerGrid';
import { FlyerView } from './components/FlyerView';
import { flyerDisplayStyles } from './styles';
import {
    getFlyerVis,
    setFlyerVis,
    getShowFlyer,
    setShowFlyer,
} from '../../_features/globals/flyerSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

const FlyerDisplay = () => {
    const dispatch = useDispatch();

    const theme = useSelector(selectActiveTheme);
    const classes = flyerDisplayStyles(theme);
    const flyerOpen = useSelector((state) => getFlyerVis(state));
    const showFlyer = useSelector((state) => getShowFlyer(state));

    const handleClose = () => {
        dispatch(setFlyerVis({ open: false }));
        dispatch(setShowFlyer({ showFlyer: false, flyerImg: '' }));
    };

    const imgs = [
        'https://source.unsplash.com/random/?nature,water',
        'https://source.unsplash.com/random/?nature',
        'https://source.unsplash.com/random/?water',
        'https://source.unsplash.com/random/?moutain,water',
        'https://source.unsplash.com/random/?moutain',
        'https://source.unsplash.com/random/?sand',
        'https://source.unsplash.com/random/?sand,water',
    ];

    // Flyer is closed
    if (!flyerOpen) return null;

    return (
        <div>
            <Modal open={flyerOpen} onClose={handleClose}>
                <div className={classes.modalContainer}>
                    {showFlyer ? (
                        <FlyerView />
                    ) : (
                        <FlyerGrid numCol={3} imgs={imgs} />
                    )}
                    <Button
                        className={classes.close}
                        onClick={handleClose}
                        variant="contained"
                        disableElevation
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export { FlyerDisplay };
