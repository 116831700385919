import { useState } from 'react';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { MdChevronLeft, MdMenu } from 'react-icons/md';
import { useSelector } from 'react-redux';

import logo from 'assets/logo.png';
import NavList from './components/NavList';
import { useStyles } from './styles';
import {
    ContentValidationModal,
    ActionModal,
    GenericDisplayModal,
} from 'components';
import {
    getLoadingState,
    getProgress,
    getShowProgress,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { accountsSlice } from 'app/store';
import {
    isAboutModalOpen,
    selectActionModalInfo,
} from 'GeminiViewerComponent/_features/common/actionModalSlice';
import { ViewerSlideLayout } from 'components/_Layout/ViewerSlideLayout';
import { LoadingOverlay } from 'GeminiViewerComponent/components/LoadingOverlay';
import { AboutModal } from 'components/_Modals/AboutModal/Index';
import VttEditor from 'scenes/ContentManager/VttEditor/VttEditor';

const { selectActiveUser } = accountsSlice;

function Nav() {
    //#region Constants
    //#endregion Constants

    //#region Hooks
    const classes = useStyles();
    //#endregion Hooks

    //#region State
    const [open, setOpen] = useState(false);
    //#endregion State

    //#region Selectors
    const activeUser = useSelector(selectActiveUser);
    const showLoading = useSelector(getLoadingState);
    const progress = useSelector(getProgress);
    const showProgress = useSelector(getShowProgress);
    const actionModalInfo = useSelector(selectActionModalInfo);
    const aboutModalInfo = useSelector(isAboutModalOpen);

    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Effects
    //#endregion Effects

    //#region Methods
    const handleDrawerState = (update) => setOpen(update);
    //#endregion Methods

    if (!activeUser) return null;

    //#region Render
    return (
        <>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.containerDrawer}>
                    <div className={classes.toolbar}>
                        <img
                            src={logo}
                            alt="logo"
                            className={clsx(classes.logo, {
                                [classes.hide]: !open,
                            })}
                        ></img>
                        <IconButton
                            color="inherit"
                            edge="start"
                            onClick={() => handleDrawerState(false)}
                            className={clsx(classes.arrowIcon, {
                                [classes.hide]: !open,
                            })}
                            size="large"
                        >
                            <MdChevronLeft
                                className={`react-icon ${classes.closeIcon}`}
                            />
                        </IconButton>
                        <IconButton
                            onClick={() => handleDrawerState(true)}
                            className={clsx({ [classes.hide]: open })}
                            size="large"
                        >
                            <MdMenu className="react-icon" />
                        </IconButton>
                    </div>
                    <NavList drawerOpen={open}></NavList>
                </div>
            </Drawer>
            <LoadingOverlay
                showBackdrop={showLoading}
                showProgress={showProgress}
                progress={progress.progress}
                label={progress.label}
                description={progress.description}
                noAnimation={progress.noAnimation}
            />

            {actionModalInfo.isOpen && <ActionModal {...actionModalInfo} />}
            {aboutModalInfo && <AboutModal />}

            <ContentValidationModal />

            <ViewerSlideLayout />

            <GenericDisplayModal />

            <VttEditor />
        </>
    );
    //#endregion Render
}

export { Nav };
