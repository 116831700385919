import React from 'react';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { useStyles } from './styles';

const AppButton = ({
    text,
    style,
    children,
    variant,
    className,
    handleButtonClick,
}) => {
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);
    return (
        <Button
            variant={variant}
            disableElevation
            className={classes[className]}
            style={style}
            onClick={handleButtonClick}
        >
            {text}
            {children}
        </Button>
    );
};

export default AppButton;
