import { IconButton } from '@mui/material';
import { MdAdd } from 'react-icons/md';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import ImageAnswerField from './ImageAnswerField';
import ImagePairsTargetNode from './ImagePairsTargetNode';

const ImageAnswerGroupType = ({
    node,
    nodes,
    values,
    setFieldValue,
    nodeFormStyles,
    onDragEnd,
    handleRemove,
    handleRemoveOptionsSelected,
}) => {
    const handleAddImageOption = (image_options, setFieldValue) => {
        if (!Array.isArray(image_options)) {
            image_options = [];
        }
        const imageOpts = [...image_options];
        imageOpts.push({
            action: {
                id: '',
                type: 'node',
            },
            img_id: Number(imageOpts?.length),
            img_url: '',
            img_name: '',
            img_data: null,
            option_text: uuidv4(),
            incorrect: false,
            answer_value: '',
        });
        setFieldValue('image_options', imageOpts);
    };

    const handleAddImagePairTarget = (
        image_target_combinations,
        setFieldValue
    ) => {
        if (!Array.isArray(image_target_combinations)) {
            image_target_combinations = [];
        }
        const imagePairs = [...image_target_combinations];
        imagePairs.push({
            action: {
                id: '',
                type: 'node',
            },
            image_pair_id: Number(imagePairs?.length),
            options_required: [],
            incorrect: false,
            answer_value: '',
        });
        setFieldValue('image_target_combinations', imagePairs);
    };

    return (
        <>
            <div>
                Image Options{' '}
                <IconButton
                    className={nodeFormStyles.addButtonIcon}
                    onClick={() =>
                        handleAddImageOption(
                            values?.image_options,
                            setFieldValue
                        )
                    }
                    size="small"
                >
                    <MdAdd className="react-icon" />
                </IconButton>
            </div>
            <DragDropContext
                onDragEnd={(result) =>
                    onDragEnd(
                        result,
                        values?.image_options,
                        'image_options',
                        setFieldValue
                    )
                }
            >
                <Droppable droppableId="droppable-content-nodes-images">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{ margin: '40px 0' }}
                        >
                            {values?.image_options?.map((option, idx) => {
                                return (
                                    <ImageAnswerField
                                        option={option}
                                        key={`ImageAnswerField${idx}`}
                                        idx={idx}
                                        node={node}
                                        nodes={nodes}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        provided={provided}
                                        handleRemove={(id) => {
                                            let opt_txt =
                                                values.image_options[id]
                                                    .option_text;
                                            handleRemove(
                                                id,
                                                values.image_options,
                                                'image_options',
                                                setFieldValue
                                            );
                                            handleRemoveOptionsSelected(
                                                opt_txt,
                                                values.image_target_combinations,
                                                'image_target_combinations',
                                                setFieldValue
                                            );
                                        }}
                                    />
                                );
                            })}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {values?.multiple_answers_allow === true &&
                Array.isArray(values?.image_options) &&
                values?.image_options?.filter((opt) => opt?.img_url)?.length >
                    1 && (
                    <>
                        <div>
                            Target Combinations{' '}
                            <IconButton
                                className={nodeFormStyles.addButtonIcon}
                                onClick={() =>
                                    handleAddImagePairTarget(
                                        values?.image_target_combinations,
                                        setFieldValue
                                    )
                                }
                                size="small"
                            >
                                <MdAdd className="react-icon" />
                            </IconButton>
                        </div>
                        <DragDropContext
                            onDragEnd={(result) =>
                                onDragEnd(
                                    result,
                                    values?.image_target_combinations,
                                    'image_target_combinations',
                                    setFieldValue
                                )
                            }
                        >
                            <Droppable droppableId="droppable-content-nodes-images-combinations">
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        style={{ margin: '40px 0' }}
                                    >
                                        {values?.image_target_combinations?.map(
                                            (option, idx) => (
                                                <ImagePairsTargetNode
                                                    option={option}
                                                    selectOptions={values?.image_options?.filter(
                                                        (opt) => opt?.img_url
                                                    )}
                                                    key={`ImagePairsTargetNode${idx}`}
                                                    idx={idx}
                                                    node={node}
                                                    nodes={nodes}
                                                    values={values}
                                                    setFieldValue={
                                                        setFieldValue
                                                    }
                                                    provided={provided}
                                                    handleRemove={(id) =>
                                                        handleRemove(
                                                            id,
                                                            values.image_target_combinations,
                                                            'image_target_combinations',
                                                            setFieldValue
                                                        )
                                                    }
                                                />
                                            )
                                        )}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                )}
        </>
    );
};

export default ImageAnswerGroupType;
