class PanoDraggable {
    dragging = false;
    disableClick = false;
    currentX;
    currentY;
    initialX;
    initialY;
    offsetX;
    offsetY;
    moved = false;
    dragElement;
    draggingHotspot;
    onMovedElement;
    relativeMove;
    initialOffset;
    lastLocation = null;
    unPositionedItem = false;
    isLabelDrag = false;
    labelOffset;

    getTranslateValues(element) {
        const style = window.getComputedStyle(element);
        const matrix =
            style['transform'] || style.webkitTransform || style.mozTransform;

        // No transform property. Simply return 0 values.
        if (matrix === 'none' || typeof matrix === 'undefined') {
            return {
                x: 0,
                y: 0,
                z: 0,
            };
        }

        // Can either be 2d or 3d transform
        const matrixType = matrix.includes('3d') ? '3d' : '2d';
        const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ');

        // 2d matrices have 6 values
        // Last 2 values are X and Y.
        // 2d matrices does not have Z value.
        if (matrixType === '2d') {
            return {
                x: matrixValues[4],
                y: matrixValues[5],
                z: 0,
            };
        }

        // 3d matrices have 16 values
        // The 13th, 14th, and 15th values are X, Y, and Z
        if (matrixType === '3d') {
            return {
                x: matrixValues[12],
                y: matrixValues[13],
                z: matrixValues[14],
            };
        }
    }

    handleMouseDown = (
        event,
        dragElement,
        hotspot,
        onMoved,
        relativeMove = true,
        unPositionedItem = false
    ) => {
        this.onMovedElement = onMoved;

        if (window.geminiViewManager) {
            window.geminiViewManager.disableControls();
        }
        this.dragging = true;
        this.draggingHotspot = hotspot;
        this.unPositionedItem = unPositionedItem;

        this.relativeMove = relativeMove;

        this.dragElement = dragElement;

        let clientPos = this.getPosition(event);

        if (relativeMove === false) {
            let rect = dragElement.getBoundingClientRect();
            let centerX = (rect.left + rect.right) / 2;
            let centerY = (rect.top + rect.bottom) / 2;

            this.initialOffset = {
                x: clientPos.x - centerX,
                y: clientPos.y - centerY,
            };
        } else {
            this.initialOffset = { x: 0, y: 0 };
        }
        var translate = this.getTranslateValues(this.dragElement);

        this.offsetX = translate.x;
        this.offsetY = translate.y;

        this.initialX = clientPos.x - translate.x;
        this.initialY = clientPos.y - translate.y;

        document.onpointerup = this.closeDragElement;
        // document.onpointerend = this.closeDragElement;

        document.onpointermove = this.elementDrag;
        // document.onmousemove = this.elementDrag;
    };

    elementDrag = (e) => {
        e = e || window.event;

        this.disableClick = true;

        let clientPos = this.getPosition(e);

        this.currentX = clientPos.x - this.initialX;
        this.currentY = clientPos.y - this.initialY;

        this.offsetX = this.currentX;
        this.offsetY = this.currentY;

        const { location } = this.getCoordinates(e);
        if (
            +location.x < 0 ||
            +location.x > 1 ||
            +location.y < 0 ||
            +location.y > 1
        ) {
            return;
        } else {
            this.lastLocation = location;
            this.dragElement.style.transform =
                'translate(' + this.currentX + 'px, ' + this.currentY + 'px)';
        }
        this.moved = true;
    };

    handleLabelPosition = (
        event,
        dragElement,
        hotspot,
        onMoved,
        relativeMove = true,
        unPositionedItem = false
    ) => {
        this.isLabelDrag = true;
        this.onMovedElement = onMoved;

        if (window.geminiViewManager) {
            window.geminiViewManager.disableControls();
        }
        this.dragging = true;
        this.draggingHotspot = hotspot;
        this.unPositionedItem = unPositionedItem;

        this.relativeMove = relativeMove;

        this.dragElement = dragElement;

        let clientPos = this.getPosition(event);

        let rect = dragElement.getBoundingClientRect();

        this.initialOffset = {
            x: rect.x,
            y: rect.y,
        };

        var translate = this.getTranslateValues(this.dragElement);

        this.offsetX = translate.x;
        this.offsetY = translate.y;

        this.initialX = clientPos.x - translate.x;
        this.initialY = clientPos.y - translate.y;

        document.onpointerup = this.closeDragElement;
        // document.onpointerend = this.closeDragElement;

        document.onpointermove = this.labelElementDrag;
        // document.onmousemove = this.elementDrag;
    };

    labelElementDrag = (e) => {
        e = e || window.event;

        this.disableClick = true;

        let clientPos = this.getPosition(e);
        var translate = this.getTranslateValues(this.dragElement);
        const { location } = this.getCoordinates(e);

        this.currentX = clientPos.x - this.initialX;
        this.currentY = clientPos.y - this.initialY;

        this.offsetX = this.currentX;
        this.offsetY = this.currentY;

        this.lastLocation = location;
        this.dragElement.style.transform =
            'translate(' + this.currentX + 'px, ' + this.currentY + 'px)';

        this.labelOffset = {
            x:
                parseInt(this.draggingHotspot.label_x ?? 75) +
                parseInt(translate.x),
            y:
                parseInt(this.draggingHotspot.label_y ?? 20) +
                parseInt(translate.y),
        };
        this.moved = true;
    };

    getPosition = (e) => {
        if (e.type === 'touchmove') {
            return { x: e.touches[0].clientX, y: e.touches[0].clientY };
        } else {
            return { x: e.clientX, y: e.clientY };
        }
    };

    closeDragElement = (e) => {
        e = e || window.event;

        if (window.geminiViewManager) {
            window.geminiViewManager.enableControls();
        }

        document.onpointerup = null;
        document.onpointermove = null;
        // document.onmouseup = null;
        // document.ontouchend = null;
        // document.onmousemove = null;
        // document.ontouchmove = null;

        if (this.moved) {
            this.moved = false;

            if (this.onMovedElement) {
                const { location, screen } = this.getCoordinates(e);
                if (location) {
                    if (this.isLabelDrag) {
                        this.isLabelDrag = false;
                        this.onMovedElement(
                            this.draggingHotspot,
                            this.labelOffset ? this.labelOffset : location,
                            screen,
                            this.dragElement
                        );
                    } else {
                        this.onMovedElement(
                            this.draggingHotspot,
                            this.lastLocation ? this.lastLocation : location,
                            screen,
                            this.dragElement
                        );
                    }
                    this.lastLocation = null;
                    this.unPositionedItem = false;
                }
            }
        }

        setTimeout(() => {
            this.disableClick = false;
        }, 100);
    };

    getCoordinates = (e) => {
        let screen;
        if (this.relativeMove === true) {
            screen = {
                x: this.offsetX,
                y: this.offsetY,
            };
        } else {
            screen = this.getPosition(e);
            screen.x -= this.initialOffset.x;
            screen.y -= this.initialOffset.y + (this.unPositionedItem ? 41 : 0); //Change 41 as same as navbar height while changing Navbar height
        }
        let location = window.geminiViewManager.screenToCoordinates(screen);
        return { location, screen };
    };
}

export let panoDraggable = new PanoDraggable();
