import { Grid, Button, FormControl } from '@mui/material';
import { FormTextField } from 'components/TextField';

const RadioGroupField = ({
    idx,
    values,
    setFieldValue,
    handleRemoveRadioGroup,
    ...otherProps
}) => {
    return (
        <Grid
            container
            key={'radio_' + idx}
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                marginTop: '6px',
            }}
            {...otherProps}
        >
            <Grid item xs={12} sm={12} md={10} lg={10}>
                <FormTextField
                    fullWidth
                    label="Label"
                    placeholder="Label"
                    name={`fields[${idx}].label`}
                    onChange={(e) => {
                        let currOptions = [...values.fields];
                        currOptions[idx] = {
                            ...values.fields[idx],
                            type: 'radio',
                            label: e.target.value,
                        };
                        setFieldValue(`fields[${idx}].label`, e.target.value);
                    }}
                    {...otherProps}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1}>
                <FormControl fullWidth>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            handleRemoveRadioGroup(idx);
                        }}
                    >
                        Remove
                    </Button>{' '}
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default RadioGroupField;
