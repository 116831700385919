import { makeStyles } from '@mui/styles';

export const makeClientConfigStyles = makeStyles(() => ({
    form: {
        width: '100%',
    },
    globalRow: {
        borderLeft: '2px solid #77fbec',
    },
    unsetRow: {
        borderLeft: '2px solid #fae08b',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '10px',
        alignItems: 'center',
        borderBottom: '2px solid #7E8C8D',
    },
    label: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#7E8C8D',
        paddingLeft: '20px',
    },
    column: {
        padding: '3px',
        border: '1px solid',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '10px',
        alignItems: 'center',
        flexGrow: '1',
    },
    materialInput: {
        width: '90%',
        fontSize: '18px',
        color: '#2D3D50',
    },
    button: {
        marginTop: '5px',
        background: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        border: '0px',
        width: '100px',
        padding: '10px',
        borderRadius: '5px',
        fontSize: '14px',
        textTransform: 'uppercase',
        cursor: 'pointer',
        variant: 'outlined',
        '&:disabled': {
            background: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(50%)',
        },
        '&:hover': {
            background: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(90%)',
        },
    },
    unsaveData: {
        backgroundColor: 'white',
        zIndex: 2,
        pointerEvents: 'auto',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        padding: '16px 15px',
        position: 'relative',
    },
    configContainer: {
        overflow: 'auto',
        height: 'calc(100vh - 300px)',
        marginBottom: '10px',
    },
}));
