import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Chip, Avatar, TextField } from '@mui/material';
import { BiShow } from 'react-icons/bi';
import Dropzone from 'react-dropzone';
import { MdClose } from 'react-icons/md';

import { FormActions } from '_helpers/form-action';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { makeFormStyles } from '../styles';
import { panelStyles } from 'GeminiViewerComponent/components/styles';
import { userPanelStyles } from 'scenes/styles';
import { SelectionAutocomplete } from 'components';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { LinkTypes } from '_helpers/enumerations';
import { closePanel } from '_features/common/formSlice';
import {
    selectAllGroups,
    getGroupStatus,
    fetchGroupsPage,
    resetGroupState,
} from '_features/groups/groupsSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { accountsSlice, accountsThunk } from 'app/store';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import SetDirtyForm from 'forms/SetDirtyForm';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';
import { Role } from '_helpers';
import { FormTextField } from 'components/TextField';
const { createUser, updateUser, getAccountById, fetchRoles } = accountsThunk;
const { selectActiveUser, selectAllRoles, getRolesStatus } = accountsSlice;

const AccountForm = ({ formAction, accountId }) => {
    const dispatch = useDispatch();
    const activeUser = useSelector(selectActiveUser);
    const { enqueueSnackbar } = useSnackbar();

    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);

    const panelClasses = panelStyles(theme);
    const userPanelClasses = userPanelStyles();
    const [loaded, setLoaded] = useState(
        formAction.id === FormActions.Create.id ? true : false
    );
    const INIT_VALUES = {
        first_name: '',
        last_name: '',
        email: '',
        groups: [],
        role: '',
        password: '',
        confirm_password: '',
        image: {},
        showPassword: false,
        previewImage: '',
    };
    const [initValues, setInitValues] = useState({ ...INIT_VALUES });

    useEffect(() => {
        if (formAction.id === FormActions.Edit.id) {
            const fetchData = async () => {
                var objectData = await dispatch(getAccountById(accountId));
                var fetchedObject = objectData.payload;
                setInitValues({
                    first_name: fetchedObject.first_name,
                    last_name: fetchedObject.last_name,
                    email: fetchedObject.email,
                    groups: fetchedObject.groups.map((x) => x.group.group_id),
                    role: fetchedObject.role.roleId,
                    image: {},
                    previewImage: fetchedObject.image_url,
                });
                setLoaded(true);
            };
            fetchData();
        } else {
            setInitValues({ ...INIT_VALUES });
        }
    }, [accountId]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (
        values,
        setSubmitting,
        resetForm,
        setErrors
    ) => {
        dispatch(activateLoading({ showProgress: true }));
        var resultAction = null;
        try {
            switch (formAction.id) {
                case FormActions.Edit.id:
                    resultAction = await dispatch(
                        updateUser({
                            user_id: accountId,
                            ...values,
                            dispatch,
                        })
                    );
                    if (!resultAction.error) {
                        dispatch(closePanel({ formKey: 'accountForm' }));
                    }
                    break;
                case FormActions.Create.id:
                    resultAction = await dispatch(
                        createUser({
                            ...values,
                            dispatch,
                        })
                    );
                    break;
                default:
                    break;
            }
            if (!resultAction.error) {
                dispatch(
                    closePanel({
                        formKey: 'accountForm',
                    })
                );
                resetForm();
                dispatch(resetGroupState());
                const { message, variant } = snackbarHandler(
                    resultAction.meta.requestStatus,
                    formAction.label
                );
                enqueueSnackbar(message, {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: variant,
                });
            } else {
                if (resultAction?.payload?.error?.errors) {
                    setErrors({ ...resultAction?.payload?.error?.errors });
                } else {
                    throw new Error(resultAction?.payload?.error?.message);
                }
            }
        } catch (err) {
            enqueueSnackbar(
                err?.message !== undefined ? err.message : 'Create Failed',
                {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'error',
                }
            );
        }

        dispatch(deactivateLoading());
        setSubmitting(false);
    };

    const handleShow = (setValue, value) => {
        setValue('showPassword', !value);
    };

    const onFileUpload = (setValue, files) => {
        if (files[0]) {
            var reader = new FileReader();
            reader.onload = (e) => {
                setValue('previewImage', e.target.result);
            };
            reader.readAsDataURL(files[0]);
        } else {
            setValue('previewImage', '');
        }
    };

    const editValidationSchema = Yup.object({
        first_name: Yup.string().max(50, 'Must be 50 characters or less'),
        last_name: Yup.string().max(50, 'Must be 50 characters or less'),
        email: Yup.string().email(),
        ...(activeUser.user_id !== accountId ||
            (activeUser.role === Role.IPSAdmin && { groups: Yup.array() })),
        ...(activeUser.user_id !== accountId && {
            role: Yup.number().required('Required'),
        }),
        password: Yup.string()
            .min(
                8,
                'Passwords must be at least 8 characters long and contain a capitalized letter and a number'
            )
            .matches(
                /[0-9]+/,
                'Passwords must be at least 8 characters long and contain a capitalized letter and a number'
            )
            .matches(
                /[A-Z]+/,
                'Passwords must be at least 8 characters long and contain a capitalized letter and a number'
            ),
        confirm_password: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Passwords do not match'
        ),
        image: Yup.mixed(),
    });

    const createValidationSchema = Yup.object({
        first_name: Yup.string()
            .max(50, 'Must be 50 characters or less')
            .required('Required'),
        last_name: Yup.string()
            .max(50, 'Must be 50 characters or less')
            .required('Required'),
        email: Yup.string().email().required('Required'),
        ...(activeUser.user_id !== accountId ||
            (activeUser.role === Role.IPSAdmin && { groups: Yup.array() })),
        ...(activeUser.user_id !== accountId && {
            role: Yup.string().required('Required'),
        }),
        password: Yup.string()
            .min(
                8,
                'Passwords must be at least 8 characters long and contain a capitalized letter and a number'
            )
            .matches(
                /[0-9]+/,
                'Passwords must be at least 8 characters long and contain a capitalized letter and a number'
            )
            .matches(
                /[A-Z]+/,
                'Passwords must be at least 8 characters long and contain a capitalized letter and a number'
            )
            .required('Required'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords do not match')
            .required('Required'),
        image: Yup.mixed(),
    });

    return !loaded ? (
        <LoadingLogo styleClassName={classes.loadingSvg} />
    ) : (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            validationSchema={
                formAction.id === FormActions.Create.id
                    ? createValidationSchema
                    : editValidationSchema
            }
            onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
                handleSubmit(values, setSubmitting, resetForm, setErrors);
            }}
        >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form className={classes.form}>
                    <div className={classes.formHeader}>User Details</div>
                    <Field className={classes.input} name="image" type="file">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <div
                                className={panelClasses.dropzoneContainer}
                                style={{ display: 'flex' }}
                            >
                                <div className={panelClasses.previewContainer}>
                                    <div style={{ padding: '5px 20px' }}>
                                        <Avatar
                                            className={
                                                userPanelClasses.previewAvatar
                                            }
                                            src={values.previewImage}
                                        ></Avatar>
                                    </div>
                                    <div style={{ padding: '0px 5px' }}>
                                        {value &&
                                            Object.keys(value).length > 0 && (
                                                <Chip
                                                    className={
                                                        panelClasses.previewUploadFileName
                                                    }
                                                    color="secondary"
                                                    size="small"
                                                    deleteIcon={
                                                        <MdClose className="react-icon" />
                                                    }
                                                    onDelete={() => {
                                                        setFieldValue(
                                                            'image',
                                                            {}
                                                        );
                                                        setFieldValue(
                                                            'previewImage',
                                                            ''
                                                        );
                                                    }}
                                                    label={value?.name}
                                                />
                                            )}
                                    </div>
                                </div>
                                <div style={{ width: '80%' }}>
                                    <Dropzone
                                        className={panelClasses.dropzoneArea}
                                        maxSize={LinkTypes.Image.fileSizeLimit}
                                        accept={LinkTypes.Image.contentTypes.map(
                                            (at) => at.extension
                                        )}
                                        maxFiles={1}
                                        onDropAccepted={(file) => {
                                            setFieldValue('image', file[0]);
                                            onFileUpload(setFieldValue, file);
                                        }}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} style={{}}>
                                                <input {...getInputProps()} />
                                                <div
                                                    className={
                                                        panelClasses.dropzoneText
                                                    }
                                                >
                                                    Upload Profile Picture
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>
                            </div>
                        )}
                    </Field>
                    <FormTextField
                        label="First Name"
                        name="first_name"
                        placeholder="First Name"
                    />
                    <FormTextField
                        label="Last Name"
                        name="last_name"
                        placeholder="Last Name"
                    />
                    <FormTextField
                        label="Email"
                        name="email"
                        placeholder="Email"
                    />
                    {(activeUser.user_id !== accountId ||
                        activeUser.role === Role.IPSAdmin) && (
                        <>
                            <Field className={classes.input} name="groups">
                                {({
                                    field: { value },
                                    form: { setFieldValue },
                                }) => (
                                    <SelectionAutocomplete
                                        title="Groups"
                                        keyProperty="group_id"
                                        nameProperty="display_name"
                                        entityIds={value}
                                        setEntityIds={setFieldValue}
                                        entitySelector={selectAllGroups}
                                        entityStatusSelector={getGroupStatus}
                                        fetchEntityPage={fetchGroupsPage}
                                        formField={'groups'}
                                    />
                                )}
                            </Field>
                            <Field className={classes.input} name="role">
                                {({
                                    field: { value },
                                    form: { setFieldValue },
                                }) => (
                                    <SelectionAutocomplete
                                        title="Roles"
                                        keyProperty="role_id"
                                        nameProperty="display_name"
                                        entityIds={value}
                                        setEntityIds={setFieldValue}
                                        entitySelector={selectAllRoles}
                                        entityStatusSelector={getRolesStatus}
                                        fetchEntityPage={fetchRoles}
                                        formField={'role'}
                                        multiSelection={false}
                                        errorMessage={errors['role']}
                                        touched={touched['role']}
                                        enableCache
                                        refreshCacheType="allAssignableRoles"
                                    />
                                )}
                            </Field>
                            <div className={classes.formErrorDiv}>
                                <ErrorMessage name="role" component="div" />
                            </div>
                        </>
                    )}

                    <div
                        className={classes.formHeader}
                        style={{ paddingTop: '10px' }}
                    >
                        User Credentials
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <TextField
                            margin="dense"
                            variant="outlined"
                            className={classes.materialInput}
                            value={values?.password || ''}
                            label="New Password"
                            name="password"
                            placeholder="New Password"
                            type={values.showPassword ? 'text' : 'password'}
                            style={{ width: '90%' }}
                            onChange={(e) => {
                                setFieldValue('password', e.target.value);
                            }}
                            error={!!errors?.password}
                            helperText={errors?.password}
                        />
                        <div
                            style={{
                                padding: '10px',
                                color: '#34485E',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <BiShow
                                size={30}
                                onClick={() =>
                                    handleShow(
                                        setFieldValue,
                                        values.showPassword
                                    )
                                }
                            />
                        </div>
                    </div>
                    <TextField
                        margin="dense"
                        variant="outlined"
                        className={classes.materialInput}
                        value={values?.confirm_password || ''}
                        label="Confirm Password"
                        name="confirm_password"
                        placeholder="Confirm Password"
                        type={values.showPassword ? 'text' : 'password'}
                        style={{ width: '90%' }}
                        onChange={(e) => {
                            setFieldValue('confirm_password', e.target.value);
                        }}
                        error={!!errors?.confirm_password}
                        helperText={errors?.confirm_password}
                    />
                    <SetDirtyForm />
                    <button
                        className={classes.submit}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {formAction.buttonLabel}
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export { AccountForm };
