import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    paper: {
        boxShadow: 'unset',
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh)',
        padding: '25px 50px',
        overflow: 'auto',
    },
    assetHeader: {
        display: 'flex',
        alignItems: 'center',
        color: '#297FB9',
        fontSize: '22px',
        fontWeight: 'bold',
    },
    assetS3: {
        display: 'flex',
        color: '#7E8C8D',
    },
    backArrow: {
        display: 'flex',
        marginRight: '10px',
        color: '#297FB9',
    },
    '@keyframes rotate': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' },
    },
    loadingSvg: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        marginLeft: '-50px',
        marginTop: '-50px',
        height: '100px',
        width: '100px',
    },
    rotateSvg: {
        animationName: '$rotate',
        animationDuration: '1.5s',
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: 'infinite',
    },
}));

const userPanelStyles = makeStyles(() => ({
    panelContainer: {
        '& .MuiPaper-root': {
            overflowY: 'scroll',
        },
    },
    createContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
        backgroundColor: '#ECF0F1',
        color: '#2D3D50',
    },
    createHeader: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        fontSize: '24px',
        gap: '5px',
    },
    createHeaderText: {
        fontWeight: 800,
        paddingLeft: '10px',
    },
    drawer: {
        maxWidth: '500px',
    },
    panel: {
        padding: '15px',
    },
    panelHeader: {
        paddingBottom: '15px',
        fontWeight: 800,
        color: '#2D3D50',
    },
    panelSectionHeader: {
        paddingBottom: '15px',
        fontWeight: 800,
        color: '#2D3D50',
        paddingTop: '15px',
    },
    panelInputBottom: {
        marginTop: '15px',
        width: '100%',
    },
    splitInput: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    splitInputRight: {
        width: '100%',
    },
    splitInputLeft: {
        width: '35%',
    },
    stackInputs: {
        display: 'flex',
        flexDirection: 'column',
    },
    stackInput: {
        marginTop: '15px',
    },
    previewAvatar: {
        width: '125px',
        height: '125px',
    },
    previewUploadFileName: {
        backgroundColor: '#18BC9B',
        color: 'white',
        width: '125px',
        marginTop: '10px',
    },
    avatarPreviewContainer: {
        display: 'flex',
    },
    avatarContainer: {
        margin: '15px',
    },
    avatarDropzoneContainer: {
        margin: '15px',
        flexGrow: 2,
    },
    avatarDropzone: {
        minHeight: '160px !important',
        '& svg': {
            width: '1em',
            height: '1em',
        },
    },
    dropzoneText: {
        fontSize: '1em !important',
        margin: '10px !important',
    },
}));

export { useStyles, userPanelStyles };
