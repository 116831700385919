import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    labelText: {
        '&.MuiInputLabel-shrink': {
            backgroundColor: '#ffffff',
        },
    },
});

export { useStyles };
