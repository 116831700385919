import React from 'react';
import { Flyer } from '../Flyer';
import { flyerGridStyles } from './styles.jsx';

const FlyerGrid = ({ numCol, imgs }) => {
    const classes = flyerGridStyles();
    var row = 1;

    return (
        <div
            className={classes.flyerGrid}
            style={{ gridTemplateColumns: `repeat(${numCol}, 1fr)` }}
        >
            {imgs.map((url, index) => {
                if (index % numCol === 0 && index > 0) {
                    row++;
                }
                return (
                    <Flyer
                        imgUrl={url}
                        row={row}
                        col={(index % numCol) + 1}
                        key={index}
                    />
                );
            })}
        </div>
    );
};

export { FlyerGrid };
