import React from 'react';
import { Box, IconButton } from '@mui/material';
import { MdDelete } from 'react-icons/md';
import { TableFieldChildElement } from './TableFieldChildElement';
import {
    getDuplicateFields,
    setDuplicateFields,
} from 'GeminiViewerComponent/components/Procedure/_features/checklistDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { makeCommonFormFieldStyles } from '../../styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

const TableDuplicateFieldArray = ({
    fieldData,
    setFieldData,
    data,
    idx,
    hasHeaders,
    colWidths,
    cols,
    allDuplicateFields,
    isMobileWidth,
    fieldValue,
    setFieldValue,
    errors,
    setErrors,
    isReadOnly,
    isSubmitAllow,
    handleBlur,
    handleClick,
    contentService,
}) => {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const classes = makeCommonFormFieldStyles(theme);
    const getDuplicatedField = useSelector(getDuplicateFields);
    let duplicateRows = [];
    let duplicateStartIndex = 0;
    let duplicateSpanTotal = 0;
    fieldData?.forEach((field, index) => {
        duplicateSpanTotal += field?.colspan ? Number(field?.colspan) : 1;
        if (duplicateSpanTotal >= cols || index === fieldData?.length - 1) {
            // Pass true in rows to display add button dynamically.
            if (fieldData[index]?.duplicateCount) {
                if (!fieldData?.[index + cols]?.duplicateCount) {
                    duplicateSpanTotal = 0;
                    duplicateRows = [
                        ...duplicateRows,
                        [duplicateStartIndex, index + 1, true],
                    ];
                    duplicateStartIndex = index + 1;
                } else {
                    duplicateSpanTotal = 0;
                    duplicateRows = [
                        ...duplicateRows,
                        [duplicateStartIndex, index + 1],
                    ];
                    duplicateStartIndex = index + 1;
                }
            } else {
                if (!fieldData?.[index + cols]?.duplicateCount) {
                    duplicateSpanTotal = 0;
                    duplicateRows = [
                        ...duplicateRows,
                        [duplicateStartIndex, index + 1, true],
                    ];
                    duplicateStartIndex = index + 1;
                } else {
                    duplicateSpanTotal = 0;
                    duplicateRows = [
                        ...duplicateRows,
                        [duplicateStartIndex, index + 1],
                    ];
                    duplicateStartIndex = index + 1;
                }
            }
        }
    });

    const removeRepeatingRow = (row, index) => {
        let allFields = [...fieldData];
        row.forEach((rowData) => {
            delete fieldValue[rowData.variable];
        });
        allFields.splice(index, row.length);
        let newRowStart = false;
        const allField = allFields.map((field, idx) => {
            let fieldData = { ...field };
            if (idx >= index) {
                if (fieldData?.duplicateCount && !newRowStart) {
                    let slicedVariable = fieldData.variable.slice(
                        0,
                        fieldData.variable.length - 1
                    );
                    let slicedCount = fieldData.variable.slice(
                        fieldData.variable.length - 1,
                        fieldData.variable.length
                    );
                    fieldData.variable = `${slicedVariable}${slicedCount - 1}`;
                    fieldData.duplicateCount = slicedCount - 1;
                    return fieldData;
                } else {
                    newRowStart = true;
                    return fieldData;
                }
            } else return fieldData;
        });
        setFieldData({ ...allDuplicateFields, [idx]: [...allField] });
        dispatch(
            setDuplicateFields({
                ...getDuplicatedField,
                [data?.id]: { ...allDuplicateFields, [idx]: [...allField] },
            })
        );
        handleBlur(fieldValue);
    };

    return duplicateRows.map((rowIndexes, index) => {
        const cells = fieldData.slice(rowIndexes[0], rowIndexes[1]);
        return (
            <tr
                key={`duplicate_row_${index}`}
                style={{
                    display: `${isMobileWidth ? 'flex' : 'table-row'}`,
                    flexDirection: `${isMobileWidth ? 'column' : 'row'}`,
                    borderRadius: '5px',
                    boxShadow: `${
                        isMobileWidth && isSubmitAllow
                            ? '0 0 5px 0 rgb(134 128 128 / 64%)'
                            : 'none'
                    }`,
                    padding: `${isMobileWidth && isSubmitAllow ? '1rem' : '0'}`,
                    margin: `${
                        isMobileWidth && isSubmitAllow ? '1rem 0' : '0'
                    }`,
                    position: 'relative',
                    borderTop: `${
                        rowIndexes[3] ? '1px solid #c4c4c4' : 'none'
                    }`,
                }}
            >
                {cells.map((cellData, cellIdx) => {
                    const tdWidth =
                        !hasHeaders && index === 0
                            ? {
                                  width: `${colWidths[cellIdx % cols]}%`,
                              }
                            : {};
                    return (
                        <td
                            style={{
                                ...tdWidth,
                                verticalAlign: `${
                                    cellData.type === 'checkbox'
                                        ? cellData.cellAlignment || 'middle'
                                        : 'middle'
                                }`,
                            }}
                            colSpan={
                                cellData?.colspan
                                    ? Number(cellData?.colspan)
                                    : 1
                            }
                            key={`table_${data.id}_row_${index}_col_${cellIdx}`}
                        >
                            {isMobileWidth === true && (
                                <>
                                    <Box pl={'5px'}>
                                        {
                                            data?.columns?.find(
                                                (d, idx) =>
                                                    idx ===
                                                    cellIdx %
                                                        (data?.columns
                                                            ?.length ?? 1)
                                            )?.label
                                        }
                                    </Box>
                                </>
                            )}
                            <TableFieldChildElement
                                fieldData={cellData}
                                fieldValue={fieldValue}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                setErrors={setErrors}
                                groupKeyPrefix={'Table'}
                                isReadOnly={isReadOnly}
                                isSubmitAllow={isSubmitAllow}
                                handleBlur={handleBlur}
                                handleClick={handleClick}
                                isMobileWidth={isMobileWidth}
                                contentService={contentService}
                            />
                        </td>
                    );
                })}
                {!isReadOnly && (
                    <IconButton
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            width: 'fit-content',
                            marginLeft: 'auto',
                            padding: '8px',
                            top: `${isMobileWidth ? '5px' : '50%'}`,
                            right: `${isMobileWidth ? '5px' : '-7px'}`,
                            transform: 'translate(50%, -50%)',
                        }}
                        className={classes.deleteRepeateRowButton}
                        size="large"
                        onClick={() => {
                            removeRepeatingRow(cells, rowIndexes[0]);
                        }}
                    >
                        <MdDelete
                            className="react-icon"
                            style={{ fontSize: '14px' }}
                        />
                    </IconButton>
                )}
            </tr>
        );
    });
};

export { TableDuplicateFieldArray };
