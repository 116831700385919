import React from 'react';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';

function SnackbarDismiss({ snackItem }) {
    const { closeSnackbar } = useSnackbar();

    return (
        <Button onClick={() => closeSnackbar(snackItem)}>
            <div style={{ color: '#3598DB', textTransform: 'uppercase' }}>
                Dismiss
            </div>
        </Button>
    );
}

export { SnackbarDismiss };
