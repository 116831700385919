import React, { createRef, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';

import { resizableTableStyles } from './styles';

const fetchSize = 20;

export const ResizableTable = ({
    initialRows,
    allRows,
    totalRowCount,
    loadMoreRows,
    columns,
    accordianTable = false,
    selected = [],
    onRowClick = () => {},
    setCardWrapperHeight = () => {},
    ...tableProps
}) => {
    //#region Constants
    const totalDBRowCount = totalRowCount ?? 0;
    const totalFetched = accordianTable ? initialRows.length : allRows.length;
    const classes = resizableTableStyles();
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    const [sorting, setSorting] = useState([]);
    const [myFetching, setMyFetching] = useState(false);

    //#endregion State

    //#region Selectors
    //#endregion Selectors

    //#region Refs
    const tableContainerRef = createRef();
    //#endregion Refs

    //#region Effects
    useEffect(() => {
        if (accordianTable) {
            const rowCount = initialRows.length;
            if (rowCount <= 11) {
                let tableHeight = 60 * rowCount + 76;
                if (selected?.length > 0) {
                    tableHeight += 64;
                }
                setCardWrapperHeight(tableHeight);
            } else {
                setCardWrapperHeight(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialRows.length, selected?.length]);
    //#endregion Effects

    //#region Methods
    const { fetchNextPage, isFetching, isLoading } = useInfiniteQuery({
        queryKey: ['people', sorting],
        queryFn: async () => {
            if (accordianTable ? initialRows.length > 14 : allRows.length > 0) {
                const start = accordianTable
                    ? initialRows.length
                    : allRows.length;
                setMyFetching(true);
                const fetchedData = await loadMoreRows({
                    startIndex: start,
                    stopIndex: start + fetchSize,
                    sorting,
                });
                setMyFetching(false);
                return fetchedData;
            }
        },
        initialPageParam: 0,
        getNextPageParam: (_lastGroup, groups) => groups.length,
        refetchOnWindowFocus: false,
    });

    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } =
                    containerRefElement;

                if (
                    scrollHeight - scrollTop - clientHeight < 200 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ) {
                    fetchNextPage({
                        pageParam: accordianTable
                            ? initialRows.length
                            : allRows.length,
                    });
                }
            }
        },
        [
            totalFetched,
            totalDBRowCount,
            fetchNextPage,
            accordianTable,
            isFetching,
            initialRows.length,
            allRows.length,
        ]
    );

    const handleSortingChange = (updater) => {
        setSorting(updater);
        if (table.getRowModel().rows.length) {
            rowVirtualizer.scrollToIndex?.(0);
        }
    };

    const dragStartHandler = (e, asset) => {
        e.dataTransfer.setData('cardInfo', JSON.stringify(asset));
        e.dataTransfer.setData('assetIds', JSON.stringify(selected));
    };

    // check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
    React.useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached, tableContainerRef]);

    //#endregion Methods

    //#region Render time calcs
    const table = useReactTable({
        data: accordianTable ? initialRows : allRows,
        columns,
        state: {
            sorting,
        },
        enableColumnResizing: true,
        getCoreRowModel: getCoreRowModel(),
        columnResizeMode: 'onChange',
        manualSorting: true,
        defaultColumn: {
            size: 180,
            minSize: 80,
            maxSize: 500,
        },
    });

    const { rows } = table.getRowModel();

    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        estimateSize: () => 60,
        getScrollElement: () => tableContainerRef.current,
        measureElement:
            typeof window !== 'undefined' &&
            navigator.userAgent.indexOf('Firefox') === -1
                ? (element) => element?.getBoundingClientRect().height
                : undefined,
        overscan: 5,
    });

    table.setOptions((prev) => ({
        ...prev,
        onSortingChange: handleSortingChange,
    }));

    //#endregion Render time calcs

    if (isLoading) {
        return <div>Loading...</div>;
    }
    //#region Render
    return (
        <div
            className={classes.container}
            onScroll={(e) => fetchMoreOnBottomReached(e.target)}
            ref={tableContainerRef}
            style={{
                height: `calc(100% - ${selected.length > 0 ? '64px' : '0px'})`,
            }}
        >
            {myFetching && <div>Loading more...</div>}
            <table className={classes.resizableTable}>
                <thead
                    style={{ position: 'sticky', top: 0, zIndex: 2 }}
                    className={classes.resizableTableThead}
                >
                    {table.getHeaderGroups().map((headerGroup, idx) => {
                        return (
                            <tr
                                key={idx}
                                className={clsx(
                                    tableProps?.rowClassName,
                                    classes.resizableTableTr
                                )}
                            >
                                {headerGroup.headers.map((header, index) => (
                                    <th
                                        key={index}
                                        {...(header.column.getCanSort()
                                            ? {
                                                  onClick:
                                                      header.column.getToggleSortingHandler(),
                                              }
                                            : {})}
                                        colSpan={header.colSpan}
                                        style={{
                                            position: 'relative',
                                            height: tableProps?.headerHeight
                                                ? tableProps.headerHeight
                                                : 40,
                                            width: header.getSize(),
                                            minWidth: header.getSize(),
                                        }}
                                        className={classes.resizableTableTh}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef
                                                      .header,
                                                  header.getContext()
                                              )}
                                        {header.column.getCanResize() && (
                                            <div
                                                onMouseDown={header.getResizeHandler()}
                                                onTouchStart={header.getResizeHandler()}
                                                className={`resizer ${
                                                    header.column.getIsResizing()
                                                        ? 'isResizing'
                                                        : ''
                                                }`}
                                            ></div>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        );
                    })}
                </thead>

                <tbody style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                    {table.getRowModel().rows.map((row, idx) => {
                        return (
                            <tr
                                key={idx}
                                draggable="true"
                                className={clsx(
                                    tableProps.rowClassName,
                                    tableProps.getRowClassName?.(row.original),
                                    classes.resizableTableTr,
                                    selected && selected?.length > 0
                                        ? selected.includes(
                                              row.original.asset_id
                                          )
                                            ? 'virtualTableRows'
                                            : ''
                                        : 'virtualTableRows'
                                )}
                                onClick={() => {
                                    onRowClick({ rowData: row.original });
                                }}
                                style={{
                                    userSelect: 'all',
                                    height: '60px',
                                }}
                                onDragStart={(e) =>
                                    dragStartHandler(e, row.original)
                                }
                            >
                                {row.getVisibleCells().map((cell, index) => (
                                    <td
                                        key={index}
                                        style={{
                                            width: cell.column.getSize(),
                                            minWidth: cell.column.getSize(),
                                        }}
                                        className={classes.resizableTableTd}
                                    >
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="h-4" />
        </div>
    );
    //#endregion Render
};
