const validateRequiredField = (errors, key, e, idx) => {
    if (!e) {
        if (idx === 0) {
            errors = {
                ...errors,
                [key]: 'Required',
            };
        } else {
            errors = {
                ...errors,
                [`${key}-${idx}`]: 'Required',
            };
        }
    }
    return errors;
};

const validateInspectionPoint = (errors, key, e, idx) => {
    if (!e?.action) {
        if (idx === 0) {
            errors = {
                ...errors,
                [key]: {
                    ...errors[key],
                    action: 'Required',
                },
            };
        } else {
            errors = {
                ...errors,
                [`${key}-${idx}`]: {
                    ...errors[`${key}-${idx}`],
                    action: 'Required',
                },
            };
        }
    }
    if (!e?.reason) {
        if (idx === 0) {
            errors = {
                ...errors,
                [key]: {
                    ...errors[key],
                    reason: 'Required',
                },
            };
        } else {
            errors = {
                ...errors,
                [`${key}-${idx}`]: {
                    ...errors[`${key}-${idx}`],
                    reason: 'Required',
                },
            };
        }
    }
    return errors;
};

export { validateRequiredField, validateInspectionPoint };
