import {
    Grid,
    MenuItem,
    Select,
    Button,
    InputLabel,
    FormControl,
} from '@mui/material';
import { Field } from 'formik';
import { makeFormStyles } from 'forms/styles';
import { Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { MdDragHandle } from 'react-icons/md';

import { SelectionAutocomplete } from 'components/_Autocomplete/SelectionAutocomplete';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    getItemsPicklist,
    selectItemsPicklist,
    selectItemsPicklistLoadingStatus,
} from '_features/picklists/pickListsSlice';
import { FormTextField } from 'components/TextField';

const HotspotAnswerField = ({
    option,
    idx,
    nodes,
    node,
    values,
    setFieldValue,
    handleRemove,
    ...otherProps
}) => {
    const theme = useSelector(selectActiveTheme);
    nodes = nodes.filter((nd) => nd.id !== node.id);
    const commonFormStyles = makeFormStyles(theme);
    const zoneItems = useSelector(selectItemsPicklist);

    return (
        <Draggable
            key={'hotspot_opts_draggable_' + idx}
            draggableId={String('hotspot_opts_draggable_' + idx)}
            index={idx}
        >
            {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                    <Grid
                        container
                        key={'hotspot_opts_' + idx}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            marginBottom: '6px',
                        }}
                        {...otherProps}
                    >
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            justifyContent={'center'}
                            item
                            xs={12}
                            sm={12}
                            md={1}
                            lg={1}
                            {...provided.dragHandleProps}
                        >
                            <MdDragHandle className="react-icon" />
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                        >
                            <FormControl fullWidth>
                                <Field name="item_id">
                                    {({ form: { setFieldValue } }) => (
                                        <SelectionAutocomplete
                                            title="Item"
                                            keyProperty="item_id"
                                            nameProperty="display_name"
                                            entityIds={
                                                option?.item_id
                                                    ? option?.item_id
                                                    : null
                                            }
                                            setEntityIds={setFieldValue}
                                            onCustomChange={(evt) => {
                                                let currHotspotOptions = [
                                                    ...values.hotspot_options,
                                                ];
                                                currHotspotOptions[idx] = {
                                                    ...values.hotspot_options[
                                                        idx
                                                    ],
                                                    item_id: evt?.item_id,
                                                    item: zoneItems.find(
                                                        (itm) =>
                                                            itm?.item_id ===
                                                            evt?.item_id
                                                    ),
                                                };
                                                setFieldValue(
                                                    'hotspot_options',
                                                    currHotspotOptions
                                                );
                                            }}
                                            entitySelector={selectItemsPicklist}
                                            fetchEntityPage={getItemsPicklist}
                                            formField={'item_id'}
                                            multiSelection={false}
                                            entityStatusSelector={
                                                selectItemsPicklistLoadingStatus
                                            }
                                            fetchParams={{
                                                zoneId: option?.zone_id,
                                            }}
                                        />
                                    )}
                                </Field>
                            </FormControl>
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={3}
                            lg={3}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="target-node-id">
                                    Target Node
                                </InputLabel>
                                <Select
                                    className={commonFormStyles.materialSelect}
                                    style={{
                                        margin: '0',
                                    }}
                                    labelId="target-node-id"
                                    margin="dense"
                                    id="target_node_id"
                                    label="Target Node"
                                    placeholder="Target Node"
                                    name={`hotspot_options[${idx}].target_node_id`}
                                    value={option?.action?.id}
                                    onChange={(e) => {
                                        let currHotspotOptions = [
                                            ...values.hotspot_options,
                                        ];
                                        currHotspotOptions[idx] = {
                                            ...values.hotspot_options[idx],
                                            action: {
                                                ...values.hotspot_options[idx]
                                                    .action,
                                                id: e.target.value,
                                            },
                                        };
                                        setFieldValue(
                                            'hotspot_options',
                                            currHotspotOptions
                                        );
                                    }}
                                >
                                    <MenuItem key={'next'} value={'next'}>
                                        Next
                                    </MenuItem>
                                    <MenuItem key={'back'} value={'back'}>
                                        Back
                                    </MenuItem>
                                    {nodes?.length > 0 &&
                                        nodes.map((nd) => (
                                            <MenuItem
                                                key={nd?.id}
                                                value={nd?.id}
                                            >
                                                {nd?.title} - #{nd?.id}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={2}
                            lg={2}
                        >
                            <FormControl fullWidth>
                                <FormTextField
                                    label="Answer Value"
                                    placeholder="Answer Value"
                                    name={`hotspot_options[${idx}].answer_value`}
                                    onChange={(e) => {
                                        let currOptions = [
                                            ...values.hotspot_options,
                                        ];
                                        currOptions[idx] = {
                                            ...values.hotspot_options[idx],
                                            answer_value: e.target.value,
                                        };
                                        setFieldValue(
                                            `hotspot_options`,
                                            currOptions
                                        );
                                    }}
                                    {...otherProps}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={1}
                            lg={1}
                        >
                            <FormControl fullWidth>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        handleRemove(idx);
                                    }}
                                >
                                    Remove
                                </Button>{' '}
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            )}
        </Draggable>
    );
};

export default HotspotAnswerField;
