import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    Button,
    Box,
    Autocomplete,
    Checkbox,
    TextField,
    Grid,
    useTheme,
    Tooltip,
    IconButton,
} from '@mui/material';
import moment from 'moment';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import useResizeObserver from 'use-resize-observer';
import { useSnackbar } from 'notistack';
import {
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdCheckCircle,
    MdList,
    MdMenu,
} from 'react-icons/md';
import ReactDOMServer from 'react-dom/server';

import { useStyles } from './styles';
import {
    getActiveTree,
    getCurrentNode,
    gotoNode,
    nextNode,
    previousNode,
    resetCurrentTree,
    setActiveTreeId,
    setProcedureAnswer,
    setParentTreeId,
    setIsBranchNode,
    findNextNode,
} from 'GeminiViewerComponent/components/Procedure/_features/procedureSlice';
import {
    makeCommonChecklistStyles,
    customTinyMce,
    overrideResetStyles,
} from '../../styles';
import {
    displayProcedureContentVersion,
    getProcedureContentVersion,
    loadAssociateAsset,
    loadChecklist,
} from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    getECTLToken,
    getECTLUrl,
    saveCompletion,
    saveStatement,
} from 'GeminiViewerComponent/_features/xAPI/xapiSlice';
import { LrsVerbs } from 'GeminiViewerComponent/_features/xAPI/xApiData';
import { getNodesHistoricalAnswers } from 'GeminiViewerComponent/_helpers/node_helpers';
import {
    getActiveSessionId,
    getChecklistData,
    setChecklistData,
    getDuplicateFields,
} from '../../_features/checklistDataSlice';
import ProcedureFormNode from '../ProcedureFormNode';
import { getProcedureSession } from 'GeminiViewerComponent/_features/procedureSession/procedureSessionSlice';
import { updateStoreSessionData } from 'GeminiViewerComponent/_helpers/updateStoreSessionData';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import { ProcedureNodeTypes } from '../../ProcedureNodeTypes';
import { accountsSlice } from 'app/store';
import {
    validateInspectionPoint,
    validateRequiredField,
} from './_helpers/duplicateFieldValidation';
import {
    getDependencyContents,
    selectAsset,
} from 'GeminiViewerComponent/_features/asset/assetSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    formatTime,
    timeToSeconds,
} from 'GeminiViewerComponent/_helpers/helper';
import { prefixUrl, CaptionLanguages } from 'GeminiViewerComponent/_helpers';
import useContentLinkInterceptor from 'GeminiViewerComponent/hooks/useContentLinkInterceptor';
import { persistor } from 'app/store';

const { selectActiveUser } = accountsSlice;

const ProcedureQuestions = ({
    displayNode,
    displayNodesFields,
    contentService,
    isSubmitAllow,
    isReadOnly = false,
    discardPreviousSession,
    idx = null,
    hasNavigationPane,
    showNavigationPane,
    setShowNavigationPane,
}) => {
    const theme = useSelector(selectActiveTheme);
    const procedureRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles(theme);
    const commonStyles = makeCommonChecklistStyles(theme);
    const tinyMceCustomClass = customTinyMce(theme);
    const overrideResetClasses = overrideResetStyles();
    const dispatch = useDispatch();
    const activeTree = useSelector(getActiveTree);
    const procedureContentVersion = useSelector(getProcedureContentVersion);
    const activeUser = useSelector(selectActiveUser);
    const currentNode = useSelector(getCurrentNode);
    const userData = useSelector(getChecklistData);
    const getSessionData = useSelector(getProcedureSession);
    const config = useSelector(getConfig);
    const activeSessionId = useSelector(getActiveSessionId);
    const dependencyContents = useSelector(getDependencyContents);
    const getAsset = useSelector(selectAsset);
    const ectlURL = useSelector(getECTLUrl);
    const ectlToken = useSelector(getECTLToken);
    const [activeChapter, setActiveChapter] = useState([]);
    const [isChapterVideo, setIsChapterVideo] = useState({
        chapterPlayed: false,
        chapterCapture: false,
    });
    const setActiveChapterRef = useRef(activeChapter);
    const isChapterVideoRef = useRef(isChapterVideo);
    const procedureFormId = 'procedure-form-' + currentNode.id;
    useEffect(() => {
        document.getElementById(procedureFormId).scrollIntoView();
    }, [currentNode]);

    const [clickedBtnValue, setClickedBtnValue] = useState(false);
    const [contentVideoProcessed, setContentVideoProcessed] = useState(false);
    const [selectedAnswers, setSelectedAnswers] = useState(
        currentNode?.answer
            ? currentNode?.answer_group === 'select'
                ? Array.isArray(currentNode?.answer?.options_required) &&
                  currentNode?.answer?.options_required?.length > 0
                    ? [...currentNode.answer.options_required]
                    : currentNode?.multiple_answers_allow === true
                    ? [currentNode?.answer]
                    : currentNode?.answer
                : currentNode?.answer_group === 'image'
                ? Array.isArray(currentNode?.answer?.options_required) &&
                  currentNode?.answer?.options_required?.length > 0
                    ? [...currentNode.answer.options_required]
                    : [currentNode?.answer]
                : currentNode?.answer
            : (currentNode?.answer_group === 'select' &&
                  currentNode?.multiple_answers_allow === true) ||
              currentNode?.answer_group === 'image'
            ? []
            : null
    );

    const duplicateFields = useSelector(getDuplicateFields);
    const fieldValidationSchema = useMemo(() => {
        const fieldValidations = {};

        function setValidation(field) {
            const variable = field.variable;
            if (
                field.type === 'text' ||
                field.type === 'select' ||
                field.type === 'multiline' ||
                field.type === 'radiogroup' ||
                field.type === 'initial'
            ) {
                fieldValidations[variable] = Yup.string();
                if (field.required) {
                    fieldValidations[variable] =
                        Yup.string().required('Required');
                }
            } else if (field.type === 'checkbox') {
                fieldValidations[variable] = Yup.boolean()
                    .transform((currentValue, _originalValue) => {
                        if (currentValue === null) {
                            return false;
                        }
                        return currentValue;
                    })
                    .nullable();
                if (field.required) {
                    fieldValidations[variable] = Yup.boolean()
                        .nullable()
                        .required('Required');
                }
            } else if (field.type === 'datetime') {
                fieldValidations[variable] = Yup.string().nullable();
                if (field.required) {
                    fieldValidations[variable] = Yup.string()
                        .nullable()
                        .required('Required');
                }
            } else if (field.type === 'inspectionpoint') {
                if (field.required) {
                    fieldValidations[variable] = Yup.object({
                        field_id: Yup.string().required('Required'),
                        action: Yup.string().required('Required'),
                        reason: Yup.string().required('Required'),
                    }).test(
                        'format-errors',
                        'Invalid format',
                        function (value) {
                            const errors = {};
                            if (value) {
                                value.forEach((field, index) => {
                                    if (!field.action) {
                                        errors[`[${index}].action`] =
                                            'Required';
                                    }
                                    if (!field.reason) {
                                        errors[`[${index}].reason`] =
                                            'Required';
                                    }
                                });
                            }
                            return Object.keys(errors).length === 0
                                ? true
                                : errors;
                        }
                    );
                }
            }
        }

        for (const field of displayNodesFields) {
            if (field.type === 'group' || field.type === 'table') {
                for (const subfield of field.fields ?? []) {
                    setValidation(subfield);
                }
            } else {
                setValidation(field);
            }
        }

        for (const fieldId in duplicateFields) {
            for (const row in duplicateFields[fieldId]) {
                for (const field of duplicateFields[fieldId][row]) {
                    setValidation(field);
                }
            }
        }

        return Yup.object(fieldValidations);
    }, [displayNodesFields, duplicateFields]);

    const initialFieldValues = useMemo(() => {
        const initialValues = {};

        function setInitialValue(field) {
            if (!field.variable) return;
            let defaultValue;
            switch (field.type) {
                case 'text':
                case 'select':
                case 'multiline':
                case 'radiogroup':
                case 'initial':
                    defaultValue = '';
                    break;
                case 'checkbox':
                    defaultValue = false;
                    break;
                case 'datetime':
                    if (field.autofill) {
                        defaultValue = moment(Date.now()).format(
                            field?.date_time_type === 'datetime'
                                ? 'MM/DD/YYYY h:mm A'
                                : field?.date_time_type === 'time'
                                ? 'h:mm A'
                                : 'MM/DD/YYYY'
                        );
                    } else {
                        defaultValue = null;
                    }
                    break;
                case 'inspectionpoint':
                    defaultValue = {
                        field_id: '',
                        action: '',
                        reason: '',
                    };
                    break;
                default:
                    defaultValue = null;
                    break;
            }

            const variable = field.variable;
            const userValue = userData?.[variable] ?? [undefined];
            for (let i = 0; i < userValue.length; i++) {
                const value =
                    userValue[i] === undefined ? defaultValue : userValue[i];
                if (i === 0) {
                    initialValues[variable] = value;
                } else {
                    initialValues[`${variable}-${i}`] = value;
                }
            }
        }

        for (const field of displayNodesFields) {
            if (!field) continue;
            if (field.type === 'group' || field.type === 'table') {
                for (const subfield of field.fields ?? []) {
                    if (!subfield) continue;
                    setInitialValue(subfield);
                }
            } else {
                setInitialValue(field);
            }
        }

        return initialValues;
    }, [displayNodesFields, discardPreviousSession]);

    useEffect(() => {
        setSelectedAnswers(
            currentNode?.answer
                ? currentNode?.answer_group === 'select'
                    ? Array.isArray(currentNode?.answer?.options_required) &&
                      currentNode?.answer?.options_required?.length > 0
                        ? [...currentNode.answer.options_required]
                        : currentNode?.multiple_answers_allow === true
                        ? [currentNode?.answer]
                        : currentNode?.answer
                    : currentNode?.answer_group === 'image'
                    ? Array.isArray(currentNode?.answer?.options_required) &&
                      currentNode?.answer?.options_required?.length > 0
                        ? [...currentNode.answer.options_required]
                        : [currentNode?.answer]
                    : currentNode?.answer
                : (currentNode?.answer_group === 'select' &&
                      currentNode?.multiple_answers_allow === true) ||
                  currentNode?.answer_group === 'image'
                ? []
                : null
        );
        setContentVideoProcessed(false);
    }, [currentNode]);

    useEffect(() => {
        setActiveChapterRef.current = activeChapter;
        isChapterVideoRef.current = isChapterVideo;
        for (let index = 0; index < activeChapter.length; index++) {
            const chapterButtons = document.querySelectorAll(
                `div[accessKey="${index}"]`
            );
            chapterButtons.forEach((button) => {
                if (
                    +button.getAttribute('data-item-chapter-id') ===
                    activeChapter[index]?.chapter_id
                ) {
                    button.classList.add('activeChapterButton');
                } else {
                    button.classList.remove('activeChapterButton');
                }
            });
            const tooltipContainers =
                document.querySelectorAll('.tooltip-container');
            tooltipContainers?.forEach((item) => {
                item.remove();
            });
        }
    }, [activeChapter, isChapterVideo]);

    useEffect(() => {
        processContentVideos();
    }, [displayNode]);

    useEffect(() => {
        return () => {
            const tooltipContainers =
                document.querySelectorAll('.tooltip-container');
            tooltipContainers?.forEach((item) => {
                item.remove();
            });
        };
    }, []);

    const { ref, width = 1 } = useResizeObserver();
    const { width: formWidth } = useResizeObserver({ ref: procedureRef });

    const handleBlur = (values) => {
        const result = {};
        for (const key of Object.keys(values)) {
            const dupeIndex = key.indexOf('-');
            if (dupeIndex > 0) {
                const originalKey = key.substring(0, dupeIndex);
                const index = parseInt(key.substring(dupeIndex + 1));
                if (!result[originalKey]) {
                    result[originalKey] = [];
                }
                result[originalKey][index] = values[key];
            } else {
                if (!result[key]) {
                    result[key] = [];
                }
                result[key][0] = values[key];
            }
        }
        dispatch(setChecklistData(result));
    };
    const handleReturn = () => {
        if (activeTree.parent_tree_id) {
            dispatch(resetCurrentTree());
            dispatch(setActiveTreeId(activeTree.parent_tree_id));
            dispatch(loadAssociateAsset(false));
            dispatch(displayProcedureContentVersion(null));
            if (activeTree.return_node_id) {
                dispatch(gotoNode(activeTree.return_node_id));
            }
        }
    };

    const handleBack = useCallback(
        async (e, validateForm) => {
            if (
                currentNode.back_action?.type === 'return' &&
                activeTree.parent_tree_id
            ) {
                dispatch(resetCurrentTree());
                dispatch(setActiveTreeId(activeTree.parent_tree_id));
                dispatch(loadAssociateAsset(false));
                dispatch(displayProcedureContentVersion(null));
            } else {
                if (activeTree?.history?.length > 0) {
                    const lastNode = [...activeTree.history].pop();
                    if (
                        (lastNode.type ===
                            ProcedureNodeTypes.navigationAction.type ||
                            (!lastNode.close_associated_view &&
                                lastNode.asset_id &&
                                lastNode.zone_id)) &&
                        config.modeType === 'viewer'
                    ) {
                        const navigateAsset = getAsset(
                            lastNode.asset_id,
                            lastNode.zone_id
                        );
                        if (!navigateAsset) {
                            dispatch(previousNode());
                            return;
                        }

                        dispatch(loadAssociateAsset(true));

                        dispatch(previousNode());
                        if (
                            lastNode.type ===
                            ProcedureNodeTypes.navigationAction.type
                        ) {
                            dispatch(loadChecklist(false));
                        }
                        return;
                    }
                    if (lastNode.type === ProcedureNodeTypes.branch.type) {
                        dispatch(setIsBranchNode(true));
                    }
                }

                if (!isSubmitAllow && validateForm) {
                    validateForm();
                    e.preventDefault();
                } else {
                    dispatch(previousNode());
                }
            }
        },
        [
            activeTree,
            activeUser.s3_prefix,
            config.modeType,
            dispatch,
            isSubmitAllow,
        ]
    );

    const handleNext = useCallback(
        async (e, validateForm) => {
            const _nextNode = findNextNode(
                activeTree.allNodes ?? activeTree.nodes ?? [],
                activeTree.current_node
            );
            if (!_nextNode) return;
            if (
                (_nextNode.type === ProcedureNodeTypes.navigationAction.type ||
                    (!_nextNode.close_associated_view &&
                        _nextNode.asset_id &&
                        _nextNode.zone_id)) &&
                config.modeType === 'viewer'
            ) {
                const navigateAsset = getAsset(
                    _nextNode.asset_id,
                    _nextNode.zone_id
                );
                if (!navigateAsset) {
                    return;
                }

                dispatch(loadAssociateAsset(true));
                dispatch(nextNode());
                if (
                    _nextNode.type === ProcedureNodeTypes.navigationAction.type
                ) {
                    dispatch(loadChecklist(false));
                }
                return;
            }
            if (_nextNode.type === ProcedureNodeTypes.branch.type) {
                dispatch(setIsBranchNode(true));
            }
            if (!isSubmitAllow && validateForm) {
                validateForm();
                e.preventDefault();
            } else {
                dispatch(nextNode());
            }
        },
        [
            activeTree,
            activeUser.s3_prefix,
            config.modeType,
            dispatch,
            isSubmitAllow,
        ]
    );

    useContentLinkInterceptor(
        '.gemini-node-content',
        contentService,
        handleNext,
        handleBack
    );

    const showNextButton =
        currentNode.next_button &&
        (currentNode.answer ||
            !currentNode.answer_group ||
            (currentNode.answer_group === 'button' &&
                (!currentNode.option_buttons ||
                    currentNode.option_buttons?.length === 0)) ||
            (currentNode.answer_group === 'select' &&
                (!currentNode.select_options ||
                    currentNode.select_options?.length === 0)) ||
            (currentNode.answer_group === 'image' &&
                (!currentNode.image_options ||
                    currentNode.image_options?.length === 0)));

    const muiTheme = useTheme();
    const smWidth = muiTheme.breakpoints.values.sm;

    const buttonsFirst = width < smWidth;
    let questionOrder = 1;
    let buttonOrder = 2;
    if (buttonsFirst) {
        questionOrder = 2;
        buttonOrder = 1;
    }

    const questionColumns = width < smWidth ? 12 : 8;
    const buttonColumns = width < smWidth ? 12 : 4;

    const showQuestion =
        currentNode.question.length > 0 ||
        currentNode.option_buttons?.length > 0 ||
        currentNode.select_options?.length > 0 ||
        currentNode.image_options?.length > 0;

    const showReturnButton = activeTree.parent_tree_id;

    const nodes = Array.isArray(activeTree.nodes) && activeTree.nodes;
    const completionNode = nodes.find((node) => node?.completion === true);
    const isResultNode = currentNode.type === ProcedureNodeTypes.results.type;
    const isCompletionNode = useMemo(
        () =>
            (config.modeType === 'viewer' &&
                activeTree?.save_sessions &&
                activeSessionId &&
                (currentNode?.type === ProcedureNodeTypes.content.type ||
                    currentNode?.type === ProcedureNodeTypes.results.type) &&
                completionNode?.id === currentNode.id) ||
            (procedureContentVersion &&
                procedureContentVersion?.procedure_end_node === currentNode.id),
        [
            config,
            activeTree,
            activeSessionId,
            currentNode,
            completionNode,
            procedureContentVersion,
        ]
    );

    const validateDuplicateField = (values) => {
        let errors = {};
        displayNodesFields.map((field) => {
            if (field?.type === 'group' || field?.type === 'table') {
                field?.fields &&
                    field?.fields.length > 0 &&
                    field?.fields?.map((subfield) => {
                        Object.keys(values).map((key) => {
                            if (
                                subfield.variable === key &&
                                subfield.required === true
                            ) {
                                if (Array.isArray(values[key])) {
                                    values[key].forEach((e, idx) => {
                                        if (
                                            values[key].filter(Boolean)
                                                .length !== values[key].length
                                        ) {
                                            errors = {
                                                ...errors,
                                                ...validateRequiredField(
                                                    errors,
                                                    key,
                                                    e,
                                                    idx
                                                ),
                                            };
                                        } else {
                                            if (
                                                subfield.type ===
                                                'inspectionpoint'
                                            ) {
                                                errors = {
                                                    ...errors,
                                                    ...validateInspectionPoint(
                                                        errors,
                                                        key,
                                                        e,
                                                        idx
                                                    ),
                                                };
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    });
            } else {
                if (field.required === true) {
                    if (field.type === 'datetime') {
                        if (!values[field.variable]) {
                            errors = {
                                ...errors,
                                [field.variable]: 'Required',
                            };
                        } else {
                            if (typeof values[field.variable] === 'object') {
                                if (!values[field.variable].find(Boolean)) {
                                    errors = {
                                        ...errors,
                                        [field.variable]: 'Required',
                                    };
                                }
                            }
                        }
                    }
                    if (field.type === 'inspectionpoint') {
                        if (!values[field.variable]) {
                            errors = {
                                ...errors,
                                ...validateInspectionPoint(
                                    errors,
                                    field.variable,
                                    values[field.variable],
                                    0
                                ),
                            };
                        } else {
                            if (typeof values[field.variable] === 'object') {
                                values[field.variable].forEach((e, idx) => {
                                    errors = {
                                        ...errors,
                                        ...validateInspectionPoint(
                                            errors,
                                            field.variable,
                                            e,
                                            idx
                                        ),
                                    };
                                });
                            }
                        }
                    }
                }
            }
        });
        return errors;
    };

    const handleTimeUpdate = useCallback(
        (e, video, videoChapters, index) => {
            const activeChapter = setActiveChapterRef.current;
            const isChapterVideo = isChapterVideoRef.current;
            if (activeChapter && activeChapter.length > 0) {
                if (
                    e.target.currentTime >=
                        timeToSeconds(activeChapter?.[index]?.end_time) &&
                    !isChapterVideo?.chapterPlayed?.[index]
                ) {
                    video.pause();
                    setIsChapterVideo((prev) => ({
                        ...prev,
                        chapterPlayed: { ...prev.chapterPlayed, [index]: true },
                    }));
                }
            }
            const currentTime = e.target.currentTime;

            videoChapters?.forEach((chapter, index) => {
                const startTime = timeToSeconds(chapter?.start_time);
                const endTime = timeToSeconds(chapter?.end_time);

                const button = document.querySelector(
                    `[data-item-chapter-id="${chapter.chapter_id}"]`
                );
                if (currentTime >= startTime && currentTime <= endTime) {
                    if (button) {
                        button.classList.add('chaptersActive');
                    }
                } else {
                    if (button) {
                        button.classList.remove('chaptersActive');
                    }
                }
            });
        },
        [setActiveChapterRef, isChapterVideoRef]
    );

    const handleSeekCapture = useCallback(
        (e, index) => {
            const activeChapter = setActiveChapterRef.current;

            if (activeChapter && activeChapter.length > 0) {
                if (
                    e.target.currentTime <
                    timeToSeconds(activeChapter?.[index]?.end_time)
                ) {
                    setIsChapterVideo((prev) => ({
                        ...prev,
                        chapterPlayed: {
                            ...prev.chapterPlayed,
                            [index]: false,
                        },
                    }));
                }
            }
        },
        [setActiveChapterRef]
    );

    const displayChapterButtons = useCallback(
        (videoChapters, video, index) => {
            const shouldShowChapters =
                video.getAttribute('data-target-show_chapters') === 'true';
            if (!shouldShowChapters) return;
            const collapseChapter = video.getAttribute(
                'data-target-collapse_chapters'
            );
            const chapterLocation = video.getAttribute(
                'data-target-chapter_buttons_location'
            );
            const topBottomChapterButtons =
                chapterLocation === 'Top' || chapterLocation === 'Bottom';

            video.parentNode.style.gap = '10px';
            video.parentNode.style.flexDirection = topBottomChapterButtons
                ? 'column'
                : 'row';

            // Chapter Buttons Wrapper
            const buttonsDiv = document.createElement('div');
            buttonsDiv.style.display =
                collapseChapter === 'false' ? 'flex' : 'none';
            buttonsDiv.className = 'chapterButtonWrapper';
            buttonsDiv.style.marginBottom = topBottomChapterButtons
                ? '5px'
                : '0px';
            buttonsDiv.style.height = topBottomChapterButtons
                ? '50px'
                : video.getAttribute('height') + 'px';
            buttonsDiv.style.width = topBottomChapterButtons
                ? video.getAttribute('width') + 'px'
                : '100%';
            buttonsDiv.style.order =
                chapterLocation === 'Right' || chapterLocation === 'Bottom'
                    ? 2
                    : -1;
            buttonsDiv.style.flexDirection = topBottomChapterButtons
                ? 'row'
                : 'column';
            if (!chapterLocation || chapterLocation === 'Right') {
                buttonsDiv.style.flex = '0 0 107px';
            }

            // Chapter Buttons Label
            const chapterLabel = document.createElement('p');
            chapterLabel.textContent = 'Chapters';
            chapterLabel.style.backgroundColor = '#000000';
            chapterLabel.style.color = '#FFFFFF';
            chapterLabel.style.borderRadius = '4px';
            chapterLabel.style.padding = '5px';
            chapterLabel.style.fontWeight = '600';
            chapterLabel.style.display = 'flex';
            chapterLabel.style.justifyContent = 'center';
            chapterLabel.style.alignItems = 'center';
            chapterLabel.style.margin = topBottomChapterButtons
                ? '0px 5px 0 0'
                : '0 0 5px';
            chapterLabel.style.position = 'sticky';
            chapterLabel.style.maxWidth = '100px';
            chapterLabel.style.top = '0px';
            chapterLabel.style.zIndex = '2';

            // Chapter toggle button
            const menuButton = document.createElement('button');
            menuButton.className = 'videoChapterCollapseButton';
            menuButton.style.backgroundColor =
                collapseChapter === 'false' ? '#000' : '#555555';

            const componentHTML = ReactDOMServer.renderToString(
                <MdList
                    className="react-icon"
                    style={{ backgroundColor: 'transparent', color: '#ffffff' }}
                />
            );
            menuButton.innerHTML = componentHTML;

            const wrapperDiv = document.createElement('div');
            wrapperDiv.style.position = 'relative';
            wrapperDiv.className = 'chapterToggleButton';
            wrapperDiv.appendChild(menuButton);

            if (videoChapters && videoChapters?.length > 0) {
                const chapters = [...videoChapters].sort(
                    (a, b) => a.chapter_id - b.chapter_id
                );
                buttonsDiv.appendChild(chapterLabel);
                chapters.forEach((item) => {
                    const button = document.createElement('div');
                    button.classList.add('chaptersButton');
                    button.style.minWidth = '100px';
                    button.title = item.title;
                    button.accessKey = index;
                    button.setAttribute(
                        'data-item-chapter-id',
                        item.chapter_id
                    );
                    const buttonText = document.createElement('p');
                    buttonText.classList.add('chaptersButtonText');
                    buttonText.style.webkitLineClamp = topBottomChapterButtons
                        ? 1
                        : 2;
                    if (item?.chapter_id) {
                        buttonText.textContent = item.title;
                        const tooltipDiv = document.createElement('div');
                        button.appendChild(buttonText);
                        button.onmouseover = () => {
                            const existingTooltipContainer =
                                document.querySelector('.tooltip-container');
                            if (existingTooltipContainer) {
                                existingTooltipContainer.remove();
                            }
                            tooltipDiv.className = 'customTooltip';
                            tooltipDiv.innerHTML = `<strong>${
                                item.title
                            }</strong> <br/> (${formatTime(
                                item.start_time
                            )} - ${formatTime(item.end_time)})`;
                            tooltipDiv.style.display = 'none';
                            tooltipDiv.id = `tooltip-${item.chapter_id}`;
                            const tooltipContainer =
                                document.createElement('div');
                            tooltipContainer.className = 'tooltip-container';
                            document.body.appendChild(tooltipContainer);
                            button.appendChild(tooltipDiv);
                            tooltipDiv.style.display = 'block';
                            const tooltip = document.getElementById(
                                `tooltip-${item.chapter_id}`
                            );
                            tooltipContainer.appendChild(tooltip);
                            const buttonRect = button.getBoundingClientRect();
                            const buttonWidth = buttonRect.width;
                            const buttonHeight = buttonRect.height;
                            let tooltipTop;
                            if (chapterLocation === 'Top') {
                                tooltipTop = buttonRect.top + buttonHeight + 10;
                                tooltip.style.left = `${
                                    buttonRect.left - 20
                                }px`;
                            } else if (chapterLocation === 'Bottom') {
                                tooltipTop = buttonRect.top - 10;
                                tooltip.style.left = `${
                                    buttonRect.left - 20
                                }px`;
                                tooltip.style.transform = 'translateY(-100%)';
                            } else if (chapterLocation === 'Right') {
                                tooltipTop = buttonRect.top;
                                tooltip.style.left = `${
                                    buttonRect.left - 20
                                }px`;
                                tooltip.style.transform = 'translateX(-100%)';
                            } else {
                                tooltipTop = buttonRect.top + 20;
                                tooltip.style.left = `${
                                    buttonRect.left + buttonWidth
                                }px`;
                                tooltip.style.transform = 'translateY(-50%)';
                            }
                            tooltip.style.top = `${tooltipTop}px`;
                            tooltip.style.display = 'block';
                        };

                        button.onmouseout = () => {
                            const tooltip = document.getElementById(
                                `tooltip-${item.chapter_id}`
                            );
                            tooltip.style.display = 'none';
                            tooltipDiv.style.display = 'none';
                        };
                    }

                    button.onclick = () => {
                        setActiveChapter((prev) => {
                            const newChapter = [...prev];
                            newChapter[index] = item;
                            return newChapter;
                        });
                        video.currentTime = timeToSeconds(item.start_time);
                        if (
                            video?.getAttribute('data-target-auto_play') ===
                            'true'
                        ) {
                            video.play();
                        }
                    };
                    buttonsDiv.appendChild(button);
                });
                video.addEventListener('play', () => {
                    menuButton.style.display = 'none';
                });
                video.addEventListener('pause', () => {
                    menuButton.style.display = 'flex';
                });
                video.addEventListener('timeupdate', (e) =>
                    handleTimeUpdate(e, video, videoChapters, index)
                );
                video.addEventListener('seeking', (e) =>
                    handleSeekCapture(e, index)
                );
                video.parentNode.insertBefore(buttonsDiv, video);
                video.parentNode.insertBefore(wrapperDiv, video);
            }
            menuButton.onclick = () => {
                buttonsDiv.style.display =
                    buttonsDiv.style.display === 'none' ? 'flex' : 'none';
                menuButton.style.backgroundColor =
                    buttonsDiv.style.display === 'none' ? '#555555' : '#000';
            };
        },
        [handleSeekCapture, handleTimeUpdate]
    );

    const playSelectedChapter = (videoChapters, video, index) => {
        const selectedChapterId = video.getAttribute(
            'data-target-selectedChapter'
        );
        const chapters = [...videoChapters];
        const selectedChapter = chapters.find(
            (chapter) => chapter.chapter_id === +selectedChapterId
        );
        // || chapters.sort((a, b) => b.chapter_id - a.chapter_id).pop();
        if (Object.keys(selectedChapter || {}).length > 0) {
            setActiveChapter((prev) => {
                const newChapter = [...prev];
                newChapter[index] = selectedChapter;
                return newChapter;
            });
            if (+selectedChapterId) {
                video.currentTime = timeToSeconds(selectedChapter?.start_time);
            }
        }
    };

    const fetchContent = useCallback(
        async (contentId, video, videoIndex, contentVersionId) => {
            dispatch(activateLoading());
            const versions = await contentService.getContentVersions(
                contentId,
                activeUser.s3_prefix
            );
            if (versions && versions.length > 0) {
                const contentVersion = contentVersionId
                    ? versions.find(
                          (version) =>
                              version.content_version_id === contentVersionId
                      )
                    : versions[versions.length - 1];
                processVideoContentVersion(video, videoIndex, contentVersion);
            }

            dispatch(deactivateLoading());
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeUser.s3_prefix, contentService, dispatch]
    );

    const getContentFromDependencies = useCallback(
        async (contentId, video, videoIndex, contentVersionId) => {
            const data = dependencyContents.find(
                (content) => content.content_id === contentId
            );
            if (!data) return;

            if (data.content_versions && data.content_versions?.length > 0) {
                const contentVersion = contentVersionId
                    ? data.content_versions.find(
                          (version) =>
                              version.content_version_id === contentVersionId
                      )
                    : data.content_versions[data.content_versions.length - 1];
                processVideoContentVersion(video, videoIndex, contentVersion);
            }

            if (typeof data.skill_id === 'number') {
                video.addEventListener('ended', () => {
                    dispatch(
                        saveCompletion({
                            url: ectlURL,
                            api_token: ectlToken,
                            skill_id: data.skill_id,
                            // FIXME: remove static value once api side changes done
                            dod_id: activeUser?.dod_id || 'notavailable',
                            completion_time: moment(new Date()).format(
                                'YYYY-MM-DD'
                            ),
                        })
                    );
                });
            }
        },
        [dependencyContents, displayChapterButtons]
    );

    const processVideoContentVersion = (video, videoIndex, contentVersion) => {
        if (!contentVersion) return;

        if (contentVersion.chapters) {
            displayChapterButtons(contentVersion.chapters, video, videoIndex);
            playSelectedChapter(contentVersion.chapters, video, videoIndex);
        }

        if (
            Array.isArray(contentVersion.caption_languages) &&
            contentVersion.caption_languages.length > 0
        ) {
            video.setAttribute('crossorigin', 'anonymous');
            contentVersion.caption_languages.forEach((caption, index) => {
                const track = document.createElement('track');
                track.setAttribute('key', `caption-${index}`);
                track.setAttribute(
                    'label',
                    (track.label =
                        Object.values(CaptionLanguages)?.find(
                            (lang) => lang.LanguageCode === caption
                        )?.DisplayName ?? '')
                );
                track.kind = 'subtitles';
                track.srclang = caption;
                track.default = index == 0;

                var contentUrl = prefixUrl(
                    contentVersion.url,
                    activeUser?.s3_prefix
                );

                track.src = `${contentUrl.substring(
                    0,
                    contentUrl.lastIndexOf('.')
                )}_${caption}.vtt`;
                video.appendChild(track);
            });
        }
    };

    const processContentVideos = useCallback(() => {
        if (!contentVideoProcessed) {
            setContentVideoProcessed(true);
            const videos = document.querySelectorAll(
                'video[data-target-contentid]'
            );
            const menuButtons = document.querySelectorAll(
                'div[class="chapterToggleButton"]'
            );
            const chapterButtons = document.querySelectorAll(
                'div[class="chapterButtonWrapper"]'
            );
            const tooltipContainers =
                document.querySelectorAll('.tooltip-container');
            tooltipContainers?.forEach((item) => {
                item.remove();
            });
            menuButtons.forEach((btn) => {
                btn.remove();
            });
            chapterButtons.forEach((btn) => {
                btn.remove();
            });
            videos.forEach((video, index) => {
                const contentId = video.getAttribute('data-target-contentid');
                const contentVersionId = video.getAttribute(
                    'data-target-selectedVersion'
                );

                if (config.modeType === 'viewer') {
                    getContentFromDependencies(
                        +contentId,
                        video,
                        index,
                        +contentVersionId
                    );
                } else {
                    fetchContent(+contentId, video, index, +contentVersionId);
                }
            });
        }
    }, [
        config.modeType,
        fetchContent,
        getContentFromDependencies,
        contentVideoProcessed,
    ]);

    const handleProcedureContentVersion = useCallback(
        (targetNodeData = null, answerType = null) => {
            if (config?.modeType === 'viewer') {
                let targetNode = targetNodeData;
                if (!targetNodeData) {
                    if (answerType === 'select') {
                        targetNode = currentNode?.select_options?.find(
                            (opt) =>
                                opt?.option_text ===
                                (Array.isArray(selectedAnswers)
                                    ? selectedAnswers[0]?.option_text
                                    : selectedAnswers?.option_text)
                        );
                    }
                    if (answerType === 'image') {
                        targetNode = currentNode?.image_options?.find(
                            (opt) =>
                                opt?.option_text ===
                                (Array.isArray(selectedAnswers)
                                    ? selectedAnswers[0]?.option_text
                                    : selectedAnswers?.option_text)
                        );
                    }
                }
                const actionNode = nodes.find(
                    (node) => node.id === targetNode?.action?.id
                );
                if (
                    !actionNode?.close_associated_view &&
                    actionNode?.asset_id &&
                    actionNode?.zone_id
                ) {
                    dispatch(
                        displayProcedureContentVersion({
                            procedure: {
                                procedure_id: activeTree?.procedure_id,
                                procedure_json: JSON.stringify(activeTree),
                                header_image_url: prefixUrl(
                                    activeTree?.header_image_url,
                                    activeUser.s3_prefix
                                ),
                            },
                            doAction: actionNode || currentNode,
                            addToHistory: true,
                        })
                    );
                    dispatch(loadAssociateAsset(true));
                    dispatch(loadChecklist(false));
                }
            }
        },
        [
            config?.modeType,
            nodes,
            currentNode,
            selectedAnswers,
            dispatch,
            activeTree,
            activeUser.s3_prefix,
        ]
    );

    const updateSessionData = useCallback(() => {
        updateStoreSessionData(dispatch, activeTree, userData, getSessionData);
    }, [dispatch, activeTree, userData, getSessionData]);

    const handleButtonAnswer = useCallback(
        async (buttonId) => {
            const selectedButton = currentNode.option_buttons.find(
                (button) => button.button_id === buttonId
            );

            await dispatch(
                setProcedureAnswer({
                    id: currentNode.id,
                    answer: selectedButton,
                })
            );
            dispatch(
                saveStatement({
                    LrsVerb: LrsVerbs.Answered,
                    response:
                        selectedButton.answer_value ??
                        selectedButton.button_text,
                    extensions: getNodesHistoricalAnswers(activeTree),
                })
            );
            if (currentNode?.variable) {
                await dispatch(
                    setChecklistData({
                        [currentNode.variable]: selectedButton.answer_value,
                    })
                );
            }
            const type = selectedButton.action.type
                ? selectedButton.action.type
                : 'node';

            if (type === 'sub') {
                dispatch(setActiveTreeId(selectedButton.action?.id));
                dispatch(setParentTreeId(activeTree.id));
            }
            if (type === 'node') {
                if (
                    selectedButton.action.id === 'next' ||
                    selectedButton.action.id === 'submit'
                ) {
                    handleNext(null, null);
                } else if (selectedButton.action.id === 'back') {
                    handleBack(null, null);
                } else {
                    dispatch(gotoNode(selectedButton.action.id));
                }
            }
            updateSessionData();
        },
        [
            activeTree,
            currentNode.id,
            currentNode.option_buttons,
            currentNode.variable,
            dispatch,
            updateSessionData,
        ]
    );

    const handleSelectAnswer = useCallback(
        async (selectedOptions, isSubmit = false) => {
            let selectedAnswersGroup = null;
            if (currentNode?.multiple_answers_allow === true && !isSubmit) {
                setSelectedAnswers(selectedOptions);
            } else {
                setSelectedAnswers(selectedOptions);
                const totalOptionsSelected = Array.isArray(selectedOptions)
                    ? selectedOptions.length
                    : 1;
                if (totalOptionsSelected > 1) {
                    const selectedOptionsText = selectedOptions.map(
                        (opt) => opt.option_text
                    );
                    if (currentNode?.select_target_combinations?.length > 0) {
                        const combinations =
                            currentNode.select_target_combinations.filter(
                                (target) =>
                                    target.options_required?.length ===
                                    totalOptionsSelected
                            );
                        combinations.map((target) => {
                            const totalMatch = target.options_required.filter(
                                (opt) =>
                                    selectedOptionsText.includes(
                                        opt.option_text
                                    )
                            ).length;
                            if (
                                !selectedAnswersGroup &&
                                totalMatch === totalOptionsSelected
                            ) {
                                selectedAnswersGroup = target;
                            }
                            return null;
                        });
                    }
                } else if (totalOptionsSelected === 1) {
                    selectedAnswersGroup = currentNode?.select_options.find(
                        (opt) =>
                            opt?.option_text ===
                            (Array.isArray(selectedOptions)
                                ? selectedOptions[0]?.option_text
                                : selectedOptions?.option_text)
                    );
                }
                if (selectedAnswersGroup) {
                    await dispatch(
                        setProcedureAnswer({
                            id: currentNode.id,
                            answer: selectedAnswersGroup,
                        })
                    );
                    if (currentNode?.variable) {
                        await dispatch(
                            setChecklistData({
                                [currentNode.variable]:
                                    selectedAnswersGroup.answer_value,
                            })
                        );
                    }
                    const type = selectedAnswersGroup.action.type
                        ? selectedAnswersGroup.action.type
                        : 'node';

                    if (type === 'sub') {
                        dispatch(
                            setActiveTreeId(selectedAnswersGroup.action?.id)
                        );
                        dispatch(setParentTreeId(activeTree.id));
                    }
                    if (type === 'node') {
                        if (selectedAnswersGroup.action.id === 'next') {
                            handleNext(null, null);
                        } else if (selectedAnswersGroup.action.id === 'back') {
                            handleBack(null, null);
                        } else {
                            dispatch(gotoNode(selectedAnswersGroup.action.id));
                        }
                    }
                } else {
                    enqueueSnackbar(
                        totalOptionsSelected === 0
                            ? 'Select Answers'
                            : 'Incorrect Answers',
                        {
                            action: (key) => <SnackbarDismiss key={key} />,
                            variant: 'error',
                        }
                    );
                }
            }
            updateSessionData();
        },
        [
            activeTree.id,
            currentNode.id,
            currentNode?.multiple_answers_allow,
            currentNode?.select_options,
            currentNode.select_target_combinations,
            currentNode.variable,
            dispatch,
            enqueueSnackbar,
            updateSessionData,
        ]
    );

    const handleImageAnswer = useCallback(
        async (selectedOptions, isSubmit = false) => {
            let selectedAnswersGroup = null;
            if (currentNode?.multiple_answers_allow === true && !isSubmit) {
                setSelectedAnswers(selectedOptions);
            } else {
                setSelectedAnswers(selectedOptions);
                const totalOptionsSelected = selectedOptions.length;
                if (totalOptionsSelected > 1) {
                    const selectedOptionsText = selectedOptions.map(
                        (opt) => opt.option_text
                    );
                    if (currentNode?.image_target_combinations?.length > 0) {
                        const combinations =
                            currentNode.image_target_combinations.filter(
                                (target) =>
                                    target.options_required?.length ===
                                    totalOptionsSelected
                            );
                        combinations.map((target) => {
                            const totalMatch = target.options_required.filter(
                                (opt) =>
                                    selectedOptionsText.includes(
                                        opt.option_text
                                    )
                            ).length;
                            if (
                                !selectedAnswersGroup &&
                                totalMatch === totalOptionsSelected
                            ) {
                                selectedAnswersGroup = target;
                            }
                            return null;
                        });
                    }
                } else if (totalOptionsSelected === 1) {
                    selectedAnswersGroup = currentNode?.image_options.find(
                        (opt) =>
                            opt?.option_text === selectedOptions[0]?.option_text
                    );
                }
                if (selectedAnswersGroup) {
                    await dispatch(
                        setProcedureAnswer({
                            id: currentNode.id,
                            answer: selectedAnswersGroup,
                        })
                    );
                    if (currentNode?.variable) {
                        await dispatch(
                            setChecklistData({
                                [currentNode.variable]:
                                    selectedAnswersGroup.answer_value,
                            })
                        );
                    }
                    const type = selectedAnswersGroup.action.type
                        ? selectedAnswersGroup.action.type
                        : 'node';

                    if (type === 'sub') {
                        dispatch(
                            setActiveTreeId(selectedAnswersGroup.action?.id)
                        );
                        dispatch(setParentTreeId(activeTree.id));
                    }
                    if (type === 'node') {
                        if (selectedAnswersGroup.action.id === 'next') {
                            handleNext(null, null);
                        } else if (selectedAnswersGroup.action.id === 'back') {
                            handleBack(null, null);
                        } else {
                            dispatch(gotoNode(selectedAnswersGroup.action.id));
                        }
                    }
                } else {
                    enqueueSnackbar(
                        totalOptionsSelected === 0
                            ? 'Select Image'
                            : 'Incorrect Answers',
                        {
                            action: (key) => <SnackbarDismiss key={key} />,
                            variant: 'error',
                        }
                    );
                }
            }
            updateSessionData();
        },
        [
            activeTree.id,
            currentNode.id,
            currentNode?.image_options,
            currentNode.image_target_combinations,
            currentNode?.multiple_answers_allow,
            currentNode.variable,
            dispatch,
            enqueueSnackbar,
            updateSessionData,
        ]
    );

    const handleSubmit = useCallback(
        (values, resetForm) => {
            if (isSubmitAllow) {
                let inspectioKey = [];
                let inspectioPoint = [];
                Object.entries(values).map((inspectionField) => {
                    if (
                        inspectionField?.[1]?.field_id ||
                        inspectionField?.[1]?.action ||
                        inspectionField?.[1]?.reason
                    ) {
                        inspectioKey.push(inspectionField[0]);
                        inspectioPoint.push(inspectionField[1]);
                    }
                });
                values.inspection_points = inspectioPoint;

                let formValues = { ...values };

                if (inspectioKey.length > 0) {
                    inspectioKey.map((key) => {
                        delete formValues[key];
                    });
                }

                Object.keys(formValues).forEach((key) => {
                    let val = formValues[key];
                    if (typeof val === 'object') {
                        values[key] = val;
                    } else {
                        values[key] = [val];
                    }
                });

                if (
                    userData?.imgAtt &&
                    typeof userData?.imgAtt === 'object' &&
                    !Array.isArray(userData?.imgAtt)
                ) {
                    values = { ...values, imgAtt: userData?.imgAtt };
                }
                if (clickedBtnValue.type === 'button') {
                    dispatch(setChecklistData(values));
                    handleButtonAnswer(clickedBtnValue?.button_id);
                    setSelectedAnswers(null);
                    return;
                }
                if (clickedBtnValue.type === 'select') {
                    dispatch(setChecklistData(values));
                    handleSelectAnswer(selectedAnswers, true);
                    setSelectedAnswers(null);
                    return;
                }
                if (clickedBtnValue.type === 'image') {
                    dispatch(setChecklistData(values));
                    handleImageAnswer(selectedAnswers, true);
                    setSelectedAnswers(null);
                    return;
                }
                dispatch(
                    saveStatement({
                        LrsVerb: LrsVerbs.Submitted,
                        extensions: JSON.stringify(values),
                    })
                );
                if (clickedBtnValue?.button_id) {
                    const selectedButton = currentNode.option_buttons.find(
                        (button) =>
                            button.button_id === clickedBtnValue?.button_id
                    );
                    dispatch(
                        setProcedureAnswer({
                            id: currentNode.id,
                            answer: selectedButton,
                        })
                    );
                    if (
                        currentNode?.variable &&
                        clickedBtnValue?.answer_value
                    ) {
                        values = {
                            ...values,
                            [`${currentNode?.variable}`]:
                                clickedBtnValue?.answer_value,
                        };
                    }
                }
                dispatch(setChecklistData(values));
                if (clickedBtnValue?.action?.type === 'node') {
                    if (
                        clickedBtnValue?.action?.id === 'next' ||
                        clickedBtnValue?.action?.id === 'submit'
                    ) {
                        // dispatch(nextNode());
                        handleNext(null, null);
                    } else if (clickedBtnValue?.action?.id === 'back') {
                        handleBack(null, null);
                    } else {
                        dispatch(gotoNode(clickedBtnValue?.action?.id));
                    }
                }
                updateSessionData();
                resetForm();
            } else if (isReadOnly) {
                if (clickedBtnValue.type === 'button') {
                    dispatch(setChecklistData(values));
                    handleButtonAnswer(clickedBtnValue?.button_id);
                    setSelectedAnswers(null);
                    return;
                }
                if (clickedBtnValue.type === 'select') {
                    dispatch(setChecklistData(values));
                    handleSelectAnswer(selectedAnswers, true);
                    setSelectedAnswers(null);
                    return;
                }
                if (clickedBtnValue.type === 'image') {
                    dispatch(setChecklistData(values));
                    handleImageAnswer(selectedAnswers, true);
                    setSelectedAnswers(null);
                    return;
                }
            }
        },
        [
            isSubmitAllow,
            isReadOnly,
            userData?.imgAtt,
            clickedBtnValue.type,
            clickedBtnValue?.button_id,
            clickedBtnValue?.action?.type,
            clickedBtnValue?.action?.id,
            clickedBtnValue?.answer_value,
            dispatch,
            updateSessionData,
            handleButtonAnswer,
            handleSelectAnswer,
            selectedAnswers,
            handleImageAnswer,
            currentNode.option_buttons,
            currentNode.id,
            currentNode?.variable,
        ]
    );

    const shouldShowResultContent = (curNode) => {
        return (
            isReadOnly &&
            !!curNode?.content &&
            (curNode.show_content_in_results ||
                ((curNode.show_content_in_results === undefined ||
                    curNode.show_content_in_results === null) &&
                    activeTree?.node_show_content))
        );
    };

    const formRef = useRef(null);
    useEffect(() => {
        if (config.modeType !== 'viewer') {
            return;
        }
        const handler = () => {
            if (document.hidden) {
                handleBlur(formRef.current.values);
                persistor.flush();
            }
        };
        document.addEventListener('visibilitychange', handler);
        return () => document.removeEventListener('visibilitychange', handler);
    }, []);

    return (
        <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialFieldValues}
            validationSchema={fieldValidationSchema}
            validate={validateDuplicateField}
            onSubmit={(values, { resetForm }) => {
                handleSubmit(values, resetForm);
            }}
        >
            {({ validateForm, submitForm }) => (
                <Form style={{ padding: '0 5px 10px' }}>
                    <Grid
                        container
                        className={classes.checkListContainer}
                        ref={ref}
                        id={procedureFormId}
                    >
                        <Grid
                            item
                            container
                            style={{ marginBottom: '10px' }}
                            gap={2}
                            sx={{
                                position: !activeTree?.scroll_header
                                    ? 'sticky'
                                    : 'relative',
                                top: '0',
                                zIndex: '999',
                                pt: '10px',
                                backgroundColor: '#f8f8f8',
                            }}
                        >
                            <Grid item xs={12} className={classes.nodeTitle}>
                                {isSubmitAllow && hasNavigationPane && (
                                    <Tooltip
                                        title={`${
                                            showNavigationPane
                                                ? 'Close'
                                                : 'Open'
                                        } navigation pane`}
                                    >
                                        <IconButton
                                            className={classes.nodeButton}
                                            onClick={() => {
                                                setShowNavigationPane(
                                                    (v) => !v
                                                );
                                            }}
                                        >
                                            <MdMenu className="react-icon" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {displayNode.title}
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    {!isCompletionNode &&
                                        currentNode.back_button && (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={(e) =>
                                                    handleBack(e, validateForm)
                                                }
                                                className={commonStyles.button}
                                            >
                                                Back
                                            </Button>
                                        )}
                                    {!isCompletionNode && showNextButton && (
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={commonStyles.button}
                                            onClick={(e) =>
                                                handleNext(e, validateForm)
                                            }
                                        >
                                            Next
                                        </Button>
                                    )}
                                    {!isCompletionNode &&
                                        !isReadOnly &&
                                        showReturnButton && (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleReturn}
                                                className={commonStyles.button}
                                            >
                                                Return
                                            </Button>
                                        )}
                                </div>
                            </Grid>
                        </Grid>
                        {!isCompletionNode && showQuestion && (
                            <Grid
                                item
                                container
                                className={classes.checkListContentContainer}
                                spacing={'10px'}
                            >
                                {/* {currentNode.question && ( */}
                                <Grid
                                    item
                                    xs={questionColumns}
                                    order={{ xs: questionOrder }}
                                    className={classes.confirmationTitle}
                                >
                                    {currentNode.question}
                                </Grid>
                                {/* )} */}
                                {currentNode.answer_group === 'hotspot' ? (
                                    'hotspot'
                                ) : currentNode.answer_group === 'image' ? (
                                    <Grid
                                        item
                                        xs={buttonColumns}
                                        order={{ xs: buttonOrder }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            {currentNode?.image_options
                                                ?.length > 0 &&
                                                currentNode?.image_options?.map(
                                                    (image_option) => {
                                                        let isSelected = null;
                                                        if (
                                                            Array.isArray(
                                                                selectedAnswers
                                                            )
                                                        ) {
                                                            isSelected =
                                                                selectedAnswers?.find(
                                                                    (opt) =>
                                                                        opt.option_text ===
                                                                        image_option.option_text
                                                                );
                                                        }

                                                        const optionWidth =
                                                            '200px';
                                                        const optionHeight =
                                                            '180px';
                                                        return (
                                                            <div
                                                                key={
                                                                    image_option.option_text
                                                                }
                                                                style={{
                                                                    position:
                                                                        'relative',
                                                                    width: optionWidth,
                                                                    height: optionHeight,
                                                                    marginRight:
                                                                        '15px',
                                                                    marginBottom:
                                                                        '15px',
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        image_option?.img_url
                                                                    }
                                                                    alt={
                                                                        image_option?.img_name
                                                                    }
                                                                    width={
                                                                        optionWidth
                                                                    }
                                                                    height={
                                                                        optionHeight
                                                                    }
                                                                    onClick={async () => {
                                                                        if (
                                                                            !currentNode.multiple_answers_allow
                                                                        ) {
                                                                            handleProcedureContentVersion(
                                                                                image_option,
                                                                                'image'
                                                                            );
                                                                        }
                                                                        let filterAnswers =
                                                                            [];
                                                                        const isAlreadySelected =
                                                                            selectedAnswers?.find(
                                                                                (
                                                                                    opt
                                                                                ) =>
                                                                                    opt.option_text ===
                                                                                    image_option.option_text
                                                                            );
                                                                        setClickedBtnValue(
                                                                            {
                                                                                type: 'image',
                                                                            }
                                                                        );
                                                                        if (
                                                                            currentNode?.multiple_answers_allow ===
                                                                            true
                                                                        ) {
                                                                            if (
                                                                                isAlreadySelected
                                                                            ) {
                                                                                filterAnswers =
                                                                                    selectedAnswers?.filter(
                                                                                        (
                                                                                            opt
                                                                                        ) =>
                                                                                            opt.option_text !==
                                                                                            image_option.option_text
                                                                                    );
                                                                            } else {
                                                                                filterAnswers =
                                                                                    [
                                                                                        ...selectedAnswers,
                                                                                        image_option,
                                                                                    ];
                                                                            }
                                                                            setSelectedAnswers(
                                                                                filterAnswers
                                                                            );
                                                                        } else {
                                                                            filterAnswers =
                                                                                [
                                                                                    image_option,
                                                                                ];
                                                                            setSelectedAnswers(
                                                                                filterAnswers
                                                                            );
                                                                            await submitForm();
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        border: `3px solid ${
                                                                            isSelected
                                                                                ? '#3598db'
                                                                                : '#d0d6d7'
                                                                        }`,
                                                                        borderRadius:
                                                                            '5px',
                                                                    }}
                                                                />
                                                                {isSelected && (
                                                                    <MdCheckCircle
                                                                        className="react-icon"
                                                                        style={{
                                                                            position:
                                                                                'absolute',
                                                                            top: '8px',
                                                                            right: '8px',
                                                                            fill: '#3598db',
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        );
                                                    }
                                                )}
                                        </div>
                                        {currentNode.multiple_answers_allow && (
                                            <div
                                                style={{
                                                    textAlign: 'right',
                                                    marginTop: '15px',
                                                }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    onClick={() => {
                                                        handleProcedureContentVersion(
                                                            null,
                                                            'image'
                                                        );
                                                        setClickedBtnValue({
                                                            type: 'image',
                                                        });
                                                    }}
                                                    className={
                                                        commonStyles.button
                                                    }
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        )}
                                    </Grid>
                                ) : currentNode.answer_group === 'select' &&
                                  Array.isArray(currentNode?.select_options) ? (
                                    <Grid
                                        item
                                        xs={buttonColumns}
                                        order={{ xs: buttonOrder }}
                                    >
                                        <div>
                                            <Autocomplete
                                                key={`select-answer`}
                                                limitTags={3}
                                                multiple={
                                                    currentNode.multiple_answers_allow
                                                }
                                                variant="outline"
                                                id={`select-answer`}
                                                disableCloseOnSelect
                                                options={
                                                    currentNode.select_options
                                                }
                                                isOptionEqualToValue={(
                                                    option,
                                                    value
                                                ) =>
                                                    option.option_id ===
                                                    value.option_id
                                                }
                                                value={
                                                    selectedAnswers
                                                        ? selectedAnswers
                                                        : currentNode?.multiple_answers_allow
                                                        ? []
                                                        : null
                                                }
                                                onChange={(
                                                    event,
                                                    newInputValue
                                                ) => {
                                                    if (
                                                        !currentNode.multiple_answers_allow
                                                    ) {
                                                        handleProcedureContentVersion(
                                                            newInputValue,
                                                            'select'
                                                        );
                                                    }
                                                    setClickedBtnValue({
                                                        type: 'select',
                                                    });
                                                    setSelectedAnswers(
                                                        newInputValue
                                                    );
                                                    if (
                                                        !currentNode?.multiple_answers_allow
                                                    ) {
                                                        submitForm();
                                                    }
                                                }}
                                                style={{ width: '100%' }}
                                                getOptionLabel={(option) =>
                                                    option.option_text
                                                }
                                                renderOption={(
                                                    props,
                                                    option,
                                                    { selected }
                                                ) => {
                                                    return (
                                                        <li {...props}>
                                                            <Checkbox
                                                                icon={
                                                                    <MdCheckBoxOutlineBlank className="react-icon" />
                                                                }
                                                                checkedIcon={
                                                                    <MdCheckBox className="react-icon" />
                                                                }
                                                                style={{
                                                                    marginRight: 8,
                                                                }}
                                                                checked={
                                                                    selected
                                                                }
                                                            />
                                                            {option.option_text}
                                                        </li>
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Answer"
                                                        placeholder="Select Answer"
                                                    />
                                                )}
                                            />
                                        </div>
                                        {currentNode.multiple_answers_allow && (
                                            <div
                                                style={{
                                                    textAlign: 'right',
                                                    marginTop: '15px',
                                                }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    onClick={() => {
                                                        handleProcedureContentVersion(
                                                            null,
                                                            'select'
                                                        );
                                                        setClickedBtnValue({
                                                            type: 'select',
                                                        });
                                                    }}
                                                    className={
                                                        commonStyles.button
                                                    }
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        )}
                                    </Grid>
                                ) : ['hotspot', 'image', 'select'].includes(
                                      currentNode.answer_group
                                  ) === false &&
                                  Array.isArray(currentNode.option_buttons) ? (
                                    (!isResultNode || idx === 0) && (
                                        <Grid
                                            item
                                            xs={buttonColumns}
                                            order={{ xs: buttonOrder }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    gap: '10px',
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                {currentNode.option_buttons.map(
                                                    (button) => {
                                                        return (
                                                            <Button
                                                                key={
                                                                    button.button_id
                                                                }
                                                                variant="contained"
                                                                type="submit"
                                                                className={clsx(
                                                                    commonStyles.button,
                                                                    {
                                                                        [classes.confirmationButton]:
                                                                            button?.style ===
                                                                            'positive',
                                                                    },
                                                                    {
                                                                        [classes.cancelButton]:
                                                                            button?.style ===
                                                                            'negative',
                                                                    },
                                                                    {
                                                                        [classes.selectedButton]:
                                                                            currentNode.answer ===
                                                                            button.button_text,
                                                                    }
                                                                )}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    handleProcedureContentVersion(
                                                                        button
                                                                    );
                                                                    if (
                                                                        !isSubmitAllow
                                                                    ) {
                                                                        validateForm();
                                                                        e.preventDefault();
                                                                    } else {
                                                                        setClickedBtnValue(
                                                                            {
                                                                                type: 'button',
                                                                                button_id:
                                                                                    button?.button_id,
                                                                            }
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                {
                                                                    button.button_text
                                                                }
                                                            </Button>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </Grid>
                                    )
                                ) : null}
                            </Grid>
                        )}
                        <Grid
                            item
                            container
                            className={classes.checkListContentContainer}
                        >
                            <Grid
                                item
                                container
                                className={classes.checkListContentContainer}
                            >
                                {currentNode.content && (
                                    <div
                                        className={clsx(
                                            classes.nodeContent,
                                            `gemini-node-content ${tinyMceCustomClass.tinymceCustom}`
                                        )}
                                        dangerouslySetInnerHTML={{
                                            __html: currentNode.content,
                                        }}
                                    ></div>
                                )}
                                {currentNode.image && (
                                    <Box
                                        component="img"
                                        alt="forklift"
                                        src={currentNode.image}
                                        className={classes.QuestionImage}
                                    />
                                )}
                                {currentNode.embed && (
                                    <div
                                        className={clsx(
                                            classes.embedContainer,
                                            overrideResetClasses.overrideMarzipanoResetCSS
                                        )}
                                        dangerouslySetInnerHTML={{
                                            __html: currentNode.embed,
                                        }}
                                    ></div>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item container ref={procedureRef}>
                            {shouldShowResultContent(displayNode) && (
                                <div
                                    className={clsx(
                                        classes.nodeContent,
                                        `gemini-node-content ${tinyMceCustomClass.tinymceCustom}`
                                    )}
                                    dangerouslySetInnerHTML={{
                                        __html: displayNode.content,
                                    }}
                                ></div>
                            )}
                            <ProcedureFormNode
                                key={`ProcedureQuestions`}
                                isSubmitAllow={isSubmitAllow}
                                isReadOnly={isReadOnly}
                                formFields={displayNodesFields}
                                handleBlur={handleBlur}
                                isMobileWidth={formWidth < smWidth}
                                resultNode={true}
                                contentService={contentService}
                            />
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export { ProcedureQuestions };
