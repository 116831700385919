import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import Tree, { moveItemOnTree } from '@atlaskit/tree';
import { MdAdd, MdDelete, MdDragHandle } from 'react-icons/md';
import {
    Paper,
    styled,
    Tooltip,
    IconButton,
    Box,
    Modal,
    Button,
    Checkbox,
    Typography,
    TextField,
} from '@mui/material';

import { makeFormStyles } from 'forms/styles';
import { sortBy } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { modalStyles } from 'GeminiViewerComponent/components/styles';
import { makeContentNodeFormStyles } from '../ContentNodeEditor/styles';
import { AddNodeCategoryDialog } from './components/AddNodeCategoryDialog';
import { ResetCategoryOrderModal } from './components/ResetCategoryOrderDialog';
import { DiscardCategoryChangeModal } from './components/DiscardCategoryChangeDialog';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import { NodeCategoryConfirmationDialog } from './components/NodeCategoryConfirmationDialog';

const makeFormEditorStyles = makeStyles(() => ({
    container: {
        width: '100%',
    },
    icon: {
        width: '18px',
        height: '18px',
    },
    row: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        '&:hover': {
            filter: 'brightness(90%)',
        },
    },
}));

const makeNodeTableStyles = makeStyles(() => ({
    detailsWrapper: {
        alignItems: 'center',
        display: 'flex',
        align: 'left',
        width: '22%',
    },
    detailsContainer: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        lineHeight: '20px',
    },
    detailsTitle: {
        fontWeight: 'bold',
    },
    categoryCount: {
        fontWeight: 'bold',
        fontSize: '14px',
    },
    addButtonIcon: {
        padding: '0 !important',
        borderRadius: '25px !important',
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
    },
}));

const addField = (tree, field) => {
    tree.items[field.id] = {
        id: field.id,
        data: field,
    };
};

const fieldsToTree = (fields) => {
    var tree = {
        rootId: 'root',
        items: {
            root: {
                id: 'root',
                children: [],
            },
        },
    };

    fields?.forEach((field) => {
        addField(tree, field);
        tree.items.root.children.push(field.id);
    });

    return tree;
};

const StyledTableRow = styled(Box)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    verticalAlign: 'center',
    width: '100%',
    padding: '0 6px 0 0',
    display: 'flex',
    flexWrap: 'nowrap',
    fontSize: '12px',
}));

const NodeContentCell = ({
    category,
    categoryCount,
    setUpdatedNodeCategories,
}) => {
    const theme = useSelector(selectActiveTheme);
    const nodeTableStyles = makeNodeTableStyles(theme);
    const [isCategoryEditing, setIsCategoryEditing] = useState(false);
    return (
        <>
            <Box
                className={nodeTableStyles.detailsWrapper}
                onClick={() => setIsCategoryEditing(true)}
            >
                {isCategoryEditing ? (
                    <TextField
                        autoFocus
                        size="small"
                        style={{ paddingRight: '5px' }}
                        variant="outlined"
                        defaultValue={category.name}
                        onBlur={(e) => {
                            setIsCategoryEditing(false);
                            setUpdatedNodeCategories((prev) => {
                                const nodeCategories = [];
                                prev.forEach((prevCat) => {
                                    if (prevCat.id === category.id) {
                                        const updatedCategory = {
                                            ...prevCat,
                                            name:
                                                e.target.value.trim() ||
                                                prevCat?.name,
                                        };
                                        nodeCategories.push(updatedCategory);
                                    } else {
                                        nodeCategories.push(prevCat);
                                    }
                                });

                                return nodeCategories;
                            });
                        }}
                    />
                ) : (
                    <Tooltip
                        title={category.name}
                        className={nodeTableStyles.detailsContainer}
                    >
                        <div className={nodeTableStyles.detailsTitle}>
                            {category.name}
                        </div>
                    </Tooltip>
                )}
            </Box>
            <Box className={nodeTableStyles.detailsWrapper}>
                <div className={nodeTableStyles.categoryCount}>
                    {categoryCount}
                </div>
            </Box>
        </>
    );
};

const ActionCell = ({
    category,
    selected,
    setSelected,
    setUpdatedNodeCategories,
}) => {
    return (
        <Box alignItems="center" display="flex" width="20%" overflow="hidden">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                    <Checkbox
                        onChange={() => {
                            if (selected.includes(category.id)) {
                                setSelected((prev) =>
                                    prev.filter((item) => item !== category.id)
                                );
                            } else {
                                setSelected((prev) => [...prev, category.id]);
                            }
                        }}
                        checked={selected.includes(category.id)}
                        name={category.id}
                        color="primary"
                    />
                </Box>
                <MdDelete
                    className="react-icon"
                    style={{ cursor: 'pointer', margin: '10px' }}
                    onClick={() => {
                        setSelected((prev) =>
                            prev.filter((selected) => selected !== category.id)
                        );
                        setUpdatedNodeCategories((prev) =>
                            prev.filter((item) => item.id !== category.id)
                        );
                    }}
                />
            </div>
        </Box>
    );
};

const FieldDataRow = ({
    category,
    provided,
    selected,
    setSelected,
    categoryCount,
    setUpdatedNodeCategories,
}) => {
    return (
        <div
            style={{
                width: '100%',
                borderBottom: '1px solid black',
            }}
        >
            <StyledTableRow
                gap="5px"
                tabIndex={-1}
                key={category.id}
                style={{
                    backgroundColor: 'white',
                }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    style={{ width: '7%' }}
                    {...provided.dragHandleProps}
                >
                    <MdDragHandle className="react-icon" />
                </Box>
                <ActionCell
                    category={category}
                    selected={selected}
                    setSelected={setSelected}
                    setUpdatedNodeCategories={setUpdatedNodeCategories}
                />
                <NodeContentCell
                    category={category}
                    categoryCount={categoryCount}
                    setUpdatedNodeCategories={setUpdatedNodeCategories}
                />
            </StyledTableRow>
        </div>
    );
};

const treeToFields = (tree) => {
    var nodeFields = [];

    var treeRootChildren = tree.items[tree.rootId].children;

    treeRootChildren?.forEach((itemId) => {
        var treeItem = tree.items[itemId];
        if (
            (treeItem.data.type === 'group' ||
                treeItem.data.type === 'table') &&
            treeItem?.children?.length > 0
        ) {
            treeItem.data = { ...treeItem.data, fields: [] };
            treeItem?.children?.forEach((itemId) => {
                var childTreeItem = tree.items[itemId];
                treeItem.data.fields[treeItem.data.fields.length] =
                    childTreeItem.data;
            });
        }
        nodeFields.push(treeItem.data);
    });

    return nodeFields;
};

export const NodeCategoriesModal = ({
    procedure,
    openDialog,
    setOpenDialog,
    updateProcedureData,
}) => {
    const procedureCategories = useMemo(
        () =>
            procedure?.procedureCategories?.length > 0
                ? procedure.procedureCategories
                : [],
        [procedure.procedureCategories]
    );
    //#region Constants
    const tree = fieldsToTree(procedureCategories);
    //#endregion Constants

    //#region Hooks
    const theme = useSelector(selectActiveTheme);
    const classes = modalStyles(theme);
    const formClasses = makeFormStyles(theme);
    const formEditorStyles = makeFormEditorStyles();
    const nodeFormStyles = makeContentNodeFormStyles(theme);
    //#endregion Hooks

    //#region State
    const [treeState, setTreeState] = useState({ tree: tree });
    const [selected, setSelected] = useState([]);
    const [categoryCounts, setCategoryCounts] = useState({});
    const [openResetOrderDialog, setOpenResetOrderDialog] = useState(false);
    const [openDiscardChangesDialog, setOpenDiscardChangesDialog] =
        useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [openAddCategoryDialog, setOpenAddCategoryDialog] = useState(false);
    const [updatedNodeCategories, setUpdatedNodeCategories] =
        useState(procedureCategories);
    const isNodeCategoryNotUpdated =
        JSON.stringify(procedureCategories) ===
        JSON.stringify(updatedNodeCategories);
    //#endregion State

    //#region Selectors
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    useEffect(() => {
        setTreeState({
            tree: fieldsToTree(procedureCategories),
        });
        if (procedure?.nodes && procedure?.nodes?.length > 0) {
            const categoryCount = {};
            procedure?.nodes.forEach((node) => {
                if (node?.node_category) {
                    const categoryId = node.node_category.id;
                    categoryCount[categoryId] =
                        (categoryCount[categoryId] || 0) + 1;
                }
            });
            setCategoryCounts(categoryCount);
        }
    }, [procedureCategories, procedure?.nodes]);

    useEffect(() => {
        setTreeState({
            tree: fieldsToTree(updatedNodeCategories),
        });
    }, [updatedNodeCategories]);

    useEffect(() => {
        setUpdatedNodeCategories([...procedureCategories]);
    }, [procedureCategories]);

    //#endregion Effects

    //#region Methods
    const renderItem = ({ item, provided }) => {
        const categoryCount = categoryCounts[item?.id] || 0;
        return (
            <div
                className={formEditorStyles.row}
                ref={provided.innerRef}
                {...provided.draggableProps}
            >
                <FieldDataRow
                    key={item.id}
                    category={item.data}
                    provided={provided}
                    selected={selected}
                    setSelected={setSelected}
                    categoryCount={categoryCount}
                    setUpdatedNodeCategories={setUpdatedNodeCategories}
                />
            </div>
        );
    };

    const orderDraggableField = (
        sortedNodes,
        draggableId,
        sourceIndex,
        destinationIndex,
        sourceDroppableId,
        destinationDroppableId
    ) => {
        let draggableObject = null;
        let isSourceBeforeDestination = null;
        if (
            isSourceBeforeDestination === null &&
            ((sourceDroppableId !== 'root' &&
                destinationDroppableId !== 'root' &&
                sourceDroppableId === destinationDroppableId) ||
                (sourceDroppableId === 'root' &&
                    destinationDroppableId === 'root'))
        ) {
            if (sourceIndex < destinationIndex) {
                isSourceBeforeDestination = true;
            } else {
                isSourceBeforeDestination = false;
            }
        }
        sortedNodes.map((i, index) => {
            if (
                isSourceBeforeDestination === null &&
                ((destinationDroppableId === 'root' &&
                    index === destinationIndex) ||
                    (destinationDroppableId !== 'root' &&
                        destinationDroppableId === i.id))
            ) {
                isSourceBeforeDestination = false;
            }
            if (
                isSourceBeforeDestination === null &&
                ((sourceDroppableId === 'root' && index === sourceIndex) ||
                    (sourceDroppableId !== 'root' &&
                        sourceDroppableId === i.id))
            ) {
                isSourceBeforeDestination = true;
            }

            if (draggableObject === null) {
                if (i.id === draggableId) {
                    draggableObject = i;
                } else if (
                    (i.type === 'group' || i.type === 'table') &&
                    i?.fields &&
                    i?.fields?.length > 0
                ) {
                    i.fields.map((j) => {
                        if (
                            isSourceBeforeDestination === null &&
                            destinationDroppableId !== 'root' &&
                            destinationDroppableId === j.id
                        ) {
                            isSourceBeforeDestination = false;
                        }
                        if (
                            isSourceBeforeDestination === null &&
                            sourceDroppableId !== 'root' &&
                            sourceDroppableId === j.id
                        ) {
                            isSourceBeforeDestination = true;
                        }
                        if (draggableObject === null) {
                            if (j.id === draggableId) {
                                draggableObject = j;
                            }
                        }
                        return null;
                    });
                }
            }
            return null;
        });
        if (draggableObject === null) {
            return sortedNodes;
        }
        if (
            (draggableObject.type === 'group' ||
                draggableObject.type === 'table') &&
            destinationDroppableId !== 'root'
        ) {
            return sortedNodes;
        }

        let fieldsOrder = [];
        if (sourceDroppableId === 'root' && destinationDroppableId === 'root') {
            sortedNodes.map((i, index) => {
                if (sourceIndex === index) {
                    return null;
                } else if (destinationIndex === index) {
                    if (isSourceBeforeDestination) {
                        fieldsOrder = [...fieldsOrder, i, draggableObject];
                    } else {
                        fieldsOrder = [...fieldsOrder, draggableObject, i];
                    }
                } else {
                    fieldsOrder = [...fieldsOrder, i];
                }
            });
        }
        return fieldsOrder;
    };

    const onDragEnd = async (source, destination) => {
        if (
            !destination ||
            (source.parentId === destination.parentId &&
                source.index === destination.index)
        ) {
            return;
        }
        let currentFields = treeToFields(treeState.tree);
        if (
            destination.parentId !== 'root' &&
            treeState.tree.items[destination.parentId]?.data?.type !==
                'group' &&
            treeState.tree.items[destination.parentId]?.data?.type !== 'table'
        ) {
            return;
        }
        const orderedFields = await orderDraggableField(
            currentFields,
            treeState.tree.items[source.parentId].children[source.index],
            source.index,
            destination?.index ? destination.index : 0,
            source.parentId,
            destination.parentId
        );
        if (
            currentFields !== orderedFields ||
            (source.parentId === destination.parentId &&
                source.index === (destination?.index ? destination.index : 0))
        ) {
            const updatedCategories = [];
            orderedFields.forEach((category, index) => {
                updatedCategories.push({ ...category, order: index + 1 });
            });

            moveItemOnTree(treeState.tree, source, destination);
            setTreeState({
                tree: fieldsToTree(orderedFields),
            });
            setUpdatedNodeCategories(updatedCategories);
        }
    };

    const resetCategoryOrder = () => {
        const updatedOrderedCategories = [];
        [...updatedNodeCategories]
            .sort(sortBy('name', 'DESC'))
            .forEach((category, index) => {
                updatedOrderedCategories.push({
                    ...category,
                    order: index + 1,
                });
            });
        setUpdatedNodeCategories([...updatedOrderedCategories]);
        setOpenResetOrderDialog(false);
    };

    const addNodeCategory = useCallback(
        (categoryName) => {
            if (updatedNodeCategories && updatedNodeCategories?.length > 0) {
                const newCategory = {
                    id: uuidv4(),
                    name: categoryName?.trim(),
                };
                const sortedProcedureCategories = [
                    ...updatedNodeCategories,
                    newCategory,
                ].sort(sortBy('name', 'DESC'));

                const updatedProcedureCategories = [];
                sortedProcedureCategories.forEach((category, index) => {
                    updatedProcedureCategories.push({
                        ...category,
                        order: index + 1,
                    });
                });

                setUpdatedNodeCategories([...updatedProcedureCategories]);
            } else {
                setUpdatedNodeCategories([
                    {
                        id: uuidv4(),
                        name: categoryName?.trim(),
                        order: 1,
                    },
                ]);
            }
            setOpenAddCategoryDialog(false);
        },
        [updatedNodeCategories]
    );

    const confirmChanges = useCallback(() => {
        const procedureNodes = [];
        procedure?.nodes?.forEach((node) => {
            if (node?.node_category) {
                const nodeWithCategory = updatedNodeCategories.find(
                    (category) => category.id === node?.node_category?.id
                );
                if (
                    nodeWithCategory &&
                    Object.keys(nodeWithCategory || {}).length > 0
                ) {
                    // eslint-disable-next-line no-unused-vars
                    const { order, ...rest } = nodeWithCategory;
                    procedureNodes.push({ ...node, node_category: rest });
                } else {
                    // eslint-disable-next-line no-unused-vars
                    const { node_category, ...rest } = node;
                    procedureNodes.push(rest);
                }
            } else {
                procedureNodes.push(node);
            }
        });
        updateProcedureData(procedureNodes, updatedNodeCategories);
        setOpenConfirmationDialog(false);
        setOpenDialog(false);
    }, [updatedNodeCategories]);
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render
    return (
        <>
            <Modal onClose={() => setOpenDialog(false)} open={openDialog}>
                <div className={classes.dargModalContainer}>
                    <Box className={classes.dragModalWrapper}>
                        <Typography width="100%" variant="h6">
                            Categories
                        </Typography>
                        {selected && selected?.length > 0 && (
                            <Box display="block" mt="10px" width="100%">
                                <EnhancedTableToolbar
                                    numSelected={selected.length}
                                    toolTipTitle="Delete"
                                    totalCount={updatedNodeCategories?.length}
                                >
                                    <MdDelete
                                        className="react-icon"
                                        style={{
                                            cursor: 'pointer',
                                            margin: '10px',
                                            color: '#7E8C8D',
                                        }}
                                        onClick={() => {
                                            setSelected([]);
                                            setUpdatedNodeCategories((prev) =>
                                                prev.filter(
                                                    (item) =>
                                                        !selected.includes(
                                                            item.id
                                                        )
                                                )
                                            );
                                        }}
                                    />
                                </EnhancedTableToolbar>
                            </Box>
                        )}
                        <Box overflow="auto">
                            <Box
                                display="flex"
                                mt="10px"
                                width="600px"
                                flexDirection="column"
                                maxHeight="60vh"
                            >
                                <table
                                    style={{
                                        borderBottom: '1px solid #000',
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                    }}
                                >
                                    <tr align="left">
                                        <th
                                            width="27%"
                                            style={{ padding: '10px' }}
                                        ></th>
                                        <th
                                            width="22%"
                                            style={{ padding: '10px' }}
                                        >
                                            Name
                                        </th>
                                        <th style={{ padding: '10px' }}>
                                            # Nodes
                                        </th>
                                    </tr>
                                </table>
                                <Paper
                                    style={{
                                        flex: '1',
                                        overflow: 'auto',
                                        boxShadow: 'none',
                                    }}
                                >
                                    <div
                                        className={formEditorStyles.container}
                                        tabIndex="1"
                                    >
                                        <Tree
                                            tree={treeState.tree}
                                            renderItem={renderItem}
                                            onDragEnd={onDragEnd}
                                            offsetPerLevel={12}
                                            isDragEnabled={true}
                                            isNestingEnabled={true}
                                        />
                                    </div>
                                </Paper>
                            </Box>
                        </Box>
                        <Box sx={{ margin: '1rem auto 1rem 0' }}>
                            <Tooltip title="Add Node Category">
                                <IconButton
                                    className={nodeFormStyles.addButtonIcon}
                                    onClick={() => {
                                        setOpenAddCategoryDialog(true);
                                    }}
                                    size="small"
                                >
                                    <MdAdd className="react-icon" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                        >
                            <Button
                                className={formClasses.submitButtonSmall}
                                onClick={() => {
                                    setOpenResetOrderDialog(true);
                                }}
                            >
                                Reset Order...
                            </Button>

                            <Box display="flex" gap="5px">
                                <Button
                                    className={formClasses.cancelButtonSmall}
                                    onClick={() => {
                                        if (isNodeCategoryNotUpdated) {
                                            setOpenDialog(false);
                                        } else {
                                            setOpenDiscardChangesDialog(true);
                                        }
                                        setSelected([]);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={formClasses.submitButtonSmall}
                                    onClick={() => {
                                        if (
                                            updatedNodeCategories.length !==
                                            procedureCategories?.length
                                        ) {
                                            setOpenConfirmationDialog(true);
                                        } else {
                                            confirmChanges();
                                        }
                                        setSelected([]);
                                    }}
                                    disabled={isNodeCategoryNotUpdated}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </div>
            </Modal>
            <ResetCategoryOrderModal
                openModal={openResetOrderDialog}
                setOpenModal={setOpenResetOrderDialog}
                resetOrder={resetCategoryOrder}
            />
            <DiscardCategoryChangeModal
                openModal={openDiscardChangesDialog}
                setOpenModal={setOpenDiscardChangesDialog}
                discardChanges={() => {
                    setOpenDialog(false);
                    setOpenDiscardChangesDialog(false);
                    setUpdatedNodeCategories(procedureCategories);
                }}
            />
            <NodeCategoryConfirmationDialog
                openModal={openConfirmationDialog}
                setOpenModal={setOpenConfirmationDialog}
                confirmChanges={confirmChanges}
            />
            <AddNodeCategoryDialog
                openModal={openAddCategoryDialog}
                setOpenModal={setOpenAddCategoryDialog}
                updatedNodeCategories={updatedNodeCategories}
                addNodeCategory={addNodeCategory}
            />
        </>
    );
    //#endregion Render
};
