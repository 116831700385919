import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { linkService } from '../services/link.service';

const initialState = {
    itemLinks: [],
    assetLinks: [],
    itemLinksPageInfo: {},
    loadedRowsMap: {},
    status: LoadingStatus.Idle,
    error: null,
    link: {},
    techUCoursesStatus: LoadingStatus.Idle,
    techUCourses: [],
};

export const fetchItemLinksPage = createAsyncThunk(
    'links/fetchItemLinksPage',
    async (params, { getState }) => {
        return await linkService.getAllItemLinks(
            params.page,
            params.pageSize,
            params.itemId,
            params.linkTypeId,
            getState().accounts.activeUser.s3_prefix
        );
    }
);

export const fetchMoreItemLinks = createAsyncThunk(
    'links/fetchMoreItemLinks',
    async (params, { getState }) =>
        await linkService.getAllItemLinksRange(
            params.startIndex,
            params.stopIndex,
            params.itemId,
            params.linkTypeId,
            getState().accounts.activeUser.s3_prefix
        )
);

export const fetchLinkByAssetId = createAsyncThunk(
    'links/fetchLinkByAssetId',
    async (assetId, { getState }) =>
        await linkService.getAllLinksByAssetId(
            assetId,
            getState().accounts.activeUser.s3_prefix
        )
);

export const getLinkById = createAsyncThunk(
    'links/getLinkById',
    async (linkId, { getState }) => {
        const response = await linkService.getById(
            linkId,
            getState().accounts.activeUser.s3_prefix
        );
        response.id = linkId;
        return response;
    }
);

export const getAllTechUCourses = createAsyncThunk(
    'links/getAllTechUCourses',
    async (params, { getState }) => {
        const response = await linkService.getTechUCourses(
            params.token,
            getState().accounts.activeUser.s3_prefix
        );
        return response;
    }
);

export const getTechUCourseContentByCourseId = createAsyncThunk(
    'links/getTechUCourseContentByCourseId',
    async (params, { getState }) => {
        const response = await linkService.getTechUCourseContentById(
            params.token,
            params.id,
            getState().accounts.activeUser.s3_prefix
        );
        return { response, id: params.id };
    }
);

export const createLink = createAsyncThunk(
    'links/createLink',
    async (newLink, { getState, dispatch }) => {
        const response = await linkService.create(
            newLink,
            getState().accounts.activeUser.s3_prefix
        );
        if (!response.error) {
            dispatch(
                fetchLinkByAssetId(getState().asset?.activeAsset?.asset_id)
            );
        }
        return response;
    }
);

export const editLink = createAsyncThunk(
    'links/editLink',
    async (updates, { getState, dispatch }) => {
        const response = await linkService.update(
            updates.linkId,
            updates.linkUpdates,
            getState().accounts.activeUser.s3_prefix
        );
        if (!response.error) {
            dispatch(
                fetchLinkByAssetId(getState().asset?.activeAsset?.asset_id)
            );
        }
        return response;
    }
);

export const deleteLinkById = createAsyncThunk(
    'links/deleteLinkById',
    async (linkId, { getState, dispatch }) => {
        const response = await linkService.delete(linkId);
        response.id = linkId;
        if (!response.error) {
            dispatch(
                fetchLinkByAssetId(getState().asset?.activeAsset?.asset_id)
            );
        }
        return response;
    }
);

const linksSlice = createSlice({
    name: 'links',
    initialState,
    reducers: {
        resetLinkState: (state) => initialState,
        resetLinkStatus: (state) => {
            state.status = LoadingStatus.Idle;
        },
    },
    extraReducers: {
        [fetchItemLinksPage.fulfilled]: (state, action) => {
            if (action.payload === undefined) {
                state.status = LoadingStatus.Failed;
                state.error = 'fetchItemLinksPage returned no payload';
                return;
            }
            state.status = LoadingStatus.Loaded;
            state.itemLinksPageInfo = action.payload.pageInfo;
            state.itemLinks = action.payload.itemLinks;

            const { startIndex, stopIndex, requestedStopIndex } = fetchPage(
                action.meta,
                state.itemLinks.length
            );

            // const startIndex = (action.meta.arg.page - 1) * action.meta.arg.pageSize;
            // const stopIndex = startIndex + state.itemLinks.length - 1;
            // const requestedStopIndex = startIndex + action.meta.arg.pageSize - 1;

            state.loadedRowsMap = {};

            for (var i = startIndex; i <= stopIndex; i++) {
                state.loadedRowsMap[i] = LoadingStatus.Loaded;
            }

            // In case we didn't load as many as requested
            for (var j = stopIndex + 1; j <= requestedStopIndex; j++) {
                delete state.loadedRowsMap[j];
            }
        },
        [fetchItemLinksPage.rejected]: (state, action) => {
            state.status = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [fetchMoreItemLinks.pending]: (state, action) => {
            const startIndex = action.meta.arg.startIndex;
            const stopIndex = action.meta.arg.stopIndex;

            for (var i = startIndex; i <= stopIndex; i++) {
                state.loadedRowsMap[i] = LoadingStatus.Loading;
            }
        },
        [fetchMoreItemLinks.fulfilled]: (state, action) => {
            const startIndex = action.meta.arg.startIndex;
            const stopIndex = startIndex + action.payload.itemLinks.length - 1;
            const requestedStopIndex = action.meta.arg.stopIndex;

            for (var i = startIndex; i <= stopIndex; i++) {
                state.loadedRowsMap[i] = LoadingStatus.Loaded;
            }

            // In case we didn't load as many as requested
            for (var j = stopIndex + 1; j <= requestedStopIndex; j++) {
                delete state.loadedRowsMap[j];
            }

            state.itemLinks = state.itemLinks.concat(action.payload.itemLinks);
        },
        [fetchMoreItemLinks.rejected]: (state, action) => {
            const startIndex = action.meta.arg.startIndex;
            const stopIndex = action.meta.arg.stopIndex;

            for (var i = startIndex; i <= stopIndex; i++) {
                delete state.loadedRowsMap[i];
            }

            state.status = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [fetchLinkByAssetId.fulfilled]: (state, action) => {
            state.assetLinks = action.payload;
        },
        [fetchLinkByAssetId.rejected]: (state, action) => {
            state.status = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [getLinkById.fulfilled]: (state, action) => {
            state.link = action.payload;
        },
        [getAllTechUCourses.fulfilled]: (state, action) => {
            state.techUCourses = action.payload?.courses?.map((course) => ({
                id: course.id,
                display_name: course.displayname,
                contentStatus: LoadingStatus.Idle,
                contents: [],
            }));
            state.techUCoursesStatus = LoadingStatus.Loaded;
        },
        [getTechUCourseContentByCourseId.fulfilled]: (state, action) => {
            state.techUCourses = state.techUCourses?.map((course) => {
                if (course.id === action.payload.id) {
                    let contents = [];
                    if (
                        Array.isArray(action.payload.response) &&
                        action.payload.response.length > 0
                    ) {
                        contents = action.payload.response.map((content) => {
                            if (
                                Array.isArray(content.modules) &&
                                content.modules.length > 0
                            ) {
                                content = {
                                    ...content,
                                    modules: content.modules.filter(
                                        (module) =>
                                            [
                                                'page',
                                                'scorm',
                                                'url',
                                                'resource',
                                                'drupal',
                                            ].includes(module?.modname) === true
                                    ),
                                };
                            }
                            return content;
                        });
                    }
                    course = {
                        ...course,
                        contentStatus: LoadingStatus.Loaded,
                        contents: contents,
                    };
                }
                return course;
            });
        },
        [getTechUCourseContentByCourseId.rejected]: (state, action) => {
            console.log('Error in Getting Delta TechU Contents.');
        },
        [createLink.fulfilled]: (state, action) => {
            state.status = LoadingStatus.Idle;
            state.itemLinks = [];
            state.loadedRowsMap = {};
        },
        [createLink.rejected]: (state, action) => {},
        [editLink.fulfilled]: (state, action) => {
            state.status = LoadingStatus.Idle;
            state.itemLinks = [];
            state.loadedRowsMap = {};
        },
        [deleteLinkById.fulfilled]: (state, action) => {
            state.status = LoadingStatus.Idle;
            state.itemLinks = [];
            state.loadedRowsMap = {};
        },
    },
});

function fetchPage(meta, arrLen) {
    const startIndex = (meta.arg.page - 1) * meta.arg.pageSize;
    const stopIndex = startIndex + arrLen - 1;
    const requestedStopIndex = startIndex + meta.arg.pageSize - 1;

    return {
        startIndex: startIndex,
        stopIndex: stopIndex,
        requestedStopIndex: requestedStopIndex,
    };
}

export const getItemLinksPageInfo = (state) => state.links.itemLinksPageInfo;
export const selectAllItemLinks = (state) => state.links.itemLinks;
export const getAllAssetLinks = (state) => state.links.assetLinks;
export const selectLinkStatus = (state) => state.links.status;
export const selectTechUCourse = (state) => state.links.techUCourses;
export const selectTechUCourseStatus = (state) =>
    state.links.techUCoursesStatus;

export const { resetLinkState, resetLinkStatus } = linksSlice.actions;

export default linksSlice.reducer;
