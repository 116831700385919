import React from 'react';
import moment from 'moment';
import { MdClear } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Box, FormControl, Grid, IconButton, TextField } from '@mui/material';

import useStyles from '../../../styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

const EndDateFilter = ({
    debouncedSearch,
    searchString,
    startDate,
    endDate,
    displayOptionId,
    setEndDate,
}) => {
    //#region Constants
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    //#endregion State

    //#region Selectors
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    //#endregion Effects

    //#region Methods
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    return (
        <Grid
            item={true}
            xs={12}
            md={6}
            lg={6}
            className={classes.customSelect}
        >
            <FormControl style={{ position: 'relative' }} fullWidth>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={(newValue) => {
                            const newDate = moment(newValue).format(
                                'YYYY-MM-DD[T]HH:mm:ss'
                            );
                            if (newDate !== 'Invalid date') {
                                setEndDate(newDate);
                                debouncedSearch(
                                    searchString,
                                    startDate,
                                    newDate,
                                    displayOptionId
                                );
                            }
                        }}
                        renderInput={(params) => {
                            delete params?.error;
                            return (
                                <>
                                    <TextField
                                        variant="outlined"
                                        {...params}
                                        sx={{
                                            minWidth: '200px',
                                            width: '100%',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            right: '35px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            fontSize: '20px',
                                        }}
                                        className={'clearSelect'}
                                    >
                                        <IconButton
                                            onClick={() => {
                                                setEndDate(null);
                                                debouncedSearch(
                                                    searchString,
                                                    null,
                                                    endDate,
                                                    displayOptionId
                                                );
                                            }}
                                        >
                                            <MdClear className="react-icon" />
                                        </IconButton>
                                    </Box>
                                </>
                            );
                        }}
                    />
                </LocalizationProvider>
            </FormControl>
        </Grid>
    );
};

export { EndDateFilter };
