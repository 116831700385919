import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { snippetService } from '_features/_services/snippet.service';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';

const initialState = {
    snippetsStatus: LoadingStatus.Idle,
    foldersStatus: LoadingStatus.Idle,
    snippetFolders: [],
    snippets: [],
    folders: [],
    snippet: {},
    folder: {},
};

export const fetchSnippets = createAsyncThunk(
    'snippets/fetchSnippets',
    async () => {
        const response = await snippetService.getAll();
        return response;
    }
);

export const getSnippetById = createAsyncThunk(
    'snippets/getSnippetById',
    async (id) => {
        const response = await snippetService.getById(id);
        response.id = id;
        return response;
    }
);

export const createSnippet = createAsyncThunk(
    'snippets/createSnippet',
    async (params) => await snippetService.create(params)
);

export const duplicateSnippet = createAsyncThunk(
    'snippets/duplicateSnippet',
    async (id) => await snippetService.duplicate(id)
);

export const editSnippet = createAsyncThunk(
    'snippets/editSnippet',
    async (params) => await snippetService.update(params)
);

export const deleteSnippet = createAsyncThunk(
    'snippets/deleteSnippet',
    async (id) => await snippetService.delete(id)
);

export const fetchFolders = createAsyncThunk(
    'snippets/fetchFolders',
    async () => {
        const response = await snippetService.getAllFolder();
        return response;
    }
);

export const getFoldersById = createAsyncThunk(
    'snippets/getFoldersById',
    async (id) => {
        const response = await snippetService.getFolderById(id);
        response.id = id;
        return response;
    }
);

export const createFolder = createAsyncThunk(
    'snippets/createFolder',
    async (params) => await snippetService.createFolder(params)
);

export const editFolder = createAsyncThunk(
    'snippets/editFolder',
    async (params) => await snippetService.updateFolder(params)
);

export const deleteFolder = createAsyncThunk(
    'snippets/deleteFolder',
    async (id) => await snippetService.deleteFolder(id)
);

const snippetsSlice = createSlice({
    name: 'snippets',
    initialState,
    reducers: {
        setSnippet: (state, action) => {
            state.snippet = action.payload;
        },
    },
    extraReducers: {
        [fetchSnippets.pending]: (state, action) => {
            state.snippetsStatus = LoadingStatus.Loading;
        },
        [fetchSnippets.fulfilled]: (state, action) => {
            state.snippetsStatus = LoadingStatus.Loaded;
            state.snippets = action.payload.snippets;
        },
        [getSnippetById.pending]: (state, action) => {
            state.snippetsStatus = LoadingStatus.Loading;
        },
        [getSnippetById.fulfilled]: (state, action) => {
            state.snippetsStatus = LoadingStatus.Loaded;
            state.snippets = action.payload.snippets;
        },
        [createSnippet.pending]: (state, action) => {
            state.snippetsStatus = LoadingStatus.Loading;
        },
        [createSnippet.fulfilled]: (state, action) => {
            state.snippetsStatus = LoadingStatus.Idle;
        },
        [fetchFolders.pending]: (state, action) => {
            state.foldersStatus = LoadingStatus.Loading;
        },
        [fetchFolders.fulfilled]: (state, action) => {
            state.foldersStatus = LoadingStatus.Loaded;
            state.folders = action.payload;
        },
        [createFolder.pending]: (state, action) => {
            state.foldersStatus = LoadingStatus.Loading;
        },
        [createFolder.fulfilled]: (state, action) => {
            state.foldersStatus = LoadingStatus.Idle;
        },
    },
});

export const getAllSnippets = (state) => state.snippets.snippets;
export const getAllFolders = (state) => state.snippets.folders;
export const getSnippet = (state) => state.snippets.snippet;
export const getFolder = (state) => state.snippets.folder;
export const getSnippetStatus = (state) => state.snippets.snippetsStatus;
export const getFolderStatus = (state) => state.snippets.foldersStatus;

export const { setSnippet } = snippetsSlice.actions;

export default snippetsSlice.reducer;
