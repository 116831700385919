import React from 'react';
import { Divider } from '@mui/material';

import { Action } from '_helpers';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';

function NewsFlashPopup({ object, level, onClose, readOnlyMode }) {
    return (
        <React.Fragment>
            <PopupAction
                action={Action.Edit}
                object={object}
                level={level}
                showLabel={true}
                onActionHandled={onClose}
            />
            {!readOnlyMode && (
                <>
                    <Divider />
                    <PopupAction
                        action={Action.Delete}
                        object={object}
                        level={level}
                        showLabel={true}
                        onActionHandled={onClose}
                    />
                </>
            )}
        </React.Fragment>
    );
}

export { NewsFlashPopup };
