import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    searchResults: null,
};

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setSearchResults: (state, action) => {
            state.searchResults = action.payload;
        },
    },
    extraReducers: {},
});

export const getSearchResults = (state) => state.search.searchResults;

export const { setSearchResults } = searchSlice.actions;

export default searchSlice.reducer;
