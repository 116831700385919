import React from 'react';
import { LinkTypes } from '_helpers/enumerations';
import { GeneralContentTab } from '../GeneralContentTab';

function ImageTab() {
    return (
        <GeneralContentTab
            contentName={'Image'}
            linkTypeId={LinkTypes.Image.id}
            contentTypes={LinkTypes.Image.contentTypes}
        />
    );
}

export { ImageTab };
