import React, { useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import {
    MdDelete,
    MdEdit,
    MdExitToApp,
    MdMoreVert,
    MdRotateRight,
} from 'react-icons/md';

import { rotateable } from './Rotateable';
import { panoDraggable } from './PanoDraggable';
import { NavIconEditDialog } from 'components/NavIconEditDialog';
import { clamp } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import { NavHotspotPopup } from 'components/_Misc/PopupActions/components/NavHotspotPopup';
import {
    getChildZones,
    selectCompactZones,
} from 'GeminiViewerComponent/_features/asset/assetSlice';
import { BiReset } from 'react-icons/bi';

function PanoLinkHotspot({
    hotspot,
    icon,
    onUpdatedLinkHotspot,
    onDeletedLinkHotspot,
    onNavClicked,
    hotspotIcons,
    relativeMoves,
    readOnly,
    enableDragging,
    setDraggableFlag = () => {},
}) {
    const curIcon = icon ?? hotspotIcons.find((x) => x.is_default === true);
    const [editingLink, setEditingLink] = useState();
    const config = useSelector(getConfig);
    const allZones = useSelector(selectCompactZones);
    const childZones = useSelector(getChildZones);
    const allZoneData = useMemo(() => {
        if (childZones && childZones?.length > 0) {
            return [...allZones, ...childZones];
        } else {
            return [...allZones];
        }
    }, [allZones, childZones]);
    const [anchorEl, setAnchorEl] = useState(null);
    const rootRef = useRef();

    const handleClick = (event) => {
        setAnchorEl(rootRef.current);
        event.preventDefault();
    };

    function handleRotateClick(hotspot) {
        let rotation = hotspot.rotation + 45;
        if (rotation > 360) {
            rotation = 45;
        }

        const updatedHotspot = {
            zone_id: hotspot.zone_id,
            nav_link_id: hotspot.nav_link_id,
            rotation: rotation,
        };
        if (hotspot.hotspot_icon && hotspot.hotspot_icon.hotspot_icon_id)
            updatedHotspot.hotspot_icon_id =
                hotspot.hotspot_icon.hotspot_icon_id;
        if (onUpdatedLinkHotspot) {
            onUpdatedLinkHotspot(updatedHotspot);
        }
    }

    function handleScaleClick(hotspot, amount) {
        let existingScale = hotspot.scale ? hotspot.scale : 1.0;

        if (existingScale >= 5.0 || existingScale <= 0.1) {
            return;
        }

        let scale = existingScale + amount;
        if (scale > 5.0) {
            scale = 5.0;
        }
        if (scale < 0.3) {
            scale = 0.3;
        }

        const updatedHotspot = {
            zone_id: hotspot.zone_id,
            nav_link_id: hotspot.nav_link_id,
            scale: scale,
        };
        if (hotspot.hotspot_icon && hotspot.hotspot_icon.hotspot_icon_id)
            updatedHotspot.hotspot_icon_id =
                hotspot.hotspot_icon.hotspot_icon_id;
        if (onUpdatedLinkHotspot) {
            onUpdatedLinkHotspot(updatedHotspot);
        }
    }

    function handleDeleteClick() {
        if (onDeletedLinkHotspot) {
            onDeletedLinkHotspot(hotspot);
        }
    }

    function handleEditClick(hotspot) {
        if (editingLink === hotspot.nav_link_id) {
            setEditingLink(undefined);
        } else {
            setEditingLink(hotspot.nav_link_id);
        }
    }

    function handleNavClick(hotspot) {
        if (onNavClicked) {
            onNavClicked(hotspot.target_zone_id);
        }
    }

    function handleEditClose() {
        setEditingLink(undefined);
    }

    function handleTargetChange(newValue, hotspot) {
        let updatedHotspot = {
            zone_id: hotspot.zone_id,
            nav_link_id: hotspot.nav_link_id,
            target_zone_id: newValue,
        };
        if (hotspot.label_x) updatedHotspot.label_x = hotspot.label_x;
        if (hotspot.label_y) updatedHotspot.label_y = hotspot.label_y;
        if (hotspot.yaw) updatedHotspot.yaw = hotspot.yaw;
        if (hotspot.pitch) updatedHotspot.pitch = hotspot.pitch;
        if (hotspot.flat_x) updatedHotspot.flat_x = hotspot.flat_x;
        if (hotspot.flat_y) updatedHotspot.flat_y = hotspot.flat_y;
        if (hotspot.hotspot_icon && hotspot.hotspot_icon_id)
            updatedHotspot.hotspot_icon_id =
                hotspot.hotspot_icon.hotspot_icon_id;
        if (onUpdatedLinkHotspot) {
            onUpdatedLinkHotspot(updatedHotspot);
        }
    }

    function handleHotspotChange(newValue, hotspot) {
        let updatedHotspot = {
            zone_id: hotspot.zone_id,
            nav_link_id: hotspot.nav_link_id,
        };
        if (newValue != 0) {
            updatedHotspot.hotspot_icon_id = newValue;
        }
        if (onUpdatedLinkHotspot) {
            onUpdatedLinkHotspot(updatedHotspot);
        }
    }

    function onMovedLinkHotspot(
        hotspot,
        location,
        screenPosition,
        dragElement
    ) {
        let updatedHotspot = {
            zone_id: hotspot.zone_id,
            nav_link_id: hotspot.nav_link_id,
        };
        if (location.yaw) updatedHotspot.yaw = location.yaw;
        if (location.pitch) updatedHotspot.pitch = location.pitch;
        if (location.x) updatedHotspot.flat_x = clamp(location.x, 0, 1);
        if (location.y) updatedHotspot.flat_y = clamp(location.y, 0, 1);
        if (hotspot.label_x) updatedHotspot.label_x = hotspot.label_x;
        if (hotspot.label_y) updatedHotspot.label_y = hotspot.label_y;
        if (hotspot.target_zone_id)
            updatedHotspot.target_zone_id = hotspot.target_zone_id;
        if (hotspot.hotspot_icon && hotspot.hotspot_icon.hotspot_icon_id)
            updatedHotspot.hotspot_icon_id =
                hotspot.hotspot_icon.hotspot_icon_id;

        onUpdatedLinkHotspot(updatedHotspot, dragElement);
    }

    function onMovedLabel(hotspot, location, screenPosition, dragElement) {
        let updatedHotspot = {
            zone_id: hotspot.zone_id,
            nav_link_id: hotspot.nav_link_id,
        };
        if (hotspot.yaw) updatedHotspot.yaw = hotspot.yaw;
        if (hotspot.pitch) updatedHotspot.pitch = hotspot.pitch;
        if (hotspot.target_zone_id)
            updatedHotspot.target_zone_id = hotspot.target_zone_id;
        if (location.x) updatedHotspot.label_x = parseInt(location.x);
        if (location.y) updatedHotspot.label_y = parseInt(location.y);
        if (hotspot.hotspot_icon && hotspot.hotspot_icon.hotspot_icon_id)
            updatedHotspot.hotspot_icon_id =
                hotspot.hotspot_icon.hotspot_icon_id;
        onUpdatedLinkHotspot(updatedHotspot, dragElement);
    }

    function onRotatedLinkHotspot(hotspot, rotation) {
        const updatedHotspot = {
            zone_id: hotspot.zone_id,
            nav_link_id: hotspot.nav_link_id,
            rotation: rotation,
        };
        if (onUpdatedLinkHotspot) {
            onUpdatedLinkHotspot(updatedHotspot);
        }
    }

    const rotation = hotspot.rotation ? hotspot.rotation : 0.0;
    const scale = hotspot.scale ? hotspot.scale : 1.0;
    let dragStyle = {
        pointerEvents: `${
            enableDragging === true ? 'all !important' : 'none !important'
        }`,
        cursor: `${
            enableDragging === true ? 'move !important' : 'auto !important'
        }`,
        width: 'fit-content',
    };
    let style = {
        transform: `rotate(${rotation}deg) scale(${scale},${scale})`,
    };
    let zone = allZoneData?.find(
        (zone) => zone.zone_id === hotspot.target_zone_id
    );
    if (Object.keys(zone || {}).length <= 0) return;

    const handleResetPosition = () => {
        let updatedHotspot = {
            zone_id: hotspot.zone_id,
            nav_link_id: hotspot.nav_link_id,
        };
        updatedHotspot.label_x = '100';
        updatedHotspot.label_y = '35';
        if (hotspot.hotspot_icon && hotspot.hotspot_icon.hotspot_icon_id)
            updatedHotspot.hotspot_icon_id =
                hotspot.hotspot_icon.hotspot_icon_id;
        onUpdatedLinkHotspot(updatedHotspot);
    };

    return (
        <div ref={rootRef}>
            <img
                src={curIcon?.image_url}
                className="link-hotspot-icon"
                alt={curIcon?.image_url?.toLowerCase()}
                style={style}
                onPointerDown={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (readOnly !== true && enableDragging === true) {
                        setDraggableFlag(false);
                        panoDraggable.handleMouseDown(
                            event,
                            event.currentTarget.parentNode.parentNode,
                            hotspot,
                            onMovedLinkHotspot,
                            relativeMoves
                        );
                    }
                }}
                onPointerUp={() => setDraggableFlag(true)}
            ></img>
            <Box
                className="hotspot-tooltip link-hotspot-tooltip"
                left={`${hotspot.label_x && hotspot.label_x + 'px !important'}`}
                top={`${hotspot.label_y && hotspot.label_y + 'px !important'}`}
                sx={dragStyle}
                onPointerDown={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (readOnly !== true && enableDragging === true) {
                        setDraggableFlag(false);
                        panoDraggable.handleLabelPosition(
                            event,
                            event.currentTarget,
                            hotspot,
                            onMovedLabel,
                            relativeMoves
                        );
                    }
                }}
                onPointerUp={() => {
                    setDraggableFlag(true);
                }}
            >
                {zone?.display_name}
            </Box>
            {config.allowZoneNavigation && (
                <MdExitToApp
                    className="react-icon link-hotspot-control link-hotspot-nav-icon"
                    title="Navigate"
                    onClick={() => handleNavClick(hotspot)}
                />
            )}
            {readOnly !== true && (
                <>
                    <BiReset
                        className="react-icon link-hotspot-control link-hotspot-delete-icon"
                        title="Reset Label Position"
                        style={{ left: '75px', top: '40px' }}
                        onClick={handleResetPosition}
                    />

                    <MdDelete
                        className="react-icon link-hotspot-control link-hotspot-delete-icon"
                        title="Delete"
                        style={{ left: '60px', top: '72px' }}
                        onClick={() => handleDeleteClick(hotspot)}
                    />
                    <MdEdit
                        className="react-icon link-hotspot-control link-hotspot-edit-icon"
                        title="Edit"
                        onClick={() => handleEditClick(hotspot)}
                    />
                    <MdRotateRight
                        className="react-icon link-hotspot-control link-hotspot-rotate-icon"
                        title="Rotate Clockwise"
                        onClick={() => handleRotateClick(hotspot)}
                        onPointerDown={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            rotateable.handleMouseDown(
                                event,
                                hotspot,
                                onRotatedLinkHotspot
                            );
                        }}
                    />
                    <img
                        src={config.paths.LINK_SCALEUP_ICON_URL}
                        alt={`${config.paths.LINK_SCALEUP_ICON_URL}`.toLowerCase()}
                        title="Scale Up"
                        className="link-hotspot-control link-hotspot-scaleup-icon"
                        onClick={() => handleScaleClick(hotspot, 0.1)}
                    ></img>
                    <img
                        src={config.paths.LINK_SCALEDOWN_ICON_URL}
                        alt={`${config.paths.LINK_SCALEDOWN_ICON_URL}`.toLowerCase()}
                        title="Scale Down"
                        style={{ left: '2px', top: '52px' }}
                        className="link-hotspot-control link-hotspot-scaledown-icon"
                        onClick={() => handleScaleClick(hotspot, -0.1)}
                    ></img>
                </>
            )}
            <NavIconEditDialog
                open={!!(editingLink === hotspot.nav_link_id)}
                handleClose={handleEditClose}
                targetZoneDefaultValue={hotspot.target_zone_id}
                targetZoneOnChange={(value) =>
                    handleTargetChange(parseInt(value), hotspot)
                }
                targetZoneData={allZoneData}
                iconsDefaultValue={hotspot?.hotspot_icon?.hotspot_icon_id}
                iconsOnChange={(element) =>
                    handleHotspotChange(parseInt(element.target.value), hotspot)
                }
                iconsData={hotspotIcons}
            />
            <NavHotspotPopup
                object={{
                    ResetPosition: handleResetPosition,
                }}
                level={'linkHotspot'}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
            />
        </div>
    );
}

export { PanoLinkHotspot };
