import { makeStyles } from '@mui/styles';

export const makeFormTableStyles = makeStyles((theme) => ({
    actionCell: {
        display: 'flex',
    },
    addButtonIcon: {
        color: (props) => props.colors.button.primaryBackground,
    },
}));
