import React, { useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import Dropzone from 'react-dropzone';
import { useSelector } from 'react-redux';
import { MdClose } from 'react-icons/md';

import { panelStyles } from 'GeminiViewerComponent/components/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

const IconUploadDialog = ({ setDialogData, dialogData, setIconData }) => {
    const theme = useSelector(selectActiveTheme);
    const panelClasses = panelStyles(theme);
    const [errorMessage, showErrorMessage] = useState('');

    const closeDialog = () => {
        setDialogData(null);
        showErrorMessage('');
    };

    const handleFileChange = (acceptedFile) => {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = (e) => {
            setIconData((preState) => {
                delete preState?.[dialogData + '_reset'];
                return {
                    ...preState,
                    [dialogData]: acceptedFile?.[0],
                    [`${dialogData}_url`]: e.target.result,
                };
            });
        };
        reader.readAsDataURL(acceptedFile?.[0]);
        showErrorMessage('');
        closeDialog();
    };

    const onDropError = (error) => {
        if (error?.[0]?.errors?.[0]?.message) {
            showErrorMessage(error[0].errors[0].message);
        }
    };

    return (
        <Dialog open={!!dialogData}>
            <DialogTitle
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                Upload Icon
                <MdClose
                    className="react-icon"
                    style={{ cursor: 'pointer' }}
                    onClick={closeDialog}
                >
                    &times;
                </MdClose>
            </DialogTitle>
            <DialogContent>
                <Dropzone
                    maxFiles={1}
                    maxSize={250000000}
                    accept="image/svg+xml"
                    onDropAccepted={handleFileChange}
                    onDropRejected={onDropError}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div
                            {...getRootProps()}
                            className={panelClasses.dropzoneArea}
                        >
                            <input {...getInputProps()} />

                            <div className={panelClasses.dropzoneText}>
                                Drag &apos;n&apos; drop svg icon here, or click
                                to select files
                            </div>
                        </div>
                    )}
                </Dropzone>
                <Box mt="8px" fontSize="14px" color="red">
                    {errorMessage}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export { IconUploadDialog };
