import { makeStyles } from '@mui/styles';

const importAssetStyle = makeStyles(() => ({
    '@keyframes rotate': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        spacing: '10px',
        textAlign: 'center',
    },
    formHeader: {
        fontWeight: 'bold',
        fontSize: '20px',
        color: '#2D3D50',
        marginBottom: '10px',
    },
    input: {
        border: '1px solid #BDC3C7',
        fontSize: '18px',
        marginTop: '10px',
        padding: '10px',
        borderRadius: '5px',
        color: '#757575',
        '& option': {
            color: '#2D3D50',
        },
    },
    submit: {
        backgroundColor: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        border: '0px',
        padding: '10px',
        borderRadius: '5px',
        fontSize: '18px',
        textTransform: 'uppercase',
        marginTop: '10px',
        cursor: 'pointer',
        '&:disabled': {
            backgroundColor: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(50%)',
        },
        '&:hover': {
            backgroundColor: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(90%)',
        },
    },
    cancel: {
        backgroundColor: (props) => props.colors.button.secondaryBackground,
        color: (props) => props.colors.button.secondaryForeground,
        padding: '10px',
        borderRadius: '5px',
        fontSize: '18px',
        textTransform: 'uppercase',
        marginTop: '10px',
        cursor: 'pointer',
        border: '0px',
        '&:hover': {
            backgroundColor: (props) => props.colors.button.secondaryBackground,
            color: (props) => props.colors.button.secondaryForeground,
            filter: 'brightness(90%)',
        },
    },
    dropzoneContainer: {
        marginTop: '15px',
        flexGrow: 2,
    },
    dropzoneArea: {
        minHeight: '50px !important',
        minWidth: '200px !important',
        '& svg': {
            width: '1em',
            height: '1em',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid',
        borderRadius: '5px',
    },
    dropzoneAreaCompressed: {
        minHeight: '80px !important',
        '& svg': {
            width: '1em',
            height: '1em',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dropzoneText: {
        fontSize: '1em !important',
        margin: '10px !important',
        backgroundColor: '#0009',
        color: 'white',
        cursor: 'default',
        borderRadius: '5px',
        padding: '5px',
        width: 'max-content',
    },
    previewUploadFileName: {
        backgroundColor: '#18BC9B',
        color: 'white',
        width: '100%',
    },
    dropzonePreviewContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
}));

export { importAssetStyle };
