// eslint-disable-next-line
import React, { createElement } from 'react';
import { sceneButtonStyles } from './styles';
import clsx from 'clsx';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';

const SceneButton = ({ onClick, extraClasses, iconPath, tooltipTitle }) => {
    const theme = useSelector(selectActiveTheme);

    const classes = sceneButtonStyles(theme);

    return (
        <Tooltip title={tooltipTitle ?? ''}>
            <div
                onClick={onClick}
                className={clsx(
                    classes.imageHoverContainer,
                    classes.sceneButton,
                    extraClasses
                )}
            >
                <img
                    src={iconPath}
                    className={clsx(classes.sceneImg, 'normal')}
                    alt="normal-icon"
                ></img>
            </div>
        </Tooltip>
    );
};

export { SceneButton };
