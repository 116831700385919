import { LinkTypeIds, LinkTypeSortOrder } from './enumerations';

export const getSortedLinkTypeKeys = (linkTypes) => {
    const linkTypeKeys = linkTypes.map((assetLinkType) => {
        return Object.keys(LinkTypeIds).find((key) => {
            return LinkTypeIds[key] === assetLinkType.link_type.link_type_id;
        });
    });
    linkTypeKeys.sort((key1, key2) => {
        const order1 = LinkTypeSortOrder[key1];
        const order2 = LinkTypeSortOrder[key2];
        if (order1 < order2) return -1;
        if (order2 > order1) return 1;
        return 0;
    });

    return linkTypeKeys;
};

export const getSortedLinkTypeIds = (linkTypes) => {
    const sortedKeys = getSortedLinkTypeKeys(linkTypes);
    return sortedKeys.map((key) => LinkTypeIds[key]);
};
