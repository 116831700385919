import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    hotspotSelectorContainer: {
        width: '50x',
        maxHeight: '190px',
        color: 'white',
        overflowY: 'auto',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: 'grey',
        padding: '5px',
    },
    hotspotIconContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: '5px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#444',
            //filter: 'grayscale(100%)',
        },
    },
    selectedHotspotIcon: {
        backgroundColor: '#aaa',
    },
    hotspotIcon: {
        width: '35px',
        padding: '5px',
        // cursor: 'pointer',
        // '&:hover': {
        //     borderRadius: '100%',
        //     filter: 'opacity(.5)',
        // },
    },
    sceneContainer: {
        backgroundColor: (props) => props.colors.button.accentBackground,
        color: (props) => props.colors.button.accentForeground,
        border: '0px',
        padding: '0',
        borderRadius: '5px',
        fontSize: '16px',
        textTransform: 'uppercase',
        cursor: 'pointer',
        overflow: 'hidden',
        alignSelf: 'center',
        display: 'inline-flex',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        '&:disabled': {
            backgroundColor: (props) => props.colors.button.accentBackground,
            color: (props) => props.colors.button.accentForeground,
            filter: 'brightness(50%)',
        },
        '&:hover': {
            backgroundColor: (props) => props.colors.button.accentBackground,
            color: (props) => props.colors.button.accentForeground,
            filter: 'brightness(90%)',
        },
        whiteSpace: 'nowrap',
    },
    sceneIconWrap: {
        paddingLeft: '8px',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        paddingRight: '3px',
        background: 'rgba(0,0,0,0.15)',
    },
    repeatIconWrap: {
        padding: '0 6px',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        margin: '4px',
    },
    initialViewIconWrap: {
        paddingLeft: '8px',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
    },
    sceneButton: {
        padding: '12px 8px',
        fontSize: '14px',
        textTransform: 'uppercase',
        backgroundColor: (props) =>
            `${props.colors.button.secondaryBackground} !important`,
        color: (props) =>
            `${props.colors.button.secondaryForeground} !important`,
    },
    infoIcon: {
        width: '20px',
        height: '20px',
        marginRight: '5px',
    },
}));

export { useStyles };
