import { Button, Card } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { useStyles } from './styles';

const MultiSelectDoneButton = ({ children }) => {
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);
    return (
        <Card>
            {children}
            <Button
                className={`${classes.submit} ${classes.multiSelectDoneButton}`}
            >
                Done
            </Button>
        </Card>
    );
};

export { MultiSelectDoneButton };
