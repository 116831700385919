const validationTypeNameToColumnTypes = {
    Boolean: 'checkbox',
    Integer: 'text',
    String: 'text',
    JSON: 'text',
    Select: 'select',
};

// Get column config based on field configuration
export const getColumnConfig = (
    data,
    configFields,
    validationTypes,
    validationRawTypes
) => {
    let columnOptions = {};

    const validationType = validationTypes.find(
        (validationType) =>
            validationType.validation_type_id === data.validation_type_id
    );
    if (validationType) {
        const validationRawType = validationRawTypes.find(
            (validationRawType) =>
                validationRawType.validation_raw_type_id ===
                validationType.type_id
        );
        if (validationRawType) {
            columnOptions.type =
                validationTypeNameToColumnTypes[validationRawType.display_name];
            if (columnOptions.type === 'select') {
                // Generate select options
                const configField = configFields.find(
                    (configField) =>
                        configField.config_field_id === data.config_field_id
                );
                if (configField) {
                    columnOptions.options = validationType.options_array;
                }
            }
            // TODO: Set columnOptions.validation based on validationType and make it
            // compatible with Formik validationSchema
            //
        }
    }

    return columnOptions;
};
