import { GeminiViewManager } from './GeminiViewManager';

class OrbitManager {
    config = {};
    hotspotItems = {};
    zoneItems = {};
    scenes = [];
    onSceneChanged = undefined;
    onShiftRightClick = undefined;
    onRightClick = undefined;
    viewer = undefined;
    data = undefined;
    autorotateToggleElement = undefined;
    disableClick = true;
    panoElement = undefined;
    viewerIRef = undefined;

    constructor(props) {
        this.config = props.config;
        this.initialZoneId = props.initialZoneId;
        this.hotspotItems = props.hotspotItems;
        this.zoneItems = props.zoneItems;
        this.onSceneChanged = props.onSceneChanged;
        this.onShiftRightClick = props.onShiftRightClick;
        this.onRightClick = props.onRightClick;
        this.viewerRef = props.viewerRef;
        window.geminiViewManager = new GeminiViewManager(this);
    }

    init() {
        this.data = this.config.data;
    }

    update(props) {
        this.config = props.config;
        this.initialZoneId = props.initialZoneId;
        this.hotspotItems = props.hotspotItems;
        this.zoneItems = props.zoneItems;
        this.onSceneChanged = props.onSceneChanged;
        this.onShiftRightClick = props.onShiftRightClick;
        this.onRightClick = props.onRightClick;
        this.viewerRef = props.viewerRef;
        window.geminiViewManager = new GeminiViewManager(this);
        this.data = this.config.data;
    }

    switchSceneById(sceneId) {
        this.onSceneChanged({ data: { id: sceneId } });
    }
    updateView() {}
    disableControls() {}
    enableControls() {}
    screenToCoordinates(coord) {
        if (this.viewerRef.current) {
            return this.viewerRef.current.viewCoordToImageCoord(coord);
        } else {
            return null;
        }
    }
    deleteView() {}
    getCurrentViewParams() {
        return { x: 0.5, y: 0.5, yaw: null, pitch: null };
    }
}

export { OrbitManager };
