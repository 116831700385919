import React from 'react';
import {
    Box,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@mui/material';
import FieldValidationError from './FieldValidationError';
import { ImageAttachments } from '../../ImageAttachments';

const RadioGroupField = ({
    data,
    fieldValue,
    setFieldValue,
    validationError,
    handleBlur,
    inGroup = false,
    isReadOnly = false,
    isSubmitAllow = true,
    handleClick = () => {},
}) => {
    const value = fieldValue[data.variable];

    const radioGroupData = (
        <FormControl
            style={{
                padding: '5px',
                width: data?.field_width ? data.field_width + '%' : '100%',
            }}
            onClick={(e) => {
                if (!isSubmitAllow) {
                    e.stopPropagation();
                    handleClick(e, data);
                }
            }}
        >
            {data?.hide_label !== true && data?.label && (
                <div>
                    <label className="Mui-disabled">{data.label}</label>
                </div>
            )}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <RadioGroup
                    name={data.variable}
                    value={value}
                    sx={{
                        [`&, &.Mui-disabled.Mui-checked, &.Mui-disabled, &.MuiFormControlLabel-label`]:
                            {
                                color: 'black',
                            },
                    }}
                    onBlur={() => isSubmitAllow && handleBlur(fieldValue)}
                    onChange={(event) => {
                        if (isSubmitAllow) {
                            setFieldValue(
                                data.variable,
                                event.currentTarget.value
                            );
                        }
                    }}
                    row
                >
                    <div>
                        {data?.fields &&
                            data?.fields?.length > 0 &&
                            data?.fields.map((opt, idx) => (
                                <FormControlLabel
                                    key={`radio-options-${opt?.label}${idx}`}
                                    value={opt?.label}
                                    sx={{
                                        [`&, &.Mui-disabled.Mui-checked, &.Mui-disabled, &.MuiFormControlLabel-label`]:
                                            { color: 'black' },
                                    }}
                                    style={{ marginRight: '20px' }}
                                    control={
                                        <Radio
                                            key={`radio-options-label-${opt?.label}${idx}`}
                                            color="primary"
                                            disabled={isReadOnly}
                                            sx={{
                                                [`&, &.Mui-disabled.Mui-checked, &.Mui-disabled, &.MuiFormControlLabel-label`]:
                                                    { color: 'black' },
                                            }}
                                            checked={opt?.label === value}
                                        />
                                    }
                                    label={opt?.label}
                                    labelPlacement="end"
                                />
                            ))}
                    </div>
                </RadioGroup>
                {data.image_attachments_allow && (
                    <ImageAttachments
                        data={data}
                        var_name={data.variable}
                        level="field"
                        isReadOnly={!isSubmitAllow}
                        handleClick={handleClick}
                    />
                )}
            </Box>
            {validationError && (
                <FieldValidationError validationError={validationError} />
            )}
        </FormControl>
    );

    return inGroup ? (
        radioGroupData
    ) : (
        <div style={{ width: '100%' }}>{radioGroupData}</div>
    );
};

export default RadioGroupField;
