import { IconButton } from '@mui/material';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ButtonField from '../../ButtonField';
import { MdAdd } from 'react-icons/md';

const ButtonAnswerGroupType = ({
    node,
    nodes,
    values,
    setFieldValue,
    nodeFormStyles,
    onDragEnd,
    handleRemove,
}) => {
    const handleAddOptionButton = (option_buttons, setFieldValue) => {
        if (!Array.isArray(option_buttons)) {
            option_buttons = [];
        }
        const optButtons = [...option_buttons];
        optButtons.push({
            action: {
                id: '',
                type: 'node',
            },
            button_id: Number(optButtons?.length),
            button_text: '',
            incorrect: false,
            answer_value: '',
        });
        setFieldValue('option_buttons', optButtons);
    };

    return (
        <>
            <div>
                Buttons{' '}
                <IconButton
                    className={nodeFormStyles.addButtonIcon}
                    onClick={() =>
                        handleAddOptionButton(
                            values?.option_buttons,
                            setFieldValue
                        )
                    }
                    size="small"
                >
                    <MdAdd className="react-icon" />
                </IconButton>
            </div>
            <DragDropContext
                onDragEnd={(result) =>
                    onDragEnd(
                        result,
                        values.option_buttons,
                        'option_buttons',
                        setFieldValue
                    )
                }
            >
                <Droppable droppableId="droppable-content-nodes-buttons">
                    {(provided) => {
                        return (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{
                                    margin: '40px 0',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '8px',
                                }}
                            >
                                {values.option_buttons?.map((button, idx) => {
                                    return (
                                        <ButtonField
                                            button={button}
                                            key={`ButtonField${idx}`}
                                            idx={idx}
                                            node={node}
                                            nodes={nodes}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            provided={provided}
                                            handleRemoveOptionButton={(id) =>
                                                handleRemove(
                                                    id,
                                                    values.option_buttons,
                                                    'option_buttons',
                                                    setFieldValue
                                                )
                                            }
                                        />
                                    );
                                })}
                            </div>
                        );
                    }}
                </Droppable>
            </DragDropContext>
        </>
    );
};

export default ButtonAnswerGroupType;
