import React from 'react';
import { Box, Checkbox, IconButton, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';

import TableColumnHeaderField from '../TableColumnHeaderField';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { makeFieldFormStyles } from '../styles';

const TableField = ({ values, setFieldValue }) => {
    const theme = useSelector(selectActiveTheme);
    const nodeFormStyles = makeFieldFormStyles(theme);

    const handleRemoveTableColumn = (id, values, setFieldValue) => {
        let currOptionButtons = [...values.columns];
        currOptionButtons.splice(id, 1);
        setFieldValue('columns', currOptionButtons);
    };

    const handleAddTableColumn = (columns, setFieldValue) => {
        var uuid = uuidv4();
        if (!Array.isArray(columns)) {
            columns = [];
        }
        const columnsArray = [...columns];
        columnsArray.push({
            id: uuid,
            label: '',
            width: '',
        });
        setFieldValue('columns', columnsArray);
    };
    return (
        <>
            <Box>
                <Checkbox
                    checked={Boolean(values.showBorder)}
                    onClick={(e) =>
                        setFieldValue('showBorder', e.target.checked)
                    }
                />
                Show Border
            </Box>
            <Box>
                <Checkbox
                    checked={Boolean(values.allowRepeatingRows)}
                    onClick={(e) =>
                        setFieldValue('allowRepeatingRows', e.target.checked)
                    }
                />
                Allow repeating rows
            </Box>
            <div className={nodeFormStyles.tableColumnsContainer}>
                <div className={nodeFormStyles.tableColumnsText}>
                    Column Headers
                </div>
                {values.columns?.map((_field, idx) => {
                    return (
                        <TableColumnHeaderField
                            key={`Table${idx}`}
                            idx={idx}
                            values={values}
                            setFieldValue={setFieldValue}
                            handleRemoveTableColumn={(id) =>
                                handleRemoveTableColumn(
                                    id,
                                    values,
                                    setFieldValue
                                )
                            }
                        />
                    );
                })}
                <Box>
                    <Tooltip title="Add Buttons">
                        <IconButton
                            className={nodeFormStyles.addButtonIcon}
                            onClick={() =>
                                handleAddTableColumn(
                                    values?.columns,
                                    setFieldValue
                                )
                            }
                            size="small"
                        >
                            <MdAdd className="react-icon" />
                        </IconButton>
                    </Tooltip>
                </Box>
            </div>
        </>
    );
};

export default TableField;
