const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { procedureSessionService } from 'GeminiViewerComponent/_features/services/procedureSession.service';

const initialState = {
    procedureSessions: [],
    procedureSessionData: null,
    errors: null,
    sessionStatus: LoadingStatus.Idle,
    sessionSubmit: false,
    sessionPageInfo: {},
    sessionLoadedRowsMap: {},
    procedureSessionUsers: [],
    inspectionPointStatus: LoadingStatus.Idle,
};

export const fetchProcedureSession = createAsyncThunk(
    'procedureSession/fetchProcedureSession',
    async (params) =>
        await procedureSessionService.fetchAllProcedureSessions(
            params.startIndex ?? 0,
            params.stopIndex ?? 0,
            params.sort,
            params.createdById,
            params.status,
            params.hasIssue,
            params.searchByPrimaryId,
            params.searchBySecondaryId,
            params.createdDate
        )
);

export const fetchProcedureSessionById = createAsyncThunk(
    'procedureSession/fetchProcedureSessionById',
    async (params) =>
        await procedureSessionService.fetchProcedureSessionsById(params)
);

export const fetchSessionUsers = createAsyncThunk(
    'procedureSession/fetchSessionUsers',
    async (params) => await procedureSessionService.fetchAllUsers(params)
);

export const createProcedureSession = createAsyncThunk(
    'procedureSession/createProcedureSession',
    async (params) =>
        await procedureSessionService.createProcedureSession(params)
);

export const updateProcedureSession = createAsyncThunk(
    'procedureSession/updateProcedureSession',
    async (params) =>
        await procedureSessionService.updateProcedureSession(params)
);

export const updateProcedureInspectionPoint = createAsyncThunk(
    'procedureSession/updateProcedureSession',
    async (params) =>
        await procedureSessionService.updateProcedureInspectionPoint(params)
);

export const deleteProcedureSession = createAsyncThunk(
    'procedureSession/deleteProcedureSession',
    async (params) =>
        await procedureSessionService.deleteProcedureSession(params)
);

const procedureSessionSlice = createSlice({
    name: 'procedureSession',
    initialState,
    reducers: {
        resetSessionData: (state) => initialState,
        submitSessionData: (state, action) => {
            state.sessionSubmit = action.payload;
        },
        resetSessionStatus: (state, action) => {
            state.sessionStatus = LoadingStatus.Idle;
            state.procedureSessions = [];
            state.sessionPageInfo = {};
            state.sessionLoadedRowsMap = {};
        },
    },
    extraReducers: {
        [fetchProcedureSession.pending]: (state, action) => {
            state.sessionStatus = LoadingStatus.Loading;
        },
        [fetchProcedureSession.fulfilled]: (state, action) => {
            state.sessionStatus = LoadingStatus.Loaded;
            state.sessionPageInfo = action.payload.pageInfo;

            if (action.meta.arg.reset) {
                state.sessionLoadedRowsMap = {};
            }

            const startIndex = action.meta.arg.startIndex;
            const stopIndex =
                startIndex + action.payload.procedureSessions.length - 1;

            for (var i = startIndex; i <= stopIndex; i++) {
                state.sessionLoadedRowsMap[i] = LoadingStatus.Loaded;
            }

            // In case we didn't load as many as requested
            for (
                let j = stopIndex + 1;
                j <= action.payload?.pageInfo?.TotalCount;
                j++
            ) {
                delete state.sessionLoadedRowsMap[j];
            }

            if (action.meta.arg.reset) {
                state.procedureSessions = action.payload.procedureSessions;
            } else {
                state.procedureSessions = state.procedureSessions.concat(
                    action.payload.procedureSessions
                );
            }
        },
        [fetchProcedureSession.rejected]: (state, action) => {
            state.sessionStatus = LoadingStatus.Failed;
            state.errors = action.error.message;
        },
        [fetchSessionUsers.fulfilled]: (state, action) => {
            state.sessionStatus = LoadingStatus.Idle;
            state.procedureSessionUsers = action.payload;
        },
        [fetchSessionUsers.rejected]: (state, action) => {
            state.sessionStatus = LoadingStatus.Failed;
            state.errors = action.error.message;
        },
        [fetchProcedureSessionById.fulfilled]: (state, action) => {
            state.sessionStatus = LoadingStatus.Loaded;
            state.procedureSessionData = action.payload;
        },
        [fetchProcedureSessionById.rejected]: (state, action) => {
            state.sessionStatus = LoadingStatus.Failed;
            state.errors = action.error.message;
        },
        [createProcedureSession.fulfilled]: (state, action) => {
            state.sessionStatus = LoadingStatus.Idle;
            state.procedureSessionData = action.payload;
        },
        [createProcedureSession.rejected]: (state, action) => {
            state.sessionStatus = LoadingStatus.Failed;
            state.errors = action.error.message;
        },
        [updateProcedureSession.fulfilled]: (state, action) => {
            state.sessionStatus = LoadingStatus.Idle;
            state.procedureSessionData = action.payload;
        },
        [updateProcedureSession.rejected]: (state, action) => {
            state.sessionStatus = LoadingStatus.Failed;
            state.errors = action.error.message;
        },
        [updateProcedureInspectionPoint.fulfilled]: (state, action) => {
            state.inspectionPointStatus = LoadingStatus.Idle;
        },
        [updateProcedureInspectionPoint.rejected]: (state, action) => {
            state.inspectionPointStatus = LoadingStatus.Failed;
            state.errors = action.error.message;
        },
        [deleteProcedureSession.fulfilled]: (state, action) => {
            state.sessionStatus = LoadingStatus.Idle;
        },
    },
});

export const getSessionErrors = (state) => state.procedureSession.errors;
export const getSessionStatus = (state) => state.procedureSession.sessionStatus;
export const getAllProcedureSessions = (state) =>
    state.procedureSession.procedureSessions;
export const getSessionPageInfo = (state) =>
    state.procedureSession.sessionPageInfo;
export const getSessionLoadedRowsMap = (state) =>
    state.procedureSession.sessionLoadedRowsMap;
export const getProcedureSession = (state) =>
    state.procedureSession.procedureSessionData;
export const getSessionSubmit = (state) => state.procedureSession.sessionSubmit;
export const getSessionUsers = (state) =>
    state.procedureSession.procedureSessionUsers;
export const getInspectionPointStatus = (state) =>
    state.procedureSession.inspectionPointStatus;

export const { resetSessionData, submitSessionData, resetSessionStatus } =
    procedureSessionSlice.actions;

export default procedureSessionSlice.reducer;
