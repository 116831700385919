import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';

export const AddZoneCategoryDialog = ({
    openModal,
    setOpenModal,
    updatedZoneCategories,
    addZoneCategory,
}) => {
    const [newCategoryName, setNewCategoryName] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const isExisting = updatedZoneCategories.some(
            (option) =>
                newCategoryName?.trim() === option['display_name']?.trim()
        );
        if (newCategoryName?.length > 150) {
            setError('Must be 150 characters or less.');
        } else if (isExisting) {
            setError('Zone Category must be unique.');
        } else {
            setError(null);
        }
    }, [newCategoryName, updatedZoneCategories]);

    return (
        <Dialog open={openModal}>
            <DialogTitle>Add a new Zone Category</DialogTitle>
            <DialogContent sx={{ width: '400px' }}>
                <TextField
                    fullWidth
                    autoFocus
                    margin="dense"
                    id="name"
                    onChange={(event) => {
                        setNewCategoryName(event.target.value);
                    }}
                    label="Zone Category"
                    type="text"
                    variant="outlined"
                    error={!!error}
                    helperText={error}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        setOpenModal(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    autoFocus
                    variant="contained"
                    color="primary"
                    disabled={!newCategoryName}
                    onClick={() => {
                        if (!error) {
                            setNewCategoryName(null);
                            setError(null);
                            addZoneCategory(newCategoryName);
                        }
                    }}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
