import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';

import { useStyles } from './styles';
import { Action } from '_helpers';
import clsx from 'clsx';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { useSelector } from 'react-redux';
import getActionIcon from 'GeminiViewerComponent/_helpers/getActionIcon';

const HotspotSelector = ({
    buttonLabel,
    buttonAction,
    srcImgAlt,
    updateIconForSave,
    defaultHotspot,
    selectedHotspotIconId,
    hotspotIcons,
    enableMultipleHotspot = false,
    handleMultipleHotspot,
    isMultipleHotspotEnabled,
    selectTooltip,
    placeTooltip,
    placeMultipleTooltip,
}) => {
    const theme = useSelector(selectActiveTheme);

    const classes = useStyles(theme);

    const [btnImg, setBtnImg] = useState({
        imgUrl: defaultHotspot?.image_url,
        imgAlt: srcImgAlt,
    });

    const [anchorEl, setItemAnchorEl] = React.useState(null);

    const changeHotspotAnchor = (event) => {
        event.stopPropagation();
        setItemAnchorEl(event.currentTarget.parentElement);
    };

    const handleClose = () => {
        setItemAnchorEl(null);
    };

    const handleIconChange = (hotspot, assetDefault = false) => {
        setBtnImg({
            imgUrl: hotspot.image_url,
            imgAlt: hotspot.display_name + ' Image',
        });

        // Set null if navlink or item should use parent asset
        const iconIdForSave = assetDefault ? null : hotspot.hotspot_icon_id;
        updateIconForSave(iconIdForSave);
    };

    const hotspotOpen = Boolean(anchorEl);
    const hotspotAnchorId = hotspotOpen ? 'simple-popover' : undefined;

    return (
        <div className={classes.sceneContainer}>
            <Tooltip title={selectTooltip} placement="top">
                <div className={classes.sceneIconWrap}>
                    <img
                        src={btnImg.imgUrl}
                        alt={btnImg.imgAlt}
                        className={classes.infoIcon}
                        onClick={(e) => changeHotspotAnchor(e)}
                    ></img>
                </div>
            </Tooltip>
            <Popover
                id={hotspotAnchorId}
                open={hotspotOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <div className={classes.hotspotSelectorContainer}>
                    {defaultHotspot && (
                        <>
                            <div
                                className={clsx(classes.hotspotIconContainer, {
                                    [classes.selectedHotspotIcon]:
                                        !selectedHotspotIconId,
                                })}
                                onClick={() =>
                                    handleIconChange(defaultHotspot, true)
                                }
                            >
                                <img
                                    src={defaultHotspot.image_url}
                                    alt={btnImg.display_name}
                                    className={classes.hotspotIcon}
                                />
                                Default
                            </div>
                            <Divider sx={{ width: '100%' }} />
                        </>
                    )}
                    {hotspotIcons.map((hs, index) => {
                        return (
                            <div
                                className={clsx(classes.hotspotIconContainer, {
                                    [classes.selectedHotspotIcon]:
                                        hs.hotspot_icon_id ===
                                        selectedHotspotIconId,
                                })}
                                onClick={() => handleIconChange(hs)}
                                key={index}
                            >
                                <img
                                    key={index}
                                    alt={hs.display_name}
                                    src={hs.image_url}
                                    className={classes.hotspotIcon}
                                />
                                {hs.display_name}
                            </div>
                        );
                    })}
                </div>
            </Popover>
            <Tooltip title={placeTooltip} placement="top">
                <div className={classes.sceneButton} onClick={buttonAction}>
                    {buttonLabel}
                </div>
            </Tooltip>
            {enableMultipleHotspot && (
                <Tooltip title={placeMultipleTooltip} placement="top">
                    <div
                        onClick={handleMultipleHotspot}
                        className={classes.repeatIconWrap}
                        style={
                            isMultipleHotspotEnabled
                                ? { filter: 'brightness(60%)' }
                                : {}
                        }
                    >
                        {getActionIcon(Action.Repeat.iconId, {
                            color: theme.colors.button.primaryForeground,
                        })}
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export { HotspotSelector };
