import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, List, ListItem, Popover, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { MdArrowDropDownCircle } from 'react-icons/md';

import { loadItem } from '_features/common/editItemSlice';
import {
    selectViewContentPanel,
    setActiveLinkTab,
    setDefaultItemPanelSection,
    setViewContentPanel,
} from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import getLinkIcon from 'GeminiViewerComponent/_helpers/getLinkIcon';
import { LinkTypeIds } from 'GeminiViewerComponent/_helpers';
import {
    getSortedLinkTypeKeys,
    getSortedLinkTypeIds,
} from 'GeminiViewerComponent/_helpers/linkTypeHelpers';
import { resetLinkStatus } from 'GeminiViewerComponent/_features/links/linksSlice';
import { resetContentStatus } from '_features/content/contentSlice';

export function ShortcutIcons({ displayAsset, rowData }) {
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const isContentPanel = useSelector(selectViewContentPanel);
    let linkValues = [];
    let dropdownValues = [];
    const open = Boolean(anchorEl);
    if (
        displayAsset?.asset_link_types &&
        displayAsset?.asset_link_types?.length > 0
    ) {
        const linkTypeKeys = getSortedLinkTypeKeys(
            displayAsset?.asset_link_types
        );

        if (linkTypeKeys.length > 8) {
            var count = 1;
            linkTypeKeys.forEach((key) => {
                const linkType = displayAsset?.asset_link_types.find(
                    (lt) => lt.link_type.link_type_id === LinkTypeIds[key]
                );
                if (count <= 7) {
                    linkValues.push(linkType);
                    count = count + 1;
                } else {
                    dropdownValues.push(linkType);
                }
            });
        } else {
            linkValues = linkTypeKeys.map((key) =>
                displayAsset?.asset_link_types.find(
                    (lt) => lt.link_type.link_type_id === LinkTypeIds[key]
                )
            );
        }
    }
    const handleClick = async (zone_id, item_id, link_id) => {
        setAnchorEl(null);
        const sortedLinkIds = getSortedLinkTypeIds(
            displayAsset?.asset_link_types
        );
        const linkIndex = sortedLinkIds.findIndex((link) => link === link_id);

        linkIndex > -1 && dispatch(setActiveLinkTab(linkIndex));
        dispatch(setDefaultItemPanelSection(0));
        dispatch(resetLinkStatus());
        dispatch(resetContentStatus());
        if (isContentPanel !== true) {
            dispatch(setViewContentPanel(true));
            await dispatch(loadItem({ zoneId: zone_id, itemId: item_id }));
        }
    };

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    bgcolor: isContentPanel ? 'none' : 'background.paper',
                    maxWidth: isContentPanel ? 250 : 125,
                    width: isContentPanel ? 250 : 125,
                    borderRadius: 1,
                }}
            >
                {linkValues?.map((item, index) => (
                    <Tooltip
                        title={item?.link_type?.display_name}
                        key={`shortcut-icon-visible-${item?.link_type?.link_type_id}`}
                        disableInteractive
                        arrow
                    >
                        <span
                            alt={item?.link_type.display_name}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '25px',
                                width: '30px',
                                // filter: 'grayscale(100%)',
                                padding: '2px',
                                cursor: 'pointer',
                            }}
                            onClick={(e) =>
                                handleClick(
                                    rowData?.zone_id,
                                    rowData?.item_id,
                                    item?.link_type?.link_type_id
                                )
                            }
                        >
                            {getLinkIcon(item?.link_type?.link_type_id)}
                        </span>
                    </Tooltip>
                ))}
                {displayAsset?.asset_link_types?.length > 8 && (
                    <>
                        <IconButton
                            onClick={(e) => setAnchorEl(e.currentTarget)}
                            aria-label="close"
                            size="large"
                            style={{ margin: '0 5px', padding: '0px' }}
                        >
                            <Tooltip
                                title="Click to see more"
                                arrow
                                disableInteractive
                            >
                                <MdArrowDropDownCircle
                                    className="react-icon"
                                    style={{ fontSize: '22px', color: 'black' }}
                                />
                            </Tooltip>
                        </IconButton>
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={(e) => setAnchorEl(null)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <List sx={{ width: '100%', maxWidth: 360 }}>
                                {dropdownValues?.map((item, index) => (
                                    <ListItem
                                        key={`shortcut-icon-visible-${item?.link_type?.link_type_id}`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            handleClick(
                                                rowData?.zone_id,
                                                rowData?.item_id,
                                                item?.link_type?.link_type_id
                                            )
                                        }
                                        title={item?.link_type?.display_name}
                                    >
                                        <Tooltip
                                            title={
                                                item?.link_type?.display_name
                                            }
                                            disableInteractive
                                            arrow
                                        >
                                            <div
                                                alt={
                                                    item?.link_type
                                                        ?.display_name
                                                }
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    height: '22px',
                                                    width: '25px',
                                                    // filter: 'grayscale(100%)',
                                                }}
                                            >
                                                {getLinkIcon(
                                                    item?.link_type
                                                        ?.link_type_id
                                                )}
                                            </div>
                                        </Tooltip>
                                    </ListItem>
                                ))}
                            </List>
                        </Popover>
                    </>
                )}
            </Box>
        </div>
    );
}
