import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { FormActions } from '_helpers/form-action';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { makeFormStyles } from '../styles';
import {
    setProgress,
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import SetDirtyForm from 'forms/SetDirtyForm';
import { accountsSlice } from 'app/store';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { closePanel } from '_features/common/formSlice';
import { addNewAssetExport } from '_features/assets/assetExportSlice';
import { useConfigValues } from 'hooks/useConfigValues';
import {
    selectConfigValuesAssetId,
    selectConfigValuesByFields,
} from '_features/configValues/configValuesSlice';
import { FormSwitchField } from 'components/TextField';

const AssetExportForm = ({ formAction, assetId }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    // eslint-disable-next-line no-unused-vars
    const theme = useSelector(selectActiveTheme);
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const selectedAssetId = useSelector(selectConfigValuesAssetId);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    const classes = makeFormStyles(theme);

    const { configValues, configFields } = useConfigValues({
        clientId: activeUser?.client_id,
        assetId: selectedAssetId,
    });
    // eslint-disable-next-line no-unused-vars
    const INIT_VALUES = {
        is_scorm: false, // web = false
        is_self_contained: false,
        place_on_additional_storage: false,
    };

    const [additionalStorageConfigs, setAdditionalStorageConfigs] =
        useState(null);

    useEffect(() => {
        const updateAdditionalStorageSettings = async () => {
            const updatedResponse = selectConfigValuesByFields(
                configValues,
                configFields,
                [
                    'AllowAdditionalExportStorage',
                    'AdditionalExportStorageValidation',
                ]
            );
            if (updatedResponse) {
                setAdditionalStorageConfigs({
                    AllowAdditionalExportStorage:
                        updatedResponse?.AllowAdditionalExportStorage === 'true'
                            ? true
                            : false,
                    AdditionalExportStorageValidation:
                        updatedResponse?.AdditionalExportStorageValidation &&
                        typeof updatedResponse?.AdditionalExportStorageValidation ===
                            'object' &&
                        !Array.isArray(
                            updatedResponse?.AdditionalExportStorageValidation
                        ) &&
                        updatedResponse?.AdditionalExportStorageValidation !==
                            null
                            ? updatedResponse?.AdditionalExportStorageValidation
                            : null,
                });
            }
        };
        updateAdditionalStorageSettings();
    }, [configValues, configFields]);

    const handleSubmit = async (
        values,
        storeConfigs,
        setSubmitting,
        resetForm
    ) => {
        dispatch(activateLoading({ showProgress: true }));
        if (showAdditionalStorageOption(storeConfigs, values) !== true) {
            delete values?.place_on_additional_storage;
        }
        let resultAction = null;

        switch (formAction.id) {
            case FormActions.Edit.id:
                break;
            case FormActions.Create.id:
                dispatch(
                    setProgress({
                        progress: 0,
                        label: 'Step 1 of 2: Uploading',
                        noAnimation: true,
                    })
                );
                resultAction = await dispatch(
                    addNewAssetExport({
                        ...values,
                        asset_id: assetId,
                        dispatch,
                    })
                );
                if (!resultAction.error) {
                    resetForm();
                }
                break;
            default:
                break;
        }
        if (!resultAction.error) {
            dispatch(closePanel({ formKey: 'assetExportForm' }));
        }
        dispatch(deactivateLoading());
        const { message, variant } = snackbarHandler(
            resultAction.meta.requestStatus,
            '',
            resultAction.error?.message ??
                'Export job started. Review on the Job Status page.'
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });
        setSubmitting(false);
    };

    const showAdditionalStorageOption = (storageConfigs, values) => {
        if (
            storageConfigs &&
            storageConfigs?.AllowAdditionalExportStorage === true
        ) {
            if (storageConfigs?.AdditionalExportStorageValidation === null) {
                return true;
            }
            if (
                typeof storageConfigs?.AdditionalExportStorageValidation ===
                    'object' &&
                !Array.isArray(
                    storageConfigs?.AdditionalExportStorageValidation
                ) &&
                storageConfigs?.AdditionalExportStorageValidation !== null &&
                storageConfigs?.AdditionalExportStorageValidation?.is_scorm ===
                    values?.is_scorm &&
                storageConfigs?.AdditionalExportStorageValidation
                    ?.is_self_contained === values?.is_self_contained
            ) {
                return true;
            }
        }
        return false;
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...INIT_VALUES }}
            validationSchema={Yup.object({})}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(
                    values,
                    additionalStorageConfigs,
                    setSubmitting,
                    resetForm
                );
            }}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form className={classes.form}>
                    <div className={classes.formHeader}>Export Details</div>
                    <div style={{ marginTop: '10px' }}>
                        How will users access the asset?
                    </div>
                    <FormSwitchField
                        label="Self-contained"
                        checked={!!values.is_self_contained}
                        onChange={(e) => {
                            if (!readOnlyMode) {
                                setFieldValue(
                                    'is_self_contained',
                                    e.target.checked,
                                    false
                                );
                            }
                        }}
                        name="is_self_contained"
                    />
                    {showAdditionalStorageOption(
                        additionalStorageConfigs,
                        values
                    ) === true && (
                        <FormSwitchField
                            label="Place on additional storage system"
                            checked={!!values.place_on_additional_storage}
                            onChange={(e) => {
                                if (!readOnlyMode) {
                                    setFieldValue(
                                        'place_on_additional_storage',
                                        e.target.checked,
                                        false
                                    );
                                }
                            }}
                            name="place_on_additional_storage"
                        />
                    )}
                    {!readOnlyMode && (
                        <>
                            <SetDirtyForm />
                            <button
                                className={classes.submit}
                                type="submit"
                                disabled={isSubmitting}
                            >
                                Submit
                            </button>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export { AssetExportForm };
