import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    primaryButton: {
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
        fontWeight: '600 !important',
        '&:hover': {
            filter: 'brightness(90%) !important',
        },
    },
}));
