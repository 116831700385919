import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    deleteActiveZoneItem,
    updateActiveZoneItem,
} from 'GeminiViewerComponent/_features/asset/assetSlice';
import {
    resetContentStatus,
    resetExternalContentStatus,
} from '_features/content/contentSlice';
import { resetLinkStatus } from 'GeminiViewerComponent/_features/links/linksSlice';
import { itemService } from '_features/_services';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { setStackPointer, updateNavItemStack } from 'shared/loadZoneSlice';
import { updateAllAssetItem } from '_features/items/itemsTableSlice';

const initItemState = {
    audience_ids: [],
    display_name: '',
    description: '',
    external_id: '',
    pano_id: '',
    hotspot_icon: {},
    tag_ids: [],
};

const initialState = {
    item: initItemState,
    loadingStatus: LoadingStatus.Idle,
    savingStatus: SavingStatus.Idle,
};

export const loadItem = createAsyncThunk(
    'editItem/loadItem',
    async ({ zoneId, itemId }, { getState, dispatch }) => {
        const item = await itemService.getById(
            zoneId,
            itemId,
            getState().accounts.activeUser.s3_prefix
        );
        dispatch(resetExternalContentStatus());
        dispatch(resetLinkStatus());
        dispatch(resetContentStatus());
        if (item) {
            dispatch(updateActiveZoneItem(item));
        }

        return item;
    }
);

export const editItem = createAsyncThunk(
    'editItem/editItem',
    async (props, { getState, dispatch }) => {
        const original_zone_id = props.original_zone_id ?? props.zone_id;
        delete props.original_zone_id;
        const response = await itemService.update(
            props.item_id,
            props,
            getState().accounts.activeUser.s3_prefix
        );
        if (response) {
            dispatch(setSelectedItem({ ...props }));
            if (original_zone_id !== props.zone_id) {
                dispatch(
                    deleteActiveZoneItem({
                        ...props,
                        zone_id: original_zone_id,
                    })
                );
            }
            dispatch(updateActiveZoneItem({ ...props }));
            dispatch(updateAllAssetItem({ ...props }));

            if (props.action === 'undo' || props.action === 'redo') {
                dispatch(setStackPointer({ ...props, type: 'items' }));
            } else {
                dispatch(updateNavItemStack({ ...props, type: 'items' }));
            }
            // dispatch(updateActiveZoneItem(response));
        }
        return response;
    }
);

const editItemSlice = createSlice({
    name: 'editItem',
    initialState,
    reducers: {
        clearEditItem: (state, action) => {
            state.loadingStatus = LoadingStatus.Idle;
            state.item = initItemState;
        },
        setSelectedItem: (state, action) => {
            state.item = { ...state.item, ...action.payload };
        },
    },
    extraReducers: {
        [loadItem.pending]: (state, action) => {
            state.loadingStatus = LoadingStatus.Loading;
            state.item = initItemState;
        },
        [loadItem.fulfilled]: (state, action) => {
            state.item = {
                ...action.payload,
                audience_ids: action.payload.audiences?.map(
                    (x) => x.audience_id
                ),
                tag_ids: action.payload.tags?.map((x) => x.tag_id),
            };
            state.loadingStatus = LoadingStatus.Loaded;
        },
        [loadItem.rejected]: (state, action) => {
            state.loadingStatus = LoadingStatus.Failed;
        },
        [editItem.pending]: (state, action) => {
            state.savingStatus = SavingStatus.Saving;
        },
        [editItem.fulfilled]: (state, action) => {
            // state.item = {
            //     ...action.payload,
            //     audience_ids: action.payload.audiences?.map(
            //         (x) => x.audience_id
            //     ),
            //     tag_ids: action.payload.tags?.map((x) => x.tag_id),
            // };
            state.savingStatus = SavingStatus.Saved;
        },
        [editItem.rejected]: (state, action) => {
            state.savingStatus = SavingStatus.Failed;
        },
    },
});

export const selectEditItem = (state) => state.editItem?.item;
export const selectEditItemId = (state) => state.editItem?.item?.item_id;
export const selectEditItemLoadingStatus = (state) =>
    state.editItem.loadingStatus;
export const selectEditItemSavingStatus = (state) =>
    state.editItem.savingStatus;

export const { clearEditItem, setSelectedItem } = editItemSlice.actions;

export default editItemSlice.reducer;
