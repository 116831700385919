import { makeStyles } from '@mui/styles';

export const assetStatsModalStyles = makeStyles(() => ({
    headerWrapper: {
        top: 0,
        color: '#fff',
        width: '100%',
        display: 'flex',
        zIndex: 9,
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: (props) => props.colors.primarySectionBackground,
    },
    headerButtons: {
        margin: '5px 10px',
        color: (props) => props.colors.primarySectionForeground,
    },
    headerText: {
        padding: '5px',
        overflow: 'hidden',
        fontSize: '18px',
        maxWidth: '50%',
        fontWeight: '600',
        textOverflow: 'ellipsis',
    },
}));
