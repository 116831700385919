import React from 'react';
import { LinkTypes } from '_helpers/enumerations';
import { GeneralContentTab } from '../GeneralContentTab';

function SystemTab() {
    return (
        <GeneralContentTab
            contentName={'System'}
            linkTypeId={LinkTypes.System.id}
            contentTypes={LinkTypes.System.contentTypes}
        />
    );
}

export { SystemTab };
