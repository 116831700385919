import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAllAudiences,
    getAudienceStatus,
    fetchAudiencesPage,
    resetAudienceState,
} from '_features/audiences/audiencesSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { AudienceTable } from './AudienceTable';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

const AudienceManagementTab = () => {
    const dispatch = useDispatch();
    const ref = useRef(false);
    const audiences = useSelector(selectAllAudiences);
    const audienceStatus = useSelector(getAudienceStatus);
    const [audiencePayload, setAudiencePayload] = useState({});
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');

    const getAudiencesData = async (payload) => {
        dispatch(activateLoading());
        await dispatch(fetchAudiencesPage({ ...payload }));
        dispatch(deactivateLoading());
    };

    useEffect(() => {
        if (ref.current === true) {
            getAudiencesData({ ...audiencePayload });
        }
        return () => {
            dispatch(resetAudienceState());
        };
    }, [audiencePayload]);

    useEffect(() => {
        if (audienceStatus === LoadingStatus.Idle) {
            getAudiencesData({
                ...audiencePayload,
                page: 1,
                pageSize: 10,
                sort: '',
            });
        }
        ref.current = true;
    }, [audienceStatus, dispatch]);

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        setAudiencePayload({
            ...audiencePayload,
            sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
        });
    };

    return (
        <>
            <AudienceTable
                audiences={audiences}
                pageInfo={audiences}
                audiencePayload={audiencePayload}
                setAudiencePayload={setAudiencePayload}
                sortDirection={sortDirection}
                sortBy={sortBy}
                handleSort={(_sortBy, _sortDirection) =>
                    setSorting(_sortBy, _sortDirection)
                }
            />
        </>
    );
};

export { AudienceManagementTab };
