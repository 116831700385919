import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    DialogTitle,
    FormControl,
    Divider,
    Box,
    Typography,
    Alert,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';

import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { dialogStyles } from 'GeminiViewerComponent/components/Procedure/styles';
import {
    fetchAllAssets,
    selectAssetPage,
    selectAssetsLoadingStatus,
} from '_features/assets/assetsTableSlice';
import { SelectionAutocomplete } from 'components/_Autocomplete/SelectionAutocomplete';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    copyZoneToAsset,
    setTableZoneStatus,
} from '_features/zones/zonesTableSlice';
import { snackbarHandler } from '_helpers';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';

const DuplicateZonesModal = ({
    open,
    handleClose,
    selectedItems,
    setSelectedItems,
    currentAssetId,
    orbitZones = [],
}) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useSelector(selectActiveTheme);
    const classes = dialogStyles(theme);
    const allOrbitZones = orbitZones.length === selectedItems.length;

    const handleSubmit = async (values) => {
        let assetIds = values.assetIds;
        if (values.assetIds.length <= 0) {
            assetIds = [currentAssetId];
        }
        const selectedZones = selectedItems.filter(
            (value) => !orbitZones.includes(value)
        );
        dispatch(activateLoading());
        try {
            const resultAction = await dispatch(
                copyZoneToAsset({
                    zone_ids: selectedZones,
                    asset_ids: assetIds,
                })
            );
            const { message, variant } = snackbarHandler(
                resultAction.meta.requestStatus,
                'Duplicate Zones'
            );
            enqueueSnackbar(message, {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: variant,
            });
            setSelectedItems([]);
            dispatch(setTableZoneStatus(LoadingStatus.Idle));
            dispatch(deactivateLoading());
        } catch (error) {
            dispatch(deactivateLoading());
            enqueueSnackbar(
                error.message !== 'undefined' ? error.message : 'Create Failed',
                {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'error',
                }
            );
        }
        handleClose();
    };

    const warningMessages = () => {
        if (allOrbitZones) {
            return (
                <Alert
                    severity="error"
                    sx={{ fontWeight: 600, marginBottom: '1rem' }}
                >
                    Zones that are part of an orbit sequence may not be copied.
                </Alert>
            );
        } else if (orbitZones.length > 0) {
            return (
                <>
                    <Alert
                        severity="warning"
                        sx={{
                            fontWeight: 600,
                            marginBottom: '1rem',
                        }}
                    >
                        When copying to a different asset, navigation hotspots
                        will be reset to the zone they are currently in.
                    </Alert>
                    <Alert
                        severity="error"
                        sx={{
                            fontWeight: 600,
                            marginBottom: '1rem',
                        }}
                    >
                        Some selected zones are part of an orbit sequence and
                        cannot be copied. Do you want to proceed with copying
                        the other zones?
                    </Alert>
                </>
            );
        } else {
            return (
                <Alert
                    severity="warning"
                    sx={{
                        fontWeight: 600,
                        marginBottom: '1rem',
                    }}
                >
                    When copying to a different asset, navigation hotspots will
                    be reset to the zone they are currently in.
                </Alert>
            );
        }
    };

    return (
        <Dialog open={open} fullWidth maxWidth={'sm'} onClose={handleClose}>
            <Formik
                initialValues={{
                    assetIds: [],
                }}
                onSubmit={handleSubmit}
            >
                <Form>
                    <DialogTitle>Copy Zones</DialogTitle>
                    <Divider />
                    <DialogContent>
                        {warningMessages()}
                        <Box display="flex" flexDirection="column" gap="10px">
                            <FormControl>
                                <Field name={'assetIds'}>
                                    {({
                                        field: { value },
                                        form: { setFieldValue },
                                    }) => (
                                        <SelectionAutocomplete
                                            title="Asset"
                                            placeholder="Asset"
                                            keyProperty="asset_id"
                                            nameProperty="display_name"
                                            entityIds={value}
                                            setEntityIds={setFieldValue}
                                            entitySelector={selectAssetPage}
                                            entityStatusSelector={
                                                selectAssetsLoadingStatus
                                            }
                                            readOnly={allOrbitZones}
                                            fetchEntityPage={fetchAllAssets}
                                            formField={'assetIds'}
                                            fetchParams={{
                                                sort: 'display_name',
                                            }}
                                        />
                                    )}
                                </Field>
                            </FormControl>
                        </Box>
                        {!allOrbitZones && (
                            <Typography variant="body2" paddingBottom="5px">
                                <span style={{ fontWeight: '700' }}>
                                    Note:{' '}
                                </span>
                                Select one or more destination assets. If no
                                assets are selected the zone(s) will be
                                duplicated in the current asset.
                            </Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="reset"
                            className={classes.cancel}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            disabled={allOrbitZones}
                            className={classes.submit}
                        >
                            Copy
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
};

export { DuplicateZonesModal };
