/* eslint-disable no-empty-pattern */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SavingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import XAPI from '@xapi/xapi';
import { completionService } from '../services/completionSkill.service';

var initialState = {
    lrs_creds: null,
    lrs_actor: null,
    client: null,
    asset: null,
    cached_statements: {},
    lrs_status: 'Not Initiated',
    saveStatus: 'no calls',
    ectlURL: null,
    ectlToken: null,
};

function createStatement({
    state,
    LrsVerb,
    response,
    extensions,
    zoneId,
    itemId,
    linkId,
    contentId,
    timestamp,
}) {
    const lrs_actor = state.xAPI.lrs_actor;
    let gZone,
        gItem,
        gLink,
        gContent = (undefined, undefined, undefined, undefined);
    state.asset.activeAsset.zones.forEach((zone) => {
        if (!!zoneId && zone.zone_id == zoneId) {
            gZone = {
                id: zone.zone_id,
                name: zone.display_name,
                audience_ids: zone.audience_ids,
            };
        }

        zone.items.forEach((item) => {
            if (
                !!itemId &&
                itemId == item.item_id &&
                (!zoneId || zone.zone_id == zoneId)
            ) {
                gItem = {
                    id: item.item_id,
                    name: item.display_name,
                    zone_id: zone.zone_id,
                    audience_ids: item.audience_ids,
                };
            }

            item.links.forEach((link) => {
                if (
                    !!linkId &&
                    linkId == link.link_id &&
                    (!itemId || item.item_id == itemId) &&
                    (!zoneId || zone.zone_id == zoneId)
                ) {
                    gLink = {
                        id: link.link_id,
                        name: link.display_name,
                        item_id: item.item_id,
                        zone_id: zone.zone_id,
                        type_id: link.link_type.link_type_id,
                        type_name: link.link_type.display_name,
                        audience_ids: link.audience_ids,
                    };
                }

                if (
                    !!contentId &&
                    link.content.content_id == contentId &&
                    (!linkId || link.link_id == linkId) &&
                    (!itemId || item.item_id == itemId) &&
                    (!zoneId || zone.zone_id == zoneId)
                ) {
                    gContent = {
                        id: link.content.content_id,
                        name: link.content.display_name,
                        link_id: link.link_id,
                        item_id: item.item_id,
                        zone_id: zone.zone_id,
                        type_id: link.content.content_type?.content_type_id,
                        type_name: link.content.content_type?.display_name,
                        version_id: link.content_version.content_version_id,
                        version: link.content_version.version,
                        url: link.content_version.url,
                    };
                }
            });
        });
    });

    const stmt = {
        actor: {
            name: lrs_actor.name,
            account: {
                homePage: `http://geminiplus.io/xapi/user/${lrs_actor.id}`,
                name: lrs_actor.name,
            },
        },
        verb: { id: LrsVerb.id, display: { 'en-US': LrsVerb.display } },
        object: {
            objectType: 'Activity',
            id: `http://geminiplus.io/asset/${state.xAPI.asset.id}`,
        },
        context: {
            extensions: {
                'https://geminiplus.io/data': {
                    client: state.xAPI.client,
                    asset: state.xAPI.asset,
                    zone: gZone,
                    item: gItem,
                    link: gLink,
                    content: gContent,
                    user: lrs_actor,
                },
            },
        },
    };

    var resultObj = {
        success: true,
        completion: true,
        response: '',
        extensions: {},
    };
    if (response) {
        let responseStr = response;
        if (typeof response === 'object') {
            responseStr = JSON.stringify(response);
        }
        resultObj = { ...resultObj, ...{ response: responseStr } };
    }

    if (extensions) {
        resultObj = {
            ...resultObj,
            ...{
                extensions: {
                    'http://geminiplus.io/xapi/question_responses': extensions,
                },
            },
        };
    }

    stmt.result = resultObj;

    if (timestamp) {
        stmt.timestamp = timestamp;
    }

    return stmt;
}

export const saveCompletion = createAsyncThunk(
    'xAPI/saveCompletion',
    async (params) => {
        const response = await completionService.create(
            params?.url,
            {
                skill_id: params?.skill_id,
                dod_id: params?.dod_id,
                completion_time: params?.completion_time,
            },
            params?.api_token
        );
        return response;
    }
);
export const saveStatement = createAsyncThunk(
    'xAPI/saveStatement',
    async ({}, { getState }) => {
        let state = getState();

        if (state.xAPI.lrs_status === 'Ready') {
            let successfulRequestIds = [];
            const lrsCreds = state.xAPI.lrs_creds;
            const xApiClient = new XAPI({
                endpoint: lrsCreds.endpoint,
                auth: lrsCreds.auth,
            });

            for (var cachedStmtKey in state.xAPI.cached_statements) {
                try {
                    xApiClient.sendStatement({
                        statement: createStatement({
                            state,
                            ...state.xAPI.cached_statements[cachedStmtKey],
                        }),
                    });
                    successfulRequestIds.push(cachedStmtKey);
                } catch (e) {
                    console.log(e);
                }
            }

            return successfulRequestIds;
        } else if (state.xAPI.lrs_status === 'Not Initiated') {
            console.log(
                'xAPI has not been init or is not ready. Statement has been cached for next submit'
            );
        } else {
            console.log(
                'xAPI is not ready for calls. Statement has been cached for next submit'
            );
        }
    }
);

const xapiSlice = createSlice({
    name: 'xAPI',
    initialState,
    reducers: {
        setECTLUrl: (state, action) => {
            state.ectlURL = action.payload?.ectlURL;
            state.ectlToken = action.payload?.ectlToken;
        },
        setupXapi: (state, action) => {
            const safeSet = action.payload?.IsSafeSet ?? false;

            if (
                !!action.payload.LrsCredentials &&
                !!action.payload.LrsCredentials?.endpoint &&
                !!action.payload.LrsCredentials?.client_key &&
                !!action.payload.LrsCredentials?.client_secret
            ) {
                if (!safeSet || state.lrs_creds == null) {
                    state.lrs_creds = {
                        endpoint: action.payload.LrsCredentials.endpoint,
                        auth: XAPI.toBasicAuth(
                            action.payload.LrsCredentials.client_key,
                            action.payload.LrsCredentials.client_secret
                        ),
                    };
                }
            }

            if (
                !!action.payload.LrsActor &&
                action.payload.LrsActor?.id &&
                action.payload.LrsActor?.name
            ) {
                if (!safeSet || state.lrs_actor == null) {
                    state.lrs_actor = action.payload.LrsActor;
                }
            }

            if (
                !!action.payload.GeminiClient &&
                !!action.payload.GeminiClient?.id &&
                !!action.payload.GeminiClient?.name
            ) {
                if (!safeSet || state.client == null) {
                    state.client = action.payload.GeminiClient;
                }
            }

            if (
                !!action.payload.GeminiAsset &&
                action.payload.GeminiAsset?.id &&
                action.payload.GeminiAsset?.type_id &&
                action.payload.GeminiAsset?.name
            ) {
                if (!safeSet || state.asset == null) {
                    state.asset = action.payload.GeminiAsset;
                }
            }

            if (!state.lrs_creds?.endpoint || !state.lrs_creds?.auth) {
                console.log('Missing LRS Credentials');
                state.lrs_status = 'Missing LRS Credentials';
            } else if (!state.lrs_actor?.id || !state.lrs_actor?.name) {
                console.log('Missing LRS Actor');
                state.lrs_status = 'Missing LRS Actor';
            } else if (state.lrs_status !== 'Ready') {
                state.lrs_status = 'Ready';
                console.log('LRS Ready');
            } else {
                console.log(
                    `setupXapi hit else. lrs_status = ${state.lrs_status}`
                );
            }
        },
    },
    extraReducers: {
        [saveStatement.pending]: (state, { meta }) => {
            state.cached_statements[meta.requestId] = {
                ...meta.arg,
                timestamp: new Date().toISOString(),
            };
            state.saveStatus = 'saving';
        },
        [saveStatement.fulfilled]: (state, action) => {
            for (var successfulRequestIds in action.payload) {
                delete state.cached_statements[
                    action.payload[successfulRequestIds]
                ];
            }
            state.saveStatus = 'success';
        },
        [saveStatement.rejected]: (state, action) => {
            state.saveStatus = SavingStatus.Failed;
        },
    },
});

export const getLrsStatus = (state) => state.xAPI.lrs_status;
export const getSaveStatus = (state) => state.xAPI.saveStatus;
export const getECTLUrl = (state) => state.xAPI.ectlURL;
export const getECTLToken = (state) => state.xAPI.ectlToken;
export const { setupXapi, setECTLUrl } = xapiSlice.actions;

export default xapiSlice.reducer;
