import { config } from 'gemini-config';
import {
    fetchByKey,
    setByKey,
} from 'GeminiViewerComponent/_helpers/cacheStore';
import {
    createProgressParams,
    FormDataUtils,
    getNetworkService,
    prefixUrl,
} from 'GeminiViewerComponent/_helpers';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/tag`;

const mapTag = (tag, s3Prefix, cacheTag = null) => {
    if (tag?.image_url) {
        tag.image_url = prefixUrl(tag.image_url, s3Prefix);
    }
    if (cacheTag && cacheTag?.tag_usage) {
        return { ...tag, tag_usage: cacheTag.tag_usage };
    } else {
        return tag;
    }
};

const mapTags = (tags, s3Prefix) => {
    return tags.map((tag) => {
        return mapTag(tag, s3Prefix);
    });
};

const filterAndSortTags = (data, search, sort) => {
    if (data) {
        let filterData = data;
        if (search) {
            filterData = filterData.filter(
                (tag) =>
                    tag.name
                        .trim()
                        .toLowerCase()
                        .indexOf(search.trim().toLowerCase()) !== -1
            );
        }
        if (sort) {
            filterData = sortTags(filterData, sort);
        }
        return filterData;
    }
    return data;
};

const sortTags = (data, sort) => {
    if (data) {
        let filterData = data;
        if (sort) {
            let order = sort.trim()[0] === '-' ? 'DESC' : 'ASC';
            if (order === 'DESC') {
                sort = sort.substring(1);
            }
            filterData = filterData.sort((a, b) => {
                if (order === 'DESC') {
                    if (b[sort] < a[sort]) return -1;
                    if (b[sort] > a[sort]) return 1;
                } else {
                    if (a[sort] < b[sort]) return -1;
                    if (a[sort] > b[sort]) return 1;
                }
                return 0;
            });
        }
        return filterData;
    }
    return data;
};

const getTags = async (searchString = '', sort = '', s3Prefix = '') => {
    const user = JSON.parse(localStorage.getItem('user'));

    var response = fetchByKey(`tags_${user.user_id}`);
    if (response) {
        return {
            pageInfo: {},
            tags: filterAndSortTags(response, searchString, sort),
        };
    } else {
        try {
            const response = await networkService.get(
                `${baseUrl}/client/${user.client_id}`
            );
            response.data = mapTags(response?.data, s3Prefix);
            if (sort) {
                response.data = sortTags(response.data, sort);
            }
            setByKey(`tags_${user.user_id}`, response.data);
            const pageInfo = response.headers['x-pagination'];
            return {
                pageInfo: pageInfo,
                tags: filterAndSortTags(response?.data, searchString),
            };
        } catch (e) {
            return Promise.reject(e.error);
        }
    }
};

const create = async (params, s3Prefix = '') => {
    const user = JSON.parse(localStorage.getItem('user'));
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [
        ['name', params.name],
        ['client_id', user.client_id],
        ['image', params.image],
    ]);
    let progressParams = createProgressParams(params);
    try {
        const response = await networkService.postMultiFormData(
            baseUrl,
            formData,
            progressParams
        );

        if (!response.error) {
            // Update cache
            let tagsCache = fetchByKey(`tags_${user.user_id}`);
            tagsCache.push({
                ...mapTag(response.data, s3Prefix),
                tag_usage: {},
            });

            tagsCache = sortTags(tagsCache, 'name');

            setByKey(`tags_${user.user_id}`, tagsCache);
        }

        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const update = async (params, s3Prefix = '') => {
    const user = JSON.parse(localStorage.getItem('user'));
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [
        ['name', params.name],
        ['image', params.image],
        ['remove_existing_image', params.remove_existing_image],
    ]);
    let progressParams = createProgressParams(params);
    try {
        const response = await networkService.putMultiFormData(
            `${baseUrl}/${params.tag_id}`,
            formData,
            progressParams
        );

        if (!response.error) {
            // Update cache
            let tagsCache = fetchByKey(`tags_${user.user_id}`);
            tagsCache = tagsCache.map((tag) => {
                if (tag.tag_id === params.tag_id) {
                    return mapTag(response.data, s3Prefix, tag);
                }
                return tag;
            });
            tagsCache = sortTags(tagsCache, 'name');
            setByKey(`tags_${user.user_id}`, tagsCache);
        }

        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _delete = async (tag_id) => {
    try {
        const response = await networkService.delete(`${baseUrl}/${tag_id}`);
        if (!response.error) {
            // Update cache
            const user = JSON.parse(localStorage.getItem('user'));
            var tagsCache = fetchByKey(`tags_${user.user_id}`);
            tagsCache = tagsCache.filter((tag) => tag.tag_id !== tag_id);
            setByKey(`tags_${user.user_id}`, tagsCache);
        }
        return response;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getById = async (id, s3Prefix = '') => {
    try {
        const response = await networkService.get(`${baseUrl}/${id}`);
        return mapTag(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const deleteArray = async (ids) => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        const query = ids.join('&ids=');
        const response = await networkService.delete(
            `${baseUrl}/?client_id=${user.client_id}&ids=${query}`
        );
        if (!response.error) {
            // Update cache
            var tagsCache = fetchByKey(`tags_${user.user_id}`);
            tagsCache = tagsCache.filter((tag) => !ids.includes(tag.tag_id));
            setByKey(`tags_${user.user_id}`, tagsCache);
        }

        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getTagUsageById = async (tagId) => {
    try {
        const response = await networkService.get(`${baseUrl}/usage/${tagId}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const tagsService = {
    getTags,
    create,
    update,
    delete: _delete,
    deleteArray,
    getById,
    getTagUsageById,
};
