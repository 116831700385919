import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { categoryService } from '_features/_services';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';

const initialState = {
    allCategories: [],
    category: {},
    categoryStatus: LoadingStatus.Idle,
    loadedCategoryMap: {},
    categoryPageInfo: {},
};

export const fetchAllCategories = createAsyncThunk(
    'category/fetchCategoriesPage',
    async (params) =>
        await categoryService.getAll(
            params.page,
            params.pageSize,
            params.searchString,
            params.sort,
            params.asset_type_id
        )
);

export const fetchCategoryById = createAsyncThunk(
    'category/fetchCategoryById',
    async (categoryId) => {
        const response = await categoryService.getById(categoryId);
        response.id = categoryId;
        return response;
    }
);

export const createCategory = createAsyncThunk(
    'category/createCategory',
    async (params) => await categoryService.create(params)
);

export const updateCategory = createAsyncThunk(
    'category/updateCategory',
    async (params) => await categoryService.update(params)
);

export const deleteCategory = createAsyncThunk(
    'category/deleteCategory',
    async (id) => {
        const response = await categoryService.delete(id);
        response.id = id;
        return response;
    }
);

export const deleteCategoryArray = createAsyncThunk(
    'category/deleteCategoryArray',
    async (categoryIds) => {
        const response = await categoryService.deleteArray(categoryIds);
        response.ids = categoryIds;
        return response;
    }
);

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        resetCategoryState: (state) => initialState,
        resetCategoryStatus: (state) => {
            state.categoryStatus = LoadingStatus.Idle;
        },
    },
    extraReducers: {
        [fetchAllCategories.pending]: (state, action) => {
            state.categoryStatus = LoadingStatus.Loading;
        },
        [fetchAllCategories.fulfilled]: (state, action) => {
            state.categoryStatus = LoadingStatus.Loaded;
            state.categoryPageInfo = action.payload.pageInfo;
            state.allCategories = action.payload.categories;

            const startIndex =
                (action.meta.arg.page - 1) * action.meta.arg.pageSize;
            const stopIndex = startIndex + state.allCategories.length - 1;
            const requestedStopIndex =
                startIndex + action.meta.arg.pageSize - 1;

            state.loadedCategoryMap = {};

            for (var i = startIndex; i <= stopIndex; i++) {
                state.loadedCategoryMap[i] = LoadingStatus.Loaded;
            }

            // In case we didn't load as many as requested
            for (var j = stopIndex + 1; j <= requestedStopIndex; j++) {
                delete state.loadedCategoryMap[j];
            }
        },
        [fetchAllCategories.rejected]: (state, action) => {
            state.categoryStatus = LoadingStatus.Failed;
            state.loadedCategoryMap = {};
        },
        [fetchCategoryById.pending]: (state, action) => {
            state.categoryStatus = LoadingStatus.Loading;
        },
        [fetchCategoryById.fulfilled]: (state, action) => {
            state.category = action.payload;
        },
        [createCategory.fulfilled]: (state, action) => {
            state.categoryStatus = LoadingStatus.Idle;
        },
        [updateCategory.fulfilled]: (state, action) => {
            state.categoryStatus = LoadingStatus.Idle;
        },
        [deleteCategory.fulfilled]: (state, action) => {
            state.categoryStatus = LoadingStatus.Idle;
        },
        [deleteCategoryArray.fulfilled]: (state, action) => {
            state.categoryStatus = LoadingStatus.Idle;
        },
    },
});

export const getAllCategories = (state) => state.category.allCategories;
export const selectCategory = (state) => state.category.category;
export const getCategoryStatus = (state) => state.category.categoryStatus;
export const getCategoryPageInfo = (state) => state.category.categoryPageInfo;

export const { resetCategoryState, resetCategoryStatus } =
    categorySlice.actions;

export default categorySlice.reducer;
