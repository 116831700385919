import { ContentTypes } from '_helpers';

const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
};

const handleFileExtension = (file, setFieldValue) => {
    const re = /(?:\.([^.]+))?$/;
    const extension = re.exec(file?.name)[1];
    const ext = extension.replace(/^.{1}/g, extension[0]);
    setFieldValue(
        'content_type_id',
        ContentTypes[
            ext.charAt(0).toUpperCase() + ext.substring(1).toLowerCase()
        ]?.id
    );
};

export { blobToBase64, handleFileExtension };
