import React, { useCallback } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useFormikContext } from 'formik';

import { getAllProcedureVariables } from 'GeminiViewerComponent/components/Procedure/_features/procedureSlice';
import { FIELD_TYPES } from '../../_helpers/constant';

const FieldDuplicateDialog = ({
    openDuplicateConfirmDialog,
    closeDuplicateConfirmDialog,
    unsavedVariables,
    setUnsavedVariables,
    nodes,
    duplicateVariables,
    procedureNodes,
    setSortedNodes,
    selectedDuplicateField,
    setOpenEditFieldForm,
    setActiveField,
    setTreeState,
}) => {
    const { setFieldValue } = useFormikContext();
    const DEFAULT_FIELD_STATE = {
        id: uuidv4(),
        display_name: '',
        action: 'create',
        has_group: false,
    };
    const activeFieldName = FIELD_TYPES.find(
        (field) => field.id === selectedDuplicateField?.type
    )?.display_name;

    const addField = (tree, field) => {
        tree.items[field.id] = {
            id: field.id,
            children: [],
            hasChildren:
                field.type === 'group' || field.type === 'table' ? false : true,
            isExpanded:
                field.type === 'group' || field.type === 'table' ? true : false,
            isChildrenLoading: false,
            data: field,
        };
        if (field.type === 'group' || field.type === 'table') {
            const group = tree.items[field.id];
            group.hasChildren = false;
            field?.fields?.forEach((fld) => {
                group.children.push(fld.id);
                addField(tree, fld);
            });
        }
    };

    const fieldsToTree = (fields) => {
        var tree = {
            rootId: 'root',
            items: {
                root: {
                    id: 'root',
                    children: [],
                    hasChildren: false,
                    isExpanded: true,
                    isChildrenLoading: false,
                },
            },
        };

        fields?.forEach((field) => {
            addField(tree, field);
            tree.items.root.children.push(field.id);
        });

        return tree;
    };

    const updateNonGroupFormField = (node, allVariables) => {
        allVariables = [
            ...(Array.isArray(allVariables)
                ? allVariables.map((vr) => vr?.name)
                : []),
            ...(Array.isArray(unsavedVariables)
                ? unsavedVariables.map((vr) => vr?.name)
                : []),
            ...(Array.isArray(duplicateVariables.current)
                ? duplicateVariables.current.map((vr) => vr?.name)
                : []),
        ];
        let isUniqueVariable = false;
        let suffixVariableCount = 1;
        let newId = uuidv4();
        let duplicateNode = { ...node, id: newId };
        if (node?.variable) {
            while (isUniqueVariable === false) {
                if (
                    !allVariables.includes(
                        `${node?.variable}_${suffixVariableCount}`
                    )
                ) {
                    let newUnsavedVar = {
                        id: newId,
                        name: `${node?.variable}_${suffixVariableCount}`,
                    };
                    duplicateNode = {
                        ...duplicateNode,
                        variable: newUnsavedVar.name,
                    };
                    duplicateVariables.current =
                        duplicateVariables.current.length > 0
                            ? [...duplicateVariables.current, newUnsavedVar]
                            : [newUnsavedVar];
                    isUniqueVariable = true;
                } else {
                    ++suffixVariableCount;
                }
            }
        }
        return duplicateNode;
    };

    const setDuplicateNode = (nodes, node, duplicate) => {
        if (!Array.isArray(nodes) || nodes.length <= 0) {
            return [];
        }
        let isAdded = false;
        let rootNodes = [];
        nodes?.forEach((nd) => {
            if (isAdded) {
                rootNodes = [...rootNodes, nd];
            } else {
                if (nd.id === node.id) {
                    rootNodes = [...rootNodes, node, duplicate];
                    isAdded = true;
                } else if (nd.type === 'group' || nd.type === 'table') {
                    let ndFields = [];
                    nd?.fields?.forEach((field) => {
                        if (isAdded) {
                            ndFields = [...ndFields, field];
                        } else {
                            if (field.id === node.id) {
                                ndFields = [...ndFields, node, duplicate];
                                isAdded = true;
                            } else {
                                ndFields = [...ndFields, field];
                            }
                        }
                    });
                    rootNodes = [...rootNodes, { ...nd, fields: ndFields }];
                } else {
                    rootNodes = [...rootNodes, nd];
                }
            }
        });
        return rootNodes;
    };

    const handleNodeDuplicate = useCallback(async () => {
        let node = selectedDuplicateField;
        let allVariables = await getAllProcedureVariables(procedureNodes);
        let finalNodes = [];
        let duplicateNode = updateNonGroupFormField(node, allVariables);
        if (node.type === 'group' || node.type === 'table') {
            if (node?.fields?.length > 0) {
                let updatedFields = node?.fields?.map((nd) =>
                    updateNonGroupFormField(nd, allVariables)
                );
                duplicateNode = { ...duplicateNode, fields: updatedFields };
            }
        }
        finalNodes = await setDuplicateNode(nodes, node, duplicateNode);
        setSortedNodes(finalNodes);
        setOpenEditFieldForm(false);
        setFieldValue('fields', finalNodes);
        setActiveField(DEFAULT_FIELD_STATE);
        node = { ...node, fields: finalNodes };
        setTreeState({ tree: fieldsToTree(node.fields) });
        setUnsavedVariables(
            unsavedVariables.length > 0
                ? [...unsavedVariables, ...duplicateVariables.current]
                : [...duplicateVariables.current]
        );
        duplicateVariables.current = [];
        closeDuplicateConfirmDialog();
    }, [procedureNodes, nodes, unsavedVariables, selectedDuplicateField]);

    return (
        <Dialog
            open={openDuplicateConfirmDialog}
            onClose={closeDuplicateConfirmDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {`Duplicate ${activeFieldName} Field`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`Are you sure you want to duplicate this Field?`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDuplicateConfirmDialog} autoFocus>
                    No
                </Button>
                <Button variant="outlined" onClick={handleNodeDuplicate}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { FieldDuplicateDialog };
