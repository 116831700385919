import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { makeStyles } from '@mui/styles';
import { makeFormStyles } from 'forms/styles';
import { QueryBuilder } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import DirtyProcedure from '../DirtyProcedure';
import SaveNodeDataDialog from '../SaveNodeDataDialog';
import { isEqual } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { setProcedureDirty } from '_features/procedures/proceduresSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { getQueryBuilderFieldsData } from 'GeminiViewerComponent/components/Procedure/_features/procedureSlice';
import {
    CommonNodeFormFIelds,
    ExcludeFromNavigationField,
    NodeCategoryField,
} from '../CommonNodeFormFIelds';

const makeNodeFormStyles = makeStyles((theme) => ({
    container: {
        padding: '20px',
        width: '100%',
        height: '100%',
    },
    form: {
        display: 'flex',
        width: '100%',
        height: 'auto',
        padding: '10px',
        border: '1px solid',
    },
    formBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        width: '100%',
    },
    submitButtonRow: {
        marginTop: 'auto',
        alignSelf: 'end',
        display: 'flex',
        gap: '5px',
    },
    contentRow: {
        cursor: 'pointer',
    },
    dialogPaper: {
        minHeight: '100%',
        maxHeight: '100%',
    },
    addButtonIcon: {
        padding: '3px !important',
        borderRadius: '25px !important',
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
    },
    rulesWrapper: {
        '& select.rule-fields': {
            width: '33%',
        },
        '& select.rule-value': {
            width: '33%',
        },
    },
}));

const LogicNodeForm = ({
    nodes,
    node,
    variables,
    onCancel,
    onSubmit,
    openSaveDataDialog,
    setOpenSaveDataDialog,
    procedureCategories,
}) => {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const nodeFormStyles = makeNodeFormStyles(theme);
    const commonFormStyles = makeFormStyles(theme);
    const [allNodes, setAllNodes] = useState(nodes);
    const [fields, setFields] = useState([]);

    const query = { combinator: 'and', rules: [] };

    const [formValues, setFormValues] = useState({
        ...node,
        logic_rules: node?.logic_rules ? node?.logic_rules : query,
    });

    useEffect(() => {
        setFormValues({
            exclude_from_navigation: true,
            ...node,
            procedureCategories: procedureCategories,
            logic_rules: node?.logic_rules ? node?.logic_rules : query,
        });
    }, [node]);

    useEffect(() => {
        setAllNodes(nodes);
    }, [nodes]);

    useEffect(() => {
        return () => {
            setFormValues({});
            dispatch(setProcedureDirty(false));
        };
    }, []);

    nodes = nodes.filter((nd) => nd.id !== node.id);

    useEffect(() => {
        async function getBuilderFields(allNodes) {
            await setFields(await getQueryBuilderFieldsData(allNodes));
        }
        getBuilderFields(allNodes);
    }, [allNodes]);

    return (
        <div className={nodeFormStyles.container}>
            <div className={nodeFormStyles.form}>
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={Yup.object({})}
                    onSubmit={async (values, { setSubmitting }) => {
                        onSubmit(values);
                    }}
                >
                    {({
                        values,
                        setFieldValue,
                        isSubmitting,
                        errors,
                        dirty,
                    }) => (
                        <Form className={nodeFormStyles.formBody}>
                            <DirtyProcedure />
                            <CommonNodeFormFIelds />
                            <NodeCategoryField />
                            <ExcludeFromNavigationField />
                            <FormControl
                                fullWidth
                                style={{ marginTop: '10px' }}
                                className={nodeFormStyles.rulesWrapper}
                            >
                                <label id="logic-rules">Rules</label>
                                <QueryBuilder
                                    fields={fields}
                                    query={values?.logic_rules}
                                    showNotToggle={true}
                                    resetOnFieldChange={true}
                                    autoSelectField={true}
                                    autoSelectOperator={true}
                                    addRuleToNewGroups={true}
                                    onQueryChange={(q) => {
                                        if (
                                            !isEqual(
                                                values?.logic_rules?.rules,
                                                q?.rules
                                            ) ||
                                            values?.logic_rules?.combinator !==
                                                q?.combinator ||
                                            values?.logic_rules?.not !== q?.not
                                        ) {
                                            setFieldValue('logic_rules', q);
                                        }
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                fullWidth
                                style={{ marginTop: '10px' }}
                            >
                                <InputLabel id="pass-target-node-id">
                                    Pass Target Node
                                </InputLabel>
                                <Select
                                    className={commonFormStyles.materialSelect}
                                    style={{ margin: 0 }}
                                    labelId="pass-target-node-id"
                                    margin="dense"
                                    id="pass_target_node_id"
                                    label="Pass Target Node"
                                    placeholder="Pass Target Node"
                                    name={`pass_target_node_id`}
                                    value={values?.pass_target_node_id?.id}
                                    onChange={(e) => {
                                        setFieldValue('pass_target_node_id', {
                                            id: e.target.value,
                                            type: 'node',
                                        });
                                    }}
                                >
                                    {nodes?.length > 0 &&
                                        nodes.map((nd) => (
                                            <MenuItem
                                                key={nd?.id}
                                                value={nd?.id}
                                            >
                                                {nd?.title} - #{nd?.id}
                                            </MenuItem>
                                        ))}
                                </Select>
                                {errors?.pass_target_node_id && (
                                    <div className={commonFormStyles.inputErr}>
                                        {errors.pass_target_node_id}
                                    </div>
                                )}
                            </FormControl>
                            <FormControl
                                fullWidth
                                style={{ marginTop: '10px' }}
                            >
                                <InputLabel id="fail-target-node-id">
                                    Fail Target Node
                                </InputLabel>
                                <Select
                                    className={commonFormStyles.materialSelect}
                                    style={{ margin: 0 }}
                                    labelId="fail-target-node-id"
                                    margin="dense"
                                    id="fail_target_node_id"
                                    label="Fail Target Node"
                                    placeholder="Fail Target Node"
                                    name={`fail_target_node_id`}
                                    value={values?.fail_target_node_id?.id}
                                    onChange={(e) => {
                                        setFieldValue('fail_target_node_id', {
                                            id: e.target.value,
                                            type: 'node',
                                        });
                                    }}
                                >
                                    {nodes?.length > 0 &&
                                        nodes.map((nd) => (
                                            <MenuItem
                                                key={nd?.id}
                                                value={nd?.id}
                                            >
                                                {nd?.title} - #{nd?.id}
                                            </MenuItem>
                                        ))}
                                </Select>
                                {errors?.fail_target_node_id && (
                                    <div className={commonFormStyles.inputErr}>
                                        {errors.fail_target_node_id}
                                    </div>
                                )}
                            </FormControl>
                            {openSaveDataDialog && (
                                <SaveNodeDataDialog
                                    openSaveDataDialog={openSaveDataDialog}
                                    setOpenSaveDataDialog={
                                        setOpenSaveDataDialog
                                    }
                                    isSubmitting={isSubmitting}
                                    onCancel={onCancel}
                                />
                            )}
                            <div className={nodeFormStyles.submitButtonRow}>
                                <button
                                    className={commonFormStyles.cancel}
                                    type="button"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        if (dirty) {
                                            setOpenSaveDataDialog(true);
                                        } else {
                                            onCancel();
                                        }
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={commonFormStyles.submit}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};
export default LogicNodeForm;
