import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { navItemStyles } from '../NavItem/styles';
import { FiArrowRight } from 'react-icons/fi';

export default function NavItemModal({ icon, text, onClick }) {
    const classes = navItemStyles();
    const isCurrentLoc = false;

    return (
        <Tooltip title={text} placement="right">
            <ListItem button key={text} onClick={onClick}>
                <ListItemIcon
                    className={clsx(classes.navLinkIcon, {
                        [classes.activeLink]: isCurrentLoc,
                    })}
                >
                    {icon}
                </ListItemIcon>
                <ListItemText
                    primary={text}
                    className={clsx(classes.navLink)}
                />
                {isCurrentLoc ? (
                    <FiArrowRight className={classes.activeLink} />
                ) : null}
            </ListItem>
        </Tooltip>
    );
}
