import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    viewPanel: false,
    object: null,
    user_id: null,
    content_id: null,
    level: '',
};

const editPanelSlice = createSlice({
    name: 'editPanel',
    initialState,
    reducers: {
        showEditPanel: (state, action) => {
            return action.payload;
        },
        closePanel: (state) => initialState,
    },
});

export const selectPanelInfo = (state) => state.editPanel;

export const { showEditPanel, closePanel } = editPanelSlice.actions;

export default editPanelSlice.reducer;
