import { IoMusicalNotesSharp } from 'react-icons/io5';

import { ReactComponent as ContentDocument } from 'GeminiViewerComponent/assets/content_document.svg';
import { ReactComponent as ContentImage } from 'GeminiViewerComponent/assets/content_image.svg';
import { ReactComponent as ContentLearning } from 'GeminiViewerComponent/assets/content_learning.svg';
import { ReactComponent as ContentSystem } from 'GeminiViewerComponent/assets/content_system.svg';
import { ReactComponent as ContentVideo } from 'GeminiViewerComponent/assets/content_video.svg';
import { ReactComponent as ProcessIcon } from 'GeminiViewerComponent/assets/process.svg';
import {
    MdLocalLibrary,
    MdPsychology,
    MdViewInAr,
    MdWysiwyg,
    MdDvr,
    MdDataObject,
} from 'react-icons/md';

export default function getLinkIcon(linkTypeId) {
    switch (Number(linkTypeId)) {
        case 1:
            return <ContentDocument width="20px" height="20px" />;
        case 2:
            return <ContentVideo width="20px" height="20px" />;
        case 3:
            return <ContentSystem width="20px" height="20px" />;
        case 4:
            return (
                <IoMusicalNotesSharp
                    className="react-icon"
                    width="20px"
                    height="20px"
                />
            );
        case 5:
            return <ContentLearning width="20px" height="20px" />;
        case 6:
            return (
                <MdPsychology
                    className="react-icon"
                    width="20px"
                    height="20px"
                />
            );
        case 7:
            return <ContentImage width="20px" height="20px" />;
        case 8:
            return (
                <MdDataObject
                    className="react-icon"
                    width="20px"
                    height="20px"
                />
            );
        case 9:
            return (
                <MdLocalLibrary
                    className="react-icon"
                    width="20px"
                    height="20px"
                />
            );
        case 10:
            return (
                <MdWysiwyg className="react-icon" width="20px" height="20px" />
            );
        case 11:
            return <ProcessIcon width="20px" height="20px" />;
        case 12:
            return (
                <MdViewInAr className="react-icon" width="20px" height="20px" />
            );
        case 13:
            return <MdDvr className="react-icon" width="20px" height="20px" />;
    }
}
