import { useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import NodeTypeButton from '../NodeTypeButton';
import { makeNodeManagerStyles } from './style';
import { getProcedureDirty } from '_features/procedures/proceduresSlice';
import {
    MdAccountTree,
    MdContentPaste,
    MdSchema,
    MdVerticalSplit,
} from 'react-icons/md';

const NodeManagerOptions = ({
    onAddNode,
    clickedAddButton,
    setClickedAddButton,
    onEditProperties,
    compactMode,
    setCompactMode,
    showBranches,
    setShowBranches,
    showNodeFlow,
    setShowNodeFlow,
    showTwoPanelMode,
    setShowTwoPanelMode,
    setOpenSaveDataDialog,
    setOpenNodeEditForm,
    setOpenCategoryDialog,
}) => {
    const theme = useSelector(selectActiveTheme);
    const isProcedureDirty = useSelector(getProcedureDirty);
    const nodeTableStyles = makeNodeManagerStyles(theme);
    const [openDialog, setOpenDialog] = useState(
        clickedAddButton ? true : false
    );

    return (
        <div
            style={{
                display: 'flex',
                gap: '5px',
                flex: '0',
                marginBottom: '5px',
            }}
        >
            <button
                className={nodeTableStyles.button}
                onClick={() => setOpenDialog(!openDialog)}
            >
                New Node
            </button>
            <NodeTypeButton
                handleButtonSelection={onAddNode}
                clickedAddButton={clickedAddButton}
                setClickedAddButton={setClickedAddButton}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
            />
            <button
                className={nodeTableStyles.button}
                onClick={onEditProperties}
            >
                Edit Properties
            </button>
            <button
                className={nodeTableStyles.button}
                onClick={() => setOpenCategoryDialog((prev) => !prev)}
            >
                Edit Categories
            </button>
            <div className={nodeTableStyles.rightButtons}>
                <Tooltip title="Toggle Content Display">
                    <Box>
                        <MdContentPaste
                            className="react-icon"
                            style={{
                                cursor: 'pointer',
                                color: `${!compactMode ? 'blue' : 'unset'}`,
                            }}
                            onClick={() => setCompactMode(!compactMode)}
                        />
                    </Box>
                </Tooltip>
                <Tooltip title="Toggle Branch Display">
                    <Box>
                        <MdAccountTree
                            className="react-icon"
                            style={{
                                cursor: 'pointer',
                                color: `${showBranches ? 'blue' : 'unset'}`,
                            }}
                            onClick={() => setShowBranches(!showBranches)}
                        />
                    </Box>
                </Tooltip>
                <Tooltip title="Toggle Node View ">
                    <Box>
                        <MdSchema
                            className="react-icon"
                            style={{
                                cursor: 'pointer',
                                color: `${showNodeFlow ? 'blue' : 'unset'}`,
                                transform: `rotate(-90deg)`,
                            }}
                            onClick={() => setShowNodeFlow(!showNodeFlow)}
                        />
                    </Box>
                </Tooltip>
                <Tooltip title="Toggle Two Panel Mode ">
                    <Box>
                        <MdVerticalSplit
                            className="react-icon"
                            style={{
                                cursor: 'pointer',
                                color: `${showTwoPanelMode ? 'blue' : 'unset'}`,
                            }}
                            onClick={() => {
                                if (
                                    isProcedureDirty === true &&
                                    showTwoPanelMode === true
                                ) {
                                    setOpenSaveDataDialog(true);
                                    return;
                                }
                                setShowTwoPanelMode(!showTwoPanelMode);
                                setOpenNodeEditForm(true);
                            }}
                        />
                    </Box>
                </Tooltip>
            </div>
        </div>
    );
};

export { NodeManagerOptions };
