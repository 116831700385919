/**
 * This slice is to manage validation types
 */
import {
    createSlice,
    createAsyncThunk,
    createSelector,
} from '@reduxjs/toolkit';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { configValueService } from '_features/_services/configValue.service';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    error: null,
    configValues: [],
    clientId: 0,
    assetId: 0,
    loadingStatus: LoadingStatus.Idle,
    savingStatus: SavingStatus.Idle,
};

export const createNewConfigValue = () => {
    return {
        config_value_id: uuidv4(),
        value: '',
        category: '',
        display_name: '',
        required: false,
        client_visible: false,
        client_obscured: false,
        valid_until_date: null,
    };
};

export const getConfigValues = createAsyncThunk(
    'configValues/getConfigValues',
    async ({ clientId, assetId }) => {
        // await new Promise((resolve) => setTimeout(resolve, 500)); // debug temp
        return await configValueService.getAll(clientId, assetId);
    }
);

export const updateConfigValues = createAsyncThunk(
    'configValues/updateConfigValues',
    async (params, { rejectWithValue }) => {
        // await new Promise((resolve) => setTimeout(resolve, 500)); // debug temp
        if (params.client_id !== 0) {
            // Set client id on each value
            params.config_values.map(
                (value) => (value.client_id = params.client_id)
            );
            params.editing_global = false;
            if (params.asset_id !== 0) {
                // Set asset id on each value
                params.config_values.map(
                    (value) => (value.asset_id = params.asset_id)
                );
            } else {
                delete params?.asset_id;
            }
        } else {
            delete params?.client_id;
            delete params?.asset_id;
            params.editing_global = true;
        }
        const response = await configValueService.updateAll(params);
        if (!response) {
            return rejectWithValue(response);
        }
        return response;
    }
);

const configValuesSlice = createSlice({
    name: 'configValues',
    initialState,
    reducers: {
        clearConfigValues: (state, action) => initialState,
        setConfigValuesClientId: (state, action) => {
            state.clientId = action.payload;
            state.assetId = 0;
        },
        setConfigValuesAssetId: (state, action) => {
            state.error = null;
            state.configValues = [];
            state.clientId = action.payload.clientId;
            state.assetId = action.payload.assetId;
        },
        setConfigAssetClient: (state, action) => {
            if (action.payload?.clientId) {
                state.clientId = action.payload.clientId;
            }

            if (action.payload?.assetId) {
                state.assetId = action.payload.assetId;
            }
        },
        setConfigValues: (state, action) => {
            state.validationTypes = action.payload;
        },
    },

    extraReducers: {
        [getConfigValues.pending]: (state) => {
            state.loadingStatus = LoadingStatus.Loading;
        },
        [getConfigValues.fulfilled]: (state, action) => {
            state.loadingStatus = LoadingStatus.Loaded;
            state.configValues = action.payload;
            state.error = null;
        },
        [getConfigValues.rejected]: (state, action) => {
            state.loadingStatus = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [updateConfigValues.pending]: (state) => {
            state.savingStatus = SavingStatus.Saving;
        },
        [updateConfigValues.fulfilled]: (state, action) => {
            state.savingStatus = SavingStatus.Saved;
            state.configValuesStatus = LoadingStatus.Idle;
            state.configValues = action.payload;
            state.error = null;
        },
        [updateConfigValues.rejected]: (state, action) => {
            state.savingStatus = SavingStatus.Failed;
            state.error = action.error.message;
        },
    },
});

export const selectConfigValuesClientId = (state) =>
    state.configValues.clientId;
export const selectConfigValuesAssetId = (state) => state.configValues.assetId;
export const selectConfigValue = (state) => state.configValues.configValues;
export const selectConfigValuesErrorMessage = (state) =>
    state.configValues.error;
export const selectConfigValuesSavingStatus = (state) =>
    state.configValues.savingStatus;
export const selectConfigValuesLoadingStatus = (state) =>
    state.configValues.loadingStatus;

const selectConfigValuesInternal = (state, configFields) => {
    return {
        configValues: state.configValues.configValues,
        configFields: configFields,
    };
    /*
    // Simulate logic on server where all ConfigFields are shown - either
    // with empty value or value from ConfigValue

    return configFields.map((configField) => {
        // See if there is matching ConfigValue
        let configValue = state.configValues.configValues.find(
            (configValue) =>
                configValue.config_field_id === configField.config_field_id
        );

        if (!configValue) {
            configValue = createNewConfigValue();
            configValue.config_field_id = configField.config_field_id;
        }

        return {
            ...configValue,
            ...{
                display_name: configField?.display_name ?? '',
                category: configField?.category ?? '',
            },
        };
    });

    return state.configValues.configValues.map((configValue) => {
        const configField = configFields.find(
            (configField) =>
                configField.config_field_id === configValue.config_field_id
        );
        return {
            ...configValue,
            ...{
                display_name: configField?.display_name ?? '',
                category: configField?.category ?? '',
            },
        };
    });
        */
};

// Have to use createSelector, otherwise returning a map of configValues
// directly results in an endless rerender loop. createSelector ensures
// that a cached result is returned if configValues and configFields have
// not changed

export const selectConfigValues = createSelector(
    selectConfigValuesInternal,
    ({ configValues, configFields }) => {
        // We need to show all ConfigFields, either
        // with empty value or value from ConfigValue

        return configFields?.map((configField) => {
            // See if there is matching ConfigValue
            let configValue = configValues?.find(
                (configValue) =>
                    configValue.config_field_id === configField.config_field_id
            );

            if (!configValue) {
                configValue = createNewConfigValue();
                configValue.config_field_id = configField.config_field_id;
                configValue.field_source = 'Global';
            }

            return {
                ...configValue,
                ...{
                    display_name: configField?.display_name ?? '',
                    category: configField?.category ?? '',
                    dirty: false,
                },
            };
        });

        // return configValues.map((configValue) => {
        //     const configField = configFields.find(
        //         (configField) =>
        //             configField.config_field_id === configValue.config_field_id
        //     );
        //     return {
        //         ...configValue,
        //         ...{
        //             display_name: configField?.display_name ?? '',
        //             category: configField?.category ?? '',
        //         },
        //     };
        // });
    }
);

export const selectConfigValuesByFields = (
    configValues,
    configFields,
    keys = []
) => {
    // We need to show all ConfigFields, either
    // with empty value or value from ConfigValue
    let selectedFields = [];
    configFields.forEach((configField) => {
        // See if there is matching ConfigValue
        let configValue = configValues.find(
            (configValue) =>
                configValue.config_field_id === configField.config_field_id
        );

        if (configValue) {
            if (keys.includes(configField?.key)) {
                selectedFields[configField?.key] = configValue.value;
            }
        }
    });
    return selectedFields;
};

export const {
    clearConfigValues,
    setConfigValuesClientId,
    setConfigValuesAssetId,
    setConfigAssetClient,
} = configValuesSlice.actions;

export default configValuesSlice.reducer;
