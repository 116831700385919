import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { MdList } from 'react-icons/md';
import ReactHlsPlayer from 'react-hls-player';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, IconButton, Typography } from '@mui/material';

import { VideoCaptions } from '../VideoCaptions';
import { linkDisplayStyles } from '../../styles';
import { Each } from 'GeminiViewerComponent/_helpers/Each';
import { contentCaptionURL } from 'GeminiViewerComponent/_helpers';
import {
    formatTime,
    timeToSeconds,
} from 'GeminiViewerComponent/_helpers/helper';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    fetchChapterById,
    resetChapterStatus,
    selectChapter,
    setChapter,
} from 'GeminiViewerComponent/_features/globals/chapterSlice';
import {
    getChapterData,
    getChapterId,
    getLinkAutoPlay,
    getLinkDisplayContent,
    getLinkDisplayData,
    getLinkDisplayVis,
    setChapterData,
} from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import { HtmlTooltip } from 'GeminiViewerComponent/components/_Tooltip';

export const DisplayVideoContent = ({
    handleLoadedMetadata,
    isChapterVideo,
    setIsChapterVideo,
    videoRef,
}) => {
    const dispatch = useDispatch(null);
    const theme = useSelector(selectActiveTheme);
    const classes = linkDisplayStyles(theme);

    const linkDisplay = useSelector(getLinkDisplayVis);
    const linkContent = useSelector(getLinkDisplayContent);
    const linkData = useSelector(getLinkDisplayData);
    const linkAutoPlay = useSelector(getLinkAutoPlay);
    const chapter_id = useSelector(getChapterId);
    const chapter_data = useSelector(getChapterData);
    const chapterData = useSelector(selectChapter);
    const [displayChapter, setDisplayChapter] = useState(true);

    const chaptersButtonOrder = useMemo(
        () =>
            linkData?.chapter_buttons_location &&
            (linkData?.chapter_buttons_location === 'Right' ||
                linkData?.chapter_buttons_location === 'Bottom')
                ? 2
                : -1,
        [linkData?.chapter_buttons_location]
    );
    const topBottomChaptersButtons = useMemo(
        () =>
            linkData?.chapter_buttons_location &&
            (linkData?.chapter_buttons_location === 'Top' ||
                linkData?.chapter_buttons_location === 'Bottom'),
        [linkData?.chapter_buttons_location]
    );

    let extension = linkContent ? linkContent.split('.').pop() : '';
    if (extension.indexOf('?') !== -1) {
        extension = extension.split('?')?.[0];
    }

    let contentURL = contentCaptionURL(linkContent);

    useEffect(() => {
        if (chapter_data) {
            setIsChapterVideo({ chapterPlayed: false, chapterCapture: true });
        }
        if (
            linkDisplay &&
            chapter_id &&
            chapterData &&
            isChapterVideo.chapterCapture &&
            typeof videoRef.current?.currentTime === 'number'
        ) {
            const timeInSeconds = timeToSeconds(chapterData?.start_time || '0');
            if (isFinite(timeInSeconds)) {
                videoRef.current.currentTime = timeInSeconds;
                videoRef.current.play();
                if (!linkAutoPlay) {
                    videoRef.current.pause();
                }
            }
        }
        if (
            linkDisplay &&
            chapter_data &&
            typeof videoRef.current?.currentTime === 'number'
        ) {
            const timeInSeconds = timeToSeconds(
                chapter_data?.start_time || '0'
            );
            if (isFinite(timeInSeconds)) {
                videoRef.current.currentTime = timeInSeconds;
                videoRef.current.play();
                if (!linkAutoPlay) {
                    videoRef.current.pause();
                }
            }
        }
        if (videoRef.current && linkAutoPlay) {
            videoRef.current.play();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        chapterData,
        linkDisplay,
        chapter_data,
        linkAutoPlay,
        isChapterVideo.chapterCapture,
    ]);

    useEffect(() => {
        if (chapter_id) {
            fetchChapter(chapter_id);
            setIsChapterVideo({ chapterPlayed: false, chapterCapture: true });
        } else {
            setIsChapterVideo({ chapterPlayed: false, chapterCapture: false });
        }
        return () => {
            dispatch(resetChapterStatus());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chapter_id]);

    useEffect(() => {
        setDisplayChapter(!linkData?.collapse_chapters);
    }, [linkData?.collapse_chapters]);

    const fetchChapter = async (chapter_id) => {
        await dispatch(activateLoading());
        await dispatch(fetchChapterById(chapter_id));
        await dispatch(deactivateLoading());
    };

    const handleTimeUpdate = useCallback(
        (e) => {
            if (chapter_id && Object.keys(chapterData || {}).length > 0) {
                if (
                    e.target.currentTime >=
                        timeToSeconds(chapterData.end_time) &&
                    !isChapterVideo.chapterPlayed
                ) {
                    videoRef.current?.pause();
                    setIsChapterVideo((prev) => ({
                        ...prev,
                        chapterPlayed: true,
                    }));
                }
            }
            if (chapter_data) {
                if (
                    e.target.currentTime >=
                        timeToSeconds(chapter_data.end_time) &&
                    !isChapterVideo.chapterPlayed
                ) {
                    videoRef.current?.pause();
                    setIsChapterVideo((prev) => ({
                        ...prev,
                        chapterPlayed: true,
                    }));
                }
            }
            const currentTime = e.target.currentTime;

            linkData?.chapters?.forEach?.((chapter, index) => {
                const startTime = timeToSeconds(chapter?.start_time);
                const endTime = timeToSeconds(chapter?.end_time);

                const button = document.querySelector(
                    `[data-chapterId="${chapter.chapter_id}"]`
                );
                if (currentTime >= startTime && currentTime <= endTime) {
                    if (button) {
                        button.classList.add('chaptersActive');
                    }
                } else {
                    if (button) {
                        button.classList.remove('chaptersActive');
                    }
                }
            });
        },

        [
            chapter_id,
            chapterData,
            chapter_data,
            linkData?.chapters,
            isChapterVideo.chapterPlayed,
            videoRef,
            setIsChapterVideo,
        ]
    );

    const handleSeekCapture = useCallback(
        (e) => {
            if (chapter_id) {
                if (
                    e.target.currentTime < timeToSeconds(chapterData.end_time)
                ) {
                    setIsChapterVideo((prev) => ({
                        ...prev,
                        chapterPlayed: false,
                    }));
                }
            }
            if (chapter_data) {
                if (
                    e.target.currentTime < timeToSeconds(chapter_data.end_time)
                ) {
                    setIsChapterVideo((prev) => ({
                        ...prev,
                        chapterPlayed: false,
                    }));
                }
            }
        },
        [chapter_id, chapter_data, chapterData.end_time, setIsChapterVideo]
    );
    const chapterButtonsStyle = useMemo(
        () => ({
            flexDirection: topBottomChaptersButtons ? 'column' : 'row',
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [linkData?.chapter_buttons_location]
    );

    const chapterButtonsWrapperStyle = useMemo(
        () => ({
            order: chaptersButtonOrder,
            flexDirection: topBottomChaptersButtons ? 'row' : 'column',
            height: topBottomChaptersButtons && '50px',
            width: topBottomChaptersButtons && '100%',
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [chaptersButtonOrder]
    );

    return (
        <div className={classes.videoContainer}>
            {extension.toLowerCase() == 'm3u8' ? (
                <>
                    {linkData?.show_chapters &&
                    linkData?.chapters &&
                    linkData?.chapters?.length > 0 ? (
                        <Box
                            display="flex"
                            gap="5px"
                            style={{ ...chapterButtonsStyle }}
                        >
                            {displayChapter && (
                                <Box
                                    className={classes.chaptersWrapper}
                                    height={videoRef?.current?.clientHeight}
                                    style={{ ...chapterButtonsWrapperStyle }}
                                >
                                    <Typography
                                        className={classes.chaptersLabel}
                                        style={{
                                            margin: topBottomChaptersButtons
                                                ? '0px 5px 0 0'
                                                : '0 0 5px',
                                            left: topBottomChaptersButtons
                                                ? '0px'
                                                : 'auto',
                                            top: topBottomChaptersButtons
                                                ? 'auto'
                                                : '0px',
                                        }}
                                    >
                                        Chapters
                                    </Typography>
                                    <Each
                                        of={[...linkData.chapters].sort(
                                            (a, b) =>
                                                a.chapter_id - b.chapter_id
                                        )}
                                        render={(data, idx) => (
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <Typography
                                                            variant="caption"
                                                            fontWeight="bold"
                                                        >
                                                            {data?.title}
                                                        </Typography>
                                                        <br />
                                                        {'(' +
                                                            formatTime(
                                                                data?.start_time
                                                            ) +
                                                            '-' +
                                                            formatTime(
                                                                data?.end_time
                                                            ) +
                                                            ')'}
                                                    </React.Fragment>
                                                }
                                                direction={
                                                    linkData?.chapter_buttons_location
                                                }
                                            >
                                                <Button
                                                    title={data?.title}
                                                    className={clsx(
                                                        classes.chaptersButton,
                                                        {
                                                            [classes.activeChapterButton]:
                                                                data?.chapter_id ===
                                                                    chapter_data?.chapter_id ||
                                                                data?.chapter_id ===
                                                                    chapterData?.chapter_id,
                                                        }
                                                    )}
                                                    data-chapterId={
                                                        data.chapter_id
                                                    }
                                                    style={{
                                                        minWidth:
                                                            topBottomChaptersButtons &&
                                                            '150px',
                                                    }}
                                                    onClick={() => {
                                                        dispatch(
                                                            setChapter(data)
                                                        );
                                                        dispatch(
                                                            setChapterData(data)
                                                        );
                                                    }}
                                                >
                                                    <Typography
                                                        className={
                                                            classes.chapterButtonText
                                                        }
                                                        style={{
                                                            WebkitLineClamp:
                                                                topBottomChaptersButtons
                                                                    ? 1
                                                                    : 2,
                                                        }}
                                                    >
                                                        {data?.title}
                                                    </Typography>
                                                </Button>
                                            </HtmlTooltip>
                                        )}
                                    />
                                </Box>
                            )}
                            <ReactHlsPlayer
                                style={{
                                    position: `${
                                        displayChapter ? 'relative' : 'absolute'
                                    }`,
                                    width: `${
                                        displayChapter &&
                                        !topBottomChaptersButtons
                                            ? 'calc(100% - 200px)'
                                            : '100%'
                                    }`,
                                    height: `${
                                        displayChapter &&
                                        topBottomChaptersButtons
                                            ? 'calc(100vh - 180px - 55px)'
                                            : 'calc(100vh - 180px)'
                                    }`,
                                }}
                                src={linkContent}
                                autoPlay={linkAutoPlay}
                                controls={true}
                                playerRef={videoRef}
                                onLoadedMetadata={handleLoadedMetadata}
                                disablePictureInPicture={true}
                                controlsList="nodownload noplaybackrate"
                                crossOrigin="anonymous"
                                onTimeUpdate={handleTimeUpdate}
                                onSeekingCapture={handleSeekCapture}
                            >
                                <VideoCaptions
                                    contentURL={contentURL}
                                    captionLanguages={
                                        linkData.caption_languages
                                    }
                                />
                            </ReactHlsPlayer>
                            <IconButton
                                className={classes.chaptersCollapseButton}
                                sx={{
                                    left: `${
                                        displayChapter &&
                                        !topBottomChaptersButtons &&
                                        chaptersButtonOrder === -1
                                            ? '200px'
                                            : '25px'
                                    }`,
                                    top: `${
                                        displayChapter &&
                                        linkData?.chapter_buttons_location ===
                                            'Top'
                                            ? '55px'
                                            : '0px'
                                    }`,
                                    backgroundColor: `${
                                        displayChapter ? '#000' : '#555555'
                                    }`,
                                    '&:hover': {
                                        backgroundColor: `${
                                            displayChapter ? '#000' : '#555555'
                                        }`,
                                    },
                                    display: 'flex',
                                }}
                                onClick={() => {
                                    setDisplayChapter(!displayChapter);
                                }}
                            >
                                <MdList className="react-icon" />
                            </IconButton>
                        </Box>
                    ) : (
                        <ReactHlsPlayer
                            src={linkContent}
                            autoPlay={linkAutoPlay}
                            controls={true}
                            playerRef={videoRef}
                            onLoadedMetadata={handleLoadedMetadata}
                            disablePictureInPicture={true}
                            controlsList="nodownload noplaybackrate"
                            crossOrigin="anonymous"
                            onTimeUpdate={handleTimeUpdate}
                            onSeekingCapture={handleSeekCapture}
                        >
                            <VideoCaptions
                                contentURL={contentURL}
                                captionLanguages={linkData.caption_languages}
                            />
                        </ReactHlsPlayer>
                    )}
                </>
            ) : extension.toLowerCase() !== 'mp4' ? (
                <div
                    style={{
                        textAlign: 'center',
                        color: 'red',
                    }}
                >
                    This video will be available once conversion is completed
                </div>
            ) : (
                <>
                    {linkData?.show_chapters &&
                    linkData?.chapters &&
                    linkData?.chapters?.length > 0 ? (
                        <Box
                            display="flex"
                            gap="5px"
                            style={{ ...chapterButtonsStyle }}
                        >
                            {displayChapter && (
                                <Box
                                    className={classes.chaptersWrapper}
                                    height={videoRef?.current?.clientHeight}
                                    style={{ ...chapterButtonsWrapperStyle }}
                                >
                                    <Typography
                                        className={classes.chaptersLabel}
                                        style={{
                                            margin: topBottomChaptersButtons
                                                ? '0px 5px 0 0'
                                                : '0 0 5px',
                                            left: topBottomChaptersButtons
                                                ? '0px'
                                                : 'auto',
                                            top: topBottomChaptersButtons
                                                ? 'auto'
                                                : '0px',
                                        }}
                                    >
                                        Chapters
                                    </Typography>
                                    <Each
                                        of={[...linkData.chapters].sort(
                                            (a, b) =>
                                                a.chapter_id - b.chapter_id
                                        )}
                                        render={(data, idx) => (
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <Typography
                                                            variant="caption"
                                                            fontWeight="bold"
                                                        >
                                                            {data?.title}
                                                        </Typography>
                                                        <br />
                                                        {'(' +
                                                            formatTime(
                                                                data?.start_time
                                                            ) +
                                                            '-' +
                                                            formatTime(
                                                                data?.end_time
                                                            ) +
                                                            ')'}
                                                    </React.Fragment>
                                                }
                                                direction={
                                                    linkData?.chapter_buttons_location
                                                }
                                            >
                                                <Button
                                                    title={data?.title}
                                                    className={clsx(
                                                        classes.chaptersButton,
                                                        {
                                                            [classes.activeChapterButton]:
                                                                data?.chapter_id ===
                                                                    chapter_data?.chapter_id ||
                                                                data?.chapter_id ===
                                                                    chapterData?.chapter_id,
                                                        }
                                                    )}
                                                    style={{
                                                        minWidth:
                                                            topBottomChaptersButtons &&
                                                            '150px',
                                                    }}
                                                    data-chapterId={
                                                        data.chapter_id
                                                    }
                                                    onClick={() => {
                                                        dispatch(
                                                            setChapter(data)
                                                        );
                                                        dispatch(
                                                            setChapterData(data)
                                                        );
                                                    }}
                                                >
                                                    <Typography
                                                        className={
                                                            classes.chapterButtonText
                                                        }
                                                        style={{
                                                            WebkitLineClamp:
                                                                topBottomChaptersButtons
                                                                    ? 1
                                                                    : 2,
                                                        }}
                                                    >
                                                        {data?.title}
                                                    </Typography>
                                                </Button>
                                            </HtmlTooltip>
                                        )}
                                    />
                                </Box>
                            )}
                            <video
                                src={linkContent}
                                autoPlay={linkAutoPlay}
                                style={{
                                    position: `${
                                        displayChapter ? 'relative' : 'absolute'
                                    }`,
                                    width: `${
                                        displayChapter &&
                                        !topBottomChaptersButtons
                                            ? 'calc(100% - 200px)'
                                            : '100%'
                                    }`,
                                    height: `${
                                        displayChapter &&
                                        topBottomChaptersButtons
                                            ? 'calc(100vh - 180px - 55px)'
                                            : 'calc(100vh - 180px)'
                                    }`,
                                }}
                                playsInline
                                controls
                                ref={videoRef}
                                onLoadedMetadata={handleLoadedMetadata}
                                disablePictureInPicture={true}
                                controlsList="nodownload noplaybackrate"
                                crossOrigin="anonymous"
                                onTimeUpdate={handleTimeUpdate}
                                onSeekingCapture={handleSeekCapture}
                            >
                                <VideoCaptions
                                    contentURL={contentURL}
                                    captionLanguages={
                                        linkData.caption_languages
                                    }
                                />
                            </video>
                            <IconButton
                                className={classes.chaptersCollapseButton}
                                sx={{
                                    left: `${
                                        displayChapter &&
                                        !topBottomChaptersButtons &&
                                        chaptersButtonOrder === -1
                                            ? '200px'
                                            : '25px'
                                    }`,
                                    top: `${
                                        displayChapter &&
                                        linkData?.chapter_buttons_location ===
                                            'Top'
                                            ? '55px'
                                            : '0px'
                                    }`,
                                    backgroundColor: `${
                                        displayChapter ? '#000' : '#555555'
                                    }`,
                                    '&:hover': {
                                        backgroundColor: `${
                                            displayChapter ? '#000' : '#555555'
                                        }`,
                                    },
                                    display: 'flex',
                                }}
                                onClick={() => {
                                    setDisplayChapter(!displayChapter);
                                }}
                            >
                                <MdList className="react-icon" />
                            </IconButton>
                        </Box>
                    ) : (
                        <video
                            src={linkContent}
                            autoPlay={linkAutoPlay}
                            playsInline
                            controls
                            ref={videoRef}
                            onLoadedMetadata={handleLoadedMetadata}
                            disablePictureInPicture={true}
                            controlsList="nodownload noplaybackrate"
                            crossOrigin="anonymous"
                            onTimeUpdate={handleTimeUpdate}
                            onSeekingCapture={handleSeekCapture}
                        >
                            <VideoCaptions
                                contentURL={contentURL}
                                captionLanguages={linkData.caption_languages}
                            />
                        </video>
                    )}
                </>
            )}
        </div>
    );
};
