import { makeStyles } from '@mui/styles';

export const accordionStyle = makeStyles((theme) => ({
    mainAccordion: {
        borderRadius: '0',
        width: '100%',
        '&.Mui-expanded': {
            margin: '0',
        },
        '& > div.MuiAccordionSummary-root': {
            backgroundColor: '#ECF0F1',
            minHeight: 'inherit',
        },
        '& > div.MuiCollapse-root': {
            width: '100%',
            position: 'absolute',
            zIndex: 3,
            backgroundColor: '#ffffff',
            border: '1px solid #cacaca',
            borderTop: 'none',
        },
        '&:before': {
            content: 'inherit',
        },
    },
    accordionSummary: {
        '& > div.MuiAccordionSummary-content': {
            overflow: 'hidden',
            position: 'relative',
            paddingRight: '1.8rem',
            margin: '12px 0 !important',
        },
        '& > div.MuiAccordionSummary-expandIconWrapper': {
            fontSize: '1.5rem',
            minHeight: 'auto',
        },
    },
}));
