import React from 'react';
import { useSelector } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import { IconButton, Tooltip } from '@mui/material';

import RadioGroupField from '../RadioGroupField';
import { makeFieldFormStyles } from '../styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

const RadioField = ({ values, setFieldValue }) => {
    const theme = useSelector(selectActiveTheme);
    const nodeFormStyles = makeFieldFormStyles(theme);

    const handleRemoveRadioGroup = (id, values, setFieldValue) => {
        let currOptionButtons = [...values.fields];
        currOptionButtons.splice(id, 1);
        setFieldValue('fields', currOptionButtons);
    };

    const handleAddRadioGroup = (fields, setFieldValue) => {
        var uuid = uuidv4();
        if (!Array.isArray(fields)) {
            fields = [];
        }
        const fieldsArray = [...fields];
        fieldsArray.push({
            id: uuid,
            label: '',
            type: '',
        });
        setFieldValue('fields', fieldsArray);
    };

    return (
        <div className={nodeFormStyles.radioGroupsContainer}>
            <div className={nodeFormStyles.radioGroupsText}>
                Radio Button Labels
            </div>
            {values.fields?.map((_field, idx) => {
                return (
                    <RadioGroupField
                        key={`RadioGroup${idx}`}
                        idx={idx}
                        values={values}
                        setFieldValue={setFieldValue}
                        handleRemoveRadioGroup={(id) =>
                            handleRemoveRadioGroup(id, values, setFieldValue)
                        }
                    />
                );
            })}
            <div>
                <Tooltip title="Add Buttons">
                    <IconButton
                        className={nodeFormStyles.addButtonIcon}
                        onClick={() =>
                            handleAddRadioGroup(values?.fields, setFieldValue)
                        }
                        size="small"
                    >
                        <MdAdd className="react-icon" />
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
};

export default RadioField;
