import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    getChildZones,
    selectCompactZones,
} from 'GeminiViewerComponent/_features/asset/assetSlice';

export const useAllZoneData = () => {
    const childZones = useSelector(getChildZones);
    const allZones = useSelector(selectCompactZones);

    return useMemo(() => {
        if (childZones && childZones?.length > 0) {
            return [...allZones, ...childZones];
        } else {
            return allZones;
        }
    }, [allZones, childZones]);
};
