import { config } from 'gemini-config';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import { getNetworkService } from 'GeminiViewerComponent/_helpers';

const baseUrl = `${config.gapsApiUrl}/proceduresession`;
const inspectionbaseUrl = `${config.gapsApiUrl}/inspectionpoint`;

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});

const fetchAllProcedureSessions = async (
    startIndex,
    stopIndex,
    sort = '-created_date',
    createdById = '',
    status = 1,
    hasIssue = '',
    searchByPrimaryId = '',
    searchBySecondaryId = '',
    createdDate = ''
) => {
    const filters = [];

    if (createdById?.length > 0) {
        filters.push(`created_by_id==${createdById.join('|').toString()}`);
    }
    if (status?.length > 0) {
        filters.push(`status==${status.join('|').toString()}`);
    }
    if (hasIssue) {
        filters.push(`open_count>0`);
    }
    if (searchByPrimaryId) {
        filters.push(`primary_tag@=*${searchByPrimaryId}`);
    }
    if (searchBySecondaryId) {
        filters.push(`secondary_tag@=*${searchBySecondaryId}`);
    }
    if (createdDate) {
        filters.push(`created_date>=${createdDate}`);
    }

    const params = {
        filters: filters.join(','),
        sorts: sort,
    };

    if (startIndex !== 0 || stopIndex !== 0) {
        params.start_index = startIndex;
        params.stop_index = stopIndex;
    }

    const query = new URLSearchParams(params).toString();

    const response = await networkService.get(`${baseUrl}/?${query}`);
    const pageInfo = response.headers['x-pagination'];
    return { pageInfo: pageInfo, procedureSessions: response?.data };
};

const fetchAllUsers = async () => {
    const response = await networkService.get(`${baseUrl}/users`);
    return response.data;
};

const fetchProcedureSessionsById = async (sessionId) => {
    const response = await networkService.get(`${baseUrl}/${sessionId}`);
    return response.data;
};

const createProcedureSession = async (params) => {
    const response = await networkService.post(baseUrl, params);

    return response.data;
};

const updateProcedureSession = async (params) => {
    const { procedure_session_id } = params;

    const response = await networkService.put(
        `${baseUrl}/${procedure_session_id}`,
        params
    );

    return response.data;
};

const updateProcedureInspectionPoint = async (params) => {
    const { procedure_session_id } = params;

    const response = await networkService.put(
        `${inspectionbaseUrl}/${procedure_session_id}`,
        params
    );

    return response.data;
};

const deleteProcedureSession = async (params) => {
    const response = await networkService.delete(`${baseUrl}/${params}`);
    return response.data;
};

export const procedureSessionService = {
    fetchAllProcedureSessions,
    fetchProcedureSessionsById,
    fetchAllUsers,
    createProcedureSession,
    updateProcedureSession,
    updateProcedureInspectionPoint,
    deleteProcedureSession,
};
