import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useCategoryPopoverStyles = makeStyles(() =>
    createStyles({
        popupWrapper: {
            zIndex: '1300',
            backgroundColor: '#ffffff',
            margin: '0 0.8rem !important',
            boxShadow: '8px 3px 18px 2px rgba(0,0,0,0.2)',
            borderRadius: '4px',
        },
        categoriesWrapper: {
            height: '250px',
            overflow: 'auto',
            overflowX: 'hidden',
        },
        popupActionContainer: {
            display: 'flex',
            alignItems: 'center',
            color: '#7E8C8D',
            padding: '5px',
            fontSize: '14px',
            maxWidth: '200px',
            '&:hover': {
                cursor: 'pointer',
                transform: 'scale(1.05)',
            },
        },
        submit: {
            backgroundColor: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            border: '0px',
            padding: '10px',
            borderRadius: '5px',
            fontSize: '18px',
            textTransform: 'uppercase',
            marginTop: '10px',
            cursor: 'pointer',
            '&:disabled': {
                backgroundColor: (props) =>
                    props.colors.button.primaryBackground,
                color: (props) => props.colors.button.primaryForeground,
                filter: 'brightness(50%)',
            },
            '&:hover': {
                backgroundColor: (props) =>
                    props.colors.button.primaryBackground,
                color: (props) => props.colors.button.primaryForeground,
                filter: 'brightness(90%)',
            },
        },
        multiSelectDoneButton: {
            width: '100%',
            marginTop: '0px',
            padding: '0.3rem',
        },
    })
);

export { useCategoryPopoverStyles };
