import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    headerTitle: {
        fontWeight: 800,
        fontSize: '28px',
        color: '#297FB9',
    },
    headerIcon: {
        display: 'flex',
        backgroundColor: '#ECF0F1',
        padding: '15px',
        borderRadius: '5px',
        position: 'relative',
        top: { sx: '0', md: '-25px' },
        [theme.breakpoints.up('md')]: {
            top: '-25px',
        },
        '& svg': {
            height: '45px',
            width: '45px',
        },
    },
}));

export { useStyles };
