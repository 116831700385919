import React from 'react';
import { LinkTypes } from '_helpers/enumerations';
import { GeneralContentTab } from '../GeneralContentTab';

function CustomDataTab() {
    return (
        <GeneralContentTab
            contentName={'Custom Data'}
            linkTypeId={LinkTypes.CustomData.id}
            contentTypes={LinkTypes.CustomData.contentTypes}
        />
    );
}

export { CustomDataTab };
