import { Avatar, Typography } from '@mui/material';
import clsx from 'clsx';
import { profileItemStyles } from './styles';
import { PopupActions } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { accountsSlice } from 'app/store';
import { openPanel, setEditObjectId } from '_features/common/formSlice';
const { selectActiveUser } = accountsSlice;

export default function ProfileItem({ drawerOpen }) {
    const activeUser = useSelector(selectActiveUser);
    const dispatch = useDispatch();
    const classes = profileItemStyles();
    function handleProfileEdit() {
        dispatch(
            setEditObjectId({
                formKey: `accountForm`,
                object_id: activeUser?.user_id,
            })
        );
        dispatch(openPanel({ formKey: `accountForm`, formAction: 'Edit' }));
    }
    return (
        <div>
            <div
                className={clsx(classes.profileDisplay, {
                    [classes.minProfile]: !drawerOpen,
                })}
            >
                <div onClick={handleProfileEdit}>
                    <Avatar
                        alt={`${activeUser.first_name} ${activeUser.last_name}`}
                        src={activeUser.image_url}
                        className={clsx(classes.profileImage, {
                            [classes.minImage]: !drawerOpen,
                        })}
                    />
                </div>
                <div>
                    <Typography
                        className={classes.profileName}
                        noWrap={true}
                    >{`${activeUser.first_name} ${activeUser.last_name}`}</Typography>
                    <Typography className={classes.profileTitle}>{`${
                        activeUser.role ?? ''
                    }`}</Typography>
                    <Typography className={classes.profileClientName}>{`${
                        activeUser.client_name ?? ''
                    }`}</Typography>
                </div>
                <PopupActions
                    object={{ user_id: activeUser.user_id, drawerOpen }}
                    level={'user'}
                    targetPopup={'userProfile'}
                />
            </div>
            <Typography
                className={classes.smallProfileName}
                noWrap={true}
            >{`Hi ${activeUser.first_name}`}</Typography>
        </div>
    );
}
