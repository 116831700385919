// eslint-disable-next-line
import React, { createElement, useCallback } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
    MdCenterFocusWeak,
    MdExpandMore,
    MdOutlineFilterCenterFocus,
} from 'react-icons/md';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';

import { linkAccordionStyles } from './styles';
import { loadZone } from 'shared/loadZoneSlice';
import { LinkButton } from '../../components/LinkButton';
import getLinkIcon from 'GeminiViewerComponent/_helpers/getLinkIcon';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    setSelectedSearchItemId,
    getActiveAssetActiveZone,
    getSelectedSearchItemId,
} from 'GeminiViewerComponent/_features/asset/assetSlice';

const LinkTypeAccordion = ({
    linkTypes,
    linkTypeId,
    links,
    isZoneMedia,
    setOpen,
}) => {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const activeZone = useSelector(getActiveAssetActiveZone);
    const selectedSearchItemId = useSelector(getSelectedSearchItemId);

    const classes = linkAccordionStyles(theme);
    const linkType = linkTypes[linkTypeId];
    const handleMediaItemClick = useCallback(
        async (id, zone_id) => {
            if (zone_id !== activeZone?.zone_id) {
                await dispatch(loadZone({ zoneId: zone_id, searchItemId: id }));
            } else {
                await dispatch(setSelectedSearchItemId(id));
            }
        },
        [activeZone]
    );

    return (
        <div>
            <Accordion>
                <AccordionSummary
                    className={classes.sectionHeader}
                    expandIcon={
                        <MdExpandMore
                            className={`react-icon ${classes.expandIcon}`}
                        />
                    }
                >
                    <Typography className={classes.heading}>
                        <span className={classes.icon}>
                            {getLinkIcon(linkTypeId)}
                        </span>
                        {linkType.label}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.links}>
                    {links.map((link) => {
                        if (isZoneMedia) {
                            return (
                                <Box
                                    key={link?.link_id}
                                    display="flex"
                                    alignItems="center"
                                    gap="5px"
                                >
                                    {link?.zone_id === activeZone?.zone_id ? (
                                        <IconButton
                                            color="inherit"
                                            edge="start"
                                            onClick={() => {
                                                handleMediaItemClick(
                                                    link.item_id,
                                                    link?.zone_id || null
                                                );
                                            }}
                                        >
                                            <MdOutlineFilterCenterFocus
                                                className={`react-icon ${classes.expandIcon}`}
                                            />
                                        </IconButton>
                                    ) : (
                                        <Tooltip
                                            title={link?.zone_display_name}
                                        >
                                            <IconButton
                                                color="inherit"
                                                edge="start"
                                                onClick={() => {
                                                    handleMediaItemClick(
                                                        link.item_id,
                                                        link?.zone_id || null
                                                    );
                                                }}
                                            >
                                                <MdCenterFocusWeak
                                                    className={`react-icon ${classes.expandIcon}`}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Box
                                        width="100%"
                                        overflow="hidden"
                                        className={clsx([
                                            selectedSearchItemId ===
                                                link.item_id &&
                                                classes.linkButtonWrapper,
                                        ])}
                                    >
                                        <LinkButton
                                            link={link}
                                            allLinks={links}
                                            setOpen={setOpen}
                                        />
                                    </Box>
                                </Box>
                            );
                        } else {
                            return (
                                <LinkButton
                                    key={link?.link_id}
                                    allLinks={links}
                                    link={link}
                                    setOpen={setOpen}
                                />
                            );
                        }
                    })}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

// create new user

export { LinkTypeAccordion };
