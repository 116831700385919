import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { MdClose } from 'react-icons/md';

import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { LinkTypes } from '_helpers';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { makeFormStyles } from '../styles';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,
    DialogTitle,
    RadioGroup,
    FormControlLabel,
    IconButton,
    Radio,
    Box,
} from '@mui/material';
import Dropzone from 'react-dropzone';
import { panelStyles } from 'GeminiViewerComponent/components/styles';
import { ImageResizer } from 'forms/ZoneForm/ImageResizer';
import JSZip from 'jszip';
import { editOrbitAssetImages } from '_features/assets/editAssetSlice';

const UpdateOrbitImageForm = ({
    openUpdateOrbitImagesDialog,
    setOpenUpdateOrbitImagesDialog,
    asset,
}) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    const panelClasses = panelStyles(theme);
    const zoneHeightWidth = useRef(null);
    const [hasImages, setHasImages] = React.useState(false);
    const [sequenceImages, setSequenceImages] = React.useState([]);
    const [imageResize, setImageResize] = React.useState(0);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

    const onDrop = async (files) => {
        let img = [];
        const isImageType = files.some((file) => file?.type.includes('image'));
        setHasImages(isImageType);
        if (isImageType && files && files.length > 0) {
            let hasError = false;
            const filteredFiles = files
                .map((file) => {
                    const removeExt = file?.name?.substring(
                        0,
                        file?.name?.lastIndexOf('.')
                    );
                    if (hasString(removeExt) || hasInvalidFileName(removeExt)) {
                        hasError = true;
                        return false;
                    }
                    const numericFilesName = removeExt.replace(/[^\d.]/g, '');
                    return renameFile(file, numericFilesName);
                })
                .sort((a, b) => a.name - b.name);
            if (hasError) {
                setSequenceImages([]);
                setHasImages(false);
                return;
            }
            const promises = [];
            filteredFiles.forEach((file) => {
                promises.push(resizeImage(file, imageResize));
            });
            let results = await Promise.all(promises);
            if ([1, 2, 3].includes(imageResize)) {
                results = results.map((myBlob, index) => {
                    return renameFile(myBlob, filteredFiles[index]?.name);
                });
            }
            results.forEach((resizedFile) => {
                img.push([
                    window.URL.createObjectURL(resizedFile),
                    resizedFile?.name,
                ]);
            });

            setSequenceImages(img);
            setOpenConfirmDialog(true);
        }
    };

    const resizeImage = async (file, imageResizeNumber = 3) => {
        let imageResizer = new ImageResizer(file);
        switch (imageResizeNumber) {
            case 1:
                return await imageResizer.run(512, 512);
            case 2:
                return await imageResizer.run(1024, 1024);
            case 3:
                return await imageResizer.run(1280, 1280);
            default:
                return file;
        }
    };

    const renameFile = (originalFile, newName) => {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    };

    const hasString = (removeExt) => {
        let hasError = false;
        if (/^[a-zA-Z!@#$%*&]+$/.test(removeExt)) {
            setHasImages(false);
            setSequenceImages([]);
            enqueueSnackbar('File name must be only numeric', {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
            hasError = true;
        }
        return hasError;
    };

    const hasInvalidFileName = (removeExt) => {
        let hasError = false;
        const onlyNumeric = removeExt.replace(/[^\d.]/g, '');
        if (isNaN(Number(onlyNumeric))) {
            setHasImages(false);
            setSequenceImages([]);
            enqueueSnackbar('Invalid file name', {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
            hasError = true;
        }
        return hasError;
    };

    const onDropError = (error) => {
        if (error?.[0]?.errors?.[0]?.message) {
            let msg = error[0].errors[0].message;
            if (error[0].errors[0].code === 'too-many-files') {
                msg = `Maximum ${asset?.orbit_images_count} files allowed.`;
            }
            enqueueSnackbar(msg, {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
        }
    };

    const getBase64 = (url) => {
        return new Promise((resolve) => {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = () => {
                    var img = new Image();
                    img.onload = function () {
                        if (
                            !zoneHeightWidth.current?.width ||
                            !zoneHeightWidth.current?.height
                        )
                            zoneHeightWidth.current = {
                                width: img.width,
                                height: img.height,
                            };
                    };
                    img.src = reader.result;
                    resolve(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        });
    };

    const createImagesZip = async (images, zip) => {
        for (let index = 0; index < (images || []).length; index++) {
            const image = images[index];
            if (hasImages) {
                const base64Img = await getBase64(image[0]);
                zip.file(`${index + 1}.jpg`, base64Img.split('base64,')[1], {
                    base64: true,
                });
            } else {
                zip.file(`${index + 1}.jpg`, image.split('base64,')[1], {
                    base64: true,
                });
            }
        }
    };

    const handleSave = async () => {
        dispatch(activateLoading({ showProgress: true }));
        let orbitImages = [];
        if (hasImages) {
            orbitImages = sequenceImages;
        } else {
            return;
        }
        let orbitZip = new JSZip();
        await createImagesZip(orbitImages, orbitZip);
        const orbitImagesZip = await orbitZip.generateAsync({ type: 'blob' });
        orbitZip = null;
        const resultAction = await dispatch(
            editOrbitAssetImages({
                asset_id: asset.asset_id,
                orbit_images_zip: orbitImagesZip,
                zone_image_width: zoneHeightWidth.current?.width,
                zone_image_height: zoneHeightWidth.current?.height,
                dispatch,
            })
        );
        dispatch(deactivateLoading());
        const { message, variant } = snackbarHandler(
            resultAction.meta.requestStatus,
            'Update Orbit Images'
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });
        handleCancel();
    };

    const handleClose = () => setOpenUpdateOrbitImagesDialog(false);

    const handleCancel = () => {
        setOpenConfirmDialog(false);
        setOpenUpdateOrbitImagesDialog(false);
    };

    return (
        <>
            <Dialog
                fullWidth={true}
                open={openUpdateOrbitImagesDialog}
                onClose={handleClose}
                maxWidth={'md'}
            >
                <DialogTitle sx={{ fontWeight: 'bold' }}>
                    Update Orbit Images{' '}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 6,
                            color: '#000',
                        }}
                        size="large"
                    >
                        <MdClose className="react-icon" />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ width: '100%' }}>
                    <DialogContentText marginBottom={'5px'} component={'div'}>
                        Use this feature to replace one or more original orbit
                        images with updated images. This will not replace any
                        other data related to this asset. The edited images
                        should have the same sequence numbers as the original
                        images.
                    </DialogContentText>
                    <DialogContentText
                        marginBottom={'25px'}
                        component={'div'}
                        style={{ color: '#472c2f' }}
                    >
                        <p>
                            Image filenames must contain at least one numeric
                            character and should be in sequence. <br />
                            Some valid sets of examples are:
                        </p>
                        <ul style={{ listStyle: 'inside' }}>
                            <li>1.jpg, 2.jpg, 3.jpg, etc.</li>
                            <li>a1.jpg, a2.jpg, a3.jpg, etc.</li>
                            <li>a1.1.jpg, a1.2.jpg, a1.3.jpg, etc.</li>
                        </ul>
                    </DialogContentText>
                    <RadioGroup
                        aria-label="image-resize"
                        name="controlled-radio-buttons-group"
                        value={imageResize}
                        onChange={(e) => setImageResize(Number(e.target.value))}
                        row={true}
                        style={{ alignItems: 'flex-end' }}
                    >
                        <Box p={'12px'} pl={0} component={'label'}>
                            Image Resize
                        </Box>
                        <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label="None"
                        />
                        <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="Small"
                        />
                        <FormControlLabel
                            value={2}
                            control={<Radio />}
                            label="Medium"
                        />
                        <FormControlLabel
                            value={3}
                            control={<Radio />}
                            label="Large"
                        />
                    </RadioGroup>
                    <Dropzone
                        accept={['image/jpeg', 'image/jpg', 'image/png']}
                        maxFiles={asset?.orbit_images_count ?? 1}
                        maxSize={LinkTypes.Image.fileSizeLimit}
                        multiple={true}
                        onDropAccepted={onDrop}
                        onDropRejected={onDropError}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div
                                {...getRootProps()}
                                className={panelClasses.dropzoneArea}
                                style={{
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center center',
                                }}
                            >
                                <input {...getInputProps()} />
                                <div className={panelClasses.dropzoneText}>
                                    Drag &apos;n&apos; drop images here, or
                                    click to select files
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </DialogContent>
            </Dialog>
            {openConfirmDialog && (
                <Dialog
                    fullWidth={false}
                    open={openConfirmDialog}
                    onClose={handleCancel}
                >
                    <DialogTitle>
                        Update Orbit Images{' '}
                        <IconButton
                            aria-label="close"
                            onClick={handleCancel}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 6,
                                color: '#000',
                            }}
                            size="large"
                        >
                            <MdClose className="react-icon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{ width: '400px' }}>
                        <DialogContentText
                            marginBottom={'5px'}
                            component={'div'}
                        >
                            Are you sure you want to replace the original orbit
                            images?
                            <br />
                            This will not replace any other data related to this
                            asset.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            className={classes.cancel}
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSave}
                            className={classes.submit}
                            type="button"
                        >
                            Replace
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export { UpdateOrbitImageForm };
