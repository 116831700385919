import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AppBar, Box, Tab, Tabs, Typography } from '@mui/material';
import { tabStyles } from 'GeminiViewerComponent/components/styles';
import {
    getPartsTabIndex,
    setPartsTabIndex,
} from '_features/globals/visibilitySlice';
import { PartsTab } from '../PartsTab';
import { PartGroupsTab } from '../PartGroupsTab';
import { PartCategoryTab } from '../PartCategoryTab';

function PartTab(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            style={{ height: '100%' }}
            {...other}
        >
            {value === index && (
                <Box pt={1} height="100%" overflow="auto hidden">
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

PartTab.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function PartsTabPanel() {
    const dispatch = useDispatch();
    const classes = tabStyles();
    const displayTabIndex = useSelector(getPartsTabIndex);

    const handleChange = (event, newValue) => {
        dispatch(setPartsTabIndex(newValue));
    };

    let tabBarIndex = -1;
    let tabPageIndex = -1;
    return (
        <div className={classes.tabRoot}>
            <AppBar position="static" color="default">
                <Tabs
                    value={displayTabIndex}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    className={classes.tabs}
                >
                    <Tab label="Parts" {...a11yProps(++tabBarIndex)} />
                    <Tab label="Categories" {...a11yProps(++tabBarIndex)} />
                    <Tab label="Groups" {...a11yProps(++tabBarIndex)} />
                </Tabs>
            </AppBar>
            <PartTab value={displayTabIndex} index={++tabPageIndex}>
                <PartsTab />
            </PartTab>
            <PartTab value={displayTabIndex} index={++tabPageIndex}>
                <PartCategoryTab />
            </PartTab>
            <PartTab value={displayTabIndex} index={++tabPageIndex}>
                <PartGroupsTab />
            </PartTab>
        </div>
    );
}

export { PartsTabPanel };
