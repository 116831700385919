import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Chip } from '@mui/material';
import Dropzone from 'react-dropzone';
import { MdClose } from 'react-icons/md';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { LinkTypes } from '_helpers';
import { makeFormStyles } from '../styles';
import SetDirtyForm from 'forms/SetDirtyForm';
import { FormActions } from '_helpers/form-action';
import { FormTextField } from 'components/TextField';
import { closePanel } from '_features/common/formSlice';
import { panelStyles } from 'GeminiViewerComponent/components/styles';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';
import {
    createPartCategory,
    fetchPartCategoryById,
    updatePartCategory,
} from '_features/parts/partCategorySlice';
import {
    setProgress,
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

const INIT_VALUES = {
    display_name: '',
    image_url: '',
    image: null,
};

const PartCategoryForm = ({ formAction, partCategoryId }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [loaded, setLoaded] = useState(
        formAction.id === FormActions.Create.id ? true : false
    );
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    const panelClasses = panelStyles(theme);
    const [initValues, setInitValues] = useState({ ...INIT_VALUES });
    const categoryNameRef = useRef(null);

    useEffect(() => {
        if (formAction.id === FormActions.Edit.id) {
            const fetchData = async () => {
                let objectData = await dispatch(
                    fetchPartCategoryById(partCategoryId)
                );
                let fetchedObject = objectData.payload;
                setInitValues({
                    display_name: fetchedObject.display_name,
                    image_url: fetchedObject.image_url,
                    image: null,
                });
                setLoaded(true);
            };
            fetchData();
        } else {
            setInitValues({ ...INIT_VALUES });
        }
    }, [partCategoryId]);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        dispatch(activateLoading({ showProgress: true }));
        let resultAction = null;

        switch (formAction.id) {
            case FormActions.Edit.id:
                if (values?.image) {
                    delete values?.image_url;
                }
                resultAction = await dispatch(
                    updatePartCategory({
                        part_category_id: partCategoryId,
                        ...values,
                        dispatch,
                    })
                );
                if (!resultAction.error) {
                    dispatch(closePanel({ formKey: 'partCategoryForm' }));
                }
                break;
            case FormActions.Create.id:
                dispatch(
                    setProgress({
                        progress: 0,
                        label: 'Step 1 of 2: Uploading',
                        noAnimation: true,
                    })
                );
                resultAction = await dispatch(
                    createPartCategory({ ...values, dispatch })
                );
                if (!resultAction.error) {
                    resetForm();
                    if (categoryNameRef.current) {
                        categoryNameRef.current.focus();
                    }
                }
                break;
            default:
                break;
        }

        dispatch(deactivateLoading());
        const { message, variant } = snackbarHandler(
            resultAction.meta.requestStatus,
            formAction.label
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });
        setSubmitting(false);
    };

    const onDrop = useCallback(({ file, setFieldValue }) => {
        setFieldValue('image', file);
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = (e) => {
            setFieldValue('image_url', e.target.result);
        };
        reader.readAsDataURL(file);
    }, []);

    return !loaded ? (
        <LoadingLogo styleClassName={classes.loadingSvg} />
    ) : (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            validationSchema={Yup.object({
                display_name: Yup.string()
                    .max(150, 'Must be 150 characters or less')
                    .required('Required'),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values, setSubmitting, resetForm);
            }}
        >
            {({ isSubmitting, values }) => (
                <Form className={classes.form}>
                    <div className={classes.formHeader}>
                        Part Category Details
                    </div>
                    <FormTextField
                        inputRef={categoryNameRef}
                        autoFocus
                        label="Category Name"
                        name="display_name"
                        placeholder="Category Name"
                    />
                    <Field className={classes.input} name="image" type="file">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <div className={panelClasses.dropzoneContainer}>
                                <Dropzone
                                    accept={LinkTypes.Image.contentTypes.map(
                                        (at) => at.extension
                                    )}
                                    maxFiles={1}
                                    maxSize={LinkTypes.Image.fileSizeLimit}
                                    multiple={false}
                                    onDropAccepted={(files) => {
                                        onDrop({
                                            file: files[0],
                                            setFieldValue: setFieldValue,
                                        });
                                    }}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            {...getRootProps()}
                                            className={
                                                panelClasses.dropzoneArea
                                            }
                                            style={{
                                                backgroundImage: `url(${values?.image_url})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'contain',
                                                backgroundPosition:
                                                    'center center',
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            {!values.image_url && (
                                                <div
                                                    className={
                                                        panelClasses.dropzoneText
                                                    }
                                                >
                                                    Drop Category Icon Here
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Dropzone>
                                <div className={panelClasses.previewContainer}>
                                    {(value && Object.keys(value).length > 0) ||
                                    values?.image_url ? (
                                        <Chip
                                            className={
                                                panelClasses.previewUploadFileName
                                            }
                                            color="secondary"
                                            size="small"
                                            deleteIcon={
                                                <MdClose className="react-icon" />
                                            }
                                            onDelete={() => {
                                                setFieldValue('image', null);
                                                setFieldValue('image_url', '');
                                            }}
                                            label={value?.name}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        )}
                    </Field>
                    <SetDirtyForm />
                    <button
                        className={classes.submit}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {formAction.buttonLabel}
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export { PartCategoryForm };
