import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    procedureResults: {
        width: '95%',
        marginRight: 'auto',
        marginLeft: 'auto',
        overflowY: 'hidden',
        overflow: 'hidden',
    },
    title: {
        fontSize: '28px',
        lineHeigth: '1.2',
        fontWeight: '600',
        color: '#2c3d4d',
        marginBottom: '30px',
        textAlign: 'center',
    },
    failedChecklistTable: {
        '& td': {
            borderBottom: '1px solid #fff',
        },
        '& th': {
            borderBottom: '3px solid #fff',
            padding: '10px 30px',
            whiteSpace: 'nowrap',
            color: '#505457',
            fontWeight: '600',
        },
        '& h3,& p': {
            margin: '0',
        },
        '& p': {
            color: '#505457',
            fontWeight: '600',
        },
    },
    tableBG100: {
        backgroundColor: '#f7f9f8',
    },
    tableBG200: {
        backgroundColor: '#e9edf0',
    },
    tableBG300: {
        backgroundColor: '#e8ecef',
    },
    tableBG400: {
        backgroundColor: '#bec3c7',
    },
    tableBG500: {
        backgroundColor: '#b5bbc4',
    },
    tableBG600: {
        backgroundColor: '#2c3d4d',
        color: '#ffffff',
        fontSize: '26px',
        lineHeight: '1.4',
    },
    exitButton: {
        marginTop: '50px',
        textAlign: 'center',
    },
    contentInfo: {
        fontSize: '16px',
        color: '#e4e9ef',
        fontWeight: '500',
        marginBottom: '15px',
    },
    content: {
        padding: '20px',
        backgroundColor: '#2c3d4d',
        height: '100%',
    },
    scoreSummary: {
        fontSize: '16px',
        marginLeft: '20px',
        color: '#989c9f',
    },
    header: {
        fontSize: '32px',
        marginTop: '10px',
        color: '#2c3d4d',
        textAlign: 'center',
        paddingTop: '40px',
        paddingBottom: '40px',
        fontWeight: 'bold',
    },
    contentBody: {
        fontSize: '16px',
        color: '#002A50',
        fontWeight: '500',
        margin: '30px',
    },
    postSection: {
        margin: '50px',
        textAlign: 'center',
    },
    signature: {
        fontSize: '16px',
        color: '#989c9f',
        fontWeight: 'bold',
        border: '1px dashed #878f99',
        textAlign: 'center',
    },
    subSignature: {
        padding: '5px',
        fontSize: '16px',
        color: '#989c9f',
        fontWeight: 'bold',
        border: '1px dashed #878f99',
        textAlign: 'center',
        height: '200px',
        marginTop: '40px',
        position: 'relative',
    },
    signatureCanvas: {
        width: 'calc(100% - 48px)',
        height: '100%',
        marginRight: '48px',
    },
    pageBreak: {
        pageBreakBefore: 'always',
    },
    nonPageBreak: {},
}));

export { useStyles };
