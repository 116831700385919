// eslint-disable-next-line
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { marzipanoStyles } from './styles';
import { MarzipanoManager } from './MarzipanoManager';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import { useLookAtHighlightedItem } from 'GeminiViewerComponent/hooks/useLookAtHighlightedItem';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { getSelectedSearchItemId } from 'GeminiViewerComponent/_features/asset/assetSlice';
import { selectContentPanelItem } from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import {
    getIsEmptyHotspotVisible,
    getShowOnlyByFilter,
    getTagFilters,
} from '../../_features/globals/visibilitySlice';
import {
    getSelectedHotspotFromUser,
    getActiveAssetActiveZone,
    selectActiveAsset,
    selectCompactZones,
    getChildZones,
} from '../../_features/asset/assetSlice';

const MarzipanoViewer = ({
    hotspotElements,
    onSelectHotspot,
    onShiftRightClick,
    onRightClick,
    onClick,
    isMultipleHotspotEnabled,
    isLoadedState,
    onClickRefreshItem,
    setActiveItem,
    handleMultipleHotspot,
    previewZone = null,
}) => {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const panoStyles = marzipanoStyles(theme);

    const activeItemId = null;

    const config = useSelector(getConfig);
    const allZones = useSelector(selectCompactZones);
    const childZones = useSelector(getChildZones);
    const allZoneData = useMemo(() => {
        if (childZones && childZones?.length > 0) {
            return [...allZones, ...childZones];
        } else {
            return allZones;
        }
    }, [allZones, childZones]);
    const panelItem = useSelector(selectContentPanelItem);

    const showOnlyByFilter = useSelector(getShowOnlyByFilter);
    const selectedHotspotFromUser = useSelector(getSelectedHotspotFromUser);
    const activeAsset = useSelector(selectActiveAsset);
    let activeZone = useSelector(getActiveAssetActiveZone);
    const tagFilters = useSelector(getTagFilters);
    const searchItem = useSelector(getSelectedSearchItemId);
    const highlightedItem = useLookAtHighlightedItem(activeZone);
    const isEmptyHotspotVisible = useSelector(getIsEmptyHotspotVisible);

    if (previewZone) {
        activeZone = previewZone;
    }

    useEffect(() => {
        if (!config.user) return;

        window.viewerManager = new MarzipanoManager({
            config,
            initialZoneId: activeZone ? activeZone.zone_id : null,
            hotspotItems: activeZone?.items,
            zoneItems: allZoneData,
            itemHotspots: activeZone?.items,
            tagFilters: tagFilters,
            showOnlyByFilter: showOnlyByFilter,
            isEmptyHotspotVisible: isEmptyHotspotVisible,
            onShiftRightClick: onShiftRightClick,
            onRightClick: onRightClick,
            onClick: onClick,
            isMultipleHotspotEnabled: isMultipleHotspotEnabled,
            handleMultipleHotspot: handleMultipleHotspot,
        });

        window.viewerManager.init();
    }, [
        config.data,
        config.paths,
        config.user?.role,
        JSON.stringify(config.user?.audienceIds?.toSorted()),
        activeAsset?.asset_id,
    ]); // eslint-disable-line react-hooks/exhaustive-deps

    // NOTE: This method previously used allZones and activeZone as dependencies.
    // This was causing some odd timing issues resulting in dragged hotspots jumpnig
    // back to their previous position even though they were successfully moved to
    // the new location. It was updated to use activeZone only.
    useEffect(() => {
        async function updateScenes() {
            if (!activeZone) return;

            window.viewerManager.updateSceneData(
                isMultipleHotspotEnabled,
                activeZone,
                handleMultipleHotspot
            );

            await window.viewerManager.addScenesFromZones(
                [activeZone],
                hotspotElements,
                tagFilters,
                showOnlyByFilter,
                isEmptyHotspotVisible
            );

            window.viewerManager.switchSceneById(activeZone.zone_id);
            if (activeItemId) {
                // Show current node id
                if (
                    activeZone?.items?.find(
                        (item) => item.item_id === activeItemId
                    )
                ) {
                    window.showHotspot(activeItemId);
                } else {
                    if (setActiveItem) {
                        dispatch(setActiveItem({}));
                    }
                }
            }
            let panoEl = document.getElementById('pano');
            if (panoEl) {
                if (isMultipleHotspotEnabled) {
                    panoEl.style.cursor = 'crosshair';
                } else {
                    panoEl.style.cursor = 'default';
                }
            }
        }
        updateScenes();
        // NOTE: This will be called twice when active zone changes - because after the first
        // render for zone change the hotspotElements are created which causes another render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        config,
        searchItem,
        activeZone,
        hotspotElements,
        tagFilters,
        isMultipleHotspotEnabled,
        isEmptyHotspotVisible,
        showOnlyByFilter,
    ]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (activeZone) {
            window.viewerManager.updatesceneView(highlightedItem);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchItem]);

    useEffect(() => {
        if (
            window.viewerManager !== undefined &&
            window.showHotspot !== undefined
        ) {
            window.showHotspot(activeItemId);
        }
    }, [activeItemId]);

    useEffect(() => {
        if (
            activeItemId !== undefined &&
            onSelectHotspot !== undefined &&
            onSelectHotspot.canExecute &&
            panelItem !== null &&
            selectedHotspotFromUser === true
        ) {
            onSelectHotspot.execute(panelItem.ZoneId, panelItem.ItemId);
        }

        // eslint-disable-next-line
    }, [panelItem, selectedHotspotFromUser]);

    let hasImage =
        activeZone &&
        (activeZone.cubeFaces !== undefined ||
            activeZone.equirect_image_url !== undefined ||
            activeZone.flat_image_url !== undefined);

    let message = '';
    if (!hasImage && isLoadedState) {
        message = 'This Zone Requires An Image';
    }

    return (
        <>
            <div
                id="pano"
                tabIndex="0"
                className={panoStyles.panoWrapper}
                onKeyDown={(e) => {
                    if (e.code === 'KeyR') {
                        onClickRefreshItem();
                    }
                }}
            ></div>

            {!hasImage && (
                <div
                    style={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 1.0)',
                    }}
                >
                    <div
                        style={{
                            fontSize: '24px',
                            color: 'white',
                            position: 'relative',
                            top: '50%',
                            textAlign: 'center',
                        }}
                    >
                        {message}
                    </div>
                </div>
            )}

            {config.data.settings.viewControlButtons && (
                <>
                    <button
                        id="viewUp"
                        className="viewControlButton viewControlButton-1"
                    >
                        <img
                            className="icon"
                            alt="Pan Up"
                            src={config.paths.BASE_STORE_URL + 'img/up.png'}
                        ></img>
                    </button>
                    <button
                        id="viewDown"
                        className="viewControlButton viewControlButton-2"
                    >
                        <img
                            className="icon"
                            alt="Pan Down"
                            src={config.paths.BASE_STORE_URL + 'img/down.png'}
                        ></img>
                    </button>
                    <button
                        id="viewLeft"
                        className="viewControlButton viewControlButton-3"
                    >
                        <img
                            className="icon"
                            alt="Pan Left"
                            src={config.paths.BASE_STORE_URL + 'img/left.png'}
                        ></img>
                    </button>
                    <button
                        id="viewRight"
                        className="viewControlButton viewControlButton-4"
                    >
                        <img
                            className="icon"
                            alt="Pan Right"
                            src={config.paths.BASE_STORE_URL + 'img/right.png'}
                        ></img>
                    </button>
                    <button
                        id="viewIn"
                        className="viewControlButton viewControlButton-5"
                    >
                        <img
                            className="icon"
                            alt="Zoom In"
                            src={config.paths.BASE_STORE_URL + 'img/plus.png'}
                        ></img>
                    </button>
                    <button
                        id="viewOut"
                        className="viewControlButton viewControlButton-6"
                    >
                        <img
                            className="icon"
                            alt="Zoom Out"
                            src={config.paths.BASE_STORE_URL + 'img/minus.png'}
                        ></img>
                    </button>
                </>
            )}
        </>
    );
};

export { MarzipanoViewer };
