// eslint-disable-next-line
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { MdClose } from 'react-icons/md';
import { createRoot } from 'react-dom/client';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Tooltip } from '@mui/material';

import { TagBar } from './TagBar';
import { OrbitViewer } from './OrbitViewer';
import { loadZone } from 'shared/loadZoneSlice';
import { makeCommonStyles } from './Common/styles';
import { MarzipanoViewer } from './MarzipanoViewer';
import { SidePanel } from '../components/SidePanel';
import { GeminiTitleBar } from './Common/GeminiTitleBar';
import { FlyerDisplay } from '../components/FlyerDisplay';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    HotspotIconTypes,
    ProcedureSplitViewTypes,
} from 'GeminiViewerComponent/_helpers';
import {
    getActiveAssetActiveZone,
    getChildZones,
    getDefaultHighlightIconId,
    getOrbitImagesCount,
    getSelectedSearchItemId,
    selectActiveAsset,
    selectCompactZones,
} from 'GeminiViewerComponent/_features/asset/assetSlice';
import {
    getLoadedAssetType,
    getProcedureContentVersion,
    getProcedureLoaded,
    getTagFilters,
} from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import { useAvailableZones } from 'GeminiViewerComponent/hooks/useAvailableZones';

const SplitViewButton = ({ viewType, splitViewMode, setSplitViewMode }) => {
    return (
        <Tooltip title={viewType?.label}>
            <IconButton
                onClick={() => setSplitViewMode(viewType?.id)}
                style={{
                    padding: 0,
                    backgroundColor: '#ffffff',
                    borderRadius: 'inherit',
                }}
            >
                <Box
                    style={{
                        width: '35px',
                        height: '35px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '3px',
                        border: '1px solid #ffffff',
                        backgroundColor: `${
                            viewType.id === splitViewMode
                                ? 'white'
                                : 'lightgray'
                        }`,
                    }}
                >
                    <img
                        src={viewType.icon}
                        alt={`split view ${viewType?.type}`}
                        height={'30px'}
                        width={'30px'}
                    />
                </Box>
            </IconButton>
        </Tooltip>
    );
};

const GeminiContainer = ({
    draggableFlag,
    setDraggableFlag,
    hotspotIcons,
    hotspotElements,
    setHotspotElements,
    onSelectHotspot,
    onShiftRightClick,
    onRightClick,
    onClick,
    onUpdatedHotspot,
    onDeletedHotspot,
    onUpdatedLinkHotspot,
    onDeletedLinkHotspot,
    onEditClicked,
    onItemNavClicked,
    onNavClicked,
    onClose,
    PanoItemHotspot,
    PanoLinkHotspot,
    isMultipleHotspotEnabled,
    itemHotspots,
    navigationHotspots,
    onSearchItems,
    searchItemSelected,
    onClickRefreshItem,
    setActiveItem,
    readOnly,
    handleItemDragging,
    handleNavDragging,
    enableItemDragging,
    enableNavDragging,
    undoRedoProps,
    handleMultipleHotspot,
    viewerRef = null,
    splitViewMode = null,
    setSplitViewMode = () => {},
    headerRef = null,
    zoneMediaConfigValue = false,
    zoneCategory = [],
    emptyItemOpacity,
    setShowAssociatedAsset,
}) => {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const commonStyles = makeCommonStyles(theme);
    const INIT_HIDE_NAV_OPTIONS = {
        hideNavBar: false,
        hideNavZones: false,
        hideNavSearch: false,
        hideNavTitle: false,
        hideNavControls: false,
    };
    const [hideNavOptions, setHideNavOptions] = useState(INIT_HIDE_NAV_OPTIONS);

    const tridiRef = useRef(null);
    const allZones = useSelector(selectCompactZones);
    const childZones = useSelector(getChildZones);
    const allZoneData = useMemo(() => {
        if (childZones && childZones?.length > 0) {
            return [...allZones, ...childZones];
        } else {
            return allZones;
        }
    }, [allZones, childZones]);
    const config = useSelector(getConfig);
    const isProcedureLoaded = useSelector(getProcedureLoaded);
    const activeAsset = useSelector(selectActiveAsset);
    const orbitImagesCount = useSelector(getOrbitImagesCount);
    const selectedSearchItemId = useSelector(getSelectedSearchItemId);
    const defaultHighlightHotspot = useSelector(getDefaultHighlightIconId);
    const loadedAssetType = useSelector(getLoadedAssetType);
    const [itemTagsList, setItemTagsList] = useState([]);
    const tagContainerRef = useRef(null);
    const procedureContentVersion = useSelector(getProcedureContentVersion);
    const [splitViewTopPos, setSplitViewTopPos] = useState(0);

    useEffect(() => {
        if (config.modeType === 'viewer') {
            setHideNavOptions({
                hideNavBar:
                    activeAsset?.options?.hide_nav_zones &&
                    activeAsset?.options?.hide_nav_controls &&
                    activeAsset?.options?.hide_nav_search &&
                    activeAsset?.options?.hide_nav_title,
                hideNavZones: activeAsset?.options?.hide_nav_zones,
                hideNavSearch: activeAsset?.options?.hide_nav_search,
                hideNavTitle: activeAsset?.options?.hide_nav_title,
                hideNavControls: activeAsset?.options?.hide_nav_controls,
            });
        } else if (config.modeType === 'author') {
            setHideNavOptions(INIT_HIDE_NAV_OPTIONS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config, isProcedureLoaded, activeAsset]);

    let activeZone = useSelector(getActiveAssetActiveZone);

    const tagFilters = useSelector(getTagFilters);

    const availableZones = useAvailableZones();

    const generateCategoryMapKey = (zone) => {
        return `${zone?.asset_id ?? '!'}!${
            zone?.category || zone?.zone_category_name || '_-Gemini-_![NONE]'
        }`;
    };

    const generateCategoryDisplayName = (zone) => {
        let result = '';
        if (zone?.asset_name) {
            result = zone.asset_name + ' | ';
        }
        return (
            result + (zone?.category ?? zone?.zone_category_name ?? '[NONE]')
        );
    };

    const generateDisplayOrder = (zone, assetIdx) => {
        let result = `${(assetIdx * 2 + (!zone.category ? 1 : 0))
            .toString()
            .padStart(5, '0')}`;

        if (zone?.category_display_order !== undefined) {
            result += `!${zone.category_display_order
                .toString()
                .padStart(5, '0')}`;
        } else {
            result += `!${
                zone?.category ?? zone?.zone_category_name ?? 'zzzzz'
            }`;
        }

        if (zone?.display_order !== undefined) {
            result += `!${zone.display_order.toString().padStart(5, '0')}`;
        } else {
            result += `!${zone.display_name}`;
        }

        return result;
    };

    const createZonesByCategory = useCallback(
        (zones) => {
            const zonesByCategory = {};
            const zoneCategoryMap = new Map();
            let assetsFound = [activeAsset.asset_id];
            let colors = [
                {
                    backgroundColor: theme.colors.button.primaryBackground,
                    color: theme.colors.button.primaryForeground,
                },
                {
                    backgroundColor: theme.colors.button.secondaryBackground,
                    color: theme.colors.button.secondaryForeground,
                },
                {
                    backgroundColor: theme.colors.button.accentBackground,
                    color: theme.colors.button.accentForeground,
                },
            ];
            Object.values(
                config.modeType === 'viewer'
                    ? [...allZones, ...childZones]
                    : zones
            ).forEach((zone) => {
                if (zone?.category || zone?.zone_category_name) {
                    let assetIdx = 0;
                    let colorIdx = 0;
                    if (zone?.asset_id !== undefined) {
                        let found = assetsFound.indexOf(zone.asset_id);
                        assetIdx = assetsFound.length;
                        if (found === -1) {
                            assetsFound[assetIdx] = zone.asset_id;
                        } else {
                            assetIdx = found;
                        }
                        colorIdx = (assetIdx % (colors.length - 1)) + 1;
                    }

                    zoneCategoryMap.set(generateCategoryMapKey(zone), {
                        display_name: generateCategoryDisplayName(zone),
                        display_order: generateDisplayOrder(zone, assetIdx),
                        asset_id: zone?.asset_id ?? `!${activeAsset?.asset_id}`,
                        asset_name: zone?.asset_name,
                        category_color: colors[colorIdx],
                    });
                }
            });

            const noCategoryZones = Object.values(zones).filter(
                (zone) => !zone.category
            );
            if (noCategoryZones?.length > 0) {
                noCategoryZones.forEach((zone) => {
                    let assetIdx = 0;
                    if (zone?.asset_id !== undefined) {
                        let found = assetsFound.indexOf(zone.asset_id);
                        assetIdx = assetsFound.length;
                        if (found === -1) {
                            assetsFound[assetIdx] = zone.asset_id;
                        } else {
                            assetIdx = found;
                        }
                    }

                    zoneCategoryMap.set(generateCategoryMapKey(zone), {
                        display_name: generateCategoryDisplayName(zone),
                        display_order: generateDisplayOrder(zone, assetIdx),
                        asset_id: zone?.asset_id ?? `!${activeAsset?.asset_id}`,
                        asset_name: zone?.asset_name,
                    });
                });
            }

            const zoneCategories = [
                ...Array.from(zoneCategoryMap.values()),
            ].sort((a, b) => {
                if (!a.display_order && b.display_order) return 1;
                if (a.display_order && !b.display_order) return -1;
                return a.display_order > b.display_order ? 1 : -1;
            });

            let categoriesZone = [];
            zoneCategories.forEach((cat) => {
                if (config.modeType === 'viewer') {
                    categoriesZone = Object.values([
                        ...allZones,
                        ...childZones,
                    ]).filter(
                        (zone) =>
                            zone.zone_id in zones &&
                            generateCategoryDisplayName(zone) ===
                                cat.display_name
                    );
                } else {
                    categoriesZone = Object.values(zones).filter(
                        (zone) =>
                            generateCategoryDisplayName(zone) ===
                            cat.display_name
                    );
                }
                if (categoriesZone.length > 0) {
                    zonesByCategory[cat.display_name] = {
                        open: false,
                        zones: categoriesZone,
                        asset_id: cat.asset_id,
                        category_color: cat.category_color,
                    };
                }
            });
            return zonesByCategory;
        },
        [allZones, childZones, config, activeAsset]
    );

    const zonesByCategoryInit = createZonesByCategory(availableZones);

    const [zonesByCategory, setZonesByCategory] = useState(zonesByCategoryInit);
    useEffect(() => {
        const zoneByCategoryUpdated = createZonesByCategory(availableZones);
        setZonesByCategory(zoneByCategoryUpdated);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagFilters, availableZones]);

    function handleCategoryClick(categoryName) {
        const open = !zonesByCategory[categoryName].open;

        const zoneByCategoryUpdated = createZonesByCategory(availableZones);
        zoneByCategoryUpdated[categoryName].open = open;

        setZonesByCategory(zoneByCategoryUpdated);
    }

    const hotspotItemIcon = useCallback(
        (item) => {
            if (
                (selectedSearchItemId &&
                    selectedSearchItemId === item.item_id) ||
                procedureContentVersion?.doAction?.item_id === item.item_id
            ) {
                let icon =
                    hotspotIcons?.length > 0 &&
                    hotspotIcons.find(
                        (data) =>
                            data?.is_highlight &&
                            data?.hotspot_type_id === HotspotIconTypes.Item.id
                    );
                if (icon) {
                    return icon;
                }
            }

            const iconId =
                item.hotspot_icon_id || item.hotspot_icon_id === 0
                    ? item.hotspot_icon_id
                    : item.hotspot_icon
                    ? item.hotspot_icon.hotspot_icon_id
                    : activeAsset.item_hotspot_icon_id;
            if (!iconId || iconId === 0) {
                const defaultIcon =
                    hotspotIcons?.length > 0 &&
                    hotspotIcons.find(
                        (data) =>
                            data?.is_default &&
                            data?.hotspot_type_id === HotspotIconTypes.Item.id
                    );
                if (!defaultIcon) {
                    return hotspotIcons.find(
                        (data) =>
                            data?.hotspot_type_id === HotspotIconTypes.Item.id
                    );
                }
                return defaultIcon;
            }
            return (
                hotspotIcons?.length > 0 &&
                hotspotIcons.find((data) => data?.hotspot_icon_id === iconId)
            );
        },
        [
            selectedSearchItemId,
            procedureContentVersion?.doAction?.item_id,
            activeAsset.item_hotspot_icon_id,
            hotspotIcons,
            defaultHighlightHotspot,
        ]
    );

    const hotspotNavIcon = (link) => {
        const iconId =
            link.hotspot_icon_id || link.hotspot_icon_id === 0
                ? link.hotspot_icon_id
                : link.hotspot_icon
                ? link.hotspot_icon.hotspot_icon_id
                : activeAsset.nav_hotspot_icon_id;
        if (!iconId || iconId === 0) {
            const defaultIcon =
                hotspotIcons?.length > 0 &&
                hotspotIcons.find(
                    (data) =>
                        data?.is_default &&
                        data?.hotspot_type_id === HotspotIconTypes.Navigation.id
                );

            if (!defaultIcon) {
                return hotspotIcons.find(
                    (data) =>
                        data?.hotspot_type_id === HotspotIconTypes.Navigation.id
                );
            }
            return defaultIcon;
        }
        return (
            hotspotIcons?.length > 0 &&
            hotspotIcons.find((data) => data?.hotspot_icon_id === iconId)
        );
    };

    function CreateHotspotElementsFromZone(zoneInfo) {
        const hotspots = [];
        let navLinks = zoneInfo.nav_links;
        let items = zoneInfo.items;

        items?.forEach((item) => {
            const element = document.createElement('div');
            element.classList.add('hotspot');
            element.classList.add('info-hotspot');
            element.id = `info-${item.item_id}`;

            const root = createRoot(element);
            root.render(
                <PanoItemHotspot
                    zoneId={zoneInfo.zone_id}
                    config={config}
                    hotspot={item}
                    icon={hotspotItemIcon(item)}
                    onClicked={onSelectHotspot}
                    setDraggableFlag={setDraggableFlag}
                    readOnly={readOnly}
                    enableDragging={enableItemDragging}
                    emptyItemOpacity={emptyItemOpacity}
                />
            );

            hotspots.push({
                item_id: item.item_id,
                element: element,
            });
            // }
        });

        navLinks?.forEach((navLink) => {
            if (!hotspots.find((i) => i.nav_link_id === navLink.nav_link_id)) {
                const element = document.createElement('div');
                element.classList.add('hotspot');
                element.classList.add('link-hotspot');
                element.id = `link-${navLink.nav_link_id}`;

                const root = createRoot(element);
                root.render(
                    <PanoLinkHotspot
                        config={config}
                        hotspot={navLink}
                        icon={hotspotNavIcon(navLink)}
                        allZones={allZoneData}
                        onNavClicked={onLocalNavClicked}
                        setDraggableFlag={setDraggableFlag}
                        readOnly={readOnly}
                        enableDragging={enableNavDragging}
                    />
                );

                hotspots.push({
                    nav_link_id: navLink.nav_link_id,
                    element: element,
                });
            }
        });

        return hotspots;
    }

    useEffect(() => {
        if (setHotspotElements) {
            const hotspotByZone = {};

            const zone = allZoneData.find(
                (zone) => zone.zone_id === activeZone.zone_id
            );

            const hotspots = CreateHotspotElementsFromZone(zone);
            hotspotByZone[zone.zone_id] = hotspots;

            setHotspotElements(hotspotByZone);
        }
    }, [activeZone, selectedSearchItemId]); // eslint-disable-line react-hooks/exhaustive-deps

    const onLocalNavClicked = async (zoneId) => {
        const zone = allZoneData.find((zone) => zone.zone_id === zoneId);
        if (zone) {
            dispatch(loadZone({ zoneId: zone.zone_id }));
        }
    };

    if (!activeZone || !activeZone.display_name) {
        return null;
    }

    const handleResetPosition = () => {
        if (window.geminiViewManager) {
            window.geminiViewManager.resetViewParams();
        }
    };

    const orbitMode =
        activeZone.orbit_frame !== undefined && orbitImagesCount > 1
            ? true
            : false;

    return (
        <div
            style={{ height: '100%', position: 'relative', overflow: 'hidden' }}
        >
            <GeminiTitleBar
                hideNavOptions={hideNavOptions}
                activeZone={activeZone}
                zonesByCategory={zonesByCategory}
                onCategoryClick={handleCategoryClick}
                onSearchItems={onSearchItems}
                searchItemSelected={searchItemSelected}
                handleResetPosition={handleResetPosition}
                onClose={onClose}
                onClickRefreshItem={onClickRefreshItem}
                handleItemDragging={handleItemDragging}
                handleNavDragging={handleNavDragging}
                enableItemDragging={enableItemDragging}
                enableNavDragging={enableNavDragging}
                undoRedoProps={undoRedoProps}
                isOrbitMode={orbitMode}
                tridiRef={tridiRef}
                setSplitViewTopPos={setSplitViewTopPos}
                zoneMediaConfigValue={zoneMediaConfigValue}
            />
            <div style={{ height: 'calc(100% - 40px)', width: '100%' }}>
                {isProcedureLoaded && config.modeType === 'viewer' && (
                    <Box
                        className={commonStyles.splitViewModes}
                        style={{
                            top: `${splitViewTopPos * 40}px`,
                        }}
                    >
                        {Object.keys(ProcedureSplitViewTypes).map(
                            (viewType) =>
                                viewType !== 'None' && (
                                    <SplitViewButton
                                        key={`${ProcedureSplitViewTypes[viewType]?.id}`}
                                        viewType={
                                            ProcedureSplitViewTypes[viewType]
                                        }
                                        splitViewMode={splitViewMode}
                                        setSplitViewMode={setSplitViewMode}
                                    />
                                )
                        )}
                        {loadedAssetType === 3 && (
                            <Tooltip title="Hide Asset">
                                <IconButton
                                    onClick={() => {
                                        setShowAssociatedAsset(false);
                                    }}
                                    style={{
                                        padding: 0,
                                        color: '#ffffff',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <MdClose
                                        className="react-icon"
                                        fontWeight={'1000'}
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                )}
                <div
                    ref={viewerRef}
                    className={clsx([
                        commonStyles.viewWrapper,
                        orbitMode
                            ? commonStyles.orbitViewWrapper
                            : commonStyles.panoViewWrapper,
                    ])}
                    style={{ height: '100%' }}
                    // onPointerMove={(e) => onDragTagContainer(e)}
                    // onPointerUp={(e) => setAllowMoving(false)}
                >
                    {orbitMode ? (
                        <OrbitViewer
                            config={config}
                            hotspotIcons={hotspotIcons}
                            draggableFlag={draggableFlag}
                            setDraggableFlag={setDraggableFlag}
                            onSelectHotspot={onSelectHotspot}
                            onShiftRightClick={onShiftRightClick}
                            onRightClick={onRightClick}
                            onUpdatedHotspot={onUpdatedHotspot}
                            onDeletedHotspot={onDeletedHotspot}
                            onUpdatedLinkHotspot={onUpdatedLinkHotspot}
                            onDeletedLinkHotspot={onDeletedLinkHotspot}
                            onEditClicked={onEditClicked}
                            onItemNavClicked={onItemNavClicked}
                            onNavClicked={
                                onNavClicked ? onNavClicked : onLocalNavClicked
                            }
                            onClose={onClose}
                            PanoItemHotspot={PanoItemHotspot}
                            PanoLinkHotspot={PanoLinkHotspot}
                            onClick={onClick}
                            isMultipleHotspotEnabled={isMultipleHotspotEnabled}
                            itemHotspots={itemHotspots}
                            navigationHotspots={navigationHotspots}
                            zonesByCategory={zonesByCategory}
                            onCategoryClick={handleCategoryClick}
                            onSearchItems={onSearchItems}
                            searchItemSelected={searchItemSelected}
                            onClickRefreshItem={onClickRefreshItem}
                            hideNavOptions={hideNavOptions}
                            enableItemDragging={enableItemDragging}
                            enableNavDragging={enableNavDragging}
                            undoRedoProps={undoRedoProps}
                            tridiRef={tridiRef}
                            splitViewMode={splitViewMode}
                            viewerRef={viewerRef}
                        />
                    ) : (
                        <MarzipanoViewer
                            hotspotElements={hotspotElements}
                            // onSelectHotSpot={onSelectHotspot}
                            onShiftRightClick={onShiftRightClick}
                            onRightClick={onRightClick}
                            onClick={onClick}
                            isMultipleHotspotEnabled={isMultipleHotspotEnabled}
                            onClose={onClose}
                            zonesByCategory={zonesByCategory}
                            onCategoryClick={handleCategoryClick}
                            onSearchItems={onSearchItems}
                            searchItemSelected={searchItemSelected}
                            onClickRefreshItem={onClickRefreshItem}
                            setActiveItem={setActiveItem}
                            hideNavOptions={hideNavOptions}
                            undoRedoProps={undoRedoProps}
                            handleMultipleHotspot={handleMultipleHotspot}
                        />
                    )}
                    {config.modeType === 'author' && (
                        <>
                            <TagBar
                                tagContainerRef={tagContainerRef}
                                itemTagsList={itemTagsList}
                                setItemTagsList={setItemTagsList}
                            />
                            <SidePanel
                                linkTypes={config.linkConfig.LinkTypes}
                                audienceIds={config.user.audienceIds}
                                zoneMediaConfigValue={zoneMediaConfigValue}
                            />
                            <FlyerDisplay />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export { GeminiContainer };
