import React from 'react';
import { PageHeader, AdminTabPanel } from 'components';
import { useStyles } from 'scenes/styles';
import { FiBox } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { clearCacheStore } from 'GeminiViewerComponent/_helpers';
import { fetchTags } from '_features/tags/tagsSlice';
import { getAssetTypes } from '_features/assets/assetManagerSlice';

function Admin() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onRefreshIconClick = () => {
        clearCacheStore();
        dispatch(fetchTags({ sort: 'name' }));
        dispatch(getAssetTypes());
    };

    return (
        <div className={classes.content}>
            <PageHeader
                header="Admin Manager"
                icon={<FiBox />}
                onRefreshIconClick={onRefreshIconClick}
            />
            <AdminTabPanel />
        </div>
    );
}

export { Admin };
