import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useStyles } from './styles';
import { Button, TextField } from '@mui/material';
import {
    fetchMoreExternalContent,
    selectAllExternalContent,
    createContent,
    resetContentStatus,
} from '_features/content/contentSlice';
import {
    activateLoading,
    deactivateLoading,
    setProgress,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    resetLinkStatus,
    createLink,
} from 'GeminiViewerComponent/_features/links/linksSlice';
import { AutoSizer } from 'react-virtualized';
import NativeSelect from '@mui/material/NativeSelect';
import FormControl from '@mui/material/FormControl';
import { ContentTypes, LinkTypes } from '_helpers';
import { toggleSchooxTab } from '_features/globals/visibilitySlice';
import {
    labelHeader,
    labelCell,
    checkCell,
    viewLink,
    schooxNestedLabelCell,
    VirtualTable,
} from 'components';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { selectEditItemId } from '_features/common/editItemSlice';
import { DisplayTypes } from 'GeminiViewerComponent/_helpers';

function TableGrid({
    externalAssests,
    pageInfo,
    searchString,
    filterByTag,
    category,
    zoneTag,
}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const tableClasses = tableStyles();
    const [selected, setSelected] = useState([]);
    const [contentDisplayName, setContentDisplayName] = useState('');
    const [contentDescription, setContentDescription] = useState('');
    const [target, setTarget] = useState(DisplayTypes.External.id);
    const [showInputs, setShowInputs] = useState(false);

    const editItemId = useSelector(selectEditItemId);
    const allRows = useSelector(selectAllExternalContent);
    const loadedRowsMap = useSelector((state) => state.content.loadedRowsMap);

    const headerHeight = 40;
    const rowHeight = 60;

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleClick = (event, id, display_name) => {
        const selectedIndex = selected.indexOf(id);
        if (selectedIndex === -1) {
            setSelected([id]);
            setShowInputs(true);
            setContentDisplayName(display_name);
            setContentDescription(display_name);
        } else if (selectedIndex === 0) {
            resetLinkState();
        }
    };

    const resetLinkState = () => {
        setSelected([]);
        setContentDisplayName('');
        setContentDescription('');
        setShowInputs(false);
        setTarget(DisplayTypes.External.id);
    };

    const columns = [
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: '',
            dataKey: 'external_id',
            padding: 'checkbox',
            cellLevel: 'external',
            handleClick: handleClick,
            isSelected: isSelected,
            component: checkCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 200,
            minWidth: 120,
            label: 'Name',
            dataKey: 'display_name',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 200,
            minWidth: 120,
            label: 'Category',
            dataKey: 'category',
            nestedDataKey: 'name',
            padding: 'normal',
            component: schooxNestedLabelCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            minWidth: 80,
            maxWidth: 80,
            width: 80,
            label: '',
            dataKey: 'external_id',
            padding: 'none',
            dispatch: dispatch,
            component: viewLink,
            headerComponent: labelHeader,
        },
    ];

    function loadMoreRows({ startIndex, stopIndex }) {
        dispatch(
            fetchMoreExternalContent({
                startIndex: startIndex,
                stopIndex: stopIndex,
                contentType: ContentTypes.Schoox.label,
                useTagFilter: filterByTag,
                tagFilter: zoneTag ?? '',
                searchString: searchString,
                categoryId: category,
            })
        );
    }

    const handleCreate = async () => {
        dispatch(activateLoading({ showProgress: true }));
        const { external_id, url } = externalAssests.find(
            (a) => a.external_id === selected[0]
        );
        const newContent = {
            external_id: external_id,
            display_name: contentDisplayName,
            content_type_id: ContentTypes.Schoox.id,
            content_version: { url: url },
            dispatch,
        };
        const createdContent = await dispatch(createContent(newContent));
        dispatch(
            setProgress({
                progress: 0,
                label: 'Step 1 of 2: Uploading',
                noAnimation: true,
            })
        );
        if (!createdContent.error) {
            await dispatch(
                createLink({
                    item_id: editItemId,
                    display_name: contentDisplayName,
                    description: contentDescription,
                    content_id: createdContent.payload.content_id,
                    content_version_id:
                        createdContent.payload.latest_content_version
                            .content_version_id,
                    link_type_id: LinkTypes.Schoox.id,
                    display_type_id: parseInt(target),
                })
            );
            dispatch(toggleSchooxTab());
            resetLinkState();
            dispatch(resetLinkStatus());
            dispatch(resetContentStatus());
            dispatch(deactivateLoading());
        }
    };

    return (
        <div className={classes.root}>
            <div
                style={{
                    height: 'calc(100vh - 350px)',
                    minWidth: '750px',
                    marginTop: '25px',
                }}
            >
                <AutoSizer>
                    {({ width, height }) => (
                        <VirtualTable
                            initialRows={externalAssests}
                            allRows={allRows}
                            totalRowCount={pageInfo.TotalCount}
                            loadedRowsMap={loadedRowsMap}
                            loadMoreRows={loadMoreRows}
                            columnDefinitions={columns}
                            cellClassName={tableClasses.flexContainer}
                            className={tableClasses.table}
                            rowClassName={tableClasses.flexContainer}
                            headerHeight={headerHeight}
                            width={width}
                            height={height}
                            rowHeight={rowHeight}
                        />
                    )}
                </AutoSizer>
            </div>
            <div className={clsx(classes.buttonContainer)}>
                <div
                    className={clsx(classes.containerRight, {
                        [classes.hidden]: !showInputs,
                    })}
                >
                    <TextField
                        className={classes.formInput}
                        label="Display Name"
                        variant="outlined"
                        size="small"
                        onChange={(evt) =>
                            setContentDisplayName(evt.target.value)
                        }
                        value={contentDisplayName}
                    />
                    <TextField
                        className={classes.formInput}
                        label="Description"
                        variant="outlined"
                        size="small"
                        onChange={(evt) =>
                            setContentDescription(evt.target.value)
                        }
                        value={contentDescription}
                    />
                    <FormControl className={classes.formControl}>
                        <NativeSelect
                            value={target}
                            onChange={(e) => setTarget(e.target.value)}
                            name="catgeory"
                            className={classes.selectEmpty}
                        >
                            <option value={DisplayTypes.Inline.id}>
                                {DisplayTypes.Inline.label}
                            </option>
                            <option value={DisplayTypes.External.id}>
                                {DisplayTypes.External.label}
                            </option>
                        </NativeSelect>
                    </FormControl>
                </div>
                <div>
                    <Button
                        className={classes.buttonSpacing}
                        onClick={() => dispatch(toggleSchooxTab())}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        className={clsx(classes.buttonSpacing, {
                            [classes.hidden]: !showInputs,
                        })}
                        onClick={handleCreate}
                        variant="contained"
                        color="primary"
                        disableElevation
                    >
                        Create
                    </Button>
                </div>
            </div>
        </div>
    );
}

export { TableGrid };
