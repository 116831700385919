import { makeStyles } from '@mui/styles';

const flyerDisplayStyles = makeStyles(() => ({
    modalContainer: {
        position: 'absolute',
        backgroundColor: 'white',
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        '&:focus': {
            outline: 'none',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '5px',
        boxShadow: '0 0 5px #444444',
        padding: '20px',
        width: '85%',
        height: '90%',
    },
    close: {
        marginTop: '10px',
        width: '100%',
        background: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        '&:hover': {
            background: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(90%)',
        },
    },
}));

export { flyerDisplayStyles };
