import React from 'react';
import { Field } from 'formik';
import { Select, Switch, TextField } from 'formik-mui';
import { useSelector } from 'react-redux';

import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { Box, MenuItem } from '@mui/material';
import { makeFormStyles } from 'forms/styles';

export const FormTextField = (props) => {
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    return (
        <Field
            component={TextField}
            margin="dense"
            variant="outlined"
            className={classes.materialInput}
            type="text"
            {...props}
        />
    );
};

export const FormSwitchField = (props) => {
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    return (
        <Box>
            <Field
                component={Switch}
                type="switch"
                margin="dense"
                variant="outlined"
                className={classes.materialInput}
                {...props}
            />
            {props?.label}
        </Box>
    );
};

export const FormSelectField = (props) => {
    const {
        name,
        label,
        placeholder,
        disabled,
        onChange,
        entities,
        nameProperty,
        namePrefix,
        keyProperty,
        valueProperty,
        optionProperty,
        optionStyleProperty,
        defaultOptionName,
        defaultOptionValue,
        defaultOptionDisabled,
    } = props;

    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);

    return (
        <Box className={classes.materialSelect}>
            <Field
                component={Select}
                margin="dense"
                variant="outlined"
                name={name}
                label={label}
                placeholder={placeholder}
                disabled={disabled}
                onChange={onChange}
            >
                {defaultOptionName && defaultOptionValue && (
                    <MenuItem
                        value={defaultOptionValue}
                        disabled={defaultOptionDisabled ? true : false}
                    >
                        {defaultOptionName}
                    </MenuItem>
                )}
                {Array.isArray(entities) ? (
                    entities.length > 0 ? (
                        entities.map((value, idx) => (
                            <MenuItem
                                key={value?.[keyProperty] || idx}
                                value={value[valueProperty]}
                                style={{
                                    [optionProperty]:
                                        value?.[optionStyleProperty],
                                }}
                            >
                                {namePrefix && namePrefix}
                                {value?.[nameProperty || 'display_name'] ||
                                    `-# Option ${idx + 1}`}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>No Options</MenuItem>
                    )
                ) : Object.keys(entities ?? {}).length > 0 ? (
                    Object.keys(entities).map((value, idx) => (
                        <MenuItem
                            key={entities[value]?.[keyProperty] || idx}
                            value={entities[value][valueProperty]}
                            style={{
                                [optionProperty]: value?.[optionStyleProperty],
                            }}
                        >
                            {namePrefix && namePrefix}
                            {entities[value]?.[
                                nameProperty || 'display_name'
                            ] || `-# Option ${idx + 1}`}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem disabled>No Options</MenuItem>
                )}
            </Field>
        </Box>
    );
};
