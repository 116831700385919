import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { inspectionsService } from '../services/inspection.service';

const initialState = {
    error: null,
    inspectionActionList: [],
    inspectionReasonList: [],
    inspectionAction: [],
    inspectionReason: [],
    actionListloadingStatus: LoadingStatus.Idle,
    reasonListloadingStatus: LoadingStatus.Idle,
    savingStatus: SavingStatus.Idle,
};

export const fetchInspectionActionList = createAsyncThunk(
    'inspection/fetchInspectionActionList',
    async () => {
        return await inspectionsService.getInspectionAction();
    }
);

export const fetchInspectionActionById = createAsyncThunk(
    'inspection/fetchInspectionActionById',
    async (actionId) => {
        return await inspectionsService.getInspectionActionById(actionId);
    }
);

export const createInspectionAction = createAsyncThunk(
    'inspection/createInspectionAction',
    async (params) => {
        return await inspectionsService.createInspectionAction(params);
    }
);

export const updateInspectionAction = createAsyncThunk(
    'inspection/updateInspectionAction',
    async (params) => {
        return await inspectionsService.updateInspectionAction(params);
    }
);

export const fetchInspectionReasonList = createAsyncThunk(
    'inspection/fetchInspectionReasonList',
    async () => {
        return await inspectionsService.getInspectionReason();
    }
);

export const fetchInspectionReasonById = createAsyncThunk(
    'inspection/fetchInspectionReasonById',
    async (reasonId) => {
        return await inspectionsService.getInspectionReasonById(reasonId);
    }
);

export const createInspectionReason = createAsyncThunk(
    'inspection/createInspectionReason',
    async (params) => {
        const response = await inspectionsService.createInspectionReason(
            params
        );
        return response;
    }
);

export const updateInspectionReason = createAsyncThunk(
    'inspection/updateInspectionReason',
    async (params) => {
        const response = await inspectionsService.updateInspectionReason(
            params
        );
        return response;
    }
);

const inspectionSlice = createSlice({
    name: 'inspection',
    initialState,
    reducers: {
        setInspectionActionList: (state, action) => {
            state.inspectionActionList = action.payload;
        },
        setInspectionReasonList: (state, action) => {
            state.inspectionReasonList = action.payload;
        },
        setInspectionAction: (state, action) => {
            state.inspectionAction = action.payload;
        },
        setInspectionReason: (state, action) => {
            state.inspectionReason = action.payload;
        },
    },
    extraReducers: {
        [fetchInspectionActionList.fulfilled]: (state, action) => {
            state.actionListloadingStatus = LoadingStatus.Loaded;
            state.inspectionActionList = action.payload;
        },
        [fetchInspectionActionList.rejected]: (state, action) => {
            state.actionListloadingStatus = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [fetchInspectionActionById.fulfilled]: (state, action) => {
            state.inspectionAction = action.payload;
        },
        [fetchInspectionActionById.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        [createInspectionAction.pending]: (state) => {},
        [createInspectionAction.fulfilled]: (state, action) => {
            state.actionListloadingStatus = LoadingStatus.Idle;
            state.inspectionAction = action.payload;
        },
        [updateInspectionAction.pending]: (state) => {
            state.actionListloadingStatus = LoadingStatus.Loading;
        },
        [updateInspectionAction.fulfilled]: (state, action) => {
            state.actionListloadingStatus = LoadingStatus.Idle;
            state.inspectionAction = action.payload;
        },
        [fetchInspectionReasonList.fulfilled]: (state, action) => {
            state.reasonListloadingStatus = LoadingStatus.Loaded;
            state.inspectionReasonList = action.payload;
        },
        [fetchInspectionReasonList.rejected]: (state, action) => {
            state.reasonListloadingStatus = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [createInspectionReason.pending]: (state) => {},
        [createInspectionReason.fulfilled]: (state, action) => {
            state.reasonListloadingStatus = LoadingStatus.Idle;
            state.inspectionReason = action.payload;
        },
        [updateInspectionReason.pending]: (state) => {},
        [updateInspectionReason.fulfilled]: (state, action) => {
            state.reasonListloadingStatus = LoadingStatus.Idle;
            state.inspectionReason = action.payload;
        },
    },
});

export const getInspectionErrorMessage = (state) => state.inspection.error;
export const getActionListLoadingStatus = (state) =>
    state.inspection.actionListloadingStatus;
export const getReasonListLoadingStatus = (state) =>
    state.inspection.reasonListloadingStatus;
export const getInspectionActionList = (state) =>
    state.inspection.inspectionActionList;
export const getInspectionReasonList = (state) =>
    state.inspection.inspectionReasonList;
export const getInspectionAction = (state) => state.inspection.inspectionAction;
export const getInspectionReason = (state) => state.inspection.inspectionReason;

export const {
    setInspectionActionList,
    setInspectionReasonList,
    setInspectionAction,
    setInspectionReason,
} = inspectionSlice.actions;

export default inspectionSlice.reducer;
