import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkTableGrid } from './LinkTableGrid';
import {
    fetchItemLinksPage,
    selectAllItemLinks,
    getItemLinksPageInfo,
} from 'GeminiViewerComponent/_features/links/linksSlice';
import { Typography } from '@mui/material';
import create_asset from 'assets/create_asset.png';
import { useStyles } from './styles';
import { makeFormStyles } from 'forms/styles';
import { selectEditItemId } from '_features/common/editItemSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';

function ItemLinkTable({ linkTypeId, editAllow = true }) {
    const classes = useStyles();

    const theme = useSelector(selectActiveTheme);

    const formClasses = makeFormStyles(theme);

    const dispatch = useDispatch();
    const itemLinks = useSelector(selectAllItemLinks);
    const editItemId = useSelector(selectEditItemId);

    const itemLinkPageInfo = useSelector((state) =>
        getItemLinksPageInfo(state)
    );
    const itemLinkStatus = useSelector((state) => state.links.status);
    useEffect(() => {
        if (itemLinkStatus === LoadingStatus.Idle && editItemId !== undefined) {
            dispatch(
                fetchItemLinksPage({
                    page: 1,
                    pageSize: 10,
                    itemId: editItemId,
                    linkTypeId: linkTypeId,
                })
            );
        }
    }, [itemLinkStatus, editItemId, dispatch, linkTypeId]);

    return itemLinkStatus === LoadingStatus.Loaded && itemLinks.length === 0 ? (
        <div className={classes.createNew}>
            <Typography className={classes.createNewTitle}>
                There are no links. Start by creating one above.
            </Typography>
            <img
                className={classes.createNewImage}
                alt="asset"
                src={create_asset}
            ></img>
        </div>
    ) : itemLinkStatus === LoadingStatus.Loaded ? (
        <LinkTableGrid
            itemLinks={itemLinks}
            pageInfo={itemLinkPageInfo}
            editAllow={editAllow}
        />
    ) : (
        <LoadingLogo styleClassName={formClasses.loadingSvg} />
    );
}

export { ItemLinkTable };
