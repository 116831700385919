const IncorrectFieldSet = ({ children }) => {
    return (
        <fieldset
            style={{
                marginTop: '-12px',
                border: '1px solid #cccccc',
                borderRadius: '5px',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <legend
                style={{
                    marginLeft: '10px',
                    padding: '5px',
                    fontSize: '13px',
                    color: '#6d7178',
                }}
            >
                Is Incorrect?
            </legend>
            {children}
        </fieldset>
    );
};

export default IncorrectFieldSet;
