import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import {
    selectAllItemLinks,
    fetchMoreItemLinks,
} from 'GeminiViewerComponent/_features/links/linksSlice';
import { AutoSizer } from 'react-virtualized';
import {
    labelHeader,
    viewLink,
    actionCell,
    checkCell,
    VirtualTable,
    itemLabelCell,
} from 'components';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { accountsSlice } from 'app/store';
import { selectEditItemId } from '_features/common/editItemSlice';

function LinkTableGrid({ itemLinks, pageInfo, editAllow }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const tableClasses = tableStyles();
    const [selected, setSelected] = React.useState([]);

    const editItemId = useSelector(selectEditItemId);
    const allRows = useSelector(selectAllItemLinks);
    const loadedRowsMap = useSelector((state) => state.links.loadedRowsMap);
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    const headerHeight = 40;
    const rowHeight = 50;

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
        // setSelectedAll(selected.length === allRows.length);
    };

    let columns = [
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 200,
            minWidth: 120,
            label: 'Link Name',
            dataKey: 'display_name',
            padding: 'normal',
            component: itemLabelCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            label: '',
            dataKey: 'link_id',
            padding: 'none',
            component: actionCell,
            actionCellLevel: 'link',
            targetPopup: editAllow === false ? 'techu_link' : 'link',
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            minWidth: 80,
            maxWidth: 80,
            width: 80,
            label: '',
            dataKey: 'link_id',
            padding: 'none',
            dispatch: dispatch,
            component: viewLink,
            headerComponent: labelHeader,
        },
    ];
    if (readOnlyMode !== true) {
        columns = [
            {
                classes: tableClasses,
                minWidth: 50,
                maxWidth: 50,
                width: 50,
                label: '',
                dataKey: 'link_id',
                padding: 'checkbox',

                handleClick: handleClick,
                isSelected: isSelected,
                component: checkCell,
                headerComponent: labelHeader,
                cellLevel: 'link',
            },
            ...columns,
        ];
    }

    function loadMoreRows({ startIndex, stopIndex }) {
        dispatch(
            fetchMoreItemLinks({
                startIndex: startIndex,
                stopIndex: stopIndex,
                itemId: editItemId,
            })
        );
    }

    return (
        <div className={classes.root}>
            <div
                style={{ height: '100%', minWidth: '300px', marginTop: '10px' }}
            >
                <AutoSizer>
                    {({ width, height }) => (
                        <VirtualTable
                            initialRows={itemLinks}
                            allRows={allRows}
                            totalRowCount={pageInfo.TotalCount}
                            loadedRowsMap={loadedRowsMap}
                            loadMoreRows={loadMoreRows}
                            columnDefinitions={columns}
                            cellClassName={tableClasses.flexContainer}
                            className={tableClasses.table}
                            rowClassName={tableClasses.flexContainer}
                            headerHeight={headerHeight}
                            disableHeader={true}
                            width={width}
                            height={height}
                            rowHeight={rowHeight}
                        />
                    )}
                </AutoSizer>
            </div>
        </div>
    );
}

export { LinkTableGrid };
