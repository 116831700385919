import React from 'react';

import { FormTextField } from 'components/TextField';

const VariableField = ({ validationError }) => {
    return (
        <FormTextField
            label="Variable"
            name="variable"
            placeholder="Variable"
            error={!!validationError}
            helperText={validationError}
        />
    );
};

export default VariableField;
