import clsx from 'clsx';
import { useStyles } from './styles';
import { Avatar, Divider, Link, Typography } from '@mui/material';
import { HiOutlineMail } from 'react-icons/hi';
import { FiPhone } from 'react-icons/fi';
import { BiVideo } from 'react-icons/bi';

function SmeView({ sme, companionSME = false }) {
    const classes = useStyles();
    const smeContent = sme?.additional_data
        ? JSON.parse(sme.additional_data)
        : {};

    return (
        <div
            className={classes.smeViewContainer}
            style={{ width: `${companionSME ? '100%' : '50%'}` }}
        >
            <div className={classes.smeTopInputs}>
                <div>
                    <Avatar
                        className={clsx(classes.avatar)}
                        alt={''}
                        src={sme?.url}
                    />
                </div>
                <div className={classes.smeInput}>
                    <Typography
                        className={classes.smeName}
                        style={{ width: '100%' }}
                    >
                        {smeContent.sme_name ?? '--'}
                    </Typography>
                    <Typography
                        className={classes.smeField}
                        style={{ width: '100%' }}
                    >
                        {smeContent.sme_title ?? '--'}
                    </Typography>
                </div>
            </div>
            <Divider />
            <div>
                <div className={classes.smeInputContainer}>
                    <HiOutlineMail className={classes.smeInputIcon} />
                    <div className={classes.smeInput}>
                        <div>Email</div>
                        <Typography
                            className={classes.smeField}
                            style={{ width: '100%' }}
                        >
                            {smeContent.sme_email ? (
                                <Link
                                    href={`mailto:${smeContent.sme_email}`}
                                    target="_blank"
                                    rel="noopener"
                                >
                                    {smeContent.sme_email}
                                </Link>
                            ) : (
                                '--'
                            )}
                        </Typography>
                    </div>
                </div>
                <Divider />
                <div className={classes.smeInputContainer}>
                    <FiPhone className={classes.smeInputIcon} />
                    <div className={classes.smeInput}>
                        <div>Phone</div>
                        <Typography
                            className={classes.smeField}
                            style={{ width: '100%' }}
                        >
                            {smeContent.sme_phone ? (
                                <Link
                                    href={`tel:${smeContent.sme_phone}`}
                                    target="_blank"
                                    rel="noopener"
                                >
                                    {smeContent.sme_phone}
                                </Link>
                            ) : (
                                '--'
                            )}
                        </Typography>
                    </div>
                </div>
                {smeContent.sme_zoom && (
                    <>
                        <Divider />
                        <div className={classes.smeInputContainer}>
                            <BiVideo className={classes.smeInputIcon} />
                            <div className={classes.smeInput}>
                                <div>Zoom ID</div>
                                <Typography
                                    className={classes.smeField}
                                    style={{ width: '100%' }}
                                >
                                    {smeContent.sme_zoom ? (
                                        <Link
                                            href={smeContent.sme_zoom}
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            Click to open
                                        </Link>
                                    ) : (
                                        '--'
                                    )}
                                </Typography>
                            </div>
                        </div>
                    </>
                )}
                {smeContent.sme_teams && (
                    <>
                        <Divider />
                        <div className={classes.smeInputContainer}>
                            <BiVideo className={classes.smeInputIcon} />
                            <div className={classes.smeInput}>
                                <div>Teams ID</div>
                                <Typography
                                    className={classes.smeField}
                                    style={{ width: '100%' }}
                                >
                                    {smeContent.sme_teams ? (
                                        <Link
                                            href={smeContent.sme_teams}
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            Click to open
                                        </Link>
                                    ) : (
                                        '--'
                                    )}
                                </Typography>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export { SmeView };
