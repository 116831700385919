import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
    createStyles({
        popupWrapper: {
            zIndex: '9999',
            backgroundColor: '#ffffff',
            margin: '0 0.8rem !important',
            padding: '0.5rem 1rem',
            boxShadow: '8px 3px 18px 2px rgba(0,0,0,0.2)',
            borderRadius: '4px',
        },
        popupActionContainer: {
            display: 'flex',
            alignItems: 'center',
            color: '#7E8C8D',
            padding: '10px',
            fontSize: '14px',
            '&:hover': {
                cursor: 'pointer',
                transform: 'scale(1.05)',
            },
        },
        popupActionIcon: {
            display: 'flex',
            justifyContent: 'center',
            '& svg': {
                height: '20px',
                width: '20px',
            },
        },
        popupActionLabel: {
            textTransform: 'capitalize',
        },
    })
);

export { useStyles };
