import React from 'react';

const FieldValidationError = ({ validationError }) => {
    return (
        <div
            style={{
                color: '#d32f2f',
                margin: '4px 14px',
                fontSize: '0.75rem',
                textAlign: 'left',
                fontFamily: 'Open Sans',
                lineHeight: 1.66,
            }}
        >
            {validationError}
        </div>
    );
};

export default FieldValidationError;
