import { makeStyles } from '@mui/styles';

export const manageSnippetDialogStyles = makeStyles(() => ({
    dialogTitle: {
        backgroundColor: 'lightgray',
        fontSize: '26px',
        fontWeight: '700',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    tableHeader: {
        paddingTop: '5px',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
    },
    tableHeaderContainer: {
        color: '#606060',
        display: 'flex',
        alignItems: 'center',
        padding: '10px 20px',
        borderBottom: '1px solid #606060',
        textAlign: 'left',
    },
    collapseRow: {
        width: '100%',
        fontSize: '18px',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
    },
    popupActionCell: {
        borderBottom: 'none',
        width: '4%',
        padding: 0,
    },
    nestedTableCell: {
        borderBottom: 'none',
        width: '48%',
        fontSize: '16px',
    },
    nestedTableText: {
        width: '210px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
    },
}));
