import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContentSubTab } from '../LinkContentSubTab';
import { Button } from '@mui/material';

import { ContentForm } from 'forms/ContentForm';
import { EmbedForm } from 'forms/EmbedForm';
import { FormActions } from '_helpers/form-action';
import { LinkForm } from 'forms/LinkForm';
import { tabStyles } from 'GeminiViewerComponent/components/styles';
import { accountsSlice } from 'app/store';
import { selectDefaultItemPanelSection } from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import { resetLinkStatus } from 'GeminiViewerComponent/_features/links/linksSlice';
import { resetContentStatus } from '_features/content/contentSlice';
import { selectActiveAsset } from 'GeminiViewerComponent/_features/asset/assetSlice';

function GeneralContentTab({ contentName, linkTypeId, contentTypes }) {
    const dispatch = useDispatch();
    const [toggleLinkView, setToggleLinkView] = useState(false);
    const [toggleDocumentView, setToggleDocumentView] = useState(false);
    const [newContentClicked, setNewContentClicked] = useState(false);
    const defaultItemSection = useSelector(selectDefaultItemPanelSection);
    const displayAsset = useSelector(selectActiveAsset);
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    const classes = tabStyles();

    useEffect(() => {
        if (defaultItemSection) {
            dispatch(resetContentStatus());
            dispatch(resetLinkStatus());
            setToggleLinkView(true);
        }
    }, [defaultItemSection]);

    const navigateToContentForm = () => {
        setToggleLinkView(false);
        setToggleDocumentView(true);
        setNewContentClicked(true);
    };

    const navigateToLinkForm = () => {
        setToggleLinkView(true);
        setToggleDocumentView(false);
        setNewContentClicked(false);
    };

    return (
        <div className={classes.tabContent}>
            {!toggleLinkView && !toggleDocumentView ? (
                <>
                    {readOnlyMode !== true && (
                        <div className={classes.tabButtons}>
                            <Button
                                className={classes.tabButton}
                                onClick={(e) =>
                                    setToggleLinkView(!toggleLinkView)
                                }
                                variant="outlined"
                                color="primary"
                                disableElevation
                            >
                                New {contentName} Link
                            </Button>
                            <Button
                                className={classes.tabButton}
                                onClick={(e) => {
                                    setToggleDocumentView(!toggleDocumentView);
                                    if (newContentClicked) {
                                        setNewContentClicked(false);
                                    }
                                }}
                                variant="outlined"
                                color="primary"
                                disableElevation
                            >
                                New {contentName} Content
                            </Button>
                        </div>
                    )}
                    <LinkContentSubTab
                        linkTypeId={linkTypeId}
                        contentTypes={contentTypes}
                    />
                </>
            ) : (
                <>
                    {toggleDocumentView &&
                    contentName === 'Embed' &&
                    linkTypeId === 10 ? (
                        <EmbedForm
                            formAction={FormActions.Create}
                            linkTypeId={linkTypeId}
                            contentId={
                                contentTypes.length > 0 && contentTypes[0]?.id
                            }
                            tabView={true}
                            setToggleView={setToggleDocumentView}
                            selectVisibleAsset={displayAsset?.asset_id}
                        />
                    ) : (
                        toggleDocumentView && (
                            <ContentForm
                                formAction={FormActions.Create}
                                linkTypeId={linkTypeId}
                                tabView={true}
                                setToggleView={setToggleDocumentView}
                                selectVisibleAsset={displayAsset?.asset_id}
                                navigateToLinkForm={
                                    newContentClicked
                                        ? navigateToLinkForm
                                        : false
                                }
                            />
                        )
                    )}
                    {toggleLinkView && (
                        <LinkForm
                            formAction={FormActions.Create}
                            contentTypes={contentTypes}
                            linkTypeId={linkTypeId}
                            tabView={true}
                            setToggleView={setToggleLinkView}
                            navigateToContentForm={navigateToContentForm}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export { GeneralContentTab };
