import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { makeCommonFormPreviewStyles } from './styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import GroupField from './FormFields/GroupField';
import FormTextField from './FormFields/FormTextField';
import InitialField from './FormFields/InitialField';
import MultilineField from './FormFields/MultilineField';
import CheckboxField from './FormFields/CheckboxField';
import SelectField from './FormFields/SelectField';
import DateField from './FormFields/DateField';
import RadioGroupField from './FormFields/RadioGroupField';
import LabelField from './FormFields/LabelField';
import TableField from './FormFields/TableField';
import LinkField from './FormFields/LinkField';
import HiddenField from './FormFields/HiddenField';
import MediaField from './FormFields/MediaField';
import MediaCompareField from './FormFields/MediaCompareField';
import InspectionField from './FormFields/InspectionField';
import { FieldPreviewPopup } from './FormFields/components/FieldPreviewPopup';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';

const ProcedureFormNode = ({
    keyPrefix = '',
    isSubmitAllow = true,
    isReadOnly = false,
    formFields,
    isMobileWidth = false,
    handleBlur = () => {},
    selectedField = null,
    setSelectedField = () => {},
    resultNode = false,
    contentService = null,
}) => {
    const { setFieldValue, values, errors, setErrors } = useFormikContext();
    const theme = useSelector(selectActiveTheme);
    const config = useSelector(getConfig);
    const formStyles = makeCommonFormPreviewStyles(theme);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event, variable) => {
        if (config.modeType !== 'viewer' && !resultNode) {
            event.preventDefault();
            setSelectedField(variable);
            setAnchorEl(event.currentTarget);
        }
    };

    return (
        <>
            <FieldPreviewPopup
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                selectedField={selectedField}
            />
            {formFields &&
                formFields.map((fieldData, index) => {
                    if (fieldData.type === 'group') {
                        return (
                            <GroupField
                                data={fieldData}
                                fieldValue={values}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                setErrors={setErrors}
                                key={`${keyPrefix}${fieldData.id}${index}`}
                                isReadonly={isReadOnly}
                                isSubmitAllow={isSubmitAllow}
                                handleBlur={handleBlur}
                                handleClick={handleClick}
                                contentService={contentService}
                            />
                        );
                    } else if (fieldData.type === 'text') {
                        return (
                            <FormTextField
                                data={fieldData}
                                key={`${keyPrefix}${fieldData.id}${index}`}
                                isReadOnly={isReadOnly}
                                isSubmitAllow={isSubmitAllow}
                                fieldValue={values}
                                handleBlur={handleBlur}
                                handleClick={handleClick}
                            />
                        );
                    } else if (fieldData.type === 'initial') {
                        return (
                            <InitialField
                                data={fieldData}
                                key={`${keyPrefix}${fieldData.id}${index}`}
                                fieldValue={values}
                                errors={errors}
                                setErrors={setErrors}
                                setFieldValue={setFieldValue}
                                isReadOnly={isReadOnly}
                                isSubmitAllow={isSubmitAllow}
                                handleBlur={handleBlur}
                                handleClick={handleClick}
                            />
                        );
                    } else if (fieldData.type === 'multiline') {
                        return (
                            <MultilineField
                                data={fieldData}
                                key={`${keyPrefix}${fieldData.id}${index}`}
                                isReadOnly={isReadOnly}
                                isSubmitAllow={isSubmitAllow}
                                fieldValue={values}
                                handleBlur={handleBlur}
                                handleClick={handleClick}
                            />
                        );
                    } else if (fieldData.type === 'checkbox') {
                        return (
                            <CheckboxField
                                data={fieldData}
                                fieldValue={values}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                setErrors={setErrors}
                                validationError={errors?.[fieldData.variable]}
                                key={`${keyPrefix}${fieldData.id}${index}`}
                                isReadOnly={isReadOnly}
                                isSubmitAllow={isSubmitAllow}
                                handleBlur={handleBlur}
                                handleClick={handleClick}
                            />
                        );
                    } else if (fieldData.type === 'select') {
                        return (
                            <SelectField
                                data={fieldData}
                                fieldValue={values}
                                setFieldValue={setFieldValue}
                                validationError={errors?.[fieldData?.variable]}
                                key={`${keyPrefix}${fieldData.id}${index}`}
                                isReadOnly={isReadOnly}
                                isSubmitAllow={isSubmitAllow}
                                handleBlur={handleBlur}
                                handleClick={handleClick}
                            />
                        );
                    } else if (fieldData.type === 'datetime') {
                        return (
                            <DateField
                                data={fieldData}
                                isReadOnly={isReadOnly}
                                isSubmitAllow={isSubmitAllow}
                                fieldValue={values}
                                setFieldValue={setFieldValue}
                                validationError={errors?.[fieldData?.variable]}
                                key={`${keyPrefix}${fieldData.id}${index}`}
                                handleBlur={handleBlur}
                                handleClick={handleClick}
                            />
                        );
                    } else if (fieldData.type === 'radiogroup') {
                        return (
                            <RadioGroupField
                                data={fieldData}
                                fieldValue={values}
                                setFieldValue={setFieldValue}
                                validationError={errors?.[fieldData?.variable]}
                                key={`${keyPrefix}${fieldData.id}${index}`}
                                isReadOnly={isReadOnly}
                                isSubmitAllow={isSubmitAllow}
                                handleBlur={handleBlur}
                                handleClick={handleClick}
                            />
                        );
                    } else if (fieldData.type === 'label') {
                        return (
                            <LabelField
                                data={fieldData}
                                key={`${keyPrefix}${fieldData.id}${index}`}
                                isSubmitAllow={isSubmitAllow}
                                handleClick={handleClick}
                                contentService={contentService}
                            />
                        );
                    } else if (fieldData.type === 'table') {
                        return (
                            <TableField
                                data={fieldData}
                                fieldValue={values}
                                key={`${keyPrefix}${fieldData.id}${index}`}
                                errors={errors}
                                setErrors={setErrors}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                isReadOnly={isReadOnly}
                                isSubmitAllow={isSubmitAllow}
                                isMobileWidth={isMobileWidth}
                                handleClick={handleClick}
                                contentService={contentService}
                            />
                        );
                    } else if (fieldData.type === 'hidden') {
                        return (
                            <HiddenField
                                data={fieldData}
                                key={`${keyPrefix}${fieldData.id}${index}`}
                                setFieldValue={setFieldValue}
                                isSubmitAllow={isSubmitAllow}
                                handleClick={handleClick}
                            />
                        );
                    } else if (fieldData.type === 'link' && !isReadOnly) {
                        return (
                            <LinkField
                                data={fieldData}
                                fieldValue={values}
                                styles={formStyles}
                                key={`${keyPrefix}${fieldData.id}${index}`}
                                isSubmitAllow={isSubmitAllow}
                                handleClick={handleClick}
                            />
                        );
                    } else if (fieldData.type === 'media') {
                        return (
                            <MediaField
                                key={`${keyPrefix}${fieldData.id}`}
                                isSubmitAllow={isSubmitAllow}
                                errors={errors}
                                setErrors={setErrors}
                                data={fieldData}
                                handleClick={handleClick}
                            />
                        );
                    } else if (fieldData.type === 'mediacompare') {
                        return (
                            <MediaCompareField
                                key={`${keyPrefix}${fieldData.id}`}
                                data={fieldData}
                                isSubmitAllow={isSubmitAllow}
                                handleClick={handleClick}
                            />
                        );
                    } else if (fieldData.type === 'inspectionpoint') {
                        return (
                            <InspectionField
                                key={`${keyPrefix}${fieldData.id}${index}`}
                                data={fieldData}
                                fieldValue={values}
                                setFieldValue={setFieldValue}
                                isSubmitAllow={isSubmitAllow}
                                validationError={errors?.[fieldData?.variable]}
                                handleClick={handleClick}
                                handleBlur={handleBlur}
                            />
                        );
                    } else if (fieldData.type === 'pagebreak') {
                        return (
                            <div
                                key={`${keyPrefix}${fieldData.id}${index}`}
                                style={{ pageBreakBefore: 'always' }}
                            ></div>
                        );
                    }
                    return null;
                })}
        </>
    );
};

export default ProcedureFormNode;
