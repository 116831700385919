import { makeStyles } from '@mui/styles';

const actionListDialogStyle = makeStyles(() => ({
    addButtonIcon: {
        padding: '3px !important',
        borderRadius: '25px !important',
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
        margin: '10px 0 0 0',
    },
    actionListButton: {
        width: '20%',
        border: '1px solid #c4c4c4',
    },
}));

export { actionListDialogStyle };
