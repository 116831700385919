import React, { useState, useEffect, useCallback } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { MdSearch } from 'react-icons/md';

import { FormActions } from '_helpers/form-action';
import {
    createLink,
    selectTechUCourseStatus,
    getAllTechUCourses,
    selectTechUCourse,
    getTechUCourseContentByCourseId,
} from 'GeminiViewerComponent/_features/links/linksSlice';
import { ContentTypes } from '_helpers';
import { makeFormStyles } from '../styles';
import { loadItem, selectEditItem } from '_features/common/editItemSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { CourseAccordion } from './components/CourseAccordion';
import Loader from 'GeminiViewerComponent/components/Loader';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    createContent,
    fetchContentByTypeId,
} from '_features/content/contentSlice';
import { useConfigFields } from 'hooks/useConfigFields';
import { useConfigValues } from 'hooks/useConfigValues';
import {
    selectConfigValuesAssetId,
    selectConfigValuesByFields,
    selectConfigValuesClientId,
} from '_features/configValues/configValuesSlice';
import { config } from 'gemini-config';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';

const TechULinkForm = ({ formAction, linkTypeId, tabView, setToggleView }) => {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);

    const { configFields } = useConfigFields();
    const selectedClientId = useSelector(selectConfigValuesClientId);
    const selectedAssetId = useSelector(selectConfigValuesAssetId);

    const { configValues } = useConfigValues({
        clientId: selectedClientId,
        assetId: selectedAssetId,
    });
    const [expandedCourses, setExpandedCourses] = useState([]);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [selectedActivities, setSelectedActivities] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [token, setToken] = useState('');
    const editItem = useSelector(selectEditItem);
    const techUCourseStatus = useSelector(selectTechUCourseStatus);
    const techUCourses = useSelector(selectTechUCourse);

    const debouncedSearch = useCallback(
        debounce((searchString) => setSearchString(searchString), 1000),
        [searchString]
    );

    const handleSearch = (evt) => debouncedSearch(evt.target.value);

    const handleExpand = async (id, current) => {
        if (current) {
            setExpandedCourses(
                expandedCourses.filter((courseId) => courseId !== id)
            );
        } else {
            const course = techUCourses.find((crs) => crs.id === id);
            if (
                token &&
                course &&
                course.contentStatus === LoadingStatus.Idle
            ) {
                await dispatch(
                    getTechUCourseContentByCourseId({ token: token, id })
                );
            }
            setExpandedCourses([...expandedCourses, id]);
        }
    };

    const handleCourseClick = async (id, current) => {
        if (current) {
            setSelectedCourses(
                selectedCourses.filter((courseId) => courseId !== id)
            );
        } else {
            setSelectedCourses([...selectedCourses, id]);
        }
    };

    const handleActivityClick = async (id, current) => {
        if (current) {
            setSelectedActivities(
                selectedActivities.filter((courseId) => courseId !== id)
            );
        } else {
            setSelectedActivities([...selectedActivities, id]);
        }
    };

    useEffect(() => {
        const updatedResponse = selectConfigValuesByFields(
            configValues,
            configFields,
            ['techu_token']
        );
        if (updatedResponse?.['techu_token']) {
            setToken(updatedResponse?.['techu_token']);
        }
    }, [configValues, configFields]);

    useEffect(() => {
        if (token && techUCourseStatus === LoadingStatus.Idle) {
            dispatch(getAllTechUCourses({ token: token }));
        }
    }, [techUCourseStatus, token]);

    const checkContentExisting = async (id) => {
        const response = await dispatch(
            fetchContentByTypeId({ externalId: `techu_${id}` })
        );
        if (!response.error) {
            if (
                Array.isArray(response.payload.contentById) &&
                response.payload.contentById.length > 0
            ) {
                return response.payload.contentById;
            }
        }
        return [];
    };

    const setContentTypeId = (contentRequest, moduleContent) => {
        if (moduleContent.type === 'file') {
            contentRequest = {
                ...contentRequest,
                content_type_id:
                    moduleContent.mimetype === 'application/pdf'
                        ? ContentTypes.Pdf.id
                        : moduleContent.mimetype === 'image/jpeg'
                        ? ContentTypes.Jpeg.id
                        : moduleContent.mimetype === 'image/png'
                        ? ContentTypes.Png.id
                        : ContentTypes.Doc.id,
            };
        }
        if (moduleContent.type === 'url') {
            contentRequest = {
                ...contentRequest,
                content_type_id: ContentTypes.Html.id,
            };
        }
        return contentRequest;
    };

    const saveResults = async (data) => {
        let contentResponse = null;
        let isExistLink = [];
        let linkRequest = {
            item_id: editItem.item_id,
            link_type_id: linkTypeId,
            display_name: data?.name,
            description: '',
            display_type_id: 2,
            content_id: null,
        };
        isExistLink = await checkContentExisting(data?.id);
        if (isExistLink?.length > 0) {
            await dispatch(
                createLink({
                    ...linkRequest,
                    content_id: isExistLink[0]?.content_id,
                })
            );
        } else {
            let contentRequest = {
                display_name: data?.name,
                content_type_id: data?.content_type_id,
                external_id: `techu_${data?.id}`,
                content_version: {
                    content_version_type: 1,
                    url: data?.url,
                },
            };
            contentResponse = await dispatch(createContent(contentRequest));
            if (!contentResponse.error) {
                linkRequest = {
                    ...linkRequest,
                    content_id: contentResponse?.payload?.content_id,
                };
                await dispatch(createLink({ ...linkRequest }));
            }
        }
    };

    const handleSubmit = async (setSubmitting, resetForm) => {
        dispatch(activateLoading());
        if (FormActions.Create.id === formAction.id) {
            for (const course of techUCourses) {
                if (selectedCourses.includes(course?.id) === true) {
                    let data = {
                        name: course?.display_name,
                        id: course?.id,
                        content_type_id: 21,
                        url: `${config.deltaTechUDomain}/courses/course/view.php?id=${course.id}`,
                    };
                    await saveResults(data);
                }
                if (
                    Array.isArray(course.contents) &&
                    course.contents.length > 0
                ) {
                    for (const content of course.contents) {
                        if (
                            Array.isArray(content.modules) &&
                            content.modules.length > 0
                        ) {
                            for (const contentModule of content.modules) {
                                if (
                                    selectedActivities.includes(
                                        contentModule?.id
                                    ) === true
                                ) {
                                    let data = {
                                        name: contentModule?.name,
                                        id: contentModule?.id,
                                        content_type_id: 21,
                                        url: contentModule?.url,
                                    };
                                    if (contentModule?.contents > 0) {
                                        let moduleContent =
                                            contentModule?.contents[0];
                                        data = setContentTypeId(
                                            data,
                                            moduleContent
                                        );
                                    }
                                    await saveResults(data);
                                }
                            }
                        }
                    }
                }
            }
            await dispatch(
                loadItem({ zoneId: editItem.zone_id, itemId: editItem.item_id })
            );
            resetForm();
            setToggleView(false);
        }

        setSubmitting(false);
        dispatch(deactivateLoading());
    };

    function handleCancel() {
        setToggleView(false);
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{}}
            validationSchema={Yup.object({})}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(setSubmitting, resetForm);
            }}
        >
            {({ isSubmitting }) => (
                <Form className={classes.form}>
                    {techUCourseStatus === LoadingStatus.Idle ? (
                        <Loader
                            styles={{
                                width: '100%',
                                marginTop: '8px',
                                marginBottom: '4px',
                                height: '56px',
                            }}
                        />
                    ) : (
                        <>
                            <TextField
                                placeholder="Search…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                type="search"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton size="large">
                                                <MdSearch className="react-icon" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(evt) => handleSearch(evt)}
                            />
                            {Array.isArray(techUCourses) &&
                            techUCourses.length > 0
                                ? techUCourses.map((course) => (
                                      <CourseAccordion
                                          key={`techu-course${course.id}`}
                                          course={course}
                                          expandedCourses={expandedCourses}
                                          selectedCourses={selectedCourses}
                                          selectedActivities={
                                              selectedActivities
                                          }
                                          handleExpand={handleExpand}
                                          handleCourseClick={handleCourseClick}
                                          handleActivityClick={
                                              handleActivityClick
                                          }
                                          searchString={searchString}
                                      />
                                  ))
                                : null}
                            <button
                                className={classes.submit}
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {formAction.buttonLabel}
                            </button>
                            {tabView && (
                                <button
                                    type="button"
                                    className={classes.cancel}
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </button>
                            )}
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export { TechULinkForm };
