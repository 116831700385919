import { config } from 'gemini-config';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import {
    getNetworkService,
    FormDataUtils,
    prefixUrl,
    createProgressParams,
} from 'GeminiViewerComponent/_helpers';

const baseUrl = `${config.apiUrl}/content`;
const baseContentVersionUrl = `${config.apiUrl}/contentversion`;

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});

const mapContentVersion = (contentVersion, s3Prefix) => {
    contentVersion.url = prefixUrl(contentVersion.url, s3Prefix);
    if (contentVersion?.encoded_url) {
        contentVersion.encoded_url = prefixUrl(
            contentVersion.encoded_url,
            s3Prefix
        );
    }
    return contentVersion;
};

const mapContentVersions = (contentVersions, s3Prefix) => {
    return contentVersions.map((contentVersion) => {
        return mapContentVersion(contentVersion, s3Prefix);
    });
};

const mapContent = (content, s3Prefix) => {
    content.url = prefixUrl(content.url, s3Prefix);
    if (content?.encoded_url) {
        content.encoded_url = prefixUrl(content.encoded_url, s3Prefix);
    }
    return content;
};

const mapContents = (contents, s3Prefix) => {
    return contents.map((content) => {
        return mapContent(content, s3Prefix);
    });
};

const mapLastContentVersion = (content, s3Prefix) => {
    if (content?.last_content_version?.url) {
        content.last_content_version.url = prefixUrl(
            content?.last_content_version?.url,
            s3Prefix
        );
    }
    if (content?.last_content_version?.encoded_url) {
        content.last_content_version.encoded_url = prefixUrl(
            content?.last_content_version?.encoded_url,
            s3Prefix
        );
    }
    return content;
};

const getAllExternalContent = async (
    page = 1,
    pageSize = 10,
    contentType,
    useTagFilter,
    categoryId,
    searchString = '',
    tagFilter
) => {
    const filters = [searchString ? `display_name@=*${searchString}` : ''];

    if (categoryId !== '' || categoryId !== undefined) {
        filters.push(`category_id==${categoryId.toString()}`);
    }

    const params = {
        filters: filters.join(','),
        sorts: 'display_name',
        content_type: contentType,
        page: page,
        pageSize: pageSize,
    };

    if (useTagFilter === true) {
        params.tag_filter = tagFilter;
    }

    const query = new URLSearchParams(params).toString();
    try {
        const response = await networkService.get(
            `${baseUrl}/getexternalcontent?${query}`
        );
        const externalContent = response.data;
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, externalContent: externalContent };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getAllExternalContentRange = async (
    startIndex = 1,
    stopIndex = 10,
    contentType,
    useTagFilter,
    categoryId,
    searchString = '',
    tagFilter
) => {
    const filters = [searchString ? `display_name@=*${searchString}` : ''];

    if (categoryId !== '' || categoryId !== undefined) {
        filters.push(`category_id==${categoryId.toString()}`);
    }

    const params = {
        filters: filters.join(','),
        sorts: 'display_name',
        content_type: contentType,
        start_index: startIndex,
        stop_index: stopIndex,
    };

    if (useTagFilter === true) {
        params.tag_filter = tagFilter;
    }

    const query = new URLSearchParams(params).toString();
    try {
        const response = await networkService.get(
            `${baseUrl}/getexternalcontent?${query}`
        );
        const externalContent = response.data;
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, externalContent: externalContent };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getAllContentCategories = async (contentType) => {
    const params = {
        content_type: contentType,
    };

    const query = new URLSearchParams(params).toString();

    try {
        const response = await networkService.get(
            `${config.apiUrl}/category?${query}`
        );
        const contentCategories = response.data;
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, contentCategories: contentCategories };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getAllContentByProcedureId = async (procedure_id = null) => {
    const { client_id } = JSON.parse(localStorage.getItem('user'));
    let filters = [`is_active==true`];

    if (procedure_id) {
        filters.push(`procedure_id==${procedure_id}`);
    }
    const params = {
        filters: filters.join(','),
    };

    const query = new URLSearchParams(params).toString();
    try {
        const response = await networkService.get(
            `${baseUrl}/getallbyclientid/${client_id}?${query}`
        );

        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getAllContentVersionByProcedureId = async (procedure_id = null) => {
    const { client_id } = JSON.parse(localStorage.getItem('user'));
    let filters = [];

    if (procedure_id) {
        filters.push(`procedure_id==${procedure_id}`);
    }
    const params = {
        filters: filters.join(','),
    };

    const query = new URLSearchParams(params).toString();
    try {
        const response = await networkService.get(
            `${baseContentVersionUrl}/getallbyclientid/${client_id}?${query}`
        );

        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getAllContentByTypeId = async (
    page = 1,
    pageSize = 10,
    contentTypeIds,
    assetId,
    searchString = '',
    sort = 'display_name',
    s3Prefix = ''
) => {
    const { client_id } = JSON.parse(localStorage.getItem('user'));
    let filters = [`is_active==true`];
    if (contentTypeIds?.length > 0) {
        filters.push(`content_type_id==${contentTypeIds.join('|').toString()}`);
    }
    if (searchString?.length > 0) {
        filters.push(`display_name@=*${searchString}`);
    }
    if (assetId) {
        filters.push(`asset_id==${assetId}`);
    }
    const params = {
        filters: filters.join(','),
        sorts: sort,
        page: page,
        pageSize: pageSize,
    };

    const query = new URLSearchParams(params).toString();
    try {
        const response = await networkService.get(
            `${baseUrl}/getallbyclientid/${client_id}?${query}`
        );
        const contentById = mapContents(response.data, s3Prefix);
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, contentById: contentById };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getAllContentByTypeIdRange = async (
    startIndex,
    stopIndex,
    contentTypeIds,
    assetId,
    searchString = '',
    sort = 'display_name',
    s3Prefix = '',
    asset_id_filter = '',
    zone_id_filter = '',
    item_id_filter = '',
    procedure_id_filter = '',
    min_file_size = '',
    max_file_size = '',
    min_file_width = '',
    max_file_width = '',
    min_file_height = '',
    max_file_height = '',
    assetOnly = false,
    external_id = '',
    encodingProgress = false,
    excludeContentTypeIds = '',
    noCaptions = false
) => {
    const { client_id } = JSON.parse(localStorage.getItem('user'));
    let filters = [`is_active==true`];
    if (contentTypeIds?.length > 0) {
        filters.push(`content_type_id==${contentTypeIds.join('|').toString()}`);
    }
    if (excludeContentTypeIds?.length > 0) {
        filters.push(
            `content_type_id!=${excludeContentTypeIds.join('|').toString()}`
        );
    }
    if (searchString?.length > 0) {
        filters.push(`display_name@=*${searchString}`);
    }
    if (assetId) {
        if (assetOnly) {
            filters.push(`asset_id==${assetId}`);
        } else {
            filters.push(`asset_id==${assetId}|NULL`);
        }
    }
    if (assetId === null && assetOnly === true) {
        filters.push(`asset_id!=NULL`);
    }
    if (external_id) {
        filters.push(`external_id==${external_id}`);
    }
    if (min_file_size && min_file_size !== 'No Min') {
        filters.push(`file_size>=${min_file_size}`);
    }
    if (max_file_size && max_file_size !== 'No Max') {
        filters.push(`file_size<=${max_file_size}`);
    }
    if (min_file_width && min_file_width !== 'No Min') {
        filters.push(`file_width>=${min_file_width}`);
    }
    if (max_file_width && max_file_width !== 'No Max') {
        filters.push(`file_width<=${max_file_width}`);
    }
    if (min_file_height && min_file_height !== 'No Min') {
        filters.push(`file_height>=${min_file_height}`);
    }
    if (max_file_height && max_file_height !== 'No Max') {
        filters.push(`file_height<=${max_file_height}`);
    }
    if (encodingProgress === true) {
        filters.push(`encoding_status!=*complete`);
        filters.push(`encoding_status!=`);
    }
    if (noCaptions) {
        filters.push('caption_count==0');
    }
    let params = {
        filters: filters.join(','),
        sorts: sort,
    };
    if (asset_id_filter) {
        params = { ...params, asset_id_filter };
    }
    if (zone_id_filter) {
        params = { ...params, zone_id_filter };
    }
    if (item_id_filter) {
        params = { ...params, item_id_filter };
    }
    if (procedure_id_filter) {
        params = { ...params, procedure_id_filter };
    }

    if (startIndex !== 0 || stopIndex !== 0) {
        params.start_index = startIndex;
        params.stop_index = stopIndex;
    }

    const query = new URLSearchParams(params).toString();
    try {
        const response = await networkService.get(
            `${baseUrl}/getallbyclientid/${client_id}?${query}`
        );
        const contentById = mapContents(response.data, s3Prefix);
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, contentById: contentById };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const create = async (params, s3Prefix = '') => {
    try {
        const { client_id } = JSON.parse(localStorage.getItem('user'));

        const formData = new FormData();

        FormDataUtils.safeAppend(formData, [
            ['client_id', client_id],
            ['asset_id', params.asset_id],
            ['display_name', params.display_name],
            ['content_type_id', params.content_type_id],
            ['additional_data', params.additional_data],
            ['skill_id', params.skill_id],
            ['embed_data', params.embed_data],
            ['external_id', params.external_id],
        ]);

        addContentVersionParams(formData, true, params.content_version);

        let progressParams = createProgressParams(params);

        const response = await networkService.postMultiFormData(
            baseUrl,
            formData,
            progressParams
        );

        return mapLastContentVersion(
            mapContent(response.data, s3Prefix),
            s3Prefix
        );
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const fetchContentById = async (contentId, s3Prefix = '') => {
    try {
        const response = await networkService.get(`${baseUrl}/${contentId}`);
        return mapLastContentVersion(
            mapContent(response.data, s3Prefix),
            s3Prefix
        );
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const fetchContentUsageById = async (contentId) => {
    try {
        const response = await networkService.get(
            `${baseUrl}/usage/${contentId}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const editContentById = async (params, s3Prefix = '') => {
    try {
        const formData = new FormData();

        FormDataUtils.safeAppend(formData, [
            ['asset_id', params.asset_id],
            ['display_name', params.display_name],
            ['content_type_id', params.content_type_id],
            ['additional_data', params.additional_data],
            ['skill_id', params.skill_id],
            ['embed_data', params.embed_data],
            ['external_id', params.external_id],
        ]);

        const response = await networkService.putMultiFormData(
            `${baseUrl}/${params.content_id}`,
            formData
        );
        return mapContent(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _delete = async (id) => {
    try {
        const response = await networkService.delete(`${baseUrl}/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const deleteArray = async (ids) => {
    const query = ids.join('&ids=');
    try {
        const response = await networkService.delete(`${baseUrl}?ids=${query}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const deleteContentVersionArray = async (ids) => {
    const query = ids.join('&ids=');
    try {
        const response = await networkService.delete(
            `${baseContentVersionUrl}?ids=${query}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};
const addContentVersionParams = (formData, addPrefix, params) => {
    const keyPrefix = addPrefix ? 'content_version.' : '';

    FormDataUtils.safeAppend(formData, [
        [`${keyPrefix}url`, params.url],
        [
            `${keyPrefix}content_data`,
            params.content_data,
            params.content_data?.name,
        ],
        [`${keyPrefix}embed_data`, params.embed_data],
        [`${keyPrefix}procedure_id`, params.procedure_id],
        [`${keyPrefix}content_id`, params.content_id],
        [`${keyPrefix}content_version_type`, params.content_version_type],
        [`${keyPrefix}compress_videos`, params.compress_videos],
        [`${keyPrefix}enable_hls`, params.enable_hls],
        [`${keyPrefix}transcribe_videos`, params.transcribe_videos],
        [`${keyPrefix}transcribe_language`, params.transcribe_language],
        [
            `${keyPrefix}max_video_height`,
            params.max_video_height == 'None' ? null : params.max_video_height,
        ],
        [`${keyPrefix}overlay_text`, JSON.stringify(params.overlay_text)],
        [`${keyPrefix}procedure_start_node`, params.procedure_start_node],
        [`${keyPrefix}procedure_end_node`, params.procedure_end_node],
        [
            `${keyPrefix}procedure_display_all_nodes`,
            params.procedure_display_all_nodes,
        ],
        [
            `${keyPrefix}procedure_force_start_node`,
            params.procedure_force_start_node,
        ],
    ]);

    FormDataUtils.safeArrayAppend(formData, [
        [`${keyPrefix}caption_files`, params.caption_files],
        [`${keyPrefix}caption_languages`, params.caption_languages],
    ]);

    if (params.chapters && params.chapters?.length > 0) {
        FormDataUtils.safeArrayOfObjectAppend(
            formData,
            params.chapters,
            `${keyPrefix}chapters`
        );
    }
};

const createContentVersion = async (params, s3Prefix = '') => {
    const formData = new FormData();

    addContentVersionParams(formData, false, params);

    let progressParams = createProgressParams(params);

    try {
        const response = await networkService.postMultiFormData(
            baseContentVersionUrl,
            formData,
            progressParams
        );

        return mapContentVersion(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const updateContentVersion = async (params, s3Prefix = '') => {
    const formData = new FormData();
    FormDataUtils.safeAppend(formData, [
        ['url', params.url],
        ['content_data', params.content_data, params.content_data?.name],
        ['embed_data', params.embed_data],
        ['procedure_id', params.procedure_id],
        ['content_id', params.content_id],
        ['content_version_type', params.content_version_type],
        ['compress_videos', params.compress_videos],
        ['enable_hls', params.enable_hls],
        ['transcribe_videos', params.transcribe_videos],
        ['transcribe_language', params.transcribe_language],
        [
            'max_video_height',
            params.max_video_height == 'None' ? null : params.max_video_height,
        ],
        ['overlay_text', JSON.stringify(params.overlay_text)],
        ['procedure_start_node', params.procedure_start_node],
        ['procedure_end_node', params.procedure_end_node],
        ['procedure_display_all_nodes', params.procedure_display_all_nodes],
        ['procedure_force_start_node', params.procedure_force_start_node],
    ]);

    FormDataUtils.safeArrayAppend(formData, [
        ['caption_files', params.caption_files],
        ['caption_languages', params.caption_languages],
    ]);

    if (params.chapters && params.chapters?.length > 0) {
        FormDataUtils.safeArrayOfObjectAppend(
            formData,
            params.chapters,
            'chapters'
        );
    }

    let progressParams = createProgressParams(params);

    try {
        const response = await networkService.putMultiFormData(
            `${baseContentVersionUrl}/${params.version}`,
            formData,
            progressParams
        );
        return mapContentVersion(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const updateContentVersionCaptions = async (params, s3Prefix = '') => {
    const formData = new FormData();
    FormDataUtils.safeAppend(formData, [['content_id', params.content_id]]);

    FormDataUtils.safeArrayAppend(formData, [
        ['caption_files', params.caption_files],
    ]);

    let progressParams = createProgressParams(params);

    try {
        const response = await networkService.putMultiFormData(
            `${baseContentVersionUrl}/updatecaptions/${params.content_version_id}`,
            formData,
            progressParams
        );
        return mapContentVersion(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const transcribeContents = async (ids, params) => {
    const query = ids.join('&ids=');

    try {
        const response = await networkService.put(
            `${baseUrl}/TranscribeContent/?ids=${query}`,
            params
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const isDuplicated = async (params) => {
    try {
        const response = await networkService.post(
            `${baseUrl}/isduplicated`,
            params
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getContentVersions = async (contentId, s3Prefix = '') => {
    try {
        const response = await networkService.get(
            `${baseUrl}/${contentId}/versions`
        );
        return mapContentVersions(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getContentImportCsv = async () => {
    try {
        const response = await networkService.get(`${baseUrl}/csv`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const uploadContentImportCsv = async (params) => {
    try {
        const formData = new FormData();
        FormDataUtils.safeAppend(formData, [
            ['global', params.global],
            ['asset_id', params.asset_id],
            ['file', params.file],
            ['allow_duplicates', params.allow_duplicates],
        ]);
        let progressParams = createProgressParams(params);
        const response = await networkService.postMultiFormData(
            `${baseUrl}/csv`,
            formData,
            progressParams
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

const fetchAllUsageById = async (
    contentTypeIds,
    assetId,
    searchString = '',
    sort = 'display_name',
    asset_id_filter = '',
    zone_id_filter = '',
    item_id_filter = '',
    min_file_size = '',
    max_file_size = '',
    min_file_width = '',
    max_file_width = '',
    min_file_height = '',
    max_file_height = '',
    assetOnly = false,
    external_id = '',
    encodingProgress = false,
    excludeContentTypeIds = '',
    noCaptions = false
) => {
    const { client_id } = JSON.parse(localStorage.getItem('user'));
    let filters = [`is_active==true`];

    if (contentTypeIds?.length > 0) {
        filters.push(`content_type_id==${contentTypeIds.join('|').toString()}`);
    }
    if (excludeContentTypeIds?.length > 0) {
        filters.push(
            `content_type_id!=${excludeContentTypeIds.join('|').toString()}`
        );
    }
    if (searchString?.length > 0) {
        filters.push(`display_name@=*${searchString}`);
    }
    if (assetId) {
        if (assetOnly) {
            filters.push(`asset_id==${assetId}`);
        } else {
            filters.push(`asset_id==${assetId}|NULL`);
        }
    }
    if (assetId === null && assetOnly === true) {
        filters.push(`asset_id!=NULL`);
    }
    if (external_id) {
        filters.push(`external_id==${external_id}`);
    }
    if (min_file_size && min_file_size !== 'No Min') {
        filters.push(`file_size>=${min_file_size}`);
    }
    if (max_file_size && max_file_size !== 'No Max') {
        filters.push(`file_size<=${max_file_size}`);
    }
    if (min_file_width && min_file_width !== 'No Min') {
        filters.push(`file_width>=${min_file_width}`);
    }
    if (max_file_width && max_file_width !== 'No Max') {
        filters.push(`file_width<=${max_file_width}`);
    }
    if (min_file_height && min_file_height !== 'No Min') {
        filters.push(`file_height>=${min_file_height}`);
    }
    if (max_file_height && max_file_height !== 'No Max') {
        filters.push(`file_height<=${max_file_height}`);
    }
    if (encodingProgress === true) {
        filters.push(`encoding_status!=*complete`);
        filters.push(`encoding_status!=`);
    }
    if (noCaptions) {
        filters.push('caption_count==0');
    }
    let params = {
        filters: filters.join(','),
        sorts: sort,
    };
    if (asset_id_filter) {
        params = { ...params, asset_id_filter };
    }
    if (zone_id_filter) {
        params = { ...params, zone_id_filter };
    }
    if (item_id_filter) {
        params = { ...params, item_id_filter };
    }
    const query = new URLSearchParams(params).toString();
    try {
        const response = await networkService.get(
            `${baseUrl}/getallusagebyclientid/${client_id}?${query}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const exportContent = async (
    startIndex,
    stopIndex,
    contentTypeIds,
    assetId,
    searchString = '',
    sort = 'display_name',
    asset_id_filter = '',
    zone_id_filter = '',
    item_id_filter = '',
    min_file_size = '',
    max_file_size = '',
    min_file_width = '',
    max_file_width = '',
    min_file_height = '',
    max_file_height = '',
    assetOnly = false,
    external_id = '',
    encodingProgress = false,
    excludeContentTypeIds = '',
    include_header = false,
    include_unused = false,
    output_fields = null,
    contentIds = []
) => {
    const { client_id } = JSON.parse(localStorage.getItem('user'));
    let filters = [`is_active==true`];
    if (contentTypeIds?.length > 0) {
        filters.push(`content_type_id==${contentTypeIds.join('|').toString()}`);
    }
    if (excludeContentTypeIds?.length > 0) {
        filters.push(
            `content_type_id!=${excludeContentTypeIds.join('|').toString()}`
        );
    }
    if (searchString?.length > 0) {
        filters.push(`display_name@=*${searchString}`);
    }
    if (assetId) {
        if (assetOnly) {
            filters.push(`asset_id==${assetId}`);
        } else {
            filters.push(`asset_id==${assetId}|NULL`);
        }
    }
    if (assetId === null && assetOnly === true) {
        filters.push(`asset_id!=NULL`);
    }
    if (external_id) {
        filters.push(`external_id==${external_id}`);
    }
    if (min_file_size && min_file_size !== 'No Min') {
        filters.push(`file_size>=${min_file_size}`);
    }
    if (max_file_size && max_file_size !== 'No Max') {
        filters.push(`file_size<=${max_file_size}`);
    }
    if (min_file_width && min_file_width !== 'No Min') {
        filters.push(`file_width>=${min_file_width}`);
    }
    if (max_file_width && max_file_width !== 'No Max') {
        filters.push(`file_width<=${max_file_width}`);
    }
    if (min_file_height && min_file_height !== 'No Min') {
        filters.push(`file_height>=${min_file_height}`);
    }
    if (max_file_height && max_file_height !== 'No Max') {
        filters.push(`file_height<=${max_file_height}`);
    }
    if (encodingProgress === true) {
        filters.push(`encoding_status!=*complete`);
        filters.push(`encoding_status!=`);
    }
    let params = {
        filters: filters.join(','),
        sorts: sort,
        include_header: include_header,
        include_unused: include_unused,
        output_fields: output_fields,
    };
    if (asset_id_filter) {
        params = { ...params, asset_id_filter };
    }
    if (zone_id_filter) {
        params = { ...params, zone_id_filter };
    }
    if (item_id_filter) {
        params = { ...params, item_id_filter };
    }

    if (startIndex !== 0 || stopIndex !== 0) {
        params.start_index = startIndex;
        params.stop_index = stopIndex;
    }
    let query = new URLSearchParams(params).toString();
    if (contentIds && contentIds?.length > 0) {
        const data = contentIds
            .map((id) => `content_ids_filter=${id}`)
            .join('&');
        query = query + '&' + data;
    }

    try {
        const response = await networkService.get(
            `${baseUrl}/export/${client_id}/?${query}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const contentService = {
    create,
    delete: _delete,
    createContentVersion,
    updateContentVersion,
    updateContentVersionCaptions,
    transcribeContents,
    editContentById,
    getAllExternalContent,
    getAllExternalContentRange,
    getAllContentCategories,
    getAllContentByTypeId,
    getAllContentByProcedureId,
    getAllContentVersionByProcedureId,
    getAllContentByTypeIdRange,
    getContentVersions,
    fetchContentById,
    isDuplicated: isDuplicated,
    mapContentVersion: mapContentVersion,
    fetchContentUsageById,
    deleteArray,
    deleteContentVersionArray,
    uploadContentImportCsv,
    getContentImportCsv,
    fetchAllUsageById,
    exportContent,
};
