import { useSelector } from 'react-redux';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

export const useTinyMCEInit = () => {
    const theme = useSelector(selectActiveTheme);

    const contentStyle = `
        body {
            font-family: 'Lato', 'Helvetica', Helvetica;
        }
        button {
            border: 0;
            display: inline-block;
            padding: 12px 24px;
            font-size: 14px;
            border-radius: 4px;
            margin-top: 5px;
            cursor: pointer;
            background-color: ${
                theme.colors.link_button_background_color
                    ? theme.colors.link_button_background_color
                    : '#1064EA'
            };
            color: ${
                theme.colors.link_button_text_color
                    ? theme.colors.link_button_text_color
                    : '#FFFFFF'
            };
        }
        button a {
            color: #FFFFFF;
            cursor: pointer;
            font-size: 14px;
            text-decoration: none;
        }
        img {
            max-width: 100%;
        }
    `;

    return {
        height: 300,
        menubar: 'file edit view snippets format tools table help',
        contextmenu: 'link lists',
        content_style: contentStyle,
        plugins: [
            'advlist autolink lists link charmap anchor',
            'searchreplace visualblocks code',
            'insertdatetime media table paste code help quickbars textpattern',
        ],
        quickbars_insert_toolbar: 'quicktable',
        menu: {
            file: { title: 'File', items: 'clearcontent' },
            snippets: {
                title: 'Snippets',
                items: 'insert create manage',
            },
        },
        help_tabs: ['shortcuts'],
        target_list: [
            {
                title: 'External',
                value: '_blank',
            },
            {
                title: 'Inline',
                value: '_self',
            },
        ],
        toolbar:
            'undo redo | formatselect | bold italic backcolor | link | alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | removeformat insertSnippet',
        images_upload_url: 'postAcceptor.php',
        paste_data_images: false,
        automatic_uploads: false,
        relative_urls: false,
        convert_urls: false,
        remove_script_host: false,
        images_upload_handler: function (blobInfo, success, failure) {
            setTimeout(function () {
                success(
                    'http://moxiecode.cachefly.net/tinymce/v9/images/logo.png'
                );
            }, 2000);
        },
        branding: false,
        toolbar_mode: 'sliding',
    };
};
