import { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { MdExpandMore } from 'react-icons/md';
import { Checkbox, FormControlLabel } from '@mui/material';

const ModulesListing = ({
    module,
    selectedActivities,
    handleActivityClick,
}) => {
    return (
        <FormControlLabel
            key={`module${module.id}`}
            control={
                <Checkbox
                    style={{
                        padding: '0 7px 0',
                    }}
                    onChange={() =>
                        handleActivityClick(
                            module.id,
                            selectedActivities.includes(module.id)
                        )
                    }
                    checked={selectedActivities.includes(module.id)}
                    color="success"
                />
            }
            label={`${module.name}`}
            sx={{
                width: '100%',
                alignItems: 'start',
            }}
        />
    );
};

const CourseAccordion = ({
    course,
    expandedCourses,
    selectedCourses,
    selectedActivities,
    handleExpand,
    handleCourseClick,
    handleActivityClick,
    searchString = '',
}) => {
    const [modules, setModules] = useState([]);
    const [show, setShow] = useState(true);
    useEffect(() => {
        if (!searchString) {
            setShow(true);
        }
        if (searchString) {
            setShow(false);
        }
        if (
            searchString &&
            course.display_name
                .toLowerCase()
                .indexOf(searchString.toLowerCase()) !== -1
        ) {
            setShow(true);
        }
        let courseModules = [];
        course.contents.forEach((cnt) => {
            if (Array.isArray(cnt.modules) && cnt.modules.length > 0) {
                let contentModules = [...cnt.modules];
                if (searchString) {
                    contentModules = cnt.modules.filter(
                        (mod) =>
                            mod.name
                                .toLowerCase()
                                .indexOf(searchString.toLowerCase()) !== -1
                    );
                    if (contentModules.length > 0) {
                        setShow(true);
                    }
                }
                courseModules = [...courseModules, ...contentModules];
            }
        });
        setModules(courseModules);
    }, [course.contents, searchString]);

    return (
        show && (
            <Accordion
                expanded={expandedCourses.includes(course.id)}
                key={`techUCourse${course.id}`}
            >
                <AccordionSummary
                    expandIcon={
                        <MdExpandMore
                            className="react-icon"
                            onClick={() =>
                                handleExpand(
                                    course.id,
                                    expandedCourses.includes(course.id)
                                )
                            }
                        />
                    }
                    sx={{
                        padding: '0 15px !important',
                        backgroundColor: '#ecf0f1',
                        '.MuiAccordionSummary-content': {
                            margin: '5px 0',
                        },
                    }}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{
                                    padding: '0 7px 0 0',
                                }}
                                onChange={() => {
                                    handleCourseClick(
                                        course.id,
                                        selectedCourses.includes(course.id)
                                    );
                                }}
                                checked={selectedCourses.includes(course.id)}
                                color="primary"
                            />
                        }
                        label={course.display_name}
                    />
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        padding: '0 0 5px 25px !important',
                    }}
                >
                    {Array.isArray(modules) && modules?.length > 0
                        ? modules.map((module, idx) => (
                              <ModulesListing
                                  key={`techu-content-${idx}`}
                                  module={module}
                                  selectedActivities={selectedActivities}
                                  handleActivityClick={handleActivityClick}
                              />
                          ))
                        : 'No Activities Found'}
                </AccordionDetails>
            </Accordion>
        )
    );
};

export { CourseAccordion };
