import { makeStyles } from '@mui/styles';

const makeFormStyles = makeStyles(() => ({
    '@keyframes rotate': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' },
    },
    section: {
        padding: '5px',
        background: '#CCC',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    paper: {
        background: 'transparent',
    },
    loadingSvg: {
        height: '100px',
        width: '100px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        spacing: '10px',
    },
    linkForm: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
    },
    formHeader: {
        fontWeight: 'bold',
        fontSize: '20px',
        color: '#2D3D50',
        marginBottom: '10px',
    },
    input: {
        border: '1px solid #BDC3C7',
        fontSize: '18px',
        marginTop: '10px',
        padding: '10px',
        borderRadius: '5px',
        color: '#757575',
        '& option': {
            color: '#2D3D50',
        },
    },
    materialInput: {
        fontSize: '18px',
        color: '#2D3D50',
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
                '-webkit-appearance': 'none',
                margin: 0,
            },
    },
    materialSelect: {
        fontSize: '16px',
        color: '#2D3D50',
        marginTop: '8px',
        marginBottom: '4px',
        '& .MuiFormControl-root': {
            width: '100%',
        },
    },
    infoIcon: {
        width: '20px',
        height: '20px',
        marginRight: '5px',
    },
    submit: {
        backgroundColor: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        border: '0px',
        padding: '10px',
        borderRadius: '5px',
        fontSize: '18px',
        textTransform: 'uppercase',
        marginTop: '10px',
        cursor: 'pointer',
        '&:disabled': {
            backgroundColor: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            opacity: '0.5',
        },
        '&:hover': {
            backgroundColor: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(90%)',
        },
    },
    cancel: {
        backgroundColor: (props) => props.colors.button.secondaryBackground,
        color: (props) => props.colors.button.secondaryForeground,
        padding: '10px',
        borderRadius: '5px',
        fontSize: '18px',
        textTransform: 'uppercase',
        marginTop: '10px',
        cursor: 'pointer',
        border: '0px',
        '&:hover': {
            backgroundColor: (props) => props.colors.button.secondaryBackground,
            color: (props) => props.colors.button.secondaryForeground,
            filter: 'brightness(90%)',
        },
    },
    submitButtonSmall: {
        backgroundColor: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        border: '0px',
        padding: '6px',
        borderRadius: '5px',
        fontSize: '12px',
        textTransform: 'uppercase',
        cursor: 'pointer',
        '&:disabled': {
            backgroundColor: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            opacity: '0.5',
        },
        '&:hover': {
            backgroundColor: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(90%)',
        },
    },
    cancelButtonSmall: {
        backgroundColor: (props) => props.colors.button.secondaryBackground,
        color: (props) => props.colors.button.secondaryForeground,
        border: '0px',
        padding: '6px',
        borderRadius: '5px',
        fontSize: '12px',
        textTransform: 'uppercase',
        cursor: 'pointer',
        '&:disabled': {
            backgroundColor: (props) => props.colors.button.secondaryBackground,
            color: (props) => props.colors.button.secondaryForeground,
            opacity: '0.5',
        },
        '&:hover': {
            backgroundColor: (props) => props.colors.button.secondaryBackground,
            color: (props) => props.colors.button.secondaryForeground,
            border: '0',
            filter: 'brightness(90%)',
        },
    },
    preview: {
        color: '#2D3D50',
        padding: '10px',
        borderRadius: '5px',
        fontSize: '18px',
        textTransform: 'uppercase',
        marginTop: '10px',
        cursor: 'pointer',
        border: '0px',
        '&:hover': {
            filter: 'brightness(90%)',
        },
    },
    sceneButton: {
        background: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        border: '0px',
        padding: '8px',
        borderRadius: '5px',
        fontSize: '16px',
        textTransform: 'uppercase',
        cursor: 'pointer',
        alignSelf: 'center',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&:disabled': {
            background: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(50%)',
        },
        '&:hover': {
            background: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(90%)',
        },
    },

    inputErr: {
        color: '#E84C3C',
        margin: '4px 14px',
        fontSize: '0.75rem',
        textAlign: 'left',
        fontFamily: 'Open Sans',
        fontWeight: 400,
        lineHeight: 1.66,
    },
    materialTextarea: {
        paddingLeft: '15px',
        fontSize: '18px',
        color: '#757575',
        borderRadius: '4px',
    },
    formErrorDiv: {
        color: '#f44336',
        marginLeft: '14px',
        marginRight: '14px',
        marginTop: '4px',
        fontSize: '0.75rem',
        fontFamily: 'Open Sans',
        fontWeight: 400,
        lineHeight: 1.66,
        marginBottom: '10px',
    },
    formErrorField: {
        borderColor: '#f44336',
        color: '#f44336',
        '& option': {
            color: '#2D3D50',
        },
        '&::placeholder': {
            color: '#f44336',
        },
    },
    selectWrap: {
        transform: 'translate(14px, 12px) scale(1)',
        '.MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(1)',
        },
    },
    smeMaterialInput: {
        width: '100%',
        display: 'flex',
        fontSize: '18px',
        color: '#2D3D50',
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
                '-webkit-appearance': 'none',
                margin: 0,
            },
    },
    smeInputContainer: {
        display: 'flex',
        color: '#2D3D50',
        fontSize: '18px',
        fontWeight: 'bold',
        margin: '25px 0px',
    },
    smeInputIcon: {
        color: '#2D3D50',
        height: '30px',
        width: '30px',
        marginRight: '10px',
    },
    smeInput: {
        width: '100%',
        marginLeft: '10px',
    },
    smeField: {
        marginTop: '5px',
    },
    smeTopInputs: {
        display: 'flex',
        paddingTop: '16px',
        paddingBottom: '16px',
    },
    smeTopInput: {
        marginBottom: '15px',
    },
    smeAvatar: {
        height: '100px',
        width: '100px',
        marginRight: '15px',
    },
    smeDropzone: {
        backgroundColor: '#2D3D50',
        borderRadius: '50%',
        minHeight: '100px',
        width: '100px',
        height: '100px',
        outline: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    smeDropzoneText: {
        fontSize: '12px',
        color: 'white',
        margin: '0px',
        display: 'flex',
        flexDirection: 'column',
    },
    removeSmeImage: {
        height: '25px',
        width: '25px',
        cursor: 'pointer',
    },
    avatarHidden: {
        display: 'none',
    },
    fiFileUpload: {
        color: 'white',
        margin: '0 auto 5px auto',
        height: '20px',
        width: '20px',
    },
    fieldWrapper: {
        width: '100%',
    },
    smeLabel: {
        lineHeight: '27px',
    },
    imageInput: {
        border: '1px solid',
        borderColor: 'lightgray',
        borderRadius: '4px',
        padding: '15px 5px',
    },
    handleClear: {
        '&:hover .clearSelect': {
            display: 'block',
        },
        '& .clearSelect': {
            display: 'none',
        },
    },
}));

export { makeFormStyles };
