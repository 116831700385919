import { useSelector } from 'react-redux';
import { accountsSlice } from 'app/store';
import { ThemePreview } from './ThemePreview';
import makeStyles from '@mui/styles/makeStyles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import themes from 'GeminiViewerComponent/theme/defaultThemes';

const { selectActiveUser } = accountsSlice;

const makeThemeCardStyles = makeStyles({
    container: {
        display: 'grid',
        gap: '1rem',
        gridTemplateColumns: 'repeat(4, 1fr)',
        marginTop: '3rem',
        padding: '10px',
    },
    previewTitleContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        padding: '5px',
        zIndex: '1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    previewTitle: {
        width: '100%',
        fontSize: '22px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#000',
        backgroundColor: 'white',
        padding: '6px',
        borderRadius: '5px',
        border: '2px solid black',
    },
    previewButtons: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '5px',
        padding: '20px',
        zIndex: '1',
        backgroundColor: '#fff9',
        width: '100%',
        height: '100%',
        opacity: '0',
        '&:hover': {
            opacity: '1',
        },
        webkitTransition: 'opacity 0.5s',
        transition: 'opacity 0.5s',
    },
    previewButton: {
        backgroundColor: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        border: '0px',
        padding: '10px',
        borderRadius: '5px',
        fontSize: '18px',
        textTransform: 'uppercase',
        marginTop: '10px',
        cursor: 'pointer',
        '&:disabled': {
            filter: 'brightness(50%)',
        },
        '&:hover': {
            filter: 'brightness(90%)',
        },
    },
});

export const ThemeCard = (props) => {
    const activeUser = useSelector(selectActiveUser);
    const theme = useSelector(selectActiveTheme);
    const themeSelectorStyles = makeThemeCardStyles(theme);
    const usePreview = true;

    return usePreview ? (
        <ThemePreview
            theme={{ theme: props.theme }}
            isActive={props.name === activeUser.active_theme}
            style={{ width: '250px', minHeight: '265px' }}
        >
            <div className={themeSelectorStyles.previewTitleContainer}>
                <div className={themeSelectorStyles.previewTitle}>
                    {props.theme.name}
                </div>
            </div>

            <div className={themeSelectorStyles.previewButtons}>
                {props.theme.id !== theme?.id && (
                    <button
                        className={themeSelectorStyles.previewButton}
                        onClick={() =>
                            props.themeSwitcher(props.theme, props.name)
                        }
                    >
                        SELECT
                    </button>
                )}
                {props.theme.id !== themes?.default?.id && (
                    <button
                        className={themeSelectorStyles.previewButton}
                        onClick={() => props.themeEditor(props.theme)}
                    >
                        EDIT
                    </button>
                )}
                <button
                    className={themeSelectorStyles.previewButton}
                    onClick={() => props.themeDuplicate(props.theme)}
                >
                    DUPLICATE
                </button>
                {props.theme.name !== themes?.default?.name &&
                    props.theme.id !== themes?.default?.id &&
                    props.theme.id !== theme?.id && (
                        <button
                            className={themeSelectorStyles.previewButton}
                            onClick={() => props.themeRemover(props.theme)}
                        >
                            DELETE
                        </button>
                    )}
            </div>
        </ThemePreview>
    ) : (
        <div
            style={{
                backgroundColor: `${props.theme.colors.body}`,
                color: `${props.theme.colors.text}`,
                // fontFamily: props.theme.font,
            }}
        >
            <span>Click on the button to set this theme</span>
            <div
                onClick={() => props.themeSwitcher(props.theme, props.name)}
                style={{
                    backgroundColor: `${props.theme.colors.button.background}`,
                    color: `${props.theme.colors.button.text}`,
                    // fontFamily: `${props.theme.font}`,
                }}
            >
                {props.theme.name}
            </div>
            <div
                onClick={() => props.themeEditor(props.theme)}
                style={{
                    backgroundColor: `${props.theme.colors.button.background}`,
                    color: `${props.theme.colors.button.text}`,
                    // fontFamily: `${props.theme.font}`,
                }}
            >
                Edit
            </div>
        </div>
    );
};
