import { makeStyles } from '@mui/styles';

const loadingStyles = makeStyles(() => ({
    loadingSpinner: {
        zIndex: '9999 !important',
    },
    '@keyframes blinker': {
        from: { rotate: 1 },
        to: { opacity: 0 },
    },
    '@keyframes rotate': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' },
    },
    rotateSvg: {
        animationName: '$rotate',
        animationDuration: '1.5s',
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: 'infinite',
    },
    loadingSvg: {
        height: '200px',
        width: '200px',
    },
    progressArea: {
        width: '200px',
        textAlign: 'center',
        color: '#eee',
    },
    progressBar: {
        marginTop: '10px',
    },
    progressBarNoAnimation: {
        transition: 'none',
    },
}));

export { loadingStyles };
