import { LinkTypes } from '_helpers/enumerations';
import { GeneralContentTab } from '../GeneralContentTab';

function SmeTab() {
    return (
        <GeneralContentTab
            contentName={'Sme'}
            linkTypeId={LinkTypes.SubjectMatterExpert.id}
            contentTypes={LinkTypes.SubjectMatterExpert.contentTypes}
        />
    );
}

export { SmeTab };
