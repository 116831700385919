import { useDispatch, useSelector } from 'react-redux';
import { loadZone } from 'shared/loadZoneSlice';
import { loadContentPanelItem } from 'shared/loadContentPanelSlice';
import { selectAllTableZones } from '_features/zones/zonesTableSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

export const useZoneNav = () => {
    const dispatch = useDispatch();

    const allZones = useSelector(selectAllTableZones);

    const gotoZone = async (zoneId) => {
        await dispatch(loadZone({ zoneId: Number(zoneId) }));
    };

    const gotoZoneAndItem = async (zoneId, itemId, linkId = null) => {
        await dispatch(loadZone({ zoneId: zoneId }));
        if (itemId) {
            await dispatch(activateLoading());
            await dispatch(
                loadContentPanelItem({
                    zoneId: zoneId,
                    itemId: itemId,
                    linkId: linkId,
                })
            );
            await dispatch(deactivateLoading());
        }
    };

    return { allZones, gotoZone, gotoZoneAndItem };
};
