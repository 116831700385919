import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdAdd, MdRefresh } from 'react-icons/md';
import { IconButton, Tooltip } from '@mui/material';

import { Action } from '_helpers';
import { useStyles } from './styles';
import SearchWithFilters from 'components/SearchWithFilters';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { deleteKey } from 'GeminiViewerComponent/_helpers/cacheStore';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { makeButtonStyles } from '../../../../GeminiViewerComponent/components/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { ResizableTable } from 'GeminiViewerComponent/components/_Tables/ResizableTable';
import { fetchAudiencesPage } from '../../../../../src/_features/audiences/audiencesSlice';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import {
    closePanel,
    openPanel,
    setEditObjectId,
} from '_features/common/formSlice';
import {
    resizableLabelHeader,
    resizableLabelCell,
    resizableActionCell,
    resizableCheckCell,
    resizableCheckHeaderCell,
} from 'components';

function AudienceTable({
    audiences,
    pageInfo,
    audiencePayload,
    setAudiencePayload,
    sortDirection,
    sortBy,
    handleSort,
}) {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const buttonStyles = makeButtonStyles(theme);
    const classes = useStyles();
    const tableClasses = tableStyles();
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = React.useState(false);

    const loadedRowsMap = useSelector(
        (state) => state.audiences.loadedAudiencesMap
    );

    const headerHeight = 40;
    const rowHeight = 60;

    const isSelected = (id) => selected.indexOf(id) !== -1;
    const user = JSON.parse(localStorage.getItem('user'));

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== audiences.length) {
            setSelected(audiences.map((row) => row.audience_id));
            setSelectedAll(true);
        }
    }

    const onRefreshIconClick = () => {
        deleteKey(`audience_${user.user_id}`);
        dispatch(fetchAudiencesPage({ ...audiencePayload }));
    };

    const handleClick = (event, audience_id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(audience_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, audience_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        setSelectedAll(newSelected.length === audiences.length);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedIds = audiences.map(
                (audience) => audience.audience_id
            );
            setSelected(newSelectedIds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const handleRowClick = async ({ rowData }) => {
        await dispatch(
            closePanel({
                formKey: 'audienceForm',
            })
        );
        if (rowData?.audience_id) {
            await dispatch(
                setEditObjectId({
                    formKey: `audienceForm`,
                    object_id: rowData?.audience_id,
                })
            );
            dispatch(
                openPanel({
                    formKey: `audienceForm`,
                    formAction: 'Edit',
                })
            );
        }
    };

    const columns = [
        {
            accessorKey: 'audience_id',
            size: 50,
            minSize: 50,
            enableResizing: false,
            label: '',
            padding: 'checkbox',
            cellLevel: 'audience',
            numSelected: selected.length,
            rowCount: audiences.length,
            handleClick: handleClick,
            handleSelectAllClick: handleSelectAllClick,
            isSelected: isSelected,
            header: (info) =>
                resizableCheckHeaderCell(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableCheckCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'audience_id',
            size: 50,
            minSize: 50,
            enableResizing: false,
            padding: 'none',
            label: '',
            actionCellLevel: 'audience',
            targetPopup: 'audienceManagement',
            formKey: 'audienceForm',
            dispatch: dispatch,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableActionCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'display_name',
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'Name',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
    ];

    // eslint-disable-next-line no-unused-vars
    function loadMoreRows({ startIndex, stopIndex }) {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setAudiencePayload({
                ...audiencePayload,
                searchString: searchString,
            });
        }, 1000),
        [audiencePayload]
    );

    const handleCreateNew = () => {
        dispatch(
            openPanel({
                formKey: `audienceForm`,
                formAction: 'Create',
                clearEditId: true,
            })
        );
    };

    return (
        <div className={classes.root}>
            <div style={{ display: 'flex', gap: '5px', marginBottom: '10px' }}>
                <Tooltip title="Add New Audience">
                    <IconButton
                        onClick={handleCreateNew}
                        size="large"
                        className={buttonStyles.contentAddButton}
                    >
                        <MdAdd className="react-icon" />
                    </IconButton>
                </Tooltip>
                <div style={{ flexGrow: 1 }}></div>
                <SearchWithFilters
                    filter={false}
                    onChange={(evt) => debouncedSearch(evt.target.value)}
                />
                <IconButton
                    onClick={onRefreshIconClick}
                    aria-label="close"
                    sx={{ color: '#707070' }}
                    size="large"
                >
                    <MdRefresh
                        className="react-icon"
                        style={{ fontSize: '18px', color: 'black' }}
                    />
                </IconButton>
            </div>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    toolTipTitle={'Delete'}
                    totalCount={pageInfo?.length}
                >
                    <PopupAction
                        action={Action.DeleteArray}
                        object={{
                            audienceIds: selected,
                            setAudienceIds: setSelected,
                        }}
                        level={'audience'}
                        showLabel={false}
                    />
                </EnhancedTableToolbar>
            )}
            <div
                style={{
                    height: `calc(100vh - ${
                        selected.length > 0 ? '345px' : '280px'
                    })`,
                    minWidth: '500px',
                }}
            >
                <ResizableTable
                    initialRows={audiences}
                    allRows={audiences}
                    totalRowCount={pageInfo.TotalCount}
                    loadedRowsMap={loadedRowsMap}
                    loadMoreRows={loadMoreRows}
                    columns={columns}
                    cellClassName={tableClasses.flexContainer}
                    className={tableClasses.table}
                    rowClassName={tableClasses.flexContainer}
                    headerHeight={headerHeight}
                    rowHeight={rowHeight}
                    onRowClick={handleRowClick}
                />
            </div>
        </div>
    );
}

export { AudienceTable };
