import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Box, Button, Tab, Tabs, Typography } from '@mui/material';

import { AssetSelect } from './AssetSelect';
import { ClientSelect } from './ClientSelect';
import { setIsDirty } from '_features/common/formSlice';
import { selectConfigValuesClientId } from '_features/configValues/configValuesSlice';
import {
    ConfigValuesDisplayMode,
    ConfigValuesManager,
} from './ConfigValuesManager';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ClientConfigManagementTab = () => {
    const dispatch = useDispatch();
    const selectedClientId = useSelector(selectConfigValuesClientId);
    const [displayTabIndex, setDisplayTabIndex] = useState(0);

    const handleChange = (event, newValue) => {
        setDisplayTabIndex(newValue);
    };

    return (
        <>
            <ClientSelect />

            {selectedClientId !== 0 && (
                <>
                    &nbsp;&nbsp;&nbsp;
                    <AssetSelect />
                </>
            )}
            <AppBar position="static" color="default">
                <Tabs
                    value={displayTabIndex}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    // className={classes.tabs}
                >
                    <Tab label="Settings" {...a11yProps(0)} />
                    <Tab label="Features" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <Button
                variant="contained"
                style={{ marginTop: '10px' }}
                onClick={() => {
                    dispatch(setIsDirty(true));
                }}
            >
                Edit
            </Button>
            <TabPanel value={displayTabIndex} index={0}>
                <ConfigValuesManager
                    configValuesDisplayMode={ConfigValuesDisplayMode.Settings}
                />
            </TabPanel>
            <TabPanel value={displayTabIndex} index={1}>
                <ConfigValuesManager
                    configValuesDisplayMode={ConfigValuesDisplayMode.Features}
                />
            </TabPanel>
        </>
    );
};

export { ClientConfigManagementTab };
