import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import Tree, { moveItemOnTree } from '@atlaskit/tree';
import { MdAdd, MdDelete, MdDragHandle } from 'react-icons/md';
import { CgReorder } from 'react-icons/cg';
import {
    Paper,
    styled,
    Tooltip,
    IconButton,
    Box,
    Modal,
    Button,
    Checkbox,
    Typography,
    TextField,
} from '@mui/material';

import { makeFormStyles } from 'forms/styles';
import { sortBy } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { modalStyles } from 'GeminiViewerComponent/components/styles';
import { AddZoneCategoryDialog } from './components/AddZoneCategoryDialog';
import { ReorderZoneDialog } from './components/ReorderZoneDialog';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import { makeContentNodeFormStyles } from 'components/ProcedureDesigner/components/ContentNodeEditor/styles';
import { ResetCategoryOrderModal } from 'components/ProcedureDesigner/components/NodeCategoriesModal/components/ResetCategoryOrderDialog';
import { DiscardCategoryChangeModal } from 'components/ProcedureDesigner/components/NodeCategoriesModal/components/DiscardCategoryChangeDialog';
import { NodeCategoryConfirmationDialog } from 'components/ProcedureDesigner/components/NodeCategoriesModal/components/NodeCategoryConfirmationDialog';
import {
    selectAllTableZones,
    selectZoneCategory,
} from '_features/zones/zonesTableSlice';

const makeFormEditorStyles = makeStyles(() => ({
    container: {
        width: '100%',
    },
    icon: {
        width: '18px',
        height: '18px',
    },
    row: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        '&:hover': {
            filter: 'brightness(90%)',
        },
    },
}));

const makeNodeTableStyles = makeStyles(() => ({
    detailsWrapper: {
        alignItems: 'center',
        display: 'flex',
        align: 'left',
        width: '22%',
    },
    detailsContainer: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        lineHeight: '20px',
    },
    detailsTitle: {
        fontWeight: 'bold',
    },
    categoryCount: {
        fontWeight: 'bold',
        fontSize: '14px',
    },
    addButtonIcon: {
        padding: '0 !important',
        borderRadius: '25px !important',
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
    },
}));

const addCategory = (tree, category) => {
    tree.items[category?.zone_category_id ?? 'null'] = {
        id: category?.zone_category_id ?? 'null',
        data: category,
    };
};

const categoriesToTree = (categories) => {
    var tree = {
        rootId: 'root',
        items: {
            root: {
                id: 'root',
                children: [],
            },
        },
    };

    categories?.forEach((category) => {
        addCategory(tree, category);
        tree.items.root.children.push(category?.zone_category_id ?? 'null');
    });

    return tree;
};

const StyledTableRow = styled(Box)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    verticalAlign: 'center',
    width: '100%',
    padding: '0 6px 0 0',
    display: 'flex',
    flexWrap: 'nowrap',
    fontSize: '12px',
}));

const CategoryCell = ({
    category,
    categoryCount,
    error,
    setError,
    updatedZoneCategories,
    setUpdatedNodeCategories,
}) => {
    const theme = useSelector(selectActiveTheme);
    const nodeTableStyles = makeNodeTableStyles(theme);
    const [isCategoryEditing, setIsCategoryEditing] = useState(false);
    const [categoryName, setCategoryName] = useState('');

    useEffect(() => {
        const isExisting = updatedZoneCategories.some(
            (option) => categoryName?.trim() === option['display_name']?.trim()
        );
        if (categoryName?.length > 150) {
            setError('Must be 150 characters or less.');
        } else if (isExisting) {
            setError('Zone Category must be unique.');
        } else {
            setError(null);
        }
    }, [categoryName]);

    return (
        <>
            <Box
                className={nodeTableStyles.detailsWrapper}
                onClick={() => setIsCategoryEditing(true)}
            >
                {isCategoryEditing ? (
                    <TextField
                        autoFocus
                        size="small"
                        style={{ paddingRight: '5px' }}
                        variant="outlined"
                        defaultValue={category.display_name}
                        onChange={(e) => setCategoryName(e.target.value)}
                        error={!!error}
                        helperText={error}
                        onBlur={(e) => {
                            if (error) return;
                            setIsCategoryEditing(false);
                            setUpdatedNodeCategories((prev) => {
                                const nodeCategories = [];
                                prev.forEach((prevCat) => {
                                    if (
                                        prevCat.zone_category_id ===
                                        category.zone_category_id
                                    ) {
                                        const updatedCategory = {
                                            ...prevCat,
                                            display_name:
                                                e.target.value.trim() ||
                                                prevCat?.display_name,
                                        };
                                        nodeCategories.push(updatedCategory);
                                    } else {
                                        nodeCategories.push(prevCat);
                                    }
                                });

                                return nodeCategories;
                            });
                        }}
                    />
                ) : (
                    <Tooltip
                        title={category.display_name}
                        className={nodeTableStyles.detailsContainer}
                    >
                        <div className={nodeTableStyles.detailsTitle}>
                            {category.display_name}
                        </div>
                    </Tooltip>
                )}
            </Box>
            <Box className={nodeTableStyles.detailsWrapper}>
                <div className={nodeTableStyles.categoryCount}>
                    {categoryCount}
                </div>
            </Box>
        </>
    );
};

const ActionCell = ({
    category,
    selected,
    setSelected,
    categoryCount,
    setDeletedZoneCategories,
    setUpdatedNodeCategories,
    setIsReorderingZones,
    setCategoryBeingReordered,
}) => {
    return (
        <Box alignItems="center" display="flex" width="20%" overflow="hidden">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                    <Checkbox
                        onChange={() => {
                            if (selected.includes(category.zone_category_id)) {
                                setSelected((prev) =>
                                    prev.filter(
                                        (item) =>
                                            item !== category.zone_category_id
                                    )
                                );
                            } else {
                                setSelected((prev) => [
                                    ...prev,
                                    category.zone_category_id,
                                ]);
                            }
                        }}
                        disabled={categoryCount == 0}
                        checked={selected.includes(category.zone_category_id)}
                        name={category.zone_category_id}
                        color="primary"
                    />
                </Box>
                <Tooltip
                    title={`Change order of zones in '${category.display_name}'`}
                >
                    <IconButton
                        disabled={categoryCount == 0}
                        onClick={() => {
                            setIsReorderingZones(true);
                            setCategoryBeingReordered({
                                zone_category_id: category.zone_category_id,
                                display_name: category.display_name,
                            });
                        }}
                    >
                        <CgReorder className="react-icon" />
                    </IconButton>
                </Tooltip>
                <IconButton
                    disabled={categoryCount > 0}
                    onClick={() => {
                        setSelected((prev) =>
                            prev.filter(
                                (selected) =>
                                    selected !== category.zone_category_id
                            )
                        );
                        setDeletedZoneCategories((prev) => [
                            ...prev,
                            category.zone_category_id,
                        ]);
                        setUpdatedNodeCategories((prev) =>
                            prev.filter(
                                (item) =>
                                    item.zone_category_id !==
                                    category.zone_category_id
                            )
                        );
                    }}
                >
                    <MdDelete className="react-icon" />
                </IconButton>
            </div>
        </Box>
    );
};

const CategoryDataRow = ({
    category,
    provided,
    selected,
    setSelected,
    categoryCount,
    error,
    setError,
    setDeletedZoneCategories,
    updatedZoneCategories,
    setUpdatedNodeCategories,
    setIsReorderingZones,
    setCategoryBeingReordered,
}) => {
    return (
        <div
            style={{
                width: '100%',
                borderBottom: '1px solid black',
            }}
        >
            <StyledTableRow
                gap="5px"
                tabIndex={-1}
                key={category.zone_category_id}
                style={{
                    backgroundColor: 'white',
                }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    style={{ width: '7%' }}
                    {...provided.dragHandleProps}
                >
                    <MdDragHandle className="react-icon" />
                </Box>
                <ActionCell
                    category={category}
                    selected={selected}
                    setSelected={setSelected}
                    categoryCount={categoryCount}
                    setDeletedZoneCategories={setDeletedZoneCategories}
                    setUpdatedNodeCategories={setUpdatedNodeCategories}
                    setIsReorderingZones={setIsReorderingZones}
                    setCategoryBeingReordered={setCategoryBeingReordered}
                />
                <CategoryCell
                    category={category}
                    categoryCount={categoryCount}
                    error={error}
                    setError={setError}
                    updatedZoneCategories={updatedZoneCategories}
                    setUpdatedNodeCategories={setUpdatedNodeCategories}
                />
            </StyledTableRow>
        </div>
    );
};

const treeToCategories = (tree) => {
    var categories = [];

    var treeRootChildren = tree.items[tree.rootId].children;

    treeRootChildren?.forEach((itemId) => {
        var treeItem = tree.items[itemId];
        categories.push(treeItem.data);
    });

    return categories;
};

const ZoneCategoryModal = ({
    asset,
    openDialog,
    setOpenDialog,
    updateZoneData,
}) => {
    //#region State
    const [selected, setSelected] = useState([]);
    const [error, setError] = useState(null);
    const [categoryCounts, setCategoryCounts] = useState({});
    const [openResetCategoryOrderDialog, setOpenResetCategoryOrderDialog] =
        useState(false);
    const [openResetZoneOrderDialog, setOpenResetZoneOrderDialog] =
        useState(false);
    const [openDiscardChangesDialog, setOpenDiscardChangesDialog] =
        useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [openAddCategoryDialog, setOpenAddCategoryDialog] = useState(false);
    const [openZoneOrderDialog, setOpenZoneOrderDialog] = useState(false);
    const [deletedZoneCategories, setDeletedZoneCategories] = useState([]);
    const [categoryBeingReordered, setCategoryBeingReordered] = useState({});
    const [anyZonesUpdated, setAnyZonesUpdated] = useState(false);
    //#endregion

    //#region Constants
    //#endregion Constants

    //#region Hooks
    const theme = useSelector(selectActiveTheme);
    const classes = modalStyles(theme);
    const formClasses = makeFormStyles(theme);
    const formEditorStyles = makeFormEditorStyles();
    const nodeFormStyles = makeContentNodeFormStyles(theme);
    //#endregion Hooks

    //#region Selectors
    const allZones = useSelector(selectAllTableZones);
    const memoZones = useMemo(
        () => (allZones?.length > 0 ? allZones : []),
        [allZones]
    );
    const zoneCategory = useSelector(selectZoneCategory);
    const zoneCategories = useMemo(
        () => (zoneCategory?.length > 0 ? zoneCategory : []),
        [zoneCategory]
    );

    const [updatedZoneCategories, setUpdatedZoneCategories] =
        useState(zoneCategories);
    const [updatedZones, setUpdatedZones] = useState(memoZones);

    const tree = categoriesToTree(zoneCategories);
    const [treeState, setTreeState] = useState({ tree: tree });
    const anyCategoryUpdates =
        JSON.stringify(zoneCategories) !==
        JSON.stringify(updatedZoneCategories);
    const anyZoneUpdates =
        JSON.stringify(memoZones) !== JSON.stringify(updatedZones);
    const anyUpdates = anyCategoryUpdates || anyZoneUpdates;
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    useEffect(() => {
        //alert(anyZonesUpdated);
    }, [anyZonesUpdated]);

    useEffect(() => {
        setTreeState({
            tree: categoriesToTree(zoneCategories),
        });
        if (allZones && allZones?.length > 0) {
            const categoryCount = {};
            allZones.forEach((zone) => {
                if (zone?.zone_category_id) {
                    const categoryId = zone.zone_category_id;
                    categoryCount[categoryId] =
                        (categoryCount[categoryId] || 0) + 1;
                }
            });
            setCategoryCounts(categoryCount);
        }
    }, [zoneCategories, asset, allZones]);

    useEffect(() => {
        setTreeState({
            tree: categoriesToTree(updatedZoneCategories),
        });
    }, [updatedZoneCategories]);

    useEffect(() => {
        setUpdatedZoneCategories([...zoneCategories]);
    }, [zoneCategories]);

    useEffect(() => {
        setUpdatedZones([...memoZones]);
    }, [memoZones]);
    //#endregion Effects

    //#region Methods
    const renderItem = ({ item, provided }) => {
        const categoryCount = categoryCounts[item?.id] || 0;
        return (
            <div
                className={formEditorStyles.row}
                ref={provided.innerRef}
                {...provided.draggableProps}
            >
                <CategoryDataRow
                    key={item.zone_category_id ?? 'null'}
                    category={item.data}
                    provided={provided}
                    selected={selected}
                    setSelected={setSelected}
                    categoryCount={categoryCount}
                    error={error}
                    setError={setError}
                    setDeletedZoneCategories={setDeletedZoneCategories}
                    updatedZoneCategories={updatedZoneCategories}
                    setUpdatedNodeCategories={setUpdatedZoneCategories}
                    setIsReorderingZones={setOpenZoneOrderDialog}
                    setCategoryBeingReordered={setCategoryBeingReordered}
                />
            </div>
        );
    };

    const resetCategoryOrder = () => {
        const updatedOrderedCategories = [...updatedZoneCategories].sort(
            sortBy('display_name', 'DESC')
        );
        setUpdatedZoneCategories([
            ...updatedOrderedCategories.map((category, index) => ({
                ...category,
                display_order: index + 1,
            })),
        ]);
        setOpenResetCategoryOrderDialog(false);
    };

    const resetZoneOrder = () => {
        if (categoryBeingReordered?.zone_category_id !== undefined) {
            let modEntries = [];
            let unmodifiedEntries = [];
            updatedZones?.forEach((x) => {
                if (
                    x.zone_category_id ===
                    categoryBeingReordered.zone_category_id
                ) {
                    modEntries.push(x);
                } else {
                    unmodifiedEntries.push(x);
                }
            });

            const updatedOrderedZones = [
                ...unmodifiedEntries,
                ...modEntries
                    .sort(sortBy('display_name', 'DESC'))
                    .map((x, idx) => ({ ...x, display_order: idx + 1 })),
            ];

            setUpdatedZones(updatedOrderedZones);
            setOpenResetZoneOrderDialog(false);
            setOpenZoneOrderDialog(false);
        }
    };

    const addZoneCategory = useCallback(
        async (categoryName) => {
            if (updatedZoneCategories && updatedZoneCategories?.length > 0) {
                const newCategory = {
                    zone_category_id: uuidv4(),
                    display_name: categoryName?.trim(),
                };
                const sortedProcedureCategories = [
                    ...updatedZoneCategories,
                    newCategory,
                ];
                const updatedProcedureCategories = [];
                sortedProcedureCategories.forEach((category, index) => {
                    updatedProcedureCategories.push({
                        ...category,
                        display_order: index + 1,
                    });
                });
                setUpdatedZoneCategories([...updatedProcedureCategories]);
            } else {
                setUpdatedZoneCategories([
                    {
                        zone_category_id: uuidv4(),
                        display_name: categoryName?.trim(),
                        display_order: 1,
                    },
                ]);
            }
            setOpenAddCategoryDialog(false);
        },
        [updatedZoneCategories, asset]
    );

    const confirmChanges = useCallback(() => {
        let finalZones = [];
        let idx = 1;
        updatedZoneCategories
            ?.sort(sortBy('display_order', 'DESC'))
            .forEach((category) => {
                updatedZones
                    ?.sort(sortBy('display_order', 'DESC'))
                    .forEach((zone) => {
                        if (
                            zone.asset_id === asset?.asset_id &&
                            zone.zone_category_id === category.zone_category_id
                        ) {
                            finalZones.push({
                                ...zone,
                                display_order: idx++,
                            });
                        }
                    });
            });

        updatedZones?.sort(sortBy('display_order', 'DESC')).forEach((zone) => {
            if (
                zone.asset_id === asset?.asset_id &&
                zone.zone_category_id === undefined
            ) {
                finalZones.push({
                    ...zone,
                    display_order: idx++,
                });
            }
        });

        updateZoneData(
            updatedZoneCategories,
            deletedZoneCategories,
            finalZones
        );
        setOpenConfirmationDialog(false);
    }, [updatedZoneCategories, deletedZoneCategories, updatedZones, asset]);

    const updateDisplayOrderZones = useCallback(
        async (modifiedZones) => {
            let res = [];
            updatedZones?.forEach((zone) => {
                let obj = { ...zone };
                let foundZone = modifiedZones?.find(
                    (x) => x.zone_id == zone.zone_id
                );
                if (foundZone !== undefined) {
                    obj.display_order = foundZone.display_order;
                }

                res.push(obj);
            });

            setUpdatedZones(res);
        },
        [updatedZones]
    );

    const orderDraggableField = (
        sortedNodes,
        draggableId,
        sourceIndex,
        destinationIndex,
        sourceDroppableId,
        destinationDroppableId
    ) => {
        let draggableObject = null;
        let isSourceBeforeDestination = null;
        if (
            isSourceBeforeDestination === null &&
            ((sourceDroppableId !== 'root' &&
                destinationDroppableId !== 'root' &&
                sourceDroppableId === destinationDroppableId) ||
                (sourceDroppableId === 'root' &&
                    destinationDroppableId === 'root'))
        ) {
            if (sourceIndex < destinationIndex) {
                isSourceBeforeDestination = true;
            } else {
                isSourceBeforeDestination = false;
            }
        }
        sortedNodes.map((i, index) => {
            if (
                isSourceBeforeDestination === null &&
                ((destinationDroppableId === 'root' &&
                    index === destinationIndex) ||
                    (destinationDroppableId !== 'root' &&
                        destinationDroppableId === i.zone_category_id))
            ) {
                isSourceBeforeDestination = false;
            }
            if (
                isSourceBeforeDestination === null &&
                ((sourceDroppableId === 'root' && index === sourceIndex) ||
                    (sourceDroppableId !== 'root' &&
                        sourceDroppableId === i.zone_category_id))
            ) {
                isSourceBeforeDestination = true;
            }

            if (draggableObject === null) {
                if (i.zone_category_id === draggableId) {
                    draggableObject = i;
                } else if (
                    (i.type === 'group' || i.type === 'table') &&
                    i?.fields &&
                    i?.fields?.length > 0
                ) {
                    i.fields.map((j) => {
                        if (
                            isSourceBeforeDestination === null &&
                            destinationDroppableId !== 'root' &&
                            destinationDroppableId === j.zone_category_id
                        ) {
                            isSourceBeforeDestination = false;
                        }
                        if (
                            isSourceBeforeDestination === null &&
                            sourceDroppableId !== 'root' &&
                            sourceDroppableId === j.zone_category_id
                        ) {
                            isSourceBeforeDestination = true;
                        }
                        if (draggableObject === null) {
                            if (j.zone_category_id === draggableId) {
                                draggableObject = j;
                            }
                        }
                        return null;
                    });
                }
            }
            return null;
        });
        if (draggableObject === null) {
            return sortedNodes;
        }
        if (
            (draggableObject.type === 'group' ||
                draggableObject.type === 'table') &&
            destinationDroppableId !== 'root'
        ) {
            return sortedNodes;
        }

        let fieldsOrder = [];
        if (sourceDroppableId === 'root' && destinationDroppableId === 'root') {
            sortedNodes.map((i, index) => {
                if (sourceIndex === index) {
                    return null;
                } else if (destinationIndex === index) {
                    if (isSourceBeforeDestination) {
                        fieldsOrder = [...fieldsOrder, i, draggableObject];
                    } else {
                        fieldsOrder = [...fieldsOrder, draggableObject, i];
                    }
                } else {
                    fieldsOrder = [...fieldsOrder, i];
                }
            });
        }
        return fieldsOrder.map((field, idx) => ({
            ...field,
            display_order: idx + 1,
        }));
    };

    const onDragEnd = async (source, destination) => {
        if (
            !destination ||
            (source.parentId === destination.parentId &&
                source.index === destination.index)
        ) {
            return;
        }
        let currentFields = treeToCategories(treeState.tree);
        if (destination.parentId !== 'root') {
            return;
        }
        const orderedFields = await orderDraggableField(
            currentFields,
            treeState.tree.items[source.parentId].children[source.index],
            source.index,
            destination?.index ? destination.index : 0,
            source.parentId,
            destination.parentId
        );
        if (
            currentFields !== orderedFields ||
            (source.parentId === destination.parentId &&
                source.index === (destination?.index ? destination.index : 0))
        ) {
            moveItemOnTree(treeState.tree, source, destination);
            setTreeState({
                tree: categoriesToTree(orderedFields),
            });
            setUpdatedZoneCategories(orderedFields);
        }
    };
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render
    return (
        <>
            <Modal onClose={() => setOpenDialog(false)} open={openDialog}>
                <div className={classes.dargModalContainer}>
                    <Box className={classes.dragModalWrapper}>
                        <Typography width="100%" variant="h6">
                            Zone Categories
                        </Typography>
                        {selected && selected?.length > 0 && (
                            <Box display="block" mt="10px" width="100%">
                                <EnhancedTableToolbar
                                    numSelected={selected.length}
                                    toolTipTitle="Delete"
                                    totalCount={updatedZoneCategories?.length}
                                >
                                    <MdDelete
                                        className="react-icon"
                                        style={{
                                            cursor: 'pointer',
                                            margin: '10px',
                                            color: '#7E8C8D',
                                        }}
                                        onClick={() => {
                                            setSelected([]);
                                            setDeletedZoneCategories((prev) => [
                                                ...prev,
                                                ...selected,
                                            ]);
                                            setUpdatedZoneCategories((prev) =>
                                                prev.filter(
                                                    (item) =>
                                                        !selected.includes(
                                                            item?.zone_category_id ??
                                                                'null'
                                                        )
                                                )
                                            );
                                        }}
                                    />
                                </EnhancedTableToolbar>
                            </Box>
                        )}
                        <Box overflow="auto">
                            <Box
                                display="flex"
                                mt="10px"
                                width="600px"
                                flexDirection="column"
                                maxHeight="60vh"
                            >
                                <table
                                    style={{
                                        borderBottom: '1px solid #000',
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                    }}
                                >
                                    <tr align="left">
                                        <th
                                            width="27%"
                                            style={{ padding: '10px' }}
                                        ></th>
                                        <th
                                            width="22%"
                                            style={{ padding: '10px' }}
                                        >
                                            Name
                                        </th>
                                        <th style={{ padding: '10px' }}>
                                            # Zones
                                        </th>
                                    </tr>
                                </table>
                                <Paper
                                    style={{
                                        flex: '1',
                                        overflow: 'auto',
                                        boxShadow: 'none',
                                    }}
                                >
                                    <div
                                        className={formEditorStyles.container}
                                        tabIndex="1"
                                    >
                                        <Tree
                                            tree={treeState.tree}
                                            renderItem={renderItem}
                                            onDragEnd={onDragEnd}
                                            offsetPerLevel={12}
                                            isDragEnabled={true}
                                            isNestingEnabled={true}
                                        />
                                    </div>
                                </Paper>
                            </Box>
                        </Box>
                        <Box sx={{ margin: '1rem auto 1rem 0' }}>
                            <Tooltip title="Add Node Category">
                                <IconButton
                                    className={nodeFormStyles.addButtonIcon}
                                    onClick={() => {
                                        setOpenAddCategoryDialog(true);
                                    }}
                                    size="small"
                                >
                                    <MdAdd className="react-icon" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                        >
                            <Button
                                className={formClasses.submitButtonSmall}
                                onClick={() => {
                                    setOpenResetCategoryOrderDialog(true);
                                }}
                            >
                                Reset Order...
                            </Button>

                            <Box display="flex" gap="5px">
                                <Button
                                    className={formClasses.cancelButtonSmall}
                                    onClick={() => {
                                        if (anyUpdates) {
                                            setOpenDiscardChangesDialog(true);
                                        } else {
                                            setOpenDialog(false);
                                        }
                                        setSelected([]);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={formClasses.submitButtonSmall}
                                    onClick={() => {
                                        if (error) return;
                                        if (
                                            updatedZoneCategories.length !==
                                            zoneCategories?.length
                                        ) {
                                            setOpenConfirmationDialog(true);
                                        } else {
                                            confirmChanges();
                                        }
                                        setSelected([]);
                                    }}
                                    disabled={!anyUpdates}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </div>
            </Modal>
            <ReorderZoneDialog
                openModal={openZoneOrderDialog}
                setOpenModal={setOpenZoneOrderDialog}
                resetOrder={setOpenResetZoneOrderDialog}
                zones={updatedZones}
                formEditorStyles={formEditorStyles}
                categoryBeingReordered={categoryBeingReordered}
                setAnyZonesUpdated={setAnyZonesUpdated}
                updateDisplayOrderZones={updateDisplayOrderZones}
            />
            <ResetCategoryOrderModal
                openModal={openResetCategoryOrderDialog}
                setOpenModal={setOpenResetCategoryOrderDialog}
                resetOrder={resetCategoryOrder}
            />
            <ResetCategoryOrderModal
                title={'Reset Zone Order'}
                text={
                    'This will reset the zones to alphabetically order. Are you sure?'
                }
                openModal={openResetZoneOrderDialog}
                setOpenModal={setOpenResetZoneOrderDialog}
                resetOrder={resetZoneOrder}
            />
            <DiscardCategoryChangeModal
                openModal={openDiscardChangesDialog}
                setOpenModal={setOpenDiscardChangesDialog}
                discardChanges={() => {
                    setOpenDialog(false);
                    setOpenDiscardChangesDialog(false);
                    setUpdatedZoneCategories(zoneCategories);
                    setUpdatedZones(memoZones);
                }}
            />
            <NodeCategoryConfirmationDialog
                openModal={openConfirmationDialog}
                setOpenModal={setOpenConfirmationDialog}
                confirmChanges={confirmChanges}
            />
            <AddZoneCategoryDialog
                openModal={openAddCategoryDialog}
                setOpenModal={setOpenAddCategoryDialog}
                updatedZoneCategories={updatedZoneCategories}
                addZoneCategory={addZoneCategory}
            />
        </>
    );
    //#endregion Render
};

export default ZoneCategoryModal;
