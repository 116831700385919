import { useCallback, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { accountsSlice } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Tooltip, IconButton } from '@mui/material';

import useStyles from '../../styles';
import { openPanel } from '_features/common/formSlice';
import { EndDateFilter } from './components/EndDateFilter';
import SearchWithFilters from 'components/SearchWithFilters';
import { StartDateFilter } from './components/StartDateFilter';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { DisplayOptionFilter } from './components/DisplayOptionFilter';
import { FilterAccordion } from 'components/_Accordions/FilterAccordion';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

const { selectActiveUser } = accountsSlice;

const NewsFlashActionBar = ({
    initialPayload,
    newsFlashPayload,
    setNewsFlashPayload,
    searchString,
    setSearchString,
    setSorting,
    sortDirection,
    sortBy,
}) => {
    //#region Constants
    const dispatch = useDispatch();
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [displayOptionId, setDisplayOptionId] = useState([]);
    const [filterTitle, setFilterTitle] = useState([]);
    //#endregion State

    //#region Selectors
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);
    const activeUser = useSelector(selectActiveUser);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    //#endregion Effects

    //#region Methods
    const clearFilters = () => {
        setNewsFlashPayload(initialPayload);
        setFilterTitle([]);
        setStartDate(null);
        setEndDate(null);
        setDisplayOptionId([]);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce((searchString, startDate, endDate, displayOptionId) => {
            setNewsFlashPayload({
                ...newsFlashPayload,
                searchString: searchString,
                startDate: startDate,
                endDate: endDate,
                displayOptionId: displayOptionId,
            });
        }, 1000),
        [newsFlashPayload]
    );

    const handleCreateNew = () => {
        dispatch(
            openPanel({
                formKey: `newsFlashForm`,
                formAction: 'Create',
                clearEditId: true,
            })
        );
    };

    const handleSearch = useCallback(
        (searchString) => {
            debouncedSearch(searchString, startDate, endDate, displayOptionId);
        },
        [debouncedSearch, startDate, endDate, displayOptionId]
    );

    const updateSearchString = (value) => {
        setSearchString(value);
        handleSearch(value);
    };
    //#endregion Methods

    return (
        <Box
            className={classes.actionBar}
            sx={{ flexWrap: 'wrap', alignItems: 'flex-start' }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: {
                        xs: '0 0 100%',
                        md: '0 0 70%',
                    },
                }}
            >
                <Box className={classes.newsFlashFilter}>
                    {readOnlyMode !== true && (
                        <Tooltip title="Add New News Flash">
                            <IconButton
                                onClick={handleCreateNew}
                                size="large"
                                className={classes.newsFlashTypeIcon}
                            >
                                <MdAdd className="react-icon" />
                            </IconButton>
                        </Tooltip>
                    )}
                    <FilterAccordion
                        filteredTitles={filterTitle}
                        expandedId="news-flash-filters"
                        onClearFilter={clearFilters}
                        style={{ margin: '0', width: '100%' }}
                    >
                        <Grid container spacing={'1rem'}>
                            <StartDateFilter
                                debouncedSearch={debouncedSearch}
                                searchString={searchString}
                                startDate={startDate}
                                endDate={endDate}
                                displayOptionId={displayOptionId}
                                setStartDate={setStartDate}
                            />
                            <EndDateFilter
                                debouncedSearch={debouncedSearch}
                                searchString={searchString}
                                startDate={startDate}
                                endDate={endDate}
                                displayOptionId={displayOptionId}
                                setEndDate={setEndDate}
                            />
                            <DisplayOptionFilter
                                debouncedSearch={debouncedSearch}
                                searchString={searchString}
                                startDate={startDate}
                                endDate={endDate}
                                displayOptionId={displayOptionId}
                                setDisplayOptionId={setDisplayOptionId}
                            />
                        </Grid>
                    </FilterAccordion>
                </Box>
            </Box>
            <Box
                sx={{
                    position: 'relative',
                    flex: { xs: '0 0 100%', md: '0 0 25%' },
                    mt: { xs: '0.5rem', md: '0' },
                    ml: { xs: '3.93rem', md: '0' },
                    maxWidth: '280px',
                    height: '100%',
                }}
            >
                <SearchWithFilters
                    onChange={(evt) => updateSearchString(evt.target.value)}
                    filter={false}
                    searchString={searchString}
                    setSorting={setSorting}
                    sortDirection={sortDirection}
                    sortBy={sortBy}
                    addButtonText={'APPLY'}
                />
            </Box>
        </Box>
    );
};
export default NewsFlashActionBar;
