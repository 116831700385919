import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MediaConversionTable } from './MediaConversionTable';
import {
    fetchContentByTypeId,
    getContentStatus,
    resetContentStatus,
    selectContentById,
} from '_features/content/contentSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

const MediaConversionTab = () => {
    const dispatch = useDispatch();
    const ref = useRef(false);
    const contentStatus = useSelector(getContentStatus);
    let contents = useSelector(selectContentById);
    const [contentPayload, setContentPayload] = useState({});
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');

    const getContentData = async (payload) => {
        dispatch(activateLoading());
        await dispatch(
            fetchContentByTypeId({ ...payload, encodingProgress: true })
        );
        dispatch(deactivateLoading());
    };

    useEffect(() => {
        if (ref.current === true && contentStatus === LoadingStatus.Idle) {
            getContentData({ reset: true, contentTypeIds: [] });
        }
    }, [contentStatus]);

    useEffect(() => {
        if (ref.current === true) {
            getContentData({ ...contentPayload });
        } else {
            getContentData({ reset: true, contentTypeIds: [] });
            ref.current = true;
        }
    }, [contentPayload]);

    useEffect(() => {
        return () => {
            dispatch(resetContentStatus());
        };
    }, []);

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        setContentPayload({
            ...contentPayload,
            reset: true,
            sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
        });
    };

    return (
        <MediaConversionTable
            contents={contents}
            pageInfo={contents?.length ?? 0}
            contentPayload={contentPayload}
            setContentPayload={setContentPayload}
            sortDirection={sortDirection}
            sortBy={sortBy}
            handleSort={(_sortBy, _sortDirection) =>
                setSorting(_sortBy, _sortDirection)
            }
        />
    );
};

export { MediaConversionTab };
