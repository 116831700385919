import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    fetchPartCategoryPage,
    getPartCategoryPageInfo,
    getPartCategoryStatus,
    resetPartCategoryState,
    selectPartCategories,
} from '_features/parts/partCategorySlice';
import { PartCategoryTable } from './PartCategoryTable';

const PartCategoryTab = () => {
    const dispatch = useDispatch();
    const ref = useRef(false);
    const partCategories = useSelector(selectPartCategories);
    const partCategoryStatus = useSelector(getPartCategoryStatus);
    const partCategoriesPageInfo = useSelector(getPartCategoryPageInfo);
    const [partCategoryPayload, setPartCategoryPayload] = useState({});
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const getAllPartCategories = async (payload) => {
        dispatch(activateLoading());
        await dispatch(fetchPartCategoryPage(payload));
        dispatch(deactivateLoading());
    };

    useEffect(() => {
        if (ref.current === true) {
            getAllPartCategories({ ...partCategoryPayload });
        }
    }, [partCategoryPayload]);

    useEffect(() => {
        return () => {
            dispatch(resetPartCategoryState());
        };
    }, []);
    useEffect(() => {
        if (partCategoryStatus === LoadingStatus.Idle) {
            getAllPartCategories({ ...partCategoryPayload });
        }
        ref.current = true;
    }, [partCategoryStatus]);

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        setPartCategoryPayload({
            ...partCategoryPayload,
            sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
        });
    };

    return (
        <PartCategoryTable
            partCategories={partCategories}
            pageInfo={partCategoriesPageInfo}
            partCategoryPayload={partCategoryPayload}
            setPartCategoryPayload={setPartCategoryPayload}
            sortDirection={sortDirection}
            sortBy={sortBy}
            handleSort={(_sortBy, _sortDirection) =>
                setSorting(_sortBy, _sortDirection)
            }
        />
    );
};

export { PartCategoryTab };
