import React from 'react';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { Action } from '_helpers';

function UserProfilePopup({ object, level, onClose }) {
    return (
        <PopupAction
            action={Action.Edit}
            object={object}
            level={level}
            showLabel={true}
            onActionHandled={onClose}
        />
    );
}

export { UserProfilePopup };
