import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { ToolsTable } from './ToolsTable';
import {
    fetchToolsPage,
    getToolsPageInfo,
    getToolsStatus,
    resetToolsState,
    selectTools,
} from '_features/tools/toolSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { resetLocationState } from '_features/tools/locationSlice';
import { resetCategoryState } from '_features/tools/toolsCategorySlice';

const ToolsTab = () => {
    const dispatch = useDispatch();
    const ref = useRef(false);
    const tools = useSelector(selectTools);
    const toolStatus = useSelector(getToolsStatus);
    const toolsPageInfo = useSelector(getToolsPageInfo);
    const [toolPayload, setToolPayload] = useState({});
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');

    const getAllTools = async (payload) => {
        dispatch(activateLoading());
        await dispatch(fetchToolsPage(payload));
        dispatch(deactivateLoading());
    };

    useEffect(() => {
        if (ref.current === true) {
            getAllTools({ ...toolPayload });
        }
    }, [toolPayload]);

    useEffect(() => {
        return () => {
            dispatch(resetToolsState());
            dispatch(resetLocationState());
            dispatch(resetCategoryState());
        };
    }, []);

    useEffect(() => {
        if (toolStatus === LoadingStatus.Idle) {
            getAllTools({ ...toolPayload });
        }
        ref.current = true;
    }, [toolStatus]);

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        setToolPayload({
            ...toolPayload,
            sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
        });
    };

    return (
        <ToolsTable
            tools={tools}
            pageInfo={toolsPageInfo}
            toolPayload={toolPayload}
            setToolPayload={setToolPayload}
            sortDirection={sortDirection}
            sortBy={sortBy}
            handleSort={(_sortBy, _sortDirection) =>
                setSorting(_sortBy, _sortDirection)
            }
        />
    );
};

export { ToolsTab };
