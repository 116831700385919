import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Tooltip,
} from '@mui/material';
import { modalStyles } from 'GeminiViewerComponent/components/styles';
import { accountsSlice, accountsThunk } from 'app/store';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { MdContentCopy } from 'react-icons/md';

const { fetchRoles } = accountsThunk;
const { selectAllRoles, getRolesStatus } = accountsSlice;

const AssetShareDialog = ({ playerUrlStr, openDialog, setOpenDialog }) => {
    const theme = useSelector(selectActiveTheme);
    const classes = modalStyles(theme);
    const dispatch = useDispatch();

    const userModeOptions = [
        { label: 'Anonymous', val: 'anonymous' },
        { label: 'Param', val: 'param' },
        { label: 'Form', val: 'form' },
    ];

    const userExceptionOptions = [
        { label: 'No Exception', val: 'continue' },
        { label: 'Deny', val: 'deny' },
        { label: 'Redirect', val: 'redirect' },
    ];

    const [assetURL, setAssetURL] = useState(playerUrlStr);
    const [selectedOptions, setSelectedOptions] = useState({});

    const roles = useSelector(selectAllRoles);
    const roleStatus = useSelector(getRolesStatus);

    const generateShareURL = () => {
        let url = playerUrlStr;
        if (selectedOptions?.userMode) {
            url += `&user-mode=${selectedOptions?.userMode}`;
            if (selectedOptions?.userMode === 'param') {
                url += `&user-id=${
                    selectedOptions?.paramUserID ?? ''
                }&user-name=${selectedOptions?.paramUserName ?? ''}&role=${
                    selectedOptions?.userRole ?? ''
                }`;
            }
        }
        if (selectedOptions?.userException) {
            url += `&user-exception=${selectedOptions?.userException}`;
            if (selectedOptions?.userException === 'redirect') {
                url += `&redirect-url=${selectedOptions?.exceptionURL ?? ''}`;
            }
        }
        setAssetURL(url);
    };

    useEffect(() => {
        if (roleStatus === LoadingStatus.Idle) {
            dispatch(fetchRoles());
        }
    }, [roleStatus]);

    useEffect(() => {
        generateShareURL();
    }, [selectedOptions]);

    return (
        openDialog && (
            <Modal onClose={() => setOpenDialog(false)} open={openDialog}>
                <div
                    className={classes.modalContainer}
                    style={{ minWidth: '10%', maxWidth: '90%' }}
                >
                    <div className={classes.actionIcon}>
                        <div
                            style={{
                                padding: '10px 5px',
                                backgroundColor: '#cccccc',
                                color: '#000000',
                                display: 'flex',
                                flexWrap: 'wrap',
                            }}
                        >
                            <div
                                style={{
                                    width: '90%',
                                    float: 'left',
                                    display: 'flex',
                                    alignItems: 'center',
                                    wordBreak: 'break-all',
                                    fontSize: '12px',
                                }}
                            >
                                {assetURL}
                            </div>
                            <div
                                style={{
                                    width: '10%',
                                    float: 'right',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                }}
                            >
                                <Tooltip title="Copy">
                                    <IconButton
                                        onClick={(e) => {
                                            setTimeout(async () => {
                                                await navigator.clipboard.writeText(
                                                    assetURL
                                                );
                                            });
                                        }}
                                        size="small"
                                        style={{ padding: 0 }}
                                    >
                                        <MdContentCopy
                                            className="react-icon"
                                            style={{
                                                color: '#000000',
                                                width: '25px',
                                                height: '25px',
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '100%', marginTop: '15px' }}>
                        <FormControl fullWidth>
                            <InputLabel id="user-mode-label">
                                User Mode
                            </InputLabel>
                            <Select
                                fullWidth
                                label="User Mode"
                                size="small"
                                labelId="user-mode-label"
                                id="user-mode"
                                value={selectedOptions?.userMode ?? ''}
                                onChange={(e) =>
                                    setSelectedOptions({
                                        ...selectedOptions,
                                        userMode: e.target.value,
                                    })
                                }
                            >
                                {userModeOptions.map((opt) => (
                                    <MenuItem key={opt.label} value={opt.val}>
                                        {opt.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {selectedOptions?.userMode === 'param' && (
                        <div
                            style={{
                                marginLeft: '10%',
                                width: '90%',
                                marginTop: '5px',
                            }}
                        >
                            <div style={{ marginTop: '8px' }}>
                                <TextField
                                    fullWidth
                                    label="User ID"
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) =>
                                        setSelectedOptions({
                                            ...selectedOptions,
                                            paramUserID: e.target.value,
                                        })
                                    }
                                    value={selectedOptions?.paramUserID ?? ''}
                                />
                            </div>
                            <div style={{ marginTop: '8px' }}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) =>
                                        setSelectedOptions({
                                            ...selectedOptions,
                                            paramUserName: e.target.value,
                                        })
                                    }
                                    value={selectedOptions?.paramUserName ?? ''}
                                />
                            </div>
                            <div style={{ marginTop: '8px' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="user-role-label">
                                        Role
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        label="Role"
                                        size="small"
                                        labelId="user-role-label"
                                        id="user-role"
                                        value={selectedOptions?.userRole ?? ''}
                                        onChange={(e) =>
                                            setSelectedOptions({
                                                ...selectedOptions,
                                                userRole: e.target.value,
                                            })
                                        }
                                    >
                                        {(roles?.length > 0 ? roles : []).map(
                                            (opt) => (
                                                <MenuItem
                                                    key={opt.display_name}
                                                    value={opt.display_name}
                                                >
                                                    {opt.display_name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    )}
                    <div style={{ width: '100%', marginTop: '10px' }}>
                        <FormControl fullWidth>
                            <InputLabel id="user-exception-label">
                                User Exception
                            </InputLabel>
                            <Select
                                label="User Exception"
                                size="small"
                                labelId="user-exception-label"
                                id="user-exception"
                                value={selectedOptions?.userException ?? ''}
                                onChange={(e) =>
                                    setSelectedOptions({
                                        ...selectedOptions,
                                        userException: e.target.value,
                                    })
                                }
                            >
                                {userExceptionOptions.map((opt) => (
                                    <MenuItem key={opt.label} value={opt.val}>
                                        {opt.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {selectedOptions?.userException === 'redirect' && (
                        <div
                            style={{
                                marginLeft: '10%',
                                width: '90%',
                                marginTop: '5px',
                            }}
                        >
                            <div
                                style={{
                                    marginTop: '8px',
                                }}
                            >
                                <TextField
                                    fullWidth
                                    label="URL"
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) =>
                                        setSelectedOptions({
                                            ...selectedOptions,
                                            exceptionURL: e.target.value,
                                        })
                                    }
                                    value={selectedOptions?.exceptionURL ?? ''}
                                />
                            </div>
                        </div>
                    )}
                    <div
                        style={{
                            marginTop: '10px',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '100%',
                        }}
                    >
                        <Button
                            onClick={() => {
                                setOpenDialog(false);
                            }}
                            className={classes.buttonSpacing}
                            variant="outlined"
                            style={{ margin: 0 }}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </Modal>
        )
    );
};

export default AssetShareDialog;
