import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
    GroupManagementTab,
    UserManagementTab,
    ConfigManagementTab,
    HotspotManagementTab,
    ItemTagManagementTab,
    AudienceManagementTab,
    CategoryManagementTab,
} from 'components';
import ThemeManager from '../../../theme/ThemeManager';
import { tabStyles } from 'GeminiViewerComponent/components/styles';
import {
    getAdminTabIndex,
    setAdminTabIndex,
} from '_features/globals/visibilitySlice';
import { accountsSlice } from 'app/store';
import { FieldsManagementTab } from '../FieldsManagementTab';
import { Role } from '_helpers';
import { ClientConfigManagementTab } from '../ClientConfigManagementTab';
import { CompanionAppsTab } from '../CompanionAppsTab';
import { closeAllPanel } from '_features/common/formSlice';

const { selectActiveUser } = accountsSlice;
function AdminTab(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            style={{ height: '100%' }}
            {...other}
        >
            {value === index && (
                <Box
                    p={3}
                    style={{
                        overflow: 'auto hidden',
                    }}
                >
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

AdminTab.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function AdminTabPanel() {
    const dispatch = useDispatch();
    const classes = tabStyles();

    const activeUser = useSelector(selectActiveUser);
    const displayTabIndex = useSelector((state) => getAdminTabIndex(state));

    const handleChange = (event, newValue) => {
        dispatch(setAdminTabIndex(newValue));
        dispatch(closeAllPanel());
    };

    let tabBarIndex = 0;
    let tabPageIndex = 0;
    return (
        <div className={classes.tabRoot}>
            <AppBar position="static" color="default">
                <Tabs
                    value={displayTabIndex}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    className={classes.tabs}
                >
                    <Tab
                        label="Hotspot Management"
                        {...a11yProps(tabBarIndex)}
                    />
                    <Tab
                        label="User Management"
                        {...a11yProps(++tabBarIndex)}
                    />
                    <Tab
                        label="Group Management"
                        {...a11yProps(++tabBarIndex)}
                    />
                    <Tab
                        label="Category Management"
                        {...a11yProps(++tabBarIndex)}
                    />
                    <Tab label="Tags" {...a11yProps(++tabBarIndex)} />
                    <Tab label="Audiences" {...a11yProps(++tabBarIndex)} />
                    {activeUser?.role === Role.IPSAdmin && (
                        <Tab
                            label="Client Config"
                            {...a11yProps(++tabBarIndex)}
                        />
                    )}
                    {activeUser?.role === Role.IPSAdmin && (
                        <Tab label="Fields" {...a11yProps(++tabBarIndex)} />
                    )}
                    {activeUser?.role !== Role.IPSAdmin && (
                        <Tab
                            label="Config Management"
                            {...a11yProps(++tabBarIndex)}
                        />
                    )}
                    <Tab label="Companion Apps" {...a11yProps(++tabBarIndex)} />
                    <Tab label="Theme Manager" {...a11yProps(++tabBarIndex)} />
                </Tabs>
            </AppBar>
            <AdminTab value={displayTabIndex} index={tabPageIndex}>
                <HotspotManagementTab />
            </AdminTab>
            <AdminTab value={displayTabIndex} index={++tabPageIndex}>
                <UserManagementTab />
            </AdminTab>
            <AdminTab value={displayTabIndex} index={++tabPageIndex}>
                <GroupManagementTab />
            </AdminTab>
            <AdminTab value={displayTabIndex} index={++tabPageIndex}>
                <CategoryManagementTab />
            </AdminTab>
            <AdminTab value={displayTabIndex} index={++tabPageIndex}>
                <ItemTagManagementTab />
            </AdminTab>
            <AdminTab value={displayTabIndex} index={++tabPageIndex}>
                <AudienceManagementTab />
            </AdminTab>
            {activeUser?.role === Role.IPSAdmin && (
                <AdminTab value={displayTabIndex} index={++tabPageIndex}>
                    <ClientConfigManagementTab />
                </AdminTab>
            )}
            {activeUser?.role === Role.IPSAdmin && (
                <AdminTab value={displayTabIndex} index={++tabPageIndex}>
                    <FieldsManagementTab />
                </AdminTab>
            )}
            {activeUser?.role !== Role.IPSAdmin && (
                <AdminTab value={displayTabIndex} index={++tabPageIndex}>
                    <ConfigManagementTab />
                </AdminTab>
            )}
            <AdminTab value={displayTabIndex} index={++tabPageIndex}>
                <CompanionAppsTab />
            </AdminTab>
            <AdminTab value={displayTabIndex} index={++tabPageIndex}>
                <ThemeManager />
            </AdminTab>
        </div>
    );
}

export { AdminTabPanel };
