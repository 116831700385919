import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    listRoot: {
        width: '100%',
        backgroundColor: '#efefef',
        marginTop: '10px !important',
        paddingBottom: '0px !important',
        cursor: 'pointer',
    },
    listSubHeaderRoot: {
        backgroundColor: '#efefef !important',
        fontSize: '18px !important',
        color: '#2D3D50 !important',
        display: 'flex !important',
        fontWeight: '600 !important',
    },
    listSubHeaderText: {
        width: '96%',
        display: 'flex',
        alignItems: 'center',
    },
    listSubHeaderNavigation: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export { useStyles };
