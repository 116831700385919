import { config } from 'gemini-config';
import { linkService } from '../../GeminiViewerComponent/_features/services/link.service';

import {
    getNetworkService,
    mapPrefixUrl,
} from 'GeminiViewerComponent/_helpers';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import { clamp } from 'GeminiViewerComponent/_helpers/lodashUtils';

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/item`;

const mapItem = (item, s3Prefix) => {
    if (item.links) {
        item.links = linkService.mapLinks(item.links, s3Prefix);
    }
    return item;
};

const getPage = async (
    searchString = '',
    zoneId,
    page = 1,
    pageSize = 10,
    activeOnly = true
) => {
    try {
        const response = await networkService.get(
            `${baseUrl}/getallbyzoneid/${zoneId}?filters=is_active==${activeOnly.toString()},display_name@=*${searchString}&sorts=-is_pinned,display_name&page=${page}&pageSize=${pageSize}`
        );
        const items = response.data;
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, zoneId: zoneId, items: items };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const cleanItems = (items, s3Prefix = '') => {
    return items.map((item) => {
        item = mapItem(item, s3Prefix);
        if (Array.isArray(item?.tags) && item.tags.length > 0) {
            item.tags = mapPrefixUrl(item.tags, s3Prefix, 'image_url');
        }
        return {
            ...item,
            ...{
                flat_x: clamp(item.flat_x, 0, 1),
                flat_y: clamp(item.flat_y, 0, 1),
            },
        };
    });
};

const getAll = async (zoneId, s3_prefix = '', activeOnly = true) => {
    try {
        const response = await networkService.get(
            `${baseUrl}/getallbyzoneid/${zoneId}?filters=is_active==${activeOnly.toString()}`
        );
        const items = cleanItems(response.data, s3_prefix);
        return { zone_id: zoneId, items: items };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getRange = async (
    zoneId,
    startIndex = 1,
    stopIndex = 10,
    activeOnly = true
) => {
    const filters = [`is_active==${activeOnly.toString()}`];

    const params = {
        filters: filters.join(','),
        sorts: '-is_pinned,display_name',
        start_index: startIndex,
        stop_index: stopIndex,
    };

    const query = new URLSearchParams(params).toString();
    try {
        const response = await networkService.get(
            `${baseUrl}/getallbyzoneid/${zoneId}?${query}`
        );
        const items = response.data;
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, items: items };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const search = async (assetId, searchString = '', activeOnly = true) => {
    try {
        const response = await networkService.get(
            `${baseUrl}/getallbyassetid/${assetId}?filters=is_active==${activeOnly.toString()},(display_name|external_id)@=*${searchString}&sorts=display_name`
        );
        const items = response.data;
        return { items: items };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const create = async (params) => {
    //params.audience_ids = params.audiences;
    try {
        const response = await networkService.post(baseUrl, params);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getById = async (zoneId, itemId, s3Prefix = '') => {
    try {
        const response = await networkService.get(
            `${baseUrl}/${itemId}?zone_id=${zoneId}`
        );
        return mapItem(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getExportById = async (id) => {
    try {
        const response = await networkService.get(`${baseUrl}/${id}/export`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const update = async (id, params, s3Prefix = '') => {
    //params.audience_ids = params.audiences;
    try {
        const response = await networkService.put(`${baseUrl}/${id}`, params);
        return mapItem(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const addItemToZone = async ({ zoneId, itemId }, s3Prefix = '') => {
    try {
        const response = await networkService.put(
            `${baseUrl}/${itemId}/AddZone?zone_id=${zoneId}`
        );
        return mapItem(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const duplicate = async (zoneId, itemId, s3Prefix = '') => {
    const user = JSON.parse(localStorage.getItem('user'));
    var data = {
        client_id: user.client_id,
        user_id: user.user_id,
    };
    try {
        const response = await networkService.post(
            `${baseUrl}/duplicate/${itemId}/${zoneId}`,
            data
        );
        return mapItem(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _delete = async (zoneId, itemId) => {
    try {
        const response = await networkService.delete(
            `${baseUrl}/${itemId}/${zoneId}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const deleteArray = async (zoneId, itemIds) => {
    var zoneItems = itemIds.map((id) => {
        return { zone_id: zoneId, item_id: id };
    });
    try {
        const response = await networkService.put(`${baseUrl}/delete`, {
            ids: zoneItems,
        });
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const itemPinning = async (params) => {
    try {
        const response = await networkService.post(
            `${baseUrl}/${params.object_id}/${
                params.is_pinned ? 'pin' : 'unpin'
            }`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getAllItems = async (assetId) => {
    try {
        const response = await networkService.get(
            `${baseUrl}/getallbyassetid/${assetId}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const itemService = {
    create: create,
    delete: _delete,
    deleteArray,
    duplicate,
    itemPinning,
    getAll,
    getPage,
    getRange,
    getById,
    getExportById,
    update,
    addItemToZone,
    search,
    getAllItems,
};
