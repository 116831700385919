import React, { useState, useEffect, useCallback, useRef } from 'react';
import clsx from 'clsx';
import { MdList, MdRefresh } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import ReactHlsPlayer from 'react-hls-player/dist/index.js';
import {
    Modal,
    Button,
    NativeSelect,
    Box,
    IconButton,
    Typography,
} from '@mui/material';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { ContentTypes, LinkTypes } from '_helpers';
import { procedureService } from '_features/_services';
import { Each } from 'GeminiViewerComponent/_helpers/Each.jsx';
import { SmeView } from 'GeminiViewerComponent/components/SmeView';
import {
    formatTime,
    timeToSeconds,
} from 'GeminiViewerComponent/_helpers/helper.js';
import GenericModel from 'GeminiViewerComponent/components/Models/GenericModel.js';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import ModelContainer from 'GeminiViewerComponent/components/Models/ModelContainer.js';
import NodeTableViewOnly from '../../ProcedureDesigner/components/NodeTableViewOnly.jsx';
import { overrideResetStyles } from 'GeminiViewerComponent/components/Procedure/styles.jsx';
import { VideoCaptions } from 'GeminiViewerComponent/components/LinkDisplay/components/VideoCaptions/index.jsx';
import {
    contentCaptionURL,
    mapVideoContentURL,
} from 'GeminiViewerComponent/_helpers/urlUtils.js';
import {
    getContentVersions,
    fetchContentVersions,
} from '_features/content/contentSlice';
import {
    getContentValidationModalVis,
    toggleContentValidationModal,
    getContentValidationModalContent,
} from '_features/globals/visibilitySlice';
import {
    modalStyles,
    panelStyles,
} from 'GeminiViewerComponent/components/styles';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice.js';
import {
    fetchProcedure,
    getActiveTree,
    setInitialState,
} from 'GeminiViewerComponent/components/Procedure/_features/procedureSlice';
import { HtmlTooltip } from 'GeminiViewerComponent/components/_Tooltip/index.jsx';

function ContentValidationModal() {
    const videoRef = useRef(null);
    const theme = useSelector(selectActiveTheme);
    const classes = modalStyles(theme);
    const panelClasses = panelStyles(theme);
    const overrideResetClasses = overrideResetStyles();
    const dispatch = useDispatch();
    const modalVis = useSelector(getContentValidationModalVis);
    const activeTree = useSelector(getActiveTree);
    const modalContent = useSelector(getContentValidationModalContent);
    const [modalContentUrl, setModalContentUrl] = useState('');
    const [selectedVersion, setSelectedVersion] = useState(null);
    const [selectedChapter, setSelectedChapter] = useState(null);
    const [displayChapter, setDisplayChapter] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isChapterVideo, setIsChapterVideo] = useState({
        chapterPlayed: false,
        chapterCapture: false,
    });
    const linkTypeKey = Object.keys(LinkTypes).find((key) => {
        return (
            LinkTypes[key].contentTypes.findIndex(
                (ct) => ct.id == modalContent?.content_type_id
            ) != -1
        );
    });

    const linkTypeId = LinkTypes?.[linkTypeKey]?.id;

    const contentVersions = useSelector(getContentVersions);
    const sortedContentVersion = [...contentVersions].sort(
        (a, b) => a.version - b.version
    );
    const handleClose = async () => {
        dispatch(setInitialState());
        dispatch(toggleContentValidationModal());
    };
    const fetchProceduresList = async (procedureId) => {
        if (!activeTree || activeTree?.procedure_id !== procedureId) {
            dispatch(activateLoading());
            await dispatch(
                fetchProcedure({
                    service: procedureService,
                    procedureId,
                })
            );
            dispatch(deactivateLoading());
        }
    };

    const fetchContentVersion = async (content_id) => {
        const version = await dispatch(fetchContentVersions(content_id));
        if (version.payload && version.payload?.length > 0) {
            setSelectedVersion([...version.payload].pop()?.version);
        }
    };

    useEffect(() => {
        setSelectedVersion(null);
        if (modalContent?.content_id !== undefined && modalVis) {
            fetchContentVersion(modalContent.content_id);
            if (modalContent.content_type_id === ContentTypes.Procedure.id) {
                fetchProceduresList(modalContent.procedure_id);
                setModalContentUrl(modalContent.procedure_id);
            } else if (
                modalContent.default_link_type_id === LinkTypes.Video.id
            ) {
                setModalContentUrl(mapVideoContentURL(modalContent));
            } else {
                setModalContentUrl(
                    modalContent?.embed_data !== undefined
                        ? modalContent.embed_data
                        : modalContent?.url
                        ? modalContent.url
                        : modalContent?.encoding_status &&
                          modalContent?.encoding_status.toLowerCase() ===
                              'complete'
                        ? modalContent?.encoded_url
                        : modalContent.url
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalContent, modalVis, dispatch]);

    useEffect(() => {
        if (videoRef.current) {
            const timeInSeconds = timeToSeconds(
                selectedChapter?.start_time || '0'
            );
            if (isFinite(timeInSeconds)) {
                videoRef.current.currentTime = timeInSeconds;
            }
        }
    }, [selectedChapter]);

    const handlePlay = () => {
        setIsPlaying(true);
    };

    const handlePause = () => {
        setIsPlaying(false);
    };

    const handleTimeUpdate = useCallback(
        (e) => {
            if (
                selectedChapter &&
                Object.keys(selectedChapter || {}).length > 0
            ) {
                if (
                    e.target.currentTime >=
                        timeToSeconds(selectedChapter.end_time) &&
                    !isChapterVideo.chapterPlayed
                ) {
                    videoRef.current?.pause();
                    setIsChapterVideo((prev) => ({
                        ...prev,
                        chapterPlayed: true,
                    }));
                }
            }
            const currentTime = e.target.currentTime;
            const activeVersion =
                Array.isArray(contentVersions) && contentVersions.length > 0
                    ? contentVersions.find(
                          (conVer) => conVer?.version === selectedVersion
                      )
                    : null;

            activeVersion?.chapters?.forEach((chapter, index) => {
                const startTime = timeToSeconds(chapter?.start_time);
                const endTime = timeToSeconds(chapter?.end_time);

                const button = document.querySelector(
                    `[data-chapterId="${chapter.chapter_id}"]`
                );
                if (currentTime >= startTime && currentTime <= endTime) {
                    if (button) {
                        button.classList.add('chaptersActive');
                    }
                } else {
                    if (button) {
                        button.classList.remove('chaptersActive');
                    }
                }
            });
        },
        [
            selectedChapter,
            contentVersions,
            isChapterVideo.chapterPlayed,
            selectedVersion,
        ]
    );

    const handleSeekCapture = useCallback(
        (e) => {
            if (selectedChapter) {
                if (
                    e.target.currentTime <
                    timeToSeconds(selectedChapter.end_time)
                ) {
                    setIsChapterVideo((prev) => ({
                        ...prev,
                        chapterPlayed: false,
                    }));
                }
            }
        },
        [selectedChapter]
    );

    const viewContainer = useCallback(() => {
        // Find link type that matches content type
        if (linkTypeId === LinkTypes.Procedure.id) {
            return (
                <NodeTableViewOnly
                    procedure={activeTree}
                    contentVersions={sortedContentVersion}
                    selectedVersion={selectedVersion}
                    nodes={activeTree?.nodes}
                    width="80%"
                    content={modalContent}
                />
            );
        }

        if (linkTypeId === LinkTypes.SubjectMatterExpert.id) {
            return <SmeView sme={modalContent} />;
        }

        if (linkTypeId === LinkTypes.Model.id) {
            return (
                <ModelContainer
                    Model={GenericModel}
                    modelURL={modalContentUrl}
                />
            );
        }

        if (linkTypeId === LinkTypes.Embed.id) {
            return (
                <div
                    className={clsx(
                        classes.embedContainer,
                        overrideResetClasses.overrideMarzipanoResetCSS
                    )}
                    dangerouslySetInnerHTML={{
                        __html: modalContentUrl || modalContent.embed_data,
                    }}
                ></div>
            );
        }

        if (linkTypeId === LinkTypes.Video.id) {
            const activeVersion =
                Array.isArray(contentVersions) && contentVersions.length > 0
                    ? contentVersions.find(
                          (conVer) => conVer?.version === selectedVersion
                      )
                    : null;
            const activeVersionURL = activeVersion?.encoded_url;
            const extension = activeVersionURL
                ? activeVersionURL?.split('.')?.pop()
                : modalContentUrl && typeof modalContentUrl === 'string'
                ? modalContentUrl?.split('.')?.pop()
                : '';

            let captionsAvailable = Array.isArray(
                modalContent?.caption_languages
            )
                ? modalContent?.caption_languages
                : [];
            let contentURL = contentCaptionURL(modalContentUrl);

            if (extension == 'm3u8') {
                return (
                    <>
                        {activeVersion &&
                        activeVersion?.chapters &&
                        activeVersion?.chapters?.length > 0 ? (
                            <Box
                                display="flex"
                                gap="5px"
                                overflow="hidden"
                                height="100%"
                                width="100%"
                                position="relative"
                            >
                                {displayChapter && (
                                    <Box
                                        className={classes.chaptersWrapper}
                                        height={videoRef?.current?.clientHeight}
                                    >
                                        <Typography
                                            className={classes.chaptersLabel}
                                        >
                                            Chapters
                                        </Typography>
                                        <Each
                                            of={[
                                                ...activeVersion.chapters,
                                            ].sort(
                                                (a, b) =>
                                                    a.chapter_id - b.chapter_id
                                            )}
                                            render={(data, idx) => (
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <Typography
                                                                variant="caption"
                                                                fontWeight="bold"
                                                            >
                                                                {data?.title}
                                                            </Typography>
                                                            <br />
                                                            {'(' +
                                                                formatTime(
                                                                    data?.start_time
                                                                ) +
                                                                '-' +
                                                                formatTime(
                                                                    data?.end_time
                                                                ) +
                                                                ')'}
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <Button
                                                        className={clsx(
                                                            classes.chaptersButton,
                                                            {
                                                                [classes.activeChapterButton]:
                                                                    data?.chapter_id ===
                                                                    selectedChapter?.chapter_id,
                                                            }
                                                        )}
                                                        data-chapterId={
                                                            data.chapter_id
                                                        }
                                                        onClick={() => {
                                                            setSelectedChapter(
                                                                data
                                                            );
                                                        }}
                                                    >
                                                        <Typography
                                                            className={
                                                                classes.chapterButtonText
                                                            }
                                                        >
                                                            {data?.title}
                                                        </Typography>
                                                    </Button>
                                                </HtmlTooltip>
                                            )}
                                        />
                                    </Box>
                                )}
                                <ReactHlsPlayer
                                    className={classes.videoContainer}
                                    src={activeVersionURL || modalContentUrl}
                                    autoPlay={false}
                                    controls={true}
                                    playerRef={videoRef}
                                    onPlay={handlePlay}
                                    onPause={handlePause}
                                    disablePictureInPicture={true}
                                    controlsList="nodownload noplaybackrate"
                                    crossOrigin="anonymous"
                                    onTimeUpdate={handleTimeUpdate}
                                    onSeekingCapture={handleSeekCapture}
                                >
                                    <VideoCaptions
                                        caption_languages={captionsAvailable}
                                        contentURL={contentURL}
                                    />
                                </ReactHlsPlayer>

                                <IconButton
                                    className={classes.chaptersCollapseButton}
                                    sx={{
                                        left: `${
                                            displayChapter ? '200px' : '25px'
                                        }`,
                                        backgroundColor: `${
                                            displayChapter ? '#000' : '#555555'
                                        }`,
                                        '&:hover': {
                                            backgroundColor: `${
                                                displayChapter
                                                    ? '#000'
                                                    : '#555555'
                                            }`,
                                        },
                                        display: `${
                                            isPlaying ? 'none' : 'flex'
                                        }`,
                                    }}
                                    onClick={() => {
                                        setDisplayChapter(!displayChapter);
                                    }}
                                >
                                    <MdList className="react-icon" />
                                </IconButton>
                            </Box>
                        ) : (
                            <ReactHlsPlayer
                                className={classes.videoContainer}
                                src={activeVersionURL || modalContentUrl}
                                autoPlay={false}
                                controls={true}
                                disablePictureInPicture={true}
                                controlsList="nodownload noplaybackrate"
                                crossOrigin="anonymous"
                            >
                                <VideoCaptions
                                    caption_languages={captionsAvailable}
                                    contentURL={contentURL}
                                />
                            </ReactHlsPlayer>
                        )}
                    </>
                );
            } else if (extension?.toLowerCase() !== 'mp4') {
                return (
                    <div style={{ textAlign: 'center', color: 'red' }}>
                        This video will be available once conversion is
                        completed
                    </div>
                );
            } else {
                return (
                    <>
                        {activeVersion &&
                        activeVersion?.chapters &&
                        activeVersion?.chapters?.length > 0 ? (
                            <Box
                                display="flex"
                                gap="5px"
                                overflow="hidden"
                                height="100%"
                                width="100%"
                                position="relative"
                            >
                                {displayChapter && (
                                    <Box
                                        className={classes.chaptersWrapper}
                                        height={videoRef?.current?.clientHeight}
                                    >
                                        <Typography
                                            className={classes.chaptersLabel}
                                        >
                                            Chapters
                                        </Typography>
                                        <Each
                                            of={[
                                                ...activeVersion.chapters,
                                            ].sort(
                                                (a, b) =>
                                                    a.chapter_id - b.chapter_id
                                            )}
                                            render={(data, idx) => (
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <Typography
                                                                variant="caption"
                                                                fontWeight="bold"
                                                            >
                                                                {data?.title}
                                                            </Typography>
                                                            <br />
                                                            {'(' +
                                                                formatTime(
                                                                    data?.start_time
                                                                ) +
                                                                '-' +
                                                                formatTime(
                                                                    data?.end_time
                                                                ) +
                                                                ')'}
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <Button
                                                        className={clsx(
                                                            classes.chaptersButton,
                                                            {
                                                                [classes.activeChapterButton]:
                                                                    data?.chapter_id ===
                                                                    selectedChapter?.chapter_id,
                                                            }
                                                        )}
                                                        data-chapterId={
                                                            data.chapter_id
                                                        }
                                                        onClick={() => {
                                                            setSelectedChapter(
                                                                data
                                                            );
                                                        }}
                                                    >
                                                        <Typography
                                                            className={
                                                                classes.chapterButtonText
                                                            }
                                                        >
                                                            {data?.title}
                                                        </Typography>
                                                    </Button>
                                                </HtmlTooltip>
                                            )}
                                        />
                                    </Box>
                                )}
                                <video
                                    className={classes.videoContainer}
                                    src={activeVersionURL || modalContentUrl}
                                    autoPlay={false}
                                    style={{
                                        position: `${
                                            displayChapter
                                                ? 'relative'
                                                : 'absolute'
                                        }`,
                                        width: `${
                                            displayChapter
                                                ? 'calc(100% - 200px)'
                                                : '100%'
                                        }`,
                                        height: '100%',
                                    }}
                                    controls={true}
                                    ref={videoRef}
                                    onPlay={handlePlay}
                                    onPause={handlePause}
                                    disablePictureInPicture={true}
                                    controlsList="nodownload noplaybackrate"
                                    crossOrigin="anonymous"
                                    onTimeUpdate={handleTimeUpdate}
                                    onSeekingCapture={handleSeekCapture}
                                >
                                    <VideoCaptions
                                        caption_languages={captionsAvailable}
                                        contentURL={contentURL}
                                    />
                                </video>
                                <IconButton
                                    className={classes.chaptersCollapseButton}
                                    sx={{
                                        left: `${
                                            displayChapter ? '200px' : '25px'
                                        }`,
                                        backgroundColor: `${
                                            displayChapter ? '#000' : '#555555'
                                        }`,
                                        '&:hover': {
                                            backgroundColor: `${
                                                displayChapter
                                                    ? '#000'
                                                    : '#555555'
                                            }`,
                                        },
                                        display: `${
                                            isPlaying ? 'none' : 'flex'
                                        }`,
                                    }}
                                    onClick={() => {
                                        setDisplayChapter(!displayChapter);
                                    }}
                                >
                                    <MdList className="react-icon" />
                                </IconButton>
                            </Box>
                        ) : (
                            <video
                                className={classes.videoContainer}
                                src={activeVersionURL || modalContentUrl}
                                autoPlay={false}
                                controls={true}
                                disablePictureInPicture={true}
                                controlsList="nodownload noplaybackrate"
                                crossOrigin="anonymous"
                            >
                                <VideoCaptions
                                    caption_languages={captionsAvailable}
                                    contentURL={contentURL}
                                />
                            </video>
                        )}
                    </>
                );
            }
        }

        if (linkTypeId === LinkTypes.Image.id) {
            const activeVersion =
                Array.isArray(contentVersions) && contentVersions.length > 0
                    ? contentVersions.find(
                          (conVer) => conVer?.url === modalContentUrl
                      )
                    : null;
            const overlayText = activeVersion?.overlay_text
                ? JSON.parse(activeVersion?.overlay_text)
                : '';
            return (
                <Box className={classes.zoomWrapperMain}>
                    <TransformWrapper
                        panning={{
                            velocityDisabled: true,
                            velocityAnimation: true,
                        }}
                        centerOnInit={true}
                        centerZoomedOut={true}
                        disablePadding={true}
                        pinch={{ step: 5 }}
                        wheel={{ smoothStep: 0.001, step: 0.2 }}
                        doubleClick={{ step: 0.7 }}
                    >
                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                            <>
                                <div className={classes.imageZoomButtons}>
                                    <div
                                        className={classes.zoomButton}
                                        onClick={() => resetTransform()}
                                    >
                                        <MdRefresh />
                                    </div>
                                </div>
                                <TransformComponent>
                                    <img
                                        src={modalContentUrl}
                                        alt={
                                            activeVersion?.original_file_name ||
                                            modalContentUrl
                                        }
                                        style={{
                                            objectFit: 'cover',
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                        }}
                                    />
                                </TransformComponent>
                                {overlayText?.html && (
                                    <div
                                        className={clsx(
                                            classes.overlayText,
                                            overrideResetClasses.overrideMarzipanoResetCSS
                                        )}
                                        dangerouslySetInnerHTML={{
                                            __html: overlayText?.html,
                                        }}
                                    ></div>
                                )}
                            </>
                        )}
                    </TransformWrapper>
                </Box>
            );
        }

        return (
            <iframe
                title="ContentValidationModal"
                src={modalContentUrl}
                key={modalContentUrl}
                width="100%"
                height="100%"
                frameBorder="0"
            ></iframe>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        linkTypeId,
        modalContentUrl,
        activeTree,
        sortedContentVersion,
        selectedVersion,
        modalContent,
        overrideResetClasses.overrideMarzipanoResetCSS,
        contentVersions,
        displayChapter,
        handleTimeUpdate,
        handleSeekCapture,
        isPlaying,
        selectedChapter?.chapter_id,
    ]);

    return (
        <div>
            <Modal open={modalVis} onClose={handleClose}>
                <div
                    className={clsx(
                        classes.modalContainer,
                        classes.modalContainerLarge
                    )}
                >
                    {/* Add hidden class to select if viewing a links content */}
                    <NativeSelect
                        className={clsx(panelClasses.selectBox, {
                            [panelClasses.hidden]:
                                Object.prototype.hasOwnProperty.call(
                                    modalContent || '',
                                    'link_type_id'
                                ) ||
                                linkTypeId === LinkTypes.SubjectMatterExpert.id,
                        })}
                        style={{ margin: '0px 0px 20px 0px', width: '100%' }}
                        // value={modalContentUrl}
                        value={
                            selectedVersion ||
                            [...sortedContentVersion].pop()?.version
                        }
                        onChange={(e) => {
                            setSelectedVersion(+e.target.value);
                            const value =
                                e.target.options[
                                    e.target.selectedIndex
                                ].getAttribute('name');

                            if (linkTypeId === LinkTypes.Procedure.id) {
                                fetchProceduresList(+value);
                            }
                            setModalContentUrl(value);
                        }}
                        name="content_version"
                    >
                        {sortedContentVersion.map((version) => {
                            let name = null;
                            if (linkTypeId === LinkTypes.Procedure.id) {
                                name = version?.procedure_id;
                            } else {
                                if (version?.embed_data !== undefined) {
                                    name = version.embed_data;
                                } else {
                                    if (
                                        version?.encoding_status &&
                                        version?.encoding_status?.toLowerCase() ===
                                            'complete'
                                    ) {
                                        name = version?.encoded_url;
                                    } else {
                                        name = version?.url;
                                    }
                                }
                            }
                            return (
                                <option
                                    name={name}
                                    key={`version-${version?.content_version_id}`}
                                    value={version.version}
                                >
                                    Version {version.version}
                                </option>
                            );
                        })}
                    </NativeSelect>
                    {viewContainer()}
                    <Button
                        className={classes.close}
                        onClick={handleClose}
                        variant="contained"
                        color="primary"
                        disableElevation
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        </div>
    );
}

export { ContentValidationModal };
