class Draggable {
    dragging = false;
    currentX;
    currentY;
    initialX;
    initialY;
    offsetX;
    offsetY;
    moved = false;
    dragElement;
    onMovedElement;
    initialOffset;
    initialTranslate;
    lastLocation = null;
    horizontalOnly = false;
    verticalOnly = false;
    contained = false;

    getTranslateValues(element) {
        const style = window.getComputedStyle(element);
        const matrix =
            style['transform'] || style.webkitTransform || style.mozTransform;

        // No transform property. Simply return 0 values.
        if (matrix === 'none' || typeof matrix === 'undefined') {
            return {
                x: 0,
                y: 0,
                z: 0,
            };
        }

        // Can either be 2d or 3d transform
        const matrixType = matrix.includes('3d') ? '3d' : '2d';
        const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ');

        // 2d matrices have 6 values
        // Last 2 values are X and Y.
        // 2d matrices does not have Z value.
        if (matrixType === '2d') {
            return {
                x: matrixValues[4],
                y: matrixValues[5],
                z: 0,
            };
        }

        // 3d matrices have 16 values
        // The 13th, 14th, and 15th values are X, Y, and Z
        if (matrixType === '3d') {
            return {
                x: matrixValues[12],
                y: matrixValues[13],
                z: matrixValues[14],
            };
        }
    }

    handleMouseDown = ({
        event,
        dragElement,
        horizontalOnly,
        verticalOnly,
        contained,
        onMoved,
    }) => {
        this.parentRect = dragElement.parentElement.getBoundingClientRect();
        this.onMovedElement = onMoved;
        this.horizontalOnly = horizontalOnly;
        this.verticalOnly = verticalOnly;
        this.contained = contained;

        this.dragging = true;

        this.dragElement = dragElement;

        let clientPos = this.getPosition(event);

        let rect = dragElement.getBoundingClientRect();
        let centerX = (rect.left + rect.right) / 2;
        let centerY = (rect.top + rect.bottom) / 2;

        this.initialOffset = {
            x: clientPos.x - centerX,
            y: clientPos.y - centerY,
        };
        var translate = this.getTranslateValues(this.dragElement);

        this.initialTranslate = translate;

        this.offsetX = translate.x;
        this.offsetY = translate.y;

        this.initialX = clientPos.x - translate.x;
        this.initialY = clientPos.y - translate.y;

        document.onpointerup = this.closeDragElement;

        document.onpointermove = this.elementDrag;
    };

    elementDrag = (e) => {
        e = e || window.event;

        let clientPos = this.getPosition(e);

        if (this.horizontalOnly === true) {
            clientPos.y = this.initialY;
        }
        if (this.verticalOnly === true) {
            clientPos.x = this.initialX;
        }

        const lastX = this.currentX;
        const lastY = this.currentY;

        this.currentX = clientPos.x - this.initialX;
        this.currentY = clientPos.y - this.initialY;

        this.offsetX = this.currentX;
        this.offsetY = this.currentY;

        this.dragElement.style.transform =
            'translate(' + this.currentX + 'px, ' + this.currentY + 'px)';

        if (this.contained) {
            let rect = this.dragElement.getBoundingClientRect();
            if (
                rect.left < this.parentRect.left ||
                rect.right > this.parentRect.right
            ) {
                this.currentX = lastX;
                this.currentY = lastY;
                this.dragElement.style.transform =
                    'translate(' +
                    this.currentX +
                    'px, ' +
                    this.currentY +
                    'px)';
            }
        }

        let movedX = this.initialTranslate.x - this.currentX;
        let movedY = this.initialTranslate.y - this.currentY;

        if (Math.abs(movedX) > 0.2 || Math.abs(movedY) > 0.1) {
            this.moved = true;
        }
    };

    getPosition = (e) => {
        if (e.type === 'touchmove') {
            return { x: e.touches[0].clientX, y: e.touches[0].clientY };
        } else {
            return { x: e.clientX, y: e.clientY };
        }
    };

    closeDragElement = (e) => {
        e = e || window.event;

        document.onpointerup = null;
        document.onpointermove = null;

        if (this.moved) {
            this.moved = false;

            if (this.onMovedElement) {
                const screen = this.getCoordinates(e);
                this.onMovedElement(screen, this.dragElement);
            }
        }
    };

    getCoordinates = (e) => {
        let screen;
        screen = this.getPosition(e);
        screen.x -= this.initialOffset.x;
        screen.y -= this.initialOffset.y;
        return screen;
    };
}

export let draggable = new Draggable();
