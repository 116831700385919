import { createSlice } from '@reduxjs/toolkit';

import { last, remove } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { FormActions } from '_helpers/form-action';

const initialState = {
    openForm: null,
    isDirty: false,
    formStack: [],
    currentForm: [],
    accountForm: {
        formKey: 'accountForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
    },
    contentForm: {
        formKey: 'contentForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: true,
    },
    contentVersionForm: {
        formKey: 'contentVersionForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
    },
    groupForm: {
        formKey: 'groupForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
    },
    categoryForm: {
        formKey: 'categoryForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
        assetTypeID: null,
    },
    hotspotForm: {
        formKey: 'hotspotForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
    },
    itemForm: {
        formKey: 'itemForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: true,
        isContentPanelOpen: false,
    },
    linkForm: {
        formKey: 'linkForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: true,
    },
    assetForm: {
        formKey: 'assetForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
        assetTypeID: null,
    },
    assetExportForm: {
        formKey: 'assetExportForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
        assetTypeID: null,
    },
    procedureForm: {
        formKey: 'procedureForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
        assetTypeID: null,
    },
    userForm: {
        formKey: 'userForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
    },
    zoneForm: {
        formKey: 'zoneForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: true,
        isContentPanelOpen: false,
    },
    itemTagForm: {
        formKey: 'itemTagForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
        assetTypeID: null,
    },
    audienceForm: {
        formKey: 'audienceForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
        assetTypeID: null,
    },
    toolCategoryForm: {
        formKey: 'toolCategoryForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
        assetTypeID: null,
    },
    locationForm: {
        formKey: 'locationForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
        assetTypeID: null,
    },
    toolForm: {
        formKey: 'toolForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
        assetTypeID: null,
    },
    partForm: {
        formKey: 'partForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
        assetTypeID: null,
    },
    partGroupForm: {
        formKey: 'partGroupForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
        assetTypeID: null,
    },
    partCategoryForm: {
        formKey: 'partCategoryForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
        assetTypeID: null,
    },
    companionForm: {
        formKey: 'companionForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
    },
    newsFlashForm: {
        formKey: 'newsFlashForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
    },
    managementDeployForm: {
        formKey: 'managementDeployForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
    },
    contentInventoryForm: {
        formKey: 'contentInventoryForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
    },
    exportAssetDetailsForm: {
        formKey: 'exportAssetDetailsForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
    },
    jobDeployForm: {
        formKey: 'jobDeployForm',
        isOpen: false,
        formAction: null,
        editObjectId: null,
        editObjectInfo: null,
        showGeminiViewer: false,
    },
};

/*
export const loadEditObject = createAsyncThunk(
    'formPanel/loadEditObject',
    async (props, { getState }) => {
        var response;
        switch (props.formKey) {
            case 'itemForm':
                const item = await itemService.getById(
                    props.objectId,
                    getState().accounts.activeUser.s3_prefix
                );

                response = {
                    formKey: props.formKey,
                    formAction: props.formAction,
                    editObjectInfo: { editObject: item },
                };
                break;
            default:
                break;
        }
        return response;
    }
);
*/

const formPanelSlice = createSlice({
    name: 'formPanel',
    initialState,
    reducers: {
        openPanel: (state, action) => {
            if (
                state[action.payload.formKey].isOpen !== true ||
                state[action.payload.formKey].formAction.id !==
                    FormActions[action.payload.formAction].id
            ) {
                state[action.payload.formKey].isOpen = true;
                state[action.payload.formKey].formAction =
                    FormActions[action.payload.formAction];
                state[action.payload.formKey].assetTypeID =
                    action.payload.assetTypeID;
                state[action.payload.formKey].isContentPanelOpen =
                    action.payload.isContentPanelOpen ?? false;
                state.openForm = state[action.payload.formKey];
                state.formStack.push(state[action.payload.formKey]);
                state.currentForm.push(action.payload.formKey);
                state.isDirty = false;
                if (action.payload?.clearEditId) {
                    state[action.payload.formKey].editObjectId = null;
                }
            }
        },
        closePanel: (state, action) => {
            state[action.payload.formKey].isOpen = false;
            state[action.payload.formKey].formAction = null;
            state[action.payload.formKey].editObjectId = null;
            state[action.payload.formKey].editObjectInfo = null;
            state[action.payload.formKey].isContentPanelOpen =
                action.payload.isContentPanelOpen ?? false;
            state.openForm = null;
            remove(state.formStack, state[action.payload.formKey]);
            state.openForm =
                state.formStack.lengh > 0 ? last(state.formStack) : null;
            state.currentForm = state.currentForm.filter(
                (formName) => formName !== action.payload.formKey
            );
            state.isDirty = false;
        },
        setEditObjectId: (state, action) => {
            state[action.payload.formKey].editObjectId =
                action.payload.object_id;
        },
        setEditObject: (state, action) => {
            state[action.payload.formKey].editObjectInfo =
                action.payload.editObject;
        },
        closeAllPanel: (state, action) => {
            return initialState;
        },
        setIsDirty: (state, action) => {
            state.isDirty = action.payload;
        },
    },
    extraReducers: {},
});

// Select all form info for rendering opened forms
export const selectFormInfo = (state) => state.forms;
export const currentFormInfo = (state) =>
    state.forms.currentForm[state.forms.currentForm.length - 1];
export const isFormOpen = (state) =>
    state.forms.accountForm.isOpen ||
    state.forms.contentForm.isOpen ||
    state.forms.contentVersionForm.isOpen ||
    state.forms.groupForm.isOpen ||
    state.forms.categoryForm.isOpen ||
    state.forms.hotspotForm.isOpen ||
    state.forms.itemForm.isOpen ||
    state.forms.linkForm.isOpen ||
    state.forms.assetForm.isOpen ||
    state.forms.procedureForm.isOpen ||
    state.forms.userForm.isOpen ||
    state.forms.itemTagForm.isOpen ||
    state.forms.audienceForm.isOpen ||
    state.forms.assetExportForm.isOpen ||
    state.forms.toolCategoryForm.isOpen ||
    state.forms.locationForm.isOpen ||
    state.forms.toolForm.isOpen ||
    state.forms.partForm.isOpen ||
    state.forms.partGroupForm.isOpen ||
    state.forms.partCategoryForm.isOpen ||
    state.forms.companionForm.isOpen ||
    state.forms.newsFlashForm.isOpen ||
    state.forms.zoneForm.isOpen ||
    state.forms.contentInventoryForm.isOpen ||
    state.forms.managementDeployForm.isOpen ||
    state.forms.exportAssetDetailsForm.isOpen;

export const getDirtyFlag = (state) => state.forms.isDirty;
// Get the object id of the editable object
// export const selectEditObjectId = (state, formKey) => state.forms[formKey].editObjectId;

export const {
    openPanel,
    closePanel,
    setEditObjectId,
    setEditObject,
    closeAllPanel,
    setIsDirty,
} = formPanelSlice.actions;

export default formPanelSlice.reducer;
