import React from 'react';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { Action } from '_helpers';
import { Divider } from '@mui/material';

function UserManagementPopup({ object, level, onClose }) {
    return (
        <React.Fragment>
            <PopupAction
                action={Action.Edit}
                object={object}
                level={level}
                showLabel={true}
                onActionHandled={onClose}
            />
            {object.is_active ? (
                <React.Fragment>
                    <Divider />
                    <PopupAction
                        action={Action.Deactivate}
                        object={object}
                        level={level}
                        showLabel={true}
                        onActionHandled={onClose}
                    />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Divider />
                    <PopupAction
                        action={Action.Reactivate}
                        object={object}
                        level={level}
                        showLabel={true}
                        onActionHandled={onClose}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export { UserManagementPopup };
