import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { tabStyles } from 'GeminiViewerComponent/components/styles';
import {
    getToolsTabIndex,
    setToolsTabIndex,
} from '_features/globals/visibilitySlice';
import { ToolsTab } from '../ToolsTab';
import { CategoryTab } from '../ToolCategoryTab';
import { LocationTab } from '../LocationTab';

function ToolTab(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            style={{ height: '100%' }}
            {...other}
        >
            {value === index && (
                <Box pt={1} height="100%" overflow="auto hidden">
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

ToolTab.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function ToolsTabPanel() {
    const dispatch = useDispatch();
    const classes = tabStyles();
    const displayTabIndex = useSelector(getToolsTabIndex);

    const handleChange = (event, newValue) => {
        dispatch(setToolsTabIndex(newValue));
    };

    let tabBarIndex = -1;
    let tabPageIndex = -1;
    return (
        <div className={classes.tabRoot}>
            <AppBar position="static" color="default">
                <Tabs
                    value={displayTabIndex}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    className={classes.tabs}
                >
                    <Tab label="Tools" {...a11yProps(++tabBarIndex)} />
                    <Tab label="Categories" {...a11yProps(++tabBarIndex)} />
                    <Tab label="Locations" {...a11yProps(++tabBarIndex)} />
                </Tabs>
            </AppBar>
            <ToolTab value={displayTabIndex} index={++tabPageIndex}>
                <ToolsTab />
            </ToolTab>
            <ToolTab value={displayTabIndex} index={++tabPageIndex}>
                <CategoryTab />
            </ToolTab>
            <ToolTab value={displayTabIndex} index={++tabPageIndex}>
                <LocationTab />
            </ToolTab>
        </div>
    );
}

export { ToolsTabPanel };
