import { makeStyles } from '@mui/styles';

const sidePanelStyles = makeStyles(() => ({
    drawer: {
        '& .MuiDrawer-paper': {
            overflowX: 'hidden',
            zIndex: '1300',
            backgroundColor: (props) => props.colors.side_panel_body,
        },
    },
    sidePanelContainer: {
        minWidth: '300px',
        width: '26vw',
    },
}));

export { sidePanelStyles };
