import { Box } from '@mui/material';
import { LinkTypes } from '_helpers';
import { MdPublic, MdVrpano, MdWorkOutline } from 'react-icons/md';

const FieldSource = ({ source }) => {
    if (source === 'Global') {
        return <MdPublic className="react-icon" style={{ color: '#1154ce' }} />;
    }
    if (source === 'Client') {
        return (
            <MdWorkOutline
                className="react-icon"
                style={{ color: '#11cc6d' }}
            />
        );
    }
    if (source === 'Asset') {
        return <MdVrpano className="react-icon" style={{ color: '#d65712' }} />;
    }
    return null;
};

const ConfigHeader = ({ link_type_id }) => {
    return (
        <>
            <legend
                style={{
                    marginLeft: '10px',
                    padding: '3px',
                    fontSize: '14px',
                    color: '#6d7178',
                }}
            >
                {link_type_id === LinkTypes.Video.id && 'Video Settings'}
                {link_type_id === LinkTypes.Image.id && 'Image Settings'}
            </legend>
            <Box display="flex" gap="10px" margin="10px 0">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MdPublic
                        className="react-icon"
                        style={{ color: '#1154ce' }}
                    />
                    &nbsp;= Global Value
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MdWorkOutline
                        className="react-icon"
                        style={{ color: '#11cc6d' }}
                    />
                    &nbsp;= Client Value
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MdVrpano
                        className="react-icon"
                        style={{ color: '#d65712' }}
                    />
                    &nbsp;= Asset Value
                </div>
            </Box>
            <hr />
        </>
    );
};

export { FieldSource, ConfigHeader };
