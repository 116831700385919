import { makeStyles } from '@mui/styles';

const profileItemStyles = makeStyles(() => ({
    profileDisplay: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 16,
    },
    minProfile: {
        padding: '16px 1px',
    },
    minImage: {
        height: '45px !important',
        width: '45px !important',
        margin: '5px 7px',
    },
    profileName: {
        width: '175px',
        textAlign: 'left',
        color: '#2D3D50',
        fontSize: '16px',
        fontWeight: 800,
    },
    smallProfileName: {
        textAlign: 'center',
        color: '#2D3D50',
        fontSize: '12px',
        fontWeight: 400,
        padding: '2px',
    },
    profileTitle: {
        width: '175px',
        textAlign: 'left',
        color: '#2D3D50',
        fontSize: '12px',
    },
    profileClientName: {
        width: '175px',
        textAlign: 'left',
        color: '#696969',
        fontSize: '12px',
    },
    profileImage: {
        height: '55 !important',
        width: '55 !important',
        marginRight: 15,
        cursor: 'pointer',
    },
}));

export { profileItemStyles };
