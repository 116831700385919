import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { useStyles } from './styles';
import SearchWithFilters from 'components/SearchWithFilters';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { resizableLabelHeader, resizableLabelCell } from 'components';
import { ResizableTable } from 'GeminiViewerComponent/components/_Tables/ResizableTable';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';

function MediaConversionTable({
    contents,
    pageInfo,
    contentPayload,
    setContentPayload,
    sortDirection,
    sortBy,
    handleSort,
}) {
    const classes = useStyles();
    const tableClasses = tableStyles();
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = React.useState(false);

    const loadedRowsMap = useSelector((state) => state.content.loadedRowsMap);

    const headerHeight = 40;
    const rowHeight = 60;

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== contents.length) {
            setSelected(contents.map((row) => row.tag_id));
            setSelectedAll(true);
        }
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const columns = [
        {
            accessorKey: 'display_name',
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'Media Name',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'encoding_status',
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'Encoding Status',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'encoding_percent',
            flexGrow: 1,
            size: 200,
            minSize: 200,
            label: 'Encoding Percent',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'transcribing_status',
            flexGrow: 1,
            size: 200,
            minSize: 200,
            label: 'Transcribe Status',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'transcribing_percent',
            flexGrow: 1,
            size: 200,
            minSize: 200,
            label: 'Transcribe Percent',
            padding: 'normal',
            enableResizing: false,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
    ];

    // eslint-disable-next-line no-unused-vars
    function loadMoreRows({ startIndex, stopIndex }) {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setContentPayload({
                ...contentPayload,
                reset: true,
                searchString: searchString,
            });
        }, 1000),
        [contentPayload]
    );

    return (
        <div className={classes.root}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="end"
                marginBottom="10px"
            >
                <SearchWithFilters
                    filter={false}
                    onChange={(evt) => debouncedSearch(evt.target.value)}
                />
            </Box>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    toolTipTitle={'Delete'}
                    totalCount={pageInfo.TotalCount}
                ></EnhancedTableToolbar>
            )}
            <div style={{ height: 'calc(100vh - 375px)', minWidth: '500px' }}>
                <ResizableTable
                    initialRows={contents}
                    allRows={contents}
                    totalRowCount={pageInfo.TotalCount}
                    loadedRowsMap={loadedRowsMap}
                    loadMoreRows={loadMoreRows}
                    columns={columns}
                    cellClassName={tableClasses.flexContainer}
                    className={tableClasses.table}
                    rowClassName={tableClasses.flexContainer}
                    headerHeight={headerHeight}
                    rowHeight={rowHeight}
                    selected={selected}
                />
            </div>
        </div>
    );
}

export { MediaConversionTable };
