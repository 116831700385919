import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { MdAdd, MdClose } from 'react-icons/md';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    IconButton,
    Button,
    TextField,
} from '@mui/material';

import { ThemeProvider } from '@mui/styles';
import { accountsSlice, accountsThunk } from 'app/store';
import { ThemeSelector } from './ThemeSelector';
import EditThemeForm from './EditThemeForm';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import {
    selectActiveTheme,
    selectDefaultTheme,
} from 'GeminiViewerComponent/_features/globals/themeSlice';
const { updateClientThemeInfo, newCustomThemeData, updateCustomThemeData } =
    accountsThunk;

const { selectActiveUser } = accountsSlice;

const ThemeManager = () => {
    const theme = useSelector(selectActiveTheme);
    const defaultTheme = useSelector(selectDefaultTheme);
    const { enqueueSnackbar } = useSnackbar();

    const [selectedTheme, setSelectedTheme] = useState(theme);
    const [showDialog, setShowDialog] = useState(false);
    const [selectedDeleteTheme, setSelectedDeleteTheme] = useState(null);
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] =
        useState(false);
    const [duplicateThemeName, setDuplicateThemeName] = useState('');
    const [selectedDuplicateTheme, setSelectedDuplicateTheme] = useState(null);
    const [showDuplicateConfirmDialog, setShowDuplicateConfirmDialog] =
        useState(false);

    const dispatch = useDispatch();

    const activeUser = useSelector(selectActiveUser);

    useEffect(() => {
        setSelectedTheme({
            ...defaultTheme?.default,
            ...theme,
            colors: {
                ...defaultTheme?.default.colors,
                ...theme?.colors,
            },
        });
    }, [theme]);

    const toggleDialog = () => setShowDialog(!showDialog);

    const updateTheme = async (theme) => {
        if (theme.name.trim().toLowerCase() === 'default') {
            enqueueSnackbar('Theme name must not be "Default"', {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: '',
            });
            return;
        }

        var userThemes = { ...activeUser.themes };
        const key = Object.keys(userThemes).find(
            (key) => userThemes[key].id === theme.id
        );
        userThemes[key] = theme;

        dispatch(activateLoading({ showProgress: false }));

        const resultAction = await dispatch(
            updateClientThemeInfo({
                client_id: activeUser.client_id,
                params: { themes: userThemes },
            })
        );

        dispatch(deactivateLoading());

        if (!resultAction.error) {
            setSelectedTheme(theme);
        }

        const { message, variant } = snackbarHandler(
            resultAction.meta.requestStatus,
            'Update theme'
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });

        setShowDialog(false);
    };
    const handleAddTheme = async () => {
        let newThemeValues = { ...defaultTheme?.default };
        newThemeValues.name = 'Custom Theme';
        delete newThemeValues.id;
        setSelectedTheme(newThemeValues);
        setShowDialog(true);
    };
    const newTheme = async (
        theme,
        iconData,
        mode = 'New',
        source_theme_id = null
    ) => {
        var userThemes = { ...activeUser.themes };
        if (theme.name.trim().toLowerCase() === 'default') {
            enqueueSnackbar('Theme name must not be "Default"', {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
            return;
        }

        const key = Object.keys(userThemes).find(
            (key) =>
                userThemes[key].name.toLowerCase() === theme.name.toLowerCase()
        );

        if (key) {
            enqueueSnackbar('Duplicate theme name', {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
            return;
        }

        userThemes[theme.name.toLowerCase()] = theme;

        dispatch(activateLoading({ showProgress: false }));

        const defaultIconResult = await dispatch(
            newCustomThemeData({
                theme_id: theme.id,
                source_theme_id: source_theme_id,
            })
        );
        let isError = false;
        if (defaultIconResult.error) {
            enqueueSnackbar('Icon creation failed, Please try again later', {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
            isError = true;
        }
        if (isError === false && iconData) {
            const updateIconResult = await dispatch(
                updateCustomThemeData({ ...iconData, theme_id: theme.id })
            );
            if (updateIconResult.error) {
                enqueueSnackbar('Icon update failed, Please try again later', {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'error',
                });
                isError = true;
            }
        }
        if (isError === false) {
            const resultAction = await dispatch(
                updateClientThemeInfo({
                    client_id: activeUser.client_id,
                    params: { themes: userThemes },
                })
            );
            if (!resultAction.error) {
                setSelectedTheme(theme);
            }
            const { message, variant } = snackbarHandler(
                resultAction.meta.requestStatus,
                `${mode} theme`
            );
            enqueueSnackbar(message, {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: variant,
            });
        }
        dispatch(deactivateLoading());

        setShowDialog(false);
        if (mode === 'Duplicate') {
            closeDuplicateConfirmDialog();
        }
    };

    const editTheme = (editTheme) => {
        setSelectedTheme({
            ...defaultTheme?.default,
            ...editTheme,
            colors: {
                ...defaultTheme?.default.colors,
                ...editTheme?.colors,
            },
        });
        setShowDialog(true);
    };

    const duplicateTheme = useCallback(async () => {
        let newThemeId = uuidv4();
        await newTheme(
            {
                ...selectedDuplicateTheme,
                id: newThemeId,
                name: duplicateThemeName,
            },
            null,
            'Duplicate',
            selectedDuplicateTheme.id
        );
    }, [duplicateThemeName, selectedDuplicateTheme]);

    const deleteTheme = async () => {
        var userThemes = { ...activeUser.themes };
        const key = Object.keys(userThemes).find(
            (key) => userThemes[key].id === selectedDeleteTheme.id
        );
        if (key) {
            delete userThemes[key];
        }
        dispatch(activateLoading({ showProgress: false }));

        const resultAction = await dispatch(
            updateClientThemeInfo({
                client_id: activeUser.client_id,
                params: { themes: userThemes },
            })
        );

        dispatch(deactivateLoading());

        setShowDeleteConfirmDialog(false);
        setSelectedDeleteTheme(null);

        const { message, variant } = snackbarHandler(
            resultAction.meta.requestStatus,
            'Delete theme'
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });
    };

    const showDeleteThemeDialog = (deleteTheme) => {
        setSelectedDeleteTheme(deleteTheme);
        setShowDeleteConfirmDialog(true);
    };

    const closeDeleteConfirmDialog = () => {
        setSelectedDeleteTheme(null);
        setShowDeleteConfirmDialog(false);
    };

    const showDuplicateThemeDialog = (duplicate) => {
        setSelectedDuplicateTheme(duplicate);
        setShowDuplicateConfirmDialog(true);
        setDuplicateThemeName('');
    };

    const closeDuplicateConfirmDialog = () => {
        setSelectedDuplicateTheme(null);
        setShowDuplicateConfirmDialog(false);
        setDuplicateThemeName('');
    };

    return (
        <ThemeProvider theme={selectedTheme}>
            <div style={{ margin: '10px' }}>
                <IconButton
                    onClick={handleAddTheme}
                    size="large"
                    title="Add Theme"
                    style={{
                        padding: '10px',
                        borderRadius: '25px',
                        color: 'rgb(255, 255, 255)',
                        backgroundColor: '#3598db',
                    }}
                >
                    <MdAdd className="react-icon" />
                </IconButton>
                <Dialog open={showDialog} fullWidth={true} maxWidth="xl">
                    <DialogTitle
                        sx={{
                            backgroundColor:
                                selectedTheme.colors.primarySectionBackground,
                            color: selectedTheme.colors
                                .primarySectionForeground,
                        }}
                    >
                        Edit Theme
                        <IconButton
                            aria-label="close"
                            onClick={toggleDialog}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 6,
                                color: selectedTheme.colors
                                    .primarySectionForeground,
                            }}
                            size="large"
                        >
                            <MdClose className="react-icon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent
                        sx={{ marginTop: '10px', overflowY: 'auto' }}
                    >
                        <EditThemeForm
                            theme={selectedTheme}
                            updateTheme={updateTheme}
                            newTheme={newTheme}
                            activeUser={activeUser}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={showDuplicateConfirmDialog}
                    onClose={closeDuplicateConfirmDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Duplicate Theme
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Provide new name for duplicate Theme
                            <TextField
                                fullWidth
                                autoFocus
                                margin="dense"
                                id="theme_name"
                                value={duplicateThemeName}
                                onChange={(e) => {
                                    setDuplicateThemeName(e.target.value);
                                }}
                                label={'Theme Name'}
                                type="text"
                                variant="outlined"
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDuplicateConfirmDialog}>
                            Cancel
                        </Button>
                        <Button onClick={duplicateTheme}>Duplicate</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={showDeleteConfirmDialog}
                    onClose={closeDeleteConfirmDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {'Delete Theme'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`Are you sure you want to delete "${selectedDeleteTheme?.name}" Theme?`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDeleteConfirmDialog} autoFocus>
                            Cancel
                        </Button>
                        <Button onClick={deleteTheme}>Confirm</Button>
                    </DialogActions>
                </Dialog>
                <ThemeSelector
                    setter={setSelectedTheme}
                    editor={editTheme}
                    remover={showDeleteThemeDialog}
                    duplicate={showDuplicateThemeDialog}
                />
            </div>
        </ThemeProvider>
    );
};

export default ThemeManager;
