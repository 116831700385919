import React, { useCallback, useRef } from 'react';
import Dropzone from 'react-dropzone';
import { MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import {
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';

import { panelStyles } from 'GeminiViewerComponent/components/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { makeFormStyles } from 'forms/styles';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { useSnackbar } from 'notistack';

export const VideoCaptionField = ({
    readOnlyMode,
    getConfigOptionsArray,
    configFields,
    validationTypes,
    defaultConfigValues,
}) => {
    const { values, setFieldValue } = useFormikContext();
    const { enqueueSnackbar } = useSnackbar();
    const noOfFilesAllowed = useRef(0);
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    const panelClasses = panelStyles(theme);

    const onDropError = useCallback(
        (err) => {
            if (err?.[0]?.errors?.[0]?.message) {
                let msg;
                if (err[0].errors[0]?.code === 'too-many-files') {
                    msg = `Only ${noOfFilesAllowed.current} files allowed`;
                } else {
                    msg = err[0].errors[0].message;
                }
                enqueueSnackbar(msg, {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'error',
                });
            }
        },
        [noOfFilesAllowed]
    );

    const removeCaptionFile = (value, idx, setFieldValue) => {
        let captionFiles = (
            Array.isArray(value?.caption_files) ? value.caption_files : []
        ).filter((lang, index) => index !== idx);
        let captionLanguages = (
            Array.isArray(value?.caption_languages)
                ? value.caption_languages
                : []
        ).filter((lang, index) => index !== idx);
        setFieldValue(`caption_files`, captionFiles);
        setFieldValue(`caption_languages`, captionLanguages);
    };

    return (
        <>
            <div className={panelClasses.dropzoneContainer}>
                <Dropzone
                    maxFiles={noOfFilesAllowed.current}
                    maxSize={250000000}
                    multiple={true}
                    accept={['.vtt']}
                    onDrop={(accepted_files, rejected_files) => {
                        if (
                            Array.isArray(rejected_files) &&
                            rejected_files.length === 0
                        ) {
                            setFieldValue('caption_files', accepted_files);
                            setFieldValue(
                                'caption_languages',
                                new Array(accepted_files.length).fill(
                                    '',
                                    0,
                                    accepted_files.length
                                )
                            );
                        } else {
                            onDropError(rejected_files);
                            setFieldValue('caption_files', null);
                            setFieldValue('caption_languages', null);
                        }
                    }}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div
                            {...getRootProps()}
                            className={`${panelClasses.dropzoneArea} ${panelClasses.dropZoneMinHeight50}`}
                            style={{
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center center',
                            }}
                        >
                            <input {...getInputProps()} />
                            <div className={panelClasses.dropzoneText}>
                                Drop/Select Caption Files
                            </div>
                        </div>
                    )}
                </Dropzone>
            </div>
            {Array.isArray(values?.caption_files) &&
                values.caption_files.map((fl, idx) => (
                    <div
                        style={{ display: 'flex', alignItems: 'center' }}
                        key={`caption-file-${idx}`}
                    >
                        <Grid
                            container
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                                marginBottom: '6px',
                            }}
                        >
                            <Grid
                                display={'flex'}
                                alignItems="center"
                                justifyContent={'left'}
                                item
                                xs={12}
                                sm={12}
                                md={5}
                                lg={5}
                            >
                                {fl?.name?.replace(/.vtt/i, '')}
                            </Grid>
                            <Grid
                                display={'flex'}
                                alignItems="center"
                                justifyContent={'left'}
                                item
                                xs={12}
                                sm={12}
                                md={5}
                                lg={5}
                            >
                                <Field
                                    className={classes.input}
                                    name={`caption_languages[${idx}]`}
                                    disabled={readOnlyMode}
                                >
                                    {({
                                        field: { value },
                                        form: { setFieldValue },
                                    }) => (
                                        <FormControl
                                            sx={{ margin: '8px 0' }}
                                            fullWidth
                                        >
                                            <InputLabel
                                                id={`caption_languages-${idx}-label`}
                                            >
                                                Caption Language
                                            </InputLabel>
                                            <Select
                                                labelId={`caption_languages-${idx}-label`}
                                                id={`caption_languages-${idx}-id`}
                                                label={`Caption Language`}
                                                value={
                                                    values?.caption_languages?.[
                                                        idx
                                                    ] || ''
                                                }
                                                onChange={(e) => {
                                                    let captionLanguages = (
                                                        Array.isArray(
                                                            values?.caption_languages
                                                        )
                                                            ? values.caption_languages
                                                            : []
                                                    ).map((lang, index) =>
                                                        index === idx
                                                            ? e.target.value
                                                            : lang
                                                    );
                                                    setFieldValue(
                                                        `caption_languages`,
                                                        captionLanguages
                                                    );
                                                }}
                                            >
                                                {getConfigOptionsArray(
                                                    configFields,
                                                    validationTypes,
                                                    defaultConfigValues,
                                                    'transcribe_language'
                                                )
                                                    .filter(
                                                        (lang) =>
                                                            lang &&
                                                            lang !==
                                                                'Auto Detect' &&
                                                            lang !==
                                                                values?.transcribe_language
                                                    )
                                                    .map((opt) => (
                                                        <MenuItem
                                                            key={`caption_languages-${idx}-${opt}`}
                                                            value={opt}
                                                        >
                                                            {opt}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Field>
                            </Grid>
                            <Grid
                                display={'flex'}
                                alignItems="center"
                                justifyContent={'left'}
                                item
                                xs={12}
                                sm={12}
                                md={1}
                                lg={1}
                            >
                                <IconButton
                                    aria-label="close"
                                    onClick={() => {
                                        removeCaptionFile(
                                            values,
                                            idx,
                                            setFieldValue
                                        );
                                    }}
                                    sx={{ padding: '0' }}
                                    size="small"
                                >
                                    <MdClose className="react-icon" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                ))}
        </>
    );
};
