/**
 * This slice is to manage validation types
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { validationTypeService } from '_features/_services/validationType.service';

const initialState = {
    error: null,
    validationRawTypes: [],
    loadingStatus: LoadingStatus.Idle,
    savingStatus: SavingStatus.Idle,
};

export const getValidationRawTypes = createAsyncThunk(
    'validationRawTypes/getValidationRawTypes',
    async () => {
        // await new Promise((resolve) => setTimeout(resolve, 500)); // debug temp
        return (await validationTypeService.getRawTypes()) ?? [];
    }
);

const validationRawTypesSlice = createSlice({
    name: 'validationRawTypes',
    initialState,
    reducers: {},
    extraReducers: {
        [getValidationRawTypes.pending]: (state) => {
            state.loadingStatus = LoadingStatus.Loading;
        },
        [getValidationRawTypes.fulfilled]: (state, action) => {
            state.loadingStatus = LoadingStatus.Loaded;
            state.validationRawTypes = action.payload;
        },
        [getValidationRawTypes.rejected]: (state, action) => {
            state.loadingStatus = LoadingStatus.Failed;
            state.error = action.error.message;
        },
    },
});

export const selectValidationRawTypesLoadingStatus = (state) =>
    state.validationRawTypes.loadingStatus;
export const selectValidationRawTypes = (state) => {
    return state.validationRawTypes.validationRawTypes;
};

export default validationRawTypesSlice.reducer;
