import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { useStyles } from './styles';
import SearchWithFilters from 'components/SearchWithFilters';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { ResizableTable } from 'GeminiViewerComponent/components/_Tables/ResizableTable';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import {
    resizableLabelHeader,
    resizableLabelCell,
    resizableAssetExportDownloadLink,
    resizableCheckCell,
    resizableProgressStatusCell,
    resizableActionCell,
} from 'components';

function AssetExportTable({
    assetExports,
    pageInfo,
    assetExportPayload,
    setAssetExportPayload,
    sortDirection,
    sortBy,
    handleSort,
}) {
    const classes = useStyles();
    const tableClasses = tableStyles();
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = React.useState(false);

    const loadedRowsMap = useSelector(
        (state) => state.assetExport.loadedRowsMap
    );

    const headerHeight = 40;
    const rowHeight = 70;
    const isSelected = (id) => {
        return Boolean(
            assetExports?.find((exp) => exp.player_export_job_id === id)
                ?.is_self_contained
        );
    };

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== assetExports.length) {
            setSelected(assetExports.map((row) => row.tag_id));
            setSelectedAll(true);
        }
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const columns = [
        {
            accessorKey: 'player_export_job_id',
            size: 50,
            minSize: 50,
            enableResizing: false,
            padding: 'none',
            label: '',
            actionCellLevel: 'player_export_job',
            targetPopup: 'jobStatusAssetExportPopup',
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableActionCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'asset_name',
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'Asset Name',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'is_scorm',
            flexGrow: 1,
            size: 100,
            minSize: 100,
            enableResizing: false,
            label: 'Viewing',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'is_self_contained',
            flexGrow: 1,
            size: 120,
            minSize: 120,
            enableResizing: false,
            label: 'Self-Contained',
            padding: 'normal',
            cellLevel: 'player_export_job',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            handleClick: () => {},
            isSelected: isSelected,
            cell: (info) =>
                resizableCheckCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
        },
        {
            accessorKey: 'created_date',
            flexGrow: 1,
            size: 220,
            minSize: 220,
            label: 'Export Date',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'status_name',
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'Status',
            padding: 'normal',
            enableResizing: false,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableProgressStatusCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'file_name',
            flexGrow: 1,
            size: 220,
            minSize: 220,
            label: 'Completed File',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableAssetExportDownloadLink(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
    ];

    // eslint-disable-next-line no-unused-vars
    function loadMoreRows({ startIndex, stopIndex }) {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setAssetExportPayload({
                ...assetExportPayload,
                searchString: searchString,
            });
        }, 1000),
        [assetExportPayload]
    );

    return (
        <div className={classes.root}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="end"
                marginBottom="10px"
            >
                <SearchWithFilters
                    filter={false}
                    onChange={(evt) => debouncedSearch(evt.target.value)}
                />
            </Box>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    toolTipTitle={'Delete'}
                    totalCount={pageInfo.TotalCount}
                ></EnhancedTableToolbar>
            )}
            <div style={{ height: 'calc(100vh - 375px)', minWidth: '500px' }}>
                <ResizableTable
                    initialRows={assetExports}
                    allRows={assetExports}
                    totalRowCount={pageInfo.TotalCount}
                    loadedRowsMap={loadedRowsMap}
                    loadMoreRows={loadMoreRows}
                    columns={columns}
                    cellClassName={tableClasses.flexContainer}
                    className={tableClasses.table}
                    rowClassName={tableClasses.flexContainer}
                    headerHeight={headerHeight}
                    rowHeight={rowHeight}
                    selected={selected}
                />
            </div>
        </div>
    );
}

export { AssetExportTable };
