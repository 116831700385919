import { useEffect, useState } from 'react';
import { MdAdd, MdLeaderboard } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {
    Paper,
    IconButton,
    ClickAwayListener,
    Grow,
    Tooltip,
} from '@mui/material';

import useStyles from './style';
import { Action } from '_helpers';
import AppButton from 'components/AppButton';
import ImportAsset from 'components/ImportAsset';
import { openPanel } from '_features/common/formSlice';
import Loader from 'GeminiViewerComponent/components/Loader';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import getActionIcon from 'GeminiViewerComponent/_helpers/getActionIcon';
import {
    getAssetStatsRange,
    selectAssetTypes,
} from '_features/assets/assetManagerSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

const AssetTypeButton = ({ assetType, setOpenAssetStatsModal }) => {
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);
    const [toggle, setToggle] = useState(false);
    const dispatch = useDispatch();

    const entities = useSelector(selectAssetTypes);
    const [assetTypes, setAssetTypes] = useState([]);
    const [openImportDialog, setOpenImportDialog] = useState(false);

    useEffect(() => {
        let data = entities
            .filter((types) => types.asset_type_id !== 3)
            .map((types) => {
                if (types.asset_type_id === 1) {
                    return { ...types, display_name: '360 Orbit' };
                } else if (types.asset_type_id === 2) {
                    return { ...types, display_name: '360 Pano' };
                } else {
                    return types;
                }
            });
        data = [
            ...data,
            { asset_type_id: null, display_name: 'Standard Photos' },
            { asset_type_id: null, display_name: 'Import' },
        ];
        setAssetTypes(data);
    }, [entities]);

    const handleCreateAsset = (assetType) => {
        // if procedure use procedure form
        if (assetType?.asset_type_id === 3)
            dispatch(
                openPanel({
                    formKey: `procedureForm`,
                    formAction: 'Create',
                    assetTypeID: assetType?.asset_type_id,
                    clearEditId: true,
                })
            );
        else if (assetType?.display_name === 'Import')
            setOpenImportDialog(true);
        else
            dispatch(
                openPanel({
                    formKey: `assetForm`,
                    formAction: 'Create',
                    assetTypeID: assetType?.asset_type_id,
                    clearEditId: true,
                })
            );
    };

    const handleToggleChange = (e, assetType) => {
        e.preventDefault();
        setToggle(!toggle);
        handleCreateAsset(assetType);
    };

    return (
        <>
            <ClickAwayListener onClickAway={() => setToggle(false)}>
                <div className={classes.assetTypeContainer}>
                    {assetTypes?.length === 0 ? (
                        <Loader
                            styles={{
                                width: '44px',
                                height: '44px',
                                borderRadius: '25px',
                            }}
                        />
                    ) : (
                        <>
                            <Tooltip
                                title={
                                    assetType === 'procedures'
                                        ? 'New Procedure'
                                        : 'New Asset'
                                }
                            >
                                <IconButton
                                    className={classes.assetTypeIcon}
                                    onClick={() => {
                                        if (assetType === 'procedures') {
                                            handleCreateAsset({
                                                asset_type_id: 3,
                                                display_name: 'Procedure',
                                            });
                                        } else {
                                            setToggle(!toggle);
                                        }
                                    }}
                                    size="large"
                                >
                                    <MdAdd className="react-icon" />
                                </IconButton>
                            </Tooltip>

                            <Grow
                                in={toggle}
                                style={{ transformOrigin: '0 50% 0' }}
                                {...(toggle ? { timeout: 1000 } : {})}
                            >
                                <Paper
                                    className={classes.assetTypeButtonsWrap}
                                    id="asset-type-menu"
                                >
                                    {assetTypes.map((item) => (
                                        <AppButton
                                            variant="contained"
                                            className="primaryButton"
                                            key={
                                                item?.asset_type_id +
                                                item?.display_name
                                            }
                                            style={{
                                                width: '100%',
                                                padding: '0px',
                                            }}
                                            handleButtonClick={(e) =>
                                                handleToggleChange(e, item)
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.assetTypeIconWrap
                                                }
                                            >
                                                {getActionIcon(
                                                    Action[item?.display_name]
                                                        .iconId,
                                                    {
                                                        marginRight: '10px',
                                                        fill: '#374760',
                                                    }
                                                )}
                                                {item?.display_name}
                                            </div>
                                        </AppButton>
                                    ))}
                                </Paper>
                            </Grow>
                            {assetType === 'assets' && (
                                <Tooltip title="Asset Stats">
                                    <IconButton
                                        className={classes.assetTypeIcon}
                                        onClick={async () => {
                                            setOpenAssetStatsModal(true);
                                            await dispatch(activateLoading());
                                            await dispatch(
                                                getAssetStatsRange({
                                                    startIndex: 0,
                                                    stopIndex: 14,
                                                })
                                            );
                                            await dispatch(deactivateLoading());
                                        }}
                                        size="large"
                                    >
                                        <MdLeaderboard className="react-icon" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </>
                    )}
                </div>
            </ClickAwayListener>
            <ImportAsset
                openDialog={openImportDialog}
                setOpenDialog={setOpenImportDialog}
            />
        </>
    );
};

export default AssetTypeButton;
