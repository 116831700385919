import { Button, FormLabel, Radio, Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

const useStyles = makeStyles(() => ({
    root: {
        '& .assetHeader': {
            fontSize: '10px',
        },
        '& .MuiDataGrid-colCellTitle': {
            color: '#7E8C8D',
            fontWeight: 800,
            fontSize: '12px',
        },
        '& .MuiDataGrid-columnSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-cell': {
            color: '#34485E',
            fontSize: '14px',
        },
        '& .MuiCheckbox-root': {
            color: '#7E8C8D',
        },
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: '#3598DB',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1.25rem',
        },
    },
    actionBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },
    actionBarRight: {
        display: 'flex',
        alignItems: 'center',
    },
    actionButton: {
        margin: '15px',
        background: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        fontWeight: 600,
        '&:hover': {
            background: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(90%)',
        },
    },
    list: {
        width: 576,
    },
    fullList: {
        width: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: '25px 50px',
    },
    frameGrid: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        marginTop: 0,
    },
    frameImage: {
        width: '230px',
        // height: '140px',
        border: '1px solid currentColor',
        borderRadius: 10,
        marginBottom: 5,
    },
    tick: {
        position: 'absolute',
        marginLeft: 0,
        width: 1,
        backgroundColor: 'black',
    },
    smallTick: {
        height: 5,
    },
    bigTick: {
        height: 8,
    },
    playPause: {
        position: 'absolute',
        left: 0,
        bottom: 10,
        width: 30,
        height: 30,
        opacity: 1,
        '&.hide': {
            opacity: 0,
        },
    },
    generatingPreview: {
        visibility: ({ visible }) => (visible ? 'visible' : 'hidden'),
        fontSize: 14,
        marginLeft: 5,
        alignSelf: 'center',
    },
    preview: {
        border: '1px solid currentColor',
        borderRadius: 10,
    },
    previewHeader: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 5,
        gap: 10,
    },
    video: {
        width: '100%',
        height: '100%',
        maxHeight: '50vh',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        '&.hide': {
            opacity: 0,
        },
    },
    videoControls: {
        '&.hide': {
            opacity: 0,
        },
    },
    dropzoneAreaImport: {
        width: '100%',
        minWidth: '150px',
        height: '100%',
        minHeight: '80px !important',
        '& svg': {
            width: '1em',
            height: '1em',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        opacity: 1,
        '&.hide': {
            opacity: 0,
        },
        border: '1px solid black',
        borderRadius: 10,
    },
    dropzoneText: {
        fontSize: '1em !important',
        margin: '10px !important',
        backgroundColor: '#0009',
        color: 'white',
        cursor: 'default',
        borderRadius: '5px',
        padding: '5px',
    },
    importHeaderContainer: {
        display: 'flex',
        gap: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    smoothnessRadioGroup: {
        display: 'flex',
        width: 'fit-content',
        flexDirection: 'row',
        justifyContent: 'start',
        backgroundColor: '#eee',
        borderRadius: 10,
        border: '1px solid',
        color: '#3c97d5',
    },
    zonePreviews: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 10,
        borderStyle: 'solid',
        borderWidth: 1,
        overflow: 'hidden',
        minWidth: '120px',
        marginBottom: 5,
    },
    trimZonePreviews: {
        display: 'flex',
        flexDirection: 'row',
        borderColor: '#575757',
        borderRadius: 10,
        borderStyle: 'solid',
        borderWidth: 1,
        overflow: 'hidden',
        margin: '10px',
        flexWrap: 'wrap',
        minWidth: '200px',
        maxWidth: '320px',
    },
    zonePreviewContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    trimZoneWrap: {
        width: '50%',
    },
    zonePreviewHeader: {
        backgroundColor: '#3c97d5',
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        lineHeight: '14px',
        fontSize: '14px',
        padding: '3px 0px 3px 0px',
        width: '100%',
    },
    zonePreviewName: {
        color: 'white',
        textAlign: 'center',
        lineHeight: '14px',
        fontSize: '14px',
        padding: '3px 0px 3px 0px',
    },
    orbitContainer: {
        width: '100%',
        marginTop: 10,
        paddingBottom: 40,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    orbitHeader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '0px',
        left: '0px',
    },
    titleBar: {
        textAlign: 'center',
        width: '100%',
        height: '40px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        flexDirection: 'row',
    },
    orbitControls: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        columnGap: '5px',
        height: '30px',
        alignSelf: 'center',
        marginRight: '5px',
        marginLeft: 'auto',
    },
    orbitNavigation: {
        width: '100%',
        position: 'absolute',
        top: '50%',
        display: 'flex',
        flexDirection: 'row',
        height: '30px',
        alignSelf: 'center',
        justifyContent: 'space-between',
        pointerEvents: 'none',
    },
    maxOpacity: {
        opacity: '1 !important',
    },
    imgDimensions: {
        width: '190px',
        // height: '112px',
    },
    marginBottom: {
        marginBottom: '15px',
    },
    fileDescriptionDiv: {
        fontSize: '14px',
        color: '#3c97d5',
        fontWeight: 'bold',
        lineHeight: 'initial',
        alignSelf: 'center',
        flex: '1.5',
    },
}));

const StyledButton = withStyles({
    root: {
        wordWrap: 'nowrap',
        // height: 30,
    },
})(Button);

const SmoothnessRadio = withStyles({
    root: {
        color: '#3c97d5',
        '&$checked': {
            color: '#3c97d5',
        },
    },
    checked: {},
})(Radio);

const OrbitReverseCheckbox = withStyles({
    root: {
        color: '#3c97d5',
        '&$checked': {
            color: '#3c97d5',
        },
    },
    checked: {},
})(Checkbox);

const SmoothnessFormLabel = withStyles({
    root: {
        alignSelf: 'self-end',
        marginRight: 10,
        padding: '14px 0',
        color: '#3c97d5',
        '&$focused': {
            color: '#3c97d5',
        },
    },
    focused: {},
})(FormLabel);

export default useStyles;

export {
    SmoothnessRadio,
    SmoothnessFormLabel,
    StyledButton,
    OrbitReverseCheckbox,
};
