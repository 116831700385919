import { makeStyles } from '@mui/styles';

const overrideMarzipanoResetCSS = {
    '& div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video':
        {
            border: 'revert',
            fontSize: 'revert',
            verticalAlign: 'revert',
            margin: 'revert',
            padding: 'revert',
            fontStyle: 'revert',
        },
    '& article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section':
        {
            display: 'revert',
        },
    '& body': {
        lineHeight: 'revert',
    },
    '& ol,ul': {
        listStyle: 'revert',
    },
    '& blockquote,q': {
        quotes: 'revert',
        borderLeft: '2px solid #ccc',
        marginLeft: '1.5rem',
        paddingLeft: '1rem',
    },
    '& blockquote:before,blockquote:after,q:before,q:after': {
        content: 'revert',
    },
    '& table': {
        borderCollapse: 'revert',
        borderSpacing: 'revert',
    },
};

const useStyles = makeStyles(() => ({
    procedureWrapper: {
        fontFamily: 'sans-serif',
        display: 'flex',
        height: '100%',
        // overflow: 'auto',
        flexDirection: 'column',
        position: 'relative',
    },
    content: {
        margin: '0',
        flexGrow: '1',
        overflow: 'auto',
    },
    procedureContainer: {
        height: '100%',
        width: '100%',
        backgroundColor: '#f8f8f8',
        overflow: 'auto',
    },
    navLinkWrapper: {
        '& .navigationPanel': {
            color: (props) => props.colors.link_button_text_color,
            backgroundColor: (props) =>
                props.colors.link_button_background_color,
            '&:hover': {
                color: (props) => props.colors.link_button_text_color,
                backgroundColor: (props) =>
                    props.colors.link_button_background_color,
            },
        },
        '& .activeNavigationLink': {
            color: (props) => props.colors.secondarySectionForeground,
            backgroundColor: (props) => props.colors.secondarySectionBackground,
            boxShadow: (props) =>
                `10px 0 0 inset ${props.colors.primarySectionBackground}`,
            '&:hover': {
                color: (props) => props.colors.secondarySectionForeground,
                backgroundColor: (props) =>
                    props.colors.secondarySectionBackground,
            },
        },
        '& .navigationLinkButton': {
            border: '1px solid black',
            marginTop: '-1px',
            textAlign: 'center',
            padding: '10px',
            width: '100%',
            borderRadius: '0',
            fontSize: '18px',
            display: 'inline-block',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
    },
    categoryButton: {
        color: (props) => props.colors.link_button_text_color,
        backgroundColor: (props) => props.colors.link_button_background_color,
        '&:hover': {
            color: (props) => props.colors.link_button_text_color,
            backgroundColor: (props) =>
                props.colors.link_button_background_color,
        },
        border: '1px solid black',
        marginTop: '-1px',
        width: '100%',
        fontWeight: 'bold',
        borderRadius: '0',
        fontSize: '25px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
}));

const overrideResetStyles = makeStyles(() => ({
    overrideMarzipanoResetCSS,
}));

const customTinyMce = makeStyles(() => ({
    tinymceCustom: {
        fontFamily: 'unset',
        marginLeft: '10px',
        ...overrideMarzipanoResetCSS,
        '& h1': {
            fontSize: '2em',
            fontWeight: 'bold',
        },
        '& h2': {
            fontSize: '1.5em',
            fontWeight: 'bold',
        },
        '& h3': {
            fontSize: '1.17em',
            fontWeight: 'bold',
        },
        '& h4': {
            fontWeight: 'bold',
        },
        '& h5': {
            fontSize: '0.83em',
            fontWeight: 'bold',
        },
        '& h6': {
            fontSize: '0.67em',
            fontWeight: 'bold',
        },
        '& p': {
            display: 'block',
            marginBlockStart: '1em',
            marginBlockEnd: '1em',
            marginInlineStart: '0px',
            marginInlineEnd: '0px',
            margin: 'revert',
        },
        '& a': {
            textDecoration: 'none',
        },
        '& a:visited': {
            textDecoration: 'none',
        },
        '& strong': {
            fontWeight: 'bold',
        },
    },
}));
const makeCommonChecklistStyles = makeStyles(() => ({
    button: {
        backgroundColor: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
    },
    '@keyframes rotate': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' },
    },
    loadingSvg: {
        width: '100%',
        height: '50px',
    },
    rotateSvg: {
        animationName: '$rotate',
        animationDuration: '1.5s',
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: 'infinite',
    },
}));

const dialogStyles = makeStyles(() => ({
    submit: {
        backgroundColor: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        border: '0px',
        padding: '10px',
        borderRadius: '5px',
        fontSize: '18px',
        textTransform: 'uppercase',
        marginTop: '10px',
        cursor: 'pointer',
        '&:disabled': {
            backgroundColor: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(50%)',
        },
        '&:hover': {
            backgroundColor: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(90%)',
        },
    },
    cancel: {
        backgroundColor: (props) => props.colors.button.secondaryBackground,
        color: (props) => props.colors.button.secondaryForeground,
        padding: '10px',
        borderRadius: '5px',
        fontSize: '18px',
        textTransform: 'uppercase',
        marginTop: '10px',
        cursor: 'pointer',
        border: '0px',
        '&:hover': {
            backgroundColor: (props) => props.colors.button.secondaryBackground,
            color: (props) => props.colors.button.secondaryForeground,
            filter: 'brightness(90%)',
        },
    },
}));

export {
    useStyles,
    dialogStyles,
    makeCommonChecklistStyles,
    customTinyMce,
    overrideResetStyles,
};
