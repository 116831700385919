import React, { useEffect } from 'react';
import { Box, FormControl } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Field, useFormikContext } from 'formik';

import { SelectionAutocomplete } from 'components/_Autocomplete/SelectionAutocomplete';
import {
    getAssetsPicklist,
    getItemsPicklist,
    getZonesPicklist,
    resetItemsPicklistStatus,
    resetZonesPicklistStatus,
    selectAssetsPicklist,
    selectAssetsPicklistLoadingStatus,
    selectItemsPicklist,
    selectItemsPicklistLoadingStatus,
    selectZonesPicklist,
    selectZonesPicklistLoadingStatus,
} from '_features/picklists/pickListsSlice';
import { FormTextField } from 'components/TextField';
import { CheckboxWithLabel } from 'formik-mui';

const AssociatedView = ({
    asset_id,
    zone_id,
    item_id,
    close_associated_view,
}) => {
    const dispatch = useDispatch();
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        dispatch(resetZonesPicklistStatus());
        dispatch(resetItemsPicklistStatus());
    }, [asset_id]);

    useEffect(() => {
        dispatch(resetItemsPicklistStatus());
    }, [zone_id]);

    return (
        <Box component="fieldset" border="1px solid #cccccc" borderRadius="5px">
            <Box
                component="legend"
                marginLeft="10px"
                padding="3px"
                fontSize="14px"
                color="#6d7178"
            >
                Associated View
            </Box>
            <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                gap="5px"
                width="100%"
            >
                {!asset_id && !zone_id && !item_id && (
                    <FormControl sx={{ padding: '0 8px' }} fullWidth>
                        <FormTextField
                            component={CheckboxWithLabel}
                            Label={{
                                label: 'Close associated view',
                            }}
                            label="Close associated view"
                            type="checkbox"
                            checked={close_associated_view}
                            onChange={(evt) =>
                                setFieldValue(
                                    'close_associated_view',
                                    evt.target.checked
                                )
                            }
                        />
                    </FormControl>
                )}
                <FormControl sx={{ padding: '0 8px' }} fullWidth>
                    <Field name="asset_id">
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <SelectionAutocomplete
                                title="Asset"
                                keyProperty="asset_id"
                                nameProperty="display_name"
                                entityIds={value}
                                setEntityIds={setFieldValue}
                                onCustomChange={() => {
                                    dispatch(resetZonesPicklistStatus());
                                    setFieldValue(
                                        'close_associated_view',
                                        false
                                    );
                                    setFieldValue('zone_id', null);
                                    setFieldValue('item_id', null);
                                }}
                                entitySelector={(state) =>
                                    selectAssetsPicklist(state).filter(
                                        (asset) => asset.asset_type_id !== 3
                                    )
                                }
                                fetchEntityPage={getAssetsPicklist}
                                formField={'asset_id'}
                                multiSelection={false}
                                entityStatusSelector={
                                    selectAssetsPicklistLoadingStatus
                                }
                            />
                        )}
                    </Field>
                </FormControl>
                {asset_id && (
                    <FormControl sx={{ padding: '0 8px' }} fullWidth>
                        <Field name="zone_id">
                            {({
                                field: { value },
                                form: { setFieldValue },
                            }) => (
                                <SelectionAutocomplete
                                    title="Zone"
                                    keyProperty="zone_id"
                                    nameProperty="display_name"
                                    entityIds={value}
                                    setEntityIds={setFieldValue}
                                    onCustomChange={() => {
                                        dispatch(resetItemsPicklistStatus());
                                        setFieldValue(
                                            'close_associated_view',
                                            false
                                        );
                                        setFieldValue('item_id', null);
                                    }}
                                    entitySelector={selectZonesPicklist}
                                    fetchEntityPage={getZonesPicklist}
                                    formField={'zone_id'}
                                    multiSelection={false}
                                    entityStatusSelector={
                                        selectZonesPicklistLoadingStatus
                                    }
                                    fetchParams={{
                                        assetId: asset_id,
                                        includeChildren: false,
                                    }}
                                />
                            )}
                        </Field>
                    </FormControl>
                )}
                {asset_id && zone_id && (
                    <FormControl sx={{ padding: '0 8px' }} fullWidth>
                        <Field name="item_id">
                            {({ form: { setFieldValue } }) => (
                                <SelectionAutocomplete
                                    title="Item"
                                    keyProperty="item_id"
                                    nameProperty="display_name"
                                    entityIds={item_id}
                                    setEntityIds={setFieldValue}
                                    entitySelector={selectItemsPicklist}
                                    fetchEntityPage={getItemsPicklist}
                                    formField={'item_id'}
                                    multiSelection={false}
                                    entityStatusSelector={
                                        selectItemsPicklistLoadingStatus
                                    }
                                    fetchParams={{
                                        zoneId: zone_id,
                                    }}
                                />
                            )}
                        </Field>
                    </FormControl>
                )}
            </Box>
        </Box>
    );
};

export { AssociatedView };
