import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { createRoot } from 'react-dom/client';
import { Provider, useDispatch, useSelector, useStore } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

import { LinkTypes } from '_helpers';
import { accountsSlice } from 'app/store';
const { selectActiveUser } = accountsSlice;
import { PromptDialog } from 'components/PromptDialog';
import { searchItems } from '_features/items/itemsSlice';
import { ImageResizer } from 'forms/ZoneForm/ImageResizer';
import { SceneControls } from './components/SceneControls';
import { HotspotIconTypes } from 'GeminiViewerComponent/_helpers';
import { editItem, loadItem } from '_features/common/editItemSlice';
import { loadContentPanelItem } from 'shared/loadContentPanelSlice';
import { closePanel, selectFormInfo } from '_features/common/formSlice';
import { useZoneMediaConfigValue } from 'hooks/useZoneMediaConfigValue';
import { UnPositionedItemsBar } from './components/UnPositionedItemsBar';
import { PanoLinkHotspot } from 'components/PanoControls/PanoLinkHotspot';
import { PanoItemHotspot } from 'components/PanoControls/PanoItemHotspot';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { GeminiContainer } from 'GeminiViewerComponent/components/GeminiContainer';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { setSearchResults } from 'GeminiViewerComponent/_features/globals/searchSlice';
import { showActionModal } from 'GeminiViewerComponent/_features/common/actionModalSlice';
import { getViewerLoaded } from 'GeminiViewerComponent/_features/globals/viewerStatusSlice';
import {
    clearContentPanelItem,
    setViewContentPanel,
} from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    setActiveAssetId,
    setActiveAssetProcedureId,
} from 'GeminiViewerComponent/components/Procedure/_features/checklistDataSlice';
import {
    addNewItem,
    resetAssetItem,
    selectAllAssetItem,
} from '_features/items/itemsTableSlice';
import {
    addNewZoneNavLink,
    editZone,
    editZoneNavLink,
} from '_features/common/editZoneSlice';
import {
    displayProcedureContentVersion,
    getTagFilters,
    setLinkDisplayVis,
    setPanelVis,
} from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import {
    loadZone,
    selectZoneLoaded,
    selectZoneNavItemsStack,
    selectZoneLoadingMessage,
    selectZoneLoadingStatus,
} from 'shared/loadZoneSlice';
import {
    selectAllHotspots,
    getNavigationHotspots,
    getItemHotspots,
    getIsMultipleHotspotEnabled,
    setEnableMultipleHotspot,
    getItemDragging,
    getNavDragging,
    setItemDragging,
    setNavDragging,
} from 'shared/hotspotsSlice';
import {
    selectActiveAsset,
    getActiveAssetActiveZone,
    selectCompactZones,
    selectHasUnpositionedItems,
    setSelectedSearchItemId,
    getSelectedSearchItemId,
    updateActiveZoneItem,
    updateActiveZoneNavLink,
    getChildZones,
} from 'GeminiViewerComponent/_features/asset/assetSlice';
import { selectZoneCategory } from '_features/zones/zonesTableSlice';
import { useConfigFields } from 'hooks/useConfigFields';
import { useConfigValues } from 'hooks/useConfigValues';
import { selectConfigValuesByFields } from '_features/configValues/configValuesSlice';

const removeItemData = (itemData) => {
    let updatedItemData = {
        ...itemData,
        clear_position_if_missing: true,
    };
    if (itemData.flat_x) delete updatedItemData.flat_x;
    if (itemData.flat_y) delete updatedItemData.flat_y;
    if (itemData.label_x) delete updatedItemData.label_x;
    if (itemData.label_y) delete updatedItemData.label_y;
    if (itemData.yaw) delete updatedItemData.yaw;
    if (itemData.pitch) delete updatedItemData.pitch;
    return updatedItemData;
};

const GeminiEditor = ({ onClose }) => {
    //#region Constants
    const ITEM_HOTSPOT_PREFIX = 'New Item ';
    const DEFAULT_ITEM_HOTSPOT_NAME = 'New Item 1';
    //#endregion Constants

    //#region Hooks
    const activeUser = useSelector(selectActiveUser);
    const zoneCategory = useSelector(selectZoneCategory);

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    //#endregion Hooks

    //#region State

    const [draggableFlag, setDraggableFlag] = useState(true);
    const itemDragging = useSelector(getItemDragging);
    const navDragging = useSelector(getNavDragging);
    const [hotspotElements, setHotspotElements] = useState([]);
    const [itemName, setItemName] = useState();
    const [emptyItemOpacity, setEmptyItemOpacity] = useState(60);
    const [manageDialog, setManageDialog] = useState({
        isDialogOpen: false,
        zoneId: '',
        location: '',
        initZoneDialog: false,
    });
    const [selectedHotspotIcons, setSelectedHotspotIcons] = useState({
        navLinkIconId: null,
        itemIconId: null,
    });
    const zoneLoaded = useSelector(selectZoneLoaded);
    const [undoRedoStack, setUndoRedoStack] = useState({ undo: 0, redo: 0 });

    //#endregion State

    //#region Selectors

    const selectedSearchItemId = useSelector(getSelectedSearchItemId);

    const compactZones = useSelector(selectCompactZones);
    const childZones = useSelector(getChildZones);
    const allZoneData = useMemo(() => {
        if (childZones && childZones?.length > 0) {
            return [...compactZones, ...childZones];
        } else {
            return compactZones;
        }
    }, [compactZones, childZones]);
    const isMultipleHotspotEnabled = useSelector(getIsMultipleHotspotEnabled);

    const asset = useSelector(selectActiveAsset);

    const { configFields } = useConfigFields();

    const { configValues } = useConfigValues({
        clientId: asset.client_id,
        assetId: asset.asset_id,
    });

    const navigationHotspots = useSelector(getNavigationHotspots);
    const itemHotspots = useSelector(getItemHotspots);
    const viewerLoaded = useSelector(getViewerLoaded);
    const hotspotIcons = useSelector(selectAllHotspots);
    const isFormOpen = useSelector(selectFormInfo);

    const activeZone = useSelector(getActiveAssetActiveZone);
    const hasUnpositionedItems = useSelector(selectHasUnpositionedItems);
    const activeTagFilter = useSelector(getTagFilters);

    const zoneLoadingStatus = useSelector(selectZoneLoadingStatus);
    const zoneLoadingMessage = useSelector(selectZoneLoadingMessage);
    const navItemsStack = useSelector(selectZoneNavItemsStack);
    const allAssetItem = useSelector(selectAllAssetItem);

    //#endregion Selectors

    //#region Refs

    const activeZoneRef = useRef();
    activeZoneRef.current = activeZone;

    const selectedHotspotIconsRef = useRef();

    const itemChipsRef = useRef(null);
    const viewerRef = useRef(null);

    //#endregion Refs

    //#region Methods

    const handleUndoRedoActions = useCallback(
        async (
            updateItem = false,
            updateNavLink = false,
            baseObject,
            action
        ) => {
            let response;
            if (updateItem && baseObject) {
                await dispatch(activateLoading());
                await dispatch(
                    updateActiveZoneItem({
                        zone_id: activeZone.zone_id,
                        ...baseObject,
                        flat_x: baseObject.flat_x ?? null,
                        flat_y: baseObject.flat_y ?? null,
                        label_x: baseObject.label_x ?? null,
                        label_y: baseObject.label_y ?? null,
                        yaw: baseObject.yaw ?? null,
                        pitch: baseObject.pitch ?? null,
                    })
                );

                response = await dispatch(
                    editItem({
                        ...baseObject,
                        clear_position_if_missing: true,
                        action,
                    })
                );
                await dispatch(deactivateLoading());
            }

            if (updateNavLink && baseObject) {
                if (!baseObject.label_x && !baseObject.label_y) {
                    await dispatch(activateLoading());
                    response = await dispatch(
                        editZoneNavLink({ ...baseObject, action })
                    );
                    await dispatch(
                        updateActiveZoneNavLink({
                            zone_id: activeZone.zone_id,
                            ...baseObject,
                            label_x: 100,
                            label_y: 35,
                        })
                    );
                    await dispatch(deactivateLoading());
                } else {
                    await dispatch(activateLoading());
                    response = dispatch(
                        editZoneNavLink({ ...baseObject, action })
                    );
                    await dispatch(deactivateLoading());
                }
            }
            if (response?.error) {
                enqueueSnackbar(
                    'Item could not be edited. Check your connection',
                    {
                        action: (key) => <SnackbarDismiss key={key} />,
                        variant: 'warning',
                    }
                );
                // Reload zone if there was any error editing the hotspot
                await dispatch(
                    loadZone({ zoneId: activeZone.zone_id, refresh: true })
                );
            }
            dispatch(deactivateLoading());
        },
        [activeZone.zone_id, dispatch, enqueueSnackbar]
    );

    const undoItemPosition = useCallback(() => {
        dispatch(activateLoading({ showProgress: true }));
        if (zoneLoaded && navItemsStack[zoneLoaded]) {
            const stackData = navItemsStack[zoneLoaded];
            let currentPointer = stackData.currentPointer;
            let baseObject = null;
            let updateItem = false;
            let updateNavLink = false;
            if (currentPointer === 0) {
                const currentObject = stackData?.stack[0];
                if (currentObject.type === 'items') {
                    baseObject = stackData.items.find(
                        (item) => item.item_id === currentObject.item_id
                    );
                    if (Object.keys(baseObject || {}).length > 0) {
                        if (!baseObject?.label_x || !baseObject?.label_y) {
                            baseObject = {
                                ...baseObject,
                                label_x: 75,
                                label_y: 20,
                            };
                        }
                    } else {
                        let allItemBaseObject = allAssetItem.find(
                            (item) => item.item_id === currentObject.item_id
                        );

                        if (Object.keys(allItemBaseObject || {}).length > 0) {
                            let updatedBaseHotspot =
                                removeItemData(allItemBaseObject);
                            baseObject = {
                                ...updatedBaseHotspot,
                                clear_position_if_missing: true,
                            };
                        }
                    }
                    if (baseObject) {
                        updateItem = true;
                    }
                } else if (currentObject.type === 'nav_links') {
                    baseObject = stackData.nav_links.find(
                        (navLink) =>
                            navLink.nav_link_id === currentObject.nav_link_id
                    );
                    if (baseObject) {
                        updateNavLink = true;
                    }
                }
            } else if (currentPointer > 0) {
                baseObject = stackData?.stack[currentPointer];
                if (baseObject) {
                    if (baseObject.type === 'items') {
                        if (stackData?.stack?.length > 1) {
                            let checkItemStackAvailability =
                                stackData?.stack?.filter(
                                    (item, index) =>
                                        index < currentPointer &&
                                        item.item_id === baseObject.item_id
                                );
                            if (
                                Array.isArray(checkItemStackAvailability) &&
                                checkItemStackAvailability.length > 0
                            ) {
                                checkItemStackAvailability =
                                    checkItemStackAvailability[
                                        checkItemStackAvailability.length - 1
                                    ];
                            } else {
                                checkItemStackAvailability = false;
                            }
                            if (checkItemStackAvailability) {
                                updateItem = true;
                                baseObject = checkItemStackAvailability;
                            }
                        }
                        if (!updateItem) {
                            let itemDataBaseObject = stackData.items.find(
                                (item) => item.item_id === baseObject.item_id
                            );
                            if (
                                Object.keys(itemDataBaseObject || {}).length > 0
                            ) {
                                if (
                                    !itemDataBaseObject?.label_x ||
                                    !itemDataBaseObject?.label_y
                                ) {
                                    baseObject = {
                                        ...itemDataBaseObject,
                                        label_x: 75,
                                        label_y: 20,
                                    };
                                }
                            } else {
                                let allItemBaseObject = allAssetItem.find(
                                    (item) =>
                                        item.item_id === baseObject.item_id
                                );
                                if (
                                    Object.keys(allItemBaseObject || {})
                                        .length > 0
                                ) {
                                    let updatedBaseHotspot =
                                        removeItemData(allItemBaseObject);
                                    baseObject = {
                                        ...updatedBaseHotspot,
                                        clear_position_if_missing: true,
                                    };
                                }
                            }
                            if (baseObject) {
                                updateItem = true;
                            }
                        }
                    } else if (baseObject.type === 'nav_links') {
                        if (stackData?.stack?.length > 1) {
                            let checkNavLinkStackAvailability =
                                stackData?.stack?.filter(
                                    (nav_links, index) =>
                                        index < currentPointer &&
                                        nav_links.nav_link_id ===
                                            baseObject.nav_link_id
                                );
                            if (
                                Array.isArray(checkNavLinkStackAvailability) &&
                                checkNavLinkStackAvailability.length > 0
                            ) {
                                checkNavLinkStackAvailability =
                                    checkNavLinkStackAvailability[
                                        checkNavLinkStackAvailability.length - 1
                                    ];
                            } else {
                                checkNavLinkStackAvailability = false;
                            }
                            if (checkNavLinkStackAvailability) {
                                updateNavLink = true;
                                baseObject = checkNavLinkStackAvailability;
                            }
                        }
                        if (!updateNavLink) {
                            baseObject = stackData.nav_links.find(
                                (nav_links) =>
                                    nav_links.nav_link_id ===
                                    baseObject.nav_link_id
                            );
                            if (baseObject) {
                                updateNavLink = true;
                            }
                        }
                    }
                }
            }
            handleUndoRedoActions(
                updateItem,
                updateNavLink,
                baseObject,
                'undo'
            );
            dispatch(resetAssetItem());
        }
    }, [
        dispatch,
        zoneLoaded,
        navItemsStack,
        handleUndoRedoActions,
        allAssetItem,
    ]);

    const redoItemPosition = () => {
        dispatch(activateLoading({ showProgress: true }));
        if (zoneLoaded && navItemsStack[zoneLoaded]) {
            const stackData = navItemsStack[zoneLoaded];
            let currentPointer = stackData.currentPointer;
            let baseObject = null;
            let updateItem = false;
            let updateNavLink = false;
            if (
                Array.isArray(navItemsStack[zoneLoaded].stack) &&
                navItemsStack[zoneLoaded].stack.length > 0 &&
                navItemsStack[zoneLoaded].stack[currentPointer + 1]
            ) {
                baseObject =
                    navItemsStack[zoneLoaded].stack[currentPointer + 1];
                if (baseObject.type === 'items') {
                    updateItem = true;
                } else if (baseObject.type === 'nav_links') {
                    updateNavLink = true;
                }
            }
            handleUndoRedoActions(
                updateItem,
                updateNavLink,
                baseObject,
                'redo'
            );
            dispatch(resetAssetItem());
        }
    };

    function warningSnackbar() {
        enqueueSnackbar(
            'The new item you added may not be visible because you have active filters.',
            {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'warning',
            }
        );
    }
    function handleItemDragging() {
        dispatch(setItemDragging());
    }
    function handleNavDragging() {
        dispatch(setNavDragging());
    }
    // Utility function used for debugging
    // eslint-disable-next-line no-unused-vars
    const hotspotSummary = (hotspot) => {
        const summary = (({ item_id, yaw, pitch }) => ({
            item_id,
            yaw,
            pitch,
        }))(hotspot);
        return JSON.stringify(summary);
    };

    async function onUpdatedHotspot(
        hotspot,
        // eslint-disable-next-line no-unused-vars
        dragElement = null,
        movedFromTray = false
    ) {
        await dispatch(activateLoading());
        delete hotspot.action;
        dispatch(setEnableMultipleHotspot(false));
        var original_zone_id = hotspot.zone_id;
        hotspot.zone_id = activeZone.zone_id;
        const response = await dispatch(
            editItem({
                original_zone_id: original_zone_id,
                ...hotspot,
            })
        );
        if (response.error) {
            enqueueSnackbar('Item could not be edited. Check your connection', {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'warning',
            });
            // Reload zone if there was any error editing the hotspot
            await dispatch(
                loadZone({ zoneId: activeZone.zone_id, refresh: true })
            );
        }
        if (hotspot?.clear_position_if_missing === true) {
            hotspot.flat_x = null;
            hotspot.flat_y = null;
            hotspot.label_x = null;
            hotspot.label_y = null;
            hotspot.yaw = null;
            hotspot.pitch = null;
            delete hotspot.clear_position_if_missing;
            await dispatch(
                updateActiveZoneItem({
                    zone_id: activeZone.zone_id,
                    ...hotspot,
                })
            );
        }
        if (
            movedFromTray &&
            Array.isArray(activeTagFilter) &&
            activeTagFilter.length > 0
        ) {
            warningSnackbar();
        }
        if (response) {
            await dispatch(resetAssetItem());
        }
        await dispatch(deactivateLoading());
    }

    async function onDeletedHotspot(hotspot) {
        await dispatch(
            showActionModal({
                isOpen: true,
                object: { zone_id: activeZone.zone_id, ...hotspot },
                action: { label: 'delete' },
                level: 'item',
            })
        );
    }

    function onUpdatedLinkHotspot(hotspot) {
        dispatch(editZoneNavLink(hotspot));
    }

    async function onDeletedLinkHotspot(hotspot) {
        const zone = allZoneData.find(
            (zone) => zone.zone_id === hotspot.target_zone_id
        );

        const hotspotWithTargetZone = {
            ...hotspot,
            target_zone: zone.display_name,
        };

        await dispatch(
            showActionModal({
                isOpen: true,
                object: hotspotWithTargetZone,
                action: { label: 'delete navigation to' },
                level: 'navLink',
            })
        );
    }

    function handleSceneInfoClick() {
        if (window.geminiViewManager) {
            window.geminiViewManager.getCurrentViewParams();
        }
        addNewItemDialog(activeZone.zone_id, {
            yaw: null,
            pitch: null,
            flat_x: null,
            flat_y: null,
        });
    }

    function handleSceneNavClick() {
        let viewParams = window.geminiViewManager.getCurrentViewParams();
        addNewNavLink(activeZone.zone_id, activeZone.zone_id, viewParams);
    }
    function handleSceneInitViewClickConfirm() {
        setManageDialog({ ...manageDialog, initZoneDialog: true });
    }

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    async function handleSceneInitViewClick(isConfirm) {
        if (window.geminiViewManager) {
            dispatch(activateLoading());
            let initViewThumbnail = null;
            if (isConfirm === true) {
                initViewThumbnail =
                    await window.geminiViewManager.getCurrentViewImage();
                const file = dataURLtoFile(initViewThumbnail, 'testfile');
                let imageResizer = await new ImageResizer(file);
                let previewFile = await imageResizer.run(200, 200);
                previewFile.name = `${activeZone?.display_name}_preview`;
                initViewThumbnail = previewFile;
            }
            const viewParams = window.geminiViewManager.getCurrentViewParams();

            let previewData = {};
            if (activeZone.equirect_image_preview_url) {
                previewData = {
                    equirect_image_preview:
                        initViewThumbnail !== null
                            ? initViewThumbnail
                            : activeZone.equirect_image_preview,
                };
            }

            if (activeZone.flat_image_preview_url) {
                previewData = {
                    flat_image_preview:
                        initViewThumbnail !== null
                            ? initViewThumbnail
                            : activeZone.flat_image_preview,
                };
            }

            const updatedZone = {
                ...activeZone,
                audiences:
                    typeof activeZone?.audiences === 'undefined'
                        ? []
                        : activeZone?.audiences?.map(
                              (audience) => audience?.audience_id
                          ),
                tag_ids:
                    typeof activeZone?.tags === 'undefined'
                        ? []
                        : activeZone?.tags?.map((tag) => tag?.tag_id),

                ...previewData,
                ...{
                    init_view_yaw: viewParams.yaw,
                    init_view_pitch: viewParams.pitch,
                    init_view_roll: viewParams.roll,
                    init_view_fov: viewParams.fov,
                    init_view_flat_x: viewParams.x,
                    init_view_flat_y: viewParams.y,
                    init_view_zoom: viewParams.zoom,
                },
            };

            await dispatch(editZone({ ...updatedZone, dispatch }));
            window.geminiViewManager.setSceneInitView();
            handleDialogClose();
            dispatch(deactivateLoading());
        }
    }

    function onShiftRightClick(rightClickInfo) {
        addNewNavLink(
            rightClickInfo.zone_id,
            rightClickInfo.target,
            rightClickInfo.location
        );
    }

    function onRightClick(rightClickInfo) {
        if (showAddInfoHotspot) {
            addNewItemDialog(rightClickInfo.zone_id, rightClickInfo.location);
        }
    }

    const onClick = (clickInfo) => {
        if (showAddInfoHotspot && clickInfo?.isMultipleHotspotEnabled) {
            const itemHotspot = clickInfo?.itemHotspots;
            let itemHotspotDisplayName = DEFAULT_ITEM_HOTSPOT_NAME;
            if (itemHotspot?.length > 0) {
                let lastInsertedItem = 0;
                itemHotspot.forEach((item) => {
                    const convertToLowerCase = item.display_name
                        .toLowerCase()
                        .trim();
                    const startWithPrefix = convertToLowerCase.startsWith(
                        ITEM_HOTSPOT_PREFIX.toLowerCase()
                    );
                    if (startWithPrefix) {
                        const removePrefix = convertToLowerCase.replace(
                            ITEM_HOTSPOT_PREFIX.toLowerCase(),
                            ''
                        );
                        if (
                            removePrefix.indexOf('e') === -1 &&
                            !isNaN(removePrefix) &&
                            Number(removePrefix) > 0
                        ) {
                            if (Number(removePrefix) > lastInsertedItem) {
                                lastInsertedItem = parseInt(
                                    Number(removePrefix)
                                );
                            }
                        }
                    }
                });
                itemHotspotDisplayName = `${ITEM_HOTSPOT_PREFIX}${
                    lastInsertedItem + 1
                }`;
            }
            addNewItemHotspot(
                clickInfo,
                itemHotspotDisplayName,
                clickInfo.zone_id,
                false
            );
        }
    };

    const addNewItemHotspot = async (
        zoneInfo,
        displayName,
        zoneId,
        showStatus = true
    ) => {
        let hotspot = {
            zone_id: zoneId,
            display_name: displayName,
            yaw: zoneInfo?.location.yaw,
            pitch: zoneInfo?.location.pitch,
            flat_x: zoneInfo?.location.x,
            flat_y: zoneInfo?.location.y,
            rotation: 0.0,
            scale: 1.0,
            label_x: 75,
            label_y: 20,
            hotspot_icon_id: selectedHotspotIconsRef.current.itemIconId,
        };
        dispatch(activateLoading());
        let resultAction = await dispatch(addNewItem(hotspot));
        dispatch(deactivateLoading());
        if (showStatus) {
            const { message, variant } = snackbarHandler(
                resultAction.meta.requestStatus,
                'Create Item'
            );
            enqueueSnackbar(message, {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: variant,
            });
        }
        if (
            Array.isArray(activeTagFilter) &&
            activeTagFilter.length > 0 &&
            hotspot?.flat_x !== undefined &&
            hotspot?.flat_y !== undefined
        ) {
            warningSnackbar();
        }
    };

    const handleMultipleHotspot = () => {
        dispatch(setEnableMultipleHotspot(!isMultipleHotspotEnabled));
    };

    const onNavClicked = async (zoneId) => {
        if (zoneId === activeZoneRef.current.zone_id) {
            return;
        }

        await dispatch(clearContentPanelItem());

        isFormOpen?.zoneForm?.isOpen &&
            dispatch(
                closePanel({ formKey: 'zoneForm', isContentPanelOpen: true })
            );
        isFormOpen?.itemForm?.isOpen &&
            dispatch(
                closePanel({ formKey: 'itemForm', isContentPanelOpen: true })
            );

        await dispatch(loadZone({ zoneId }));
    };

    async function onItemNavClicked(itemId) {
        const itemResult = await dispatch(
            loadContentPanelItem({ zoneId: activeZone.zone_id, itemId: itemId })
        );

        if (!itemResult.error) {
            const item = itemResult.payload;
            // Check to see if there is only one content item
            if (
                item?.links.length === 1 &&
                (item?.auto_view_single_link === true ||
                    asset?.auto_view_single_link === true)
            ) {
                if (item.links[0].link_type_id === LinkTypes.Procedure.id) {
                    dispatch(
                        setActiveAssetProcedureId(
                            item.links[0]?.content_version?.procedure_id
                        )
                    );
                    dispatch(
                        displayProcedureContentVersion(
                            item.links[0].content_version
                        )
                    );
                } else {
                    const link = item.links[0];
                    dispatch(
                        setLinkDisplayVis({
                            open: true,
                            linkContent:
                                link?.content_version?.encoding_status &&
                                link?.content_version?.encoding_status.toLowerCase() ===
                                    'complete'
                                    ? link?.content_version?.encoded_url
                                    : link.content_version.url
                                    ? link.content_version.url
                                    : link.content_version.embed_data,
                            linkData: {
                                ...link,
                                ...link?.content,
                                ...(typeof link?.content_version === 'object'
                                    ? link.content_version
                                    : {}),
                            },
                            linkTypeId: link.link_type_id,
                            autoPlay: Boolean(link?.auto_play),
                            overlayText: link.content_version?.overlay_text,
                        })
                    );
                }
            } else {
                dispatch(setPanelVis({ open: true }));
            }
        }
    }

    async function onEditClicked(hotspot) {
        isFormOpen?.zoneForm?.isOpen &&
            dispatch(
                closePanel({ formKey: 'zoneForm', isContentPanelOpen: true })
            );
        isFormOpen?.itemForm?.isOpen &&
            dispatch(
                closePanel({ formKey: 'itemForm', isContentPanelOpen: true })
            );

        await dispatch(setViewContentPanel(true));

        await dispatch(
            loadItem({ zoneId: activeZone.zone_id, itemId: hotspot.item_id })
        );
    }

    function addNewNavLink(zoneId, targetZoneId, location) {
        let navLink = {
            zone_id: zoneId,
            target_zone_id: targetZoneId,
            yaw: location.yaw,
            pitch: location.pitch,
            flat_x: location.x,
            flat_y: location.y,
            rotation: 0.0,
            scale: 1.0,
            hotspot_icon_id: selectedHotspotIconsRef.current.navLinkIconId,
        };
        if (Array.isArray(activeTagFilter) && activeTagFilter.length > 0) {
            warningSnackbar();
        }
        dispatch(addNewZoneNavLink(navLink));
    }

    function addNewItemDialog(zoneId, location) {
        setManageDialog({ isDialogOpen: true, zoneId, location });
    }

    const handleDialogClose = () => {
        setManageDialog({
            ...manageDialog,
            isDialogOpen: false,
            initZoneDialog: false,
        });
        setItemName('');
    };

    const handleDialogSubmit = async (value) => {
        if (!value) {
            return;
        }
        setItemName('');
        setManageDialog({ ...manageDialog, isDialogOpen: false });
        addNewItemHotspot(manageDialog, value, manageDialog?.zoneId);
    };

    const store = useStore();

    function createHotspotElements() {
        const hotspots = [];
        let navLinks = activeZone.nav_links;
        let items = activeZone.items;

        let updateStats = {
            addedLinkHotspots: 0,
            updatedLinkHotspots: 0,
            addedInfoHotspots: 0,
            updatedInfoHotspots: 0,
        };

        items?.forEach((item) => {
            if (!item.yaw && !item.flat_x) {
                return;
            }

            let element = document.querySelector(
                `#info-${item.zone_id}-${item.item_id}`
            );
            if (!element) {
                updateStats.addedInfoHotspots++;
                element = document.createElement('div');
                element.classList.add('hotspot');
                element.classList.add('info-hotspot');
                element.id = `info-${item.zone_id}-${item.item_id}`;
            } else {
                updateStats.updatedInfoHotspots++;
            }

            const root = createRoot(element);

            root.render(
                <Provider store={store}>
                    <PanoItemHotspot
                        hotspot={item}
                        icon={hotspotItemIcon(item)}
                        onUpdatedHotspot={onUpdatedHotspot}
                        onDeletedHotspot={onDeletedHotspot}
                        onEditClicked={onEditClicked}
                        onNavClicked={onItemNavClicked}
                        hotspotIcons={itemHotspots}
                        readOnly={activeUser?.role === 'User'}
                        enableDragging={itemDragging}
                        setDraggableFlag={setDraggableFlag}
                        emptyItemOpacity={emptyItemOpacity}
                    />
                </Provider>
            );

            let component = {
                zone_id: item.zone_id,
                item_id: item.item_id,
                element: element,
            };

            hotspots.push(component);
        });

        navLinks?.forEach((navLink) => {
            let element = document.querySelector(
                `#link-${navLink.nav_link_id}`
            );
            if (!element) {
                updateStats.addedLinkHotspots++;
                element = document.createElement('div');
                element.classList.add('hotspot');
                element.classList.add('link-hotspot');
                element.id = `link-${navLink.nav_link_id}`;
            } else {
                updateStats.updatedLinkHotspots++;
            }

            const root = createRoot(element);

            root.render(
                <Provider store={store}>
                    <PanoLinkHotspot
                        hotspot={navLink}
                        icon={hotspotNavIcon(navLink)}
                        onUpdatedLinkHotspot={onUpdatedLinkHotspot}
                        onDeletedLinkHotspot={onDeletedLinkHotspot}
                        onNavClicked={onNavClicked}
                        hotspotIcons={navigationHotspots}
                        readOnly={activeUser?.role === 'User'}
                        enableDragging={navDragging}
                        setDraggableFlag={setDraggableFlag}
                    />
                </Provider>
            );

            hotspots.push({
                nav_link_id: navLink.nav_link_id,
                element: element,
            });
        });

        let hotspotsByZone = {};
        if (activeZone.zone_id) {
            hotspotsByZone[activeZone.zone_id] = hotspots;
        }

        return hotspotsByZone;
    }

    function onMovedHotspot(hotspot, location, screenPosition, element) {
        itemChipsRef.current.style.overflow = 'auto';

        // Make sure drop area was within the editor view
        let bounds = viewerRef.current.getBoundingClientRect();

        if (
            screenPosition.x < bounds.left ||
            screenPosition.x > bounds.right ||
            screenPosition.y < bounds.top ||
            screenPosition.y > bounds.bottom
        ) {
            // Invalid drop position - place back at original location
            element.style.transform = 'translate(0px,0px)';
            return;
        }

        let updatedHotspot = {
            item_id: hotspot.item_id,
            display_name: hotspot.display_name,
            zone_id: hotspot.zone_id,
        };
        if (location.yaw) updatedHotspot.yaw = location.yaw;
        if (location.pitch) updatedHotspot.pitch = location.pitch;
        if (location.x) updatedHotspot.flat_x = location.x;
        if (location.y) updatedHotspot.flat_y = location.y;
        if (!updatedHotspot.scale) updatedHotspot.scale = 1.0;
        if (!updatedHotspot.rotation) updatedHotspot.rotation = 0.0;
        if (!updatedHotspot.label_x) updatedHotspot.label_x = '75';
        if (!updatedHotspot.label_y) updatedHotspot.label_y = '20';

        onUpdatedHotspot(updatedHotspot, null, true);
    }

    const hotspotItemIcon = (item) => {
        if (selectedSearchItemId && selectedSearchItemId === item.item_id) {
            return (
                hotspotIcons?.length > 0 &&
                hotspotIcons.find(
                    (data) =>
                        data?.is_highlight &&
                        data?.hotspot_type_id === HotspotIconTypes.Item.id
                )
            );
        }

        const iconId =
            item.hotspot_icon_id || item.hotspot_icon_id === 0
                ? item.hotspot_icon_id
                : item.hotspot_icon
                ? item.hotspot_icon.hotspot_icon_id
                : asset.item_hotspot_icon_id;

        if (!iconId || iconId === 0) {
            return (
                hotspotIcons?.length > 0 &&
                hotspotIcons.find(
                    (data) =>
                        data?.is_default &&
                        data?.hotspot_type_id === HotspotIconTypes.Item.id
                )
            );
        }
        return (
            hotspotIcons?.length > 0 &&
            hotspotIcons.find((data) => data?.hotspot_icon_id === iconId)
        );
    };

    const hotspotNavIcon = (link) => {
        const iconId =
            link.hotspot_icon_id || link.hotspot_icon_id === 0
                ? link.hotspot_icon_id
                : link.hotspot_icon
                ? link.hotspot_icon.hotspot_icon_id
                : asset.nav_hotspot_icon_id;

        if (!iconId || iconId === 0) {
            return (
                hotspotIcons?.length > 0 &&
                hotspotIcons.find(
                    (data) =>
                        data?.is_default &&
                        data?.hotspot_type_id === HotspotIconTypes.Navigation.id
                )
            );
        }
        return (
            hotspotIcons?.length > 0 &&
            hotspotIcons.find((data) => data?.hotspot_icon_id === iconId)
        );
    };

    const handleSearchItems = async (searchString) => {
        if (searchString.length > 0) {
            const result = await dispatch(
                searchItems({
                    assetId: asset.asset_id,
                    searchString: searchString,
                })
            );
            if (!result.error) {
                dispatch(setSearchResults(result.payload));
            }
        } else {
            dispatch(setSearchResults(null));
        }
    };

    const searchItemSelected = async (item) => {
        dispatch(setSearchResults(null));
        // Make sure zone is loaded and display item content
        await dispatch(loadZone({ zoneId: item.zone_id }));
        await dispatch(
            loadContentPanelItem({ zoneId: item.zone_id, itemId: item.item_id })
        );
        dispatch(setSelectedSearchItemId(item?.item_id));
    };

    const onClickRefreshItem = async () => {
        await dispatch(loadZone({ zoneId: activeZone.zone_id, refresh: true }));
    };

    //#endregion Methods

    //#region Render time calcs

    var hasImage =
        activeZone !== undefined &&
        (activeZone.cubeFaces !== undefined ||
            activeZone.equirect_image_url !== undefined ||
            activeZone.flat_image_url !== undefined);

    var shouldShowPano = viewerLoaded;

    var message = viewerLoaded ? '' : 'Loading...';

    if (zoneLoadingStatus === LoadingStatus.Loaded && !hasImage) {
        message = 'This Zone Requires An Image';
    }

    const showAddInfoHotspot = true; //zoneItem === undefined || (!zoneItem.yaw && !zoneItem.flat_x);

    const defaultItemHotspot = asset.hotspot_item_icon_id
        ? asset.hotspot_item_icon_id
        : hotspotIcons.find((h) => {
              return (
                  h.hotspot_type_id === HotspotIconTypes.Item.id && h.is_default
              );
          });
    const defaultNavHotspot = asset.hotspot_nav_icon_id
        ? asset.hotspot_nav_icon_id
        : hotspotIcons.find((h) => {
              return (
                  h.hotspot_type_id === HotspotIconTypes.Navigation.id &&
                  h.is_default
              );
          });

    var addInfoHotspotLabel = 'Item';
    const zoneMediaValue = useZoneMediaConfigValue();

    // if (zoneItem && !zoneItem.yaw && !zoneItem.flat_x) {
    //     addInfoHotspotLabel = 'Place Item Hotspot';
    // }

    //#endregion Render time calcs

    //#region Effects

    useEffect(() => {
        if (Array.isArray(navItemsStack[zoneLoaded].stack)) {
            let data = navItemsStack[zoneLoaded];
            if (data.currentPointer === -1) {
                setUndoRedoStack({
                    undo: 0,
                    redo: navItemsStack[zoneLoaded].stack.length,
                });
            } else {
                setUndoRedoStack({
                    undo: data.currentPointer + 1,
                    redo:
                        navItemsStack[zoneLoaded].stack.length -
                        data.currentPointer -
                        1,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navItemsStack]);

    useEffect(() => {
        // NOTE: If unPositionedItems changes then view size changes because
        // of the bottom panel showing - so need to tell Marzipano to udpate view
        if (window.geminiViewManager) {
            window.geminiViewManager.updateView();
        }
    }, [isFormOpen, hasUnpositionedItems]);

    useEffect(() => {
        selectedHotspotIconsRef.current = selectedHotspotIcons;
    }, [selectedHotspotIcons]);

    useEffect(() => {
        if (zoneLoadingMessage) {
            enqueueSnackbar(zoneLoadingMessage, {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'warning',
            });
        }
    }, [zoneLoadingMessage, enqueueSnackbar]);

    const orbitMode =
        typeof activeZone?.orbit_frame === 'number' &&
        activeZone.orbit_frame > 0;

    useEffect(() => {
        if (!orbitMode) {
            setHotspotElements(createHotspotElements());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedSearchItemId,
        activeZone,
        hotspotIcons,
        itemDragging,
        navDragging,
    ]);

    useEffect(() => {
        return () => {
            if (window.geminiViewManager) {
                window.geminiViewManager.deleteView();
            }
        };
    }, []);

    useEffect(() => {
        dispatch(setActiveAssetId(asset?.asset_id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asset]);

    useEffect(() => {
        const updatedResponse = selectConfigValuesByFields(
            configValues,
            configFields,
            ['theme_empty_item_opacity']
        );
        if (updatedResponse?.['theme_empty_item_opacity']) {
            setEmptyItemOpacity(
                Number(updatedResponse?.['theme_empty_item_opacity'])
            );
        }
    }, [configValues, configFields]);

    //#endregion Effects

    //#region Render
    return (
        <>
            {!shouldShowPano ? (
                <div
                    style={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    }}
                >
                    <div
                        style={{
                            fontSize: '24px',
                            color: 'white',
                            position: 'relative',
                            top: '50%',
                            textAlign: 'center',
                        }}
                    >
                        {message}
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <GeminiContainer
                        allZones={asset.zones_compact}
                        asset={asset}
                        draggableFlag={draggableFlag}
                        setDraggableFlag={setDraggableFlag}
                        hotspotIcons={hotspotIcons}
                        hotspotElements={hotspotElements}
                        onShiftRightClick={onShiftRightClick}
                        onRightClick={onRightClick}
                        onClick={onClick}
                        onUpdatedHotspot={onUpdatedHotspot}
                        onDeletedHotspot={onDeletedHotspot}
                        onUpdatedLinkHotspot={onUpdatedLinkHotspot}
                        onDeletedLinkHotspot={onDeletedLinkHotspot}
                        onEditClicked={onEditClicked}
                        onItemNavClicked={onItemNavClicked}
                        onNavClicked={onNavClicked}
                        onClose={onClose}
                        PanoItemHotspot={PanoItemHotspot}
                        PanoLinkHotspot={PanoLinkHotspot}
                        isMultipleHotspotEnabled={isMultipleHotspotEnabled}
                        itemHotspots={itemHotspots}
                        navigationHotspots={navigationHotspots}
                        onSearchItems={handleSearchItems}
                        searchItemSelected={searchItemSelected}
                        onClickRefreshItem={onClickRefreshItem}
                        readOnly={activeUser?.role === 'User'}
                        // handleEnableDragging={handleEnableDragging}
                        // enableDragging={enableDragging}
                        handleItemDragging={handleItemDragging}
                        handleNavDragging={handleNavDragging}
                        enableItemDragging={itemDragging}
                        enableNavDragging={navDragging}
                        undoRedoProps={{
                            undoRedoStack: undoRedoStack,
                            undoItemPosition: undoItemPosition,
                            redoItemPosition: redoItemPosition,
                        }}
                        handleMultipleHotspot={handleMultipleHotspot}
                        viewerRef={viewerRef}
                        zoneMediaConfigValue={zoneMediaValue}
                        zoneCategory={zoneCategory}
                    />
                    {activeUser.role !== 'User' && (
                        <UnPositionedItemsBar
                            itemChipsRef={itemChipsRef}
                            onMovedHotspot={onMovedHotspot}
                            assetId={asset?.asset_id}
                        />
                    )}

                    <SceneControls
                        showAddInfoHotspot={showAddInfoHotspot}
                        hotspotIcons={hotspotIcons}
                        isNotUser={activeUser?.role !== 'User'}
                        addInfoHotspotLabel={addInfoHotspotLabel}
                        handleSceneInfoClick={handleSceneInfoClick}
                        asset={asset}
                        setSelectedHotspotIcons={setSelectedHotspotIcons}
                        selectedHotspotIcons={selectedHotspotIcons}
                        defaultItemHotspot={defaultItemHotspot}
                        itemHotspots={itemHotspots}
                        handleMultipleHotspot={handleMultipleHotspot}
                        isMultipleHotspotEnabled={isMultipleHotspotEnabled}
                        handleSceneNavClick={handleSceneNavClick}
                        defaultNavHotspot={defaultNavHotspot}
                        navigationHotspots={navigationHotspots}
                        orbitMode={orbitMode}
                        handleSceneInitViewClick={
                            handleSceneInitViewClickConfirm
                        }
                    />
                </div>
            )}
            {activeUser.role !== 'User' && (
                <PromptDialog
                    title="Enter item name"
                    label="New item hotspot"
                    cancelButtonLabel="Cancel"
                    okButtonLabel="Ok"
                    open={manageDialog.isDialogOpen}
                    value={itemName}
                    handleClose={handleDialogClose}
                    handleSubmit={(e) => handleDialogSubmit(e)}
                    handleOnChange={(e) => setItemName(e)}
                />
            )}
            <Dialog
                open={manageDialog.initZoneDialog}
                onClose={handleDialogClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {'Update zone thumbnail'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to update the zone thumbnail image to match?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => handleSceneInitViewClick(false)}>
                        No
                    </Button>
                    <Button
                        onClick={(e) => handleSceneInitViewClick(true)}
                        autoFocus
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
    //#endregion Render
};

export { GeminiEditor };
