import { useSelector } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { dialogStyles } from 'GeminiViewerComponent/components/Procedure/styles';

const CancelDialog = ({
    originalAttachmentData,
    openCancelDialog,
    setSelectedImages,
    setOpenCancelDialog,
    setOpenPreviewDialog,
}) => {
    const theme = useSelector(selectActiveTheme);
    const classes = dialogStyles(theme);

    return (
        <Dialog
            open={openCancelDialog}
            onClose={() => setOpenCancelDialog(false)}
        >
            <DialogTitle>Cancel Attachments</DialogTitle>
            <DialogContent sx={{ width: '400px' }}>
                <DialogContentText marginBottom={'5px'}>
                    Are you sure you want to cancel all image attachment
                    changes?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    className={classes.cancel}
                    onClick={() => setOpenCancelDialog(false)}
                >
                    No
                </Button>
                <Button
                    onClick={() => {
                        setSelectedImages(originalAttachmentData);
                        setOpenPreviewDialog(false);
                        setOpenCancelDialog(false);
                    }}
                    className={classes.submit}
                    type="button"
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { CancelDialog };
