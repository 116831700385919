import { config } from 'gemini-config';
import {
    createProgressParams,
    FormDataUtils,
    getNetworkService,
    prefixUrl,
} from 'GeminiViewerComponent/_helpers';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/part`;

const mapPart = (part, s3Prefix) => {
    if (part?.image_url) {
        part.image_url = prefixUrl(part.image_url, s3Prefix);
    }
    return part;
};

const mapParts = (parts, s3Prefix) => {
    return parts.map((part) => {
        return mapPart(part, s3Prefix);
    });
};

const getParts = async (
    partGroupIDs,
    partCategoryIDs,
    searchString = '',
    sort = '-display_name',
    s3Prefix = '',
    activeOnly = true
) => {
    const filters = [
        `is_active==${activeOnly.toString()}`,
        `display_name@=*${searchString}`,
    ];
    if (partGroupIDs?.length > 0) {
        filters.push(`part_group_id==${partGroupIDs.join('|').toString()}`);
    }
    if (partCategoryIDs?.length > 0) {
        filters.push(
            `part_category_id==${partCategoryIDs.join('|').toString()}`
        );
    }
    const params = { filters: filters.join(','), sorts: sort };
    const query = new URLSearchParams(params).toString();
    try {
        const response = await networkService.get(`${baseUrl}?${query}`);
        response.data = mapParts(response?.data, s3Prefix);
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, parts: response?.data };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const create = async (params, s3Prefix = '') => {
    const user = JSON.parse(localStorage.getItem('user'));
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [
        ['display_name', params.display_name],
        ['description', params.description],
        ['oem', params.oem],
        ['number', params.number],
        ['part_category_id', params.part_category_id],
        ['client_id', user.client_id],
        ['image', params.image],
    ]);
    FormDataUtils.safeArrayAppend(formData, [
        ['part_group_ids', params.part_group_ids],
        ['procedure_ids', params.procedure_ids],
    ]);
    let progressParams = createProgressParams(params);
    try {
        const response = await networkService.postMultiFormData(
            baseUrl,
            formData,
            progressParams
        );
        response.data = mapPart(response.data, s3Prefix);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const update = async (params, s3Prefix = '') => {
    const user = JSON.parse(localStorage.getItem('user'));
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [
        ['display_name', params.display_name],
        ['description', params.description],
        ['oem', params.oem],
        ['number', params.number],
        ['part_category_id', params.part_category_id],
        ['client_id', user.client_id],
        ['image', params.image],
        ['image_url', params.image_url],
    ]);
    FormDataUtils.safeArrayAppend(formData, [
        ['part_group_ids', params.part_group_ids],
        ['procedure_ids', params.procedure_ids],
    ]);
    let progressParams = createProgressParams(params);
    try {
        const response = await networkService.putMultiFormData(
            `${baseUrl}/${params.part_id}`,
            formData,
            progressParams
        );
        response.data = mapPart(response.data, s3Prefix);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _delete = async (part_id) => {
    try {
        const response = await networkService.delete(`${baseUrl}/${part_id}`);
        return response;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getById = async (id, s3Prefix = '') => {
    try {
        const response = await networkService.get(`${baseUrl}/${id}`);
        return mapPart(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getPartImportCsv = async () => {
    try {
        const response = await networkService.get(`${baseUrl}/csv`);
        return response?.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const uploadPartImportCsv = async (params) => {
    try {
        let formData = new FormData();
        FormDataUtils.safeAppend(formData, [['file', params.file]]);
        let progressParams = createProgressParams(params);
        const response = await networkService.postMultiFormData(
            `${baseUrl}/csv`,
            formData,
            progressParams
        );
        return response.data;
    } catch (err) {
        return err;
    }
};

export const partsService = {
    getParts,
    getById,
    create,
    update,
    delete: _delete,
    getPartImportCsv,
    uploadPartImportCsv,
};
