import { config } from 'gemini-config';
import { getNetworkService } from 'GeminiViewerComponent/_helpers';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/rule`;

const getById = async (id) => {
    try {
        const response = await networkService.get(`${baseUrl}/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const update = async (id, params) => {
    try {
        const response = await networkService.put(`${baseUrl}/${id}`, params);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const create = async (params) => {
    const { client_id } = JSON.parse(localStorage.getItem('user'));
    params.client_id = client_id;
    try {
        const response = await networkService.post(baseUrl, params);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _delete = async (id) => {
    try {
        const response = await networkService.delete(`${baseUrl}/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const ruleService = {
    getById,
    create,
    update,
    delete: _delete,
};
