import ReactGA from 'react-ga4';

const useAnalyticsEventTracker = (category = 'GA category') => {
    return (action = 'GA action', label = 'GA label', value = undefined) => {
        if (typeof value === 'string') {
            value = parseInt(value);
        }
        ReactGA.event({ category, action, label, value });
    };
};
export default useAnalyticsEventTracker;
