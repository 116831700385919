import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Badge, Box, IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { MdAddAPhoto } from 'react-icons/md';

import { getChecklistData } from '../../_features/checklistDataSlice';
import { PreviewDialog } from './components/PreviewDialog';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { getBase64 } from 'GeminiViewerComponent/_helpers/helper';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';

const ImageAttachmentBtn = ({
    data,
    selectedImages,
    setOpenPreviewDialog,
    openFileSelection,
    level,
    isGuest,
    isReadOnly,
    handleClick,
}) => {
    const procedureLevelStyle = useMemo(() => {
        return level === 'procedure'
            ? {
                  position: 'absolute',
                  right: 0,
                  backgroundColor: '#ffffff',
                  borderRadius: 0,
                  padding: '3px',
              }
            : {};
    }, [level]);

    return (
        <IconButton
            onClick={(e) => {
                if (isReadOnly) {
                    e.stopPropagation();
                    handleClick(e, data);
                } else {
                    if (!isGuest) {
                        if (selectedImages?.length > 0) {
                            setOpenPreviewDialog(true);
                        } else {
                            openFileSelection();
                        }
                    }
                }
            }}
            aria-label="close"
            size="large"
            sx={{
                cursor: `${isGuest ? 'not-allowed' : 'pointer'}`,
                ...procedureLevelStyle,
            }}
        >
            {selectedImages?.length > 0 ? (
                <Badge
                    sx={{
                        '& span': {
                            backgroundColor: '#FF0000',
                            color: '#ffffff',
                        },
                    }}
                    badgeContent={selectedImages?.length}
                    invisible={false}
                >
                    <MdAddAPhoto
                        className="react-icon"
                        style={{ padding: 0, color: '#000000' }}
                    />
                </Badge>
            ) : (
                <MdAddAPhoto
                    className="react-icon"
                    style={{ padding: 0, color: '#000000' }}
                />
            )}
        </IconButton>
    );
};

const ImageAttachments = ({
    data,
    var_name = '',
    level = 'field',
    isReadOnly = false,
    handleClick,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const inputFileRef = useRef(null);
    const [selectedImages, setSelectedImages] = useState([]);
    const [originalAttachmentData, setOriginalAttachmentData] = useState([]);
    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
    const checklistData = useSelector(getChecklistData);
    const userData = useSelector(getConfig);

    useEffect(() => {
        if (
            Array.isArray(checklistData?.imgAtt?.[var_name]) &&
            checklistData?.imgAtt?.[var_name]?.length > 0
        ) {
            setSelectedImages(checklistData?.imgAtt?.[var_name]);
            setOriginalAttachmentData(checklistData?.imgAtt?.[var_name]);
        } else {
            setSelectedImages([]);
            setOriginalAttachmentData([]);
        }
    }, [checklistData?.imgAtt, var_name]);

    const openFileSelection = () => {
        if (inputFileRef.current) {
            inputFileRef.current.click();
        }
    };

    const ImagePreviewURL = async (files) => {
        let updateObject = [];
        for (let [index, fl] of files.entries()) {
            updateObject[index] = { ...fl };
            if (fl?.isSaved === true || fl?.url) {
                continue;
            }
            const img = await getBase64(fl?.file);
            updateObject[index] = { ...fl, url: img };
        }
        setSelectedImages(updateObject);
    };

    const saveSelectedFiles = useCallback(
        async (files) => {
            if (isReadOnly === false && files && files.length > 0) {
                let newFiles = [];
                let isError = false;
                let fileName = '';
                for (let fl of files) {
                    if (fl.type.indexOf('image/') !== -1) {
                        fileName = (fl?.name || '').substring(
                            0,
                            (fl?.name || '').lastIndexOf('.')
                        );
                        newFiles.push({
                            file: fl,
                            name: fileName,
                            id: uuidv4(),
                            isSaved: false,
                            url: '',
                        });
                    } else {
                        isError = true;
                    }
                }
                if (isError === true) {
                    enqueueSnackbar('Only images are accepted', {
                        action: (key) => <SnackbarDismiss key={key} />,
                        variant: 'error',
                    });
                    return;
                }
                inputFileRef.current.value = null;
                if (selectedImages?.length === 0) {
                    await ImagePreviewURL(newFiles);
                    setOpenPreviewDialog(true);
                } else {
                    await ImagePreviewURL([...selectedImages, ...newFiles]);
                }
            }
        },
        [selectedImages]
    );

    return (
        <>
            <input
                ref={inputFileRef}
                type="file"
                accept="image/*"
                name={var_name}
                id={var_name}
                style={{ display: 'none' }}
                multiple={true}
                onChange={(e) => saveSelectedFiles(e.target.files)}
            />
            <PreviewDialog
                selectedImages={selectedImages}
                setSelectedImages={setSelectedImages}
                originalAttachmentData={originalAttachmentData}
                var_name={var_name}
                level={level}
                openPreviewDialog={openPreviewDialog}
                setOpenPreviewDialog={setOpenPreviewDialog}
                openFileSelection={openFileSelection}
            />
            <div>
                {userData?.user?.isGuest ? (
                    <Tooltip
                        title={`You're not authenticated to access this feature.`}
                    >
                        <Box>
                            <ImageAttachmentBtn
                                data={data}
                                selectedImages={selectedImages}
                                setOpenPreviewDialog={setOpenPreviewDialog}
                                openFileSelection={openFileSelection}
                                level={level}
                                isGuest={userData?.user?.isGuest}
                                isReadOnly={isReadOnly}
                                handleClick={handleClick}
                            />
                        </Box>
                    </Tooltip>
                ) : (
                    <ImageAttachmentBtn
                        data={data}
                        selectedImages={selectedImages}
                        setOpenPreviewDialog={setOpenPreviewDialog}
                        openFileSelection={openFileSelection}
                        level={level}
                        isGuest={userData?.user?.isGuest}
                        isReadOnly={isReadOnly}
                        handleClick={handleClick}
                    />
                )}
            </div>
        </>
    );
};

export { ImageAttachments };
