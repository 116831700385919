import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    root: {
        '& .assetHeader': {
            fontSize: '10px',
        },
        '& .MuiDataGrid-colCellTitle': {
            color: '#7E8C8D',
            fontWeight: 800,
            fontSize: '12px',
        },
        '& .MuiDataGrid-columnSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-cell': {
            color: '#34485E',
            fontSize: '14px',
        },
        '& .MuiCheckbox-root': {
            color: '#7E8C8D',
        },
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: '#3598DB',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1.25rem',
        },
    },
    selectRoot: {
        '& .MuiSelect-root': {
            padding: '13px 14px',
        },
    },
    sortContainer: {
        display: 'flex',
        marginTop: '16px',
        flexWrap: 'wrap',
    },
    inputLabel: {
        transform: 'translate(14px, 14px) scale(1)',
    },
    assetDisplayname: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    actionBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },
    actionBarRight: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    actionButton: {
        margin: '15px',
        background: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        fontWeight: 600,
        '&:hover': {
            background: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(90%)',
        },
    },
    list: {
        width: 576,
    },
    fullList: {
        width: 'auto',
    },
    assetContent: {
        flexGrow: 1,
        padding: '25px 50px',
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh)',
        padding: '25px 50px',
        overflow: 'auto',
    },
    createContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
        backgroundColor: '#ECF0F1',
        color: '#2D3D50',
    },
    createHeader: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        fontSize: '24px',
        gap: '5px',
    },
    createHeaderText: {
        fontWeight: 800,
        paddingLeft: '10px',
    },
    panel: {
        padding: '25px',
    },
    panelHeader: {
        paddingBottom: '15px',
        fontWeight: 800,
        color: '#2D3D50',
    },
    panelInputBottom: {
        marginTop: '15px',
        width: '100%',
    },
    splitInput: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    splitInputRight: {
        width: '100%',
    },
    splitInputLeft: {
        width: '35%',
    },
    stackInputs: {
        display: 'flex',
        flexDirection: 'column',
    },
    stackInput: {
        marginTop: '15px',
    },
    pin: {
        display: 'flex',
        cursor: 'pointer',
        '& svg': {
            height: '20px',
            width: '20px',
        },
        '& svg:hover': {
            filter: 'brightness(50%)',
        },
    },
    isPinned: {
        color: '#E84C3C',
        display: 'flex',
    },
    gridLink: {
        textDecoration: 'none',
    },
    gridLinkSpan: {
        backgroundColor: '#3598DB',
        padding: '5px',
        color: 'white',
        borderRadius: '5px',
        fontSize: '12px',
    },
    createAsset: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    createAssetTitle: {
        fontSize: '24px',
        fontWeight: 300,
        color: '#BDC3C7',
        marginBottom: '20px',
        textAlign: 'center',
    },
    createAssetImage: {
        marginTop: '30px',
        height: '200px',
    },
    datePickerWrap: {
        '& .MuiFormControl-root': {
            width: '100%',
        },
    },
    assetFilter: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        alignItems: 'flex-start',
    },
    assetFilterFieldset: {
        padding: '7px',
        border: '1px solid #cccccc',
        borderRadius: '5px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '10px',
    },
    assetFilterFieldsetLegend: {
        marginLeft: '10px',
        padding: '5px',
        fontSize: '13px',
        color: '#6d7178',
    },
    sortButton: {
        position: 'absolute',
        right: '0',
        top: '50%',
        transform: 'translateY(-50%)',
    },
}));

export default useStyles;
