import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import {
    selectContentById,
    resetContentStatus,
    fetchContentVersions,
    fetchContentByTypeId,
    getContentIdToCreateLink,
    setContentIdToCreateLink,
} from '_features/content/contentSlice';
import {
    resetLinkStatus,
    createLink,
} from 'GeminiViewerComponent/_features/links/linksSlice';
import { AutoSizer } from 'react-virtualized';
import { labelHeader, labelCell, viewLink, VirtualTable } from 'components';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { loadItem, selectEditItem } from '_features/common/editItemSlice';
import { DisplayTypes } from 'GeminiViewerComponent/_helpers';

function ContentTableGrid({
    setExpanded,
    formField,
    linkNameValue,
    linkNameField,
    contentVersionsField,
    content,
    pageInfo,
    linkTypeId,
    contentTypeIds,
    selectedContentId,
    setFieldValue,
    contentDisplayName,
    setContentDisplayName,
    searchValue,
    displayAsset
}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const tableClasses = tableStyles();
    const [selected, setSelected] = useState([selectedContentId]);
    const [contentDescription, setContentDescription] = useState('');
    const [target, setTarget] = useState(DisplayTypes.External.id);

    const editItem = useSelector(selectEditItem);
    const allRows = useSelector(selectContentById);
    const contentIdToCreateLink = useSelector(getContentIdToCreateLink);
    const loadedRowsMap = useSelector(
        (state) => state.content.contentLoadedRowsMap
    );

    const headerHeight = 40;
    const rowHeight = 60;

    useEffect(() => {
        if (
            contentIdToCreateLink &&
            Array.isArray(content) &&
            content.length > 0
        ) {
            let clickableContent = content.find(
                (cnt) => cnt.content_id === contentIdToCreateLink
            );
            handleRowClick(clickableContent);
        }
    }, [content, contentIdToCreateLink]);

    // eslint-disable-next-line
    const handleClick = async (event, id, display_name) => {
        const selectedIndex = selected.indexOf(id);
        if (selectedIndex === -1) {
            const contentVersions = await dispatch(fetchContentVersions(id));
            setFieldValue(formField, id);
            setContentDisplayName(display_name);
            setContentDescription(display_name);
            if (!linkNameValue) {
                setFieldValue(linkNameField, display_name);
            }
            setFieldValue(contentVersionsField, contentVersions.payload);
            setExpanded(false);
        } else if (selectedIndex === 0) {
            resetLinkState();
        }
    };

    const handleRowClick = async (rowData) => {
        const id = rowData.content_id;
        const display_name = rowData.display_name;
        const selectedIndex = selected.indexOf(id);
        if (selectedIndex === -1) {
            const contentVersions = await dispatch(fetchContentVersions(id));
            setFieldValue(formField, id);
            setContentDisplayName(display_name);
            setContentDescription(display_name);
            if (!linkNameValue) {
                setFieldValue(linkNameField, display_name);
            }
            setFieldValue(contentVersionsField, contentVersions.payload);
            setExpanded(false);
        } else if (selectedIndex === 0) {
            resetLinkState();
        }
        if (contentIdToCreateLink) {
            dispatch(setContentIdToCreateLink(null));
        }
    };

    const columns = [
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 100,
            minWidth: 100,
            label: 'Name',
            dataKey: 'display_name',
            padding: 'normal',
            onClick: handleRowClick,
            component: labelCell,
            headerComponent: labelHeader,
        },
        {
            classes: tableClasses,
            minWidth: 60,
            maxWidth: 60,
            width: 60,
            label: '',
            dataKey: 'content_id',
            padding: 'none',
            dispatch: dispatch,
            component: viewLink,
            headerComponent: labelHeader,
        },
    ];

    const resetLinkState = () => {
        setSelected([]);
        setContentDisplayName('');
        setContentDescription('');
        setTarget(DisplayTypes.External.id);
    };

    function loadMoreRows({ startIndex, stopIndex }) {
        dispatch(
            fetchContentByTypeId({
                startIndex: startIndex,
                stopIndex: stopIndex,
                contentTypeIds: contentTypeIds,
                searchString: searchValue,
                assetId: displayAsset.asset_id,
            })
        );
    }

    // eslint-disable-next-line
    const handleCreate = async () => {
        const { content_id } = content.find(
            (a) => a.content_id === selected[0]
        );
        await dispatch(
            createLink({
                item_id: editItem.item_id,
                display_name: contentDisplayName,
                description: contentDescription,
                content_id: content_id,
                content_version_id: null,
                link_type_id: linkTypeId,
                display_type_id: parseInt(target),
            })
        );
        resetLinkState();
        dispatch(resetLinkStatus());
        dispatch(resetContentStatus());
        dispatch(loadItem(editItem.item_id));
    };

    return (
        <div className={classes.root}>
            <div style={{ height: '200px', marginTop: '0px' }}>
                <AutoSizer>
                    {({ width, height }) => (
                        <VirtualTable
                            disableHeader={true}
                            initialRows={content}
                            allRows={allRows}
                            totalRowCount={pageInfo.TotalCount}
                            loadedRowsMap={loadedRowsMap}
                            loadMoreRows={loadMoreRows}
                            columnDefinitions={columns}
                            cellClassName={tableClasses.flexContainer}
                            className={tableClasses.table}
                            rowClassName={tableClasses.flexContainer}
                            headerHeight={headerHeight}
                            width={width}
                            height={height}
                            rowHeight={rowHeight}
                        />
                    )}
                </AutoSizer>
            </div>
            {/* <div className={clsx(classes.flexEnd, {[classes.hidden]: showContentTable})}>{createButton}</div> */}
        </div>
    );
}

export { ContentTableGrid };
