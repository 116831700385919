import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import { IconButton, Tooltip } from '@mui/material';

import { Action } from '_helpers';
import { useStyles } from './styles';
import SearchWithFilters from 'components/SearchWithFilters';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { makeButtonStyles } from '../../../../GeminiViewerComponent/components/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { ResizableTable } from 'GeminiViewerComponent/components/_Tables/ResizableTable';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import {
    closePanel,
    openPanel,
    setEditObjectId,
} from '_features/common/formSlice';
import {
    resizableLabelHeader,
    resizableLabelCell,
    resizableActionCell,
    resizableCheckCell,
    resizableCheckHeaderCell,
} from 'components';

function CompanionAppsTable({
    companionApps,
    pageInfo,
    companionPayload,
    setCompanionPayload,
    sortDirection,
    sortBy,
    handleSort,
}) {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const buttonStyles = makeButtonStyles(theme);
    const classes = useStyles();
    const tableClasses = tableStyles();

    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = React.useState(false);

    const loadedRowsMap = useSelector(
        (state) => state.companionApps.loadedRowsMap
    );

    const headerHeight = 40;
    const rowHeight = 60;
    const isSelected = (id) => selected.indexOf(id) !== -1;

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== companionApps.length) {
            setSelected(companionApps.map((row) => row.companion_app_id));
            setSelectedAll(true);
        }
    }

    const handleClick = (event, companion_app_id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(companion_app_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, companion_app_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        setSelectedAll(newSelected.length === companionApps.length);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedIds = companionApps.map(
                (companionApp) => companionApp.companion_app_id
            );
            setSelected(newSelectedIds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    const handleRowClick = async ({ rowData }) => {
        await dispatch(closePanel({ formKey: 'companionForm' }));
        if (rowData?.companion_app_id) {
            await dispatch(
                setEditObjectId({
                    formKey: `companionForm`,
                    object_id: rowData?.companion_app_id,
                })
            );
            dispatch(
                openPanel({ formKey: `companionForm`, formAction: 'Edit' })
            );
        }
    };

    const columns = [
        {
            accessorKey: 'companion_app_id',
            size: 50,
            minSize: 50,
            enableResizing: false,
            label: 'Select',
            padding: 'checkbox',
            cellLevel: 'companion_app',
            numSelected: selected.length,
            rowCount: companionApps.length,
            handleClick: handleClick,
            handleSelectAllClick: handleSelectAllClick,
            isSelected: isSelected,
            header: (info) =>
                resizableCheckHeaderCell(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableCheckCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'companion_app_id',
            size: 50,
            minSize: 50,
            enableResizing: false,
            padding: 'none',
            label: '',
            actionCellLevel: 'companion_app',
            targetPopup: 'companionApps',
            formKey: 'companionForm',
            dispatch: dispatch,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableActionCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'display_name',
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'Name',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'app_url',
            flexGrow: 1,
            size: 300,
            minSize: 300,
            label: 'URL',
            padding: 'normal',
            sortBy: sortBy,
            sortDirection: sortDirection,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'asset_count',
            flexGrow: 1,
            size: 100,
            minSize: 100,
            label: 'N* ASSETS',
            enableResizing: false,
            displayDataKey: '',
            padding: 'none',
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
    ];

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    // eslint-disable-next-line no-unused-vars
    function loadMoreRows({ startIndex, stopIndex }) {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setCompanionPayload({
                ...companionPayload,
                searchString: searchString,
            });
        }, 1000),
        [companionPayload]
    );

    const handleCreateNew = () => {
        dispatch(
            openPanel({
                formKey: `companionForm`,
                formAction: 'Create',
                clearEditId: true,
            })
        );
    };

    return (
        <div className={classes.root}>
            <div style={{ display: 'flex', gap: '5px', marginBottom: '10px' }}>
                <Tooltip title="Add New Companion App">
                    <IconButton
                        onClick={handleCreateNew}
                        size="large"
                        className={buttonStyles.contentAddButton}
                    >
                        <MdAdd className="react-icon" />
                    </IconButton>
                </Tooltip>
                <div style={{ flexGrow: 1 }}></div>
                <SearchWithFilters
                    filter={false}
                    onChange={(evt) => debouncedSearch(evt.target.value)}
                />
            </div>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    toolTipTitle={'Delete'}
                    totalCount={pageInfo.TotalCount}
                >
                    <PopupAction
                        action={Action.DeleteArray}
                        object={{
                            companionAppIds: selected,
                            setCompanionAppIds: setSelected,
                        }}
                        level={'companion_app'}
                        showLabel={false}
                    />
                </EnhancedTableToolbar>
            )}
            <div
                style={{
                    height: `calc(100vh - ${
                        selected.length > 0 ? '345px' : '280px'
                    })`,
                    minWidth: '500px',
                }}
            >
                <ResizableTable
                    initialRows={companionApps}
                    allRows={companionApps}
                    totalRowCount={pageInfo.TotalCount}
                    loadedRowsMap={loadedRowsMap}
                    loadMoreRows={loadMoreRows}
                    columns={columns}
                    cellClassName={tableClasses.flexContainer}
                    className={tableClasses.table}
                    rowClassName={tableClasses.flexContainer}
                    headerHeight={headerHeight}
                    rowHeight={rowHeight}
                    onRowClick={handleRowClick}
                    // selected={selected}
                />
            </div>
        </div>
    );
}

export { CompanionAppsTable };
