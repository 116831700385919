import { useCallback, useEffect, useState } from 'react';
import {
    Grid,
    MenuItem,
    Select,
    Button,
    InputLabel,
    FormControl,
    Checkbox,
    Box,
    Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { makeFormStyles } from 'forms/styles';
import { Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { MdDragHandle } from 'react-icons/md';

import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import IncorrectFieldSet from './IncorrectFieldSet';
import { setProcedureCreateNodeData } from '_features/procedures/proceduresSlice';
import NodeTypeButton from './NodeTypeButton';
import { FormTextField } from 'components/TextField';

const ButtonField = ({
    button,
    idx,
    nodes,
    node,
    values,
    setFieldValue,
    handleRemoveOptionButton,
    ...otherProps
}) => {
    const { errors } = useFormikContext();
    const dispatch = useDispatch();
    const validationError = errors?.[`option_buttons[${idx}].button_text`];
    useEffect(() => {
        if (node?.focusTargetBtnId === 0 || node?.focusTargetBtnId) {
            let ele = document.getElementById(
                `target-node-id-${node?.focusTargetBtnId}`
            );
            if (ele) ele.scrollIntoView({ behavior: 'smooth' });
            ele = document.getElementById(
                `target_node_id_${node?.focusTargetBtnId}`
            );
            if (ele) ele.focus();
        }
    }, [node]);

    const [openNodeTypeSelectionDialog, setOpenNodeTypeSelectionDialog] =
        useState(false);
    const theme = useSelector(selectActiveTheme);
    nodes = nodes.filter((nd) => nd.id !== node.id);
    const commonFormStyles = makeFormStyles(theme);

    const handleButtonSelection = useCallback(
        (nodeType) => {
            dispatch(
                setProcedureCreateNodeData({
                    currentNode: openNodeTypeSelectionDialog,
                    newNodeType: nodeType,
                })
            );
        },
        [openNodeTypeSelectionDialog]
    );

    return (
        <Draggable
            key={'button_opts_draggable_' + idx}
            draggableId={String('button_opts_draggable_' + idx)}
            index={idx}
        >
            {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                    <Grid
                        container
                        key={'button_opts_' + idx}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            marginBottom: '6px',
                        }}
                        {...otherProps}
                    >
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            justifyContent={'center'}
                            item
                            xs={12}
                            sm={12}
                            md={1}
                            lg={1}
                            {...provided.dragHandleProps}
                        >
                            <MdDragHandle className="react-icon" />
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={2}
                            lg={2}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                position="relative"
                                display="flex"
                                flexDirection="column"
                            >
                                <FormControl fullWidth>
                                    <FormTextField
                                        label="Text"
                                        placeholder="Text"
                                        name={`option_buttons[${idx}].button_text`}
                                        onChange={(e) => {
                                            let currOptions = [
                                                ...values.option_buttons,
                                            ];
                                            currOptions[idx] = {
                                                ...values.option_buttons[idx],
                                                button_text: e.target.value,
                                            };
                                            setFieldValue(
                                                `option_buttons`,
                                                currOptions
                                            );
                                        }}
                                        {...otherProps}
                                        error={
                                            !!errors?.[
                                                `option_buttons[${idx}].button_text`
                                            ]
                                        }
                                    />
                                </FormControl>
                                {validationError && (
                                    <Typography
                                        position="absolute"
                                        top="100%"
                                        sx={{
                                            color: '#d32f2f',
                                            fontSize: '0.75rem',
                                            lineHeight: '0.75rem',
                                        }}
                                    >
                                        {validationError}
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={2}
                            lg={2}
                        >
                            <FormControl fullWidth>
                                <InputLabel id={`target-node-id-${idx}`}>
                                    Target Node
                                </InputLabel>
                                <Select
                                    className={commonFormStyles.materialSelect}
                                    style={{ margin: 0 }}
                                    labelId={`target-node-id-${idx}`}
                                    margin="dense"
                                    id={`target_node_id_${idx}`}
                                    label="Target Node"
                                    placeholder="Target Node"
                                    name={`option_buttons[${idx}].target_node_id`}
                                    value={button?.action?.id}
                                    onChange={(e) => {
                                        let currOptions = [
                                            ...values.option_buttons,
                                        ];
                                        currOptions[idx] = {
                                            ...values.option_buttons[idx],
                                            action: {
                                                ...values.option_buttons[idx]
                                                    .action,
                                                id: e.target.value,
                                            },
                                        };
                                        if (e.target.value === 'new-node') {
                                            setOpenNodeTypeSelectionDialog({
                                                ...values,
                                                option_buttons: currOptions,
                                                focusTargetBtnId: idx,
                                            });
                                        } else {
                                            setFieldValue(
                                                'option_buttons',
                                                currOptions
                                            );
                                        }
                                    }}
                                >
                                    <MenuItem key={'next'} value={'next'}>
                                        Next
                                    </MenuItem>
                                    <MenuItem key={'back'} value={'back'}>
                                        Back
                                    </MenuItem>
                                    {nodes?.length > 0 &&
                                        nodes.map((nd) => (
                                            <MenuItem
                                                key={nd?.id}
                                                value={nd?.id}
                                            >
                                                {nd?.title} - #{nd?.id}
                                            </MenuItem>
                                        ))}
                                    {values?.option_buttons?.[idx]
                                        ?.button_text && (
                                        <MenuItem
                                            key={'new-node'}
                                            value={'new-node'}
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            + New node -&nbsp;
                                            {
                                                values?.option_buttons?.[idx]
                                                    ?.button_text
                                            }
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={2}
                            lg={2}
                        >
                            <FormControl fullWidth>
                                <FormTextField
                                    label="Answer Value"
                                    placeholder="Answer Value"
                                    name={`option_buttons[${idx}].answer_value`}
                                    onChange={(e) => {
                                        let currOptions = [
                                            ...values.option_buttons,
                                        ];
                                        currOptions[idx] = {
                                            ...values.option_buttons[idx],
                                            answer_value: e.target.value,
                                        };
                                        setFieldValue(
                                            `option_buttons`,
                                            currOptions
                                        );
                                    }}
                                    {...otherProps}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={2}
                            lg={2}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="btn-style">Style</InputLabel>
                                <Select
                                    labelId="btn-style"
                                    className={commonFormStyles.materialSelect}
                                    style={{ margin: 0 }}
                                    id="btn_style"
                                    label="Style"
                                    name={`option_buttons[${idx}].btn_style`}
                                    value={
                                        button?.style ? button.style : 'neutral'
                                    }
                                    onChange={(e) => {
                                        let currOptions = [
                                            ...values.option_buttons,
                                        ];
                                        if (e.target.value === 'neutral') {
                                            if (currOptions[idx]?.style) {
                                                const { ...allProps } =
                                                    currOptions[idx];
                                                currOptions[idx] = allProps;
                                            }
                                        } else {
                                            currOptions[idx] = {
                                                ...values.option_buttons[idx],
                                                style: e.target.value,
                                            };
                                        }
                                        setFieldValue(
                                            'option_buttons',
                                            currOptions
                                        );
                                    }}
                                >
                                    <MenuItem key={'neutral'} value={'neutral'}>
                                        Neutral
                                    </MenuItem>
                                    <MenuItem
                                        key={'positive'}
                                        value={'positive'}
                                    >
                                        Positive
                                    </MenuItem>
                                    <MenuItem
                                        key={'negative'}
                                        value={'negative'}
                                    >
                                        Negative
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={1}
                            lg={1}
                        >
                            <FormControl fullWidth>
                                <IncorrectFieldSet>
                                    <Checkbox
                                        color="primary"
                                        checked={Boolean(button?.incorrect)}
                                        onChange={(e) => {
                                            let currOptions = [
                                                ...values.option_buttons,
                                            ];
                                            currOptions[idx] = {
                                                ...values.option_buttons[idx],
                                                incorrect: e.target.checked,
                                            };
                                            setFieldValue(
                                                'option_buttons',
                                                currOptions
                                            );
                                        }}
                                    />
                                </IncorrectFieldSet>
                            </FormControl>
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={1}
                            lg={1}
                        >
                            <FormControl fullWidth>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        handleRemoveOptionButton(idx);
                                    }}
                                >
                                    Remove
                                </Button>{' '}
                            </FormControl>
                        </Grid>
                    </Grid>
                    {openNodeTypeSelectionDialog && (
                        <NodeTypeButton
                            openDialog={Boolean(openNodeTypeSelectionDialog)}
                            setOpenDialog={setOpenNodeTypeSelectionDialog}
                            handleButtonSelection={handleButtonSelection}
                        />
                    )}
                </div>
            )}
        </Draggable>
    );
};

export default ButtonField;
