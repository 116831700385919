import React from 'react';

import { FormTextField } from 'components/TextField';

const MultiLineField = () => {
    return (
        <FormTextField
            autoFocus={true}
            label="No of lines"
            type="number"
            name="lines"
            placeholder="No of lines"
        />
    );
};

export default MultiLineField;
