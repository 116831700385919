import React from 'react';
import { useDispatch } from 'react-redux';
import { flyerStyles } from './styles';
import { setShowFlyer } from '../../../../_features/globals/flyerSlice';

const Flyer = ({ imgUrl, row, col }) => {
    const dispatch = useDispatch();
    const classes = flyerStyles();

    const handleShowFlyer = () => {
        dispatch(setShowFlyer({ showFlyer: true, flyerImg: imgUrl }));
    };

    return (
        <div
            className={classes.flyer}
            style={{ gridColumn: col, gridRow: row, minHeight: '300px' }}
            onClick={handleShowFlyer}
        >
            <img src={imgUrl} alt="" />
        </div>
    );
};

export { Flyer };
