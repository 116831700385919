import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    sliderWrapper: {
        minWidth: '550px',
        overflow: 'hidden',
        padding: '30px 36px 15px',
    },
}));

export default useStyles;
