import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    assetTypeContainer: {
        position: 'relative',
        marginTop: 'auto',
    },
    buttonLabel: {
        lineHeight: '40px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 20px',
    },
    assetTypeButtonsWrap: {
        position: 'absolute',
        zIndex: '16',
        top: '-80px',
        left: '76px',
        boxShadow: 'none !important',
    },
    assetTypeIcon: {
        padding: '5px !important',
        borderRadius: '25px !important',
        color: 'rgb(255, 255, 255) !important',
        backgroundColor: '#3598db !important',
    },
    assetTypeIconWrap: {
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        padding: '6px 8px',
        '&:hover svg': {
            fill: '#fff !important',
        },
    },
}));

export default useStyles;
