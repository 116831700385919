import { useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    TextField,
    FormHelperText,
    FormControl,
    Slider,
    ClickAwayListener,
    Autocomplete,
    Paper,
} from '@mui/material';
import useStyles from './style';

const MinMaxSelection = ({
    disabled = false,
    readOnly = false,
    variant = 'outlined',
    displayUnit = '',
    values,
    inputLabel = 'Filter',
    selectorId,
    value,
    onChange,
    error = false,
    helperText = '',
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [sliderValue, setSliderValue] = useState([]);

    values = useMemo(
        () => [
            { value: 0, filterValue: null, label: 'No min' },
            ...values.map((v, i) => ({
                value: i + 1,
                filterValue: v,
                label: v + displayUnit,
            })),
            { value: values.length + 1, filterValue: null, label: 'No max' },
        ],
        [values, displayUnit]
    );

    useEffect(() => {
        setSliderValue([
            values.findIndex((v) => v.filterValue === value[0]),
            values.findLastIndex((v) => v.filterValue === value[1]),
        ]);
    }, [values, value]);

    const onSubmit = function () {
        setIsOpen(false);
        const newValue = [
            values[sliderValue[0]].filterValue,
            values[sliderValue[1]].filterValue,
        ];
        if (newValue[0] !== value[0] || newValue[1] !== value[1]) {
            onChange?.(newValue);
        }
    };

    const clearValue = function () {
        onChange?.([null, null]);
    };

    let displayValue = null;
    if (!Array.isArray(value) || value.length !== 2) {
        value = [null, null];
    }
    if (value) {
        if (value[0]) {
            if (value[1]) {
                displayValue = `${value[0]}${displayUnit} – ${value[1]}${displayUnit}`;
            } else {
                displayValue = `≥ ${value[0]}${displayUnit}`;
            }
        } else if (value[1]) {
            displayValue = `≤ ${value[1]}${displayUnit}`;
        }
    }

    return (
        <ClickAwayListener onClickAway={() => isOpen && onSubmit()}>
            <FormControl style={{ width: '100%' }}>
                <Autocomplete
                    name={selectorId}
                    id={selectorId}
                    variant={variant}
                    options={[{ label: '' }]}
                    disablePortal
                    isOptionEqualToValue={() => true}
                    open={isOpen}
                    value={displayValue}
                    disabled={disabled}
                    readOnly={readOnly}
                    renderInput={({ inputProps, ...params }) => {
                        return (
                            <TextField
                                {...params}
                                inputProps={{
                                    ...inputProps,
                                    readOnly: true,
                                    style: { cursor: 'pointer' },
                                }}
                                label={inputLabel}
                                error={error}
                                onClick={() => setIsOpen((v) => !v)}
                            />
                        );
                    }}
                    PaperComponent={Dropdown}
                    slotProps={{
                        paper: {
                            values,
                            sliderValue,
                            setSliderValue,
                            onSubmit,
                            onCancel: () => setIsOpen(false),
                        },
                        clearIndicator: {
                            onClick: (e) => {
                                clearValue();
                                e.stopPropagation();
                            },
                        },
                    }}
                />
                {error && (
                    <FormHelperText error={error}>{helperText}</FormHelperText>
                )}
            </FormControl>
        </ClickAwayListener>
    );
};

const Dropdown = function ({
    values,
    sliderValue,
    setSliderValue,
    onSubmit,
    onCancel,
}) {
    const classes = useStyles();

    const onSliderChange = function (_event, value, activeThumb) {
        if (activeThumb === 0) {
            setSliderValue([Math.min(value[0], value[1] - 1), value[1]]);
        } else {
            setSliderValue([value[0], Math.max(value[1], value[0] + 1)]);
        }
    };

    return (
        <Paper className={classes.sliderWrapper}>
            <Slider
                step={null}
                min={0}
                max={values.length - 1}
                marks={values}
                value={sliderValue}
                onChange={onSliderChange}
                valueLabelDisplay="off"
                disableSwap
            ></Slider>
            <Box
                sx={{
                    mt: 2,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '10px',
                }}
            >
                <Button onClick={onCancel}>Cancel</Button>
                <Button variant="contained" size="small" onClick={onSubmit}>
                    Done
                </Button>
            </Box>
        </Paper>
    );
};

export default MinMaxSelection;
