import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    viewMode: {
        display: 'flex',
        color: '#2D3D50',
        margin: '0px 0px',
    },
    viewModes: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            color: '#95A5A6',
            cursor: 'pointer',
            marginLeft: '5px',
        },
    },
    selectedView: {
        color: '#3598DB !important',
    },
}));

export { useStyles };
