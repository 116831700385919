import React, { useState } from 'react';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { Action, Role } from '_helpers';
import { Divider } from '@mui/material';
import { PopoverAction } from './components/PopoverAction';
import { prefixUrl } from 'GeminiViewerComponent/_helpers';
import { LATEST_DRAFT } from 'components/ProcedureDesigner/components/FormNodeEditor/_helpers/constant';
import { playerURL } from '_helpers/playerURL';
import { CategoryPopoverAction } from './components/CategoryAction';

function AssetPopup({ object, level, onClose, readOnlyMode, activeUser }) {
    const [openReleasePopover, setOpenReleasePopover] = useState(false);
    const [openCategoryPopover, setOpenCategoryPopover] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleReleasePopoverAction = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenCategoryPopover(false);
        setOpenReleasePopover((previousOpen) => !previousOpen);
    };

    const handleCategoryPopoverAction = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenReleasePopover(false);
        setOpenCategoryPopover((previousOpen) => !previousOpen);
    };

    const canBeOpen = openReleasePopover && Boolean(anchorEl);
    const id = canBeOpen ? 'spring-popper' : undefined;

    const playerUrlStr = playerURL(object?.player_url);

    const handleRelease = (menuItem) => {
        const isDraft = menuItem === LATEST_DRAFT;
        if (isDraft && object?.last_draft_published_url) {
            window.open(
                `${playerUrlStr}?published=${prefixUrl(
                    object?.last_draft_published_url,
                    activeUser.s3_prefix
                )}`,
                '_blank'
            );
        } else {
            window.open(
                `${playerUrlStr}?published=${prefixUrl(
                    object?.last_release_published_url,
                    activeUser.s3_prefix
                )}`,
                '_blank'
            );
        }
        onClose();
    };

    return (
        <React.Fragment>
            <PopupAction
                action={Action.Edit}
                object={object}
                level={level}
                showLabel={true}
                onActionHandled={onClose}
            />
            {!readOnlyMode && (
                <>
                    <Divider />
                    <PopupAction
                        action={Action.Duplicate}
                        object={object}
                        level={level}
                        showLabel={true}
                        onActionHandled={onClose}
                    />
                    <Divider />
                    <PopupAction
                        action={Action.Delete}
                        object={object}
                        level={level}
                        showLabel={true}
                        onActionHandled={onClose}
                    />
                    <Divider />
                    <PopupAction
                        action={Action.Publish}
                        object={object}
                        level={level}
                        showLabel={true}
                        onActionHandled={onClose}
                    />
                    {!Role?.DisableAdminRoles?.includes(activeUser?.role) && (
                        <>
                            <Divider />
                            <PopupAction
                                action={Action.Export}
                                object={object}
                                level={level}
                                showLabel={true}
                                onActionHandled={onClose}
                            />
                            <Divider />
                            <PopupAction
                                action={Action.ExportAsset}
                                object={object}
                                level={level}
                                showLabel={true}
                                onActionHandled={onClose}
                            />
                        </>
                    )}
                </>
            )}
            {object?.last_published_url && (
                <>
                    <Divider />
                    <PopupAction
                        action={Action.Playback}
                        object={{
                            object: object,
                            openPopover: handleReleasePopoverAction,
                        }}
                        level={level}
                        showLabel={true}
                        activeUser={activeUser}
                    />
                    {openReleasePopover && (
                        <PopoverAction
                            anchorEl={anchorEl}
                            id={id}
                            handleRelease={handleRelease}
                            object={object}
                        />
                    )}
                </>
            )}
            <Divider />
            <PopupAction
                action={Action.Category}
                object={{
                    object: object,
                    openPopover: handleCategoryPopoverAction,
                }}
                level={level}
                showLabel={true}
                activeUser={activeUser}
            />
            {openCategoryPopover && (
                <CategoryPopoverAction
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    id={id}
                    object={object}
                />
            )}
        </React.Fragment>
    );
}

export { AssetPopup };
