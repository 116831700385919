import React from 'react';
import { GeneralContentTab } from '../GeneralContentTab/index';
import { LinkTypes } from '_helpers/enumerations';

function AudioTab() {
    return (
        <GeneralContentTab
            contentName={'Audio'}
            linkTypeId={LinkTypes.Audio.id}
            contentTypes={LinkTypes.Audio.contentTypes}
        />
    );
}

export { AudioTab };
