import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import { useSelector } from 'react-redux';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { ReasonsDropdown } from './ReasonsDropdown';
import { getInspectionActionList } from 'GeminiViewerComponent/_features/inspection/inspectionSlice';
import FieldValidationError from '../FieldValidationError';

const ActionsDropdown = ({
    data,
    handleBlur,
    isSubmitAllow,
    fieldValue,
    setFieldValue,
    validationError,
    inspectionActionId,
}) => {
    const [actionsList, setActionsList] = useState([]);
    const [reasonId, setReasonId] = useState('');
    const actionList = useSelector(getInspectionActionList);
    const [selectedValue, setSelectedValue] = useState('');
    let variable = data?.variable;
    if (data?.duplicateCount) {
        let lastIndex = data?.variable.lastIndexOf(`-${data?.duplicateCount}`);
        variable = data?.variable.slice(0, lastIndex);
    }

    const validationMessage = validationError?.['action'];

    // const validationMessage = data?.duplicateCount
    //     ? validationError?.[data.duplicateCount]?.['action']
    //     : validationError?.[0]?.['action'];

    useEffect(() => {
        setActionsList(
            actionList?.find(
                (actionData) =>
                    actionData.inspection_action_list_id === inspectionActionId
            )
        );
    }, [inspectionActionId, actionList]);

    useEffect(() => {
        const actionValue = data?.duplicateCount
            ? fieldValue[variable]?.[data.duplicateCount]?.['action']
            : fieldValue[variable]?.[0]?.['action'] ||
              fieldValue[variable]?.['action'];
        const actionData = actionsList?.inspection_actions?.find(
            (action) => action.display_name === actionValue
        );
        setSelectedValue(actionValue);
        setReasonId(actionData?.inspection_reason_list_id);
    }, [actionsList, fieldValue]);

    const setActions = (e) => {
        const actionData = actionsList.inspection_actions.find(
            (action) => action.display_name === e.target.value
        );
        if (data?.duplicateCount) {
            setFieldValue(`${variable}.${[data?.duplicateCount]}.reason`, '');
            setFieldValue(
                `${variable}.${[data?.duplicateCount]}.field_name`,
                data.label
            );
            setFieldValue(`${variable}.${[data?.duplicateCount]}.status`, 1);
            setFieldValue(
                `${variable}.${[data?.duplicateCount]}.field_id`,
                data?.id
            );
            setFieldValue(
                `${variable}.${[data?.duplicateCount]}.action`,
                actionData.display_name
            );
        } else {
            setFieldValue(`${variable}.${[0]}.reason`, '');
            setFieldValue(`${variable}.${[0]}.field_name`, data.label);
            setFieldValue(`${variable}.${[0]}.status`, 1);
            setFieldValue(`${variable}.${[0]}.field_id`, data?.id);
            setFieldValue(`${variable}.${[0]}.action`, actionData.display_name);
        }
        setReasonId(actionData.inspection_reason_list_id);
    };

    return (
        <>
            <FormControl
                sx={{
                    width: { xs: '100%', sm: 'calc(20% - 10px)' },
                    minWidth: '200px',
                }}
                margin="dense"
                disabled={!isSubmitAllow}
                error={validationError?.action}
            >
                <Field
                    name={
                        data?.duplicateCount
                            ? fieldValue[variable]?.[data.duplicateCount]?.[
                                  'action'
                              ]
                            : fieldValue[variable]?.[0]?.['action']
                    }
                >
                    {() => (
                        <Box display="flex" width="100%">
                            <InputLabel id="actions-list-label">
                                Action
                            </InputLabel>
                            <Select
                                labelId="actions-list-label"
                                id={`Action-${inspectionActionId}`}
                                label="Action"
                                value={selectedValue}
                                onChange={setActions}
                                style={{
                                    width: '100%',
                                }}
                                onBlur={() =>
                                    isSubmitAllow && handleBlur(fieldValue)
                                }
                            >
                                {actionsList &&
                                    actionsList?.inspection_actions?.map(
                                        (actions) => (
                                            <MenuItem
                                                key={
                                                    actions.inspection_action_id
                                                }
                                                value={actions.display_name}
                                            >
                                                {actions.display_name}
                                            </MenuItem>
                                        )
                                    )}
                            </Select>
                        </Box>
                    )}
                </Field>
                {validationMessage && (
                    <FieldValidationError validationError={validationMessage} />
                )}
            </FormControl>
            {reasonId && (
                <ReasonsDropdown
                    data={data}
                    handleBlur={handleBlur}
                    isSubmitAllow={isSubmitAllow}
                    fieldValue={fieldValue}
                    setFieldValue={setFieldValue}
                    validationError={validationError}
                    reasonId={reasonId}
                />
            )}
        </>
    );
};
export { ActionsDropdown };
