import React from 'react';
import { useSelector } from 'react-redux';
import { ItemSchooxTable } from './ItemSchooxTable';
import { SchooxQueryTable } from './SchooxQueryTable';
import { getSchooxVis } from '_features/globals/visibilitySlice';

function SchooxTab() {
    const tabVis = useSelector(getSchooxVis);

    return tabVis ? <ItemSchooxTable /> : <SchooxQueryTable />;
}

export { SchooxTab };
