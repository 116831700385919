import { config } from 'gemini-config';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import { getNetworkService } from 'GeminiViewerComponent/_helpers';

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});

const create = async (url, params, token) => {
    try {
        const response = await networkService.post(
            `${url}/External/completion`,
            { ...params },
            0,
            { 'X-API-KEY': token }
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const completionService = {
    create,
};
