import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Toolbar, Tooltip, Typography } from '@mui/material';

import { Action } from '_helpers';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { useToolbarStyles } from 'GeminiViewerComponent/components/styles';
import { getAsset } from '_features/assets/assetManagerSlice';
import { fetchAllHotspotIcons, getHotspotStatus } from 'shared/hotspotsSlice';
import { loadZone } from 'shared/loadZoneSlice';
import { closePanel } from '_features/common/formSlice';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { ResizableTable } from 'GeminiViewerComponent/components/_Tables/ResizableTable';
import { displayProcedureContentVersion } from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import {
    selectVisualEditZone,
    setVisualEditZone,
} from '_features/common/editZoneSlice';
import {
    closeAllPanel,
    openPanel,
    setEditObjectId,
} from '_features/common/formSlice';
import {
    setDefaultItemPanelSection,
    setViewContentPanel,
} from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import {
    fetchAllNewsFlash,
    getAllNewsFlash,
    getNewsFlashLoadedRowsMap,
    getNewsFlashStatus,
} from 'GeminiViewerComponent/_features/newsFlash/newsFlashSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    resizableLabelHeader,
    resizableLabelCell,
    resizableActionCell,
    resizableCheckCell,
    resizableCheckHeaderCell,
    resizableUsageDisplayCell,
} from 'components';

const EnhancedTableToolbar = (props) => {
    //#region Constants
    const { numSelected, selectedItems, totalItems, setSelected } = props;
    //#endregion Constants

    //#region Hooks
    const classes = useToolbarStyles();
    //#endregion Hooks

    //#region State
    //#endregion State

    //#region Selectors
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    //#endregion Effects

    //#region Methods
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} of {totalItems} selected
                </Typography>
            ) : (
                <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                ></Typography>
            )}

            {numSelected > 0 && (
                <Tooltip title="Publish">
                    <PopupAction
                        action={Action.DeleteArray}
                        object={{
                            newsFlashIds: selectedItems,
                            setNewsFlashIds: setSelected,
                        }}
                        level={'news_flash'}
                        showLabel={false}
                    />
                </Tooltip>
            )}
        </Toolbar>
    );
};

function NewsFlashTable({
    newsFlashes,
    pageInfo,
    searchString,
    sortDirection,
    sortBy,
    handleSort,
    newsFlashType,
    newsFlashPayload,
    readOnly,
}) {
    //#endregion Constants
    const dispatch = useDispatch();
    const headerHeight = 40;
    const rowHeight = 60;
    const tableClasses = tableStyles();
    //#region Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);
    //#endregion State

    //#region Selectors
    const allRows = useSelector(getAllNewsFlash);
    const newsFlashStatus = useSelector(getNewsFlashStatus);
    const loadedRowsMap = useSelector(getNewsFlashLoadedRowsMap);
    const visualEditZone = useSelector(selectVisualEditZone);
    const hotspotStatus = useSelector(getHotspotStatus);
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    useEffect(() => {
        setSelected([]);
        setSelectedAll(false);
    }, [newsFlashType]);
    //#endregion Effects

    //#region Methods
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    let prevSelectedAll = usePrevious(selectedAll);

    const isSelected = (id) => selected.indexOf(id) !== -1;

    if (prevSelectedAll && selectedAll) {
        if (selected.length !== allRows?.length) {
            setSelected(allRows.map((row) => row.news_flash_id));
            setSelectedAll(true);
        }
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = allRows.map((row) => row.news_flash_id);
            setSelected(newSelecteds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    const handleClick = (event, id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else {
            newSelected = selected.filter((selectedId) => selectedId !== id);
        }
        setSelected(newSelected);
        setSelectedAll(newSelected.length === allRows?.length);
    };

    const handleRowClick = async ({ rowData }) => {
        if (rowData?.news_flash_id) {
            dispatch(closeAllPanel());
            await dispatch(
                setEditObjectId({
                    formKey: `newsFlashForm`,
                    object_id: rowData?.news_flash_id,
                })
            );
            dispatch(
                openPanel({ formKey: `newsFlashForm`, formAction: 'Edit' })
            );
        }
    };

    async function loadMoreRows({ startIndex, stopIndex }) {
        if (newsFlashStatus !== LoadingStatus.Loading) {
            await dispatch(
                fetchAllNewsFlash({
                    ...newsFlashPayload,
                    reset: false,
                    startIndex: startIndex,
                    stopIndex: stopIndex,
                    searchString: searchString,
                })
            );
        }
    }

    const clearLastViewerProperties = async () => {
        await dispatch(setVisualEditZone(false));
        await dispatch(setViewContentPanel(false));
        await dispatch(setDefaultItemPanelSection(0));
        await dispatch(displayProcedureContentVersion(null));
        await dispatch(
            closePanel({
                formKey: 'zoneForm',
                formAction: 'Edit',
                isContentPanelOpen: false,
            })
        );
        await dispatch(
            closePanel({
                formKey: 'itemForm',
                formAction: 'Edit',
                isContentPanelOpen: false,
            })
        );
        await dispatch(
            closePanel({
                formKey: 'contentForm',
                formAction: 'Edit',
                isContentPanelOpen: false,
            })
        );
        if (window.geminiViewManager) {
            window.geminiViewManager.updateView();
        }
    };

    const handleZoneClick = async (event, rowData, assetId) => {
        event.stopPropagation();
        dispatch(activateLoading());
        if (visualEditZone === true) {
            await clearLastViewerProperties();
        }
        dispatch(
            closePanel({
                formKey: 'contentForm',
            })
        );
        await dispatch(getAsset(assetId));
        if (hotspotStatus === LoadingStatus.Idle) {
            await dispatch(fetchAllHotspotIcons());
        }
        await dispatch(loadZone({ zoneId: rowData?.id, refresh: false }));
        await dispatch(setVisualEditZone(true));
        dispatch(deactivateLoading());
    };
    //#endregion Methods

    const columns = [
        {
            accessorKey: 'news_flash_id',
            size: 50,
            minSize: 50,
            enableResizing: false,
            label: 'Select',
            padding: 'checkbox',
            cellLevel: 'news_flash',
            numSelected: selected.length,
            rowCount: allRows.length,
            handleClick: handleClick,
            handleSelectAllClick: handleSelectAllClick,
            isSelected: isSelected,
            header: (info) =>
                resizableCheckHeaderCell(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableCheckCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'news_flash_id',
            size: 50,
            minSize: 50,
            enableResizing: false,
            padding: 'none',
            label: '',
            actionCellLevel: 'news_flash',
            targetPopup: 'newsFlash',
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableActionCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'display_name',
            flexGrow: 1,
            size: 250,
            minSize: 200,
            label: 'Display Name',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'news_flash_display_option_display_name',
            flexGrow: 1,
            size: 250,
            minSize: 200,
            label: 'Display Option Name',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'zone_usage',
            flexGrow: 1,
            size: 80,
            minSize: 80,
            enableResizing: false,
            label: 'Usage',
            padding: 'normal',
            displayDataKey: 'display_name',
            displayAggregateKey: 'asset_id',
            displayAggregateText: 'asset_display_name',
            onClick: handleZoneClick,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableUsageDisplayCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'start_date',
            flexGrow: 1,
            size: 250,
            minSize: 200,
            label: 'Start Date',
            padding: 'normal',
            transform: (data) =>
                data ? moment(data).local().format('MM/DD/YYYY hh:mm A') : '',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'end_date',
            flexGrow: 1,
            size: 250,
            minSize: 200,
            label: 'End Date',
            padding: 'normal',
            transform: (data) =>
                data ? moment(data).local().format('MM/DD/YYYY hh:mm A') : '',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
    ];

    //#region Render time calcs
    //#endregion Render time calcs

    return (
        <Box>
            <EnhancedTableToolbar
                numSelected={selected.length}
                selectedItems={selected}
                totalItems={pageInfo.TotalCount}
                setSelected={setSelected}
            />
            <Box
                style={{
                    height: 'calc(100vh - 250px)',
                    minWidth: '500px',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                }}
            >
                <ResizableTable
                    initialRows={newsFlashes}
                    allRows={allRows}
                    totalRowCount={pageInfo.TotalCount}
                    loadedRowsMap={loadedRowsMap}
                    loadMoreRows={loadMoreRows}
                    columns={columns}
                    cellClassName={tableClasses.flexContainer}
                    className={tableClasses.table}
                    rowClassName={clsx(
                        tableClasses.flexContainer,
                        tableClasses.tableRowHover
                    )}
                    dispatch={dispatch}
                    headerHeight={headerHeight}
                    rowHeight={rowHeight}
                    onRowClick={handleRowClick}
                    selected={selected}
                />
            </Box>
        </Box>
    );
}

export { NewsFlashTable };
