import { useEffect } from 'react';
import {
    getConfigFields,
    selectConfigFields,
    selectConfigFieldsLoadingStatus,
} from '_features/configFields/configFieldsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { useValidationTypes } from './useValidationTypes';

export function useConfigFields() {
    const dispatch = useDispatch();

    const {
        validationTypesLoadingStatus,
        validationTypes,
        validationRawTypes,
    } = useValidationTypes();
    const configFieldsLoadingStatus = useSelector(
        selectConfigFieldsLoadingStatus
    );
    const configFields = useSelector(selectConfigFields);

    useEffect(() => {
        const fetchConfigFields = async () => {
            console.log('Getting configFields');
            dispatch(getConfigFields());
        };
        if (
            validationTypesLoadingStatus === LoadingStatus.Loaded &&
            configFieldsLoadingStatus == LoadingStatus.Idle
        ) {
            fetchConfigFields();
        }
    }, [validationTypesLoadingStatus]);

    return {
        configFieldsLoadingStatus,
        validationTypesLoadingStatus,
        configFields,
        validationTypes,
        validationRawTypes,
    };
}
