import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { partGroupService } from '_features/_services';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';

const initialState = {
    partGroups: [],
    partGroupStatus: LoadingStatus.Idle,
    partGroupPageInfo: {},
    displayPartGroup: {},
    loadedPartGroupMap: {},
};

export const fetchPartGroupPage = createAsyncThunk(
    'partGroup/fetchPartGroupPage',
    async (params = {}, { getState }) =>
        await partGroupService.getPartGroup(
            params.searchString,
            params.sort,
            getState().accounts.activeUser.s3_prefix
        )
);

export const fetchPartGroupById = createAsyncThunk(
    'partGroup/fetchPartGroupById',
    async (partGroupId, { getState }) => {
        const response = await partGroupService.getById(
            partGroupId,
            getState().accounts.activeUser.s3_prefix
        );
        response.id = partGroupId;
        return response;
    }
);

export const createPartGroup = createAsyncThunk(
    'partGroup/createPartGroup',
    async (params, { getState }) =>
        await partGroupService.create(
            params,
            getState().accounts.activeUser.s3_prefix
        )
);

export const updatePartGroup = createAsyncThunk(
    'partGroup/updatePartGroup',
    async (params, { getState }) =>
        await partGroupService.update(
            params,
            getState().accounts.activeUser.s3_prefix
        )
);

export const deletePartGroup = createAsyncThunk(
    'partGroup/deletePartGroup',
    async ({ part_group_id }) => {
        const response = await partGroupService.delete(part_group_id);
        return response;
    }
);

const partGroupSlice = createSlice({
    name: 'partGroups',
    initialState,
    reducers: {
        resetPartGroupState: (state) => initialState,
    },
    extraReducers: {
        [fetchPartGroupPage.pending]: (state, action) => {
            state.partGroupStatus = LoadingStatus.Loading;
        },
        [fetchPartGroupPage.fulfilled]: (state, action) => {
            state.partGroupPageInfo = action.payload.pageInfo;
            state.partGroups = action.payload.partGroups;
            state.partGroupStatus = LoadingStatus.Loaded;
        },
        [fetchPartGroupPage.rejected]: (state, action) => {
            state.partGroupStatus = LoadingStatus.Failed;
        },
        [createPartGroup.fulfilled]: (state, action) => {
            state.partGroupStatus = LoadingStatus.Idle;
        },
        [updatePartGroup.fulfilled]: (state, action) => {
            state.partGroupStatus = LoadingStatus.Idle;
        },
        [deletePartGroup.fulfilled]: (state, action) => {
            state.partGroupStatus = LoadingStatus.Idle;
        },
        [fetchPartGroupById.fulfilled]: (state, action) => {
            state.displayPartGroup = action.payload;
        },
    },
});

export const selectPartGroup = (state) => state.partGroup.partGroups;
export const getPartGroupStatus = (state) => state.partGroup.partGroupStatus;
export const getPartGroupPageInfo = (state) =>
    state.partGroup.partGroupPageInfo;

export const { resetPartGroupState } = partGroupSlice.actions;

export default partGroupSlice.reducer;
