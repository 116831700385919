import React, { useRef } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MdClear } from 'react-icons/md';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
    Autocomplete,
    Box,
    Checkbox,
    Chip,
    IconButton,
    MenuItem,
    Select,
    TableCell,
    TextField,
    Typography,
    createFilterOptions,
} from '@mui/material';

import { MultiSelectDoneButton } from 'GeminiViewerComponent/components/MultiSelectDoneButton';
import { getDirtyFlag } from '_features/common/formSlice';
const filter = createFilterOptions();

const parseBool = (value) => {
    return value === 'true' || value === '1';
};

const ControlledTexField = (props) => {
    const { value, onChange, ...rest } = props;
    const ref = useRef(null);

    return (
        <TextField
            inputProps={{ ref: ref }}
            value={value}
            onChange={onChange}
            {...rest}
            style={{
                minWidth: '160px',
            }}
        />
    );
};

const FieldValidationError = ({ children }) => {
    return (
        <div
            style={{
                color: '#E84C3C',
                margin: '4px',
                fontSize: '0.75rem',
                textAlign: 'left',
                fontFamily: 'Open Sans',
                fontWeight: 400,
                lineHeight: 1.66,
            }}
        >
            {children}
        </div>
    );
};

export const FormTableCell = ({
    data,
    column,
    fieldId,
    handleChange,
    selectedClientId,
    getRowIcon,
}) => {
    const { values } = useFormikContext();
    const isDirty = useSelector(getDirtyFlag);
    const columnKey = column.updateOnSubmit ? `${column.key}_temp` : column.key;
    let fieldError;
    let config = column.config;

    if (
        column.title === 'Name' &&
        (typeof data?.config_field_id === 'string' ||
            typeof data?.validation_type_id === 'string')
    ) {
        config = { ...config, type: 'text' };
    }

    if (typeof config === 'function') {
        config = column.config(data);
    }

    if (!config) {
        console.log('We have a problem');
    }

    let className = column.class ?? null;
    if (typeof column.class === 'function') {
        className = column.class(data);
    }
    if (
        Array.isArray(data?.hideColumns) &&
        data?.hideColumns.length > 0 &&
        data.hideColumns.includes(column.key)
    ) {
        return <TableCell width={column.width}></TableCell>;
    }
    if (data?.fieldError?.[column.key]) {
        fieldError = (
            <FieldValidationError>
                {data?.fieldError?.[column.key]}
            </FieldValidationError>
        );
    } else {
        fieldError = null;
    }

    const filterOption = (options, params) => {
        if (column.key === 'options_array') {
            const filtered = filter(options, params);
            if (params?.inputValue !== '') {
                const isExisting = options.some(
                    (option) => params?.inputValue === option
                );
                if (params?.inputValue !== '' && !isExisting) {
                    filtered.push(`Add "${params.inputValue}"`);
                }
            }
            return filtered;
        } else {
            return options;
        }
    };
    const pattern = /^Add "|"/g;

    switch (config.type) {
        case 'label':
            return (
                <TableCell
                    style={{
                        maxWidth: '230px',
                        minWidth: '230px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            marginLeft: `${
                                selectedClientId !== 0 &&
                                selectedClientId !== undefined &&
                                column.key === 'display_name' &&
                                typeof data.config_value_id === 'string'
                                    ? '27px'
                                    : '0px'
                            }`,
                            alignItems: 'center',
                        }}
                    >
                        {selectedClientId !== 0 &&
                            selectedClientId !== undefined &&
                            column.key === 'display_name' &&
                            typeof data.config_value_id !== 'string' &&
                            getRowIcon(data?.field_source)}
                        <Typography
                            title={values?.[fieldId]?.[columnKey]}
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            padding="4px"
                        >
                            {values?.[fieldId]?.[columnKey] || data[columnKey]}
                        </Typography>
                    </div>
                </TableCell>
            );
        case 'checkbox':
            return (
                <TableCell>
                    <Checkbox
                        className={className}
                        size="small"
                        checked={
                            typeof values?.[fieldId]?.[columnKey] === 'string'
                                ? parseBool(
                                      values?.[fieldId]?.[columnKey] ||
                                          data[columnKey]
                                  )
                                : values?.[fieldId]?.[columnKey] ||
                                  data[columnKey]
                        }
                        onChange={(e) =>
                            handleChange(
                                columnKey,
                                data,
                                e.target.checked,
                                column,
                                fieldId
                            )
                        }
                        readOnly={column.readOnly}
                        disabled={column.readOnly || !isDirty}
                    />
                    {fieldError}
                </TableCell>
            );
        case 'text':
            return (
                <TableCell width={column.width}>
                    <div
                        style={{
                            display: 'flex',
                            marginLeft: `${
                                selectedClientId !== 0 &&
                                selectedClientId !== undefined &&
                                column.key === 'display_name' &&
                                typeof data.config_value_id === 'string'
                                    ? '27px'
                                    : '0px'
                            }`,
                        }}
                    >
                        {selectedClientId !== 0 &&
                            selectedClientId !== undefined &&
                            column.key === 'display_name' &&
                            typeof data.config_value_id !== 'string' &&
                            getRowIcon(data?.field_source)}
                        <ControlledTexField
                            size="small"
                            className={className}
                            value={
                                values?.[fieldId]?.[columnKey] ||
                                data[columnKey]
                            }
                            onChange={(e) =>
                                handleChange(
                                    columnKey,
                                    data,
                                    e.target.value,
                                    column,
                                    fieldId
                                )
                            }
                            readOnly={column.readOnly}
                            disabled={column.readOnly || !isDirty}
                        />
                        {fieldError}
                    </div>
                </TableCell>
            );
        case 'select':
            return (
                <TableCell width={column.width}>
                    <Select
                        className={className}
                        size="small"
                        labelId="type-select-label"
                        id="type-select"
                        value={
                            values?.[fieldId]?.[columnKey] || data[columnKey]
                        }
                        onChange={(e) =>
                            handleChange(
                                columnKey,
                                data,
                                e.target.value,
                                column,
                                fieldId
                            )
                        }
                        readOnly={column.readOnly}
                        disabled={column.readOnly || !isDirty}
                    >
                        {config.options.map((option, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={
                                        config.options_value_key
                                            ? option[config.options_value_key]
                                            : option
                                    }
                                >
                                    {config.options_display_key
                                        ? option[config.options_display_key]
                                        : option}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {fieldError}
                </TableCell>
            );
        case 'autocomplete':
            return (
                <TableCell width={column.width} style={{ minWidth: '160px' }}>
                    <Autocomplete
                        size="small"
                        multiple
                        className={className}
                        sx={{ rows: '3' }}
                        id="tags-filled"
                        options={data[columnKey]}
                        value={data[column.value]}
                        freeSolo
                        renderTags={(value, getTagProps) => {
                            return value.map((option, index) => (
                                <Chip
                                    key={index}
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                />
                            ));
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(e, newValue) => {
                            if (column.key === 'options_array') {
                                const updatedValue = newValue.map((str) =>
                                    str.replace(pattern, '')
                                );
                                handleChange(
                                    columnKey,
                                    data,
                                    updatedValue,
                                    column,
                                    fieldId
                                );
                            } else {
                                handleChange(
                                    columnKey,
                                    data,
                                    newValue,
                                    column,
                                    fieldId
                                );
                            }
                        }}
                        filterOptions={(options, params) => {
                            return filterOption(options, params);
                        }}
                        readOnly={column.readOnly}
                        disabled={column.readOnly || !isDirty}
                        PaperComponent={MultiSelectDoneButton}
                    />
                    {fieldError}
                </TableCell>
            );
        case 'date':
            return (
                <TableCell width={column.width}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            className={className}
                            name={`datepicker-${columnKey}`}
                            value={
                                values?.[fieldId]?.[columnKey] ||
                                data[columnKey]
                            }
                            disabled={column.readOnly || !isDirty}
                            onChange={(date, keyboardInputValue) => {
                                if (date !== null) {
                                    date = date.startOf('day');
                                }
                                handleChange(
                                    columnKey,
                                    data,
                                    date,
                                    column,
                                    fieldId
                                );
                            }}
                            renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                            }) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <TextField
                                        size="small"
                                        fullWidth
                                        ref={inputRef}
                                        {...inputProps}
                                        style={{ minWidth: '160px' }}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    onClick={(e) =>
                                                        handleChange(
                                                            columnKey,
                                                            data,
                                                            null,
                                                            column,
                                                            fieldId
                                                        )
                                                    }
                                                >
                                                    <MdClear className="react-icon" />
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                    {InputProps?.endAdornment}
                                </Box>
                            )}
                        />
                    </LocalizationProvider>
                    {fieldError}
                </TableCell>
            );
        default:
            return (
                <TableCell className={className} width={column.width}>
                    <div>default</div>
                </TableCell>
            );
    }
};
