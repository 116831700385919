import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { makeStyles } from '@mui/styles';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { MdAdd, MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    TextField as MuiText,
    Tooltip,
} from '@mui/material';

import ButtonField from '../ButtonField';
import { makeFormStyles } from 'forms/styles';
import DirtyProcedure from '../DirtyProcedure';
import { FormTextField } from 'components/TextField';
import { useTinyMCEInit } from 'hooks/useTinyMCEInit';
import SaveNodeDataDialog from '../SaveNodeDataDialog';
import { TinyMCEEditor } from 'components/TinyMCEEditor';
import { DisplayAnswer } from 'GeminiViewerComponent/_helpers';
import { setProcedureDirty } from '_features/procedures/proceduresSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    CommonNodeFormFIelds,
    ExcludeFromNavigationField,
    NodeCategoryField,
} from '../CommonNodeFormFIelds';

const makeNodeFormStyles = makeStyles(() => ({
    container: {
        padding: '20px',
        width: '100%',
        height: '100%',
    },
    form: {
        display: 'flex',
        width: '100%',
        height: 'auto',
        padding: '10px',
        border: '1px solid',
    },
    formBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        width: '100%',
    },
    submitButtonRow: {
        marginTop: 'auto',
        alignSelf: 'end',
        display: 'flex',
        gap: '5px',
    },
    contentRow: {
        cursor: 'pointer',
    },
    dialogPaper: {
        minHeight: '100%',
        maxHeight: '100%',
    },
    addButtonIcon: {
        padding: '3px !important',
        borderRadius: '25px !important',
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
    },
}));

const ResultNodeForm = ({
    nodes,
    node,
    onCancel,
    onSubmit,
    openSaveDataDialog,
    setOpenSaveDataDialog,
    procedureCategories,
}) => {
    const dispatch = useDispatch();
    const init = useTinyMCEInit();
    const theme = useSelector(selectActiveTheme);
    const nodeFormStyles = makeNodeFormStyles(theme);
    const commonFormStyles = makeFormStyles(theme);
    const [completionNode, setCompletionNode] = useState(null);

    // eslint-disable-next-line no-unused-vars
    const [formValues, setFormValues] = useState({
        ...node,
        signature_titles: node?.signature_titles ? node.signature_titles : [],
        answer_display_type: node?.answer_display_type
            ? Array.isArray(node?.answer_display_type)
                ? node?.answer_display_type?.map((answer_type) =>
                      Object.values(DisplayAnswer).find(
                          (answer) =>
                              answer.value === answer_type?.value ||
                              answer.value === answer_type
                      )
                  )
                : Object.values(DisplayAnswer).filter(
                      (answer) => answer.value === node?.answer_display_type
                  )
            : Object.values(DisplayAnswer).filter(
                  (answer) => answer.value === DisplayAnswer.failed_only.value
              ),
    });

    useEffect(() => {
        setFormValues({
            ...node,
            signature_titles: node?.signature_titles
                ? node.signature_titles
                : [],
            procedureCategories: procedureCategories,
            answer_display_type: node?.answer_display_type
                ? Array.isArray(node?.answer_display_type)
                    ? node?.answer_display_type?.map((answer_type) =>
                          Object.values(DisplayAnswer).find(
                              (answer) =>
                                  answer.value === answer_type?.value ||
                                  answer.value === answer_type
                          )
                      )
                    : Object.values(DisplayAnswer).filter(
                          (answer) => answer.value === node?.answer_display_type
                      )
                : Object.values(DisplayAnswer).filter(
                      (answer) =>
                          answer.value === DisplayAnswer.failed_only.value
                  ),
        });
    }, [node]);

    useEffect(() => {
        return () => {
            setFormValues({});
            dispatch(setProcedureDirty(false));
        };
    }, []);

    const handleRemoveOptionButton = (id, values, setFieldValue) => {
        let currOptionButtons = [...values.option_buttons];
        currOptionButtons.splice(id, 1);
        setFieldValue('option_buttons', currOptionButtons);
    };

    const handleAddOptionButton = (option_buttons, setFieldValue) => {
        if (!Array.isArray(option_buttons)) {
            option_buttons = [];
        }
        const optButtons = [...option_buttons];
        optButtons.push({
            action: { id: '', type: 'node' },
            button_id: Number(optButtons?.length),
            button_text: '',
        });
        setFieldValue('option_buttons', optButtons);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result, option_buttons, setFieldValue) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const orderedNodes = reorder(
            option_buttons,
            result.source.index,
            result.destination.index
        );

        setFieldValue('option_buttons', orderedNodes);
    };

    const selectCompletionNode = (value, setFieldValue) => {
        if (
            nodes.find((node) => node?.completion === true) &&
            nodes.find((node) => node?.completion === true).id !== node.id
        ) {
            setCompletionNode(nodes.find((node) => node?.completion === true));
        } else {
            setCompletionNode(null);
            setFieldValue('completion', value);
        }
    };

    return (
        <div className={nodeFormStyles.container}>
            <div className={nodeFormStyles.form}>
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={Yup.object({})}
                    onSubmit={async (values, { setSubmitting }) => {
                        values = {
                            ...values,
                            answer_display_type:
                                values.answer_display_type?.map(
                                    (answer) => answer.value
                                ),
                        };
                        onSubmit(values);
                    }}
                >
                    {({ values, setFieldValue, isSubmitting, dirty }) => (
                        <Form className={nodeFormStyles.formBody}>
                            <DirtyProcedure />
                            <CommonNodeFormFIelds />
                            <NodeCategoryField />
                            <FormControl fullWidth>
                                <Autocomplete
                                    limitTags={2}
                                    multiple
                                    variant="outline"
                                    id="answer_display_type"
                                    options={Object.values(DisplayAnswer)}
                                    onChange={(event, newInputValue) => {
                                        setFieldValue(
                                            'answer_display_type',
                                            newInputValue
                                        );
                                    }}
                                    disableCloseOnSelect
                                    value={values.answer_display_type}
                                    getOptionLabel={(option) =>
                                        option.display_name
                                    }
                                    renderOption={(
                                        props,
                                        option,
                                        { selected }
                                    ) => (
                                        <li
                                            {...props}
                                            style={{
                                                padding: '0',
                                            }}
                                        >
                                            <Checkbox
                                                icon={
                                                    <MdCheckBoxOutlineBlank className="react-icon" />
                                                }
                                                checkedIcon={
                                                    <MdCheckBox className="react-icon" />
                                                }
                                                style={{
                                                    marginRight: 8,
                                                }}
                                                checked={selected}
                                            />
                                            {option.display_name}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <MuiText
                                            {...params}
                                            variant="outlined"
                                            label="Display Answers"
                                            placeholder="Display Answers"
                                        />
                                    )}
                                />
                            </FormControl>
                            <FormTextField
                                component={CheckboxWithLabel}
                                Label={{
                                    label: 'Completion (submit button)',
                                }}
                                type="checkbox"
                                checked={Boolean(values.completion)}
                                onChange={(evt) =>
                                    selectCompletionNode(
                                        evt.target.checked,
                                        setFieldValue
                                    )
                                }
                            />
                            {completionNode && (
                                <Box className={commonFormStyles.inputErr}>
                                    Node {completionNode?.id} -{' '}
                                    {completionNode?.title} is already
                                    completion node.
                                </Box>
                            )}
                            <ExcludeFromNavigationField />
                            {/* <FormControl fullWidth>
                                <FormControlLabel
                                    label="Signatures required?"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={values.signature_required}
                                            onChange={(evt) =>
                                                setFieldValue(
                                                    'signature_required',
                                                    evt.target.checked
                                                )
                                            }
                                        />
                                    }
                                />
                            </FormControl> */}
                            {values.signature_required === true && (
                                <FormControl fullWidth>
                                    <fieldset
                                        style={{
                                            border: '1px solid #cccccc',
                                            borderRadius: '5px',
                                            paddingLeft: '15px',
                                        }}
                                    >
                                        <legend
                                            style={{
                                                padding: '3px',
                                                fontSize: '14px',
                                                color: '#6d7178',
                                            }}
                                        >
                                            Signatures
                                        </legend>
                                        <div style={{ margin: '5px auto' }}>
                                            <Tooltip title="Add signature title">
                                                <IconButton
                                                    className={
                                                        nodeFormStyles.addButtonIcon
                                                    }
                                                    onClick={() => {
                                                        let signatures =
                                                            Array.isArray(
                                                                values?.signature_titles
                                                            ) &&
                                                            values
                                                                ?.signature_titles
                                                                ?.length > 0
                                                                ? [
                                                                      ...values.signature_titles,
                                                                  ]
                                                                : [];
                                                        signatures[
                                                            signatures.length
                                                        ] = '';
                                                        setFieldValue(
                                                            'signature_titles',
                                                            signatures
                                                        );
                                                    }}
                                                    size="small"
                                                >
                                                    <MdAdd className="react-icon" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        {Array.isArray(
                                            values?.signature_titles
                                        ) &&
                                            values?.signature_titles?.length >
                                                0 &&
                                            values?.signature_titles?.map(
                                                (title, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                margin: '5px auto',
                                                            }}
                                                        >
                                                            <Grid
                                                                container
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    gap: '10px',
                                                                    marginBottom:
                                                                        '6px',
                                                                }}
                                                            >
                                                                <Grid
                                                                    display={
                                                                        'flex'
                                                                    }
                                                                    alignItems="center"
                                                                    justifyContent={
                                                                        'center'
                                                                    }
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={10}
                                                                    lg={10}
                                                                >
                                                                    <MuiText
                                                                        fullWidth
                                                                        margin="dense"
                                                                        value={
                                                                            title
                                                                        }
                                                                        onChange={(
                                                                            event
                                                                        ) => {
                                                                            let signatures =
                                                                                [
                                                                                    ...(values?.signature_titles ??
                                                                                        ''),
                                                                                ];
                                                                            signatures[
                                                                                index
                                                                            ] =
                                                                                event.target.value;
                                                                            setFieldValue(
                                                                                'signature_titles',
                                                                                signatures
                                                                            );
                                                                        }}
                                                                        label={`Signature Title ${
                                                                            index +
                                                                            1
                                                                        }`}
                                                                        type="text"
                                                                        variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    display={
                                                                        'flex'
                                                                    }
                                                                    alignItems="center"
                                                                    justifyContent={
                                                                        'center'
                                                                    }
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={1}
                                                                    lg={1}
                                                                >
                                                                    <Button
                                                                        variant="outlined"
                                                                        onClick={() => {
                                                                            let signatures =
                                                                                [
                                                                                    ...(values?.signature_titles ??
                                                                                        ''),
                                                                                ];
                                                                            signatures.splice(
                                                                                index,
                                                                                1
                                                                            );
                                                                            setFieldValue(
                                                                                'signature_titles',
                                                                                signatures
                                                                            );
                                                                        }}
                                                                    >
                                                                        Remove
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    );
                                                }
                                            )}
                                    </fieldset>
                                </FormControl>
                            )}
                            <FormControl fullWidth>
                                <Field
                                    name="content"
                                    style={{ marginTop: '10px' }}
                                >
                                    {({ field, form: { setFieldValue } }) => (
                                        <TinyMCEEditor
                                            id="content"
                                            tinymceScriptSrc={
                                                process.env.PUBLIC_URL +
                                                '/tinymce/tinymce.min.js'
                                            }
                                            init={{
                                                ...init,
                                            }}
                                            textareaName="content"
                                            onBlur={field.onBlur}
                                            onEditorChange={(content) => {
                                                setFieldValue(
                                                    field.name,
                                                    content
                                                );
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                </Field>
                            </FormControl>
                            <FormControl fullWidth>
                                <br />
                                <label>Buttons</label> <br />
                                <div>
                                    Options{' '}
                                    <Tooltip title="Add Buttons">
                                        <IconButton
                                            className={
                                                nodeFormStyles.addButtonIcon
                                            }
                                            onClick={() =>
                                                handleAddOptionButton(
                                                    values?.option_buttons,
                                                    setFieldValue
                                                )
                                            }
                                            size="small"
                                        >
                                            <MdAdd className="react-icon" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <DragDropContext
                                    onDragEnd={(result) =>
                                        onDragEnd(
                                            result,
                                            values.option_buttons,
                                            setFieldValue
                                        )
                                    }
                                >
                                    <Droppable droppableId="droppable-form-nodes">
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                style={{
                                                    margin: '40px 0',
                                                }}
                                            >
                                                {values?.option_buttons?.map(
                                                    (button, idx) => {
                                                        return (
                                                            <ButtonField
                                                                button={button}
                                                                key={`ButtonField${idx}`}
                                                                idx={idx}
                                                                node={node}
                                                                nodes={nodes}
                                                                values={values}
                                                                setFieldValue={
                                                                    setFieldValue
                                                                }
                                                                provided={
                                                                    provided
                                                                }
                                                                handleRemoveOptionButton={(
                                                                    id
                                                                ) =>
                                                                    handleRemoveOptionButton(
                                                                        id,
                                                                        values,
                                                                        setFieldValue
                                                                    )
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </FormControl>
                            {openSaveDataDialog && (
                                <SaveNodeDataDialog
                                    openSaveDataDialog={openSaveDataDialog}
                                    setOpenSaveDataDialog={
                                        setOpenSaveDataDialog
                                    }
                                    isSubmitting={isSubmitting}
                                    onCancel={onCancel}
                                />
                            )}
                            <div className={nodeFormStyles.submitButtonRow}>
                                <button
                                    className={commonFormStyles.cancel}
                                    type="button"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        if (dirty) {
                                            setOpenSaveDataDialog(true);
                                        } else {
                                            onCancel();
                                        }
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={commonFormStyles.submit}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};
export default ResultNodeForm;
