import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';

const contentManagerStyles = makeStyles((theme) => ({
    alignCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            // marginLeft: theme.spacing(3),
            width: 'auto',
            margin: '0px',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#BDC3C7',
    },
    inputRoot: {
        color: 'inherit',
        border: '1px solid #BDC3C7',
        borderRadius: '5px',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    actionBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },
    actionBarRight: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
    },
    actionButton: {
        margin: '15px',
        background: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        fontWeight: 600,
        '&:hover': {
            background: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(90%)',
        },
    },
    content: {
        flexGrow: 1,
        padding: '25px 50px',
    },
    pin: {
        display: 'flex',
        cursor: 'pointer',
        '& svg': {
            height: '20px',
            width: '20px',
        },
        '& svg:hover': {
            filter: 'brightness(50%)',
        },
    },
    isPinned: {
        color: '#E84C3C',
        display: 'flex',
    },
    advancedSearchContainer: {
        backgroundColor: '#ecf0f1',
        borderRadius: '2px',
        padding: '15px',
    },
    contentAddButton: {
        padding: '10px !important',
        borderRadius: '25px !important',
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
    },
    sortContainer: {
        marginTop: '16px',
    },
    selectRoot: {
        '& .MuiSelect-root': {
            padding: '13px 14px',
        },
    },
    contentActionBar: {
        width: '100%',
        marginTop: '12px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        alignItems: 'start',
    },
    contentActionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    filterFieldset: {
        padding: '7px',
        border: '1px solid #cccccc',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'left',
        gap: '10px',
        alignItems: 'center',
    },
    filterFieldsetFields: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
    },
    filterFieldsetLegend: {
        marginLeft: '10px',
        padding: '5px',
        fontSize: '13px',
        color: '#6d7178',
    },
    ExcludeGlobalFieldset: {
        marginTop: '-12px',
        border: '1px solid #cccccc',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
    },
    ExcludeGlobalFieldsetLegend: {
        margin: 'auto 8px',
        padding: '5px',
        fontSize: '13px',
        color: '#6d7178',
    },
}));

export default contentManagerStyles;
