import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { LocationTable } from './LocationTable';
import {
    fetchLocationsPage,
    getLocationPageInfo,
    getLocationStatus,
    resetLocationState,
    selectLocations,
} from '_features/tools/locationSlice';

const LocationTab = () => {
    const dispatch = useDispatch();
    const ref = useRef(false);
    const locations = useSelector(selectLocations);
    const locationStatus = useSelector(getLocationStatus);
    const locationsPageInfo = useSelector(getLocationPageInfo);
    const [locationPayload, setLocationPayload] = useState({});
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');

    const getAllLocations = async (payload) => {
        dispatch(activateLoading());
        await dispatch(fetchLocationsPage(payload));
        dispatch(deactivateLoading());
    };

    useEffect(() => {
        if (ref.current === true) {
            getAllLocations({ ...locationPayload });
        }
    }, [locationPayload]);

    useEffect(() => {
        return () => {
            dispatch(resetLocationState());
        };
    }, []);

    useEffect(() => {
        if (locationStatus === LoadingStatus.Idle) {
            getAllLocations({ ...locationPayload });
        }
        ref.current = true;
    }, [locationStatus]);

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        setLocationPayload({
            ...locationPayload,
            sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
        });
    };

    return (
        <LocationTable
            locations={locations}
            pageInfo={locationsPageInfo}
            locationPayload={locationPayload}
            setLocationPayload={setLocationPayload}
            sortDirection={sortDirection}
            sortBy={sortBy}
            handleSort={(_sortBy, _sortDirection) =>
                setSorting(_sortBy, _sortDirection)
            }
        />
    );
};

export { LocationTab };
