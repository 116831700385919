import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
        },
        default: {
            backgroundColor: '#2D3D50',
            color: 'white',
            fontWeight: '600',
        },
        success: {
            backgroundColor: '#2D3D50 !important',
            textTransform: 'capitalize',
            padding: '15px !important',
            fontWeight: '600',
            '& svg': {
                fill: '#18BC9B !important',
                height: '30px',
                width: '30px',
            },
        },
        error: {
            backgroundColor: '#2D3D50 !important',
            padding: '15px !important',
            fontWeight: '600',
            '& svg': {
                fill: '#E84C3C !important',
                height: '30px',
                width: '30px',
            },
        },
        warning: { backgroundColor: 'green' },
        info: { backgroundColor: 'yellow' },
        unsaveDataMain: {
            pointerEvents: 'none',
            backgroundColor: 'rgb(0 0 0 / 10%)',
            '&::before': {
                content: '',
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                zIndex: 0,
            },
            '& .ReactVirtualized__Table__Grid': {
                backgroundColor: '#E5E5E5 !important',
            },
        },
    })
);

export { useStyles };
