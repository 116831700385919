import { config } from 'gemini-config';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import { getNetworkService } from 'GeminiViewerComponent/_helpers';

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});

const baseUrl = `${config.apiUrl}/audience`;

const getAll = async (
    searchString = '',
    page = 1,
    pageSize = 10,
    sort = 'display_name'
) => {
    const filters = [searchString ? `display_name@=*${searchString}` : ''];
    const params = {
        filters: filters.join(','),
        sorts: sort,
        start_index: page,
        end_index: pageSize,
    };
    const user = JSON.parse(localStorage.getItem('user'));
    const query = new URLSearchParams(params);
    try {
        const response = await networkService.get(
            `${baseUrl}/getallbyclientid/${user.client_id}/?${query}`
        );
        const audiences = response.data;
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, audiences: audiences };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getById = async (id) => {
    try {
        const response = await networkService.get(`${baseUrl}/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const update = async (id, params) => {
    try {
        const response = await networkService.put(`${baseUrl}/${id}`, params);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const addRule = async (params) => {
    try {
        const response = await networkService.post(
            `${baseUrl}/addRule`,
            params
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const removeRule = async (params) => {
    try {
        const response = await networkService.post(
            `${baseUrl}/removeRule`,
            params
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const create = async (params) => {
    const { client_id } = JSON.parse(localStorage.getItem('user'));
    params.client_id = client_id;
    try {
        const response = await networkService.post(baseUrl, params);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _delete = async (id) => {
    try {
        const response = await networkService.delete(`${baseUrl}/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const deleteArray = async (ids) => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        const query = ids.join('&ids=');
        const response = await networkService.delete(
            `${baseUrl}/?client_id=${user.client_id}&ids=${query}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getAudienceUsageById = async (tagId) => {
    try {
        const response = await networkService.get(`${baseUrl}/usage/${tagId}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const audienceService = {
    addRule,
    getById,
    getAll,
    create,
    removeRule,
    update,
    delete: _delete,
    getAudienceUsageById,
    deleteArray,
};
