import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    tagBarContainer: {
        position: 'absolute',
        left: '0',
        right: '0',
        maxWidth: '80%',
        backgroundColor: (props) => props?.colors?.main_background_color,
        color: 'white',
        width: 'max-content',
        border: '2.5px solid #c4c4c4',
        borderBottomWidth: '0',
        cursor: 'grabbing',
        marginLeft: 'auto',
        marginRight: 'auto',
        opacity: (props) => props?.opacity?.main_background_opacity / 100,
        zIndex: 4,
    },
    tagsIcons: {
        color: (props) => props.colors.icon_active_color,
    },
    tagContainerListDiv: {
        display: 'flex',
        height: '25px',
        gap: '8px',
        overflow: 'auto',
    },
    tagContainerTagDiv: {
        width: 'max-content',
        cursor: 'pointer',
        padding: '3px 5px',
        borderRadius: '8px',
        color: '#000000',
        userSelect: 'none',
        opacity: (props) => props?.opacity?.tag_background_opacity / 100,
    },
    tagContainerTagNameDiv: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        userSelect: 'none',
        opacity: (props) => props?.opacity?.tag_foreground_opacity / 100,
    },
    tagButtons: {
        display: 'flex',
        flexWrap: 'nowrap',
        minWidth: '204px',
        marginTop: '5px',
    },
    tagSelectButtons: {
        flex: 1,
        flexBasis: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        minWidth: '115px',
    },
    tagContainerTagNameToggleIconDiv: {
        flex: 1,
        flexBasis: '100%',
        minWidth: '115px',
        justifyContent: 'center',
    },
    tagContainerToggleIconDiv: {
        flex: 1,
        flexBasis: '100%',
        textAlign: 'center',
    },
}));

export { useStyles };
