import { makeStyles } from '@mui/styles';

const linkButtonStyles = makeStyles(() => ({
    linkContainer: {
        borderRadius: '5px',
        fontSize: '16px',
        background: (props) => props?.colors?.link_button_background_color,
        color: (props) => props?.colors?.link_button_text_color,
        border: '1px solid #34485e38',
        marginBottom: '5px',
        padding: '10px',
        '&:hover': {
            background: (props) => props?.colors?.link_button_background_color,
            color: (props) => props?.colors?.link_button_text_color,
            filter: 'brightness(85%)',
            cursor: 'pointer',
        },
        display: 'flex',
        flexDirection: 'column',
    },
    description: {
        fontSize: '13px',
        marginTop: '3px',
        fontWeight: '500',
    },
}));

export { linkButtonStyles };
