import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { useValidationTypes } from 'hooks/useValidationTypes';
import {
    createNewValidationType,
    selectValidationTypesErrorMessage,
    selectValidationTypesSavingStatus,
    updateValidationTypes,
} from '_features/validationTypes/validationTypesSlice';
import { useStyles } from './styles';
import { FormTable } from 'components/Forms/FormTable';
import { Alert, AlertTitle } from '@mui/material';
import { getDirtyFlag, setIsDirty } from '_features/common/formSlice';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

const ValidationTypesManager = () => {
    //#region Hooks

    const theme = useSelector(selectActiveTheme);
    const styles = useStyles(theme);
    const dispatch = useDispatch();
    const getDirty = useSelector(getDirtyFlag);

    const {
        validationTypesLoadingStatus,
        validationTypes,
        validationRawTypes,
    } = useValidationTypes([]);

    useEffect(() => {
        setFormValidationTypes(validationTypes);
    }, [validationTypes]);

    //#endregion Hooks

    //#region State

    const [formValidationTypes, setFormValidationTypes] =
        useState(validationTypes);
    const [updatedFormValidationTypes, setUpdatedFormValidationTypes] =
        useState(validationTypes);
    const [isDirty, setDirty] = useState(false);

    useEffect(() => {
        if (getDirty === true) {
            setDirty(true);
        }
    }, [getDirty]);
    //#endregion State

    //#region Selectors
    useEffect(() => {
        if (
            Array.isArray(updatedFormValidationTypes) &&
            updatedFormValidationTypes.length > 0
        ) {
            const hideColumnsForValidation = {
                0: ['options_array', 'min_value', 'max_value', 'max_length'],
                1: ['max_length', 'options_array'],
                2: ['options_array', 'min_value', 'max_value'],
                3: ['options_array', 'min_value', 'max_value', 'max_length'],
                4: ['max_length', 'min_value', 'max_value'],
            };
            let currentValues = updatedFormValidationTypes.map(
                (validationType) => {
                    validationType = {
                        ...validationType,
                        hideColumns:
                            hideColumnsForValidation[validationType.type_id],
                    };
                    return validationType;
                }
            );
            setFormValidationTypes(currentValues);
        } else {
            setFormValidationTypes([]);
        }
    }, [updatedFormValidationTypes]);

    const validationTypesSavingStatus = useSelector(
        selectValidationTypesSavingStatus
    );
    const errorMessage = useSelector(selectValidationTypesErrorMessage);
    //#endregion Selectors

    //#region Refs
    //#endregion Refs
    const handleSubmit = async (values) => {
        await dispatch(updateValidationTypes({ values: values }));
        setUpdatedFormValidationTypes(formValidationTypes);
        dispatch(setIsDirty(false));
        setDirty(false);
    };

    const handleCancel = () => {
        setDirty(false);
        setUpdatedFormValidationTypes(validationTypes);
        dispatch(setIsDirty(false));
    };
    //#region Constants
    const formValues = useMemo(() => {
        const values = {};
        formValidationTypes.forEach((value) => {
            values[value?.validation_type_id] = value;
        });
        return values;
    }, [formValidationTypes]);

    const formTableDefinition = {
        data: formValidationTypes,
        createMethod: createNewValidationType,
        submitMethod: handleSubmit,
        handleCancel: handleCancel,
        setStateMethod: setUpdatedFormValidationTypes,
        selectFormState: formValidationTypes,
        key: 'validation_type_id',
        class: styles.formTable,
        allowDelete: true,
        formValues: formValues,
        columns: [
            {
                config: { type: 'label' },
                title: 'Name',
                class: styles.nameColumn,
                key: 'display_name',
                width: '25%',
                validation: {},
            },
            {
                config: {
                    type: 'select',
                    options: validationRawTypes,
                    options_key: 'validation_raw_type_id',
                    options_value_key: 'validation_raw_type_id',
                    options_display_key: 'display_name',
                },
                title: 'Type',
                class: styles.typeColumn,
                key: 'type_id',
                validation: {},
            },
            {
                config: { type: 'text' },
                title: 'Max Length',
                class: styles.typeColumn,
                key: 'max_length',
                validation: {},
            },
            {
                config: { type: 'text' },
                title: 'Min Value',
                class: styles.typeColumn,
                key: 'min_value',
                validation: {},
            },
            {
                config: { type: 'text' },
                title: 'Max Value',
                class: styles.typeColumn,
                key: 'max_value',
                validation: {},
            },
            {
                config: { type: 'autocomplete' },
                title: 'Options',
                class: styles.typeColumn,
                key: 'options_array',
                value: 'options_array',
                validation: {},
            },
        ],
    };

    //#endregion Constants

    //#region Render time calcs

    const isBusy =
        validationTypesLoadingStatus === LoadingStatus.Loading ||
        validationTypesLoadingStatus === LoadingStatus.Idle ||
        validationTypesSavingStatus === SavingStatus.Saving;

    //#endregion Render time calcs

    //#region Effects
    //#endregion Effects

    //#region Methods

    //#endregion Methods

    //#region Render

    return isBusy ? (
        <LoadingLogo />
    ) : (
        <>
            {errorMessage && (
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {errorMessage}
                </Alert>
            )}
            <div
                className={`${styles.configContainer} ${
                    isDirty ? styles.unsaveData : ''
                }`}
            >
                <FormTable tableDefinition={formTableDefinition} />
            </div>
        </>
    );
    //#endregion Render
};

export { ValidationTypesManager };
