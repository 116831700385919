import { useSceneStyles } from './styles';
import { useStyles } from '../HotspotSelector/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import { HotspotSelector } from '../HotspotSelector';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';

const SceneControls = ({
    showAddInfoHotspot,
    hotspotIcons,
    isNotUser,
    addInfoHotspotLabel,
    handleSceneInfoClick,
    asset,
    setSelectedHotspotIcons,
    selectedHotspotIcons,
    defaultItemHotspot,
    itemHotspots,
    handleMultipleHotspot,
    isMultipleHotspotEnabled,
    handleSceneNavClick,
    defaultNavHotspot,
    navigationHotspots,
    handleSceneInitViewClick,
    orbitMode = false,
}) => {
    const theme = useSelector(selectActiveTheme);
    const config = useSelector(getConfig);
    const classes = useSceneStyles(theme);
    const hotspotBtnClasses = useStyles(theme);

    return (
        <div className={classes.sceneControls}>
            {showAddInfoHotspot === true &&
                hotspotIcons.length > 0 &&
                isNotUser && (
                    <HotspotSelector
                        buttonLabel={addInfoHotspotLabel}
                        buttonAction={handleSceneInfoClick}
                        srcImgId={asset.item_hotspot_icon_id}
                        scrImgAlt={'Add Scene Info Hotspot'}
                        selectedHotspotIconId={selectedHotspotIcons.itemIconId}
                        updateIconForSave={(hotspotIconId) => {
                            setSelectedHotspotIcons({
                                ...selectedHotspotIcons,
                                itemIconId: hotspotIconId,
                            });
                        }}
                        defaultHotspot={defaultItemHotspot}
                        hotspotIcons={itemHotspots}
                        enableMultipleHotspot
                        handleMultipleHotspot={handleMultipleHotspot}
                        isMultipleHotspotEnabled={isMultipleHotspotEnabled}
                        selectTooltip="Select item icon"
                        placeTooltip="Place item"
                        placeMultipleTooltip="Place multiple items"
                    />
                )}
            {hotspotIcons.length > 0 && isNotUser && (
                <HotspotSelector
                    buttonLabel={'Navigation'}
                    buttonAction={handleSceneNavClick}
                    srcImgId={asset.nav_hotspot_icon_id}
                    scrImgAlt={'Add Scene Navigation Hotspot'}
                    selectedHotspotIconId={selectedHotspotIcons.navLinkIconId}
                    updateIconForSave={(hotspotIconId) => {
                        setSelectedHotspotIcons({
                            ...selectedHotspotIcons,
                            navLinkIconId: hotspotIconId,
                        });
                    }}
                    defaultHotspot={defaultNavHotspot}
                    hotspotIcons={navigationHotspots}
                    selectTooltip="Select navigation icon"
                    placeTooltip="Place navigation link"
                    placeMultipleTooltip="Place multiple navigation links"
                />
            )}
            {orbitMode === false && (
                <Tooltip title="Set the initial location and zoom level for this zone">
                    <div
                        className={hotspotBtnClasses.sceneContainer}
                        onClick={handleSceneInitViewClick}
                    >
                        <div className={hotspotBtnClasses.initialViewIconWrap}>
                            <img
                                src={config.paths.SCENE_INITVIEW_ICON_URL}
                                alt="Set Scene Initial View"
                                className={hotspotBtnClasses.infoIcon}
                            ></img>
                        </div>
                        <div className={hotspotBtnClasses.sceneButton}>
                            Initial View
                        </div>
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export { SceneControls };
