import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { MdAdd } from 'react-icons/md';

import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import SelectField from '../SelectField';
import { makeFieldFormStyles } from '../styles';
import FieldValidationError from 'GeminiViewerComponent/components/Procedure/components/ProcedureFormNode/FormFields/FieldValidationError';

const SelectFormField = ({ values, setFieldValue, validationError }) => {
    const theme = useSelector(selectActiveTheme);
    const nodeFormStyles = makeFieldFormStyles(theme);

    const handleRemoveSelect = (id, values, setFieldValue) => {
        let currOptionButtons = [...values.options];
        currOptionButtons.splice(id, 1);
        setFieldValue('options', currOptionButtons);
    };

    const handleAddSelect = (options, setFieldValue) => {
        var uuid = uuidv4();
        if (!Array.isArray(options)) {
            options = [];
        }
        const optionsArray = [...options];
        optionsArray.push({
            id: uuid,
            label: '',
            type: '',
        });
        setFieldValue('options', optionsArray);
    };

    return (
        <div className={nodeFormStyles.radioGroupsContainer}>
            <div className={nodeFormStyles.radioGroupsText}>Select</div>
            {values.options?.map((_field, idx) => {
                return (
                    <SelectField
                        key={`Select${idx}`}
                        idx={idx}
                        values={values}
                        setFieldValue={setFieldValue}
                        handleRemoveSelect={(id) =>
                            handleRemoveSelect(id, values, setFieldValue)
                        }
                        validationError={validationError}
                    />
                );
            })}
            {!!validationError.options && (
                <FieldValidationError
                    validationError={validationError.options}
                />
            )}
            <div>
                <Tooltip title="Add Buttons">
                    <IconButton
                        className={nodeFormStyles.addButtonIcon}
                        onClick={() =>
                            handleAddSelect(values?.options, setFieldValue)
                        }
                        size="small"
                    >
                        <MdAdd className="react-icon" />
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
};

export default SelectFormField;
