import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdClose, MdAdd } from 'react-icons/md';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    InputLabel,
    FormControl,
    MenuItem,
    Select,
    IconButton,
    Typography,
    Tooltip,
} from '@mui/material';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { useStyles } from '../../scenes/Asset/components/styles.jsx';
import { modalStyles } from 'GeminiViewerComponent/components/styles';
import {
    closeAllPanel,
    openPanel,
    isFormOpen,
} from '_features/common/formSlice';

const NavIconEditDialog = ({
    open,
    handleClose,
    targetZoneDefaultValue,
    targetZoneOnChange,
    targetZoneData,
    iconsDefaultValue,
    iconsOnChange,
    iconsData,
}) => {
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);
    const modalClasses = modalStyles(theme);
    const dispatch = useDispatch();
    const [selectedZoneId, setSelectedZoneId] = useState(
        targetZoneDefaultValue
    );
    const [selectedAssetId, setSelectedAssetId] = useState(
        Array.isArray(targetZoneData) && targetZoneData.length > 0
            ? targetZoneData.find(
                  (zone) => zone.zone_id == targetZoneDefaultValue
              )?.asset_id ?? '0'
            : '0'
    );
    const [previousZoneId] = useState(selectedZoneId);
    const [previousAssetId, setPreviousAssetId] = useState(selectedAssetId);
    const [selectedIconId, setSelectedIconId] = useState(iconsDefaultValue);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [hidden, setHidden] = useState(false);
    const formOpen = useSelector(isFormOpen);

    const targetZonesByAssetId = useMemo(() => {
        if (Array.isArray(targetZoneData) && targetZoneData.length > 0) {
            let sortedZones = targetZoneData?.sort((a, b) => {
                if (a?.display_name < b?.display_name) return -1;
                if (a?.display_name > b?.display_name) return 1;
                return 0;
            });

            return Object.groupBy(sortedZones, ({ asset_id }) => asset_id ?? 0);
        }
        return {};
    }, [targetZoneData]);

    const assetOptions = useMemo(() => {
        let options = [];

        if (Object.keys(targetZonesByAssetId).length > 1) {
            Object.keys(targetZonesByAssetId).forEach((key) => {
                if (key === '0') {
                    options.push({ assetId: key, assetName: 'Parent' });
                } else {
                    let value = targetZonesByAssetId[key];
                    options.push({
                        assetId: key,
                        assetName: value[0].asset_name,
                    });
                }
            });
        }
        return options;
    }, [targetZonesByAssetId]);

    let selectedZones = targetZonesByAssetId[selectedAssetId];

    const handleTargetZoneIdChange = useCallback(
        (value) => {
            setPreviousAssetId(selectedAssetId);
            setSelectedZoneId(value);
            targetZoneOnChange(value);
        },
        [selectedAssetId, targetZoneOnChange]
    );

    const handleCreateZone = (confirmed = false) => {
        if (+selectedAssetId !== 0) {
            if (!confirmed) {
                setIsConfirmDialogOpen(true);
                return;
            }
            setSelectedAssetId(0);
        }
        setHidden(true);
        dispatch(closeAllPanel());
        dispatch(
            openPanel({
                formKey: 'zoneForm',
                formAction: 'Create',
                clearEditId: true,
            })
        );
    };

    useEffect(() => {
        if (!formOpen) {
            setHidden(false);
        }
    }, [formOpen]);

    useEffect(() => {
        if (open) {
            const mostRecent = targetZoneData.toSorted(
                (a, b) => b.zone_id - a.zone_id
            )[0];
            if (mostRecent) {
                handleTargetZoneIdChange(mostRecent.zone_id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedZones]);

    return (
        <>
            <Dialog
                open={open && !hidden}
                onClose={() => {
                    if (selectedZoneId == '') {
                        setSelectedZoneId(previousZoneId);
                        setSelectedAssetId(previousAssetId);
                    }
                    handleClose();
                }}
            >
                <DialogTitle
                    sx={{
                        backgroundColor: '#78706e',
                        color: '#000',
                        fontSize: '20px',
                        fontWeight: '600',
                        padding: '10px 24px',
                    }}
                >
                    Edit Navigation Icon
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            if (selectedZoneId == '') {
                                setSelectedZoneId(previousZoneId);
                                setSelectedAssetId(previousAssetId);
                            }
                            handleClose();
                        }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 6,
                            color: '#000',
                        }}
                        size="large"
                    >
                        <MdClose className="react-icon" />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ width: '400px', marginTop: '10px' }}>
                    {assetOptions.length > 0 && (
                        <>
                            <FormControl sx={{ margin: '8px 0' }} fullWidth>
                                <InputLabel id="target-zone-label">
                                    Target Asset
                                </InputLabel>
                                <Select
                                    labelId="target-asset-label"
                                    id="target-asset-id"
                                    label="Target Asset"
                                    value={selectedAssetId}
                                    onChange={(element) => {
                                        setSelectedAssetId(
                                            element.target.value
                                        );
                                        if (
                                            !targetZonesByAssetId[
                                                element.target.value
                                            ][selectedZoneId]
                                        ) {
                                            setSelectedZoneId('');
                                        }
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 48 * 5.5 + 8,
                                                width: 'auto',
                                            },
                                        },
                                    }}
                                >
                                    {assetOptions.map((asset) => (
                                        <MenuItem
                                            key={asset?.assetId}
                                            value={asset?.assetId}
                                        >
                                            <Typography
                                                style={{
                                                    width: '100%',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {asset?.assetName}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </>
                    )}
                    <Box display="flex" alignItems="center" gap={2}>
                        <FormControl sx={{ margin: '8px 0' }} fullWidth>
                            <InputLabel id="target-zone-label">
                                Target Zone
                            </InputLabel>
                            <Select
                                labelId="target-zone-label"
                                id="target-zone-id"
                                label="Target Zone"
                                value={selectedZoneId}
                                onChange={(element) => {
                                    if (element.target.value) {
                                        handleTargetZoneIdChange(
                                            parseInt(element.target.value)
                                        );
                                    }
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 48 * 5.5 + 8,
                                            width: 'auto',
                                        },
                                    },
                                }}
                            >
                                {selectedZones?.length > 0 &&
                                    selectedZones.map((item) => (
                                        <MenuItem
                                            key={item?.zone_id}
                                            value={item?.zone_id}
                                        >
                                            <Typography
                                                style={{
                                                    width: '100%',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {item?.display_name}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <Tooltip title="New Zone">
                            <IconButton
                                className={classes.addNewIcon}
                                onClick={() => handleCreateZone()}
                                size="large"
                            >
                                <MdAdd className="react-icon" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <FormControl fullWidth sx={{ margin: '8px 0 0 0' }}>
                        <InputLabel id="icon-label">Icon</InputLabel>
                        <Select
                            labelId="icon-label"
                            id="icon-id"
                            label="Icon"
                            value={selectedIconId}
                            onChange={(evt) => {
                                setSelectedIconId(evt.target.value);
                                iconsOnChange(evt);
                            }}
                        >
                            {iconsData?.length > 0 &&
                                iconsData.map(
                                    (item, index) =>
                                        item.is_default && (
                                            <MenuItem
                                                key={index}
                                                value={0}
                                                style={{
                                                    borderBottom:
                                                        '1px solid #424242',
                                                    background: '#c7c7c7db',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            marginRight: '15px',
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                item?.image_url
                                                            }
                                                            alt={
                                                                item?.display_name
                                                            }
                                                            style={{
                                                                width: '30px',
                                                            }}
                                                        />
                                                    </div>
                                                    <div>Default</div>
                                                </div>
                                            </MenuItem>
                                        )
                                )}

                            {iconsData.length > 0 &&
                                iconsData.map((item) => (
                                    <MenuItem
                                        key={item?.hotspot_icon_id}
                                        value={item?.hotspot_icon_id}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    marginRight: '15px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <img
                                                    src={item?.image_url}
                                                    alt={item?.display_name}
                                                    style={{
                                                        width: '30px',
                                                    }}
                                                />
                                            </div>
                                            <div>{item?.display_name}</div>
                                        </div>
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </DialogContent>
            </Dialog>
            <Dialog
                open={isConfirmDialogOpen}
                onClose={() => setIsConfirmDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText sx={{ p: 2 }}>
                        <Typography className={modalClasses.actionMessage}>
                            The new zone will be created in the parent asset.
                            <br />
                            Do you want to continue?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button onClick={() => setIsConfirmDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setIsConfirmDialogOpen(false);
                            handleCreateZone(true);
                        }}
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export { NavIconEditDialog };
