import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    form: {
        width: '100%',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '10px',
        alignItems: 'center',
        borderBottom: '2px solid #7E8C8D',
    },
    label: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#7E8C8D',
        paddingLeft: '20px',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '10px',
        alignItems: 'center',
        flexGrow: '1',
    },
    materialInput: {
        width: '90%',
        fontSize: '18px',
        color: '#2D3D50',
    },
    button: {
        marginTop: '5px',
        background: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        border: '0px',
        width: '100px',
        padding: '10px',
        borderRadius: '5px',
        fontSize: '14px',
        textTransform: 'uppercase',
        cursor: 'pointer',
        variant: 'outlined',
        '&:disabled': {
            background: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(50%)',
        },
        '&:hover': {
            background: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(90%)',
        },
    },
    removeButton: {
        backgroundColor: 'white',
        border: '2px solid #3598DB',
        color: '#3598DB',
        width: '100px',
        padding: '10px',
        borderRadius: '5px',
        fontSize: '14px',
        textTransform: 'uppercase',
        cursor: 'pointer',
        variant: 'outlined',
        '&:disabled': {
            filter: 'brightness(50%)',
        },
        '&:hover': {
            filter: 'brightness(90%)',
        },
    },
}));
