import { makeStyles } from '@mui/styles';
import login_image from 'assets/gemini_splash_image.jpg';

const aboutInfoStyles = makeStyles((theme) => ({
    aboutWrapper: {
        width: '100%',
        margin: '0 auto',
        minHeight: 'calc(100vh - 244px)',
        display: 'flex',
        alignItems: 'center',
    },
    aboutBackgroundContainer: {
        width: '100%',
        background: `no-repeat url(${login_image})`,
        backgroundSize: 'cover',
        height: '100%',
    },
    aboutContainer: {
        justifyContent: 'center',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0 30px 15px #c4c7c7',
        border: '1px solid #e1e1e1',
        borderRadius: '1rem',
        overflow: 'hidden',
    },
    imgResponsive: {
        maxWidth: '100%',
        width: 'auto',
        height: 'auto',
        display: 'block',
    },
    borderRounded: {
        borderRadius: '1rem',
        overflow: 'hidden',
    },
    aboutContent: {
        color: '#757575',
    },
    aboutModalWrapper: {
        '& .MuiPaper-root': {
            overflow: 'visible',
        },
    },
}));

export { aboutInfoStyles };
