import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    getValidationRawTypes,
    selectValidationRawTypes,
    selectValidationRawTypesLoadingStatus,
} from '_features/validationRawTypes/validationRawTypesSlice';

export function useValidationRawTypes() {
    const dispatch = useDispatch();

    const loadingStatus = useSelector(selectValidationRawTypesLoadingStatus);
    const validationRawTypes = useSelector(selectValidationRawTypes);

    useEffect(() => {
        const fetchValidationRawTypes = async () => {
            console.log('Getting validation raw types');
            dispatch(getValidationRawTypes());
        };
        if (loadingStatus == LoadingStatus.Idle) {
            fetchValidationRawTypes();
        }
    }, []);

    return { loadingStatus, validationRawTypes };
}
