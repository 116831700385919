import React from 'react';
import { LinkTypes } from '_helpers/enumerations';
import { GeneralContentTab } from '../GeneralContentTab';

function ProcedureTab() {
    return (
        <GeneralContentTab
            contentName={'Procedure'}
            linkTypeId={LinkTypes.Procedure.id}
            contentTypes={LinkTypes.Procedure.contentTypes}
        />
    );
}

export { ProcedureTab };
