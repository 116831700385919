import React, { useState } from 'react';
import { ItemLinkTable } from 'components';
import { LinkTypes } from '_helpers/enumerations';
import { Button } from '@mui/material';
import { FormActions } from '_helpers/form-action';
import { tabStyles } from 'GeminiViewerComponent/components/styles';
import { TechULinkForm } from 'forms/TechULinkForm';

function TechUTab() {
    const [toggleLinkView, setToggleLinkView] = useState(false);
    const classes = tabStyles();

    return (
        <div className={classes.tabContent}>
            {!toggleLinkView ? (
                <>
                    <div className={classes.tabButtons}>
                        <Button
                            className={classes.tabButton}
                            onClick={() => setToggleLinkView(!toggleLinkView)}
                            variant="outlined"
                            color="primary"
                            disableElevation
                        >
                            New TechU Link
                        </Button>
                    </div>
                    <div className={classes.linksHeader}>Links</div>
                    <div className={classes.tabBottom}>
                        <ItemLinkTable
                            linkTypeId={LinkTypes.TechU.id}
                            editAllow={false}
                        />
                    </div>
                </>
            ) : (
                <>
                    {toggleLinkView && (
                        <TechULinkForm
                            formAction={FormActions.Create}
                            linkTypeId={LinkTypes.TechU.id}
                            tabView={true}
                            setToggleView={setToggleLinkView}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export { TechUTab };
