import React from 'react';
import { InputBase, MenuItem, Button, Badge, Paper } from '@mui/material';
import { MdArrowDropDown, MdArrowDropUp, MdSearch } from 'react-icons/md';

import { useStyles } from './styles';

const SearchFilter = ({
    filterBadge,
    onChange,
    clearFilters,
    addFilters,
    children,
    searchResults,
    searchItemSelected,
}) => {
    const [toggleFilter, setToggleFilter] = React.useState(false);
    const [searchString, setSearchString] = React.useState('');
    const classes = useStyles();

    const handleToggleFilter = () => {
        setToggleFilter(!toggleFilter);
    };

    const handleSearchClick = (item) => {
        searchItemSelected(item);
        setSearchString('');
    };

    const handleChange = (event) => {
        onChange(event);
        setSearchString(event.target.value);
    };

    return (
        <div className={classes.searchFilterContainer}>
            <div className={classes.searchContainer}>
                <div className={classes.searchIcon}>
                    <MdSearch className="react-icon" />
                </div>
                <InputBase
                    placeholder="Search…"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    onChange={handleChange}
                    value={searchString}
                    inputProps={{ type: 'search' }}
                />
                <div
                    className={classes.filterContent}
                    onClick={handleToggleFilter}
                >
                    <Badge
                        className={filterBadge ? classes.filterBadge : ''}
                        sx={{ ml: 'auto' }}
                        badgeContent={filterBadge}
                        invisible={!filterBadge}
                    >
                        <div className={classes.filterText}>FILTER</div>
                    </Badge>
                    {toggleFilter ? (
                        <MdArrowDropUp className="react-icon" />
                    ) : (
                        <MdArrowDropDown className="react-icon" />
                    )}
                </div>
            </div>
            <div className={classes.filterContainer}>
                {toggleFilter && (
                    <Paper id="simple-menu" onClick={() => handleToggleFilter}>
                        {children}
                        <MenuItem
                            className={classes.filterButtons}
                            disableRipple
                        >
                            <div className={classes.filterClearButton}>
                                <Button
                                    className={classes.filterButtonPadding}
                                    onClick={() => {
                                        clearFilters();
                                        setToggleFilter(false);
                                    }}
                                >
                                    Clear
                                </Button>
                            </div>
                            <div>
                                <Button
                                    className={classes.filterButtonPadding}
                                    onClick={() => {
                                        addFilters();
                                        setToggleFilter(false);
                                    }}
                                    color="secondary"
                                    variant="contained"
                                >
                                    Apply Filters
                                </Button>
                            </div>
                        </MenuItem>
                    </Paper>
                )}
            </div>
            {searchResults && (
                <Paper className={classes.searchResultsContainer}>
                    <div className={classes.searchResultsHeader}>
                        Search Results
                    </div>
                    <div className={classes.searchResultsItemsContainer}>
                        {searchResults.items.map((item) => {
                            return (
                                <div
                                    key={`${item.zone_id}${item.item_id}`}
                                    className={classes.searchResultsItem}
                                    onClick={() => handleSearchClick(item)}
                                >
                                    <span
                                        className={
                                            classes.searchResultsItemZone
                                        }
                                    >
                                        {item.zone_name}
                                    </span>{' '}
                                    - {item.display_name}
                                </div>
                            );
                        })}
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default SearchFilter;
