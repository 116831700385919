import React from 'react';
import { LinkTypes } from '_helpers/enumerations';
import { GeneralContentTab } from '../GeneralContentTab';

function LearningTab() {
    return (
        <GeneralContentTab
            contentName={'Learning'}
            linkTypeId={LinkTypes.Learning.id}
            contentTypes={LinkTypes.Learning.contentTypes}
        />
    );
}

export { LearningTab };
