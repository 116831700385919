import { makeStyles } from '@mui/styles';

const resizableTableStyles = makeStyles({
    container: {
        margin: '0 auto',
        width: '100%',
        overflow: 'auto',
        height: '100%',
        borderRadius: '5px',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            height: '6px',
            width: '6px',
            backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#a4a4a4',
        },
    },
    resizableTable: {
        borderCollapse: 'collapse',
        borderSpacing: 0,
        fontFamily: 'arial, sans-serif',
        width: '100%',
        border: '1px solid lightgray',
        borderTop: 'none',
    },
    resizableTableTr: {
        borderBottom: '1px solid lightgray',
    },
    resizableTableTh: {
        verticalAlign: 'middle',
        borderRight: '1px solid darkgray',
    },
    resizableTableTd: {
        verticalAlign: 'middle',
    },
    resizableTableThead: {
        borderCollapse: 'separate',
        position: 'sticky',
        top: 0,
        backgroundColor: '#ecf0f1',
        '& th:last-child': {
            borderRight: 'none',
        },
    },
});

export { resizableTableStyles };
