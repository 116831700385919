import {
    convertSecondstoTime,
    timeToSeconds,
} from 'GeminiViewerComponent/_helpers/helper';
import { LinkTypes } from '_helpers';
import moment from 'moment';

const validateChapterTimeFormat = (errors, values) => {
    values.chapters?.map(async (chapter, idx) => {
        // chapter title validation
        if (chapter.title.trim()?.length === 0) {
            errors = {
                ...errors,
                chapters: {
                    ...errors?.chapters,
                    [idx]: {
                        ...errors?.chapters?.[idx],
                        title: 'Required',
                    },
                },
            };
        }

        // No start_time then end_time is required
        if (!chapter.start_time) {
            if (!chapter.end_time) {
                errors = {
                    ...errors,
                    chapters: {
                        ...errors?.chapters,
                        [idx]: {
                            ...errors?.chapters?.[idx],
                            end_time: 'Required',
                        },
                    },
                };
            }
        }

        // No end_time then start_time is required
        if (!chapter.end_time) {
            if (!chapter.start_time) {
                errors = {
                    ...errors,
                    chapters: {
                        ...errors?.chapters,
                        [idx]: {
                            ...errors?.chapters?.[idx],
                            start_time: 'Required',
                        },
                    },
                };
                errors = {
                    ...errors,
                    chapters: {
                        ...errors?.chapters,
                        [idx]: {
                            ...errors?.chapters?.[idx],
                            start_time: 'Required',
                        },
                    },
                };
            }
        }

        if (
            moment(
                chapter.start_time?._i || chapter.start_time,
                'HH:mm:ss.SSS'
            ).format('HH:mm:ss.SSS') === 'Invalid date'
        ) {
            // start_time invalid format validation
            if (chapter.start_time?._i || chapter.start_time) {
                const [hours, minutes, seconds] =
                    chapter.start_time?._i?.split(':').map(Number) ||
                    chapter.start_time.split(':').map(Number);

                if (hours > 23) {
                    errors = {
                        ...errors,
                        chapters: {
                            ...errors?.chapters,
                            [idx]: {
                                ...errors?.chapters?.[idx],
                                start_time: 'Hours is Invalid',
                            },
                        },
                    };
                } else if (minutes > 59) {
                    errors = {
                        ...errors,
                        chapters: {
                            ...errors?.chapters,
                            [idx]: {
                                ...errors?.chapters?.[idx],
                                start_time: 'Minutes is Invalid',
                            },
                        },
                    };
                } else if (seconds > 59) {
                    errors = {
                        ...errors,
                        chapters: {
                            ...errors?.chapters,
                            [idx]: {
                                ...errors?.chapters?.[idx],
                                start_time: 'Seconds is Invalid',
                            },
                        },
                    };
                }
            }
        } else {
            // start_time always less then video duration
            if (
                timeToSeconds(
                    moment(
                        chapter.start_time?._i || chapter.start_time,
                        'HH:mm:ss.SSS'
                    ).format('HH:mm:ss.SSS')
                ) >= timeToSeconds(values?.videoDuration)
            ) {
                errors = {
                    ...errors,
                    chapters: {
                        ...errors?.chapters,
                        [idx]: {
                            ...errors?.chapters?.[idx],
                            start_time: 'Start time exceeds video duration.',
                        },
                    },
                };
            }
        }

        if (
            moment(
                chapter.end_time?._i || chapter.end_time,
                'HH:mm:ss.SSS'
            ).format('HH:mm:ss.SSS') === 'Invalid date'
        ) {
            // end_time invalid format validation
            if (chapter.end_time?._i || chapter.end_time) {
                const [hours, minutes, seconds] =
                    chapter.end_time?._i?.split(':').map(Number) ||
                    chapter.end_time.split(':').map(Number);

                if (hours > 23) {
                    errors = {
                        ...errors,
                        chapters: {
                            ...errors?.chapters,
                            [idx]: {
                                ...errors?.chapters?.[idx],
                                end_time: 'Hours is Invalid',
                            },
                        },
                    };
                } else if (minutes > 59) {
                    errors = {
                        ...errors,
                        chapters: {
                            ...errors?.chapters,
                            [idx]: {
                                ...errors?.chapters?.[idx],
                                end_time: 'Minutes is Invalid',
                            },
                        },
                    };
                } else if (seconds > 59) {
                    errors = {
                        ...errors,
                        chapters: {
                            ...errors?.chapters,
                            [idx]: {
                                ...errors?.chapters?.[idx],
                                end_time: 'Seconds is Invalid',
                            },
                        },
                    };
                }
            }
        } else {
            // end_time always less then video duration
            if (
                timeToSeconds(
                    moment(
                        chapter.end_time?._i || chapter.end_time,
                        'HH:mm:ss.SSS'
                    ).format('HH:mm:ss.SSS')
                ) >= timeToSeconds(values?.videoDuration)
            ) {
                errors = {
                    ...errors,
                    chapters: {
                        ...errors?.chapters,
                        [idx]: {
                            ...errors?.chapters?.[idx],
                            end_time: 'End time exceeds video duration.',
                        },
                    },
                };
            }
        }

        // start_time always less then end_time validation
        if (
            moment(
                chapter.start_time?._i || chapter.start_time,
                'HH:mm:ss.SSS'
            ).format('HH:mm:ss.SSS') !== 'Invalid date' &&
            moment(
                chapter.end_time?._i || chapter.end_time,
                'HH:mm:ss.SSS'
            ).format('HH:mm:ss.SSS') !== 'Invalid date'
        ) {
            if (
                timeToSeconds(
                    moment(
                        chapter.start_time?._i || chapter.start_time,
                        'HH:mm:ss.SSS'
                    ).format('HH:mm:ss.SSS')
                ) >=
                timeToSeconds(
                    moment(
                        chapter.end_time?._i || chapter.end_time,
                        'HH:mm:ss.SSS'
                    ).format('HH:mm:ss.SSS')
                )
            ) {
                errors = {
                    ...errors,
                    chapters: {
                        ...errors?.chapters,
                        [idx]: {
                            ...errors?.chapters?.[idx],
                            end_time: 'Invalid End Time',
                        },
                    },
                };
            }
        }
    });
    return errors;
};

const validateContentForm = (values) => {
    let errors = {};
    if (values.link_type_id === LinkTypes.SubjectMatterExpert.id) {
        if (!values?.sme_email?.trim()) {
            errors.sme_email = 'Required';
        }
        if (!values?.sme_name?.trim()) {
            errors.sme_name = 'Required';
        }
    }
    if (values.link_type_id === LinkTypes.Procedure.id) {
        if (!values?.procedure_id) {
            errors.procedure_id = 'Required';
        }
    }

    if (values.link_type_id === LinkTypes.Video.id) {
        if (values?.chapters && values.chapters?.length > 0) {
            errors = validateChapterTimeFormat(errors, values);
        }
    }
    return errors;
};

const validateContentVersionForm = (values, link_type_id) => {
    let errors = {};

    if (link_type_id === LinkTypes.Video.id) {
        if (values?.chapters && values.chapters?.length > 0) {
            errors = validateChapterTimeFormat(errors, values);
        }
    }

    return errors;
};

const validChapterTime = async (chapters, videoDuration) => {
    if (chapters && chapters?.length > 0) {
        const updatedChapters = chapters.map((chapter) => {
            const updatedStartDate = !chapter.start_time
                ? '00:00:00.000'
                : typeof chapter.start_time === 'string'
                ? chapter.start_time
                : chapter.start_time?._i;
            const videoDurationTime = videoDuration
                ? timeToSeconds(videoDuration) - 1
                : timeToSeconds(updatedStartDate) + 1;
            const updatedEndDate = !chapter.end_time
                ? convertSecondstoTime(videoDurationTime)
                : typeof chapter.end_time === 'string'
                ? chapter.end_time
                : chapter.end_time?._i;

            const updatedChapter = {
                title: chapter?.title,
                start_time: moment(updatedStartDate, 'HH:mm:ss.SSS').format(
                    'HH:mm:ss.SSS'
                ),
                end_time: moment(updatedEndDate, 'HH:mm:ss.SSS').format(
                    'HH:mm:ss.SSS'
                ),
            };
            if (chapter?.chapter_id) {
                updatedChapter.chapter_id = chapter.chapter_id;
            }
            return updatedChapter;
        });
        return updatedChapters;
    }
    return [];
};

const getVideoDurationFromURL = async (url) => {
    return new Promise((resolve) => {
        var video = document.createElement('video');
        video.src = url;
        video.preload = 'metadata';
        video.onloadedmetadata = async () => {
            window.URL.revokeObjectURL(video.src);
            var duration = video.duration;
            resolve(convertSecondstoTime(duration));
        };
    });
};

export {
    validateContentForm,
    validateContentVersionForm,
    validChapterTime,
    getVideoDurationFromURL,
};
