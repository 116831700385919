import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
    createStyles({
        navContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flexGrow: 1,
        },
        accountLogout: {
            backgroundColor: '#E84C3C',
            padding: '5px',
            textAlign: 'center',
            width: '100%',
            borderRadius: '5px',
            color: '#fff !important',
            fontSize: '12px',
            cursor: 'pointer',
        },
        logoutContainer: {
            display: 'flex',
            padding: '0px 4px',
        },
        version: {
            fontSize: '11px',
            marginTop: '2px',
            textAlign: 'center',
            padding: '2px',
        },
        procedureIcon: {
            fontSize: '10px',
            height: '2em',
            width: '2em',
            fill: 'rgba(0, 0, 0, 0.54)',
        },
    })
);

export { useStyles };
