import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Badge,
    Box,
    FormControl,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import { MdAddAPhoto } from 'react-icons/md';

import { getBase64 } from 'GeminiViewerComponent/_helpers/helper';
import { PreviewDialog } from '../../ImageAttachments/components/PreviewDialog';
import { getChecklistData } from 'GeminiViewerComponent/components/Procedure/_features/checklistDataSlice';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import FieldValidationError from './FieldValidationError';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';

const MediaFieldBtn = ({
    data,
    isSubmitAllow,
    selectedImages,
    setOpenPreviewDialog,
    openFileSelection,
    isGuest = false,
    inspectionMedia,
    handleClick = () => {},
}) => {
    return (
        <IconButton
            onClick={(e) => {
                if (!isSubmitAllow) {
                    e.stopPropagation();
                    handleClick(e, data);
                } else {
                    if (!isGuest) {
                        if (
                            isSubmitAllow === true ||
                            inspectionMedia === true
                        ) {
                            if (selectedImages?.length > 0) {
                                setOpenPreviewDialog(true);
                            } else {
                                openFileSelection();
                            }
                        }
                    }
                }
            }}
            aria-label="close"
            style={{
                width: '100%',
                borderRadius: 'inherit',
                display: 'flex',
                flexDirection: 'column',
                cursor: `${isGuest ? 'not-allowed' : 'pointer'}`,
            }}
        >
            {selectedImages?.length > 0 ? (
                <Badge
                    sx={{
                        '& span': {
                            backgroundColor: '#FF0000',
                            color: '#ffffff',
                        },
                    }}
                    badgeContent={selectedImages?.length}
                    invisible={false}
                >
                    <MdAddAPhoto
                        className="react-icon"
                        style={{
                            padding: 0,
                            color: '#000000',
                        }}
                    />
                </Badge>
            ) : (
                <MdAddAPhoto
                    className="react-icon"
                    style={{
                        padding: 0,
                        color: '#000000',
                    }}
                />
            )}
            <Tooltip title={data?.label}>
                <Typography
                    style={{
                        textTransform: 'none',
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                    variant="body2"
                    color="textSecondary"
                >
                    {data?.label}
                </Typography>
            </Tooltip>
        </IconButton>
    );
};

const MediaField = ({
    data,
    errors = [],
    setErrors = () => {},
    isSubmitAllow,
    inGroup = false,
    inspectionMedia = false,
    handleClick = () => {},
}) => {
    const checklistData = useSelector(getChecklistData);
    const { enqueueSnackbar } = useSnackbar();
    const inputFileRef = useRef(null);
    const [selectedImages, setSelectedImages] = useState([]);
    const [originalAttachmentData, setOriginalAttachmentData] = useState([]);
    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
    const userData = useSelector(getConfig);

    useEffect(() => {
        if (isSubmitAllow === true) {
            let allErrors = errors;
            if (data?.required === true && selectedImages?.length === 0) {
                allErrors[data?.variable] = 'Required';
            } else {
                delete allErrors?.[data?.variable];
            }
            setErrors({ ...allErrors });
        }
    }, [selectedImages, data, errors]);

    useEffect(() => {
        if (isSubmitAllow === true) {
            if (
                Array.isArray(checklistData?.imgAtt?.[data?.variable]) &&
                checklistData?.imgAtt?.[data?.variable]?.length > 0
            ) {
                setSelectedImages(checklistData?.imgAtt?.[data?.variable]);
                setOriginalAttachmentData(
                    checklistData?.imgAtt?.[data?.variable]
                );
            } else {
                setSelectedImages([]);
                setOriginalAttachmentData([]);
            }
        }
    }, [checklistData?.imgAtt, isSubmitAllow]);

    const openFileSelection = () => {
        if (inputFileRef.current) {
            inputFileRef.current.click();
        }
    };

    const ImagePreviewURL = async (files) => {
        let updateObject = [];
        for (let [index, fl] of files.entries()) {
            updateObject[index] = { ...fl };
            if (fl?.isSaved === true || fl?.url) {
                continue;
            }
            const img = await getBase64(fl?.file);
            updateObject[index] = { ...fl, url: img };
        }
        setSelectedImages(updateObject);
    };

    const saveSelectedFiles = useCallback(
        async (files) => {
            if (
                (isSubmitAllow === true || inspectionMedia === true) &&
                files &&
                files.length > 0
            ) {
                let newFiles = [];
                let isError = false;
                let fileName = '';
                for (let fl of files) {
                    if (fl.type.indexOf('image/') !== -1) {
                        fileName = (fl?.name || '').substring(
                            0,
                            (fl?.name || '').lastIndexOf('.')
                        );
                        newFiles.push({
                            file: fl,
                            name: fileName,
                            id: uuidv4(),
                            isSaved: false,
                            url: '',
                        });
                    } else {
                        isError = true;
                    }
                }
                if (isError === true) {
                    enqueueSnackbar('Only images are accepted', {
                        action: (key) => <SnackbarDismiss key={key} />,
                        variant: 'error',
                    });
                    return;
                }
                inputFileRef.current.value = null;
                if (selectedImages?.length === 0) {
                    await ImagePreviewURL(newFiles);
                    setOpenPreviewDialog(true);
                } else {
                    await ImagePreviewURL([...selectedImages, ...newFiles]);
                }
            }
        },
        [selectedImages]
    );

    const mediaFieldData = (
        <FormControl
            style={{
                padding: '5px',
                width: data?.field_width ? data.field_width + '%' : '100%',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    textAlign: 'center',
                    border: `1px solid ${
                        errors?.[data?.variable] ? '#d32f2f' : '#80808080'
                    }`,
                    borderRadius: '5px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <>
                        <input
                            ref={inputFileRef}
                            type="file"
                            accept="image/*"
                            name={data?.variable}
                            id={data?.variable}
                            style={{ display: 'none' }}
                            multiple={true}
                            onChange={(e) => saveSelectedFiles(e.target.files)}
                        />
                        <PreviewDialog
                            selectedImages={selectedImages}
                            setSelectedImages={setSelectedImages}
                            originalAttachmentData={originalAttachmentData}
                            var_name={data?.variable}
                            level={'field'}
                            openPreviewDialog={openPreviewDialog}
                            setOpenPreviewDialog={setOpenPreviewDialog}
                            openFileSelection={openFileSelection}
                        />
                        <div>
                            {userData?.user?.isGuest ? (
                                <Tooltip
                                    title={`You're not authenticated to access this feature.`}
                                >
                                    <Box>
                                        <MediaFieldBtn
                                            data={data}
                                            isSubmitAllow={isSubmitAllow}
                                            selectedImages={selectedImages}
                                            setOpenPreviewDialog={
                                                setOpenPreviewDialog
                                            }
                                            openFileSelection={
                                                openFileSelection
                                            }
                                            isGuest={userData?.user?.isGuest}
                                            inspectionMedia={inspectionMedia}
                                            handleClick={handleClick}
                                        />
                                    </Box>
                                </Tooltip>
                            ) : (
                                <MediaFieldBtn
                                    data={data}
                                    isSubmitAllow={isSubmitAllow}
                                    selectedImages={selectedImages}
                                    setOpenPreviewDialog={setOpenPreviewDialog}
                                    openFileSelection={openFileSelection}
                                    isGuest={userData?.user?.isGuest}
                                    inspectionMedia={inspectionMedia}
                                    handleClick={handleClick}
                                />
                            )}
                        </div>
                    </>
                </div>
            </div>
            {!inspectionMedia && errors?.[data?.variable] && (
                <FieldValidationError
                    validationError={errors?.[data?.variable]}
                />
            )}
        </FormControl>
    );

    return inGroup ? (
        mediaFieldData
    ) : (
        <div style={{ width: '100%' }}>{mediaFieldData}</div>
    );
};

export default MediaField;
