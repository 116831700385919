export const BasicLinkTypes = {
    Document: {
        id: 1,
        label: 'Document',
    },
    Video: {
        id: 2,
        label: 'Video',
    },
    System: {
        id: 3,
        label: 'System',
    },
    Audio: {
        id: 4,
        label: 'Audio',
    },
    Learning: {
        id: 5,
        label: 'Learning',
    },
    SubjectMatterExpert: {
        id: 6,
        label: 'Subject Matter Expert',
    },
    Image: {
        id: 7,
        label: 'Image',
    },
    CustomData: {
        id: 8,
        label: 'Custom Data',
    },
    Schoox: {
        id: 9,
        label: 'Schoox',
    },
    Embed: {
        id: 10,
        label: 'Embed',
    },
    Procedure: {
        id: 11,
        label: 'Procedure',
    },
    Model: {
        id: 12,
        label: 'Model',
    },
    TechU: {
        id: 13,
        label: 'TechU',
    },
};
