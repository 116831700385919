import { makeStyles } from '@mui/styles';

const scenePreviewsStyles = makeStyles(() => ({
    scenePreviewsContainer: {
        flexGrow: 1,
        // position: 'relative',
        width: '0px',
    },
    scenePreviews: {
        backgroundColor: '#0003',
        cursor: 'default',
        width: '160px',
        visibility: 'inherit',
        pointerEvents: 'auto',
        transformOrigin: '50% 50%',
        margin: '0',
        padding: '0',
        listStyle: 'none',
        overflow: 'auto',
        transition: 'max-height .3s ease-out',
        position: 'absolute',
        left: '5px',
        top: '40px',
        maxHeight: '0px',
        overflowY: 'auto',
    },
    sceneOpen: {
        border: '1px solid rgb(0, 0, 0)',
        padding: '5px',
        maxHeight: 'calc(100% - 180px)',
        visibility: 'inherit',
    },
    scenePreviewContainer: {
        border: '3px solid rgb(150, 150, 150)',
        padding: '0',
        marginBottom: '5px',
        position: 'relative',
        '& img': {
            width: '100%',
            height: 'auto',
        },
        '&:hover': {
            border: '3px solid rgb(255, 255, 255)',
        },
    },
    sceneActive: {
        border: '3px solid rgb(0, 0, 0)',
    },
    sceneImage: {
        border: '3px solid rgb(150, 150, 150)',
        width: '100%',
        height: 'auto',
    },
    sceneNoImage: {
        backgroundColor: 'transparent',
        color: 'white',
        textAlign: 'center',
        padding: '4px',
        width: '100%',
        height: 'auto',
    },
    scenePreviewTitle: {
        position: 'relative',
        fontSize: '14px',
        height: 'auto',
        lineHeight: 'auto',
        width: '100%',
        padding: '2px',
        margin: '0',
        left: '0px',
        top: '100%',
        textAlign: 'center',
        backgroundColor: (props) => props.colors.primarySectionBackground,
        color: (props) => props.colors.primarySectionForeground,
        wordWrap: 'break-word',
    },
    scenePreviewTitleActive: {
        backgroundColor: (props) => props.colors.secondarySectionBackground,
    },
    categoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
    },
    categoryTitle: {
        border: 'solid white 1px',
        textAlign: 'center',
        backgroundColor: 'black',
        color: 'white',
        padding: '6px',
        wordWrap: 'break-word',
    },
    categoryZones: {
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        maxHeight: '0px',
        overflow: 'hidden',
        // transition: 'max-height 0.8s ease-out',
        '&.categoryOpen': {
            maxHeight: '100%',
            // transition: 'max-height 0.8s ease-in',
        },
    },
}));

export { scenePreviewsStyles };
