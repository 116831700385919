import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { makeFormStyles } from 'forms/styles';
import { CheckboxWithLabel } from 'formik-mui';
import { useDispatch, useSelector } from 'react-redux';
import {
    TextField as MUITextField,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Autocomplete,
} from '@mui/material';

import DirtyProcedure from '../DirtyProcedure';
import makeStyles from '@mui/styles/makeStyles';
import { FormTextField } from 'components/TextField';
import SaveNodeDataDialog from '../SaveNodeDataDialog';
import { VideoChapterField } from './components/VideoChapterField';
import { ChapterButtonsLocation } from 'GeminiViewerComponent/_helpers';
import { setProcedureDirty } from '_features/procedures/proceduresSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { setViewContentPanel } from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import { SelectContentDialog } from '../ContentNodeEditor/SelectContentForm/components/SelectContentDialog';
import {
    fetchContentById,
    fetchContentByTypeId,
    resetContentStatus,
} from '_features/content/contentSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    CommonNodeFormFIelds,
    ExcludeFromNavigationField,
    NodeCategoryField,
} from '../CommonNodeFormFIelds';

const makeNodeFormStyles = makeStyles(() => ({
    container: {
        padding: '20px',
        width: '100%',
        height: '100%',
    },
    form: {
        display: 'flex',
        width: '100%',
        height: 'auto',
        padding: '10px',
        border: '1px solid',
    },
    formBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        width: '100%',
    },
    submitButtonRow: {
        marginTop: 'auto',
        alignSelf: 'end',
        display: 'flex',
        gap: '5px',
    },
    contentRow: {
        cursor: 'pointer',
    },
    dialogPaper: {
        minHeight: '100%',
        maxHeight: '100%',
    },
    addButtonIcon: {
        padding: '3px !important',
        borderRadius: '25px !important',
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
    },
}));

const ContentViewActionForm = ({
    nodes,
    node,
    onCancel,
    onSubmit,
    openSaveDataDialog,
    setOpenSaveDataDialog,
    procedureCategories,
}) => {
    const theme = useSelector(selectActiveTheme);
    const isComponentLoaded = useRef(true);
    const nodeFormStyles = makeNodeFormStyles(theme);
    const commonFormStyles = makeFormStyles(theme);
    const dispatch = useDispatch();
    const [openContentDialog, setOpenContentDialog] = useState(false);
    const [sortBy, setSortBy] = useState('display_name');
    const [sortDirection, setSortDirection] = useState('ASC');
    const [selectedContent, setSelectedContent] = useState(null);
    const [contentPayload, setContentPayload] = useState({
        reset: true,
        startIndex: 0,
        stopIndex: 19,
        contentTypeIds: [],
    });

    const [formValues, setFormValues] = useState({ ...node });

    nodes = nodes.filter((nd) => nd.id !== node.id);

    const getContentById = async () => {
        if (node.content_id) {
            await dispatch(activateLoading());
            const contentName = await dispatch(
                fetchContentById({ contentId: node?.content_id })
            );
            setSelectedContent(contentName?.payload?.display_name);
            await dispatch(deactivateLoading());
        }
    };

    const getContentData = async (payload) => {
        await dispatch(activateLoading());
        await dispatch(
            fetchContentByTypeId({
                ...payload,
                sort: `${sortDirection === 'ASC' ? '' : '-'}${
                    sortBy === 'content_type_id' ? 'content_type_name' : sortBy
                }`,
            })
        );
        await dispatch(deactivateLoading());
    };

    useEffect(() => {
        dispatch(setViewContentPanel(false));
        dispatch(resetContentStatus());
        if (isComponentLoaded.current) {
            isComponentLoaded.current = false;
            return;
        }
        getContentData({ ...contentPayload });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentPayload, dispatch]);

    useEffect(() => {
        getContentById();
        setSelectedContent('');
        setFormValues({
            exclude_from_navigation: true,
            auto_play: true,
            content_id: '',
            action: {
                id: '',
                type: 'node',
            },
            chapter_buttons_location: ChapterButtonsLocation[1],
            ...node,
            procedureCategories: procedureCategories,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [node]);

    useEffect(() => {
        return () => {
            setFormValues({});
            dispatch(setProcedureDirty(false));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        if (_sortBy !== '') {
            setContentPayload({
                ...contentPayload,
                reset: true,
                startIndex: 0,
                stopIndex: 19,
                sort: `${_sortDirection === 'ASC' ? '' : '-'}${
                    _sortBy === 'content_type_id'
                        ? 'content_type_name'
                        : _sortBy
                }`,
            });
        }
    };

    return (
        <div className={nodeFormStyles.container}>
            <div className={nodeFormStyles.form}>
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={Yup.object({
                        content_id: Yup.string().required('Required'),
                        video_playback_end_margin: Yup.number(
                            'Please provide valid number.'
                        )
                            .integer('Value must be integer.')
                            .min(0, 'Minimum value must be 0.'),
                    })}
                    onSubmit={async (values) => {
                        // Add content url
                        const content = await dispatch(
                            fetchContentById({
                                contentId: values.content_id,
                                addS3Prefix: false,
                            })
                        );
                        var updatedValues = { ...values };
                        updatedValues.content_url =
                            content.payload.last_content_version.url ||
                            content.payload.last_content_version.encoded_url;
                        updatedValues.content_link_type_id =
                            content.payload.default_link_type_id;
                        if (updatedValues.require_video_playback) {
                            if (!updatedValues?.video_playback_end_margin) {
                                updatedValues.video_playback_end_margin = 0;
                            }
                        } else {
                            delete updatedValues?.video_playback_end_margin;
                        }
                        onSubmit(updatedValues);
                    }}
                >
                    {({
                        values,
                        setFieldValue,
                        isSubmitting,
                        dirty,
                        errors,
                    }) => (
                        <Form className={nodeFormStyles.formBody}>
                            <DirtyProcedure />
                            <CommonNodeFormFIelds />
                            <NodeCategoryField />
                            <Autocomplete
                                open={false}
                                fullWidth
                                sx={{
                                    margin: '8px 0 4px',
                                }}
                                variant="outline"
                                id="existing-content"
                                value={selectedContent}
                                onKeyPress={(e) => {
                                    e.preventDefault();
                                }}
                                disableClearable={true}
                                renderInput={(params) => (
                                    <MUITextField
                                        {...params}
                                        error={!!errors.content_id}
                                        helperText={errors.content_id}
                                        variant="outlined"
                                        label="Content"
                                        placeholder="Content"
                                    />
                                )}
                                freeSolo={true}
                                onClickCapture={() =>
                                    setOpenContentDialog(true)
                                }
                            />
                            <VideoChapterField />
                            {/* <FormTextField
                                autoFocus={true}
                                label="Skill Id"
                                name="content_skill_id"
                                placeholder="Skill Id"
                                type="number"
                            /> */}
                            <FormTextField
                                component={CheckboxWithLabel}
                                Label={{
                                    label: 'Display Chapters',
                                }}
                                label="Display Chapters"
                                type="checkbox"
                                checked={!!values?.show_chapters}
                                onChange={(evt) => {
                                    if (!evt.target.checked) {
                                        setFieldValue(
                                            'collapse_chapters',
                                            false
                                        );
                                    }
                                    setFieldValue(
                                        'show_chapters',
                                        evt.target.checked
                                    );
                                }}
                            />
                            {values?.show_chapters && (
                                <>
                                    <FormTextField
                                        component={CheckboxWithLabel}
                                        Label={{
                                            label: 'Collapse Chapters',
                                        }}
                                        label="Collapse Chapters"
                                        type="checkbox"
                                        checked={!!values?.collapse_chapters}
                                        onChange={(evt) =>
                                            setFieldValue(
                                                'collapse_chapters',
                                                evt.target.checked
                                            )
                                        }
                                    />
                                    <Autocomplete
                                        fullWidth
                                        variant="outline"
                                        id="chapter-buttons-location"
                                        value={values?.chapter_buttons_location}
                                        options={Object.values(
                                            ChapterButtonsLocation
                                        )}
                                        onChange={(evt, newInputValue) => {
                                            setFieldValue(
                                                'chapter_buttons_location',
                                                newInputValue
                                            );
                                        }}
                                        disableClearable={true}
                                        renderInput={(params) => (
                                            <MUITextField
                                                {...params}
                                                margin="dense"
                                                variant="outlined"
                                                label="Chapter Buttons Location"
                                                placeholder="Chapter Buttons Location"
                                            />
                                        )}
                                    />
                                </>
                            )}
                            <FormTextField
                                component={CheckboxWithLabel}
                                Label={{
                                    label: 'Auto play videos',
                                }}
                                label="Auto play videos"
                                type="checkbox"
                                checked={!!values?.auto_play}
                                onChange={(evt) =>
                                    setFieldValue(
                                        'auto_play',
                                        evt.target.checked
                                    )
                                }
                            />
                            <FormTextField
                                component={CheckboxWithLabel}
                                Label={{
                                    label: 'Require video playback',
                                }}
                                label="Require video playback"
                                type="checkbox"
                                checked={!!values.require_video_playback}
                                onChange={(evt) =>
                                    setFieldValue(
                                        'require_video_playback',
                                        evt.target.checked
                                    )
                                }
                            />
                            <ExcludeFromNavigationField />
                            {!!values.require_video_playback && (
                                <FormTextField
                                    label="Video playback end margin (Seconds)"
                                    type="number"
                                    name="video_playback_end_margin"
                                    placeholder="Video playback end margin (Seconds)"
                                />
                            )}
                            <FormControl
                                fullWidth
                                style={{ marginTop: '10px' }}
                            >
                                <InputLabel id="target-node-id">
                                    Target Node
                                </InputLabel>
                                <Select
                                    className={commonFormStyles.materialSelect}
                                    style={{ margin: '0' }}
                                    labelId="target-node-id"
                                    margin="dense"
                                    id="target_node_id"
                                    label="Target Node"
                                    placeholder="Target Node"
                                    name={`target_node_id`}
                                    value={values?.action?.id}
                                    onChange={(e) => {
                                        setFieldValue('action', {
                                            id: e.target.value,
                                            type: 'node',
                                        });
                                    }}
                                >
                                    <MenuItem key={'none'} value={''}>
                                        None
                                    </MenuItem>
                                    <MenuItem key={'next'} value={'next'}>
                                        Next
                                    </MenuItem>
                                    <MenuItem key={'back'} value={'back'}>
                                        Back
                                    </MenuItem>
                                    {nodes?.length > 0 &&
                                        nodes.map((nd) => (
                                            <MenuItem
                                                key={nd?.id}
                                                value={nd?.id}
                                            >
                                                {nd?.title} - #{nd?.id}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            {openSaveDataDialog && (
                                <SaveNodeDataDialog
                                    openSaveDataDialog={openSaveDataDialog}
                                    setOpenSaveDataDialog={
                                        setOpenSaveDataDialog
                                    }
                                    isSubmitting={isSubmitting}
                                    onCancel={onCancel}
                                />
                            )}
                            <div className={nodeFormStyles.submitButtonRow}>
                                <button
                                    className={commonFormStyles.cancel}
                                    type="button"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        if (dirty) {
                                            setOpenSaveDataDialog(true);
                                        } else {
                                            onCancel();
                                        }
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={commonFormStyles.submit}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </button>
                            </div>
                            {openContentDialog && (
                                <SelectContentDialog
                                    openDialog={openContentDialog}
                                    setOpenDialog={setOpenContentDialog}
                                    setFieldValue={setFieldValue}
                                    setSorting={setSorting}
                                    contentPayload={contentPayload}
                                    setContentPayload={setContentPayload}
                                    sortBy={sortBy}
                                    sortDirection={sortDirection}
                                    setSelectedContent={setSelectedContent}
                                />
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default ContentViewActionForm;
