import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';

import { ContentTypes, LinkTypes } from '_helpers';
import { SelectExistingContentFilters } from './SelectExistingContentFilters';
import { SelectExistingContentTable } from './SelectExistingContentTable';
import {
    fetchContentVersions,
    getContentPageInfo,
    selectContentById,
} from '_features/content/contentSlice';

const SelectContentDialog = ({
    openDialog,
    setOpenDialog,
    setFieldValue,
    setSorting,
    contentPayload,
    setContentPayload,
    sortBy,
    sortDirection,
    setSelectedContent,
    setSelectedPoster = null,
    contentType = null,
}) => {
    const dispatch = useDispatch();
    const content = useSelector(selectContentById);
    const contentPageInfo = useSelector(getContentPageInfo);
    const [searchStringState, setSearchStringState] = useState('');

    useEffect(() => {
        let contentTypeIds;
        if (contentType === 'image' || setSelectedPoster) {
            contentTypeIds = LinkTypes.Image.contentTypes.map(
                (link) => link.id
            );
        } else if (contentType === 'video') {
            contentTypeIds = LinkTypes.Video.contentTypes.map(
                (link) => link.id
            );
        } else {
            contentTypeIds = Object.values(ContentTypes)
                .filter(
                    (ct) =>
                        ct.id !== ContentTypes.Procedure.id &&
                        ct.id !== ContentTypes.Schoox.id
                )
                .map((ct) => ct.id);
        }

        setContentPayload({
            reset: true,
            startIndex: 0,
            stopIndex: 19,
            contentTypeIds,
        });
    }, []);

    return (
        <Dialog
            open={openDialog}
            fullWidth
            maxWidth="sm"
            onClose={() => setOpenDialog(false)}
        >
            <DialogTitle style={{ fontWeight: 700, fontSize: '24px' }}>
                Select Content
            </DialogTitle>
            <DialogContent style={{ padding: '10px 24px 20px' }}>
                <SelectExistingContentFilters
                    contentPayload={contentPayload}
                    setContentPayload={setContentPayload}
                    searchStringState={searchStringState}
                    setSearchStringState={setSearchStringState}
                    contentType={contentType}
                />
                <SelectExistingContentTable
                    content={content}
                    pageInfo={contentPageInfo}
                    contentPayload={contentPayload}
                    sortDirection={sortDirection}
                    sortBy={sortBy}
                    handleSort={(_sortBy, _sortDirection) =>
                        setSorting(_sortBy, _sortDirection)
                    }
                    handleRowClick={async (data) => {
                        const versions = await dispatch(
                            fetchContentVersions(data?.content_id)
                        );
                        if (setSelectedPoster) {
                            setFieldValue('poster_image_url', data?.url);
                            setFieldValue('poster_id', data?.content_id);
                            setFieldValue(
                                'poster_image_name',
                                data?.display_name
                            );
                            setFieldValue('posterVersions', versions?.payload);
                            setSelectedPoster(data?.display_name);
                        } else {
                            setFieldValue('contentVersions', versions?.payload);
                            setSelectedContent(data?.display_name);
                            setFieldValue('content_id', data?.content_id);
                        }
                        if (
                            contentType === 'video' &&
                            data?.chapters &&
                            data?.chapters?.length > 0
                        ) {
                            setFieldValue('chapters', data.chapters);
                        }
                        setOpenDialog(false);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export { SelectContentDialog };
