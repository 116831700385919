import { config } from 'gemini-config';
import { getNetworkService, prefixUrl } from 'GeminiViewerComponent/_helpers';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import { contentService } from '_features/_services';

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});

const baseUrl = `${config.apiUrl}/link`;
const techUBaseUrl = `${config.deltaTechUDomain}/courses/webservice/rest/server.php`;

const mapLink = (link, s3Prefix) => {
    link.url = prefixUrl(link.url, s3Prefix);
    if (link.content_version)
        link.content_version = contentService.mapContentVersion(
            link.content_version,
            s3Prefix
        );
    return link;
};

const mapLinks = (links, s3Prefix) => {
    return links.map((link) => {
        return mapLink(link, s3Prefix);
    });
};

const getAllItemLinks = async (
    page = 1,
    pageSize = 10,
    itemId,
    linkTypeId,
    s3Prefix = ''
) => {
    const filters = [`is_active==${'true'}`];

    if (linkTypeId && linkTypeId !== '') {
        filters.push(`link_type_id==${linkTypeId}`);
    }

    const params = {
        filters: filters.join(','),
        sorts: 'display_name',
        page: page,
        pageSize: pageSize,
    };

    const query = new URLSearchParams(params).toString();
    const response = await networkService.get(
        `${baseUrl}/getallbyitemid/${itemId}?${query}`
    );
    const itemLinks = mapLinks(response.data, s3Prefix);
    itemLinks.forEach((itemLink) => {
        itemLink.item_id = itemId;
    });
    const pageInfo = response.headers['x-pagination'];
    return { pageInfo: pageInfo, itemLinks: itemLinks };
};

const getAllLinksByAssetId = async (assetId, s3Prefix) => {
    const response = await networkService.get(
        `${baseUrl}/getallbyassetid/${assetId}`
    );
    const itemLinks = mapLinks(response.data, s3Prefix);
    return itemLinks;
};

const getAllItemLinksRange = async (
    startIndex = 1,
    stopIndex = 10,
    itemId,
    linkTypeId,
    s3Prefix = ''
) => {
    const filters = [`is_active==${'true'}`];

    if (linkTypeId && linkTypeId !== '') {
        filters.push(`link_type_id@=${linkTypeId}`);
    }

    const params = {
        filters: filters.join(','),
        sorts: 'display_name',
    };

    if (stopIndex > 0) {
        params.start_index = startIndex;
        params.stop_index = stopIndex;
    }

    const query = new URLSearchParams(params).toString();

    const response = await networkService.get(
        `${baseUrl}/getallbyitemid/${itemId}?${query}`
    );
    const itemLinks = mapLinks(response.data, s3Prefix);
    itemLinks.forEach((itemLink) => {
        itemLink.item_id = itemId;
    });
    const pageInfo = response.headers['x-pagination'];
    return { pageInfo: pageInfo, itemLinks: itemLinks };
};

const getTechUCourses = async (wstoken, s3Prefix = '') => {
    const response = await networkService.get(
        `${techUBaseUrl}?moodlewsrestformat=json&wsfunction=core_course_get_courses_by_field&wstoken=${wstoken}`
    );
    return mapLink(response.data, s3Prefix);
};

const getTechUCourseContentById = async (
    wstoken = '',
    course_id = null,
    s3Prefix = ''
) => {
    const response = await networkService.get(
        `${techUBaseUrl}?moodlewsrestformat=json&wsfunction=core_course_get_contents&wstoken=${wstoken}&courseid=${course_id}`
    );
    return mapLink(response.data, s3Prefix);
};

const getById = async (id, s3Prefix = '') => {
    const response = await networkService.get(`${baseUrl}/${id}`);
    return mapLink(response.data, s3Prefix);
};

const create = async (params, s3Prefix = '') => {
    params.audience_ids = params.audiences;
    const response = await networkService.post(baseUrl, params);
    return mapLink(response.data, s3Prefix);
};

const update = async (id, params, s3Prefix = '') => {
    params.audience_ids = params.audiences;
    const response = await networkService.put(`${baseUrl}/${id}`, params);
    return mapLink(response.data, s3Prefix);
};

const _delete = async (id) => {
    try {
        const response = await networkService.delete(`${baseUrl}/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const linkService = {
    create: create,
    delete: _delete,
    getById: getById,
    getAllItemLinks: getAllItemLinks,
    getAllItemLinksRange: getAllItemLinksRange,
    update: update,
    mapLinks: mapLinks,
    getTechUCourses,
    getTechUCourseContentById,
    getAllLinksByAssetId,
};
