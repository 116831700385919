import React from 'react';
import { LinkTypes } from '_helpers/enumerations';
import { GeneralContentTab } from '../GeneralContentTab';

function VideoTab() {
    return (
        <GeneralContentTab
            contentName={'Video'}
            linkTypeId={LinkTypes.Video.id}
            contentTypes={LinkTypes.Video.contentTypes}
        />
    );
}

export { VideoTab };
