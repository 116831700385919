import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import { getClientService } from 'GeminiViewerComponent/_features/services/client.service';
import { fetchAllHotspotIcons } from 'shared/hotspotsSlice';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    setActiveTheme,
    setLoadingSvg,
    setUserThemes,
} from '../globals/themeSlice';
import { getNetworkService } from 'GeminiViewerComponent/_helpers';

const initialState = {
    activeUser: null,
    selfContained: false,
    loginError: null,
    accounts: [],
    editAccount: {},
    assetInfo: {},
    status: LoadingStatus.Idle,
    editAccountStatus: SavingStatus.Idle,
    error: null,
    apiVersion: null,

    // Account managemnet
    accountManagementAccounts: [],
    loadedAccountsMap: {},
    accountManagementPageInfo: {},
    loadedAccountsStatus: LoadingStatus.Idle,

    // Roles
    loadedRolesStatus: LoadingStatus.Idle,
    loadedRoles: [],
    isAppLoading: false,

    //Client Switch
    selectedClient: null,
    availableClients: [],

    //UserList
    userList: [],
    userByClientIdStatus: LoadingStatus.Idle,
};
export const getAccountsThunk = ({ config }) => {
    const clientService = getClientService({ config: config });
    const accountService = getAccountService({ config: config });
    const networkService = getNetworkService({
        config: config,
        checkAuth: accountService.checkAuth,
    });

    const createUser = createAsyncThunk(
        'accounts/createUser',
        async (newUser, { getState, rejectWithValue }) => {
            try {
                await accountService.create(
                    newUser,
                    getState().accounts.activeUser.s3_prefix
                );
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );

    const loginUser = createAsyncThunk(
        'accounts/loginUser',
        async (params, { rejectWithValue, dispatch }) => {
            try {
                const response = await accountService.login(
                    params.email,
                    params.password,
                    params.dod_id
                );

                if (response) {
                    // Uncomment if we want to track user_ids, but make sure
                    // we have privacy policy in place
                    // ReactGA.gtag('set', {
                    //     user_id: response.user_id.toString(),
                    // });

                    if (response?.themes) {
                        dispatch(setUserThemes(response.themes));
                        dispatch(
                            setActiveTheme(response.active_theme ?? 'default')
                        );
                    }

                    if (response?.s3_prefix && response?.loading_svg) {
                        dispatch(
                            setLoadingSvg({
                                loadingSvgUri: response.loading_svg,
                                loadingSvgCssAnimate:
                                    response.loading_svg_css_animate,
                            })
                        );
                    }

                    dispatch(
                        fetchAllHotspotIcons({ s3_prefix: response.s3_prefix })
                    );
                }

                return response;
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );

    const logoutUser = createAsyncThunk(
        'accounts/logoutUser',
        async (params, { dispatch }) => {
            dispatch(
                setLoadingSvg({
                    loadingSvgUri: '',
                    loadingSvgCssAnimate: true,
                })
            );
            await accountService.logout();
            networkService.clearUserStorage();
            // Uncomment if we want to track user_ids, but make sure
            // we have privacy policy in place
            // ReactGA.gtag('set', { user_id: null });
        }
    );

    const refreshToken = createAsyncThunk(
        'accounts/refreshToken',
        async (params, { dispatch }) => {
            const response = await accountService.refreshToken();
            if (response) {
                // Uncomment if we want to track user_ids, but make sure
                // we have privacy policy in place
                // ReactGA.gtag('set', {
                //     user_id: response.user_id.toString(),
                // });

                if (response?.themes) {
                    dispatch(setUserThemes(response.themes));
                    dispatch(
                        setActiveTheme(response.active_theme ?? 'default')
                    );
                }
                if (response?.s3_prefix && response?.loading_svg) {
                    dispatch(
                        setLoadingSvg({
                            loadingSvgUri: response.loading_svg,
                            loadingSvgCssAnimate:
                                response.loading_svg_css_animate,
                        })
                    );
                }

                dispatch(
                    fetchAllHotspotIcons({
                        s3_prefix: response.s3_prefix,
                    })
                );
            }
            return response;
        }
    );

    const getAccountsByClientId = createAsyncThunk(
        'accounts/getAccountsByClientId',
        async (params, { getState }) =>
            await accountService.getAllByClientId(
                getState().accounts.activeUser.s3_prefix
            )
    );

    const fetchAccountsPage = createAsyncThunk(
        'accounts/fetchAccountsPage',
        async (params, { getState }) =>
            await accountService.getAllAccountsByClientId(
                params.searchString,
                params.sort,
                params.roles,
                getState().accounts.activeUser.s3_prefix
            )
    );

    const getAccountById = createAsyncThunk(
        'accounts/getAccountById',
        async (id, { getState }) => {
            return await accountService.getById(
                id,
                getState().accounts.activeUser.s3_prefix
            );
        }
    );

    const fetchRoles = createAsyncThunk(
        'accounts/fetchRoles',
        async () => await accountService.getAllAssignableRoles()
    );

    const updateUser = createAsyncThunk(
        'accounts/updateUser',
        async (userUpdate, { getState, rejectWithValue, dispatch }) => {
            try {
                const response = await accountService.update(
                    userUpdate,
                    getState().accounts.activeUser.s3_prefix
                );

                if (
                    response &&
                    getState().accounts.activeUser.user_id === response.user_id
                ) {
                    if (response.themes) {
                        dispatch(setUserThemes(response.themes));
                        dispatch(
                            setActiveTheme(response.active_theme ?? 'default')
                        );
                    }
                }
                return response;
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );

    const updateUserSettings = createAsyncThunk(
        'accounts/updateUserSettings',
        async (userUpdate, { getState }) => {
            const response = await accountService.updateSettings(
                userUpdate,
                getState().accounts.activeUser
            );
            return response;
        }
    );

    const updateClientThemeInfo = createAsyncThunk(
        'accounts/updateClientThemeInfo',
        async (params, { dispatch }) => {
            const response = await clientService.update(
                params.client_id,
                params.params
            );
            if (!response.error) {
                dispatch(setUserThemes(response.themes));
                dispatch(setActiveTheme(response.active_theme ?? 'default'));
                accountService.updateLocalUser(params.params);
            }

            return response;
        }
    );

    const newCustomThemeData = createAsyncThunk(
        'accounts/newCustomThemeData',
        async (params, { getState }) => {
            const response = await clientService.newThemeData(
                getState().accounts.activeUser.client_id,
                params
            );
            return response;
        }
    );

    const updateCustomThemeData = createAsyncThunk(
        'accounts/updateCustomThemeData',
        async (params, { getState }) => {
            const response = await clientService.updateCustomThemeData(
                getState().accounts.activeUser.client_id,
                params
            );
            return response;
        }
    );

    const deleteAccountById = createAsyncThunk(
        'accounts/deleteAccountById',
        async (userId) => {
            const response = await accountService.delete(userId);
            response.id = userId;
            return response;
        }
    );

    const deleteUserArray = createAsyncThunk(
        'accounts/deleteUserArray',
        async (userIds) => {
            const response = await accountService.deleteArray(userIds);
            response.ids = userIds;
            return response;
        }
    );

    const undeleteAccountById = createAsyncThunk(
        'accounts/undeleteAccountById',
        async (userId) => {
            const response = await accountService.undelete(userId);
            response.id = userId;
            return response;
        }
    );

    const clientSwitch = createAsyncThunk(
        'accounts/clientSwitch',
        async (params) => {
            const response = await accountService.clientSwitch(params);
            return response;
        }
    );

    const fetchAllUserByClientID = createAsyncThunk(
        'accounts/fetchAllUserByClientID',
        async (params) => {
            const response = await accountService.getUserByClientId(
                params.target_client_id
            );
            return response;
        }
    );

    const getApiVersion = createAsyncThunk(
        'accounts/getApiVersion',
        async () => {
            const response = await accountService.fetchApiVersion();
            return response;
        }
    );

    return {
        createUser,
        loginUser,
        logoutUser,
        refreshToken,
        getAccountsByClientId,
        fetchAccountsPage,
        getAccountById,
        fetchRoles,
        updateUser,
        updateUserSettings,
        updateClientThemeInfo,
        updateCustomThemeData,
        deleteAccountById,
        deleteUserArray,
        undeleteAccountById,
        newCustomThemeData,
        clientSwitch,
        fetchAllUserByClientID,
        getApiVersion,
    };
};

export const getCreateAccountsSlice = ({ accountsThunk }) => {
    const slice = createSlice({
        name: 'accounts',
        initialState,
        reducers: {
            resetAccountState: () => initialState,
            resetAccountEditState: (state) => {
                state.editAccount = {};
                state.editAccountStatus = SavingStatus.Idle;
            },
            setActiveUser: (state, action) => {
                console.log(`setActiveUser: ${action.payload?.user_id}`);
                state.activeUser = action.payload;
            },
            setActiveUserS3Prefix: (state, action) => {
                console.log(
                    `setActiveUserS3Prefix: S3_PREFIX = ${action.payload}`
                );
                state.activeUser.s3_prefix = action.payload;
            },
            setAppLoading: (state, action) => {
                state.isAppLoading = action.payload;
            },
            setAssetInfo: (state, action) => {
                state.assetInfo = action.payload;
            },
            setSelfContained: (state, action) => {
                state.selfContained = action.payload;
            },
            setAvailableClients: (state, action) => {
                state.availableClients = action.payload;
            },
            setSelectedClient: (state, action) => {
                state.selectedClient = action.payload;
            },
            resetUsersByClientId: (state) => {
                state.userByClientIdStatus = SavingStatus.Idle;
            },
            resetAccountLoadedStatus: (state) => {
                state.loadedAccountsStatus = LoadingStatus.Idle;
            },
        },
        extraReducers: {
            [accountsThunk.createUser.fulfilled]: (state) => {
                state.status = LoadingStatus.Idle;
                state.loadedAccountsStatus = LoadingStatus.Idle;
            },
            [accountsThunk.refreshToken.fulfilled]: (state, action) => {
                state.activeUser = action.payload;
                // if (action.payload.themes_json) {
                //     state.activeUser.themes = JSON.parse(
                //         action.payload.themes_json
                //     );
                //     delete state.activeUser.themes_json;
                // }
            },
            [accountsThunk.refreshToken.rejected]: (state) => {
                state.activeUser = null;
            },
            [accountsThunk.loginUser.fulfilled]: (state, action) => {
                state.activeUser = action.payload;
                state.loginError = null;
                // if (action.payload.themes_json) {
                //     state.activeUser.themes = JSON.parse(
                //         action.payload.themes_json
                //     );
                //     delete state.activeUser.themes_json;
                // }
            },
            [accountsThunk.loginUser.rejected]: (state, action) => {
                state.activeUser = null;
                state.loginError = action.payload;
            },
            [accountsThunk.logoutUser.fulfilled]: (state) => {
                state.activeUser = null;
                state.loginError = null;
            },
            [accountsThunk.getAccountsByClientId.pending]: (state) => {
                state.status = LoadingStatus.Loading;
            },
            [accountsThunk.getAccountsByClientId.fulfilled]: (
                state,
                action
            ) => {
                state.status = LoadingStatus.Loaded;
                state.accounts = action.payload;
            },
            [accountsThunk.getAccountsByClientId.rejected]: (state, action) => {
                state.status = LoadingStatus.Failed;
                state.error = action.error.message;
            },
            [accountsThunk.fetchAccountsPage.fulfilled]: (state, action) => {
                state.loadedAccountsStatus = LoadingStatus.Loaded;
                state.accountManagementPageInfo = action.payload.pageInfo;
                state.accountManagementAccounts = action.payload.allAccounts;

                // const startIndex =
                //     (action.meta.arg.page - 1) * action.meta.arg.pageSize;
                // const stopIndex =
                //     startIndex + state.accountManagementAccounts.length - 1;
                // const requestedStopIndex =
                //     startIndex + action.meta.arg.pageSize - 1;

                // state.loadedAccountsMap = {};

                // for (var i = startIndex; i <= stopIndex; i++) {
                //     state.loadedAccountsMap[i] = LoadingStatus.Loaded;
                // }

                // // In case we didn't load as many as requested
                // for (var j = stopIndex + 1; j <= requestedStopIndex; j++) {
                //     delete state.loadedAccountsMap[j];
                // }
            },
            [accountsThunk.getAccountById.pending]: (state) => {
                state.editAccountstatus = LoadingStatus.Loading;
            },
            [accountsThunk.getAccountById.fulfilled]: (state, action) => {
                state.editAccountStatus = 'succeeded';
                state.editAccount = action.payload;
            },
            [accountsThunk.getAccountById.rejected]: (state, action) => {
                state.editAccountStatus = SavingStatus.Failed;
                state.error = action.error.message;
            },
            [accountsThunk.fetchRoles.fulfilled]: (state, action) => {
                state.loadedRolesStatus = LoadingStatus.Loaded;
                state.loadedRoles = action.payload;
            },
            [accountsThunk.updateUser.pending]: (state) => {
                state.status = LoadingStatus.Loading;
            },
            [accountsThunk.updateUser.fulfilled]: (state, action) => {
                var account = state.accounts.find(
                    (account) => account.user_id === action.payload.user_id
                );
                if (account) {
                    account.first_name = action.payload.first_name;
                    account.last_name = action.payload.last_name;
                    account.email = action.payload.email;
                    account.image_url = action.payload.image_url;
                }
                if (state.activeUser.user_id === action.payload.user_id) {
                    state.activeUser = action.payload;
                    state.activeUser.role = action.payload?.role?.displayName;
                }
                state.editAccount = action.payload;
                state.loadedAccountsStatus = LoadingStatus.Idle;
            },
            [accountsThunk.updateUserSettings.fulfilled]: (state, action) => {
                var account = state.accounts.find(
                    (account) => account.user_id === action.payload.user_id
                );
                if (account) {
                    account.user_settings = action.payload.user_settings;
                }
                if (state.activeUser.user_id === action.payload.user_id) {
                    state.activeUser.user_settings =
                        action.payload.user_settings;
                }
                state.editAccount = action.payload;
            },
            [accountsThunk.updateClientThemeInfo.fulfilled]: (
                state,
                action
            ) => {
                state.activeUser.active_theme = action.payload.active_theme;
                state.activeUser.themes = action.payload.themes;
            },
            [accountsThunk.deleteAccountById.fulfilled]: (state) => {
                state.status = LoadingStatus.Idle;
                state.loadedAccountsStatus = LoadingStatus.Idle;
            },
            [accountsThunk.deleteUserArray.fulfilled]: (state) => {
                state.status = LoadingStatus.Idle;
                state.loadedAccountsStatus = LoadingStatus.Idle;
            },
            [accountsThunk.undeleteAccountById.fulfilled]: (state) => {
                state.status = LoadingStatus.Idle;
                state.loadedAccountsStatus = LoadingStatus.Idle;
            },
            [accountsThunk.clientSwitch.pending]: (state) => {
                state.loadingStatus = LoadingStatus.Loading;
                state.error = null;
            },
            [accountsThunk.clientSwitch.fulfilled]: (state, action) => {
                state.loadingStatus = LoadingStatus.Loaded;
                state.activeUser = action.payload;
            },
            [accountsThunk.clientSwitch.rejected]: (state, action) => {
                state.loadingStatus = LoadingStatus.Failed;
                state.error = action.error.message;
            },
            [accountsThunk.fetchAllUserByClientID.pending]: (state) => {
                state.userByClientIdStatus = LoadingStatus.Loading;
            },
            [accountsThunk.fetchAllUserByClientID.fulfilled]: (
                state,
                action
            ) => {
                state.userByClientIdStatus = LoadingStatus.Loaded;
                const allUsers = [...action.payload];
                allUsers?.map((user) => {
                    user.full_name = user.first_name + ' ' + user.last_name;
                });
                state.userList = allUsers;
            },
            [accountsThunk.fetchAllUserByClientID.rejected]: (
                state,
                action
            ) => {
                state.userByClientIdStatus = LoadingStatus.Failed;
                state.error = action.error.message;
            },
            [accountsThunk.getApiVersion.pending]: (state, action) => {
                state.appIsLoading = true;
            },
            [accountsThunk.getApiVersion.fulfilled]: (state, action) => {
                state.apiVersion = action.payload;
                state.appIsLoading = false;
            },
            [accountsThunk.getApiVersion.rejected]: (state, action) => {
                state.appIsLoading = false;
            },
        },
    });

    const selectActiveUser = (state) => {
        return state.accounts.activeUser;
    };

    const selectSelfContained = (state) => {
        return state.accounts.selfContained;
    };

    const selectLoginError = (state) => {
        return state.accounts.loginError;
    };

    const selectUserSetting = (state, setting_key) => {
        var settings = state.accounts.activeUser.user_settings ?? false;
        if (settings) {
            settings = JSON.parse(settings);
            return settings[setting_key] ?? null;
        }

        return null;
    };

    const selectAllAccounts = (state) => state.accounts.accounts;

    const getAccountStatus = (state) => state.accounts.status;

    const selectEditAccountStatus = (state) => state.accounts.editAccountStatus;

    const selectEditAccount = (state) => state.accounts.editAccount;

    const selectAccountById = (state, accountId) => {
        return state.accounts.accounts.find(
            (accounts) => accounts.user_id === accountId
        );
    };

    const selectAssetInfo = (state) => state.accounts.assetInfo;

    const getManagementAccounts = (state) =>
        state.accounts.accountManagementAccounts;
    const getManagementPageInfo = (state) =>
        state.accounts.accountManagementPageInfo;

    const selectAllRoles = (state) => state.accounts.loadedRoles;
    const getRolesStatus = (state) => state.accounts.loadedRolesStatus;
    const getIsAppLoaded = (state) => state.accounts.isAppLoading;

    const getUserByClientId = (state) => state.accounts.userList;
    const getUserByClientIdStatus = (state) =>
        state.accounts.userByClientIdStatus;

    const selectApiVersion = (state) => state.accounts.apiVersion;

    const {
        resetAccountState,
        resetAccountEditState,
        setActiveUser,
        setSelfContained,
        setActiveUserS3Prefix,
        setAppLoading,
        setAssetInfo,
        setAvailableClients,
        setSelectedClient,
        resetUsersByClientId,
        resetAccountLoadedStatus,
    } = slice.actions;

    return {
        slice,
        resetAccountState,
        resetAccountEditState,
        setActiveUser,
        setSelfContained,
        setActiveUserS3Prefix,
        setAppLoading,
        setAssetInfo,
        setAvailableClients,
        setSelectedClient,
        resetUsersByClientId,
        resetAccountLoadedStatus,
        selectActiveUser,
        selectSelfContained,
        selectLoginError,
        selectUserSetting,
        selectAllAccounts,
        getAccountStatus,
        selectEditAccountStatus,
        selectEditAccount,
        selectAccountById,
        selectAssetInfo,
        getManagementAccounts,
        getManagementPageInfo,
        selectAllRoles,
        getRolesStatus,
        getIsAppLoaded,
        getUserByClientId,
        getUserByClientIdStatus,
        selectApiVersion,
    };
};
