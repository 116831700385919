import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setContentPanelItem } from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import { itemService } from '_features/_services';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { setLinkDisplayVis } from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import { mapVideoContentURL } from 'GeminiViewerComponent/_helpers';

const initialState = {
    loadingStatus: LoadingStatus.Idle,
};

export const loadContentPanelItem = createAsyncThunk(
    'loadContentPanel/loadContentPanelItem',
    async ({ zoneId, itemId, linkId = null }, { getState, dispatch }) => {
        const item = await itemService.getById(
            zoneId,
            itemId,
            getState().accounts.activeUser.s3_prefix
        );
        if (item) {
            dispatch(setContentPanelItem(item));
            if (item?.links && item?.links?.length > 0) {
                const link = item?.links.find(
                    (link) => link?.link_id === +linkId
                );
                if (link) {
                    dispatch(
                        setLinkDisplayVis({
                            open: true,
                            chapter_data: link.chapter,
                            linkContent: mapVideoContentURL(link),
                            linkData: {
                                ...link,
                                ...link.content,
                                ...(typeof link.content_version === 'object'
                                    ? link.content_version
                                    : {}),
                                ...{ show_chapters: link?.show_chapters },
                            },
                            linkTypeId:
                                link.link_type_id ||
                                link.link_type?.link_type_id,
                            autoPlay: Boolean(link.auto_play),
                            overlayText: link.content_version?.overlay_text,
                        })
                    );
                }
            }
        }
        return item;
    }
);

const loadContentPanelSlice = createSlice({
    name: 'loadContentPanel',
    initialState,
    reducers: {
        clearLoadContentPanel: (state) => {
            state.loadingStatus = LoadingStatus.Idle;
        },
    },
    extraReducers: {
        [loadContentPanelItem.pending]: (state) => {
            state.loadingStatus = LoadingStatus.Loading;
            state.item = {};
        },
        [loadContentPanelItem.fulfilled]: (state, action) => {
            state.item = {
                ...action.payload,
                audience_ids: action.payload.audiences?.map(
                    (x) => x.audience_id
                ),
                tag_ids: action.payload.tags?.map((x) => x.tag_id),
            };
            state.loadingStatus = LoadingStatus.Loaded;
        },
        [loadContentPanelItem.rejected]: (state) => {
            state.loadingStatus = LoadingStatus.Failed;
        },
    },
});

export const selectContentPanelLoadingStatus = (state) =>
    state.loadContentPanel.loadingStatus;

export const { clearLoadContentPanel } = loadContentPanelSlice.actions;

export default loadContentPanelSlice.reducer;
