export const useSetSplitModePosition = ({
    searchMenu,
    controlMenu,
    setSplitViewTopPos,
}) => {
    let splitModeTop = 0;
    if (searchMenu === true) splitModeTop++;
    if (controlMenu === true) splitModeTop++;
    setSplitViewTopPos && setSplitViewTopPos(splitModeTop);

    return splitModeTop;
};
