import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NewSessionDialog = ({
    openNewSessionDialog,
    setOpenNewSessionDialog,
    CreateProcedureSession,
}) => {
    const navigate = useNavigate();
    const handleSessionData = () => {
        setOpenNewSessionDialog(false);
        CreateProcedureSession();
    };

    return (
        <Dialog open={openNewSessionDialog} fullWidth maxWidth={'xs'}>
            <DialogTitle>Procedure</DialogTitle>
            <DialogContent sx={{ width: '400px' }}>
                <DialogContentText marginBottom={'5px'}>
                    Do you want to start new session?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSessionData}>Yes</Button>
                <Button
                    onClick={() => {
                        setOpenNewSessionDialog(false);
                        navigate(`/procedure-sessions`);
                    }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { NewSessionDialog };
