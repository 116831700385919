import React from 'react';
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

const PromptDialog = ({
    title,
    label,
    description = '',
    cancelButtonLabel,
    okButtonLabel,
    open,
    value,
    handleSubmit,
    handleOnChange,
    handleClose,
}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            onContextMenu={(e) => {
                e.preventDefault();
                handleClose();
            }}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent sx={{ width: '450px' }}>
                {description && (
                    <DialogContentText>{description}</DialogContentText>
                )}
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label={label}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={value}
                    onChange={(e) => handleOnChange(e.target.value)}
                    onKeyPress={(event) =>
                        event.key === 'Enter' && handleSubmit(value)
                    }
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{cancelButtonLabel}</Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit(value)}
                >
                    {okButtonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { PromptDialog };
