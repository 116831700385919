import { useStyles } from 'scenes/styles';
import { FiCheckCircle } from 'react-icons/fi';
import { useDispatch } from 'react-redux';

import { JobsTabPanel, PageHeader } from 'components';
import { resetContentStatus } from '_features/content/contentSlice';
import { setAssetExportStatus } from '_features/assets/assetExportSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { resetDeploymentPackageState } from '_features/jobs/jobsSlice';

const Jobs = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);
    const tabIndex = Object.fromEntries(queryParams);

    const onRefreshIconClick = () => {
        if (Object.keys(tabIndex).includes('id')) {
            const url = new URL(window.location.href);
            url.searchParams.delete('id');
            window.location.href = url.href;
        }
        dispatch(resetContentStatus());
        dispatch(setAssetExportStatus(LoadingStatus.Idle));
        dispatch(resetDeploymentPackageState());
    };

    return (
        <div className={classes.content}>
            <PageHeader
                header="Job Status"
                icon={<FiCheckCircle />}
                onRefreshIconClick={onRefreshIconClick}
            />
            <JobsTabPanel />
        </div>
    );
};

export { Jobs };
