import React, { useCallback } from 'react';
import { PageHeader } from 'components';
import { FiHome } from 'react-icons/fi';
import { useStyles } from 'scenes/styles';
import { homeStyles } from './styles';

import Autocomplete from '@mui/material/Autocomplete';
import { MenuItem, TextField } from '@mui/material';

import { BiComment } from 'react-icons/bi';
import { BsExclamationOctagon } from 'react-icons/bs';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { CgChevronDownO } from 'react-icons/cg';

// TESTING
// import { AudienceTesting } from 'components/_Misc/AudienceTesting'
// import { RuleTesting } from 'components/_Misc/RuleTesting'
import { HomeGrid } from './HomeGrid';
import { StatusButton } from 'components/StatusButton';
import SearchWithFilters from 'components/SearchWithFilters';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';

function Home() {
    const classes = useStyles();
    const homeClasses = homeStyles();

    // const [inputValue, setInputValue] = React.useState('');
    const [homeSelection, setHomeSelection] = React.useState({
        isFiltered: false,
        displayType: '',
        displayLabel: null,
        isComponentLoaded: true,
    });
    const [filterBadge, setFilterBadge] = React.useState(0);
    const DisplayType = [
        {
            value: '0',
            label: 'Display Home',
        },
        {
            value: '1',
            label: 'Display None',
        },
    ];

    // eslint-disable-next-line
    const debouncedSearch = useCallback(
        // eslint-disable-next-line no-unused-vars
        debounce((searchString) => {
            // setInputValue(searchString);
            // dispatch(fetchAssetsPage({ page: 1, pageSize: 10, searchString: searchString }));
        }, 1000),
        []
    );

    const handleSearch = (searchString) => {
        debouncedSearch(searchString);
    };

    const updateInputValue = (evt) => {
        var searchString = evt.target.value;
        handleSearch(searchString);
    };

    const selectionView = () => {
        switch (true) {
            case homeSelection.displayType === '0' && homeSelection.isFiltered:
                return <HomeGrid rows={HOME_DATA} />;
            case homeSelection?.isComponentLoaded:
                return <HomeGrid rows={HOME_DATA} />;
            default:
                break;
        }
    };

    const handleHomeSelection = (newInputValue) => {
        setHomeSelection({
            displayType: newInputValue?.value,
            displayLabel: newInputValue?.label,
            isComponentLoaded:
                newInputValue?.value === '1'
                    ? true
                    : homeSelection?.isComponentLoaded,
            isFiltered: newInputValue?.value === '0' ? false : true,
        });
    };

    const clearFilters = () => {
        setHomeSelection({
            displayType: homeSelection.displayType,
            isFiltered: false,
            displayLabel: '',
            isComponentLoaded: true,
        });
        setFilterBadge(0);
    };

    const addFilters = () => {
        setHomeSelection({
            displayType: homeSelection.displayType,
            displayLabel: homeSelection.displayLabel,
            isFiltered: homeSelection.value === '0' ? false : true,
            isComponentLoaded: false,
        });
        setFilterBadge(1);
    };

    return (
        <div className={classes.content}>
            <PageHeader header="Home" icon={<FiHome />} />

            <div className={homeClasses.actionBar}>
                <div className={homeClasses.actionButtons}>
                    <StatusButton
                        text="Attention needed"
                        icon={<BsExclamationOctagon />}
                        color="#F49D12"
                        amount="19"
                    />
                    <StatusButton
                        text="Pending"
                        icon={<AiOutlineClockCircle />}
                        color="#18BC9B"
                        amount="4"
                    />
                    <StatusButton
                        text="Follow up"
                        icon={<CgChevronDownO />}
                        color="#9B59B6"
                        amount=""
                    />
                    <StatusButton
                        text="Feedback"
                        icon={<BiComment />}
                        color="#E84C3C"
                        amount="7"
                    />
                </div>

                <div className={homeClasses.actionBarRight}>
                    <SearchWithFilters
                        filterBadge={filterBadge}
                        onChange={(evt) => updateInputValue(evt)}
                        addFilters={addFilters}
                        clearFilters={clearFilters}
                    >
                        <MenuItem disableRipple>
                            {DisplayType.length > 0 && (
                                <Autocomplete
                                    limitTags={2}
                                    variant="outline"
                                    id="checkboxes-tags-demo"
                                    options={DisplayType}
                                    value={homeSelection.displayLabel}
                                    onChange={(event, newInputValue) => {
                                        handleHomeSelection(newInputValue);
                                    }}
                                    style={{ width: 500 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Display Type"
                                            placeholder="Display Type"
                                        />
                                    )}
                                />
                            )}
                        </MenuItem>
                    </SearchWithFilters>
                </div>
            </div>

            <div>{selectionView()}</div>

            {/* <AudienceTesting />
            <div style={{height: '2px', width: '100%', backgroundColor: 'black', margin: '25px 0px'}}></div>
            <RuleTesting /> */}
        </div>
    );
}

export { Home };

const HOME_DATA = [
    {
        row_id: '1',
        item_name: 'Aft Apu Mount',
        asset: 'A330',
        zone: 'APU',
        status: 'approved',
        modified_by: 'Carl Stone',
        feedback: 'yes',
    },
    {
        row_id: '2',
        item_name: 'Air Intake Scoop',
        asset: 'A330',
        zone: 'APU',
        status: 'pending for review',
        modified_by: 'Carl Stone',
        feedback: 'no',
    },
    {
        row_id: '3',
        item_name: 'Apu Bleed Valve',
        asset: 'A330',
        zone: 'APU',
        status: 'pending for review',
        modified_by: 'Carl Stone',
        feedback: 'no',
    },
    {
        row_id: '4',
        item_name: 'Apu Drains',
        asset: 'A330',
        zone: 'APU',
        status: 'pending for review',
        modified_by: 'Carl Stone',
        feedback: 'yes',
    },
    {
        row_id: '5',
        item_name: '3l 3r Door',
        asset: 'A330',
        zone: 'Cabin',
        status: 'pending for review',
        modified_by: 'Carl Stone',
        feedback: 'yes',
    },
    {
        row_id: '6',
        item_name: '5000 Panel',
        asset: 'A330',
        zone: 'Cabin',
        status: 'approved',
        modified_by: 'Carl Stone',
        feedback: 'no',
    },
    {
        row_id: '7',
        item_name: 'Access for Battery Pack',
        asset: 'A330',
        zone: 'Cabin',
        status: 'rejected',
        modified_by: 'Carl Stone',
        feedback: 'yes',
    },
    {
        row_id: '8',
        item_name: 'Air Additional Attendant Panel',
        asset: 'A330',
        zone: 'Cabin',
        status: 'rejected',
        modified_by: 'Carl Stone',
        feedback: 'yes',
    },
    {
        row_id: '9',
        item_name: 'Air Outlet Grill',
        asset: 'A330',
        zone: 'Cabin',
        status: 'approved',
        modified_by: 'Carl Stone',
        feedback: 'no',
    },
    {
        row_id: '10',
        item_name: 'Access to Safety Valves',
        asset: 'A330',
        zone: 'Cabin',
        status: 'approved',
        modified_by: 'Carl Stone',
        feedback: 'no',
    },
    {
        row_id: '11',
        item_name: '5000 Panel',
        asset: 'A330',
        zone: 'Cabin',
        status: 'approved',
        modified_by: 'Carl Stone',
        feedback: 'no',
    },
    {
        row_id: '12',
        item_name: '5000 Panel',
        asset: 'A330',
        zone: 'Cabin',
        status: 'approved',
        modified_by: 'Carl Stone',
        feedback: 'no',
    },
    {
        row_id: '13',
        item_name: 'Access for Battery Pack',
        asset: 'A330',
        zone: 'Cabin',
        status: 'rejected',
        modified_by: 'Carl Stone',
        feedback: 'yes',
    },
    {
        row_id: '14',
        item_name: 'Air Additional Attendant Panel',
        asset: 'A330',
        zone: 'Cabin',
        status: 'rejected',
        modified_by: 'Carl Stone',
        feedback: 'yes',
    },
    {
        row_id: '15',
        item_name: 'Air Outlet Grill',
        asset: 'A330',
        zone: 'Cabin',
        status: 'approved',
        modified_by: 'Carl Stone',
        feedback: 'no',
    },
    {
        row_id: '16',
        item_name: 'Access to Safety Valves',
        asset: 'A330',
        zone: 'Cabin',
        status: 'approved',
        modified_by: 'Carl Stone',
        feedback: 'no',
    },
];
