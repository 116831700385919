const CURRENT_TIME = new Date().getTime();
const EXPIRY_TIME = CURRENT_TIME + 86400000;

export const setAssetTypes = (data) => {
    const assetTypesObj = {
        assetTypes: {
            data: data,
            expiry: EXPIRY_TIME,
        },
    };
    setStoreData(assetTypesObj);
};

export const setAllLinkTypes = (data) => {
    const allLinkObj = {
        allLinkTypes: {
            data: data,
            expiry: EXPIRY_TIME,
        },
    };
    setStoreData(allLinkObj);
};

export const setAllAssignableRoles = (data) => {
    const rolesObj = {
        allAssignableRoles: {
            data: data,
            expiry: EXPIRY_TIME,
        },
    };
    setStoreData(rolesObj);
};

export const setHotspotTypes = (data) => {
    const hotspotTypesObj = {
        hotspotTypes: {
            data: data,
            expiry: EXPIRY_TIME,
        },
    };
    setStoreData(hotspotTypesObj);
};

export const setByKey = (key, data) => {
    const obj = {};
    obj[key] = {
        data: data,
        expiry: EXPIRY_TIME,
    };
    setStoreData(obj);
};

export const fetchByKey = (key) => {
    const storeObj = getCacheStore();
    if (CURRENT_TIME > storeObj?.[key]?.expiry) {
        delete storeObj?.[key];
        setCacheStore(storeObj);
    }
    return storeObj?.[key]?.data;
};

export const deleteKey = (key) => {
    const storeObj = getCacheStore();
    if (storeObj?.[key]) {
        delete storeObj?.[key];
        setCacheStore(storeObj);
    }
};

const setStoreData = (_setters) => {
    const storeObj = getCacheStore();
    storeObj
        ? setCacheStore({ ...storeObj, ..._setters })
        : setCacheStore(_setters);
};

const setCacheStore = (value) => {
    localStorage.setItem('cacheStore', JSON.stringify(value));
};

const getCacheStore = () => {
    const item = localStorage.getItem('cacheStore');
    return item ? JSON.parse(item) : null;
};

export const clearCacheStore = () => {
    localStorage.removeItem('cacheStore');
};
