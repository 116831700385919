import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        position: 'relative',
    },
    newObject: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    locationWrapper: {
        minWidth: '500px',
    },
    addPartGroupIcon: {
        padding: '10px !important',
        borderRadius: '25px !important',
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
    },
}));
