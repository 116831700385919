import { IoImageOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
} from '@mui/material';
import clsx from 'clsx';
import Dropzone from 'react-dropzone';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { panelStyles } from 'GeminiViewerComponent/components/styles';
import useStyles from '../styles';

function PanoImportDropzoneDialog({ setOpenDialog, handleFileChange }) {
    const theme = useSelector(selectActiveTheme);
    const panelClasses = panelStyles(theme);
    const classes = useStyles();

    return (
        <Dialog
            open={true}
            onClose={() => setOpenDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEscapeKeyDown
            disableEnforceFocus={false}
            fullWidth={true}
            maxWidth="sm"
        >
            <Dropzone
                maxFiles={100}
                maxSize={250000000}
                multiple={true}
                accept={['image/jpeg', 'image/jpg', 'image/png']}
                onDropAccepted={handleFileChange}
            >
                {({ getRootProps, getInputProps }) => (
                    <div
                        {...getRootProps()}
                        className={clsx(
                            panelClasses.dropzoneArea,
                            classes.dropZoneDiv
                        )}
                    >
                        <input {...getInputProps()} />
                        <DialogTitle
                            className={classes.dropZoneDialogTitle}
                            id="alert-dialog-title"
                        >
                            Start By Adding Panos
                        </DialogTitle>
                        <DialogContent
                            className={classes.dropZoneDialogContent}
                        >
                            <DialogContentText
                                id="alert-dialog-description"
                                className={classes.dropZoneDialogContentText1}
                                component="div"
                            >
                                Drag and drop 360 pano images here
                                <br /> --or--
                                <br /> Click here to select images
                                <div
                                    style={{ width: '100%', margin: '10px 0' }}
                                >
                                    <IoImageOutline
                                        className={classes.dropZonePictureIcon}
                                    />
                                </div>
                            </DialogContentText>
                            <DialogContentText
                                id="alert-dialog-description"
                                className={classes.dropZoneDialogContentText2}
                            >
                                Image must be equilateral with 2:1 aspect
                                <br /> ratio
                            </DialogContentText>
                            <DialogContentText
                                id="alert-dialog-description"
                                className={classes.dropZoneDialogContentText3}
                            >
                                After adding images, rename panos on
                                <br /> the left and select to preview
                            </DialogContentText>
                        </DialogContent>
                    </div>
                )}
            </Dropzone>
        </Dialog>
    );
}

export { PanoImportDropzoneDialog };
