import { config } from 'gemini-config';

import { getNetworkService } from 'GeminiViewerComponent/_helpers';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import { clamp } from 'GeminiViewerComponent/_helpers/lodashUtils';

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});

const baseUrl = `${config.apiUrl}/navlink`;

const cleanLinks = (links) => {
    return links.map((link) => {
        return {
            ...link,
            ...{
                flat_x: clamp(link.flat_x, 0, 1),
                flat_y: clamp(link.flat_y, 0, 1),
            },
        };
    });
};

const getAll = async (zoneId) => {
    try {
        const response = await networkService.get(
            `${baseUrl}/getallbyzoneid/${zoneId}`
        );
        const navLinks = cleanLinks(response.data);
        return { zone_id: zoneId, nav_links: navLinks };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const create = async (params) => {
    try {
        const response = await networkService.post(baseUrl, params);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getById = async (id) => {
    try {
        const response = await networkService.get(`${baseUrl}/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getExportById = async (id) => {
    try {
        const response = await networkService.get(`${baseUrl}/${id}/export`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const update = async (id, params) => {
    try {
        const response = await networkService.put(`${baseUrl}/${id}`, params);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _delete = async (id) => {
    try {
        const response = await networkService.delete(`${baseUrl}/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const navLinkService = {
    create: create,
    delete: _delete,
    getAll,
    getById,
    getExportById,
    update: update,
};
