import { Backdrop, LinearProgress, Typography } from '@mui/material';
import clsx from 'clsx';
import { loadingStyles } from './styles';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';

function LoadingOverlay({
    showBackdrop,
    showProgress,
    progress,
    label,
    description,
    noAnimation,
}) {
    const classes = loadingStyles();

    const barClasses = noAnimation
        ? { bar: classes.progressBarNoAnimation }
        : {};

    return (
        <Backdrop className={clsx(classes.loadingSpinner)} open={showBackdrop}>
            <div>
                <LoadingLogo
                    styleClassName={classes.loadingSvg}
                    rotateClassName={classes.rotateSvg}
                />

                {showProgress && (
                    <div className={classes.progressArea}>
                        {label}
                        <LinearProgress
                            className={classes.progressBar}
                            classes={barClasses}
                            variant="determinate"
                            value={progress}
                        />
                        {description && (
                            <Typography mt={1}>{description}</Typography>
                        )}
                    </div>
                )}
            </div>
        </Backdrop>
    );
}

export { LoadingOverlay };
