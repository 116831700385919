/**
 * This slice is to manage the procedures display view
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { procedureService } from '_features/_services';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { updateActiveAsset } from 'GeminiViewerComponent/_features/asset/assetSlice';
import {
    active_node_actions,
    highlight_visibility,
    node_highlighting,
    orientations,
    smoothness,
} from 'components/ProcedureDesigner/FlowSettingsOptions';
import { prefixUrl } from 'GeminiViewerComponent/_helpers';

const initialState = {
    error: null,
    savingError: null,
    isDirty: false,
    procedureTypes: [],
    savingStatus: SavingStatus.Idle,
    procedureTypesStatus: LoadingStatus.Idle,
    flowConfigs: {
        smoothness: smoothness.Low.id,
        orientation: orientations.TB.id,
        active_action: active_node_actions.Center.id,
        node_highlight: node_highlighting.Incoming.id,
        highlight_visibility: highlight_visibility.Normal.id,
        // expand_branches: false,
    },
    createNodeData: null,
    procedureData: { originalData: null, unsavedData: null },
    fieldPreview: { previewAction: null, previewField: null },
};

export const getProcedureTypes = createAsyncThunk(
    'procedures/getProcedureTypes',
    async () => {
        return await procedureService.getProcedureTypes();
    }
);

export const getProcedureById = createAsyncThunk(
    'procedures/getProcedureById',
    async (procedure_id) => {
        return await procedureService.getById(procedure_id);
    }
);

export const createProcedure = createAsyncThunk(
    'procedures/createProcedure',
    async (params) => await procedureService.create(params)
);

export const updateProcedure = createAsyncThunk(
    'procedures/updateProcedure',
    async (params, { dispatch, getState }) => {
        const response = await procedureService.update(params);
        if (!response.error) {
            response.procedure.procedure_id = response.procedure_id;
            dispatch(
                updateActiveAsset({
                    procedure: {
                        ...response.procedure,
                        header_image_url: prefixUrl(
                            response.header_image_url,
                            getState().accounts.activeUser.s3_prefix
                        ),
                    },
                    procedure_json: response.procedure_json,
                    asset_id: params.asset_id,
                    s3_prefix: getState().accounts.activeUser.s3_prefix,
                })
            );
        }
        return response;
    }
);

const proceduresSlice = createSlice({
    name: 'procedures',
    initialState,
    reducers: {
        setProcedureDirty: (state, action) => {
            state.isDirty = action.payload;
        },
        setProcedureFlowConfigs: (state, action) => {
            state.flowConfigs = { ...state.flowConfigs, ...action.payload };
        },
        setProcedureCreateNodeData: (state, action) => {
            state.createNodeData = action.payload;
        },
        setOriginalProcedureData: (state, action) => {
            state.procedureData = {
                originalData: action.payload,
                unsavedData: action.payload,
            };
        },
        setUnsavedProcedureData: (state, action) => {
            let node = action.payload;
            let nodes = state.procedureData?.unsavedData?.nodes ?? [];
            if (nodes?.find((nd) => nd?.id === node?.id)) {
                nodes = nodes.map((nd) => {
                    if (nd?.id === node?.id) {
                        return node;
                    }
                    return nd;
                });
            } else {
                nodes = [...nodes, node];
            }
            state.procedureData.unsavedData.nodes = nodes;
        },
        setFieldPreview: (state, action) => {
            state.fieldPreview.previewAction = action.payload?.previewAction;
            state.fieldPreview.previewField = action.payload?.field;
        },
    },
    extraReducers: {
        [getProcedureTypes.pending]: (state) => {
            state.procedureTypesStatus = LoadingStatus.Loading;
        },
        [getProcedureTypes.fulfilled]: (state, action) => {
            state.procedureTypesStatus = LoadingStatus.Loaded;
            state.procedureTypes = action.payload.procedure.procedure_types;
        },
        [getProcedureTypes.rejected]: (state) => {
            state.procedureTypesStatus = LoadingStatus.Failed;
        },
        [createProcedure.pending]: (state) => {
            state.savingStatus = SavingStatus.Saving;
        },
        [createProcedure.fulfilled]: (state) => {
            state.savingStatus = SavingStatus.Saved;
            state.procedureStatus = LoadingStatus.Idle;
        },
        [createProcedure.rejected]: (state, action) => {
            state.savingStatus = SavingStatus.Failed;
            state.savingError = action.error.message;
        },
        [updateProcedure.pending]: (state) => {
            state.savingStatus = SavingStatus.Pending;
        },
        [updateProcedure.fulfilled]: (state, action) => {
            action.payload.procedure.procedure_id = action.payload.procedure_id;
            state.procedure = action.payload.procedure;
            state.procedure_json = action.payload.procedure_json;
            state.savingStatus = SavingStatus.Saved;
        },
        [updateProcedure.rejected]: (state, action) => {
            state.savingStatus = SavingStatus.Failed;
            state.savingError = action.error.message;
        },
        [getProcedureById.fulfilled]: (state, action) => {
            state.procedureStatus = LoadingStatus.Idle;
            state.procedureData = {
                originalData: action.payload.procedure,
                unsavedData: action.payload.procedure,
            };
        },
        [getProcedureById.rejected]: (state, action) => {
            state.savingStatus = SavingStatus.Failed;
            state.savingError = action.error.message;
        },
    },
});

export const selectProcedureSavingStatus = (state) =>
    state.procedures.savingStatus;
export const selectProcedureTypes = (state) => {
    return state.procedures.procedureTypes;
};
export const selectProcedureTypesStatus = (state) =>
    state.procedures.procedureTypesStatus;

export const getProcedureDirty = (state) => state.procedures.isDirty;
export const getProcedureFlowConfigs = (state) => state.procedures.flowConfigs;
export const getProcedureCreateNodeData = (state) =>
    state.procedures.createNodeData;
export const getProcedureUnsavedData = (state) =>
    state.procedures.procedureData.unsavedData;
export const getProcedureOriginalData = (state) =>
    state.procedures.procedureData.originalData;
export const getPreviewField = (state) => state.procedures.fieldPreview;

export const {
    setProcedureDirty,
    setProcedureFlowConfigs,
    setProcedureCreateNodeData,
    setOriginalProcedureData,
    setUnsavedProcedureData,
    setFieldPreview,
} = proceduresSlice.actions;

export default proceduresSlice.reducer;
