import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAllZoneData } from './useAllZoneData';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import { getTagFilters } from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import {
    isZoneVisible,
    zoneHasTag,
} from 'GeminiViewerComponent/_helpers/hotspot_helpers';

export const useAvailableZones = () => {
    const tagFilters = useSelector(getTagFilters);
    const allZoneData = useAllZoneData();
    const config = useSelector(getConfig);

    return useMemo(() => {
        let availableZones = {};
        let selected_item_tags = [];
        if (
            Array.isArray(tagFilters.item_tags) &&
            tagFilters.item_tags.length > 0
        ) {
            tagFilters.item_tags.forEach((tag) => {
                selected_item_tags.push(tag.tag_id);
            });
            allZoneData.forEach((zone) => {
                let zone_tags = [];
                zone.item_tags.forEach((tag) => {
                    zone_tags.push(tag.tag_id);
                });
                if (
                    zoneHasTag(
                        tagFilters.zone_tags.map((tag) => tag.tag_id),
                        zone
                    ) &&
                    (config.user.role.toUpperCase() === 'ADMIN' ||
                        isZoneVisible(config.user.audienceIds, zone)) &&
                    zone_tags.length > 0 &&
                    zone_tags.filter((tag) => selected_item_tags.includes(tag))
                        .length > 0
                ) {
                    availableZones[zone.zone_id] = zone;
                }
            });
        } else {
            allZoneData.forEach((zone) => {
                if (
                    zoneHasTag(
                        tagFilters.zone_tags.map((tag) => tag.tag_id),
                        zone
                    ) &&
                    (config.user.role.toUpperCase() === 'ADMIN' ||
                        isZoneVisible(config.user.audienceIds, zone))
                ) {
                    availableZones[zone.zone_id] = zone;
                }
            });
        }
        return availableZones;
    }, [allZoneData, config.user.role, config.user.audienceIds, tagFilters]);
};
