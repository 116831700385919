import { getAccountService } from './account.service';
import {
    getNetworkService,
    FormDataUtils,
} from 'GeminiViewerComponent/_helpers';

export const getClientService = ({ config }) => {
    const baseUrl = `${config.apiUrl}/client`;
    const accountService = getAccountService({ config: config });
    const networkService = getNetworkService({
        config: config,
        checkAuth: accountService.checkAuth,
    });

    const getAllClients = async () => {
        const response = await networkService.get(`${baseUrl}`);
        return response.data;
    };

    const getClientConfig = async (id) => {
        const response = await networkService.get(`${baseUrl}/${id}`);
        if (response.data.config_json) {
            response.data.config_json = JSON.parse(response.data.config_json);
        } else {
            response.data.config_json = [];
        }
        return response.data;
    };

    const updateClientConfig = async (id, params) => {
        params.config_json = params.config_json
            ? JSON.stringify(params.config_json)
            : null;
        const response = await networkService.put(`${baseUrl}/${id}`, params);
        return response.data;
    };

    const update = async (id, params) => {
        var formData = new FormData();
        FormDataUtils.safeAppend(formData, [
            ['active_theme', params.active_theme],
            ['themes_json', JSON.stringify(params.themes)],
        ]);
        const response = await networkService.putMultiFormData(
            `${baseUrl}/${id}`,
            formData
        );

        if (response.data.themes_json) {
            response.data.themes = JSON.parse(response.data.themes_json);
            delete response.data.themes_json;
        }

        return response.data;
    };

    const newThemeData = async (id, params) => {
        let data = { theme_id: params?.theme_id };
        if (params?.source_theme_id) {
            data = { ...data, source_theme_id: params?.source_theme_id };
        }
        const response = await networkService.put(
            `${baseUrl}/${id}/NewThemeData`,
            data
        );
        return response.data;
    };

    const updateCustomThemeData = async (id, params) => {
        let formData = new FormData();
        FormDataUtils.safeAppend(formData, [
            ['theme_id', params?.theme_id],
            ['navigation_hamburger_icon', params?.navigation_hamburger_icon],
            ['navigation_back_icon', params?.navigation_back_icon],
            ['navigation_home_icon', params?.navigation_home_icon],
            ['zone_media_icon', params?.zone_media_icon],
            [
                'navigation_hamburger_icon_reset',
                params?.navigation_hamburger_icon_reset,
            ],
            ['navigation_back_icon_reset', params?.navigation_back_icon_reset],
            ['navigation_home_icon_reset', params?.navigation_home_icon_reset],
            ['zone_media_icon_reset', params?.zone_media_icon_reset],
        ]);
        const response = await networkService.putMultiFormData(
            `${baseUrl}/${id}/CustomThemeData`,
            formData
        );

        return response.data;
    };

    return {
        getAllClients,
        getClientConfig,
        updateClientConfig,
        updateCustomThemeData,
        update,
        newThemeData,
    };
};
