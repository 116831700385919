import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { BiComment } from 'react-icons/bi';
import { styled } from '@mui/material/styles';
import { PinAction, PopupActions } from 'components';
import { FiExternalLink } from 'react-icons/fi';
import LinearProgress, {
    linearProgressClasses,
} from '@mui/material/LinearProgress';
import {
    MdArrowDownward,
    MdArrowUpward,
    MdCheck,
    MdDownload,
    MdInfoOutline,
    MdOpenInNew,
    MdSearch,
} from 'react-icons/md';
import {
    IconButton,
    Link as MUILink,
    Tooltip,
    Typography,
    Button,
    TableCell,
    InputBase,
    Checkbox,
    Box,
    Stack,
    Chip,
    tooltipClasses,
} from '@mui/material';

import { tableStyles } from './styles';
import AppButton from 'components/AppButton';
import { get } from 'GeminiViewerComponent/_helpers/lodashUtils';
import getActionIcon from 'GeminiViewerComponent/_helpers/getActionIcon';
import {
    ContentTypes,
    Action,
    AssetExportJobStatus,
    DeploymentPackageStatus,
} from '_helpers';
import {
    setContentValidationModalContent,
    setGenericModalContent,
    toggleContentValidationModal,
    toggleGenericModal,
} from '_features/globals/visibilitySlice';
import { Each } from 'GeminiViewerComponent/_helpers/Each';
import React from 'react';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 5,
    border: `1px solid ${'#3cbc9b'}`,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#808080',
    },
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: '#3cbc9b',
    },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: '#ffffff',
        boxShadow: '5px 5px 17px rgb(0 0 0 / 47%)',
        maxWidth: 320,
        maxHeight: '300px',
        overflow: 'auto',
        padding: '1rem 0.8rem',
        borderRadius: '0.5rem',
    },
}));

const headerHeight = 40;
const rowHeight = 60;

function resizableCheckCell(props, column) {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { isSelected, handleClick, cellLevel } = column;
    const rowDataId = rowData[`${cellLevel}_id`];
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            style={{ height: rowHeight }}
        >
            <Checkbox
                checked={isSelected(rowDataId)}
                onClick={(event) =>
                    handleClick(event, rowDataId, rowData.display_name)
                }
            />
        </TableCell>
    );
}

function resizableCheckHeaderCell(props, column) {
    const tableClasses = tableStyles();
    const { numSelected, rowCount, handleSelectAllClick, hasHeaderSearchBar } =
        column;

    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(
                tableClasses.tableCell,
                tableClasses.flexContainer,
                tableClasses.noClick,
                hasHeaderSearchBar && tableClasses.searchBarWrapperAll
            )}
            variant="head"
            style={{ height: headerHeight }}
        >
            <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={handleSelectAllClick}
            />
        </TableCell>
    );
}

function resizableActionCell(props, column) {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { actionCellLevel, childComponents, targetPopup, displayContent } =
        column;
    return (
        <TableCell
            padding={column.padding}
            className={clsx(
                tableClasses.popupActions,
                tableClasses.tableCell,
                tableClasses.flexContainer
            )}
            component="div"
            style={{
                height: rowHeight,
                justifyContent:
                    displayContent === 'start' ? 'flex-start' : 'flex-end',
            }}
        >
            {childComponents?.map((Component) => {
                return (
                    <Component
                        key={rowData[`${actionCellLevel}_id`]}
                        rowData={rowData}
                    />
                );
            })}
            <PopupActions
                object={rowData}
                level={actionCellLevel}
                targetPopup={targetPopup}
                size="small"
            />
        </TableCell>
    );
}

function resizableProgressStatusCell(props, column) {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { onClick, accessorKey, padding, numeric } = column;
    let data = rowData[accessorKey];
    if (column.transform !== undefined) {
        data = column.transform(data, column);
    }
    return (
        <TableCell
            component="div"
            padding={padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            variant="body"
            style={{ height: rowHeight, width: '200px', userSelect: 'none' }}
            align={numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            <div
                style={{
                    display: 'flex',
                    position: 'relative',
                    width: '100%',
                    justifyContent: 'center',
                }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    {column?.deploymentStatus ? (
                        <BorderLinearProgress
                            variant="determinate"
                            value={
                                rowData['status_id'] === 0
                                    ? 0
                                    : rowData['status_id'] === 2
                                    ? 100
                                    : 0
                            }
                        />
                    ) : (
                        <BorderLinearProgress
                            variant="determinate"
                            value={rowData['progress_percentage'] ?? 0}
                        />
                    )}
                </Box>
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        color: '#ffffff',
                        fontWeight: 600,
                    }}
                >
                    {data}
                </div>
            </div>
        </TableCell>
    );
}

function resizableAssetExportDownloadLink(props, column) {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { onClick, accessorKey, padding, numeric } = column;
    let data = rowData[accessorKey];
    if (column.transform !== undefined) {
        data = column.transform(data, column);
    }
    return (
        <TableCell
            component="div"
            padding={padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            {rowData['status_id'] === AssetExportJobStatus.Complete.id && (
                <MUILink
                    href={rowData['file_url']}
                    rel="noreferrer"
                    target="_blank"
                    download={true}
                    sx={{
                        ':hover': { color: '#34485e' },
                        ':visited': { color: '#297fb9' },
                        color: '#297fb9',
                        textDecoration: 'none',
                        display: 'inline-block',
                    }}
                    title={data}
                >
                    <Typography
                        component="span"
                        sx={{
                            whiteSpace: { xs: 'nowrap', md: 'inherit' },
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: { xs: '150px', md: 'auto' },
                            display: { xs: 'inline-block', md: 'inline' },
                        }}
                        variant="subtitle2"
                    >
                        {data}
                    </Typography>
                    <IconButton aria-label="close">
                        <MdDownload
                            className="react-icon"
                            style={{ fontSize: '16px' }}
                        />
                    </IconButton>
                </MUILink>
            )}
        </TableCell>
    );
}

function resizableViewLink(props, column) {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { dispatch } = column;

    const handleViewLink = (content) => {
        if (content?.display_type_id === 2) {
            window.open(content.url, '_blank');
        } else {
            dispatch(setContentValidationModalContent(content));
            dispatch(toggleContentValidationModal());
        }
    };

    return (
        <TableCell
            component="div"
            scope="row"
            padding={column.padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            style={{ height: rowHeight, cursor: 'pointer' }}
        >
            <span
                onClick={() => handleViewLink(rowData)}
                className={clsx(tableClasses.gridLink, tableClasses.launchLink)}
            >
                <FiExternalLink />
            </span>
        </TableCell>
    );
}

function resizableContentTypeCell(props, column) {
    const tableClasses = tableStyles();
    const { cellData } = props;
    const { padding, numeric } = column;
    let data = cellData;
    return (
        <TableCell
            component="div"
            padding={padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={numeric ? 'right' : 'left'}
        >
            {Object.keys(ContentTypes).find(
                (at) => ContentTypes[at].id === data
            )}
        </TableCell>
    );
}

function resizableExistingContentTypeCell(props, column) {
    const tableClasses = tableStyles();
    const { cellData, rowData } = props;
    const { onClick } = column;
    let data = cellData;
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            {Object.keys(ContentTypes).find(
                (at) => ContentTypes[at].id === data
            )}
        </TableCell>
    );
}

function resizableContentButtonTypeCell(props, column) {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { accessorKey, padding, numeric, onClick, displayDataKey } = column;
    let data = rowData?.[accessorKey];
    return (
        <TableCell
            component="div"
            padding={padding}
            className={clsx(tableClasses.tableCell)}
            variant="body"
            style={{ height: rowHeight, overflow: 'auto' }}
            align={numeric ? 'right' : 'left'}
        >
            {data?.length > 0 &&
                data.map((at, index) => (
                    <label
                        className={tableClasses.usageLabel}
                        key={displayDataKey + index}
                        onClick={(event) => {
                            if (typeof onClick === 'function') {
                                if (accessorKey === 'asset_usage') {
                                    onClick(event, at['asset_id']);
                                } else if (accessorKey === 'zone_usage') {
                                    onClick(event, at, at['asset_id']);
                                } else if (accessorKey === 'item_usage') {
                                    onClick(event, at, at['asset_id'], at);
                                }
                            }
                        }}
                    >
                        {at[displayDataKey]}
                    </label>
                ))}
        </TableCell>
    );
}

function resizableTagButtonTypeCell(props, column) {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { accessorKey, padding, numeric, onClick, displayDataKey } = column;
    let data = rowData?.tag_usage?.[accessorKey];
    return (
        <TableCell
            component="div"
            padding={padding}
            className={clsx(tableClasses.tableCell)}
            variant="body"
            style={{ height: rowHeight, overflow: 'auto' }}
            align={numeric ? 'right' : 'left'}
        >
            {data?.length > 0 &&
                data.map((at, index) => (
                    <label
                        className={tableClasses.usageLabel}
                        key={displayDataKey + index}
                        onClick={(event) => {
                            if (typeof onClick === 'function') {
                                if (accessorKey === 'assets') {
                                    onClick(event, at['assetId']);
                                } else if (accessorKey === 'zones') {
                                    onClick(event, at, at['asset_id']);
                                } else if (accessorKey === 'items') {
                                    onClick(event, at, at['asset_id'], at);
                                }
                            }
                        }}
                    >
                        {at[displayDataKey]}
                    </label>
                ))}
        </TableCell>
    );
}

function resizableToolButtonTypeCell(props, column) {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { accessorKey } = column;
    let data = rowData?.[accessorKey];

    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(tableClasses.tableCell)}
            variant="body"
            style={{ height: rowHeight, overflow: 'auto' }}
            align={column.numeric ? 'right' : 'left'}
        >
            {data?.length > 0 &&
                data.map((at, index) => (
                    <label
                        className={tableClasses.usageLabel}
                        key={column?.displayDataKey + index}
                        onClick={() => {
                            if (column?.onClick) {
                                if (column?.dataKey === 'locations') {
                                    column.onClick(at['tool_location_id']);
                                } else {
                                    column.onClick(at, at['tool_location_id']);
                                }
                            }
                        }}
                    >
                        {at[column?.displayDataKey]}
                    </label>
                ))}
        </TableCell>
    );
}

function resizablePartButtonTypeCell(props, column) {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { accessorKey } = column;
    let data = rowData?.[accessorKey];
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(tableClasses.tableCell)}
            variant="body"
            style={{ height: rowHeight, overflow: 'auto' }}
            align={column.numeric ? 'right' : 'left'}
        >
            {data?.length > 0 &&
                data.map((at, index) => (
                    <label
                        className={tableClasses.usageLabel}
                        key={column?.displayDataKey + index}
                        onClick={() => {
                            if (column?.onClick) {
                                if (column?.dataKey === 'groups') {
                                    column.onClick(at['part_group_id']);
                                } else {
                                    column.onClick(at, at['part_group_id']);
                                }
                            }
                        }}
                    >
                        {at[column?.displayDataKey]}
                    </label>
                ))}
        </TableCell>
    );
}

function resizableNoOfRecordCell(props, column) {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { accessorKey } = column;
    let data = rowData?.[accessorKey];

    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(tableClasses.tableCell)}
            variant="body"
            style={{ height: rowHeight, overflow: 'auto' }}
            align={column.numeric ? 'right' : 'left'}
        >
            {Array.isArray(data) ? data.length : 0}
        </TableCell>
    );
}

function resizableActiveCell(props, column) {
    const tableClasses = tableStyles();
    const { padding, numeric, accessorKey } = column;
    let data = props[accessorKey];
    if (column.transform !== undefined) {
        data = column.transform(data);
    }

    const active = data ? (
        <div
            className={clsx(
                tableClasses.activeCellContainer,
                tableClasses.activeCell
            )}
        >
            Active
        </div>
    ) : (
        <div
            className={clsx(
                tableClasses.activeCellContainer,
                tableClasses.inactiveCell
            )}
        >
            Inactive
        </div>
    );

    return (
        <TableCell
            component="div"
            padding={padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={numeric ? 'right' : 'left'}
        >
            {active}
        </TableCell>
    );
}

function resizableTagCell(props, column) {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { onClick, childComponents, width } = column;

    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            variant="body"
            style={{ height: rowHeight, width: width }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            {childComponents?.map((Component) => {
                return <Component key={rowData?.item_id} rowData={rowData} />;
            })}
        </TableCell>
    );
}
function resizableShortcutCell(props, column) {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { onClick, childComponents, displayAsset } = column;

    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            variant="body"
            style={{ height: '60px', minHeight: '60px', overflow: 'hidden' }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            {childComponents?.map((Component) => {
                return (
                    <Component
                        key={rowData?.item_id}
                        displayAsset={displayAsset}
                        rowData={rowData}
                    />
                );
            })}
        </TableCell>
    );
}

function resizableLabelCell(props, column) {
    const tableClasses = tableStyles();
    const { onClick, assetLabel, rowHeight: height, accessorKey } = column;
    let data = props[accessorKey];
    let toolTip = props['toolTip'] ?? null;

    if (column.transform !== undefined) {
        data = column.transform(data, props);
    }
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(
                tableClasses.tableCell,
                tableClasses.scrollingFlexContainer
            )}
            variant="body"
            style={{
                height: height ? height : rowHeight,
                width: '100%',
                userSelect: 'none',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
            }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(props) : null}
        >
            <Tooltip title={toolTip ?? data}>
                <Typography
                    overflow="hidden"
                    textOverflow="ellipsis"
                    display="inline-block"
                    whiteSpace="nowrap"
                    fontSize={height ? '0.9rem' : '1rem'}
                    maxWidth="100%"
                >
                    {data}
                </Typography>
            </Tooltip>
            {assetLabel === true && props?.description && (
                <Typography
                    fontSize="11px"
                    width="100%"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    maxWidth="100%"
                >
                    {props.description}
                </Typography>
            )}
        </TableCell>
    );
}

function resizableConditionalLabelCell(props, column) {
    const tableClasses = tableStyles();
    const { onClick, size: width, conditionalFn, accessorKey } = column;
    let data = props[accessorKey];
    if (column?.transform !== undefined) {
        data = column.transform(data);
    }
    return (
        <TableCell
            component="div"
            padding={column?.padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            variant="body"
            style={{
                height: rowHeight,
                width: width,
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
            }}
            align={column?.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(column) : null}
        >
            <Tooltip title={data}>
                <Typography
                    overflow="hidden"
                    textOverflow="ellipsis"
                    display="inline-block"
                    whiteSpace="nowrap"
                >
                    {typeof conditionalFn === 'function'
                        ? conditionalFn(data)
                        : data}
                </Typography>
            </Tooltip>
        </TableCell>
    );
}

function resizableHotspotDefaultIcon(props, column) {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { onClick, padding, accessorKey } = column;
    let data = rowData[accessorKey];
    return (
        <TableCell
            component="div"
            padding={padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={'center'}
            onClick={
                onClick
                    ? (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onClick(rowData);
                      }
                    : null
            }
        >
            {data ? (
                <MdCheck
                    className="react-icon"
                    style={{ marginLeft: '10px' }}
                />
            ) : accessorKey === 'is_default' ||
              (accessorKey === 'is_highlight' &&
                  rowData.hotspot_type_id === 1) ? (
                <AppButton
                    style={{ marginLeft: '-10px' }}
                    text={'Set'}
                    className={'primaryButton'}
                />
            ) : null}
        </TableCell>
    );
}

function resizableHotspotPopulatedIcon(props, column) {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { onClick, padding, accessorKey } = column;
    let data = rowData[accessorKey];
    return (
        <TableCell
            component="div"
            padding={padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={'center'}
            onClick={
                onClick
                    ? (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onClick(rowData);
                      }
                    : null
            }
        >
            {data ? (
                <MdCheck
                    className="react-icon"
                    style={{ marginLeft: '10px' }}
                />
            ) : accessorKey === 'is_populated' ||
              accessorKey === 'is_unpopulated' ? (
                <AppButton
                    style={{ marginLeft: '-10px' }}
                    text={'Set'}
                    className={'primaryButton'}
                />
            ) : null}
        </TableCell>
    );
}

function resizableZonePreviewLabelCell(props, column) {
    const tableClasses = tableStyles();
    const { onClick, accessorKey, padding, numeric } = column;

    let data = props[accessorKey];
    if (column.transform !== undefined) {
        data = column.transform(data, column);
    }

    const previewImage = props.equirect_image_preview_url
        ? props.equirect_image_preview_url
        : props.flat_image_preview_url
        ? props.flat_image_preview_url
        : null;

    return (
        <TableCell
            component="div"
            padding={padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            variant="body"
            style={{ height: rowHeight, width: '100%' }}
            align={numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(props) : null}
        >
            <span
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    alignContent: 'center',
                    justifyContent: 'left',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                {previewImage && (
                    <img
                        src={previewImage}
                        alt={`${previewImage}`.toLowerCase()}
                        style={{
                            maxWidth: rowHeight - 10,
                            maxHeight: rowHeight,
                            padding: '5px 5px 5px 0px',
                        }}
                    />
                )}
                <Tooltip title={data}>
                    <Typography
                        overflow="hidden"
                        textOverflow="ellipsis"
                        display="inline-block"
                        whiteSpace="nowrap"
                    >
                        {data}
                    </Typography>
                </Tooltip>
            </span>
        </TableCell>
    );
}

function resizableHotspotPreviewCell(props, column) {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { onClick, padding, numeric } = column;

    const previewImage = rowData.image_url ? rowData.image_url : null;

    return (
        <TableCell
            component="div"
            padding={padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            <span
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {previewImage && (
                    <img
                        src={previewImage}
                        alt={`${previewImage}`.toLowerCase()}
                        style={{
                            width: rowHeight - 20,
                            height: 'auto',
                            padding: '5px 5px 5px 0px',
                        }}
                    />
                )}
            </span>
        </TableCell>
    );
}

function resizableStatusCell(props, column) {
    const tableClasses = tableStyles();
    const { cellData, rowData } = props;
    const { onClick } = column;
    let data = cellData;

    if (column.transform !== undefined) {
        data = column.transform(cellData);
    }

    let statusCellType;
    if (data === 'approved') {
        statusCellType = tableClasses.approvedCell;
    } else if (data === 'pending for review') {
        statusCellType = tableClasses.pendingCell;
    } else if (data === 'rejected') {
        statusCellType = tableClasses.rejectedCell;
    }
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            <div className={clsx(tableClasses.statusCell, statusCellType)}>
                {data}
            </div>
        </TableCell>
    );
}

function resizableFeedbackCell(props, column) {
    const tableClasses = tableStyles();
    const { cellData, rowData } = props;
    const { onClick } = column;
    let data = cellData;

    if (column.transform !== undefined) {
        data = column.transform(cellData);
    }
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            {data === 'yes' ? <BiComment size={25} color="#BDC3C7" /> : null}
        </TableCell>
    );
}

function resizableLabelHeader(props, column) {
    const tableClasses = tableStyles();
    const {
        accessorKey,
        label,
        padding,
        numeric,
        sortDirection,
        sortBy,
        handleSort,
        allowHeaderSearchBar = false,
        onSearchChange,
        hasHeaderSearchBar = false,
    } = column;

    const isAscendingOrder = sortDirection === 'ASC' && accessorKey === sortBy;
    const activeSort =
        sortBy == accessorKey
            ? { fontSize: '16px', color: 'black' }
            : { fontSize: '16px' };
    return (
        <TableCell
            component="div"
            padding={padding}
            className={clsx(
                tableClasses.tableCell,
                tableClasses.flexContainer,
                tableClasses.noClick,
                tableClasses.tableCellHeader,
                hasHeaderSearchBar && tableClasses.searchBarWrapperAll,
                allowHeaderSearchBar && tableClasses.searchBarWrapper
            )}
            variant="head"
            style={{
                height: headerHeight,
                userSelect: 'none',
            }}
            align={numeric ? 'right' : 'left'}
        >
            {typeof handleSort === 'function' ? (
                <div
                    className={tableClasses.sortIndicatorContainer}
                    onClick={() =>
                        handleSort(
                            accessorKey,
                            isAscendingOrder ? 'DESC' : 'ASC'
                        )
                    }
                >
                    <>
                        <span>{label}</span>
                        {isAscendingOrder ? (
                            <MdArrowUpward
                                className="react-icon"
                                style={activeSort}
                            />
                        ) : (
                            <MdArrowDownward
                                className="react-icon"
                                style={activeSort}
                            />
                        )}
                    </>
                </div>
            ) : (
                <span>{label}</span>
            )}
            {allowHeaderSearchBar && (
                <div className={tableClasses.search}>
                    <div className={tableClasses.searchIcon}>
                        <MdSearch className="react-icon" />
                    </div>
                    <InputBase
                        placeholder="Search…"
                        classes={{
                            root: tableClasses.inputRoot,
                            input: tableClasses.inputInput,
                        }}
                        onChange={onSearchChange}
                        inputProps={{ type: 'search' }}
                    />
                </div>
            )}
        </TableCell>
    );
}

function resizableLabelHeaderWithSearch(props, column) {
    const tableClasses = tableStyles();
    const {
        accessorKey,
        label,
        sortDirection,
        sortBy,
        handleSort,
        allowHeaderSearchBar = false,
        onSearchChange,
        hasHeaderSearchBar = false,
        isOpenSearch = false,
        toggleOpenSearch = () => {},
        searchString,
    } = column;
    const isAscendingOrder = sortDirection === 'ASC' && accessorKey === sortBy;
    const activeSort =
        sortBy == accessorKey
            ? { fontSize: '16px', color: 'black' }
            : { fontSize: '16px' };
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(
                tableClasses.tableCell,
                tableClasses.flexContainer,
                tableClasses.noClick,
                tableClasses.tableCellHeader,
                hasHeaderSearchBar && tableClasses.searchBarWrapperAll,
                allowHeaderSearchBar && tableClasses.searchBarWrapper
            )}
            variant="head"
            style={{ height: headerHeight }}
            align={column.numeric ? 'right' : 'left'}
        >
            {typeof handleSort === 'function' ? (
                <div
                    className={tableClasses.sortIndicatorContainer}
                    onClick={() =>
                        handleSort(
                            accessorKey,
                            isAscendingOrder ? 'DESC' : 'ASC'
                        )
                    }
                    style={{ width: 'inherit' }}
                >
                    <span>{label}</span>
                    {isAscendingOrder ? (
                        <MdArrowUpward
                            className="react-icon"
                            style={activeSort}
                        />
                    ) : (
                        <MdArrowDownward
                            className="react-icon"
                            style={activeSort}
                        />
                    )}
                </div>
            ) : (
                <span>{label}&nbsp;</span>
            )}

            {allowHeaderSearchBar && (
                <Tooltip title="Click to Search">
                    <IconButton
                        onClick={toggleOpenSearch}
                        aria-label="search"
                        style={{ padding: 0 }}
                    >
                        <MdSearch className="react-icon" sx={activeSort} />
                    </IconButton>
                </Tooltip>
            )}

            {allowHeaderSearchBar && isOpenSearch && (
                <div className={tableClasses.search}>
                    <div className={tableClasses.searchIcon}>
                        <MdSearch className="react-icon" />
                    </div>
                    <InputBase
                        placeholder="Search…"
                        defaultValue={searchString ? searchString : ''}
                        classes={{
                            root: tableClasses.inputRoot,
                            input: tableClasses.inputInput,
                        }}
                        onChange={onSearchChange}
                        inputProps={{ type: 'search' }}
                    />
                </div>
            )}
        </TableCell>
    );
}

function resizablePinCell(props, column) {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { cellLevel, pinCell } = column;
    const pinId = rowData[`${cellLevel}_id`];
    return (
        <TableCell
            component="div"
            padding={pinCell}
            className={clsx(
                tableClasses.tableCell,
                tableClasses.flexContainer,
                tableClasses.pin,
                { [tableClasses.isPinned]: rowData.is_pinned }
            )}
            style={{ height: rowHeight }}
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            <PinAction pinId={pinId} level={cellLevel} />
        </TableCell>
    );
}

function resizableSeeAssetCell(props, column) {
    const tableClasses = tableStyles();
    const { rowData } = props;
    return (
        <TableCell
            component="div"
            scope="row"
            padding={column.padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            style={{ height: rowHeight }}
        >
            <Link
                to={`/asset/${rowData.asset_id}`}
                className={tableClasses.gridLink}
            >
                <span className={tableClasses.gridLinkSpan}>See More</span>
            </Link>
        </TableCell>
    );
}

function resizableSchooxNestedLabelCell(props, column) {
    const tableClasses = tableStyles();
    const { cellData } = props;
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            variant="body"
            style={{ height: rowHeight, cursor: 'pointer' }}
            align={column.numeric ? 'right' : 'left'}
        >
            {get(cellData, column.nestedDataKey)}
        </TableCell>
    );
}

function resizableAssetTypeIconCell(props, column) {
    const tableClasses = tableStyles();
    const { cellData } = props;
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            variant="body"
            style={{ height: rowHeight, cursor: 'pointer' }}
        >
            {cellData === 1
                ? getActionIcon(Action.Product.iconId)
                : cellData === 2
                ? getActionIcon(Action.Place.iconId)
                : getActionIcon(Action.Procedure.iconId)}
        </TableCell>
    );
}

function resizableAssetDisclaimerIconCell(props, column) {
    const tableClasses = tableStyles();
    const { dispatch, padding } = column;

    const asset = props.rowData;

    return (
        <TableCell
            component="div"
            padding={padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            variant="body"
            style={{ height: rowHeight, cursor: 'pointer' }}
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            {(asset?.disclaimer_tooltip || asset?.disclaimer_html) && (
                <Tooltip
                    title={asset?.disclaimer_tooltip}
                    componentsProps={{
                        tooltip: { className: tableClasses.tooltip },
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        if (
                            asset?.disclaimer_html &&
                            asset.disclaimer_html !== ''
                        ) {
                            dispatch(
                                setGenericModalContent(
                                    asset?.disclaimer_html ??
                                        asset?.disclaimer_tooltip
                                )
                            );
                            dispatch(toggleGenericModal());
                        }
                    }}
                >
                    <Typography
                        display="flex"
                        className={clsx(
                            tableClasses.disclaimer,
                            tableClasses.cardDisclaimer
                        )}
                    >
                        <MdInfoOutline className="react-icon" />
                    </Typography>
                </Tooltip>
            )}
        </TableCell>
    );
}

function resizableItemLabelCell(props, column) {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { onClick, accessorKey } = column;
    let data = rowData[accessorKey];
    if (column.transform !== undefined) {
        data = column.transform(data, rowData);
    }
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(
                tableClasses.tableCell,
                tableClasses.scrollingFlexContainer
            )}
            variant="body"
            style={{ height: rowHeight }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            <Tooltip title={data}>
                <Typography
                    overflow="hidden"
                    textOverflow="ellipsis"
                    display="inline-block"
                    whiteSpace="nowrap"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {data}
                    <Typography
                        fontSize="11px"
                        width="100%"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                    >
                        {rowData.description}
                    </Typography>
                </Typography>
            </Tooltip>
        </TableCell>
    );
}

function resizableCheckBoxCell(props, column) {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { cellLevel, padding } = column;
    const rowDataId = rowData[`${cellLevel}`];
    return (
        <TableCell
            component="div"
            padding={padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            style={{ height: rowHeight }}
        >
            <Checkbox checked={rowDataId} />
        </TableCell>
    );
}

const resizableLabelHeaderDiffSortKey = (props, column) => {
    const tableClasses = tableStyles();
    const { label } = props;
    const {
        sortDirection,
        sortBy,
        handleSort,
        sortKey,
        allowHeaderSearchBar = false,
        onSearchChange,
        hasHeaderSearchBar = false,
    } = column;
    const isAscendingOrder = sortDirection === 'ASC' && sortKey === sortBy;
    const activeSort =
        sortBy == sortKey
            ? { fontSize: '16px', color: 'black' }
            : { fontSize: '16px' };
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(
                tableClasses.tableCell,
                tableClasses.flexContainer,
                tableClasses.noClick,
                tableClasses.tableCellHeader,
                hasHeaderSearchBar && tableClasses.searchBarWrapperAll,
                allowHeaderSearchBar && tableClasses.searchBarWrapper
            )}
            variant="head"
            style={{ height: headerHeight }}
            align={column.numeric ? 'right' : 'left'}
        >
            {typeof handleSort === 'function' ? (
                <div
                    className={tableClasses.sortIndicatorContainer}
                    onClick={() =>
                        handleSort(sortKey, isAscendingOrder ? 'DESC' : 'ASC')
                    }
                >
                    <>
                        <span>{label}</span>
                        {isAscendingOrder ? (
                            <MdArrowUpward
                                className="react-icon"
                                style={activeSort}
                            />
                        ) : (
                            <MdArrowDownward
                                className="react-icon"
                                style={activeSort}
                            />
                        )}
                    </>
                </div>
            ) : (
                <span>{label}</span>
            )}
            {allowHeaderSearchBar && (
                <div className={tableClasses.search}>
                    <div className={tableClasses.searchIcon}>
                        <MdSearch className="react-icon" />
                    </div>
                    <InputBase
                        placeholder="Search…"
                        classes={{
                            root: tableClasses.inputRoot,
                            input: tableClasses.inputInput,
                        }}
                        onChange={onSearchChange}
                        inputProps={{ type: 'search' }}
                    />
                </div>
            )}
        </TableCell>
    );
};

const resizableDeploymentPackageDownloadLink = (props, column) => {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { onClick, accessorKey } = column;
    let data = rowData[accessorKey];
    if (column.transform !== undefined) {
        data = column.transform(data);
    }
    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            variant="body"
            style={{ height: rowHeight }}
            align={column.numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            {rowData['status_id'] === DeploymentPackageStatus.Complete.id && (
                <MUILink
                    href={rowData['file_url']}
                    rel="noreferrer"
                    target="_blank"
                    download={true}
                    sx={{
                        ':hover': { color: '#34485e' },
                        ':visited': { color: '#297fb9' },
                        color: '#297fb9',
                        textDecoration: 'none',
                        display: 'inline-block',
                    }}
                    title={data}
                >
                    <Typography
                        component="span"
                        sx={{
                            whiteSpace: { xs: 'nowrap', md: 'inherit' },
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: { xs: '150px', md: 'auto' },
                            display: { xs: 'inline-block', md: 'inline' },
                        }}
                        variant="subtitle2"
                    >
                        {data}
                    </Typography>
                    <IconButton aria-label="close">
                        <MdDownload
                            className="react-icon"
                            style={{ fontSize: '16px' }}
                        />
                    </IconButton>
                </MUILink>
            )}
        </TableCell>
    );
};

const resizableDynamicLinkCell = (props, column) => {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { setIndex, link, accessorKey, subDataKey, linkParamsKey } = column;
    let data = rowData[accessorKey];
    if (column.transform !== undefined) {
        data = column.transform(data, rowData);
    }
    const handleOpenLink = () => {
        if (linkParamsKey && rowData?.deployment_package) {
            const linkParams = `${link}?id=${rowData?.deployment_package[linkParamsKey]}&tabIndex=${setIndex}`;
            window.open(linkParams, '_blank');
        } else {
            const updatedLink = `${link}?tabIndex=${setIndex}`;
            window.open(updatedLink, '_blank');
        }
    };
    if (subDataKey) {
        data = rowData[accessorKey][subDataKey];
    }

    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(tableClasses.tableCell, tableClasses.flexContainer)}
            style={{ height: rowHeight }}
        >
            <Button onClick={handleOpenLink} endIcon={<MdOpenInNew />}>
                {data || '-'}
            </Button>
        </TableCell>
    );
};

const resizableCategoryTypeCell = (props, column) => {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const { onClick, width, accessorKey, padding, numeric } = column;
    let data = rowData[accessorKey];
    if (column.transform !== undefined) {
        data = column.transform(data, rowData);
    }
    return (
        <TableCell
            component="div"
            padding={padding}
            className={clsx(
                tableClasses.tableCell,
                tableClasses.scrollingFlexContainer
            )}
            variant="body"
            style={{ height: rowHeight, width: width, userSelect: 'none' }}
            align={numeric ? 'right' : 'left'}
            onClick={onClick ? () => onClick(rowData) : null}
        >
            <Typography
                overflow="hidden"
                textOverflow="ellipsis"
                display="inline-block"
                whiteSpace="nowrap"
            >
                {data === 1 ? 'Asset' : data === 3 ? 'Procedure' : 'All'}
            </Typography>
        </TableCell>
    );
};
function resizableUsageDisplayCell(props, column) {
    const tableClasses = tableStyles();
    const { rowData } = props;
    const {
        accessorKey,
        displayDataKey,
        onClick,
        displayDataTransform,
        displayAggregateKey,
        displayAggregateText,
    } = column;
    let data = rowData[accessorKey];
    if (column.transform !== undefined) {
        data = column.transform(data, rowData);
    }
    let isGrouped = false;
    let groupedData = {};
    if (displayAggregateKey !== undefined) {
        for (let i = 0; i < data.length; i++) {
            let curData = data[i];
            let curKey = curData[displayAggregateKey];

            if (groupedData[curKey] === undefined) {
                groupedData[curKey] = {
                    data: [],
                    text: curData[displayAggregateText],
                };
            }

            groupedData[curKey].data.push(curData);
        }

        isGrouped = true;
    }

    const RenderGroupedChips = ({
        groupedData,
        displayDataTransform,
        accessorKey,
        onClick,
    }) => {
        let aggKeys = Object.keys(groupedData);
        return aggKeys.map((xKey, idx) => {
            let x = groupedData[xKey];
            return (
                <div
                    className={tableClasses.aggTooltipGrouping}
                    key={`grouped-chips-${idx}`}
                >
                    <Typography className={tableClasses.aggTooltipTitle}>
                        {x.text}
                    </Typography>
                    {RenderChips({
                        data: x.data,
                        displayDataTransform,
                        accessorKey,
                        onClick,
                    })}
                </div>
            );
        });
    };

    const RenderChips = ({
        data,
        displayDataTransform,
        accessorKey,
        onClick,
    }) => {
        return (
            <Stack direction="row" sx={{ flexWrap: 'wrap', gap: '0.5rem' }}>
                <Each
                    of={data}
                    render={(item) => (
                        <Chip
                            label={
                                displayDataTransform
                                    ? displayDataTransform(item)
                                    : item?.[displayDataKey]
                            }
                            onClick={(event) => {
                                if (typeof onClick === 'function') {
                                    if (
                                        accessorKey === 'asset_usage' ||
                                        accessorKey === 'procedure_usage'
                                    ) {
                                        onClick(event, item['asset_id']);
                                    } else if (accessorKey === 'zone_usage') {
                                        onClick(event, item, item['asset_id']);
                                    } else if (accessorKey === 'item_usage') {
                                        onClick(
                                            event,
                                            item,
                                            item['asset_id'],
                                            item
                                        );
                                    }
                                }
                            }}
                        />
                    )}
                />
            </Stack>
        );
    };

    return (
        <TableCell
            component="div"
            padding={column.padding}
            className={clsx(
                tableClasses.tableCell,
                tableClasses.scrollingFlexContainer
            )}
            variant="body"
            style={{ height: rowHeight, pointerEvents: 'auto' }}
            align={column.numeric ? 'right' : 'left'}
        >
            {data?.length > 0 ? (
                <HtmlTooltip
                    title={
                        isGrouped
                            ? RenderGroupedChips({
                                  groupedData,
                                  displayDataTransform,
                                  accessorKey,
                                  onClick,
                              })
                            : RenderChips({
                                  data,
                                  displayDataTransform,
                                  accessorKey,
                                  onClick,
                              })
                    }
                >
                    <label className={tableClasses.usageLabel}>
                        {data?.length}
                    </label>
                </HtmlTooltip>
            ) : (
                <label className={tableClasses.usageLabel}></label>
            )}
        </TableCell>
    );
}

export {
    resizableActiveCell,
    resizableActionCell,
    resizableContentTypeCell,
    resizableCheckCell,
    resizableCheckHeaderCell,
    resizableLabelHeader,
    resizableLabelCell,
    resizableConditionalLabelCell,
    resizableStatusCell,
    resizableFeedbackCell,
    resizablePinCell,
    resizableSchooxNestedLabelCell,
    resizableSeeAssetCell,
    resizableViewLink,
    resizableZonePreviewLabelCell,
    resizableHotspotPreviewCell,
    resizableAssetTypeIconCell,
    resizableAssetDisclaimerIconCell,
    resizableHotspotDefaultIcon,
    resizableHotspotPopulatedIcon,
    resizableTagCell,
    resizableShortcutCell,
    resizableContentButtonTypeCell,
    resizableProgressStatusCell,
    resizableAssetExportDownloadLink,
    resizableLabelHeaderWithSearch,
    resizableToolButtonTypeCell,
    resizablePartButtonTypeCell,
    resizableNoOfRecordCell,
    resizableTagButtonTypeCell,
    resizableItemLabelCell,
    resizableExistingContentTypeCell,
    resizableCheckBoxCell,
    resizableDeploymentPackageDownloadLink,
    resizableLabelHeaderDiffSortKey,
    resizableDynamicLinkCell,
    resizableCategoryTypeCell,
    resizableUsageDisplayCell,
};
