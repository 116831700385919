import React from 'react';
import { useDispatch } from 'react-redux';
import { MdVrpano } from 'react-icons/md';
import { IconButton, Tooltip } from '@mui/material';

import { loadZone } from 'shared/loadZoneSlice';
import { setVisualEditZone } from '_features/common/editZoneSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

export function VisualEdit({ rowData }) {
    const dispatch = useDispatch();

    async function handleClick(rowData) {
        dispatch(activateLoading());
        await dispatch(loadZone({ zoneId: rowData.zone_id }));
        dispatch(setVisualEditZone(true));
        dispatch(deactivateLoading());
    }

    var hasImage =
        rowData.equirect_image_preview_url !== undefined ||
        rowData.flat_image_preview_url !== undefined;

    if (!hasImage) {
        return null;
    }

    return (
        <Tooltip title="View Zone">
            <IconButton
                onClick={(e) => {
                    e.stopPropagation();
                    handleClick(rowData);
                }}
            >
                <MdVrpano className="react-icon" />
            </IconButton>
        </Tooltip>
    );
}
