import React from 'react';
import { LinkTypes } from '_helpers/enumerations';
import { GeneralContentTab } from '../GeneralContentTab';

function ModelTab() {
    return (
        <GeneralContentTab
            contentName={'Model'}
            linkTypeId={LinkTypes.Model.id}
            contentTypes={LinkTypes.Model.contentTypes}
        />
    );
}

export { ModelTab };
