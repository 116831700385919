import React, { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import makeStyles from '@mui/styles/makeStyles';
import { ResizableBox } from 'react-resizable';
import { useDispatch, useSelector } from 'react-redux';
import { MdClose, MdPhoneIphone, MdPreview } from 'react-icons/md';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip,
} from '@mui/material';

import { NodeTypeIcons } from '_helpers';
import { accountsSlice } from 'app/store';
import NodeTable from './components/NodeTable';
import { makeProcedureStyles } from './styles';
import { useZoneNav } from 'shared/hooks/useZoneNav';
import { NodeEditor } from './components/NodeEditor';
import ProcedurePropsForm from './ProcedurePropsForm';
import { PanelResize } from './components/PanelResize';
import NodeTableViewOnly from './components/NodeTableViewOnly';
import { ProcedureFlowSettings } from './ProcedureFlowSettings';
import { cloneDeep, last } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { NodeManagerOptions } from './components/NodeManagerOptions';
import { NodeCategoriesModal } from './components/NodeCategoriesModal';
import { Procedure } from 'GeminiViewerComponent/components/Procedure';
import ProcedureFlow, { layoutEngines } from 'components/ProcedureFlow';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { ProcedureNodeTypes } from 'GeminiViewerComponent/components/Procedure/ProcedureNodeTypes';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    assetService,
    contentService,
    procedureService,
} from '_features/_services';
import {
    setActiveAssetId,
    setActiveAssetProcedureId,
    setChecklistInitialState,
    setDuplicateFields,
} from 'GeminiViewerComponent/components/Procedure/_features/checklistDataSlice';
import {
    fetchProcedure,
    getCurrentNode,
    gotoNode,
    setProcedureState,
    sortNodes,
    getAllProcedureVariables,
    setHeaderVariables,
} from 'GeminiViewerComponent/components/Procedure/_features/procedureSlice';
import {
    getProcedureCreateNodeData,
    getProcedureDirty,
    getProcedureUnsavedData,
    setOriginalProcedureData,
    setProcedureCreateNodeData,
    setProcedureDirty,
    updateProcedure,
} from '_features/procedures/proceduresSlice';

const treeRanker = 'network-simplex';

const makeResizableStyles = makeStyles(() => ({
    resizable: {
        '& .react-resizable': {
            position: 'relative',
        },
        '& .react-resizable-handle': {
            position: 'absolute',
            width: '20px',
            height: '20px',
            backgroundRepeat: 'no-repeat',
            backgroundOrigin: 'content-box',
            boxSizing: 'border-box',
            backgroundImage:
                'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+")',
            backgroundPosition: 'bottom right',
            padding: '0 3px 3px 0',
        },
        '& .react-resizable-handle-sw': {
            bottom: '0',
            left: '0',
            cursor: 'sw-resize',
            transform: 'rotate(90deg)',
        },
        '& .react-resizable-handle-se': {
            bottom: '0',
            right: '0',
            cursor: 'se-resize',
        },
        '& .react-resizable-handle-nw': {
            top: '0',
            left: '0',
            cursor: 'nw-resize',
            transform: 'rotate(180deg)',
        },
        '& .react-resizable-handle-ne': {
            top: '0',
            right: '0',
            cursor: 'ne-resize',
            transform: 'rotate(270deg)',
        },
        '& .react-resizable-handle-w, .react-resizable-handle-e': {
            top: '50%',
            marginTop: '-10px',
            cursor: 'ew-resize',
        },
        '& .react-resizable-handle-w': {
            left: '0',
            transform: 'rotate(135deg)',
        },
        '& .react-resizable-handle-e': {
            right: '0',
            transform: 'rotate(315deg)',
        },
        '& .react-resizable-handle-n, .react-resizable-handle-s': {
            left: '50%',
            marginLeft: '-10px',
            cursor: 'ns-resize',
        },
        '& .react-resizable-handle-n': {
            top: '0',
            transform: 'rotate(225deg)',
        },
        '& .react-resizable-handle-s': {
            bottom: '0',
            transform: 'rotate(45deg)',
        },
    },
}));

const computeTargetInfoFromAction = (
    node,
    label,
    action,
    nodes,
    parentId = null
) => {
    // Compute the potential next node. This is intened to be used
    // for previwing the node flow. We can only know the next node for
    // actions that move forward in the flow. For actions the move back or
    // return to previous nodes, we can't know the actual node without the
    // user navigating the node tree

    if (action?.type === 'sub') {
        return null;
    }
    switch (action?.id) {
        case 'submit':
        case 'next': {
            // Find next in the current
            const index = nodes.findIndex((item) => item.id === node.id);
            if (index !== -1 && index < nodes.length - 1) {
                return {
                    label: label ? label : undefined,
                    nodeId: parentId
                        ? parentId + ':' + nodes[index + 1].id
                        : nodes[index + 1].id,
                };
            } else {
                return null;
            }
        }
        case 'back':
            // Don't know for sure the previous node since
            // user isn't actually navigating the tree
            return null;
        case 'previous':
            // Don't know for sure the previous node since
            // user isn't actually navigating the tree
            return null;
        default:
            if (
                typeof action?.id === 'number' ||
                (typeof action?.id === 'string' && !isNaN(parseInt(action?.id)))
            ) {
                return {
                    label: label ? label : undefined,
                    nodeId: parentId ? parentId + ':' + action?.id : action?.id,
                };
            } else {
                // We can't determine the last display node without the user
                // actually navigating the tree since we depend on storing the
                // last display node as the user navigates.
                return null;
            }
    }
};

const getNodeConnections = (node, nodes, parentId = null) => {
    let connections = [];
    let getOptions = false;
    let newConnections = null;
    if (node?.type === ProcedureNodeTypes.content.type) {
        if (node?.answer_group === 'select') {
            getOptions = true;
            if (
                Array.isArray(node?.select_options) &&
                node?.select_options?.length > 0
            ) {
                newConnections = node.select_options?.map((sel, idx) => {
                    const targetId = computeTargetInfoFromAction(
                        node,
                        sel?.answer_value ? sel?.answer_value : `Select ${idx}`,
                        sel.action,
                        nodes,
                        parentId
                    );
                    return targetId;
                });
                if (Array.isArray(newConnections)) {
                    connections = [...connections, ...newConnections];
                }
                if (
                    node?.multiple_answers_allow === true &&
                    Array.isArray(node?.select_target_combinations) &&
                    node?.select_target_combinations?.length > 0
                ) {
                    newConnections = node.select_target_combinations?.map(
                        (sel) => {
                            if (
                                Array.isArray(sel?.options_required) &&
                                sel?.options_required?.length > 0
                            ) {
                                const targetId = computeTargetInfoFromAction(
                                    node,
                                    sel?.answer_value
                                        ? sel?.answer_value
                                        : `Select ${sel?.options_required
                                              ?.map((opt) => opt?.option_id)
                                              .join(',')}`,
                                    sel.action,
                                    nodes,
                                    parentId
                                );
                                return targetId;
                            }
                            return null;
                        }
                    );
                    if (Array.isArray(newConnections)) {
                        connections = [...connections, ...newConnections];
                    }
                }
            }
        } else if (node?.answer_group === 'image') {
            getOptions = true;
            if (
                Array.isArray(node?.image_options) &&
                node?.image_options?.length > 0
            ) {
                newConnections = node.image_options?.map((img, idx) => {
                    const targetId = computeTargetInfoFromAction(
                        node,
                        img?.answer_value ? img?.answer_value : `Image ${idx}`,
                        img.action,
                        nodes,
                        parentId
                    );
                    return targetId;
                });
                if (Array.isArray(newConnections)) {
                    connections = [...connections, ...newConnections];
                }
                if (
                    node?.multiple_answers_allow === true &&
                    Array.isArray(node?.image_target_combinations) &&
                    node?.image_target_combinations?.length > 0
                ) {
                    newConnections = node.image_target_combinations?.map(
                        (img) => {
                            if (
                                Array.isArray(img?.options_required) &&
                                img?.options_required?.length > 0
                            ) {
                                const targetId = computeTargetInfoFromAction(
                                    node,
                                    img?.answer_value
                                        ? img?.answer_value
                                        : `Image ${img?.options_required
                                              ?.map((opt) => opt?.img_id)
                                              .join(',')}`,
                                    img.action,
                                    nodes,
                                    parentId
                                );
                                return targetId;
                            }
                            return null;
                        }
                    );
                    if (Array.isArray(newConnections)) {
                        connections = [...connections, ...newConnections];
                    }
                }
            }
        }
    }
    if (getOptions === false) {
        connections =
            node.option_buttons?.map((button) => {
                const targetId = computeTargetInfoFromAction(
                    node,
                    button.button_text,
                    button.action,
                    nodes,
                    parentId
                );
                return targetId;
            }) ?? [];
    }

    if (node.next_button) {
        const nextNodeId = computeTargetInfoFromAction(
            node,
            'next',
            { id: 'next' },
            nodes,
            parentId
        );
        if (nextNodeId) {
            connections.push(nextNodeId);
        }
    }
    if (node.type === 'logic') {
        connections.push(
            computeTargetInfoFromAction(
                node,
                'pass',
                node?.pass_target_node_id,
                nodes,
                parentId
            )
        );
        connections.push(
            computeTargetInfoFromAction(
                node,
                'fail',
                node?.fail_target_node_id,
                nodes,
                parentId
            )
        );
    }

    if (node.action?.id) {
        connections.push(
            computeTargetInfoFromAction(
                node,
                node.action.id,
                node.action,
                nodes,
                parentId
            )
        );
    }

    connections = connections.filter((e) => e != null);

    return connections;
};

const generateProcedureFlow = (
    startNodeId,
    nodes,
    ranker,
    showBranch = false,
    parentId = null
) => {
    if (typeof startNodeId == 'string') {
        startNodeId = parseInt(startNodeId);
    }
    let subNodes = [];
    let flowNodes = Object.keys(nodes ?? {}).map((key) => {
        const node = nodes[key];
        let branchNodes = null;
        if (
            showBranch &&
            node.type === 'branch' &&
            node?.asset_title &&
            !parentId
        ) {
            branchNodes = generateProcedureFlow(
                Number(node.branch_procedure?.start_node_id ?? 1),
                node?.branch_procedure?.nodes ?? [],
                treeRanker,
                showBranch,
                node.id
            );
            if (branchNodes?.nodes?.length > 0) {
                subNodes.push(...branchNodes.nodes);
            }
        }

        return {
            title: node.title,
            id: parentId ? parentId + ':' + node.id : node.id,
            icons: [node?.type].map(
                (type) =>
                    NodeTypeIcons?.[type] && (
                        <Tooltip key={type} title={NodeTypeIcons[type].title}>
                            {NodeTypeIcons[type].icon}
                        </Tooltip>
                    )
            ),
            isHome: String(node.id) === String(startNodeId),
            connections: getNodeConnections(node, nodes, parentId),
            inConnections: [],
            order_idx: node?.order_idx,
            data: node,
            parentId,
            branchNode: branchNodes ?? null,
        };
    });

    if (subNodes?.length > 0) {
        flowNodes = [...flowNodes, ...subNodes];
    }
    return {
        nodeWidth: 200,
        nodeHeight: 100,
        marginTop: 20,
        marginLeft: 20,
        nodeHorizontalSpacing: 40,
        nodeVerticalSpacing: 60,
        activeNode: startNodeId,
        connectors: [],
        ranker: ranker,
        nodes: flowNodes,
    };
};

const NodeManagerDisplay = ({
    showNodeFlow,
    flowData,
    selectedNodeId,
    showTwoPanelMode,
    handleNodeSelected,
    procedure,
    sortedNodes,
    handleNodeClick,
    handleNodePreview,
    handleNodesChanged,
    handleNodeDuplicate,
    showDeleteNodeDialog,
    setClickedAddButton,
    compactMode,
    showBranches,
    setOpenSaveDataDialog,
    setComponentMethodsRef,
    setOpenFlowSettingsDialog,
    nodeDisplayPanel,
}) => {
    return showNodeFlow ? (
        <ProcedureFlow
            setComponentMethodsRef={setComponentMethodsRef}
            draggable={false}
            layoutEngine={layoutEngines.dagreLayout}
            data={flowData}
            activeNodeId={selectedNodeId}
            showTwoPanelMode={showTwoPanelMode}
            onNodeClicked={handleNodeClick}
            onNodeActive={handleNodeSelected}
            onNodeDelete={showDeleteNodeDialog}
            onNodePreview={(node) => handleNodePreview({ procedure, node })}
            setClickedAddButton={setClickedAddButton}
            openSettings={setOpenFlowSettingsDialog}
            nodeDisplayPanel={nodeDisplayPanel}
        />
    ) : (
        <NodeTable
            procedure={procedure}
            nodes={sortedNodes}
            activeNodeId={selectedNodeId}
            onNodeClicked={handleNodeClick}
            onNodeActive={handleNodeSelected}
            onNodePreview={handleNodePreview}
            onNodesChanged={handleNodesChanged}
            onNodeDuplicate={handleNodeDuplicate}
            onNodeDelete={showDeleteNodeDialog}
            setClickedAddButton={setClickedAddButton}
            compactMode={compactMode}
            showBranches={showBranches}
            showTwoPanelMode={showTwoPanelMode}
            setOpenSaveDataDialog={setOpenSaveDataDialog}
        />
    );
};

const ProcedureDesigner = ({
    procedureId,
    procedure,
    parentWidth,
    parentHeight,
    assetId,
    fetchAsset,
}) => {
    //#region Constants
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const procedureStyles = makeProcedureStyles();
    const resizableStyles = makeResizableStyles();
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    // eslint-disable-next-line no-unused-vars
    const [componentMethodsRef, setComponentMethodsRef] = useState({});
    const [activeNode, setActiveNode] = useState(null);
    const [previewMode, setPreviewMode] = useState(false);
    const [sortedNodes, setSortedNodes] = useState(sortNodes(procedure.nodes));
    const [selectedNodeId, setSelectedNodeId] = useState(1);
    const [previewWidth, setPreviewWidth] = useState(parentWidth);
    const [phoneWidth, setPhoneWidth] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [variables, setVariables] = useState([]);
    const [openFlowSettingsDialog, setOpenFlowSettingsDialog] = useState(false);
    const [openSaveDataDialog, setOpenSaveDataDialog] = useState(false);
    const [clickedAddButton, setClickedAddButton] = useState(false);
    const [compactMode, setCompactMode] = useState(true);
    const [showBranches, setShowBranches] = useState(false);
    const [showNodeFlow, setShowNodeFlow] = useState(true);
    const [showTwoPanelMode, setShowTwoPanelMode] = useState(true);
    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
        useState(false);
    const [selectedDeleteNode, setSelectedDeleteNode] = useState(null);
    const [openNodeEditForm, setOpenNodeEditForm] = useState(false);
    const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
    const [flowData, setFlowData] = useState(
        generateProcedureFlow(
            Number(procedure.start_node_id),
            sortedNodes,
            treeRanker
        )
    );
    //#endregion State

    //#region Selectors
    const unsavedProcedure = useSelector(getProcedureUnsavedData);
    const isProcedureDirty = useSelector(getProcedureDirty);
    const currentNode = useSelector(getCurrentNode);
    const createNodeData = useSelector(getProcedureCreateNodeData);
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    //#endregion Selectors

    //#region Refs
    const nodeDisplayPanel = useRef(null);
    const nodePreviewPanel = useRef(null);
    //#endregion Refs

    //#region Effects
    useEffect(() => {
        if (unsavedProcedure) {
            setFlowData(
                generateProcedureFlow(
                    Number(unsavedProcedure?.start_node_id),
                    sortedNodes,
                    treeRanker,
                    showBranches
                )
            );
        }
    }, [showBranches, unsavedProcedure, sortedNodes]);

    useEffect(() => {
        async function createNodeForBtnTarget() {
            if (
                createNodeData &&
                createNodeData?.currentNode &&
                createNodeData?.newNodeType &&
                Array.isArray(createNodeData?.currentNode?.option_buttons) &&
                createNodeData?.currentNode?.option_buttons?.length > 0
            ) {
                let newNode = await handleAddNode(
                    createNodeData?.newNodeType,
                    false,
                    false,
                    false
                );
                let btnOptions =
                    createNodeData?.currentNode?.option_buttons?.map((btn) => {
                        if (btn?.action?.id === 'new-node') {
                            btn = {
                                ...btn,
                                action: {
                                    ...btn?.action,
                                    id: newNode.id,
                                },
                            };
                            newNode = { ...newNode, title: btn?.button_text };
                        }
                        return btn;
                    });
                let btnNode = {
                    ...createNodeData?.currentNode,
                    option_buttons: btnOptions,
                };
                await handleSubmit(newNode, btnNode);
            }
        }
        createNodeForBtnTarget();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createNodeData, sortedNodes]);

    useEffect(() => {
        dispatch(setActiveAssetId(assetId));
        dispatch(setActiveAssetProcedureId(procedureId));
        dispatch(setChecklistInitialState());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetId, procedureId]);

    useEffect(() => {
        if (procedure) {
            changeProcedure(procedure, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [procedureId]);

    useEffect(() => {
        if (unsavedProcedure) {
            changeProcedure(unsavedProcedure, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unsavedProcedure]);

    useEffect(() => {
        setPreviewWidth(parentWidth);
    }, [parentWidth]);
    //#endregion Effects

    //#region Methods
    async function changeProcedure(proc, callFromOriginal = true) {
        await setVariables(await getAllProcedureVariables(proc?.nodes));
        setSortedNodes(sortNodes(proc.nodes));
        setFlowData(
            generateProcedureFlow(
                Number(proc?.start_node_id),
                sortedNodes,
                treeRanker
            )
        );
        if (callFromOriginal === true) {
            let homeNodeId = proc?.start_node_id ? +proc.start_node_id : 1;
            if (activeNode?.id) {
                homeNodeId = activeNode?.id;
            }
            setSelectedNodeId(homeNodeId);
            setActiveNode(proc?.nodes?.find((nd) => +nd?.id === homeNodeId));
            dispatch(setOriginalProcedureData(proc));
        }
        if (createNodeData?.currentNode) {
            setActiveNode(createNodeData?.currentNode);
            setSelectedNodeId(createNodeData?.currentNode?.id);
            dispatch(setProcedureCreateNodeData(null));
        }
    }

    const getNewNodeId = (nodes) => {
        const usedIDs = [];
        nodes.forEach((nd) => {
            usedIDs.push(Number(nd.id));
        });
        return usedIDs?.length === 0 ? 1 : Math.max(...usedIDs) + 1;
    };

    const showDeleteNodeDialog = (nodeId) => {
        if (isProcedureDirty === true) {
            setOpenSaveDataDialog(true);
            return;
        }
        setSelectedDeleteNode(nodeId);
        setOpenDeleteConfirmDialog(true);
    };

    const closeDeleteConfirmDialog = () => {
        setSelectedDeleteNode(null);
        setOpenDeleteConfirmDialog(false);
    };

    const handleNodeDelete = async () => {
        let filteredNodes = [];
        if (Array.isArray(selectedDeleteNode)) {
            filteredNodes = await sortedNodes.filter(
                (nd) => selectedDeleteNode.includes(nd.id) === false
            );
        } else {
            filteredNodes = await sortedNodes.filter(
                (nd) => nd.id !== selectedDeleteNode
            );
        }
        setSortedNodes(filteredNodes);
        handleNodesChanged(filteredNodes);
        closeDeleteConfirmDialog();
    };

    const handleNodeClick = useCallback(
        (node) => {
            if (isProcedureDirty === true) {
                setOpenSaveDataDialog(true);
                return;
            }
            dispatch(setProcedureState(procedure));
            dispatch(setChecklistInitialState());
            dispatch(gotoNode(node.id));
            setSelectedNodeId(node.id);
            setActiveNode(node);
            setOpenNodeEditForm(true);
        },
        [dispatch, isProcedureDirty, procedure]
    );

    const handleNodeSelected = useCallback(
        (nodeId, node) => {
            if (isProcedureDirty === true) {
                setOpenSaveDataDialog(true);
                return;
            }
            if (showTwoPanelMode === true) {
                handleNodeClick(node);
            } else {
                setSelectedNodeId(nodeId);
            }
        },
        [isProcedureDirty, showTwoPanelMode, handleNodeClick]
    );

    const handleAddNode = async (
        nodeType,
        afterOrderIdx = false,
        makeActive = true,
        checkDirty = true
    ) => {
        // Make sure we have the latest nodes from the server
        if (isProcedureDirty === true && checkDirty) {
            setOpenSaveDataDialog(true);
            return;
        }
        dispatch(activateLoading());
        const updatedProcedure = await dispatch(
            fetchProcedure({
                service: procedureService,
                procedureId: procedureId,
            })
        );
        dispatch(deactivateLoading());
        if (updatedProcedure.error) {
            enqueueSnackbar(updatedProcedure.error, {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
            return;
        }
        const updatedProcedureJson = updatedProcedure.payload.procedure;

        const updatedSortedNodes = sortNodes(updatedProcedureJson.nodes);

        let lastNode = last(updatedSortedNodes);
        if (!lastNode) {
            lastNode = { id: 0, order_idx: -1 };
        }
        let newNode =
            nodeType === ProcedureNodeTypes.branch.type
                ? {
                      id: getNewNodeId(updatedSortedNodes),
                      type: nodeType,
                      order_idx: lastNode.order_idx + 1,
                      title: '',
                  }
                : {
                      id: getNewNodeId(updatedSortedNodes),
                      type: nodeType,
                      order_idx: lastNode.order_idx + 1,
                      back_button: false,
                      next_button: false,
                      submit_button: false,
                      option_buttons: [],
                      title: '',
                      question: '',
                      content: '',
                      fields: [],
                      target_title: '',
                      target_details: '',
                      target_url: '',
                  };
        newNode = { ...newNode, isNew: true };
        if (afterOrderIdx) {
            newNode = { ...newNode, afterOrderIdx };
        }
        if (makeActive) {
            setFlowData(
                generateProcedureFlow(
                    Number(procedure?.start_node_id),
                    sortedNodes,
                    treeRanker
                )
            );
            setSelectedNodeId(newNode.id);
            setActiveNode(newNode);
            setOpenNodeEditForm(true);
        }
        return newNode;
    };

    const handleSubmit = useCallback(
        async (values, currNode = null, nodes) => {
            let node = {};
            let procedureCategories = [];
            node = { ...values };

            if (values?.procedureCategories) {
                procedureCategories = values.procedureCategories;
                delete node.procedureCategories;
            }
            delete node?.isNew;
            delete node?.focusTargetBtnId;
            dispatch(activateLoading({ showProgress: true }));
            let newSortedNodes = [];
            const nodeIdx = sortedNodes.findIndex(
                (anode) => anode.id === node.id
            );
            let updatedProcedure = { ...procedure };
            if (procedureCategories.length > 0) {
                updatedProcedure.procedureCategories = [...procedureCategories];
            }
            if (nodeIdx !== -1) {
                sortedNodes[nodeIdx] = node;
                newSortedNodes = sortedNodes;
            } else {
                if (sortedNodes.length === 0) {
                    updatedProcedure.start_node_id = node.id;
                }
                if (
                    node?.afterOrderIdx &&
                    Array.isArray(sortedNodes) &&
                    sortedNodes?.length !== node?.afterOrderIdx
                ) {
                    sortedNodes.map((nd, index) => {
                        if (node?.afterOrderIdx === index) {
                            newSortedNodes[newSortedNodes.length] = {
                                ...node,
                                order_idx: node?.afterOrderIdx,
                            };
                            newSortedNodes[newSortedNodes.length] = nd;
                        } else {
                            newSortedNodes[newSortedNodes.length] = {
                                ...nd,
                                order_idx:
                                    node?.afterOrderIdx < index
                                        ? nd.order_idx + 1
                                        : nd.order_idx,
                            };
                        }
                        return false;
                    });
                    newSortedNodes = newSortedNodes.map((nd, index) => {
                        if (node?.afterOrderIdx === nd.order_idx + 1) {
                            nd = { ...nd, next_button: true };
                        }
                        delete nd?.afterOrderIdx;
                        nd.order_idx = index;
                        return nd;
                    });
                    newSortedNodes = newSortedNodes.sort(function (a, b) {
                        return a.order_idx - b.order_idx;
                    });
                    newSortedNodes = sortNodes(newSortedNodes);
                } else {
                    if (node?.afterOrderIdx) {
                        sortedNodes[sortedNodes.length - 1].next_button = true;
                    }
                    sortedNodes.push(node);
                    newSortedNodes = sortedNodes;
                }
            }

            let procedureWithoutBranch = cloneDeep(updatedProcedure);

            removeBranchProcedure(procedureWithoutBranch.nodes);
            removeBranchProcedure(newSortedNodes);

            const result = await dispatch(
                updateProcedure({
                    header_image_url: procedure?.header_image_url,
                    procedure_id: procedureId,
                    procedure_json: JSON.stringify(
                        await setHeaderVariables({
                            ...procedureWithoutBranch,
                            ...{ nodes: newSortedNodes },
                        })
                    ),
                    asset_id: assetId,
                })
            );
            if (result.error) {
                dispatch(deactivateLoading());
                enqueueSnackbar(result.error.message, {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'error',
                });
                return;
            }
            const updatedProcedureJson = result.payload.procedure;

            const updatedSortedNodes = sortNodes(updatedProcedureJson.nodes);

            if (
                currNode &&
                Array.isArray(updatedSortedNodes) &&
                updatedSortedNodes?.length > 0
            ) {
                if (currNode?.isNew === true) {
                    const lastNode = last(updatedSortedNodes);
                    currNode = {
                        ...currNode,
                        id: getNewNodeId(updatedSortedNodes),
                        order_idx: lastNode?.order_idx + 1,
                    };
                }
                dispatch(setProcedureCreateNodeData({ currentNode: currNode }));
            }
            setSortedNodes(updatedSortedNodes);
            setFlowData(
                generateProcedureFlow(
                    Number(procedure.start_node_id),
                    updatedSortedNodes,
                    treeRanker
                )
            );
            setSelectedNodeId(node.id);

            setActiveNode(node);
            dispatch(setOriginalProcedureData(updatedProcedureJson));
            if (node.type === 'branch') {
                fetchAsset();
            }
            dispatch(setProcedureDirty(false));
            setOpenNodeEditForm(false);
            dispatch(deactivateLoading());
        },
        [
            dispatch,
            sortedNodes,
            procedure,
            procedureId,
            assetId,
            enqueueSnackbar,
            fetchAsset,
        ]
    );

    const removeBranchProcedure = (nodes) => {
        for (let key in nodes) {
            if (Object.prototype.hasOwnProperty.call(nodes, key)) {
                const node = nodes[key];
                if (node.type === 'branch') {
                    delete node.branch_procedure;
                }
            }
        }
    };

    const handleNodeDuplicate = async (nodeId) => {
        if (isProcedureDirty === true) {
            setOpenSaveDataDialog(true);
            return;
        }
        let nodeIDs = Array.isArray(nodeId) ? nodeId : [nodeId];
        let recursiveSortNodes = sortedNodes;
        let newNodesAdded = [];
        let newNodesTypes = [];
        dispatch(activateLoading({ showProgress: true }));
        for (let id of nodeIDs) {
            let copyNode = recursiveSortNodes?.find((node) => node?.id === id);
            if (copyNode) {
                let lastNode = last(recursiveSortNodes);
                if (!lastNode) {
                    lastNode = { id: 0, order_idx: -1 };
                }
                copyNode = {
                    ...copyNode,
                    id: getNewNodeId(recursiveSortNodes),
                    order_idx: lastNode.order_idx + 1,
                    title: `${copyNode.title} copy`,
                };
                if (copyNode?.variable) {
                    copyNode = {
                        ...copyNode,
                        variable: `${copyNode.variable}_${
                            copyNode?.id ? copyNode.id : new Date().getTime()
                        }`,
                    };
                }
                if (
                    copyNode?.type === ProcedureNodeTypes.content.type &&
                    Array.isArray(copyNode?.fields) &&
                    copyNode?.fields?.length > 0
                ) {
                    let nodeFields = copyNode?.fields?.map((field) => {
                        if (field.type === 'group' || field.type === 'table') {
                            if (
                                Array.isArray(field?.fields) &&
                                field?.fields?.length > 0
                            ) {
                                field = {
                                    ...field,
                                    fields: field?.fields?.map((fld) => {
                                        if (fld?.variable) {
                                            fld = {
                                                ...fld,
                                                variable: `${fld.variable}_${
                                                    copyNode?.id
                                                        ? copyNode.id
                                                        : new Date().getTime()
                                                }`,
                                            };
                                        }
                                        return { ...fld, id: uuidv4() };
                                    }),
                                };
                            }
                        } else {
                            if (field?.variable) {
                                field = {
                                    ...field,
                                    variable: `${field.variable}_${
                                        copyNode?.id
                                            ? copyNode.id
                                            : new Date().getTime()
                                    }`,
                                };
                            }
                        }
                        return { ...field, id: uuidv4() };
                    });
                    copyNode = { ...copyNode, fields: nodeFields };
                }
                newNodesAdded.push(copyNode?.id);
                newNodesTypes.push(copyNode?.type);
                recursiveSortNodes = sortNodes([
                    ...recursiveSortNodes,
                    copyNode,
                ]);
            }
        }
        if (newNodesAdded?.length > 0) {
            const result = await dispatch(
                updateProcedure({
                    header_image_url: procedure?.header_image_url,
                    procedure_id: procedureId,
                    procedure_json: JSON.stringify(
                        await setHeaderVariables({
                            ...procedure,
                            ...{ nodes: [...recursiveSortNodes] },
                        })
                    ),
                    asset_id: assetId,
                })
            );

            if (result.error) {
                dispatch(deactivateLoading());
                enqueueSnackbar(result.error, {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'error',
                });
                return;
            } else {
                enqueueSnackbar(
                    `Added new node #${newNodesAdded.join(
                        ', #'
                    )} successfully.`,
                    {
                        action: (key) => <SnackbarDismiss key={key} />,
                        variant: 'success',
                    }
                );
            }
            const updatedProcedureJson = JSON.parse(
                result.payload.procedure_json
            );

            const updatedSortedNodes = sortNodes(updatedProcedureJson.nodes);

            setSortedNodes(updatedSortedNodes);

            setActiveNode(null);
            if (newNodesTypes.includes('branch') === true) {
                fetchAsset();
            }
            dispatch(setOriginalProcedureData(updatedProcedureJson));
        }
        dispatch(deactivateLoading());
    };

    const handleNodesChanged = async (nodes) => {
        if (isProcedureDirty === true) {
            setOpenSaveDataDialog(true);
            return;
        }
        dispatch(activateLoading());
        const result = await dispatch(
            updateProcedure({
                header_image_url: procedure?.header_image_url,
                procedure_id: procedureId,
                procedure_json: JSON.stringify(
                    await setHeaderVariables({
                        ...procedure,
                        ...{ nodes: nodes },
                    })
                ),
                asset_id: assetId,
            })
        );
        dispatch(deactivateLoading());
        if (result.error) {
            enqueueSnackbar(result.error, {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
            return;
        }

        // Update state based on return data from server
        const updatedProcedureJson = JSON.parse(result.payload.procedure_json);

        const updatedSortedNodes = sortNodes(updatedProcedureJson.nodes);

        setSortedNodes(updatedSortedNodes);

        setActiveNode(null);
        dispatch(setOriginalProcedureData(updatedProcedureJson));
    };

    const handleCancel = () => {
        dispatch(setProcedureDirty(false));
        setActiveNode(null);
        dispatch(setOriginalProcedureData(procedure));
    };

    const handlePropsSubmit = async (procedure) => {
        dispatch(activateLoading({ showProgress: true }));
        if (procedure?.split_view_mode) {
            procedure = {
                ...procedure,
                split_view_mode: +procedure.split_view_mode,
            };
        }
        const updatedProcedure = { ...procedure };
        if (updatedProcedure.header_image) {
            delete updatedProcedure?.image;
            delete updatedProcedure?.image_url;
            delete updatedProcedure?.image_name;
            delete updatedProcedure?.content_data;
            delete updatedProcedure?.header_image;
            delete updatedProcedure?.header_image_url;
            delete updatedProcedure?.header_image_name;
        }
        // Make sure we have the latest nodes from the server
        const serverProcedure = await dispatch(
            fetchProcedure({
                service: procedureService,
                procedureId: procedureId,
            })
        );

        const serverProcedureJson = serverProcedure.payload.procedure;
        // assetService.updateProcedureNodes(serverProcedureJson);
        updatedProcedure.nodes = serverProcedureJson.nodes;
        if (procedure?.header_image) {
            delete procedure?.header_image_url;
        }
        const result = await dispatch(
            updateProcedure({
                header_image_url: procedure?.header_image_url,
                header_image: procedure?.header_image,
                procedure_id: procedureId,
                procedure_json: JSON.stringify({
                    ...updatedProcedure,
                }),
                asset_id: assetId,
            })
        );

        if (result.error) {
            dispatch(deactivateLoading());
            enqueueSnackbar(result.error, {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
            return;
        }
        const updatedProcedureJson = result.payload.procedure;

        const updatedSortedNodes = sortNodes(updatedProcedureJson.nodes);

        setSortedNodes(updatedSortedNodes);

        setShowEditDialog(false);
        dispatch(deactivateLoading());
        dispatch(setOriginalProcedureData(updatedProcedureJson));
    };

    const handlePropsCancel = () => {
        setShowEditDialog(false);
    };

    const handlePreview = async () => {
        dispatch(setProcedureState(procedure));
        dispatch(setChecklistInitialState());
        dispatch(
            gotoNode(
                procedure?.start_node_id ? Number(procedure.start_node_id) : 1
            )
        );
        setPreviewMode(true);
    };

    const handleNodePreview = ({ procedure, node }) => {
        if (isProcedureDirty === true) {
            setOpenSaveDataDialog(true);
            return;
        }
        dispatch(setProcedureState(procedure));
        dispatch(setChecklistInitialState());
        dispatch(gotoNode(node.id));
        setPreviewMode(true);
    };

    const handleClose = () => {
        setPreviewMode(false);
        dispatch(setDuplicateFields({}));
    };

    const togglePhoneWidth = () => {
        setPhoneWidth(!phoneWidth);
        if (!phoneWidth) {
            setPreviewWidth(500);
        } else {
            setPreviewWidth(parentWidth);
        }
    };

    const handleEditProperties = () => {
        setShowEditDialog(true);
    };

    const updateProcedureData = useCallback(
        async (updatedNodes, updatedNodeCategories) => {
            const updatedProcedure = { ...procedure };
            updatedProcedure.procedureCategories = [...updatedNodeCategories];

            dispatch(activateLoading());
            const result = await dispatch(
                updateProcedure({
                    header_image_url: procedure?.header_image_url,
                    procedure_id: procedureId,
                    procedure_json: JSON.stringify(
                        await setHeaderVariables({
                            ...updatedProcedure,
                            ...{ nodes: updatedNodes },
                        })
                    ),
                    asset_id: assetId,
                })
            );
            if (result.error) {
                dispatch(deactivateLoading());
                enqueueSnackbar(result.error.message, {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'error',
                });
                return;
            }
            setOpenCategoryDialog(false);
            dispatch(deactivateLoading());
            const updatedProcedureJson = result.payload.procedure;

            const updatedSortedNodes = sortNodes(updatedProcedureJson.nodes);

            setSortedNodes(updatedSortedNodes);
            setFlowData(
                generateProcedureFlow(
                    Number(procedure.start_node_id),
                    updatedSortedNodes,
                    treeRanker
                )
            );
            const updatedCurrentNode = updatedNodes?.find(
                (node) => node.id === activeNode?.id
            );
            if (
                updatedCurrentNode &&
                Object.keys(updatedCurrentNode || {}).length > 0
            ) {
                setActiveNode(updatedCurrentNode);
            }
            dispatch(setOriginalProcedureData(updatedProcedureJson));
        },
        [
            activeNode?.id,
            assetId,
            dispatch,
            enqueueSnackbar,
            procedure,
            procedureId,
        ]
    );
    //#endregion Methods

    //#region Render time calcs
    const nodeDisplay = (
        <NodeManagerDisplay
            showNodeFlow={showNodeFlow}
            flowData={flowData}
            selectedNodeId={selectedNodeId}
            handleNodeSelected={handleNodeSelected}
            procedure={unsavedProcedure}
            sortedNodes={sortedNodes}
            showTwoPanelMode={showTwoPanelMode}
            handleNodeClick={handleNodeClick}
            handleNodePreview={handleNodePreview}
            handleNodesChanged={handleNodesChanged}
            handleNodeDuplicate={handleNodeDuplicate}
            setClickedAddButton={setClickedAddButton}
            compactMode={compactMode}
            showBranches={showBranches}
            setOpenSaveDataDialog={setOpenSaveDataDialog}
            showDeleteNodeDialog={showDeleteNodeDialog}
            setComponentMethodsRef={setComponentMethodsRef}
            setOpenFlowSettingsDialog={setOpenFlowSettingsDialog}
            nodeDisplayPanel={nodeDisplayPanel}
        />
    );
    //#endregion Render time calcs

    //#region Render
    return (
        <>
            {/* <div className={procedureStyles.container}> */}
            <Tooltip title="Preview">
                <MdPreview
                    className="react-icon"
                    style={{ cursor: 'pointer' }}
                    onClick={handlePreview}
                />
            </Tooltip>
            {readOnlyMode === true ? (
                <NodeTableViewOnly procedure={procedure} nodes={sortedNodes} />
            ) : (
                <>
                    <NodeManagerOptions
                        onAddNode={handleAddNode}
                        clickedAddButton={clickedAddButton}
                        setClickedAddButton={setClickedAddButton}
                        onEditProperties={handleEditProperties}
                        compactMode={compactMode}
                        setCompactMode={setCompactMode}
                        showBranches={showBranches}
                        setShowBranches={setShowBranches}
                        showNodeFlow={showNodeFlow}
                        setShowNodeFlow={setShowNodeFlow}
                        showTwoPanelMode={showTwoPanelMode}
                        setShowTwoPanelMode={setShowTwoPanelMode}
                        setOpenSaveDataDialog={setOpenSaveDataDialog}
                        setOpenNodeEditForm={setOpenNodeEditForm}
                        setOpenCategoryDialog={setOpenCategoryDialog}
                    />
                    {showTwoPanelMode ? (
                        <div id="grid" className={procedureStyles.twoPanelMode}>
                            <div
                                ref={nodeDisplayPanel}
                                className={procedureStyles.nodeDisplayPanel}
                            >
                                {nodeDisplay}
                            </div>
                            <div className={procedureStyles.panelResizer}>
                                <PanelResize
                                    previousPanelRef={nodeDisplayPanel}
                                    nextPanelRef={nodePreviewPanel}
                                />
                            </div>
                            <div
                                ref={nodePreviewPanel}
                                className={procedureStyles.nodePreviewPanel}
                            >
                                {showTwoPanelMode === true && activeNode && (
                                    <NodeEditor
                                        activeNode={activeNode}
                                        assetId={assetId}
                                        variables={variables}
                                        sortedNodes={sortedNodes}
                                        handleSubmit={handleSubmit}
                                        handleCancel={handleCancel}
                                        openSaveDataDialog={openSaveDataDialog}
                                        setOpenSaveDataDialog={
                                            setOpenSaveDataDialog
                                        }
                                        showTwoPanelMode={showTwoPanelMode}
                                        procedureCategories={
                                            procedure?.procedureCategories
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    ) : (
                        <div
                            ref={nodeDisplayPanel}
                            className={procedureStyles.nodeDisplayPanel}
                            style={{ borderTop: '1px solid' }}
                        >
                            {nodeDisplay}
                        </div>
                    )}
                </>
            )}
            {showTwoPanelMode === false && activeNode && (
                <Dialog
                    fullScreen={false}
                    open={openNodeEditForm}
                    fullWidth
                    maxWidth="xl"
                    disableEnforceFocus={true}
                >
                    <DialogContent>
                        <NodeEditor
                            activeNode={activeNode}
                            assetId={assetId}
                            variables={variables}
                            sortedNodes={sortedNodes}
                            handleSubmit={handleSubmit}
                            handleCancel={handleCancel}
                            openSaveDataDialog={openSaveDataDialog}
                            setOpenSaveDataDialog={setOpenSaveDataDialog}
                            showTwoPanelMode={showTwoPanelMode}
                            procedureCategories={procedure?.procedureCategories}
                        />
                    </DialogContent>
                </Dialog>
            )}
            {showEditDialog && (
                <Dialog open={true} fullWidth>
                    <DialogContent>
                        <div className={procedureStyles.formContainer}>
                            <ProcedurePropsForm
                                procedure={procedure}
                                variables={variables}
                                onSubmit={handlePropsSubmit}
                                onCancel={handlePropsCancel}
                            />
                        </div>
                    </DialogContent>
                </Dialog>
            )}
            {previewMode && (
                <Dialog
                    fullScreen={false}
                    open={previewMode}
                    onClose={handleClose}
                    maxWidth="xl"
                    classes={{ paper: procedureStyles.paper }}
                >
                    <ResizableBox
                        width={previewWidth}
                        height={parentHeight}
                        className={resizableStyles.resizable}
                        style={{
                            maxWidth: '100%',
                            minHeight: '500px',
                            minWidth: '400px',
                        }}
                    >
                        <>
                            <DialogTitle
                                sx={{
                                    fontSize: '12px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div>{`Node Id ${currentNode?.id}`}</div>
                                <MdPhoneIphone
                                    className="react-icon"
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: 'auto',
                                        color: phoneWidth ? 'blue' : 'unset',
                                    }}
                                    onClick={togglePhoneWidth}
                                />
                                <MdClose
                                    className="react-icon"
                                    onClick={handleClose}
                                />
                            </DialogTitle>
                            <DialogContent
                                style={{ height: 'calc(100% - 56px)' }}
                            >
                                <Procedure
                                    procedureService={procedureService}
                                    contentService={contentService}
                                    useZoneNav={useZoneNav}
                                />
                            </DialogContent>
                        </>
                    </ResizableBox>
                </Dialog>
            )}
            {openDeleteConfirmDialog && (
                <Dialog
                    open={openDeleteConfirmDialog}
                    onClose={closeDeleteConfirmDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {'Delete Node'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`Are you sure you want to delete node #${
                                Array.isArray(selectedDeleteNode)
                                    ? selectedDeleteNode.join(', #')
                                    : selectedDeleteNode
                            }?`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDeleteConfirmDialog}>
                            Cancel
                        </Button>
                        <Button
                            autoFocus
                            variant="contained"
                            color="error"
                            onClick={handleNodeDelete}
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {showNodeFlow === true && openFlowSettingsDialog && (
                <ProcedureFlowSettings
                    openDialog={openFlowSettingsDialog}
                    setOpenDialog={setOpenFlowSettingsDialog}
                />
            )}
            {showNodeFlow === true && openFlowSettingsDialog && (
                <ProcedureFlowSettings
                    openDialog={openFlowSettingsDialog}
                    setOpenDialog={setOpenFlowSettingsDialog}
                />
            )}

            <NodeCategoriesModal
                procedure={procedure}
                openDialog={openCategoryDialog}
                setOpenDialog={setOpenCategoryDialog}
                updateProcedureData={updateProcedureData}
            />
        </>
    );
    //#endregion Render
};

export default ProcedureDesigner;
