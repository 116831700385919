import { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { NodeTypeIcons } from '_helpers';
import { MdClose } from 'react-icons/md';

import AppButton from 'components/AppButton';
import useStyles from './style';
import { ProcedureNodeTypes } from 'GeminiViewerComponent/components/Procedure/ProcedureNodeTypes';

const NodeTypeButton = ({
    setOpenDialog,
    openDialog = false,
    clickedAddButton = false,
    setClickedAddButton,
    handleButtonSelection = () => {},
}) => {
    const classes = useStyles();

    useEffect(() => {
        if (clickedAddButton) {
            setOpenDialog(true);
        }
    }, [clickedAddButton]);

    useEffect(() => {
        if (!openDialog) {
            setClickedAddButton(false);
        }
    }, [openDialog]);

    const handleToggleChange = (e, nodeType) => {
        e.preventDefault();
        setOpenDialog(!openDialog);
        handleButtonSelection(nodeType, clickedAddButton);
    };

    return (
        <Dialog
            open={openDialog}
            onClose={() => {
                if (clickedAddButton) {
                    setOpenDialog(!openDialog);
                } else {
                    setOpenDialog(false);
                }
            }}
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle fontWeight={'600'}>
                Select Node Type
                <IconButton
                    aria-label="close"
                    onClick={() => setOpenDialog(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 6,
                        color: '#000',
                    }}
                    size="large"
                >
                    <MdClose className="react-icon" />
                </IconButton>
            </DialogTitle>
            <DialogContent
                style={{
                    display: 'flex',
                    justifyContent: 'left',
                    flexWrap: 'wrap',
                }}
            >
                {ProcedureNodeTypes &&
                    Object.values(ProcedureNodeTypes)?.map((item) => (
                        <AppButton
                            variant="contained"
                            className="primaryButton"
                            key={`app-button-${item?.type}`}
                            style={{ margin: '5px', padding: 0 }}
                            handleButtonClick={(e) =>
                                handleToggleChange(e, item?.type)
                            }
                        >
                            <div className={classes.assetTypeIconWrap}>
                                {item?.displayName}&nbsp;&nbsp;
                                {NodeTypeIcons?.[item?.type]?.icon}
                            </div>
                        </AppButton>
                    ))}
            </DialogContent>
        </Dialog>
    );
};

export default NodeTypeButton;
