import { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { makeFormStyles } from 'forms/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import DirtyProcedure from '../DirtyProcedure';
import SaveNodeDataDialog from '../SaveNodeDataDialog';
import { SelectionAutocomplete } from 'components/_Autocomplete/SelectionAutocomplete';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    CommonNodeFormFIelds,
    ExcludeFromNavigationField,
    NodeCategoryField,
} from '../CommonNodeFormFIelds';
import {
    getAssetsPicklist,
    getItemsPicklist,
    getZonesPicklist,
    resetAssetsPicklistStatus,
    resetItemsPicklistStatus,
    resetZonesPicklistStatus,
    selectAssetsPicklist,
    selectAssetsPicklistLoadingStatus,
    selectItemsPicklist,
    selectItemsPicklistLoadingStatus,
    selectZonesPicklist,
    selectZonesPicklistLoadingStatus,
} from '_features/picklists/pickListsSlice';
import { setProcedureDirty } from '_features/procedures/proceduresSlice';

const makeNodeFormStyles = makeStyles(() => ({
    container: {
        padding: '20px',
        width: '100%',
        height: '100%',
    },
    form: {
        display: 'flex',
        width: '100%',
        height: 'auto',
        padding: '10px',
        border: '1px solid',
    },
    formBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        width: '100%',
    },
    submitButtonRow: {
        marginTop: 'auto',
        alignSelf: 'end',
        display: 'flex',
        gap: '5px',
    },
    contentRow: {
        cursor: 'pointer',
    },
    dialogPaper: {
        minHeight: '100%',
        maxHeight: '100%',
    },
    addButtonIcon: {
        padding: '3px !important',
        borderRadius: '25px !important',
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
    },
}));

const NavigationActionForm = ({
    nodes,
    node,
    onCancel,
    onSubmit,
    openSaveDataDialog,
    setOpenSaveDataDialog,
    procedureCategories,
}) => {
    const theme = useSelector(selectActiveTheme);
    const nodeFormStyles = makeNodeFormStyles(theme);
    const commonFormStyles = makeFormStyles(theme);
    const dispatch = useDispatch();

    const [formValues, setFormValues] = useState({ ...node });

    nodes = nodes.filter((nd) => nd.id !== node.id);
    useEffect(() => {
        setFormValues({
            exclude_from_navigation: true,
            ...node,
            procedureCategories: procedureCategories,
        });
        dispatch(resetAssetsPicklistStatus());
        dispatch(resetZonesPicklistStatus());
        dispatch(resetItemsPicklistStatus());
    }, [node]);

    useEffect(() => {
        return () => {
            dispatch(setProcedureDirty(false));
        };
    }, []);

    return (
        <div className={nodeFormStyles.container}>
            <div className={nodeFormStyles.form}>
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validate={() => {
                        const errors = {};
                        return errors;
                    }}
                    onSubmit={async (values) => {
                        onSubmit(values);
                    }}
                >
                    {({ values, setFieldValue, isSubmitting, dirty }) => (
                        <Form className={nodeFormStyles.formBody}>
                            <DirtyProcedure />
                            <CommonNodeFormFIelds />
                            <NodeCategoryField />
                            <ExcludeFromNavigationField />
                            <FormControl fullWidth>
                                <Field name="asset_id">
                                    {({
                                        field: { value },
                                        form: { setFieldValue },
                                    }) => (
                                        <SelectionAutocomplete
                                            title="Asset"
                                            keyProperty="asset_id"
                                            nameProperty="display_name"
                                            entityIds={value}
                                            setEntityIds={setFieldValue}
                                            onCustomChange={() => {
                                                dispatch(
                                                    resetZonesPicklistStatus()
                                                );
                                                setFieldValue('zone_id', null);
                                                setFieldValue('item_id', null);
                                            }}
                                            entitySelector={(state) =>
                                                selectAssetsPicklist(
                                                    state
                                                ).filter(
                                                    (asset) =>
                                                        asset.asset_type_id !==
                                                        3
                                                )
                                            }
                                            fetchEntityPage={getAssetsPicklist}
                                            formField={'asset_id'}
                                            multiSelection={false}
                                            entityStatusSelector={
                                                selectAssetsPicklistLoadingStatus
                                            }
                                        />
                                    )}
                                </Field>
                            </FormControl>
                            {values?.asset_id && (
                                <FormControl fullWidth>
                                    <Field name="zone_id">
                                        {({
                                            field: { value },
                                            form: { setFieldValue },
                                        }) => (
                                            <SelectionAutocomplete
                                                title="Zone"
                                                keyProperty="zone_id"
                                                nameProperty="display_name"
                                                entityIds={value}
                                                setEntityIds={setFieldValue}
                                                onCustomChange={() => {
                                                    dispatch(
                                                        resetItemsPicklistStatus()
                                                    );
                                                    setFieldValue(
                                                        'item_id',
                                                        null
                                                    );
                                                }}
                                                entitySelector={
                                                    selectZonesPicklist
                                                }
                                                fetchEntityPage={
                                                    getZonesPicklist
                                                }
                                                formField={'zone_id'}
                                                multiSelection={false}
                                                entityStatusSelector={
                                                    selectZonesPicklistLoadingStatus
                                                }
                                                fetchParams={{
                                                    assetId: values?.asset_id,
                                                }}
                                            />
                                        )}
                                    </Field>
                                </FormControl>
                            )}
                            {values?.asset_id && values?.zone_id && (
                                <FormControl fullWidth>
                                    <Field name="item_id">
                                        {({ form: { setFieldValue } }) => (
                                            <SelectionAutocomplete
                                                title="Item"
                                                keyProperty="item_id"
                                                nameProperty="display_name"
                                                entityIds={values?.item_id}
                                                setEntityIds={setFieldValue}
                                                entitySelector={
                                                    selectItemsPicklist
                                                }
                                                fetchEntityPage={
                                                    getItemsPicklist
                                                }
                                                formField={'item_id'}
                                                multiSelection={false}
                                                entityStatusSelector={
                                                    selectItemsPicklistLoadingStatus
                                                }
                                                fetchParams={{
                                                    zoneId: values?.zone_id,
                                                }}
                                            />
                                        )}
                                    </Field>
                                </FormControl>
                            )}
                            <FormControl
                                fullWidth
                                style={{
                                    marginTop: '10px',
                                }}
                            >
                                <InputLabel id="target-node-id">
                                    Target Node
                                </InputLabel>
                                <Select
                                    className={commonFormStyles.materialSelect}
                                    style={{
                                        margin: '0',
                                    }}
                                    labelId="target-node-id"
                                    margin="dense"
                                    id="target_node_id"
                                    label="Target Node"
                                    placeholder="Target Node"
                                    name={`target_node_id`}
                                    value={values?.action?.id}
                                    onChange={(e) => {
                                        setFieldValue('action', {
                                            id: e.target.value,
                                            type: 'node',
                                        });
                                    }}
                                >
                                    <MenuItem key={'none'} value={''}>
                                        None
                                    </MenuItem>
                                    <MenuItem key={'next'} value={'next'}>
                                        Next
                                    </MenuItem>
                                    <MenuItem key={'back'} value={'back'}>
                                        Back
                                    </MenuItem>
                                    {nodes?.length > 0 &&
                                        nodes.map((nd) => (
                                            <MenuItem
                                                key={nd?.id}
                                                value={nd?.id}
                                            >
                                                {nd?.title} - #{nd?.id}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            {openSaveDataDialog && (
                                <SaveNodeDataDialog
                                    openSaveDataDialog={openSaveDataDialog}
                                    setOpenSaveDataDialog={
                                        setOpenSaveDataDialog
                                    }
                                    isSubmitting={isSubmitting}
                                    onCancel={onCancel}
                                />
                            )}
                            <div className={nodeFormStyles.submitButtonRow}>
                                <button
                                    className={commonFormStyles.cancel}
                                    type="button"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        if (dirty) {
                                            setOpenSaveDataDialog(true);
                                        } else {
                                            onCancel();
                                        }
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={commonFormStyles.submit}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default NavigationActionForm;
