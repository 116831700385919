import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        '& .MuiInput-underline:before': {
            borderBottom: 'unset !important',
        },
        '& .MuiInputBase-root': {
            border: '1px solid #BDC3C7',
            borderRadius: '5px',
        },
        '& .MuiNativeSelect-select': {
            padding: '8px 24px 8px 5px',
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '15px',
        alignItems: 'center',
    },
    containerRight: {
        display: 'flex',
        alignItems: 'center',
    },
    buttonSpacing: {
        margin: '0px 5px',
    },
    inputRoot: {
        color: 'inherit',
        border: '1px solid #BDC3C7',
        borderRadius: '5px',
    },
    formInput: {
        marginRight: '5px',
    },
    hidden: {
        display: 'none',
    },
}));
