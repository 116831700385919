import { useEffect } from 'react';
import {
    getValidationTypes,
    selectValidationTypes,
    selectValidationTypesLoadingStatus,
} from '_features/validationTypes/validationTypesSlice';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { useValidationRawTypes } from './useValidationRawTypes';

export function useValidationTypes() {
    const dispatch = useDispatch();

    const validationTypesLoadingStatus = useSelector(
        selectValidationTypesLoadingStatus
    );
    const validationTypes = useSelector(selectValidationTypes);
    const {
        loadingStatus: validationRawTypesLoadingStatus,
        validationRawTypes,
    } = useValidationRawTypes();

    useEffect(() => {
        const fetchValidationTypes = async () => {
            console.log('Getting validation types');
            dispatch(getValidationTypes());
        };
        if (
            validationRawTypesLoadingStatus === LoadingStatus.Loaded &&
            validationTypesLoadingStatus === LoadingStatus.Idle
        ) {
            fetchValidationTypes();
        }
    }, [validationRawTypesLoadingStatus]);

    return {
        validationTypesLoadingStatus,
        validationRawTypesLoadingStatus,
        validationTypes,
        validationRawTypes,
    };
}
