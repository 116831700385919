import React from 'react';
import { useSelector } from 'react-redux';
import { makeCommonFormPreviewStyles } from '../styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import FormTextField from './FormTextField';
import InitialField from './InitialField';
import MultilineField from './MultilineField';
import CheckboxField from './CheckboxField';
import SelectField from './SelectField';
import DateField from './DateField';
import RadioGroupField from './RadioGroupField';
import LabelField from './LabelField';
import HiddenField from './HiddenField';
import LinkField from './LinkField';
import MediaField from './MediaField';
import MediaCompareField from './MediaCompareField';
import InspectionField from './InspectionField';
import { Box } from '@mui/material';

const GroupField = ({
    data,
    fieldValue,
    setFieldValue,
    errors,
    setErrors,
    handleBlur,
    isReadonly = false,
    isSubmitAllow = true,
    handleClick = () => {},
    contentService,
}) => {
    const theme = useSelector(selectActiveTheme);
    const formStyles = makeCommonFormPreviewStyles(theme);
    return (
        <div style={{ width: '100%', padding: '5px 0' }}>
            <fieldset
                disabled={isReadonly}
                style={{
                    border: '1px solid #cccccc',
                    borderRadius: '5px',
                    margin: '0 5px',
                }}
                onClick={(e) => {
                    !isSubmitAllow && handleClick(e, data);
                }}
            >
                {data?.hide_label !== true && data?.label && (
                    <legend
                        style={{
                            marginLeft: '10px',
                            padding: '3px',
                            fontSize: '14px',
                            color: '#6d7178',
                        }}
                    >
                        {data.label}
                    </legend>
                )}

                <div>
                    {data?.fields && data?.fields?.length > 0 ? (
                        data.fields.map((fieldData, index) => {
                            switch (fieldData.type) {
                                case 'text':
                                    return (
                                        <FormTextField
                                            data={fieldData}
                                            key={
                                                `Group` + fieldData?.id + index
                                            }
                                            inGroup={true}
                                            isReadOnly={isReadonly}
                                            isSubmitAllow={isSubmitAllow}
                                            fieldValue={fieldValue}
                                            handleBlur={handleBlur}
                                            handleClick={handleClick}
                                        />
                                    );
                                case 'initial':
                                    return (
                                        <InitialField
                                            key={
                                                `Group` + fieldData?.id + index
                                            }
                                            data={fieldData}
                                            fieldValue={fieldValue}
                                            setFieldValue={setFieldValue}
                                            errors={errors}
                                            setErrors={setErrors}
                                            inGroup={true}
                                            isReadOnly={isReadonly}
                                            isSubmitAllow={isSubmitAllow}
                                            handleClick={handleClick}
                                        />
                                    );
                                case 'multiline':
                                    return (
                                        <MultilineField
                                            key={
                                                `Group` + fieldData?.id + index
                                            }
                                            data={fieldData}
                                            inGroup={true}
                                            isReadOnly={isReadonly}
                                            isSubmitAllow={isSubmitAllow}
                                            fieldValue={fieldValue}
                                            handleBlur={handleBlur}
                                            handleClick={handleClick}
                                        />
                                    );
                                case 'checkbox':
                                    return (
                                        <CheckboxField
                                            key={
                                                `Group` + fieldData?.id + index
                                            }
                                            data={fieldData}
                                            inGroup={true}
                                            fieldValue={fieldValue}
                                            setFieldValue={setFieldValue}
                                            errors={errors}
                                            setErrors={setErrors}
                                            isReadOnly={isReadonly}
                                            isSubmitAllow={isSubmitAllow}
                                            validationError={
                                                errors?.[fieldData?.variable]
                                            }
                                            handleBlur={handleBlur}
                                            handleClick={handleClick}
                                        />
                                    );
                                case 'select':
                                    return (
                                        <SelectField
                                            key={
                                                `Group` + fieldData?.id + index
                                            }
                                            data={fieldData}
                                            inGroup={true}
                                            fieldValue={fieldValue}
                                            setFieldValue={setFieldValue}
                                            isReadOnly={isReadonly}
                                            isSubmitAllow={isSubmitAllow}
                                            validationError={
                                                errors?.[fieldData?.variable]
                                            }
                                            handleBlur={handleBlur}
                                            handleClick={handleClick}
                                        />
                                    );
                                case 'datetime':
                                    return (
                                        <DateField
                                            key={
                                                `Group` + fieldData?.id + index
                                            }
                                            data={fieldData}
                                            inGroup={true}
                                            fieldValue={fieldValue}
                                            setFieldValue={setFieldValue}
                                            isReadOnly={isReadonly}
                                            isSubmitAllow={isSubmitAllow}
                                            validationError={
                                                errors?.[fieldData?.variable]
                                            }
                                            handleBlur={handleBlur}
                                            handleClick={handleClick}
                                        />
                                    );
                                case 'radiogroup':
                                    return (
                                        <RadioGroupField
                                            key={
                                                `Group` + fieldData?.id + index
                                            }
                                            data={fieldData}
                                            inGroup={true}
                                            fieldValue={fieldValue}
                                            setFieldValue={setFieldValue}
                                            isReadOnly={isReadonly}
                                            isSubmitAllow={isSubmitAllow}
                                            validationError={
                                                errors?.[fieldData?.variable]
                                            }
                                            handleBlur={handleBlur}
                                            handleClick={handleClick}
                                        />
                                    );
                                case 'label':
                                    return (
                                        <LabelField
                                            key={
                                                `Group` + fieldData?.id + index
                                            }
                                            data={fieldData}
                                            inGroup={true}
                                            isSubmitAllow={isSubmitAllow}
                                            handleClick={handleClick}
                                            contentService={contentService}
                                        />
                                    );
                                case 'hidden':
                                    return (
                                        <HiddenField
                                            data={fieldData}
                                            key={
                                                `Group` + fieldData?.id + index
                                            }
                                            setFieldValue={setFieldValue}
                                            isSubmitAllow={isSubmitAllow}
                                            handleClick={handleClick}
                                        />
                                    );
                                case 'link':
                                    if (!isReadonly) {
                                        return (
                                            <LinkField
                                                data={fieldData}
                                                fieldValue={fieldValue}
                                                styles={formStyles}
                                                key={
                                                    `Group` +
                                                    fieldData?.id +
                                                    index
                                                }
                                                isSubmitAllow={isSubmitAllow}
                                                handleClick={handleClick}
                                            />
                                        );
                                    }
                                    return null;
                                case 'media':
                                    return (
                                        <MediaField
                                            key={
                                                `Group` + fieldData?.id + index
                                            }
                                            isSubmitAllow={isSubmitAllow}
                                            data={fieldData}
                                            inGroup={true}
                                            errors={errors}
                                            setErrors={setErrors}
                                            handleClick={handleClick}
                                        />
                                    );
                                case 'mediacompare':
                                    return (
                                        <MediaCompareField
                                            key={
                                                `Group` + fieldData?.id + index
                                            }
                                            data={fieldData}
                                            isSubmitAllow={isSubmitAllow}
                                            inGroup={true}
                                            handleClick={handleClick}
                                        />
                                    );
                                case 'inspectionpoint':
                                    return (
                                        <InspectionField
                                            key={
                                                `Group` + fieldData?.id + index
                                            }
                                            data={fieldData}
                                            fieldValue={fieldValue}
                                            setFieldValue={setFieldValue}
                                            inGroup={true}
                                            isSubmitAllow={isSubmitAllow}
                                            validationError={
                                                errors?.[fieldData?.variable]
                                            }
                                            handleClick={handleClick}
                                            handleBlur={handleBlur}
                                        />
                                    );
                            }
                        })
                    ) : (
                        <Box p={2}></Box>
                    )}
                </div>
            </fieldset>
            <div></div>
        </div>
    );
};

export default GroupField;
