import React, { useEffect, useState } from 'react';
import { PageHeader } from 'components';
import { useSnackbar } from 'notistack';
import { Link, useParams } from 'react-router-dom';
import useResizeObserver from 'use-resize-observer';
import { FiGrid, FiArrowLeft } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ProcessIcon } from 'assets/process.svg';
import {
    Tooltip,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Divider,
} from '@mui/material';
import {
    MdFilter,
    MdFolderZip,
    MdLibraryAdd,
    MdPresentToAll,
    MdShare,
    MdVisibility,
} from 'react-icons/md';

import { Action, Role } from '_helpers';
import { accountsSlice } from 'app/store';
import { useStyles } from 'scenes/styles';
import { playerURL } from '_helpers/playerURL';
import { assetService } from '_features/_services';
import { AssetZones } from './components/AssetZones';
import { fetchTags } from '_features/tags/tagsSlice';
import { DataNotFound } from 'components/DataNotFound';
import { prefixUrl } from 'GeminiViewerComponent/_helpers';
import ProcedureDesigner from 'components/ProcedureDesigner';
import AssetShareDialog from './components/AssetShareDialog';
import { exportAsset } from '_features/assets/assetsTableSlice';
import { UpdateOrbitImageForm } from 'forms/UpdateOrbitImageForm';
import { cloneDeep } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { fetchLinkByAssetId } from 'GeminiViewerComponent/_features/links/linksSlice';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { updateNodes } from 'GeminiViewerComponent/components/Procedure/_features/procedureSlice';
import {
    clearActiveAsset,
    selectActiveAsset,
    updateActiveAsset,
} from 'GeminiViewerComponent/_features/asset/assetSlice';
import {
    LATEST_DRAFT,
    LATEST_RELEASE,
} from 'components/ProcedureDesigner/components/FormNodeEditor/_helpers/constant';
import {
    getAsset,
    selectAssetLoadingStatus,
    setDisplayAssetProcedureNodes,
} from '_features/assets/assetManagerSlice';
import { openPanel, setEditObjectId } from '_features/common/formSlice';
import { resetTableZones } from '_features/zones/zonesTableSlice';

const { selectActiveUser } = accountsSlice;

const Asset = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const asset = useSelector(selectActiveAsset);
    const activeUser = useSelector(selectActiveUser);
    const assetStatus = useSelector(selectAssetLoadingStatus);

    const [openShareDialog, setOpenShareDialog] = useState(false);
    const [openUpdateOrbitImagesDialog, setOpenUpdateOrbitImagesDialog] =
        useState(false);

    const [errorMessage, setErrorMessage] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    let params = useParams();
    let id = params.id;

    const { ref, width = 1, height = 1 } = useResizeObserver();

    const fillBranchNodes = async ({
        branchesAdded,
        parentProcedure,
        nodes,
        assetsLoaded,
    }) => {
        if (!Array.isArray(nodes) || nodes?.length === 0) {
            return branchesAdded;
        }
        for (var node of nodes) {
            if (node.type === 'branch') {
                if (node.asset_id !== 0 && !assetsLoaded.has(node.asset_id)) {
                    assetsLoaded.add(node.asset_id);
                    const response = await assetService.getById(node.asset_id);
                    if (response && Number(response.asset_type_id) === 3) {
                        branchesAdded++;
                        var procedure = JSON.parse(response.procedure_json);
                        procedure.procedure_id = response.procedure_id;
                        procedure.parent_tree_id = parentProcedure.procedure_id;
                        node.asset_title = procedure.title ?? node.title;
                        node.branch_procedure = procedure;
                        // Recursively get branch nodes in current tree
                        branchesAdded = await fillBranchNodes({
                            branchesAdded,
                            parentProcedure: procedure,
                            nodes: procedure.nodes,
                            assetsLoaded: assetsLoaded,
                        });
                    }
                }
            }
        }
        return branchesAdded;
    };

    async function fetchAsset() {
        const response = await dispatch(getAsset(id));
        if (!response.error && Number(response.payload?.asset_type_id) !== 3) {
            await dispatch(fetchLinkByAssetId(id));
        }

        if (!response.error && Number(response.payload?.asset_type_id) === 3) {
            setErrorMessage(null);
        } else {
            dispatch(deactivateLoading());
            if (response.error) {
                enqueueSnackbar(response.error.message, {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'error',
                });
                setErrorMessage(response.error.message);
            }
            return;
        }
        // if (response.data?.asset_type_id === 2) {
        //     var checkList = JSON.parse(response.data?.procedure_json);
        //     dispatch(setChecklistState(checkList));
        //     dispatch(loadChecklist(true));
        // }
    }

    async function getTags() {
        await dispatch(fetchTags({ sort: 'name' }));
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function getLatestRelease(asset, isDraft) {
        return isDraft && asset?.last_draft_published_url
            ? `${playerUrlStr}?published=${asset?.last_draft_published_url}`
            : asset?.last_release_published_url
            ? `${playerUrlStr}?published=${asset?.last_release_published_url}`
            : null;
    }

    const handleRelease = (menuItem) => {
        const isDraft = menuItem === LATEST_DRAFT;

        const latestItemUrl = getLatestRelease(asset, isDraft);

        if (latestItemUrl) {
            window.open(latestItemUrl, '_blank');
        }

        handleClose();
    };
    useEffect(() => {
        return () => {
            // Clear out active asset when component is destroyed
            // NOTE: This is required or the AssetZones component
            // loadMoreRows can get called when navigating to the
            // asset page - but with the previously viewed asset
            // so when the fetch is fulfilled it can load zones
            // from the previous asset into the current list of zones
            dispatch(resetTableZones());
            dispatch(clearActiveAsset());
        };
    }, [dispatch]);

    useEffect(() => {
        fetchAsset();
        getTags();
    }, [id, dispatch]);

    const playerUrlStr = playerURL(asset?.player_url);

    return errorMessage ||
        (!asset?.is_active && assetStatus === LoadingStatus.Loaded) ? (
        <DataNotFound
            title={asset.asset_type_id === 3 ? 'Procedure' : 'Asset'}
        />
    ) : (
        <div
            ref={ref}
            className={classes.content}
            style={{ overflow: 'hidden' }}
        >
            <PageHeader
                header={asset.asset_type_id === 3 ? 'Procedure' : 'Asset'}
                icon={asset.asset_type_id === 3 ? <ProcessIcon /> : <FiGrid />}
            />
            {assetStatus === LoadingStatus.Loaded ? (
                <React.Fragment>
                    <div className={classes.assetHeader}>
                        <Link
                            to={`/${
                                asset.asset_type_id === 3
                                    ? 'procedures'
                                    : 'assets'
                            }`}
                            className={classes.backArrow}
                        >
                            <FiArrowLeft />
                        </Link>
                        <Tooltip title={asset?.display_name}>
                            <span
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'inline-block',
                                    whiteSpace: 'nowrap',
                                    width: 'auto',
                                }}
                            >
                                {asset?.display_name}
                                <Typography
                                    fontSize="14px"
                                    width="100%"
                                    textOverflow="ellipsis"
                                    overflow="hidden"
                                    whiteSpace="nowrap"
                                >
                                    {asset?.description}
                                </Typography>
                            </span>
                        </Tooltip>
                        {activeUser?.role !== 'User' && (
                            <Tooltip title="Publish Asset">
                                <span>
                                    <PopupAction
                                        action={Action.Publish}
                                        object={asset}
                                        level={'asset'}
                                        showLabel={false}
                                    />
                                </span>
                            </Tooltip>
                        )}
                        {!Role?.DisableAdminRoles?.includes(
                            activeUser?.role
                        ) && (
                            <>
                                <Tooltip title={`Export Data`}>
                                    <IconButton
                                        onClick={async () => {
                                            dispatch(activateLoading());
                                            var result = await dispatch(
                                                exportAsset({
                                                    asset_id: asset.asset_id,
                                                })
                                            );

                                            dispatch(deactivateLoading());
                                            if (result.error) {
                                                enqueueSnackbar(
                                                    result.error.message,
                                                    {
                                                        action: (key) => (
                                                            <SnackbarDismiss
                                                                key={key}
                                                            />
                                                        ),
                                                        variant: 'error',
                                                    }
                                                );
                                                return;
                                            }
                                        }}
                                    >
                                        <MdPresentToAll className="react-icon" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={`Create Export Job`}>
                                    <IconButton
                                        onClick={async () => {
                                            dispatch(
                                                setEditObjectId({
                                                    formKey: `assetExportForm`,
                                                    object_id: asset.asset_id,
                                                })
                                            );
                                            dispatch(
                                                openPanel({
                                                    formKey: `assetExportForm`,
                                                    formAction: 'Create',
                                                })
                                            );
                                        }}
                                    >
                                        <MdFolderZip className="react-icon" />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                        {asset?.last_published_url && (
                            <>
                                <Tooltip title="Player">
                                    <IconButton
                                        id="basic-button"
                                        aria-controls={
                                            open ? 'basic-menu' : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                            open ? 'true' : undefined
                                        }
                                        onClick={handleClick}
                                    >
                                        <MdVisibility className="react-icon" />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem
                                        onClick={() =>
                                            handleRelease(LATEST_DRAFT)
                                        }
                                        disabled={
                                            !getLatestRelease(asset, true)
                                        }
                                    >
                                        {LATEST_DRAFT}
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem
                                        onClick={() =>
                                            handleRelease(LATEST_RELEASE)
                                        }
                                        disabled={
                                            !getLatestRelease(asset, false)
                                        }
                                    >
                                        {LATEST_RELEASE}
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                        <Tooltip title="Edit Asset">
                            <IconButton size="small">
                                <PopupAction
                                    action={Action.Edit}
                                    object={asset}
                                    level={'asset'}
                                    showLabel={false}
                                />
                            </IconButton>
                        </Tooltip>
                        {!asset?.isOrbitZoneExist &&
                            [1, 2].includes(Number(asset?.asset_type_id)) &&
                            activeUser?.role !== 'User' && (
                                <Tooltip
                                    title={
                                        Number(asset?.asset_type_id) === 2
                                            ? 'Import Pano Images'
                                            : 'Import Orbit'
                                    }
                                >
                                    <Link
                                        to={`/asset/${asset?.asset_id}/${
                                            Number(asset?.asset_type_id) === 2
                                                ? 'panoimporter'
                                                : 'videoimporter'
                                        }`}
                                    >
                                        <IconButton
                                            style={{ marginLeft: '-10px' }}
                                            size="large"
                                        >
                                            <MdLibraryAdd className="react-icon" />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            )}
                        {asset?.isOrbitZoneExist && (
                            <Tooltip title={'Update orbit images'}>
                                <IconButton
                                    onClick={() =>
                                        setOpenUpdateOrbitImagesDialog(true)
                                    }
                                >
                                    <MdFilter className="react-icon" />
                                </IconButton>
                            </Tooltip>
                        )}
                        {asset?.last_published_url && (
                            <Tooltip title="Share">
                                <IconButton
                                    onClick={() => setOpenShareDialog(true)}
                                >
                                    <MdShare className="react-icon" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                    {Number(asset?.asset_type_id) === 3 ? (
                        <ProcedureDesigner
                            procedureId={asset?.procedure_id}
                            procedure={asset?.procedure}
                            parentWidth={width}
                            parentHeight={height}
                            assetId={asset?.asset_id}
                            fetchAsset={fetchAsset}
                        />
                    ) : (
                        (Number(asset?.asset_type_id) === 1 ||
                            Number(asset?.asset_type_id) === 2) && (
                            <AssetZones asset={asset} />
                        )
                    )}
                </React.Fragment>
            ) : (
                <LoadingLogo
                    styleClassName={classes.loadingSvg}
                    rotateClassName={classes.rotateSvg}
                />
            )}
            {openShareDialog && (
                <AssetShareDialog
                    playerUrlStr={`${playerUrlStr}?published=${prefixUrl(
                        asset?.last_published_url,
                        activeUser.s3_prefix
                    )}`}
                    openDialog={openShareDialog}
                    setOpenDialog={setOpenShareDialog}
                />
            )}
            {openUpdateOrbitImagesDialog && (
                <UpdateOrbitImageForm
                    asset={asset}
                    openUpdateOrbitImagesDialog={openUpdateOrbitImagesDialog}
                    setOpenUpdateOrbitImagesDialog={
                        setOpenUpdateOrbitImagesDialog
                    }
                />
            )}
        </div>
    );
};

export { Asset };
