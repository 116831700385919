import { useState } from 'react';

export const Spin = 0;
export const Move = 1;

export const useHandleDragging = (tridiRef) => {
    const [viewMode, setViewMode] = useState(Spin);

    const modeClickHandler = () => {
        setViewMode((viewMode) => {
            if (tridiRef) {
                const newMode = viewMode === Spin ? Move : Spin;
                tridiRef.current.toggleMoving(newMode === Spin ? false : true);
                return newMode;
            }
            return viewMode;
        });
    };

    return { modeClickHandler, viewMode };
};
