import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    isAboutModalOpen: false,
    object: null,
    action: {},
    level: '',
};

const actionModalSlice = createSlice({
    name: 'actionModal',
    initialState,
    reducers: {
        showActionModal: (state, action) => {
            return action.payload;
        },
        closeModal: (state) => initialState,
        showAboutModal: (state, action) => {
            state.isAboutModalOpen = action.payload;
        },
    },
});

export const selectActionModalInfo = (state) => state.actionModal;

export const isAboutModalOpen = (state) => state.actionModal.isAboutModalOpen;

export const { showAboutModal, showActionModal, closeModal } =
    actionModalSlice.actions;

export default actionModalSlice.reducer;
