import React, { useState, useRef, useEffect, useCallback } from 'react';
import { MdAdd } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Tooltip } from '@mui/material';

import { useStyles } from './styles';
import SearchWithFilters from 'components/SearchWithFilters';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { ResizableTable } from 'GeminiViewerComponent/components/_Tables/ResizableTable';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import {
    openPanel,
    closePanel,
    setEditObjectId,
    closeAllPanel,
} from '_features/common/formSlice';
import {
    resizableLabelHeader,
    resizableLabelCell,
    resizableActionCell,
    resizableCheckCell,
    resizableCheckHeaderCell,
} from 'components';

function LocationTable({
    locations,
    pageInfo,
    locationPayload,
    setLocationPayload,
    sortDirection,
    sortBy,
    handleSort,
}) {
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);
    const tableClasses = tableStyles();
    const dispatch = useDispatch();
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);

    const loadedRowsMap = useSelector(
        (state) => state.location.loadedLocationMap
    );

    const headerHeight = 40;
    const rowHeight = 70;

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== locations.length) {
            setSelected(locations.map((row) => row.tool_location_id));
            setSelectedAll(true);
        }
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedIds = locations.map((loc) => loc.tool_location_id);
            setSelected(newSelectedIds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    const handleClick = (event, tool_location_id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(tool_location_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, tool_location_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        setSelectedAll(newSelected.length === locations.length);
    };

    const handleRowClick = async ({ rowData }) => {
        await dispatch(closePanel({ formKey: 'locationForm' }));
        if (rowData?.tool_location_id) {
            await dispatch(
                setEditObjectId({
                    formKey: 'locationForm',
                    object_id: rowData?.tool_location_id,
                })
            );
            dispatch(
                openPanel({ formKey: 'locationForm', formAction: 'Edit' })
            );
        }
    };

    const columns = [
        {
            accessorKey: 'tool_location_id',
            size: 50,
            minSize: 50,
            enableResizing: false,
            label: '',
            padding: 'checkbox',
            cellLevel: 'tool_location',
            numSelected: selected.length,
            rowCount: locations.length,
            handleClick: handleClick,
            handleSelectAllClick: handleSelectAllClick,
            isSelected: isSelected,
            header: (info) =>
                resizableCheckHeaderCell(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableCheckCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'tool_location_id',
            flexGrow: 1,
            size: 50,
            minSize: 50,
            label: '',
            padding: 'none',
            enableResizing: false,
            actionCellLevel: 'tool_location',
            targetPopup: 'locationManagement',
            formKey: 'locationForm',
            dispatch: dispatch,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableActionCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'display_name',
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'Location Name',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
    ];

    // eslint-disable-next-line no-unused-vars
    function loadMoreRows({ startIndex, stopIndex }) {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setLocationPayload({
                ...locationPayload,
                searchString: searchString,
            });
        }, 1000),
        [locationPayload]
    );

    const handleCreateLocation = () => {
        dispatch(closeAllPanel());
        dispatch(
            openPanel({
                formKey: 'locationForm',
                formAction: 'Create',
                clearEditId: true,
            })
        );
    };

    return (
        <div className={classes.root}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="10px"
            >
                <Tooltip title="New Tool Location">
                    <IconButton
                        className={classes.addLocationIcon}
                        onClick={handleCreateLocation}
                        size="large"
                    >
                        <MdAdd className="react-icon" />
                    </IconButton>
                </Tooltip>
                <SearchWithFilters
                    filter={false}
                    onChange={(evt) => debouncedSearch(evt.target.value)}
                />
            </Box>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    toolTipTitle={'Delete'}
                    totalCount={pageInfo.TotalCount}
                >
                    {/* <PopupAction
                        action={Action.DeleteArray}
                        object={{
                            locationIds: selected,
                            setLocationIDs: setSelected,
                        }}
                        level={'location'}
                        showLabel={false}
                    /> */}
                </EnhancedTableToolbar>
            )}
            <div
                className={classes.locationWrapper}
                style={{
                    height: `calc(100vh - ${
                        selected.length > 0 ? '305px' : '240px'
                    })`,
                }}
            >
                <ResizableTable
                    initialRows={locations}
                    allRows={locations}
                    totalRowCount={pageInfo.TotalCount}
                    loadedRowsMap={loadedRowsMap}
                    loadMoreRows={loadMoreRows}
                    columns={columns}
                    cellClassName={tableClasses.flexContainer}
                    className={tableClasses.table}
                    rowClassName={tableClasses.flexContainer}
                    headerHeight={headerHeight}
                    rowHeight={rowHeight}
                    onRowClick={handleRowClick}
                />
            </div>
        </div>
    );
}

export { LocationTable };
