import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Button,
} from '@mui/material';

const UnsavedCaptionsDialog = ({ openDialog, setOpenDialog, handleClose }) => {
    return (
        openDialog && (
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {'Unsaved Changes'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`You have unsaved changes. Continue?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} autoFocus>
                        No
                    </Button>
                    <Button onClick={handleClose}>Yes</Button>
                </DialogActions>
            </Dialog>
        )
    );
};

export default UnsavedCaptionsDialog;
