import { makeStyles } from '@mui/styles';

const flyerGridStyles = makeStyles(() => ({
    flyerGrid: {
        display: 'grid',
        gap: '15px',
        // gridAutoRows: 'minmax(100px, auto)',
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
    },
}));

export { flyerGridStyles };
