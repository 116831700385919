import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { cellAlignment } from 'GeminiViewerComponent/_helpers';

const CellAlignmentField = ({ values, setFieldValue }) => {
    return (
        <FormControl fullWidth>
            <InputLabel id="alignment-label">Cell Alignment</InputLabel>
            <Select
                labelId="alignment-label"
                id="alignment_field"
                label="Cell Alignment"
                value={values?.cellAlignment}
                onChange={(e) => {
                    setFieldValue('cellAlignment', e.target.value);
                }}
            >
                {Object.values(cellAlignment)?.map((item) => (
                    <MenuItem key={`fieldType-${item?.id}`} value={item.value}>
                        {item.display_name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default CellAlignmentField;
