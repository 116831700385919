import { useState } from 'react';

import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    FormControl,
    Grid,
} from '@mui/material';
import { SelectionAutocomplete } from 'components/_Autocomplete/SelectionAutocomplete';
import {
    getAssetsPicklist,
    getZonesPicklist,
    resetItemsPicklistStatus,
    resetZonesPicklistStatus,
    selectAssetsPicklist,
    selectAssetsPicklistLoadingStatus,
    selectZonesPicklist,
    selectZonesPicklistLoadingStatus,
} from '_features/picklists/pickListsSlice';

const HotspotPanoDataForm = ({
    valuesData,
    setFieldValue,
    setOpenHotspotOptionForm,
}) => {
    const dispatch = useDispatch();
    const INIT_VALUES = {
        asset_id: valuesData?.asset_id,
        zone_id: valuesData?.zone_id,
    };

    const assetList = useSelector(selectAssetsPicklist);
    const zoneList = useSelector(selectZonesPicklist);
    // eslint-disable-next-line no-unused-vars
    const [initValues, setInitValues] = useState({
        ...INIT_VALUES,
    });

    const fetchAssetName = (assetId) => {
        if (assetId) {
            const filterAssetName = assetList.filter((val) => {
                return assetId === val.asset_id ? val.display_name : null;
            });
            return filterAssetName[0].display_name;
        }
    };
    const fetchZoneName = (zoneId) => {
        if (zoneId) {
            const filterZoneName = zoneList.filter((val) => {
                return zoneId === val.zone_id ? val.display_name : null;
            });
            return filterZoneName[0].display_name;
        }
    };
    const handleSubmit = (values) => {
        setOpenHotspotOptionForm(false);
        if (
            valuesData.asset_id !== values?.asset_id ||
            valuesData.zone_id !== values?.zone_id
        ) {
            if (values?.asset_id) {
                var assetName = fetchAssetName(values?.asset_id);
                setFieldValue('asset_id', values.asset_id);
                setFieldValue('asset_name', assetName ? assetName : null);
                if (values?.zone_id) {
                    var zoneName = fetchZoneName(values?.zone_id);
                    setFieldValue('zone_id', values.zone_id);
                    setFieldValue('zone_name', zoneName ? zoneName : null);
                } else {
                    setFieldValue('zone_id', null);
                    setFieldValue('zone_name', null);
                }
            } else {
                setFieldValue('asset_id', null);
                setFieldValue('asset_name', null);
                setFieldValue('zone_id', null);
                setFieldValue('zone_name', null);
            }
            setFieldValue('hotspot_options', []);
            setFieldValue('hotspot_target_combinations', []);
            dispatch(resetItemsPicklistStatus());
        }
    };

    return (
        <Dialog open={true} fullWidth maxWidth="sm">
            <DialogTitle>Select Asset & Zone</DialogTitle>
            <DialogContent
                style={{
                    paddingTop: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Formik
                            initialValues={initValues}
                            onSubmit={(values) => {
                                handleSubmit(values);
                            }}
                        >
                            {({ values }) => (
                                <Form>
                                    <FormControl
                                        fullWidth
                                        style={{ marginTop: '10px' }}
                                    >
                                        <Field name="asset_id">
                                            {({
                                                field: { value },
                                                form: { setFieldValue },
                                            }) => (
                                                <SelectionAutocomplete
                                                    title="Asset"
                                                    keyProperty="asset_id"
                                                    nameProperty="display_name"
                                                    entityIds={value}
                                                    setEntityIds={setFieldValue}
                                                    onCustomChange={() => {
                                                        dispatch(
                                                            resetZonesPicklistStatus()
                                                        );
                                                        setFieldValue(
                                                            'zone_id',
                                                            null
                                                        );
                                                    }}
                                                    entitySelector={
                                                        selectAssetsPicklist
                                                    }
                                                    fetchEntityPage={
                                                        getAssetsPicklist
                                                    }
                                                    formField={'asset_id'}
                                                    multiSelection={false}
                                                    entityStatusSelector={
                                                        selectAssetsPicklistLoadingStatus
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </FormControl>
                                    {!!values.asset_id && (
                                        <FormControl
                                            fullWidth
                                            style={{ marginTop: '10px' }}
                                        >
                                            <Field name="zone_id">
                                                {({
                                                    field: { value },
                                                    form: { setFieldValue },
                                                }) => (
                                                    <SelectionAutocomplete
                                                        title="Zone"
                                                        keyProperty="zone_id"
                                                        nameProperty="display_name"
                                                        entityIds={value}
                                                        setEntityIds={
                                                            setFieldValue
                                                        }
                                                        onCustomChange={() => {
                                                            dispatch(
                                                                resetItemsPicklistStatus()
                                                            );
                                                        }}
                                                        entitySelector={
                                                            selectZonesPicklist
                                                        }
                                                        fetchEntityPage={
                                                            getZonesPicklist
                                                        }
                                                        formField={'zone_id'}
                                                        multiSelection={false}
                                                        entityStatusSelector={
                                                            selectZonesPicklistLoadingStatus
                                                        }
                                                        fetchParams={{
                                                            assetId:
                                                                values?.asset_id,
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </FormControl>
                                    )}

                                    <div
                                        style={{
                                            justifyContent: 'end',
                                            display: 'flex',
                                            marginTop: '25px',
                                        }}
                                    >
                                        <Button
                                            onClick={() => {
                                                setOpenHotspotOptionForm(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button type="submit">Save</Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default HotspotPanoDataForm;
