import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    getConfigValues,
    selectConfigValue,
    selectConfigValues,
    selectConfigValuesAssetId,
    selectConfigValuesClientId,
    selectConfigValuesLoadingStatus,
    setConfigAssetClient,
} from '_features/configValues/configValuesSlice';
import { useConfigFields } from './useConfigFields';

export function useConfigValues({ clientId, assetId }) {
    const dispatch = useDispatch();

    const {
        validationTypesLoadingStatus,
        configFieldsLoadingStatus,
        configFields,
        validationTypes,
        validationRawTypes,
    } = useConfigFields();

    const configValuesLoadingStatus = useSelector(
        selectConfigValuesLoadingStatus
    );
    let configValues = useSelector((state) =>
        selectConfigValues(state, configFields)
    );
    const configAssetId = useSelector(selectConfigValuesAssetId);
    const configClientId = useSelector(selectConfigValuesClientId);
    const configValue = useSelector(selectConfigValue);

    useEffect(() => {
        const fetchConfigValues = async () => {
            if (
                clientId !== configClientId ||
                assetId !== configAssetId ||
                !configValue ||
                configValue?.length <= 0
            ) {
                console.log('Getting configValues');
                dispatch(
                    setConfigAssetClient({
                        assetId: assetId,
                        clientId: clientId,
                    })
                );
                return await dispatch(getConfigValues({ clientId, assetId }));
            }
        };

        if (
            validationTypesLoadingStatus === LoadingStatus.Loaded &&
            configFieldsLoadingStatus === LoadingStatus.Loaded
        ) {
            fetchConfigValues();
        }
    }, [
        validationTypesLoadingStatus,
        configFieldsLoadingStatus,
        clientId,
        assetId,
    ]);

    return {
        configValuesLoadingStatus,
        configFieldsLoadingStatus,
        validationTypesLoadingStatus,
        configValues,
        configFields,
        validationTypes,
        validationRawTypes,
    };
}
