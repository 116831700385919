import useStyles from '../styles';
import { useSelector } from 'react-redux';
import Tridi from 'GeminiViewerComponent/components/react-tridi';

import { useZoneMediaConfigValue } from 'hooks/useZoneMediaConfigValue';
import { ViewControls } from 'GeminiViewerComponent/components/Common/ViewControls';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { OrbitNavigation } from 'GeminiViewerComponent/components/OrbitViewer/OrbitNavigation';

const TridiComponent = ({
    tridiImages,
    viewerRef,
    zoneFrameIndexes,
    hasImages,
    currentImageIdx,
    inverse,
    setInverse,
    onNextClick,
    onPrevClick,
    onFrameChange,
    onKeyFrameChange,
    showHeader = true,
    showPreview = false,
}) => {
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);
    const zoneMediaValue = useZoneMediaConfigValue();

    return (
        <>
            <Tridi
                ref={viewerRef}
                className={classes.preview}
                zoom={1}
                minZoom={1}
                maxZoom={5}
                draggable={true}
                images={tridiImages}
                format="jpeg"
                keyFrames={zoneFrameIndexes}
                startImageIdx={
                    hasImages && !showPreview
                        ? currentImageIdx
                        : zoneFrameIndexes.length > 0
                        ? zoneFrameIndexes[0]
                        : 0
                }
                dragInterval={3}
                mousewheel={!hasImages}
                resetOnToggleMove={false}
                inverse={inverse}
                setInverse={setInverse}
                showControlBar={false}
                showStatusBar={false}
                autoplay={false}
                limitImageHeight={true}
                onFrameChange={onFrameChange}
                onKeyFrameChange={onKeyFrameChange}
            />
            {tridiImages.length > 0 && (
                <>
                    {showHeader && (
                        <div className={classes.orbitHeader}>
                            <div className={classes.titleBar}>
                                <ViewControls
                                    className={classes.orbitControls}
                                    tridiRef={viewerRef}
                                    showPreview={false}
                                    zoneMediaConfigValue={zoneMediaValue}
                                />
                            </div>
                        </div>
                    )}
                    {zoneFrameIndexes.length > 0 && (
                        <OrbitNavigation
                            className={classes.orbitNavigation}
                            tridiRef={viewerRef}
                            onNextClick={onNextClick}
                            onPrevClick={onPrevClick}
                            hasImages={hasImages}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default TridiComponent;
