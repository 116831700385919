import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdClose } from 'react-icons/md';

import {
    closePanel,
    getDirtyFlag,
    setIsDirty,
} from '_features/common/formSlice';
import { setEnableMultipleHotspot } from 'shared/hotspotsSlice';
import { sidePanelHeaderStyles } from './styles';
import { VisualEdit } from 'scenes/Asset/components/AssetZones/VisualEdit';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import UnsavedFormDataDialog from 'forms/UnsavedFormDataDialog';

const SidePanelHeader = ({ icon, header, subheader, formKey, data }) => {
    const dispatch = useDispatch();

    const theme = useSelector(selectActiveTheme);

    const classes = sidePanelHeaderStyles(theme);

    const getDirty = useSelector(getDirtyFlag);
    const [openDialog, setOpenDialog] = useState(false);

    const handleClose = () => {
        dispatch(setEnableMultipleHotspot(false));
        dispatch(closePanel({ formKey: formKey }));
        dispatch(setIsDirty(false));
    };

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.headerTopRow}>
                    {data && <VisualEdit rowData={data} />}
                </div>
                <div className={classes.headerBottomRow}>
                    {icon &&
                        React.cloneElement(icon, {
                            className: classes.headerIcon,
                        })}
                    <div className={classes.info}>
                        {subheader && (
                            <div className={classes.subheader}>{subheader}</div>
                        )}
                        <div className={classes.header}>{header}</div>
                    </div>
                </div>
            </div>
            <div className={classes.headerCloseButton}>
                <MdClose
                    className={`react-icon ${classes.close}`}
                    onClick={() => {
                        if (getDirty) {
                            setOpenDialog(true);
                        } else {
                            handleClose();
                        }
                    }}
                />
            </div>
            <UnsavedFormDataDialog
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                handleClose={handleClose}
            />
        </div>
    );
};

export { SidePanelHeader };
