import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    '@keyframes rotate': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' },
    },
    submit: {
        backgroundColor: (props) => props.colors.button.primaryBackground,
        color: (props) => props.colors.button.primaryForeground,
        border: '0px',
        padding: '10px',
        borderRadius: '5px',
        fontSize: '18px',
        textTransform: 'uppercase',
        marginTop: '10px',
        cursor: 'pointer',
        '&:disabled': {
            backgroundColor: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(50%)',
        },
        '&:hover': {
            backgroundColor: (props) => props.colors.button.primaryBackground,
            color: (props) => props.colors.button.primaryForeground,
            filter: 'brightness(90%)',
        },
    },
    multiSelectDoneButton: {
        width: '100%',
        marginTop: '0px',
        padding: '0.3rem',
    },
}));

export { useStyles };
