import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { SelectionAutocomplete } from 'components';
import { makeFormStyles } from '../../forms/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { accountsSlice, accountsThunk } from 'app/store';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { MdClose } from 'react-icons/md';
const { updateUser, fetchRoles } = accountsThunk;
const { selectAllRoles, getRolesStatus } = accountsSlice;

const EditMultipleUsersDialog = ({ open, handleClose, users }) => {
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);

    const dispatch = useDispatch();
    const initValues = {
        role_id: null,
    };

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        dispatch(activateLoading({ showProgress: true }));
        for (const user_id of users) {
            await dispatch(
                updateUser({
                    user_id: user_id,
                    role: values.role_id,
                })
            );
        }
        setSubmitting(false);
        resetForm();
        handleClose();
        dispatch(deactivateLoading());
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ fontSize: '20px', padding: '10px 24px' }}>
                Edit Multiple User
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 6,
                        color: '#000',
                    }}
                    size="large"
                >
                    <MdClose className="react-icon" />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ width: '400px', marginTop: '10px' }}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{ ...initValues }}
                    validationSchema={Yup.object({
                        role_id: Yup.number().nullable().required('Required'),
                    })}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        handleSubmit(values, setSubmitting, resetForm);
                    }}
                >
                    {({ isSubmitting, errors }) => (
                        <Form
                            className={classes.form}
                            style={{ padding: '0px' }}
                        >
                            <Field className={classes.input} name="role_id">
                                {({
                                    field: { value },
                                    form: { setFieldValue },
                                }) => (
                                    <SelectionAutocomplete
                                        title="Roles"
                                        keyProperty="role_id"
                                        nameProperty="display_name"
                                        entityIds={value}
                                        setEntityIds={setFieldValue}
                                        entitySelector={selectAllRoles}
                                        entityStatusSelector={getRolesStatus}
                                        fetchEntityPage={fetchRoles}
                                        formField={'role_id'}
                                        multiSelection={false}
                                        enableCache
                                        refreshCacheType="allAssignableRoles"
                                    />
                                )}
                            </Field>
                            {errors?.role_id && (
                                <div className={classes.inputErr}>
                                    {errors.role_id}
                                </div>
                            )}
                            <button
                                className={classes.submit}
                                type="submit"
                                disabled={isSubmitting}
                            >
                                Update
                            </button>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export { EditMultipleUsersDialog };
