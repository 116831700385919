import React, { useCallback } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useFormikContext } from 'formik';

import FieldForm from '../../FieldForm';
import { useDispatch } from 'react-redux';
import { setFieldPreview } from '_features/procedures/proceduresSlice';

const FieldEditDialog = ({
    openEditFieldForm,
    setOpenEditFieldForm,
    activeField,
    setActiveField,
    sortedNodes,
    setSortedNodes,
    unsavedVariables,
    setUnsavedVariables,
    node,
    procedureNodes,
    isValidVariableName,
    checkUniqueVariableName,
    setTreeState,
}) => {
    const dispatch = useDispatch();
    const { setFieldValue } = useFormikContext();
    const DEFAULT_FIELD_STATE = {
        id: uuidv4(),
        display_name: '',
        action: 'create',
        has_group: false,
    };

    const editField = (searchData, searchValue) => {
        return searchData.map((i) => {
            if (i.id === searchValue.id) {
                return { ...i, ...searchValue };
            } else if (Object.prototype.hasOwnProperty.call(i, 'fields')) {
                return { ...i, fields: editField(i.fields, searchValue) };
            } else {
                return i;
            }
        });
    };

    const addNodeAt = useCallback(
        (nodes, newNode) => {
            if (!Array.isArray(nodes) || nodes.length <= 0) {
                return [];
            }
            let isAdded = false;
            let rootNodes = [];
            if (
                ['group', 'table'].includes(activeField?.add_node_at?.type) ===
                true
            ) {
                sortedNodes.forEach((nd) => {
                    if (
                        activeField?.add_node_at?.id === nd.id &&
                        ['group', 'table'].includes(nd?.type) === true &&
                        !isAdded
                    ) {
                        if (
                            Array.isArray(nd?.fields) &&
                            nd?.fields?.length > 0
                        ) {
                            nd = { ...nd, fields: [newNode, ...nd.fields] };
                        } else {
                            nd = { ...nd, fields: [newNode] };
                        }
                        isAdded = true;
                    }
                    rootNodes = [...rootNodes, nd];
                });
            } else {
                nodes?.forEach((nd) => {
                    if (isAdded) {
                        rootNodes = [...rootNodes, nd];
                    } else {
                        if (nd.id === activeField?.add_node_at?.id) {
                            if (['group', 'table'].includes(nd.type) === true) {
                                if (
                                    Array.isArray(nd?.fields) &&
                                    nd?.fields?.length > 0
                                ) {
                                    nd = {
                                        ...nd,
                                        fields: [newNode, ...nd.fields],
                                    };
                                } else {
                                    nd = { ...nd, fields: [newNode] };
                                }
                            }
                            rootNodes = [...rootNodes, nd, newNode];
                            isAdded = true;
                        } else if (nd.type === 'group' || nd.type === 'table') {
                            let ndFields = [];
                            nd?.fields?.forEach((field) => {
                                if (isAdded) {
                                    ndFields = [...ndFields, field];
                                } else {
                                    if (
                                        field.id ===
                                        activeField?.add_node_at?.id
                                    ) {
                                        ndFields = [
                                            ...ndFields,
                                            field,
                                            newNode,
                                        ];
                                        isAdded = true;
                                    } else {
                                        ndFields = [...ndFields, field];
                                    }
                                }
                            });
                            rootNodes = [
                                ...rootNodes,
                                { ...nd, fields: ndFields },
                            ];
                        } else {
                            rootNodes = [...rootNodes, nd];
                        }
                    }
                });
            }
            return rootNodes;
        },
        [activeField, sortedNodes]
    );

    const addField = (tree, field) => {
        tree.items[field.id] = {
            id: field.id,
            children: [],
            hasChildren:
                field.type === 'group' || field.type === 'table' ? false : true,
            isExpanded:
                field.type === 'group' || field.type === 'table' ? true : false,
            isChildrenLoading: false,
            data: field,
        };
        if (field.type === 'group' || field.type === 'table') {
            const group = tree.items[field.id];
            group.hasChildren = false;
            field?.fields?.forEach((fld) => {
                group.children.push(fld.id);
                addField(tree, fld);
            });
        }
    };

    const fieldsToTree = (fields) => {
        var tree = {
            rootId: 'root',
            items: {
                root: {
                    id: 'root',
                    children: [],
                    hasChildren: false,
                    isExpanded: true,
                    isChildrenLoading: false,
                },
            },
        };

        fields?.forEach((field) => {
            addField(tree, field);
            tree.items.root.children.push(field.id);
        });

        return tree;
    };

    const handleSubmit = useCallback(
        (values) => {
            let finalNodes = [];
            if (values?.action === 'edit') {
                finalNodes = editField(sortedNodes, values);
            } else {
                if (values.type === 'group' && !values?.fields) {
                    values = {
                        id: values.id,
                        label: values.label,
                        type: values.type,
                    };
                }
                if (values.type === 'table' && !values?.columns) {
                    values = {
                        id: values.id,
                        label: values.label,
                        type: values.type,
                    };
                }
                delete values.add_node_at;
                delete values.action;
                if (
                    activeField?.add_node_at &&
                    Object.keys(activeField?.add_node_at)?.length > 0
                ) {
                    finalNodes = addNodeAt(sortedNodes, values);
                } else {
                    finalNodes = [...sortedNodes, values];
                }
            }
            if (values?.variable) {
                setUnsavedVariables(
                    unsavedVariables.length > 0
                        ? [
                              ...unsavedVariables.filter(
                                  (unsave) => unsave.name !== values.id
                              ),
                              { id: values.id, name: values.variable },
                          ]
                        : [{ id: values.id, name: values.variable }]
                );
            }
            setSortedNodes(finalNodes);
            setOpenEditFieldForm(false);
            setFieldValue('fields', finalNodes);
            setActiveField({ ...DEFAULT_FIELD_STATE, id: uuidv4() });
            setTreeState({ tree: fieldsToTree(finalNodes) });
        },
        [activeField, sortedNodes]
    );

    const handleCancel = () => {
        setOpenEditFieldForm(false);
        setActiveField({ ...DEFAULT_FIELD_STATE, id: uuidv4() });
        dispatch(
            setFieldPreview({
                previewAction: null,
                field: null,
            })
        );
    };

    const getComparisonField = useCallback(() => {
        const comparisonFields = [];
        Object.keys(node ?? {})?.length > 0 &&
            node?.fields.length > 0 &&
            node?.fields.map((field) => {
                if (field?.type === 'group' || field?.type === 'table') {
                    if (field?.fields?.length > 0) {
                        field?.fields.map((groupField) => {
                            if (
                                groupField?.image_attachments_allow === true ||
                                groupField?.type === 'media'
                            ) {
                                comparisonFields.push(groupField);
                            }
                        });
                    }
                }
                if (
                    field?.image_attachments_allow === true ||
                    field?.type === 'media'
                ) {
                    comparisonFields.push(field);
                }
            });
        return comparisonFields;
    }, [node]);

    return (
        <Dialog
            fullWidth
            fullScreen={false}
            open={openEditFieldForm}
            maxWidth="sm"
            disableEnforceFocus={true}
        >
            <DialogContent>
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'white',
                        overflow: 'auto',
                    }}
                >
                    <FieldForm
                        node={activeField}
                        procedureNodes={procedureNodes}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        isValidVariableName={isValidVariableName}
                        unsavedVariables={unsavedVariables}
                        checkUniqueVariableName={checkUniqueVariableName}
                        getComparisonField={getComparisonField}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export { FieldEditDialog };
