import React from 'react';
import { Field } from 'formik';
import { TextField as MUITextField } from '@mui/material';

const ColspanField = () => {
    return (
        <Field
            name="colspan"
            render={({ field }) => (
                <MUITextField
                    {...field}
                    margin="dense"
                    variant="outlined"
                    label="Colspan"
                    type="number"
                    placeholder="Colspan"
                    inputProps={{
                        inputMode: 'numeric',
                        min: 1,
                    }}
                />
            )}
        />
    );
};

export default ColspanField;
