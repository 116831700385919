import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    fetchDeployementPackageJob,
    getDeploymentPackageJobPageInfo,
    getDeploymentPackageJobStatus,
    resetDeploymentPackageState,
    selectAllDeploymentPackageJob,
} from '_features/jobs/jobsSlice';
import { DeploymentPackageJobsTable } from './DeploymentPackageJobsTable';

const DeploymentPackageStatusTab = () => {
    const dispatch = useDispatch();
    const ref = useRef(false);
    const deploymentPackageJob = useSelector(selectAllDeploymentPackageJob);
    const deploymentPackageJobStatus = useSelector(
        getDeploymentPackageJobStatus
    );
    const pageInfo = useSelector(getDeploymentPackageJobPageInfo);
    const [deploymentPackageJobsPayload, setDeploymentPackgeJobsPayload] =
        useState({});
    const [sortBy, setSortBy] = useState('created_date');
    const [sortDirection, setSortDirection] = useState('DESC');

    const getDeploymentData = async (payload) => {
        dispatch(activateLoading());
        await dispatch(fetchDeployementPackageJob({ ...payload }));
        dispatch(deactivateLoading());
    };

    useEffect(() => {
        if (ref.current === true) {
            getDeploymentData({
                ...deploymentPackageJobsPayload,
                sort: `${sortDirection === 'ASC' ? '' : '-'}${sortBy}`,
            });
        }
    }, [deploymentPackageJobsPayload]);

    useEffect(() => {
        return async () => {
            await dispatch(resetDeploymentPackageState());
        };
    }, []);

    useEffect(() => {
        if (deploymentPackageJobStatus === LoadingStatus.Idle) {
            getDeploymentData({
                ...deploymentPackageJobsPayload,
                sort: `${sortDirection === 'ASC' ? '' : '-'}${sortBy}`,
            });
        }
        ref.current = true;
    }, [deploymentPackageJobStatus]);

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        setDeploymentPackgeJobsPayload({
            ...deploymentPackageJobsPayload,
            sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
        });
    };

    return (
        <DeploymentPackageJobsTable
            deploymentPackageJob={deploymentPackageJob}
            pageInfo={pageInfo}
            deploymentPackageJobsPayload={deploymentPackageJobsPayload}
            setDeploymentPackgeJobsPayload={setDeploymentPackgeJobsPayload}
            sortDirection={sortDirection}
            sortBy={sortBy}
            handleSort={(_sortBy, _sortDirection) =>
                setSorting(_sortBy, _sortDirection)
            }
        />
    );
}

export { DeploymentPackageStatusTab };
