import { makeStyles } from '@mui/styles';

const makeFieldFormStyles = makeStyles((theme) => ({
    container: {
        padding: '20px',
        width: '100%',
        minWidth: '500px',
        [theme.breakpoints.down('md')]: {
            minWidth: 'auto',
        },
        height: '100%',
    },
    form: {
        display: 'flex',
        width: '100%',
        height: 'auto',
        padding: '10px',
        border: '1px solid',
    },
    formBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        width: '100%',
    },
    submitButtonRow: {
        marginTop: 'auto',
        alignSelf: 'end',
        display: 'flex',
        gap: '5px',
    },
    contentRow: {
        cursor: 'pointer',
    },
    dialogPaper: {
        minHeight: '100%',
        maxHeight: '100%',
    },
    addButtonIcon: {
        padding: '10px !important',
        borderRadius: '25px !important',
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
    },
    addButtonLessSpaceing: {
        padding: '6px !important',
    },
    radioGroupsContainer: {
        border: '1px solid #6b6b6b',
        padding: '15px 12px',
        borderRadius: '4px',
    },
    radioGroupsText: { fontWeight: '700', marginBottom: '10px' },
}));

export { makeFieldFormStyles };
