import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    createNew: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        margin: '15px 0px',
    },
    createNewTitle: {
        fontSize: '24px',
        fontWeight: 300,
        color: '#BDC3C7',
        textAlign: 'center',
    },
    createNewImage: {
        height: '200px',
    },
}));
