import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@mui/styles';
import { MdSearch } from 'react-icons/md';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';

import { dialogStyles } from 'GeminiViewerComponent/components/Procedure/styles';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    fetchContentByTypeId,
    getContentPageInfo,
    getContentStatus,
    selectContentById,
} from '_features/content/contentSlice';
import ExistingContentCard from '../ExistingContentCard';
import { ExistingImageVirtualGrid } from '../ExistingImageVirtualGrid';
import { ContentTypes } from '_helpers';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';

const ExistingImageDialog = ({
    openDialog,
    setOpenDialog,
    referenceImages,
    setReferenceImages,
}) => {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const classes = dialogStyles(theme);
    const content = useSelector(selectContentById);
    const contentPageInfo = useSelector(getContentPageInfo);
    const contentStatus = useSelector(getContentStatus);
    const parentRef = useRef(null);
    const loadedRowsMap = useSelector(
        (state) => state.content.contentLoadedRowsMap
    );
    const [selectedContent, setSelectedContent] = useState([]);
    const [contentPayload, setContentPayload] = useState({
        reset: true,
        startIndex: 0,
        stopIndex: 19,
        contentTypeIds: [
            ContentTypes.Jpeg.id,
            ContentTypes.Jpg.id,
            ContentTypes.Png.id,
            ContentTypes.Svg.id,
        ],
    });

    const useStyles = makeStyles(() => ({
        actionBarRight: {
            '& > div > div': {
                outline: 'none',
            },
        },
    }));

    const [selectedAll, setSelectedAll] = useState(false);
    const [shiftSelectionInitialId, setShiftSelectionInitialId] =
        useState(null);

    const isSelected = useCallback(
        (id) => selectedContent.includes(id) === true,
        [selectedContent]
    );

    const getContentData = async (payload) => {
        dispatch(activateLoading());
        await dispatch(fetchContentByTypeId({ ...payload }));
        dispatch(deactivateLoading());
    };

    const handleSubmit = () => {
        const refImages = [];
        content?.forEach((cnt) => {
            if (selectedContent.includes(cnt.content_id)) {
                refImages.push({
                    id: uuidv4(),
                    content_id: cnt.content_id,
                    name: cnt.display_name,
                    url: cnt.url,
                    isSaved: true,
                });
            } else {
                return;
            }
        });
        setReferenceImages([...referenceImages, ...refImages]);
        setOpenDialog(false);
    };

    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setContentPayload({
                ...contentPayload,
                searchString: searchString,
            });
        }, 1000),
        [contentPayload]
    );

    useEffect(() => {
        getContentData({ ...contentPayload });
    }, [openDialog, contentPayload]);

    const handleSelectAllClick = useCallback(
        (event) => {
            if (event.target.checked) {
                const newSelectedIds = content.map((item) => item.content_id);
                setSelectedContent(newSelectedIds);
                setSelectedAll(true);
            } else {
                setSelectedContent([]);
                setSelectedAll(false);
            }
        },
        [content]
    );

    const handleSelect = useCallback(
        (event, item) => {
            let currentId =
                shiftSelectionInitialId ??
                selectedContent?.[selectedContent.length - 1];
            let targetId = item.content_id;
            if (selectedContent?.length === 0) {
                setSelectedContent([item.content_id]);
                setShiftSelectionInitialId(item.content_id);
            } else if (event.shiftKey === true) {
                let newContents = [];
                let begin = false;
                let stop = false;
                content?.forEach((cnt) => {
                    if (stop === false) {
                        if (begin === true) {
                            if (
                                cnt.content_id === currentId ||
                                cnt.content_id === targetId
                            ) {
                                stop = true;
                            }
                            newContents.push(cnt.content_id);
                        } else if (
                            cnt.content_id === currentId ||
                            cnt.content_id === targetId
                        ) {
                            begin = true;
                            newContents.push(cnt.content_id);
                        }
                    }
                });
                setSelectedContent([...newContents]);
            } else if (event.ctrlKey === true || event.metaKey === true) {
                if (selectedContent.includes(item.content_id) === true) {
                    setSelectedContent(
                        selectedContent.filter((cnt) => cnt !== item.content_id)
                    );
                } else {
                    setSelectedContent([...selectedContent, item.content_id]);
                }
                setShiftSelectionInitialId(item.content_id);
            } else {
                setSelectedContent([item.content_id]);
                setShiftSelectionInitialId(item.content_id);
            }
        },
        [selectedContent, content]
    );

    return (
        <Dialog
            fullWidth
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            maxWidth={'lg'}
        >
            <DialogTitle
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        id="input-with-icon-textfield"
                        placeholder="Search..."
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MdSearch className="react-icon" />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => debouncedSearch(e.target.value)}
                        variant="outlined"
                    />
                    <FormControlLabel
                        style={{ margin: '0 0 0 20px' }}
                        control={<Checkbox checked={selectedAll} />}
                        label="Select All"
                        onChange={handleSelectAllClick}
                    />
                </div>
                <Typography>{selectedContent.length} selected</Typography>
            </DialogTitle>
            <DialogContent style={{ overflow: 'hidden' }}>
                <div ref={parentRef}>
                    <div
                        className={useStyles().actionBarRight}
                        style={{ height: 'calc(100vh - 315px)' }}
                    >
                        <AutoSizer style={{ outline: 'none' }}>
                            {({ width, height }) => (
                                <ExistingImageVirtualGrid
                                    initialRows={content}
                                    allRows={content}
                                    totalRowCount={contentPageInfo.TotalCount}
                                    loadedRowsMap={loadedRowsMap}
                                    loadMoreRows={loadMoreRows}
                                    width={width}
                                    height={height}
                                    rowHeight={200}
                                    cellRenderer={cellRenderer}
                                    parentRef={parentRef}
                                    style={{ overflowX: 'hidden' }}
                                />
                            )}
                        </AutoSizer>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    className={classes.cancel}
                    onClick={() => setOpenDialog(false)}
                >
                    Cancel
                </Button>
                <Button onClick={handleSubmit} className={classes.submit}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );

    async function loadMoreRows({ startIndex, stopIndex }) {
        if (contentStatus !== LoadingStatus.Loading) {
            await dispatch(
                fetchContentByTypeId({
                    ...contentPayload,
                    reset: false,
                    startIndex: startIndex,
                    stopIndex: stopIndex,
                })
            );
        }
    }

    function cellRenderer(gridItem, key, style, columnWidth) {
        if (gridItem == null) {
            return null;
        }
        return (
            <div style={style} key={key}>
                <ExistingContentCard
                    handleSelect={handleSelect}
                    content={gridItem}
                    width={columnWidth - 20}
                    isSelected={isSelected}
                />
            </div>
        );
    }
};

export default ExistingImageDialog;
