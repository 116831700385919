import React from 'react';
import { InputBase, MenuItem, Button, Badge, Paper } from '@mui/material';
import { MdArrowDropDown, MdArrowDropUp, MdSearch } from 'react-icons/md';

import { useStyles } from './styles';

const SearchWithFilters = ({
    style,
    filterBadge,
    onChange,
    children,
    filter = true,
    searchString,
    setSorting = () => {},
    sortDirection,
    sortBy,
    addButtonText = 'Add Filters',
    clearFilters = () => {},
    addFilters = () => {},
}) => {
    const [toggleFilter, setToggleFilter] = React.useState(false);
    const classes = useStyles();

    const handleToggleFilter = () => {
        setToggleFilter(!toggleFilter);
    };

    return (
        <div className={classes.actionBarRight} style={style}>
            <div style={{ position: 'relative', width: '100%' }}>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <MdSearch className="react-icon" />
                    </div>
                    <InputBase
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: filter
                                ? classes.inputInput
                                : classes.inputInputWithoutFilter,
                        }}
                        onChange={onChange}
                        inputProps={{ type: 'search' }}
                        value={searchString}
                    />
                </div>
                {filter && (
                    <div className={classes.filterContainer}>
                        <div
                            className={classes.filterContent}
                            onClick={handleToggleFilter}
                        >
                            <Badge
                                className={
                                    filterBadge ? classes.filterBadge : ''
                                }
                                sx={{ ml: 'auto' }}
                                badgeContent={filterBadge}
                                invisible={!filterBadge}
                            >
                                <div className={classes.filterText}>SORT</div>
                            </Badge>
                            {toggleFilter ? (
                                <MdArrowDropUp className="react-icon" />
                            ) : (
                                <MdArrowDropDown className="react-icon" />
                            )}
                        </div>
                        {toggleFilter && (
                            <Paper
                                id="simple-menu"
                                onClick={() => handleToggleFilter}
                                sx={{ padding: '1rem' }}
                            >
                                {children}
                                <MenuItem
                                    className={classes.filterButtons}
                                    disableRipple
                                >
                                    <div className={classes.filterClearButton}>
                                        <Button
                                            className={
                                                classes.filterButtonPadding
                                            }
                                            onClick={() => {
                                                setSorting(
                                                    'display_name',
                                                    'ASC'
                                                );
                                                clearFilters();
                                                setToggleFilter(false);
                                            }}
                                        >
                                            Clear
                                        </Button>
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <Button
                                            className={
                                                classes.filterButtonPadding
                                            }
                                            onClick={() => {
                                                setSorting(
                                                    sortBy,
                                                    sortDirection
                                                );
                                                addFilters();
                                                setToggleFilter(false);
                                            }}
                                            color="secondary"
                                            variant="contained"
                                        >
                                            {addButtonText}
                                        </Button>
                                    </div>
                                </MenuItem>
                            </Paper>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SearchWithFilters;
