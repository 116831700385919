import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    Divider,
    Tooltip,
    Checkbox,
    Box,
} from '@mui/material';
import { assetCardStyles } from './styles';
import { PopupActions, PinAction } from 'components';
import clsx from 'clsx';
import { MdCalendarToday, MdInfoOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Action } from '_helpers';
import { selectAssetTypes } from '_features/assets/assetManagerSlice';
import getActionIcon from 'GeminiViewerComponent/_helpers/getActionIcon';
import useAnalyticsEventTracker from 'shared/hooks/useAnalyticsEventTracker';
import {
    toggleGenericModal,
    setGenericModalContent,
} from '_features/globals/visibilitySlice';
import { DateUtils } from 'GeminiViewerComponent/_helpers/dateUtils';

export default function AssetCard({
    asset,
    width,
    assetType,
    handleClick,
    cellLevel,
    isSelected,
    readOnly,
    selected,
}) {
    const dispatch = useDispatch();
    const classes = assetCardStyles();
    const gaEventTracker = useAnalyticsEventTracker('Assets');
    const lastPublishedDate = asset.last_release_published_date
        ? DateUtils.formatPublishedDate(asset.last_release_published_date)
        : '-- not published --';
    const draftPublishedDate = asset.last_draft_published_date
        ? DateUtils.formatPublishedDate(asset.last_draft_published_date)
        : '';
    const rowDataId = asset[`${cellLevel}_id`];
    const preventDefault = (event) => {
        event.preventDefault();
    };
    const assetTypes = useSelector(selectAssetTypes);
    const assetTypeTitle = assetTypes
        ?.filter((type) => type?.asset_type_id === asset?.asset_type_id)
        .map((name) => name?.display_name)[0];

    const handleGA = () => {
        gaEventTracker('Asset view by id', 'Card View', asset.asset_id);
    };

    const isDate = function (date) {
        return new Date(date) !== 'Invalid Date' && !isNaN(new Date(date));
    };

    const dragStartHandler = (e) => {
        e.dataTransfer.setData('cardInfo', JSON.stringify(asset));
        e.dataTransfer.setData('assetIds', JSON.stringify(selected));
    };

    return (
        <div
            id={asset?.asset_id}
            draggable={
                selected && selected?.length > 0
                    ? selected.includes(asset.asset_id)
                        ? 'true'
                        : 'false'
                    : 'true'
            }
            style={{
                margin: '8px',
                userSelect: `${
                    selected && selected?.length > 0
                        ? selected.includes(asset.asset_id)
                            ? 'all'
                            : 'none'
                        : 'all'
                }`,
            }}
            onDragStart={dragStartHandler}
        >
            <Link
                onClick={handleGA}
                to={`/${assetType === 'procedures' ? 'procedure' : 'asset'}/${
                    asset.asset_id
                }`}
                className={classes.cardLink}
            >
                <Card
                    className={classes.card}
                    style={{ width: width }}
                    data-asset-id={asset.asset_id}
                >
                    <div className={classes.assetIcon} title={assetTypeTitle}>
                        {asset.asset_type_id === 1
                            ? getActionIcon(Action.Product.iconId, {
                                  fill: '#ffffff',
                                  width: '24px',
                                  height: '24px',
                              })
                            : asset.asset_type_id === 2
                            ? getActionIcon(Action.Place.iconId, {
                                  fill: '#ffffff',
                                  width: '24px',
                                  height: '24px',
                              })
                            : getActionIcon(Action.Procedure.iconId, {
                                  fill: '#ffffff',
                                  width: '24px',
                                  height: '24px',
                              })}
                    </div>
                    <CardMedia
                        component="img"
                        image={asset.image_url || 'no_image_preview.svg'}
                        title={asset.display_name}
                        className={classes.cardImage}
                    />
                    <CardContent className={classes.cardContainer}>
                        <div
                            className={classes.cardContainerWrapper}
                            data-asset-id={asset.asset_id}
                        >
                            <Typography
                                className={classes.cardHeader}
                                component="div"
                                data-asset-id={asset.asset_id}
                            >
                                <span
                                    style={{
                                        display: '-webkit-box',
                                        userSelect: 'none',
                                    }}
                                    className={classes.cardHeader}
                                    title={asset.display_name}
                                    data-asset-id={asset.asset_id}
                                >
                                    {asset.display_name}
                                </span>
                                <div className={classes.iconWrapper}>
                                    {readOnly !== true && (
                                        <Checkbox
                                            color="primary"
                                            sx={{ padding: 0 }}
                                            checked={isSelected(rowDataId)}
                                            onClick={(event) =>
                                                handleClick(event, rowDataId)
                                            }
                                        />
                                    )}
                                    <span
                                        className={clsx(
                                            classes.pin,
                                            classes.cardPin,
                                            {
                                                [classes.isPinned]:
                                                    asset.is_pinned,
                                            }
                                        )}
                                        data-asset-id={asset.asset_id}
                                        onClick={preventDefault}
                                    >
                                        <PinAction
                                            pinId={asset.asset_id}
                                            level={'asset'}
                                        />
                                    </span>
                                </div>
                            </Typography>
                            <Typography
                                title={asset.description}
                                className={classes.cardDescription}
                                data-asset-id={asset.asset_id}
                            >
                                {asset.description}
                            </Typography>
                            <Typography
                                className={classes.authorDescription}
                                data-asset-id={asset.asset_id}
                            >
                                {asset.owner_last_name}
                            </Typography>
                            {(asset?.disclaimer_tooltip ||
                                asset?.disclaimer_html) && (
                                <Tooltip
                                    title={asset?.disclaimer_tooltip}
                                    componentsProps={{
                                        tooltip: { className: classes.tooltip },
                                    }}
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        if (
                                            asset?.disclaimer_html &&
                                            asset.disclaimer_html !== ''
                                        ) {
                                            await dispatch(
                                                setGenericModalContent(
                                                    asset?.disclaimer_html ??
                                                        asset?.disclaimer_tooltip
                                                )
                                            );
                                            dispatch(toggleGenericModal());
                                        }
                                    }}
                                >
                                    <Typography
                                        className={clsx(
                                            classes.disclaimer,
                                            classes.cardDisclaimer
                                        )}
                                    >
                                        <MdInfoOutline className="react-icon" />
                                    </Typography>
                                </Tooltip>
                            )}
                        </div>
                        <Divider sx={{ width: '100%' }} />
                        <Box className={classes.cardBottom}>
                            <Box sx={{ maxWidth: '80%', userSelect: 'none' }}>
                                <Tooltip
                                    title={`${
                                        isDate(lastPublishedDate)
                                            ? 'Published Date:'
                                            : ''
                                    } ${lastPublishedDate}`}
                                >
                                    <Typography className={classes.cardDate}>
                                        <MdCalendarToday className="react-icon" />
                                        {lastPublishedDate}
                                    </Typography>
                                </Tooltip>
                                {draftPublishedDate && (
                                    <Tooltip
                                        title={`${
                                            isDate(draftPublishedDate)
                                                ? 'Draft Date:'
                                                : ''
                                        } ${draftPublishedDate}`}
                                    >
                                        <Typography
                                            className={classes.cardDate}
                                        >
                                            <MdCalendarToday
                                                className="react-icon"
                                                color="red"
                                            />
                                            {draftPublishedDate}
                                        </Typography>
                                    </Tooltip>
                                )}
                            </Box>
                            <PopupActions
                                object={asset}
                                level={'asset'}
                                onClick={preventDefault}
                                targetPopup={'asset'}
                                size="medium"
                            />
                        </Box>
                    </CardContent>
                </Card>
            </Link>
        </div>
    );
}
