import { makeStyles } from '@mui/styles';

const sceneMenuStyles = makeStyles(() => ({
    sceneMenu: {
        display: 'flex',
        flexDirection: 'column',
        flex: 'none',
        height: 'fit-content',
        zIndex: '1',
        pointerEvents: 'all',
    },
    previewsContainer: {
        width: '0',
        overflow: 'visible',
    },
}));

export { sceneMenuStyles };
