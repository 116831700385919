import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MenuItem, Select, Typography } from '@mui/material';

import { setIsDirty } from '_features/common/formSlice';
import {
    selectConfigValuesAssetId,
    selectConfigValuesClientId,
    setConfigValuesAssetId,
} from '_features/configValues/configValuesSlice';
import {
    getClientAssetsPicklist,
    selectClientAssetsPicklist,
} from '_features/picklists/pickListsSlice';
import { sortByCaseInsensitiveText } from 'GeminiViewerComponent/_helpers/lodashUtils';

export const AssetSelect = () => {
    const assets = useSelector(selectClientAssetsPicklist);
    const selectedClientId = useSelector(selectConfigValuesClientId);
    const selectedAssetId = useSelector(selectConfigValuesAssetId);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedClientId !== 0) {
            dispatch(getClientAssetsPicklist(selectedClientId));
        }
    }, [selectedClientId]);

    const assetsWithDefault = [
        { asset_id: 0, display_name: '--ASSET OVERRIDE--' },
        ...assets,
    ];

    return (
        <Select
            size="small"
            labelId="asset-select-label"
            id="asset-select"
            style={{ minWidth: '300px' }}
            value={selectedAssetId}
            onChange={async (e) => {
                await dispatch(
                    setConfigValuesAssetId({
                        assetId: e.target.value,
                        clientId: selectedClientId,
                    })
                );
                await dispatch(setIsDirty(false));
            }}
            MenuProps={{
                PaperProps: {
                    style: {
                        maxHeight: 48 * 10 + 8,
                        width: 250,
                    },
                },
            }}
        >
            {assetsWithDefault
                .sort(sortByCaseInsensitiveText('display_name'))
                .map((asset) => (
                    <MenuItem
                        key={asset.asset_id}
                        value={asset.asset_id}
                        style={{ maxWidth: '300px' }}
                        title={asset.display_name}
                    >
                        <Typography
                            style={{
                                width: '100%',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {asset.display_name}
                        </Typography>
                    </MenuItem>
                ))}
        </Select>
    );
};
