/**
 * This slice is to manage config fields
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { getClientService } from 'GeminiViewerComponent/_features/services/client.service';
import { config } from 'gemini-config';

const clientService = getClientService({ config: config });

const initialState = {
    error: null,
    clients: [],
    loadingStatus: LoadingStatus.Idle,
    savingStatus: SavingStatus.Idle,
};

export const getClients = createAsyncThunk(
    'clients/getClients',
    async (params) => {
        return await clientService.getAllClients(params);
    }
);

const clientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {},
    extraReducers: {
        [getClients.pending]: (state) => {
            state.loadingStatus = LoadingStatus.Loading;
            state.error = null;
        },
        [getClients.fulfilled]: (state, action) => {
            state.loadingStatus = LoadingStatus.Loaded;
            state.clients = action.payload;
        },
        [getClients.rejected]: (state, action) => {
            state.loadingStatus = LoadingStatus.Failed;
            state.error = action.error.message;
        },
    },
});

export const selectClientsErrorMessage = (state) => state.clients.error;
export const selectClientsLoadingStatus = (state) =>
    state.clients.loadingStatus;
export const selectClients = (state) => {
    return state.clients.clients;
};
// export const { } = clientsSlice.actions;

export default clientsSlice.reducer;
