import React from 'react';
import { Box, FormControl, TextField } from '@mui/material';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { ImageAttachments } from '../../ImageAttachments';

const DateField = ({
    data,
    fieldValue,
    setFieldValue,
    validationError,
    handleBlur,
    inGroup = false,
    isReadOnly = false,
    isSubmitAllow = true,
    handleClick = () => {},
}) => {
    const format =
        data?.date_time_type === 'date'
            ? 'MM/DD/YYYY'
            : data?.date_time_type === 'time'
            ? 'h:mm A'
            : data?.date_time_type === 'datetime' && 'MM/DD/YYYY h:mm A';

    const value = fieldValue[data.variable]
        ? moment(fieldValue[data.variable], format)
        : null;

    const storeData = (newValue) => {
        if (isSubmitAllow) {
            setFieldValue(
                data.variable,
                !newValue || moment(newValue).format(format) === 'Invalid date'
                    ? ''
                    : moment(newValue).format(format)
            );
            handleBlur({
                ...fieldValue,
                [data.variable]:
                    !newValue ||
                    moment(newValue).format(format) === 'Invalid date'
                        ? ''
                        : moment(newValue).format(format),
            });
        }
    };

    const dateOnlyField = (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                name={data.variable}
                label={data?.hide_label === true ? '' : data?.label}
                inputFormat={format}
                value={value}
                style={isReadOnly && { font: 'black' }}
                disabled={!isSubmitAllow}
                onChange={(newValue) => storeData(newValue)}
                renderInput={(params) => {
                    params = {
                        ...params,
                        inputProps: {
                            ...params.inputProps,
                            readOnly:
                                data?.readonly === true || isReadOnly
                                    ? true
                                    : false,
                        },
                    };
                    return (
                        <TextField
                            variant="outlined"
                            fullWidth
                            {...params}
                            error={!!validationError}
                            helperText={validationError}
                            sx={{
                                '& .Mui-disabled': {
                                    WebkitTextFillColor: '#000000!important',
                                },
                            }}
                        />
                    );
                }}
            />
        </LocalizationProvider>
    );

    const timeOnlyField = (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
                name={data.variable}
                label={data?.hide_label === true ? '' : data?.label}
                inputFormat={format}
                value={value}
                disabled={!isSubmitAllow}
                style={isReadOnly && { font: 'black' }}
                onChange={(newValue) => storeData(newValue)}
                renderInput={(params) => {
                    params = {
                        ...params,
                        inputProps: {
                            ...params.inputProps,
                            readOnly:
                                data?.readonly === true || isReadOnly
                                    ? true
                                    : false,
                        },
                    };
                    return (
                        <TextField
                            variant="outlined"
                            fullWidth
                            {...params}
                            error={!!validationError}
                            helperText={validationError}
                            sx={{
                                '& .Mui-disabled': {
                                    WebkitTextFillColor: '#000000!important',
                                },
                            }}
                        />
                    );
                }}
            />
        </LocalizationProvider>
    );

    const dateTimeField = (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
                name={data.variable}
                label={data?.hide_label === true ? '' : data?.label}
                disabled={!isSubmitAllow}
                value={value}
                inputFormat={format}
                onChange={(newValue) => storeData(newValue)}
                renderInput={(params) => {
                    params = {
                        ...params,
                        inputProps: {
                            ...params.inputProps,
                            readOnly:
                                data?.readonly === true || isReadOnly
                                    ? true
                                    : false,
                        },
                    };
                    return (
                        <TextField
                            variant="outlined"
                            fullWidth
                            {...params}
                            error={!!validationError}
                            helperText={validationError}
                            sx={{
                                '& .Mui-disabled': {
                                    WebkitTextFillColor: '#000000!important',
                                },
                            }}
                        />
                    );
                }}
            />
        </LocalizationProvider>
    );

    const dateTimeData = (
        <div
            style={{
                display: 'inline-flex',
                width: data?.field_width ? data.field_width + '%' : '100%',
                paddingTop: '5px',
            }}
        >
            <FormControl
                style={{
                    padding: inGroup ? '7px 5px 5px' : '5px',
                    width: '100%',
                }}
            >
                <Box
                    display="flex"
                    onClick={(e) => {
                        if (!isSubmitAllow) {
                            e.stopPropagation();
                            handleClick(e, data);
                        }
                    }}
                >
                    {data?.date_time_type === 'date' && dateOnlyField}
                    {data?.date_time_type === 'time' && timeOnlyField}
                    {data?.date_time_type === 'datetime' && dateTimeField}
                    {data?.image_attachments_allow === true && (
                        <ImageAttachments
                            data={data}
                            var_name={data?.variable}
                            level="field"
                            isReadOnly={!isSubmitAllow}
                            handleClick={handleClick}
                        />
                    )}
                </Box>
            </FormControl>
        </div>
    );

    return inGroup ? (
        dateTimeData
    ) : (
        <div style={{ width: '100%' }}>{dateTimeData}</div>
    );
};

export default DateField;
