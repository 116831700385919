import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

export const NodeCategoryConfirmationDialog = ({
    openModal,
    setOpenModal,
    confirmChanges,
}) => {
    return (
        <Dialog open={openModal} onClose={() => setOpenModal(false)}>
            <DialogTitle id="alert-dialog-title">
                Save Category Order
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Nodes assigned to deleted categories will no longer be
                    categorized. Please confirm that you want to save changes.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpenModal(false)}
                >
                    Cancel
                </Button>
                <Button
                    autoFocus
                    variant="contained"
                    color="primary"
                    onClick={confirmChanges}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
