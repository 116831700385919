import { useEffect, useMemo, useState } from 'react';
import { accountsSlice } from 'app/store';
import { useConfigValues } from 'hooks/useConfigValues';
import clsx from 'clsx';
import {
    createNewConfigValue,
    selectConfigValuesClientId,
    selectConfigValuesErrorMessage,
    selectConfigValuesSavingStatus,
    updateConfigValues,
    selectConfigValuesAssetId,
} from '_features/configValues/configValuesSlice';
import { FormTable } from 'components/Forms/FormTable';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { TableLegend } from '../TableLegend';
import { makeClientConfigStyles } from '../styles';
import { getColumnConfig } from './GetColumnConfig';
import { getDirtyFlag, setIsDirty } from '_features/common/formSlice';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { cloneDeep } from 'GeminiViewerComponent/_helpers/lodashUtils';

export const ConfigValuesDisplayMode = {
    Settings: 0,
    Features: 1,
};

const { selectActiveUser } = accountsSlice;

const ConfigValuesManager = ({
    configValuesDisplayMode,
    clientMode = false,
}) => {
    //#region Hooks

    const theme = useSelector(selectActiveTheme);
    const styles = makeClientConfigStyles(theme);

    const dispatch = useDispatch();
    const getDirty = useSelector(getDirtyFlag);
    const activeUser = useSelector(selectActiveUser);
    const selectedClientId = useSelector(selectConfigValuesClientId);
    const selectedAssetId = useSelector(selectConfigValuesAssetId);

    const {
        configValuesLoadingStatus,
        configValues,
        configFields,
        validationTypes,
        validationRawTypes,
    } = useConfigValues({
        clientId: selectedClientId,
        assetId: selectedAssetId,
    });
    let initConfigValues = configValues;
    let hideDefaultValues = configValues;

    useEffect(() => {
        initConfigValues = configValues;
    }, []);
    const [formConfigValues, setFormConfigValues] = useState(hideDefaultValues);
    const [updatedFormConfigValues, setUpdatedFormConfigValues] =
        useState(configValues);
    const [isDirty, setDirty] = useState(false);

    useEffect(() => {
        if (getDirty === true) {
            setDirty(true);
        }
    }, [getDirty]);

    useEffect(() => {
        setFormConfigValues(updatedFormConfigValues);
    }, [updatedFormConfigValues, configFields, validationTypes]);
    //#endregion Hooks

    //#region Selectors
    const configValuesSavingStatus = useSelector(
        selectConfigValuesSavingStatus
    );
    const errorMessage = useSelector(selectConfigValuesErrorMessage);

    useEffect(() => {
        console.log('form config values changed');
        if (clientMode === true) {
            hideDefaultValues = addAstrickForHideDefaults(hideDefaultValues);
            setFormConfigValues(hideDefaultValues);
        } else {
            setFormConfigValues(configValues);
        }
    }, [configValues]);
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Constants

    //#endregion Constants

    //#region Methods

    /**
     * Reset a single form value back to defaults
     * @param {object} data Single form value object
     */
    const addAstrickForHideDefaults = (val) => {
        return val.map((value) => {
            return {
                ...value,
                ...{
                    value:
                        value?.client_obscured === true &&
                        value?.field_source === 'Global'
                            ? '****'
                            : value?.value,
                },
            };
        });
    };
    const resetData = async (data) => {
        if (selectedClientId === 0) {
            // Updating global values

            let updatedFormValues = cloneDeep(filteredValues);

            // Find the value to update in the cloned values
            const index = updatedFormValues.findIndex(
                (value) => value.config_value_id === data.config_value_id
            );
            if (index >= 0) {
                const configField = configFields.find(
                    (configField) =>
                        configField.config_field_id === data.config_field_id
                );
                if (configField) {
                    updatedFormValues[index] = createNewConfigValue();
                    updatedFormValues[index].config_field_id =
                        configField.config_field_id;

                    updatedFormValues[index].display_name =
                        configField?.display_name ?? '';
                    updatedFormValues[index].category =
                        configField?.category ?? '';

                    await dispatch(setIsDirty(true));
                    setFormConfigValues(updatedFormValues);
                }
            }
        } else {
            let updatedFormValues = cloneDeep(filteredValues);

            // Find the value to update in the cloned values
            const index = updatedFormValues.findIndex(
                (value) => value.config_value_id === data.config_value_id
            );
            if (index >= 0) {
                // Set value back to Global if there is one
                if (updatedFormValues[index].global_values) {
                    updatedFormValues[index] = {
                        ...updatedFormValues[index],
                        ...updatedFormValues[index].global_values,
                    };
                    // updatedFormValues[index].config_valid_id = uuidv4();
                    // Set flag to indicate that it is global source
                    if (selectedAssetId == 0) {
                        updatedFormValues[index].field_source = 'Global';
                    } else {
                        updatedFormValues[index].field_source =
                            data.global_values.field_source;
                    }
                    updatedFormValues[index].dirty = false;
                    // if (data.field_source === 'Client') {
                    //     updatedFormValues[index].dirty = true;
                    //     updatedFormValues[index].field_source = 'Global';
                    //     delete updatedFormValues[index].global_values;
                    // }
                    await dispatch(setIsDirty(true));
                    if (clientMode === true) {
                        setFormConfigValues(
                            addAstrickForHideDefaults(updatedFormValues)
                        );
                    } else {
                        setFormConfigValues(updatedFormValues);
                    }
                } else {
                    // If no Global value then reset to defaults from ConfigField
                    const configField = configFields.find(
                        (configField) =>
                            configField.config_field_id === data.config_field_id
                    );
                    if (configField) {
                        updatedFormValues[index] = createNewConfigValue();
                        updatedFormValues[index].config_field_id =
                            configField.config_field_id;
                        updatedFormValues[index].display_name =
                            configField?.display_name ?? '';
                        updatedFormValues[index].category =
                            configField?.category ?? '';
                        if (selectedAssetId == 0) {
                            updatedFormValues[index].field_source = 'Global';
                        } else {
                            updatedFormValues[index].field_source = 'Client';
                        }
                        updatedFormValues[index].dirty = false;
                        await dispatch(setIsDirty(true));
                        if (clientMode === true) {
                            setFormConfigValues(
                                addAstrickForHideDefaults(updatedFormValues)
                            );
                        } else {
                            setFormConfigValues(updatedFormValues);
                        }
                    }
                }
            }
        }
    };

    const validateField = async (value, validationType) => {
        try {
            let schemaValidatorObject = Yup.object();
            if (validationType.type_id === 0) {
                schemaValidatorObject = Yup.object().shape({
                    Value: Yup.boolean(),
                });
            } else if (validationType.type_id === 1) {
                schemaValidatorObject = Yup.object().shape({
                    Value: Yup.number()
                        .min(validationType.min_value)
                        .max(validationType.max_value),
                });
            } else if (
                validationType.type_id === 2 &&
                validationType?.max_length &&
                validationType.max_length !== 0
            ) {
                schemaValidatorObject = Yup.object().shape({
                    Value: Yup.string().max(validationType.max_length),
                });
            } else if (validationType.type_id === 3) {
                schemaValidatorObject = Yup.object();
            } else if (validationType.type_id === 4) {
                schemaValidatorObject = Yup.object().shape({
                    Value: Yup.string(),
                });
            }
            await schemaValidatorObject.validate({ Value: value });
            return false;
        } catch (err) {
            return { error: err.message };
        }
    };

    const handleSubmit = async (values) => {
        let isError = false;
        let validate;
        let modifiedValues = values;
        if (Array.isArray(values) && values.length > 0) {
            modifiedValues = [];
            for (let val of values) {
                if (
                    configValuesDisplayMode === ConfigValuesDisplayMode.Features
                ) {
                    val = { ...val, client_visible: false };
                }
                if (val.validation_type_id && val?.field_source !== 'Global') {
                    const validationType = validationTypes.find(
                        (value) =>
                            value.validation_type_id === val.validation_type_id
                    );
                    if (validationType) {
                        validate = await validateField(
                            val.value,
                            validationType
                        );
                        if (validate && validate?.error) {
                            val = {
                                ...val,
                                fieldError: { value: validate.error },
                            };
                            isError = true;
                        } else {
                            delete val?.fieldError;
                        }
                    }
                }
                modifiedValues.push(val);
            }
        }
        if (isError) {
            setUpdatedFormConfigValues(modifiedValues);
        } else {
            const resultValues = await modifiedValues.map((value) => {
                if (value.value === '****') {
                    const field = initConfigValues.find(
                        (field) =>
                            field.config_field_id === value.config_field_id
                    );
                    return {
                        ...value,
                        ...{
                            value: field?.value,
                        },
                    };
                } else {
                    return value;
                }
            });
            setUpdatedFormConfigValues(resultValues);
            dispatch(
                updateConfigValues({
                    config_values: modifiedValues,
                    editing_features:
                        configValuesDisplayMode ===
                        ConfigValuesDisplayMode.Features,
                    client_id: selectedClientId,
                    asset_id: selectedAssetId,
                })
            );
            dispatch(setIsDirty(false));
            setDirty(false);
        }
    };

    const handleCancel = () => {
        setDirty(false);
        dispatch(setIsDirty(false));
    };
    //#endregion Methods

    //#region Render time calcs

    let filteredValues = formConfigValues.filter((value) => {
        const field = configFields.find(
            (field) => field.config_field_id === value.config_field_id
        );
        return configValuesDisplayMode === ConfigValuesDisplayMode.Settings
            ? !field?.is_feature
            : field?.is_feature;
    });

    if (clientMode === true) {
        filteredValues = formConfigValues.filter((value) => {
            return value?.client_visible === true;
        });
    }
    // Add config field name, validation type id, and category to make it easier to display

    filteredValues = filteredValues.map((value) => {
        const field = configFields.find(
            (field) => field.config_field_id === value.config_field_id
        );
        return {
            ...value,
            ...{
                config_field_name: field?.display_name ?? '',
                category: field?.category ?? '',
                validation_type_id: field?.validation_type_id,
            },
        };
    });

    const formValues = useMemo(() => {
        const values = {};
        filteredValues.forEach((value) => {
            values[value?.config_field_id] = value;
        });
        return values;
    }, [filteredValues]);

    let formTableDefinition = {
        createMethod: null,
        submitMethod: handleSubmit,
        formValidations: false,
        handleCancel: handleCancel,
        resetMethod: resetData,
        allowDelete: selectedClientId === 0,
        allowGlobalChange:
            selectedClientId === 0 && activeUser?.role === 'IPS Administrator',
        allowReset: (data) => {
            // If showing Global then allow clear if value has been set
            if (selectedClientId === 0) {
                return typeof data.config_value_id !== 'string';
            } else {
                // Else allow clear if data source is not global
                return data.field_source !== 'Global';
            }
        },
        setStateMethod: setUpdatedFormConfigValues,
        selectFormState: filteredValues,
        validationTypes: validationTypes,
        validationRawTypes: validationRawTypes,
        selectedClientId: selectedClientId,
        seletectedAssetId: selectedAssetId,
        categoryKey: 'category',
        key: 'config_value_id',
        class: styles.formTable,
        formValues: formValues,
        rowClass: (data) =>
            clsx({
                [styles.unsetRow]: typeof data.config_value_id === 'string',
            }),
        columns: [
            {
                config: { type: 'label' },
                title: 'Name',
                class: styles.nameColumn,
                width: '30%',
                key: 'display_name',
                readOnly: true,
                validation: {},
            },
            {
                config: (data) => {
                    return getColumnConfig(
                        data,
                        configFields,
                        validationTypes,
                        validationRawTypes
                    );
                },
                title: 'Value',
                key: 'value',
                validation: {},
            },
        ],
    };
    if (activeUser?.role === 'IPS Administrator') {
        formTableDefinition.columns = [
            ...formTableDefinition.columns,
            {
                config: { type: 'checkbox' },
                title: 'Client Visible',
                class: styles.clientVisibleColumn,
                key: 'client_visible',
                validation: {},
            },
            {
                config: { type: 'checkbox' },
                title: 'Client Obscured',
                class: styles.clientObscuredColumn,
                key: 'client_obscured',
                validation: {},
            },
            {
                config: { type: 'checkbox' },
                title: 'Required',
                class: styles.requiredColumn,
                key: 'required',
                validation: {},
            },
            {
                config: { type: 'date' },
                title: 'Valid Until',
                class: styles.dateColumn,
                key: 'valid_until_date',
                validation: {},
            },
        ];
    }
    formTableDefinition =
        configValuesDisplayMode === ConfigValuesDisplayMode.Features
            ? {
                  ...formTableDefinition,
                  columns: formTableDefinition.columns.filter(
                      (clmns) =>
                          clmns.key !== 'client_visible' &&
                          clmns.key !== 'client_obscured'
                  ),
              }
            : formTableDefinition;

    const isBusy =
        configValuesLoadingStatus === LoadingStatus.Loading ||
        configValuesLoadingStatus === LoadingStatus.Idle ||
        configValuesSavingStatus === SavingStatus.Saving;

    //#endregion Render time calcs

    //#region Effects
    //#endregion Effects

    //#region Render

    // console.log(`Rendering settings manager`);

    return isBusy ? (
        <LoadingLogo />
    ) : errorMessage ? (
        <div>{errorMessage}</div>
    ) : (
        <>
            <TableLegend />
            <div
                className={`${styles.configContainer} ${
                    isDirty ? styles.unsaveData : ''
                }`}
            >
                <FormTable tableDefinition={formTableDefinition} />
            </div>
        </>
    );
    //#endregion Render
};

export { ConfigValuesManager };
