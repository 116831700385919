import React from 'react';

import { FormTextField } from 'components/TextField';

const WidthField = ({ validationError }) => {
    return (
        <FormTextField
            label="Width"
            name="field_width"
            placeholder="Width(%)"
            error={!!validationError}
            helperText={validationError}
        />
    );
};

export default WidthField;
