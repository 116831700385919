import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { chapterService } from '../services/chapter.service';

const initialState = {
    chapterStatus: LoadingStatus.Idle,
    chapter: {},
};

export const fetchChapterById = createAsyncThunk(
    'chapter/fetchChapterById',
    async (chapterId) => await chapterService.get(chapterId)
);

export const createChapter = createAsyncThunk(
    'chapter/createChapter',
    async (params) => await chapterService.create(params)
);

export const updateChapter = createAsyncThunk(
    'chapter/updateChapter',
    async (params) => await chapterService.update(params.chapter_id, params)
);

export const deleteChapter = createAsyncThunk(
    'chapter/deleteChapter',
    async (chapterId) => await chapterService.delete(chapterId)
);

const chapterSlice = createSlice({
    name: 'chapter',
    initialState,
    reducers: {
        resetChapterStatus: (state) => {
            state.chapterStatus = LoadingStatus.Idle;
            state.chapter = {};
        },
        setChapter: (state, action) => {
            state.chapter = action.payload;
        },
    },
    extraReducers: {
        [fetchChapterById.fulfilled]: (state, action) => {
            state.chapterStatus = LoadingStatus.Loaded;
            state.chapter = action.payload;
        },
        [createChapter.fulfilled]: (state, action) => {
            state.chapterStatus = LoadingStatus.Loaded;
            state.chapter = action.payload;
        },
        [updateChapter.fulfilled]: (state, action) => {
            state.chapterStatus = LoadingStatus.Loaded;
            state.chapter = action.payload;
        },
        [deleteChapter.pending]: (state, action) => {
            state.chapterStatus = LoadingStatus.Loading;
        },
        [deleteChapter.fulfilled]: (state, action) => {
            state.chapterStatus = LoadingStatus.Loaded;
            state.chapter = action.payload;
        },
    },
});

export const getChapterStatus = (state) => state.chapter.chapterStatus;
export const selectChapter = (state) => state.chapter.chapter;

export const { resetChapterStatus, setChapter } = chapterSlice.actions;

export default chapterSlice.reducer;
