import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    flyerOpen: false,
    flyerObj: null,

    showFlyer: false,
    flyerImg: '',
};

const flyerSlice = createSlice({
    name: 'flyer',
    initialState,
    reducers: {
        setFlyerVis: (state, action) => {
            return { ...state, flyerOpen: action.payload.open };
        },
        setFlyerObj: (state, action) => {
            return { ...state, flyerObj: action.payload.flyer };
        },
        setShowFlyer: (state, action) => {
            return {
                ...state,
                showFlyer: action.payload.showFlyer,
                flyerImg: action.payload.flyerImg,
            };
        },
        resetFlyerState: () => initialState,
    },
    extraReducers: {},
});

export const getFlyerVis = (state) => state.flyer.flyerOpen;
export const getFlyerObj = (state) => state.flyer.flyerObj;
export const getShowFlyer = (state) => state.flyer.showFlyer;
export const getShowFlyerImg = (state) => state.flyer.flyerImg;

export const { resetFlyerState, setFlyerVis, setFlyerObj, setShowFlyer } =
    flyerSlice.actions;

export default flyerSlice.reducer;
