import moment from 'moment';
import { config } from 'gemini-config';

import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import { getNetworkService } from 'GeminiViewerComponent/_helpers';

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});

const baseUrl = `${config.apiUrl}/configvalue`;

// debug temp
// eslint-disable-next-line no-unused-vars
let fakeData = [
    {
        config_value_id: 1,
        config_field_id: 1,
        client_visible: true,
        client_obscured: true,
        required: true,
        value: '123kjflwkh34h3o4io3i',
        modified_by_id: 1,
        modified_date: Date.now(),
    },
    {
        config_value_id: 2,
        config_field_id: 2,
        client_visible: true,
        client_obscured: true,
        required: true,
        value: 'hhyyeer3434435',
        modified_by_id: 1,
        modified_date: Date.now(),
    },
    {
        config_value_id: 3,
        config_field_id: 3,
        client_visible: true,
        client_obscured: false,
        required: true,
        value: 'A Bucket Name',
        modified_by_id: 1,
        modified_date: Date.now(),
    },
    {
        config_value_id: 4,
        config_field_id: 4,
        client_visible: true,
        client_obscured: false,
        required: true,
        modified_by_id: 1,
        modified_date: Date.now(),
    },
    {
        config_value_id: 6,
        config_field_id: 6,
        client_visible: true,
        client_obscured: false,
        required: true,
        value: true,
        modified_by_id: 1,
        modified_date: Date.now(),
    },
];

const convertConfigValuesToServer = (editingGlobal, configValues) => {
    // Only keep values that are not Global, or that have changed
    if (editingGlobal) {
        configValues = configValues.filter(
            (configValue) =>
                (configValue.field_source === 'Global' &&
                    typeof configValue.config_value_id !== 'string') ||
                configValue.dirty === true
        );
    } else {
        configValues = configValues.filter(
            (configValue) =>
                configValue.field_source !== 'Global' ||
                configValue.dirty === true
        );
    }

    return configValues.map((configValue) => {
        // Set config_fields_id = 0 on new values
        if (typeof configValue.config_value_id === 'string') {
            configValue.config_value_id = 0;
        }
        if (configValue.valid_until_date !== null) {
            configValue.valid_until_date = configValue.valid_until_date
                .utc()
                .format('YYYY-MM-DD');
        }
        if (typeof configValue.value === 'boolean') {
            configValue.value = configValue.value ? 'true' : 'false';
        }
        if (
            configValue.field_source === 'Client' &&
            configValue.asset_id !== 0 &&
            configValue.asset_id !== undefined
        ) {
            delete configValue.asset_id;
        }
        return configValue;
    });
};

const convertConfigValueFromServer = (configValue) => {
    if (configValue.valid_until_date) {
        configValue.valid_until_date = moment.utc(configValue.valid_until_date);
    } else {
        configValue.valid_until_date = null;
    }
    if (configValue.global_values) {
        convertConfigValueFromServer(configValue.global_values);
    }
    configValue.dirty = false;
};

const convertConfigValuesFromServer = (configValues) => {
    configValues.map((configValue) => {
        convertConfigValueFromServer(configValue);
    });
};

const getAll = async (clientId, assetId) => {
    // debug temp
    // return fakeData;
    // eslint-disable-next-line no-unreachable
    try {
        let response;
        if (clientId !== 0) {
            if (assetId !== 0 && assetId) {
                response = await networkService.get(
                    `${baseUrl}/byassetid/${assetId}`
                );
            } else {
                response = await networkService.get(
                    `${baseUrl}/byclientid/${clientId}`
                );
            }
        } else {
            response = await networkService.get(`${baseUrl}`);
        }
        if (response) {
            convertConfigValuesFromServer(response.data);
            return response.data;
        }
        return null;
    } catch (e) {
        return null;
    }
};

const updateAll = async (params) => {
    params.config_values = convertConfigValuesToServer(
        params.editing_global,
        params.config_values
    );

    // debug temp
    // convertConfigValuesFromServer(params.config_values);
    // params.config_values.map((param) => {
    //     if (param.config_value_id === 0) {
    //         param.config_value_id = uuidv4();
    //     }
    // });
    // fakeData = params.config_values;
    // return fakeData;

    // eslint-disable-next-line no-unreachable
    try {
        const response = await networkService.put(
            `${baseUrl}/multiple`,
            params
        );
        if (response) {
            convertConfigValuesFromServer(response.data.config_values);
            return response.data.config_values;
        } else {
            return null;
        }
    } catch {
        return null;
    }
};

export const configValueService = {
    getAll,
    updateAll,
};
