import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Autocomplete, Button, TextField } from '@mui/material';
import { FormControl, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';

import { modalStyles } from 'GeminiViewerComponent/components/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    addDeployementPackageJob,
    fetchDeployementPackagePage,
    getDeploymentPackageStatus,
    resetDeploymentPackageState,
    selectAllDeploymentPackage,
} from '_features/jobs/jobsSlice';
import { selectConfigFields } from '_features/configFields/configFieldsSlice';
import {
    selectConfigValue,
    selectConfigValuesByFields,
} from '_features/configValues/configValuesSlice';
import { SelectionAutocomplete } from 'components/_Autocomplete/SelectionAutocomplete';
import { FormActions, snackbarHandler } from '_helpers';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { makeFormStyles } from 'forms/styles';
import { REGEX_FOR_VALIDATE_MULTIPLE_EMAIL } from 'components/ProcedureDesigner/components/FormNodeEditor/_helpers/constant';
import { closePanel } from '_features/common/formSlice';
import { sortByCaseInsensitiveText } from 'GeminiViewerComponent/_helpers/lodashUtils';

const JobDeploymentPackageForm = ({
    formAction,
    deploymentId,
    deploymentData,
}) => {
    //#region Constants
    const theme = useSelector(selectActiveTheme);
    const classes = modalStyles(theme);
    const formClasses = makeFormStyles(theme);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [domains, setDomains] = useState([]);
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    //#endregion State

    //#region Selectors
    const configFields = useSelector(selectConfigFields);
    const configValues = useSelector(selectConfigValue);
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    useEffect(() => {
        return () => {
            dispatch(resetDeploymentPackageState);
        };
    }, []);

    useEffect(() => {
        const updatedResponse = selectConfigValuesByFields(
            configValues,
            configFields,
            ['DeploymentTestingDomains']
        );
        if (updatedResponse?.['DeploymentTestingDomains']) {
            setDomains(
                updatedResponse?.['DeploymentTestingDomains']
                    ?.split(',')
                    ?.map((x) => x.trim())
                    ?.sort(sortByCaseInsensitiveText()) ?? []
            );
        }
    }, [configValues, configFields]);
    //#endregion Effects

    //#region Methods
    const handleSubmit = async (values, setSubmitting) => {
        dispatch(activateLoading());
        switch (formAction.id) {
            case FormActions.Edit.id:
                try {
                    const resultAction = await dispatch(
                        addDeployementPackageJob({
                            deployment_package_id: deploymentId ?? null,
                            additional_notification_emails:
                                values?.additional_notification_emails,
                            testing_domain: values?.testing_domain.toString(),
                        })
                    );
                    const { message, variant } = snackbarHandler(
                        resultAction.meta.requestStatus,
                        'Deployment Job Package'
                    );
                    enqueueSnackbar(message, {
                        action: (key) => <SnackbarDismiss key={key} />,
                        variant: variant,
                    });
                    dispatch(deactivateLoading());
                } catch (error) {
                    dispatch(deactivateLoading());
                    enqueueSnackbar(
                        error.message !== 'undefined'
                            ? error.message
                            : 'Deployment Job Package Create Failed',
                        {
                            action: (key) => <SnackbarDismiss key={key} />,
                            variant: 'error',
                        }
                    );
                }
                break;
            case FormActions.Create.id:
                try {
                    const resultAction = await dispatch(
                        addDeployementPackageJob({
                            deployment_package_id:
                                values?.deploymentPackageId ?? null,
                            additional_notification_emails:
                                values?.additional_notification_emails,
                            testing_domain: values?.testing_domain.toString(),
                        })
                    );
                    const { message, variant } = snackbarHandler(
                        resultAction.meta.requestStatus,
                        'Deployment Job Package Create'
                    );
                    enqueueSnackbar(message, {
                        action: (key) => <SnackbarDismiss key={key} />,
                        variant: variant,
                    });
                } catch (error) {
                    dispatch(deactivateLoading());
                    enqueueSnackbar(
                        error.message !== 'undefined'
                            ? error.message
                            : 'Deployment Job Package Create Failed',
                        {
                            action: (key) => <SnackbarDismiss key={key} />,
                            variant: 'error',
                        }
                    );
                }
                break;
        }
        dispatch(
            closePanel({
                formKey: 'jobDeployForm',
            })
        );
        dispatch(deactivateLoading());
        setSubmitting(false);
    };
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: '9991',
                background: 'rgba(0,0,0,0.5)',
            }}
        >
            <Formik
                initialValues={{
                    deploymentPackageId: undefined,
                    additional_notification_emails: '',
                    testing_domain: [],
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    handleSubmit(values, setSubmitting, resetForm);
                }}
                validationSchema={Yup.object({
                    deploymentPackageId:
                        formAction.id === FormActions.Edit.id
                            ? Yup.string()
                            : Yup.number().required('Required'),
                    additional_notification_emails: Yup.string()?.matches(
                        REGEX_FOR_VALIDATE_MULTIPLE_EMAIL,
                        'Please enter a valid email address. Separate multiple email addresses with commas.'
                    ),
                })}
            >
                {({ isSubmitting, errors, values, setFieldValue }) => (
                    <Form>
                        <Box
                            sx={{
                                maxWidth: '540px',
                                margin: '0 auto',
                                width: '100%',
                            }}
                            className={classes.modalContainer}
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap="1rem"
                                sx={{ width: '100%', margin: '0 0 1.5rem' }}
                            >
                                {formAction.id === FormActions.Edit.id ? (
                                    <Typography variant="h5">
                                        {`Create new job for ${deploymentData?.name}?`}
                                    </Typography>
                                ) : (
                                    <>
                                        <div className={classes.actionIcon}>
                                            <Typography
                                                variant="body1"
                                                component="h2"
                                            >
                                                For which deployment package
                                                would you like to create a new
                                                job?
                                            </Typography>
                                        </div>
                                        <FormControl>
                                            <Field name={'deploymentPackageId'}>
                                                {({
                                                    field: { value },
                                                    form: { setFieldValue },
                                                }) => (
                                                    <SelectionAutocomplete
                                                        title="Deployment Package"
                                                        placeholder="Deployment Package"
                                                        keyProperty="deployment_package_id"
                                                        nameProperty="name"
                                                        entityIds={value}
                                                        setEntityIds={
                                                            setFieldValue
                                                        }
                                                        entitySelector={
                                                            selectAllDeploymentPackage
                                                        }
                                                        entityStatusSelector={
                                                            getDeploymentPackageStatus
                                                        }
                                                        fetchEntityPage={
                                                            fetchDeployementPackagePage
                                                        }
                                                        disablePortal={true}
                                                        formField={
                                                            'deploymentPackageId'
                                                        }
                                                        multiSelection={false}
                                                        fieldValidation={
                                                            !!errors?.deploymentPackageId
                                                        }
                                                        freeSolo={false}
                                                    />
                                                )}
                                            </Field>
                                            {errors?.deploymentPackageId && (
                                                <Box
                                                    sx={{
                                                        color: '#E84C3C',
                                                        margin: '4px 14px',
                                                        fontSize: '0.75rem',
                                                        textAlign: 'left',
                                                        fontFamily: 'Open Sans',
                                                        fontWeight: 400,
                                                        lineHeight: 1.66,
                                                    }}
                                                >
                                                    {errors.deploymentPackageId}
                                                </Box>
                                            )}
                                        </FormControl>
                                    </>
                                )}
                                <div className={classes.actionIcon}>
                                    <Typography variant="body1" component="h2">
                                        You will receive an email upon
                                        completion. Do you want additional
                                        emails to receive notifications?
                                    </Typography>
                                </div>
                                <FormControl>
                                    <Field
                                        name="additional_notification_emails"
                                        type="text"
                                        placeholder="Comma-separated Emails"
                                        rows={2}
                                        className={formClasses.input}
                                        style={{
                                            fontSize: '1rem',
                                            padding: '19px 10px',
                                            marginTop: '0',
                                            color: '#000000',
                                        }}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Tab') {
                                                event.preventDefault();
                                                const value =
                                                    event.currentTarget.value;
                                                if (value.trim() !== '') {
                                                    const newEmail = value
                                                        .trim()
                                                        .split(',')
                                                        .pop();
                                                    if (
                                                        REGEX_FOR_VALIDATE_MULTIPLE_EMAIL.test(
                                                            newEmail
                                                        )
                                                    ) {
                                                        if (
                                                            !value.endsWith(
                                                                ','
                                                            ) ||
                                                            value
                                                                .slice(0, -1)
                                                                .endsWith(',')
                                                        ) {
                                                            event.currentTarget.value +=
                                                                ',';
                                                        }
                                                    } else {
                                                        console.error(
                                                            'Invalid email format:',
                                                            newEmail
                                                        );
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                    {errors?.additional_notification_emails && (
                                        <Box
                                            sx={{
                                                color: '#E84C3C',
                                                margin: '4px 14px',
                                                fontSize: '0.75rem',
                                                textAlign: 'left',
                                                fontFamily: 'Open Sans',
                                                fontWeight: 400,
                                                lineHeight: 1.66,
                                            }}
                                        >
                                            {
                                                errors.additional_notification_emails
                                            }
                                        </Box>
                                    )}
                                </FormControl>
                                <div className={classes.actionIcon}>
                                    <Typography variant="body1" component="h2">
                                        Would you like this package to be
                                        deployed to a testing domain?
                                    </Typography>
                                </div>
                                <FormControl>
                                    <Autocomplete
                                        multiple={false}
                                        multiSelection={false}
                                        freeSolo={false}
                                        variant="outline"
                                        id="checkboxes-domain-types"
                                        options={domains}
                                        onChange={(event, newInputValue) => {
                                            setFieldValue(
                                                'testing_domain',
                                                newInputValue
                                            );
                                        }}
                                        value={values?.testing_domain}
                                        disablePortal={true}
                                        renderOption={(
                                            props,
                                            option,
                                            { selected }
                                        ) => <li {...props}>{option}</li>}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Testing Domain"
                                                placeholder="Testing Domain"
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Box>
                            <div>
                                <Button
                                    onClick={() =>
                                        dispatch(
                                            closePanel({
                                                formKey: 'jobDeployForm',
                                            })
                                        )
                                    }
                                    className={classes.buttonSpacing}
                                    variant="outlined"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={clsx(
                                        classes.buttonSpacing,
                                        classes.actionButton
                                    )}
                                    type="submit"
                                    variant="contained"
                                    disabled={isSubmitting}
                                >
                                    Confirm
                                </Button>
                            </div>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
    //#endregion Render
};
export { JobDeploymentPackageForm };
