import { useEffect } from 'react';
import { FormControl } from '@mui/material';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { useStyles } from 'GeminiViewerComponent/components/Procedure/components/ProcedureQuestions/styles';
import { customTinyMce } from 'GeminiViewerComponent/components/Procedure/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import useContentLinkInterceptor from 'GeminiViewerComponent/hooks/useContentLinkInterceptor';

const LabelField = ({
    data,
    inGroup = false,
    isSubmitAllow = true,
    handleClick = () => {},
    contentService,
}) => {
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);
    const tinyMceCustomClass = customTinyMce();
    const labelContent = data?.content ?? data?.labelcontent;

    useEffect(() => {
        const containers = document.querySelectorAll(
            '.gemini-form-field-content-'
        );
        if (containers && !isSubmitAllow) {
            containers.forEach((container) => {
                const buttons = container.querySelectorAll('button');
                buttons.forEach((button) => {
                    button.style.pointerEvents = 'none';
                });

                const inputs = container.querySelectorAll('input');
                inputs.forEach((input) => {
                    input.style.pointerEvents = 'none';
                });

                const links = container.querySelectorAll('a');
                links.forEach((input) => {
                    input.style.pointerEvents = 'none';
                });
            });
        }
    }, []);

    useContentLinkInterceptor(
        `.gemini-form-field-content-${data?.id}`,
        contentService
    );

    const labelFieldData = (
        <FormControl
            style={{
                padding: '5px',
                width: data?.field_width ? data.field_width + '%' : '100%',
            }}
            onClick={(e) => {
                if (!isSubmitAllow) {
                    e.stopPropagation();
                    handleClick(e, data);
                }
            }}
        >
            <div
                className={clsx(
                    classes.nodeContent,
                    `gemini-form-field-content-${
                        isSubmitAllow ? data?.id : ''
                    } ${tinyMceCustomClass.tinymceCustom}`
                )}
                dangerouslySetInnerHTML={{ __html: labelContent }}
            />
        </FormControl>
    );

    return inGroup ? (
        labelFieldData
    ) : (
        <div style={{ width: '100%' }}>{labelFieldData}</div>
    );
};

export default LabelField;
