import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsDirty } from '_features/common/formSlice';

const SetDirtyForm = () => {
    const { dirty } = useFormikContext();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setIsDirty(dirty));
    }, [dirty]);
};

export default SetDirtyForm;
