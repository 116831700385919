import React from 'react';
import { useDispatch } from 'react-redux';
import { AppBar, Box, Button, Tab, Tabs, Typography } from '@mui/material';

import { setIsDirty } from '_features/common/formSlice';
import { ConfigFieldsManager } from './ConfigFieldsManager';
import { ValidationTypesManager } from './ValidationTypesManager';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const FieldsManagementTab = () => {
    const dispatch = useDispatch();
    const [displayTabIndex, setDisplayTabIndex] = React.useState(0);

    const handleChange = (event, newValue) => {
        setDisplayTabIndex(newValue);
    };

    return (
        <>
            <AppBar position="static" color="default">
                <Tabs
                    value={displayTabIndex}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    // className={classes.tabs}
                >
                    <Tab label="Fields" {...a11yProps(0)} />
                    <Tab label="Validation Types" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <Button
                variant="contained"
                style={{ marginTop: '10px' }}
                onClick={() => {
                    dispatch(setIsDirty(true));
                }}
            >
                Edit
            </Button>
            <TabPanel value={displayTabIndex} index={0}>
                <ConfigFieldsManager />
            </TabPanel>
            <TabPanel value={displayTabIndex} index={1}>
                <ValidationTypesManager />
            </TabPanel>
        </>
    );
};

export { FieldsManagementTab };
