import { makeStyles } from '@mui/styles';

const useCategoryAsseyStyles = makeStyles(() => ({
    categoryListWrapper: {
        maxWidth: '230px',
        minWidth: '230px',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            opacity: 0,
            width: '8px',
        },
        '&:hover::-webkit-scrollbar': {
            opacity: 1,
            backgroundColor: '#F5F5F5',
        },
        '&:hover::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            backgroundColor: '#F5F5F5',
        },
        '&:hover::-webkit-scrollbar-thumb': {
            backgroundColor: '#a4a4a4',
        },
    },
    categoryAssetWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        overflow: 'auto',
        width: '100%',
        '&::-webkit-scrollbar': {
            opacity: 0,
            width: '8px',
        },
        '&:hover::-webkit-scrollbar': {
            opacity: 1,
            backgroundColor: '#F5F5F5',
        },
        '&:hover::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            backgroundColor: '#F5F5F5',
        },
        '&:hover::-webkit-scrollbar-thumb': {
            backgroundColor: '#a4a4a4',
        },
    },
}));

export default useCategoryAsseyStyles;
