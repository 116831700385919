import { makeStyles } from '@mui/styles';

const contentCardStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '15px',
    },
    cardContainer: {
        width: '60%',
        overflow: 'hidden',
    },
    card: {
        height: '250px',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid grey',
        boxShadow: ' 0 0 17px 0 rgba(45,61,80,0.11)',
        overflow: 'auto',
    },
    cardDescription: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#34485E',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    cardImage: {
        minWidth: '40%',
        height: '100%',
        backgroundPosition: 'right',
        cursor: 'pointer',
        minHeight: '75px',
    },
    contentType: {
        color: '#34485E',
        fontSize: '12px',
        marginBottom: '5px',
    },
    heading: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightRegular,
        color: 'grey',
    },
    accordion: {
        border: '1px solid #e6e6e6 !important',
        margin: 'auto 10px !important',
        backgroundColor: '#e6e6e6',
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid #e6e6e6 !important',
            backgroundColor: '#e6e6e6',
            margin: 'auto 10px !important',
        },
    },
    accordionSummary: {
        minHeight: '40px',
        height: '40px',

        '&.Mui-expanded': {
            minHeight: '40px',
            height: '40px',
        },
    },
    accordionHeading: {
        fontSize: '16px',
        fontWeight: theme.typography.fontWeightRegular,
        color: '#000000',
    },
    customAccordionSummary: {
        height: '40px',
        minHeight: '0px',
        '& div': {
            margin: '0 -12px 0 0',
        },
    },
    contentUsageDiv: {
        backgroundColor: '#ffffff',
        borderRadius: '5px',
        padding: 'inherit',
    },
}));

export { contentCardStyles };
