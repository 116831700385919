import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import moment from 'moment';
import { assetExportService } from '_features/_services';

const initialState = {
    assetExports: [],
    assetExportPageInfo: {},
    status: LoadingStatus.Idle,
    loadedRowsMap: {},
    error: null,
};

export const fetchAssetExportsPage = createAsyncThunk(
    'assetExport/fetchAssetExportsPage',
    async (params, { getState }) => {
        const response = await assetExportService.getAll(
            params.searchString,
            params.sort,
            getState().accounts.activeUser.s3_prefix
        );
        return response;
    }
);

export const addNewAssetExport = createAsyncThunk(
    'assetExport/addNewAssetExport',
    async (newAssetExport) => {
        const response = await assetExportService.create(newAssetExport);
        return response;
    }
);

export const deleteAssetExport = createAsyncThunk(
    'assetExport/deleteAssetExport',
    async (assetExportId) => {
        const response = await assetExportService.delete(assetExportId);
        return response;
    }
);

const assetExportSlice = createSlice({
    name: 'assetExport',
    initialState,
    reducers: {
        setAssetExportStatus: (state, action) => {
            state.status = action.payload;
        },
    },
    extraReducers: {
        [fetchAssetExportsPage.pending]: (state) => {
            state.status = LoadingStatus.Loading;
        },
        [fetchAssetExportsPage.fulfilled]: (state, action) => {
            state.status = LoadingStatus.Loaded;
            state.assetExportPageInfo = action.payload.pageInfo;
            state.assetExports = action.payload.assets;
            if (action.payload.assets?.length > 0) {
                state.assetExports = action.payload.assets.map((exp) => {
                    if (exp?.created_date) {
                        exp = {
                            ...exp,
                            created_date: moment(exp.created_date).format(
                                'LT, MMM. D, YYYY'
                            ),
                        };
                    }
                    return {
                        ...exp,
                        is_scorm: exp?.is_scorm === true ? 'LMS' : 'Web',
                    };
                });
            }
        },
        [fetchAssetExportsPage.rejected]: (state, action) => {
            state.status = LoadingStatus.Failed;
            state.error = action.error.message;
        },
        [addNewAssetExport.rejected]: (state, action) => {},
        [addNewAssetExport.fulfilled]: (state, action) => {
            state.status = LoadingStatus.Idle;
        },
        [deleteAssetExport.fulfilled]: (state, action) => {
            state.status = LoadingStatus.Idle;
        },
    },
});

export const selectAssetExportPageInfo = (state) =>
    state.assetExport.assetExportPageInfo;

export const selectAssetExportLoadingStatus = (state) =>
    state.assetExport.status;

export const selectAssetExports = (state) => state.assetExport.assetExports;

export const { setAssetExportStatus } = assetExportSlice.actions;

export default assetExportSlice.reducer;
