import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { MdCancel, MdPhotoLibrary } from 'react-icons/md';

import { getChecklistData } from 'GeminiViewerComponent/components/Procedure/_features/checklistDataSlice';
import { ZoomPinchComponent } from '../../ZoomPinchComponent';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    makeCommonFormPreviewStyles,
    makeCommonPreviewImageStyles,
} from '../styles';

const ImagePreview = ({
    openPreviewDialog,
    setOpenPreviewDialog,
    modalMediaData,
}) => {
    const theme = useSelector(selectActiveTheme);
    const classes = makeCommonFormPreviewStyles(theme);
    const previewImageClasses = makeCommonPreviewImageStyles(theme);
    return (
        <Dialog
            open={openPreviewDialog}
            onClose={() => setOpenPreviewDialog(false)}
            maxWidth={'md'}
            fullWidth
        >
            <Box className={previewImageClasses.imageWrapper}>
                <Box className={previewImageClasses.imageWrapperBlock}>
                    <img
                        width="100%"
                        src={modalMediaData?.url}
                        alt={
                            modalMediaData?.original_file_name ||
                            modalMediaData?.url
                        }
                    />
                </Box>
            </Box>
            <DialogActions>
                <Button
                    autoFocus
                    className={classes.cancel}
                    onClick={() => setOpenPreviewDialog(false)}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const ImageCard = ({
    url,
    isSelected = false,
    label = 'image',
    onClick = () => {},
}) => {
    return (
        <Box
            width={'250px'}
            height={'250px'}
            border={isSelected ? '2px solid' : ''}
            padding={isSelected ? '' : '2px'}
        >
            <Box
                height={'220px'}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <img
                    src={url}
                    alt={label}
                    width={'246px'}
                    height={'218px'}
                    onClick={onClick}
                    style={{
                        objectFit: 'contain',
                        maxWidth: '100%',
                        maxHeight: '100%',
                    }}
                />
            </Box>
            <Box width={'250px'} height={'30px'} textAlign={'center'}>
                {label}
            </Box>
        </Box>
    );
};

const CompareDialog = ({
    openCompareDialog,
    setOpenCompareDialog,
    compareImage,
    referenceImage,
}) => {
    return (
        <Dialog fullScreen={true} open={openCompareDialog} onClose={() => {}}>
            <DialogContent style={{ padding: 0 }}>
                <DialogContentText
                    margin={'0 15px'}
                    display={'flex'}
                    flexDirection={'row'}
                    flexWrap={'wrap'}
                    justifyContent={'center'}
                    color={'black'}
                >
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenCompareDialog(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 6,
                            color: '#000',
                            zIndex: '15',
                        }}
                        size="large"
                    >
                        <MdCancel className="react-icon" />
                    </IconButton>
                    <Box
                        width={'100%'}
                        display={'flex'}
                        flexWrap={'wrap'}
                        justifyContent={'center'}
                        height={'100vh'}
                        alignItems={'center'}
                    >
                        <Box width={'49%'} height={'100vh'}>
                            <Box
                                textAlign={'center'}
                                width={'100%'}
                                height={'80vh'}
                                overflow={'auto'}
                            >
                                <ZoomPinchComponent
                                    src={compareImage?.url}
                                    label={compareImage?.name}
                                />
                            </Box>
                            <Box textAlign={'center'} width={'100%'}>
                                {compareImage?.name}
                            </Box>
                        </Box>
                        <Box width={'2%'} height={'100vh'}></Box>
                        <Box width={'49%'} height={'100vh'}>
                            <Box
                                height={'80vh'}
                                textAlign={'center'}
                                width={'100%'}
                                overflow={'auto'}
                            >
                                <ZoomPinchComponent
                                    src={referenceImage?.url}
                                    label={referenceImage?.name}
                                />
                            </Box>
                            <Box textAlign={'center'} width={'100%'}>
                                {referenceImage?.name}
                            </Box>
                        </Box>
                    </Box>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

const ReferencesSelectedImagesDialog = ({
    data,
    openReferenceImageDialog,
    setOpenReferenceImageDialog,
}) => {
    const checklistData = useSelector(getChecklistData);
    const [referenceImages, setReferenceImages] = useState([]);
    const [compareImages, setCompareImages] = useState([]);
    const [selectedReferenceId, setSelectedReferenceId] = useState(null);
    const [selectedCompareId, setSelectedCompareId] = useState(null);
    const [openCompareDialog, setOpenCompareDialog] = useState(false);
    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

    useEffect(() => {
        if (
            data?.comparison_field &&
            Array.isArray(checklistData?.imgAtt?.[data?.comparison_field]) &&
            checklistData?.imgAtt?.[data?.comparison_field]?.length > 0
        ) {
            setCompareImages(checklistData?.imgAtt?.[data?.comparison_field]);
        } else {
            setCompareImages([]);
        }
    }, [checklistData, data]);

    useEffect(() => {
        if (data?.references?.length > 0) {
            setReferenceImages(data?.references);
        } else {
            setReferenceImages([]);
        }
    }, [data]);

    return (
        <>
            <Dialog
                fullWidth
                open={openReferenceImageDialog}
                onClose={() => setOpenReferenceImageDialog(false)}
                maxWidth={'md'}
            >
                <DialogTitle
                    style={{
                        textAlign: 'center',
                        borderBottom: '1px solid',
                        padding: '8px',
                        fontSize: '15px',
                    }}
                >
                    {data?.label}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        margin={'15px'}
                        display={'flex'}
                        flexDirection={'row'}
                        gap={'10px'}
                        flexWrap={'wrap'}
                        justifyContent={'center'}
                        color={'black'}
                    >
                        <Box
                            width={'100%'}
                            display={'flex'}
                            flexWrap={'wrap'}
                            justifyContent={'center'}
                            maxHeight={'80vh'}
                        >
                            <Grid container width={'100%'}>
                                <Grid
                                    item
                                    md={4}
                                    lg={4}
                                    sm={6}
                                    xs={6}
                                    style={{
                                        overflow: 'auto',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '10px',
                                        maxHeight: '60vh',
                                    }}
                                >
                                    <Box textAlign={'center'} width={'100%'}>
                                        Photos
                                    </Box>
                                    {compareImages.map((selected) => (
                                        <ImageCard
                                            key={`selected-image-${selected?.id}`}
                                            isSelected={
                                                selectedCompareId ===
                                                selected?.id
                                            }
                                            url={selected?.url}
                                            label={selected?.name}
                                            onClick={() => {
                                                setSelectedCompareId(
                                                    selected?.id
                                                );
                                            }}
                                        />
                                    ))}
                                </Grid>
                                <Grid
                                    item
                                    md={8}
                                    lg={8}
                                    sm={6}
                                    xs={6}
                                    style={{
                                        overflow: 'auto',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '10px',
                                        flexWrap: 'wrap',
                                        maxHeight: '60vh',
                                        paddingLeft: '20px',
                                    }}
                                >
                                    <Box textAlign={'center'} width={'100%'}>
                                        Reference Images
                                    </Box>
                                    {referenceImages.map((available) => (
                                        <ImageCard
                                            key={`reference-image-${available?.id}`}
                                            isSelected={
                                                selectedReferenceId ===
                                                available?.id
                                            }
                                            url={available?.url}
                                            label={available?.name}
                                            onClick={() => {
                                                if (
                                                    (!data.image_attachments_allow &&
                                                        !data.comparison_field) ||
                                                    data.image_attachments_allow ===
                                                        false
                                                ) {
                                                    setOpenPreviewDialog(true);
                                                    setSelectedReferenceId(
                                                        available
                                                    );
                                                } else {
                                                    setSelectedReferenceId(
                                                        available?.id
                                                    );
                                                }
                                            }}
                                        />
                                    ))}
                                </Grid>
                            </Grid>
                        </Box>
                        {selectedReferenceId && selectedCompareId ? (
                            <Box
                                width={'100%'}
                                display={'flex'}
                                flexWrap={'wrap'}
                                justifyContent={'right'}
                            >
                                <Button
                                    onClick={() => setOpenCompareDialog(true)}
                                    variant="contained"
                                    color="primary"
                                >
                                    Compare
                                </Button>
                            </Box>
                        ) : (
                            <Box
                                width={'100%'}
                                display={'flex'}
                                flexWrap={'wrap'}
                                justifyContent={'center'}
                            >
                                Select one photo and one image to compare
                            </Box>
                        )}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            {openCompareDialog && (
                <CompareDialog
                    openCompareDialog={openCompareDialog}
                    setOpenCompareDialog={setOpenCompareDialog}
                    compareImage={compareImages.find(
                        (img) => img.id === selectedCompareId
                    )}
                    referenceImage={referenceImages.find(
                        (img) => img.id === selectedReferenceId
                    )}
                />
            )}
            <ImagePreview
                openPreviewDialog={openPreviewDialog}
                setOpenPreviewDialog={setOpenPreviewDialog}
                modalMediaData={selectedReferenceId}
            />
        </>
    );
};

const MediaCompareField = ({
    data,
    isSubmitAllow,
    inGroup = false,
    handleClick = () => {},
}) => {
    const [openReferenceImageDialog, setOpenReferenceImageDialog] =
        useState(false);

    const mediaCompareData = (
        <FormControl
            style={{
                padding: '5px',
                width: data?.field_width ? data.field_width + '%' : '100%',
            }}
        >
            <div style={{ padding: '5px' }}>
                <Button
                    color="secondary"
                    variant="outlined"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                    onClick={(e) => {
                        if (isSubmitAllow !== false) {
                            setOpenReferenceImageDialog(true);
                        } else {
                            e.stopPropagation();
                            handleClick(e, data);
                        }
                    }}
                    aria-label="Compare Images"
                >
                    <MdPhotoLibrary
                        className="react-icon"
                        style={{ padding: '0', color: '#000000' }}
                    />
                    <Typography
                        style={{ textTransform: 'none' }}
                        variant="h6"
                        fontSize="12px"
                        color="black"
                    >
                        {data?.label}
                    </Typography>
                </Button>
                {openReferenceImageDialog && (
                    <ReferencesSelectedImagesDialog
                        data={data}
                        openReferenceImageDialog={openReferenceImageDialog}
                        setOpenReferenceImageDialog={
                            setOpenReferenceImageDialog
                        }
                    />
                )}
            </div>
        </FormControl>
    );

    return inGroup ? (
        mediaCompareData
    ) : (
        <div style={{ width: '100%' }}>{mediaCompareData}</div>
    );
};
export default MediaCompareField;
