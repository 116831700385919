import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toolsCategoryService } from '_features/_services';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';

const initialState = {
    category: [],
    categoryStatus: LoadingStatus.Idle,
    categoryPageInfo: {},
    displayCategory: {},
    loadedCategoryMap: {},
};

export const fetchToolCategoriesPage = createAsyncThunk(
    'toolsCategory/fetchCategoriesPage',
    async (params = {}, { getState }) =>
        await toolsCategoryService.getCategories(
            params.searchString,
            params.sort,
            getState().accounts.activeUser.s3_prefix
        )
);

export const fetchToolCategoryById = createAsyncThunk(
    'toolsCategory/fetchCategoryById',
    async (categoryId, { getState }) => {
        const response = await toolsCategoryService.getById(
            categoryId,
            getState().accounts.activeUser.s3_prefix
        );
        response.id = categoryId;
        return response;
    }
);

export const createToolCategory = createAsyncThunk(
    'toolsCategory/createCategory',
    async (params, { getState }) =>
        await toolsCategoryService.create(
            params,
            getState().accounts.activeUser.s3_prefix
        )
);

export const updateToolCategory = createAsyncThunk(
    'toolsCategory/updateCategory',
    async (params, { getState }) =>
        await toolsCategoryService.update(
            params,
            getState().accounts.activeUser.s3_prefix
        )
);

export const deleteToolCategory = createAsyncThunk(
    'toolsCategory/deleteCategory',
    async ({ tool_category_id }) => {
        const response = await toolsCategoryService.delete(tool_category_id);
        return response;
    }
);

// export const deleteCategoriesArray = createAsyncThunk(
//     'assets/deleteCategoriesArray',
//     async (categoryIDs) => {
//         const response = await toolCategoryService.deleteArray(categoryIDs);
//         response.ids = categoryIDs;
//         return response;
//     }
// );

const toolsCategorySlice = createSlice({
    name: 'toolsCategory',
    initialState,
    reducers: {
        resetCategoryState: (state) => initialState,
    },
    extraReducers: {
        [fetchToolCategoriesPage.pending]: (state, action) => {
            state.categoryStatus = LoadingStatus.Loading;
        },
        [fetchToolCategoriesPage.fulfilled]: (state, action) => {
            state.categoryPageInfo = action.payload.pageInfo;
            state.category = action.payload.categories;
            state.categoryStatus = LoadingStatus.Loaded;
        },
        [fetchToolCategoriesPage.rejected]: (state, action) => {
            state.categoryStatus = LoadingStatus.Failed;
        },
        [createToolCategory.fulfilled]: (state, action) => {
            state.categoryStatus = LoadingStatus.Idle;
        },
        [updateToolCategory.fulfilled]: (state, action) => {
            state.categoryStatus = LoadingStatus.Idle;
        },
        [deleteToolCategory.fulfilled]: (state, action) => {
            state.categoryStatus = LoadingStatus.Idle;
        },
        // [deleteCategoriesArray.fulfilled]: (state, action) => {
        //     state.categoryStatus = LoadingStatus.Idle;
        // },
        // [deleteCategoriesArray.rejected]: (state, action) => {
        //     state.status = SavingStatus.Failed;
        //     state.error = action.error.message;
        // },
        [fetchToolCategoryById.fulfilled]: (state, action) => {
            state.displayCategory = action.payload;
        },
    },
});

export const selectToolsCategories = (state) => state.toolsCategory.category;
export const getToolsCategoryStatus = (state) =>
    state.toolsCategory.categoryStatus;
export const getToolsCategoryPageInfo = (state) =>
    state.toolsCategory.categoryPageInfo;

export const { resetCategoryState } = toolsCategorySlice.actions;

export default toolsCategorySlice.reducer;
