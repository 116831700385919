import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GroupTable } from './GroupTable';
import {
    fetchGroupsPage,
    selectAllGroups,
    getGroupStatus,
    getGroupPageInfo,
    resetGroupState,
} from '_features/groups/groupsSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';

function GroupManagementTab() {
    const dispatch = useDispatch();
    const ref = useRef(false);
    const groups = useSelector(selectAllGroups);
    const groupStatus = useSelector(getGroupStatus);
    const pageInfo = useSelector(getGroupPageInfo);
    const [groupPayload, setGroupPayload] = useState({});
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');

    const getGroupData = async (payload) => {
        dispatch(activateLoading());
        await dispatch(fetchGroupsPage({ ...payload }));
        dispatch(deactivateLoading());
    };

    useEffect(() => {
        if (ref.current === true) {
            getGroupData({ ...groupPayload });
        }
    }, [groupPayload]);

    useEffect(() => {
        return async () => {
            await dispatch(resetGroupState());
        };
    }, []);

    useEffect(() => {
        if (groupStatus === LoadingStatus.Idle) {
            getGroupData({ ...groupPayload, page: 1, pageSize: 10 });
        }
        ref.current = true;
    }, [groupStatus]);

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        if (_sortBy === 'is_active') {
            _sortDirection = _sortDirection === 'ASC' ? 'DESC' : 'ASC';
        }
        setGroupPayload({
            ...groupPayload,
            sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
        });
    };

    return (
        <GroupTable
            groups={groups}
            pageInfo={pageInfo}
            groupPayload={groupPayload}
            setGroupPayload={setGroupPayload}
            sortDirection={sortDirection}
            sortBy={sortBy}
            handleSort={(_sortBy, _sortDirection) =>
                setSorting(_sortBy, _sortDirection)
            }
        />
    );
}

export { GroupManagementTab };
