import React from 'react';
import { LinkTypes } from '_helpers/enumerations';

import { GeneralContentTab } from '../GeneralContentTab';

function DocumentTab() {
    return (
        <GeneralContentTab
            contentName={'Document'}
            linkTypeId={LinkTypes.Document.id}
            contentTypes={LinkTypes.Document.contentTypes}
        />
    );
}

export { DocumentTab };
