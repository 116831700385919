import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import { accountsSlice, accountsThunk } from 'app/store';
import {
    Switch,
    FormControlLabel,
    MenuItem,
    Checkbox,
    TextField,
    IconButton,
    Tooltip,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
    MdAdd,
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdEdit,
} from 'react-icons/md';

import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { Action } from '_helpers';
import {
    resizableActiveCell,
    resizableLabelHeader,
    resizableLabelCell,
    resizableActionCell,
    resizableCheckCell,
    resizableCheckHeaderCell,
} from 'components';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import {
    closePanel,
    openPanel,
    setEditObjectId,
} from '_features/common/formSlice';
import SearchWithFilters from 'components/SearchWithFilters';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { EditMultipleUsersDialog } from 'components/EditMultipleUsersDialog';
import { makeButtonStyles } from '../../../../GeminiViewerComponent/components/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { MultiSelectDoneButton } from 'GeminiViewerComponent/components/MultiSelectDoneButton';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { ResizableTable } from 'GeminiViewerComponent/components/_Tables/ResizableTable';

const { selectActiveUser, selectAllRoles, getRolesStatus } = accountsSlice;
const { fetchRoles } = accountsThunk;

function UserTable({
    users,
    userPayload,
    setUserPayload,
    sortDirection,
    sortBy,
    handleSort,
}) {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const buttonStyles = makeButtonStyles(theme);
    const classes = useStyles();
    const tableClasses = tableStyles();
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);

    const currentUser = useSelector((state) => selectActiveUser(state));
    const [selectedRole, setSelectedRole] = useState([]);
    const [activeOnly, setActiveOnly] = useState(true);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filterBadge, setFilterBadge] = useState(0);
    const [editDialog, setEditDialog] = useState(false);
    const roles = useSelector((state) => selectAllRoles(state));
    const roleStatus = useSelector((state) => getRolesStatus(state));
    useEffect(() => {
        if (roleStatus === LoadingStatus.Idle) {
            dispatch(fetchRoles());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleStatus]);

    useEffect(() => {
        // Remove current user from table
        var exceptUsers = [...users].filter(
            (x) => x.user_id !== currentUser.user_id
        );
        if (activeOnly) {
            const activeUsers = exceptUsers.filter(
                (usr) => usr?.is_active === true
            );
            setFilteredUsers(activeUsers);
        } else {
            setFilteredUsers(exceptUsers);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, activeOnly]);

    const handleActiveOnly = () => setActiveOnly(!activeOnly);

    const loadedRowsMap = useSelector(
        (state) => state.accounts.loadedAccountsMap
    );

    const headerHeight = 40;
    const rowHeight = 60;

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== filteredUsers.length) {
            setSelected(filteredUsers.map((row) => row.user_id));
            setSelectedAll(true);
        }
    }

    const handleClick = (event, user_id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(user_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, user_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        setSelectedAll(newSelected.length === filteredUsers.length);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedIds = filteredUsers.map((user) => user.user_id);
            setSelected(newSelectedIds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const handleRowClick = async ({ rowData }) => {
        await dispatch(closePanel({ formKey: 'accountForm' }));
        if (rowData?.user_id) {
            await dispatch(
                setEditObjectId({
                    formKey: `accountForm`,
                    object_id: rowData?.user_id,
                })
            );
            dispatch(openPanel({ formKey: `accountForm`, formAction: 'Edit' }));
        }
    };

    const columns = [
        {
            accessorKey: 'user_id',
            size: 50,
            minSize: 50,
            enableResizing: false,
            label: '',
            padding: 'checkbox',
            cellLevel: 'user',
            numSelected: selected.length,
            rowCount: filteredUsers.length,
            handleClick: handleClick,
            handleSelectAllClick: handleSelectAllClick,
            isSelected: isSelected,
            header: (info) =>
                resizableCheckHeaderCell(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableCheckCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'user_id',
            size: 50,
            minSize: 50,
            enableResizing: false,
            padding: 'none',
            label: '',
            actionCellLevel: 'user',
            targetPopup: 'userManagement',
            formKey: 'accountForm',
            dispatch: dispatch,
            setViewPanel: false,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableActionCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'first_name',
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'First Name',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'last_name',
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'Last Name',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'email',
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'Email',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'role',
            flexGrow: 1,
            size: 180,
            minSize: 180,
            label: 'Role',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'is_active',
            flexGrow: 1,
            size: 100,
            minSize: 100,
            enableResizing: false,
            label: 'Active',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableActiveCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
    ];

    // eslint-disable-next-line no-unused-vars
    function loadMoreRows({ startIndex, stopIndex }) {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setUserPayload({ ...userPayload, searchString: searchString });
        }, 1000),
        [userPayload]
    );

    const handleSearch = (searchString) => debouncedSearch(searchString);

    const updateInputValue = (evt) => handleSearch(evt.target.value);

    const addFilters = () => {
        if (Array.isArray(selectedRole) && selectedRole.length > 0) {
            const roles = selectedRole.map((rl) => {
                return rl?.display_name;
            });
            setUserPayload({ ...userPayload, roles: roles });
            setFilterBadge(1);
        } else {
            clearFilters();
            setFilterBadge(0);
            setSelectedRole([]);
        }
    };

    const clearFilters = () => {
        let payload = userPayload;
        delete payload?.roles;
        setUserPayload({ ...payload });
        setFilterBadge(0);
        setSelectedRole([]);
    };

    const handleCreateNew = () => {
        dispatch(
            openPanel({
                formKey: 'accountForm',
                formAction: 'Create',
                clearEditId: true,
            })
        );
    };

    return (
        <div className={classes.root}>
            <div style={{ display: 'flex', gap: '5px', marginBottom: '10px' }}>
                <Tooltip title="Add New User">
                    <IconButton
                        onClick={handleCreateNew}
                        size="large"
                        className={buttonStyles.contentAddButton}
                    >
                        <MdAdd className="react-icon" />
                    </IconButton>
                </Tooltip>
                <div style={{ flexGrow: 1 }}></div>
                <div>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={activeOnly}
                                onChange={handleActiveOnly}
                            />
                        }
                        label="Active Only"
                    />
                </div>
                <SearchWithFilters
                    filterBadge={filterBadge}
                    addFilters={addFilters}
                    clearFilters={clearFilters}
                    onChange={(evt) => updateInputValue(evt)}
                >
                    <MenuItem disableRipple>
                        {roles.length > 0 && (
                            <Autocomplete
                                variant="outline"
                                id="roles"
                                multiple
                                limitTags={2}
                                options={roles}
                                onChange={(event, newInputValue) => {
                                    setSelectedRole(newInputValue);
                                }}
                                disableCloseOnSelect
                                value={selectedRole}
                                getOptionLabel={(option) =>
                                    option?.display_name
                                }
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={
                                                <MdCheckBoxOutlineBlank className="react-icon" />
                                            }
                                            checkedIcon={
                                                <MdCheckBox className="react-icon" />
                                            }
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option?.display_name}
                                    </li>
                                )}
                                style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Role"
                                        placeholder="Role"
                                    />
                                )}
                                PaperComponent={MultiSelectDoneButton}
                            />
                        )}
                    </MenuItem>
                </SearchWithFilters>
            </div>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    toolTipTitle={'Delete'}
                    totalCount={filteredUsers.length}
                >
                    <IconButton
                        onClick={() => setEditDialog(true)}
                        size="small"
                        toolTipTitle="Edit"
                    >
                        <MdEdit
                            className="react-icon"
                            style={{ width: '20px', height: '20px' }}
                        />
                    </IconButton>
                    <PopupAction
                        action={Action.DeleteArray}
                        object={{
                            accountIds: selected,
                            setAccountIds: setSelected,
                        }}
                        level={'user'}
                        showLabel={false}
                        toolTipTitle="Delete"
                    />
                </EnhancedTableToolbar>
            )}
            <div
                style={{
                    height: `calc(100vh - ${
                        selected.length > 0 ? '345px' : '280px'
                    })`,
                    minWidth: '500px',
                }}
            >
                <ResizableTable
                    initialRows={filteredUsers}
                    allRows={filteredUsers}
                    totalRowCount={filteredUsers.length}
                    loadedRowsMap={loadedRowsMap}
                    loadMoreRows={loadMoreRows}
                    columns={columns}
                    cellClassName={tableClasses.flexContainer}
                    className={tableClasses.table}
                    rowClassName={tableClasses.flexContainer}
                    headerHeight={headerHeight}
                    rowHeight={rowHeight}
                    onRowClick={handleRowClick}
                />
            </div>
            <EditMultipleUsersDialog
                open={editDialog}
                handleClose={() => setEditDialog(false)}
                users={selected}
            />
        </div>
    );
}

export { UserTable };
