import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { makeFormStyles } from 'forms/styles';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    FormControl,
    Grid,
    Typography,
} from '@mui/material';

import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchSnippets,
    createFolder,
    createSnippet,
    fetchFolders,
    getAllFolders,
    getFolderStatus,
    editSnippet,
    getSnippet,
    setSnippet,
} from '_features/snippets/snippetsSlice';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { useSnackbar } from 'notistack';
import { snackbarHandler } from '_helpers';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { FormActions } from '_helpers';
import { SelectionAutocomplete } from 'components/_Autocomplete/SelectionAutocomplete';
import { FormTextField } from 'components/TextField';

const CreateDialog = ({ formAction, setSnippet: setSelectedSnippet }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useSelector(selectActiveTheme);
    const commonFormStyles = makeFormStyles(theme);
    const snippet = useSelector(getSnippet);
    const INIT_VALUES = {
        display_name: '',
        snippet_folder_id: '',
        trigger_key: '',
        source: '',
    };
    const [initValues, setInitValues] = useState({
        ...INIT_VALUES,
    });

    useEffect(() => {
        if (snippet && formAction.id === FormActions.Edit.id) {
            const fetchData = () => {
                setInitValues({
                    snippet_id: snippet.snippet_id,
                    display_name: snippet.display_name,
                    snippet_folder_id: snippet.snippet_folder_id,
                    trigger_key: snippet.trigger_key,
                    source: snippet.source,
                });
            };
            fetchData();
        } else {
            setInitValues({
                ...initValues,
                source: snippet?.source,
            });
        }
    }, [snippet]);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        dispatch(activateLoading());
        var resultAction = null;

        switch (formAction.id) {
            case FormActions.Edit.id:
                resultAction = await dispatch(editSnippet({ ...values }));
                if (!resultAction.error) {
                    await dispatch(fetchSnippets());
                    await dispatch(fetchFolders());
                    const { message, variant } = snackbarHandler(
                        resultAction.meta.requestStatus,
                        formAction.label
                    );
                    enqueueSnackbar(message, {
                        action: (key) => <SnackbarDismiss snackItem={key} />,
                        variant: variant,
                    });
                    dispatch(setSnippet({}));
                    setSelectedSnippet(false);
                } else {
                    enqueueSnackbar(resultAction?.error?.message, {
                        action: (key) => <SnackbarDismiss snackItem={key} />,
                        variant: 'error',
                    });
                }
                break;
            case FormActions.Create.id:
                resultAction = await dispatch(createSnippet({ ...values }));
                if (!resultAction.error) {
                    await dispatch(fetchSnippets());
                    await dispatch(fetchFolders());
                    const { message, variant } = snackbarHandler(
                        resultAction.meta.requestStatus,
                        formAction.label
                    );
                    enqueueSnackbar(message, {
                        action: (key) => <SnackbarDismiss snackItem={key} />,
                        variant: variant,
                    });
                    dispatch(setSnippet({}));
                    setSelectedSnippet(false);
                } else {
                    enqueueSnackbar(resultAction?.error?.message, {
                        action: (key) => <SnackbarDismiss snackItem={key} />,
                        variant: 'error',
                    });
                }
                break;
        }
        dispatch(deactivateLoading());
        setSubmitting(false);
    };

    return (
        <Dialog open={true} fullWidth maxWidth="sm">
            <DialogTitle>Create Snippet</DialogTitle>
            <DialogContent
                style={{
                    paddingTop: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Formik
                            initialValues={initValues}
                            enableReinitialize={true}
                            validationSchema={Yup.object({
                                display_name: Yup.string()
                                    .max(150, 'Must be 150 characters or less')
                                    .required(),
                                snippet_folder_id: Yup.string().required(),
                                trigger_key: Yup.string().max(
                                    30,
                                    'Must be 30 characters or less'
                                ),
                            })}
                            onSubmit={(
                                values,
                                { setSubmitting, resetForm }
                            ) => {
                                handleSubmit(values, setSubmitting, resetForm);
                            }}
                        >
                            {({ setFieldValue, values }) => (
                                <Form>
                                    <FormControl fullWidth>
                                        <FormTextField
                                            label="Name"
                                            value={values.display_name}
                                            name="display_name"
                                            placeholder="Name"
                                            onChange={(evt) =>
                                                setFieldValue(
                                                    'display_name',
                                                    evt.target.value
                                                )
                                            }
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <Field
                                            className={
                                                commonFormStyles.materialInput
                                            }
                                            name="snippet_folder_id"
                                        >
                                            {({
                                                field: { value },
                                                form: { setFieldValue },
                                            }) => (
                                                <SelectionAutocomplete
                                                    title="Folder"
                                                    placeholder="Folder"
                                                    keyProperty="snippet_folder_id"
                                                    nameProperty="display_name"
                                                    entityIds={value}
                                                    setEntityIds={setFieldValue}
                                                    entitySelector={
                                                        getAllFolders
                                                    }
                                                    entityStatusSelector={
                                                        getFolderStatus
                                                    }
                                                    fetchEntityPage={
                                                        fetchFolders
                                                    }
                                                    multiSelection={false}
                                                    formField={
                                                        'snippet_folder_id'
                                                    }
                                                    fieldValidationLength={150}
                                                    enableCreatable={true}
                                                    creatableDialogTitle={
                                                        'Folder'
                                                    }
                                                    creatableDialogFieldLabel={
                                                        'Folder'
                                                    }
                                                    creatableDispatch={
                                                        createFolder
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <FormTextField
                                            label="Trigger key"
                                            value={values.trigger_key}
                                            name="trigger_key"
                                            placeholder="Trigger key"
                                            onChange={(evt) =>
                                                setFieldValue(
                                                    'trigger_key',
                                                    evt.target.value
                                                )
                                            }
                                        />
                                        <Typography color="#757575">
                                            Trigger + Tab to expand
                                        </Typography>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <FormTextField
                                            multiline
                                            placeholder="Source"
                                            label="Source"
                                            name="source"
                                            rows={5}
                                            style={{ marginTop: '15px' }}
                                        />
                                    </FormControl>
                                    <div
                                        style={{
                                            justifyContent: 'end',
                                            display: 'flex',
                                            marginTop: '25px',
                                            gap: '5px',
                                        }}
                                    >
                                        <Button
                                            className={commonFormStyles.cancel}
                                            onClick={() =>
                                                setSelectedSnippet(false)
                                            }
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            className={commonFormStyles.submit}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export { CreateDialog };
