class ImageResizer {
    constructor(file) {
        this.file = file;
        this.canvas = document.createElement('canvas');
        this.ctx = this.canvas.getContext('2d');
    }

    async run(maxWidth, maxHeight, compressQuality = 0.9) {
        this.promise = new Promise((resolve, reject) => {
            this.promiseResolve = resolve;
            this.promiseReject = reject;
        });

        const img = new Image();

        img.src = URL.createObjectURL(this.file);

        img.addEventListener('load', () => {
            var { width, height } = img;

            console.log(
                `Resizing from ${width} ${height} with ${maxWidth} ${maxHeight}`
            );

            let resize = false;

            if (maxWidth > 0 && maxHeight == 0 && width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
                resize = true;
            } else if (maxWidth == 0 && maxHeight > 0 && height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
                resize = true;
            } else if (
                maxWidth > 0 &&
                maxHeight > 0 &&
                (width > maxWidth || height > maxHeight)
            ) {
                if (width > height) {
                    height *= maxWidth / width;
                    width = maxWidth;
                } else {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
                resize = true;
            }

            console.log(`  to ${width} ${height}`);

            // Don't do anything if not compressing and not resized
            if (!resize && compressQuality == null) {
                this.promiseResolve(this.file);
                return;
            }

            this.canvas.width = width;
            this.canvas.height = height;
            this.ctx.drawImage(img, 0, 0, width, height);

            // this.canvas.toBlob(blob => this.promiseResolve(URL.createObjectURL(blob)), 'image/jpeg', 0.92);
            if (this.file?.type === 'image/svg+xml') {
                this.promiseResolve(this.file);
            } else {
                this.canvas.toBlob(
                    (blob) => {
                        blob.name = this.file.name;
                        return blob
                            ? this.promiseResolve(blob)
                            : this.promiseReject(null);
                    },
                    'image/jpeg',
                    compressQuality
                );
            }

            URL.revokeObjectURL(img.src);
        });

        return await this.promise;
    }
}

export { ImageResizer };
