import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import {
    Divider,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Button,
} from '@mui/material';

import { Action } from '_helpers';
import { useStyles } from '../PopupAction/styles';
import { useSelector, useDispatch } from 'react-redux';
import { selectAllTableZones } from '_features/zones/zonesTableSlice';
import getActionIcon from 'GeminiViewerComponent/_helpers/getActionIcon';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    editZoneNavLink,
    deleteZoneNavLink,
    deleteZone,
} from '_features/common/editZoneSlice';

function ZonePopup({ object, level, onClose, readOnlyMode }) {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const allZones = useSelector(selectAllTableZones);
    const classes = useStyles();
    const [openZoneDeleteDialog, setOpenZoneDeleteDialog] = useState(false);
    const calculateNavLinks = (zone_id, asset_zones) => {
        let calNavLinks = [];
        asset_zones.map((val) => {
            let temp = val.nav_links.filter((nav) => {
                return nav.target_zone_id === zone_id;
            });
            if (temp.length > 0) {
                calNavLinks.push(...temp);
            }
        });
        return calNavLinks;
    };
    const totalNavLinks = calculateNavLinks(object?.zone_id, allZones);
    const handleDeleteZones = async ({ zone_id, type, target_nav_links }) => {
        dispatch(activateLoading());
        try {
            switch (type) {
                case 'zoneOnly':
                    await target_nav_links?.map(async (val) => {
                        let updateHotspot = {
                            nav_link_id: val.nav_link_id,
                            target_zone_id: val.zone_id,
                            zone_id: val.zone_id,
                        };
                        await dispatch(editZoneNavLink(updateHotspot));
                        return null;
                    });
                    await dispatch(
                        deleteZone({
                            zoneId: zone_id,
                        })
                    );
                    break;
                case 'zoneAndNavLinks':
                    await target_nav_links?.map(async (val) => {
                        await dispatch(deleteZoneNavLink(val));
                        return null;
                    });
                    await dispatch(
                        deleteZone({
                            zoneId: zone_id,
                        })
                    );
                    break;
                default:
                    break;
            }
            dispatch(deactivateLoading());

            enqueueSnackbar('Delete successful', {
                action: (key) => <SnackbarDismiss snackItem={key} />,
                variant: 'success',
            });
        } catch (err) {
            dispatch(deactivateLoading());
            enqueueSnackbar('Delete failed', {
                action: (key) => <SnackbarDismiss key={key} />,
                variant: 'error',
            });
        }
    };
    return (
        <React.Fragment>
            <PopupAction
                action={Action.Edit}
                object={object}
                level={level}
                showLabel={true}
                onActionHandled={onClose}
            />
            {readOnlyMode !== true && (
                <>
                    <Divider />
                    <PopupAction
                        action={Action.Duplicate}
                        object={object}
                        level={level}
                        showLabel={true}
                        onActionHandled={onClose}
                    />
                    <Divider />
                    {totalNavLinks.length > 0 ? (
                        <div
                            className={classes.popupActionContainer}
                            onClick={() => {
                                setOpenZoneDeleteDialog(true);
                                // handleDeleteZones({ zone_id: object?.zone_id });
                            }}
                        >
                            <div className={classes.popupActionIcon}>
                                {getActionIcon(Action.Delete.iconId)}
                            </div>
                            <div className={classes.popupActionLabel}>
                                {Action.Delete.displayLabel}
                            </div>
                        </div>
                    ) : (
                        <PopupAction
                            action={Action.Delete}
                            object={object}
                            level={level}
                            showLabel={true}
                            onActionHandled={onClose}
                        />
                    )}
                    {openZoneDeleteDialog && (
                        <Dialog
                            open={openZoneDeleteDialog}
                            onClose={() => setOpenZoneDeleteDialog(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {'Delete Zone'}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {`${totalNavLinks.length} navigation link(s) are targeting the deleted zone. Please select an option below.`}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        onClose();
                                        setOpenZoneDeleteDialog(false);
                                    }}
                                    autoFocus
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => {
                                        onClose();
                                        handleDeleteZones({
                                            zone_id: object?.zone_id,
                                            type: 'zoneOnly',
                                            target_nav_links: totalNavLinks,
                                        });
                                        setOpenZoneDeleteDialog(false);
                                    }}
                                >
                                    Delete Zone Only
                                </Button>

                                <Button
                                    onClick={() => {
                                        onClose();
                                        handleDeleteZones({
                                            zone_id: object?.zone_id,
                                            type: 'zoneAndNavLinks',
                                            target_nav_links: totalNavLinks,
                                        });
                                        setOpenZoneDeleteDialog(false);
                                    }}
                                >
                                    Delete Zone and related Nav Links
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </>
            )}
        </React.Fragment>
    );
}

export { ZonePopup };
