import { MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import { useSelector } from 'react-redux';
import { accountsSlice } from 'app/store';
import useStyles from '../../styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

const { selectUserSetting } = accountsSlice;

function AssetsFilter({
    assetType,
    sortDirection,
    sortBy,
    setSortBy,
    setSortDirection,
}) {
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);

    const assetViewMode = useSelector((state) =>
        selectUserSetting(state, 'asset_view_mode')
    );

    const procedureViewMode = useSelector((state) =>
        selectUserSetting(state, 'procedure_view_mode')
    );

    return (
        <div>
            {((assetType === 'assets' && assetViewMode === 'card') ||
                (assetType === 'procedures' &&
                    procedureViewMode === 'card')) && (
                <div className={classes.sortContainer}>
                    <FormControl sx={{ mb: '1rem' }} fullWidth>
                        <InputLabel id="sort-by-id" variant="outlined">
                            Sort By
                        </InputLabel>
                        <Select
                            labelId="sort-by-id"
                            id="select-sort-by-id"
                            value={sortBy}
                            label="Sort By"
                            variant="outlined"
                            className={classes.selectRoot}
                            onChange={(event) => setSortBy(event?.target.value)}
                        >
                            {assetType !== 'procedures' && (
                                <MenuItem value="asset_type_id">
                                    Asset type
                                </MenuItem>
                            )}
                            <MenuItem value="display_name">Name</MenuItem>
                            <MenuItem value="owner_last_name">Owner</MenuItem>
                            <MenuItem value="last_published_date">
                                Publish date
                            </MenuItem>
                            {assetType !== 'procedures' && (
                                <MenuItem value="zone_count">
                                    Number of zones
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ mb: '1rem' }} fullWidth>
                        <InputLabel id="sot-direction-id" variant="outlined">
                            Order
                        </InputLabel>
                        <Select
                            labelId="sot-direction-id"
                            id="select-sort-direction-id"
                            className={classes.selectRoot}
                            value={sortDirection}
                            label="Order"
                            variant="outlined"
                            onChange={(event) =>
                                setSortDirection(event?.target.value)
                            }
                        >
                            <MenuItem value="ASC">Ascending</MenuItem>
                            <MenuItem value="DESC">Descending</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            )}
        </div>
    );
}

export { AssetsFilter };
