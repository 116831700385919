import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    LoadingStatus,
    SavingStatus,
} from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { deploymentPackageService } from '_features/_services/mangeDeployedPackage.service';
import { sortByCaseInsensitiveText } from 'GeminiViewerComponent/_helpers/lodashUtils';

const initialState = {
    deploymentPackageStatus: LoadingStatus.Idle,
    deploymentPackages: [],
    loadeddeploymentPackages: {},
    displaydeploymentPackages: {},
    deploymentPackagesPageInfo: {},
    deploymentPackageUsage: [],
    status: LoadingStatus.Idle,
    loadedRowsMap: {},
    error: null,

    deploymentPackageJobStatus: LoadingStatus.Idle,
    deploymentPackageJobs: [],
    loadeddeploymentPackageJobs: {},
    displaydeploymentPackageJobs: {},
    deploymentPackageJobsPageInfo: {},
    deploymentPackageJobUsage: [],
};

export const fetchDeployementPackagePage = createAsyncThunk(
    'deploymentPackage/fetchDeployementPackagePage',
    async (params) => {
        const response = await deploymentPackageService.getAll(
            params.deployment_package_id,
            params.page,
            params.pageSize,
            params.searchString,
            params.sort
        );
        return response;
    }
);

export const fetchDeployementById = createAsyncThunk(
    'deploymentPackage/fetchDeployementById',
    async (deploymentId) => await deploymentPackageService.getById(deploymentId)
);

export const addManagementDeployment = createAsyncThunk(
    'deploymentPackage/addManagementDeployment',
    async (params) => await deploymentPackageService.create(params)
);

export const editManagementDeployment = createAsyncThunk(
    'deploymentPackage/editManagementDeployment',
    async (updates) =>
        await deploymentPackageService.update(
            updates.deploymentId,
            updates.packageData
        )
);

export const deleteDeploymentById = createAsyncThunk(
    'deploymentPackage/deleteDeploymentById',
    async (deploymentId) => {
        const response = await deploymentPackageService.delete(deploymentId);
        return response;
    }
);

export const deleteDeployementArray = createAsyncThunk(
    'assets/deleteDeployementArray',
    async (deploymentIds) => {
        const response = await deploymentPackageService.deleteArray(
            deploymentIds
        );
        return response;
    }
);

export const fetchDeployementPackageJob = createAsyncThunk(
    'deploymentPackageJob/fetchDeployementPackageJob',
    async (params, { getState }) => {
        const response = await deploymentPackageService.getAllDeploymentJob(
            params.searchString,
            params.sort,
            getState().accounts.activeUser.s3_prefix
        );
        return response;
    }
);

export const addDeployementPackageJob = createAsyncThunk(
    'deploymentPackageJob/addDeployementPackageJob',
    async (params) => {
        return await deploymentPackageService.addDeploymentJob(params);
    }
);

const jobsSlice = createSlice({
    name: 'job',
    initialState,
    reducers: {
        resetDeploymentPackageState: (state) => initialState,
    },
    extraReducers: {
        [fetchDeployementPackagePage.fulfilled]: (state, action) => {
            state.deploymentPackageStatus = LoadingStatus.Loaded;
            state.deploymentPackagesPageInfo = action.payload.pageInfo;
            state.deploymentPackages = action.payload.deploymentPackages?.sort(
                sortByCaseInsensitiveText('name')
            );

            const startIndex =
                (action.meta.arg.page - 1) * action.meta.arg.pageSize;
            const stopIndex = startIndex + state.deploymentPackages.length - 1;
            const requestedStopIndex =
                startIndex + action.meta.arg.pageSize - 1;

            state.loadeddeploymentPackages = {};

            for (var i = startIndex; i <= stopIndex; i++) {
                state.loadeddeploymentPackages[i] = LoadingStatus.Loaded;
            }

            // In case we didn't load as many as requested
            for (var j = stopIndex + 1; j <= requestedStopIndex; j++) {
                delete state.loadeddeploymentPackages[j];
            }
        },
        [fetchDeployementPackagePage.rejected]: (state, action) => {
            state.deploymentPackageStatus = LoadingStatus.Failed;
            state.loadeddeploymentPackages = {};
        },
        [addManagementDeployment.fulfilled]: (state, action) => {
            state.deploymentPackageStatus = LoadingStatus.Idle;
        },
        [fetchDeployementById.fulfilled]: (state, action) => {
            state.deploymentPackageStatus = action.payload;
        },
        [editManagementDeployment.fulfilled]: (state, action) => {
            state.deploymentPackageStatus = LoadingStatus.Idle;
        },
        [deleteDeploymentById.fulfilled]: (state, action) => {
            state.deploymentPackageStatus = LoadingStatus.Idle;
        },
        [deleteDeployementArray.fulfilled]: (state, action) => {
            state.deploymentPackageStatus = LoadingStatus.Idle;
        },
        [deleteDeployementArray.rejected]: (state, action) => {
            state.deploymentPackageStatus = SavingStatus.Failed;
            state.error = action.error.message;
        },
        [fetchDeployementPackageJob.pending]: (state, action) => {
            state.deploymentPackageJobStatus = LoadingStatus.pending;
        },
        [fetchDeployementPackageJob.fulfilled]: (state, action) => {
            state.deploymentPackageJobStatus = LoadingStatus.Loaded;
            state.deploymentPackageJobsPageInfo = action.payload.pageInfo;
            state.deploymentPackageJobs = action.payload.deploymentPackageJobs;

            const startIndex =
                (action.meta.arg.page - 1) * action.meta.arg.pageSize;
            const stopIndex =
                startIndex + state.deploymentPackageJobs.length - 1;
            const requestedStopIndex =
                startIndex + action.meta.arg.pageSize - 1;

            state.loadeddeploymentPackageJobs = {};

            for (var i = startIndex; i <= stopIndex; i++) {
                state.loadeddeploymentPackageJobs[i] = LoadingStatus.Loaded;
            }

            // In case we didn't load as many as requested
            for (var j = stopIndex + 1; j <= requestedStopIndex; j++) {
                delete state.loadeddeploymentPackageJobs[j];
            }
        },
        [fetchDeployementPackageJob.rejected]: (state, action) => {
            state.deploymentPackageJobStatus = LoadingStatus.Failed;
            state.loadeddeploymentPackageJobs = {};
        },
        [addDeployementPackageJob.fulfilled]: (state, action) => {
            state.deploymentPackageJobStatus = LoadingStatus.Idle;
        },
    },
});

export const selectAllDeploymentPackage = (state) =>
    state.jobs.deploymentPackages;
export const getDeploymentPackagePageInfo = (state) =>
    state.jobs.deploymentPackagesPageInfo;
export const getDeploymentPackageStatus = (state) =>
    state.jobs.deploymentPackageStatus;
export const getDeploymentPackageUsage = (state) =>
    state.jobs.deploymentPackageUsage;

export const selectAllDeploymentPackageJob = (state) =>
    state.jobs.deploymentPackageJobs;
export const getDeploymentPackageJobPageInfo = (state) =>
    state.jobs.deploymentPackageJobsPageInfo;
export const getDeploymentPackageJobStatus = (state) =>
    state.jobs.deploymentPackageJobStatus;
export const getDeploymentPackageJobUsage = (state) =>
    state.jobs.deploymentPackageJobUsage;

export const { resetDeploymentPackageState } = jobsSlice.actions;

export default jobsSlice.reducer;
