export const useFilterContentProcedureNodes = (
    procedureContentVersion,
    allNodes
) => {
    if (!procedureContentVersion || !allNodes || allNodes.length === 0) {
        return [];
    }

    if (procedureContentVersion.procedure_display_all_nodes) {
        return allNodes;
    }

    let filteredNodes = [];
    const startIndex =
        'procedure_start_node' in procedureContentVersion
            ? Math.max(
                  0,
                  allNodes.findIndex(
                      (node) =>
                          node.id ===
                          procedureContentVersion?.procedure_start_node
                  )
              )
            : 0;

    const endIndex =
        'procedure_end_node' in procedureContentVersion
            ? Math.max(
                  0,
                  allNodes.findIndex(
                      (node) =>
                          node.id === procedureContentVersion.procedure_end_node
                  )
              )
            : allNodes.length - 1;

    if (startIndex === endIndex) {
        // Same start and stop node
        filteredNodes = allNodes.slice(startIndex, endIndex + 1);
    } else if (startIndex < endIndex) {
        // Default case startNode is before endNode
        if (
            endIndex === allNodes.length - 1 ||
            startIndex === allNodes.length - 1
        ) {
            filteredNodes = allNodes.slice(startIndex, allNodes.length);
        } else {
            filteredNodes = allNodes.slice(startIndex, endIndex + 1);
        }
    } else {
        // Reverse case endNode is before startNode
        if (startIndex !== -1 && endIndex !== -1) {
            // if (endIndex === 0) {
            //   filteredNodes = [
            //     ...allNodes.slice(startIndex, allNodes.length),
            //     allNodes[0],
            //   ];
            // } else {
            filteredNodes = [
                ...allNodes.slice(startIndex, allNodes.length),
                ...allNodes.slice(0, endIndex + 1),
            ];
            // }
        }
    }
    return filteredNodes;
};
