import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    nodeButton: {
        color: (props) => `${props.colors.primarySectionForeground} !important`,
    },
    nodeTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: (props) => props.colors.primarySectionBackground,
        color: (props) => props.colors.primarySectionForeground,
        padding: '10px',
        width: '100%',
    },
    checkListContainer: {
        padding: '0px',
    },
    checkListContentContainer: {
        padding: '0 10px',
        [theme.breakpoints.down('xl')]: {
            padding: '0 10px',
        },
        [theme.breakpoints.down('lg')]: {
            padding: '0 5px',
        },
    },
    nodeContent: {
        width: '100%',
        lineHeight: '1.3',
        '& a': {
            position: 'relative',
            display: 'inline-block',
            padding: '10px',
            color: (props) => props.colors.link_text_color,
            borderRadius: '5px',
            '& > button': {
                pointerEvents: 'none',
            },
            '&:visited': {
                color: (props) => props.colors.link_visited_color,
            },
            '&:hover': {
                color: (props) => props.colors.link_hover_color,
            },
        },
        '& img': {
            maxWidth: '100%'
        },
        '& button': {
            backgroundColor: (props) =>
                props.colors.link_button_background_color,
            color: (props) => props.colors.link_button_text_color,
            border: '0px',
            padding: '10px 20px',
            borderRadius: '4px',
            fontSize: '14px',
            textTransform: 'uppercase',
            cursor: 'pointer',
            marginTop: '5px',
            '&:has(a)': {
                padding: '0',
            },
            '& > a': {
                padding: '10px 20px',
                color: 'inherit',
            },
            '&:disabled': {
                backgroundColor: (props) =>
                    props.colors.link_button_background_color,
                color: (props) => props.colors.link_button_text_color,
                filter: 'brightness(50%)',
            },
            '&:hover': {
                backgroundColor: (props) =>
                    props.colors.link_button_background_color,
                color: (props) => props.colors.link_button_text_color,
                filter: 'brightness(90%)',
            },
            position: 'relative',
        },
        '& .chaptersButton': {
            backgroundColor: (props) =>
                `${props.colors.chapter_button.background_color} !important`,
            color: (props) =>
                `${props.colors.chapter_button.text_color} !important`,
            '&:hover': {
                filter: 'brightness(90%)',
            },
            width: '100px',
            maxWidth: '100px',
            border: '1px solid #ffffff',
            cursor: 'pointer',
            padding: '10px',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '14px',
            borderRadius: '4px',
            '& .chaptersButtonText': {
                overflow: 'hidden',
                margin: 0,
                WebkitBoxOrient: 'vertical',
                display: '-webkit-box',
                wordWrap: 'break-word',
            },
            '&.chaptersActive': {
                backgroundColor: (props) =>
                    `${props.colors.chapter_button.active_color} !important`,
                color: (props) =>
                    `${props.colors.chapter_button.text_color} !important`,
                '&:hover': {
                    filter: 'brightness(90%)',
                },
            },
        },
        '& .activeChapterButton': {
            backgroundColor: (props) =>
                `${props.colors.chapter_button.active_color} !important`,
            color: (props) =>
                `${props.colors.chapter_button.text_color} !important`,
            '&:hover': {
                filter: 'brightness(90%)',
            },
        },
    },
    confirmationTitle: {
        fontSize: '28px',
        lineHeigth: '1.2',
        fontWeight: '600',
        color: '#2c3d4d',
        marginBottom: '30px',
        marginTop: '20px',
    },
    cancelButton: {
        backgroundColor: '#c03a2e',
        color: 'white',
        height: '40px',
        '&:hover': {
            backgroundColor: '#ff0000ab !important',
        },
    },
    confirmationButton: {
        backgroundColor: '#33a584',
        color: 'white',
        height: '40px',
        '&:hover': {
            backgroundColor: '#008000ab !important',
        },
    },
    QuestionImage: {
        height: 'auto',
        width: 'auto',
        maxWidth: '100%',
        marginBottom: '10px',
    },
    navButtons: {
        marginTop: '10px',
        textAlign: 'right',
        marginBottom: '10px !important',
    },
    selectedButton: {
        border: '3px solid #333',
    },
    embedContainer: {
        position: 'relative',
        width: '100%',
        marginBottom: '10px',
        paddingBottom: '56.25%',
        '& iframe': {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
        },
    },
}));

export { useStyles };
