import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ruleService } from '_features/_services';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';

//
const initialState = {
    rulesSlice: LoadingStatus.Idle,
    rules: [],
    laodedRulesMap: {},
};

export const getRuleById = createAsyncThunk(
    'rules/getRuleById',
    async (ruleId) => {
        const response = await ruleService.getById(ruleId);
        response.id = ruleId;
        return response;
    }
);

export const addNewRule = createAsyncThunk(
    'rules/addNewRule',
    async (newRule) => await ruleService.create(newRule)
);

export const editRule = createAsyncThunk(
    'rules/editRule',
    async (updates) =>
        await ruleService.update(updates.ruleId, updates.ruleUpdates)
);

export const deleteRuleById = createAsyncThunk(
    'rules/deleteRuleById',
    async (ruleId) => {
        const response = await ruleService.delete(ruleId);
        response.id = ruleId;
        return response;
    }
);

const rulesSlice = createSlice({
    name: 'rules',
    initialState,
    reducers: {},
    extraReducers: {
        [getRuleById.fulfilled]: (state, action) => {},
        [addNewRule.fulfilled]: (state, action) => {},
        [editRule.fulfilled]: (state, action) => {},
        [deleteRuleById.fulfilled]: (state, action) => {},
    },
});

// export const {  } = rulesSlice.actions

export default rulesSlice.reducer;
