import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        marginTop: '10px',
    },
}));
