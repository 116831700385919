import { makeStyles } from '@mui/styles';

const flyerStyles = makeStyles(() => ({
    flyer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'contain',
        },
    },
}));

export { flyerStyles };
