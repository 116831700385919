/**
 * This slice is to manage pick lists.
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    assetService,
    contentService,
    itemService,
    linkService,
    procedureService,
    zoneService,
} from '_features/_services';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';

const initialState = {
    assetsPicklist: {
        status: LoadingStatus.Idle,
        errorMessage: '',
        assets: [],
    },
    zonesPicklist: {
        status: LoadingStatus.Idle,
        errorMessage: '',
        zones: [],
    },
    itemsPicklist: {
        status: LoadingStatus.Idle,
        errorMessage: '',
        items: [],
    },
    linksPicklist: {
        status: LoadingStatus.Idle,
        errorMessage: '',
        links: [],
    },
    contentPicklist: {
        status: LoadingStatus.Idle,
        errorMessage: '',
        content: [],
    },
    proceduresPicklist: {
        status: LoadingStatus.Idle,
        errorMessage: '',
        procedures: [],
    },
    clientAssetsPicklist: {
        status: LoadingStatus.Idle,
        errorMessage: '',
        clientAssets: [],
    },
};

export const getAssetsPicklist = createAsyncThunk(
    'picklistsSlice/getAssetsList',
    async () => {
        return await assetService.getAssetsList();
    }
);

export const getClientAssetsPicklist = createAsyncThunk(
    'picklistsSlice/getClientAssetsList',
    async (clientId) => {
        return await assetService.getClientAssetsList(clientId);
    }
);

export const getZonesPicklist = createAsyncThunk(
    `picklistsSlice/getZonesPicklist`,
    async (params, { getState }) => {
        const response = await zoneService.getAll(
            params.searchString,
            params.assetId,
            0,
            0,
            'display_name',
            false,
            params.includeChildren,
            getState().accounts.activeUser.s3_prefix
        );
        return response;
    }
);

export const getItemsPicklist = createAsyncThunk(
    `picklistsSlice/getItemsPicklist`,
    async (params, { getState }) => {
        const response = await itemService.getAll(
            params.zoneId,
            getState().accounts.activeUser.s3_prefix
        );
        return response;
    }
);

export const getLinksPicklist = createAsyncThunk(
    'picklistsSlice/getLinksPicklist',
    async (params, { getState }) => {
        return await linkService.getAllItemLinksRange(
            0,
            0,
            params.itemId,
            params.linkTypeId,
            getState().accounts.activeUser.s3_prefix
        );
    }
);

export const getContentPicklist = createAsyncThunk(
    'picklistsSlice/fetchContentByTypeId',
    async (params, { getState }) =>
        await contentService.getAllContentByTypeIdRange(
            0,
            0,
            params.contentTypeIds,
            params.asset_id ?? null,
            params.searchString,
            params.sort ?? 'display_name',
            getState().accounts.activeUser.s3_prefix
        )
);

export const getProceduresPicklist = createAsyncThunk(
    'picklistsSlice/getProceduresPicklist',
    async () => {
        return await procedureService.getProceduresList();
    }
);

const picklistsSlice = createSlice({
    name: 'picklists',
    initialState,
    reducers: {
        resetAssetsPicklistStatus: (state) => {
            state.assetsPicklist.status = LoadingStatus.Idle;
            state.assetsPicklist.assets = [];
            state.assetsPicklist.errorMessage = '';
        },
        resetZonesPicklistStatus: (state) => {
            state.zonesPicklist.status = LoadingStatus.Idle;
            state.zonesPicklist.zones = [];
            state.zonesPicklist.errorMessage = '';
        },
        resetItemsPicklistStatus: (state) => {
            state.itemsPicklist.status = LoadingStatus.Idle;
            state.itemsPicklist.items = [];
            state.itemsPicklist.errorMessage = '';
        },
        resetLinksPicklistStatus: (state) => {
            state.linksPicklist.status = LoadingStatus.Idle;
            state.linksPicklist.links = [];
            state.linksPicklist.errorMessage = '';
        },
        resetContentPicklistStatus: (state) => {
            state.contentPicklist.status = LoadingStatus.Idle;
            state.contentPicklist.content = [];
            state.contentPicklist.errorMessage = '';
        },
        resetProceduresPicklistStatus: (state) => {
            state.proceduresPicklist.status = LoadingStatus.Idle;
            state.proceduresPicklist.procedures = [];
            state.proceduresPicklist.errorMessage = '';
        },
    },
    extraReducers: {
        [getAssetsPicklist.pending]: (state, action) => {
            state.assetsPicklist.status = LoadingStatus.Pending;
        },
        [getAssetsPicklist.fulfilled]: (state, action) => {
            state.assetsPicklist.assets = action.payload;
            state.assetsPicklist.status = LoadingStatus.Loaded;
        },
        [getAssetsPicklist.rejected]: (state, action) => {
            state.assetsPicklist.status = LoadingStatus.Failed;
            state.assetsPicklist.errorMessage = action.error.message;
        },
        [getClientAssetsPicklist.pending]: (state, action) => {
            state.clientAssetsPicklist.status = LoadingStatus.Pending;
        },
        [getClientAssetsPicklist.fulfilled]: (state, action) => {
            state.clientAssetsPicklist.clientAssets = action.payload;
            state.clientAssetsPicklist.status = LoadingStatus.Loaded;
        },
        [getClientAssetsPicklist.rejected]: (state, action) => {
            state.clientAssetsPicklist.clientAssets = [];
            state.clientAssetsPicklist.status = LoadingStatus.Failed;
            state.clientAssetsPicklist.errorMessage = action.error.message;
        },
        [getZonesPicklist.pending]: (state, action) => {
            state.zonesPicklist.status = LoadingStatus.Pending;
        },
        [getZonesPicklist.fulfilled]: (state, action) => {
            state.zonesPicklist.zones = action.payload.zones;
            state.zonesPicklist.status = LoadingStatus.Loaded;
        },
        [getZonesPicklist.rejected]: (state, action) => {
            state.zonesPicklist.status = LoadingStatus.Failed;
            state.zonesPicklist.errorMessage = action.error.message;
        },
        [getItemsPicklist.pending]: (state, action) => {
            state.itemsPicklist.status = LoadingStatus.Pending;
        },
        [getItemsPicklist.fulfilled]: (state, action) => {
            state.itemsPicklist.items = action.payload.items;
            state.itemsPicklist.status = LoadingStatus.Loaded;
        },
        [getItemsPicklist.rejected]: (state, action) => {
            state.itemsPicklist.status = LoadingStatus.Failed;
            state.itemsPicklist.errorMessage = action.error.message;
        },
        [getLinksPicklist.pending]: (state, action) => {
            state.linksPicklist.status = LoadingStatus.Pending;
        },
        [getLinksPicklist.fulfilled]: (state, action) => {
            state.linksPicklist.links = action.payload.itemLinks;
            state.linksPicklist.status = LoadingStatus.Loaded;
        },
        [getLinksPicklist.rejected]: (state, action) => {
            state.linksPicklist.status = LoadingStatus.Failed;
            state.linksPicklist.errorMessage = action.error.message;
        },
        [getContentPicklist.pending]: (state, action) => {
            state.contentPicklist.status = LoadingStatus.Pending;
        },
        [getContentPicklist.fulfilled]: (state, action) => {
            state.contentPicklist.content = action.payload;
            state.contentPicklist.status = LoadingStatus.Loaded;
        },
        [getContentPicklist.rejected]: (state, action) => {
            state.contentPicklist.status = LoadingStatus.Failed;
            state.contentPicklist.errorMessage = action.error.message;
        },
        [getProceduresPicklist.pending]: (state, action) => {
            state.proceduresPicklist.status = LoadingStatus.Pending;
        },
        [getProceduresPicklist.fulfilled]: (state, action) => {
            state.proceduresPicklist.procedures = action.payload;
            state.proceduresPicklist.status = LoadingStatus.Loaded;
        },
        [getProceduresPicklist.rejected]: (state, action) => {
            state.proceduresPicklist.status = LoadingStatus.Failed;
            state.proceduresPicklist.errorMessage = action.error.message;
        },
    },
});

export const selectAssetsPicklistLoadingStatus = (state) =>
    state.picklists.assetsPicklist.status;
export const selectAssetsPicklist = (state) =>
    state.picklists.assetsPicklist.assets;

export const selectZonesPicklistLoadingStatus = (state) =>
    state.picklists.zonesPicklist.status;
export const selectZonesPicklist = (state) =>
    state.picklists.zonesPicklist.zones;

export const selectItemsPicklistLoadingStatus = (state) =>
    state.picklists.itemsPicklist.status;
export const selectItemsPicklist = (state) =>
    state.picklists.itemsPicklist.items;

export const selectLinksPicklistLoadingStatus = (state) =>
    state.picklists.linksPicklist.status;
export const selectLinksPicklist = (state) =>
    state.picklists.linksPicklist.links;

export const selectContentPicklistLoadingStatus = (state) =>
    state.picklists.contentPicklist.status;
export const selectContentPicklist = (state) =>
    state.picklists.contentPicklist.content;

export const selectProceduresPicklistLoadingStatus = (state) =>
    state.picklists.proceduresPicklist.status;
export const selectProceduresPicklist = (state) => {
    return state.picklists.proceduresPicklist.procedures;
};

export const selectClientAssetsPicklistLoadingStatus = (state) =>
    state.picklists.clientAssetsPicklist.status;
export const selectClientAssetsPicklist = (state) =>
    state.picklists.clientAssetsPicklist.clientAssets;

export const {
    resetAssetsPicklistStatus,
    resetZonesPicklistStatus,
    resetItemsPicklistStatus,
    resetLinksPicklistStatus,
    resetContentPicklistStatus,
    resetProceduresPicklistStatus,
} = picklistsSlice.actions;

export default picklistsSlice.reducer;
