import React from 'react';

export const FieldSet = (props) => {
    const { title, children } = props;
    return (
        <fieldset
            style={{
                border: '1px solid #cccccc',
                padding: '5px',
                borderRadius: '5px',
            }}
        >
            <legend
                style={{
                    marginLeft: '10px',
                    padding: '3px',
                    fontSize: '14px',
                    color: '#6d7178',
                }}
            >
                {title}
            </legend>
            {children}
        </fieldset>
    );
};
