import React, { useCallback } from 'react';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { CheckboxWithLabel } from 'formik-mui';
import { useDispatch, useSelector } from 'react-redux';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import {
    Autocomplete,
    Box,
    Checkbox,
    FormControl,
    TextField,
} from '@mui/material';

import { OutputFields } from '_helpers';
import { makeFormStyles } from '../styles';
import SetDirtyForm from 'forms/SetDirtyForm';
import { FormTextField } from 'components/TextField';
import { closePanel } from '_features/common/formSlice';
import { exportContent } from '_features/content/contentSlice';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { MultiSelectDoneButton } from 'GeminiViewerComponent/components/MultiSelectDoneButton';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { sortByCaseInsensitiveText } from 'GeminiViewerComponent/_helpers/lodashUtils';

const INIT_VALUES = {
    output_fields: [],
    apply_active_filter: false,
    include_header: false,
    include_unused: false,
};

const ContentInventoryForm = ({ formAction, contentPayload }) => {
    //#region Constants
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    //#endregion State

    //#region Selectors
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    //#endregion Effects

    //#region Methods
    const handleSubmit = useCallback(
        async (values, setSubmitting, resetForm, setErrors) => {
            let exportFilters = {
                include_header: values.include_header,
                include_unused: values.include_unused,
            };
            if (values.apply_active_filter) {
                exportFilters = { ...exportFilters, ...contentPayload };
            }
            if (values.output_fields && values.output_fields.length > 0) {
                exportFilters = {
                    ...exportFilters,
                    output_fields: values.output_fields
                        .map((field) => field.value)
                        .join(','),
                };
            }

            dispatch(activateLoading());
            try {
                const resultAction = await dispatch(
                    exportContent(exportFilters)
                );
                if (!resultAction.payload?.error && !resultAction?.error) {
                    dispatch(closePanel({ formKey: 'contentInventoryForm' }));
                } else {
                    if (resultAction?.payload?.error?.errors) {
                        setErrors({ ...resultAction?.payload?.error?.errors });
                    } else if (resultAction?.error?.message) {
                        throw new Error(resultAction.error.message);
                    } else {
                        throw new Error(resultAction?.payload?.error);
                    }
                }
            } catch (err) {
                enqueueSnackbar(
                    err?.message !== undefined ? err.message : 'Create Failed',
                    {
                        action: (key) => <SnackbarDismiss key={key} />,
                        variant: 'error',
                    }
                );
            }
            dispatch(deactivateLoading());
            setSubmitting(false);
        },
        [contentPayload]
    );

    //#endregion Methods

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...INIT_VALUES }}
            onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
                handleSubmit(values, setSubmitting, resetForm, setErrors);
            }}
        >
            {({ values, isSubmitting, setFieldValue }) => (
                <Form className={classes.form}>
                    <SetDirtyForm />
                    <Box className={classes.formHeader}>
                        Download CSV content inventory
                    </Box>
                    <FormControl margin="dense">
                        <Autocomplete
                            fullWidth
                            limitTags={2}
                            multiple
                            variant="outline"
                            id="output_fields"
                            name="output_fields"
                            value={values.output_fields}
                            options={[
                                '',
                                ...OutputFields.sort(
                                    sortByCaseInsensitiveText('name')
                                ),
                            ]}
                            onChange={(event, newInputValue) => {
                                if (newInputValue.includes('')) {
                                    if (
                                        newInputValue.filter(Boolean).length ===
                                        OutputFields.length
                                    ) {
                                        setFieldValue('output_fields', []);
                                    } else {
                                        setFieldValue(
                                            'output_fields',
                                            OutputFields
                                        );
                                    }
                                } else {
                                    setFieldValue(
                                        'output_fields',
                                        newInputValue
                                    );
                                }
                            }}
                            PaperComponent={MultiSelectDoneButton}
                            getOptionLabel={(option) => option.name || ''}
                            disableCloseOnSelect
                            renderOption={(props, option, { selected }) => (
                                <li
                                    {...props}
                                    key={props['data-option-index']}
                                    style={{
                                        padding: '0',
                                        borderBottom: `${
                                            !option && '1px solid #4a4a4a'
                                        }`,
                                    }}
                                >
                                    <Checkbox
                                        icon={
                                            <MdCheckBoxOutlineBlank className="react-icon" />
                                        }
                                        checkedIcon={
                                            <MdCheckBox className="react-icon" />
                                        }
                                        style={{
                                            marginRight: 8,
                                        }}
                                        indeterminate={
                                            !option &&
                                            values.output_fields.length > 0 &&
                                            values.output_fields.length !==
                                                OutputFields.length
                                        }
                                        checked={
                                            selected ||
                                            values.output_fields.length ===
                                                OutputFields.length
                                        }
                                    />
                                    {option.name}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Output Fields"
                                    placeholder="Output Fields"
                                />
                            )}
                        />
                    </FormControl>
                    <FormTextField
                        component={CheckboxWithLabel}
                        Label={{
                            label: 'Apply Active Filter',
                        }}
                        label="Apply Active Filter"
                        type="checkbox"
                        checked={!!values.apply_active_filter}
                        onChange={(evt) => {
                            setFieldValue(
                                'apply_active_filter',
                                evt.target.checked
                            );
                        }}
                    />
                    <FormTextField
                        component={CheckboxWithLabel}
                        Label={{
                            label: 'Include Header',
                        }}
                        label="Include Header"
                        type="checkbox"
                        checked={!!values.include_header}
                        onChange={(evt) => {
                            setFieldValue('include_header', evt.target.checked);
                        }}
                    />
                    <FormTextField
                        component={CheckboxWithLabel}
                        Label={{
                            label: 'Include Unused',
                        }}
                        label="Include Unused"
                        type="checkbox"
                        checked={!!values.include_unused}
                        onChange={(evt) => {
                            setFieldValue('include_unused', evt.target.checked);
                        }}
                    />
                    <button
                        className={classes.submit}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        Submit
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export { ContentInventoryForm };
