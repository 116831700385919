import { makeStyles } from '@mui/styles';

const sceneButtonsStyles = makeStyles(() => ({
    sceneButtons: {
        display: 'flex',
        height: '33px',
        backgroundColor: 'rgba(255,255,255,0.667)',
        padding: '3px',
        margin: '3px',
        borderRadius: '4px',
        gap: '6px',
    },
    sceneButton: {
        height: '100%',
    },
    backIcon: {
        height: '26px',
    },
    sceneImg: {
        height: '100%',
    },
}));

export { sceneButtonsStyles };
