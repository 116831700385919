import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { FormActions } from '_helpers/form-action';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';
import { makeFormStyles } from '../styles';

import {
    fetchContentById,
    editContentById,
    createContent,
    resetContentStatus,
} from '_features/content/contentSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { selectActiveAsset } from 'GeminiViewerComponent/_features/asset/assetSlice';
import SetDirtyForm from 'forms/SetDirtyForm';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';
import { FormSwitchField, FormTextField } from 'components/TextField';

const EmbedForm = ({
    formAction,
    contentId,
    linkTypeId,
    tabView,
    setToggleView,
}) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    const asset = useSelector(selectActiveAsset);
    const [loaded, setLoaded] = useState(
        formAction.id === FormActions.Create.id ? true : false
    );
    const init = {
        display_name: '',
        global: true,
        external_id: '',
        embed_data: '',
        link_type_id: linkTypeId ?? '',
        content_data: null,
        url: '',
        content_type_id: contentId,
    };
    const [initValues, setInitValues] = useState({ ...init });

    useEffect(() => {
        if (formAction.id === FormActions.Edit.id) {
            const fetchData = async () => {
                const objectData = await dispatch(
                    fetchContentById({ contentId: contentId })
                );
                const fetchedObject = objectData.payload;

                setInitValues({
                    display_name: fetchedObject.display_name,
                    global: !fetchedObject.asset_id,
                    external_id: fetchedObject.external_id,
                    additional_data: fetchedObject.additional_data ?? '',
                    content_id: fetchedObject.content_id,
                });
                setLoaded(true);
            };
            fetchData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (values, setSubmitting) => {
        dispatch(activateLoading({ showProgress: true }));
        var resultAction = null;
        var updatedValues = null;

        switch (formAction.id) {
            case FormActions.Edit.id:
                updatedValues = { ...values };
                delete updatedValues.global;
                if (values.global) {
                    delete updatedValues.asset_id;
                } else {
                    updatedValues.asset_id = asset.asset_id;
                }

                resultAction = await dispatch(
                    editContentById({ ...updatedValues, content_id: contentId })
                );
                break;
            case FormActions.Create.id: {
                updatedValues = { ...values };
                delete updatedValues.global;
                if (values.global) {
                    delete updatedValues.asset_id;
                } else {
                    updatedValues.asset_id = asset.asset_id;
                }

                resultAction = await dispatch(
                    createContent({
                        ...updatedValues,
                        content_version: updatedValues,
                    })
                );
                if (!resultAction.error) {
                    setInitValues({ ...init });
                }
                dispatch(resetContentStatus());
                break;
            }
            default:
                break;
        }

        dispatch(deactivateLoading());
        const { message, variant } = snackbarHandler(
            resultAction.meta.requestStatus,
            formAction.label
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });

        setSubmitting(false);
    };

    return !loaded ? (
        <LoadingLogo styleClassName={classes.loadingSvg} />
    ) : (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            validationSchema={Yup.object({
                display_name: Yup.string()
                    .max(150, 'Must be 150 characters or less')
                    .required('Required'),
                global: Yup.boolean(),
                external_id: Yup.string(),
                embed_data: Yup.string().required('Required'),
            })}
            onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values, setSubmitting);
            }}
        >
            {({ values, setFieldValue, isSubmitting, errors, touched }) => (
                <Form className={classes.form}>
                    <FormSwitchField
                        label="Visible to all assets"
                        checked={values.global}
                        onChange={(e) => {
                            setFieldValue('global', e.target.checked, false);
                        }}
                        name="global"
                    />
                    <FormTextField
                        label="Content Name"
                        name="display_name"
                        placeholder="Content Name"
                    />
                    <Field
                        margin="dense"
                        as="textarea"
                        variant="outlined"
                        placeholder="Embed Data"
                        className={`${classes.materialTextarea} ${
                            errors.embed_data &&
                            touched.embed_data &&
                            classes.formErrorField
                        }`}
                        name="embed_data"
                        rows={5}
                        style={{ marginTop: '10px' }}
                    />
                    <div className={classes.formErrorDiv}>
                        <ErrorMessage name="embed_data" />
                    </div>
                    <SetDirtyForm />
                    <button
                        className={classes.submit}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        Submit
                    </button>
                    {tabView && (
                        <button
                            type="button"
                            className={classes.cancel}
                            onClick={() => setToggleView(false)}
                        >
                            Cancel
                        </button>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export { EmbedForm };
