import {
    Dialog,
    DialogTitle,
    DialogContent,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Box,
    IconButton,
} from '@mui/material';
import { IoOpenOutline } from 'react-icons/io5';

import { employeeStyle } from './style';
import { MdClose, MdExpandMore } from 'react-icons/md';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { useSelector } from 'react-redux';
// import { theme } from 'theme';

const ReleaseNotesModal = ({
    isReleaseNotesOpen,
    setIsReleaseNotesOpen,
    releaseNotesPdf,
}) => {
    //#region Constants
    const theme = useSelector(selectActiveTheme);
    const classes = employeeStyle(theme);
    // const theme = mdTheme;
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    //#endregion State

    //#region Selectors
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    //#endregion Effects

    //#region Methods
    const handleClose = () => {
        setIsReleaseNotesOpen(false);
    };

    const handleOpenPdf = (url, event) => {
        event.stopPropagation();
        window.open(url, '_blank');
    };

    const sortedReleaseNotes = releaseNotesPdf.slice().sort((a, b) => {
        const regex = /(\d{4}-\d{2}-\d{2})/;
        const dateA = a.version.match(regex)[1];
        const dateB = b.version.match(regex)[1];
        return new Date(dateB) - new Date(dateA);
    });
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render

    return (
        <Dialog
            aria-labelledby="patch-notes-dialog"
            maxWidth="lg"
            open={isReleaseNotesOpen}
            onClose={handleClose}
            sx={{ width: '100%' }}
            fullWidth
        >
            <DialogTitle>
                All Release Notes{' '}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 6,
                        color: '#000',
                        fontSize: '1.4rem',
                    }}
                    size="large"
                >
                    <MdClose />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ minHeight: '600px' }}>
                {sortedReleaseNotes.length > 0 &&
                    sortedReleaseNotes?.map((patchNote, index) => (
                        <Accordion
                            key={index}
                            className={classes.cardWrapper}
                            defaultExpanded={index === 0}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <MdExpandMore
                                        className={classes.expandIcon}
                                    />
                                }
                                aria-controls={`panel-${index}-content`}
                                id={`panel-${index}-header`}
                                sx={{
                                    backgroundColor: theme.colors.body,
                                    color: theme.colors.text,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        paddingRight: '0.5rem',
                                    }}
                                >
                                    <Typography>
                                        {patchNote?.version}
                                    </Typography>
                                    <IoOpenOutline
                                        onClick={(event) =>
                                            handleOpenPdf(patchNote?.url, event)
                                        }
                                        style={{ cursor: 'pointer' }}
                                    />
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box
                                    component="iframe"
                                    src={patchNote?.url}
                                    width="100%"
                                    height="500px"
                                />
                            </AccordionDetails>
                        </Accordion>
                    ))}
            </DialogContent>
        </Dialog>
    );
    //#endregion Render
};
export { ReleaseNotesModal };
