import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/styles';

export const HtmlTooltip = styled(
    ({ className, direction = 'right', ...props }) => (
        <Tooltip
            {...props}
            classes={{ popper: className }}
            placement={
                direction === 'Top'
                    ? 'bottom'
                    : direction === 'Bottom'
                    ? 'top'
                    : direction === 'Left'
                    ? 'right'
                    : 'left'
            }
        />
    )
)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: '12px',
        border: '1px solid #dadde9',
    },
    [`& .${tooltipClasses.arrow}::before`]: {
        backgroundColor: '#f5f5f9',
        border: '1px solid #dadde9',
    },
}));
