import React, { useEffect } from 'react';
import { useStyles } from './styles';

let isResizing = null;
const minPanelSize = 40;
const maxPanelSize = 75;

const PanelResize = ({ nextPanelRef, previousPanelRef }) => {
    const classes = useStyles();
    const dragger = React.useRef('dragger');
    const virtualDragger = React.useRef('virtualDragger');
    const cbHandleMouseMove = React.useCallback(handleMousemove, []); // eslint-disable-line react-hooks/exhaustive-deps
    const cbHandleMouseUp = React.useCallback(handleMouseup, []); // eslint-disable-line react-hooks/exhaustive-deps
    const cbHandleMouseEnd = React.useCallback(handleMouseend, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (previousPanelRef.current?.style) {
            let percent = virtualDragger.current ? 50 : 100;
            previousPanelRef.current.style.width = percent + '%';
            if (nextPanelRef.current?.style) {
                nextPanelRef.current.style.width = 100 - percent + '%';
            }
            if (virtualDragger.current?.style) {
                virtualDragger.current.style.left = percent + '%';
            }
            if (dragger.current?.style) {
                dragger.current.style.left = percent + '%';
            }
            window.dispatchEvent(new Event('resize'));

            if (virtualDragger.current?.style) {
                virtualDragger.current.style.left =
                    previousPanelRef.current.style.width;
            }
            if (dragger.current?.style) {
                dragger.current.style.left =
                    previousPanelRef.current.style.width;
            }
        }
    }, []);

    function handleMousedown(e) {
        e.stopPropagation();
        e.preventDefault();
        document.addEventListener('pointermove', cbHandleMouseMove);
        document.addEventListener('pointerup', cbHandleMouseUp);
        document.addEventListener('pointerend', cbHandleMouseEnd);
        isResizing = true;
    }

    function handleMousemove(e) {
        if (!isResizing || !dragger.current) {
            return;
        }
        let panelPercent = 100 - (e.clientX / document.body.offsetWidth) * 100;
        panelPercent = Math.min(
            Math.max(panelPercent, minPanelSize),
            maxPanelSize
        );
        dragger.current.style.left = 100 - panelPercent + '%';
    }

    function handleMouseup(e) {
        dragCleanup(e);
    }

    function handleMouseend(e) {
        dragCleanup(e);
    }

    function dragCleanup(e) {
        if (isResizing) {
            let panelPercent =
                100 - (e.clientX / document.body.offsetWidth) * 100;
            panelPercent = Math.min(
                Math.max(panelPercent, minPanelSize),
                maxPanelSize
            );
            if (previousPanelRef.current) {
                previousPanelRef.current.style.width =
                    100 - panelPercent + 'vw';
            }
            nextPanelRef.current.style.width = panelPercent + 'vw';
            virtualDragger.current.style.left = 100 - panelPercent + '%';
            dragger.current.style.left = 100 - panelPercent + '%';
            isResizing = false;
            window.dispatchEvent(new Event('resize'));
        }
        document.removeEventListener('pointermove', cbHandleMouseMove);
        document.removeEventListener('pointerup', cbHandleMouseUp);
        document.removeEventListener('pointerend', cbHandleMouseEnd);
    }

    return (
        <div className={classes.draggerParent} style={{ position: 'absolute' }}>
            <div
                className={classes.virtualDragger}
                ref={virtualDragger}
                onPointerDown={handleMousedown}
            />
            <div className={classes.dragger} ref={dragger} />
        </div>
    );
};

export { PanelResize };
