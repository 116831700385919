import React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { tableStyles } from 'components/_Tables/TableCells/styles';
import {
    labelCell,
    labelHeader,
    viewLink,
    ExistingContentTypeCell,
} from 'components/_Tables/TableCells';
import { AutoSizer } from 'react-virtualized';
import { VirtualTable } from 'GeminiViewerComponent/components/_Tables/VirtualTable';
import {
    fetchContentByTypeId,
    getContentStatus,
    selectContentById,
} from '_features/content/contentSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';

const SelectExistingContentTable = ({
    content,
    pageInfo,
    contentPayload,
    sortDirection,
    sortBy,
    handleSort,
    handleRowClick,
}) => {
    const dispatch = useDispatch();
    const tableClasses = tableStyles();
    const allRows = useSelector(selectContentById);
    const contentStatus = useSelector(getContentStatus);
    const loadedRowsMap = useSelector(
        (state) => state.content.contentLoadedRowsMap
    );
    const headerHeight = 40;
    const rowHeight = 60;

    const columns = [
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Name',
            dataKey: 'display_name',
            padding: 'normal',
            component: labelCell,
            headerComponent: labelHeader,
            onClick: handleRowClick,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            flexGrow: 1,
            width: 120,
            minWidth: 120,
            label: 'Type',
            dataKey: 'content_type_id',
            padding: 'normal',
            component: ExistingContentTypeCell,
            headerComponent: labelHeader,
            onClick: handleRowClick,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            minWidth: 60,
            maxWidth: 60,
            width: 60,
            label: '',
            dataKey: 'content_id',
            padding: 'none',
            dispatch: dispatch,
            component: viewLink,
            headerComponent: labelHeader,
        },
    ];

    async function loadMoreRows({ startIndex, stopIndex }) {
        if (contentStatus !== LoadingStatus.Loading) {
            await dispatch(
                fetchContentByTypeId({
                    ...contentPayload,
                    reset: false,
                    startIndex: startIndex,
                    stopIndex: stopIndex,
                    sort: `${sortDirection === 'ASC' ? '' : '-'}${
                        sortBy === 'content_type_id'
                            ? 'content_type_name'
                            : sortBy
                    }`,
                })
            );
        }
    }

    return (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    height: 'calc(100vh - 400px)',
                    minWidth: '500px',
                    marginTop: '25px',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                }}
            >
                <AutoSizer>
                    {({ width, height }) => (
                        <VirtualTable
                            initialRows={content}
                            allRows={allRows}
                            totalRowCount={pageInfo.TotalCount}
                            loadedRowsMap={loadedRowsMap}
                            loadMoreRows={loadMoreRows}
                            columnDefinitions={columns}
                            cellClassName={tableClasses.flexContainer}
                            className={tableClasses.table}
                            rowClassName={clsx(
                                tableClasses.flexContainer,
                                tableClasses.tableRowHover
                            )}
                            headerHeight={headerHeight}
                            width={width}
                            height={height}
                            rowHeight={rowHeight}
                        />
                    )}
                </AutoSizer>
            </div>
        </div>
    );
};

export { SelectExistingContentTable };
