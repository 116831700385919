import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { MdAdd } from 'react-icons/md';
import { makeFormStyles } from 'forms/styles';
import { CheckboxWithLabel } from 'formik-mui';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    TextField as MuiText,
} from '@mui/material';

import DirtyProcedure from '../DirtyProcedure';
import { FormTextField } from 'components/TextField';
import SaveNodeDataDialog from '../SaveNodeDataDialog';
import { setProcedureDirty } from '_features/procedures/proceduresSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    CommonNodeFormFIelds,
    ExcludeFromNavigationField,
    NodeCategoryField,
} from '../CommonNodeFormFIelds';

const makeNodeFormStyles = makeStyles((theme) => ({
    container: {
        padding: '20px',
        width: '100%',
        height: '100%',
    },
    form: {
        display: 'flex',
        width: '100%',
        height: 'auto',
        padding: '10px',
        border: '1px solid',
    },
    formBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        width: '100%',
    },
    submitButtonRow: {
        marginTop: 'auto',
        alignSelf: 'end',
        display: 'flex',
        gap: '5px',
    },
    contentRow: {
        cursor: 'pointer',
    },
    dialogPaper: {
        minHeight: '100%',
        maxHeight: '100%',
    },
    addButtonIcon: {
        padding: '3px !important',
        borderRadius: '25px !important',
        background: (props) =>
            `${props.colors.button.primaryBackground} !important`,
        color: (props) => `${props.colors.button.primaryForeground} !important`,
    },
}));

const EmailActionForm = ({
    nodes,
    node,
    onCancel,
    onSubmit,
    openSaveDataDialog,
    setOpenSaveDataDialog,
    procedureCategories,
}) => {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const nodeFormStyles = makeNodeFormStyles(theme);
    const commonFormStyles = makeFormStyles(theme);

    const [formValues, setFormValues] = useState({
        ...node,
        emails: node?.emails ? node.emails : [],
        show_email_sent_dialog: node?.show_email_sent_dialog
            ? !!node?.show_email_sent_dialog
            : false,
        proceed_next_even_email_fails: node?.proceed_next_even_email_fails
            ? !!node?.proceed_next_even_email_fails
            : false,
    });

    nodes = nodes.filter((nd) => nd.id !== node.id);
    useEffect(() => {
        setFormValues({
            exclude_from_navigation: true,
            ...node,
            emails: node?.emails ? node.emails : [],
            show_email_sent_dialog: node?.show_email_sent_dialog
                ? !!node?.show_email_sent_dialog
                : false,
            proceed_next_even_email_fails: node?.proceed_next_even_email_fails
                ? !!node?.proceed_next_even_email_fails
                : false,
            procedureCategories: procedureCategories,
        });
    }, [node]);

    useEffect(() => {
        return () => {
            setFormValues({});
            dispatch(setProcedureDirty(false));
        };
    }, []);

    return (
        <div className={nodeFormStyles.container}>
            <div className={nodeFormStyles.form}>
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validate={(values) => {
                        const errors = {};
                        return errors;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        onSubmit(values);
                    }}
                >
                    {({ values, setFieldValue, isSubmitting, dirty }) => (
                        <Form className={nodeFormStyles.formBody}>
                            <DirtyProcedure />
                            <CommonNodeFormFIelds />
                            <NodeCategoryField />
                            <ExcludeFromNavigationField />
                            <FormTextField
                                component={CheckboxWithLabel}
                                Label={{ label: 'Show email sent dialog' }}
                                label="Show email sent dialog"
                                type="checkbox"
                                checked={!!values?.show_email_sent_dialog}
                                onChange={(e) => {
                                    setFieldValue(
                                        'show_email_sent_dialog',
                                        e.target.checked
                                    );
                                }}
                            />
                            <FormTextField
                                component={CheckboxWithLabel}
                                Label={{
                                    label: 'Allow proceeding to next node even though email fails.',
                                }}
                                label="Allow proceeding to next node even though email fails."
                                type="checkbox"
                                checked={
                                    !!values?.proceed_next_even_email_fails
                                }
                                onChange={(e) => {
                                    setFieldValue(
                                        'proceed_next_even_email_fails',
                                        e.target.checked
                                    );
                                }}
                            />
                            <FormControl fullWidth>
                                <fieldset
                                    style={{
                                        border: '1px solid #cccccc',
                                        borderRadius: '5px',
                                        paddingLeft: '15px',
                                    }}
                                >
                                    <legend
                                        style={{
                                            padding: '3px',
                                            fontSize: '14px',
                                            color: '#6d7178',
                                        }}
                                    >
                                        Emails
                                    </legend>
                                    <div
                                        style={{
                                            margin: '5px auto',
                                        }}
                                    >
                                        <Tooltip title="Add Email">
                                            <IconButton
                                                className={
                                                    nodeFormStyles.addButtonIcon
                                                }
                                                onClick={() => {
                                                    let emails =
                                                        Array.isArray(
                                                            values?.emails
                                                        ) &&
                                                        values?.emails?.length >
                                                            0
                                                            ? [...values.emails]
                                                            : [];
                                                    emails[emails.length] = '';
                                                    setFieldValue(
                                                        'emails',
                                                        emails
                                                    );
                                                }}
                                                size="small"
                                            >
                                                <MdAdd className="react-icon" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    {Array.isArray(values?.emails) &&
                                        values?.emails?.length > 0 &&
                                        values?.emails?.map((title, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    style={{
                                                        margin: '5px auto',
                                                    }}
                                                >
                                                    <Grid
                                                        container
                                                        style={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            gap: '10px',
                                                            marginBottom: '6px',
                                                        }}
                                                    >
                                                        <Grid
                                                            display={'flex'}
                                                            alignItems="center"
                                                            justifyContent={
                                                                'center'
                                                            }
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={10}
                                                            lg={10}
                                                        >
                                                            <MuiText
                                                                fullWidth
                                                                margin="dense"
                                                                value={title}
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    let emails =
                                                                        [
                                                                            ...(values?.emails ??
                                                                                ''),
                                                                        ];
                                                                    emails[
                                                                        index
                                                                    ] =
                                                                        event.target.value;
                                                                    setFieldValue(
                                                                        'emails',
                                                                        emails
                                                                    );
                                                                }}
                                                                label={`Email ${
                                                                    index + 1
                                                                }`}
                                                                type="text"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            display={'flex'}
                                                            alignItems="center"
                                                            justifyContent={
                                                                'center'
                                                            }
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={1}
                                                            lg={1}
                                                        >
                                                            <Button
                                                                variant="outlined"
                                                                onClick={() => {
                                                                    let emails =
                                                                        [
                                                                            ...(values?.emails ??
                                                                                []),
                                                                        ];
                                                                    emails.splice(
                                                                        index,
                                                                        1
                                                                    );
                                                                    setFieldValue(
                                                                        'emails',
                                                                        emails
                                                                    );
                                                                }}
                                                            >
                                                                Remove
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            );
                                        })}
                                </fieldset>
                            </FormControl>
                            <FormControl
                                fullWidth
                                style={{
                                    marginTop: '10px',
                                }}
                            >
                                <InputLabel id="target-node-id">
                                    Target Node
                                </InputLabel>
                                <Select
                                    className={commonFormStyles.materialSelect}
                                    style={{
                                        margin: '0',
                                    }}
                                    labelId="target-node-id"
                                    margin="dense"
                                    id="target_node_id"
                                    label="Target Node"
                                    placeholder="Target Node"
                                    name={`target_node_id`}
                                    value={values?.action?.id}
                                    onChange={(e) => {
                                        setFieldValue('action', {
                                            id: e.target.value,
                                            type: 'node',
                                        });
                                    }}
                                >
                                    <MenuItem key={'none'} value={''}>
                                        None
                                    </MenuItem>
                                    <MenuItem key={'next'} value={'next'}>
                                        Next
                                    </MenuItem>
                                    <MenuItem key={'back'} value={'back'}>
                                        Back
                                    </MenuItem>
                                    {nodes?.length > 0 &&
                                        nodes.map((nd) => (
                                            <MenuItem
                                                key={nd?.id}
                                                value={nd?.id}
                                            >
                                                {nd?.title} - #{nd?.id}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            {openSaveDataDialog && (
                                <SaveNodeDataDialog
                                    openSaveDataDialog={openSaveDataDialog}
                                    setOpenSaveDataDialog={
                                        setOpenSaveDataDialog
                                    }
                                    isSubmitting={isSubmitting}
                                    onCancel={onCancel}
                                />
                            )}
                            <div className={nodeFormStyles.submitButtonRow}>
                                <button
                                    className={commonFormStyles.cancel}
                                    type="button"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        if (dirty) {
                                            setOpenSaveDataDialog(true);
                                        } else {
                                            onCancel();
                                        }
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={commonFormStyles.submit}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default EmailActionForm;
