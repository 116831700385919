import React, { useEffect, useState } from 'react';
import {
    MdAccountTree,
    MdAdd,
    MdContentCopy,
    MdContentPaste,
    MdDelete,
    MdDragHandle,
    MdEdit,
    MdHome,
    MdMoreVert,
    MdPreview,
} from 'react-icons/md';
import {
    Checkbox,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    IconButton,
    Popover,
    Divider,
    Box,
} from '@mui/material';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { makeProcedureStyles } from '../styles';
import { useStyles } from 'GeminiViewerComponent/components/Procedure/components/ProcedureQuestions/styles';
import { customTinyMce } from 'GeminiViewerComponent/components/Procedure/styles';
import { NodeTypeIcons } from '_helpers';
import { ProcedureNodeTypes } from 'GeminiViewerComponent/components/Procedure/ProcedureNodeTypes';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { makeNodeManagerStyles } from './NodeManagerOptions/style';
import { getProcedureDirty } from '_features/procedures/proceduresSlice';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';

const computeTargetNodeFromAction = (node, action, nodes) => {
    // Compute the potential next node. This is intened to be used
    // for previwing the node flow. We can only know the next node for
    // actions that move forward in the flow. For actions the move back or
    // return to previous nodes, we can't know the actual node without the
    // user navigating the node tree

    if (action?.type === 'sub') {
        return `Branch #${action.id}`;
    }
    switch (action?.id) {
        case 'submit':
        case 'next': {
            // Find next in the current
            const index = nodes.findIndex((item) => item.id === node.id);
            if (index !== -1 && index < nodes.length - 1) {
                return `#${nodes[index + 1].id}`;
            } else {
                return '';
            }
        }
        case 'back':
            // Don't know for sure the previous node since
            // user isn't actually navigating the tree
            return 'Back';
        case 'previous':
            // Don't know for sure the previous node since
            // user isn't actually navigating the tree
            return 'Previous';
        default:
            if (
                typeof action?.id === 'number' ||
                (typeof action?.id === 'string' && !isNaN(parseInt(action?.id)))
            ) {
                return `#${action?.id}`;
            } else {
                // We can't determine the last display node without the user
                // actually navigating the tree since we depend on storing the
                // last display node as the user navigates.
                return 'None';
            }
    }
};

function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    const procedureStyles = makeProcedureStyles();

    const headCells = [
        {
            id: 'id',
            disablePadding: false,
            label: '#',
            align: 'left',
        },
        {
            id: 'action',
            noSort: true,
            disablePadding: false,
            label: 'Action',
            align: 'left',
        },
        {
            id: 'details',
            disablePadding: false,
            label: 'Details',
            align: 'left',
        },
    ];

    return (
        <TableHead>
            <TableRow>
                <TableCell />
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'Select all',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align ? headCell.align : 'right'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        <div className={procedureStyles.tableHeaderCell}>
                            {headCell.label}
                        </div>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    verticalAlign: 'top',
}));

const NodeButtonPreviews = ({ nodes, parentNode, node }) => {
    const theme = useSelector(selectActiveTheme);

    const nodeTableStyles = makeNodeManagerStyles(theme);

    return (
        <div className={nodeTableStyles.buttonPreviewContainer}>
            {node.option_buttons?.map((button) => {
                const targetLabel = computeTargetNodeFromAction(
                    node,
                    button.action,
                    nodes
                );

                return (
                    <button
                        className={nodeTableStyles.buttonPreview}
                        key={button.button_id}
                    >
                        {`${button.button_text} → ${targetLabel}`}
                    </button>
                );
            })}
            {node.return_button && (
                <button className={nodeTableStyles.buttonPreview}>
                    {`Return → ${
                        parentNode
                            ? parentNode.return_node_id
                            : node.return_node_id
                    }`}
                </button>
            )}
            {node.next_button && (
                <button className={nodeTableStyles.buttonPreview}>
                    {`Next → ${computeTargetNodeFromAction(
                        node,
                        { id: 'next' },
                        nodes
                    )}`}
                </button>
            )}
        </div>
    );
};

const NodeActionPreview = ({ nodes, node }) => {
    const theme = useSelector(selectActiveTheme);

    const nodeTableStyles = makeNodeManagerStyles(theme);

    if (node.type === 'logic') {
        return (
            <>
                <div className={nodeTableStyles.buttonPreviewContainer}>
                    <button
                        className={nodeTableStyles.buttonPreview}
                        key={'pass' + node.id}
                    >
                        {`Pass → ${computeTargetNodeFromAction(
                            node,
                            node?.pass_target_node_id,
                            nodes
                        )}`}
                    </button>
                    <button
                        className={nodeTableStyles.buttonPreview}
                        key={'fail' + node.id}
                    >
                        {`Fail → ${computeTargetNodeFromAction(
                            node,
                            node?.fail_target_node_id,
                            nodes
                        )}`}
                    </button>
                </div>
            </>
        );
    }

    const targetLabel = computeTargetNodeFromAction(node, node.action, nodes);

    return (
        <div className={nodeTableStyles.buttonPreviewContainer}>
            <button className={nodeTableStyles.buttonPreview} key={node.id}>
                {`→ ${targetLabel}`}
            </button>
        </div>
    );
};

const BranchNodes = ({ parentNode, onNodePreview }) => {
    const theme = useSelector(selectActiveTheme);
    const nodeTableStyles = makeNodeManagerStyles(theme);

    const [sortedNodes, setSortedNodes] = useState(
        parentNode.branch_procedure.nodes
    );

    useEffect(() => {
        setSortedNodes(parentNode.branch_procedure.nodes);
    }, [parentNode]);

    const [compactMode, setCompactMode] = useState(true);
    const [showBranches, setShowBranches] = useState(false);

    return (
        <div className={nodeTableStyles.branchNodesContainer}>
            <div className={nodeTableStyles.branchNodesTopButtons}>
                <div className={nodeTableStyles.rightButtons}>
                    <Tooltip title="Toggle Content Display">
                        <Box>
                            <MdContentPaste
                                className="react-icon"
                                style={{
                                    cursor: 'pointer',
                                    color: `${!compactMode ? 'blue' : 'unset'}`,
                                }}
                                onClick={() => setCompactMode(!compactMode)}
                            />
                        </Box>
                    </Tooltip>
                    <Tooltip title="Toggle Branch Display">
                        <Box>
                            <MdAccountTree
                                className="react-icon"
                                style={{
                                    cursor: 'pointer',
                                    color: `${showBranches ? 'blue' : 'unset'}`,
                                }}
                                onClick={() => setShowBranches(!showBranches)}
                            />
                        </Box>
                    </Tooltip>
                </div>
            </div>
            <Paper style={{ flex: '1', overflow: 'auto', margin: '5px' }}>
                <TableContainer>
                    <Table aria-labelledby="tableTitle" size="small">
                        <TableBody>
                            {sortedNodes.map((node, index) => (
                                <StyledTableRow
                                    key={`branch-${node?.id}-${index}`}
                                >
                                    <NodeNumberCell
                                        procedure={parentNode.branch_procedure}
                                        node={node}
                                    />
                                    <ActionCell
                                        procedure={parentNode.branch_procedure}
                                        node={node}
                                        onNodePreview={onNodePreview}
                                    />
                                    <NodeContentCell
                                        nodes={sortedNodes}
                                        parentNode={parentNode}
                                        node={node}
                                        compactMode={compactMode}
                                        showBranches={showBranches}
                                        onNodePreview={onNodePreview}
                                    />
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
};

export const NodeContentCell = ({
    onNodePreview,
    nodes,
    parentNode,
    node,
    compactMode,
    showBranches,
}) => {
    const theme = useSelector(selectActiveTheme);
    const nodeTableStyles = makeNodeManagerStyles(theme);
    const classes = useStyles(theme);
    const tinyMceCustomClass = customTinyMce(theme);

    return (
        <TableCell align="left">
            <div style={{ display: 'flex', gap: '5px' }}>
                <Tooltip title={NodeTypeIcons[node.type].title}>
                    {NodeTypeIcons[node.type].icon}
                </Tooltip>

                <div className={nodeTableStyles.detailsContainer}>
                    <div className={nodeTableStyles.detailsTitle}>
                        {node.title}
                    </div>
                    {node.type === 'branch' && (
                        <>
                            <div className={nodeTableStyles.branchTitle}>
                                Branch -{' '}
                                {node.asset_title ?? 'Loading branch...'}
                            </div>
                            {node.asset_title &&
                                showBranches &&
                                node.branch_procedure && (
                                    <BranchNodes
                                        parentNode={node}
                                        onNodePreview={onNodePreview}
                                    />
                                )}
                            {node.asset_title &&
                                showBranches &&
                                !node.branch_procedure && (
                                    <div>
                                        Error: The selected branch cannot be
                                        displayed as it references a parent
                                        procedure, creating a recursive loop.
                                    </div>
                                )}
                        </>
                    )}
                    {node.question && (
                        <div>
                            <span className={nodeTableStyles.detailsQ}>Q:</span>
                            &nbsp;
                            {node.question}
                        </div>
                    )}
                    {!compactMode && (
                        <div
                            className={clsx(
                                classes.nodeContent,
                                `gemini-node-content ${tinyMceCustomClass.tinymceCustom}`
                            )}
                            dangerouslySetInnerHTML={{ __html: node.content }}
                        ></div>
                    )}
                    {(node.option_buttons ||
                        node.return_button ||
                        node.next_button) && (
                        <NodeButtonPreviews
                            nodes={nodes}
                            parentNode={parentNode}
                            node={node}
                        />
                    )}
                    {ProcedureNodeTypes[node.type].mode === 'action' && (
                        <NodeActionPreview
                            nodes={nodes}
                            parentNode={parentNode}
                            node={node}
                        />
                    )}
                </div>
            </div>
        </TableCell>
    );
};

export const NodeNumberCell = ({ procedure, node, labelId, showHome }) => {
    return (
        <TableCell
            id={labelId}
            align="left"
            width="180"
            sx={{ minWidth: '180px' }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {node.id}
                {showHome && Number(procedure.start_node_id) === node.id && (
                    <MdHome
                        className="react-icon"
                        style={{ verticalAlign: 'middle' }}
                    />
                )}
            </div>
        </TableCell>
    );
};

const ActionCell = ({
    procedure,
    node,
    onNodeClicked,
    onNodePreview,
    onNodeDelete,
    onNodeDuplicate,
    showTwoPanelMode,
    onOptionClick = () => {},
}) => {
    const procedureStyles = makeProcedureStyles();

    return (
        <div style={{ display: 'flex', gap: '5px' }}>
            <Box p={2}>
                {showTwoPanelMode === false && onNodeClicked && (
                    <>
                        <div
                            className={procedureStyles.popupActionContainer}
                            onClick={() => {
                                onOptionClick();
                                onNodeClicked(node);
                            }}
                        >
                            <div className={procedureStyles.popupActionIcon}>
                                <MdEdit className="react-icon" />
                            </div>
                            <div className={procedureStyles.popupActionLabel}>
                                Edit
                            </div>
                        </div>
                        <Divider />
                    </>
                )}
                {onNodeDelete && (
                    <>
                        <div
                            className={procedureStyles.popupActionContainer}
                            onClick={() => {
                                onOptionClick();
                                onNodeDelete(node.id);
                            }}
                        >
                            <div className={procedureStyles.popupActionIcon}>
                                <MdDelete className="react-icon" />
                            </div>
                            <div className={procedureStyles.popupActionLabel}>
                                Delete
                            </div>
                        </div>
                        <Divider />
                    </>
                )}
                <div
                    className={procedureStyles.popupActionContainer}
                    onClick={() => {
                        onOptionClick();
                        onNodePreview({ procedure, node });
                    }}
                >
                    <div className={procedureStyles.popupActionIcon}>
                        <MdPreview className="react-icon" />
                    </div>
                    <div className={procedureStyles.popupActionLabel}>
                        Preview
                    </div>
                </div>
                {onNodeDuplicate && (
                    <>
                        <Divider />
                        <div
                            className={procedureStyles.popupActionContainer}
                            onClick={() => {
                                onOptionClick();
                                onNodeDuplicate(node.id);
                            }}
                        >
                            <div className={procedureStyles.popupActionIcon}>
                                <MdContentCopy className="react-icon" />
                            </div>
                            <div className={procedureStyles.popupActionLabel}>
                                Duplicate
                            </div>
                        </div>
                    </>
                )}
            </Box>
        </div>
    );
};

const NodeTable = ({
    procedure,
    nodes,
    onNodeClicked,
    onNodeActive,
    onNodePreview,
    onNodesChanged,
    onNodeDelete,
    onNodeDuplicate,
    setClickedAddButton,
    compactMode,
    showBranches,
    setOpenSaveDataDialog,
    activeNodeId,
    showTwoPanelMode,
}) => {
    const theme = useSelector(selectActiveTheme);
    const isProcedureDirty = useSelector(getProcedureDirty);
    const nodeTableStyles = makeNodeManagerStyles(theme);

    const [selected, setSelected] = useState([]);
    const [sortedNodes, setSortedNodes] = useState(nodes);
    const [openActionDialog, setOpenActionDialog] = useState(null);
    const [popoverNode, setPopoverNode] = useState(null);

    useEffect(() => {
        setSortedNodes(nodes);
    }, [nodes]);

    useEffect(() => {
        const row = document.getElementById(`node-table-row-${activeNodeId}`);
        if (row) row.scrollIntoView({ behavior: 'smooth' });
    }, [activeNodeId]);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (isProcedureDirty === true) {
            setOpenSaveDataDialog(true);
            return;
        }
        if (!result.destination) {
            return;
        }

        const orderedNodes = reorder(
            sortedNodes,
            result.source.index,
            result.destination.index
        );

        const newSortedNodes = orderedNodes.map((node, index) => {
            node.order_idx = index;
            return node;
        });

        setSortedNodes(newSortedNodes);
        onNodesChanged(newSortedNodes);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = nodes.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const openActionPopup = (event, node) => {
        setOpenActionDialog(event.currentTarget);
        setPopoverNode(node);
    };

    const closeActionPopup = () => {
        setOpenActionDialog(null);
        setPopoverNode(null);
    };

    return (
        <>
            <Paper style={{ flex: '1', overflow: 'auto' }}>
                {selected.length > 0 && (
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        toolTipTitle={'Total'}
                        totalCount={nodes.length}
                    >
                        <Tooltip
                            title={'Delete All'}
                            onClick={() => onNodeDelete(selected.sort())}
                        >
                            <MdDelete
                                className="react-icon"
                                style={{ color: 'black', margin: 'auto 5px' }}
                            />
                        </Tooltip>
                        <Tooltip
                            title={'Duplicate All'}
                            onClick={() => onNodeDuplicate(selected.sort())}
                        >
                            <MdContentCopy
                                className="react-icon"
                                style={{ color: 'black', margin: 'auto 5px' }}
                            />
                        </Tooltip>
                    </EnhancedTableToolbar>
                )}
                <DragDropContext onDragEnd={onDragEnd}>
                    <TableContainer style={{ overflowX: 'revert' }}>
                        <Table aria-labelledby="tableTitle" size="small">
                            <EnhancedTableHead
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                rowCount={nodes.length}
                            />
                            <Droppable droppableId="droppable-nodes">
                                {(provided) => (
                                    <TableBody
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {sortedNodes.map((node, index) => {
                                            const isItemSelected = isSelected(
                                                node.id
                                            );
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <Draggable
                                                    key={node.id}
                                                    draggableId={String(
                                                        node.id
                                                    )}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <StyledTableRow
                                                            id={`node-table-row-${node.id}`}
                                                            role="checkbox"
                                                            aria-checked={
                                                                isItemSelected
                                                            }
                                                            sx={
                                                                +node?.id ===
                                                                +activeNodeId
                                                                    ? {
                                                                          backgroundColor:
                                                                              'lightblue !important',
                                                                      }
                                                                    : {}
                                                            }
                                                            tabIndex={-1}
                                                            onClick={() => {
                                                                if (
                                                                    showTwoPanelMode ===
                                                                    true
                                                                ) {
                                                                    onNodeClicked(
                                                                        node
                                                                    );
                                                                } else {
                                                                    onNodeActive(
                                                                        +node?.id,
                                                                        node
                                                                    );
                                                                }
                                                            }}
                                                            key={node.id}
                                                            selected={
                                                                isItemSelected
                                                            }
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                        >
                                                            <TableCell
                                                                align="left"
                                                                className={
                                                                    nodeTableStyles.dragTd
                                                                }
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <div
                                                                    className={
                                                                        nodeTableStyles.dragColumn
                                                                    }
                                                                >
                                                                    <MdDragHandle className="react-icon" />
                                                                    <div>
                                                                        <Tooltip title="Add Node Here">
                                                                            <IconButton
                                                                                className={
                                                                                    nodeTableStyles.addButtonIcon
                                                                                }
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    e.stopPropagation();
                                                                                    setClickedAddButton(
                                                                                        index +
                                                                                            1
                                                                                    );
                                                                                }}
                                                                                size="small"
                                                                            >
                                                                                <MdAdd className="react-icon" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={
                                                                        isItemSelected
                                                                    }
                                                                    inputProps={{
                                                                        'aria-labelledby':
                                                                            labelId,
                                                                    }}
                                                                    onClick={(
                                                                        event
                                                                    ) =>
                                                                        handleClick(
                                                                            event,
                                                                            node.id
                                                                        )
                                                                    }
                                                                    style={{
                                                                        padding:
                                                                            '0',
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <NodeNumberCell
                                                                procedure={
                                                                    procedure
                                                                }
                                                                node={node}
                                                                labelId={
                                                                    labelId
                                                                }
                                                                showHome={true}
                                                            />
                                                            <TableCell align="left">
                                                                <IconButton
                                                                    aria-describedby="actionPopup"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        openActionPopup(
                                                                            e,
                                                                            node
                                                                        );
                                                                    }}
                                                                >
                                                                    <MdMoreVert className="react-icon" />
                                                                </IconButton>
                                                            </TableCell>
                                                            <NodeContentCell
                                                                onNodePreview={
                                                                    onNodePreview
                                                                }
                                                                nodes={
                                                                    sortedNodes
                                                                }
                                                                node={node}
                                                                compactMode={
                                                                    compactMode
                                                                }
                                                                showBranches={
                                                                    showBranches
                                                                }
                                                            />
                                                        </StyledTableRow>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </TableBody>
                                )}
                            </Droppable>
                        </Table>
                    </TableContainer>
                </DragDropContext>
            </Paper>
            {openActionDialog && (
                <Popover
                    id="actionPopup"
                    open={Boolean(openActionDialog)}
                    anchorEl={openActionDialog}
                    onClose={closeActionPopup}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <ActionCell
                        procedure={procedure}
                        node={popoverNode}
                        showTwoPanelMode={showTwoPanelMode}
                        onNodePreview={onNodePreview}
                        onNodeClicked={onNodeClicked}
                        onNodeDelete={onNodeDelete}
                        onNodeDuplicate={onNodeDuplicate}
                        onOptionClick={closeActionPopup}
                    />
                </Popover>
            )}
        </>
    );
};

export default NodeTable;
