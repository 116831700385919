import React from 'react';
import { useSelector } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { makeFormStyles } from 'forms/styles';

import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

const CopyToZonesDialog = ({ openCopyZoneModal, setOpenCopyZoneModel }) => {
    const theme = useSelector(selectActiveTheme);
    const formClasses = makeFormStyles(theme);
    return (
        <Dialog
            open={openCopyZoneModal}
            onClose={() => setOpenCopyZoneModel(false)}
        >
            <DialogTitle>Copy to Zone</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This item has already been copied to all available zones.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    className={formClasses.cancel}
                    onClick={() => {
                        setOpenCopyZoneModel(false);
                    }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { CopyToZonesDialog };
