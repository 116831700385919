import React, { useEffect } from 'react';
import { MenuItem, Select } from '@mui/material';
import {
    getClients,
    selectClients,
    selectClientsLoadingStatus,
} from '_features/clients/clientsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    clearConfigValues,
    selectConfigValuesClientId,
    setConfigValuesClientId,
} from '_features/configValues/configValuesSlice';
import { setIsDirty } from '_features/common/formSlice';

export const ClientSelect = () => {
    const clients = useSelector(selectClients);
    const clientsLoadingStatus = useSelector(selectClientsLoadingStatus);
    const dispatch = useDispatch();

    const selectedClientId = useSelector(selectConfigValuesClientId);

    useEffect(() => {
        const fetchClients = async () => {
            dispatch(getClients());
        };
        if (clientsLoadingStatus == LoadingStatus.Idle) {
            fetchClients();
        }
    }, []);

    const clientsWithGlobal = [
        { client_id: 0, display_name: 'GLOBAL' },
        ...clients,
    ];

    return (
        <Select
            size="small"
            labelId="client-select-label"
            id="client-select"
            style={{ minWidth: '300px' }}
            value={selectedClientId}
            onChange={(e) => {
                dispatch(clearConfigValues());
                dispatch(setIsDirty(false));
                dispatch(setConfigValuesClientId(e.target.value));
            }}
        >
            {clientsWithGlobal.map((client) => (
                <MenuItem key={client.client_id} value={client.client_id}>
                    {client.display_name}
                </MenuItem>
            ))}
        </Select>
    );
};
