import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { MdAdd } from 'react-icons/md';
import { IconButton, Tooltip } from '@mui/material';

import { Action } from '_helpers';
import { useStyles } from './styles';
import { openPanel } from '_features/common/formSlice';
import SearchWithFilters from 'components/SearchWithFilters';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { debounce } from 'GeminiViewerComponent/_helpers/lodashUtils';
import { makeButtonStyles } from 'GeminiViewerComponent/components/styles';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { ResizableTable } from 'GeminiViewerComponent/components/_Tables/ResizableTable';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import {
    resizableLabelHeader,
    resizableProgressStatusCell,
    resizableDeploymentPackageDownloadLink,
    resizableDynamicLinkCell,
    resizableLabelCell,
} from 'components';

function DeploymentPackageJobsTable({
    deploymentPackageJob,
    pageInfo,
    deploymentPackageJobsPayload,
    setDeploymentPackgeJobsPayload,
    sortDirection,
    sortBy,
    handleSort,
}) {
    const dispatch = useDispatch();
    const theme = useSelector(selectActiveTheme);
    const buttonStyles = makeButtonStyles(theme);
    const classes = useStyles();
    const tableClasses = tableStyles();
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);

    const loadedRowsMap = useSelector(
        (state) => state.jobs.loadeddeploymentPackageJobs
    );

    const headerHeight = 40;
    const rowHeight = 60;

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== deploymentPackageJob?.length) {
            setSelected(
                deploymentPackageJob?.map(
                    (row) => row.deployment_package_job_id
                )
            );
            setSelectedAll(true);
        }
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const columns = [
        {
            accessorKey: 'deployment_package',
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'Name',
            subDataKey: 'name',
            link: '/jobs',
            setIndex: 1,
            padding: 'normal',
            linkParamsKey: 'deployment_package_id',
            dispatch: dispatch,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableDynamicLinkCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'notification_emails',
            classes: tableClasses,
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'Notification Emails',
            setIndex: 1,
            padding: 'normal',
            transform: (data) => {
                if (data?.length > 0) {
                    return data.join(', ');
                }
            },
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'testing_domain',
            classes: tableClasses,
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'Testing Domain',
            setIndex: 1,
            padding: 'normal',
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'created_date',
            flexGrow: 1,
            size: 250,
            minSize: 250,
            label: 'Start Date',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            transform: (data) =>
                data ? moment(data).local().format('MM/DD/YYYY hh:mm A') : '',
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            flexGrow: 1,
            accessorKey: 'status_name',
            size: 250,
            minSize: 250,
            label: 'Status',
            enableResizing: false,
            sortKey: 'status_id',
            padding: 'normal',
            deploymentStatus: true,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableProgressStatusCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'file_name',
            flexGrow: 1,
            size: 300,
            minSize: 300,
            label: 'Completed File',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableDeploymentPackageDownloadLink(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
    ];

    // eslint-disable-next-line no-unused-vars
    function loadMoreRows({ startIndex, stopIndex }) {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce((searchString) => {
            setDeploymentPackgeJobsPayload({
                ...deploymentPackageJobsPayload,
                reset: true,
                searchString: searchString,
            });
        }, 1000),
        [deploymentPackageJobsPayload]
    );

    const handleCreateNew = () => {
        dispatch(
            openPanel({
                formKey: 'jobDeployForm',
                formAction: 'Create',
                clearEditId: true,
            })
        );
    };

    return (
        <div className={classes.root}>
            <div style={{ display: 'flex', gap: '5px', marginBottom: '1rem' }}>
                <Tooltip title="Add New Job">
                    <IconButton
                        onClick={handleCreateNew}
                        size="large"
                        className={buttonStyles.contentAddButton}
                    >
                        <MdAdd className="react-icon" />
                    </IconButton>
                </Tooltip>
                <div style={{ flexGrow: 1 }}></div>
                <SearchWithFilters
                    filter={false}
                    onChange={(evt) => debouncedSearch(evt.target.value)}
                />
            </div>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    toolTipTitle={'Delete'}
                    totalCount={pageInfo.TotalCount}
                >
                    <PopupAction
                        action={Action.DeleteArray}
                        object={{
                            deploymentIds: selected,
                            setDeploymentIds: setSelected,
                        }}
                        level={'managementDeploy'}
                        showLabel={false}
                    />
                </EnhancedTableToolbar>
            )}
            <div style={{ height: 'calc(100vh - 375px)', minWidth: '500px' }}>
                <ResizableTable
                    initialRows={deploymentPackageJob}
                    allRows={deploymentPackageJob}
                    totalRowCount={pageInfo.TotalCount}
                    loadedRowsMap={loadedRowsMap}
                    loadMoreRows={loadMoreRows}
                    columns={columns}
                    cellClassName={tableClasses.flexContainer}
                    className={tableClasses.table}
                    rowClassName={tableClasses.flexContainer}
                    headerHeight={headerHeight}
                    rowHeight={rowHeight}
                    selected={selected}
                />
            </div>
        </div>
    );
}

export { DeploymentPackageJobsTable };
