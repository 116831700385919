class GeminiViewManager {
    constructor(manager) {
        this.manager = manager;
    }
    setSceneInitView() {
        this.manager.setSceneInitView();
    }
    addInfoHotspot() {
        this.manager.addInfoHotspot();
    }
    addLinkHotspot() {
        this.manager.addLinkHotspot();
    }
    disableControls() {
        this.manager.disableControls();
    }
    enableControls() {
        this.manager.enableControls();
    }
    screenToCoordinates(coords) {
        return this.manager.screenToCoordinates(coords);
    }
    getCurrentViewParams() {
        return this.manager.getCurrentViewParams();
    }
    removeHotspot(hotspot) {
        this.manager.removeHotspot(hotspot);
    }
    switchSceneById(sceneId) {
        this.manager.switchSceneById(sceneId);
    }
    deleteView() {
        this.manager.deleteView();
    }
    updateView() {
        this.manager.updateView();
    }
    resetViewParams() {
        this.manager.resetViewParams();
    }
    getCurrentViewImage() {
        return this.manager.getCurrentViewImage();
    }
}

export { GeminiViewManager };
