import React from 'react';
import { useStyles } from 'components/_Layout/ConfigManagementTab/ConfigManager/styles';
import { ErrorMessage, FieldArray } from 'formik';
import { useSelector } from 'react-redux';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { FormTextField } from 'components/TextField';

function ConfigValuesField({ values, fieldName }) {
    const theme = useSelector(selectActiveTheme);
    const styles = useStyles(theme);

    return (
        <FieldArray name={fieldName}>
            {({ remove, push }) => (
                <div className={styles.form}>
                    <div className={styles.header}>
                        <label className={styles.label}>Key</label>
                        <label className={styles.label}>Value</label>
                        <button
                            type="button"
                            className={styles.button}
                            onClick={() => push({ key: '', value: '' })}
                        >
                            Add
                        </button>
                    </div>
                    {values.config_json.length > 0 &&
                        values.config_json.map((tags, index) => (
                            <div className={styles.row} key={index}>
                                <div className={styles.row}>
                                    <FormTextField
                                        name={`${fieldName}.${index}.key`}
                                        placeholder="Key"
                                    />
                                    <ErrorMessage
                                        name={`tags.${index}.key`}
                                        component="div"
                                    />
                                </div>

                                <div className={styles.row}>
                                    <FormTextField
                                        name={`${fieldName}.${index}.value`}
                                        placeholder="Value"
                                    />
                                    <ErrorMessage
                                        name={`tags.${index}.key`}
                                        component="div"
                                    />
                                </div>

                                <div>
                                    <button
                                        type="button"
                                        className={styles.removeButton}
                                        onClick={() => remove(index)}
                                    >
                                        Remove
                                    </button>
                                </div>
                            </div>
                        ))}
                </div>
            )}
        </FieldArray>
    );
}

export { ConfigValuesField };
