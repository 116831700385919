import React from 'react';
import { Box, Checkbox } from '@mui/material';

const ReadonlyField = ({ value, setFieldValue }) => {
    return (
        <Box>
            <Checkbox
                checked={Boolean(value)}
                onClick={(e) => setFieldValue('readonly', e.target.checked)}
            />
            Readonly
        </Box>
    );
};

export default ReadonlyField;
