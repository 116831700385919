import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { MdExpandMore } from 'react-icons/md';
import { accountsSlice, accountsThunk } from 'app/store';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { accordionStyles } from 'GeminiViewerComponent/components/styles';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
const { fetchRoles } = accountsThunk;

const { selectAllRoles, getRolesStatus } = accountsSlice;

function RoleAccordion({ roleId, setRoleId, formField }) {
    const dispatch = useDispatch();

    const classes = accordionStyles();

    const roles = useSelector((state) => selectAllRoles(state));
    const roleStatus = useSelector((state) => getRolesStatus(state));

    useEffect(() => {
        if (roleStatus === LoadingStatus.Idle) {
            dispatch(fetchRoles());
        }
    }, [roleStatus, dispatch]);

    const handleChange = (id) => {
        setRoleId(formField, id);
    };

    return (
        <div className={classes.root}>
            <Accordion className={classes.accordion}>
                <AccordionSummary
                    expandIcon={<MdExpandMore className="react-icon" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.customAccordionSummary}
                >
                    <Typography className={classes.heading}>Roles</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: 'column' }}>
                    {roles.map((role) => (
                        <FormControlLabel
                            key={role.role_id}
                            control={
                                <Checkbox
                                    onChange={() => handleChange(role.role_id)}
                                    checked={roleId === role.role_id}
                                    name={role.display_name}
                                    color="primary"
                                />
                            }
                            label={role.display_name}
                        />
                    ))}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export { RoleAccordion };
