import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const drawerWidth = 288;
const useStyles = makeStyles((theme) =>
    createStyles({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            '& .MuiDrawer-paper': {
                overflowY: 'unset',
            },
        },
        drawerClose: {
            overflowX: 'hidden',
            width: '60px',
        },
        containerDrawer: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#ECF0F1',
            height: '100%',
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'center',
            margin: '32px 0px',
            minHeight: '66px !important',
        },
        logo: {
            paddingRight: '15px',
            width: '185px',
        },
        arrowIcon: {
            position: 'relative',
            left: '51px',
        },
        closeIcon: {
            backgroundColor: '#BDC3C7',
            borderRadius: '100px',
            fill: 'white',
        },
        hide: {
            display: 'none',
        },
    })
);

export { useStyles };
