import {
    TextField as MUITextField,
    Autocomplete,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
} from '@mui/material';
import { MdClear } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import { makeFormStyles } from 'forms/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { getProcedureOriginalData } from '_features/procedures/proceduresSlice';

const ProcedureStartEndNodeField = ({
    procedureStartEndNode,
    setProcedureStartEndNode,
}) => {
    const { setFieldValue, values } = useFormikContext();
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    const procedureOriginalData = useSelector(getProcedureOriginalData);

    const procedureNodes =
        procedureOriginalData?.nodes?.map((node) => {
            return { id: node.id, title: node?.title };
        }) || [];

    if (values?.procedure_id && procedureNodes && procedureNodes?.length > 0) {
        return (
            <>
                {procedureNodes && procedureNodes?.length > 0 && (
                    <>
                        <FormControl
                            variant="outlined"
                            className={classes.handleClear}
                            style={{
                                marginTop: '15px',
                                position: 'relative',
                            }}
                        >
                            <Autocomplete
                                variant="outline"
                                isOptionEqualToValue={(option, value) =>
                                    option?.id === value?.id
                                }
                                id="procedure-start-node"
                                limitTags={1}
                                options={procedureNodes}
                                value={procedureStartEndNode.procedureStartNode}
                                disableClearable={true}
                                onChange={(event, newInputValue) => {
                                    setFieldValue(
                                        'procedure_start_node',
                                        newInputValue?.id
                                    );
                                    setProcedureStartEndNode((prev) => ({
                                        ...prev,
                                        procedureStartNode: procedureNodes.find(
                                            (node) =>
                                                node.id === newInputValue?.id
                                        ),
                                    }));
                                }}
                                getOptionLabel={(option) =>
                                    option?.title || '- #' + option?.id
                                }
                                renderInput={(params) => (
                                    <>
                                        <MUITextField
                                            {...params}
                                            variant="outlined"
                                            label="Procedure Start Node"
                                        />
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                right: '25px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                fontSize: '20px',
                                            }}
                                            className={'clearSelect'}
                                        >
                                            <IconButton
                                                onClick={() => {
                                                    setFieldValue(
                                                        'procedure_start_node',
                                                        null
                                                    );
                                                    setProcedureStartEndNode(
                                                        (prev) => ({
                                                            ...prev,
                                                            procedureStartNode:
                                                                null,
                                                        })
                                                    );
                                                }}
                                            >
                                                <MdClear />
                                            </IconButton>
                                        </Box>
                                    </>
                                )}
                            />
                        </FormControl>
                        <FormControl
                            variant="outlined"
                            className={classes.handleClear}
                            style={{
                                marginTop: '15px',
                                position: 'relative',
                            }}
                        >
                            <Autocomplete
                                variant="outline"
                                isOptionEqualToValue={(option, value) =>
                                    option?.id === value?.id
                                }
                                id="procedure-end-node"
                                limitTags={1}
                                options={procedureNodes}
                                value={procedureStartEndNode.procedureEndNode}
                                disableClearable={true}
                                onChange={(event, newInputValue) => {
                                    setFieldValue(
                                        'procedure_end_node',
                                        newInputValue?.id
                                    );
                                    setProcedureStartEndNode((prev) => ({
                                        ...prev,
                                        procedureEndNode: procedureNodes.find(
                                            (node) =>
                                                node.id === newInputValue?.id
                                        ),
                                    }));
                                }}
                                getOptionLabel={(option) =>
                                    option?.title || '- #' + option?.id
                                }
                                renderInput={(params) => (
                                    <>
                                        <MUITextField
                                            {...params}
                                            variant="outlined"
                                            label="Procedure End Node"
                                        />
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                right: '25px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                fontSize: '20px',
                                            }}
                                            className={'clearSelect'}
                                        >
                                            <IconButton
                                                onClick={() => {
                                                    setFieldValue(
                                                        'procedure_end_node',
                                                        null
                                                    );
                                                    setProcedureStartEndNode(
                                                        (prev) => ({
                                                            ...prev,
                                                            procedureEndNode:
                                                                null,
                                                        })
                                                    );
                                                }}
                                            >
                                                <MdClear />
                                            </IconButton>
                                        </Box>
                                    </>
                                )}
                            />
                        </FormControl>
                        {(values.procedure_start_node ||
                            values.procedure_end_node) && (
                            <FormControl variant="outlined">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                !!values.procedure_display_all_nodes
                                            }
                                            onChange={(e) =>
                                                setFieldValue(
                                                    'procedure_display_all_nodes',
                                                    e.target.checked
                                                )
                                            }
                                            id="procedure_display_all_nodes"
                                        />
                                    }
                                    label="Display nodes before start and end nodes"
                                />
                            </FormControl>
                        )}
                        {values.procedure_start_node && (
                            <FormControl variant="outlined">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                !!values.procedure_force_start_node
                                            }
                                            onChange={(e) =>
                                                setFieldValue(
                                                    'procedure_force_start_node',
                                                    e.target.checked
                                                )
                                            }
                                            id="procedure_force_start_node"
                                        />
                                    }
                                    label="Always display start node"
                                />
                                <FormHelperText sx={{ ml: 4, mt: 0 }}>
                                    Display the start node every time the
                                    procedure is opened, regardless of the last
                                    known node location.
                                </FormHelperText>
                            </FormControl>
                        )}
                    </>
                )}
            </>
        );
    }
};

export { ProcedureStartEndNodeField };
