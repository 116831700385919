import { createSlice } from '@reduxjs/toolkit';
import { last } from 'GeminiViewerComponent/_helpers/lodashUtils';

const initialState = {
    orientation: 'vertical',
    zoneStack: [],
    displayTooltip: {
        itemLabel: false,
        navLabel: false,
    },
};

const zonePreviewSlice = createSlice({
    name: 'zonePreview',
    initialState,
    reducers: {
        setOrientation: (state, action) => {
            state.orientation = action.payload;
        },
        resetZonePreviewState: (state) => initialState,
        clearActiveZones: (state) => {
            state.zoneStack = [];
        },
        pushZone: (state, action) => {
            const activeZone = last(state.zoneStack);
            if (action.payload.zoneId !== activeZone?.zoneId) {
                state.zoneStack.push(action.payload);
            }
        },
        popZone: (state) => {
            if (state.zoneStack.length > 1) {
                state.zoneStack.pop();
            }
        },
        setItemTooltip: (state, action) => {
            state.displayTooltip.itemLabel = action.payload;
        },
        setNavTooltip: (state, action) => {
            state.displayTooltip.navLabel = action.payload;
        },
    },
    extraReducers: {},
});

export const getPreviousZone = (state) =>
    state.zonePreview.zoneStack.length > 1
        ? state.zonePreview.zoneStack[state.zonePreview.zoneStack.length - 2]
        : null;

export const getPreviousAssetZoneId = (state) => {
    const length = state.zonePreview.zoneStack.length;
    if (length < 2) return null;
    const currentAssetId = state.zonePreview.zoneStack[length - 1].assetId;
    for (let i = length - 2; i >= 0; i--) {
        if (state.zonePreview.zoneStack[i].assetId !== currentAssetId) {
            return state.zonePreview.zoneStack[i].zoneId;
        }
    }
    return null;
};

export const getOrientation = (state) => state.zonePreview.orientation;
export const getItemTooltip = (state) =>
    state.zonePreview.displayTooltip.itemLabel;
export const getNavTooltip = (state) =>
    state.zonePreview.displayTooltip.navLabel;

export const {
    clearActiveZones,
    pushZone,
    popZone,
    setOrientation,
    setItemTooltip,
    setNavTooltip,
    resetZonePreviewState,
} = zonePreviewSlice.actions;

export default zonePreviewSlice.reducer;
