import { makeStyles } from '@mui/styles';

const sidePanelHeaderStyles = makeStyles(() => ({
    container: {
        backgroundColor: '#ECF0F1',
        padding: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        color: '#2D3D50',
        fontWeight: 'bold',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        fontSize: '24px',
    },
    headerTopRow: {
        display: 'flex',
        alignSelf: 'start',
    },
    headerBottomRow: {
        display: 'flex',
        alignItems: 'center',
    },
    headerCloseButton: {
        alignSelf: 'start',
    },
    info: {
        flex: '1',
        marginLeft: '15px',
    },
    headerIcon: {
        height: '25px',
        width: '25px',
    },
    subheader: {
        fontSize: '12px',
    },
    close: {
        marginRight: '10px',
        cursor: 'pointer',
    },
}));

export { sidePanelHeaderStyles };
