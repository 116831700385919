import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { Badge, Button, Tooltip, Typography } from '@mui/material';

import { accountsSlice } from 'app/store';
const { selectActiveUser } = accountsSlice;
import { sidePanelHeaderStyles } from './styles';
import {
    hasTag,
    hotSpotHasTag,
} from 'GeminiViewerComponent/_helpers/hotspot_helpers';
import { getAllAssetLinks } from 'GeminiViewerComponent/_features/links/linksSlice';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    clearItemLinks,
    getZoneMediaItems,
    setItemLinks,
} from 'shared/loadZoneSlice';
import {
    getActiveAssetActiveZone,
    getAssetZoneMedia,
    getChildZones,
    selectActiveAsset,
} from 'GeminiViewerComponent/_features/asset/assetSlice';
import {
    clearContentPanelItem,
    selectContentPanelItem,
} from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import {
    getPanelVis,
    getShowOnlyByFilter,
    getTagFilters,
    setPanelVis,
} from '../../_features/globals/visibilitySlice';
import { getConfig } from 'GeminiViewerComponent/_features/config/configSlice';
import { useAvailableZones } from 'GeminiViewerComponent/hooks/useAvailableZones';

const SidePanelHeader = ({ zoneMediaConfigValue = false }) => {
    const dispatch = useDispatch();

    const theme = useSelector(selectActiveTheme);

    const classes = sidePanelHeaderStyles(theme);

    const config = useSelector(getConfig);
    const panelItem = useSelector(selectContentPanelItem);
    const allAssetLinks = useSelector(getAllAssetLinks);
    const tagFilters = useSelector(getTagFilters);
    const activeZone = useSelector(getActiveAssetActiveZone);
    const activeAsset = useSelector(selectActiveAsset);
    const childZones = useSelector(getChildZones);
    const showOnlyByFilter = useSelector(getShowOnlyByFilter);
    const isOpen = useSelector(getPanelVis);
    const [allZoneMedia, setAllZoneMedia] = useState(false);
    const activeUser = useSelector(selectActiveUser);
    const configZoneMediaValue = useSelector(getAssetZoneMedia);
    const zoneMediaItems = useSelector(getZoneMediaItems);
    const s3Prefix = activeUser?.s3_prefix;
    const availableZones = useAvailableZones();

    const handleClose = () => {
        dispatch(setPanelVis({ open: false }));
    };

    useEffect(() => {
        if (config.modeType === 'author') {
            setAllZoneMedia(zoneMediaConfigValue);
        } else {
            setAllZoneMedia(configZoneMediaValue);
        }
    }, [isOpen, activeZone, zoneMediaConfigValue, configZoneMediaValue]);

    useEffect(() => {
        setZoneMedia(!allZoneMedia);
    }, [tagFilters, activeZone]);

    const setZoneMedia = useCallback(
        async (zoneOnlyMedia = true) => {
            let allItemData = [];
            var visibleHotspots = [];
            dispatch(clearItemLinks());
            dispatch(clearContentPanelItem());
            var itemTags = tagFilters?.item_tags.map((tag) => tag.tag_id);
            if (zoneOnlyMedia) {
                if (activeZone?.zone_id in availableZones) {
                    activeZone?.items?.forEach((hotspot) => {
                        let hasLocation = hotspot.yaw || hotspot.flat_x;
                        if (
                            hasLocation &&
                            hotSpotHasTag(itemTags, hotspot, showOnlyByFilter)
                        ) {
                            visibleHotspots.push({
                                ...hotspot,
                                zone_id: activeZone?.zone_id,
                                zone_display_name: activeZone?.display_name,
                            });
                        }
                    });
                }
                visibleHotspots?.forEach(async (item) => {
                    if (Array.isArray(item?.links) && item?.links?.length > 0) {
                        item.links.forEach(async (link) => {
                            let linkData = {
                                ...link,
                                zone_id: item?.zone_id,
                                zone_display_name: item?.zone_display_name,
                            };
                            allItemData = [...allItemData, linkData];
                        });
                    }
                });
                await dispatch(
                    setItemLinks({ s3Prefix: s3Prefix, links: allItemData })
                );
            } else {
                if (config.modeType === 'viewer') {
                    Object.values(availableZones).map((zone) => {
                        zone?.items?.forEach((item) => {
                            if (
                                !visibleHotspots.find(
                                    (hotspot) =>
                                        hotspot.item_id === item.item_id
                                )
                            ) {
                                let hasLocation = item.yaw || item.flat_x;
                                if (
                                    hasLocation &&
                                    hotSpotHasTag(
                                        itemTags,
                                        item,
                                        showOnlyByFilter
                                    )
                                ) {
                                    visibleHotspots.push({
                                        ...item,
                                        zone_id: zone?.zone_id,
                                        zone_display_name: zone?.display_name,
                                    });
                                }
                            }
                        });
                    });
                    visibleHotspots?.forEach(async (item) => {
                        if (
                            Array.isArray(item?.links) &&
                            item?.links?.length > 0
                        ) {
                            item.links.forEach(async (link) => {
                                let linkData = {
                                    ...link,
                                    zone_id: item?.zone_id,
                                    zone_display_name: item?.zone_display_name,
                                };
                                allItemData = [...allItemData, linkData];
                            });
                        }
                    });
                    await dispatch(
                        setItemLinks({ s3Prefix: s3Prefix, links: allItemData })
                    );
                } else {
                    var visibleLinks = allAssetLinks.filter((link) => {
                        return (
                            link.zone_id in availableZones &&
                            hasTag(itemTags, link.item_tags, showOnlyByFilter)
                        );
                    });

                    await dispatch(setItemLinks({ links: visibleLinks }));
                }
            }
            setAllZoneMedia(!zoneOnlyMedia);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            availableZones,
            tagFilters,
            allZoneMedia,
            activeZone,
            showOnlyByFilter,
            allAssetLinks,
        ]
    );

    return (
        <div className={classes.header}>
            <div className={classes.headerBranding}>
                {/* <img className={classes.headerLogo} src={logo} alt="" /> */}
                <IoMdCloseCircleOutline
                    className={classes.closeIcon}
                    onClick={handleClose}
                />
                {!Object.keys(panelItem ?? {}).length > 0 && (
                    <Button
                        onClick={() => setZoneMedia(allZoneMedia)}
                        size="small"
                        className={clsx(classes.displayZoneMediaButton, {
                            [classes.allZoneMediaButton]: allZoneMedia === true,
                        })}
                        variant="contained"
                    >
                        <Badge
                            className={classes.zoneMediaBadge}
                            badgeContent={zoneMediaItems?.length}
                        />
                        All Zones
                    </Button>
                )}
            </div>
            <div className={classes.hotspotContainer}>
                {panelItem?.display_name && (
                    <Tooltip
                        className={classes.displayName}
                        title={panelItem.display_name}
                    >
                        <Typography
                            overflow="hidden"
                            textOverflow="ellipsis"
                            display="inline-block"
                            whiteSpace="nowrap"
                            width="100%"
                        >
                            {panelItem.display_name}
                        </Typography>
                    </Tooltip>
                )}
                {panelItem?.description && (
                    <Tooltip
                        className={classes.description}
                        title={panelItem.description}
                    >
                        <Typography
                            overflow="hidden"
                            textOverflow="ellipsis"
                            display="inline-block"
                            whiteSpace="nowrap"
                            width="100%"
                        >
                            {panelItem.description}
                        </Typography>
                    </Tooltip>
                )}
                {panelItem?.external_id && (
                    <Tooltip
                        className={classes.externalId}
                        title={panelItem.description}
                    >
                        <Typography
                            overflow="hidden"
                            textOverflow="ellipsis"
                            display="inline-block"
                            whiteSpace="nowrap"
                            width="100%"
                        >
                            External ID: {panelItem.external_id}
                        </Typography>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

export { SidePanelHeader };
