import { config } from 'gemini-config';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';
import {
    createProgressParams,
    getNetworkService,
    FormDataUtils,
    prefixUrl,
} from 'GeminiViewerComponent/_helpers';

import {
    setHotspotTypes,
    fetchByKey,
} from 'GeminiViewerComponent/_helpers/cacheStore';

const baseUrl = `${config.apiUrl}/hotspoticon`;
const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});

const mapHotspot = (hotspot, s3Prefix) => {
    hotspot.image_url = prefixUrl(hotspot.image_url, s3Prefix);
    return hotspot;
};

const mapHotspots = (hotspots, s3Prefix) => {
    return hotspots.map((hotspot) => {
        return mapHotspot(hotspot, s3Prefix);
    });
};
const getAllByClientId = async (s3Prefix = '') => {
    const user = JSON.parse(localStorage.getItem('user'));
    try {
        const response = await networkService.get(
            `${baseUrl}/client/${user.client_id}`
        );
        const hotspots = mapHotspots(response.data, s3Prefix);
        return hotspots;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getHotspotTypes = async () => {
    try {
        if (!fetchByKey('hotspotTypes')) {
            const response = await networkService.get(
                `${baseUrl}/hotspottypes`
            );
            setHotspotTypes(response?.data);
            return response.data;
        }
        return fetchByKey('hotspotTypes');
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getRange = async (
    startIndex = 1,
    stopIndex = 10,
    s3Prefix = '',
    activeOnly = true
) => {
    const user = JSON.parse(localStorage.getItem('user'));

    const params = {
        sorts: 'hotspot_icon_id',
        start_index: startIndex,
        stop_index: stopIndex,
    };

    const query = new URLSearchParams(params);

    try {
        const response = await networkService.get(
            `${baseUrl}/client/${user.client_id}?${query}`
        );
        const hotspots = mapHotspots(response.data, s3Prefix);
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, hotspots: hotspots };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getAll = async (
    searchString = '',
    sort = '',
    s3Prefix = '',
    activeOnly = true
) => {
    const user = JSON.parse(localStorage.getItem('user'));

    const filters = [];
    if (searchString) {
        filters.push(`display_name@=*${searchString}`);
    }

    let params =
        filters.length > 0
            ? {
                  filters: filters.join(','),
              }
            : {};
    if (sort) {
        params = { ...params, sorts: sort };
    }

    const query = new URLSearchParams(params).toString();

    try {
        const response = await networkService.get(
            `${baseUrl}/client/${user.client_id}?${query}`
        );
        const hotspots = mapHotspots(response.data, s3Prefix);
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, hotspots: hotspots };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getById = async (id, s3Prefix = '') => {
    try {
        const response = await networkService.get(`${baseUrl}/${id}`);
        return mapHotspot(response.data, s3Prefix);
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const create = async (params) => {
    const user = JSON.parse(localStorage.getItem('user'));
    var formData = new FormData();

    FormDataUtils.safeAppend(formData, [
        ['display_name', params.display_name],
        [
            'hotspot_type_id',
            params.hotspot_type_id === '3' ? '1' : params.hotspot_type_id,
        ],
        ['client_id', user.client_id],
        ['image', params.image],
        ['is_highlight', params.hotspot_type_id === '3' ? true : false],
        ['is_populated', params.is_populated || false],
        ['is_unpopulated', params.is_unpopulated || false],
    ]);

    var progressParams = createProgressParams(params);

    try {
        const response = await networkService.postMultiFormData(
            baseUrl,
            formData,
            progressParams
        );

        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const update = async (params) => {
    const user = JSON.parse(localStorage.getItem('user'));
    let formData = new FormData();

    FormDataUtils.safeAppend(formData, [
        ['display_name', params.display_name],
        ['hotspot_icon_id', params.hotspot_icon_id],
        [
            'hotspot_type_id',
            params.hotspot_type_id === '3' ? '1' : params.hotspot_type_id,
        ],
        ['client_id', user.client_id],
        ['image', params.image],
        ['is_highlight', params.hotspot_type_id === '3' ? true : false],
        ['is_populated', params.is_populated || false],
        ['is_unpopulated', params.is_unpopulated || false],
    ]);

    let progressParams = createProgressParams(params);

    try {
        const response = await networkService.putMultiFormData(
            `${baseUrl}/${params.hotspot_icon_id}`,
            formData,
            progressParams
        );
        return response.data;
    } catch (e) {
        return e;
    }
};

const _delete = async (id) => {
    try {
        const response = await networkService.delete(`${baseUrl}/${id}`);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const deleteArray = async (ids) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const query = ids.join('&ids=');
    try {
        const response = await networkService.delete(
            `${baseUrl}/?client_id=${user.client_id}&ids=${query}`
        );

        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const setHotspotAsDefault = async (hotspotId) => {
    try {
        const response = await networkService.put(
            `${baseUrl}/${hotspotId}/setasdefault`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const setHotspotAsHighlight = async (hotspotId) => {
    try {
        const response = await networkService.put(
            `${baseUrl}/${hotspotId}/setashighlight`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const setPopulatedHotspot = async (hotspotId) => {
    try {
        const response = await networkService.put(
            `${baseUrl}/${hotspotId}/setaspopulated`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const setUnPopulatedHotspot = async (hotspotId) => {
    try {
        const response = await networkService.put(
            `${baseUrl}/${hotspotId}/setasunpopulated`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const getHotspotUsageById = async (hotspotId) => {
    try {
        const response = await networkService.get(
            `${baseUrl}/usage/${hotspotId}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const hotspotService = {
    getHotspotTypes,
    getAllByClientId,
    getAll,
    getById,
    create,
    update,
    getRange,
    delete: _delete,
    setHotspotAsDefault,
    setHotspotAsHighlight,
    deleteArray,
    getHotspotUsageById,
    setPopulatedHotspot,
    setUnPopulatedHotspot,
};
