import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ChapterButtonsLocation } from 'GeminiViewerComponent/_helpers';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    fetchContentById,
    fetchContentVersions,
} from '_features/content/contentSlice';
import SelectContentForm from 'components/ProcedureDesigner/components/ContentNodeEditor/SelectContentForm';

export function registerPlugin(tinymce) {
    tinymce.PluginManager.add('addContent', function (editor) {
        editor.ui.registry.addIcon(
            'contentLinkIcon',
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m0 16H5V5h14zm-5.06-8.94c.63.64.98 1.48.98 2.38s-.35 1.74-.98 2.37L11.73 17c-.65.67-1.51 1-2.37 1S7.64 17.67 7 17c-1.33-1.29-1.33-3.42 0-4.74l1.35-1.36l-.01.6c-.01.5.07 1 .23 1.44l.05.15l-.4.41a1.597 1.597 0 0 0 0 2.28c.61.62 1.67.62 2.28 0l2.2-2.19c.3-.31.48-.72.48-1.15c0-.44-.18-.83-.48-1.14a.87.87 0 0 1 0-1.24c.33-.33.91-.32 1.24 0m4.06-.7c0 .9-.35 1.74-1 2.38l-1.34 1.36v-.6c.01-.5-.07-1-.23-1.44l-.05-.14l.4-.42a1.597 1.597 0 0 0 0-2.28c-.61-.62-1.68-.61-2.28 0l-2.2 2.2c-.3.3-.48.71-.48 1.14c0 .44.18.83.48 1.14c.17.16.26.38.26.62s-.09.46-.26.62a.86.86 0 0 1-.62.25c-.22 0-.45-.08-.62-.25a3.36 3.36 0 0 1 0-4.75L12.27 7A3.31 3.31 0 0 1 17 7c.65.62 1 1.46 1 2.36"/></svg>'
        );
        editor.ui.registry.addButton('addContentLink', {
            icon: 'contentLinkIcon',
            tooltip: 'Add link to existing content',
            onAction: () => {
                editor.fire('addContent', { contentType: 'link' });
            },
        });

        editor.ui.registry.addIcon(
            'inlineImageIcon',
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 19H5V5h14m0-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m-5.04 9.29l-2.75 3.54l-1.96-2.36L6.5 17h11z"/></svg>'
        );
        editor.ui.registry.addButton('addInlineImage', {
            icon: 'inlineImageIcon',
            tooltip: 'Add inline image',
            onAction: () => {
                editor.fire('addContent', { contentType: 'image' });
            },
        });

        editor.ui.registry.addIcon(
            'inlineVideoIcon',
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2M10,16.5L16,12L10,7.5V16.5Z" /></svg>'
        );
        editor.ui.registry.addButton('addInlineVideo', {
            icon: 'inlineVideoIcon',
            tooltip: 'Add inline video',
            onAction: () => {
                editor.fire('addContent', { contentType: 'video' });
            },
        });
    });
}

function getLinkHtml(contentData, content, contentName) {
    let html = contentData.is_button
        ? '<button data-target-content="true">'
        : '';
    html += '<a data-target-content="true" ';
    if (contentData.chapter_id) {
        html += `data-chapter-id=${contentData.chapter_id} `;
    }
    if (contentData?.selectedVersion) {
        html += `data-target-selectedVersion="${contentData?.selectedVersion}" `;
    }
    html += `data-target-contentid="${
        contentData.content_id
    }" data-target-refmode="${
        contentData.reference_mode
    }" data-target-auto-play="${
        contentData.auto_play
    }" data-target-link-type-id="${
        content.default_link_type_id ||
        contentName?.payload?.default_link_type_id
    }" href="`;
    if (content?.encoding_status?.toLowerCase() === 'complete') {
        html += `${content?.encoded_url}?`;
    } else if (content?.encoded_url) {
        html += `${content.encoded_url}?`;
    } else {
        html += `${content?.url}?`;
    }
    if (contentData.auto_play) {
        html += `auto_play=${contentData.auto_play}&`;
    }
    html += `link_type_id=${
        content.default_link_type_id ||
        contentName?.payload?.default_link_type_id
    }`;

    html += `" target="${
        contentData.display_mode === 'external'
            ? '_blank'
            : contentData.display_mode === 'inline'
            ? '_self'
            : contentData.display_mode
    }">${contentData.link_text}</a>`;

    if (contentData.is_button) {
        html += '</button>';
    }
    return html;
}

function getVideoHtml(contentData, content) {
    let html = `<div style="width: fit-content;display:flex;" data-target-content="true" data-target-contentid="${contentData.content_id}" data-target-show_chapters="${contentData.show_chapters}" data-target-collapse_chapters="${contentData.collapse_chapters}" data-target-chapter_buttons_location="${contentData.chapter_buttons_location}" `;
    if (contentData.auto_play) {
        html += `data-target-auto_play="${contentData.auto_play}" `;
    }
    if (contentData.poster_image_name) {
        html += `data-target-poster_image_name="${contentData.poster_image_name}" `;
    }
    if (contentData.poster_image_url) {
        html += `data-target-poster_image_url="${contentData.poster_image_url}" `;
        html += `data-target-poster_id="${contentData.poster_id}" `;
        if (contentData.posterVersion) {
            html += `data-target-posterVersion="${contentData.posterVersion}" `;
        }
    }
    if (contentData.selectedVersion) {
        html += `data-target-selectedVersion="${contentData.selectedVersion}" `;
    }
    if (contentData.selectedChapter) {
        html += `data-target-selectedChapter="${contentData.selectedChapter}" `;
    }
    html += `>`;
    html += `<video controls data-target-contentid="${
        contentData.content_id
    }" data-target-show_chapters="${
        contentData.show_chapters
    }" data-target-collapse_chapters="${
        contentData.collapse_chapters
    }" data-target-chapter_buttons_location="${
        contentData.chapter_buttons_location
    }" height="${contentData.height || 225}" width="${
        contentData.width || 400
    }" `;

    if (contentData.auto_play) {
        html += `autoplay `;
    }
    if (contentData.selectedVersion) {
        html += `data-target-selectedVersion="${contentData.selectedVersion}" `;
    }
    if (contentData?.selectedChapter) {
        html += `data-target-selectedChapter="${contentData.selectedChapter}" `;
    }
    if (contentData.poster_image_url) {
        html += `poster="${contentData.poster_image_url}" `;
    }
    html += `src="`;
    if (content.encoding_status?.toLowerCase() === 'complete') {
        html += `${content.encoded_url}`;
    } else if (content?.encoded_url) {
        html += `${content.encoded_url}`;
    } else {
        html += `${content.url}?`;
    }
    html += `" >`;
    html += `</video></div>`;
    return html;
}

function getImageHtml(contentData, content) {
    let html = `<img data-target-content="true" data-target-contentid="${contentData.content_id}" src="${content.url}" `;
    if (contentData.selectedVersion) {
        html += `data-target-selectedVersion="${contentData.selectedVersion}" `;
    }
    if (contentData.width) {
        html += `width=${contentData.width} `;
    }
    if (contentData.height) {
        html += `height=${contentData.height} `;
    }
    if (contentData.altText) {
        html += `alt="${contentData.altText}" `;
    }
    html += '/>';
    return html;
}

export default function AddContentPlugin({ editor }) {
    const [showContentDialog, setShowContentDialog] = useState(false);
    const [contentType, setContentType] = useState(null);
    const [loadedContent, setLoadedContent] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const getSelectionParent = (selector) => {
            return editor.dom.getParent(editor.selection.getNode(), selector);
        };

        const showContentDialog = (e) => {
            setContentType(e.contentType);

            const element =
                e.contentType === 'video'
                    ? getSelectionParent('div')
                    : e.contentType === 'image'
                    ? editor.selection.getNode()
                    : getSelectionParent('a[href]');
            if (element?.getAttribute('data-target-content')) {
                if (e.contentType === 'video') {
                    setLoadedContent({
                        targetEl: element,
                        href: element.getAttribute('href'),
                        auto_play:
                            element.getAttribute('data-target-auto_play') ===
                            'true'
                                ? true
                                : false,
                        poster_image_name: element.getAttribute(
                            'data-target-poster_image_name'
                        ),
                        poster_image_url: element.getAttribute(
                            'data-target-poster_image_url'
                        ),
                        poster_id: element.getAttribute(
                            'data-target-poster_id'
                        ),
                        selectedChapter:
                            Number(
                                element.getAttribute(
                                    'data-target-selectedChapter'
                                )
                            ) || null,
                        selectedVersion:
                            Number(
                                element.getAttribute(
                                    'data-target-selectedVersion'
                                )
                            ) || null,
                        posterVersion:
                            Number(
                                element.getAttribute(
                                    'data-target-posterVersion'
                                )
                            ) || null,
                        show_chapters:
                            element.getAttribute(
                                'data-target-show_chapters'
                            ) === 'true',
                        collapse_chapters:
                            element.getAttribute(
                                'data-target-collapse_chapters'
                            ) === 'true',
                        chapter_buttons_location:
                            element?.getAttribute(
                                'data-target-chapter_buttons_location'
                            ) || ChapterButtonsLocation[1],
                        content_id: Number(
                            element.getAttribute('data-target-contentid')
                        ),
                        width: Number(
                            element.children?.[0]?.children?.[0]?.getAttribute(
                                'width'
                            )
                        ),
                        height: Number(
                            element.children?.[0]?.children?.[0]?.getAttribute(
                                'height'
                            )
                        ),
                        actionType: 'edit',
                    });
                } else if (e.contentType === 'image') {
                    setLoadedContent({
                        targetEl: element,
                        href: element.getAttribute('src'),
                        selectedVersion:
                            Number(
                                element?.getAttribute(
                                    'data-target-selectedVersion'
                                )
                            ) || null,
                        content_id: Number(
                            element.getAttribute('data-target-contentid')
                        ),
                        width: Number(element.getAttribute('width')) || null,
                        height: Number(element.getAttribute('height')) || null,
                        altText: element.getAttribute('alt'),
                        actionType: 'edit',
                    });
                } else {
                    const button = getSelectionParent('button');
                    const target = element.getAttribute('target');
                    setLoadedContent({
                        targetEl: button || element,
                        href: element.getAttribute('href'),
                        display_mode:
                            target === '_blank'
                                ? 'external'
                                : target === '_self'
                                ? 'inline'
                                : target,
                        reference_mode: element.getAttribute(
                            'data-target-refmode'
                        ),
                        content_id: Number(
                            element.getAttribute('data-target-contentid')
                        ),
                        chapter_id: Number(
                            element.getAttribute('data-chapter-id')
                        ),
                        selectedVersion:
                            Number(
                                element?.getAttribute(
                                    'data-target-selectedVersion'
                                )
                            ) || null,
                        auto_play:
                            element.getAttribute('data-target-auto-play') ===
                            'true',
                        link_type_id: Number(
                            element.getAttribute('data-target-link-type-id')
                        ),
                        is_button: button !== null,
                        link_text: element.innerText,
                        actionType: 'edit',
                    });
                }
            } else {
                setLoadedContent(null);
            }
            setShowContentDialog(true);
        };

        editor.on('addContent', showContentDialog);
        return () => editor.off('addContent', showContentDialog);
    }, [editor]);

    const updateElement = (nodeLinkElm, newContent) => {
        editor.selection.select(nodeLinkElm);
        editor.selection.setContent(newContent);
    };

    const handleSelectedContent = async (contentData) => {
        setShowContentDialog(false);
        if (!contentData) return;

        dispatch(activateLoading());
        let contentName = null;
        let content = null;
        if (contentData.content_id) {
            contentName = await dispatch(
                fetchContentById({ contentId: contentData.content_id })
            );
            if (contentData?.selectedVersion) {
                const versions = await dispatch(
                    fetchContentVersions(contentData?.content_id)
                );
                if (versions && versions?.payload.length > 0) {
                    content = versions?.payload?.find(
                        (version) =>
                            version.content_version_id ===
                            contentData?.selectedVersion
                    );
                }
            }
            if (content == null) {
                content = contentName?.payload?.last_content_version;
            }
        }
        if (content) {
            console.dir(content);
            const html =
                contentType === 'link'
                    ? getLinkHtml(contentData, content, contentName)
                    : contentType === 'video'
                    ? getVideoHtml(contentData, content, contentName)
                    : getImageHtml(contentData, content);

            if (loadedContent?.actionType === 'edit') {
                updateElement(loadedContent.targetEl, html);
            } else {
                editor.insertContent(html + '&nbsp;&nbsp;');
            }
        }
        dispatch(deactivateLoading());
    };

    return (
        <>
            {showContentDialog && (
                <SelectContentForm
                    content={loadedContent}
                    onSelectedContent={handleSelectedContent}
                    contentType={contentType}
                />
            )}
        </>
    );
}
