import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Action } from '_helpers';
import { useStyles } from './styles';
import { accountsSlice } from 'app/store';
import { loadZone } from 'shared/loadZoneSlice';
import { loadItem } from '_features/common/editItemSlice';
import { getAsset } from '_features/assets/assetManagerSlice';
import { tableStyles } from 'components/_Tables/TableCells/styles';
import { closePanel, selectFormInfo } from '_features/common/formSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { fetchAllHotspotIcons, getHotspotStatus } from 'shared/hotspotsSlice';
import PopupAction from 'components/_Misc/PopupActions/components/PopupAction';
import { ResizableTable } from 'GeminiViewerComponent/components/_Tables/ResizableTable';
import { EnhancedTableToolbar } from 'GeminiViewerComponent/components/EnhancedTableToolbar';
import { displayProcedureContentVersion } from 'GeminiViewerComponent/_features/globals/visibilitySlice';
import {
    selectVisualEditZone,
    setVisualEditZone,
} from '_features/common/editZoneSlice';
import {
    setContentValidationModalContent,
    toggleContentValidationModal,
} from '_features/globals/visibilitySlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    setDefaultItemPanelSection,
    setViewContentPanel,
} from 'GeminiViewerComponent/_features/contentPanel/contentPanelItemSlice';
import {
    selectContentById,
    fetchContentByTypeId,
    getContentStatus,
} from '_features/content/contentSlice';
import {
    resizableLabelHeader,
    resizableLabelCell,
    resizableContentTypeCell,
    resizableActionCell,
    resizableCheckCell,
    resizableCheckHeaderCell,
    resizableUsageDisplayCell,
} from 'components';

function ContentManagerTable({
    content,
    pageInfo,
    contentPayload,
    sortDirection,
    sortBy,
    handleSort,
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const tableClasses = tableStyles();
    const [selected, setSelected] = useState([]);
    const [selectedVideoIds, setSelectedVideoIds] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);
    const contentStatus = useSelector(getContentStatus);

    const allRows = useSelector(selectContentById);
    const activeUser = useSelector(accountsSlice.selectActiveUser);
    const visualEditZone = useSelector(selectVisualEditZone);
    const hotspotStatus = useSelector(getHotspotStatus);
    const isFormOpen = useSelector(selectFormInfo);
    const readOnlyMode = activeUser?.role === 'User' ? true : false;
    const loadedRowsMap = useSelector(
        (state) => state.content.contentLoadedRowsMap
    );

    const headerHeight = 40;
    const rowHeight = 60;

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const prevSelectedAll = usePrevious(selectedAll);
    if (prevSelectedAll && selectedAll) {
        if (selected.length !== content.length) {
            setSelected(content.map((row) => row.content_id));
            setSelectedAll(true);
        }
    }

    useEffect(() => {
        setSelectedVideoIds(
            selected.filter((contentId) =>
                content.find(
                    (content) =>
                        content.content_type_id === 7 &&
                        content.content_version_type === 2 &&
                        content.content_id == contentId
                )
            )
        );
    }, [selected, content]);

    const handleClick = (event, content_id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(content_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, content_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        setSelectedAll(newSelected.length === content.length);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedIds = content.map((item) => item.content_id);
            setSelected(newSelectedIds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const clearLastViewerProperties = async () => {
        await dispatch(setVisualEditZone(false));
        await dispatch(setViewContentPanel(false));
        await dispatch(setDefaultItemPanelSection(0));
        await dispatch(displayProcedureContentVersion(null));
        await dispatch(
            closePanel({
                formKey: 'zoneForm',
                formAction: 'Edit',
                isContentPanelOpen: false,
            })
        );
        await dispatch(
            closePanel({
                formKey: 'itemForm',
                formAction: 'Edit',
                isContentPanelOpen: false,
            })
        );
        await dispatch(
            closePanel({
                formKey: 'contentForm',
                formAction: 'Edit',
                isContentPanelOpen: false,
            })
        );
        if (window.geminiViewManager) {
            window.geminiViewManager.updateView();
        }
    };

    const handleAssetClick = async (event, asset_id = null) => {
        event.stopPropagation();
        if (asset_id) {
            if (visualEditZone === true) {
                clearLastViewerProperties();
            }
            navigate(`/asset/${asset_id}`);
        }
    };

    const handleProcedureClick = (event, asset_id) => {
        event.stopPropagation();
        if (asset_id) {
            navigate(`/procedure/${asset_id}`);
        }
    };

    const handleRowClick = ({ rowData }) => {
        dispatch(setContentValidationModalContent(rowData));
        dispatch(toggleContentValidationModal());
    };

    const handleZoneClick = async (
        event,
        rowData,
        assetId,
        item = null,
        link = null
    ) => {
        event.stopPropagation();
        dispatch(activateLoading());
        if (visualEditZone === true) {
            await clearLastViewerProperties();
        }
        dispatch(
            closePanel({
                formKey: 'contentForm',
            })
        );
        await dispatch(getAsset(assetId));
        if (hotspotStatus === LoadingStatus.Idle) {
            await dispatch(fetchAllHotspotIcons());
        }
        await dispatch(loadZone({ zoneId: rowData?.zone_id, refresh: false }));
        await dispatch(setVisualEditZone(true));
        if (item !== null) {
            handleItemClick(rowData.zone_id, item, link);
        }
        dispatch(deactivateLoading());
    };

    const handleItemClick = async (event, zone_id, hotspot, link = null) => {
        event.stopPropagation();
        isFormOpen?.zoneForm?.isOpen &&
            dispatch(
                closePanel({ formKey: 'zoneForm', isContentPanelOpen: true })
            );
        isFormOpen?.itemForm?.isOpen &&
            dispatch(
                closePanel({ formKey: 'itemForm', isContentPanelOpen: true })
            );

        await dispatch(setViewContentPanel(true));
        if (link !== null) {
            await dispatch(setDefaultItemPanelSection(link.link_type_id));
        }
        await dispatch(
            loadItem({
                zoneId: zone_id,
                itemId: hotspot.item_id,
            })
        );
    };

    let columns = [
        {
            accessorKey: 'content_id',
            size: 50,
            minSize: 50,
            enableResizing: false,
            padding: 'none',
            label: '',
            actionCellLevel: 'content',
            targetPopup: 'content',
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableActionCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'display_name',
            flexGrow: 1,
            size: 120,
            minSize: 120,
            maxSize: 500,
            label: 'Content Name',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'content_type_id',
            flexGrow: 1,
            size: 120,
            minSize: 120,
            label: 'Content Type',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableContentTypeCell(
                    { cellData: info?.row?.original['content_type_id'] },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'asset_usage',
            flexGrow: 1,
            size: 80,
            minSize: 80,
            enableResizing: false,
            label: 'Assets',
            displayDataKey: 'asset_name',
            padding: 'normal',
            onClick: handleAssetClick,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableUsageDisplayCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'zone_usage',
            flexGrow: 1,
            size: 80,
            minSize: 80,
            enableResizing: false,
            label: 'Zones',
            displayDataKey: 'zone_name',
            padding: 'normal',
            onClick: handleZoneClick,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableUsageDisplayCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'item_usage',
            flexGrow: 1,
            size: 80,
            minSize: 80,
            enableResizing: false,
            label: 'Items',
            displayDataKey: 'item_name',
            padding: 'normal',
            onClick: handleItemClick,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableUsageDisplayCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'procedure_usage',
            flexGrow: 1,
            size: 80,
            minSize: 80,
            enableResizing: false,
            label: 'Procedures',
            displayDataKey: 'asset_name',
            padding: 'normal',
            onClick: handleProcedureClick,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableUsageDisplayCell(
                    { rowData: info?.row?.original },
                    info?.column?.columnDef
                ),
        },

        {
            accessorKey: 'encoding_status',
            flexGrow: 1,
            size: 160,
            minSize: 160,
            maxSize: 500,
            label: 'Encoding',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            transform: (data, rowData) => {
                if (data) {
                    return `${data}${
                        data.toLowerCase() !== 'complete'
                            ? ` - ${rowData?.encoding_percent ?? 0}%`
                            : ''
                    }`;
                }
                return data;
            },
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'transcribing_status',
            flexGrow: 1,
            size: 160,
            minSize: 160,
            maxSize: 500,
            label: 'Transcribing',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            transform: (data, rowData) => {
                if (data) {
                    return `${data}${
                        data.toLowerCase() !== 'completed'
                            ? ` - ${rowData?.transcribing_percent ?? 0}%`
                            : ''
                    }`;
                }
                return data;
            },
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'caption_count',
            flexGrow: 1,
            size: 100,
            minSize: 100,
            maxSize: 200,
            label: 'Captions',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'file_size',
            flexGrow: 1,
            size: 120,
            minSize: 120,
            maxSize: 500,
            label: 'File Size',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            transform: (data) => {
                if (data) {
                    let bytesToMB = data / 1024 / 1024;
                    if (bytesToMB < 0.1) {
                        return data + ' bytes';
                    }
                    return bytesToMB.toFixed(1) + ' MB';
                }
                return data;
            },
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'file_width',
            flexGrow: 1,
            size: 120,
            minSize: 120,
            maxSize: 500,
            label: 'File Width',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) => info?.row?.original?.['file_width'],
        },
        {
            accessorKey: 'file_height',
            flexGrow: 1,
            size: 120,
            minSize: 120,
            maxSize: 500,
            label: 'File Height',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) => info?.row?.original?.['file_height'],
        },
        {
            accessorKey: 'duration',
            flexGrow: 1,
            size: 120,
            minSize: 120,
            maxSize: 500,
            label: 'Duration',
            padding: 'normal',
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            transform: (data) => {
                if (data) {
                    let result = [];
                    let [h, m, s] = data
                        .split('.')[0]
                        .split(':')
                        .map((x) => Number(x));
                    if (h > 0) {
                        result.push(h + 'h');
                    }

                    if (m > 0) {
                        result.push(m + 'm');
                    }

                    if (s > 0) {
                        result.push(s + 's');
                    }

                    return result.join(' ');
                }
                return data;
            },
            cell: (info) =>
                resizableLabelCell(
                    info?.row?.original,
                    info?.column?.columnDef
                ),
        },
        {
            accessorKey: 'asset_id',
            flexGrow: 1,
            size: 120,
            minSize: 120,
            label: 'Content Origin',
            padding: 'normal',
            conditionalFn: (data) => (data !== undefined ? 'Asset' : 'Global'),
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
            header: (info) =>
                resizableLabelHeader(null, info?.header?.column?.columnDef),
            cell: (info) =>
                info?.row?.original?.['asset_id'] !== undefined
                    ? 'Asset'
                    : 'Global',
        },
    ];
    if (readOnlyMode !== true) {
        columns = [
            {
                accessorKey: 'content_id',
                size: 50,
                minSize: 50,
                enableResizing: false,
                label: '',
                padding: 'checkbox',
                cellLevel: 'content',
                numSelected: selected.length,
                rowCount: content.length,
                handleClick: handleClick,
                handleSelectAllClick: handleSelectAllClick,
                isSelected: isSelected,
                header: (info) =>
                    resizableCheckHeaderCell(
                        null,
                        info?.header?.column?.columnDef
                    ),
                cell: (info) =>
                    resizableCheckCell(
                        { rowData: info?.row?.original },
                        info?.column?.columnDef
                    ),
            },
            ...columns,
        ];
    }
    async function loadMoreRows({ startIndex, stopIndex }) {
        if (contentStatus !== LoadingStatus.Loading) {
            await dispatch(
                fetchContentByTypeId({
                    ...contentPayload,
                    reset: false,
                    startIndex: startIndex,
                    stopIndex: stopIndex,
                })
            );
        }
    }

    return (
        <div className={classes.root}>
            <div
                style={{
                    height: 'calc(100vh - 345px)',
                    minWidth: '500px',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                }}
            >
                {selected.length > 0 && (
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        toolTipTitle={'Batch Actions'}
                        totalCount={pageInfo.TotalCount}
                    >
                        <PopupAction
                            action={Action.DeleteArray}
                            object={{
                                contentIds: selected,
                                setContentIds: setSelected,
                            }}
                            level={'content'}
                            toolTipTitle="Delete"
                            showLabel={false}
                        />
                        <PopupAction
                            enabled={selectedVideoIds.length > 0}
                            action={Action.TranscribeArray}
                            object={{
                                contentIds: selectedVideoIds,
                                setContentIds: setSelected,
                            }}
                            level={'content'}
                            toolTipTitle="Transcribe"
                            showLabel={false}
                        />
                    </EnhancedTableToolbar>
                )}
                <ResizableTable
                    initialRows={content}
                    allRows={allRows}
                    totalRowCount={pageInfo.TotalCount}
                    loadedRowsMap={loadedRowsMap}
                    loadMoreRows={loadMoreRows}
                    columns={columns}
                    cellClassName={tableClasses.flexContainer}
                    className={tableClasses.table}
                    rowClassName={clsx(
                        tableClasses.flexContainer,
                        tableClasses.tableRowHover
                    )}
                    headerHeight={headerHeight}
                    rowHeight={rowHeight}
                    onRowClick={handleRowClick}
                    selected={selected}
                />
            </div>
        </div>
    );
}

export { ContentManagerTable };
