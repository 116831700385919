import { config } from 'gemini-config';
import {
    createProgressParams,
    getNetworkService,
    FormDataUtils,
    prefixUrl,
} from 'GeminiViewerComponent/_helpers';
import { getAccountService } from 'GeminiViewerComponent/_features/services/account.service';

const accountService = getAccountService({ config: config });
const networkService = getNetworkService({
    config: config,
    checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/tool/location`;

const mapLocation = (location, s3Prefix) => {
    if (location?.image_url) {
        location.image_url = prefixUrl(location.image_url, s3Prefix);
    }
    return location;
};

const mapLocations = (locations, s3Prefix) => {
    return locations.map((loc) => {
        return mapLocation(loc, s3Prefix);
    });
};

const getLocations = async (
    searchString = '',
    sort = '-display_name',
    s3Prefix = '',
    activeOnly = true
) => {
    const filters = [
        `is_active==${activeOnly.toString()}`,
        `display_name@=*${searchString}`,
    ];
    const params = { filters: filters.join(','), sorts: sort };
    const query = new URLSearchParams(params).toString();
    try {
        const response = await networkService.get(`${baseUrl}?${query}`);
        response.data = mapLocations(response?.data, s3Prefix);
        const pageInfo = response.headers['x-pagination'];
        return { pageInfo: pageInfo, locations: response?.data };
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const create = async (params, s3Prefix = '') => {
    const user = JSON.parse(localStorage.getItem('user'));
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [
        ['display_name', params.display_name],
        ['client_id', user.client_id],
        ['description', params?.description],
        ['image', params.image],
    ]);
    let progressParams = createProgressParams(params);
    try {
        const response = await networkService.postMultiFormData(
            baseUrl,
            formData,
            progressParams
        );
        response.data = mapLocation(response.data, s3Prefix);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const update = async (params, s3Prefix = '') => {
    const user = JSON.parse(localStorage.getItem('user'));
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [
        ['display_name', params.display_name],
        ['client_id', user.client_id],
        ['description', params?.description],
        ['image', params.image],
        ['image_url', params.image_url],
    ]);
    let progressParams = createProgressParams(params);
    try {
        const response = await networkService.putMultiFormData(
            `${baseUrl}/${params.tool_location_id}`,
            formData,
            progressParams
        );
        response.data = mapLocation(response.data, s3Prefix);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

const _delete = async (tool_location_id) => {
    try {
        const response = await networkService.delete(
            `${baseUrl}/${tool_location_id}`
        );
        return response;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

// const deleteArray = async (ids) => {
//     try {
//         const user = JSON.parse(localStorage.getItem('user'));
//         const query = ids.join('&ids=');
//         const response = await networkService.delete(
//             `${baseUrl}/?client_id=${user.client_id}&ids=${query}`
//         );

//         return response.data;
//     } catch (e) {
//         return Promise.reject(e.error);
//     }
// };

const getById = async (id, s3Prefix = '') => {
    try {
        const response = await networkService.get(`${baseUrl}/${id}`);
        response.data = mapLocation(response.data, s3Prefix);
        return response.data;
    } catch (e) {
        return Promise.reject(e.error);
    }
};

export const locationService = {
    getLocations,
    getById,
    create,
    update,
    delete: _delete,
};
