import { useSelector, useDispatch } from 'react-redux';
import { accountsSlice } from 'app/store';
import {
    ConfigValuesDisplayMode,
    ConfigValuesManager,
} from '../ClientConfigManagementTab/ConfigValuesManager';
import { setConfigValuesClientId } from '_features/configValues/configValuesSlice';
import { useEffect } from 'react';
import { AssetSelect } from '../ClientConfigManagementTab/AssetSelect';

const { selectActiveUser } = accountsSlice;

const ConfigManagementTab = () => {
    //Look into using useEffect to dispatch to the item service to get the config values
    const activeUser = useSelector(selectActiveUser);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setConfigValuesClientId(activeUser?.client_id));
    }, []);

    return (
        <>
            <div style={{ marginBottom: '20px' }}>
                <AssetSelect />
            </div>
            <div>
                <ConfigValuesManager
                    configValuesDisplayMode={ConfigValuesDisplayMode.Settings}
                    clientMode={true}
                />
            </div>
        </>
    );
};

export { ConfigManagementTab };
