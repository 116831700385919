import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Button,
} from '@mui/material';
import { useFormikContext } from 'formik';

const SaveNodeDataDialog = ({
    openSaveDataDialog,
    setOpenSaveDataDialog,
    isSubmitting,
    onCancel,
}) => {
    const { submitForm } = useFormikContext();

    return (
        openSaveDataDialog && (
            <Dialog
                open={openSaveDataDialog}
                onClose={() => setOpenSaveDataDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {'Save Node Data'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`Do you want to save node data?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            onCancel();
                            setOpenSaveDataDialog(false);
                        }}
                        autoFocus
                    >
                        Don&apos;t save
                    </Button>

                    <Button
                        disabled={isSubmitting}
                        onClick={() => {
                            submitForm();
                            setOpenSaveDataDialog(false);
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    );
};

export default SaveNodeDataDialog;
