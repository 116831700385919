// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { BiDetail } from 'react-icons/bi';
import { MdExpandMore } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Tooltip,
    Typography,
} from '@mui/material';

import { linkButtonStyles } from 'GeminiViewerComponent/components/LinkButton/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { linkAccordionStyles } from 'GeminiViewerComponent/components/LinkTypeAccordion/styles';
import {
    allNewsFlashById,
    zoneNewsFlashes,
} from 'GeminiViewerComponent/_features/asset/assetSlice';
import {
    setViewerActiveNewsFlash,
    setViewerNewsFlashModal,
} from 'GeminiViewerComponent/_features/newsFlash/newsFlashSlice';

export const NewsFlashAccordian = () => {
    //#region Constants
    const dispatch = useDispatch();

    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    const [newsFlash, setnewsFlash] = useState([]);
    //#endregion State

    //#region Selectors
    const theme = useSelector(selectActiveTheme);
    const classes = linkAccordionStyles(theme);
    const linkButtonClasses = linkButtonStyles(theme);
    const zoneNewsFlashIds = useSelector(zoneNewsFlashes);
    const allNewsFlashes = useSelector(allNewsFlashById);
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    useEffect(() => {
        setnewsFlash(
            zoneNewsFlashIds.map((newsFlashId) => allNewsFlashes[newsFlashId])
        );
    }, [zoneNewsFlashIds]);
    //#endregion Effects

    //#region Methods
    const openNewsFlashDialog = (newsFlash) => {
        dispatch(setViewerActiveNewsFlash(newsFlash));
        dispatch(setViewerNewsFlashModal(true));
    };
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render
    return (
        <div>
            <Accordion>
                <AccordionSummary
                    className={classes.sectionHeader}
                    expandIcon={
                        <MdExpandMore
                            className={`react-icon ${classes.expandIcon}`}
                        />
                    }
                >
                    <Typography className={classes.heading}>
                        <span className={classes.icon}>
                            <BiDetail
                                className="react-icon"
                                width="20px"
                                height="20px"
                            />
                        </span>
                        {'Zone Info'}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.links}>
                    {newsFlash.map((newsFlash, index) => {
                        return (
                            <Box
                                key={index}
                                className={linkButtonClasses.linkContainer}
                                onClick={() => openNewsFlashDialog(newsFlash)}
                            >
                                <Tooltip title={newsFlash?.display_name}>
                                    <Typography
                                        overflow="hidden"
                                        textOverflow="ellipsis"
                                        display="inline-block"
                                        whiteSpace="nowrap"
                                        width="100%"
                                    >
                                        {newsFlash?.display_name || '-'}
                                    </Typography>
                                </Tooltip>
                            </Box>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
        </div>
    );
    //#endregion Render
};
