import clsx from 'clsx';
import useStyles from '../styles';
import { useSelector } from 'react-redux';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';

const ZonePreview = ({ zonePreview, isTrimPositions = false }) => {
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);

    const zoneNameColor = (name) => {
        switch (name) {
            case 'Start':
                return '#389d56';
            case 'End':
                return '#d84955';
            default:
                return '#575757';
        }
    };

    return (
        <div
            className={clsx(classes.zonePreviewContainer, {
                [classes.trimZoneWrap]: isTrimPositions,
            })}
        >
            <img
                src={zonePreview.image}
                className={classes.imgDimensions}
                alt={zonePreview.name}
                style={isTrimPositions ? { width: '100%' } : null}
            />
            <div
                className={classes.zonePreviewName}
                style={{ backgroundColor: zoneNameColor(zonePreview.name) }}
            >
                {zonePreview.name}
            </div>
        </div>
    );
};

const ZonePreviews = (props) => {
    const { className, title, previews, isTrimPositions } = props;
    const theme = useSelector(selectActiveTheme);
    const classes = useStyles(theme);

    return (
        <div className={className}>
            <div className={classes.zonePreviewHeader}>{title}</div>
            {previews.map((zonePreview) => {
                return (
                    <ZonePreview
                        key={zonePreview.image}
                        zonePreview={zonePreview}
                        isTrimPositions={isTrimPositions}
                    />
                );
            })}
        </div>
    );
};

export default ZonePreviews;
