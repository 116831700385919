import { useCallback } from 'react';
import {
    Grid,
    MenuItem,
    Select,
    Button,
    InputLabel,
    FormControl,
    Chip,
    Checkbox,
} from '@mui/material';
import { Field } from 'formik';
import { makeFormStyles } from 'forms/styles';
import { Draggable } from 'react-beautiful-dnd';
import clsx from 'clsx';
import Dropzone from 'react-dropzone';
import { useSelector } from 'react-redux';
import { MdClose, MdDragHandle } from 'react-icons/md';

import { panelStyles } from 'GeminiViewerComponent/components/styles';
import { LinkTypes } from '_helpers/enumerations';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import IncorrectFieldSet from '../../IncorrectFieldSet';
import { FormTextField } from 'components/TextField';

const ImageAnswerField = ({
    option,
    idx,
    nodes,
    node,
    values,
    setFieldValue,
    handleRemove,
    ...otherProps
}) => {
    const theme = useSelector(selectActiveTheme);
    nodes = nodes.filter((nd) => nd.id !== node.id);
    const commonFormStyles = makeFormStyles(theme);
    const panelClasses = panelStyles(theme);

    const onDrop = useCallback(({ file, setFieldValue, id, values }) => {
        let currImageOptions = [...values];
        const reader = new FileReader();
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = (e) => {
            currImageOptions[id] = {
                ...values[id],
                img_data: file,
                img_name: file?.name,
                img_url: e.target.result,
            };
            setFieldValue(`image_options`, currImageOptions);
        };
        reader.readAsDataURL(file);
    }, []);

    return (
        <Draggable
            key={'image_opts_draggable_' + idx}
            draggableId={String('image_opts_draggable_' + idx)}
            index={idx}
        >
            {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                    <Grid
                        container
                        key={'image_opts_' + idx}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            marginBottom: '6px',
                        }}
                        {...otherProps}
                    >
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            justifyContent={'center'}
                            item
                            xs={12}
                            sm={12}
                            md={1}
                            lg={1}
                            {...provided.dragHandleProps}
                        >
                            <MdDragHandle className="react-icon" />
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                        >
                            <FormControl fullWidth>
                                <Field name={`image_options[${idx}].img_data`}>
                                    {({ form: { setFieldValue } }) => (
                                        <div
                                            className={
                                                panelClasses.dropzoneContainer
                                            }
                                        >
                                            <Dropzone
                                                maxFiles={1}
                                                multiple={false}
                                                accept={LinkTypes.Image.contentTypes.map(
                                                    (at) => at.extension
                                                )}
                                                onDropAccepted={(files) => {
                                                    onDrop({
                                                        file: files[0],
                                                        setFieldValue:
                                                            setFieldValue,
                                                        id: idx,
                                                        values: values.image_options,
                                                    });
                                                }}
                                            >
                                                {({
                                                    getRootProps,
                                                    getInputProps,
                                                }) => (
                                                    <div
                                                        {...getRootProps()}
                                                        className={clsx(
                                                            panelClasses.dropzoneArea
                                                        )}
                                                        style={{
                                                            backgroundImage: `url(${option?.img_url})`,
                                                            backgroundRepeat:
                                                                'no-repeat',
                                                            backgroundSize:
                                                                'contain',
                                                            backgroundPosition:
                                                                'center center',
                                                            minHeight:
                                                                '100px !important',
                                                        }}
                                                    >
                                                        <input
                                                            {...getInputProps()}
                                                        />
                                                        {!option?.img_url && (
                                                            <div
                                                                className={
                                                                    panelClasses.dropzoneText
                                                                }
                                                            >
                                                                Upload Content
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </Dropzone>
                                            <div
                                                className={
                                                    panelClasses.previewContainer
                                                }
                                            >
                                                {option && option?.img_name && (
                                                    <Chip
                                                        className={
                                                            panelClasses.previewUploadFileName
                                                        }
                                                        color="secondary"
                                                        size="small"
                                                        deleteIcon={
                                                            <MdClose className="react-icon" />
                                                        }
                                                        onDelete={() => {
                                                            let currImageOptions =
                                                                [
                                                                    ...values.image_options,
                                                                ];
                                                            currImageOptions[
                                                                idx
                                                            ] = {
                                                                ...values
                                                                    .image_options[
                                                                    idx
                                                                ],
                                                                img_data: null,
                                                                img_url: '',
                                                                img_name: '',
                                                            };
                                                            setFieldValue(
                                                                `image_options`,
                                                                currImageOptions
                                                            );
                                                        }}
                                                        label={option?.img_name}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Field>
                            </FormControl>
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={2}
                            lg={2}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="target-node-id">
                                    Target Node
                                </InputLabel>
                                <Select
                                    className={commonFormStyles.materialSelect}
                                    style={{
                                        margin: '0',
                                    }}
                                    labelId="target-node-id"
                                    margin="dense"
                                    id="target_node_id"
                                    label="Target Node"
                                    placeholder="Target Node"
                                    name={`image_options[${idx}].target_node_id`}
                                    value={option?.action?.id}
                                    onChange={(e) => {
                                        let currImageOptions = [
                                            ...values.image_options,
                                        ];
                                        currImageOptions[idx] = {
                                            ...values.image_options[idx],
                                            action: {
                                                ...values.image_options[idx]
                                                    .action,
                                                id: e.target.value,
                                            },
                                        };
                                        setFieldValue(
                                            'image_options',
                                            currImageOptions
                                        );
                                    }}
                                >
                                    <MenuItem key={'next'} value={'next'}>
                                        Next
                                    </MenuItem>
                                    <MenuItem key={'back'} value={'back'}>
                                        Back
                                    </MenuItem>
                                    {nodes?.length > 0 &&
                                        nodes.map((nd) => (
                                            <MenuItem
                                                key={nd?.id}
                                                value={nd?.id}
                                            >
                                                {nd?.title} - #{nd?.id}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={2}
                            lg={2}
                        >
                            <FormControl fullWidth>
                                <FormTextField
                                    label="Answer Value"
                                    placeholder="Answer Value"
                                    name={`image_options[${idx}].answer_value`}
                                    onChange={(e) => {
                                        let currOptions = [
                                            ...values.image_options,
                                        ];
                                        currOptions[idx] = {
                                            ...values.image_options[idx],
                                            answer_value: e.target.value,
                                        };
                                        setFieldValue(
                                            `image_options`,
                                            currOptions
                                        );
                                    }}
                                    {...otherProps}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={1}
                            lg={1}
                        >
                            <FormControl fullWidth>
                                <IncorrectFieldSet>
                                    <Checkbox
                                        color="primary"
                                        checked={Boolean(option?.incorrect)}
                                        onChange={(e) => {
                                            let currImageOptions = [
                                                ...values.image_options,
                                            ];
                                            currImageOptions[idx] = {
                                                ...values.image_options[idx],
                                                incorrect: e.target.checked,
                                            };
                                            setFieldValue(
                                                `image_options`,
                                                currImageOptions
                                            );
                                        }}
                                    />
                                </IncorrectFieldSet>
                            </FormControl>
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={1}
                            lg={1}
                        >
                            <FormControl fullWidth>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        handleRemove(idx);
                                    }}
                                >
                                    Remove
                                </Button>{' '}
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            )}
        </Draggable>
    );
};

export default ImageAnswerField;
