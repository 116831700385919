import React, { useEffect } from 'react';
import { PageHeader } from 'components';
import { useStyles } from '../styles';
import { FiArrowLeft, FiGrid } from 'react-icons/fi';
import { VideoEditor } from './components/videoEditor';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { resetVideoEditor } from './videoImportSlice';
import {
    getEditAsset,
    selectEditAsset,
    selectEditAssetLoadingStatus,
} from '_features/assets/editAssetSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';

function VideoImporter() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const asset = useSelector(selectEditAsset);
    const assetStatus = useSelector(selectEditAssetLoadingStatus);

    let params = useParams();
    let id = params.id;

    useEffect(() => {
        async function fetchAsset() {
            await dispatch(getEditAsset(id));
        }
        dispatch(resetVideoEditor());
        fetchAsset();
    }, [id, dispatch]);

    useEffect(() => {
        if (asset?.isOrbitZoneExist) {
            navigate(`/asset/${id}`);
        }
    }, [asset, id]);

    const title = `Asset Video/Images Import`;

    return assetStatus === LoadingStatus.Loaded ? (
        <div
            style={{
                height: 'calc(100vh)',
                width: 'calc(100% - 165px)',
            }}
            className={classes.content}
        >
            <PageHeader header={title} icon={<FiGrid />} />
            <div
                className={classes.assetHeader}
                style={{ marginBottom: '10px' }}
            >
                <Link to={`/asset/${id}`} className={classes.backArrow}>
                    <FiArrowLeft />
                </Link>
                <span>{asset.display_name}</span>
            </div>
            <div
                style={{
                    width: '100%',
                    height: 'calc(100% - 10px)',
                    position: 'relative',
                    overflow: 'auto',
                }}
            >
                <VideoEditor />
            </div>
        </div>
    ) : (
        <LoadingLogo
            styleClassName={classes.loadingSvg}
            rotateClassName={classes.rotateSvg}
        />
    );
}

export { VideoImporter };
