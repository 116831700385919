import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    virtualDragger: {
        width: '20px',
        cursor: 'ew-resize',
        padding: '4px 0 0',
        position: 'absolute',
        top: 0,
        left: '50%',
        bottom: 0,
        pointerEvents: 'all',
    },
    dragger: {
        width: '3px',
        padding: '4px 0 0',
        borderTop: '1px solid #ddd',
        position: 'absolute',
        top: 0,
        left: '50%',
        bottom: 0,
        backgroundColor: '#000000',
    },
    draggerParent: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: 'calc(100vh - 228px)',
        zIndex: '99',
        pointerEvents: 'none',
    },
}));

export { useStyles };
