import { makeStyles } from '@mui/styles';

const dataNotFoundStyles = makeStyles(() => ({
    container: {
        height: '100vh',
        width: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: '25px 50px',
        overflow: 'auto',
    },
    content: {
        height: '100%',
        width: '100%',
        flexGrow: 1,
        padding: '25px 50px',
        overflow: 'auto',
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    text: {
        fontSize: '24px',
        fontWeight: 300,
        color: '#BDC3C7',
        marginBottom: '20px',
        textAlign: 'center',
    },
    image: {
        marginTop: '30px',
        height: '200px',
    },
}));

export default dataNotFoundStyles;
