import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    MdPauseCircle,
    MdClosedCaption,
    MdPlayCircle,
    MdClosedCaptionDisabled,
    MdRedo,
    MdUndo,
    MdSearch,
} from 'react-icons/md';
import { TbRewindBackward10, TbRewindForward10 } from 'react-icons/tb';
import {
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    DialogActions,
    Slider,
    Select,
    MenuItem,
    Toolbar,
    IconButton,
} from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import {
    getVttEditModalContent,
    getVttEditModalVis,
    setVttEditModalContent,
    toggleVttEditModal,
} from '_features/globals/visibilitySlice';
import { CaptionLanguages } from 'GeminiViewerComponent/_helpers';
import Loader from 'GeminiViewerComponent/components/Loader';
import {
    fetchContentVersions,
    updateContentVersionCaptions,
} from '_features/content/contentSlice';
import {
    EditContainer,
    EditControls,
    FixedColumn,
    FullScreenDialog,
    ScrollableColumn,
    TextInputContainer,
    vttEditorStyles,
} from './styles';
import { useDebounce } from 'GeminiViewerComponent/hooks/useDebounce';
import UnsavedCaptionsDialog from './UnsavedCaptionsDialog';
import {
    activateLoading,
    deactivateLoading,
    setProgress,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { getUrlInfoFromContentVersion } from '_helpers/vttHelpers';
import { makeFormStyles } from 'forms/styles';
import { TinyMCEEditor } from 'components/TinyMCEEditor';
import { useTinyMCETextInit } from 'hooks/useTinyMCETextInit';

const VttEditor = () => {
    //#region Selectors

    const theme = useSelector(selectActiveTheme);
    const content = useSelector(getVttEditModalContent);
    const modalVis = useSelector(getVttEditModalVis);

    //#endregion Selectors

    //#region State

    const [selectedVersion, setSelectedVersion] = useState(null);
    const [selectedContentVersionId, setSelectedContentVersionId] =
        useState(null);
    const [currentCue, setCurrentCue] = useState(null);
    const [urlInfo, setUrlInfo] = useState(null);
    const [cues, setCues] = useState([]);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [playbackRate, setPlaybackRate] = useState(1.0);
    const playbackRates = [0.5, 0.75, 1.0, 1.25, 1.5];
    const [areCaptionsEnabled, setAreCaptionsEnabled] = useState(true);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [activeTrack, setActiveTrack] = useState(0);
    const [editedTracks, setEditedTracks] = useState([]);
    const [openUnsavedDialog, setOpenUnsavedDialog] = useState(false);
    const [isEditingSource, setIsEditingSource] = useState(false);
    const [vttSource, setVttSource] = useState('');
    const [sourceChanged, setSourceChanged] = useState(false);

    //#endregion State

    //#region Refs

    const videoRef = useRef(null);
    const cueRefs = useRef([]);
    const applyChangesMethodRef = useRef(null);

    //#endregion Refs

    //#region Hooks

    const dispatch = useDispatch();
    const classes = vttEditorStyles(theme);
    const formClasses = makeFormStyles(theme);

    //#endregion Hooks

    //#region Constants

    //#endregion Constants

    //#region Methods

    const formatDisplayTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${pad(hours, 2)}:${pad(minutes, 2)}:${seconds
            .toString()
            .padStart(2, '0')}`;
    };

    const formatTime = useCallback((time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = Math.floor(time % 60);
        const milliseconds = Math.floor((time % 1) * 1000);

        return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}.${pad(
            milliseconds,
            3
        )}`;
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateStartTime = useCallback(
        (index, value) => {
            const newTime = parseTime(value);
            const updatedCues = [...cues];
            const updatingCue = updatedCues[index];
            updatingCue.cue.startTime = newTime;
            setCues(updatedCues);
            if (!editedTracks.includes(activeTrack)) {
                editedTracks.push(activeTrack);
                setEditedTracks(editedTracks);
            }
        },
        [cues, setCues, editedTracks, activeTrack]
    );

    const updateEndTime = useCallback(
        (index, value) => {
            const newTime = parseTime(value);
            const updatedCues = [...cues];
            const updatingCue = updatedCues[index];
            updatingCue.cue.endTime = newTime;
            setCues(updatedCues);
            if (!editedTracks.includes(activeTrack)) {
                editedTracks.push(activeTrack);
                setEditedTracks(editedTracks);
            }
        },
        [cues, setCues, editedTracks, activeTrack]
    );

    const debouncedStartTimeChange = useDebounce(updateStartTime, 1000);
    const debouncedEndTimeChange = useDebounce(updateEndTime, 1000);

    const handleStartTimeChange = useCallback(
        (index, value) => {
            // Update just the displayed value first
            const updatedCues = [...cues];
            updatedCues[index].startTime = value;
            setCues(updatedCues);
            // Update actual cue time after a delay
            debouncedStartTimeChange(index, value);
        },
        [cues, setCues, debouncedStartTimeChange]
    );

    const handleEndTimeChange = useCallback(
        (index, value) => {
            // Update just the displayed value first
            const updatedCues = [...cues];
            updatedCues[index].endTime = value;
            setCues(updatedCues);
            // Update actual cue time after a delay
            debouncedEndTimeChange(index, value);
        },
        [cues, setCues, debouncedEndTimeChange]
    );

    const handleTextChange = (index, value) => {
        const updatedCues = [...cues];
        updatedCues[index].cue.text = value;
        setCues(updatedCues);
        if (!editedTracks.includes(activeTrack)) {
            editedTracks.push(activeTrack);
            setEditedTracks(editedTracks);
        }
    };

    const parseTime = (timeString) => {
        const [hours, minutes, secondsAndMs] = timeString.split(':');
        const [seconds, milliseconds] = secondsAndMs.split('.');

        return (
            parseInt(hours) * 3600 +
            parseInt(minutes) * 60 +
            parseInt(seconds) +
            parseInt(milliseconds) / 1000
        );
    };

    const handleTimeUpdate = () => {
        const currentTime = videoRef.current.currentTime;
        setCurrentTime(currentTime);
        const currentCue = cues.find(
            (cue) =>
                currentTime >= cue.cue.startTime &&
                currentTime <= cue.cue.endTime
        );

        if (currentCue && cueRefs.current) {
            const activeCueElement = cueRefs.current[currentCue.cue.id];
            if (activeCueElement) {
                activeCueElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }

        setCurrentCue(currentCue);
    };

    const parseWebVTT = (webVTTText) => {
        const lines = webVTTText.split('\n').map((line) => line.trim());
        const cues = [];
        let i = 0;

        while (i < lines.length) {
            if (lines[i].includes('-->')) {
                const [startTime, endTime] = lines[i]
                    .split(' --> ')
                    .map(timeToSeconds);
                let text = '';
                i++;
                while (i < lines.length && lines[i] !== '') {
                    text += lines[i] + '\n';
                    i++;
                }
                cues.push({ startTime, endTime, text: text.trim() });
            }
            i++;
        }

        return cues;
    };

    const timeToSeconds = (time) => {
        const parts = time.split(':');
        return (
            parseFloat(parts[0]) * 3600 +
            parseFloat(parts[1]) * 60 +
            parseFloat(parts[2].replace(',', '.'))
        );
    };

    const updateCues = (textTrack, cues) => {
        // console.log(`Updating cues on active track`);

        // Clear existing cues
        while (textTrack.cues.length > 0) {
            textTrack.removeCue(textTrack.cues[0]);
        }

        // Add new cues
        cues.forEach((cue, index) => {
            const newCue = new VTTCue(cue.startTime, cue.endTime, cue.text);
            newCue.id = index + 1;
            textTrack.addCue(newCue);
        });
    };

    const handleSourceChange = (newSource) => {
        // console.log(`handleSourceChange called.`);

        const videoElement = videoRef.current;
        const textTracks = videoElement.textTracks;

        if (textTracks.length > 0) {
            const updatedCues = parseWebVTT(newSource);

            const textTrack = videoElement.textTracks[activeTrack];
            updateCues(textTrack, updatedCues);

            const trackCues = Array.from(textTrack.cues).map((cue) => {
                return {
                    startTime: formatTime(cue.startTime),
                    endTime: formatTime(cue.endTime),
                    cue: cue,
                };
            });

            // console.log(`Updating track cues from source.`);

            setCues(trackCues);

            if (!editedTracks.includes(activeTrack)) {
                editedTracks.push(activeTrack);
                setEditedTracks(editedTracks);
            }
        }
    };

    const handleSubtitleClick = (start) => {
        videoRef.current.currentTime = start;
    };

    const handleClose = (force = false) => {
        if (!force && editedTracks.length > 0) {
            setOpenUnsavedDialog(true);
            return;
        }

        setVttEditModalContent(null);
        setSelectedVersion(null);
        setSelectedContentVersionId(null);
        setCurrentCue(null);
        setUrlInfo(null);
        setCues([]);
        setIsVideoLoaded(false);
        setIsPlaying(false);
        setCurrentTime(0);
        setDuration(0);
        setActiveTrack(0);
        setEditedTracks([]);
        setIsEditingSource(false);
        setVttSource('');
        setSourceChanged(false);

        dispatch(toggleVttEditModal());
    };

    const handleSave = () => {
        saveSubtitles();
    };

    const saveSubtitles = async () => {
        if (editedTracks.length == 0) {
            return;
        }

        dispatch(activateLoading({ showProgress: true }));

        dispatch(
            setProgress({
                progress: 0,
                label: 'Step 1 of 2: Uploading',
                noAnimation: true,
            })
        );

        const videoElement = videoRef.current;
        const textTracks = videoElement.textTracks;

        // Default all caption files to null - only update ones that have changed
        const captionFiles = new Array(
            Object.keys(urlInfo.vttUrls).length
        ).fill(null);

        for (const trackIdx in editedTracks) {
            const textTrack = textTracks[trackIdx];
            if (textTrack) {
                if (textTrack.cues) {
                    let vttContent = 'WEBVTT\n\n';
                    for (let i = 0; i < textTrack.cues.length; i++) {
                        let cue = textTrack.cues[i];
                        vttContent += `${cue.id}\n${formatTime(
                            cue.startTime
                        )} --> ${formatTime(cue.endTime)}\n${cue.text}\n\n`;
                    }

                    const blob = new Blob([vttContent], {
                        type: 'text/vtt',
                    });

                    var language = Object.keys(urlInfo.vttUrls)[trackIdx];

                    var filename = urlInfo.vttUrls[language].split('/').pop();

                    captionFiles[trackIdx] = new File([blob], filename);
                } else {
                    // console.log(`No cues loaded for track ${activeTrack}`);
                }
            }
        }

        var contentVersionsParams = {
            content_id: content.content_id,
            caption_files: captionFiles,
            content_version_id: selectedContentVersionId,
        };

        var resultAction = await dispatch(
            updateContentVersionCaptions({
                ...contentVersionsParams,
            })
        );

        dispatch(deactivateLoading());

        if (resultAction.meta.requestStatus === 'rejected') {
            throw new SyntaxError(`${resultAction?.error?.message}`);
        } else {
            setEditedTracks([]);
        }
    };

    const pad = (number, length) => {
        return String(number).padStart(length, '0');
    };

    const handlePlayPauseToggle = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
    };

    const handleSkipBack = () => {
        videoRef.current.currentTime -= 10;
    };

    const handleSkipForward = () => {
        videoRef.current.currentTime += 10;
    };

    const handlePlaybackRateChange = (event) => {
        const selectedRate = parseFloat(event.target.value);
        videoRef.current.playbackRate = selectedRate;
        setPlaybackRate(selectedRate);
    };

    const setCaptionVisibility = (trackIdx, enabled) => {
        const tracks = videoRef.current.textTracks;
        for (let i = 0; i < tracks.length; i++) {
            tracks[i].mode =
                i == trackIdx ? (enabled ? 'showing' : 'hidden') : 'hidden';
        }
    };

    const handleToggleCaptions = () => {
        setCaptionVisibility(activeTrack, !areCaptionsEnabled);
        setAreCaptionsEnabled(!areCaptionsEnabled);
    };

    const handleSeekChange = (event, newValue) => {
        videoRef.current.currentTime = newValue;
        setCurrentTime(newValue);
    };

    const handleLanguageChange = (event) => {
        setActiveTrack(event.target.value);
        setCaptionVisibility(event.target.value, areCaptionsEnabled);
    };

    const handleEditSourceToggle = (event) => {
        if (isEditingSource && sourceChanged) {
            // console.log(`Source has changed, applying changes`);
            if (applyChangesMethodRef?.current) {
                applyChangesMethodRef.current();
            }
            setSourceChanged(false);
        }
        setIsEditingSource(!isEditingSource);
    };

    //#endregion Methods

    //#region Effects

    useEffect(() => {
        const _fetchContentVersions = async (content_id) => {
            const versions = await dispatch(fetchContentVersions(content_id));
            if (versions.payload && versions.payload?.length > 0) {
                let contentVersion = [...versions.payload].pop();
                setSelectedContentVersionId(contentVersion.content_version_id);
                setSelectedVersion(contentVersion?.version);
                setCues([]);
                setIsVideoLoaded(false);
                setUrlInfo(getUrlInfoFromContentVersion(contentVersion));
            }
        };

        setSelectedVersion(null);
        setSelectedContentVersionId(null);
        if (content?.content_id !== undefined && modalVis) {
            _fetchContentVersions(content.content_id);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content, modalVis]);

    useEffect(() => {
        const video = videoRef.current;
        // const track = trackRef.current;

        const handleVideoLoadedMetadata = () => {
            setIsVideoLoaded(true);
            setDuration(videoRef.current.duration);

            const textTracks = videoRef.current.textTracks;

            for (let i = 0; i < textTracks.length; i++) {
                if (textTracks[i].mode == 'disabled') {
                    textTracks[i].mode = 'hidden';
                }
            }
        };

        const handlePlayPause = () => {
            setIsPlaying(!videoRef.current.paused);
        };

        if (video) {
            video.addEventListener('loadedmetadata', handleVideoLoadedMetadata);
            video.addEventListener('play', handlePlayPause);
            video.addEventListener('pause', handlePlayPause);
        }

        return () => {
            if (video) {
                video.removeEventListener(
                    'loadedmetadata',
                    handleVideoLoadedMetadata
                );
                video.removeEventListener('play', handlePlayPause);
                video.removeEventListener('pause', handlePlayPause);
            }
        };
    }, [urlInfo, activeTrack]);

    useEffect(() => {
        // console.log(
        //     `Resetting vttSource because cues or currentCue changed. currentCue=${
        //         currentCue ? currentCue.cue.id : 'n/a'
        //     }`
        // );

        let vttContent = 'WEBVTT<br /><br />';
        for (let i = 0; i < cues.length; i++) {
            let cue = cues[i];
            vttContent += `${cue.cue.id}<br />${formatTime(
                cue.cue.startTime
            )} --> ${formatTime(cue.cue.endTime)}<br />${
                cue.cue.text
            }<br /><br />`;
        }

        if (!isEditingSource) {
            setVttSource(vttContent);
        }
    }, [cues, formatTime, isEditingSource]);

    useEffect(() => {
        const loadCues = () => {
            if (!isVideoLoaded) {
                return;
            }

            const videoElement = videoRef.current;
            const textTracks = videoElement.textTracks;

            if (textTracks.length > 0) {
                // console.log(`Checking for cues on track ${activeTrack}`);
                const textTrack = videoElement.textTracks[activeTrack];
                if (textTrack) {
                    if (textTrack.cues) {
                        // console.log(
                        //     `Cues length =  ${
                        //         Array.from(textTrack.cues).length
                        //     }`
                        // );
                        const trackCues = Array.from(textTrack.cues).map(
                            (cue) => {
                                return {
                                    startTime: formatTime(cue.startTime),
                                    endTime: formatTime(cue.endTime),
                                    cue: cue,
                                };
                            }
                        );
                        setCues(trackCues);
                    } else {
                        // console.log(`No cues loaded for track ${activeTrack}`);
                    }
                } else {
                    // console.log(`No track found at ${activeTrack}`);
                }
            }
        };
        setTimeout(() => {
            // Need slight delay to make sure cues are actually loaded
            loadCues();
        }, 500);
    }, [activeTrack, formatTime, isVideoLoaded]);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (!videoElement) {
            return;
        }

        const textTracks = videoElement.textTracks;

        for (let i = 0; i < textTracks.length; i++) {
            textTracks[i].mode = i == activeTrack ? 'showing' : 'hidden';
        }
    }, [activeTrack, isVideoLoaded]);

    useEffect(() => {
        // console.log(`vtt source changed. Len = ${vttSource.length}`);
    }, [vttSource]);

    //#endregion Effects

    //#region Render time calcs

    if (!modalVis) {
        return null;
    }

    //#endregion Render time calcs

    //#region Render

    return (
        <div>
            <FullScreenDialog open={modalVis} fullScreen disableEnforceFocus>
                {!urlInfo ? (
                    <Loader
                        styles={{
                            width: '100%',
                            marginTop: '8px',
                            marginBottom: '4px',
                            height: '56px',
                        }}
                        enableBorder
                    />
                ) : (
                    <>
                        <DialogTitle>
                            Editing for {content.display_name}
                            {editedTracks.length > 0 ? ' (edited)' : ''}
                        </DialogTitle>
                        <DialogContent
                            dividers
                            style={{ display: 'flex', padding: 0 }}
                        >
                            <FixedColumn>
                                <video
                                    ref={videoRef}
                                    src={urlInfo.url}
                                    crossOrigin="anonymous"
                                    onTimeUpdate={handleTimeUpdate}
                                    className={classes.video}
                                >
                                    {Object.keys(urlInfo.vttUrls).map(
                                        (language) => (
                                            <track
                                                key={`caption-${language}`}
                                                kind="subtitles"
                                                src={urlInfo.vttUrls[language]}
                                                label={
                                                    Object.values(
                                                        CaptionLanguages
                                                    )?.find(
                                                        (lang) =>
                                                            lang.LanguageCode ===
                                                            language
                                                    )?.DisplayName ?? ''
                                                }
                                                srcLang={language}
                                                default
                                            />
                                        )
                                    )}
                                </video>

                                <div className={classes.controls}>
                                    <Button
                                        onClick={handlePlayPauseToggle}
                                        className={classes.iconButton}
                                    >
                                        {isPlaying ? (
                                            <MdPauseCircle />
                                        ) : (
                                            <MdPlayCircle />
                                        )}
                                    </Button>
                                    <Button
                                        onClick={handleSkipBack}
                                        className={classes.iconButton}
                                    >
                                        <TbRewindBackward10 />
                                    </Button>
                                    <Button
                                        onClick={handleSkipForward}
                                        className={classes.iconButton}
                                    >
                                        <TbRewindForward10 />
                                    </Button>
                                    <Button
                                        onClick={handleToggleCaptions}
                                        className={classes.iconButton}
                                    >
                                        {areCaptionsEnabled ? (
                                            <MdClosedCaption />
                                        ) : (
                                            <MdClosedCaptionDisabled />
                                        )}
                                    </Button>
                                    <Select
                                        value={playbackRate}
                                        onChange={handlePlaybackRateChange}
                                        className={classes.speedSelect}
                                    >
                                        {playbackRates.map((rate) => (
                                            <MenuItem key={rate} value={rate}>
                                                {rate}x
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>

                                <div className={classes.seekSliderContainer}>
                                    <Slider
                                        value={currentTime}
                                        min={0}
                                        max={duration}
                                        step={0.1}
                                        onChange={handleSeekChange}
                                        aria-labelledby="continuous-slider"
                                        className={classes.seekSlider}
                                    />
                                </div>

                                <Select
                                    value={activeTrack}
                                    onChange={handleLanguageChange}
                                    className={classes.captionSelect}
                                >
                                    {Object.keys(urlInfo.vttUrls).map(
                                        (language, index) => (
                                            <MenuItem
                                                key={language}
                                                value={index}
                                            >
                                                {language}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                                <div className={classes.timeDisplay}>
                                    {formatDisplayTime(currentTime)} /{' '}
                                    {formatDisplayTime(duration)}
                                </div>
                            </FixedColumn>
                            <EditContainer>
                                {isEditingSource ? (
                                    <VttSourceEditor
                                        vttSource={vttSource}
                                        setVttSource={setVttSource}
                                        handleSourceChange={handleSourceChange}
                                        setSourceChanged={setSourceChanged}
                                        setApplyChanges={(
                                            applyChangesMethod
                                        ) => {
                                            applyChangesMethodRef.current =
                                                applyChangesMethod;
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            className={classes.editButton}
                                            onClick={() =>
                                                handleEditSourceToggle()
                                            }
                                        >
                                            {isEditingSource
                                                ? 'CAPTION MODE'
                                                : 'SOURCE MODE'}
                                        </Button>
                                    </VttSourceEditor>
                                ) : (
                                    <VttCueEditor
                                        cues={cues}
                                        cueRefs={cueRefs}
                                        currentCue={currentCue}
                                        handleStartTimeChange={
                                            handleStartTimeChange
                                        }
                                        handleEndTimeChange={
                                            handleEndTimeChange
                                        }
                                        handleSubtitleClick={
                                            handleSubtitleClick
                                        }
                                        handleTextChange={handleTextChange}
                                        editButtons={
                                            <Button
                                                size="small"
                                                className={classes.editButton}
                                                onClick={() =>
                                                    handleEditSourceToggle()
                                                }
                                            >
                                                {isEditingSource
                                                    ? 'CAPTION MODE'
                                                    : 'SOURCE MODE'}
                                            </Button>
                                        }
                                    ></VttCueEditor>
                                )}
                            </EditContainer>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                className={formClasses.cancel}
                                onClick={() => handleClose()}
                                color="primary"
                            >
                                {editedTracks.length > 0 ? 'Cancel' : 'Close'}
                            </Button>
                            <Button
                                className={formClasses.submit}
                                onClick={() => handleSave()}
                                color="primary"
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </>
                )}
            </FullScreenDialog>
            <UnsavedCaptionsDialog
                openDialog={openUnsavedDialog}
                setOpenDialog={setOpenUnsavedDialog}
                handleClose={() => {
                    // console.log(`Abandoning changes`);
                    setOpenUnsavedDialog(false);
                    setEditedTracks([]);
                    handleClose(true);
                }}
            />
        </div>
    );

    //#endregion Render
};

const VttCueEditor = ({
    cues,
    cueRefs,
    currentCue,
    handleStartTimeChange,
    handleEndTimeChange,
    handleSubtitleClick,
    handleTextChange,
    children,
    editButtons,
}) => {
    //#region Selectors

    const theme = useSelector(selectActiveTheme);

    //#endregion Selectors

    //#region State
    //#endregion State

    //#region Refs
    //#endregion Refs

    //#region Hooks

    const classes = vttEditorStyles(theme);

    //#endregion Hooks

    //#region Constants
    //#endregion Constants

    //#region Methods
    //#endregion Methods

    //#region Effects
    //#endregion Effects

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render

    return (
        <>
            <EditControls>{editButtons}</EditControls>
            <ScrollableColumn>
                {cues.map((cue, index) => (
                    <TextInputContainer
                        key={index}
                        ref={(el) => (cueRefs.current[cue.cue.id] = el)}
                        className={clsx({
                            [classes.subtitleHighlight]: currentCue === cue,
                        })}
                        onClick={() =>
                            handleSubtitleClick(
                                cue.cue.startTime + 0.0001 // Skip a small amount to make sure it highlights the subtitle
                            )
                        }
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: '10px',
                            }}
                        >
                            <TextField
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                value={cue.startTime}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                onChange={(e) =>
                                    handleStartTimeChange(index, e.target.value)
                                }
                            />
                            <TextField
                                variant="outlined"
                                size="small"
                                style={{
                                    flex: 1,
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                value={cue.endTime}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                onChange={(e) =>
                                    handleEndTimeChange(index, e.target.value)
                                }
                            />
                        </div>
                        <TextField
                            variant="outlined"
                            size="small"
                            multiline
                            fullWidth
                            value={cue.cue.text}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            onChange={(e) =>
                                handleTextChange(index, e.target.value)
                            }
                        />
                    </TextInputContainer>
                ))}
            </ScrollableColumn>
        </>
    );
    //#endregion Render
};

const VttSourceEditor = ({
    vttSource,
    setVttSource,
    handleSourceChange,
    setSourceChanged,
    children,
    setApplyChanges,
}) => {
    //#region Selectors

    const theme = useSelector(selectActiveTheme);
    const init = useTinyMCETextInit();

    //#endregion Selectors

    //#region State

    const editorRef = useRef(null);

    //#endregion State

    //#region Refs
    //#endregion Refs

    //#region Hooks

    const classes = vttEditorStyles(theme);

    //#endregion Hooks

    //#region Constants
    //#endregion Constants

    //#region Methods
    //#endregion Methods

    //#region Effects

    //#endregion Effects

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render

    const applyChanges = () => {
        const editor = editorRef?.current;
        const text = editor.getContent({
            format: 'text',
        });
        // console.log(`Calling handleSourceChange for ${text}`);
        handleSourceChange(text);
        setSourceChanged(false);
        editor.undoManager.reset();
        editor.setDirty(false);
    };

    return (
        <>
            <EditControls>
                {children}
                <Toolbar
                    variant="compact"
                    sx={{
                        gap: '10px',
                        padding: '0px !important',
                    }}
                >
                    <Button
                        className={classes.editButton}
                        aria-label="Apply"
                        size="small"
                        disabled={!editorRef?.current?.isDirty()}
                        onClick={() => {
                            applyChanges();
                        }}
                    >
                        Apply Changes
                    </Button>
                    <IconButton
                        className={classes.sourceIconButton}
                        aria-label="Undo"
                        size="small"
                        disabled={!editorRef?.current?.undoManager.hasUndo()}
                        onClick={() => editorRef?.current?.execCommand('undo')}
                    >
                        <MdUndo />
                    </IconButton>
                    <IconButton
                        className={classes.sourceIconButton}
                        aria-label="Redo"
                        size="small"
                        disabled={!editorRef?.current?.undoManager.hasRedo()}
                        onClick={() => editorRef?.current?.execCommand('redo')}
                    >
                        <MdRedo />
                    </IconButton>
                    <IconButton
                        className={classes.sourceIconButton}
                        aria-label="Search and Replace"
                        size="small"
                        onClick={() =>
                            editorRef?.current?.execCommand('SearchReplace')
                        }
                    >
                        <MdSearch />
                    </IconButton>
                </Toolbar>
            </EditControls>
            <ScrollableColumn>
                <TinyMCEEditor
                    id="vttSource"
                    tinymceScriptSrc={
                        process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'
                    }
                    init={{
                        ...init,
                    }}
                    onInit={(evt, editor) => {
                        editorRef.current = editor;
                        setApplyChanges(applyChanges);
                    }}
                    textareaName="vttSource"
                    onEditorChange={(content, editor) => {
                        console.log(`onEditorChange called`);
                        setSourceChanged(editor.isDirty());
                        setVttSource(content);
                    }}
                    value={vttSource}
                />
            </ScrollableColumn>
        </>
    );

    //#endregion Render
};

export default VttEditor;
