import { useDispatch } from 'react-redux';
import {
    Card,
    CardMedia,
    Checkbox,
    Typography,
    Tooltip,
    Chip,
} from '@mui/material';
import { contentCardStyles } from './styles';
import { AppList, PopupActions } from 'components';
import { ContentTypes } from '_helpers';
import {
    setContentValidationModalContent,
    toggleContentValidationModal,
} from '_features/globals/visibilitySlice';

export default function ContentCard({
    content,
    width,
    handleClick,
    cellLevel,
    isSelected,
    readOnly,
}) {
    const dispatch = useDispatch();
    const classes = contentCardStyles();
    const show_size_dimensions =
        (content?.encoding_status &&
            content?.encoding_status.toLowerCase() === 'complete') ||
        !content?.encoding_status;
    const rowDataId = content[`${cellLevel}_id`];
    const handleViewLink = (content) => {
        dispatch(setContentValidationModalContent(content));
        dispatch(toggleContentValidationModal());
    };

    const image = [
        ContentTypes.Jpeg.id,
        ContentTypes.Jpg.id,
        ContentTypes.Png.id,
        ContentTypes.Svg.id,
    ].includes(content.content_type_id)
        ? content?.url
        : ContentTypes[
              Object.keys(ContentTypes).find(
                  (at) => ContentTypes[at].id === content.content_type_id
              )
          ].img;

    const convertBytesToMB = (data) => {
        if (data) {
            let bytesToMB = data / 1024 / 1024;
            if (bytesToMB < 0.1) {
                return data + ' bytes';
            }
            return bytesToMB.toFixed(1) + ' MB';
        }
        return data;
    };

    return (
        <Card className={classes.card} style={{ width: width }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <div>
                    {readOnly !== true && (
                        <Checkbox
                            color="primary"
                            checked={isSelected(rowDataId)}
                            onClick={(event) => handleClick(event, rowDataId)}
                        />
                    )}
                </div>
                <div
                    style={{
                        float: 'left',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                    }}
                >
                    {!show_size_dimensions && (
                        <Chip
                            label={`${content?.encoding_status} - ${content?.encoding_percent}%`}
                            sx={{ height: '20px' }}
                            title="Encoding Status"
                        />
                    )}
                    {show_size_dimensions && content?.file_size && (
                        <Chip
                            label={convertBytesToMB(content?.file_size)}
                            sx={{ height: '20px' }}
                            title="File Size"
                        />
                    )}
                    {show_size_dimensions && content?.file_width && (
                        <Chip
                            label={`${content?.file_width}x${content?.file_height}`}
                            sx={{ height: '20px' }}
                            title="File Dimensions"
                        />
                    )}
                    <Chip
                        label={content?.asset_id ? 'Asset' : 'Global'}
                        sx={{ height: '20px' }}
                        title="Content Origin"
                    />
                    <PopupActions
                        object={content}
                        level={'content'}
                        targetPopup={'content'}
                    />
                </div>
            </div>
            <div>
                <div style={{ margin: 'auto 10px' }}>
                    <AppList
                        data={{ assets: content?.asset_usage ?? [] }}
                        listHeader={`Usage (${
                            content?.asset_usage?.length ?? 0
                        })`}
                        keyProperty="asset_name"
                        nameProperty="asset_name"
                        zoneKey="zones"
                        id="content-usage-id"
                        zoneProperty="zone_name"
                        itemKey="items"
                        itemProperty="item_name"
                        linkKey="links"
                        linkProperty="link_name"
                    />
                </div>
            </div>
            <div style={{ margin: '5px 10px' }}>
                <Typography
                    className={classes.cardDescription}
                    title={content.display_name}
                >
                    {content.display_name}
                </Typography>
                <Typography className={classes.contentType}>
                    {Object.keys(ContentTypes).find(
                        (at) => ContentTypes[at].id === content.content_type_id
                    )}
                </Typography>
            </div>
            <Tooltip title={`Preview ${content.display_name}`}>
                <CardMedia
                    component={typeof image === 'string' ? 'div' : image}
                    onClick={() => handleViewLink(content)}
                    image={typeof image === 'string' ? image : null}
                    className={classes.cardImage}
                />
            </Tooltip>
        </Card>
    );
}
