import React, { useState } from 'react';
import { AppBar, Box, Tab, Tabs, Typography } from '@mui/material';

import { AssetExportsTab } from 'components/_Layout/AssetExportsTab';
import { MediaConversionTab } from 'components/_Layout/MediaConversionTab';
import { DeploymentPackageStatusTab } from './DeploymentPackageStatusTab';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`job-tabpanel-${index}`}
            aria-labelledby={`job-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `job-tab-${index}`,
        'aria-controls': `job-tabpanel-${index}`,
    };
}

const StatusSubTabPanel = ({ DeploymentPackageJobsEnabled }) => {
    //#region Constants
    let tabBarIndex = -1;
    let tabPageIndex = -1;
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    const [displayTabIndex, setDisplayTabIndex] = useState(0);
    //#endregion State

    //#region Selectors
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    //#endregion Effects

    //#region Methods
    const handleChange = (event, newValue) => {
        setDisplayTabIndex(newValue);
    };
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render
    return (
        <>
            <AppBar position="static" color="default">
                <Tabs
                    value={displayTabIndex}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {DeploymentPackageJobsEnabled === 'true' && (
                        <Tab
                            label="Deployment Package Job"
                            {...a11yProps(++tabBarIndex)}
                        />
                    )}
                    <Tab label="Asset Exports" {...a11yProps(++tabBarIndex)} />
                    <Tab
                        label="Media Conversions"
                        {...a11yProps(++tabBarIndex)}
                    />
                </Tabs>
            </AppBar>
            {DeploymentPackageJobsEnabled === 'true' && (
                <TabPanel value={displayTabIndex} index={++tabPageIndex}>
                    <DeploymentPackageStatusTab />
                </TabPanel>
            )}
            <TabPanel value={displayTabIndex} index={++tabPageIndex}>
                <AssetExportsTab />
            </TabPanel>
            <TabPanel value={displayTabIndex} index={++tabPageIndex}>
                <MediaConversionTab />
            </TabPanel>
        </>
    );
    //#endregion Render
};
export { StatusSubTabPanel };
