import { makeStyles } from '@mui/styles';
import login_image from 'assets/login_image.png';

const useStyles = makeStyles(() => ({
    arrangement: {
        display: 'flex',
        flexDirection: 'column',
    },
    spacing: {
        marginBottom: '20px',
    },
    loginContainer: {
        minHeight: '100vh',
        padding: '50px',
        backgroundColor: '#FFFFFF',
        boxShadow: '-4px 0 29px 0 rgba(45,61,80,0.52)',
    },
    loginGridItem: {
        width: '100%',
        display: 'flex',
    },
    loginForm: {
        width: '100%',
    },
    resetForm: {
        width: '100%',
    },
    logo: {
        height: '66px',
    },
    formHeader: {
        color: '#2D3D50',
        fontWeight: 300,
        fontSize: '19px',
    },
    forgotLogin: {
        fontSize: '14px',
        color: '#2D3D50',
        textDecoration: 'underline',
        cursor: 'pointer',
        fontWeight: 600,
    },
    loginError: {
        marginTop: '35px',
        border: '2px solid #E84C3C',
        color: '#2D3D50',
        fontSize: '12px',
    },
    ssoErrorEmail: {
        display: 'block',
        background: 'rgba(150,150,150,.25)',
        paddin: '5px',
        textAlign: 'center',
    },
    formSubtitle: {
        fontSize: '14px',
        color: '#2D3D50',
        marginBottom: '30px',
    },
    resetButtons: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    resetButton: {
        width: '48%',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    cancel: {
        width: '100%',
    },
    hide: {
        display: 'none',
    },
    backgroundContainer: {
        width: '100%',
        background: `no-repeat url(${login_image})`,
        backgroundSize: 'cover',
    },
    version: {
        fontSize: '12px',
        marginLeft: '5px',
    },
    // app svg loader
    '@keyframes rotate': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' },
    },
    loadingSvg: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        height: '100px',
        width: '100px',
    },
    rotateSvg: {
        marginLeft: '-50px',
        marginTop: '-50px',
        animationName: '$rotate',
        animationDuration: '1.5s',
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: 'infinite',
    },
}));

export default useStyles;
