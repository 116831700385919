import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

export const DiscardCategoryChangeModal = ({
    openModal,
    setOpenModal,
    discardChanges,
}) => {
    return (
        <Dialog open={openModal} onClose={() => setOpenModal(false)}>
            <DialogTitle id="alert-dialog-title">
                Discard Category Changes
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    If you continue, all changes will be lost. Are you sure?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenModal(false)}
                >
                    KEEP EDITING
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={discardChanges}
                >
                    LOSE CHANGES
                </Button>
            </DialogActions>
        </Dialog>
    );
};
