import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import {
    fetchPartGroupPage,
    getPartGroupPageInfo,
    getPartGroupStatus,
    resetPartGroupState,
    selectPartGroup,
} from '_features/parts/partGroupSlice';
import { PartGroupsTable } from './PartGroupsTable';

const PartGroupsTab = () => {
    const dispatch = useDispatch();
    const ref = useRef(false);
    const partGroup = useSelector(selectPartGroup);
    const partGroupStatus = useSelector(getPartGroupStatus);
    const partGroupPageInfo = useSelector(getPartGroupPageInfo);
    const [partGroupPayload, setPartGroupPayload] = useState({});
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const getAllPartGroup = async (payload) => {
        dispatch(activateLoading());
        await dispatch(fetchPartGroupPage(payload));
        dispatch(deactivateLoading());
    };

    useEffect(() => {
        if (ref.current === true) {
            getAllPartGroup({ ...partGroupPayload });
        }
    }, [partGroupPayload]);

    useEffect(() => {
        return () => {
            dispatch(resetPartGroupState());
        };
    }, []);

    useEffect(() => {
        if (partGroupStatus === LoadingStatus.Idle) {
            getAllPartGroup({ ...partGroupPayload });
        }
        ref.current = true;
    }, [partGroupStatus]);

    const setSorting = (_sortBy, _sortDirection) => {
        setSortBy(_sortBy);
        setSortDirection(_sortDirection);
        setPartGroupPayload({
            ...partGroupPayload,
            sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
        });
    };
    return (
        <PartGroupsTable
            partGroups={partGroup}
            pageInfo={partGroupPageInfo}
            partGroupPayload={partGroupPayload}
            setPartGroupPayload={setPartGroupPayload}
            sortDirection={sortDirection}
            sortBy={sortBy}
            handleSort={(_sortBy, _sortDirection) =>
                setSorting(_sortBy, _sortDirection)
            }
        />
    );
};

export { PartGroupsTab };
