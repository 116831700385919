import { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import { FormActions } from '_helpers/form-action';
import { snackbarHandler } from 'GeminiViewerComponent/_helpers/snackbar-handler';

import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import SetDirtyForm from 'forms/SetDirtyForm';
import { closePanel } from '_features/common/formSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { LoadingLogo } from 'GeminiViewerComponent/components/LoadingLogo/LoadingLogo';
import { FormTextField } from 'components/TextField';
import { makeFormStyles } from 'forms/styles';
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { SelectionAutocomplete } from 'components';
import {
    fetchAllAssets,
    resetAssetsTableState,
    selectAssetPage,
    selectAssetsLoadingStatus,
    setAssetsStatus,
} from '_features/assets/assetsTableSlice';
import {
    addManagementDeployment,
    editManagementDeployment,
    fetchDeployementById,
} from '_features/jobs/jobsSlice';
import { useConfigValues } from 'hooks/useConfigValues';
import { accountsSlice } from 'app/store';
import { selectConfigValuesByFields } from '_features/configValues/configValuesSlice';
import { LoadingStatus } from 'GeminiViewerComponent/_helpers/AsyncStatus';

const DeploymentPackageForm = ({ formAction, deploymentId }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [loaded, setLoaded] = useState(
        formAction.id === FormActions.Create.id ? true : false
    );
    const activeUser = useSelector(accountsSlice.selectActiveUser);

    const INIT_VALUES = {
        name: '',
        viewer_authentication_id: 1,
        include_gais: false,
        include_gaps: false,
        include_landing_json: false,
        include_web_config: false,
        asset_ids: [],
        client_name_on_landing: activeUser?.client_name || null,
    };
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    const [initValues, setInitValues] = useState({ ...INIT_VALUES });
    const { configValues, configFields } = useConfigValues({
        clientId: activeUser?.client_id,
    });

    const {
        GaisApplicationAvailable: gaisApplicationAvailable,
        GapsApplicationAvailable: gapsApplicationAvailable,
        GaisAuthenticationEnabled: gaisAuthenticationEnable,
    } = selectConfigValuesByFields(configValues, configFields, [
        'GaisApplicationAvailable',
        'GapsApplicationAvailable',
        'GaisAuthenticationEnabled',
    ]);

    useEffect(() => {
        if (formAction.id === FormActions.Edit.id) {
            const fetchData = async () => {
                var objectData = await dispatch(
                    fetchDeployementById(deploymentId)
                );
                var fetchedObject = objectData.payload;
                var assetId = fetchedObject?.assets.map(
                    (item) => item.asset_id
                );
                var procedureId = fetchedObject?.assets?.map(
                    (item) => item.asset_id
                );
                setInitValues({
                    name: fetchedObject?.name,
                    viewer_authentication_id:
                        fetchedObject?.viewer_authentication_id,
                    include_gais: fetchedObject?.include_gais,
                    include_gaps: fetchedObject?.include_gaps,
                    include_landing_json: fetchedObject?.include_landing_json,
                    include_web_config: fetchedObject?.include_web_config,
                    asset_ids: assetId,
                    procedure_ids: procedureId,
                    client_name_on_landing:
                        fetchedObject?.client_name_on_landing ||
                        activeUser?.client_name,
                });
                setLoaded(true);
            };
            fetchData();
        } else {
            setInitValues({ ...INIT_VALUES });
        }
    }, [deploymentId]);

    useEffect(() => {
        return () => {
            dispatch(resetAssetsTableState);
            dispatch(setAssetsStatus(LoadingStatus.Idle));
        };
    }, []);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        dispatch(activateLoading());
        var resultAction = null;
        let combinedIds = new Map();
        if (
            (values?.asset_ids || []).length > 0 ||
            (values?.procedure_ids || []).length > 0
        ) {
            values?.asset_ids?.map((i) => combinedIds.set(i, i));
            values?.procedure_ids?.map((i) => combinedIds.set(i, i));
        }
        const newData = {
            ...values,
            asset_ids: Object.values(Object.fromEntries(combinedIds)) ?? [],
        };
        delete newData?.procedure_ids;
        if (!values?.include_landing_json) {
            delete newData?.client_name_on_landing;
        }
        switch (formAction.id) {
            case FormActions.Edit.id:
                resultAction = await dispatch(
                    editManagementDeployment({
                        deploymentId,
                        packageData: { ...newData },
                    })
                );
                break;
            case FormActions.Create.id:
                resultAction = await dispatch(
                    addManagementDeployment({ ...newData })
                );
                resetForm();
                break;
            default:
                break;
        }
        dispatch(deactivateLoading());
        await dispatch(closePanel({ formKey: 'managementDeployForm' }));
        const { message, variant } = snackbarHandler(
            resultAction.meta.requestStatus,
            formAction.label
        );
        enqueueSnackbar(message, {
            action: (key) => <SnackbarDismiss key={key} />,
            variant: variant,
        });
        setSubmitting(false);
    };

    return !loaded ? (
        <LoadingLogo styleClassName={classes.loadingSvg} />
    ) : (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values, setSubmitting, resetForm);
            }}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form
                    className={classes.form}
                    style={{ marginBottom: '2rem', gap: '0.5rem' }}
                >
                    <div className={classes.formHeader}>
                        Deployment Package Details
                    </div>
                    <FormTextField
                        label="Name"
                        name="name"
                        placeholder="Name"
                    />
                    <Typography sx={{ mt: '0.5rem' }}>
                        How should the Viewer authenticate users?
                    </Typography>
                    <RadioGroup
                        style={{
                            display: 'flex',

                            flexDirection: 'row',

                            minWidth: '150px',
                        }}
                        name="viewer_authentication_id"
                        value={values.viewer_authentication_id}
                        onChange={(e) => {
                            setFieldValue(
                                'viewer_authentication_id',

                                parseInt(e.target.value)
                            );
                        }}
                    >
                        <FormControlLabel
                            value={1}
                            control={<Radio color="primary" />}
                            label="Anonymous"
                        />

                        {gaisAuthenticationEnable === 'true' && (
                            <FormControlLabel
                                value={2}
                                control={<Radio color="primary" />}
                                label="GAIS"
                            />
                        )}
                    </RadioGroup>
                    {gaisApplicationAvailable === 'true' && (
                        <FormTextField
                            component={CheckboxWithLabel}
                            Label={{
                                label: 'Include the latest version of GAIS',
                            }}
                            type="checkbox"
                            checked={!!values?.include_gais}
                            placeholder="Include the latest version of GAIS"
                            indeterminate={
                                values?.include_gais === undefined
                                    ? true
                                    : false
                            }
                            onChange={(e) => {
                                setFieldValue('include_gais', e.target.checked);
                            }}
                        />
                    )}
                    {gapsApplicationAvailable === 'true' && (
                        <FormTextField
                            component={CheckboxWithLabel}
                            Label={{
                                label: 'Include the latest version of GAPS',
                            }}
                            type="checkbox"
                            checked={!!values?.include_gaps}
                            placeholder="Include the latest version of GAPS"
                            indeterminate={
                                values?.include_gaps === undefined
                                    ? true
                                    : false
                            }
                            onChange={(e) => {
                                setFieldValue('include_gaps', e.target.checked);
                            }}
                        />
                    )}
                    <FormTextField
                        component={CheckboxWithLabel}
                        Label={{ label: 'Generate new Landing Screen' }}
                        type="checkbox"
                        checked={!!values?.include_landing_json}
                        placeholder="Generate new Landing Screen"
                        indeterminate={
                            values?.include_landing_json === undefined
                                ? true
                                : false
                        }
                        onChange={(e) => {
                            setFieldValue(
                                'include_landing_json',
                                e.target.checked
                            );
                        }}
                    />
                    <FormTextField
                        component={CheckboxWithLabel}
                        Label={{
                            label: 'Generated zip includes default web.config',
                        }}
                        type="checkbox"
                        checked={!!values?.include_web_config}
                        placeholder="Generated zip includes default web.config"
                        indeterminate={
                            values?.include_web_config === undefined
                                ? true
                                : false
                        }
                        onChange={(e) => {
                            setFieldValue(
                                'include_web_config',
                                e.target.checked
                            );
                        }}
                    />
                    {values?.include_landing_json && (
                        <FormTextField
                            label="Client Display Name"
                            name="client_name_on_landing"
                            placeholder="Client Display Name"
                            value={values?.client_name_on_landing}
                        />
                    )}
                    <FormControl>
                        <Field name={'asset_ids'}>
                            {({
                                field: { value },
                                form: { setFieldValue },
                            }) => (
                                <SelectionAutocomplete
                                    title="Asset"
                                    keyProperty="asset_id"
                                    nameProperty="display_name"
                                    entityIds={value}
                                    setEntityIds={setFieldValue}
                                    multiSelection={true}
                                    freeSolo={false}
                                    entitySelector={(state) =>
                                        selectAssetPage(state).filter(
                                            (asset) => asset.asset_type_id !== 3
                                        )
                                    }
                                    fetchEntityPage={fetchAllAssets}
                                    formField={'asset_ids'}
                                    disablePortal={true}
                                    entityStatusSelector={
                                        selectAssetsLoadingStatus
                                    }
                                    fetchParams={{
                                        lastPublishedDate:
                                            'last_published_date!=null',
                                    }}
                                />
                            )}
                        </Field>
                    </FormControl>
                    <FormControl>
                        <Field className={classes.input} name="procedure_ids">
                            {({
                                field: { value },
                                form: { setFieldValue },
                            }) => (
                                <SelectionAutocomplete
                                    title="Procedure"
                                    keyProperty="asset_id"
                                    nameProperty="display_name"
                                    entityIds={value}
                                    setEntityIds={setFieldValue}
                                    multiSelection={true}
                                    onCustomChange={() => {}}
                                    freeSolo={false}
                                    entitySelector={(state) =>
                                        selectAssetPage(state).filter(
                                            (asset) => asset.asset_type_id === 3
                                        )
                                    }
                                    disablePortal={true}
                                    fetchEntityPage={fetchAllAssets}
                                    formField={'procedure_ids'}
                                    entityStatusSelector={
                                        selectAssetsLoadingStatus
                                    }
                                    fetchParams={{
                                        lastPublishedDate:
                                            'last_published_date!=null',
                                    }}
                                />
                            )}
                        </Field>
                    </FormControl>
                    <SetDirtyForm />
                    <button
                        className={classes.submit}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {formAction.buttonLabel}
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export { DeploymentPackageForm };
