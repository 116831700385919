import React from 'react';
import { Grid } from '@mui/material';
import { useStyles } from './styles';
import { getActiveTree } from 'GeminiViewerComponent/components/Procedure/_features/procedureSlice';
import { useSelector } from 'react-redux';

const ProcedureFooter = () => {
    const classes = useStyles();
    const activeTree = useSelector(getActiveTree);

    return (
        activeTree.title && (
            <Grid container className={classes.footer}>
                <Grid item xs={12}>
                    <div className={classes.footerContent}>
                        {activeTree.title}
                    </div>
                </Grid>
            </Grid>
        )
    );
};

export { ProcedureFooter };
