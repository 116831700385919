import {
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import { Field } from 'formik';
import { useSelector } from 'react-redux';
import { makeFormStyles } from 'forms/styles';
import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { FieldSource } from './ConfigSourceHeader';

const ImageConfigs = ({
    defaultConfigValues,
    values,
    setFieldValue,
    configFields,
    validationTypes,
    readOnlyMode,
    imageSettings,
    setImageSettings,
    getConfigOptionsArray,
}) => {
    const theme = useSelector(selectActiveTheme);
    const classes = makeFormStyles(theme);
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FieldSource
                    source={
                        defaultConfigValues.convert_image_to_jpeg.field_source
                    }
                />
                <Checkbox
                    checked={Boolean(values?.convert_image_to_jpeg)}
                    onChange={(e) => {
                        setFieldValue(
                            'convert_image_to_jpeg',
                            e.target.checked
                        );
                        setImageSettings({
                            ...imageSettings,
                            convert_image_to_jpeg: `${e.target.checked}`,
                        });
                    }}
                />
                {defaultConfigValues.convert_image_to_jpeg.display_name}
            </div>
            <div
                style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
            >
                <FieldSource
                    source={
                        defaultConfigValues.image_compression_percent
                            .field_source
                    }
                />
                <Field
                    className={classes.input}
                    name="image_compression_percent"
                    disabled={readOnlyMode}
                >
                    {({ field: { value }, form: { setFieldValue } }) => (
                        <FormControl sx={{ margin: '8px 0' }} fullWidth>
                            <InputLabel id="image-compression-label">
                                {
                                    defaultConfigValues
                                        .image_compression_percent.display_name
                                }
                            </InputLabel>
                            <Select
                                labelId="image-compression-label"
                                id="image-compression-id"
                                label={
                                    defaultConfigValues
                                        .image_compression_percent.display_name
                                }
                                value={values?.image_compression_percent || ''}
                                onChange={(e) => {
                                    setFieldValue(
                                        'image_compression_percent',
                                        e.target.value
                                    );
                                    setImageSettings({
                                        ...imageSettings,
                                        image_compression_percent:
                                            e.target.value,
                                    });
                                }}
                            >
                                {getConfigOptionsArray(
                                    configFields,
                                    validationTypes,
                                    defaultConfigValues,
                                    'image_compression_percent'
                                ).map((opt) => (
                                    <MenuItem
                                        key={`image_compression_percent${opt}`}
                                        value={opt}
                                    >
                                        {opt}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Field>
            </div>
            <div
                style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
            >
                <FieldSource
                    source={defaultConfigValues.max_image_width.field_source}
                />
                <Field
                    className={classes.input}
                    name="max_image_width"
                    disabled={readOnlyMode}
                >
                    {({ field: { value }, form: { setFieldValue } }) => (
                        <FormControl sx={{ margin: '8px 0' }} fullWidth>
                            <InputLabel id="image-max-width-label">
                                {
                                    defaultConfigValues.max_image_width
                                        .display_name
                                }
                            </InputLabel>
                            <Select
                                labelId="image-max-width-label"
                                id="image-max-width-id"
                                label={
                                    defaultConfigValues.max_image_width
                                        .display_name
                                }
                                value={values?.max_image_width || ''}
                                onChange={(e) => {
                                    setFieldValue(
                                        'max_image_width',
                                        e.target.value
                                    );
                                    setImageSettings({
                                        ...imageSettings,
                                        max_image_width: e.target.value,
                                    });
                                }}
                            >
                                {getConfigOptionsArray(
                                    configFields,
                                    validationTypes,
                                    defaultConfigValues,
                                    'max_image_width'
                                ).map((opt) => (
                                    <MenuItem
                                        key={`max_image_width${opt}`}
                                        value={opt}
                                    >
                                        {opt}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Field>
            </div>
        </>
    );
};

export { ImageConfigs };
