import {
    contentCaptionURL,
    mapVideoContentURL,
} from 'GeminiViewerComponent/_helpers';

export const vttUrlsByLanguageFromUrl = ({ caption_languages, contentURL }) => {
    if (!Array.isArray(caption_languages)) {
        return {};
    }

    let vttUrls = {};

    caption_languages.forEach((caption) => {
        vttUrls[caption] = contentURL?.concat('_', caption, '.vtt');
    });

    return vttUrls;
};

export const getUrlInfoFromContentVersion = (content) => {
    let captionsAvailable = Array.isArray(content?.caption_languages)
        ? content?.caption_languages
        : [];

    let contentURL = mapVideoContentURL(content);

    let vttUrls = vttUrlsByLanguageFromUrl({
        caption_languages: captionsAvailable,
        contentURL: contentCaptionURL(contentURL),
    });

    return {
        url: contentURL,
        vttUrls: vttUrls,
    };
};
