import React, { useState, useEffect, useCallback } from 'react';
import { ThemePreview } from './ThemePreview';
import makeStyles from '@mui/styles/makeStyles';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { makeFormStyles } from 'forms/styles';
import {
    Box,
    Button,
    Slider,
    TextField as MuiTextField,
    IconButton,
} from '@mui/material';
import { MdRefresh } from 'react-icons/md';
import { ChromePicker } from 'react-color';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { selectActiveTheme } from 'GeminiViewerComponent/_features/globals/themeSlice';
import { IconUploadDialog } from './IconUploadDialog';
import ResetThemeIconDialog from './ResetThemeIconDailog';
import { accountsThunk } from 'app/store';
import { SnackbarDismiss } from 'GeminiViewerComponent/components/SnackbarDismiss';
import {
    activateLoading,
    deactivateLoading,
} from 'GeminiViewerComponent/_features/globals/loadingProgressSlice';
import { FormTextField } from 'components/TextField';
const { updateCustomThemeData } = accountsThunk;

// const Container = styled.div`
//     margin-top: 3rem;
//     overflow: scroll;
// `;

// const Section = styled.div`
//     vertical-align: top;
//     margin-right: 10px;
//     padding: 10px;
//     flex: auto;
// `;

const makeThemeFormStyles = makeStyles((theme) => ({
    container: {
        marginTop: '0.5rem',
    },
    section: {
        verticalAlign: 'top',
        marginRight: '10px',
        padding: '10px',
        flex: 'auto',
    },
    form: {
        minWidth: '400px',
        [theme.breakpoints.up('md')]: {
            padding: '0px 10px',
        },
        padding: '0px',
    },
    materialInput: {
        width: '100%',
    },
    colorPickerField: {
        position: 'relative',
        flex: 'auto',
    },
    colorPickerContainer: {
        zIndex: '2',
    },
    fieldRow: {
        display: 'flex',
        width: '100%',
        gap: '5px',
    },
    buttonRow: {
        margin: '5px 0px 5px 0px',
        display: 'flex',
        gap: '5px',
    },
    dropzoneContainer: {
        width: '100%',
        position: 'relative',
    },
    dropzoneAreaCompressed: {
        minHeight: '40px !important',
        maxHeight: '40px !important',
        height: '40px !important',
        '& svg': {
            width: '0px',
            height: '0px',
            visibility: 'hidden',
        },
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        '& .MuiDropzonePreviewList-image': {
            height: '30px',
            width: 'auto',
        },
        '& .MuiDropzoneArea-textContainer': {
            height: '100%',
            width: '50%',
        },
    },
    dropzoneText: {
        fontSize: '12px !important',
        margin: '2px !important',
    },
    iconImage: {
        position: 'absolute',
        right: '0px',
        top: '0px',
        width: '50%',
        marginTop: '5px',
        marginRight: '5px',
        height: '30px',
        pointerEvents: 'none',
    },
    fieldSet: {
        border: '1px solid #cccccc',
        padding: '5px',
        borderRadius: '5px',
        width: '50%',
        marginBottom: '4px',
        height: 'fit-content',
    },
    legend: {
        marginLeft: '10px',
        padding: '3px',
        fontSize: '14px',
        color: '#6d7178',
    },
}));

const themePreviewStyle = {
    minWidth: '400px',
    borderRadius: '5px',
    marginTop: '8px',
    overflow: 'auto',
    border: '1px solid #c4c4c4',
};

const ThemeColorField = ({ name, ...otherProps }) => {
    const theme = useSelector(selectActiveTheme);

    const themeFormStyles = makeThemeFormStyles();
    const commonFormStyles = makeFormStyles(theme);

    const [pickerFieldName, setPickerFieldName] = useState('');
    const [previousColor, setPreviousColor] = useState('');

    const togglePickerField = (name) => {
        if (pickerFieldName === name) {
            setPickerFieldName('');
        } else {
            setPickerFieldName(name);
        }
    };

    return (
        <Field className={commonFormStyles.input} name={name}>
            {({ field: { onBlur, value, name }, form: { setFieldValue } }) => (
                <div className={themeFormStyles.colorPickerField}>
                    <MuiTextField
                        className={themeFormStyles.materialInput}
                        value={value}
                        onChange={(event) => {
                            setFieldValue(name, event.target.value);
                        }}
                        margin="dense"
                        variant="outlined"
                        type="text"
                        onBlur={onBlur}
                        InputProps={{
                            endAdornment: (
                                <Button
                                    style={{
                                        backgroundColor: value,
                                        minWidth: '30px',
                                        height: '30px',
                                        width: '30px',
                                        border: '1px solid black',
                                    }}
                                    onClick={() => {
                                        setPreviousColor(value);
                                        togglePickerField(name);
                                    }}
                                />
                            ),
                        }}
                        {...otherProps}
                    />

                    <div className={themeFormStyles.colorPickerField}>
                        {pickerFieldName === name && (
                            <div
                                className={themeFormStyles.colorPickerContainer}
                            >
                                <ChromePicker
                                    color={value}
                                    onChangeComplete={(color) => {
                                        setFieldValue(name, color.hex);
                                    }}
                                />
                                <div className={themeFormStyles.buttonRow}>
                                    <button
                                        className={
                                            commonFormStyles.cancelButtonSmall
                                        }
                                        onClick={() => {
                                            setFieldValue(name, previousColor);
                                            togglePickerField(name);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className={
                                            commonFormStyles.submitButtonSmall
                                        }
                                        onClick={() => {
                                            togglePickerField(name);
                                        }}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Field>
    );
};

const EditThemeForm = ({ theme, updateTheme, newTheme, activeUser }) => {
    const themeFormStyles = makeThemeFormStyles();
    const commonFormStyles = makeFormStyles(theme);

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const clientID = activeUser?.client_id;
    const s3Prefix = activeUser?.s3_prefix;

    const [initValues, setInitValues] = useState({ ...theme });
    const [iconUploadDialog, setIconUploadDialog] = useState(null);
    const [iconResetDialog, setIconResetDialog] = useState(null);
    const [iconData, setIconData] = useState(null);
    const [reRenderImg, setReRenderImg] = useState(new Date().getTime());

    useEffect(() => {
        setInitValues({ ...theme, homeIconImage: [] });
    }, [theme]);

    const handleSubmit = async (values, setSubmitting) => {
        if (iconData) {
            dispatch(activateLoading());
            const resultAction = await dispatch(
                updateCustomThemeData({ ...iconData, theme_id: theme.id })
            );
            setReRenderImg(new Date().getTime());
            dispatch(deactivateLoading());
            if (resultAction.error) {
                enqueueSnackbar('Icon update failed, Please try again later', {
                    action: (key) => <SnackbarDismiss key={key} />,
                    variant: 'error',
                });
                return;
            }
        }
        await updateTheme(values);
        setSubmitting(false);
    };

    const handleSaveAs = async ({ values, setSubmitting }) => {
        values.id = uuidv4();
        await newTheme(values, iconData);
        setSubmitting(false);
    };

    const handleResetIcon = (resetIcon) => {
        let tempIconData = iconData;
        delete tempIconData?.[resetIcon];
        delete tempIconData?.[`${resetIcon}_url`];
        tempIconData = { ...iconData, [`${resetIcon}_reset`]: true };
        setIconData(tempIconData);
        setReRenderImg(new Date().getTime());
        setIconResetDialog(null);
    };

    const showIcon = useCallback(
        (key) => {
            var src = '';
            if (iconData?.[`${key}_url`]) {
                src = `${iconData?.[`${key}_url`]}`;
            } else {
                src = `${s3Prefix}client_${clientID}/ThemeData${
                    !iconData?.[`${key}_reset`] &&
                    theme.id &&
                    theme.id != 'T_001'
                        ? '/' + theme.id
                        : ''
                }/${key.replace('navigation', 'nav')}.svg?${reRenderImg}`;
            }
            return src;
        },
        [iconData, reRenderImg, theme]
    );

    return (
        <div className={themeFormStyles.container}>
            <Formik
                enableReinitialize={true}
                initialValues={{ ...initValues }}
                validationSchema={Yup.object({
                    name: Yup.string().required('Required'),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit({ ...values }, setSubmitting);
                }}
            >
                {({ values, isSubmitting, setSubmitting, setFieldValue }) => (
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        width="100%"
                        padding="5px"
                    >
                        <Box
                            sx={{
                                width: { xs: '100%', md: '50%' },
                                marginRight: { xs: '0', md: '5px' },
                                marginTop: { xs: '15px', md: '0' },
                            }}
                            overflow="auto"
                            height="100%"
                        >
                            <Form
                                className={clsx(
                                    commonFormStyles.form,
                                    themeFormStyles.form
                                )}
                            >
                                <FormTextField
                                    autoFocus
                                    label="Theme Name"
                                    name="name"
                                    placeholder="Theme Name"
                                />
                                <ThemeColorField
                                    label="Body Color"
                                    name="colors.body"
                                    placeholder="Body Color"
                                />
                                <div className={themeFormStyles.fieldRow}>
                                    <ThemeColorField
                                        label="Side Panel Body Background"
                                        placeholder="Side Panel Primary Background"
                                        name="colors.side_panel_body"
                                    />
                                    <ThemeColorField
                                        label="Side Panel Body Foreground/Text"
                                        placeholder="Primary Foreground/Text"
                                        name="colors.text"
                                    />
                                </div>
                                <div className={themeFormStyles.fieldRow}>
                                    <ThemeColorField
                                        label="Primary Background"
                                        placeholder="Primary Background"
                                        name="colors.primarySectionBackground"
                                    />
                                    <ThemeColorField
                                        label="Primary Foreground/Text"
                                        placeholder="Primary Foreground/Text"
                                        name="colors.primarySectionForeground"
                                    />
                                </div>
                                <div className={themeFormStyles.fieldRow}>
                                    <ThemeColorField
                                        label="Secondary Background"
                                        placeholder="Secondary Background"
                                        name="colors.secondarySectionBackground"
                                    />
                                    <ThemeColorField
                                        label="Secondary Foreground/Text"
                                        placeholder="Secondary Foreground/Text"
                                        name="colors.secondarySectionForeground"
                                    />
                                </div>
                                <div className={themeFormStyles.fieldRow}>
                                    <ThemeColorField
                                        label="Primary Button Background"
                                        placeholder="Primary Button Background"
                                        name="colors.button.primaryBackground"
                                    />
                                    <ThemeColorField
                                        label="Primary Button Text"
                                        placeholder="Primary Button Text"
                                        name="colors.button.primaryForeground"
                                    />
                                </div>
                                <div className={themeFormStyles.fieldRow}>
                                    <ThemeColorField
                                        label="Secondary Button Background"
                                        placeholder="Secondary Button Background"
                                        name="colors.button.secondaryBackground"
                                    />
                                    <ThemeColorField
                                        label="Secondary Button Text"
                                        placeholder="Secondary Button Text"
                                        name="colors.button.secondaryForeground"
                                    />
                                </div>
                                <div className={themeFormStyles.fieldRow}>
                                    <ThemeColorField
                                        label="Accent Button Background"
                                        placeholder="Accent Button Background"
                                        name="colors.button.accentBackground"
                                    />
                                    <ThemeColorField
                                        label="Accent Button Text"
                                        placeholder="Accent Button Text"
                                        name="colors.button.accentForeground"
                                    />
                                </div>
                                <div className={themeFormStyles.fieldRow}>
                                    <ThemeColorField
                                        label="Link Button Background Color"
                                        placeholder="Link Button Background Color"
                                        name="colors.link_button_background_color"
                                    />
                                    <ThemeColorField
                                        label="Link Button Text Color"
                                        placeholder="Link Button Text Color"
                                        name="colors.link_button_text_color"
                                    />
                                </div>
                                <div className={themeFormStyles.fieldRow}>
                                    <ThemeColorField
                                        label="Pano Background Color"
                                        placeholder="Pano Background Color"
                                        name="colors.pano_background_color"
                                    />
                                    <ThemeColorField
                                        label="Orbit Background Color"
                                        placeholder="Orbit Background Color"
                                        name="colors.orbit_background_color"
                                    />
                                </div>

                                {/* Chapter buttons */}
                                <Box
                                    component="fieldset"
                                    border="1px solid #cccccc"
                                    padding="5px"
                                    borderRadius="5px"
                                >
                                    <Box
                                        component="legend"
                                        marginLeft="10px"
                                        padding="3px"
                                        fontSize="14px"
                                        color="#6d7178"
                                    >
                                        Chapter buttons
                                    </Box>
                                    <div className={themeFormStyles.fieldRow}>
                                        <ThemeColorField
                                            label="Background Color"
                                            placeholder="Background Color"
                                            name="colors.chapter_button.background_color"
                                        />
                                        <ThemeColorField
                                            label="Text Color"
                                            placeholder="Text Color"
                                            name="colors.chapter_button.text_color"
                                        />
                                    </div>
                                    <div className={themeFormStyles.fieldRow}>
                                        <Box width="50%">
                                            <ThemeColorField
                                                label="Active Color"
                                                placeholder="Active Color"
                                                name="colors.chapter_button.active_color"
                                            />
                                        </Box>
                                    </div>
                                </Box>

                                {/* Procedure Content */}
                                <fieldset
                                    style={{
                                        border: '1px solid #cccccc',
                                        padding: '5px',
                                        borderRadius: '5px',
                                    }}
                                >
                                    <legend
                                        style={{
                                            marginLeft: '10px',
                                            padding: '3px',
                                            fontSize: '14px',
                                            color: '#6d7178',
                                        }}
                                    >
                                        Procedure Content
                                    </legend>

                                    <div className={themeFormStyles.fieldRow}>
                                        <ThemeColorField
                                            label="Link Visited Color"
                                            placeholder="Link Visited Color"
                                            name="colors.link_visited_color"
                                        />
                                        <ThemeColorField
                                            label="Link Hover Color"
                                            placeholder="Link Hover Color"
                                            name="colors.link_hover_color"
                                        />
                                    </div>
                                    <div className={themeFormStyles.fieldRow}>
                                        <Box width="50%">
                                            <ThemeColorField
                                                label="Link Text Color"
                                                placeholder="Link Text Color"
                                                name="colors.link_text_color"
                                            />
                                        </Box>
                                    </div>
                                </fieldset>

                                {/* Tag Bar */}
                                <fieldset
                                    style={{
                                        border: '1px solid #cccccc',
                                        padding: '5px',
                                        borderRadius: '5px',
                                    }}
                                >
                                    <legend
                                        style={{
                                            marginLeft: '10px',
                                            padding: '3px',
                                            fontSize: '14px',
                                            color: '#6d7178',
                                        }}
                                    >
                                        Tag Bar
                                    </legend>
                                    <div className={themeFormStyles.fieldRow}>
                                        <ThemeColorField
                                            label="Main Background Color"
                                            placeholder="Main Background Color"
                                            name="colors.main_background_color"
                                        />
                                        <fieldset
                                            className={
                                                themeFormStyles?.fieldSet
                                            }
                                        >
                                            <legend
                                                className={
                                                    themeFormStyles?.legend
                                                }
                                            >
                                                Main Background Opacity
                                            </legend>
                                            <Slider
                                                size="small"
                                                defaultValue={70}
                                                aria-label="Small"
                                                valueLabelDisplay="auto"
                                                style={{ width: '100%' }}
                                                value={Number(
                                                    values?.opacity
                                                        ?.main_background_opacity
                                                )}
                                                onChange={(event, newValue) => {
                                                    setFieldValue('opacity', {
                                                        ...values.opacity,
                                                        main_background_opacity:
                                                            newValue,
                                                    });
                                                }}
                                            />
                                        </fieldset>
                                    </div>
                                    <div className={themeFormStyles.fieldRow}>
                                        <ThemeColorField
                                            label="Tag Active Background Color"
                                            placeholder="Tag Active Background Color"
                                            name="colors.tag_active_background_color"
                                        />
                                        <ThemeColorField
                                            label="Tag Inactive Background Color"
                                            placeholder="Tag Inactive Background Color"
                                            name="colors.tag_inactive_background_color"
                                        />
                                    </div>
                                    <div className={themeFormStyles.fieldRow}>
                                        <fieldset
                                            className={
                                                themeFormStyles?.fieldSet
                                            }
                                        >
                                            <legend
                                                className={
                                                    themeFormStyles?.legend
                                                }
                                            >
                                                Tag Background Opacity
                                            </legend>
                                            <Slider
                                                size="small"
                                                defaultValue={70}
                                                aria-label="Small"
                                                valueLabelDisplay="auto"
                                                style={{ width: '100%' }}
                                                value={Number(
                                                    values?.opacity
                                                        ?.tag_background_opacity
                                                )}
                                                onChange={(event, newValue) => {
                                                    setFieldValue('opacity', {
                                                        ...values.opacity,
                                                        tag_background_opacity:
                                                            newValue,
                                                    });
                                                }}
                                            />
                                        </fieldset>
                                        <fieldset
                                            className={
                                                themeFormStyles?.fieldSet
                                            }
                                        >
                                            <legend
                                                className={
                                                    themeFormStyles?.legend
                                                }
                                            >
                                                Tag Foreground Opacity
                                            </legend>
                                            <Slider
                                                size="small"
                                                defaultValue={70}
                                                aria-label="Small"
                                                valueLabelDisplay="auto"
                                                style={{ width: '100%' }}
                                                value={Number(
                                                    values?.opacity
                                                        ?.tag_foreground_opacity
                                                )}
                                                onChange={(event, newValue) => {
                                                    setFieldValue('opacity', {
                                                        ...values.opacity,
                                                        tag_foreground_opacity:
                                                            newValue,
                                                    });
                                                }}
                                            />
                                        </fieldset>
                                    </div>
                                    <div className={themeFormStyles.fieldRow}>
                                        <ThemeColorField
                                            label="Icon Active color"
                                            placeholder="Icon Active color"
                                            name="colors.icon_active_color"
                                        />
                                        <ThemeColorField
                                            label="Icon Inactive Color"
                                            placeholder="Icon Inactive Color"
                                            name="colors.icon_inactive_color"
                                        />
                                    </div>
                                </fieldset>

                                {/* Navigation Menu */}
                                <Box
                                    component="fieldset"
                                    border="1px solid #cccccc"
                                    padding="5px"
                                    borderRadius="5px"
                                >
                                    <Box
                                        component="legend"
                                        marginLeft="10px"
                                        padding="3px"
                                        fontSize="14px"
                                        color="#6d7178"
                                    >
                                        Navigation Menu
                                    </Box>
                                    <div className={themeFormStyles.fieldRow}>
                                        <Field
                                            className={commonFormStyles.input}
                                            name="navigation_hamburger_icon"
                                        >
                                            {() => (
                                                <div
                                                    className={
                                                        themeFormStyles.colorPickerField
                                                    }
                                                    onClick={() =>
                                                        setIconUploadDialog(
                                                            'navigation_hamburger_icon'
                                                        )
                                                    }
                                                >
                                                    <MuiTextField
                                                        className={
                                                            themeFormStyles.materialInput
                                                        }
                                                        margin="dense"
                                                        variant="outlined"
                                                        type="text"
                                                        label="Hamburger Menu Icon"
                                                        InputProps={{
                                                            readOnly: true,
                                                            endAdornment: (
                                                                <IconButton
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        setIconResetDialog(
                                                                            'navigation_hamburger_icon'
                                                                        );
                                                                    }}
                                                                >
                                                                    <MdRefresh className="react-icon" />
                                                                </IconButton>
                                                            ),
                                                            startAdornment: (
                                                                <img
                                                                    src={showIcon(
                                                                        'navigation_hamburger_icon'
                                                                    )}
                                                                    style={{
                                                                        height: '40px',
                                                                        width: '112px',
                                                                    }}
                                                                />
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Field>

                                        <Field
                                            className={commonFormStyles.input}
                                        >
                                            {() => (
                                                <div
                                                    className={
                                                        themeFormStyles.colorPickerField
                                                    }
                                                    onClick={() =>
                                                        setIconUploadDialog(
                                                            'navigation_back_icon'
                                                        )
                                                    }
                                                >
                                                    <MuiTextField
                                                        className={
                                                            themeFormStyles.materialInput
                                                        }
                                                        margin="dense"
                                                        variant="outlined"
                                                        type="text"
                                                        label="Back Button Icon"
                                                        InputProps={{
                                                            readOnly: true,
                                                            endAdornment: (
                                                                <IconButton
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        setIconResetDialog(
                                                                            'navigation_back_icon'
                                                                        );
                                                                    }}
                                                                >
                                                                    <MdRefresh className="react-icon" />
                                                                </IconButton>
                                                            ),
                                                            startAdornment: (
                                                                <img
                                                                    src={showIcon(
                                                                        'navigation_back_icon'
                                                                    )}
                                                                    style={{
                                                                        height: '40px',
                                                                        width: '112px',
                                                                    }}
                                                                />
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                    <div className={themeFormStyles.fieldRow}>
                                        <Field
                                            className={commonFormStyles.input}
                                            name="navigation_home_icon"
                                        >
                                            {() => (
                                                <div
                                                    className={
                                                        themeFormStyles.colorPickerField
                                                    }
                                                    onClick={() =>
                                                        setIconUploadDialog(
                                                            'navigation_home_icon'
                                                        )
                                                    }
                                                >
                                                    <MuiTextField
                                                        className={
                                                            themeFormStyles.materialInput
                                                        }
                                                        margin="dense"
                                                        variant="outlined"
                                                        type="text"
                                                        label="Home Button Icon"
                                                        InputProps={{
                                                            readOnly: true,
                                                            endAdornment: (
                                                                <IconButton
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        setIconResetDialog(
                                                                            'navigation_home_icon'
                                                                        );
                                                                    }}
                                                                >
                                                                    <MdRefresh className="react-icon" />
                                                                </IconButton>
                                                            ),
                                                            startAdornment: (
                                                                <img
                                                                    src={showIcon(
                                                                        'navigation_home_icon'
                                                                    )}
                                                                    style={{
                                                                        height: '40px',
                                                                        width: '112px',
                                                                    }}
                                                                />
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                        <Field
                                            className={commonFormStyles.input}
                                            name="zone_media_icon"
                                        >
                                            {() => (
                                                <div
                                                    className={
                                                        themeFormStyles.colorPickerField
                                                    }
                                                    onClick={() =>
                                                        setIconUploadDialog(
                                                            'zone_media_icon'
                                                        )
                                                    }
                                                >
                                                    <MuiTextField
                                                        className={
                                                            themeFormStyles.materialInput
                                                        }
                                                        margin="dense"
                                                        variant="outlined"
                                                        type="text"
                                                        label="Zone Media Icon"
                                                        InputProps={{
                                                            readOnly: true,
                                                            endAdornment: (
                                                                <IconButton
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        setIconResetDialog(
                                                                            'zone_media_icon'
                                                                        );
                                                                    }}
                                                                >
                                                                    <MdRefresh className="react-icon" />
                                                                </IconButton>
                                                            ),
                                                            startAdornment: (
                                                                <img
                                                                    src={showIcon(
                                                                        'zone_media_icon'
                                                                    )}
                                                                    style={{
                                                                        height: '40px',
                                                                        width: '112px',
                                                                    }}
                                                                />
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                        <Box width="90%" />
                                    </div>

                                    {/* <div className={themeFormStyles.fieldRow}>
                                        <ThemeColorField
                                            label="Hamburger Menu Color"
                                            placeholder="Hamburger Menu Color"
                                            name="colors.navigation_hamburger_icon_color"
                                        />
                                        <ThemeColorField
                                            label="Back Button Color"
                                            placeholder="Back Button Color"
                                            name="colors.navigation_back_icon_color"
                                        />
                                    </div>
                                    <div className={themeFormStyles.fieldRow}>
                                        <ThemeColorField
                                            label="Home Button color"
                                            placeholder="Home Button color"
                                            name="colors.navigation_home_icon_color"
                                        />
                                        <Box width="61%" />
                                    </div> */}
                                </Box>

                                <div className={themeFormStyles.buttonRow}>
                                    {theme?.id !== undefined ? (
                                        <button
                                            className={commonFormStyles.submit}
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            Save
                                        </button>
                                    ) : (
                                        <button
                                            className={commonFormStyles.submit}
                                            type="button"
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                setSubmitting(true);
                                                handleSaveAs({
                                                    values: values,
                                                    setSubmitting:
                                                        setSubmitting,
                                                });
                                            }}
                                        >
                                            Add
                                        </button>
                                    )}
                                </div>
                            </Form>
                        </Box>
                        <Box
                            sx={{
                                width: { xs: '100%', md: 'calc(50% - 10px)' },
                                order: { xs: '-1', md: '0' },
                            }}
                            height="100%"
                        >
                            <ThemePreview
                                style={themePreviewStyle}
                                theme={{ theme: values }}
                            />
                        </Box>
                    </Box>
                )}
            </Formik>
            <IconUploadDialog
                dialogData={iconUploadDialog}
                setDialogData={setIconUploadDialog}
                setIconData={setIconData}
            />

            <ResetThemeIconDialog
                dialogData={iconResetDialog}
                setDialogData={setIconResetDialog}
                handleReset={handleResetIcon}
            />
        </div>
    );
};

export default EditThemeForm;
